import { Injectable } from '@angular/core';

import { Observable} from 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';


import * as _ from 'lodash';
//import { Quickbpclass } from 'src/app/interface/quickbp';
import { IndicatorService } from '../indicator/indicator.service';
import { ProductService } from '../product/product.service';
import { Quickbpclass } from 'app/interface/quickbp';
import { promise } from 'protractor';
@Injectable({
  providedIn: 'root'
})
export class CalltrackingService {
  baseUri: string = '/api/call';
  //public indicator: IndicatorModel = new IndicatorModel();
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
   headers: { 
     'Content-Type': 'application/json',
     "Authorization" : "Bearer "+ this.Params.params.token
   },
    'id':''   };
    value;
  constructor(
    private http: HttpClient, 
    private Params: ParamsService,
    private API : ApiService,
    private productService: ProductService,
    private indicator:IndicatorService,
    ) { }
    sharedData: Quickbpclass = new Quickbpclass;

        /** DE21112
         * Check for existing call tracking lines. Currently not in use. Needs more discussions before implementation
         * @return true if existing number found else false
         */
        async checkExistingLine(data :any , orderItem : any):Promise<any> {
            //return new Promise((resolve, reject) => {
                var itemId = data.enterpriseItemIds.enterpriseItemId;

                //getContent for item from vision
                

                //get callTrackingLines from CTS
                var ctsLines = await this.getByItemId(itemId);

                let promiseArray = [ctsLines];

                if(orderItem.order.productType ==="Digital"){
                    var product = await this.productService.getDigitalContent(itemId);
                    promiseArray.push(product);
                } else{
                    return;
                }

                Observable.forkJoin(promiseArray).subscribe(function (results) {
                    var allExistingTrackingLines = [];

                    if(results[1] && results[1].data && 
                        results[1].data[data.enterpriseItemIds.enterpriseItemId].products && 
                        results[1].data[data.enterpriseItemIds.enterpriseItemId].products[0]){
                        var productTrackingLines = [];
                        var productData = results[1].data[data.enterpriseItemIds.enterpriseItemId].products[0];
                        productTrackingLines = productData.TrackingIdList ? productData.TrackingIdList : [] ;
                        
                        //http://confluence.dexknows.com/display/v/UpdateContent+-+SEM+Plus+Standard for TrackingIdList format
                        for (var j = 0; j < productTrackingLines.length; j++) {
                            allExistingTrackingLines.push({
                                terminationNumber: productTrackingLines[j].DestinationNumber,
                                distributionType:productTrackingLines[j].Source
                            });
                        }
                    }

                    if(results[0] && results[0].data  && results[0].data.trackingLines){
                        var ctsTrackingLines = results[0].data.trackingLines;

                        for (var j = 0; j < ctsTrackingLines.trackingLine.length; j++) {
                            allExistingTrackingLines.push({
                                terminationNumber: ctsTrackingLines.trackingLine[j].terminationNumber,
                                distributionType: ctsTrackingLines.trackingLine[j].distributionType
                            });
                        }
                    }

                    

                    //Now we have all call tracking lines available.
                    //Check if line already exists and if it does not exist then make a call else reject the promise
                    var numberFound = false;

                    for (var k = 0; k < allExistingTrackingLines.length; k++) {
                        if (orderItem.order.productType ==="Digital" && allExistingTrackingLines[k].distributionType === data.distributionType && allExistingTrackingLines[k].terminationNumber === data.terminationNumber) {
                            numberFound = true;
                            break;
                        } else if (orderItem.order.productType ==="Print" && allExistingTrackingLines[k].terminationNumber === data.terminationNumber) {
                            numberFound = true;
                            break;
                        }
                    }

                    return (numberFound);
                });
            //});

        };

        async create(data, orderItem) {
         let present = await this.checkExistingLine(data, orderItem);
         if(!present){
                return await this.http.post(this.API.get(this.baseUri),data).toPromise()
                    
            } else {
                let message =  "Calltracking line already exists for termination number: " + data.terminationNumber +
                                ", distributionType: " + data.distributionType
                return message;
                    
            }
        //  return this.checkExistingLine(data, orderItem).then(present =>{
        //     if(!present) {
        //         return this.http.post(this.API.get(this.baseUri),data)
                
        //     } else {
        //         let message =  "Calltracking line already exists for termination number: " + data.terminationNumber +
        //                         ", distributionType: " + data.distributionType
        //         return message;
                
        //     }

        //    })
           
            
        }
        update(data : any):Observable<any> {
          this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
          return this.http.put(this.API.get(this.baseUri), data,this.httpOptions)
            //return $http.put(API.get(vm.PATH), data);
        };

        validate(data : any):Observable<any>{
            let baseUri = "/api/call/validate";
            this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
            return this.http.put(this.API.get(baseUri), data,this.httpOptions)
            //return $http.put(API.get(PATH), data);
       
        };

        getByAccountId(accId) : Observable<any> {
          this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
          return this.http.get(this.API.get(this.baseUri)+ "/" + "acct" + "/" + accId,this.httpOptions)
            //return $http.get(API.get(vm.PATH) + "/" + "acct" + "/" + accId, { cache: false });
        };

        getByItemId(itemId:any) : Promise<any>{
          this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
          return this.http.get(this.API.get(this.baseUri)+ "/" + itemId,this.httpOptions).toPromise();
           // return $http.get(API.get(vm.PATH) + "/" + itemId, { cache: true }); //results will not change ina session. So caching true
        };

        getByYpcListing(listingId) :  Observable<any> {
          this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
          return this.http.get(this.API.get(this.baseUri)+ "/" + "ypc" + "/" + listingId,this.httpOptions)
            //return $http.get(API.get(vm.PATH) + "/" + "ypc" + "/" + listingId, { cache: false });
        };

        getDigitalCTs(itemId) {
            this.getByItemId(itemId).then(data => {
                var allCTs = [];

                //get tibco CTs
                try {
                    if (data.data.trackingLines) {
                        var tibcoCTs = data.data.trackingLines.trackingLine;
                        tibcoCTs.forEach(function (i) {
                            delete i.enterpriseItemId
                        });
                        allCTs.push.apply(allCTs, tibcoCTs);
                    }
                } catch (error) {
                    //nothing, just using try to avoid 5 if &&s
                }

                //get CTs from vision CT/item association database
                try {
                    if (_.isArray(this.indicator.items[this.sharedData.stateParamsId].data.products[1].trackingLines.trackingLine)) {
                        allCTs.push.apply(allCTs, this.indicator.items[this.sharedData.stateParamsId].data.products[1].trackingLines.trackingLine);
                    } else if (_.isObject(this.indicator.items[this.sharedData.stateParamsId].data.products[1].trackingLines.trackingLine)) {
                        allCTs.push(this.indicator.items[this.sharedData.stateParamsId].data.products[1].trackingLines.trackingLine);
                    }
                } catch (error) {
                    //nothing, just using try to avoid 5 if &&s
                }

                if (allCTs.length !== 0) {
                    var uniqueCTs = _.uniq(allCTs, function (item, key, a) {
                        return item.callTrackingId;
                    });
                }
            })
        };

        updateCTS(data) :Promise<any> {
          let baseUri = "/api/call/updateCTS";
            this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
            return this.http.put(this.API.get(baseUri),data,this.httpOptions).toPromise()
            //return $http.put(API.get(PATH), data);
        };
        terminate(data):Promise<any> {
          let baseUri = "/api/call/terminate";
          this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
          return this.http.put(this.API.get(baseUri),data,this.httpOptions).toPromise()
            //return $http.put(API.get(PATH), data);
        };


}
