import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewRef } from '@angular/core';

import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { SharedService } from '@app/service/shared/shared.service';
import { Subscription } from 'rxjs';
import { MatAccordion } from '@angular/material';

@Component({
  selector: 'app-bpcatkey',
  templateUrl: './bpcatkey.component.html',
  styleUrls: ['./bpcatkey.component.scss']
})
export class BpcatkeyComponent implements OnInit {
  sharedData: Quickbpclass;
  subscription: any;
  showNoCategories = false;
  productsLimitBreached: boolean
  OverallLimitBreached: boolean = false;
  catkeytypes = ['Products', 'Affiliations', 'Brands', 'Services', 'Certifications', 'Specialities'];
  categories = new Map();//Map[category , Map[catKeyType , {keys : [ {catkeyId : '',selected : '', _content_ : '' } ], All : '' }]] --Used in HTML view

  @ViewChild('accordion',{static:true}) Accordion: MatAccordion
  bpAccordianSub: Subscription;

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.bpAccordianSub = this.sharedService.getAccordianState().subscribe(state =>{
      if(state){
        this.Accordion.openAll();
      }else{
        this.Accordion.closeAll();
      }
    });
    this.subscription = this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    // Reading data from service and contructing a map which is used in display
    // Iterate through all cat key types Affiliations , products etc
    for (let catkeytype of this.catkeytypes) {
      //Iterate through each element of catkey type array
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype] && 
        this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype] != undefined && 
        this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype] != null && 
        this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype].length > 0) {
        for (let individualCatKeyObj of this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype]) {
          //If atleast one catkey is present in the arrary
          if (!this.categories.has(individualCatKeyObj.category)) {
            //If the category of catkey is not present in main map
            let submap = new Map();
            let catkeyarray = [];
            let caykeyobj = {};
            let submapvalueobj = {};
            if (!(individualCatKeyObj.category == '')) {
              //Contruct the catkey object
              caykeyobj['catkeyId'] = individualCatKeyObj.catkeyId
              caykeyobj['selected'] = individualCatKeyObj.selected
              caykeyobj['_content_'] = individualCatKeyObj._content_
              // Push the cat key into array
              catkeyarray.push(caykeyobj);
              // Push the array into submapvalueobj
              submapvalueobj['catkeys'] = catkeyarray
              submapvalueobj['all'] = 'N'
              submap.set(catkeytype, submapvalueobj)
              //Push the submap into main map
              this.categories.set(individualCatKeyObj.category, submap)
            }
          } else {
            //If category is present in main map
            let submap
            let submapvalueobj = {}
            // Fetch the catkey type sub map from main map
            submap = this.categories.get(individualCatKeyObj.category)
            if (!submap.has(catkeytype)) {
              //If submap does not have the catkey type
              let catkeyarray = [];
              let caykeyobj = {};
              let submapvalueobj = {};
              if (!(individualCatKeyObj.category == '')) {
                // Build catkey object
                caykeyobj['catkeyId'] = individualCatKeyObj.catkeyId
                caykeyobj['selected'] = individualCatKeyObj.selected
                caykeyobj['_content_'] = individualCatKeyObj._content_
                // push the catkey object into array
                catkeyarray.push(caykeyobj)
                // push the array into submap (fetched from main map)
                submapvalueobj['catkeys'] = catkeyarray
                submapvalueobj['all'] = 'N'
                submap.set(catkeytype, submapvalueobj)
              }
            } else {
              //If submap contains the catkey type
              let catkeyarray = [];
              let catkeyobj = {};
              let catkeyexists = false
              let submapvalueobj
              //Fetch the catkey array from submap using catkeytype
              submapvalueobj = submap.get(catkeytype)
              catkeyarray = submap.get(catkeytype).catkeys
              // Check if incoming catkey is already present (this is for setting behaviour view )
              if (catkeyarray && catkeyarray != null && !(catkeyarray === undefined)) {
                for (let catkey of catkeyarray) {
                  if (catkey.catkeyId == individualCatKeyObj.catkeyId) {
                    catkeyexists = true
                    break
                  }
                }
              }
              if (!catkeyexists) {
                if (!(individualCatKeyObj.category == '')) {
                  //If incoming cat key is not present, build the cat key object
                  catkeyobj['catkeyId'] = individualCatKeyObj.catkeyId
                  catkeyobj['selected'] = individualCatKeyObj.selected
                  catkeyobj['_content_'] = individualCatKeyObj._content_
                  // push the catkey object into array
                  catkeyarray.push(catkeyobj)
                }
              }
            }
          }
        }
      }
    }
    // Check if all has to be checked
    if (this.categories && this.categories != undefined && this.categories != null && this.categories.size > 0) {
      this.categories.forEach((catkeytypes: any, key: string) => {
        if (catkeytypes && catkeytypes != null && !(catkeytypes === undefined)) {
          catkeytypes.forEach((catkeytypesobj: any, key: string) => {
            let notselected
            // Sort the catkeys array
            if (catkeytypesobj.catkeys != undefined && catkeytypesobj.catkeys != null) {
              catkeytypesobj.catkeys = this.sortByContent(catkeytypesobj.catkeys)
              notselected = false
              for (let catkey of catkeytypesobj.catkeys) {
                // if(this.sharedData['profiles'][this.sharedData.stateParamsId].model['CustomCatKeys'] && 
                //   this.sharedData['profiles'][this.sharedData.stateParamsId].model['CustomCatKeys'][key]) {
                //     let custCatkey = this.sharedData['profiles'][this.sharedData.stateParamsId].model['CustomCatKeys'][key]
                //     if(custCatkey && custCatkey.length > 0) {
                //       for (let j = 0; j < custCatkey.length; j++) {
                //         if(custCatkey[j]['text'] == catkey._content_) {
                //           catkey.selected = 'Y'
                //         }
                //       }
                //     }
                // }
                if (catkey.selected == 'N') {
                  notselected = true
                  break;
                }
              }
            }
            if (notselected)
              catkeytypesobj.all = 'N'
            else
              catkeytypesobj.all = 'Y'
          });
        }
      });
    } else {
      this.showNoCategories = true;
    }
    console.log('categories', this.categories);
  }

  // Sorts the array of catkeys based on catkey name
  sortByContent(catKeyArray: [any]) {
    catKeyArray.sort((catkey1, catkey2) => {
      if (catkey1['_content_'] < catkey2['_content_'])
        return -1;
      if (catkey1['_content_'] > catkey2['_content_'])
        return 1;
      return 0;
    });
    return catKeyArray
  }

  // Triggered upon clicking any one of 'All' checkbox
  allcheckbox(evnt, evnt1, evnt2) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    if (evnt2.target.checked) {
      // If all check box is selected then select all catkeys in view map
      this.categories.get(evnt).get(evnt1).all = 'Y'
      for (let catkey of this.categories.get(evnt).get(evnt1).catkeys) {
        catkey.selected = 'Y'
      }
      // If all check box is selected then select all catkeys in sharedData
      for (let sharedobj of this.sharedData['profiles'][this.sharedData.stateParamsId].model[evnt1]) {
        if (sharedobj.category == evnt) {
          sharedobj.selected = 'Y'
        }
      }
    } else {
      // If all check box is de-selected then de-select all catkeys in view map
      this.categories.get(evnt).get(evnt1).all = 'N'
      for (let catkey of this.categories.get(evnt).get(evnt1).catkeys) {
        catkey.selected = 'N'
      }
      // If all check box is de-selected then de-select all catkeys in sharedData
      for (let sharedobj of this.sharedData['profiles'][this.sharedData.stateParamsId].model[evnt1]) {
        if (sharedobj.category == evnt) {
          sharedobj.selected = 'N'
        }
      }
    }
    this.setLimits()
  }

  // Triggered upon clicking any one of the catkey checkbox
  // evnt- category, evnt1- categoryType, evnt2- counter, evnt3- event, evnt4- content
  Individualcheckbox(evnt, evnt1, evnt2, evnt3, evnt4) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    let notAllChecked;
    let notAllUnChecked;
    if (evnt3.target.checked) {
      //If checkbox is selected then select check box in view map
      this.categories.get(evnt).get(evnt1).catkeys[evnt2].selected = 'Y'
      //If checkbox is selected then select check box in shareData
      for (let sharedobj of this.sharedData['profiles'][this.sharedData.stateParamsId].model[evnt1]) {
        if (sharedobj.category == evnt && sharedobj._content_ == evnt4) {
          sharedobj.selected = 'Y'
        }
      }
      // check if All checkbox has to be checked or not
      notAllChecked = false
      for (let i = 0; i < this.categories.get(evnt).get(evnt1).catkeys.length; i++) {
        if (this.categories.get(evnt).get(evnt1).catkeys[i].selected == 'N') {
          notAllChecked = true;
        }
      }
      if (notAllChecked)
        this.categories.get(evnt).get(evnt1).all = 'N'
      else
        this.categories.get(evnt).get(evnt1).all = 'Y'
    } else {
      //If checkbox is de-selected then de-select check box in view map
      this.categories.get(evnt).get(evnt1).catkeys[evnt2].selected = 'N'
      //If checkbox is de-selected then de-select check box in sharedData
      for (let sharedobj of this.sharedData['profiles'][this.sharedData.stateParamsId].model[evnt1]) {
        if (sharedobj.category == evnt && sharedobj._content_ == evnt4) {
          sharedobj.selected = 'N'
        }
      }
      // Uncheck the All checkbox
      if (this.categories.get(evnt).get(evnt1).all == 'Y') {
        this.categories.get(evnt).get(evnt1).all = 'N'
      }
    }
    this.setLimits()
  }
  setLimits() {
    let allCatKeysCount
    let productsCatKeysCount

    allCatKeysCount = 0
    productsCatKeysCount = 0
    for (let catkeytype of this.catkeytypes) {
      //Iterate through each element of catkey type array
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype] && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype] != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype].length > 0) {
        for (let individualCatKeyObj of this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkeytype]) {
          if (!(individualCatKeyObj.category == '') && individualCatKeyObj.selected == 'Y') {
            allCatKeysCount = allCatKeysCount + 1
            if (catkeytype == 'Products') {
              productsCatKeysCount = productsCatKeysCount + 1
            }
          }
        }
      }
    }
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[BpcatkeyComponent] setLimits"
      }
    })
    if (productsCatKeysCount > 30)
      this.productsLimitBreached = true
    else
      this.productsLimitBreached = false

    if (allCatKeysCount > 75)
      this.OverallLimitBreached = true
    else
      this.OverallLimitBreached = false
      this.ngAfterViewChecked();
  }
  ngAfterViewChecked() {
		if (!(<ViewRef>this.cdr).destroyed) {
			this.cdr.detectChanges();
		}
	}
  closeAllPanels() {
    this.sharedService.setAccordianState(false);
  }
  openAllPanels(){
    this.sharedService.setAccordianState(true);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.bpAccordianSub.unsubscribe();
  }
}
