import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})

export class BillingService {
  baseUri: string = "/api/billing";
  baseUriEsb: string = "/api/orderitem";
  baseuriEsblookup: string = "/api/lookup"
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  private submitAction = new BehaviorSubject<boolean>(false);
  formStatus = this.submitAction.asObservable();

  constructor(
    private http: HttpClient,
    private Params: ParamsService,
    private API: ApiService,
    private utils: UtilsService,

  ) { }
  billingAccountId = "";
  contactDropdownValue = "";
  billingId = null;
  accounts = null;
  kgenCustomerId = null;
  scope: this;


  /**
       * _get - driver for changing the vm.accounts model without setting to null
       * @param  {[type]} id [description]
       * @return {[type]}    [description]
       */
  _get(id: string) {
    this.kgenCustomerId = id;
    return this.http.get(this.API.get(this.baseUri) + "/" + id).toPromise()
  };

  /**
* Get - the accounts if they exist otherwise just call the service to get the vm.accounts
* @param  string id kgenCustomerIf
* @return $promise promise to return the resulting data
*/
  get(id: string) {
    if (this.accounts) {
      return this.accounts;
    } else {
      return this._get(id)
    }

  };

/**
       * _get - driver for changing the vm.accounts model without setting to null
       * @param  {[type]} id [description]
       * @return {[type]}    [description]
       */
 getESB(id: string) {
  //this.kgenCustomerId = id;
  var response =
  //{
    //"response":
    {
      "hasWarningOrError":"false",
      "transactionId":"12345",
      "billAccountId":[
        {
      "ID":"690000287",
          "svItemId":"BBCZN32588",
          "status":"Success"
        },
      {
      "ID":"800032306",
          "svItemId":"BBCTQ30375",
          "status":"Success"
        },
      {
      "ID":"690000284",
          "svItemId":"BBCTQ30370",
          "status":"Success"
        }
      ]
    }
  //}
  return this.http.get(this.API.get(this.baseUriEsb) + "/" + id).toPromise()
  //return of((response)).toPromise();
};

  /**
   * [update description]
   * @param  {[type]} id   [description]
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  update(id: string, data: any,page?){
    if(page){
      data.page = page;
    }
    return this.http.put(this.API.get(this.baseUri) + "/" + id, data);//.subscribe(response => {
      // this._get(this.kgenCustomerId);
    // }, err => {
    //   console.log(JSON.stringify(err))
    // })
    //   .success(function(response) {
    //     vm._get(vm.kgenCustomerId).then(resolve).catch(reject);
    // })
    // .error(reject);
  };



  /**
   * [updateDigital description]
   * @param  {[type]} id   [description]
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  updateDigital(svItemId: string, billingAccountId: string, data: any) {
    this.kgenCustomerId = this.kgenCustomerId || data.billing.customerId;
    return this.http.put(this.API.get(this.baseUri) + "/digital/" + svItemId + "/" + billingAccountId, data).subscribe(response => {
      this._get(this.kgenCustomerId)
    }, err => {
      //console.log(JSON.stringify(err))
    })

    // .success(function(response) {
    //     vm._get(vm.kgenCustomerId).then(resolve).catch(reject);
    // })
    // .error(reject);
  };

  /**
   * [create description]
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
   create(data: any,page?){
     if(page){
       data.page = page;
     }
    this.kgenCustomerId = this.kgenCustomerId || data.billing.customerId;
    return this.http.post(this.API.get(this.baseUri), data);//subscribe(function (response) {
    //   this.billingId = data.accountId;
    //   this._get(this.kgenCustomerId)
    // }, err => {
    //   console.log(JSON.stringify(err))
    // });
    // .success(function(data) {
    //     vm.billingId = data.accountId;
    //     console.log("Service id : "+vm.billingId);
    //     vm._get(vm.kgenCustomerId).then(resolve).catch(reject);
    // }
    //});

  };


  /**
   * [getBillingSchema description]
   * @return {[type]} [description]
   */
  getBillingSchema(): Observable<any> {
    if (this.Params.params.sforg === 'national') {
      return this.http.get(this.API.get("/api/schemanational/billing/billing"))
      //return $http.get(API.get("/api/schemanational/billing/billing"), { cache : true });
    } else {
      return this.http.get(this.API.get("/api/schema/billing/billing"))
      // return $http.get(API.get("/api/schema/billing/billing"), { cache : true });
    }

  };

  /**
   * getItemBilling - get the billing accounts specific to an item
   * @param  {[type]} id          [description]
   * @param  {[type]} productCode [description]
   * @return {[type]}             [description]
   */
  getItemBilling(id: string, productCode: string): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + "/" + id + "/" + productCode)
    // return $http.get(API.get(vm.PATH) + "/" + id + "/" + productCode);
  };

  lookupPhoneNumber(phone: string, countryCode: string): Promise<any> {
    const url = this.API.get(this.baseuriEsblookup) + "/" + phone + "/" + countryCode ;
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  
    return this.http.get(url, { headers })
      .toPromise()
      .catch(error => {
        // Handle errors here
        console.error('An error occurred while fetching phone number details:', error);
        throw error; // Re-throw the error to propagate it to the caller
      });
  }


  formState(formStatus: boolean) {
    this.submitAction.next(formStatus);
  }

}
