import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Quickbp } from '../interface/quickbp';

export interface indicatorState{
    indicator: Quickbp;
    isloaded: boolean;
}

export const initialState = () =>{
return {
    indicator : null,
    updatedFrom: null,
    isloaded: false,
};
};

@Injectable({
    providedIn : 'root'
})
@StoreConfig({ name: 'indicator' })
export class IndicatorStore extends Store<indicatorState> {
  constructor() {
    super(initialState());
  }
}
