import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../storage/storage.service';
import { Location } from "@angular/common";
import { UtilsService } from '../utils/utils.service';
import { RequiredfieldcountService } from '../requiredfieldcount/requiredfieldcount.service';
import { ProfileService } from '../profile/profile.service';
import { ParamsService } from '../params/params.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IndicatorService } from '../indicator/indicator.service';
import { LoggerService } from '../logger/logger.service';
import { Quickbpclass } from 'app/interface/quickbp';
import { GoldenlistingmodalComponent } from 'app/modules/business-profiles/goldenlistingmodal/goldenlistingmodal.component';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialog } from '@angular/material';
import { Constants } from '@app/service/datasource/constants';
import { Subject } from 'rxjs';
import { CatKeysService } from '../CatKeys/CatKeys.service';
import { ErrorhandlingService } from '../errorhandling/errorhandling.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  page = "profile";
  sharedData: Quickbpclass;
  sdata: any = { street: 'Y', zip: 'Y', map: 'Y' };
  updateDA: any = [];
  goldenRecordResponse;
  goldenRecordFlag: boolean = false;
  continueSubscription: any;
  submitSubscription: any;
  dialogRef: any;
  bpQueryParams: {
    account:string,
    eaId:string,
  }
  spinnerText:string;
  private bpAccordionToggle = new Subject<any>();

  constructor(private StorageService: StorageService,
    private Utils: UtilsService,
    private requiredfieldcount: RequiredfieldcountService,
    private Profile: ProfileService,
    private Params: ParamsService,
    private _router: Router,
    private dialog: MatDialog,
    private ngxspinner: NgxSpinnerService,
    private indicator: IndicatorService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private Logger: LoggerService,
    private activatedRoute : ActivatedRoute,
    private location: Location,
    private ConstantText: Constants,
    private CatKeys: CatKeysService,
    private errorService: ErrorhandlingService) { }

    setAccordianState(state: any) {
      this.bpAccordionToggle.next(state);
    }
    getAccordianState() {
      return this.bpAccordionToggle.asObservable();
    }

    continueMethod(pageCounter) {

    this.updateDA['ALL'] = { street: 'Y', zip: 'Y', map: 'Y' };
    this.updateDA['CITY'] = { street: 'N', zip: 'Y', map: 'Y' };
    this.updateDA['HIDE'] = { street: 'N', zip: 'N', map: 'N' };
    //this.Params.params.loadingbartext  = "Please wait...";
    this.spinnerText = this.ConstantText.saveText;
    this.Params.params.loadingBP  = "tileContinue";
    this.ngxspinner.show();
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
    });
    this.Params.params.mode = sessionStorage.getItem("mode");
    var businessAddrVal = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddress1"];
    var subStrVal = "\\n";
    if (businessAddrVal) {
      var subStrValInd = businessAddrVal.indexOf(subStrVal);
      if (subStrValInd > -1) {
        this.Logger.logError('Please remove newline characters "\\n" from Adress field');
        return;
      }
    }
    this.ngxspinner.show();
    this.StorageService.get("copyBP", " ")
      .then(data => {
        let isCopyBP = "false";
        if(this.sharedData["isCopyBP"]){
          isCopyBP = this.sharedData["isCopyBP"];
        }
        //let isCopyBP = this.activatedRoute.snapshot.queryParams['isCopyBP'];
        if (isCopyBP == "true" &&
          data.hasOwnProperty("") &&
          data[""].isCopyBP === "Y" &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessName === this.sharedData['profiles'][""].model.BusinessName &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.streetAddress === this.sharedData['profiles'][""].model.BusinessAddress1 &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.city === this.sharedData['profiles'][""].model.BusinessAddressCity &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.state === this.sharedData['profiles'][""].model.BusinessAddressState &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.zip === this.sharedData['profiles'][""].model.BusinessAddressZip.zip &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.zip4 === this.sharedData['profiles'][""].model.BusinessAddressZip.zip4 &&
          this.sharedData['profiles'][""].data["listings"].listing[0].primaryPhone.value === this.sharedData['profiles'][""].model.BusinessPhone) {
          this.Logger.logCopyBPError("Either the Business Name, Address, or Phone Number needs to differ from copied Business Profile");
          this.ngxspinner.hide();
        } else {
    if (!(this.Params.params.mode == 'QuickBP') &&
      !(this.sharedData.tileFlag1) &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model['Non-PublishEmail'] &&
      (this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneAlternate'] == undefined ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneAlternate'] == null ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneAlternate'] == '' ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneAlternate'].length == 0) &&
      (this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneTollFree'] == undefined ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneTollFree'] == null ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneTollFree'] == '' ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['PhoneTollFree'].length == 0) &&
      (this.sharedData['profiles'][this.sharedData.stateParamsId].model['BusinessWebsiteURL'] == undefined ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['BusinessWebsiteURL'] == null ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['BusinessWebsiteURL'] == '' ||
        this.sharedData['profiles'][this.sharedData.stateParamsId].model['BusinessWebsiteURL'].length == 0)) {
      let message = 'If the email is not published, one of the following fields must be completed: <br> * Alternate Telephone <br> * Toll Free Number <br> * Website URL'
      this.Logger.logError(message);
    }
    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessDescription"]) {
      var bizDesc = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessDescription"];
      bizDesc = this.Utils.removeUnicodeChar(bizDesc);
      bizDesc = this.Utils.stripTags(bizDesc, '<br><li>');
      this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessDescription"] = bizDesc;
    }

    if ((this.sharedData.businessProfilePageIndex == 0 && this.sharedData.stateParamsId == "") || (pageCounter == 2 && this.sharedData.stateParamsId == "")) {
      var count = 0;
      var pages = this.sharedData['profiles'][this.sharedData.stateParamsId].form.length;
      for (var i = 0; i < pages; i++) {
        count += this.requiredfieldcount.get(this.sharedData['profiles'][this.sharedData.stateParamsId].schema[i], this.sharedData['profiles'][this.sharedData.stateParamsId].form[i], this.sharedData['profiles'][this.sharedData.stateParamsId].model);
      }
      var categoriesArray = [];
      var category;
      for (var index in this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessCategories"]) {
        category = {};
        category.categoryId = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessCategories"][index].categoryId;
        category.categoryName = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessCategories"][index].categoryName;
        category.primary = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessCategories"][index].primary;
        categoriesArray.push(category);
      }
      this.sdata = this.updateDA[this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions]? this.updateDA[this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions] : this.sdata;
      let dryrunRequest = {
        listings: {
          listing: [{
            enterpriseAccountIds: {
              enterpriseAccountId: this.sharedData['account']["enterpriseAccountId"]
            },
            "businessName": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessName"],
            "businessAddress": {
              "streetAddress": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddress1"],
              "city": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressCity"],
              "state": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressState"],
              "zip": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressZip"].zip,
              //"zip4": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressZip"].zip4,
              //"country": this.sharedData['account']["address"].country,
              "country": this.sharedData['account']["address"] ? this.sharedData['account']["address"].country : null,
              "showStreet": this.sdata.street,
              "showZip": this.sdata.zip,
              "showMap": this.sdata.map
            },
            "source": this.Utils.sourcetype,
            "email": {
              "value": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"],
              "show": "Y"
              //"show": (this.sharedData['profiles'][this.sharedData.stateParamsId].model["Non-PublishEmail"] ? "N" : "Y")
            },
            "primaryPhone": {
              "value": this.formatPhoneFields(this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessPhone"]),
              // "show": (vm.Indicator.profiles[$stateParams.id].model["Non-PublishPhone"] ? "N":"Y"), --> commenting this code as CA was sending value 'N'. It amy go to future release. As of now(12/03/19) CA will always send 'Y'
              "show": "Y"
            },
            "categories": {
              "category": categoriesArray
            }
            // "addressFormat": this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressDisplayOptions"],
            // "nonPubEmail": this.sharedData['profiles'][this.sharedData.stateParamsId].model["Non-PublishEmail"] ? "N" : "Y"
          }]
        }
      };
      // "body": {
      //   "response": {
      //   "hasWarningOrError": "true",
      //   "transactionId": "4854222359",
      //   "esbServiceFault": [
      //   {
      //   "sourceSystem": "MDB",
      //   "exceptionDetail": [
        
      //   { "errorCode": "MDB-ERR-000", "errorDescription": *"Object not found",* "errorSeverity": "Error" }
      //   ]
      //   },
      if (count == 0) {
        let updatedObj:any = {'updatedFlag':false};
        this.Params.params.loadingbartext = "Saving Business Profile. Please wait ...";
        this.spinnerText = this.ConstantText.saveText;
        this.ngxspinner.show();
        this.sharedData.frmEmail = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"];
        this.Profile.dryrun("dryrun", dryrunRequest).then(response => {
          if (response["hasWarningOrError"] === "false") {
            if (response["status"] == "CREATED") {
              this.submitMethod(pageCounter);
            }
            // else if(response["status"] == "UPDATED"){
            //   updatedObj['data'] = dryrunRequest;
            //   updatedObj['updatedFlag'] = true;
            // }
             else {
              let goldenRecord = response["listingId"];
              // Launch Claim Listing
              this.Profile.getByPhone(
                this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessPhone"],
                this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessName"],
                this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressCity"],
                this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressState"],
                this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddress1"],
                this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"])
                .then(data => {
                  if (data["hasWarningOrError"] === "false") {
                    data.goldenRecord = goldenRecord;
                    data.flag = this.Params.params.mode;
                    this.sharedData.goldenListingData = data;
                    this.indicatorStore.update( state =>{
                      return {
                        indicator: this.sharedData,
                        updatedFrom: "[SharedService] continueMethod",
                      }
                    });
                    this.ngxspinner.hide();
                    this.openGoldenListingModal();
                  } else {//why update storage in failure condition? Question
                    this.StorageService.update(this.page, this.sharedData.stateParamsId, this.sharedData['profiles'][this.sharedData.stateParamsId].model);
                    var activityLogHistoryObj = {
                      'displayName': this.sharedData['user']["name"],
                      'email': this.sharedData['user']["email"],
                      'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                      'itemId': this.sharedData.stateParamsId,
                      'fieldName': 'Business Profile',
                      'fieldValue': 'Updated'
                    }
                    this.StorageService.update("profileHistory", this.sharedData.stateParamsId, activityLogHistoryObj);
                    var pageIndex = this.sharedData.businessProfilePageIndex + 1;
                    this.sharedData.businessProfilePageIndex = pageIndex;
                    if (pageIndex == 1) {
                      this.sharedData.tileFlag1 = false;
                      this.sharedData.tileFlag2 = true;
                      this.sharedData.tileFlag3 = false;

                    } else if (pageIndex == 2) {
                      this.sharedData.tileFlag1 = false;
                      this.sharedData.tileFlag2 = false;
                      this.sharedData.tileFlag3 = true;
                    }
                    //this.dataService.setBehaviorView(this.sharedData);
                    let objNotFoundError = false;
                    if(response["status"] == "UPDATED"){
                      updatedObj['data'] = dryrunRequest;
                      updatedObj['updatedFlag'] = true;
                      var errorObject = data.esbServiceFault;
                      for (let i=0; i < errorObject.length; i++){
                        var sourceSystem = errorObject[i].sourceSystem;
                        if (errorObject[i].exceptionDetail[0].errorDescription.toLowerCase() == "object not found"){
                          objNotFoundError = true;  
                          this.updateBp(dryrunRequest,response['listingId']);
                          break;
                        }
                        var showErrorMsg = "<strong>Source System: </strong>" + sourceSystem + "<br>" + "<strong>Error code: </strong>" + errorObject[i].exceptionDetail[0].errorCode + "<br>" + errorObject[i].exceptionDetail[0].errorDescription;
                        this.Logger.logError(showErrorMsg);
                      }
                    }
                    if(!objNotFoundError){
                      this.ngxspinner.hide();
                    }
                  }

                }, err => {
                  var respObj = err.error.response;
                  var errorObject = respObj.esbServiceFault[0];
                  var errorCode = errorObject.exceptionDetail[0].errorCode;
                  var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                  var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                  this.Logger.logError(errorMsg);
                  this.ngxspinner.hide();
                });
            }
          } else {
            var error = "";
            if (response && response["response"] && response["response"].esbServiceFault) {
              for (var i = 0; i < response["response"].esbServiceFault.length; i++) {
              var errorObject = response["response"].esbServiceFault[i];
              var errorCode = errorObject.exceptionDetail[i].errorCode;
              var errorDesc = errorObject.exceptionDetail[i].errorDescription;
              // if (updatedObj && updatedObj.updatedFlag && errorDesc.toLowerCase() == "object not found"){
              //   //make create BP call
              //   this.createNewBp(updatedObj.data);

              // }
              error += "<strong>Transaction : " + response["response"].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            }
          }
            this.Logger.logError(error);
            this.ngxspinner.hide();
          }
        }, err => {
          var respObj = err.error.response;
          var errorObject = respObj.esbServiceFault[0];
          var errorCode = errorObject.exceptionDetail[0].errorCode;
          var errorDesc = errorObject.exceptionDetail[0].errorDescription;
          var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
          this.Logger.logError(errorMsg);
          this.ngxspinner.hide();
        });
      } else {
        this.Logger.logError("Please fill out the required fields");
        this.ngxspinner.hide();
      }
    } else if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessPhone"] &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessName"] &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressCity"] &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressState"] &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressZip"]) {

      this.Params.params.loadingbartext  = "Please wait...";
      this.spinnerText = this.ConstantText.saveText;
      this.Params.params.loadingBP  = "tileContinue";
      this.ngxspinner.show();

      var count = 0;
      count += this.requiredfieldcount.get(this.sharedData['profiles'][this.sharedData.stateParamsId].schema[this.sharedData.businessProfilePageIndex], this.sharedData['profiles'][this.sharedData.stateParamsId].form[this.sharedData.businessProfilePageIndex], this.sharedData['profiles'][this.sharedData.stateParamsId].model);
      if (count == 0) {
        this.StorageService.update(this.page, this.sharedData.stateParamsId, this.sharedData['profiles'][this.sharedData.stateParamsId].model)
        var activityLogHistoryObj = {
          'displayName': this.sharedData['user']["name"],
          'email': this.sharedData['user']["email"],
          'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
          'itemId': this.sharedData.stateParamsId,
          'fieldName': 'Business Profile',
          'fieldValue': 'Updated'
        }
        this.StorageService.update("profileHistory", this.sharedData.stateParamsId, activityLogHistoryObj);
        var pageIndex;
        if (pageCounter != undefined && pageCounter != null && pageCounter !== "") {
          pageIndex = pageCounter;
        } else {
          pageIndex = this.sharedData.businessProfilePageIndex + 1;
        }
        this.sharedData.businessProfilePageIndex = pageIndex;
        if (pageIndex == 1) {
          this.sharedData.tileFlag1 = false;
          this.sharedData.tileFlag2 = true;
          this.sharedData.tileFlag3 = false;

        } else if (pageIndex == 2) {
          this.sharedData.tileFlag1 = false;
          this.sharedData.tileFlag2 = false;
          this.sharedData.tileFlag3 = true;
        } else if (pageIndex == 0) {
          this.sharedData.tileFlag1 = true;
          this.sharedData.tileFlag2 = false;
          this.sharedData.tileFlag3 = false;
        }
       this.indicatorStore.update( state =>{
          return {
            indicator: this.sharedData,
            updatedFrom: "[SharedService] storageUpdate",
          }
        })
        this.ngxspinner.hide();
      } else {
        this.Logger.logError("Please fill out the required fields");
        this.ngxspinner.hide();
      }
    } else {
      this.Logger.logError("Please fill out the required fields");
      this.ngxspinner.hide();
    }
  }
}, err => {
  this.Logger.logError("CopyBP storage call failed");
  this.ngxspinner.hide();
});
}
  formatPhoneFields(phoneNumber) {

    if (phoneNumber != undefined && phoneNumber != null && phoneNumber != "") {
      phoneNumber = phoneNumber.split('(').join('');
      phoneNumber = phoneNumber.split(')').join('');
      phoneNumber = phoneNumber.split('-').join('');
      phoneNumber = phoneNumber.split(' ').join('');
      return phoneNumber;
    } else {
      return "";
    }
  }

  submitMethod(flag,optionalIndicator?) {
    let optionalStateParamsId = optionalIndicator ? optionalIndicator.items[optionalIndicator.stateParamsId].model.BusinessProfile : "";
    let optionalWebsiteURL = optionalIndicator ? optionalIndicator.items[optionalIndicator.stateParamsId].model.WebsiteURL : "";
    //console.log('optional indicator value is: ',optionalIndicator);
    let foundError = false;
    this.updateDA['ALL'] = { street: 'Y', zip: 'Y', map: 'Y' };
    this.updateDA['CITY'] = { street: 'N', zip: 'Y', map: 'Y' };
    this.updateDA['HIDE'] = { street: 'N', zip: 'N', map: 'N' };
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
      //this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.BusinessWebsiteURL = optionalWebsiteURL;
    });

    // if (optionalIndicator){
    //   this.sharedData = optionalIndicator;
    //   this.sharedData.stateParamsId = optionalIndicator.items[optionalIndicator.stateParamsId].model.BusinessProfile || optionalIndicator.items[optionalIndicator.stateParamsId].order.listingId;
    // }
    this.Params.params.mode = sessionStorage.getItem("mode");
    this.getSourceType();
    this.ngxspinner.show();
    this.StorageService.get("copyBP", "")
      .then(data => {
        if (optionalIndicator){
          this.sharedData = optionalIndicator;
          //this.sharedData.stateParamsId = optionalStateParamsId || this.sharedData.stateParamsId;
        }

        if (data.hasOwnProperty("") && data[""].isCopyBP === "Y" && this.sharedData['profiles'][""].data["listings"].listing[0].businessName === this.sharedData['profiles'][""].model.BusinessName &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.streetAddress === this.sharedData['profiles'][""].model.BusinessAddress1 &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.city === this.sharedData['profiles'][""].model.BusinessAddressCity &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.state === this.sharedData['profiles'][""].model.BusinessAddressState &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.zip === this.sharedData['profiles'][""].model.BusinessAddressZip.zip &&
          this.sharedData['profiles'][""].data["listings"].listing[0].businessAddress.zip4 === this.sharedData['profiles'][""].model.BusinessAddressZip.zip4 &&
          this.sharedData['profiles'][""].data["listings"].listing[0].primaryPhone === this.sharedData['profiles'][""].model.BusinessPhone) {
          this.Logger.logError("Either the Business Name, Address, or Phone Number needs to differ from copied Business Profile");
          this.ngxspinner.hide();
        } else {
          if (optionalStateParamsId || this.sharedData.stateParamsId) {
            this.sdata = JSON.parse(JSON.stringify(this.sdata));
            this.sharedData = JSON.parse(JSON.stringify(this.sharedData));
            this.sdata = this.updateDA[this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions];
            this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.listings.listing[0].businessAddress.showStreet = this.sdata.street;
            this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.listings.listing[0].businessAddress.showZip = this.sdata.zip;
            this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.listings.listing[0].businessAddress.showMap = this.sdata.map;
          }
          if (!(this.Params.params.mode == 'QuickBP') &&
            !(this.sharedData.tileFlag1) &&
            this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['Non-PublishEmail'] &&
            (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneAlternate'] == undefined ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneAlternate'] == null ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneAlternate'] == '' ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneAlternate'].length == 0) &&
            (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneTollFree'] == undefined ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneTollFree'] == null ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneTollFree'] == '' ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['PhoneTollFree'].length == 0) &&
            (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['BusinessWebsiteURL'] == undefined ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['BusinessWebsiteURL'] == null ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['BusinessWebsiteURL'] == '' ||
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model['BusinessWebsiteURL'].length == 0)) {
            foundError = true;
            this.Logger.logError('If the email is not published, one of the following fields must be completed:<br> * Alternate Telephone<br> * Toll Free Number<br> * Website URL')
          }
          var count = 0;

          if (this.Params.params.mode == 'QuickBP') {
            count += this.requiredfieldcount.get(this.sharedData['profiles'][this.sharedData.stateParamsId].schema[0], this.sharedData.quickBpForm, this.sharedData['profiles'][this.sharedData.stateParamsId].model);

          } else {
            var pages = this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].form.length;
            for (var i = 0; i < pages; i++) {
              count += this.requiredfieldcount.get(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].schema[i], this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].form[i], this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model);
            }
          }
          if (count == 0) {
            delete this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.hasWarningOrError;
            delete this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.transactionId;
            delete this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.esbServiceFault;
            if (this.sharedData.stateParamsId) {
              this.sdata = this.updateDA[this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions];
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.listings.listing[0].businessAddress.showStreet = this.sdata.street;
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.listings.listing[0].businessAddress.showZip = this.sdata.zip;
              this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].data.listings.listing[0].businessAddress.showMap = this.sdata.map;
            }

            this.sharedData.frmEmail = this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BPBDEmail"];
            let quickBPData = {
              listings: {
                listing: [{
                  enterpriseAccountIds: {
                    enterpriseAccountId: [(this.Params.params.mode != 'listing') ? this.sharedData['account']["enterpriseAccountId"] : null]
                  },
                  "businessAddress": {
                    "zip": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressZip"].zip,
                    "country": "US",
                    "showStreet": this.sdata.street,
                    "showZip": this.sdata.zip,
                    "showMap": this.sdata.map,
                    "address2": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddress2"],
                    "streetAddress": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddress1"],
                    "city": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressCity"],
                    "state": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressState"],
                  },
                  "source": this.Utils.sourcetype,
                  "primaryPhone": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessPhone"]),
                    "show": "Y"
                  },
                  "fax": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Fax"]),
                    "show": "Y"
                  },
                  "tollFreePhone": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["PhoneTollFree"]),
                    "show": "Y"
                  },
                  "alternatePhone": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["PhoneAlternate"]),
                    "show": "Y"
                  },
                  "websiteUrl": {
                    "value": optionalIndicator ? optionalWebsiteURL : this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessWebsiteURL"],
                    "show": "Y"
                  },
                  "email": {
                    "value": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BPBDEmail"],
                    "show": (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Non-PublishEmail"] ? "N" : "Y")
                  },
                  "categories": {
                    "category": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessCategories"]
                  },
                  "businessName": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessName"],
                  "addressFormat": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressDisplayOptions"],
                  "nonPubEmail": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Non-PublishEmail"]
                }]
              },
              businessProfiles: {
                businessProfile: {
                  busProfile: {
                    "updatable": "N",
                    "_content_": "Y"
                  },
                  sourceId: {
                    "updatable": "N",
                    "_content_": "DEXCCI"
                  },
                  templateData: {
                    "label": "Template",
                    "updatable": "Y",
                    "templateId": [{
                      "selected": "Y",
                      "_content_": "Default"
                    }]
                  },
                  genericData: {

                    "url": {
                      "label": "Website URL",
                      "updatable": "Y",
                      "_content_": optionalIndicator ? optionalWebsiteURL : this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessWebsiteURL"]
                    },

                    "displayUrl": {
                      "label": "Display URL",
                      "updatable": "Y"
                    },
                  }
                }
              }
            };
            let data: any = {
              listings: {
                listing: [{
                  enterpriseAccountIds: {
                    enterpriseAccountId: [(this.Params.params.mode != 'listing') ? this.sharedData['account']["enterpriseAccountId"] : null]
                  },
                  "businessAddress": {
                    "zip": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressZip"].zip,
                    "country": (this.Params.params.mode != 'listing') ? "US" : "US",
                    "showStreet": this.sdata.street,
                    "showZip": this.sdata.zip,
                    "showMap": this.sdata.map,
                    "address2": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddress2"],
                    "streetAddress": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddress1"],
                    "city": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressCity"],
                    "state": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressState"],
                  },
                  "source": this.Utils.sourcetype,
                  "primaryPhone": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessPhone"]),
                    "show": "Y"
                  },
                  "fax": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Fax"]),
                    "show": "Y"
                  },
                  "tollFreePhone": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["PhoneTollFree"]),
                    "show": "Y"
                  },
                  "alternatePhone": {
                    "value": this.formatPhoneFields(this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["PhoneAlternate"]),
                    "show": "Y"
                  },
                  "websiteUrl": {
                    "value": optionalIndicator ? optionalWebsiteURL : this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessWebsiteURL"],
                    "show": "Y"
                  },
                  "email": {
                    "value": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BPBDEmail"],
                    "show": (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Non-PublishEmail"] ? "N" : "Y")
                  },
                  "categories": {
                    "category": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessCategories"]
                  },
                  "businessName": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessName"],
                  "addressFormat": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessAddressDisplayOptions"],
                  "nonPubEmail": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Non-PublishEmail"]
                }]
              },
              businessProfiles: {
                businessProfile: {
                  busProfile: {
                    "updatable": "N",
                    "_content_": "Y"
                  },
                  sourceId: {
                    "updatable": "N",
                    "_content_": "DEXCCI"
                  },
                  templateData: {
                    "label": "Template",
                    "updatable": "Y",
                    "templateId": [{
                      "selected": "Y",
                      "_content_": "Default"
                    }]
                  },
                  genericData: {

                    "url": {
                      "label": "Website URL",
                      "updatable": "Y",
                      "_content_": optionalIndicator ? optionalWebsiteURL : this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessWebsiteURL"]
                    },

                    "displayUrl": {
                      "label": "Display URL",
                      "updatable": "Y"//optionalIndicator ? optionalWebsiteURL : this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessWebsiteURL"]
                    },
                    "busDescription": {
                      "_content_": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessDescription"],
                    },
                    "whatsNew": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["WhatsNew"],
                    "socialUrl_Facebook": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["FacebookPageURL"],
                    "socialUrl_Twitter": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["TwitterURL"],
                    "socialUrl_Linkedin": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["LinkedInURL"],
                    "socialUrl_GooglePlus": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["GooglePlusURL"],
                    "socialUrl_Pintrest": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["PinterestURL"],
                    "socialUrl_Instagram": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["InstagramURL"],
                    "promMsg": {
                      "_content_": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["BusinessTagline"],
                    },
                    "videoUrl": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["YoutubeURL"],
                    "hoursOfOperation": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["HoursOfOperation"],
                    "busSince": {
                      "_content_": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["InBusinessSince"]
                    },
                    "extraInf": {
                      "_content_": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["AdditionalInfo"]
                    },
                    "catkeyProducts": {
                      "catkey": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Products"]
                    },
                    "catkeyServices": {
                      "catkey": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Services"]
                    },
                    "catkeyBrands": {
                      "catkey": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Brands"]
                    },
                    "catkeyAffiliations": {
                      "catkey": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Affiliations"]
                    },
                    "catkeyCertifications": {
                      "catkey": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Certifications"]
                    },
                    "catkeySpecialities": {
                      "catkey": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Specialities"]
                    },
                    "paymentOptions": {
                      "payment": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["PaymentOptions"]
                    },
                    "languageSpoken": {
                      "language": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Languages"]
                    },
                    "customersServed": {
                      "cust": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["CustomersServed"]
                    },
                    "credentials": {
                      "cred": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Credentials"]
                    },
                    "emerServ": {
                      "emerId": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["EmergencyService"]
                    },
                    "ownership": {
                      "own": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["Ownership"]
                    },
                    "areasServed": {
                      "areaServ": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["MarketsServed"]
                    }
                  }
                }
              },
              assets: { "asset": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["assets"] }
              ,
              coupons: {
                "coupon": this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["coupons"],
                "modifiedBy": "UCC"
              }
            };
            if (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.AdditionalInfo === undefined || this.sharedData['profiles'][optionalStateParamsId ||this.sharedData.stateParamsId].model.AdditionalInfo === null || this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.AdditionalInfo === "") {
              data.businessProfiles.businessProfile.genericData["extraInf"] = {
                "_content_": ""
              };
            }

            if (this.Params.params.mode === 'listing') {
              data.listings.listing[0].enterpriseAccountIds.enterpriseAccountId[0] = null;
            }

            // Catkeys and Custom catkeys
            let catKeyTypes = ['Affiliations', 'Brands', 'Certifications', 'Products', 'Services', 'Specialities']
            // Remove existing data from generic data
            for (var CatKeyType of catKeyTypes) {
              delete data.businessProfiles.businessProfile.genericData['catkey' + CatKeyType]
            }
            // Add data from model to generic data
            let catKeysCount = 0;
            let catKeysProducts = 0;
            for (var CatKeyType of catKeyTypes) {
              //Read catkeys
              var catKey = this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model[CatKeyType];
              var catKeyObj = [];
              if (catKey != undefined && catKey != null) {
                for (let i = 0; i < catKey.length; i++) {
                  if (catKey[i].selected == 'Y') {
                    catKeyObj.push(catKey[i]);
                    catKeysCount = catKeysCount + 1
                    if (CatKeyType == 'Products')
                      catKeysProducts = catKeysProducts + 1
                  }
                }
              }
              //Read custom catkeys
              if (this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.hasOwnProperty('CustomCatKeys') && this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.CustomCatKeys && this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.CustomCatKeys != undefined && this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.CustomCatKeys != null) {
                var custCatKeyObj = this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model.CustomCatKeys[CatKeyType];
                var custCatKey = '';
                if (custCatKeyObj != undefined && custCatKeyObj != null) {
                  for (let j = 0; j < custCatKeyObj.length; j++) {
                    if (custCatKeyObj[j].isSelected == true) {
                      let customCatKeyText = custCatKeyObj[j].text;
                      custCatKey += customCatKeyText + ';';
                    }
                  }
                }
              }

              data.businessProfiles.businessProfile.genericData['catkey' + CatKeyType] = {};
              data.businessProfiles.businessProfile.genericData['catkey' + CatKeyType]['catkey'] = catKeyObj;
              data.businessProfiles.businessProfile.genericData['catkey' + CatKeyType]['catkeyExtra'] = {}
              data.businessProfiles.businessProfile.genericData['catkey' + CatKeyType]['catkeyExtra']['label'] = 'Extra'
              data.businessProfiles.businessProfile.genericData['catkey' + CatKeyType].catkeyExtra["_content_"] = custCatKey
            }
            if (catKeysCount > 75) {
              this.Logger.logError("Cannot select more than 75 total Products, Services, Brands and Affiliation catkeys with not more than 30 within the Products subcategories.");
              foundError = true;
            }
            if (catKeysProducts > 30) {
              this.Logger.logError("Cannot select more than 30 catkeys of 'Products' type");
              foundError = true;
            }


            if (data.hasOwnProperty("coupons") &&
              data["coupons"].hasOwnProperty("coupon") &&
              data["coupons"].coupon &&
              data["coupons"].coupon != undefined &&
              data["coupons"].coupon != null &&
              data["coupons"].coupon.length > 0) {
              data["coupons"].modifiedBy = "UCC";
            } else {
              delete data["coupons"];
            }

            if (data.hasOwnProperty("assets") &&
              data["assets"].hasOwnProperty("asset") &&
              data["assets"].asset &&
              data["assets"].asset != undefined &&
              data["assets"].asset != null &&
              data["assets"].asset.length > 0 &&
              data["assets"].asset[0]["uriType"]) {
            } else {
              delete data["assets"];
              delete this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["assets"]
            }

            if (data.businessProfiles.businessProfile.genericData.hasOwnProperty("paymentOptions") &&
              data["businessProfiles"]["businessProfile"]["genericData"].paymentOptions &&
              data["businessProfiles"]["businessProfile"]["genericData"]["paymentOptions"].payment != undefined &&
              data["businessProfiles"]["businessProfile"]["genericData"]["paymentOptions"].payment != null &&
              data["businessProfiles"]["businessProfile"]["genericData"]["paymentOptions"].payment.length > 0) {
            } else {
              delete data["businessProfiles"]["businessProfile"]["genericData"]["paymentOptions"]
            }
            if (data.businessProfiles.businessProfile.genericData.hasOwnProperty("languageSpoken") &&
              data["businessProfiles"]["businessProfile"]["genericData"].languageSpoken &&
              data["businessProfiles"]["businessProfile"]["genericData"]["languageSpoken"].language != undefined &&
              data["businessProfiles"]["businessProfile"]["genericData"]["languageSpoken"].language != null &&
              data["businessProfiles"]["businessProfile"]["genericData"]["languageSpoken"].language.length > 0) {
            } else {
              delete data["businessProfiles"]["businessProfile"]["genericData"]["languageSpoken"]
            }
            if (data.businessProfiles.businessProfile.genericData.hasOwnProperty("customersServed") &&
              data["businessProfiles"]["businessProfile"]["genericData"].customersServed &&
              data["businessProfiles"]["businessProfile"]["genericData"]["customersServed"].cust != undefined &&
              data["businessProfiles"]["businessProfile"]["genericData"]["customersServed"].cust != null &&
              data["businessProfiles"]["businessProfile"]["genericData"]["customersServed"].cust.length > 0) {
            } else {
              delete data["businessProfiles"]["businessProfile"]["genericData"]["customersServed"]
            }
            if (data.businessProfiles.businessProfile.genericData.hasOwnProperty("credentials") &&
              data["businessProfiles"]["businessProfile"]["genericData"].credentials &&
              data["businessProfiles"]["businessProfile"]["genericData"]["credentials"].cred != undefined &&
              data["businessProfiles"]["businessProfile"]["genericData"]["credentials"].cred != null &&
              data["businessProfiles"]["businessProfile"]["genericData"]["credentials"].cred.length > 0) {
            } else {
              delete data["businessProfiles"]["businessProfile"]["genericData"]["credentials"]
            }
            if (data.businessProfiles.businessProfile.genericData.hasOwnProperty("ownership") &&
              data["businessProfiles"]["businessProfile"]["genericData"].ownership &&
              data["businessProfiles"]["businessProfile"]["genericData"]["ownership"].own != undefined &&
              data["businessProfiles"]["businessProfile"]["genericData"]["ownership"].own != null &&
              data["businessProfiles"]["businessProfile"]["genericData"]["ownership"].own.length > 0) {
            } else {
              delete data["businessProfiles"]["businessProfile"]["genericData"]["ownership"]
            }
            if (data.businessProfiles.businessProfile.genericData.hasOwnProperty("areasServed") &&
              data["businessProfiles"]["businessProfile"]["genericData"].areasServed &&
              data["businessProfiles"]["businessProfile"]["genericData"]["areasServed"].areaServ != undefined &&
              data["businessProfiles"]["businessProfile"]["genericData"]["areasServed"].areaServ != null &&
              data["businessProfiles"]["businessProfile"]["genericData"]["areasServed"].areaServ.length > 0) {
            } else {
              delete data["businessProfiles"]["businessProfile"]["genericData"]["areasServed"]
            }

            if (this.Params.params.mode != 'listing') {
              var claim = {
                "enterpriseAccountId": this.sharedData['account']["enterpriseAccountId"],
                "listingId": "",
                "emailAddress": data.listings.listing[0].email.value
              };
            }

            if (data.hasOwnProperty("confirmHoursOfOperation")) {
              delete data["confirmHoursOfOperation"];
            }
            if (!foundError) {
              if (this.Params.params.mode === "QuickBP") {
                var dryrunRequest = {
                  "listings": quickBPData.listings
                };
                this.Profile.dryrun("dryrun", dryrunRequest).then(response => {
                  if (response.hasWarningOrError === "false") {
                    if (response.status == "UPDATED") {
                      var goldenRecord = response.listingId;
                      this.goldenRecordFlag = true;
                      // Launch Claim Listing
                      this.Profile.getByPhone(
                        this.sharedData['profiles'][""].model["BusinessPhone"],
                        this.sharedData['profiles'][""].model["BusinessName"],
                        this.sharedData['profiles'][""].model["BusinessAddressCity"],
                        this.sharedData['profiles'][""].model["BusinessAddressState"],
                        this.sharedData['profiles'][""].model["BusinessAddress1"],
                        this.sharedData['profiles'][""].model["BPBDEmail"])
                        .then(data => {
                          if (data.hasWarningOrError === "false") {
                            data.goldenRecord = goldenRecord;
                            this.goldenRecordFlag = true;
                            data.flag = this.Params.params.mode;
                            this.goldenRecordResponse = data;
                            this.sharedData.goldenListingData = data;
                            this.indicatorStore.update( state =>{
                              return {
                                indicator: this.sharedData,
                                updatedFrom: "[SharedService] getByPhone",
                              }
                            })
                            this.ngxspinner.hide();
                            this.openGoldenListingModal();
                          }else {
                            var errorObject = data.esbServiceFault;
                            let objNotFoundError = false;
                            // errorObject.forEach((errorMsg, index) => {
                            //   var sourceSystem = errorMsg.sourceSystem;
                            //   if(errorMsg.exceptionDetail[0].errorDescription.toLowerCase() == "object not found"){
                            //       this.createNewBp(dryrunRequest);
                            //   }
                            //   var showErrorMsg = "<strong>Source System: </strong>" + sourceSystem + "<br>" + "<strong>Error code: </strong>" + errorMsg.exceptionDetail[0].errorCode + "<br>" + errorMsg.exceptionDetail[0].errorDescription;
                            //   this.Logger.logError(showErrorMsg);
                            // })
                            for (let i=0; i < errorObject.length; i++){
                              var sourceSystem = errorObject[i].sourceSystem;
                              if (errorObject[i].exceptionDetail[0].errorDescription.toLowerCase() == "object not found"){
                                objNotFoundError = true; 
                                this.updateBp(dryrunRequest,response['listingId']);
                                break;
                              }
                              var showErrorMsg = "<strong>Source System: </strong>" + sourceSystem + "<br>" + "<strong>Error code: </strong>" + errorObject[i].exceptionDetail[0].errorCode + "<br>" + errorObject[i].exceptionDetail[0].errorDescription;
                              this.Logger.logError(showErrorMsg);
                            }
                            if(!objNotFoundError){
                              this.ngxspinner.hide();
                            }
                          }
                        }, err => {
                          var respObj = err.error.response;
                          var errorObject = respObj.esbServiceFault[0];
                          var errorCode = errorObject.exceptionDetail[0].errorCode;
                          var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                          var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                          this.Logger.logError(errorMsg);
                          this.ngxspinner.hide();
                        });
                    } else {
                      this.Profile.create("digital", quickBPData)
                        .then(result => {
                          var claim = {
                            "enterpriseAccountId": this.sharedData['account']["enterpriseAccountId"],
                            "listingId": result.listingId,
                            "emailAddress": quickBPData.listings.listing[0].email.value
                          };
                          this.Profile.claim(claim)
                            .then(response => {
                              if (response.hasWarningOrError !== "true") {
                                //Clear the blank BP when a new copied BP is created successfully or else clicking on New BP will hold up the older values
                                this.sharedData['profiles'][""].model = {
                                  BusinessAddressDisplayOptions: "ALL"
                                }
                                this.indicator.addProfileById(this.sharedData,result.listingId)
                                  .then(profileResult => {
                                    var activityLogHistoryObj = {
                                      'displayName': this.sharedData['user']["name"],
                                      'email': this.sharedData['user']["email"],
                                      'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                                      'itemId': result.listingId,
                                      'fieldName': 'Business Profile',
                                      'fieldValue': 'Created in ' + this.Params.params.mode
                                    }
                                    this.StorageService.update("profileHistory", result.listingId, activityLogHistoryObj);
                                    this.Params.params.mode = "ManageBP";
                                    this.Params.params.order = null;
                                    let bpEaId = this.activatedRoute.snapshot.queryParams['eaId'];
                                    let bpAccount = this.activatedRoute.snapshot.queryParams['account'];
                                    setTimeout(() => {
                                      sessionStorage.setItem("mode","ManageBP");
                                      console.log("update mode in session storage 960")
                                    }, 2000);
                                    this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: bpEaId, account: bpAccount}, queryParamsHandling: 'merge'});
                                    setTimeout(() => {
                                      sessionStorage.setItem("caangulareffererPath",this.location.path());
                                      sessionStorage.setItem("okta-app-url",this.location.path());
                                     }, 2000);
                                    this.Logger.logSuccess("Successfully created business profile");
                                    this.ngxspinner.hide();
                                  }, err => {
                                    var respObj = err.error.response;
                                    var errorObject = respObj.esbServiceFault[0];
                                    var errorCode = errorObject.exceptionDetail[0].errorCode;
                                    var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                                    var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                    this.Logger.logError(errorMsg);
                                    this.ngxspinner.hide();
                                  })
                              } else {
                                var error = "";
                                for (var i = 0; i < response.response.esbServiceFault.length; i++) {
                                  var errorObject = response.response.esbServiceFault[i];
                                  var errorCode = errorObject.exceptionDetail[i].errorCode;
                                  var errorDesc = errorObject.exceptionDetail[i].errorDescription;
                                  error += "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                }
                                this.Logger.logError(error);
                                this.ngxspinner.hide();
                              }
                            }, err => {
                              var respObj = err.error.response;
                              var errorObject = respObj.esbServiceFault[0];
                              var errorCode = errorObject.exceptionDetail[0].errorCode;
                              var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                              var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                              this.Logger.logError(errorMsg);
                              this.ngxspinner.hide();
                            })
                        }, err => {
                          var respObj = err.error.response;
                          var errorObject = respObj.esbServiceFault[0];
                          var errorCode = errorObject.exceptionDetail[0].errorCode;
                          var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                          var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                          this.Logger.logError(errorMsg);
                          this.ngxspinner.hide();
                        })
                    }

                  } else {
                    var error = "";
                    if (response.response && response.response.esbServiceFault) {
                      for (var i = 0; i < response.response.esbServiceFault.length; i++) {
                      var errorObject = response.response.esbServiceFault[i];
                      var errorCode = errorObject.exceptionDetail[i].errorCode;
                      var errorDesc = errorObject.exceptionDetail[i].errorDescription;
                      error += "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    }    
                  } else {
                      console.error('response.response or response.response.esbServiceFault is undefined.');
                      // Handle the situation where the properties are not accessible
                  }
                    this.Logger.logError(error);
                    this.ngxspinner.hide();
                  }
                }, err => {
                  var respObj = err.error.response;
                  var errorObject = respObj.esbServiceFault[0];
                  var errorCode = errorObject.exceptionDetail[0].errorCode;
                  var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                  var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                  this.Logger.logError(errorMsg);
                  this.ngxspinner.hide();
                })
              } else {
                if (this.sharedData.stateParamsId !== "") {//update stateparms id here
                  this.Profile.update(optionalStateParamsId || this.sharedData.stateParamsId, data)
                    .then(result => {
                      if (optionalStateParamsId){
                        this.sharedData['profiles'][optionalStateParamsId].model.BusinessWebsiteURL = optionalWebsiteURL;
                      }
                      this.Logger.logSuccess("Successfully updated business profile")
                      this.sharedData['tempModel'] = this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model;
                      this.sharedData['bpDirtyFlag'] = true;
                      this.sharedData.businessProfilePageIndex = 0;
                      this.sharedData.tileFlag1 = true;
                      this.sharedData.tileFlag2 = false;
                      this.sharedData.tileFlag3 = false;
                      // this.indicatorStore.update(state =>{
                      //   return{
                      //     indicator: this.sharedData
                      //   }
                      // })
                      this.Profile.getByDigitalListing(optionalStateParamsId || this.sharedData.stateParamsId).then(data => {
                        if (data[optionalStateParamsId || this.sharedData.stateParamsId].coupons) {
                          this.sharedData['profiles'][optionalStateParamsId || this.sharedData.stateParamsId].model["coupons"] =
                            data[optionalStateParamsId || this.sharedData.stateParamsId].coupons.coupon
                        }
                        this.indicatorStore.update(state =>{
                          return{
                            indicator: this.sharedData,
                            updatedFrom: "[SharedService] profileUpdate",
                          }
                        })
                        this.ngxspinner.hide();
                      }, err => {
                        var respObj = err.error.response;
                        var errorObject = respObj.esbServiceFault[0];
                        var errorCode = errorObject.exceptionDetail[0].errorCode;
                        var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                        var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        this.Logger.logError(errorMsg);
                        this.ngxspinner.hide();
                      })
                    }, err => {
                      if(err && err.error && err.error.response && err.error.response.esbServiceFault){
                      var respObj = err.error.response;
                      var errorObject = respObj.esbServiceFault[0];
                      var errorCode = errorObject.exceptionDetail[0].errorCode;
                      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                      var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                      this.Logger.logError(errorMsg);
                      }
                      this.ngxspinner.hide();
                    }
                    )//update stateparms id here

                } else {
                  this.Profile.create("digital", data)
                    .then(result => {
                      var claim = {
                        "enterpriseAccountId": this.sharedData['account']["enterpriseAccountId"],
                        "listingId": result.listingId,
                        "emailAddress": data.listings.listing[0].email.value
                      };
                      this.Profile.claim(claim)
                        .then(response => {
                          if (response.hasWarningOrError !== "true") {
                            this.Logger.logSuccess("Successfully created business profile");
                            this.sharedData['tempModel'] = this.sharedData['profiles'][this.sharedData.stateParamsId].model;
                            this.sharedData['bpDirtyFlag'] = true;
                            var activityLogHistoryObj = {
                              'displayName': this.sharedData['user']["name"],
                              'email': this.sharedData['user']["email"],
                              'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                              'itemId': result.listingId,
                              'fieldName': 'Business Profile',
                              'fieldValue': 'Created in ' + this.Params.params.mode,
                              'transactionId': response.transactionId
                            }
                            this.StorageService.update("profileHistory", result.listingId, activityLogHistoryObj);
                            //Clear the blank BP when a new copied BP is created successfully or else clicking on New BP will hold up the older values
                            this.sharedData['profiles'][""].model = {
                              BusinessAddressDisplayOptions: "ALL"
                            }
                            if(this.Params.params.mode != "ManageBP" && this.Params.params.mode != "QuickBP" && this.Params.params.mode != "listing" ){
                            this.Profile.getByDigitalListing(result.listingId).then((res)=>{
                              if(res[result.listingId].hasWarningOrError == "false"){
                                this.sharedData["order"].OrderItems.forEach((item, index) => {
                                  if(item.productType == "Digital" && item.actionCode != "Cancel" && item.actionType != "Cancel" ){
                                    let name = res[result.listingId].listings.listing[0].businessName;
                                    let  phoneNumLocal = "";
                                    if(res[result.listingId].listings.listing[0].primaryPhone.value){
                                       phoneNumLocal = this.Utils.transformTelephone(res[result.listingId].listings.listing[0].primaryPhone.value);
                                    }
                                    name += "," + phoneNumLocal + " " +  res[result.listingId].listings.listing[0].businessAddress.streetAddress + " " + res[result.listingId].listings.listing[0].businessAddress.city ;
                                    if (res[result.listingId].listings.listing[0].businessAddress.zip !== undefined ) {
                                      name += '-' + res[result.listingId].listings.listing[0].businessAddress.zip;
                                    }
                                    if (res[result.listingId].listings.listing[0].businessAddress.zip4 !== undefined ) {
                                      name += '-' + res[result.listingId].listings.listing[0].businessAddress.zip4;
                                    }
                                    if(!this.sharedData["items"][item.svItemId]['listingArray']){
                                      this.sharedData["items"][item.svItemId]['listingArray'] = [];
                                    }
                                    let listing = {
                                      name: name,
                                      value: res[result.listingId].listings.listing[0].listingId,
                                      index: this.sharedData["items"][item.svItemId]['listingArray'].length
                                    };
                                    if(!this.sharedData["items"][item.svItemId]['listings']){
                                      this.sharedData["items"][item.svItemId]['listings'] = [];
                                    }
                                    this.sharedData["items"][item.svItemId]['listings'].push(res[result.listingId].listings.listing[0]);
                                    this.sharedData["items"][item.svItemId]['listingArray'].push(listing);
                                  }})
                              }
                              // this.indicatorStore.update(state =>{
                              //   return {
                              //     indicator: this.sharedData
                              //   }
                              // })
                            })
                            }
                            this.indicator.addProfileById(this.sharedData, result.listingId)
                              .then(profileResult => {
                                this.sharedData['profiles'] = profileResult;
                                this.sharedData.stateParamsId = result.listingId;
                                this.sharedData['tempModel'] = this.sharedData['profiles'][this.sharedData.stateParamsId].model;
                                this.sharedData['bpDirtyFlag'] = true;
                                if (flag != undefined && flag != null && flag != '') {
                                  this.sharedData.businessProfilePageIndex = flag;
                                  if (flag == 0) {
                                    this.sharedData.tileFlag1 = true;
                                    this.sharedData.tileFlag2 = false;
                                    this.sharedData.tileFlag3 = false;
                                  } else if (flag == 1) {

                                    this.sharedData.tileFlag1 = false;
                                    this.sharedData.tileFlag2 = true;
                                    this.sharedData.tileFlag3 = false;

                                  } else if (flag == 2) {

                                    this.sharedData.tileFlag1 = false;
                                    this.sharedData.tileFlag2 = false;
                                    this.sharedData.tileFlag3 = true;

                                  }
                                } else {
                                  var pageIndex = this.sharedData.businessProfilePageIndex + 1;
                                  this.sharedData.businessProfilePageIndex = pageIndex;
                                  if (pageIndex == 1) {
                                    this.sharedData.tileFlag1 = false;
                                    this.sharedData.tileFlag2 = true;
                                    this.sharedData.tileFlag3 = false;

                                  } else if (pageIndex == 2) {
                                    this.sharedData.tileFlag1 = false;
                                    this.sharedData.tileFlag2 = false;
                                    this.sharedData.tileFlag3 = true;
                                  }
                                }
                                this.indicatorStore.update(state =>{
                                  return {
                                    indicator: this.sharedData,
                                    updatedFrom: "[SharedService] addprofilebyID",
                                  }
                                })
                                if(this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessCategories && 
                                  this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessCategories.length > 0) {
                                    for (let category of this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessCategories) {
                                      this.getCatKeys(category.categoryName, category.categoryId);
                                    }
                                }
                                this.ngxspinner.hide();
                              }, err => {
                                var respObj = err.error.response;
                                var errorObject = respObj.esbServiceFault[0];
                                var errorCode = errorObject.exceptionDetail[0].errorCode;
                                var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                                var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                this.Logger.logError(errorMsg);
                                this.ngxspinner.hide();
                              })

                          } else {
                            var error = "";

                            for (var i = 0; i < response.response.esbServiceFault.length; i++) {

                              var errorObject = response.response.esbServiceFault[i];
                              var errorCode = errorObject.exceptionDetail[i].errorCode;
                              var errorDesc = errorObject.exceptionDetail[i].errorDescription;
                              error += "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                            }
                            this.Logger.logError(error);
                            this.ngxspinner.hide();
                          }
                        }, err => {
                          var respObj = err.error.response;
                          var errorObject = respObj.esbServiceFault[0];
                          var errorCode = errorObject.exceptionDetail[0].errorCode;
                          var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                          var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                          this.Logger.logError(errorMsg);
                          this.ngxspinner.hide();
                        })

                    }, err => {
                      var respObj = err.error.response;
                      var errorObject = respObj.esbServiceFault[0];
                      var errorCode = errorObject.exceptionDetail[0].errorCode;
                      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                      var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                      this.Logger.logError(errorMsg);
                      this.ngxspinner.hide();
                    }
                    )
                }
              }
            } else {
              this.ngxspinner.hide();
            }
          } else {
            this.Logger.logError("Please fill out the required fields");
            this.ngxspinner.hide();
          }
        }
      }, err => {
        this.Logger.logError("CopyBP storage call failed");
        this.ngxspinner.hide();
      })

  }

  getCatKeys(categoryName, categoryId) {
    let indicator
    let catKeys;
    this.CatKeys.getCatKeys(categoryName)
      .subscribe(data => {
        this.indicatorQuery.getIndicator().subscribe((res) => {
          indicator = JSON.parse(JSON.stringify(res));
        });
        let catKeyExists;
        let catKeyTypes = ['Affiliations', 'Brands', 'Certifications', 'Products', 'Services', 'Specialities']
        catKeys = data;
        // Remove the catkeys that are already present in shared data from the response
        if (catKeys && catKeys != null && !(catKeys === undefined)) {
          for (let j = 0; j < catKeys.length; j++) {
            if (indicator['profiles'][indicator.stateParamsId].model[catKeys[j].type] && 
              indicator['profiles'][indicator.stateParamsId].model[catKeys[j].type] != undefined && 
              indicator['profiles'][indicator.stateParamsId].model[catKeys[j].type] != null) {
              for (let i = 0; i < indicator['profiles'][indicator.stateParamsId].model[catKeys[j].type].length; i++) {
                if (catKeys[j].id == indicator['profiles'][indicator.stateParamsId].model[catKeys[j].type][i].catkeyId && 
                  indicator['profiles'][indicator.stateParamsId].model[catKeys[j].type][i].categoryId == categoryId) {
                  catKeys.splice(j, 1)
                  j = j - 1;//Need to reset the loop counter for splicing
                  break;
                }
              }
            }
          }
        }
        // Add the new categories to shared data
        if (catKeys && catKeys != null && !(catKeys === undefined)) {
          for (let catkey of catKeys) {
            let newCatKey = {};
            newCatKey['category'] = categoryName
            newCatKey['categoryId'] = categoryId
            newCatKey['catkeyId'] = catkey.id
            newCatKey['selected'] = 'N'
            newCatKey['_content_'] = catkey.name
            if (indicator['profiles'][indicator.stateParamsId].model[catkey.type] && 
              indicator['profiles'][indicator.stateParamsId].model[catkey.type] != undefined && 
              indicator['profiles'][indicator.stateParamsId].model[catkey.type] != null) {
                indicator['profiles'][indicator.stateParamsId].model[catkey.type].push(newCatKey)
            } else {
              let catKeyArray = [];
              catKeyArray.push(newCatKey);
              indicator['profiles'][indicator.stateParamsId].model[catkey.type] = catKeyArray;
            }
          }
        }
        this.indicatorStore.update(state =>{
          return {
            indicator: indicator,
            updatedFrom: "[SharedService] addprofilebyID",
          }
        })

      }, err => {
        this.Logger.logError(this.errorService.handleApiError(err));
      });
  }

  getSourceType() {
    var sourcetype;
    this.Params.params.mode = sessionStorage.getItem("mode");
    if (this.Params.params.mode === "listing") {
      sourcetype = "SFDC";
    } else {
      sourcetype = ((this.sharedData["order"] && this.sharedData["order"].orderType && this.sharedData["order"].orderType.toLowerCase() == "national") || (this.sharedData['account'] && this.sharedData['account']["accountRecordType"] == "National Account")) ? "CMR" : "SFDC";

    }
    this.Utils.sourcetype = sourcetype;
  }

  getCatkeyObj(catObj) {
    var catKeyObj = {};
    for (var key in catObj) {
      var obj;
      Object.assign(obj, catObj[key]);
      for (var item in obj) {
        var itemKey = 'catkey' + item;
        if (catKeyObj.hasOwnProperty(itemKey)) {
          for (var catItem in obj[item]) {
            catKeyObj[itemKey].catkey.push(obj[item][catItem]);
          }
        } else {
          catKeyObj[itemKey] = {
            'catkey': obj[item]
          }
        }
      }
    }
    return catKeyObj;
  };

  updateClaim(account, myListing, matchedListing, flag) {
    //If we get any of the following field as null,then don't claim BP and show warning
    this.Params.params.mode = sessionStorage.getItem("mode");
    if (account.enterpriseAccountId && myListing[matchedListing] && myListing[matchedListing].listingId
      && (myListing[matchedListing].email)) {
      var data = {
        "enterpriseAccountId": account.enterpriseAccountId,
        "listingId": myListing[matchedListing].listingId,

        "emailAddress": (myListing[matchedListing].emailAddress) ? myListing[matchedListing].emailAddress : account.email
      };

      this.Profile.claim(data).then(results => {
        if (flag == 'quickBP') {
          this.Params.params.mode = null;
          // this.Indicator.quickbp = false ;
          this.Params.params.saving = false;
          this._router.navigate(['/businessprofilegrid/']);
        } else {
          this._router.navigate(['/businessprofiles/' + myListing[matchedListing].listingId, { mode: this.Params.params.mode, eaId: account.enterpriseAccountId, accountId: account.accountId }]);
        }
        // this.Logger.logSuccess("Successfully updated business profile");
      }, error => {
        var errorObject = error.data.response.esbServiceFault[0] ? error.data.response.esbServiceFault[0] : null;
        if (errorObject) {
          var errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
          var errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
          var errorMsg = "<strong>Transaction : " + error.data.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
          this.Logger.logError(errorMsg);
        }
      });
    } else {
      var error = " is not present";
      error = (!account.enterpriseAccountId) ? "enterpriseAccountId" + error : error;
      (!myListing[matchedListing].listingId) ? "listingId" + error : error;
      (!myListing[matchedListing].emailAddress) ? "emailAddress" + error : error;
      this.Logger.logWarning("Unable to claim business profile, " + error);
    }
  };

  openGoldenListingModal() {
    this.dialog.open(GoldenlistingmodalComponent, { disableClose: true, height: 'auto', panelClass: 'golden-listing-dialog', width: '1800px', });
  }

  deleteKey = (obj, path) => {
    const _obj = JSON.parse(JSON.stringify(obj));
    const keys = path.split('.');

    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        delete acc[key];
        return true;
      }
      return acc[key];
    }, _obj);

    return _obj;
  }
  updateBp(dryrunData,existingListingId){
    this.Params.params.mode = sessionStorage.getItem("mode");
    this.ngxspinner.show();
    let bpData : any;
    if(this.Params.params.mode === "QuickBP"){
      bpData = dryrunData;
    } else {
      let bpDesc = { 
        businessProfiles: {
          businessProfile: {
            busProfile: {
              "updatable": "N",
              "_content_": "Y"
            },
            sourceId: {
              "updatable": "N",
              "_content_": "DEXCCI"
            },
            templateData: {
              "label": "Template",
              "updatable": "Y",
              "templateId": [{
                "selected": "Y",
                "_content_": "Default"
              }]
            },
            genericData: {
              "busDescription": {
                "_content_": this.sharedData['profiles'][""].model['BusinessDescription'],
              }
            }
          }
        }
      };
      bpData = Object.assign(dryrunData, bpDesc)
    }
    console.log(bpData)
    this.Profile.update(existingListingId, bpData)
    .then(result => {
      if(this.Params.params.mode === "QuickBP"){
        // this.Params.params.mode = "ManageBP";
        // this.Params.params.order = null;
        // let bpEaId = this.activatedRoute.snapshot.queryParams['eaId'];
        // let bpAccount = this.activatedRoute.snapshot.queryParams['account'];
        // this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: bpEaId, account: bpAccount}, queryParamsHandling: 'merge'});
        
        // this.Logger.logSuccess("Successfully updated business profile")
        // this.ngxspinner.hide();
        this.indicator.addProfileById(this.sharedData,existingListingId)
        .then(profileResult => {
          var activityLogHistoryObj = {
            'displayName': this.sharedData['user']["name"],
            'email': this.sharedData['user']["email"],
            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
            'itemId': existingListingId,
            'fieldName': 'Business Profile',
            'fieldValue': 'Updated in ' + this.Params.params.mode
          }
          this.StorageService.update("profileHistory", existingListingId, activityLogHistoryObj);
          this.Params.params.mode = "ManageBP";
          this.Params.params.order = null;
          let bpEaId = this.activatedRoute.snapshot.queryParams['eaId'];
          let bpAccount = this.activatedRoute.snapshot.queryParams['account'];
          setTimeout(() => {
            sessionStorage.setItem("mode","ManageBP");
            console.log("update mode in session storage 1410")
          }, 2000);
          this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: bpEaId, account: bpAccount}, queryParamsHandling: 'merge'});
         setTimeout(() => {
          sessionStorage.setItem("caangulareffererPath",this.location.path());
          sessionStorage.setItem("okta-app-url",this.location.path());
         }, 2000);
          
          this.Logger.logSuccess("Successfully updated business profile")
          this.ngxspinner.hide();
        }, err => {
          var respObj = err.error.response;
          var errorObject = respObj.esbServiceFault[0];
          var errorCode = errorObject.exceptionDetail[0].errorCode;
          var errorDesc = errorObject.exceptionDetail[0].errorDescription;
          var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
          this.Logger.logError(errorMsg);
          this.ngxspinner.hide();
        })
      } else {
        if(this.Params.params.mode != "ManageBP" && this.Params.params.mode != "QuickBP" && this.Params.params.mode != "listing" ){
          this.Profile.getByDigitalListing(existingListingId).then((res)=>{
            if(res[existingListingId].hasWarningOrError == "false"){
              this.sharedData["order"].OrderItems.forEach((item, index) => {
                if(item.productType == "Digital" && item.actionCode != "Cancel" && item.actionType != "Cancel" ){
                  let name = res[existingListingId].listings.listing[0].businessName;
                  let  phoneNumLocal = "";
                  if(res[existingListingId].listings.listing[0].primaryPhone.value){
                     phoneNumLocal = this.Utils.transformTelephone(res[existingListingId].listings.listing[0].primaryPhone.value);
                  }
                  name += "," + phoneNumLocal + " " +  res[existingListingId].listings.listing[0].businessAddress.streetAddress + " " + res[existingListingId].listings.listing[0].businessAddress.city ;
                  if (res[existingListingId].listings.listing[0].businessAddress.zip !== undefined ) {
                    name += '-' + res[existingListingId].listings.listing[0].businessAddress.zip;
                  }
                  if (res[existingListingId].listings.listing[0].businessAddress.zip4 !== undefined ) {
                    name += '-' + res[existingListingId].listings.listing[0].businessAddress.zip4;
                  }
                  if(!this.sharedData["items"][item.svItemId]['listingArray']){
                    this.sharedData["items"][item.svItemId]['listingArray'] = [];
                  }
                  let listing = {
                    name: name,
                    value: res[existingListingId].listings.listing[0].listingId,
                    index: this.sharedData["items"][item.svItemId]['listingArray'].length
                  };
                  if(!this.sharedData["items"][item.svItemId]['listings']){
                    this.sharedData["items"][item.svItemId]['listings'] = [];
                  }
                  this.sharedData["items"][item.svItemId]['listings'].push(res[existingListingId].listings.listing[0]);
                  this.sharedData["items"][item.svItemId]['listingArray'].push(listing);
                }
              })
            }
          })
        }
        this.indicator.addProfileById(this.sharedData, existingListingId)
          .then(profileResult => {
            this.sharedData['profiles'] = profileResult;
            this.sharedData.stateParamsId = existingListingId;
            // if(!this.sharedData['profiles'][this.sharedData.stateParamsId].model['BusinessDescription']){
            //   this.sharedData['profiles'][this.sharedData.stateParamsId].model['BusinessDescription'] = this.sharedData['profiles'][""].model['BusinessDescription'];
            // }
            // // to update left nav count
            // let tile1Count = this.requiredfieldcount.get(this.sharedData['profiles'][this.sharedData.stateParamsId].schema[0], this.sharedData['profiles'][this.sharedData.stateParamsId].form[0], this.sharedData['profiles'][this.sharedData.stateParamsId].model);
            // this.sharedData['profiles'][this.sharedData.stateParamsId].menu[0][0].counter = tile1Count;
            // this.sharedData['profiles'][this.sharedData.stateParamsId].menuOrig[0].counter = tile1Count;
            // let tile2Count = this.requiredfieldcount.get(this.sharedData['profiles'][this.sharedData.stateParamsId].schema[1], this.sharedData['profiles'][this.sharedData.stateParamsId].form[1], this.sharedData['profiles'][this.sharedData.stateParamsId].model);
            // this.sharedData['profiles'][this.sharedData.stateParamsId].menu[0][1].counter = tile2Count;
            // this.sharedData['profiles'][this.sharedData.stateParamsId].menuOrig[1].counter = tile2Count;

            this.sharedData['tempModel'] = this.sharedData['profiles'][this.sharedData.stateParamsId].model;
            this.sharedData['bpDirtyFlag'] = true;

            this.sharedData.businessProfilePageIndex = 1;
            this.sharedData.tileFlag1 = false;
            this.sharedData.tileFlag2 = true;
            this.sharedData.tileFlag3 = false;
            this.indicatorStore.update(state =>{
              return {
                indicator: this.sharedData,
                updatedFrom: "[SharedService] addprofilebyID",
              }
            })
            this.ngxspinner.hide();
          }, err => {
            var respObj = err.error.response;
            var errorObject = respObj.esbServiceFault[0];
            var errorCode = errorObject.exceptionDetail[0].errorCode;
            var errorDesc = errorObject.exceptionDetail[0].errorDescription;
            var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            this.Logger.logError(errorMsg);
            this.ngxspinner.hide();
          })
      }
      // if (existingListingId){
      //   let optionalWebsiteURL = this.sharedData['profiles'][existingListingId].model["BusinessWebsiteURL"]
      //   this.sharedData['profiles'][existingListingId].model.BusinessWebsiteURL = optionalWebsiteURL;
      // }

      // this.Profile.getByDigitalListing(existingListingId).then(data => {
      //   if (data[existingListingId].coupons) {
      //    //  this.sharedData['profiles'][existingListingId].model["coupons"] =
      //    //    data[existingListingId].coupons.coupon
      //   }
      //   this.indicatorStore.update(state =>{
      //     return{
      //       indicator: this.sharedData,
      //       updatedFrom: "[SharedService] updateBP",
      //     }
      //   })
      //   this.ngxspinner.hide();
      // }, err => {
      //   var respObj = err.error.response;
      //   var errorObject = respObj.esbServiceFault[0];
      //   var errorCode = errorObject.exceptionDetail[0].errorCode;
      //   var errorDesc = errorObject.exceptionDetail[0].errorDescription;
      //   var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
      //   this.Logger.logError(errorMsg);
      //   this.ngxspinner.hide();
      // })
    }, err => {
      var respObj = err.error.response;
      var errorObject = respObj.esbServiceFault[0];
      var errorCode = errorObject.exceptionDetail[0].errorCode;
      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
      var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
      this.Logger.logError(errorMsg);
      this.ngxspinner.hide();
    })

    // this.Profile.create("digital", bpData).then(result => {
    //   this.ngxspinner.show();
    //   var claim = {
    //     "enterpriseAccountId": this.sharedData['account']["enterpriseAccountId"],
    //     "listingId": result.listingId,
    //     "emailAddress": bpData.listings.listing[0].email.value
    //   };
    //   this.Profile.claim(claim)
    //     .then(response => {
    //       this.ngxspinner.hide();
    //       if (response.hasWarningOrError !== "true") {
    //         //Clear the blank BP when a new copied BP is created successfully or else clicking on New BP will hold up the older values
    //         this.sharedData['profiles'][""].model = {
    //           BusinessAddressDisplayOptions: "ALL"
    //         }
    //         this.indicator.addProfileById(this.sharedData,result.listingId)
    //           .then(profileResult => {
    //             var activityLogHistoryObj = {
    //               'displayName': this.sharedData['user']["name"],
    //               'email': this.sharedData['user']["email"],
    //               'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
    //               'itemId': result.listingId,
    //               'fieldName': 'Business Profile',
    //               'fieldValue': 'Created in ' + this.Params.params.mode
    //             }
    //             this.StorageService.update("profileHistory", result.listingId, activityLogHistoryObj);
    //             this.Params.params.mode = "ManageBP";
    //             this.Params.params.order = null;
    //             let bpEaId = this.activatedRoute.snapshot.queryParams['eaId'];
    //             let bpAccount = this.activatedRoute.snapshot.queryParams['account'];
    //             this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: bpEaId, account: bpAccount}, queryParamsHandling: 'merge'});
    //             this.Logger.logSuccess("Successfully created business profile");
    //             this.ngxspinner.hide();
    //           }, err => {
    //             var respObj = err.error.response;
    //             var errorObject = respObj.esbServiceFault[0];
    //             var errorCode = errorObject.exceptionDetail[0].errorCode;
    //             var errorDesc = errorObject.exceptionDetail[0].errorDescription;
    //             var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
    //             this.Logger.logError(errorMsg);
    //             this.ngxspinner.hide();
    //           })
    //       } else {
    //         var error = "";
    //         for (var i = 0; i < response.response.esbServiceFault.length; i++) {
    //           var errorObject = response.response.esbServiceFault[i];
    //           var errorCode = errorObject.exceptionDetail[i].errorCode;
    //           var errorDesc = errorObject.exceptionDetail[i].errorDescription;
    //           error += "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
    //         }
    //         this.Logger.logError(error);
    //         this.ngxspinner.hide();
    //       }
    //     }, err => {
    //       var respObj = err.error.response;
    //       var errorObject = respObj.esbServiceFault[0];
    //       var errorCode = errorObject.exceptionDetail[0].errorCode;
    //       var errorDesc = errorObject.exceptionDetail[0].errorDescription;
    //       var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
    //       this.Logger.logError(errorMsg);
    //       this.ngxspinner.hide();
    //     })
    // }, err => {
    //   var respObj = err.error.response;
    //   var errorObject = respObj.esbServiceFault[0];
    //   var errorCode = errorObject.exceptionDetail[0].errorCode;
    //   var errorDesc = errorObject.exceptionDetail[0].errorDescription;
    //   var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
    //   this.Logger.logError(errorMsg);
    //   this.ngxspinner.hide();
    // })
  }
  
}
