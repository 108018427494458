export interface Campaign {
    campaignPageIndex?: number;
    stateParamsId?: string;
    model?: any;
    campaignType?: string;
    listingDtls?: any;
    ddlvalue?: string;
    campaignFlag?: boolean;
    selectedCampaign?: any;
    campaignSchema?: any;
    tileFlag1?:boolean;
    tileFlag2?:boolean;
    tileFlag3?:boolean;
    tileFlag4?:boolean;
}

export class CampaignClass implements Campaign {
    campaignPageIndex?: number = 0;
    stateParamsId?: string = '';
    model?: any = {
    };
    campaignType?: string = ''
    listingDtls?: any = {}
    ddlvalue?: string = ''
    campaignFlag?: boolean = false;
    selectedCampaign?: any = {}
    campaignSchema?: any = {}
    tileFlag1?:boolean = false;
    tileFlag2?:boolean = false;
    tileFlag3?:boolean = false;
    tileFlag4?:boolean = false;
}