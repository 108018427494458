import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SetBaseCollectionService {
    PATH: string = '/api/setBaseCollection';
    constructor(private API: ApiService, private http: HttpClient) { }
    headers = new HttpHeaders()
    // /**
    // * getByAccountIdOrderId - get based on account and order id
    // * @param  {[type]} id [description]
    // * @return {[type]}    [description]
    // */
    //To get the set based images data from mongo db
    async get(KgenCustomerId: string): Promise<any> {
        let fullPath = this.API.get(this.PATH) + "/" + KgenCustomerId;
        console.log("fullPath::" + fullPath);
        return this.http.get(fullPath).toPromise();
    };
    //To insert/update a given record of set base image data into mongo db
    upsertsetBaseImage(KgenCustomerId: string, itemId: string, orderId: string, username: string, setbaseImg){
        let requestbody = {};
        requestbody['setbaseImg']=setbaseImg;
        let apipath = this.API.get(this.PATH) + "/" + KgenCustomerId + "/" + itemId + "/" + orderId + "/" + username;
        //console.log("APIpath:"+apipath);
        return this.http.post(apipath,requestbody).toPromise();
    }
    //To insert/update set base image array for given kgencustomerid into mongo db
    upsertsetBaseAdfromKgen(KgenCustomerId: string, itemId: string, orderId: string, username: string, setbaseImgArr){
        let requestbody = {};
        requestbody['setbaseImgArr']=setbaseImgArr;
        let apipath = this.API.get(this.PATH)  + "/setBaseImg/" + KgenCustomerId + "/" + itemId + "/" + orderId + "/" + username;
        //console.log("APIpath:"+apipath);
        return this.http.post(apipath,requestbody).toPromise();
    }

}