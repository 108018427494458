import  * as $ from 'jquery';
import { Router } from '@angular/router';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { OnInit } from '@angular/core';
import { ConfigurationService } from '@app/service/configuration/configuration.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { AdminDashboardComponent } from '../components/admin-dashboard/admin-dashboard.component';
var that;
export class ConfigurationModel {
    indicatorValue :Quickbp
    subscribe: any
    constructor(private router: Router,
              private params: ParamsService, private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,private config:ConfigurationService,private logger:LoggerService ) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  that = this;
                });
    }
    columns: any ={
        "configurationgrid":[
            {title:"Type", field:"type"},
            {title:"Name", field:"name"},
            {title:"Value", field:"value",editor:"input"},
            {title:"Actions",formatter:function(cell, formatterParams){ //plain text value
              return `<div>
              <button class="mat-button mat-button-base" id="update">Update</button>
              <button class="mat-button mat-button-base" id="delete">Delete</button>
              </div>`
              // return button
          },cellClick: (e, cell) => {
          var Btn = document.createElement('Button');
          Btn.id = "Btn_Id";
          var id1 = document.getElementsByTagName('button')[0].id;
          var id2 = document.getElementsByTagName('button')[1].id
          console.log("event called",Btn.id);
          // console.log(id1);
          // console.log(id2);
          if(e.target.id == 'update'){
            //this._router.navigate(['servicehistory/' + cell.getRow().getData().ItemID + '/' + cell.getRow().getData().ID]); 
            console.log("update button called",cell.getRow().getData());
            let configData = {            
              name: cell.getRow().getData().name,
              type: cell.getRow().getData().type,
              value: cell.getRow().getData().value}
            this.config.update(configData).then((data)=> {
               // vm.loadConfigData();
                this.logger.logSuccess('Values updated successfully');
            })
          }
          if(e.target.id == 'delete'){
            //this._router.navigate(['servicedetails/' + cell.getRow().getData().ItemID + '/' + cell.getRow().getData().ID]);
            console.log("delete button called",cell.getRow().getData())
          //   this.adminuser.deleteUser(cell.getRow().getData().user).subscribe((data)=> {
          //     console.log("updated value",cell.getRow().getData().user)
          //     this.logger.logWarning('User deleted succesfully');
          //     //vm.loadData();
          // });;
          cell.getRow().delete();
          this.config.delete(cell.getRow().getData().type,cell.getRow().getData().name,cell.getRow().getData()).then((data)=> {
            this.logger.logSuccess('Configuration data deleted succesfully');
            //this.admindashboard.configurationDetails();
        })
          }
          return {id2, id1};
        }
            }
            ],
    }
}


