import { FileUploader, FileItem } from 'ng2-file-upload';

export class Photos {
    browsedeladdArrObj?:Array<any> = [];
    uploadLogoArrObj?:Array<any> = [];
    logoArrObj?:Array<any> = [];
    deleteFromLogoarrObj?:Array<any> = [];
    deleteArrFromIHObj?:Array<any> = [];
    fileItemObj?:Array<any> = [];
    uploaderObj?:FileUploader;
    deleteLogoarrObj?:Array<any> = [];
    configObj?:any  = {};
    fileitemObj?:FileItem;
    browseFilesObj?:Array<any> = [];
    attachmentsObj?:Array<any> = [];
}