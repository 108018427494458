import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';

@Component({
  selector: 'app-ngx-spinner',
  templateUrl: './ngx-spinner.component.html',
  styleUrls: ['./ngx-spinner.component.scss']
})
export class NgxSpinnerComponent implements OnInit, OnDestroy {
  isPublishClicked;
  spinnerText;
  isCopySheet;
  constructor( private SpinerService: SpinnerService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.spinnerText = this.SpinerService.getSpinnerText();
    this.isPublishClicked = this.SpinerService.isPublishSpinner();
    this.isCopySheet = this.SpinerService.isCopySheetSpinner();
   
  }

  ngOnDestroy() {
    this.spinnerText ='';
    this.isPublishClicked = false;
  }
  ngAfterViewInit() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }

}
