import { Component, OnDestroy, OnInit } from '@angular/core';
//import { DatasharingService } from '@app/service/datasharing/datasharing.service';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignQuery } from 'app/state/campaignquery';
import { Subject } from 'rxjs';
import { ComponentQuery } from 'app/state/component.query';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { CampaignStore } from 'app/state/campaignstore';

@Component({
  selector: 'app-string',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.scss']
})
export class StringComponent implements OnInit, OnDestroy {
  titleContent: any;
  constructor( private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
               private campaignQuery: CampaignQuery,
               private componentQuery: ComponentQuery,
               private campaignStore: CampaignStore) { }

  sharedData;
  customField = '';
  numbers: any = /^[0-9]+$/;
  error: boolean = false;
  id: any;
  errorMsg: any;
  invalid: boolean = false;
  options;
  campaignModel: CampaignClass;
  destroySubject$: Subject<void> = new Subject();
  bpRequiredField: boolean = false;
  campaignRequiredField: boolean = false;
  readonly: boolean = false;

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      if (this['layoutNode'].name == "BusinessAddress1") {

        this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessAddress1']['title'];
        this.bpRequiredField = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessAddress1']['required'];
        this.id = this['layoutNode'].name;
        // this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress1;
        this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
          // this.customField = data[0];
          if(data && !(data == "Yes" || data == "No")){
            this.customField = data[0];
          }
          }, err => {
            console.log(err)
          }, () => {
          //  console.log('Completed Address1 update!!!!!')
        });
        this.customField = this.customField? this.customField : this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress1;
        if (this.customField != undefined && this.customField != null  && this.customField.length > 200) {
          this.invalid = true;
          this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
        } else {
          if(this.customField == ''){
            this.invalid = true;
          }else{
            this.invalid = false;
          }

        }
      }

      if (this['layoutNode'].name == "BusinessAddress2") {
        this.id = this['layoutNode'].name
        this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessAddress2']['title'];
        this.bpRequiredField = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessAddress2']['required'];
        this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress2;
        if (this.customField != undefined && this.customField != null && this.customField.length > 200) {
          this.invalid = true;
          this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
        } else {
        this.invalid = false
        }
      }

      if (this['layoutNode'].name == "BusinessAddressCity") {
        this.id = this['layoutNode'].name
        this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessAddressCity']['title'];
        this.bpRequiredField = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessAddressCity']['required'];
        // this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressCity;
        this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
          // this.customField = data[1];
          if(data && !(data == "Yes" || data == "No")){
            this.customField = data[1];
          }
          }, err => {
            console.log(err)
          }, () => {
          //  console.log('Completed City update!!!!!')
        });
        this.customField = this.customField ? this.customField : this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressCity;
        if (this.customField != undefined && this.customField != null  && this.customField.length > 200) {
            this.invalid = true;
            this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
          } else {
            if(this.customField == ''){
              this.invalid = true;
            }else{
              this.invalid = false;
            }

          }
      }
    })
    if (this['layoutNode'].name == "BusinessName") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessName']['title'];
      this.bpRequiredField = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessName']['required'];
      this.id = this['layoutNode'].name;
      this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessName;
      if (this.customField != undefined && this.customField != null && this.customField.length > 200) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
      } else {
      this.invalid = false
      this.errorMsg ="";
      }
    }

    if (this['layoutNode'].name == "BusinessTagline") {
      this.id = this['layoutNode'].name
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['BusinessTagline']['title'];
      this.bpRequiredField = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['BusinessTagline']['required'];
      this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessTagline;
      if (this.customField != undefined && this.customField != null && this.customField.length > 200) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
      } else {
        this.invalid = false;
      }
    }

    if (this['layoutNode'].name == "InBusinessSince") {
      this.id = this['layoutNode'].name
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['InBusinessSince']['title'];
      this.bpRequiredField = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['InBusinessSince']['required'];
      this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.InBusinessSince;
      if (this.customField != undefined && this.customField != null && this.customField.length > 200) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
      } else {
        this.invalid = false;
      }
    }
    if (this['layoutNode'].name == "LicenseNumber") {
      this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.LicenseNumber;
    }
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data }
      // if(this['layoutNode'].name === "CampaignTitle"){
      //   this.customField = this.campaignModel.model.CampaignTitle;
      // }
    })
    if (this.campaignModel && this.campaignModel.campaignFlag) {
      if(this.sharedData && this.sharedData.stateParamsId && this.sharedData.items && this.sharedData.items[this.sharedData.stateParamsId]){
      for (var i = 0; i < this.sharedData.items[this.sharedData.stateParamsId].form.length; i++) {
        if (this.sharedData.items[this.sharedData.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.sharedData.items[this.sharedData.stateParamsId].form[i].readonly;
          break;
        }
      }
    }
    // Add check for Sentry issue
      if(this.campaignModel && this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name]){
        this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title
      }else{
        this.titleContent = " ";
      }
      this.campaignRequiredField = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].required
      if (this['layoutNode'].name === "CampaignTitle") {
        this.customField = this.campaignModel.model.CampaignTitle;
      }

      if (this['layoutNode'].name === "CampaignDescription") {
        this.customField = this.campaignModel.model.CampaignDescription;
      }

      if (this['layoutNode'].name === "Address1") {
        this.customField = this.campaignModel.model.Address1;
        if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit'){
            if(this.campaignModel.model['CampaignId']){
              this.readonly = true;
            } else {
              if(this.campaignModel.model.ListingId) {
                this.readonly = true;
              } else {
                this.readonly = false;
              }
            }
              
          // if(this.campaignModel.selectedCampaign.isCampaignFromVision && 
          //   this.campaignModel.selectedCampaign.ListingId) {
          //   this.customField = this.campaignModel.model.Address1;
          // } else if (this.campaignModel.selectedCampaign.isCampaignFromVision && 
          //   (!this.campaignModel.selectedCampaign.ListingId || this.campaignModel.model['isSelectedEmpty'])) {

          //   }
        } else if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
            // this.readonly = false;
            if(this.campaignModel.model.ListingId){
              this.readonly = true;
            } else {
              this.readonly = false;
            }
        } else {
          if(this.campaignModel.model.ListingId) {
          // this.customField = this.campaignModel.model.Address1;
            this.readonly = true;
          } else {
            this.readonly = false;
          }
        }
      }

      if (this['layoutNode'].name === "Address2") {
        this.customField = this.campaignModel.model.Address2
        // if(this.campaignModel.model.Address2) {
        //   this.readonly = true;
        // } else {
        //   this.readonly = false;
        // }
        // if(this.campaignModel.model && this.campaignModel.selectedCampaign && this.campaignModel.selectedCampaign.isCampaignFromVision && this.campaignModel.model['isSelectedEmpty']) {
        //   this.customField = this.campaignModel.model.Address2;
        //   this.readonly = true;
        // } 
        if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit'){
            if(this.campaignModel.model['CampaignId']){
              this.readonly = true;
            } else {
              if(this.campaignModel.model.ListingId) {
                this.readonly = true;
              } else {
                this.readonly = false;
              }
            }
        } else if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
            // this.readonly = false;
            if(this.campaignModel.model.ListingId){
              this.readonly = true;
            } else {
              this.readonly = false;
            }
        } else {
          if(this.campaignModel.model.ListingId) {
            this.readonly = true;
          } else {
            this.readonly = false;
          }
        }
      }

      if (this['layoutNode'].name === "CampaignAddressCity") {
        this.campaignModel.model.CampaignAddressCity = (this.campaignModel.model.BPText) ? this.campaignModel.model.City : this.campaignModel.model.CampaignAddressCity
        this.customField = this.campaignModel.model.CampaignAddressCity
        // if(this.campaignModel.model.CampaignAddressCity && this.campaignModel.model.ListingId) {
        //   this.readonly = true;
        // } else {
        //   this.readonly = false;
        // }
        // if(this.campaignModel.model && this.campaignModel.selectedCampaign && this.campaignModel.selectedCampaign.isCampaignFromVision && this.campaignModel.model['isSelectedEmpty']) {
        //   this.customField = this.campaignModel.model.CampaignAddressCity;
        //   this.readonly = true;
        // } 
        if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit'){
            if(this.campaignModel.model['CampaignId']){
              this.readonly = true;
            } else {
              if(this.campaignModel.model.ListingId) {
                this.readonly = true;
              } else {
                this.readonly = false;
              }
            }
        } else if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
            // this.readonly = false;
            if(this.campaignModel.model.ListingId){
              this.readonly = true;
            } else {
              this.readonly = false;
            }
        } else {
          if(this.campaignModel.model.ListingId) {
            this.readonly = true;
          } else {
            this.readonly = false;
          }
        }
      }

      if (this['layoutNode'].name === "CampaignAddressZip") {
        this.campaignModel.model.CampaignAddressZip = (this.campaignModel.model.BPText) ? this.campaignModel.model.Zip : this.campaignModel.model.CampaignAddressZip
        this.customField = this.campaignModel.model.CampaignAddressZip
        // if(this.campaignModel.model.CampaignAddressZip && this.campaignModel.model.ListingId) {
        //   this.readonly = true;
        // } else {
        //   this.readonly = false;
        // }
        // if(this.campaignModel.model && this.campaignModel.selectedCampaign && this.campaignModel.selectedCampaign.isCampaignFromVision && this.campaignModel.model['isSelectedEmpty']) {
        //   this.customField = this.campaignModel.model.CampaignAddressZip;
        //   this.readonly = true;
        // } 
        if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit'){
            if(this.campaignModel.model['CampaignId']){
              this.readonly = true;
            } else {
              if(this.campaignModel.model.ListingId) {
                this.readonly = true;
              } else {
                this.readonly = false;
              }
            }
        } else if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
            // this.readonly = false;
            if(this.campaignModel.model.ListingId){
              this.readonly = true;
            } else {
              this.readonly = false;
            }
        } else {
          if(this.campaignModel.model.ListingId) {
            this.readonly = true;
          } else {
            this.readonly = false;
          }
        }
      }
    } else { }
  }
  sendValue(args) {

    if(!this.campaignModel.campaignFlag){
    if (this['layoutNode'].name === "BusinessName") {
      if (args.length > 200) {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessName = '';
        this.invalid = true;
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessName = args;
        if(this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessName == ''){
          this.invalid = true;
          this.errorMsg = "";
        }else{
          this.invalid = false;
        this.errorMsg = "";
        }

      }
    }
    if (this['layoutNode'].name === "BusinessAddress1") {
      if (args.length > 200) {
      this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress1  = args;
        this.invalid = true;
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress1  = args;
        this.invalid = false;
        this.errorMsg = "";
      }
    }
    if (this['layoutNode'].name === "BusinessAddress2") {
      if (args.length > 200) {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress2  = '';
        this.invalid = true;
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddress2  = args;
        this.invalid = false;
        this.errorMsg = "";
      }
    }
    if (this['layoutNode'].name === "BusinessAddressCity") {
      if (args.length > 200) {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressCity  = args;
        this.invalid = true;
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressCity  = args;
        this.invalid = false;
        this.errorMsg = "";
      }
    }
    if (this['layoutNode'].name === "BusinessTagline") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessTagline = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessTagline = args;
        this.invalid = false;
      }
    }
    if (this['layoutNode'].name === "InBusinessSince") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.InBusinessSince = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.InBusinessSince = args;
        this.invalid = false;
      }
    }

   // if (this['layoutNode'].name === "BusinessName" || this['layoutNode'].name === "BusinessAddress1" || this['layoutNode'].name === "BusinessAddressCity") {
      //this.dataService.setBehaviorView(this.sharedData);
    let layoutNodeName = this['layoutNode'].name;
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
//    const res=  this.indicatorQuery.getValue();
    modelData[layoutNodeName]=args;
   // this.sharedData['profiles'][this.sharedData.stateParamsId].model= modelData;
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;
      this.indicatorStore.setLoading(true);
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[StringComponent] sendvalue",
          isloaded: true
        }
      });
      this.indicatorStore.setLoading(false);
    //}
  }
    if (this.campaignModel.campaignFlag) {
      let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
      if (this['layoutNode'].name === "CampaignTitle") {
        modelData.CampaignTitle = args;
      }

      if (this['layoutNode'].name === "CampaignDescription") {
        modelData.CampaignDescription = args;
      }

      if (this['layoutNode'].name === "Address1") {
        modelData.Address1 = args;
      }

      if (this['layoutNode'].name === "Address2") {
        modelData.Address2 = args;
      }

      if (this['layoutNode'].name === "CampaignAddressCity") {
        modelData.CampaignAddressCity = args;
      }

      if (this['layoutNode'].name === "CampaignAddressZip") {
        modelData.CampaignAddressZip = args;
        if(this.campaignModel.selectedCampaign && 
          !this.campaignModel.selectedCampaign['modelClicktypes']){
            modelData.TargetZIPcode = args;
        }
      }
      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[StringComponent] sendvalue'
        }
      })
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
