import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-campaignalert',
  templateUrl: './campaignalert.component.html',
  styleUrls: ['./campaignalert.component.scss']
})
export class CampaignalertComponent implements OnInit {

  content: any;
  heading: any;

  constructor(private dialogRef: MatDialogRef<CampaignalertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.content = this.data.message;
    this.heading = this.data.heading;
  }

  close(){
    this.dialogRef.close();
  }

}
