import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()

export class CouponSharingService {
  private couponObjSource = new BehaviorSubject([]);
  couponObj = this.couponObjSource.asObservable()
  
  constructor() { }
  
  setcouponObj(couponObj: []) {
    this.couponObjSource.next(couponObj);
  }

  getcouponObj(): Observable<any[]>{
      return this.couponObjSource.asObservable();
  }
}
