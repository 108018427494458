import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { LoggerService } from '@app/service/logger/logger.service';
import { CallconfirmationComponent } from 'app/shared/components/call-new/callconfirmation/callconfirmation.component';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Observable, Observer } from 'rxjs';
import { ParamsService } from '@app/service/params/params.service';
import { CounterService } from '@app/service/counter/counter.service';
import { ManagecopysheetService } from '@app/service/managecopysheet/managecopysheet.service';



@Injectable({
  providedIn: 'root'
})
export class FormDirtyGuardService implements CanDeactivate<any>{

  result: string = '';
  Indicator: any;
  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private matDialog: MatDialog,
    private Logger: LoggerService,
    private Params: ParamsService,
		private counterService: CounterService,
    private manageCopySheetService: ManagecopysheetService) { }

  canDeactivate(component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {
      this.Params.params.mode = sessionStorage.getItem("mode");
    if (currentRoute && (currentRoute.component['name'] === 'BusinessProfilesComponent')
      && this.Params.params.mode == 'ManageBP') {
      if (this.indicatorQuery.getValue().indicator['defaultCopyBP'] == 'Y') {
        let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        if (x['defaultCopyBP'] == 'Y') {
          x['profiles'][""]['model'] = x['tempDefaultData'];
        }
        this.indicatorStore.update(state => {
          return {
            indicator: x,
            updatedFrom: "[FormDirtyGuardService] canDeactivate",
            isloaded: true
          }
        });
      }
    }
    if (currentRoute && (currentRoute.component['name'] === 'BusinessProfilesComponent')
      && this.Params.params.mode != 'ManageBP'
      && this.Params.params.mode != 'listing') {
      let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      if ((this.indicatorQuery.getValue().indicator['bpDirtyFlag'] &&
        JSON.stringify(x['profiles'][x.stateParamsId].model) != JSON.stringify(x['tempModel'])) || this.indicatorQuery.getValue().indicator['defaultCopyBP'] == 'Y') {
        return new Observable((observer: Observer<boolean>) => {
          let msg = 'Please click the "Ok" button and proceed to the Business Profile to Save your changes. Clicking the "Cancel" button will not save your Business Profile changes.';
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'bp-dirty-flag-modal'
          dialogConfig.data = {
            message: msg,
          }
          dialogConfig.width = '600px';
          const dialogRef = this.matDialog.open(CallconfirmationComponent, dialogConfig);

          dialogRef.afterClosed().subscribe(dialogResult => {
            if (!dialogResult) {
              let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));

              x['profiles'][x.stateParamsId].model = x['tempModel'];
              x['profiles'][x.stateParamsId].form[1][11] = x['tempFormConfirmHOP'];
              x['tempModel'] = {};
              x['tetempFormConfirmHOPmpForm'] = {};
              x['bpDirtyFlag'] = false;
              if (x['defaultCopyBP'] == 'Y') {
                x['profiles'][""]['model'] = x['tempDefaultData'];
              }
               //remove any newly added profiles from indicator
              if (this.Params.params.mode === null) {
                for (let key in x['profiles']) {
                  if (key != "" && x['listingIds'] != undefined && x['listingIds'] != null) {
                    if (x['listingIds'].indexOf(key) < 0) {
                    delete x['profiles'][key];
                    x.stateParamsId = "";
                    }
                  }

                }
              }
              this.indicatorStore.update(state => {
                return {
                  indicator: x,
                  updatedFrom: "[FormDirtyGuardService] dialog",
                  isloaded: true
                }
              });
            }
            observer.next(!dialogResult);
            observer.complete();
          }, (error) => {
            observer.next(false);
            observer.complete();
          });
        });
      } else {
        return true;
      }

    } else if (currentRoute && (currentRoute.component['name'] === 'ItemComponent' || currentRoute.component['name'] === 'OrderItemsComponent')) {
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.Indicator = JSON.parse(JSON.stringify(data));
      });
      if (this.Indicator && this.Indicator.items[this.Indicator.stateParamsId] && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet &&
        this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.manageCopyModified) {
        this.Logger.logError("You must Save the item to send the Copy Sheet/Attachment/Ad Instructions to graphics");
        return false;
      } else { return true; }
    } else if (currentRoute && (currentRoute.component['name'] === 'PublishComponent')){
        this.indicatorQuery.getIndicator().subscribe(data => {
          this.Indicator = JSON.parse(JSON.stringify(data));
        });
        console.log("Data",currentRoute.data);
        //return Boolean(currentRoute.data.navigateflag);
        if(this.Indicator.hasOwnProperty('navigateFlag')){
          console.log("Data",this.Indicator.navigateFlag);
          return (this.Indicator.navigateFlag)
        }
        return true;
    } else if (currentRoute && (currentRoute.component['name'] === 'GraphicItemsComponent')) {
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.Indicator = JSON.parse(JSON.stringify(data));
      });
      // Graphic item IA group or NonIA - check copyshet uploaded or view annotated - if not saved the item then don't alow to route
      if(this.Indicator && this.Indicator.items){
        let isGraphicItemEdited = false;
        let isGraphicItemEditedArray = [];
        for (var i in this.Indicator.items) {
          let item = this.Indicator.items[i];
          if (item && item.model.ManageCopysheet && item.model.ManageCopysheet.manageCopyModifiedGraphic) {
            isGraphicItemEdited = true;
            isGraphicItemEditedArray.push(item.order.svItemId)
            let element = document.getElementById('required-' + item.order.svItemId);
            if(element){
              element.classList.add("required-card");
            }
          } 
        }
        if(isGraphicItemEditedArray.length > 0){
          this.Logger.logError("You must Save/Resend copysheet to send the Copy Sheet/Attachment/Ad Instructions to graphics");
          this.manageCopySheetService.setBehaviorRequiredItemGraphic(isGraphicItemEditedArray);
          return false;
        } else { return true; }
      }
    } else { return true; }
  }

}
