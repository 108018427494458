import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ComponentQuery } from 'app/state/component.query';
import { Subject } from 'rxjs';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { BillingService } from '@app/service/billing/billing.service';

@Component({
  selector: 'app-billingstring',
  templateUrl: './billingstring.component.html',
  styleUrls: ['./billingstring.component.scss']
})
export class BillingstringComponent implements OnInit, OnDestroy {
  subscription: any;
  customField = '';
  numbers: any = /^[0-9]+$/;
  error: boolean = false;
  id: any;
  errorMsg: any;
  invalid: boolean = false;
  sharedData;
  options: any;
  titleContent: string;
  hidden: boolean = false;
  destroySubject$: Subject<void> = new Subject();
  baFlag: boolean = false;
  formSumbitted: boolean = false;
  disabledstreetaddress2: boolean = false;

  constructor(
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private componentQuery: ComponentQuery,
    private billing: BillingService) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.id = this['layoutNode'].name;

    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
      if (this.data && this.data.title && this.data.title === "Update") {
        if (this['layoutNode'].name == "BusinessName") {
          this.titleContent = "Business Name";
          this.baFlag = true;
          this.customField = this.data.dialogform._cell.row.data.accountName;
        }
        if (this['layoutNode'].name == "Attention") {
          this.titleContent = "Attention";
          this.baFlag = false;
          this.customField = this.data.dialogform._cell.row.data.attn;
        }
        if (this['layoutNode'].name == "Address2") {
          this.titleContent = "Neighborhood / Suburb";
          if (this.sharedData && this.sharedData.account && this.sharedData.account.address &&
            this.sharedData.account.address.country === 'NZ') {
            this.baFlag = false;
            this.disabledstreetaddress2 = false;
          } else {
            this.disabledstreetaddress2 = true;
          }
        }
        if (this['layoutNode'].name == "Address1") {
          this.titleContent = "Street Address";
          this.baFlag = true;
          this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
            this.customField = data[0];

          }, err => {
            console.log(err)
          }, () => {
            //  console.log('Completed Address1 update!!!!!')
          });
          this.customField = this.data.dialogform._cell.row.data.address ? this.data.dialogform._cell.row.data.address : this.customField;
        }
        if (this['layoutNode'].name === "City") {
          this.titleContent = "City";
          this.baFlag = true;
          this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
            this.customField = data[1];

          }, err => {
            console.log(err)
          }, () => {
            //  console.log('Completed City update!!!!!')
          });
          this.customField = this.data.dialogform._cell.row.data.city ? this.data.dialogform._cell.row.data.city : this.customField;
        }
        if (this['layoutNode'].name === "Zip") {
          this.titleContent = "Zip";
          this.baFlag = true;
          this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
            this.customField = data[3];

          }, err => {
            console.log(err)
          }, () => {
            //  console.log('Completed zip update!!!!!')
          });
          this.customField = this.data.dialogform._cell.row.data.zip ? this.data.dialogform._cell.row.data.zip : this.customField;
        }
      } else {
        if (this['layoutNode'].name == "BusinessName") {
          this.titleContent = "Business Name";
          this.baFlag = true;
          this.customField = this.sharedData.account.name;
        } if (this['layoutNode'].name == "Attention") {
          this.titleContent = "Attention";
          this.baFlag = false;
          this.customField = '';
        }
        if (this['layoutNode'].name == "Address2") {
          this.titleContent = "Neighborhood / Suburb";
          if (this.sharedData && this.sharedData.account && this.sharedData.account.address &&
            this.sharedData.account.address.country === 'NZ') {
            this.baFlag = false;
            this.disabledstreetaddress2 = false;
          } else {
            this.disabledstreetaddress2 = true;
          }
        }
        if (this['layoutNode'].name == "Address1") {
          this.titleContent = "Street Address";
          this.baFlag = true;
          this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
            if (data && !(data == "Yes" || data == "No")) {
              this.customField = data[0];
            }

          }, err => {
            console.log(err)
          }, () => {
            //  console.log('Completed Address1 create!!!!!')
          });
          this.customField = this.customField ? this.customField : this.sharedData.account.address.street1;
        }
        if (this['layoutNode'].name === "City") {
          this.titleContent = "City";
          this.baFlag = true;
          this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
            if (data && !(data == "Yes" || data == "No")) {
              this.customField = data[1];
            }

          }, err => {
            console.log(err)
          }, () => {
            //  console.log('Completed city create!!!!!')
          });
          this.customField = this.customField ? this.customField : this.sharedData.account.address.city;
        }
        if (this['layoutNode'].name === "Zip") {
          this.titleContent = "Zip";
          this.baFlag = true;
          this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
            if (data && !(data == "Yes" || data == "No")) {
              this.customField = data[3];
            }
          }, err => {
            console.log(err)
          }, () => {
            //  console.log('Completed zip create!!!!!')
          });
          this.customField = this.customField ? this.customField : this.sharedData.account.address.zip;
        }
      }
    });
  }
  sendValue(args) {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
    if (this['layoutNode'].name === "BusinessName") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        upadatedName.account.name = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        upadatedName.account.name = args;
        upadatedName.account['updatedBillName'] = args;
        this.invalid = false;
      }
    }
    
    if (this['layoutNode'].name == "Attention") {
      if (args != undefined && args != null && args.length > 200) {
        upadatedName.account['attn'] = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        upadatedName.account['attn'] = args;
      }
    }
    if (this['layoutNode'].name === "Address1") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        upadatedName.address.street1 = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        upadatedName.account.address.street1 = args;
        this.invalid = false;
      }
    }
    if (this['layoutNode'].name == "Address2") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        upadatedName.address.street2 = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        if(args) {
          upadatedName.account.address.street2 = args;
        } else {
          upadatedName.account.address.street2 = '';
        }
        this.invalid = false;
      }
    }
    if (this['layoutNode'].name === "City") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        upadatedName.address.city = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        upadatedName.account.address.city = args;
        this.invalid = false;
      }


    }
    if (this['layoutNode'].name === "Zip") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        upadatedName.address.zip = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        upadatedName.account.address.zip = args;
        this.invalid = false;
      }

    }
    this.indicatorStore.update((state) => {
      return {
        indicator: upadatedName,
        updatedFrom: "[BillingstringComponent] sendValue",
        isloaded: true,
      };
    });
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
