import { Component, OnDestroy, OnInit } from '@angular/core';
import { FooterService } from '../footer/footer.service';
import { Subscription } from 'rxjs';
import { CounterService } from '@app/service/counter/counter.service';
import { BillingAccountsComponent } from 'app/modules/billing-accounts/billing-accounts.component';
import { BillingService } from '@app/service/billing/billing.service';
import { IndicatorQuery } from 'app/state/query';
import * as JSZip from 'jszip';
import * as JSZipUtils from '../../../../assets/scripts/jszip-utils.js';
import { LoggerService } from "../../../core/service/logger/logger.service";
import { ParamsService } from '@app/service/params/params.service';
import { Router } from '@angular/router';
import { ItemService } from '@app/service/item/item.service';
import { ItemsaveService } from 'app/core/service/itemsave/itemsave.service';
import { CallserviceService } from '../call-new/callservice.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CallconfirmationComponent } from '../call-new/callconfirmation/callconfirmation.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManagecopysheetService } from '@app/service/managecopysheet/managecopysheet.service';
import { Constants } from '@app/service/datasource/constants';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { IndicatorStore } from 'app/state/store';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IagroupHelperService } from '@app/service/iagroup-helper/iagroup-helper.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { CopyService } from '@app/service/copy/copy.service';
import { GroupService } from '@app/service/group/group.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public activeTabName: string;
  public activeView: string;
  public isData: boolean;
  billingForm: boolean = false;
  isdisabled: boolean = false;
  subscription: Subscription;
  indicatorValue: any;
  detailsArray = [];
  itemDesc: string;
  wordsArray: any;
  itemDescFinal: string;
  folderName: string;
  hasCopysheet: boolean;
  itemIds: any;
  indicator: any;
  isAddCallTrackingBlocked: boolean = true;
  isremoveCallTrackingBlocked: boolean = true;
  isNZdisableCT: boolean = true;
  isOrderItemPage: boolean = false;
  itemId: string;
  svItemIds: any;
  copySheetDisabled: boolean = true;
  copysheetAttachment: boolean = true;
  addlInstructions: string;
  spinnerText: string;
  isDownloadCopysheetEnabled: boolean = false;
  showThumbnail: boolean = true;
  thumbnailText: string = "Hide Thumbnail";
  isListingMode: boolean = false;
  splitItemFromIA = false;
  addtnlMaxLength = 1500;
  newActivityLogHistory: any = [];

  constructor(private footerService: FooterService,
    private counterService: CounterService,
    private billing: BillingService,
    private indicatorQuery: IndicatorQuery,
    private logger: LoggerService,
    private params: ParamsService,
    private itemService: ItemService,
    private itemSaveService: ItemsaveService,
    private router: Router,
    private callshared: CallserviceService,
    private spinnerService: NgxSpinnerService,
    private manageCopySheetService: ManagecopysheetService,
    private matDialog: MatDialog,
    private requiredfieldcount: RequiredfieldcountService,
    private indicatorStore: IndicatorStore,
    private spinnerTextService: SpinnerService,
    private constant: Constants,
    private iaGroupHelper: IagroupHelperService,
    private utils: UtilsService,
    private Copysheet: CopysheetService,
    private storageService: StorageService,
    private copyService :CopyService,
    private grouper :GroupService,
    private salesforceService: SalesforceService,

    ) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
    });
    if (this.indicatorValue.account.address.country === 'NZ') {
      this.isNZdisableCT = true;
    } else {
      this.isNZdisableCT = false;
    }
    this.params.params.mode = sessionStorage.getItem("mode");
    this.footerService.tabName.subscribe(result => {
      this.activeTabName = result;
      //console.log("CURRENT PGE>>>", this.activeTabName);
    });
    this.footerService.viewName.subscribe(result => {
      this.activeView = result;
      //console.log("Current view>>>>>", this.activeView);
    });
    this.footerService.availableData.subscribe(result => {
      this.isData = result;
      //console.log("Is empty Grid/List>>>>>",  this.isData);
    });
    this.subscription = this.counterService.isUpdate().subscribe(show => {
      this.isdisabled = show;
    });

    if (this.params.params.mode == 'listing') {
      this.isListingMode = true;
    }
    this.counterService.getItem().subscribe(items => {
      this.itemIds = items;
    });
    this.footerService.getCalltracking().subscribe(result => {
      this.indicatorQuery.getIndicator().subscribe((res) => {
        this.indicatorValue = { ...res };
      });
      this.itemId = result;
      if (result) {
        let productCode = (this.indicatorValue.items[result] && this.indicatorValue.items[result].order) ? this.indicatorValue.items[result].order.productCode : '';
        this.isAddCallTrackingBlocked = false;
        if (this.indicatorValue.items[result] && this.indicatorValue.items[result].calltracking.length > 0) {
          this.isremoveCallTrackingBlocked = false;
        }
        if (this.indicatorValue.items[result].calltracking.length === 0) {
          this.isremoveCallTrackingBlocked = true;
        }
        if (this.indicatorValue.items[result].order.statusCode >= 6000) {
          this.isAddCallTrackingBlocked = true;
          this.isremoveCallTrackingBlocked = true;
        }
        if ((productCode === 'Single IYP' || productCode === 'YPC') && (this.indicatorValue.items[result] && this.indicatorValue.items[result].calltracking.length > 0)) {
          this.isAddCallTrackingBlocked = true;
          if (this.indicatorValue.items[result] && this.indicatorValue.items[result].order.actionCode === "Renew") {
            this.isAddCallTrackingBlocked = true;
            this.isremoveCallTrackingBlocked = true;
          }
        }
        if (productCode === 'Single IYP' && this.indicatorValue.items[result] && this.indicatorValue.items[result].order.actionCode === "Renew") {
          this.isAddCallTrackingBlocked = true;
          this.isremoveCallTrackingBlocked = true;
        }
        if (productCode === "SEM" || productCode === "SEMP" || productCode === "SEM Kicker") {
          if (this.indicatorValue.items[result].data.products[0] && this.indicatorValue.items[result].data.products[0].CallTrackingEnabled && this.indicatorValue.items[result].data.products[0].CallTrackingEnabled.toLowerCase() == "true") {
            this.isAddCallTrackingBlocked = true;
            this.isremoveCallTrackingBlocked = true;
          }
        }

      } else {
        this.isAddCallTrackingBlocked = true;
        this.isremoveCallTrackingBlocked = true;
      }
    });
    this.footerService.getSeletedItems().subscribe(result => {
      this.svItemIds = result;
      if (result.length == 0) {
        this.copySheetDisabled = true;
        this.copysheetAttachment = true;
      } else {
        if (result && result.length == 1) {
          this.copySheetDisabled = false;
          this.copysheetAttachment = false;
          this.addlInstructions = this.indicatorValue.items[result[0]].copy.instructions;

        }
        if (result && result.length > 1) {
          this.copySheetDisabled = true;
          this.copysheetAttachment = false;
        }
      }
    });
    this.checkIfCopysheetAvailable();
  }
  private checkIfCopysheetAvailable() {
    if (this.indicatorValue && this.indicatorValue.items) {
      for (let i in this.indicatorValue.items) {
        if (this.indicatorValue.items[i].hasCopysheet) {
          this.isDownloadCopysheetEnabled = true;
          break;
        }
      }
    }
  }
  openDialog() {
    this.footerService.matDialogFunction(null, null, "CREATE-BILLING-ACCOUNT-BILLINGPAGE").subscribe((data) => {
      let billingObj = new BillingAccountsComponent(this.footerService, this.indicatorQuery, this.billing, this.spinnerService, this.utils);
      billingObj.ngOnInit()
    });
  }

  createNewBP() {
    let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    x["isCopyBP"] = "false";
    this.indicatorStore.update(state => {
      return {
        indicator: x,
        updatedFrom: "[FooterComponent] CreateNewBP",
        isloaded: true
      }
    });
    this.router.navigate(['/business/business/0']);
  }

  toggleThumbnail() {
    let displayVal = 'inline-block';
    this.showThumbnail = !this.showThumbnail;
    if (this.showThumbnail) {
      this.thumbnailText = "Hide Thumbnail";
      displayVal = 'inline-block'
    } else {
      this.thumbnailText = "Show Thumbnail";
      displayVal = 'none';
    }
    let elem = document.getElementsByClassName("parentimage") as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < document.getElementsByClassName("parentimage").length; i++) {
      elem[i].style.display = displayVal;
    }
  }

  downloadCopysheets(flag: string) {
    let selectedItemsId = this.svItemIds;
    this.spinnerTextService.setSpinnerText('');
    this.spinnerService.show();
    let count = 0;
    const zip = new JSZip();

    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
    });

    this.folderName = this.indicatorValue.account.name;
    if (flag === 'selectedcs' && selectedItemsId && selectedItemsId.length > 0) {
      for (let i = 0; i < selectedItemsId.length; i++) {
        let currentItemVal = selectedItemsId[i];
        if (this.indicatorValue.items[currentItemVal].hasCopysheet) {
          this.hasCopysheet = this.indicatorValue.items[currentItemVal].hasCopysheet;
          if (this.hasCopysheet === false) {
            //console.log(this.hasCopysheet);
          } else {
            if (this.indicatorValue.items[currentItemVal].copy.copysheet) {
              this.itemDesc = this.indicatorValue.items[currentItemVal].order.description;
              this.wordsArray = this.itemDesc.split(" ");
              this.itemDescFinal = this.wordsArray[0] + " " + this.wordsArray[1];
              let subName = this.indicatorValue.account && this.indicatorValue.account.name ? this.indicatorValue.account.name.substring(0, 10) + '-' : '';
              let directoryName = this.indicatorValue.items[currentItemVal].order.directoryNumber ? this.indicatorValue.items[currentItemVal].order.directoryNumber + '-' : '';
              let heading = this.indicatorValue.items[currentItemVal].order.heading ? this.indicatorValue.items[currentItemVal].order.heading.substring(0, 15) + '-' : '';
              let descFinal = this.itemDescFinal ? this.itemDescFinal.replace(/\//g, "-") + '*' : '';
              let copysheet = this.indicatorValue.items[currentItemVal].copy && this.indicatorValue.items[currentItemVal].copy.copysheet ? this.indicatorValue.items[currentItemVal].copy.copysheet : '';
              this.detailsArray.push(subName + directoryName + heading + descFinal + copysheet);
            }
          }
        }
      }
    } else {
      for (let i in this.indicatorValue.items) {
        if (this.indicatorValue.items[i].hasCopysheet) {
          this.hasCopysheet = this.indicatorValue.items[i].hasCopysheet;
          if (this.hasCopysheet === false) {
            //console.log(this.hasCopysheet);
          } else {
            if (this.indicatorValue.items[i].copy.copysheet) {
              this.itemDesc = this.indicatorValue.items[i].order.description;
              this.wordsArray = this.itemDesc.split(" ");
              this.itemDescFinal = this.wordsArray[0] + " " + this.wordsArray[1];
              let heading = (this.indicatorValue.items[i].order && this.indicatorValue.items[i].order.heading) ? this.indicatorValue.items[i].order.heading.substring(0, 15) : '';
              let accName = (this.indicatorValue.account && this.indicatorValue.account.name) ? this.indicatorValue.account.name.substring(0, 10) : '';
              let dirNumber = (this.indicatorValue.items[i].order.directoryNumber) ? this.indicatorValue.items[i].order.directoryNumber : '';
              let descFinal = (this.itemDescFinal) ? this.itemDescFinal.replace(/\//g, "-") : '';
              let copysheet = this.indicatorValue.items[i].copy && this.indicatorValue.items[i].copy.copysheet ? this.indicatorValue.items[i].copy.copysheet : '';
              this.detailsArray.push(accName + "-" + dirNumber + "-" + heading + "-" + descFinal + "*" + copysheet);
            }
          }
        }
      }
    }

    this.detailsArray.forEach((details) => {

      const csname = details.substring(0, details.indexOf('*')) + "." + details.split('.').pop();
      const csurl = details.substring(details.indexOf("*") + 1);

      JSZipUtils.getBinaryContent(csurl, (err, data) => {
        if (err) {
          throw err;
        }

        zip.file(csname, data, { binary: true });
        count++;

        if (count === this.detailsArray.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            const objectUrl: string = URL.createObjectURL(content);
            const link: any = document.createElement('a');

            link.download = this.folderName + "-Copysheets.zip"
            link.href = objectUrl;
            link.click();
            this.spinnerService.hide();
          });
        }
      });
    });
    if (this.detailsArray.length === 0) {
      this.spinnerService.hide();
      this.logger.logError("No Copysheet to download");
    }
  }

  saveData() {
    this.spinnerService.show();
    this.logger.clearErrorToast();
    this.isOrderItemPage = true;
    this.spinnerTextService.setSpinnerText('');
    this.footerService.SpinnerText = this.constant.ITEM_SAVE;
    this.spinnerTextService.setSpinnerText(this.constant.ITEM_SAVE);
    let indicator = null;
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      indicator = { ...res };
    });
    let tempIndicator = JSON.parse(JSON.stringify(indicator));
    let multipleItemsUpdate = []
    this.itemIds.forEach(async (id, index) => {
      //Enabling the save functionality for upload copysheet FUL-38251
      if (tempIndicator.items[id].model.ManageCopysheet && typeof tempIndicator.items[id].model.ManageCopysheet === "object") {
        tempIndicator.items[id].model.ManageCopysheet.manageCopyModified = false;
      } else {
        tempIndicator.items[id].model.manageCopyModified = false;
      }
      tempIndicator.items[id]['isCopySheetUploaded'] = false;
      tempIndicator.items[id]['isCoysheetAnnotated'] = false;
      this.indicatorStore.update(state => {
        return {
          indicator: tempIndicator,
          updatedFrom: "[FooterService] saveData()"
        }
      })
      this.indicatorQuery.getIndicator().subscribe(data => {
        tempIndicator = JSON.parse(JSON.stringify(data));
      });
      if (indicator.items[id].counter > 0) {
        if (indicator.items[id].order.productType == 'Print') {
          if (!indicator.items[id].model.BillingAccount || !(indicator.items[id].model.BusinessProfile || indicator.items[id].model.ListingId)) {
            this.logger.logError(`Please select Billing Account and Business Profile/Listing for Item ${id} to continue`);
            this.spinnerService.hide();
            return;
          }
          multipleItemsUpdate.push(id)
        } else {
          this.logger.logError(`Item ${id} has required fields that need to be completed`);
        }
        if (index == this.itemIds.length - 1) {
          if (multipleItemsUpdate != undefined && multipleItemsUpdate.length > 0) {
            await this.itemSaveService.update(tempIndicator, multipleItemsUpdate)
              .then(success => {
                this.spinnerService.hide();
                this.isOrderItemPage = false;
              }
                , failure => {
                  this.spinnerService.hide();
                  this.isOrderItemPage = false;
                })
          } else {
            this.spinnerService.hide();
            this.isOrderItemPage = false;
          }
        }
      } else {
        let page = null;
        //console.log(this.activeTabName);
        if (this.activeTabName == "0") {
          page = "orderGrid";
        }
        if (indicator.items[id].order.productType == 'Print') {
          multipleItemsUpdate.push(id)
        }
        else {
          await this.itemService.update(
            tempIndicator.account,
            tempIndicator.order,
            tempIndicator.items[id],
            tempIndicator.listing,
            id,
            page).then(success => {
              //if (index == this.itemIds.length - 1) {
              //  this.spinnerService.hide();
              //  this.isOrderItemPage = false;
              //}
              if (tempIndicator.items[id].order.statusCode >= 6000
                && tempIndicator.items[id].hasCopysheet
                && tempIndicator.items[id].needsNewCopysheet) {
                  this.sendCopysheetToKGEN(tempIndicator, id)
                }
            }, failure => {
              //if (index == this.itemIds.length - 1) {
              // this.spinnerService.hide();
              //this.isOrderItemPage = false;
              //}
              if (failure && failure.error && failure.error.message) {
                this.logger.logError(failure.error.message);
              }
              //console.log('Item save call failed from order items!!',failure);
            });
        }
        if (index == this.itemIds.length - 1) {
          if (multipleItemsUpdate != undefined && multipleItemsUpdate.length > 0) {

            await this.itemSaveService.update(tempIndicator, multipleItemsUpdate)
              .then(success => {
                this.spinnerService.hide();
                this.isOrderItemPage = false;
                for(let i = 0; i < multipleItemsUpdate.length; i++) {
                  if (tempIndicator.items[multipleItemsUpdate[i]].order.statusCode >= 6000
                    && tempIndicator.items[multipleItemsUpdate[i]].hasCopysheet
                    && tempIndicator.items[multipleItemsUpdate[i]].needsNewCopysheet) {
                      this.sendCopysheetToKGEN(tempIndicator, multipleItemsUpdate[i])
                    }
                }
              }
                , failure => {
                  this.spinnerService.hide();
                  this.isOrderItemPage = false;
                })
          } else {
            this.spinnerService.hide();
            this.isOrderItemPage = false;
          }
        }
      }
      //console.log(id);
    });
    this.counterService.setUpdate(false);
    let navId = document.getElementById('enableDisableLeftNavigationId');
    let tabId = document.getElementById('oredrItemTabId');
    let ViewId = document.getElementById('oredrChangeViewId');
    if (navId) {
      navId.classList.remove('enable-disable-link');
    }
    if (tabId) {
      tabId.classList.remove('enable-disable-link');
    }
    if (ViewId) {
      ViewId.classList.remove('enable-disable-link');
    }
  }

  logObject(itemId, fName, fValue, fOldValue, transactionId?,errorMsg?,apiStatus?,page?) {
    let localItemId = (this.indicatorValue && this.indicatorValue.stateParamsId) ? this.indicatorValue.stateParamsId : itemId
    this.newActivityLogHistory.push({
      'displayName': this.indicatorValue['user']['name'],
      'email': this.indicatorValue['user']['email'],
      'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
      'itemId': localItemId,
      'itemStatus': this.indicatorValue && this.indicatorValue.items && this.indicatorValue.items[localItemId] && this.indicatorValue.items[localItemId].order && this.indicatorValue.items[localItemId].order.status ? this.indicatorValue.items[localItemId].order.status : "" ,
      'itemActionCode': this.indicatorValue.items[localItemId].order.actionCode + "-" + this.indicatorValue.items[localItemId].order.actionType,
      'fieldName': fName,
      'fieldOldValue': fOldValue,
      'fieldNewValue': fValue,
      'transactionId':transactionId,
      'errorMsg':errorMsg,
      'apiStatus': apiStatus,
      'page': page
    });
  }

  sendCopysheetToKGEN(tempIndicator, selectedItemId) {
    let item = tempIndicator.items[selectedItemId];
    let customerId = tempIndicator.account.kgenCustomerId;

    let adVersionIncIndflg;
    this.storageService.get("item", item.order.svItemId).then(data => {
      adVersionIncIndflg = data[item.order.svItemId].adVersionIncInd;
      console.log("adVersionIncIndflg:", data)
    })
    this.copyService.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
      .then((value) => {
        this.spinnerService.show();
        this.copyService.generateCompiled(item.order.svItemId + "v" + item.order.version, tempIndicator)
          .then((updateCopyResp) => {
            this.storageService.updateField("copyz", item.order.svItemId + "v" + item.order.version, { "kgenurl": updateCopyResp.url })
              .subscribe(() => { }, err => { })

            let data = {
              "type": "uploadCopySheet",
              "id": "",
              "callerId": "UCC",
              "correlationId": this.grouper.getAllItemsOfGroupButNotSelf(item.order.svItemId, tempIndicator).toString(),
              "sourceId": "",
              "copySheet": {
                "customerId": customerId,
                "svItemId": item.order.svItemId,
                "productCode": item.order.directoryNumber,
                "productIssueNum": item.order.directoryIssueNumber,
                //"adVersionIncInd": "Y", // Y when order status is greater than 6000, increases copysheet version
                "adVersionIncInd": adVersionIncIndflg ? adVersionIncIndflg : "N", //get adVersionIncInd value from indicator 
                "pdfUrl": updateCopyResp.url,
                "date": this.utils.getKgenDate(new Date()),
                "operatorId": tempIndicator.order.opportunity.owner.operatorId
              }
            };
            this.copyService.uploadCompiled(item.order.svItemId, item.order.sfdcItemId, data)
              .then((copy) => {
                if (copy.hasWarningOrError === 'false') {
                  this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', copy.transactionId, null, "Success", "Item Page");
                } else {
                  this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', copy.transactionId, copy.esbServiceFault.exceptionDetail[0].errorDescription[0], "Failure", "Item Page");
                }
                this.storageService.update("itemHistory", item.order.svItemId, this.newActivityLogHistory);
                //  Params.saving = false;
                //vm.Params.loadingbar = false;
                tempIndicator.items[item.order.svItemId]['KGENCopysheet'] = "Item-Page";
                this.storageService.updateField("copyz", item.order.svItemId + "v" + item.order.version, { "data.KGENCopysheet": "Item-Page" }).subscribe(() => { }, err => { });
                this.indicatorStore.update((state) => {
                  return {
                    indicator: tempIndicator,
                    updatedFrom: "[ItemComponent] uploadcompiled",
                    isloaded: true,
                  }
                });
                //Setting adVersionIncInd flag to "N" 
                this.storageService.updateField("item", item.order.svItemId, { "data.adVersionIncInd": "N" }).subscribe(res => {
                  console.log("adversion set to N for", item.order.svItemId, res)
                });
                if (data.correlationId != null && data.correlationId != "") {
                  let itemArray = data.correlationId.split(",");
                  for (let item of itemArray) {
                    if (item != null && item != "" && item != undefined) {
                      this.storageService.updateField("item", item, { "data.adVersionIncInd": "N" }).subscribe(res => {
                        console.log("adversion set to N for", item, res)
                      });
                    }
                  }
                }
                tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                //update salesforce copysheet URL for all Items of IA Group
                let sfUpdateitems = [];
                for (var itemId in tempIndicator.items) {
                  if (tempIndicator.items[itemId].data.iaGroupId === tempIndicator.items[item.order.svItemId].data.iaGroupId) {
                    let sfItem = {
                      "eItemId": itemId,
                      "SFDCItemId": tempIndicator.items[itemId].order.sfdcItemId,
                      "CACopySheetUrl": updateCopyResp.url,
                      "copySheetStatus": "Success",
                      "systemExceptionReason": " "
                    };
                    sfUpdateitems.push(sfItem);
                  }
                }

                this.salesforceService.saveMultipleSFdata(sfUpdateitems).subscribe((res) => {
                  // if (updateResult.hasWarningOrError == 'false' && !updateResult.servicePending) {
                  //   this.routeToNextItem();
                  // }
                  this.spinnerService.hide();
                }
                  , err => {
                    this.spinnerService.hide();
                    this.logger.logError("<strong>Error in update SFData <strong><br>Source System : Salesforce <br>");
                  });

                //console.log('item end');
              }, err => {
                this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', err.error.response.transactionId, err.error.response.esbServiceFault.exceptionDetail[0].errorDescription);
                this.storageService.update("itemHistory", item.order.svItemId, this.newActivityLogHistory);

                //update salesforce copysheet URL and error for all Items of IA Group
                let sfUpdateitems = [];
                for (var itemId in tempIndicator.items) {
                  if (tempIndicator.items[itemId].data.iaGroupId === tempIndicator.items[item.order.svItemId].data.iaGroupId) {
                    let sfItem = {
                      "eItemId": itemId,
                      "SFDCItemId": tempIndicator.items[itemId].order.sfdcItemId,
                      "systemExceptionReason": JSON.stringify(err),
                      "CACopySheetUrl": updateCopyResp.url,
                      "copySheetStatus": "Failure"
                    };
                    sfUpdateitems.push(sfItem);
                  }
                }

                this.salesforceService.saveMultipleSFdata(sfUpdateitems).subscribe((res) => {

                }, err => {
                  this.logger.logError("<strong>Error in update SFData <strong><br>Source System : Salesforce <br>" + JSON.stringify(err));
                });
                this.spinnerService.hide();
                let errorObject = err.error.response.esbServiceFault;
                let errorCode = errorObject.sourceSystem;
                let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                let errorMsg = "<strong>Transaction : " + err.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);
                // console.log('item end');
                // reject(err);
                // console.log("updateResult : : " + JSON.stringify(updateResult));
              });
          }, err => {
            let errorMessage;
            if (err.error != undefined && err.error != null && err.error.message != undefined && err.error.message != null) {
              errorMessage = err.error.message
            } else {
              errorMessage = JSON.stringify(err);
            }
            this.spinnerService.hide();
            //Params.saving = false;
            //vm.Params.loadingbar = false;
            //fix for FUL-8569 --start
            tempIndicator.items[tempIndicator.stateParamsId].hasCopysheet = false;
            //TODO : If any issue in counter ,we will implement this
            +// tempIndicator.items[tempIndicator.stateParamsId].counter++;
              this.logger.logError("Error in Generate copysheet for item " + tempIndicator.stateParamsId + ".This error may be because of one of the below issues: <br> 1. Image is corrupted.Please remove and add valid Image.try again <br> 2.Please look the error : " + errorMessage);
            //fix for FUL-8569 --end
          });
      }, err => {
        this.spinnerService.hide();
        // Params.saving = false;
        // vm.Params.loadingbar = false;
        this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
      });
  }


  isIAGroupPresent(id) {
    let temIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    let iaGrouppresent = false;
    for (var k in temIndicator.items) {
      if (k !== id && temIndicator.items[id].data.iaGroupId != undefined) {
        if (temIndicator.items[k].data.iaGroupId === temIndicator.items[id].data.iaGroupId) {
          iaGrouppresent = true;
        }
      }
    }
    return iaGrouppresent;
  };
  openCalltracking(data) {
    let itemData = data;
    let id = this.itemId;
    let iaGrouppresent = this.isIAGroupPresent(id);
    if (iaGrouppresent) {
      let msg = "This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.";
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(dialogResult => {
        let result = dialogResult;
        if (result) {
          //console.log('inside IA group is true!!!!-- result is true');
          this.splitItemFromIA = true;
          this.openCalltrackingDialogBox(itemData);
        }
      })
    } else {
      this.openCalltrackingDialogBox(itemData)
    }
  }

  openCalltrackingDialogBox(data) {
    let id = this.itemId;
    let tempCallTracking = false;
    let isMultiCallTracking = true;
    data = data + 'grid';
    this.callshared.openmodel(data).subscribe(callTracking => {
      // console.log("data is:",callTracking);
      if (callTracking) {
        this.isremoveCallTrackingBlocked = false;
        tempCallTracking = true;
        if (this.splitItemFromIA) {
          this.iaGroupHelper.splitIAGroup(id, "splitiagridpage");
          this.splitItemFromIA = false;
        }
      }
      let productCode = this.indicatorValue.items[id] && this.indicatorValue.items[id].order.productCode;
      if (productCode === 'SEM' || productCode === 'SuperClick' || productCode === 'SEMP' || productCode === 'SEM Kicker' && (callTracking && callTracking.length > 0)) {
        let cellTrakingNumber = document.getElementById(id + 'trackNumber') as HTMLInputElement;
        let cellCtDestination = document.getElementById(id + 'ctDestination') as HTMLInputElement;
        isMultiCallTracking = false;
        if (callTracking) {
          for (let i = 0; i < callTracking.length; i++) {
            cellTrakingNumber.innerText = cellTrakingNumber.innerText != 'None' ? cellTrakingNumber.innerText + ',' + this.formatCallTracking(callTracking[i].trackingNumber) : this.formatCallTracking(callTracking[i].trackingNumber);
            cellCtDestination.innerText = cellCtDestination.innerText != 'None' ? cellCtDestination.innerText + ',' + this.formatCallTracking(callTracking[i].terminationNumber) : this.formatCallTracking(callTracking[i].terminationNumber);
          }
        }
      }
      if ((productCode === 'Single IYP' || productCode === 'YPC') && (callTracking && callTracking.trackingNumber && callTracking.terminationNumber)) {
        this.isAddCallTrackingBlocked = true;
      }

      if (tempCallTracking) {
        if (isMultiCallTracking) {
          let cellTrakingNumber = document.getElementById(id + 'trackNumber') as HTMLInputElement;
          let cellCtDestination = document.getElementById(id + 'ctDestination') as HTMLInputElement;
          cellTrakingNumber.innerText = cellTrakingNumber.innerText != 'None' ? cellTrakingNumber.innerText + ',' + this.formatCallTracking(callTracking.trackingNumber) : this.formatCallTracking(callTracking.trackingNumber);
          cellCtDestination.innerText = cellCtDestination.innerText != 'None' ? cellCtDestination.innerText + ',' + this.formatCallTracking(callTracking.terminationNumber) : this.formatCallTracking(callTracking.terminationNumber);
        }
        let temIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        let currentCount = temIndicator["items"][id].counter;
        if (temIndicator["items"][id] && temIndicator["items"][id].order.productType == 'Digital') {
          temIndicator["items"][id].model['CallTracking']['OptIntoCallTracking'] = tempCallTracking;
        }
        if (tempCallTracking && temIndicator["items"][id].model['modelValue'] == 'No') {
          temIndicator["items"][id].model['modelValue'] = 'Yes';
        }
        if (temIndicator.items[id].hasOwnProperty("hasCopysheet")) {
          temIndicator["items"][id]['hasCopysheet'] = false;
        }
        if (temIndicator["items"][id].order.actionCode == 'Renew') {
          if (temIndicator.items[id].hasOwnProperty("hasCopysheet")) {
            temIndicator.items[id].model['renewHasCopysheet'] = temIndicator["items"][id]['hasCopysheet'];
          }
          temIndicator.items[id].model['dirtyFlag'] = true;
        }
        let uniqItemId = temIndicator.stateParamsId + "v" + temIndicator.items[temIndicator.stateParamsId].order.version;
        this.storageService.update(
          "item",
          uniqItemId,
          temIndicator.items[temIndicator.stateParamsId].model
        );
        this.storageService.updateField("call", uniqItemId, { "data.selectedOption": temIndicator["items"][id].model['modelValue'] })
          .subscribe(() => { }, error => {
            //console.log("error is:",error);
          });
        let tempData = this.requiredfieldcount.getNewItemCount(temIndicator["items"][id].requiredFields, temIndicator["items"][id].model, temIndicator["items"][id].order, temIndicator["orderPercent"], temIndicator["items"][id].counter, true, temIndicator["items"][id]);
        this.updateIndictor(id, temIndicator, tempData, currentCount, true);
        if (temIndicator['items'][id].counter == 0) {
          document.getElementById(id + 'counter').classList.remove('item-order-error');
        } else if (temIndicator['items'][id].counter !== 0) {
          document.getElementById(id + 'counter').classList.add('item-order-error');
        }
      }
    });
  }
  openRemoveCalltrackin() {
    let iaGrouppresent = this.isIAGroupPresent(this.itemId);
    if (iaGrouppresent) {
      let msg = "This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.";
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(dialogResult => {
        let result = dialogResult;
        if (result) {
          //console.log('inside IA group is true!!!!-- result is true');
          this.splitItemFromIA = true;
          this.openRemoveCalltrackindialog();
        }
      })
    } else {
      this.openRemoveCalltrackindialog()
    }
  }
  openRemoveCalltrackindialog() {
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.indicator = { ...res };

    });

    let msg = 'Are you sure you want to completely remove all call tracking line(s) for this item?';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'call-tracking-confirmation-dialog';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: msg,
    }
    dialogConfig.width = '600px';
    const dialogRef = this.matDialog.open(CallconfirmationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dialogResult => {
      let result = dialogResult;
      if (result) {
        if (this.splitItemFromIA) {
          this.iaGroupHelper.splitIAGroup(this.itemId, "splitiagridpage");
          this.splitItemFromIA = false;
        }
        // console.log("result",result);
        if (this.indicator.items[this.indicator.stateParamsId].order.productType === "Digital") {
          this.callshared.callTrackingremove();
        } else {
          this.callshared.callTrackingRemovePrint();
          this.callshared.copysheetnotify();
        }
        this.isAddCallTrackingBlocked = false;
        this.isremoveCallTrackingBlocked = true;
        let id = this.itemId;
        let cellTrakingNumber = document.getElementById(id + 'trackNumber') as HTMLInputElement;
        let cellCtDestination = document.getElementById(id + 'ctDestination') as HTMLInputElement;
        cellTrakingNumber.innerText = 'None';
        cellCtDestination.innerText = 'None';
        let temIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        let currentCount = temIndicator["items"][id].counter
        temIndicator.items[temIndicator.stateParamsId].model.CallTracking = [];
        temIndicator.items[temIndicator.stateParamsId].calltracking = [];
        let tempData = this.requiredfieldcount.getNewItemCount(temIndicator["items"][id].requiredFields, temIndicator["items"][id].model, temIndicator["items"][id].order, temIndicator["orderPercent"], temIndicator["items"][id].counter, true, temIndicator["items"][id]);
        this.updateIndictor(id, temIndicator, tempData, currentCount, true);
        temIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        currentCount = temIndicator["items"][id].counter;
        if (temIndicator.items[id].hasOwnProperty("hasCopysheet")) {
          temIndicator["items"][id]['hasCopysheet'] = false;
        }
        if (temIndicator["items"][id].order.actionCode == 'Renew') {
          if (temIndicator.items[id].hasOwnProperty("hasCopysheet")) {
            temIndicator.items[id].model['renewHasCopysheet'] = temIndicator["items"][id]['hasCopysheet'];
          }
          temIndicator.items[id].model['dirtyFlag'] = true;
        }
        tempData = this.requiredfieldcount.getNewItemCount(temIndicator["items"][id].requiredFields, temIndicator["items"][id].model, temIndicator["items"][id].order, temIndicator["orderPercent"], temIndicator["items"][id].counter, true, temIndicator["items"][id]);
        this.updateIndictor(id, temIndicator, tempData, currentCount, true);
        if (temIndicator['items'][id].counter !== 0) {
          document.getElementById(id + 'counter').classList.add('item-order-error');
        }
        let activityLogHistoryObj = {
          'displayName': this.indicator['user']['name'],
          'email': this.indicator['user']['email'],
          'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
          'itemId': this.indicator.stateParamsId,
          'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
          'itemActionCode': this.indicator.items[this.indicator.stateParamsId].order.actionCode + "-" + this.indicator.items[this.indicator.stateParamsId].order.actionType,
          'fieldName': 'Call Tracking',
          'fieldOldValue': 'Yes',
          'fieldNewValue': 'No'
        }
        this.storageService.update("itemHistory", this.indicator.stateParamsId, activityLogHistoryObj);
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  formatCallTracking(trackingNumber) {
    if (trackingNumber) {
      trackingNumber = trackingNumber.replace(/\D/g, "");
      let formattedTrackingNumber = "(" + trackingNumber.substring(0, 3) + ")" + " " + trackingNumber.substring(3, 6) + "-" + trackingNumber.substring(6, 10);
      return formattedTrackingNumber;
    }
    return "None";
  }

  getCopysheet(action) {
    let id = this.svItemIds[0];
    let indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    this.manageCopySheetService.getCopySheetData(action, id, indicator, "COPYSHEET-GENERATE-ORDERITEMPAGE");
    //For Enabling the Save button at grid level when copysheet is annotated
    let items = [];
    items.push(id);
    items && items.length > 0 ? this.counterService.setItem(items) : '';
    this.counterService.setUpdate(true);
  }

  uploadCopysheet() {
    let id = this.svItemIds[0];
    let indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    this.manageCopySheetService.uploadCopysheetData("upload", id, indicator, "COPYSHEET-UPLOAD-ORDERITEMPAGE");
    //For Enabling the Save button at grid level when copysheet is uploaded
    let items = [];
    items.push(id);
    items && items.length > 0 ? this.counterService.setItem(items) : '';
    this.counterService.setUpdate(true);
  }

  openUploadAttachmentDialog() {
    let stateId = this.svItemIds[0];
    this.manageCopySheetService.openUploadAttachmentDialog(stateId, this.indicatorValue, this.svItemIds);
    let items = [];
    items.push(stateId);
    items && items.length > 0 ? this.counterService.setItem(items) : '';
    this.counterService.setUpdate(true);
  }

  openAdBasedDialog() {
    let stateId = this.svItemIds[0];
    this.manageCopySheetService.openAdBasedDialog(stateId, this.indicatorValue);
  }

  // On IA icon click
  openIADialog() {
    let selectedItem = this.svItemIds[0];
    this.footerService.openIADialogbox(selectedItem);
  }


  updateStore() {
    let stateParamsId = this.svItemIds;
    let indicator;
    let isAnyRenewal = false
    this.indicatorQuery.getIndicator().subscribe(data => {
      indicator = JSON.parse(JSON.stringify(data));
    })
    if (this.addlInstructions && this.addlInstructions.length <= this.addtnlMaxLength) {
      this.counterService.setUpdate(true);
      let items = [];
      items.push(stateParamsId[0]);
      items && items.length > 0 ? this.counterService.setItem(items) : '';
      this.counterService.setUpdate(true);
      for (var i = 0; i < stateParamsId.length; i++) {
        let storageID
        storageID = stateParamsId[i] + "v" + indicator.items[stateParamsId[i]].order.version;
        if (indicator.items[stateParamsId[i]].copy) {
          indicator.items[stateParamsId[i]].copy['instructions'] = this.addlInstructions;
        }
        else {
          indicator.items[stateParamsId[i]]['copy'] = {};
          indicator.items[stateParamsId[i]].copy['instructions'] = this.addlInstructions;
        }

        this.Copysheet.updateCopysheet(storageID, indicator.items[stateParamsId[i]].copy)
          .then(() => {
            this.logger.log("Artist Instructions updated Successfully");
          })
          .catch((err) => {
            this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
          });

        //if the item is part of an IA group
        if (indicator.items[stateParamsId[i]].data
          && indicator.items[stateParamsId[i]].data.iaGroupId) {
          //go through the other items on the order
          for (var key in indicator.items) {
            //if the other item has the matching ia group id & isn't the current item
            if (indicator.items[key].data
              && indicator.items[key].data.iaGroupId === indicator.items[stateParamsId[i]].data.iaGroupId
              && indicator.items[key].order.svItemId !== indicator.items[stateParamsId[i]]) {
              //add the copysheet to the other item
              indicator.items[key].copy.instructions = this.addlInstructions;
              if (indicator.items[key].order.statusCode >= 6000) {
                indicator.items[key].needsNewCopysheet = true;
              }
              if (indicator.items[key].order.actionCode === 'Renew') {
                indicator.items[key].model['renewalCopysheetRequiredFlag'] = true
                indicator.items[key].model['dirtyFlag'] = true
                storageID = key + "v" + indicator.items[key].order.version;
                this.copysheetnotify(key, indicator, storageID)
                isAnyRenewal = true
                let tempData = this.requiredfieldcount.getNewItemCount(indicator["items"][key].requiredFields, indicator["items"][key].model, indicator["items"][key].order, indicator["orderPercent"], indicator["items"][key].counter, true, indicator["items"][key]);
                this.storageService.update("item", storageID, indicator.items[key].model).then(result => { });
                this.updateIndictor(key, indicator, tempData, indicator.items[key].counter, false);
              }
              this.Copysheet.updateCopysheet(indicator.items[key].order.svItemId + "v" + indicator.items[key].order.version, indicator.items[key].copy)
                .then(function () { })
                .catch(function (err) {
                  this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                });
            }
          }
        }

        if (indicator.items[stateParamsId[i]].order.statusCode >= 6000) {
          indicator.items[stateParamsId[i]].needsNewCopysheet = true;
        }

        if (indicator.items[stateParamsId[i]].order.actionCode === 'Renew') {
          indicator.items[stateParamsId[i]].model['renewalCopysheetRequiredFlag'] = true
          indicator.items[stateParamsId[i]].model['dirtyFlag'] = true
          this.copysheetnotify(stateParamsId[i], indicator, storageID)
          isAnyRenewal = true
          let tempData = this.requiredfieldcount.getNewItemCount(indicator["items"][stateParamsId[i]].requiredFields, indicator["items"][stateParamsId[i]].model, indicator["items"][stateParamsId[i]].order, indicator["orderPercent"], indicator["items"][stateParamsId[i]].counter, true, indicator["items"][stateParamsId[i]]);
          this.storageService.update("item", storageID, indicator.items[stateParamsId[i]].model).then(result => { });
          this.updateIndictor(stateParamsId[i], indicator, tempData, indicator.items[stateParamsId[i]].counter, false);
        }
      }

      /*if(isAnyRenewal){
        CopySheetComponent.reTrigger();
      }*/
      this.indicatorStore.setLoading(true);
      this.indicatorStore.update(() => {
        return {
          indicator: indicator,
          updatedFrom: "[FooterComponent] updateStore",
          isloaded: true,
        };
      });
      this.indicatorStore.setLoading(false);
      //this.manageCopySheetService.updateStore(this.addlInstructions, stateParamsId[i], this.indicatorValue);
    }
  }

  copysheetnotify(stateParamsId, indicator, storageID) {
    indicator.items[stateParamsId].hasCopysheet = false;
    this.storageService.updateField("copyz", storageID, { "data.hasCopysheet": false })
      .subscribe(() => { }, err => { })
    if (indicator.items[stateParamsId].order.productCode == 'YPC' || indicator.items[stateParamsId].order.productType == "Print") {
      if (indicator.items[stateParamsId].order.productCode == 'YPC') {
        indicator.items[stateParamsId].model.ManageCopysheet = 'Y';
      } else {
        indicator.items[stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
      }
      indicator.items[stateParamsId].requiresCopysheet = true;
    }
  }

  private updateIndictor(itemId, data, tempIndicator, currentCount, updateStoreFlag) {
    let counter = [];
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    if (updateStoreFlag) {
      this.updatCounterStore(data);
    }
  }
  private updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[FooterComponent] updatCounterStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);

  }

  private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
  }
  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }
  exportData() {
    //console.log('******************** inside export data');
  }

}
