import { Component, OnInit } from '@angular/core';
import { LoggerService } from '@app/service/logger/logger.service';
import { LogoService } from '@app/service/logo/logo.service';
import { ParamsService } from '@app/service/params/params.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@app/service/datasource/constants';
import { SharedService } from '@app/service/shared/shared.service';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';


@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  sharedData: any;
  photoSubscription: any;
  closeResult: string;
  browseFiles = [];
  deletedLogoArray = [];
  configObj = {}
  listingId: string = ''
  spcuid: string = ''
  tabSelected: string = ''
  fileItem: FileItem
  uploader: FileUploader;
  mode: string = ''
  isOrderBPMode: boolean = false;
  logoSpinner: boolean = false;
  options: any;
  disableSave: boolean = false;
  constructor(private modalService: NgbModal,
    private _logoService: LogoService,
    private Logger: LoggerService,
    private spinnerService: NgxSpinnerService,
    private photoService: PhotoService,
    private Params: ParamsService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private constants: Constants,
    private spinnerTextService: SpinnerService,
    private Shared: SharedService) {
  }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      this.listingId = this.sharedData.stateParamsId;
      this.spcuid = this.sharedData['user']["Id"].substring(0, 15);
    })
    if (this.listingId === '') this.isOrderBPMode = true;
    else this.isOrderBPMode = false;
    this.photoSubscription = this.photoService.getBehaviorView().subscribe(result => {
      this.deletedLogoArray = result.deleteLogoarrObj;
      this.configObj = result.configObj;
      this.fileItem = result.fileitemObj;
      this.uploader = result.uploaderObj;
    })
    this.disableSave = false;
  }


  onTabChange(event) {
    console.log(event.nextId);
    switch (event.nextId) {
      case "logos": {
        this.disableSave = false;
        break;
      }
      case "upload": {
        this.disableSave = true;
        break;
      }
    }
  }

  onAdditionRemoval(event) {
    this.disableSave = event;
  }

  open(content) {
    this.tabSelected = 'Logos'
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: "logo-modal", backdrop: "static" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit() {
    this.sharedData.loadingText = "Saving logos..."
    this.Shared.spinnerText = this.constants.BP_UPLOAD_LOGO;
    // this.indicatorStore.update(state => {
    //   return {
    //     indicator: this.sharedData
    //   }
    // });
    for (var i = 0; i < this.deletedLogoArray.length; i++) {
      this.logoSpinner = true;
      this.spinnerTextService.setSpinnerText('');
      this.spinnerService.show();
      this.spinnerTextService.setSpinnerText(this.Shared.spinnerText);
      this._logoService.delete(this.deletedLogoArray[i], "Y").subscribe(res => {
        if (res.message == "Deletion request sent") {
          this.Logger.logSuccess("Message: Your LOGO is deleted now ");
          this.deletedLogoArray.splice(0, this.deletedLogoArray.length)
          this.spinnerService.hide();
          this.logoSpinner = false;
        }
      }, error => {
        this.spinnerService.hide();
        this.logoSpinner = false;
      });
    }
    if (this.configObj && this.configObj["uploadedFile"]) {
      if (this.configObj["uploadedFile"].Logo != null) {

        var file1 = this.configObj["uploadedFile"].Logo + "";
        var fileId = file1.substr(file1.substr(0, file1.lastIndexOf("/")).lastIndexOf("/") + 1, 24);

        var data = {
          "listingid": this.listingId,
          "SPCUID": this.spcuid,
          "imgTyp": "LOGO",
          "file1": file1,
          "fileId": fileId
        };
        this.logoSpinner = true;
        this.spinnerTextService.setSpinnerText('');
        this.spinnerService.show();
        this.spinnerTextService.setSpinnerText(this.Shared.spinnerText);
        this._logoService.uploadLogo(this.listingId, data).subscribe(res => {
          this._logoService.getuploadStatus(res.varRand).subscribe(status => {
            var localStatus = status.response.status[0];

            if (res.uploadStatus == 200 && (localStatus === "PUB" || localStatus === "APP" || localStatus === "ICAREPUB")) {
              this.Logger.logSuccess("Infohub Status Code: " + status.response.status[0] + "</br>Infohub Message:  Image is Online " + "</br>If this Business Profile has an ESS Plus or Premium, photos will be sent to Yelp in 2 weeks ");
              this.spinnerService.hide();
              this.logoSpinner = false;
            }
            else if (res.uploadStatus == 200) {
              this.Logger.logWarning("Infohub Status Code: " + status.response.status[0] + "</br>Infohub Message: " + status.response.message[0]);
              this.spinnerService.hide();
              this.logoSpinner = false;
            } else if (res.uploadStatus == 500) {
              this.Logger.logError("Status Code: 500" + "</br>Message: please try again ");
              this.spinnerService.hide();
              this.logoSpinner = false;
            }
            this.configObj["uploadedFile"] = {}
          }, error => {
            this.spinnerService.hide();
            this.logoSpinner = false;
          });
        }, error => {
          this.spinnerService.hide();
          this.logoSpinner = false;
        });
        this.fileItem.remove();
      }
    } else {
      if (this.uploader)
        this.uploader.clearQueue();
    }
  }

  onCancel() {
    if (this.fileItem && this.fileItem.isUploading)
      this.fileItem.remove();
    if (this.uploader)
      this.uploader.clearQueue();
    this.disableSave = false;
  }

  ngOnDestroy() {
    this.photoSubscription.unsubscribe();
    this.logoSpinner = false;
  }
}
