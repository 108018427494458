import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ParamsService} from "../params/params.service";
import { ApiService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUri:string = '/api/user';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient,private Params : ParamsService,private API:ApiService) { }

  get():Promise<any> {
    let url = this.baseUri;
    return this.http.get(this.API.get(url)).toPromise();
  }
  saveUser():Promise<any> {
    let url = this.baseUri;
    return this.http.post(this.API.get(url),{}).toPromise();
  }

  getSessionId():Promise<any> {
    let url = '/api/auth/salesforce/signed';
    return this.http.get(this.API.get(url)).toPromise();
  }

}
