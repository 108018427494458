import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ParamsService } from "../params/params.service";
import { AppConfigsService } from 'app/app.configs';
import { environment } from '../../../../environments/environment';
import { NodeApiEnvUrlService } from '../nodeApiEnvUrl/node-api-env-url.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  apiUrl: string = "https://ucc-pm.dexmedia.com";
  pdfUrl: string = null;
  constructor( private appConfigsService: AppConfigsService, private nodeApiEnvUrlService : NodeApiEnvUrlService) {
    this.apiUrl = nodeApiEnvUrlService.getNodeUrl("api");
    this.pdfUrl = nodeApiEnvUrlService.getNodeUrl("pdfwindow");
   }
  current: any = 0;
  endpoints: any;
  // Error handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  /**
 * [sleep description]
 * @param  {[type]} milliseconds [description]
 * @return {[type]}              [description]
 */
  sleep(milliseconds: any): void {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  };

  /**
   * [this method will return the final path to nodeAPI]
   * @param  {[type]} path [input from different services]
   * @return {[type]}      [return back to services]
   */
  get(path: string): string {
    this.endpoints = this.apiUrl ;//environment.apiEndpointUrl;
    while (typeof this.endpoints === "undefined") {
      this.sleep(100);
      console.log("60", window.location)
      if (window.location && window.location.host == "localhost") {
        this.endpoints = "https://localhost:3002"
      }else if(window.location && window.location.host == "ucc-pm.dexmedia.com"){
        this.endpoints = "https://ucc-pm.dexmedia.com"
      }else if(window.location && window.location.host == "ucc-cfit.dexmedia.com"){
        this.endpoints = "https://ucc-cfit.dexmedia.com"
      }else if(window.location && window.location.host == "ucc-ppt.dexmedia.com"){
        this.endpoints = "https://ucc-ppt.dexmedia.com"
      }else if(window.location && window.location.host == "ucc-train.dexmedia.com"){
        this.endpoints = "https://ucc-train.dexmedia.com"
      }else if(window.location && window.location.host == "ucc.dexmedia.com"){
        this.endpoints = "https://ucc.dexmedia.com"
      }else if(window.location && window.location.host == "ucc-dev.dexmedia.com"){
        this.endpoints = "https://ucc-dev.dexmedia.com"
      }
      else {
        this.endpoints = environment.apiEndpointUrl;
      }
    }
    if (this.endpoints instanceof Array) {
      this.current = (this.current === (this.endpoints.length - 1)) ? 0 : this.current + 1;
      return this.endpoints[this.current] + path;
    } else {
      return this.endpoints + path;
    }
  };


  delete(path: string): string {
    var endpoints = this.appConfigsService.configs.apiEndpoint;

    while (typeof endpoints === "undefined") {
      this.sleep(100);
      endpoints = this.appConfigsService.configs.apiEndpoint;
    }
    if (this.endpoints instanceof Array) {
      this.current = (this.current === (endpoints.length - 1)) ? 0 : this.current + 1;
      return endpoints[this.current] + path;
    } else {
      return endpoints + path;
    }
  };


}
