import { Injectable } from "@angular/core";
import { ParamsModel } from '../params/params.model';
@Injectable()
export class IndicatorModel{
     params : ParamsModel;
     items : {};
     [key: string]: any;
     profileSchema:{};
     profiles :{};
     getprofiledata :{};
     account :{};
     user:{};
     listingIds:{};
     orders:{};
     order:{};
     primaryContactEmail: any;
     billing: {};
     digitalBilling: any;
     printBilling: any;
     stateParamsId :any;
     listing:[];
     isAlreadyLoaded: boolean = false;
     isMCProfile: boolean;
}
