import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';
import { StorageService } from '../storage/storage.service';
import { CopyService } from '../copy/copy.service';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  baseUri:string = '/api/group';

  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService,
    private StorageService: StorageService,
    private Copysheet: CopyService,
    private logger: LoggerService
  ) { }

  generateGroupID(): Observable<any>{
    return this.http.get(this.API.get(this.baseUri))
  }

  getIAGroupID(itemId, custId, prdctCode): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + '/' + itemId + '/' + custId + '/' + prdctCode);
  }
  
  splitIAItems(data): Observable<any> {
    return this.http.post(this.API.get(this.baseUri) + '/split', data);
  }

  mergeIAItems (data): Observable<any> {
    return this.http.post(this.API.get(this.baseUri) + '/merge', data);
  }

  createGroupObject(orderItemsZ, currentItemZ) {
    let orderItems = orderItemsZ,
    currentItem = currentItemZ.order,
    currentSize = currentItem.size,
    currentListing = currentItem.listingId,
    currentDirectory = currentItem.directoryName,
    currentProductCode = currentItem.productCode,
    currentNumberColumns = currentItem.numberColumns,
    currentId = currentItem.svItemId,
    currentCallTracking = currentItemZ.model.CallTracking,
    storageId = currentId + "v" + currentItem.version;
    
    this.StorageService.getField('iaGroup', storageId).then(data => {

    })
  }

  removeIaGroup(item) {
    item.copy.ad = undefined;
    item.copy.attachments = [];
    item.copy.copysheet = undefined;
    item.copy.displayAdId = undefined;
    item.copy.displayAdIdPrefix = undefined;
    item.copy.graphicRegion = undefined;
    item.copy.instructions = undefined;
    item.model.ManageCopysheet.baseAdId = undefined;
    item.model.ManageCopysheet.baseAdIdPrefix = undefined;
    item.model.ManageCopysheet.copySheetIndicator = "Y";
    item.model.ManageCopysheet.iaGroupId = undefined;
    item.hasCopysheet = false;

    this.Copysheet.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
      .then(response => {
        this.logger.logSuccess('Successfully Updated Copysheet');
      }, error => {
        this.logger.logError('Failed to update copysheet :' +JSON.stringify(error))
      });
    return item;
  }

  removeSetBasedOnAd(item) {
    item.copy.ad = undefined;
    item.copy.displayAdId = undefined;
    item.copy.displayAdIdPrefix = undefined;
    item.copy.graphicRegion = undefined;
    item.copy.headingName = undefined;

    item.model.ManageCopysheet.baseAdId = undefined;
    item.model.ManageCopysheet.baseAdIdPrefix = undefined;
    item.model.ManageCopysheet.copySheetIndicator = "Y";

    this.Copysheet.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
      .then(function (response) {
        this.logger.logSuccess('Successfully Updated Copysheet');
      },err => {
        this.logger.logError('Failed to update copysheet :' + JSON.stringify(err));
      });
    return item;
  }

  getUniqueItemsForCopysheetUpload(itemList) {
    let filtereditemList = [];
    let uniqueIAGroupIdList = [];
    for (let i = 0; i < itemList.length; i++) {

      if (itemList[i].order.productType === "Print" || itemList[i].order.productCode === "YPC") {
        if (itemList[i].data   //new IA group ID
          && itemList[i].data.iaGroupId
          && uniqueIAGroupIdList.indexOf(itemList[i].data.iaGroupId) === -1
        ) {
          uniqueIAGroupIdList.push(itemList[i].data.iaGroupId);

          filtereditemList.push(itemList[i])
        } else if (!itemList[i].data    // if there is no group ID
          || !itemList[i].data.iaGroupId) {
          filtereditemList.push(itemList[i])
        }
      }

    }
    return filtereditemList;
  }

  getAllItemsOfGroup(svItemId, Indicator) {
    let allGroupItems = [];
    for (let item in Indicator.items) {
      if (Indicator.items[item].order.productType === "Print" || Indicator.items[item].order.productCode === "YPC") {
        if (Indicator.items[item].data && Indicator.items[item].data.iaGroupId && Indicator.items[item].data.iaGroupId != "" &&
          Indicator.items[item].data.iaGroupId != null && Indicator.items[item].data.iaGroupId != undefined &&
          (Indicator.items[svItemId].data.iaGroupId === Indicator.items[item].data.iaGroupId)) {
          allGroupItems.push(item);
        }
      }
    }
    return allGroupItems;
  }

  getAllItemsOfGroupButNotSelf(svItemId, Indicator) {
    let allGroupItems = [];
    for (let item in Indicator.items) {
      if (Indicator.items[item].order.productType === "Print" || Indicator.items[item].order.productCode === "YPC") {
        if (Indicator.items[item].data && Indicator.items[item].data.iaGroupId && Indicator.items[item].data.iaGroupId != "" &&
          Indicator.items[item].data.iaGroupId != null && Indicator.items[item].data.iaGroupId != undefined &&
          (Indicator.items[svItemId].data.iaGroupId === Indicator.items[item].data.iaGroupId) && (item !== svItemId)) {
          allGroupItems.push(item);
        }
      }
    }
    return allGroupItems;
  }

}
