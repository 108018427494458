import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Campaigndetails  {
    
  constructor() { }
        
        data = {};
        form = [];
        schema = [];
        menuOrig = [];
        menu = [];

        campaignForm =[];
        businessInfoForm =[];
        geosForm =[];
        catkeysForm =[];


        campaignSchema = {};
        businessInfoSchema = {};
        geosSchema = {};
        catkeysSchema = {};
        model = {};

        type = "";
        zip = "";
        geoArray = [];
        length = 0;
        selectedcampaign = {};

//--------------------- Forms---------------------------//

        setCampaignForm(newObj){
            this.campaignForm = newObj;
        };

        getCampaignForm(){
            return this.campaignForm;
        };

        setBusinessForm(newObj){
            this.businessInfoForm = newObj;
        };

        getBusinessForm(){
            return this.businessInfoForm;
        };

        setGeosForm(newObj){
            this.geosForm = newObj;
        };

        getGeosForm(){
            return this.geosForm;
        };

        setCatkeysForm(newObj){
            this.catkeysForm = newObj;
        };

        getCatkeysForm(){
            return this.catkeysForm;
        };

// --------------------- Schemas-----------------------------//

         setCampaignSchema(newObj){
            this.campaignSchema = newObj;
        };

         getCampaignSchema  (){
            return this.campaignSchema;
        };

         setBusinessSchema(newObj){
            this.businessInfoSchema = newObj;
        };

         getBusinessSchema(){
            return this.businessInfoSchema;
        };

         setGeosSchema(newObj){
            this.geosSchema = newObj;
        };

         getGeosSchema  (){
            return this.geosSchema;
        };

         setCatkeysSchema(newObj){
            this.catkeysSchema = newObj;
        };

         getCatkeysSchema(){
            return this.catkeysSchema;
        };

// -----------------Campaign Modal Type (National/Local)-------------------//
         setType  (newObj){
            this.type = newObj;
        };

         getType(){
            return this.type;
        };

// -----------------Campaign model set details entered by MC--------------//
 
         setModel(newObj){
            this.model = newObj;
        };
 
         getModel  (){
            return this.model;
        };

// -----------------Zip code for Geos--------------//
 
         setZip(newObj){
            this.zip = newObj;
        };
 
         getZip(){
            return this.zip;
        };

// -----------------Campaign GeoList Array--------------//
 
         setCampaignLength(newObj){
            this.length = newObj;
        };

         getCampaignLength  (newObj){
            return this.length;
        };

         setGeoArray(newObj){
            this.geoArray = newObj;
        };
 
         getGeoArray(){
            return this.geoArray;
        };

         clearGeoArray(){
            this.geoArray = [];
        };

// -----------------Selected Campaign--------------//
 
         setSelectedCampaign(newObj){
            this.selectedcampaign = newObj;
        };

         getSelectedCampaign(newObj){
            return this.selectedcampaign;
        };

}
