import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@app/service/utils/utils.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-profiled-directive',
  templateUrl: './profiled-directive.component.html',
  styleUrls: ['./profiled-directive.component.scss']
})
export class ProfiledDirectiveComponent implements OnInit {
  customField  :any={} ;
  invalidUrl : boolean =false;
  Indicator ;
  schemaObj;
  isRequiredField: boolean =false;
  isReadonly : boolean =false;
  titleContent;
  UseBusinessProfileChcked;
  websiteAddress ;
  constructor(private indicatorQuery :IndicatorQuery,
    private utils :UtilsService,
    private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
    });

    this.schemaObj=this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name];
    this.isRequiredField = this.schemaObj.required;

    this.titleContent =  this.schemaObj.title;

    let value;
    if(this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId] && this.Indicator.items[this.Indicator.stateParamsId].model && this.Indicator.items[this.Indicator.stateParamsId].model[this['layoutNode'].name]) {
      value = JSON.parse(JSON.stringify(this.Indicator.items[this.Indicator.stateParamsId].model[this['layoutNode'].name]));
    }else{
      value = "";
    }
    this.customField =value;
    this.UseBusinessProfileChcked = this.customField['UseBusinessProfile'] && this.customField['UseBusinessProfile'] == 'Y'? true :false;
    if(this.customField && this.customField['WebsiteAddress']){
      this.websiteAddress =this.customField['WebsiteAddress'];
      if(!this.utils.validateURL(this.customField['WebsiteAddress'],false)){
          this.invalidUrl = true;
      }else{
          this.invalidUrl = false;
      }
   }
  }


      enableWebsiteAddress(value) {
        let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        this.customField = JSON.parse(JSON.stringify(this.customField));
            if (value){
                this.isReadonly = true;
                this.websiteAddress = "";
                this.UseBusinessProfileChcked = true;
                this.customField['WebsiteAddress'] = this.websiteAddress;
                this.customField['UseBusinessProfile'] = "Y";
                this.invalidUrl = false;
            }else{
              this.UseBusinessProfileChcked = false;
              this.customField['UseBusinessProfile'] = "N";
              this.isReadonly = false;
              if(this.customField['WebsiteAddress']){
                if(!this.utils.validateURL(this.customField['WebsiteAddress'], false)){
                  this.invalidUrl = true;
                } else {
                  this.invalidUrl = false
                }
              } else {
                this.invalidUrl = true;
              }
              }
              this.updateStore(modelData);
        }

        changeWebsite(website){
          let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
          
          // Ensure this.customField is an object
          if (!this.customField || typeof this.customField !== 'object') {
              this.customField = {};
          }
      
        this.customField = JSON.parse(JSON.stringify(this.customField));

          if (!this.utils.validateURL(website, false)) {
              this.invalidUrl = true;
              this.websiteAddress = "";
              this.customField['WebsiteAddress'] = this.websiteAddress;
          } else {
              this.invalidUrl = false;
              this.websiteAddress = website;
              this.customField['WebsiteAddress'] = this.websiteAddress;
          }
          this.updateStore(modelData);
      }      
      updateStore(modelData){
        modelData.items[this.Indicator.stateParamsId].model[this['layoutNode'].name] = this.customField;
        // this.indicatorQuery.updateModel(this['layoutNode'].name, this.customField,modelData,tempObj);
        let x = JSON.parse(JSON.stringify(this.Indicator));
        x = modelData;
        this.indicatorStore.update(state => {
          return {
            indicator: x,
            updatedFrom: "[ProfileDirective] updateStore",
            isloaded: true
          }
        });

      }

}



