import { Component, OnDestroy, OnInit } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignQuery } from 'app/state/campaignquery';
import { Subject } from 'rxjs';
import { ComponentQuery } from 'app/state/component.query';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit, OnDestroy  {

  titleContent: any;
  constructor( private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
               private campaignQuery: CampaignQuery,
               private componentQuery: ComponentQuery) { }

  sharedData;
  customField = '';
  numbers: any = /^[0-9]+$/;
  error: boolean = false;
  id: any;
  errorMsg: any;
  invalid: boolean = false;
  options;
  destroySubject$: Subject<void> = new Subject();

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    if (this['layoutNode'].name == "InBusinessSince") {
      this.id = this['layoutNode'].name
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['InBusinessSince']['title'];
      this.customField = this.sharedData['profiles'][this.sharedData.stateParamsId].model.InBusinessSince;
      if (this.customField != undefined && this.customField != null && this.customField.length > 200) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.customField.length + ' chars), maximum 200'
      } else {
        this.invalid = false;
      }
    }
  }

  sendValue(args) {
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));

    if (this['layoutNode'].name === "InBusinessSince") {
      if (args != undefined && args != null && args.length > 200) {
        this.invalid = true;
        modelData.InBusinessSince = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum 200'
      } else {
        modelData.InBusinessSince = args;
        this.invalid = false;
      }
    }
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;

this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[NumberComponent] sendValue",
        isloaded: true
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
