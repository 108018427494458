import { Injectable, RendererStyleFlags2 } from '@angular/core';
import { promise } from 'protractor';
import { NgxSpinnerService } from 'ngx-spinner';
import { GroupService } from '../group/group.service';
import { StorageService } from '../storage/storage.service';
import { TransformerService } from '../transformer/transformer.service';
import { UtilsService } from '../utils/utils.service';
import { constant } from '../../../data/constants';

//addign indicator store
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { SalesforceService } from '../salesforce/salesforce.service';
import { ProductService } from '../product/product.service';
import { LoggerService } from '../logger/logger.service';
import { CopysheetService } from '../copysheet/copysheet.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ItemService {
    indicator;
    saving;
    productCode;
    upsertMapping1 = {};


    //dirtyFlag: boolean = false;
    //Changed dirty flag to digitalApiFlag and using this flag to make print/digital call.
    digitalApiFlag: boolean = false;
    productCallPending: boolean = false;
    constructor(
        private grouper: GroupService,
        private transformer: TransformerService,
        // private API : ApiService,
        private utils: UtilsService,
        private storageService: StorageService,
        private indicatorQuery: IndicatorQuery,
        private indicatorStore: IndicatorStore,
        private sfService: SalesforceService,
        private productService: ProductService,
        private logger: LoggerService,
        private copysheet: CopysheetService,
        private spinnerService: NgxSpinnerService,
        private router: Router
    ) {
        this.utils.errArray = [];
        this.utils.errorCount = 0;
    }
    async update(account, order, item, listings, svItemId, page): Promise<any> {
        let publishDirtyflag = false;
        return new Promise<[any]>(async (resolve, reject) => {
            item = JSON.parse(JSON.stringify(item));
            this.spinnerService.show();
            this.indicatorQuery.getIndicator().subscribe(async (res) => {
                this.indicator = JSON.parse(JSON.stringify(res));
                if (page == "publish") {
                   // publishDirtyflag = this.indicator.items[svItemId].model.dirtyFlag;
                   publishDirtyflag = this.indicator.items[svItemId].model['digitalApiFlag'];
                }
            });
            let upsertMapping = {};
            this.productCode = item.order.productCode;
            if (item.order.productType === "Digital") {
                upsertMapping = {
                    AuthData: {
                        "apiSource": "CA"
                    },
                    ProductHeader: {},
                    products: [{}]
                };
                if (item.model.hasOwnProperty("Phone")) {
                    item.model["Phone"] = item.model["Phone"].match(/\d+/g).join("");
                }
            } else {
                if (
                    order &&
                    order.opportunity &&
                    order.opportunity.owner &&
                    order.opportunity.owner.operatorId
                ) {
                upsertMapping['products'] = {
                    product: {
                        type: "updateItem",
                        callerId: "UCC",
                        correlationId: this.grouper.getAllItemsOfGroupButNotSelf(svItemId, this.indicator).toString(),
                        sourceId: order.accountId,
                        svOrderId: order.orderId,
                        customerId: account.kgenCustomerId,
                        operatorId: order.opportunity.owner.operatorId,
                        content: [{
                            svItemId: svItemId,
                            copySheetIndicator: "N"
                        }]
                    }
                };
                //11.10.15 trying to track when this happens, if it's happening again on refresh of publish page

            }
        }
            // Find the matching listing
            let key = null;
            let listing = null;
            let itemListingId = null;

            if (item.model.hasOwnProperty("ListingId")) {
                key = "ListingId";
                itemListingId = item.model.ListingId;
            } else if (item.model.hasOwnProperty("BusinessProfile")) {
                key = "BusinessProfile";
                itemListingId = item.model.BusinessProfile;
            }
            if (key != null && listings !== null && listings !== undefined && itemListingId !== null) {
                if (listings instanceof Array) {
                    for (let i = 0; i < listings.length; i++) {
                        if (listings[i].listingId === itemListingId) {
                            listing = listings[i];
                            break;
                        }
                    }
                } else {
                    if (listings.listingId === itemListingId) {
                        listing = listings;
                        // break;
                    }
                }
            }

            if (listing != null) {
                if (item.copy == null) {
                    item.copy = {};
                }
                item.copy.listing = listing;

                if (item.model.hasOwnProperty("LicenseNumber")) {
                    item.copy.licenseNumber = item.model["LicenseNumber"];
                }
                if (item.hasOwnProperty("calltracking")) {
                    if (item.model && item.model.modelValue && item.model.modelValue === "No") {
                        item.copy.callTracking = [];
                    } else {
                        item.copy.callTracking = item.calltracking;
                    }
                }

                try {
                    await this.copysheet.updateCopysheet(svItemId + "v" + item.order.version, item.copy).then(success=>{
                    })  // update copies collection with new data
                } catch (error) {
                    this.logger.logError("Failed to update copysheet :" + JSON.stringify(error));
                }
            }
            if (item.model.modelValue == "No" && item.order.productType == "Digital" && (item.order.productCode === 'YPC' || item.order.productCode === 'Single IYP')) {
                // item.calltracking =  {"OptIntoCallTracking":false}; //commented to resolve FUL-5510
                item.model["CallTracking"] = { "OptIntoCallTracking": false };
            };
            //shouldupload will be false if all mandatory fields are not filled. else will contain the object to be sent to tibco
            let shouldUpload = this.transformer.upsertMapping(upsertMapping, item.form, item.model, "ITEM");
            if (item.order.productType === "Digital" && shouldUpload) {
                if (account.enterpriseAccountId) {
                    upsertMapping['ProductHeader'].enterpriseAcctId = account.enterpriseAccountId;
                } else {
                    this.logger.logError("CA: Salesforce error - account.enterpriseAccountId is null");
                    console.log("Salesforce error - Account is : ", account);
                }

                upsertMapping['ProductHeader'].enterpriseItemId = svItemId;

                // Added svOrderId in the productheader for digital
                if (order.orderId) {
                    upsertMapping['ProductHeader'].enterpriseOrderId = order.orderId;
                } else {
                    this.logger.logError("CA: Salesforce error - order.orderId is null");
                    console.log("Salesforce error - Order is : ", order);
                }

                if (upsertMapping['products'][0].PhoneNumber) {
                    upsertMapping['products'][0].PhoneNumber = upsertMapping['products'][0].PhoneNumber.match(/\d/g).join("");
                }
                if (upsertMapping['products'][0].ContactPhone) {
                    upsertMapping['products'][0].ContactPhone = upsertMapping['products'][0].ContactPhone.match(/\d/g).join("");
                }
                if (upsertMapping['products'][0].Phone) {
                    upsertMapping['products'][0].Phone = upsertMapping['products'][0].Phone.match(/\d/g).join("");
                }
                this.productCode = item.order.productCode;

                if (this.productCode === 'Dex Hub') { this.productCode = 'DEXHUB'; }
                if (this.productCode === 'DPM') { this.productCode = 'LLC'; }

                //US43673 Start
                if ((this.productCode === "SEM" || this.productCode === 'SuperClick' || this.productCode === 'SEMP' || this.productCode === 'SEM Kicker') && item.model.hasOwnProperty('CallTracking')) {
                    upsertMapping['products'][0].CallTrackingCount = item.model.CallTracking.length;
                    if ((item.model.dirtyFlag || item.model['digitalApiFlag']) && item.model.modelValue) {
                        upsertMapping['products'][0].CallTrackingEnabled = item.model.modelValue.toLowerCase() == "yes" ? "true" : "false"; //FUL-20583 Angular 1.5 fix
                    } else if (item.model.CallTracking) {
                        upsertMapping['products'][0].CallTrackingEnabled = item.model.CallTracking.length == 0 ? "false" : "true"
                    }
                }
                if (this.productCode === 'SEMP' || this.productCode === 'SEM') {
                    if (item.model.Contact) {
                        let Contact = item.model.Contact;
                        upsertMapping['products'][0].ContactName = Contact.ContactName;
                        upsertMapping['products'][0].ContactPhone = Contact.ContactPhone.replace(/[^0-9]/g, '');
                        upsertMapping['products'][0].ContactEmail = Contact.ContactEmail;
                    }
                    if (item.model.CampaignType) {
                        for (let campaign of item.model.CampaignType) {
                            if (campaign.CampaignType.toUpperCase() === 'NATIONAL' && campaign.hasOwnProperty("UseBusinessProfile")) {
                                delete campaign.UseBusinessProfile
                            }
                        }
                    }
                }
                //us33906 Start
                if (this.productCode === "SEOG Add On") {
                    upsertMapping['products'][0].BusinessName = item.model.BusinessName;
                    if (item.model.ProposalID) {
                        upsertMapping['products'][0].ProposalID = item.model.ProposalID;
                    }
                }//us33906 End
                //DE16719: Update Content of DBA
                if (this.productCode === 'Display Brand Awareness' && item.model.Contact && item.model.UseBusinessProfile) {
                    let Contact = item.model.Contact;
                    let UseBusinessProfile = item.model.UseBusinessProfile;
                    upsertMapping['products'][0].FirstName = Contact.FirstName;
                    upsertMapping['products'][0].LastName = Contact.LastName;
                    if (Contact.Phone != null) {
                        upsertMapping['products'][0].PhoneNumber = Contact.Phone.match(/\d/g).join("");
                    }
                    upsertMapping['products'][0].Email = Contact.Email;
                    upsertMapping['products'][0].UseBusinessProfile = UseBusinessProfile.UseBusinessProfile;
                    if(UseBusinessProfile.WebsiteAddress){
                        upsertMapping['products'][0].WebsiteAddress = UseBusinessProfile.WebsiteAddress
                    }
                }
                if ((this.productCode === 'DPM' || this.productCode === 'LLC') && item.model.Contact) {
                    let Contact = item.model.Contact;
                    upsertMapping['products'][0].FirstName = Contact.FirstName;
                    upsertMapping['products'][0].LastName = Contact.LastName;
                    upsertMapping['products'][0].ContactPhone = Contact.ContactPhone;
                    upsertMapping['products'][0].ContactEmail = Contact.ContactEmail;
                }
                if (this.productCode === 'SEO') {
                    if (item.model.Contact) {
                        let Contact = item.model.Contact;
                        upsertMapping['products'][0].FirstName = Contact.FirstName;
                        upsertMapping['products'][0].LastName = Contact.LastName;
                        upsertMapping['products'][0].Phone = Contact.Phone.match(/\d/g).join("");
                        upsertMapping['products'][0].EmailID = Contact.EmailID;
                    }
                    if (item.model.WebsiteUrl) {
                        upsertMapping['products'][0]['WebsiteUrlList'] = [];
                        upsertMapping['products'][0]['WebsiteUrlList'].push(item.model.WebsiteUrl);
                    }
                    if (item.model.BusinessCategories) {//PrimaryCatId
                        if (item.model.BusinessCategories.PrimaryCatId) {
                            upsertMapping['products'][0]['PrimaryCatId'] = item.model.BusinessCategories.PrimaryCatId;
                            upsertMapping['products'][0]['PrimaryCatId']['CategoryName'] = item.model.BusinessCategories.PrimaryCatId.categoryName;
                            upsertMapping['products'][0]['PrimaryCatId']['CategoryId'] = item.model.BusinessCategories.PrimaryCatId.categoryId;
                            upsertMapping['products'][0]['PrimaryCatId']['primary'] = "Yes";
                        }
                        if (item.model.BusinessCategories.CategoryList) {
                            upsertMapping['products'][0]["CategoryList"] = item.model.BusinessCategories.CategoryList
                                .map((currentValue, index, array) => {
                                    return {
                                        CategoryId: currentValue.categoryId,
                                        categoryId: currentValue.categoryId,
                                        CategoryName: currentValue.categoryName,
                                        categoryName: currentValue.categoryName,
                                        primary: currentValue.primary
                                    }
                                });

                        }


                    }
                    upsertMapping['products'][0].ProposalID = item.model.ProposalID;
                    upsertMapping['products'][0].ListingCategories = item.model.ListingCategories;


                }
                //DE16719: Update Content of DBA
                upsertMapping['products'][0].productId = this.productCode;
                if (item.model.AccountId) {
                    if (item.model.AccountId.accountId !== 'No AccountID associated with EnterpriseID') {
                        upsertMapping['products'][0].AccountId = item.model.AccountId.accountId;
                    }
                    if (item.model.AccountId.accountName !== 'No Account associated with EnterpriseID') {
                        upsertMapping['products'][0].AccountName = item.model.AccountId.accountName;
                    }

                }
                if (upsertMapping['products'][0].UseBusinessProfile !== undefined && upsertMapping['products'][0].UseBusinessProfile === true) {
                    if (upsertMapping['products'][0].UseBusinessProfile) {
                        upsertMapping['products'][0].UseBusinessProfile = "Y";
                    } else {
                        upsertMapping['products'][0].UseBusinessProfile = "N";
                    }
                }
            }

            let mapRequired = false;
            let isPrepaid = false;
            if (this.indicator.billing && this.indicator.billing.billing) {
                //console.log(this.indicator.billing, item.model["BillingAccount"]);
                for (let i = 0; i < this.indicator.billing.billing.length; i++) {
                    if (this.indicator.account.billingAccountSystem == constant.BRM_BILLING_DETAILS) {
                        if (item.model["BillingAccount"] == this.indicator.billing.billing[i].billing_account_id && this.indicator.billing.billing[i].profile.is_map == "true") {
                            mapRequired = true;
                            break;
                        }
                    } else {
                        if (item.model["BillingAccount"] == this.indicator.billing.billing[i].billingAccountId &&
                            this.indicator.billing.billing[i].digitalAdvPayInd == "Y") {
                            mapRequired = true;
                            break;
                        }
                    }

                }

                let Copy_Required__c = false;
                for (let i = 0; i < item.form.length; i++) {
                    if (item.form[i].key != null && item.form[i].type === "managecopy") {
                        if (item.order.productType === "Digital") {
                            Copy_Required__c = (item.model[item.form[i].key] === "Y");
                        } else {
                            Copy_Required__c = (item.model[item.form[i].key].copySheetIndicator === "Y");
                            upsertMapping['products'].product.content[0].copySheetIndicator = item.model[item.form[i].key].copySheetIndicator;
                        }
                        break;
                    }
                }
                //DE33481
                if (item.order.productType === "Digital" && item.order.productCode === "YPC" && item.model.copySheetIndicator == "Y") {
                    Copy_Required__c = true;
                }//DE33481
                let data = {
                    Billing_Account_ID__c: item.model["BillingAccount"],
                    Billing_Basis__c: item.model["BillingBasis"],
                    Billing_MAP_Required__c: mapRequired,
                    Copy_Required__c: Copy_Required__c
                };

                if (key === "ListingId") {
                    data['Listing_Id__c'] = item.model["ListingId"];
                    if (listing) {
                        data['Listing__c'] = this.formatPhoneNumber(listing.listedTN);
                        data['Listing_Name__c'] = listing.listedName;
                    }
                    item.order.listingId = item.model["ListingId"];
                } else if (key === "BusinessProfile") {
                    data['Listing_Id__c'] = item.model["BusinessProfile"];
                    item.order.listingId = item.model["BusinessProfile"];
                    if (listing && listing.primaryPhone) {
                        data['Listing__c'] = this.formatPhoneNumber(listing.primaryPhone.value);
                        //  data['Listing__c']= $filter('telephone')(listing.primaryPhone.toString().replace(/[^0-9]/g, ''), false);
                        data['Listing_Name__c'] = listing.businessName;
                        //this.indicator.addProfileById(item.model["BusinessProfile"]);
                    }
                }

                // let data = {
                //        Billing_Account_ID__c: item.model["BillingAccount"],
                //        Billing_Basis__c: item.model["BillingBasis"],
                //        Billing_MAP_Required__c: mapRequired,
                //        //Copy_Required__c : Copy_Required__c
                //    };
                //More fixing for DE14004: Update Content of DPM
                if (this.productCode === 'LLC' && upsertMapping['products'][0]) {
                    if (upsertMapping['products'][0].Zip && upsertMapping['products'][0].Zip.zip) {
                        upsertMapping['products'][0].Zip = upsertMapping['products'][0].Zip.zip
                    }
                    if (upsertMapping['products'][0].Zip4) {
                        upsertMapping['products'][0].Zip4 = upsertMapping['products'][0].Zip.zip4;
                    }
                    upsertMapping['products'][0].OptOutCallTracking = true; //Modified the field
                    if (upsertMapping['products'][0].Website) {
                        upsertMapping['products'][0].Website = this.removeProtocol(upsertMapping['products'][0].Website);
                    }
                    if (upsertMapping['products'][0].FacebookUrl) {
                        upsertMapping['products'][0].FacebookUrl = this.removeProtocol(upsertMapping['products'][0].FacebookUrl);
                    }
                }

                // Fix for Campaign Description unicode issue
                //  if (this.indicator.items[this.indicator.stateParamsId] !== undefined && this.indicator.items[this.indicator.stateParamsId].model.CampaignType) {
                //      for(let i=0; i < this.indicator.items[this.indicator.stateParamsId].model.CampaignType.length; i++){
                //          //replace(/(\u([0-9A-Za-z]))/g,'')
                //          let campaignDesc = this.indicator.items[this.indicator.stateParamsId].model.CampaignType[i].CampaignDescription;
                //          if(!(campaignDesc === null || campaignDesc === undefined)){
                //              upsertMapping['products'][0].CampaignList[i].CampaignDescription = this.utils.removeUnicodeChar(this.indicator.items[this.indicator.stateParamsId].model.CampaignType[i].CampaignDescription).trim();
                //          }
                //      }
                //  }
                //Fix for DE14004, else the rubik goes on and on if billing data is undefined
                if (data != null && !this.indicator.largeOrderValue) { //This condition will not be required once the form.$valid is uncommented and Billing Account gets saved

                    let uniqItemId = item.order.svItemId + "v" + item.order.version;
                    let storageData = await this.storageService.get("item", uniqItemId)
                    let flag;

                    if (storageData[uniqItemId].digitalApiFlag === undefined && !this.digitalApiFlag) {
                        flag = false;
                    } else {
                        flag = storageData[uniqItemId].digitalApiFlag;
                    }
                    this.digitalApiFlag = flag;
                    let billingId = data['Billing_Account_ID__c'] ? data['Billing_Account_ID__c'] : item.model.BillingAccount || this.indicator.billing.billing[0].billingAccountId;
                    let itemSfData = {
                        "eItemId": item.order.svItemId,
                        "SFDCItemId": item.order.sfdcItemId,
                        "listingId": data['Listing_Id__c'],
                        "listing": data['Listing__c'],
                        "listingName": data['Listing_Name__c'],
                        "billingAccountId": billingId,
                        "billingBasis": data.Billing_Basis__c,
                        "billingMapRequired": data.Billing_MAP_Required__c,
                        "copySheetRequired": data['Copy_Required__c'],
                        "coopType": item.model.CoOpType ? item.model.CoOpType : 'No',  // if we directly publish the item then we are not able to get this value from ordergrid page
                        "coopNiAmount": item.model.CoOpNIAmount,
                        "isPrepaid": isPrepaid
                    };
                    //US34991 Start
                    if (item.order.productType === "Print" || item.order.productCode == "YPC") {
                        if (this.indicator.items[item.order.svItemId].model && this.indicator.items[item.order.svItemId].model.CTNconf) {
                            /*indicator.items[this.indicator.stateParamsId].model.ManageCopysheet.CTNconf=true;*/
                            itemSfData["confirmCTN"] = this.indicator.items[item.order.svItemId].model.CTNconf;
                        } else {
                            /*indicator.items[this.indicator.stateParamsId].model.ManageCopysheet.CTNconf=false;*/
                            itemSfData["confirmCTN"] = false;
                        }
                    }//US34991 END
                    try{
                    let fieldName = ""    
                    if(page == "item"){
                        fieldName = "Item Salesforce API called"
                    }else if(page == "publish"){
                        fieldName = "Publish Salesforce API called"
                    }    
                    this.saveHistory(itemSfData.eItemId, fieldName, "", "Yes")
                    let sfresult = await this.sfService.savesfdata(itemSfData)
                        /*update content call to kgen for coop fields only for print items*/
                        if (item.order.productType === "Print" && item.model.CoOpType && item.model.CoOpType.toLowerCase() == "yes") {
                            let itemCoopData = {
                                "eItemId": item.order.svItemId,
                                "coopValue": item.model.CoOpType,
                                "issueNumber": item.order.directoryIssueNumber || "999"
                            };
                            await this.sfService.savecoopdata(itemCoopData);
                        }
                        /*YPC Geos*/
                        if (item.order.productCode === 'YPC') {
                            upsertMapping['products'][0].GeoID = item.model.GeoID;
                        }
                        let stopPrintCallforItemStatus = (item.order.statusCode == 10000 || item.order.statusCode == 7000) ? true : false;

                        //FUL-17254 Changes
                        if (((item.order.actionCode == "Renew" && !(this.digitalApiFlag || item.model['digitalApiFlag']) && item.order.productType !== "Print")
                            || ((this.digitalApiFlag || item.model['digitalApiFlag'] || publishDirtyflag) && item.order.productType === "Digital"))
                            && !item.order.parentId && order.orderType !== "National" && !stopPrintCallforItemStatus) {
                            this.upsertMapping1 = {
                                AuthData: {
                                    "apiSource": "CA"
                                },
                                ProductHeader: {},
                                products: [{}]
                            };
                            this.upsertMapping1['products'] = {
                                product: {
                                    type: "updateItem",
                                    callerId: "UCC",
                                    correlationId: this.grouper.getAllItemsOfGroupButNotSelf(item.order.svItemId, this.indicator).toString(),
                                    sourceId: order.accountId,
                                    svOrderId: order.orderId,
                                    customerId: account.kgenCustomerId,
                                    operatorId: order.opportunity.owner.operatorId,
                                    content: [{
                                        svItemId: item.order.svItemId,
                                        billingAccountId: item.model.BillingAccount

                                    }]
                                }
                            };
                            if (item.order.productType.toLowerCase() === "print") {
                                this.upsertMapping1['products'].product.content[0].copySheetIndicator = "Y";
                            }
                            //this.productCallPassed = false;
                            try {
                                this.productCallPending = false;
                                let updateResult;
                                let fieldName = ""    
                                if(page == "item"){
                                    fieldName = "Item Print Content API called"
                                }else if(page == "publish"){
                                    fieldName = "Publish Print Content API called"
                                }    
                                this.saveHistory(item.order.svItemId, fieldName, "", "Yes")
                                updateResult = await this.productService.updateSync("print", this.upsertMapping1, page);

                                if(page == 'publish' && updateResult && updateResult.error && updateResult.error.response && updateResult.error.response.hasWarningOrError =='true') {
                                    let errorMsg = "";
                                    let item = updateResult.error.response.item
                                    let errorObject = updateResult.error.response.esbServiceFault ? updateResult.error.response.esbServiceFault : null;
                                    if(item!=undefined && item!=null){
                                        for(let indx=0;indx < item.length; indx++){
                                            let errorCode = item[indx].sourceSystem ? item[indx].sourceSystem : null;
                                            let errorDesc = item[indx].errorMessage ? item[indx].errorMessage : null;
                                            let itemId = item[indx].svItemId? item[indx].svItemId : null;
                                            errorMsg = errorMsg + "<strong>Transaction : " + updateResult.error.response.transactionId + "</strong><br>"+"Update failed for item "+ itemId+"<br><strong>"+ errorCode + ":</strong><br>" + errorDesc;
                                        } 
                                    }
                                    if(errorObject!=undefined && errorObject!=null){
                                        let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                                        let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                                        errorMsg = "<strong>Transaction : " + updateResult.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                    }    
                                    this.logger.logError(errorMsg);
                                    if(item.order.productType.toLowerCase() === "print") {
                                        reject(updateResult);
                                    }
                                } else if (updateResult && (updateResult.hasWarningOrError === "false" || (updateResult.response && updateResult.response.hasWarningOrError && updateResult.response.hasWarningOrError === "false"))) {
                                    //item.model['digitalApiFlag'] = false;
                                    item.model.itemVisited = true;
                                    //console.log('Success 1' + item.order.svItemId);
                                    // this.logger.logSuccess("Successfully updated item." + item.order.svItemId)
                                    this.logger.logItemSuccess("Successfully updated item " + item.order.svItemId)
                                    await this.storageService.update("item", uniqItemId, item.model).then(result => { });
                                    this.productCallPending = true;
                                    if (page == "item" && item.order.productCode != "SEMP") this.routeToNextItem();
                                    if(page == "splitIA"|| page == "splitiaitempage" || page =="splitiagridpage") this.spinnerService.hide();
                                    if(item.order.productType.toLowerCase() === "print") {
                                        resolve(updateResult);
                                    }
                                } else {
                                    this.logger.logError("Error in Print Service Response");
                                    reject(null);
                                }
                            } catch (err) {
                                this.collectErrorMessage(err.error, item.order.svItemId, page);
                                reject(err);
                            }
                        }
                        /**
                        * Update Product if:
                        * dirtyFlag is true (managecopy field is modified)
                        * OR
                        *
                        * Product is NEW YPC
                        * AND
                        * shouldUpload is true (All mandatory fields are filled)
                        *  AND
                        * order is not "In Fulfillment"
                        * AND
                        * NOT from below listed UDACs for national org
                        * */
                        if (upsertMapping['products'].product && upsertMapping['products'].product.content && item.model["BillingBasis"]) {
                            upsertMapping['products'].product.content[0].billingBasis = item.model["BillingBasis"];
                        }
                        let nationalUDAC = [constant.NATIONALUDAC_XDCALL, constant.NATIONALUDAC_XDCPM, constant.NATIONALUDAC_XDFCHS, constant.NATIONALUDAC_XDPPC, constant.NATIONALUDAC_SPCITMPPC, constant.NATIONALUDAC_SPPFPCLR, constant.NATIONALUDAC_SPPFPICNL, constant.NATIONALUDAC_SPPFPICNN, constant.NATIONALUDAC_SPPFPITL, constant.NATIONALUDAC_SPPFPSZE, constant.NATIONALUDAC_SPPPCAS, constant.NATIONALUDAC_SPPPCNS, constant.NATIONALUDAC_SPPPCVC, constant.NATIONALUDAC_SEONAT, constant.NATIONALUDAC_DPM];
                        if (item.order.UDAC != "PRINTOLN" && ((item.order.itemrefreshed && !item.order.parentId) || (this.digitalApiFlag || item.model['digitalApiFlag']) ||
                            (item.order.productCode === "YPC" && item.order.actionCode !== "Renew") || item.order.isFYPDigital == true) &&
                            shouldUpload &&
                            ((item.order.statusCode < 7000 && item.order.productType === "Digital") ||
                                (item.order.statusCode < 6000 && item.order.productType === "Print")) && nationalUDAC.indexOf(item.order.UDAC) < 0   && 
                                (item.order.statusCode < 6000 && item.order.productType === "Print" || (item.order.fulfillmentSystem && item.order.fulfillmentSystem.toLowerCase() == "vision")) ){ //vision call start sending for seo
                            try {
                                this.productCallPending = true;
                                this.spinnerService.show();
                                let updateResult
                                let fieldName = ""    
                                if(page == "item"){
                                    fieldName = "Item "+ item.order.productType.toLowerCase()+" Content API called"
                                }else if(page == "publish"){
                                    fieldName = "Publish "+ item.order.productType.toLowerCase()+" Content API called"
                                }    
                                this.saveHistory(item.order.svItemId, fieldName, "", "Yes")
                                updateResult = await this.productService.updateSync(item.order.productType.toLowerCase(), JSON.parse(JSON.stringify(upsertMapping)), page);
                                if(page == 'publish' && updateResult && updateResult.error && updateResult.error.response && updateResult.error.response.hasWarningOrError =='true') {
                                    let errorMsg = "";
                                    let item = updateResult.error.response.item
                                    let errorObject = updateResult.error.response.esbServiceFault ? updateResult.error.response.esbServiceFault : null;                                    
                                    if(item!=undefined && item!=null){
                                        for(let indx=0;indx < item.length; indx++){
                                            let errorCode = item[indx].sourceSystem ? item[indx].sourceSystem : null;
                                            let errorDesc = item[indx].errorMessage ? item[indx].errorMessage : null;
                                            let itemId = item[indx].svItemId? item[indx].svItemId : null;                                            
                                            errorMsg = errorMsg + "<strong>Transaction : " + updateResult.error.response.transactionId + "</strong><br>"+"Update failed for item "+ itemId+"<br><strong>"+ errorCode + ":</strong><br>" + errorDesc;
                                        } 
                                    }
                                    if(errorObject!=undefined && errorObject!=null){
                                        let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                                        let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                                        errorMsg = "<strong>Transaction : " + updateResult.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                    } 
                                    this.logger.logError(errorMsg);
                                    reject(updateResult);
                                } else if (updateResult && (updateResult.hasWarningOrError === "false" || (updateResult.response && updateResult.response.hasWarningOrError && updateResult.response.hasWarningOrError === "false"))) {
                                    //item.model['digitalApiFlag'] = false;
                                    item.model.itemVisited = true;
                                    //console.log('Success 2' + item.order.svItemId);
                                    // this.logger.logSuccess("Successfully updated item." + item.order.svItemId);
                                    this.logger.logItemSuccess("Successfully updated item " + item.order.svItemId);
                                    let result = await this.storageService.update("item", uniqItemId, item.model)
                                    updateResult['servicePending'] = true;
                                    this.productCallPending = false;
                                    resolve(updateResult);
                                    if (page == "item") {
                                        this.routeToNextItem();
                                    }
                                    if(page == "splitIA" || page == "splitiaitempage" || page =="splitiagridpage") this.spinnerService.hide();

                                    //Delete all the campaign flags after upsert mapping
                                    if (this.indicator.items[this.indicator.stateParamsId] !== undefined && (this.indicator.items[this.indicator.stateParamsId].model.CampaignType)) {
                                        for (let i = 0; i < this.indicator.items[this.indicator.stateParamsId].model.CampaignType.length; i++) {
                                            if (this.indicator.items[this.indicator.stateParamsId].model.CampaignType[i].campaignFlag) {
                                                delete (this.indicator.items[this.indicator.stateParamsId].model.CampaignType[i].campaignFlag);
                                            }
                                        }
                                    }
                                }
                                else{
                                    if(updateResult) {
                                        this.collectErrorMessage(updateResult, item.order.svItemId, page);
                                    } else {
                                        this.logger.logError("Error in Print Service Response");
                                        reject(null);
                                    }
                                }
                            } catch (err) {
                                this.collectErrorMessage(err.error, item.order.svItemId, page);
                                //$rootScope.disablePublishBtn = true;
                                //reject(err);this line is uncommented since rejecting this is causing other promise calls not to execute in sequence
                            }
                        } else {
                            //Had to put this code here, so that the updateContent(Print) can be called
                            // only after updating Salesforce for all items with billing account and listing info

                            //Fix for DE16005, DE15829 and DE16446
                            //Blocking updateContent(Print) calls for
                            //Digital Child items between with
                            // a status code less than 3000 and
                            // a status code 6000 and above
                            if (item.order.statusCode >= 3000) {
                                if (item.order.productType === "Digital") {
                                    //(item.order.actionCode == "Add" && !item.order.parentId )
                                    // As update content should always be called for new YPC
                                    if (((item.order.itemrefreshed && !item.order.parentId) || (item.order.actionCode == "Add" && !item.order.parentId) || (((this.digitalApiFlag || item.model['digitalApiFlag']) || (item.order.productCode === "YPC" && item.order.actionCode !== "Renew") || item.order.isFYPDigital == true) && (item.order.parentId == null && order.orderType !== "National") && (item.order.statusCode < 6000) && this.indicator.account.billingAccountSystem !== constant.BRM_BILLING_DETAILS)) && order.orderType !== "National") {
                                        let updateRequest = this.updateContent(order, account.kgenCustomerId, item);
                                        try {
                                            this.productCallPending = true;
                                            let updateResult;
                                            let fieldName = ""    
                                            if(page == "item"){
                                                fieldName = "Item print Content API called"
                                            }else if(page == "publish"){
                                                fieldName = "Publish print Content API called"
                                            } 
                                            this.saveHistory(item.order.svItemId, fieldName, "", "Yes")
                                            updateResult = await this.productService.updateSync("print", JSON.parse(JSON.stringify(updateRequest)), page);
                                            if(page == 'publish' && updateResult && updateResult.error && updateResult.error.response && updateResult.error.response.hasWarningOrError ==="true") {
                                                let errorMsg = "";
                                                let item = updateResult.error.response.item
                                                let errorObject = updateResult.error.response.esbServiceFault ? updateResult.error.response.esbServiceFault : null;                                                
                                                if(item!=undefined && item!=null){
                                                    for(let indx=0;indx < item.length; indx++){
                                                        let errorCode = item[indx].sourceSystem ? item[indx].sourceSystem : null;
                                                        let errorDesc = item[indx].errorMessage ? item[indx].errorMessage : null;
                                                        let itemId = item[indx].svItemId? item[indx].svItemId : null;
                                                        errorMsg = errorMsg + "<strong>Transaction : " + updateResult.error.response.transactionId + "</strong><br>"+"Update failed for item "+ itemId+"<br><strong>"+ errorCode + ":</strong><br>" + errorDesc;
                                                    } 
                                                }
                                                if(errorObject!=undefined && errorObject!=null){
                                                    let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                                                    let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                                                    errorMsg = "<strong>Transaction : " + updateResult.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                                } 
                                                this.logger.logError(errorMsg);
                                                reject(updateResult);
                                            } else if (updateResult.hasWarningOrError === "false" || (updateResult.response && updateResult.response.hasWarningOrError && updateResult.response.hasWarningOrError === "false")) {
                                                //item.model['digitalApiFlag'] = false;
                                                item.model.itemVisited = true;
                                                //console.log('Success 3' + item.order.svItemId);
                                                // this.logger.logSuccess("Successfully updated item." + item.order.svItemId)
                                                this.logger.logItemSuccess("Successfully updated item " + item.order.svItemId)
                                                //console.log("UpdateContent(Print) for Digital Item Response is : ", updateResult);
                                                await this.storageService.update("item", uniqItemId, item.model).then(result => { });
                                                this.productCallPending = false;
                                                resolve(updateResult);
                                                if (page == "item") {
                                                    this.routeToNextItem();
                                                }
                                            }
                                            resolve(updateResult);
                                        } catch (err) {
                                            if (err && err.error && err.error.response && err.error.response.hasWarningOrError == "true") {
                                                let transactionId = err.error.response.transactionId;
                                                let errorCode;
                                                let errorDesc;
                                                if (err.error.response.esbServiceFault) {
                                                    errorCode = err.error.response.esbServiceFault.exceptionDetail[0].errorCode;
                                                    errorDesc = err.error.response.esbServiceFault.exceptionDetail[0].errorDescription[0];
                                                }
                                                if (page != "publish") // no need to show error message for publish click bcz we are taking care from error icon on publish page
                                                    this.logger.logError("<strong>Transaction Id : " + transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc);
                                            }
                                            this.collectErrorMessage(err.error, item.order.svItemId, page);
                                            //$rootScope.disablePublishBtn = true;
                                            reject(err);
                                        }

                                    } else {
                                        //No update content for a child item: Fix for DE16005, DE15829 and DE16446
                                        //console.log('Success 4' + item.order.svItemId);
                                        // this.logger.logSuccess("Successfully updated item." + item.order.svItemId);
                                        this.logger.logItemSuccess("Successfully updated item " + item.order.svItemId);
                                        sfresult['servicePending'] = this.productCallPending ? true : false; //setting this flag to block the navigation from item page if any call is pending from item service
                                        resolve(sfresult);
                                        //No update content for a child item: Fix for DE16005, DE15829 and DE16446
                                    }
                                } else {
                                    //No update content for a child item: Fix for DE16005, DE15829 and DE16446
                                   //console.log('Success 5' + item.order.svItemId);
                                    // this.logger.logSuccess("Successfully updated item." + item.order.svItemId);
                                    this.logger.logItemSuccess("Successfully updated item " + item.order.svItemId);
                                    sfresult['servicePending'] = this.productCallPending ? true : false; //setting this flag to block the navigation from item page if any call is pending from item service
                                    resolve(sfresult);
                                    //No update content for a child item: Fix for DE16005, DE15829 and DE16446
                                }
                                //Fix for DE16005, DE15829 and DE16446
                            } else {
                                //Fix for DE16005, DE15829
                                sfresult['servicePending'] = this.productCallPending ? true : false; //setting this flag to block the navigation from item page if any call is pending from item service
                                //console.log('Success 6' + item.order.svItemId);
                                // this.logger.logSuccess("Successfully updated item." + item.order.svItemId);
                                this.logger.logItemSuccess("Successfully updated item " + item.order.svItemId);
                                resolve(sfresult);
                            }
                        }

                    }catch(error){
                        this.spinnerService.hide();// Added spinner hide condition for salesforce update on item save
                        this.logger.logError("<strong>Error in Save Sfdata <strong><br>Source System : Salesforce <br>");
                        this.collectErrorMessage(error, item.order.svItemId, page);
                        reject(error);
                    };
                }
            }
        });
    }



    /* Method to handle UpdateContent request for products with one or two fields */
    updateContent(order, kgenCustomerId, item) {
        let products = {};
        let product = {};
        product['type'] = "updateItem";
        product['callerId'] = "UCC";
        product['correlationId'] = this.grouper.getAllItemsOfGroupButNotSelf(item.order.svItemId, this.indicator).toString();
        product['sourceId'] = order.accountId;
        product['svOrderId'] = order.orderId;
        product['customerId'] = kgenCustomerId;
        product['operatorId'] = order.opportunity.owner.operatorId;
        product['content'] = [];

        let contentData = {};
        contentData['svItemId'] = item.order.svItemId;
        contentData['billingAccountId'] = item.model["BillingAccount"];
        contentData['billingBasis'] = item.model["BillingBasis"];
        product['content'].push(contentData);

        products['product'] = product;
        let updateRequest = {};
        updateRequest['products'] = products;

        return updateRequest;
    }

    /* Method to handle UpdateContent request for products with one or two fields */

    /* Method to remove the http protocal from Facebook and Website URLs fields in  UpdateContent request */
    removeProtocol(url) {
        if (url !== undefined && url !== null) {
            if (url.indexOf("http://") !== 0 || url.indexOf("https://") !== 0) {
                let wwwIndex = url.indexOf("www");
                let modifiedUrl = url.substring(wwwIndex, url.length);
                return modifiedUrl;
            }
        } else return "";
    };
    formatPhoneNumber(getNumber) {
        if (getNumber) {
            let num = getNumber.replace(/\D/g, "");
            return "(" + num.substring(0, 3) + ")" + " " + num.substring(3, 6) + "-" + num.substring(6, 10);
        }
    }


    collectErrorMessage(message, svItemId, page) {
        let response = (message.hasOwnProperty("response")) ? message.response : message;
        if (this.indicator.stateParamsId  || page == 'orderGrid' || page == "splitIA" || page == "splitiaitempage" || page =="splitiagridpage"){
            let transactionId = response.transactionId;
            let errorCode;
            let errorDesc;
            if (response && response.esbServiceFault && response.esbServiceFault.exceptionDetail && response.esbServiceFault.exceptionDetail[0]) {
                errorCode = response.esbServiceFault.exceptionDetail[0].errorCode;
                errorDesc = response.esbServiceFault.exceptionDetail[0].errorDescription[0];
            } else if (response.hasOwnProperty('item') && response.item[0].detail) {
                errorCode = response.item[0].detail.code;
                errorDesc = response.item[0].detail.description;
            } else if (response.hasOwnProperty('message')) {
                transactionId = "N/A";
                errorCode = "N/A";
                errorDesc = response.message;
            } else if(response && response.item && response.item[0] && response.item[0].errorMessage){
                transactionId = response.transactionId
                errorCode = "N/A";
                errorDesc = response.item[0].errorMessage;
            }else{
                transactionId = "N/A";
                errorCode = "N/A";
                errorDesc = "An unknown error occurred please contact CA support team.";
            }
            if (page != "publish") // no need to show error message for publish click bcz we are taking care from error icon on publish page
                this.logger.logError("<strong>Transaction Id : " + transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc);

        } else {
            response.svItemId = svItemId;
            if (!this.indicator.errArray) {//Adding below condition to show error message on Publish screen
                let tempArray = [];
                tempArray.push(response)
                this.indicator.errArray = tempArray;
            } else {
                this.indicator.errArray.push(response);
            }
            this.indicatorStore.setLoading(true);
            this.indicatorStore.update((state) => {
                return {
                    indicator: this.indicator,
                    updatedFrom: "[ItemService] collectErrorMessage",
                    isloaded: true,
                }
            });
            //this.utils.errArray.push(response);
            //this.utils.errorCount++;

        }
        if (page != "publish") {
            this.spinnerService.hide();
        }
        /*Adding below condition to show error message on Publish screen
         */
        // if (this.utils.errorCount > 0) {
        //     if(this.indicator.errArray){
        //     console.log('in item service this.indicator.errArray'+this.indicator.errArray.length+'this.utils.errArray length'+this.utils.errArray)
        //     }
        //     this.indicator.errArray = this.utils.errArray;
        //     this.indicatorStore.setLoading(true);
        //     this.indicatorStore.update((state) => {
        //         return {
        //             indicator: this.indicator,
        //             isloaded: true,
        //         }
        //     });
        //     //  $rootScope.$broadcast('errorMsgEventFired', {
        //     //      data: this.utils.errArray,
        //     //      //path : $location.path()
        //     //  });
        // }
    };

    errorLog() {
        let errorCode;
        let errorDesc;
        if (this.indicator.errArray) {
            if (this.indicator.errArray.length > 0) {
                for (let i = 0; this.indicator.errArray.length > i; i++) {
                    let transactionId = this.indicator.errArray[i].transactionId;
                    if (this.indicator.errArray[i].esbServiceFault) {
                        errorCode = this.indicator.errArray[i].esbServiceFault.exceptionDetail[0].errorCode;
                        errorDesc = this.indicator.errArray[i].esbServiceFault.exceptionDetail[0].errorDescription[0];
                    } else if (this.indicator.errArray[i].hasOwnProperty('item') && this.indicator.errArray[i].item[0].detail) {
                        errorCode = this.indicator.errArray[i].item[0].detail.code;
                        errorDesc = this.indicator.errArray[i].item[0].detail.description;
                    } else if (this.indicator.errArray[i].hasOwnProperty('message')) {
                        transactionId = "N/A";
                        errorCode = "N/A";
                        errorDesc = this.indicator.errArray[i].message;
                    } else {
                        transactionId = "N/A";
                        errorCode = "N/A";
                        errorDesc = "An unknown error occurred please contact CA support team.";
                    }

                    let errorMsg = "<strong>Transaction Id : " + transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    if (!this.indicator.errArray[i].svItemId) {
                        this.indicator.errArray[i].svItemId = this.indicator.errArray[i].item[0].svItemId;
                    }
                    errorMsg += "<br><a class='btn btn-info' [routerLink]='/orderitems/item/" + this.indicator.errArray[i].svItemId + "'>Go to item page</a>";
                    this.logger.logmultiItemError(errorMsg, this.indicator.errArray[i].svItemId, transactionId);

                }
            }
        }

    }
    routeToNextItem() {
        //routing should be done only if stateparams id present  (or else we cannot route again if already came out of item page after item save)
        if (this.indicator.stateParamsId != "" && this.indicator.items[this.indicator.stateParamsId]) {
            let index = this.utils.indexOf(this.indicator.items, this.indicator.items[this.indicator.stateParamsId]);
            this.spinnerService.hide();
           /*  if (this.indicator.items[this.indicator.stateParamsId].order.productType == constant.ITEM_TYPE_PRINT) {
                this.router.navigate([`/printitems`]);
            } else */ //if {
                if (index < Object.keys(this.indicator.items).length - 1) {
                    if (this.indicator.items[this.utils.getKey(this.indicator.items, index + 1)].order) {
                        let nextItemId = this.indicator.items[this.utils.getKey(this.indicator.items, index + 1)].order.svItemId;
                        this.router.navigate([`/orderitems/item/${nextItemId}`]);
                    }
                } else {
                    // FUL-25272
                    if (this.indicator && this.indicator.orderPercent && (this.indicator.orderPercent.digitalCount + this.indicator.orderPercent.printCount) > 0){
                        if (Object.keys(this.indicator.items).length == 1){
                            this.router.navigate([`/orderitems`]);
                        }else{
                            let firstItem = Object.keys(this.indicator.items)[0];
                            this.router.navigate([`/orderitems/item/${firstItem}`]);
                        }
                    }else{
                        this.router.navigate([`/publish`]);
                    }
                }
            }
       // }
    }

    saveHistory(itemId, fName, fOldValue, fNewValue){
        let historyObj = {
            'displayName': this.indicator['user']['name'],
            'email': this.indicator['user']['email'],
            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
            'itemId': itemId,
            'itemStatus': this.indicator.items[itemId].order.status,
            'itemActionCode':this.indicator.items[itemId].order.actionCode + "-" + this.indicator.items[itemId].order.actionType,
            'fieldName': fName,
            'fieldOldValue': fOldValue,
            'fieldNewValue': fNewValue
          }
          this.storageService.update("itemHistory", itemId, historyObj).then(result=>{});
    }

}
