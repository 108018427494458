import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalltrackinschemaService {
  model: any;
  callOnly:boolean = false;
    callValue: string;
  constructor() { } 

  schema = {
      type: "object",
      title: "calltracking",
      properties: {
          "destinationnumber":{
              "title":'Destination Number',
              "type":"string"
          },
          "cTnumber":{
              "title": "Call Tracking Number",
              "type": "string"
          },
          "calltrackingtype": {
              "title": 'Call Tracking',
              "type": "string"
          },
          // "callwhisper": {
          //     "type":"boolean",
          //     "title":"Call Whisper"
          // },
          "callrecord": {
              "type":"boolean",
              "title":"Call Record"
          },
          "callintercept":{
             "type":"boolean",
             "title": "Call Intercept"
          },
          "areacode":{
              "type":"string",
              "title":"Targeted Area Code-Prefix",
              "maxLength":3,
              "pattern":"^(0|[1-9][0-9]*)$"
          },
          "prefix":{
              "type":"string",
              "maxLength":3,
              "pattern":"^(0|[1-9][0-9]*)$"
          },
          "trackingNumber":{
              "type":"string"
          },
          "marketdestinationNumber":{
              "type":"string"
          },
          "transactionID":{
              "title":"Transaction ID",
              "type":"string"
          },
          "labelarea":{
              "type":"object",
              "title":"Targeted Area Code-Prefix"
          },
          "calltrackingbuttontype":{
              "type":"submit",
              "title":"Reserve"
          }
      }
  };

  form = [
      {
          "type":"conditional",
          "htmlClass":"form-horizontal form-group",
          "condition": "this.iaGroup === true && this.existingDest === true",
          "items":[
              {
                  "type":"help",
                  "helpvalue": " <hr class='line'> <font color='red'>Adding a Call Tracking number will remove this item from its Identical Appearance Group!</font><hr class='line'>"
              }
          ]
      },
      {
          "key" : "destinationnumber",
          "type": "phonenumberfield",
          //"condition": "vm.model.calltrackingtype !== 'Market C Number'",
          "title":"Destination Number",
          "labelHtmlClass" : "col-xs-6",
          "fieldHtmlClass" : "col-xs-6",
          "htmlClass" : "requiredField"
      },
      {
          "type":"conditional",
          "htmlClass":"form-horizontal form-group",
         // "condition": "vm.existingDest === true",
        //   "items":[
        //       {
        //           "type":"help",
        //           "helpvalue": " <hr class='line'> <font color='red'>This destination number has already been used.  Please select a different destination number.</font><hr class='line'>"
        //       }
        //   ]
      },
      {
          "key" : "cTnumber",
          "condition": "this.model.calltrackingtype === 'ExistingCT'",
          "title": "Call Tracking Number",
          "labelHtmlClass" : "col-xs-6",
          "fieldHtmlClass" : "col-xs-6 readonly",
          "htmlClass" : "requiredField"
      },
      {
          "key" : "calltrackingtype",
          //"condition": "this.model.calltrackingtype !== 'ExistingCT'",
          "type": "select",
          "labelHtmlClass" : "col-xs-6 requiredField",
          "fieldHtmlClass" : "col-xs-6",
          "htmlClass" : "requiredField"
      },
      {
          "type":"calltrackinginput",
          "htmlClass":"row form-group form-inline ",
         // "condition": "vm.model.calltrackingtype === 'Local Number'",
          "items": [
            {
                "key":"callrecord",
                "htmlClass":"col-xs-6",
                "readonly": true
            },
            {
                "key":"callintercept",
                "htmlClass":"col-xs-6",
                "readonly": true
            },
            {"key": "areacode",
                  "notitle": false,
                  "validationMessage":" ",
                  "labelHtmlClass" : "col-xs-8 requiredField arealabel",
                  "fieldHtmlClass" : " col-xs-1 calltextarea areacode2 pull-right",
                  "htmlClass" : "requiredField"
            },
            {"key": "prefix",
                  "notitle": true,
                  "validationMessage":" ",
                  "fieldHtmlClass" : "col-xs-1 calltextarea areacode1",
                  "htmlClass" : "requiredField"
            },
            {
                "key": "trackingNumber",
                "title": "Telephone Number",
                "labelHtmlClass" : "col-xs-6 requiredField",
                "fieldHtmlClass" : "col-xs-6 "
            }

          ]
      },
    //   {
    //       "type":"section",
    //       "htmlClass":"row form-inline",
    //       "condition": "vm.model.calltrackingtype === 'Market C Number'",
    //       "items":[
    //           {
    //               "type": "submit",
    //             //   "title": "Validate",
                  
    //               "htmlClass": "pull-right"
    //           },
    //           {
    //               "type": "button",
    //               "title": "Cancel",
    //               "htmlClass": "backbtnitem col-xs-6",
    //               "style": "btn-primary",
    //               "onClick": "vm.cancel()"
    //           }
    //       ]
    //   },
    //   {
    //       "type": "section",
    //       "htmlClass": "form-inline row",
    //       //TODO will this OR work?
    //       //"condition": "vm.model.calltrackingtype !== 'Market C Number' && vm.existingDest === false && vm.noChanges === false",
    //       "items": [
    //           {
    //               "type": "submit",
    //                //"title": this.callOnly?"ok":"cancle",
    //                "title":"Reserve",
    //               "htmlClass": "pull-right billingsave",
    //               //"condition": "vm.model.areacode.length !== 3",
    //               "readonly": true
    //           },
    //       ]
    //   },
    // //   {
    //       "type": "section",
    //       "htmlClass": "form-inline row",
    //       "condition": "vm.model.calltrackingtype !== 'Market C Number' && vm.existingDest === true && vm.noChanges === true",
    //       "items": [
    //           {
    //               "type": "button",
    //               "title": "Cancel",
    //               "htmlClass": "backbtnitem col-xs-4",
    //               "style": "btn-primary",
    //               "onClick": "vm.cancel()"
    //           }
    //       ]
    //   },
    //   {
    //       "type": "section",
    //       "htmlClass": "form-inline row",
    //       "condition": "vm.model.calltrackingtype !== 'Market C Number' && vm.noChanges === true",
    //       "items": [
    //           {
    //               "type": "button",
    //               "title": "Cancel",
    //               "htmlClass": "backbtnitem col-xs-4",
    //               "style": "btn-primary",
    //               "onClick": "vm.cancel()"
    //           }
    //       ]
    //   }
  ];
  options = {
    "formDefaults": {
        onChange: function(modelValue, form) {
        }
    }
 };

 
}
