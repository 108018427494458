import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CopysheetFileComponent } from '../copysheet-file/copysheet-file.component';
import { LoggerService } from '@app/service/logger/logger.service';
import { CopysheetUploadComponent } from '../copysheet-upload/copysheet-upload.component';
import { StorageService } from '@app/service/storage/storage.service';
import { FieldcountService } from '@app/service/fieldcount/fieldcount.service';
import { AccountService } from '@app/service/account/account.service';
import { OrderService } from '@app/service/order/order.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { ItemService } from '@app/service/item/item.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { AdService } from '@app/service/ad/ad.service';
import { ParamsService } from '@app/service/params/params.service';
import { GroupService } from '@app/service/group/group.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { FileService } from '@app/service/file/file.service';
import { CopysheetAnnotateComponent } from '../copysheet-annotate/copysheet-annotate.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { CounterService } from '@app/service/counter/counter.service';
import { PDFViewerComponent } from '../pdfviewer/pdfviewer.component';
import { ApiService } from '../../../core/service/api/api.service';
import { CookieService } from 'ngx-cookie-service';
import { CallconfirmationComponent } from '../../../shared/components/call-new/callconfirmation/callconfirmation.component';

var timer;
var winReference;
var self;
var cookie;

@Component({
  selector: 'app-copy-sheet',
  templateUrl: './copy-sheet.component.html',
  styleUrls: ['./copy-sheet.component.scss']
})
export class CopySheetComponent implements OnInit {

  Indicator;
  loadingCopysheet: boolean;
  loadingUpload: boolean;
  addlInstructions: string;
  storageID: string;
  dialogData: any = {};
  copyDialogData: any = {};
  loadingAnnotate: boolean;
  copysheet: any;
  tempCalltracking: any;
  doNotOpenModal: boolean;
  private static callTracking: CopySheetComponent;
  //pdfModalInstance: any;

  // actionListObj: any;
  fileType: string;
  urlAD: string = '';
  addtnlMaxLength = 1500;

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private dialog: MatDialog,
    private Logger: LoggerService,
    private StorageService: StorageService,
    private Copysheet: CopysheetService,
    private requiredFieldCount: FieldcountService,
    private accountService: AccountService,
    private orderService: OrderService,
    private profileService: ProfileService,
    private itemService: ItemService,
    private groupService: GroupService,
    private utilsService: UtilsService,
    private adService: AdService,
    private matDialog: MatDialog,
    private params: ParamsService,
    private file: FileService,
    private cdr: ChangeDetectorRef,
    private ngxspinner: NgxSpinnerService,
    private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService,
    private cookieService: CookieService,
    private API:ApiService,
  ) {
    CopySheetComponent.callTracking = this
    self = this;
  }

  ngOnInit() {
    this.getIndicator();
  }
  private getIndicator() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
      this.storageID = this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version;
      if( this.Indicator.items[this.Indicator.stateParamsId] &&this.Indicator.items[this.Indicator.stateParamsId].copy && this.Indicator.items[this.Indicator.stateParamsId].copy.instructions){
        this.addlInstructions = this.Indicator.items[this.Indicator.stateParamsId].copy.instructions;
      }else{
        this.addlInstructions ='';
      }
      if(this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId]){
        this.tempCalltracking = this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet;
      }else{
        this.tempCalltracking = false;
      }
      
      try {
        this.adService.getAdUrl(this.Indicator.stateParamsId).subscribe(() => {
        }, err => {
          console.log(err);
          this.urlAD = err['url'];
          console.log(" add url : ", this.urlAD);
          this.ngAfterViewChecked();
        })
      } catch (e) {
        console.log(" error in getting the adservice url : ", e);
      }
    })
  }
  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }
  static reTrigger() {
    CopySheetComponent.callTracking.getIndicator();
    CopySheetComponent.callTracking.ngAfterViewChecked();
  }

  // getCopysheet(action) {
  getCopysheetFoxit(action,page?) {
    //  var selectedItem =this.Indicator.stateParamsId; //$rootScope.ctArray;
    //this.Indicator.stateParamsId == undefined ? this.Indicator.stateParamsId = selectedItem[0] : this.Indicator.stateParamsId = this.Indicator.stateParamsId;
    /*
     this.indicatorStore.update(state => {
       return {
         indicator: this.indicatorQuery.getValue().indicator
       }
     })
     */
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
    this.ngxspinner.show();
    setTimeout(() => {// timeout is added for spinner issue
      if (((this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId] && 
        this.Indicator.items[this.Indicator.stateParamsId].copy) && 
        (!this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty("copysheet") || 
        this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet == "" ||
        this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet === null)) || 
        this.Indicator.items[this.Indicator.stateParamsId]['isCopySheetUploaded']){
      //|| ((!(!this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty("copysheet")
      //|| this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet == ""
      //|| this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet === null)) && this.Indicator.items[this.Indicator.stateParamsId]['isCopySheetUploaded'])) {
      console.log('Generating new copy sheet');
      this.params['saving'] = true;
      this.generateCopysheet(action,null,page);
      //Reverting the code for IA grp
      // if (vm.Indicator.items[this.Indicator.stateParamsId].data
      //     && vm.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId)
      // {
      //     //go through the other items on the order
      //     for (var key in vm.Indicator.items) {
      //         //if the other item has the matching ia group id & isn't the current item
      //         if (vm.Indicator.items[key].data
      //             && vm.Indicator.items[key].data.iaGroupId === vm.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
      //             && vm.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId)
      //         {
      //             vm.generateCopysheet(action);
      //         }
      //     }
      // } else {
      //     vm.generateCopysheet(action);
      // }
    } else {
      this.params['saving'] = true;
      if (action == 'view') {
        var win = window.open(this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet);
        if (win) {
          win.focus();
        }
      } else if (action == 'annotate') {
        console.log('Annotating existing copy sheet')
        this.annotateCopysheet();
      }
      this.params['saving'] = false;
      this.params['loadingbar'] = false;
    }
  },1000);
  }

  // getCopysheetfoxit(action) {
  //   const dialogConfig = new MatDialogConfig();
  //   let result;
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.panelClass = 'confirmaton-dialog-foxit';

  //   const dialogRef = this.matDialog.open(PDFViewerComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     result = dialogResult;
  //     this.ngxspinner.hide();
  //   });

  // }


  openUploadAttachmentDialog() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.height = '300px';
    dialogConfig.panelClass = 'attachment-dialog'
    dialogConfig.data = {
      dialogform: this.dialogData
    }
    let dialogRef = this.dialog.open(CopysheetFileComponent, dialogConfig);
    this.dialogData.dialogRef = dialogRef;
    var oldAttachment = this.Indicator.items[this.Indicator.stateParamsId].copy['attachments'];
    dialogRef.afterClosed().subscribe(data => {
      console.log('data is',data);
      console.log(this.Indicator);
      let copyData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId].copy));
      this.Indicator['items'][this.Indicator.stateParamsId].copy = copyData;
      let indicatorValue = this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId]['needsNewCopysheet'];
      if (indicatorValue !== undefined && indicatorValue !== null) {
        try {
          // Parse the JSON if it's a valid JSON string
          let updatedNeedsNewCopysheet = JSON.parse(JSON.stringify(indicatorValue));
          this.Indicator['items'][this.Indicator.stateParamsId]['needsNewCopysheet'] = updatedNeedsNewCopysheet;
          // Now you can use updatedNeedsNewCopysheet
        } catch (error) {
          // Handle the case where JSON parsing fails (e.g., it's not a valid JSON string)
          console.error('Error parsing JSON:', error);
        }
      } else {
        // Handle the case where the value is undefined or null
        console.error('The value is undefined or null.');
      }
    // let updatedNeedsNewCopysheet = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId]['needsNewCopysheet']));
     // this.Indicator['items'][this.Indicator.stateParamsId]['needsNewCopysheet'] = updatedNeedsNewCopysheet;
      // update IA related code!!
      if (data){
        if (this.Indicator.items[this.Indicator.stateParamsId].data
          && this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId)
      {
          //go through the other items on the order
          for (let key in this.Indicator.items) {
              //if the other item has the matching ia group id & isn't the current item
              if (this.Indicator.items[key].data
                  && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
                  && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId)
              {
                  //add the copysheet to the other item
                  //[...new Set([...array1,...array2])]
                  if (this.Indicator.items[key].copy.attachments){
                    let attchemntArr = this.Indicator.items[key].copy.attachments;
                    let updatedAttachmentArr = [...new Set([...attchemntArr,...data])];
                    this.Indicator.items[key].copy.attachments = updatedAttachmentArr;
                  }else{
                    this.Indicator.items[key].copy['attachments'] = data;
                  }
                  this.Indicator['items'][key]['needsNewCopysheet'] = this.Indicator['items'][this.Indicator.stateParamsId]['needsNewCopysheet'];
                  
                  this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
                  this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
                  this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                  .then((response:any)=> {
                  }).catch(function (err) {
                      this.Logger.logError("Failed to update copysheet :" +JSON.stringify(err));
                  });
              }
          }
          //update Indicator store
          this.indicatorStore.update(state => {
            return {
              indicator: this.Indicator,
              updatedFrom: "[CopySheetComponent] openUploadAttachmentDialog"
            }
          })
          this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
      }
      if(oldAttachment && this.Indicator.items[this.Indicator.stateParamsId].copy.attachments && oldAttachment.length !== this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.length){
        var activityLogHistoryObj = {
          'displayName': this.Indicator.user.name,
          'email': this.Indicator.user.email,
          'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
          'itemId': this.Indicator.stateParamsId,
          'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
          'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
          'fieldName': 'Copysheet Value',
          'fieldOldValue': oldAttachment.join(','),
          'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.join(','),
          'apiStatus' : 'Success'
        }
        this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
      }else if(!oldAttachment && this.Indicator.items[this.Indicator.stateParamsId].copy.attachments ){
        var activityLogHistoryObj = {
          'displayName': this.Indicator.user.name,
          'email': this.Indicator.user.email,
          'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
          'itemId': this.Indicator.stateParamsId,
          'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
          'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
          'fieldName': 'Copysheet Value',
          'fieldOldValue': null,
          'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.join(','),
          'apiStatus' : 'Success'
        }
        this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
      }
      }

      this.ngAfterViewChecked();
      console.log(this.Indicator);
    });
  }

  uploadCopysheet(page?) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
    if (this.Indicator.items[this.Indicator.stateParamsId] && !this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet) {
      if (this.Indicator.items[this.Indicator.stateParamsId].model.BillingAccount)
        this.generateCopysheet("upload",null,page);
      else
        this.Logger.logError(" Please select Billing Account");
        this.ngxspinner.hide();
    } else {
      this.openUploadDialog()
    }
  }

  async generateCopysheet(genType, openAttachment?,page?) {
    if (genType === "view") {
      this.loadingCopysheet = true;
    } else if (genType === "upload") {
      this.loadingUpload = true;
    } else if (genType === "annotate") {
      this.loadingAnnotate = true;
    }
    if (this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId].model.BillingAccount) {
      Promise.all([
        this.accountService.getMetoPromise(),
        this.orderService.getMeToPromise(this.Indicator.order['sfdcOrderId'])
      ]).then((results) => {
        let account = results[0];
        let order = results[1];
        let promise = null;
        let id = null;
        if ((account.parentId != null && account.kgenCustomerId == null) || this.Indicator.account.kgenCustomerId) {
          account.kgenCustomerId = this.Indicator.account.kgenCustomerId;
        }

        if (this.Indicator.items[this.Indicator.stateParamsId].order.productType === "Digital") {
          promise = this.profileService.getByEid(account.enterpriseAccountId);
          id = account.enterpriseAccountId;
        } else if (this.Indicator.items[this.Indicator.stateParamsId].order.productType === "Print") {
          promise = this.profileService.getAllPrintListing(account.kgenCustomerId, {
            productcode: this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber,
            productissuenum: this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber,
            sectioncode: this.Indicator.items[this.Indicator.stateParamsId].order.section
          });
          id = account.kgenCustomerId;
        }
        if (promise) {
          promise.then(async (response) => {
            let data = response[id];
            if (data.hasWarningOrError !== "true") {
               var pageRef = "PRODUCT-CREATE-" +page;
              await this.itemService.update(account, order, this.Indicator.items[this.Indicator.stateParamsId], data.listings.listing, this.Indicator.stateParamsId, pageRef)
                .then((printRes) => {
                  console.log(" Response from print service : " + printRes);
                  //checkfor erro rmessage and get out of the controll once failure response
                  let copySheetReqData = {
                    "type": "generateCopySheet",
                    "id": "",
                    "callerId": "UCC",
                    "correlationId": this.groupService.getAllItemsOfGroupButNotSelf(this.Indicator.stateParamsId, this.Indicator).toString(),
                    "sourceId": "",
                    "copySheet": {
                      "svOrderId": order['orderId'],
                      "customerId": account.kgenCustomerId,
                      "svItemId": this.Indicator.stateParamsId,
                      "productCode": (this.Indicator.items[this.Indicator.stateParamsId].order.productType === "Digital") ? this.Indicator.items[this.Indicator.stateParamsId].order.productCode : this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber,
                      "productIssueNum": this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber,
                      "documentType": (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === "Renew") ? "A" : "N",
                      "operatorId": order['opportunity'].owner.operatorId,
                      "date": this.utilsService.getKgenDate(new Date())
                    }
                  };
                  this.Copysheet.getCopySheets(copySheetReqData, this.Indicator.stateParamsId, this.Indicator.items[this.Indicator.stateParamsId].order.version,page)
                    .then((data) => {
                      console.log("Response from getcopysheets:",data);
                      this.loadingCopysheet = false;  
                      this.loadingUpload = false;
                      this.loadingAnnotate = false;
                      // Adding flag for Header and Footer copysheet
                      this.Indicator.items[this.Indicator.stateParamsId].copy['headercopysheet'] = true;

                      if (data && data['response'] && data['response'] == null || data['response'] == undefined) {
                        if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
                          this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = true;
                        }
                        this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = data['copysheet'];
                        this.Indicator.items[this.Indicator.stateParamsId].copy.fileType = 'pdf';
                        this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
                        this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
                        //Adding flag for cancel copysheet FUL-37656,37657,FUL-37832
                        if (genType === "annotate"){
                          this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet'] = false;
                        }
                        this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
                            .then(() => { }).catch((err) => {
                              this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                            });
                        if((this.loadingAnnotate && this.Indicator.items[this.Indicator.stateParamsId].order.actionCode == 'Add') ||
                             this.loadingCopysheet || this.loadingUpload) {
                            this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet = true;
                            this.StorageService.updateField("copyz", this.storageID, { "data.generatedCopysheet": true })
                              .subscribe(() => { }, err => { console.log(" Error in update filed service "); });
                        }
                        if (genType === "view") {
                          var win = window.open(data['copysheet']);
                          if (win) {
                            win.focus();
                          }
                        } else if (genType === "upload") {
                          openAttachment ? this.openUploadAttachmentDialog() : this.openUploadDialog();
                        } else if (genType === 'annotate') {

                          this.indicatorStore.update(state => {
                            return {
                              indicator: this.Indicator,
                              updatedFrom: "[CopySheetComponent] generateCopysheet"
                            }
                          })
                          this.indicatorQuery.getIndicator().subscribe(data => {
                            this.Indicator = { ...data }
                          });
                          this.annotateCopysheet();
                        }
                        this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": false })
                          .subscribe(() => { }, err => { console.log(" erro in copy z call") });
                        //this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = true;
                        if (openAttachment !== undefined) {
                          console.log(" item componenet save button");
                          //var itemController = $controller('ItemController', { $scope: $scope });
                          //itemController.continueButton(); // save button in the item.components
                          // this.ItemComponent.saveButton();
                        }
                        //Reverting the code for IA grp
                        if (this.Indicator.items[this.Indicator.stateParamsId].data
                          && this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId) {
                          //go through the other items on the order
                          // Temp comment
                          // for (let key in this.Indicator.items) {
                          //   //this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
                          //   //if the other item has the matching ia group id & isn't the current item
                          //   if (this.Indicator.items[key].data
                          //     && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
                          //     && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId) {
                          //     //add the copysheet to the other item
                          //     this.Indicator.items[key].copy.copysheet = data['copysheet'];
                          //     this.Indicator.items[key].copy.fileType = 'pdf';
                          //     this.Indicator.items[key].hasCopysheet = true;
                          //     this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                          //       .then(res => { }).catch(err => { console.log(" Error in update copy sheet ", err) });
                          //     this.updateIAGroupCounter(key, this.Indicator);
                          //     this.Indicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                          //     this.ngAfterViewChecked();
                          //   }
                          // }
                        }
                      } else if (data['response'].hasWarningOrError === "true") {
                        // this.params['saving'] = false;
                        // this.params['loadingbar'] = false;
                        console.log("Else if block:",data);
                        this.loadingUpload = false;
                        this.loadingAnnotate = false;
                        var errorObject = data['response'].esbServiceFault;
                        var errorCode = errorObject.sourceSystem;
                        var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                        var errorMsg = "<strong>Transaction : " + data['response'].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        this.ngxspinner.hide();
                        this.Logger.logError(errorMsg);
                        if (this.Indicator.items[this.Indicator.stateParamsId].hasOwnProperty("generatedCopysheet")  && !this.Indicator.items[this.Indicator.stateParamsId]["generatedCopysheet"]){
                          this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
                          this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
                                      .then(() => { }).catch((err) => {
                                        this.Logger.logError("Failed to update copysheetII :" + JSON.stringify(err));
                                      });
                        }
                      } else {
                        this.params['saving'] = false;
                        this.params['loadingbar'] = false;
                        this.loadingUpload = false;
                        this.loadingAnnotate = false;
                        console.log("into error condition while annotate copy sheet", data);
                        this.Logger.logError(data['message']);
                      }
                    })
                    .catch((response) => {
                      console.log("Catch block-get copysheets",response)
                      this.ngxspinner.hide();
                      this.loadingUpload = false;
                      this.loadingCopysheet = false;
                      this.loadingAnnotate = false;
                      if (response.response && response.response.hasWarningOrError == "true") {
                        //   vm.saving = false;
                        var errorObject = response.response.esbServiceFault;
                        var errorCode = errorObject.sourceSystem;
                        var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                        var errorMsg = "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        this.Logger.logError(errorMsg);
                      } else {
                        this.Logger.logError(response.message);
                      }
                    });
                })
                .catch(err => {
                  console.log("Catch II:",err)
                  this.loadingUpload = false;
                  this.loadingAnnotate = false;
                  this.params['saving'] = false;
                  this.params['loadingbar'] = false;
                });
            } else {
              this.loadingUpload = false;
              this.loadingAnnotate = false;
              this.params['saving'] = false;
              this.params['loadingbar'] = false;
              var errorObject = data.esbServiceFault[0];
              var errorCode = errorObject.sourceSystem;
              var errorDesc = errorObject.exceptionDetail[0].errorDescription;
              var errorMsg = "<strong>Transaction : " + data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              this.Logger.logError(errorMsg);
              console.log("Else block:",errorMsg)
            }
          }).catch((response) => {
            console.log("Catch Block III",response)
            this.loadingUpload = false;
            this.loadingAnnotate = false;
            console.log(" error : ", response);
            this.params['saving'] = false;
            this.params['loadingbar'] = false;
          });
        } else { }
      }).catch(err => {
        console.log(" erro 2 : ", err);
        this.loadingUpload = false;
        this.loadingAnnotate = false;
        this.params['saving'] = false;
        this.params['loadingbar'] = false;
      });
    } else {
      this.Logger.logError(" Please select Billing Account");
      this.ngxspinner.hide();
      this.loadingUpload = false;
      this.loadingAnnotate = false;
    }
  }

  convertImageToPdf(imageUrl: any) {
    let pdf_Base64: any;
    let copySheetUrl: string;
    let fd = new FormData();
    let filename = Math.round(Math.random() * 1000000000000) + "";

    console.log('Inside convertImageToPdf method ', imageUrl);
    return new Promise(async (resolve, reject) => {
      try {
        // Trigger the file service to fetch pdf in base64 format
        pdf_Base64 = await this.file.imageToPdf(imageUrl);
        // Convert the pdf in base64 format to blob
        console.log(pdf_Base64);
        const base64Response = await fetch(`data:image/jpeg;base64,${pdf_Base64}`);
        // const base64Response = await fetch(pdf_Base64);
        const blob = await base64Response.blob();
        // Trigger the service to save the pdf as copysheet in mongo database
        fd.append("files", blob, filename + '.' + 'pdf');
        fd.append("user", JSON.parse(JSON.stringify({ parameters: { accountId: this.Indicator.order.accountId, svOrderId: this.Indicator.order.orderId } })));
        this.file.savePdf(fd).then(resp => {
          console.log('resp :: ', resp);
          resolve(resp['copysheet']);
        });
      } catch (error) {
        reject(error)
      }
    });
  }

  openUploadDialog() {
    if (this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId] && this.Indicator.items[this.Indicator.stateParamsId].isCoysheetAnnotated) {
      this.Logger.logError('Please save annotated copysheet.');
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = 'upload-copysheet-modal';
      dialogConfig.data = {
        dialogform: this.copyDialogData
      }
      this.ngxspinner.hide();
      let dialogRef = this.dialog.open(CopysheetUploadComponent, dialogConfig);
      this.copyDialogData.dialogRef = dialogRef
      var copysheet;

      dialogRef.afterClosed().subscribe(async data => {
        this.loadingUpload = false;
        if (data && data.uploadedFile && data.uploadedFile.copysheet) {
          //Code changes for upload copysheet new scenario
          let resData = { ...data};
          console.log("Upload Response:",resData)
          let fileData : any = resData.fileObj;
          console.log("File obj",fileData)
          this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
          this.Indicator.items[this.Indicator.stateParamsId].copy['uploadFile'] ={};
          this.Indicator.items[this.Indicator.stateParamsId].copy['uploadFile']= resData; 																																																					
          console.log("UploadFile",this.Indicator.items[this.Indicator.stateParamsId].copy['uploadFile'])																														  
          this.Indicator.items[this.Indicator.stateParamsId]['isCopySheetUploaded'] = true;
          this.ngAfterViewChecked();
              this.indicatorStore.update(state => {
                console.log("Indicator Before update:",this.Indicator)
                return {
                  indicator: this.Indicator,
                  updatedFrom: "[CopySheetComponent] openUploadDialog"
                }
              })
              this.indicatorQuery.getIndicator().subscribe(data => {
                this.Indicator = JSON.parse(JSON.stringify(data));
                console.log("Indicator After Update:",this.Indicator)
              });

              // let msg = "Please click on 'OK' for the copysheet to be replaced, Else click on 'CANCEL' and view/annotate for the copysheet to be added as annotation.";
              // const dialogConfig = new MatDialogConfig();
              // dialogConfig.disableClose = true;
              // dialogConfig.autoFocus = true;
              // dialogConfig.panelClass = 'confirmaton-dialog';
              // dialogConfig.data = {
              //   message: msg,
              // }
              // dialogConfig.width = '600px';
              // const dialogRefConfirm = this.matDialog.open(CallconfirmationComponent, dialogConfig);
              // dialogRefConfirm.afterClosed().subscribe(async dialogResult => {
              //   console.log(dialogResult)
              //   if(dialogResult){
              //      this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
              //     this.Indicator.items[this.Indicator.stateParamsId]['isCopySheetUploaded'] = false;
              //     this.indicatorStore.update(state => {
              //       console.log("Indicator Before update:",this.Indicator)
              //       return {
              //         indicator: this.Indicator,
              //         updatedFrom: "[CopySheetComponent] Dialog result upload copysheet"
              //       }
              //     })
              //     this.indicatorQuery.getIndicator().subscribe(data => {
              //       this.Indicator = JSON.parse(JSON.stringify(data));
              //       console.log("Indicator After Update:",this.Indicator)
              //     });
              //   }
              // })

          // Adding flag for Header and Footer copysheet
          this.Indicator.items[this.Indicator.stateParamsId].copy['headercopysheet'] = false;
          //Adding flag for view annotate and cancel for the first time => FUL-37657,37656  
          if(!this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet){
            this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet'] = true;
          }
          this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = true;
          this.tempCalltracking = this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet;
          //this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['manageCopyModified'] = true;
          if (this.Indicator.items[this.Indicator.stateParamsId].order.productType == "Digital" && this.Indicator.items[this.Indicator.stateParamsId].order.productCode == "YPC") {
            this.Indicator.items[this.Indicator.stateParamsId].model['manageCopyModified'] = true;
          } else {
            this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['manageCopyModified'] = true;
          }
          //Adding flag for adVersionIncInd of copysheet
          //this.Indicator.items[this.Indicator.stateParamsId]['adVersionIncInd'] = 'Y';
          this.StorageService.updateField("item", this.Indicator.stateParamsId, { "data.adVersionIncInd": "Y" }).subscribe(res => {
            console.log(res)
         });
          this.ngAfterViewChecked();
          if (data && data.uploadedFile.copysheet != null) {
            if (data.fileType === 'copysheet') {
              this.Indicator.items[this.Indicator.stateParamsId].isCoysheetSubmitted = true;
              this.StorageService.updateField("copyz",
                this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version,
                { "data.isCoysheetSubmitted": true }
              ).subscribe(data => { }, err => { })
            }

            let copsheetFileType = (data.uploadedFile.copysheet.split(".")[data.uploadedFile.copysheet.split(".").length - 1]).toLowerCase();
            let pdf_copysheet_url;
            console.log(copsheetFileType);            
            if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {
             
              await this.convertImageToPdf(data.uploadedFile.copysheet).then((pdfurl) => {
                console.log("pdfurl :: ", pdfurl);
                pdf_copysheet_url = pdfurl;
              }).catch((err) => {
                console.log('Exception in converting image to PDF : ', err)
              })
            } else {
              pdf_copysheet_url = data.uploadedFile.copysheet;
            }

            if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
              this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = true;
            }
            let oldCopysheet;
            if(this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId] && this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet == true) {
              oldCopysheet = this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet;
            }else{
              oldCopysheet = null;
            }
            /*
            this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = data.uploadedFile.copysheet;
            this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet = data.uploadedFile.copysheet;
            this.Indicator.items[this.Indicator.stateParamsId].actionListObj = [];
            copysheet = data.uploadedFile.copysheet;
            */
            this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = pdf_copysheet_url;
            this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet = pdf_copysheet_url;
            copysheet = pdf_copysheet_url;

            if (!oldCopysheet) {
              this.updateIAGroupCounter(this.Indicator.stateParamsId, this.Indicator);
            }
            this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
            this.Indicator.items[this.Indicator.stateParamsId].copy.fileType = copysheet ? copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length) : '';
            /*
            if (this.Indicator.items[this.Indicator.stateParamsId].copy.fileType.toLowerCase() === 'png' || this.Indicator.items[this.Indicator.stateParamsId].copy.fileType.toLowerCase() === 'jpeg' || this.Indicator.items[this.Indicator.stateParamsId].copy.fileType.toLowerCase() === 'jpg') {
              if (!this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                this.Indicator.items[this.Indicator.stateParamsId].copy.attachments = [];
                this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.push(this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet);
              }
              else {
                this.Indicator.items[this.Indicator.stateParamsId].copy['attachments'].push(this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet);
              }
            }
            */
            if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {
              if (!this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                this.Indicator.items[this.Indicator.stateParamsId].copy.attachments = [];
                this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.push(data.uploadedFile.copysheet);
              }
              else {
                this.Indicator.items[this.Indicator.stateParamsId].copy['attachments'].push(data.uploadedFile.copysheet);
              }
            }
            this.Copysheet.updateCopysheet(this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].copy)
              .then((response) => { 
                console.log('response is ***********--------',response);
                console.log('indicator object',this.Indicator);
                var activityLogHistoryObj = {
                  'displayName': this.Indicator.user.name,
                  'email': this.Indicator.user.email,
                  'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                  'itemId': this.Indicator.stateParamsId,
                  'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
                  'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
                  'fieldName': 'Copysheet Value',
                  'fieldOldValue': oldCopysheet,
                  'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet,
                  'apiStatus' : 'Success'
                }
                this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
              }
                , err => {
                  this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                  var activityLogHistoryObj = {
                    'displayName': this.Indicator.user.name,
                    'email': this.Indicator.user.email,
                    'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                    'itemId': this.Indicator.stateParamsId,
                    'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
                    'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
                    'fieldName': 'Copysheet Value',
                    'fieldOldValue': oldCopysheet,
                    'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet,
                    'apiStatus' : 'Failure'
                  }
                  this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
                });

            this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = true;
            //Update CopysheetIndicatorValue
            //this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
            this.Indicator.items[this.Indicator.stateParamsId].model['digitalApiFlag'] = true;
            this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
              .subscribe(() => { }, err => { })
            if(this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet && typeof this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet === "object"){
                this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.manageCopyModified = true;
                this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';                    
            } else {
              this.Indicator.items[this.Indicator.stateParamsId].model.manageCopyModified = true;
              this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet = 'Y';                    
            }
            this.Indicator.items[this.Indicator.stateParamsId].isCopysheetSaved = true;  
            var activityLogHistoryObj = {
              'displayName': this.Indicator.user.name,
              'email': this.Indicator.user.email,
              'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
              'itemId': this.Indicator.stateParamsId,
              //'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
              'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
              'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
              'fieldName': 'Copysheet Value',
              'fieldOldValue': oldCopysheet,
              'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet
            }
            this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
            // var activityLogHistoryObj = {
            //   'displayName': this.Indicator.user.name,
            //   'email': this.Indicator.user.email,
            //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
            //   'itemId': this.Indicator.stateParamsId,
            //   'fieldName': 'Copysheet Value',
            //   'fieldOldValue': oldCopysheet,
            //   'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet
            // }
            // this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
            this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
              .subscribe(() => { }, err => { })
              
            this.Logger.log('Copysheet uploaded successfully.');
            let stateParamsId = this.Indicator.stateParamsId;
            if (this.Indicator.items[stateParamsId].requiresCopysheet) {
              let tempData = this.requiredfieldcount.getNewItemCount(this.Indicator["items"][stateParamsId].requiredFields, this.Indicator["items"][stateParamsId].model, this.Indicator["items"][stateParamsId].order, this.Indicator["orderPercent"], this.Indicator["items"][stateParamsId].counter, true, this.Indicator["items"][stateParamsId]);
              this.updateIndictor(stateParamsId, this.Indicator, tempData, this.Indicator.items[stateParamsId].counter, true);
            }
            this.loadingUpload = false;
            this.ngAfterViewChecked();
            this.indicatorStore.update(state => {
              return {
                indicator: this.Indicator,
                updatedFrom: "[CopySheetComponent] openUploadDialog"
              }
            })
            //Reverting the code for IA grp
            //if the item is part of an IA group
            if (this.Indicator.items[this.Indicator.stateParamsId].data
              && this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId) {
              //go through the other items on the order
              for (var key in this.Indicator.items) {
                this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
                //if the other item has the matching ia group id & isn't the current item
                if (this.Indicator.items[key].data
                  && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
                  && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId) {
                  //add the copysheet to the other item
                  this.Indicator.items[key].copy.copysheet = data.uploadedFile.copysheet;
                  this.Indicator.items[key].baseCopysheet = data.uploadedFile.copysheet;
                  this.Indicator.items[key].hasCopysheet = true;
                  //Adding flag for adVersionIncInd of copysheet
                  //this.Indicator.items[key]['adVersionIncInd'] = 'Y';
                  this.StorageService.updateField("item", key, { "data.adVersionIncInd": "Y" }).subscribe(res => {
                    console.log(res)
                 });
                  // update copysheetIndicator value 
                  if (this.Indicator.items[key].order.productType === "Print"){
                    if (this.Indicator.items[key].model.hasOwnProperty('ManageCopysheet')){
                        this.Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                        this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                    }else{
                        this.Indicator.items[key].model['ManageCopysheet'] = {};
                        this.Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                        this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                    }
                    this.Indicator.items[key].model['digitalApiFlag'] = true;
                  }else{
                      this.Indicator.items[key].model['copySheetIndicator'] = 'Y';
                      this.Indicator.items[this.Indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
                      this.Indicator.items[key].model['digitalApiFlag'] = true;
                  }
                  this.Indicator.items[key].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                  if (this.Indicator.items[key].copy.fileType.toLowerCase() === 'png' || this.Indicator.items[key].copy.fileType.toLowerCase() === 'jpeg' || this.Indicator.items[key].copy.fileType.toLowerCase() === 'jpg') {
                    if (!this.Indicator.items[key].copy.hasOwnProperty('attachments')) {
                      this.Indicator.items[key].copy.attachments = [];
                      this.Indicator.items[key].copy.attachments = this.Indicator.items[this.Indicator.stateParamsId].copy.attachments;
                    }
                    else {
                      this.Indicator.items[key].copy['attachments'] = this.Indicator.items[this.Indicator.stateParamsId].copy.attachments;
                    }
                  }
                  // if (this.Indicator.items[key].hasOwnProperty('actionListObj')) {
                  //   this.Indicator.items[key].actionListObj = this.Indicator.items[this.Indicator.stateParamsId].actionListObj;
                  // }
                  this.updateIAGroupCounter(key, this.Indicator);
                  //update hasCopysheet flag in storage call
                      let currentStorageId = key + 'v'+ this.Indicator.items[key].order.version;
                      this.StorageService.updateField("copyz", currentStorageId, { "data.hasCopysheet": true}).subscribe(()=>{},err=>{});
  
                  this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
                  this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
                  this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
                  this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                    .then((response) => { })
                    .catch(
                      err => {
                        this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                      });
                        //fix for FUL-40365 --start
                      var activityLogHistoryObj1 = {
                        'displayName': this.Indicator.user.name,
                        'email': this.Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                        'itemId': key,
                        'itemStatus': this.Indicator.items[key].order.status,
                        'itemActionCode':this.Indicator.items[key].order.actionCode + "-" + this.Indicator.items[key].order.actionType,
                        'fieldName': 'Copysheet Value',
                        'fieldOldValue': oldCopysheet,
                        'fieldNewValue': this.Indicator.items[key].copy.copysheet
                      }
                      this.StorageService.update("itemHistory", key, activityLogHistoryObj1);
                }
              }
            }       
          }
        } 
        else if(!data){ 
          if ( this.Indicator.items[this.Indicator.stateParamsId].hasOwnProperty("hasCopysheet")  && !this.Indicator.items[this.Indicator.stateParamsId]["hasCopysheet"]){
            this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
            //this.Indicator.items[this.Indicator.stateParamsId]['baseCopysheet'] =null;
            this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
                        .then(() => { }).catch((err) => {
                          this.Logger.logError("Failed to update copysheetII :" + JSON.stringify(err));
                        });
          }              
        }
      });
    }
  }

  updateIAGroupCounter(itemID, Indicator) {
    // var count = this.requiredFieldCount.get(this.Indicator.items[itemID].schema, this.Indicator.items[itemID].form, this.Indicator.items[itemID].model, this.Indicator.items[itemID].order);
    // this.Indicator.items[itemID].counter = count;

    // if (this.Indicator.items[itemID].requiresCopysheet
    //   && !this.Indicator.items[itemID].hasCopysheet) {
    //  // this.Indicator.items[itemID].counter++;
    //  let tempData = this.requiredfieldcount.getNewItemCount(this.Indicator["items"][itemID].requiredFields, this.Indicator["items"][itemID].model, this.Indicator["items"][itemID].order, this.Indicator["orderPercent"], this.Indicator["items"][itemID].counter, true, this.Indicator["items"][itemID]);
    //  this.updateIndictor(itemID,this.Indicator, tempData, count);
    // }

    let id = itemID;
    let currentCount = Indicator.items[itemID].counter;
    var count = this.requiredFieldCount.get(Indicator.items[itemID].schema, Indicator.items[itemID].form, Indicator.items[itemID].model, Indicator.items[itemID].order);
    Indicator.items[itemID].counter = count;
    if (Indicator.items[itemID].requiresCopysheet
      && !Indicator.items[itemID].hasCopysheet) {
      // Indicator.items[itemID].counter++;
      let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][itemID].requiredFields, Indicator["items"][itemID].model, Indicator["items"][itemID].order, Indicator["orderPercent"], Indicator["items"][itemID].counter, true, Indicator["items"][itemID]);
      this.updateIndictor(itemID, Indicator, tempData, count, true);
      //  if(Indicator['items'][itemID].counter == 0) {
      //    document.getElementById(itemID+'counter').classList.remove('item-order-error');
      //  } else if(Indicator['items'][itemID].counter != 0) {
      //   document.getElementById(itemID+'counter').classList.add('item-order-error');
      //  }
    } else {
      //Indicator =indicatorVal; //JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      Indicator.items[itemID].counter = currentCount;
      let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][id].requiredFields, Indicator["items"][id].model, Indicator["items"][id].order, Indicator["orderPercent"], Indicator["items"][id].counter, true, Indicator["items"][id]);
      this.updateIndictor(id, Indicator, tempData, currentCount, true);
      // if(Indicator['items'][itemID].counter == 0) {
      //   document.getElementById(itemID+'counter').classList.remove('item-order-error');
      // } else if(Indicator['items'][itemID].counter != 0) {
      //  document.getElementById(itemID+'counter').classList.add('item-order-error');
      // }
    }
  }

  async annotateCopysheet() {
    // this.indicatorStore.update(state =>{
    //   return{
    //     indicator: this.Indicator
    //   }
    // })
    this.doNotOpenModal = false;
    this.Indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));

    if (this.Indicator.items[this.Indicator.stateParamsId].copy &&
      this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet != 'null') {
      if(!this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints){
         this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints = {}
      }
      if(this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty('attachments')){
        //this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.attachments = this.Indicator.items[this.Indicator.stateParamsId].copy.attachments        
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.attachments = Object.assign([], this.Indicator.items[this.Indicator.stateParamsId].copy.attachments);
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.attachments = []
      }  
      this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.copysheetSavePoint = this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet
      if(this.Indicator.items[this.Indicator.stateParamsId].actionListObj){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.actionListObj = this.Indicator.items[this.Indicator.stateParamsId].actionListObj
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.actionListObj = []
      }
     // this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.ManageCopysheet = this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
      if(typeof(this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet) == 'object'){
       this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.ManageCopysheet = Object.assign({}, this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet)
      } else{
       this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.ManageCopysheet = this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
      } 
 
     if(this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.hasCopysheet = this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.hasCopysheet = false
      }
      if(this.Indicator.items[this.Indicator.stateParamsId].isCorrupted){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCorrupted = this.Indicator.items[this.Indicator.stateParamsId].isCorrupted
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCorrupted = false        
      }
      if(this.Indicator.items[this.Indicator.stateParamsId].isCoysheetSubmitted){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = this.Indicator.items[this.Indicator.stateParamsId].isCoysheetSubmitted
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = false
      }
      if(this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.generatedCopysheet = this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.generatedCopysheet = false
      }
      if(this.Indicator.items[this.Indicator.stateParamsId].isCopysheetSaved){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCopysheetSaved = this.Indicator.items[this.Indicator.stateParamsId].isCopysheetSaved
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCopysheetSaved = false
      }
      if(this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet){
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.needsNewCopysheet = this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet
      }else{
        this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.needsNewCopysheet = false
      }


      var CSfileType = "";
      this.copysheet = this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet ? this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet : this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet;
      let localCopysheet =  this.copysheet;
      if (localCopysheet && localCopysheet.split('.').pop() == 'undefined'){
        let currentUrl = localCopysheet;
        let updateCopysheetURL = currentUrl.slice(0,currentUrl.length-9)+'pdf';
        this.copysheet = updateCopysheetURL;
      }else if (localCopysheet && localCopysheet.split('.').pop() == null){
        this.copysheet = localCopysheet+'pdf';
      }
      if (this.copysheet) {
        var CSURL = this.copysheet;
        CSfileType = CSURL.slice(CSURL.lastIndexOf(".") + 1, CSURL.length);
      }
      if (this.copysheet) {
        try {
          this.ngxspinner.show();
          if (this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet'] && typeof(this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']) == "object"){
            this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
          }else{
            this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet'] = 'Y';
            //Fix for FUL-37853
            this.Indicator.items[this.Indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
          }
          this.Indicator.items[this.Indicator.stateParamsId].model['digitalApiFlag'] = true;
          var fileNameLocal = this.copysheet.split('/').pop();
          if (CSfileType == 'pdf') {
            try {
              let pdfValidationObservable = this.file.validatePDF(fileNameLocal)
              let pdfValidationPromise = pdfValidationObservable.toPromise();
             await pdfValidationPromise.then(async (res) => {
                if (res == "Invalid PDF structure" || res == "No password given") {
                  this.doNotOpenModal = true;
                  if (res == "No password given"){
                    this.Logger.logError(" Cannot View/Annotate a password protected PDF File");
                  }else{
                    this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");
                  }
                  this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = false;
                  this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
                    .subscribe(() => { }, err => { })

                  this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.fileType = '';
                  if (this.Indicator.items[this.Indicator.stateParamsId].requiresCopysheet) {
                    // this.Indicator.items[this.Indicator.stateParamsId].counter++;
                    let stateParamsId = this.Indicator.stateParamsId;
                    let tempData = this.requiredfieldcount.getNewItemCount(this.Indicator["items"][stateParamsId].requiredFields, this.Indicator["items"][stateParamsId].model, this.Indicator["items"][stateParamsId].order, this.Indicator["orderPercent"], this.Indicator["items"][stateParamsId].counter, true, this.Indicator["items"][stateParamsId]);
                   await this.updateIndictor(stateParamsId, this.Indicator, tempData, this.Indicator.items[stateParamsId].counter, true);
                  }
                  /*
                  pdfModalInstance.afterClosed().subscribe(dialogResult => {
                    console.log('dialogue has benn close with error : ', dialogResult);
                  });
                  */
                } else { }
                }).catch(err => {
                if (!(err.hasOwnProperty('error') && err.error.hasOwnProperty('code') &&  err.error.code == 'InternalError' && err.error.hasOwnProperty('message')  && err.error.message.substr(-22) == "not opened for writing")) {
                  this.doNotOpenModal = true;
                }
                this.ngxspinner.hide();
                console.log("error in vlaidating the pdf file ", err);
                this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");
                return false;
              });
            } catch (e) {
              this.doNotOpenModal = true;
              this.ngxspinner.hide();
              console.log(" error in annotate copy sheet ", e)
            }
          } else if (CSfileType == 'png' || CSfileType == 'jpg' || CSfileType == 'jpeg') {
            console.log('Starting image validation')
            let imageValidationObservable = this.file.validateImage(fileNameLocal);
            let imageValidationPromise = imageValidationObservable.toPromise();
            await imageValidationPromise.then(async (res) => {
              console.log("Image validation", res);
              if (!res) {
                this.doNotOpenModal = true;
                this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed!");

                this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
                  .subscribe(() => { }, err => { })
                this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
                this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
                this.Indicator.items[this.Indicator.stateParamsId].copy.fileType = '';
                this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = false;
                if (this.Indicator.items[this.Indicator.stateParamsId].requiresCopysheet) {
                  // this.Indicator.items[this.Indicator.stateParamsId].counter++;
                  let stateParamsId = this.Indicator.stateParamsId;
                  let tempData = this.requiredfieldcount.getNewItemCount(this.Indicator["items"][stateParamsId].requiredFields, this.Indicator["items"][stateParamsId].model, this.Indicator["items"][stateParamsId].order, this.Indicator["orderPercent"], this.Indicator["items"][stateParamsId].counter, true, this.Indicator["items"][stateParamsId]);
                  await this.updateIndictor(stateParamsId, this.Indicator, tempData, this.Indicator.items[stateParamsId].counter, true);
                }
                // this.pdfModalInstance.close();
              }
            });
          }
        }
        catch (ex) {
          this.doNotOpenModal = true;
          this.ngxspinner.hide();
        }
        this.ngxspinner.hide();
      }
      if (!this.doNotOpenModal) {
        this.fileType = this.Indicator.items[this.Indicator.stateParamsId].copy.fileType;
        if ((this.fileType && CSfileType && this.fileType !== CSfileType)
          || (!this.fileType && CSfileType)) {
          this.fileType = CSfileType;
        } else if (!this.fileType && !CSfileType) {
          this.Logger.logError("Failed to open copysheet. Please upload the copysheet again.");
          return;
        }
        this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet = this.copysheet;
        // this.actionListObj = this.Indicator.items[this.Indicator.stateParamsId].actionListObj;

        this.indicatorStore.update(state => {
          return {
            indicator: this.Indicator,
            updatedFrom: "[CopySheetComponent] annotateCopysheet"
          }
        })
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.panelClass = 'view-annotate-dialog';
        // dialogConfig.data = {
        //   dialogform: this.dialogData,
        // }
        // let pdfModalInstance: any;
        // this.ngxspinner.hide();

        //pdfModalInstance = this.matDialog.open(CopysheetAnnotateComponent, dialogConfig);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'confirmaton-dialog-foxit';
        let pdfModalInstance: any;
        this.ngxspinner.hide();

        pdfModalInstance = this.matDialog.open(PDFViewerComponent, dialogConfig);
        this.dialogData['dilogReference'] = pdfModalInstance;
        pdfModalInstance.afterClosed().subscribe(dialogResult => {
          console.log('Annotate dialogue has been close : ', dialogResult);
          /* To avoid the console error while modifying "hasCopysheet" to false */
          this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify(data));
          });
          if (dialogResult) this.tempCalltracking = true;
          else {
            this.tempCalltracking = this.tempCalltracking;
            if(this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId] && !this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet){
              this.Indicator.items[this.Indicator.stateParamsId]['hasCopysheet'] = false;
            }
            //Commented as part of View Annotate and Cancel for the first time => FUL-37657,37656
            // if ( this.Indicator.items[this.Indicator.stateParamsId].hasOwnProperty("generatedCopysheet")  && !this.Indicator.items[this.Indicator.stateParamsId]["generatedCopysheet"]){
            //   this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
            //   //this.Indicator.items[this.Indicator.stateParamsId]['baseCopysheet'] =null;
            //   this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
            //   .subscribe(() => { }, err => { })
            // }
            //View Annotate and Cancel for the first time => FUL-37657,37656
            if(this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty('cancelCopySheet') && !this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet']  && !this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet){
              this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
              //this.Indicator.items[this.Indicator.stateParamsId]['baseCopysheet'] =null;
              this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
              .subscribe(() => { }, err => { })
              this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
                        .then(() => { }).catch((err) => {
                          this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                        });
              if (this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet && typeof this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet === "object") {
                this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'N';
              } else {
                this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet = 'N';
              }
            }
            if (!this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet) {
              this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet'] = false;
              this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
                .then(() => { }).catch((err) => {
                  this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                });
            }
          }
          if (dialogResult){
            //let itemCopysheet = this.Indicator.items[this.Indicator.stateParamsId].copy.copySheet;
            if (this.Indicator.items[this.Indicator.stateParamsId].data
              && this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId) {
              //go through the other items on the order
              for (var key in this.Indicator.items) {
                //if the other item has the matching ia group id & isn't the current item
                // this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
                if (this.Indicator.items[key].data
                  && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
                  && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId) {
                  //add the copysheet to the other item
                  //this.Indicator.items[key].copy.instructions = this.addlInstructions;
                  this.Indicator.items[key].copy['copysheet'] = this.Indicator.items[this.Indicator.stateParamsId].copy['copysheet'];
                  this.Indicator.items[key].copy['fileType'] = this.Indicator.items[this.Indicator.stateParamsId].copy['fileType'];

                  this.Indicator.items[key]['baseCopysheet'] = this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet;
                  // this.Indicator.items[key].actionListObj = this.Indicator.items[this.Indicator.stateParamsId].actionListObj;
                  this.Indicator.items[key]['hasCopysheet'] = true;
                  //Adding flag for adVersionIncInd of copysheet
                  //this.Indicator.items[key]['adVersionIncInd'] = 'Y';
                  this.StorageService.updateField("item", key, { "data.adVersionIncInd": "Y" }).subscribe(res => {
                    console.log(res)
                 });
                  // update copysheetIndicator value 
                  if (this.Indicator.items[key].order.productType === "Print"){
                    if (this.Indicator.items[key].model.hasOwnProperty('ManageCopysheet')){
                        this.Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                        this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                    }else{
                        this.Indicator.items[key].model['ManageCopysheet'] = {};
                        this.Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                        this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                    }
                    this.Indicator.items[key].model['digitalApiFlag'] = true;
                  }else{
                        this.Indicator.items[key].model['copySheetIndicator'] = 'Y';
                        this.Indicator.items[this.Indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
                        this.Indicator.items[key].model['digitalApiFlag'] = true;
                  }
                  this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
                  this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
                  this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                    .then(function () { })
                    .catch(function (err) {
                      this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                    });
                  // storage update--Ashutosh
                  this.StorageService.updateField("copyz", key + "v"+ this.Indicator.items[key].order.version, {"data.hasCopysheet" : true}).subscribe(data =>{},err =>{});
                  this.updateIAGroupCounter(key, this.Indicator);
                  this.Indicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                }
              }
            }
            //Adding flag for cancel copysheet FUL-37656,37657,FUL-37832
            this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet'] = false;
            this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
              .then(() => { }).catch((err) => {
                this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
              });
          }
          this.ngAfterViewChecked();
          /*
          this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify(data));
          });
          */
          this.indicatorStore.update(state => {
            return {
              indicator: this.Indicator,
              updatedFrom: "[CopySheetComponent] annotateCopysheet1"
            }
          })
       });
       console.log('Cookies before opening child window ', this.cookieService.getAll());
       console.log('Params before opening child window ', this.params.params);
       // if (!(this.cookieService.get('token').length > 0)) {          
       //   for (let key in window['cookies']) {            
       //     this.cookieService.set(key, window['cookies'].key)
       //   }
       // }
       console.log(this.API);        
      //  let childWindowUrl = this.API.pdfUrl+ "/pdf?token=" + this.cookieService.get('token') + "&locked=false&admin=false&api=" + this.API.apiUrl + "&payNow="+ this.cookieService.get('payNow') +"&sfaUrl="+ this.cookieService.get('sfaUrl') +"&sideMenuPayNow="+ this.cookieService.get('sideMenuPayNow') +"&sforg=national&account="+ this.Indicator.account.id  +"&order=" + this.Indicator.order.sfdcOrderId + "&mode=QuickBP&listingId=";
      //let childWindowUrl = this.API.pdfUrl+ "/pdf?&api=" + this.API.apiUrl+"&mode=QuickBP&listingId="
      //  let childWindowUrl = this.API.pdfUrl+ "/pdf?&api=" + this.API.apiUrl+"&account="+ this.Indicator.account.id  +"&order=" + this.Indicator.order.sfdcOrderId+"&mode=copysheet"
      // console.log('childWindowUrl :: ', childWindowUrl);
      //  this.ngxspinner.show();
      //  winReference = window.open(childWindowUrl);
      //  winReference['parentIndicator'] = this.Indicator;
      //  // if (this.cookieService.get('token').length > 0) {
      //  //   winReference['cookies'] = this.cookieService.getAll();
      //  //   window['cookies'] = this.cookieService.getAll();
      //  // }
      //  timer = setInterval(this.checkPdfWindow, 500);
     }
     else{
       this.ngxspinner.hide()
     }

    }
  }

  // checkPdfWindow() {
  //   console.log(this.ngxspinner)
  //   if (winReference.closed) {
  //     // logic to enable the current window elemnents
  //     console.log("PDF window is closed");
  //     console.log(winReference['childValue']);// Data fetched from child window                  
  //     clearInterval(timer);// remove the logic to check for closure at regular intervals
  //     self.closeViewAnnotate();
  //   }else{
  //     self.ngxspinner.show();
  //   }
  // }

  // closeViewAnnotate() {
  //   console.log('Inside closeViewAnnotate ');
  //   let dialogResult = winReference['closingBoolean'];
  //   this.ngxspinner.hide();    
  //   console.log('Annotate dialogue has been close : ', dialogResult);
  //   // Update the indicator store with the indicator received from child window
  //   if(winReference['childIndicator']){
  //     this.indicatorStore.update(() => {
  //       return {
  //         indicator: winReference['childIndicator'],
  //         updatedFrom: "[copy-sheet.component] closeViewAnnotate",
  //         isloaded: true,
  //       };
  //     });
  //   }    
  //   // To avoid the console error while modifying "hasCopysheet" to false
  //   this.indicatorQuery.getIndicator().subscribe(data => {
  //     this.Indicator = JSON.parse(JSON.stringify(data));
  //   });
  //   /* Reset the cookies */
  //   // if (!(this.cookieService.get('token') && this.cookieService.get('token').length > 0)){
  //     // console.log('calling params from copy sheet component')
  //     // this.params.params.mode =  null;
  //     // this.params.getParamsData(this.params.params, this.params.secFlag)
  //   // }
  //   // console.log('Cookies after child window is closed : ', this.cookieService.getAll())
  //   // console.log('Params after child window is closed : ', this.params.params)
  //   // console.log('Closing boolean value after child window is closed : ', winReference['closingBoolean'])

  //   if (winReference['closingBoolean']) this.tempCalltracking = true;
  //   else {
  //     this.tempCalltracking = this.tempCalltracking;
  //     if (this.Indicator.items[this.Indicator.stateParamsId] && !this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet) {
  //       this.Indicator.items[this.Indicator.stateParamsId]['hasCopysheet'] = false;
  //     }
  //     //Commented as part of View Annotate and Cancel for the first time => FUL-37657,37656
  //     // if (this.Indicator.items[this.Indicator.stateParamsId].hasOwnProperty("generatedCopysheet") && !this.Indicator.items[this.Indicator.stateParamsId]["generatedCopysheet"]) {
  //     //   this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
  //     //   console.log('calling  storageService updateField');
  //     //   this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
  //     //     .subscribe(() => { }, err => { })
  //     // }
  //     //View Annotate and Cancel for the first time => FUL-37657,37656
  //     if(this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty('cancelCopySheet') && !this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet']  && !this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet){
  //       this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = null;
  //       //this.Indicator.items[this.Indicator.stateParamsId]['baseCopysheet'] =null;
  //       this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
  //       .subscribe(() => { }, err => { })
  //       this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
  //                 .then(() => { }).catch((err) => {
  //                   this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //                 });
  //       if (this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet && typeof this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet === "object") {
  //         this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'N';
  //       } else {
  //         this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet = 'N';
  //       }
  //     }
  //     if (!this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet) {
  //       this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet'] = false;
  //       this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
  //         .then(() => { }).catch((err) => {
  //           this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //         });
  //     }
  //   }
  //   if (winReference['closingBoolean']) {
  //     if (this.Indicator.items[this.Indicator.stateParamsId].data
  //       && this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId) {
  //         //Remove the upload object - for upload copysheet
  //         if(this.Indicator.items[this.Indicator.stateParamsId].copy.uploadFile){
  //         this.Indicator.items[this.Indicator.stateParamsId].copy.uploadFile = {};
  //         }
  //       //go through the other items on the order
  //       for (var key in this.Indicator.items) {
  //         //if the other item has the matching ia group id & isn't the current item                
  //         if (this.Indicator.items[key].data
  //           && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
  //           && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId) {
  //           //add the copysheet to the other item                  
  //           this.Indicator.items[key].copy['copysheet'] = this.Indicator.items[this.Indicator.stateParamsId].copy['copysheet'];
  //           this.Indicator.items[key].copy['fileType'] = this.Indicator.items[this.Indicator.stateParamsId].copy['fileType'];
  //           this.Indicator.items[key]['baseCopysheet'] = this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet;
  //           this.Indicator.items[key].copy['previousCopySheet'] = this.Indicator.items[this.Indicator.stateParamsId].copy.previousCopySheet;
  //           this.Indicator.items[key]['hasCopysheet'] = true;
  //           //Adding flag for adVersionIncInd of copysheet
  //           this.StorageService.updateField("item", key, { "data.adVersionIncInd": "Y" }).subscribe(res => {
  //              console.log(res)
  //           });
  //           //Remove the upload object - for upload copysheet 
  //           if(this.Indicator.items[key].copy.uploadFile){
  //             this.Indicator.items[key].copy.uploadFile = {};
  //           }
  //           // update copysheetIndicator value 
  //           if (this.Indicator.items[key].order.productType === "Print") {
  //             if (this.Indicator.items[key].model.hasOwnProperty('ManageCopysheet')) {
  //               this.Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //               this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //             } else {
  //               this.Indicator.items[key].model['ManageCopysheet'] = {};
  //               this.Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //               this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //             }
  //             this.Indicator.items[key].model['digitalApiFlag'] = true;
  //           } else {
  //             this.Indicator.items[key].model['copySheetIndicator'] = 'Y';
  //             this.Indicator.items[this.Indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
  //             this.Indicator.items[key].model['digitalApiFlag'] = true;
  //           }
  //           this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
  //           this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
  //           console.log('calling  Copysheet updateCopysheet');
  //           this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
  //             .then(function () { })
  //             .catch(function (err) {
  //               this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //             });
  //           // storage update--Ashutosh
  //           console.log('calling  storageService updateField');
  //           this.StorageService.updateField("copyz", key + "v" + this.Indicator.items[key].order.version, { "data.hasCopysheet": true }).subscribe(data => { }, err => { });
  //           this.updateIAGroupCounter(key, this.Indicator);
  //           this.Indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
  //           //Adding flag for cancel copysheet FUL-37656,37657,FUL-37832
  //           this.Indicator.items[this.Indicator.stateParamsId].copy['cancelCopySheet'] = false;
  //           this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
  //             .then(() => { }).catch((err) => {
  //               this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //             });
  //         }
  //       }
  //     }
  //   }
  //   this.ngAfterViewChecked();
  //   this.indicatorStore.update(state => {
  //     return {
  //       indicator: this.Indicator,
  //       updatedFrom: "[CopySheetComponent] annotateCopysheet1"
  //     }
  //   })
  // }

  updateStore(event) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
    if(this.addlInstructions && this.addlInstructions.length <= this.addtnlMaxLength)
    {
    if (this.addlInstructions != null) {
      this.Indicator.items[this.Indicator.stateParamsId].copy.instructions = this.addlInstructions;
      //this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['manageCopyModified'] = true;
      if (this.Indicator.items[this.Indicator.stateParamsId].order.productType == "Digital" && this.Indicator.items[this.Indicator.stateParamsId].order.productCode == "YPC") {
        this.Indicator.items[this.Indicator.stateParamsId].model['manageCopyModified'] = true;
      } else {
        this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['manageCopyModified'] = true;
      }
      this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
      this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
      this.Copysheet.updateCopysheet(this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].copy)
        .then(() => {
          this.Logger.log("Additional Instructions updated Successfully");
        })
        .catch((err) => {
          this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
        });

      this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = true;
      //Reverting the code for IA grp
      //if the item is part of an IA group
      if (this.Indicator.items[this.Indicator.stateParamsId].data
        && this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId) {
        //go through the other items on the order
        for (var key in this.Indicator.items) {
          //if the other item has the matching ia group id & isn't the current item
          this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
          if (this.Indicator.items[key].data
            && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId
            && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId) {
            //add the copysheet to the other item
            this.Indicator.items[key].copy.instructions = this.addlInstructions;
            this.Indicator.items[this.Indicator.stateParamsId].copy['directoryNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
            this.Indicator.items[this.Indicator.stateParamsId].copy['directoryIssueNumber'] = this.Indicator.items[this.Indicator.stateParamsId].order.directoryIssueNumber;
            this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
              .then(function () { })
              .catch(function (err) {
                this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
              });
          }
        }
      }
      if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew') {
        this.Indicator.items[this.Indicator.stateParamsId].model['renewalCopysheetRequiredFlag'] = true;
        this.copysheetnotify(this.Indicator);

        this.indicatorStore.update(state => {
          return {
            indicator: this.Indicator,
            updatedFrom: "[CopySheetComponent] updateStoreForRenew"
          }
        });

        CopySheetComponent.reTrigger();     
        
        this.indicatorQuery.getIndicator().subscribe(data => {
          this.Indicator = JSON.parse(JSON.stringify(data));
        });

        let tempData = this.requiredfieldcount.getNewItemCount(this.Indicator["items"][this.Indicator.stateParamsId].requiredFields, this.Indicator["items"][this.Indicator.stateParamsId].model, this.Indicator["items"][this.Indicator.stateParamsId].order, this.Indicator["orderPercent"], this.Indicator["items"][this.Indicator.stateParamsId].counter, true, this.Indicator["items"][this.Indicator.stateParamsId]);
        this.updateIndictor(this.Indicator.stateParamsId, this.Indicator, tempData, this.Indicator.items[this.Indicator.stateParamsId].counter, false);
      }
      this.indicatorStore.update(state => {
        return {
          indicator: this.Indicator,
          updatedFrom: "[CopySheetComponent] updateStore"
        }
      });

      this.indicatorQuery.getIndicator().subscribe(data => {
        this.Indicator = JSON.parse(JSON.stringify(data));
      });
      this.ngAfterViewChecked();
    }
    } 
  }

  copysheetnotify(indicator){
    indicator.items[indicator.stateParamsId].hasCopysheet = false;
    this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
    .subscribe(()=>{},err=>{})
      if(indicator.items[indicator.stateParamsId].order.productCode == 'YPC' ||  indicator.items[indicator.stateParamsId].order.productType == "Print"){
          if(indicator.items[indicator.stateParamsId].order.productCode == 'YPC'){
            indicator.items[indicator.stateParamsId].model.ManageCopysheet = 'Y';
          }else{
            indicator.items[indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
          }
          indicator.items[indicator.stateParamsId].requiresCopysheet =  true;
      }
  }

  //Below update the counter code
  private updateIndictor(itemId, data, tempIndicator, currentCount, updateFlag) {
    let counter = [];
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    if(updateFlag == true){
      this.updatCounterStore(data);
    }

  }
  private updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[CopySheetComponent] updatCounterStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);

  }

  private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
  }
  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }
}
