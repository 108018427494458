import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GroupService } from '../group/group.service';
import { IndicatorQuery } from 'app/state/query';
import { SalesforceService } from '../salesforce/salesforce.service';
import { ProductService } from '../product/product.service';
import { LoggerService } from '../logger/logger.service';
import { constant } from '../../../data/constants';


@Injectable({
    providedIn: 'root'
})
export class ItemsaveService {
    indicator;
    upsertMapping1 = {};

    constructor(
        private grouper: GroupService,
        private indicatorQuery: IndicatorQuery,
        private sfService: SalesforceService,
        private productService: ProductService,
        private logger: LoggerService,
        private spinnerService: NgxSpinnerService
    ) { }

    async update(tempIndicator, multipleItemsArray): Promise<any> {
        return new Promise<[any]>(async (resolve, reject) => {
            this.spinnerService.show();
            let account = tempIndicator.account
            let order = tempIndicator.order
            let listings = tempIndicator.listing
            let upsertMapping = {}
            let contentArray = []
            let sfRequestArray = []
            upsertMapping['products'] = {
                product: {
                    type: "updateItem",
                    callerId: "UCC",
                    sourceId: order.accountId,
                    svOrderId: order.orderId,
                    customerId: account.kgenCustomerId,
                    operatorId: order.opportunity.owner.operatorId
                }
            };
            for (let i = 0; i < multipleItemsArray.length; i++) {
                let item = JSON.parse(JSON.stringify(tempIndicator.items[multipleItemsArray[i]]));
                let contentObj = {}
                let itemSfData = {}
                contentObj["svItemId"] = item.order.svItemId
                contentObj["copySheetIndicator"] = "Y"
                contentObj["billingAccountId"] = item.model.BillingAccount
                // Find the matching listing
                let key = null;
                let listing = null;
                let itemListingId = null;

                if (item.model.hasOwnProperty("ListingId")) {
                    key = "ListingId";
                    itemListingId = item.model.ListingId;
                }
                if (key != null && listings !== null && listings !== undefined && itemListingId !== null) {
                    if (listings instanceof Array) {
                        for (let i = 0; i < listings.length; i++) {
                            if (listings[i].listingId === itemListingId) {
                                listing = listings[i];
                                break;
                            }
                        }
                    } else {
                        if (listings.listingId === itemListingId) {
                            listing = listings;
                            // break;
                        }
                    }
                }

                let mapRequired = false;
                let isPrepaid = false;
                if (tempIndicator.billing && tempIndicator.billing.billing) {
                    for (let i = 0; i < tempIndicator.billing.billing.length; i++) {
                        if (tempIndicator.account.billingAccountSystem == constant.BRM_BILLING_DETAILS) {
                            if (item.model["BillingAccount"] == tempIndicator.billing.billing[i].billing_account_id && tempIndicator.billing.billing[i].profile.is_map == "true") {
                                mapRequired = true;
                                break;
                            }
                        } else {
                            if (item.model["BillingAccount"] == tempIndicator.billing.billing[i].billingAccountId &&
                                tempIndicator.billing.billing[i].digitalAdvPayInd == "Y") {
                                mapRequired = true;
                                break;
                            }
                        }

                    }
                    let Copy_Required__c = false;
                    for (let i = 0; i < item.form.length; i++) {
                        if (item.form[i].key != null && item.form[i].type === "managecopy") {
                            Copy_Required__c = (item.model[item.form[i].key].copySheetIndicator === "Y");
                            break;
                        }
                    }
                    let data = {
                        Billing_Account_ID__c: item.model["BillingAccount"],
                        Billing_Basis__c: item.model["BillingBasis"],
                        Billing_MAP_Required__c: mapRequired,
                        Copy_Required__c: Copy_Required__c
                    };

                    if (key === "ListingId") {
                        data['Listing_Id__c'] = item.model["ListingId"];
                        if (listing) {
                            data['Listing__c'] = this.formatPhoneNumber(listing.listedTN);
                            data['Listing_Name__c'] = listing.listedName;
                        }
                        item.order.listingId = item.model["ListingId"];
                    }
                    let billingId = data['Billing_Account_ID__c'] ? data['Billing_Account_ID__c'] : item.model.BillingAccount || tempIndicator.billing.billing[0].billingAccountId;
                    itemSfData = {
                        "eItemId": item.order.svItemId,
                        "SFDCItemId": item.order.sfdcItemId,
                        "listingId": data['Listing_Id__c'],
                        "listing": data['Listing__c'],
                        "listingName": data['Listing_Name__c'],
                        "billingAccountId": billingId,
                        "billingBasis": data.Billing_Basis__c,
                        "billingMapRequired": data.Billing_MAP_Required__c,
                        "copySheetRequired": data['Copy_Required__c'],
                        "coopType": item.model.CoOpType ? item.model.CoOpType : 'No',  // if we directly publish the item then we are not able to get this value from ordergrid page
                        "coopNiAmount": item.model.CoOpNIAmount,
                        "isPrepaid": isPrepaid
                    };
                    if (item.order.productType === "Print" || item.order.productCode == "YPC") {
                        if (tempIndicator.items[item.order.svItemId].model && tempIndicator.items[item.order.svItemId].model.CTNconf) {
                            /*indicator.items[tempIndicator.stateParamsId].model.ManageCopysheet.CTNconf=true;*/
                            itemSfData["confirmCTN"] = tempIndicator.items[item.order.svItemId].model.CTNconf;
                        } else {
                            /*indicator.items[tempIndicator.stateParamsId].model.ManageCopysheet.CTNconf=false;*/
                            itemSfData["confirmCTN"] = false;
                        }
                    }
                }
                contentArray.push(contentObj)
                sfRequestArray.push(itemSfData)
            }
            try {
                let sfResult = await this.sfService.savesfdata(sfRequestArray)
                let updateResult
                try {
                    upsertMapping['products'].product.content = contentArray
                    updateResult = await this.productService.updateSync("print", upsertMapping, "item");
                    this.spinnerService.hide();
                    this.collectLoggerMessage(updateResult)
                    resolve(updateResult);

                } catch (err) {
                    this.spinnerService.hide();
                    this.collectLoggerMessage(err.error);
                    reject(err);
                }
            } catch (error) {
                this.spinnerService.hide();// Added spinner hide condition for salesforce update on item save
                this.logger.logError("<strong>Error in Save Sfdata <strong><br>Source System : Salesforce <br>");
                reject(error);
            };
        });
    }

    formatPhoneNumber(getNumber) {
        if (getNumber) {
            let num = getNumber.replace(/\D/g, "");
            return "(" + num.substring(0, 3) + ")" + " " + num.substring(3, 6) + "-" + num.substring(6, 10);
        }
    }

    collectLoggerMessage(message) {
        let response = (message.hasOwnProperty("response")) ? message.response : message;
        let transactionId = response.transactionId;
        let item = response.item
        if(response.hasWarningOrError == "true"){
            let successMessage = ""
            let errorMessage = ""
            for(let indx=0;indx < item.length; indx++){
                if(item[indx].status == 'SUCCESS'){
                    successMessage = successMessage +' '+ item[indx].svItemId
                }else{
                    errorMessage = errorMessage + "<br><strong>" + item[indx].sourceSystem + ":</strong><br>" + item[indx].errorMessage
                }
                if(indx == item.length - 1){
                    if(successMessage!=undefined && successMessage!=null && successMessage.length> 0){
                        this.logger.logItemSuccess('Successfully updated item(s) '+successMessage)
                    }
                    if(errorMessage!=undefined && errorMessage!=null && errorMessage.length > 0){
                        this.logger.logError("<strong>Error updating items. Transaction Id : " + transactionId + "</strong><br><strong>" + errorMessage);
                    }
                }
            }            
        }else{
            let successMessage=""
            for(let indx=0;indx < item.length; indx++){
                successMessage = successMessage + ' ' + item[indx].svItemId
            }
            this.logger.logItemSuccess('Successfully updated item(s) '+successMessage)
        }
    }
}
