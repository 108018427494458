import { Component, OnInit } from '@angular/core';
import { AdminuserService } from '@app/service/adminuser/adminuser.service';
import { ConfigurationService } from '@app/service/configuration/configuration.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LogoService } from '@app/service/logo/logo.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { Router } from '@angular/router';
import { IndicatorQuery } from 'app/state/query';
import { FooterService } from '../footer/footer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Moment } from 'moment';
import moment from 'moment';
import { StorageService } from '@app/service/storage/storage.service';
import Tabulator from "tabulator-tables";
import * as _ from 'lodash';
import { ClientRequest } from 'http';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  updatedPublishGridData: any = [];
  adminGridData = [];
  configGridData = [];
  ConfigGrid: any = [];
  dataValue = new Array();
  model: string;
  userCount : number = 0;
  isLoaded: boolean = false;
  adminResponse;
  nodeServer: string;
  webServer: string;
  configurationResponse;
  createUsr: boolean = false;
  createconfig: boolean = false;
  usersReport : boolean = false;
  userNamError: boolean = false;
  passwordError:boolean = false;
  loginForm: FormGroup = null;
  password = null;
  userName = null;
  name = null;
  type = null;
  value = null;
  indicatorValue: any;
  configval:any;
  userDetails: any;
  CnfgErrorType:boolean = false;
  CnfgErrorName:boolean = false;
  CnfgErrorValue:boolean = false;
  counter: Number = 0;
  requiredFieldmsg:string = "Please fill out this field";
  usersData: any = [];
  tab: any;
  index: number = 1;
  rep:any;
  addSearchlist: any;
  selected: {startDate: Moment, endDate: Moment};
  startdate ;
  enddate ;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  moment = moment;
  constructor(
    private adminuser: AdminuserService,
    private configurationService: ConfigurationService,
    private logger: LoggerService,
    private router: Router,
    private indicatorQuery: IndicatorQuery,
    private footer:FooterService,
    private ngxspinner: NgxSpinnerService,
    private storageService : StorageService) { }

  ngOnInit() {
    this.ngxspinner.hide();
    this.loadData();
    this.footer.changeTab("adminUser");
    this.indicatorQuery.getIndicator().subscribe((res) =>{
      this.indicatorValue = { ...res };
      this.nodeServer = this.indicatorValue.user.nodeserver;
      this.webServer = sessionStorage.getItem('nginxServer');
    })

  }

  loadData() {
    this.adminuser.getAll().subscribe(
      (res) => {
        this.adminResponse = res;
        if (res.length > 0) {
          this.adminGridData = [];
          res.forEach(element => {
            let localObj = {
              user: element.userId,
              password: "******",
              action: "delete/update"
            };
            this.adminGridData.push(localObj)
          });
          //console.log("value in adminGridDat", this.adminGridData);
          this.dataValue = this.adminGridData;
          this.model = "adminGrid";
          this.isLoaded = true;
          this.createUsr = false;
          //}

        }

      })
  }

  async configurationDetails() {
    this.configGridData = [];
    this.indicatorQuery.getIndicator().subscribe((res) =>{
      this.indicatorValue = { ...res };
        //console.log("indicator value from publish page is", this.indicatorValue);
        console.log('getting order items value in publish page',this.indicatorValue.order);
         this.configval = JSON.parse(JSON.stringify(this.indicatorValue.configuration));
         this.ConfigGrid.push(this.configval)
    },err=>{
      console.log("error",err)
    })
    this.configurationService.getAll().then(
      (res) => {
        this.configurationResponse = res;
        //console.log("Response of config", res)
        if (res.length > 0) {
          res.forEach(element => {
            let confObj = {
              type: element.type,
              name: element.name,
              value: element.value,
              action: ""
            };
            this.configGridData.push(confObj)
          });
          //console.log("value in confObj", this.configGridData);
          this.dataValue = this.configGridData;//DATA1;
          //this.dataValue = this.ConfigGrid;//DATA1;
          this.model = "configurationgrid",
            this.isLoaded = true;
        }
      })
  }
  createUser() {
    let localData: any = {
      userId: this.userName ? this.userName.toString().trim() : null,
      password:this.password? this.password.toString().trim():null
    };
    if (localData.userId !== null) {
      if (localData.password !== null) {
        this.adminuser.createUser(localData).subscribe((res) => {
          this.logger.logSuccess('User added succesfully');
        }, err => {
          console.log("Error in creating new user", err.error.error)
          this.logger.logError(err.error.error);
        })
      }
      else{
        this.passwordError = true;
        this.userNamError = false;
      }
    }
    else{
      this.userNamError = true;
    }
    // if (!localData.password) {
    //   this.passwordError = true;
    // }
    // this.adminuser.createUser(localData).subscribe((res) => {
    //   this.logger.logSuccess('User added succesfully');
    // }, err => {
    //   console.log("Error in creating new user", err.error.error)
    //   this.logger.logError(err.error.error);
    // })
  }
  Validate(e)
  {
    if (e.target.id.toLowerCase() == "type" && e.target.value.length>0) {
      this.CnfgErrorType = false;
    } else if (e.target.id.toLowerCase() == "name" && e.target.value.length>0) {
      this.CnfgErrorName = false;
    } else if (e.target.id.toLowerCase() == "value" && e.target.value.length>0) {
      this.CnfgErrorValue = false ;
    } else if (e.target.id.toLowerCase() == "username" && e.target.value.length>0) {
      this.userNamError = false;
    } else if (e.target.id.toLowerCase() == "password" && e.target.value.length>0) {
      this.passwordError = false ;
    }
  }
  createNewConfig() {
    let configNewData: any = {
      name: this.name ? this.name.toString().trim():null,
      type: this.type ? this.type.toString().trim():null,
      value: this.value ? this.value.toString().trim():null,
    }
    if(configNewData.type !== null){
      if(configNewData.name !== null){
        if(configNewData.value !== null){
          this.configurationService.create(configNewData).then((data) => {
            this.logger.logSuccess('Configuration added succesfully');
          }, err => {
            console.log("error", err)
            this.logger.logError(err.error.error);
          })
        }
        else{
          this.CnfgErrorValue = true
          this.CnfgErrorType = false
          this.CnfgErrorName = false
        }
      }
      else{
        this.CnfgErrorName = true
        this.CnfgErrorType = false
      }
    }
    else{this.CnfgErrorType = true}

    // if(configNewData.name !== null && configNewData.type !== null && configNewData.value !== null){
    //   this.configurationService.create(configNewData).then((data) => {
    //     this.logger.logSuccess('Configuration added succesfully');
    //   }, err => {
    //     console.log("error", err)
    //     this.logger.logError("Error in creating new Configuration");
    //   })
    // }
  }

  onTabChange($event) {
    if ($event.index == 1) {
      this.footer.changeTab("configurations");
      this.configurationDetails();
      this.createconfig = false;
      this.createUsr = false;
      this.usersReport = false;
    } else if ($event.index == 2) {
      this.footer.changeTab("createUser");
      this.createUsr = true;
      this.isLoaded = false;
      this.createconfig = false;
      this.usersReport = false;
      // this.getLoginForm();
    } else if ($event.index == 3) {
      this.footer.changeTab("createConfig");
      this.createconfig = true;
      this.isLoaded = false;
      this.createUsr = false;
      this.usersReport = false;
      // this.getLoginForm();
    }
    else if ($event.index == 4) {
      this.footer.changeTab("usersReport");
      this.usersReport = true;
      this.createconfig = false;
      this.isLoaded = false;
      this.createUsr = false;
      // this.getLoginForm();
     // this.router.navigate(['/tools/dashboardlogin'])
    }
    else if ($event.index == 5) {
      this.createconfig = false;
      this.isLoaded = false;
      this.createUsr = false;
      this.usersReport = false;
      // this.getLoginForm();
      this.router.navigate(['/tools/dashboardlogin'])
    }
    else {
      this.footer.changeTab("adminUser");
      this.loadData();
      this.dataValue = this.adminGridData;//DATA1;
      this.model = "adminGrid";
      this.isLoaded = true;
      this.createconfig = false;
      this.usersReport = false;
    }
  }
  change($event){
    if($event && $event.start && $event.start.format) {
      this.startdate = $event.start.format('YYYY-MM-DD');
      }
      if($event && $event.end && $event.end.format) {
      this.enddate = $event.end.format('YYYY-MM-DD');
      }
      this.storageService.getByAccountIdOrderId("loginuser","12345",this.startdate,this.enddate).then((data)=>{
         this.userDetails = data.filter( (userdata) => {
          return userdata.username != null;
        }); 
        this.userDetails = this.userDetails.sort((a, b) => {
          return (a.username > b.username) ? 1 : -1;
         })
        this.userDetails = _.uniqBy(this.userDetails , (userdata) => {
          return userdata.username; 
       });
           if(this.userDetails){
             this.usersData = [];
             this.index=1;
          this.userCount = this.userDetails.length;
          this.userDetails.forEach(element => {
            let confObj = {
              sno: this.index,
              name: element.username,
              FedId: element.federationIdentifier,
              loggedIn: element.updateDateTime,
            };
            this.usersData.push(confObj);
            this.index = this.index + 1;
          } );
          this.loadUsers(); 
        }   
    },error=> {
      console.log("this.storageService.getByAccountIdOrderId is getting failed");
    }) 
    }
    loadUsers(){
      let tabConfigs;
      var rep = document.getElementById("newReport");
      console.log(rep); 
      const dateFormatter = {
        inputFormat:"YYYY-MM-DD HH:mm:ss",
        outputFormat:"DD/MM/YYYY HH:mm:ss",
        invalidPlaceholder:"(invalid date)",
    }   
      tabConfigs = {
        width: 850,
        data: this.usersData,
        dataTree: true,
        dataTreeStartExpanded:true,
        layout: 'fitColumns',
        columns : [
          {title:"S.No",field:"sno",width: 90},
          {title:"Username",field:"name",headerFilter : true,width:300,sorter: "string"},
          {title:"Federation Identifier",field:"FedId",headerFilter : true,width: 200},
          {title:"Last logged-in",field:"loggedIn",headerFilter : true,width: 250}
        ],
        placeholder: 'No Data Available',
        pagination:"local",
        paginationSize:15,
        paginationSizeSelector:[50, 100, 150],
        movableColumns: true,
        tooltips:true,
        columnMinWidth:20 }
        this.tab = new Tabulator(rep, tabConfigs);
    }
  


}



