export interface Quickbp {
    indicatorProfiles?:Array<any>;
    indicatorAccount?:string;
    stateParamsId?:string;
    businessProfilePageIndex?:number;
    indicatorProfileData?:Array<any>;
    indicatorListingIds?:Array<any>;
    isIndicatorLoaded?:boolean;
    tileFlag1?:boolean;
    tileFlag2?:boolean;
    tileFlag3?:boolean;
    indicatorOrder?:any;
    quickBpForm?:Array<any>;
    indicatorUser?:string;
    goldenListingData?:any;
    frmEmail?:any;
    indicatorItems?:Array<any>;
    configuration: any;
    primaryContactEmail?:string;
    loadingText:string;
    spinnerClass: string;
    campaignFlag: boolean;
    errArray?:Array<any>;
    profiles: any;
}

export class Quickbpclass implements Quickbp {
    indicatorProfiles?:Array<any> = [];
    configuration: any;
    indicatorAccount?:string = '';
    stateParamsId?:string = '';
    businessProfilePageIndex?:number = 0;
    indicatorProfileData?:Array<any> = [];
    indicatorListingIds?:Array<any> = [];
    isIndicatorLoaded?:boolean = false;
    tileFlag1?:boolean = false;
    tileFlag2?:boolean = false;
    tileFlag3?:boolean = false;
    indicatorOrder?:any  = {};
    quickBpForm?:Array<any> = [];
    indicatorUser?:string = '';
    goldenListingData?:any;
    frmEmail?:any
    indicatorItems?:Array<any> = [];
    primaryContactEmail?:string = '';
    loadingText: string = '';
    spinnerClass: string = 'business-profile-spinner';
    campaignFlag: boolean = false;
    errArray?:Array<any> = [];
    profiles: any;
}