import {  ChangeDetectorRef, Component, OnInit, ViewRef ,OnDestroy} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Quickbpclass } from 'app/interface/quickbp';
import { DatasharingService } from '@app/service/datasharing/datasharing.service';
import { CatKeysService } from '@app/service/CatKeys/CatKeys.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { ErrorhandlingService } from '@app/service/errorhandling/errorhandling.service';
import { BusinessCategories } from '@app/service/businessCategories/businessCategories.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { ProfileService } from '@app/service/profile/profile.service';
import { CounterService } from '@app/service/counter/counter.service';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { constant } from '../../../data/constants';



@Component({
  selector: 'app-business-categories-item',
  templateUrl: './business-categories-item.component.html',
  styleUrls: ['./business-categories-item.component.scss']
})

export class BusinessCategoriesItemComponent implements OnInit,OnDestroy {

  open_dd: boolean = false;
  myPromise: Promise<[any]>;
  sharedData: Quickbpclass;
  fetched_categories: Promise<[any]>;
  selected_categories :any = [];
  primary_category = { categoryId: '', categoryName: '' };
  id: any;
  selectedCategoriesArray = [];
  hideSelected$: Subject<boolean>;
  options: any;
  categoryName;
  isReadonly :boolean=false;
  isRequiredField:boolean=false;
  schemaObj;
  showPrimaryCategory :boolean = false;
  itemStatusCode ;  
  destroySubject$: Subject<void> = new Subject();

  constructor(private BusinessCategories: BusinessCategories,
    private CatKeys: CatKeysService, private Logger: LoggerService,
    private errorService: ErrorhandlingService,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private Profile :ProfileService,
    private cdr: ChangeDetectorRef,
    private counterService :CounterService ) {
  }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    const data = [];
    this.hideSelected$ = new BehaviorSubject(!!data.length);
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    this.id = this['layoutNode'].name;
    this.itemStatusCode = this.sharedData['items'][this.sharedData.stateParamsId].order.statusCode;
    this.schemaObj = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name];
    this.isRequiredField = this.schemaObj.required;
    this.isReadonly = this.schemaObj.readonly;

    if ( this.sharedData['items'][this.sharedData.stateParamsId].model.hasOwnProperty('BusinessCategoriesDPM')) {
      this.selected_categories =  this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM.CategoryList
      this.checkPrimaryCategory();
    }else{       
      this.showPrimaryCategory = true ; //Show primary category field on UI for SEO item
      if (!this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessProfile) {
        if (constant.SEO_PRODUCT_CODE.indexOf(this.sharedData['items'][this.sharedData.stateParamsId].order.productCode) > -1) {
          if (this.sharedData['items'][this.sharedData.stateParamsId].model && this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories'] && this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.CategoryList) {
            for (let catKey of this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.CategoryList) {
              let temp = {
                categoryName: catKey.CategoryName,
                categoryId: catKey.CategoryId
              }
              this.selected_categories.push(temp);
            }
            this.primary_category['categoryName'] = this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.PrimaryCatId.CategoryName;
            this.primary_category['categoryId'] = this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.PrimaryCatId.CategoryId;
          }
        } else if (this.sharedData['items'][this.sharedData.stateParamsId].order.productCode === "DPM") {
          if (this.sharedData['items'][this.sharedData.stateParamsId].model && this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM) {
            for (let catKey of this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM.CategoryList) {
              let temp = {
                categoryName: catKey.CategoryName,
                categoryId: catKey.CategoryId
              }
              this.selected_categories.push(temp);
            }
          }
        }
      } else {
      this.Profile.getByDigitalListing(this.sharedData['items'][this.sharedData.stateParamsId].order.listingId || this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessProfile).then(res=>{
        let listingResponse = res[this.sharedData['items'][this.sharedData.stateParamsId].order.listingId || this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessProfile];
        if(listingResponse && listingResponse.listings && listingResponse.hasWarningOrError!= "true" && !this.sharedData['profiles'][this.sharedData['items'][ this.sharedData.stateParamsId].model.BusinessProfile]){
          this.selected_categories = listingResponse.listings.listing[0].categories.category;
        }else if(this.sharedData['items'][this.sharedData.stateParamsId] && this.sharedData['items'][ this.sharedData.stateParamsId].model && this.sharedData['profiles'][this.sharedData['items'][ this.sharedData.stateParamsId].model.BusinessProfile]){
          this.selected_categories = this.sharedData['profiles'][this.sharedData['items'][ this.sharedData.stateParamsId].model.BusinessProfile].model.BusinessCategories;
        }else{
          this.selected_categories = null;            
        }
        if(this.sharedData['items'][this.sharedData.stateParamsId].model.hasOwnProperty('BusinessCategories')){
          this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories']['CategoryList'] =  this.selected_categories ; 
        }  
        if ( this.sharedData['items'][this.sharedData.stateParamsId].order.productCode === "Premium IYP") {
          if ( this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories &&  !this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.PrimaryCatId) {
            this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.PrimaryCatId = this.selected_categories[0];
          }
        } 
        this.updateStore(this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories);
        this.checkPrimaryCategory();
        this.ngAfterViewChecked();
      });
    }
      this.counterService.getSeoData().pipe( takeUntil(this.destroySubject$)).subscribe(data => {​​​​
        this.selected_categories = data[2];
        this.sharedData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories']['CategoryList'] =  this.selected_categories;
        this.checkPrimaryCategory();
        this.ngAfterViewChecked();
        }​​​​);  

        
    }        
       
   }
   ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
    this.cdr.detectChanges();
  }
   checkPrimaryCategory(){
    this.primary_category = JSON.parse(JSON.stringify( this.primary_category));    
    if (this.selected_categories && this.selected_categories != undefined && this.selected_categories != null && this.selected_categories.length > 0) {
      // check for primary category
      for (let i = 0; i < this.selected_categories.length; i++) {
        if (this.selected_categories[i].primary == 'Yes')
          this.primary_category['categoryName'] = this.selected_categories[i].categoryName;
          this.primary_category['categoryId'] = this.selected_categories[i].categoryId;
      }
      // When no primary categories are found make the first category as primary
      if (!this.primary_category) {
        this.selected_categories[0].primary = 'Yes'
        this.primary_category['categoryName'] = this.selected_categories[0].categoryName;
        this.primary_category['categoryId'] = this.selected_categories[0].categoryId;
      }
      
      this.sharedData = JSON.parse(JSON.stringify( this.sharedData));    
      if(this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories']){//PrimaryCatId
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories']['PrimaryCatId'] =  this.primary_category
        this.updateStore(this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories);
      }else if(this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategoriesDPM']){
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategoriesDPM']['PrimaryCatId'] =  this.primary_category
        this.updateStore(this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM);
      }
    }
    this.ngAfterViewChecked();
   }
  

  callService(searchWord) {
    return new Promise<[any]>((resolve, reject) => {
      let categories;
      this.BusinessCategories.getCategories(searchWord)
        .subscribe(data => {
          categories = data;
          if (categories.length > 0) {
            for (let category of categories)
              category['primary'] = 'No'
          }
          resolve(categories);
        },
          err => {
            this.Logger.logError(this.errorService.handleApiError(err))
            reject(err);
          },
          () => {
            resolve()
          });
    });

  }

  getCategories(searchword) {
    let categories;
    this.open_dd = true;

    if (searchword.term.length > 0) {
      this.fetched_categories = this.callService(searchword.term)
      this.fetched_categories.then((data) => {
        this.hideSelected$.next(!!data.length);
      }, (err) => {
      })
    } else {
      this.fetched_categories = null;
    }
  }

  getCatKeys(categoryName, categoryId) {
    let catKeys;
    this.CatKeys.getCatKeys(categoryName)
      .subscribe(data => {
        catKeys = data;
        // Remove the catkeys that are already present in shared data from the response
        if (catKeys && catKeys != null && !(catKeys === undefined)) {
          for (let j = 0; j < catKeys.length; j++) {
            if ( this.sharedData['items'][this.sharedData.stateParamsId].model[catKeys[j].type] &&  this.sharedData['items'][this.sharedData.stateParamsId].model[catKeys[j].type] != undefined &&  this.sharedData['items'][this.sharedData.stateParamsId].model[catKeys[j].type] != null) {
              for (let i = 0; i <  this.sharedData['items'][this.sharedData.stateParamsId].model[catKeys[j].type].length; i++) {
                if (catKeys[j].id ==  this.sharedData['items'][this.sharedData.stateParamsId].model[catKeys[j].type][i].catkeyId &&  this.sharedData['items'][this.sharedData.stateParamsId].model[catKeys[j].type][i].categoryId == categoryId) {
                  catKeys.splice(j, 1)
                  j = j - 1;//Need to reset the loop counter for splicing
                  break;
                }
              }
            }
          }
        }
        // Add the new categories to shared data
        if (catKeys && catKeys != null && !(catKeys === undefined)) {
          for (let catkey of catKeys) {
            let newCatKey = {};
            newCatKey['category'] = categoryName
            newCatKey['categoryId'] = categoryId
            newCatKey['catkeyId'] = catkey.id
            newCatKey['selected'] = 'N'
            newCatKey['_content_'] = catkey.name
            if ( this.sharedData['items'][this.sharedData.stateParamsId].model[catkey.type] &&  this.sharedData['items'][this.sharedData.stateParamsId].model[catkey.type] != undefined &&  this.sharedData['items'][this.sharedData.stateParamsId].model[catkey.type] != null) {
               this.sharedData['items'][this.sharedData.stateParamsId].model[catkey.type].push(newCatKey)
            } else {
              let catKeyArray = [];
              catKeyArray.push(newCatKey);              
              this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories']['CategoryList'].push(catKeyArray);
               //this.sharedData['items'][this.sharedData.stateParamsId].model[catkey.type] = catKeyArray;
            }
          }
        }
      }, err => {
        this.Logger.logError(this.errorService.handleApiError(err));
      });
  }

  refreshCatKeys() { 
    //Fetch and add new catkeys to shared data
    for (let category of this.selected_categories) {
      this.getCatKeys(category.categoryName, category.categoryId);
    }
  }

  onSelectSelectedCategories(value) {
    let primary_exists;
    if (this.selected_categories && this.selected_categories != null && !(this.selected_categories == undefined) && this.selected_categories.length > 0) {
      // If primary category is deleted then make first category as primary
      primary_exists = false;
      for (let category of this.selected_categories) {
        if (category.primary == 'Yes')
          primary_exists = true
      }
      if (!primary_exists) {
        this.selected_categories = JSON.parse(JSON.stringify( this.selected_categories));
        this.sharedData = JSON.parse(JSON.stringify( this.sharedData));
        this.primary_category = JSON.parse(JSON.stringify(this.primary_category));

        this.selected_categories[0]['primary'] = 'Yes';
        this.primary_category['categoryName'] = this.selected_categories[0].categoryName;
        this.primary_category['categoryId'] = this.selected_categories[0].categoryId;
        // sentry fix
        if (!this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories){
          this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessCategories'] = {};
        }
        this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories['CategoryList'] = this.selected_categories;
        this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories['PrimaryCatId'] = this.primary_category ;
        this.updateStore(this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories);

      }
     
    } else {
      this.sharedData = JSON.parse(JSON.stringify( this.sharedData));
      // When all categories are cleared , clear primary category
      this.primary_category = { categoryId: '', categoryName: '' };
      if(this.sharedData &&this.sharedData['items'] && this.sharedData['items'][this.sharedData.stateParamsId] &&this.sharedData['items'][this.sharedData.stateParamsId].model &&this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories){
        this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories['PrimaryCatId'] = this.primary_category;
      }
      
      if (this.sharedData && this.sharedData['items'] && this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories && this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.CategoryList ) {
        this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.CategoryList.splice(0,  this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories.CategoryList.length)
      }
       this.updateStore( this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategories);
    
      
      //Clear earlier fetched categories
      this.fetched_categories = null
    }
   
    if(this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM){
      this.sharedData = JSON.parse(JSON.stringify( this.sharedData));
      this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM.CategoryList = this.selected_categories;
      this.updateStore(this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM);
    }
   
  }
  updateStore(value){
    let tempObj = JSON.parse(JSON.stringify(this.sharedData));        
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model))
    tempObj['items'][this.sharedData.stateParamsId].model[this['layoutNode'].name] =value ;
    this.indicatorQuery.updateModel(this['layoutNode'].name,value,modelData,tempObj, '[BusinessCategoriesItemComponent] updateStore');
}
  onSelectPrimaryCategories(catName) {
    // Modify the business categories in shared data
    if (this.selected_categories && this.selected_categories != null && !(this.selected_categories === undefined)) {
      for (let i = 0; i < this.selected_categories.length; i++) {
        if (this.selected_categories[i].categoryName == catName)
          this.selected_categories[i].primary = 'Yes'
        else
          this.selected_categories[i].primary = 'No'
      }
    }
     this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessCategoriesDPM.CategoryList = this.selected_categories;
    // Delete the existing primary category and add new primary category in shared data
    /*
    if (this.sharedData.PrimaryCategories && this.sharedData.PrimaryCategories != null && !(this.sharedData.PrimaryCategories === undefined)) {
      for (let i = 0; i < this.sharedData.PrimaryCategories.length; i++) {
        this.sharedData.PrimaryCategories.splice(i, 1);
        i = i - 1;
      }
    }
    this.sharedData.PrimaryCategories.push(this.primary_category)
    */
    // set the behavior view
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][  this.sharedData.stateParamsId].model))
    this.indicatorQuery.updateModel(this['layoutNode'].name, this.selected_categories,modelData, this.sharedData, '[BusinessCategoriesItemComponent] onSelectPrimaryCategories');
    
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
