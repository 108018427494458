import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import {
  MatSidenavModule,
  MatDividerModule
} from "@angular/material";




@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule
  ],
})
export class MainModule {}
