import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { ThemePalette, ProgressSpinnerMode, MatDialogRef, MAT_DIALOG_DATA, MatStepper, MatDialogConfig, MatDialog } from '@angular/material';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Subject, Subscription } from 'rxjs';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';
import { IndicatorQuery } from 'app/state/query';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: "app-publish-spinner",
  templateUrl: "./publish-spinner.component.html",
  styleUrls: ["./publish-spinner.component.scss"],
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class PublishSpinnerComponent implements OnInit, OnDestroy {

  sideBarOpen = true;
  showSpinner: boolean = false;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value: number = 33;
  counter: number = 33;
  isLinear = false;
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  processUrls: object[]  = [];
  destroySubject$: Subject<void> = new Subject();

  constructor(public dialogRef: MatDialogRef<PublishSpinnerComponent>
    ,private dialog: MatDialog
    , private indicator: IndicatorService
    , private spinnerService: SpinnerService
    , private indicatorQuery: IndicatorQuery
    , @Inject(MAT_DIALOG_DATA) data) { }

  ngOnInit() {
    let subUrl = '';
    this.showSpinner = true;
    const map = new Map();
    this.spinnerService.getSpinner().pipe(filterNil, takeUntil(this.destroySubject$))
      .subscribe(data => {  
        if (data && data.url && data.url.includes("/api/")) {
          subUrl = data.url;
          subUrl = subUrl.substring(subUrl.indexOf('/api/') + 5).split('/', 1)[0];
          (data.status == 200 || data.status == 204) ? '' : this.close();
          if (!map.has(subUrl)) {
            map.set(subUrl, true);
            data.url = subUrl;
            this.processUrls.push(data);
            (data.status == 200 || data.status == 204) ? '' : this.close();
          }
          if(this.value  <= 90) {
            this.value = this.value + this.counter;
          }
        }
    });
    this.indicatorQuery.getLoaded().subscribe(isLoaded => {
      if (isLoaded) {

        this.value = this.value + this.counter;
       // this.close();
      }
    })
     
  }

  close() {
    this.dialogRef.close();
  }
  goForward(stepper: MatStepper) {
    stepper.next();
  }

  ngOnDestroy() {
    this.destroySubject$.next();
}
  
}
