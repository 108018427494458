import { Injectable } from '@angular/core';
import { Observable,forkJoin, of  } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ParamsService } from '@app/service/params/params.service';
import { ApiService } from '@app/service/api/api.service';
import { OrderService } from '@app/service/order/order.service';
import { AccountService } from '@app/service/account/account.service';
import { UserService } from '@app/service/user/user.service';
import { IndicatorModel } from '@app/service/indicator/indicator.model';
import { IndicatorQuery } from 'app/state/query';
@Injectable({
  providedIn: 'root'
})
export class PaynowService {
  indicatorValue: any;
  public indicator: IndicatorModel = new IndicatorModel();
  baseUri:string = '/api/seo';
  finalURl:any;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  orderVal: any = {}
  accountVal: any = {}
  sideMenuPayNow;
  data:any;
  finalPaynowURL: any;
  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService,
    private order:OrderService,
    private account:AccountService,
    private user:UserService,
    private indicatorQuery: IndicatorQuery,
    //private indicator:IndicatorService
    ) { }

//Url creation for paynow under tools
  getPayNowSideMenuLink(){
    this.indicatorQuery.getIndicator().subscribe((res) => {
        this.indicatorValue = { ...res };
        //console.log("indicator value from publish page is", this.indicatorValue);
        //console.log('getting order items value in publish page',this.indicatorValue.order);
         this.orderVal = JSON.parse(JSON.stringify(this.indicatorValue.order));
         this.accountVal = this.indicatorValue.account;
         if(('parentId' in this.accountVal)){
            if((this.accountVal && this.accountVal.parentId != null && this.accountVal.kgenCustomerId == null)|| (this.indicator && this.indicator.account && this.indicator.account['parentId'])){
                         this.accountVal['kgenCustomerId'] = this.indicator.account['kgenCustomerId'];
                      }
          }
          else{
            //console.log("parentId not present")
          }

          let url = this.Params.params.sideMenuPayNow;
              let name = this.orderVal.opportunity.owner.name.split(" ");
              if (name.length !== 0) {
                this.orderVal.opportunity.owner['firstName'] = name[0];
                this.orderVal.opportunity.owner['lastName'] = (name.length > 0) ? name[(name.length - 1)] : name[1];
              } else {
                this.orderVal.opportunity.owner.firstName = null;
                this.orderVal.opportunity.owner.lastName = null;
              }

              let  data:any = {
                "CustomerID": this.accountVal.kgenCustomerId,
                "MC" : {
                    "Email": (this.orderVal.opportunity.owner.email) ? this.orderVal.opportunity.owner.email : "",
                    "Fname": (this.orderVal.opportunity.owner.firstName) ? this.orderVal.opportunity.owner.firstName : "",
                    "Lname": (this.orderVal.opportunity.owner.lastName) ? this.orderVal.opportunity.owner.lastName : "",
                    "Phone": (this.orderVal.opportunity.owner.phone) ? this.orderVal.opportunity.owner.phone : "",

                }
            }
            url += "?DefaultParms=" + encodeURIComponent(JSON.stringify(data));
              //console.log("Url = ",url)
              //console.log("data=",data);
              this.finalURl = url;
              if(this.finalURl){
                    // console.log('Before returning',this.finalURl);
                  return this.finalURl;

              }
    },err=> {
        console.log("Error in paynow",err)
    })

  };
  getPayNowLink(id){
    this.indicatorQuery.getIndicator().subscribe((res) => {
        this.indicatorValue = { ...res };
        //console.log("indicator value from publish page is", this.indicatorValue);
        //console.log('getting order items value in publish page',this.indicatorValue.order);
         this.orderVal = JSON.parse(JSON.stringify(this.indicatorValue.order));
         this.accountVal = this.indicatorValue.account;
         if(('parentId' in this.accountVal)){
            if((this.accountVal && this.accountVal.parentId != null && this.accountVal.kgenCustomerId == null)|| (this.indicator && this.indicator.account && this.indicator.account['parentId'])){
                         this.accountVal['kgenCustomerId'] = this.indicator.account['kgenCustomerId'];
                      }
          }
          else{
            //console.log("parentId not present")
          }

          let url = this.Params.params.payNow;
              let name = this.orderVal.opportunity.owner.name.split(" ");
              if (name.length !== 0) {
                this.orderVal.opportunity.owner['firstName'] = name[0];
                this.orderVal.opportunity.owner['lastName'] = (name.length > 0) ? name[(name.length - 1)] : name[1];
              } else {
                this.orderVal.opportunity.owner.firstName = null;
                this.orderVal.opportunity.owner.lastName = null;
              }

            let data = {
                              'OrderID':  this.orderVal.orderId,
                              'CustomerID': this.accountVal.kgenCustomerId,
                              'billing_id' : this.indicatorValue.selectedBillAccountId ? this.indicatorValue.selectedBillAccountId : id ,
                              'AI': {
                                  "SystemID": "PB",
                                  "SystemPassword": "PBDevelopment01"
                              },
                              'MC': {
                              'Email': ( this.orderVal.opportunity.owner.email) ?  this.orderVal.opportunity.owner.email : "",
                              'Fname': ( this.orderVal.opportunity.owner.firstName) ?  this.orderVal.opportunity.owner.firstName : "",
                              'Lname': ( this.orderVal.opportunity.owner.lastName) ?  this.orderVal.opportunity.owner.lastName : "",
                              'Phone': ( this.orderVal.opportunity.owner.phone) ?  this.orderVal.opportunity.owner.phone : "",
                              },
                              'Products': []
                          };
            url += "?CustOrder=" + encodeURIComponent(JSON.stringify(data));
              //console.log("Url = ",url)
              //console.log("data=",data);
              this.finalPaynowURL = url;
              if(this.finalPaynowURL){
                  console.log('Before returning',this.finalPaynowURL);
                  return this.finalPaynowURL;

              }
    },err=> {
        console.log("Error in paynow",err)
    })

  };
}


