import { IndicatorQuery } from 'app/state/query';
import { OnInit } from '@angular/core';

export class showHistoryModel implements OnInit{
    indicatorValue : any;
    subscribe: any
    constructor(private indicatorQuery: IndicatorQuery) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  
                });
    }
    ngOnInit(): void {
        
        throw new Error("Method not implemented.");
    }
    getColumn(bpModel,itemStatusFlag){
        let columns: any ={
            "footerChangeHistory":[
                {title:"DateTime", field:"dateTime"},
                {title:"item Id", field:"itemId", visible: bpModel||itemStatusFlag ? true : false},
                {title:"Name", field:"name"},
                {title:"Email", field:"email"},
                {title:"item Status", field:"itemStatus",visible: itemStatusFlag ? true : false},
                {title:"Item Action Code", field:"itemActionCode",visible: itemStatusFlag ? true : false},
                {title:"Field", field:"field"},
                {title:"Old Value", field:"oldValue", visible: bpModel ? false : true},
                {title:"New Value", field:"newValue", visible: bpModel ? false : true},
                {title:"field Value", field:"fieldValue", visible: bpModel ? true : false}, 
                {title:"Transaction ID", field:"transactionId"} //add column
            ]
            
        }
        return columns;
    }
    // columns: any ={
    //     "footerChangeHistory":[
    //         {title:"Name", field:"name"},
    //         {title:"Email", field:"email"},
    //         {title:"DateTime", field:"dateTime"},
    //         {title:"Field", field:"field"},
    //         {title:"Old Value", field:"oldValue", visible: true},
    //         {title:"New Value", field:"newValue", visible: this.bpModel ? true : false},
    //         {title:"field Value", field:"fieldValue", visible: this.bpModel ? true : false},
    //         {title:"item Id", field:"itemId", visible: this.bpModel ? true : false}
    //     ]
        
    // }
}
