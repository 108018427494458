export class ParamsModel{
    refresh :string;
    token :string;
    sforg :string;
    admin :string;
    account :string;
    order  :string;
    mode  :string;
    eaId  :string;
    listingId  :string;
    api :string;
    locked :string;
    sourcetype:string;
    payNow :string;
    sfaUrl :string;
    sideMenuPayNow :string;
    saving :boolean;
    loadingbar :boolean;
    warning :boolean;
    isCopyBP :string;
    isLateAd :boolean;
    quickBP : string;
    listingBP : string;
    loadingbartext : string;
    values : any;
    bpSaved :boolean;
    loadingBP :any;
    spinnerMode:string;
}