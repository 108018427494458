import { Component, OnInit, Inject } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ComponentStore } from 'app/state/component.state';

@Component({
  selector: 'app-addresskeybilling',
  templateUrl: './addresskeybilling.component.html',
  styleUrls: ['./addresskeybilling.component.scss']
})
export class AddresskeybillingComponent implements OnInit {
  sharedData;
  options: any;
  addressList = [];
  selectedAddressKey: string = '';
  titleContent: string;


  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private componentStore: ComponentStore) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.titleContent = "Address Key";
    this.indicatorQuery.getIndicator().subscribe((res) => {
      if(this.data.title === "Update"){
        this.selectedAddressKey = this.data.dialogform._cell.row.data.billingAddress;
      }
      this.sharedData = { ...res };
      if (this.sharedData ) {
        if ((this.sharedData.account && this.sharedData.account['contacts'] !== undefined) ||(this.sharedData.billing && this.sharedData.billing['billing'])){
          let contacts = this.sharedData.account['contacts'] ? this.sharedData.account['contacts'] : [];
          let billing = this.sharedData.billing && this.sharedData.billing['billing'] ? this.sharedData.billing['billing'] : [];
          if (this.sharedData && contacts && contacts.length > 0) {
            let showIndexes = [];
            let formattedAddresses = [];
            for(var i = 0; i<billing.length; i++){
              if (billing[i].billingAddress.streetAddress !== null &&
                billing[i].billingAddress.cityName !== null &&
                billing[i].billingAddress.stateAbbr !== null &&
                billing[i].billingAddress.zip5 !== null) {
                let address = "";
                // //address += billing[i].billingAddress.streetAddress.replace(/\s/g, '').trim().toLowerCase();
                // address += billing[i].billingAddress.streetAddress;//.replace(/\s/g, '').trim().toLowerCase();
                // address += " - " +  billing[i].billingAddress.cityName;//.replace(/\s/g, '').trim().toLowerCase();
                // address += " - " + billing[i].billingAddress.stateAbbr;//replace(/\s/g, '').trim().toLowerCase();
                // address += " - " + billing[i].billingAddress.zip5.split("-")[0].trim();//replace(/\s/g, '').trim().toLowerCase();
                // address += "-" + billing[i].billingAddress.zip4.split("-")[0].trim();//replace(/\s/g, '').trim().toLowerCase();
                address += billing[i].billingAddress.streetAddress;
                address += " - " + billing[i].billingAddress.cityName;
                address += " - " + billing[i].billingAddress.stateAbbr;
                let zip5 = billing[i].billingAddress.zip5.trim();
                let zipPart = zip5.includes("-") ? zip5 : zip5.replace("-", "");
                address += " - " + zipPart;

                // Check if zip4 is present
                let zip4 = billing[i].billingAddress.zip4 ? billing[i].billingAddress.zip4.replace(/\s/g, '').trim().toLowerCase() : "";

                // Append zip4 if present
                if (zip4) {
                  address += " - " + zip4;
                }

                address += " - " + this.sharedData.account.address.country.split("-")[0].trim();

                if (formattedAddresses.indexOf(address) === -1) {
                  showIndexes.push(i);
                  formattedAddresses.push(address);
                }
                }

            }
            for (var i = 0; i < contacts.length; i++) {
              if (contacts[i].address.email !== null &&
                contacts[i].address.street1 !== null &&
                contacts[i].address.city !== null &&
                contacts[i].address.state !== null &&
                contacts[i].address.zip !== null &&
                contacts[i].address.country !== null) {

                var address = "";
                address += contacts[i].address.street1;//replace(/\s/g, '').trim().toLowerCase();
                address += " - " + contacts[i].address.city;//replace(/\s/g, '').trim().toLowerCase();
                address += " - " + contacts[i].address.state;//replace(/\s/g, '').trim().toLowerCase();
                address += " - " + contacts[i].address.zip.split("-")[0].trim();//replace(/\s/g, '').trim().toLowerCase();
                address += " - " + contacts[i].address.country.split("-")[0].trim();//replace(/\s/g, '').trim().toLowerCase();
                if (formattedAddresses.indexOf(address) === -1) {
                  showIndexes.push(i);
                  formattedAddresses.push(address);
                }
              }
            }
            console.log("formatt address:",formattedAddresses);
            // formattedAddresses = [];

            // let contactobj = JSON.parse(JSON.stringify(contacts));
            // let billingbj = JSON.parse(JSON.stringify(billing));
            // for (var j = 0; j < showIndexes.length; j++) {

            //   formattedAddressesStringArray[j] = this.selectLabel(contacts[showIndexes[j]]);
            //   formattedBillingStringArray[j] = this.selectBillingLabel(billing[showIndexes[j]])

            //   contactobj[showIndexes[j]]['addressformat'] = formattedAddressesStringArray[j];
            //   billingbj[showIndexes[j]]['addressformat'] = formattedBillingStringArray[j];
            //   formattedAddresses.push(billingbj[showIndexes[j]])
            //   formattedAddresses.push(contactobj[showIndexes[j]]);
            //   console.log("address is :",formattedAddresses);
            // }
            this.addressList = formattedAddresses;
          }
        }
      }
    })

  }
  selectBillingLabel = function (item) {
    var label = "";
    //console.log("selectBillingLabel:",item);
    if(item &&item.billingAddress){
      if (item.billingAddress.streetAddress )
      label += item.billingAddress.streetAddress + " " + "-" + " ";
      if (item.billingAddress.cityName )
      label += item.billingAddress.cityName + " " + "-" + " ";
      if (item.billingAddress.stateAbbr)
      label += item.billingAddress.stateAbbr + " " + "-" + " ";
      if (item.billingAddress.zip5)
      // label += item.billingAddress.zip5 + " " + "-" + " ";
      label += (item.billingAddress.zip5 + " " + "-" + " ").split("-")[0].trim();
      if(item.billingAddress.zip4)
      label += item.billingAddress.zip4
      return label;

    }

  };

  selectLabel = function (item) {
    var label = "";
     //console.log("selectLabel:",item);
    if (item.address.street1 !== null )
      label += item.address.street1 + " " + "-" + " ";
    if (item.address.city !== null )
      label += item.address.city + " " + "-" + " ";
    if (item.address.state !== null )
      label += item.address.state + " " + "-" + " ";
    if (item.address.zip !== null )
      label += item.address.zip.split("-")[0]
    return label;
  };
  changedValue(values) {
    this.selectedAddressKey = values;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
    let addresses;
    if (values) {
      //console.log("values on address:",values);
      addresses = values.split(" - ");
      //console.log("addresses:",addresses);
      upadatedName.account.address.street1 = addresses[0];
      upadatedName.account.address.city = addresses[1];
      upadatedName.account.address.state = addresses[2];
      upadatedName.account.address.zip = addresses[3];
    }
    this.indicatorStore.update((state) => {
      return {
        indicator:upadatedName,
        updatedFrom: "[AddresskeybillingComponent] changedValue",
        isloaded: true,
      };
    });
    this.componentStore.update(() => {
      return {
        componentValue: addresses
      }
    });
  }
  splitAddressZip(zipField) {

  }
s
}
