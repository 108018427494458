import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { CouponSharingService } from '@app/service/coupon/couponsharing.service';
import { MatDialog } from '@angular/material';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-itemcampaignlink',
  templateUrl: './itemcampaignlink.component.html',
  styleUrls: ['./itemcampaignlink.component.scss']
})
export class ItemcampaignlinkComponent implements OnInit {

  dialogRef: any;
  constructor(private dialog: MatDialog,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private couponService: CouponSharingService, private cdr:ChangeDetectorRef) {

  }
  isTitle: boolean = false;
  title: string;
  titleContent: string;
  caption: string;
  fullText: string;
  offerStart: Date;
  offerEnd: Date;
  smallPrint: string;
  couponValue: string;
  offerCode: string;
  couponObject = {}
  coupons: any = [];
  coupon = [];
  closeResult: string = '';
  sharedData: Quickbpclass;
  options: any;
  ngOnInit() {
    this.options =this['layoutNode'].options;
    // this.couponService.getcouponObj().subscribe(result => {
    //   this.coupon = result;
    // })
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = data;
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1].properties.calltoactionurl.title;
    })
    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"]) {
      this.coupons = this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"]
    }
    else {
      // this.coupons = this.coupon
      this.coupons = [];
    }
    this.couponService.setcouponObj(this.coupons);
  }

  open(content) {
    this.title = undefined;
    this.caption = undefined;
    this.fullText = undefined;
    this.smallPrint = undefined;
    this.couponValue = undefined;
    this.offerCode = undefined;
    this.offerStart = new Date();
    this.offerEnd = new Date(this.offerStart.getFullYear(), this.offerStart.getMonth(), this.offerStart.getDate() + 367);
    // this.dialogRef = this.dialog.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'coupon-dialog' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    this.dialogRef = this.dialog.open(content, { disableClose: true , width: '800px', panelClass: 'coupon-modal'});
    this.dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    });
  }

  close(){
    this.dialogRef.close();
  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }


  save() {

    if (!this.title || !this.offerStart || !this.offerEnd) {
      this.isTitle = true;
      return;
    } else {
      this.isTitle = false;
    }

    var dstarttimestamp = (this.offerStart).toISOString();
    var dendtimestamp = (this.offerEnd).toISOString();
    var modifiedOntimestamp = new Date();
    //modifiedOntimestamp = modifiedOntimestamp.toISOString();
    this.couponObject = {
      title: this.title,
      caption: this.caption,
      fullText: this.fullText,
      offerStart: dstarttimestamp,
      offerEnd: dendtimestamp,
      smallPrint: this.smallPrint,
      couponValue: this.couponValue,
      offerCode: this.offerCode,
      couponId: null,
      "imageUrl": "",
      "template": "default",
      "modifiedOn": modifiedOntimestamp
    }
    this.coupons.push(this.couponObject);
    this.coupon = this.coupons;
    // this.sharedData['coupons'] = this.coupon;
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"] = this.coupon;
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][ this.sharedData.stateParamsId].model))
    this.indicatorQuery.updateBPModel(this['layoutNode'].name,this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"],modelData, this.sharedData, '[ItemcampaignlinkComponent] save');
  
    // this.indicatorStore.update(state =>{
    //   return{
    //     indicator: this.sharedData
    //   }
    // })
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
    this.close();
  };

}
