import { Component, AfterViewInit, ChangeDetectorRef, ViewContainerRef, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

//child Components

import { DigitalItemComponent } from '../digital-item/digital-item.component';

//import data from './itemDummyData.json';
import { productCode, productType, contactKeyValue, constant, omitAddressObj } from '../../../data/constants';

//addign indicator store
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillingComponent } from '../billing/billing.component';
import { StringComponent } from '../string/string.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select/select.component';

import { PhonenumberfieldComponent } from '../phonenumberfield/phonenumberfield.component';
import { PageFooterComponent } from '../page-footer/page-footer.component';
import { CampaignComponent } from 'app/shared/components/campaign/campaign.component';
import { ItemPhonenumberfieldComponent } from '../item-phonenumberfield/item-phonenumberfield.component';
import { ContactdropdownComponent } from '../contactdropdown/contactdropdown.component';

import { CheckboxinlinecaComponent } from '../checkboxinlineca/checkboxinlineca.component';

import { ItemService } from '@app/service/item/item.service';
import { ParamsService } from '@app/service/params/params.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { StorageService } from '@app/service/storage/storage.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';
import { StateComponent } from '../state/state.component';
import { CallComponent } from '../call-new/call/call.component';
import { ProfilepicklistComponent } from '../profilepicklist/profilepicklist.component';
import { ZipcodeValidationComponent } from '../zipcode-validation/zipcode-validation.component';
import { SpecialtiesitemdirectiveComponent } from '../specialtiesitemdirective/specialtiesitemdirective.component';
import { PrintItemComponent } from '../print-item/print-item.component';
import { DpmHoursofoperationComponent } from '../dpm-hoursofoperation/dpm-hoursofoperation.component';
import { ItemStringComponent } from '../item-string/item-string.component';
import { FooterService } from '../footer/footer.service';
import { BusinessCategoriesItemComponent } from '../business-categories-item/business-categories-item.component';
import { CampaignAccountComponent } from '../campaign-account/campaign-account.component';
import { AppointmentdatetimeComponent } from '../appointmentdatetime/appointmentdatetime.component';
import { RequiredfieldcountService } from '../../../core/service/requiredfieldcount/requiredfieldcount.service';
import { CounterService } from '@app/service/counter/counter.service';
import { CopySheetComponent } from '../copy-sheet/copy-sheet.component';
import { ExtralineComponent } from '../extraline/extraline.component';
import { Subject } from 'rxjs';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { ItemCategoryComponent } from 'app/shared/item-category/item-category.component';
import { WatchHelperService } from '@app/service/watch-helper/watch-helper.service';
import { ItemHoursofoperationComponent } from 'app/shared/components/item-hoursofoperation/item-hoursofoperation.component';
import { Constants } from '@app/service/datasource/constants';
import { WebsiteUrlComponent } from '../website-url/website-url.component';
import { ProposalIdComponent } from '../proposal-id/proposal-id.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { ProfileService } from '@app/service/profile/profile.service';
import { CopyService } from '@app/service/copy/copy.service';
import { GroupService } from '@app/service/group/group.service';
import { ProfiledDirectiveComponent } from '../profiled-directive/profiled-directive.component';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';
import { TradenameComponent } from '../tradename/tradename.component';
import { SharedService } from '@app/service/shared/shared.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { FieldcountService } from '@app/service/fieldcount/fieldcount.service';
import { FileService } from '@app/service/file/file.service';
import * as _ from 'lodash';
import { WebsitetypeComponent } from '../websitetype/websitetype.component';
@Component({
  selector: "app-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"],
})
export class ItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PrintItemComponent, { static: false }) printItemComponent;
  @ViewChild(DigitalItemComponent, { static: false }) digitalItemComponent;
  @ViewChild(PageFooterComponent, { static: false }) pagefooter;
  indicator;
  customWidgets;
  subscription;
  sharedData;
  itemobj;
  itemSchema: any = [];
  itemForm;
  itemData;
  isDigitalItem: boolean = false;
  niAmount;
  selectedFramework: string = "bootstrap-4"; //Change the name of the framework here
  private routeSub: Subscription;
  stateParamsId;
  items: string[];
  itemCount: any;
  frmlength = 0;
  previousObj: any;
  previousErrorCount: number = 0;
  destroySubject$: Subject<void> = new Subject();
  rejectFlag: boolean = false;
  uniqItemId;
  currentModelValue: string;
  hasSensitiveHeading: boolean = false;
  hasCopySheet: boolean = false;
  spinnerText;
  customerId;
  bpCountUpdated: boolean = false;
  newActivityLogHistory: any = [];
  temporaryItemObj: any;
  tempItem: any;
  existingIndicator;
  previousModel: any;
  thryvOrNoBPItem: boolean = false;
  tempCalltracking: any;
  storageID: string;

  constructor(
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private route: ActivatedRoute,
    private itemService: ItemService,
    private Params: ParamsService,
    private logger: LoggerService,
    private utils: UtilsService,
    private storageService: StorageService,
    private salesforceService: SalesforceService,
    private footerService: FooterService,
    private spinnerService: NgxSpinnerService,
    private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService,
    private router: Router,
    private watchHelperService: WatchHelperService,
    private constant: Constants,
    private profile: ProfileService,
    private copyService: CopyService,
    private grouper: GroupService,
    private spinnerTextService: SpinnerService,
    private Shared: SharedService,
    private Copysheet: CopysheetService,
    private fieldCount: FieldcountService,
    private file: FileService,
  ) {
    this.initCustomWidgets();
  }

  ngOnInit() {
    this.footerService.changeTab("item");
    this.footerService.SpinnerText = this.constant.ITEM_SAVE;
    this.routeSub = this.route.params.subscribe((params) => {
      this.stateParamsId = params["itemId"]; //log the value of id
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.existingIndicator = JSON.parse(JSON.stringify(res));
      this.indicator = JSON.parse(JSON.stringify(res));
      //  This line not using getting erros for sentry
      // let items = Object.keys(this.indicator.items);
      this.customerId = this.indicator.account.kgenCustomerId;
      this.frmlength = (this.stateParamsId && this.indicator.items[this.stateParamsId]) ? this.indicator.items[this.stateParamsId].form.length : 0;
      //add ItemId as stateparams id into indicator object so thet it will be accessed from anywhere
      this.indicator.stateParamsId = this.stateParamsId; //pending work ===> need to destroy stateparames id once user coumes out of item page .
      if (this.stateParamsId && this.indicator.items[this.stateParamsId]) {
        this.uniqItemId = this.stateParamsId + "v" + this.indicator.items[this.stateParamsId].order.version;
      }
      this.indicatorStore.update((state) => {
        return {
          indicator: this.indicator,
          updatedFrom: "[ItemComponent] ngOnInit()",
          isloaded: true,
        };
      });



      this.itemobj = this.indicator.items[this.stateParamsId];

      this.salesforceService.getThryvProductDetails(this.indicator.items[this.stateParamsId].order.UDAC).then(res => {
        if ((res["records"] && res["records"][0] && res["records"][0].hasBP__c == false && res["records"][0].isThryvLead__c == true) || (res["records"] && res["records"][0] && res["records"][0].Product_Code__c == 'Vivial - Digital')|| (res["records"] && res["records"][0] && res["records"][0].hasBP__c == false && this.indicator.account && this.indicator.account['address'] && this.indicator.account['address']['country'] == 'NZ')) {
          this.thryvOrNoBPItem = true;
        }
      });

      //Fix for DE15650 and  DE15652: License number mandatory and sensitive heading;
      if (this.itemobj && this.itemobj.heading && this.itemobj.heading.sensitiveHeadingInd === 'Y') {
        this.hasSensitiveHeading = true;
      }
      //console.log('Required fields are: ' +this.itemobj.requiredFields)

      //adding below code to skip unwanted schema fileds for AJSF form
      await this.processModelValues();
      await this.processSchema();
      this.spinnerService.hide();
    });

    //Check ProductType =Print/Digital
    if (this.itemobj && productType.indexOf(this.itemobj.order.productType) == 1) {
      this.isDigitalItem = true;
    }
    let itemRequired = this.indicator.items[this.stateParamsId].requiredFields;
    this.previousErrorCount = this.indicator.items[this.stateParamsId].counter;
    let map = new Map;
    for (let i = 0; i < itemRequired.length; i++) {
      let name = itemRequired[i].replace(/\s/g, "")
      if (name == 'ManageCopysheet') {
        map.set(name, this.indicator.items[this.stateParamsId].hasCopysheet);
      } else {
        map.set(name, this.indicator.items[this.stateParamsId].model[name]);
      }
    }
    this.previousObj = map;
    this.currentModelValue = this.indicator.items[this.stateParamsId].model.modelValue;
    this.sharedData = JSON.parse(JSON.stringify(this.indicator));
    // if(this.sharedData["items"][this.stateParamsId].order.actionCode == 'Renew' && (!this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("dirtyFlag") && !this.sharedData["items"][this.stateParamsId].model['dirtyFlag'])) {
    //   if(this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("ManageCopysheet") && !this.sharedData["items"][this.stateParamsId].requiresCopysheet) {
    //     this.sharedData["items"][this.stateParamsId].requiresCopysheet =  true;
    //   }
    //   if(this.sharedData["items"][this.stateParamsId].hasOwnProperty("hasCopysheet")) {
    //     this.sharedData["items"][this.stateParamsId]["hasCopysheet"] = false;
    //   }
    // }
    // let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], this.sharedData["items"][this.stateParamsId].counter, true, this.sharedData["items"][this.stateParamsId]);
    // this.updateIndictor(this.sharedData,tempData);
    if (this.sharedData["items"][this.stateParamsId].order.actionCode == 'Renew' && (!this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("dirtyFlag") && !this.sharedData["items"][this.stateParamsId].model['dirtyFlag'])) {
      let flag = false;
      if (this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("ManageCopysheet") && !this.sharedData["items"][this.stateParamsId].requiresCopysheet) {
        this.sharedData["items"][this.stateParamsId].requiresCopysheet = true;
        flag = true;
      }
      if (this.sharedData["items"][this.stateParamsId].hasOwnProperty("hasCopysheet") && !this.sharedData["items"][this.stateParamsId].requiresCopysheet) {
        this.sharedData["items"][this.stateParamsId]["hasCopysheet"] = false;
        flag = true;
      }
      /*if(flag) {
        let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], this.sharedData["items"][this.stateParamsId].counter, true, this.sharedData["items"][this.stateParamsId]);
        this.updateIndictor(this.sharedData,tempData);
      }*/
    }
    if (this.sharedData["items"][this.stateParamsId].order.actionCode == 'Renew' && (!this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("dirtyFlag") && !this.sharedData["items"][this.stateParamsId].model['dirtyFlag'])) {
      if (this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("LicenseNumber")) {
        let isLicenseRequired = false;
        let requiredFields = this.sharedData["items"][this.stateParamsId].requiredFields;
        if (requiredFields.indexOf('LicenseNumber') > -1) {
          isLicenseRequired = true;
        }
        if (isLicenseRequired) {
          this.sharedData = JSON.parse(JSON.stringify(this.sharedData));
          this.sharedData["items"][this.stateParamsId].model["LicenseNumber"] = "";
          let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], this.sharedData["items"][this.stateParamsId].counter, true, this.sharedData["items"][this.stateParamsId]);
          this.updateIndictor(this.sharedData, tempData);
          this.sharedData = JSON.parse(JSON.stringify(this.sharedData));
        }
      }
    }
    // For SEMP rehandle
    if (this.sharedData["items"][this.stateParamsId].order.actionType == 'Update' && this.sharedData["items"][this.stateParamsId].order.requireContent) {
      let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], this.sharedData["items"][this.stateParamsId].counter, true, this.sharedData["items"][this.stateParamsId]);
      this.updateIndictor(this.sharedData, tempData);
      this.sharedData = JSON.parse(JSON.stringify(this.sharedData));
    }
    this.previousModel = null;
  }

  async processSchema() {
    if (Object.keys(this.itemobj.schema).length > 0 && Object.keys(this.itemobj.form).length > 0) {
      let schemaObj = Object.keys(this.itemobj.schema.properties);
      let tempObj = JSON.parse(JSON.stringify(this.itemobj));

      schemaObj.forEach((value, index) => {
        if (!tempObj.schema.properties[value].title) {
          tempObj.schema.properties[value].title = value;
        }

        if (tempObj.schema.properties[value].default == null) {
          delete tempObj.schema.properties[value].default;
        }
      });
      //Removing Duplicate SAVE Button  from SF Form
      let duplicateFlag;

      for (let index = 0; index < tempObj.form.length; index++) {
        if (
          tempObj.form[index] &&
          tempObj.form[index].title &&
          tempObj.form[index].title == "SAVE"
        ) {
          tempObj.form[index].type = "submit"; // Adding Button type submit
          tempObj.form[index].htmlClass = "item-submit";
          if (duplicateFlag) {
            tempObj.form.splice(index, 1);
            index--;
          }
          duplicateFlag = true;
        }
      }

      for (let i = 0; i < tempObj.form.length; i++) {
        if (tempObj.form[i].type === "showCampaigns" || (tempObj.order.UDAC && tempObj.order.UDAC === 'ML' && tempObj.form[i].key === "ManageCopysheet")) {
          tempObj.form[i].type = "hidden";
          tempObj.form[i].notitle = true
        }
      }
      //await this.digitalItemComponent.
      this.itemSchema = tempObj.schema;
      this.itemForm = tempObj.form;
      this.itemData = this.itemobj.model;
    }


  }
  async processModelValues() {

    this.indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    if (!this.indicator.order.opportunity.owner.operatorId) {
      this.logger.logError("Order.opportunity.owner.operatorId is " + this.indicator.order.opportunity.owner.operatorId);
      this.spinnerService.hide();
    }

    if (this.stateParamsId && this.indicator.items[this.stateParamsId] && (!this.indicator.items[this.stateParamsId].model.dirtyFlag ||
      (this.indicator.items[this.stateParamsId].model.hasOwnProperty("AdvertiserBudget") &&
        this.indicator.items[this.stateParamsId].order.adBudget != null))
    ) {
      this.indicator.items[this.stateParamsId].model.AdvertiserBudget = this.indicator.items[this.stateParamsId].order.adBudget;
    }
    if (
      this.indicator.items[this.stateParamsId].copy &&
      this.indicator.items[this.stateParamsId].copy.attachments &&
      this.indicator.items[this.stateParamsId].copy.attachments.length == 1
    ) {
      if (this.indicator.items[this.stateParamsId].copy.attachments[0] == null) {
        this.indicator.items[this.stateParamsId].copy.attachments = [];
      }
    }
    if (this.indicator.items[this.indicator.stateParamsId].order.productCode === "YPC" && this.indicator.items[this.indicator.stateParamsId].order.UDAC === "ML") {

      for (let i = 0; i < this.indicator.items[this.indicator.stateParamsId].form.length; i++) {
        let formArray = this.indicator.items[this.indicator.stateParamsId].form[i].key;
        if (formArray === "ManageCopysheet") {
          this.indicator.items[this.indicator.stateParamsId].form.splice(i, 1);
        }
      }
      if (this.indicator.items[this.indicator.stateParamsId].schema.properties.hasOwnProperty("ManageCopysheet")) {

        delete this.indicator.items[this.indicator.stateParamsId].schema.properties["ManageCopysheet"];
      }
      if (this.indicator.items[this.indicator.stateParamsId].model.hasOwnProperty("ManageCopysheet")) {

        delete this.indicator.items[this.indicator.stateParamsId].model["ManageCopysheet"];
      }
      if (this.indicator.items[this.indicator.stateParamsId].requiresCopysheet == true) {
        this.indicator.items[this.indicator.stateParamsId].requiresCopysheet = false;
        this.indicator.items[this.indicator.stateParamsId].required--;
      }
    }
    // For displaying copysheet required message in case of YPC and Digital item
    if (this.indicator.items[this.indicator.stateParamsId].order.productCode === "YPC"
      && this.indicator.items[this.indicator.stateParamsId].order.productType === constant.ITEM_TYPE_DIGITAL
      && this.indicator.items[this.indicator.stateParamsId].requiresCopysheet === true) {
      this.indicator.items[this.indicator.stateParamsId].model.copySheetIndicator = "Y";
    }
    //US27344 -- SEM Plus/SEM Premium - Budget change(assinging AdvertiserBudget value again to model which was made undefined in printcontentgrid.controller.js -- vm.Indicator.itemLoading)
    if (this.indicator.items[this.indicator.stateParamsId].order.productType == constant.ITEM_TYPE_DIGITAL && (this.indicator.items[this.indicator.stateParamsId].order.actionCode == "Renew" || this.indicator.items[this.indicator.stateParamsId].order.actionType == "Update") &&
      (this.indicator.items[this.indicator.stateParamsId].order.productCode == "SEMP" || this.indicator.items[this.indicator.stateParamsId].order.productCode == "SEM") && this.indicator.items[this.indicator.stateParamsId].asset
      && this.indicator.items[this.indicator.stateParamsId].order.statusCode == 3000) {
      this.indicator.items[this.indicator.stateParamsId].model.AdvertiserBudget = this.indicator.items[this.indicator.stateParamsId].order.adBudget;
      //  this.indicator.items[this.indicator.stateParamsId].asset.renewStatus = false;
    }
    if (this.indicator.items[this.indicator.stateParamsId].order.productType == constant.ITEM_TYPE_DIGITAL && this.indicator.items[this.indicator.stateParamsId].order.productCode == "YPC" && this.indicator.items[this.indicator.stateParamsId].asset && (this.indicator.items[this.indicator.stateParamsId].order.actionCode == "Renew" || this.indicator.items[this.indicator.stateParamsId].order.actionType == "Update") && this.indicator.items[this.indicator.stateParamsId].order.statusCode == 3000) {
      this.indicator.items[this.indicator.stateParamsId].model.Category = this.indicator.items[this.indicator.stateParamsId].asset.Category_Id__c;
      this.indicator.items[this.indicator.stateParamsId].model.GeographyName = this.indicator.items[this.indicator.stateParamsId].order.geoName;
      this.indicator.items[this.indicator.stateParamsId].model.GeoID = this.indicator.items[this.indicator.stateParamsId].order.geoId;

    } else if (this.indicator.items[this.indicator.stateParamsId].order.productCode == "YPC") {
      this.indicator.items[this.indicator.stateParamsId].model.GeographyName = this.indicator.items[this.indicator.stateParamsId].order.geoName;
      this.indicator.items[this.indicator.stateParamsId].model.GeoID = this.indicator.items[this.indicator.stateParamsId].order.geoId;
    }
    //creating the itemVisited flag to resolve the LateWork issue where AM user login in order when order is already filled.
    this.indicator.items[this.stateParamsId].model.itemVisited = true;
    //this.indicator.items[this.stateParamsId].model.dirtyFlag = true; // dirty flag is set to true for all item //check if this flag is not required for any item and set it accordingly
    this.indicator.items[this.stateParamsId].model['digitalApiFlag'] = true;

    // .then(success=>{
    //   console.log('storage service call!!!!! success');
    //   let index = this.utils.indexOf(this.indicator.items, this.indicator.items[this.stateParamsId]);
    //   if (index < Object.keys(this.indicator.items).length -1 ){
    //     if (this.indicator.items[this.utils.getKey(this.indicator.items, index + 1)].order) {
    //       let nextItemId = this.indicator.items[this.utils.getKey(this.indicator.items, index + 1)].order.svItemId;
    //       this.router.navigate([`/orderitems/item/${nextItemId}`]);
    //     }
    //   }else{
    //     this.router.navigate([`/publish`]);
    //   }
    // });

    this.indicator.items[this.stateParamsId].saveClicked = false;
    this.indicator.stateParamsId = this.stateParamsId;

    await this.updateCoOpFields();
    let tempIndicator;
    if (this.indicator.items[this.indicator.stateParamsId].model.BusinessProfile) {
      if (constant.SEO_PRODUCT_CODE.indexOf(this.indicator.items[this.indicator.stateParamsId].order.productCode) > -1 && this.indicator.items[this.indicator.stateParamsId].model.isFirstLoadForSEO == undefined) {
        this.indicator.items[this.indicator.stateParamsId].model.isFirstLoadForSEO = true;
      } else {
        this.indicator.items[this.indicator.stateParamsId].model.isFirstLoadForSEO = false;
      }

      tempIndicator = await this.watchHelperService.setItemValuesFromBP(this.stateParamsId, this.indicator.items[this.stateParamsId].model.BusinessProfile, this.indicator, 'item');
      this.indicator.bundles.forEach((bundle, index, bundles) => {
        bundle.children.forEach((child, childIndex, children) => {
          if (bundle.parent == this.indicator.stateParamsId && this.indicator.items[child] && this.indicator.items[bundle.parent].model) {
            if (this.indicator.items[bundle.parent].model.BusinessProfile) {
              this.indicator.items[child].model.BusinessProfile = this.indicator.items[bundle.parent].model.BusinessProfile;
            }
            if (this.indicator.items[bundle.parent].model.listingId) {
              this.indicator.items[child].model.BusinessProfile = this.indicator.items[bundle.parent].model.listingId;
            }
            if (this.indicator.items[bundle.parent].model.ListingId) {
              this.indicator.items[child].model.ListingId = this.indicator.items[bundle.parent].model.ListingId;
            }
            //Condition for DPM DE16820 Fix
            if (this.indicator.items[bundle.parent].order.productCode === "DPM") {
              this.indicator.items[bundle.parent].model.initialDataSet = true;
            }
          }
        })
      });
    }
    else {
      tempIndicator = this.indicator
    }
    //watchhelper service to populate values into  model at the time of item loading //SPPOW
    this.indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    this.indicator.items[this.stateParamsId].model['CoOPPIAmount'] = "0";
    this.indicator.items[this.stateParamsId].model['CoOPPIStatus'] = "No";
    this.indicator.items[this.stateParamsId].model["CoOpNIAmount"] = "0";

    //Digital SEO item populate Business name and primary city from Business Profile selected dropdown
    if (tempIndicator.items[this.stateParamsId].order.productType === 'Digital' && tempIndicator.items[this.stateParamsId].order.productCode === "SEO" && tempIndicator.items[this.stateParamsId].order.actionType === "New" && tempIndicator.items[this.stateParamsId].order.actionCode === "Add") {
      let businessProfileId = tempIndicator.items[this.stateParamsId].model.BusinessProfile;
      if (businessProfileId != null && businessProfileId != undefined && businessProfileId != '') {
        await this.profile.getByListing('digital', businessProfileId).then((resp) => {
          if (resp && resp[businessProfileId] && resp[businessProfileId].listings) {
            let city = resp[businessProfileId].listings.listing[0].businessAddress.city;
            let name = resp[businessProfileId].listings.listing[0].businessName;
            tempIndicator.items[this.stateParamsId].model["PrimaryCity"] = city;
            tempIndicator.items[this.stateParamsId].model["BusinessName"] = name;
          }
        });
      } else {
        tempIndicator.items[this.stateParamsId].model["PrimaryCity"] = '';
        tempIndicator.items[this.stateParamsId].model["BusinessName"] = '';
      }
    }

    for (var orderItem in this.indicator.items) {
      var item = this.indicator.items[orderItem].order;
      //fix for Invalid Campaign Issue (FUL-14721)
      if (item.productCode === "SEM" || item.productCode === "SEMP" || item.productCode === "SEM Kicker") {
        if (this.indicator.items[orderItem]['data'].products[0] && this.indicator.items[orderItem]['data'].products[0].CampaignList) {
          var a = this.indicator.items[orderItem]['data'].products[0].CampaignList //will have campaigns from vision
          var b = this.indicator.items[orderItem].model.CampaignType //Have CA campaigns
          var valuesA = a.reduce(function (a, c) { a[c.CampaignId] = c.CampaignId; return a; }, {});
          var valuesB = b.reduce(function (a, c) { a[c.CampaignId] = c.CampaignId; return a; }, {});
          var result = a.filter(function (c) { return !valuesB[c.CampaignId] }).concat(b.filter(function (c) { return !valuesA[c.CampaignId] }));
          if (result.length > 0) {
            for (var i = b.length - 1; i >= 0; i--) {
              // updated result.length to result.length -1 as asked by Baskaran
              for (var j = 0; j < result.length - 1; j++) {
                if (result[j].CampaignId && result[j].CampaignId == b[i].CampaignId) {
                  b.splice(i, 1);
                }
              }
            }
          }
        }
      }
    }
    tempIndicator.orderPercent = this.sharedData.orderPercent;
    tempIndicator.items[this.stateParamsId].counter = this.sharedData.items[this.stateParamsId].counter;
    if (this.sharedData["items"][this.stateParamsId].hasOwnProperty("hasCopysheet")) {
      tempIndicator["items"][this.stateParamsId]["hasCopysheet"] = this.sharedData["items"][this.stateParamsId]["hasCopysheet"];
    }
    if (this.sharedData["items"][this.stateParamsId].hasOwnProperty("requiresCopysheet")) {
      tempIndicator["items"][this.stateParamsId]["requiresCopysheet"] = this.sharedData["items"][this.stateParamsId]["requiresCopysheet"];
    }
    if (this.sharedData["items"][this.stateParamsId].model.hasOwnProperty("LicenseNumber")) {
      tempIndicator["items"][this.stateParamsId].model["LicenseNumber"] = this.sharedData["items"][this.stateParamsId].model["LicenseNumber"];
    }
    this.indicatorStore.update((state) => {
      return {
        indicator: tempIndicator,
        updatedFrom: "[ItemComponent] processModelValues()",
        isloaded: true,
      };
    });
    this.spinnerService.hide();
  }
  async lockField(billingAccountId) {
    for (
      let j = 0; j < this.indicator.items[this.stateParamsId].form.length; j++) {
      if (this.indicator.items[this.stateParamsId].form[j].key == "BusinessProfile") {
        this.indicator.items[this.stateParamsId].schema.properties.BusinessProfile.readonly = true;
      }
      if (billingAccountId != this.indicator.items[this.stateParamsId].order.billingAccountId) {
        this.indicator.items[this.stateParamsId].order.billingAccountId = billingAccountId;
        this.indicator.items[this.stateParamsId].model.BillingAccount = billingAccountId;
      }
      if (
        billingAccountId == this.indicator.items[this.stateParamsId].order.billingAccountId &&
        this.indicator.items[this.stateParamsId].form[j].key == "BillingAccount"
      ) {
        this.indicator.items[this.stateParamsId].schema.properties.BillingAccount.readonly = true;
      }
    }
  }

  async updateCoOpFields() {

    this.indicator = JSON.parse(JSON.stringify(this.indicator));
    //Co-opStatus should be default to No
    if (
      this.indicator.items[this.stateParamsId].order.actionType == "New" &&
      this.indicator.items[this.stateParamsId].model["CoOpType"] == null
    ) {
      this.indicator.items[this.stateParamsId].model["CoOpType"] = "No";
    }


    this.salesforceService
      .getCoOp_NI_Amount(this.stateParamsId, this.indicator.order.sfdcOrderId)
      .subscribe((res) => {
        if (this.indicator.items[this.stateParamsId] && res["records"] && res["records"][0]) {
          this.indicator = JSON.parse(JSON.stringify(this.indicator));
          this.indicator.items[this.stateParamsId].model['CoOPPIAmount'] =
            res["records"][0].CoOp_PI_Amount__c
              ? res["records"][0].CoOp_PI_Amount__c.toString()
              : "0";
          this.indicator.items[this.stateParamsId].model['CoOPPIStatus'] =
            res["records"][0].CoOp_PI_Status__c
              ? res["records"][0].CoOp_PI_Status__c.toString()
              : "";
          this.niAmount =
            res["records"][0].MRC__c
              ? res["records"][0].MRC__c.toString()
              : "0";
          if (
            this.indicator.items[this.stateParamsId].model["CoOpType"] == null ||
            this.indicator.items[this.stateParamsId].order.actionCode === "Renew"
          ) {
            this.indicator.items[this.stateParamsId].model["CoOpType"] = res["records"][0].CoOp_Type__c;
          }
          if (this.indicator.items[this.stateParamsId].model["CoOpType"] == "Yes") {
            this.indicator.items[this.stateParamsId].model["CoOPPIAmount"] =
              res["records"][0].CoOp_NI_Amount__c
                ? res["records"][0].CoOp_NI_Amount__c.toString()
                : "0";
          } else if (
            this.indicator.items[this.stateParamsId].model["CoOpType"] == "No"
          ) {
            this.indicator.items[this.stateParamsId].model["CoOpNIAmount"] = "0";
          }
        }

      },
        (err) => {

          this.logger.logError("<strong>Failed to get CoOP field details </strong><br> Source system :Salesforce" + JSON.stringify(err));
        }
      );
  }

  ngAfterViewInit() {
    if (this.digitalItemComponent) {
      this.digitalItemComponent.processDigitalItemSchema(this.indicator);
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next();
    this.routeSub.unsubscribe();
    //Remove stateparamsID valuse after coming out of item page
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.indicator = JSON.parse(JSON.stringify(res));
      this.indicator.stateParamsId = ""; //pending work ===> need to destroy stateparames id once user coumes out of item page .
      this.indicatorStore.update((state) => {
        return {
          indicator: this.indicator,
          updatedFrom: "[ItemComponent] ngOnDestroy()",
          isloaded: true,
        };
      });
    });

  }
  initCustomWidgets() {
    this.customWidgets = {
      input: InputComponent,
      //string :StringComponent,
      string: ItemStringComponent,
      profilepicklist: ProfilepicklistComponent,
      select: SelectComponent,
      call: CallComponent,
      billing: BillingComponent,
      phonenumberfield: ItemPhonenumberfieldComponent,
      state: StateComponent,
      campaignMain: CampaignComponent,
      contactdropdown: ContactdropdownComponent,
      zipcodeValidation: ZipcodeValidationComponent,
      "checkbox-inlinebasic": CheckboxinlinecaComponent,
      specialtiesItemdirective: SpecialtiesitemdirectiveComponent,
      dpmhoursofoperation: DpmHoursofoperationComponent,
      businessCategoriesItem: BusinessCategoriesItemComponent,
      campaignaccount: CampaignAccountComponent,
      dateTime: AppointmentdatetimeComponent,
      managecopy: CopySheetComponent,
      extraline: ExtralineComponent,
      itemCategory: ItemCategoryComponent,
      hoursofoperation: ItemHoursofoperationComponent,
      websiteurl: WebsiteUrlComponent,
      proposalid: ProposalIdComponent,
      textarea: TextareaComponent,
      profiledirective: ProfiledDirectiveComponent,
      tradename: TradenameComponent,
      websitetype: WebsitetypeComponent

    };

  }
  onChangesItemFields(args) {
    let model;
    let currentCount;
    this.indicatorQuery.getIndicatorT().pipe(takeUntil(this.destroySubject$)).subscribe((data) => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      model = this.sharedData["items"][this.stateParamsId].model;
      if (!this.previousModel) {
        this.previousModel = model;
        this.hasCopySheet = this.sharedData["items"][this.stateParamsId]['hasCopysheet'];
      }
      if (this.sharedData["items"][this.stateParamsId].order.actionCode == 'Renew' && (!(_.isEqual(model, this.previousModel)) || this.hasCopySheet != this.sharedData["items"][this.stateParamsId]['hasCopysheet'])) {
        this.previousModel = model;
        if (this.sharedData.items[this.stateParamsId].hasOwnProperty("hasCopysheet")) {
          this.sharedData.items[this.stateParamsId].model['renewHasCopysheet'] = this.sharedData["items"][this.stateParamsId]['hasCopysheet'];
          this.sharedData.items[this.stateParamsId].order.productType === "Print" ? this.sharedData.items[this.stateParamsId].model["ManageCopysheet"].copySheetIndicator = "Y" : this.sharedData.items[this.stateParamsId].model["copySheetIndicator"] = "Y";
          this.sharedData.items[this.stateParamsId].order.requireContent = true;
        }
        this.sharedData.items[this.stateParamsId].model['dirtyFlag'] = true;
      }
      this.storageService.update(
        "item",
        this.uniqItemId,
        this.sharedData.items[this.stateParamsId].model
      );
      currentCount = this.sharedData["items"][this.stateParamsId].counter;
      //currentCount = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.stateParamsId].counter));
      if (this.sharedData.items[this.stateParamsId].order.productCode === "SEMP") {
        this.sharedData["orderPercent"] = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator["orderPercent"]));
      }
      this.previousObj.forEach((value: any, key: any) => {
        if (key && key.toLowerCase() === 'calltracking') {
          if (model['modelValue'] && (this.currentModelValue != model['modelValue'] || (model['modelValue'] == 'Yes' &&
            JSON.stringify(model[key]) !== JSON.stringify(value) && model[key] && model[key].length == 0))) {
            this.currentModelValue = model['modelValue'];
            this.previousObj.set(key, model[key]);
            if (this.previousObj.has('ManageCopysheet')) {
              this.previousObj.set('ManageCopysheet', this.sharedData["items"][this.stateParamsId]['hasCopysheet']);
            }
            let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], currentCount, true, this.sharedData["items"][this.stateParamsId]);
            this.updateIndictor(this.sharedData, tempData, true);
          }
        } else if (key && key.toLowerCase() === 'managecopysheet') {
          if ((this.sharedData["items"][this.stateParamsId]['requiresCopysheet'] != undefined && this.sharedData["items"][this.stateParamsId]['hasCopysheet'] != undefined /* && value != undefined */) && value != false && value != this.sharedData["items"][this.stateParamsId]['hasCopysheet']) {//enter this condition only when hasCopySheet is true when the page loads
            this.previousObj.set(key, this.sharedData["items"][this.stateParamsId]['hasCopysheet']);
            let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], currentCount, true, this.sharedData["items"][this.stateParamsId]);
            this.updateIndictor(this.sharedData, tempData, true);
          }
        } else if (key && typeof model[key] === 'object' && JSON.stringify(model[key]) !== JSON.stringify(value) && key != 'ManageCopysheet') {
          this.previousObj.set(key, model[key]);
          let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], currentCount, true, this.sharedData["items"][this.stateParamsId]);
          this.updateIndictor(this.sharedData, tempData, true);
        } else if (key && model && typeof model[key] !== 'object' && model[key] != value && key != 'ManageCopysheet') {
          if (key.toLowerCase() === 'businesshours' && (model[key] || value)) {
            this.sharedData["items"][this.stateParamsId].model['BusinessHours'] = model[key] ? model[key] : value;
          }
          this.previousObj.set(key, model[key]);
          let tempData = this.requiredfieldcount.getNewItemCount(this.sharedData["items"][this.stateParamsId].requiredFields, this.sharedData["items"][this.stateParamsId].model, this.sharedData["items"][this.stateParamsId].order, this.sharedData["orderPercent"], currentCount, true, this.sharedData["items"][this.stateParamsId]);
          this.updateIndictor(this.sharedData, tempData, true);
        }
      });
    });
  }

  onSubmit(tempIndicator): Promise<any> {

    return new Promise<[any]>((resolve, reject) => {
      if (tempIndicator.items[tempIndicator.stateParamsId].order.UDAC == "PRINTOLN") {
        let indicatorValue = this.indicatorQuery.getValue().indicator;
        this.Shared.submitMethod('', indicatorValue);
      }
      tempIndicator.stateParamsId = this.stateParamsId;
      if (
        tempIndicator.items[this.stateParamsId].model.WebsiteUrl &&
        tempIndicator.items[this.stateParamsId].model.WebsiteUrl.isDexmediaSite === ""
      ) {
        tempIndicator.items[this.stateParamsId].model.WebsiteUrl.isDexmediaSite = "N";
      }

      if (
        tempIndicator.items[this.stateParamsId].model.WebsiteUrl &&
        tempIndicator.items[this.stateParamsId].model.WebsiteUrl.isNew === ""
      ) {
        tempIndicator.items[this.stateParamsId].model.WebsiteUrl.isNew = "N";
      }
      // CallTracking Audit
      if (
        tempIndicator.items[this.stateParamsId].model.modelValue == "No" &&
        tempIndicator.items[this.stateParamsId].order.productType == constant.ITEM_TYPE_DIGITAL &&
        (tempIndicator.items[this.stateParamsId].order.productCode === "YPC" ||
          tempIndicator.items[this.stateParamsId].order.productCode ===
          "Single IYP")
      ) {
        tempIndicator.items[this.stateParamsId].model["CallTracking"] = {
          OptIntoCallTracking: false,
        };
      } //CallTracking Audit

      if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType) {
        for (var i = 0; i < tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType.length; i++) {
          if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].DisplayURL && tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].DestinationURL) {
            if (!(this.utils.validateURL(tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].DestinationURL, false))) {
              let errorMsg = "<strong>Please Enter Correct Destination URL</strong><br>" + "for " + tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignType + " Campaign " + tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignDescription;
              this.logger.logError(errorMsg);
              this.rejectFlag = true
              this.spinnerService.hide();
              break;
            }
          }
          if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].DisplayURL === "") {
            let errorMsg = "<strong>Please Enter a Display URL</strong><br>" + "for " + tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignType + " Campaign " + tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignDescription;
            this.logger.logError(errorMsg);
            this.rejectFlag = true;
            this.spinnerService.hide()
            break;
          }
          if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].DestinationURL === "") {
            let errorMsg = "<strong>Please Enter a DestinationURL URL</strong><br>" + "for " + tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignType + " Campaign " + tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignDescription;
            this.logger.logError(errorMsg);
            this.rejectFlag = true;
            this.spinnerService.hide()
            break;
          } else {
            this.rejectFlag = false
          }
        }
      }
      if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType) {
        for (var i = 0; i < tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType.length; i++) {
          if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].CampaignId && !('CampaignType' in tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i])) {
            if (tempIndicator.items[tempIndicator.stateParamsId].data.products) {
              for (var k = 0; k < tempIndicator.items[tempIndicator.stateParamsId].data.products.length; k++) {
                if (tempIndicator.items[tempIndicator.stateParamsId].data.products[k].CampaignList) {
                  for (var j = 0; j < tempIndicator.items[tempIndicator.stateParamsId].data.products[k].CampaignList.length; j++) {
                    if (tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].ListingId === tempIndicator.items[tempIndicator.stateParamsId].data.products[k].CampaignList[j].ListingId) {
                      tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i]['CampaignType'] = tempIndicator.items[tempIndicator.stateParamsId].data.products[k].CampaignList[j].CampaignType;
                      tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i].GeoList = tempIndicator.items[tempIndicator.stateParamsId].data.products[k].CampaignList[j].GeoList;
                    }
                  }
                }
              }
            }
          }
          tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i] = this.convertBadKeys(tempIndicator.items[tempIndicator.stateParamsId].model.CampaignType[i]);
        }
      }
      if (tempIndicator.items[this.stateParamsId].order.productCode === "SEMP") {
        let data = tempIndicator.items[this.stateParamsId].model.AppDateTime
        let tempDate = data ? data.date : null;
        if (tempDate) {
          let date = tempDate.slice(5, 7) + "-" + tempDate.slice(8, 10) + "-" + tempDate.slice(0, 4);
          date = date.replace(/-/g, "\/");
          let appdatetime = date + " " + data.time;
          tempIndicator.items[this.stateParamsId].model.AppDateTime = appdatetime;

        } else { }
      }
      if (
        (tempIndicator.items[this.stateParamsId].order.statusCode < 3000 &&
          tempIndicator.items[this.stateParamsId].order.productType == constant.ITEM_TYPE_PRINT) ||
        (tempIndicator.items[this.stateParamsId].order.statusCode < 3000 &&
          tempIndicator.items[this.stateParamsId].order.productType == constant.ITEM_TYPE_DIGITAL)
      ) {
        let message =
          "Not saving item since statusCode is :  " +
          tempIndicator.items[this.stateParamsId].order.statusCode;
      }

      if (!this.rejectFlag && tempIndicator.items[this.stateParamsId].counter === 0) {
        let profileResponse;
        let id;
        tempIndicator = this.isDigitalItem
          ? this.digitalItemComponent.onSubmit(tempIndicator)
          : tempIndicator;
        this.indicatorStore.update((state) => {
          return {
            indicator: tempIndicator,
            updatedFrom: "[ItemComponent] onSubmit()",
            isloaded: true,
          };
        });
        if (tempIndicator.items[tempIndicator.stateParamsId].order.productType === constant.ITEM_TYPE_PRINT) {
          this.profile.getAllPrintListing(tempIndicator.account.kgenCustomerId, {
            productcode: tempIndicator.items[tempIndicator.stateParamsId].order.directoryNumber,
            productissuenum: tempIndicator.items[tempIndicator.stateParamsId].order.directoryIssueNumber,
            sectioncode: tempIndicator.items[tempIndicator.stateParamsId].order.section
          }).then(response => {
            profileResponse = response;
            id = tempIndicator.account.kgenCustomerId;
            this.itemSaveDigitalPrint(tempIndicator, profileResponse, id);
          }, err => {
            this.spinnerService.hide();
            //console.log("ProfileResponse  error:",err.esbServiceFault[0].exceptionDetail[0]);
          });

        } else if (tempIndicator.items[tempIndicator.stateParamsId].order.productType === constant.ITEM_TYPE_DIGITAL) {
          if (!this.thryvOrNoBPItem) {
            this.profile.getByEid(tempIndicator.account.enterpriseAccountId).then(response => {
              id = tempIndicator.account.enterpriseAccountId;
              profileResponse = response;
              this.itemSaveDigitalPrint(tempIndicator, profileResponse, id);
            }, err => {
              this.spinnerService.hide();
              //console.log("ProfileResponse  error:",err.esbServiceFault[0].exceptionDetail[0]);
            });
          } else {
            let thryvUpdate = { "id": { "listings": { "listings": tempIndicator.items[tempIndicator.stateParamsId].order.listingId }, "hasWarningOrError": false } }
            this.itemSaveDigitalPrint(tempIndicator, thryvUpdate, "id");
          }
        }
      } else {
        this.indicatorStore.update((state) => {
          return {
            indicator: tempIndicator,
            updatedFrom: "[ItemComponent] onSubmit() in else part",
            isloaded: true,
          };
        });
        this.routeToNextItem();
        console.log('item end');
        // resolve(/*{
        // status: "success",
        // message: "Incomplete item. Will not save"
        //  }*/);

      }
    }).catch(error => {
      this.logger.logError("Profile get by eid failed");
      //console.log('item end:::',error);
    });
  }
  itemSaveDigitalPrint(tempIndicator, profileResponse, id): Promise<any> {
    return new Promise<[any]>(async (resolve, reject) => {
      tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
      if (tempIndicator.items[tempIndicator.stateParamsId].order.productCode !== "SuperClick" && tempIndicator.items[tempIndicator.stateParamsId].order.isFYPDigital != true) {
        let data = profileResponse[id];
        if (data.hasWarningOrError !== "true" && tempIndicator.stateParamsId) {
          //DE29993 -- Put below condition because geoId was showing old value in update call-- start
          if (tempIndicator.items[tempIndicator.stateParamsId].order.productCode === "Single IYP" &&
            tempIndicator.items[tempIndicator.stateParamsId].model.hasOwnProperty('GeoID')
            && tempIndicator.items[tempIndicator.stateParamsId].order.hasOwnProperty('geoId')) {
            tempIndicator.items[tempIndicator.stateParamsId].model.GeoID = tempIndicator.items[tempIndicator.stateParamsId].order.geoId;
          }
          //DE29993 -- Put below condition because geoId was showing old value in update call-- end
          await this.itemService.update(
            tempIndicator.account,
            tempIndicator.order,
            tempIndicator.items[tempIndicator.stateParamsId],
            data.listings.listing,
            tempIndicator.stateParamsId,
            "item")
            .then((updateResult) => {
              if (tempIndicator.items[tempIndicator.stateParamsId].order.statusCode >= 6000
                /*Looks like requiresCopysheet is a conflicting check.
                It is suppposed to be true when a copysheet is required by the item.
                However this is being checked for true before uploading which menas there is no copysheet
                Commenting it for now to ensure that thisngs work.
                Plz update this commnt section if you uncomment the check on why you are uncommenting.
                Uncommenting it will result in following issue:
                1. if a copysheet is uploaded for a straight renewal item it will not get upaloded to kGen
                and will also not be uploaded in salesforce
                */
                //&&tempIndicator.items[tempIndicator.stateParamsId].requiresCopysheet
                && tempIndicator.items[tempIndicator.stateParamsId].hasCopysheet
                && tempIndicator.items[tempIndicator.stateParamsId].needsNewCopysheet) {
                let item = tempIndicator.items[tempIndicator.stateParamsId];
                let adVersionIncIndflg;
                this.storageService.get("item", item.order.svItemId).then(data => {
                  adVersionIncIndflg = data[item.order.svItemId].adVersionIncInd;
                  console.log("adVersionIncIndflg:", data)
                })
                this.copyService.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
                  .then((value) => {
                    this.spinnerService.show();
                    this.copyService.generateCompiled(item.order.svItemId + "v" + item.order.version, tempIndicator)
                      .then((updateCopyResp) => {
                        this.storageService.updateField("copyz", item.order.svItemId + "v" + item.order.version, { "kgenurl": updateCopyResp.url })
                          .subscribe(() => { }, err => { })
                        let data = {
                          "type": "uploadCopySheet",
                          "id": "",
                          "callerId": "UCC",
                          "correlationId": this.grouper.getAllItemsOfGroupButNotSelf(item.order.svItemId, tempIndicator).toString(),
                          "sourceId": "",
                          "copySheet": {
                            "customerId": this.customerId,
                            "svItemId": item.order.svItemId,
                            "productCode": item.order.directoryNumber,
                            "productIssueNum": item.order.directoryIssueNumber,
                            //"adVersionIncInd": "Y", // Y when order status is greater than 6000, increases copysheet version
                            "adVersionIncInd": adVersionIncIndflg ? adVersionIncIndflg : "N", //get adVersionIncInd value from indicator 
                            "pdfUrl": updateCopyResp.url,
                            "date": this.utils.getKgenDate(new Date()),
                            "operatorId": tempIndicator.order.opportunity.owner.operatorId
                          }
                        };
                        this.copyService.uploadCompiled(item.order.svItemId, item.order.sfdcItemId, data)
                          .then((copy) => {
                            if (copy.hasWarningOrError === 'false') {
                              this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', copy.transactionId, null, "Success", "Item Page");
                            } else {
                              this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', copy.transactionId, copy.esbServiceFault.exceptionDetail[0].errorDescription[0], "Failure", "Item Page");
                            }
                            this.storageService.update("itemHistory", item.order.svItemId, this.newActivityLogHistory);
                            //  Params.saving = false;
                            //vm.Params.loadingbar = false;
                            tempIndicator.items[item.order.svItemId]['KGENCopysheet'] = "Item-Page";
                            this.storageService.updateField("copyz", item.order.svItemId + "v" + item.order.version, { "data.KGENCopysheet": "Item-Page" }).subscribe(() => { }, err => { });
                            this.indicatorStore.update((state) => {
                              return {
                                indicator: tempIndicator,
                                updatedFrom: "[ItemComponent] uploadcompiled",
                                isloaded: true,
                              }
                            });
                            //Setting adVersionIncInd flag to "N" 
                            this.storageService.updateField("item", item.order.svItemId, { "data.adVersionIncInd": "N" }).subscribe(res => {
                              console.log("adversion set to N for", item.order.svItemId, res)
                            });
                            if (data.correlationId != null && data.correlationId != "") {
                              let itemArray = data.correlationId.split(",");
                              for (let item of itemArray) {
                                if (item != null && item != "" && item != undefined) {
                                  this.storageService.updateField("item", item, { "data.adVersionIncInd": "N" }).subscribe(res => {
                                    console.log("adversion set to N for", item, res)
                                  });
                                }
                              }
                            }
                            tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                            //update salesforce copysheet URL for all Items of IA Group
                            let sfUpdateitems = [];
                            for (var itemId in tempIndicator.items) {
                              if (tempIndicator.items[itemId].data.iaGroupId === tempIndicator.items[item.order.svItemId].data.iaGroupId) {
                                let sfItem = {
                                  "eItemId": itemId,
                                  "SFDCItemId": tempIndicator.items[itemId].order.sfdcItemId,
                                  "CACopySheetUrl": updateCopyResp.url,
                                  "copySheetStatus": "Success",
                                  "systemExceptionReason": " "
                                };
                                sfUpdateitems.push(sfItem);
                              }
                            }

                            this.salesforceService.saveMultipleSFdata(sfUpdateitems).subscribe((res) => {
                              this.spinnerService.hide();

                              if (updateResult.hasWarningOrError == 'false' && !updateResult.servicePending) {
                                this.routeToNextItem();
                              }
                            }
                              , err => {
                                this.logger.logError("<strong>Error in update SFData <strong><br>Source System : Salesforce <br>");
                              });

                            //console.log('item end');
                            resolve(updateResult);
                          }, err => {
                           // this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', err.error.response.transactionId, err.error.response.esbServiceFault.exceptionDetail[0].errorDescription);
                           if (
                            item &&
                            item.order &&
                            item.order.svItemId &&
                            tempIndicator &&
                            tempIndicator.items &&
                            tempIndicator.items[item.order.svItemId] &&
                            tempIndicator.items[item.order.svItemId].copy &&
                            tempIndicator.items[item.order.svItemId].copy.copysheet &&
                            err.error &&
                            err.error.response &&
                            err.error.response.transactionId &&
                            err.error.response.esbServiceFault &&
                            err.error.response.esbServiceFault.exceptionDetail &&
                            err.error.response.esbServiceFault.exceptionDetail[0] &&
                            err.error.response.esbServiceFault.exceptionDetail[0].errorDescription
                        ) {
                          this.logObject(item.order.svItemId, 'Copysheet Value', tempIndicator.items[item.order.svItemId].copy.copysheet, '', err.error.response.transactionId, err.error.response.esbServiceFault.exceptionDetail[0].errorDescription);
                        } else {
                          console.error('Some properties are undefined or not in the expected format.');
                        }
                          this.storageService.update("itemHistory", item.order.svItemId, this.newActivityLogHistory);

                            //update salesforce copysheet URL and error for all Items of IA Group
                            let sfUpdateitems = [];
                            for (var itemId in tempIndicator.items) {
                              if (tempIndicator.items[itemId].data.iaGroupId === tempIndicator.items[item.order.svItemId].data.iaGroupId) {
                                let sfItem = {
                                  "eItemId": itemId,
                                  "SFDCItemId": tempIndicator.items[itemId].order.sfdcItemId,
                                  "systemExceptionReason": JSON.stringify(err),
                                  "CACopySheetUrl": updateCopyResp.url,
                                  "copySheetStatus": "Failure"
                                };
                                sfUpdateitems.push(sfItem);
                              }
                            }

                            this.salesforceService.saveMultipleSFdata(sfUpdateitems).subscribe((res) => {
                              this.spinnerService.hide();

                            }, err => {
                              this.logger.logError("<strong>Error in update SFData <strong><br>Source System : Salesforce <br>" + JSON.stringify(err));
                            });
                            this.spinnerService.hide();
                            let errorObject = err.error.response.esbServiceFault;
                            let errorCode = errorObject.sourceSystem;
                            let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                            let errorMsg = "<strong>Transaction : " + err.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                            this.logger.logError(errorMsg);
                            // console.log('item end');
                            reject(err);
                            // console.log("updateResult : : " + JSON.stringify(updateResult));
                          });
                      }, err => {
                        let errorMessage;
                        if (err.error != undefined && err.error != null && err.error.message != undefined && err.error.message != null) {
                          errorMessage = err.error.message
                        } else {
                          errorMessage = JSON.stringify(err);
                        }
                        this.spinnerService.hide();
                        //Params.saving = false;
                        //vm.Params.loadingbar = false;
                        //fix for FUL-8569 --start
                        tempIndicator.items[tempIndicator.stateParamsId].hasCopysheet = false;
                        //TODO : If any issue in counter ,we will implement this
                        +// tempIndicator.items[tempIndicator.stateParamsId].counter++;
                          this.logger.logError("Error in Generate copysheet for item " + tempIndicator.stateParamsId + ".This error may be because of one of the below issues: <br> 1. Image is corrupted.Please remove and add valid Image.try again <br> 2.Please look the error : " + errorMessage);
                        //fix for FUL-8569 --end
                      });
                  }, err => {
                    this.spinnerService.hide();
                    // Params.saving = false;
                    // vm.Params.loadingbar = false;
                    this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                  });
              } else {
                // vm.Params.loadingbar = false;
                // Params.saving = false;
                //console.log('item end');
                if (updateResult.hasWarningOrError == 'false' && !updateResult.servicePending) {
                  this.routeToNextItem();
                }
                resolve(updateResult);
              }

            }, error => {
              this.spinnerService.hide();
              //console.log('item end');
              reject(error);
            });

          // Need to check if this item needs a new copysheet
          // if it does then we call generateCompiled and send
        } else {
          this.spinnerService.hide();
          if (data.esbServiceFault) {
            let errorCode = data.esbServiceFault[0].exceptionDetail[0].errorCode;
            let errorDesc = data.esbServiceFault[0].exceptionDetail[0].errorDescription;
            this.logger.logError("<strong>Transaction Id : " + data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc);
          }
        }
      } else {
        this.makeServiceCall(tempIndicator);
      }
    });
  }
  validateFeaturedMessage() {
    //future implementation
  }
  addAuditLog(temporaryItemObj) {
    let modelData = this.indicator.items[this.indicator.stateParamsId].model;
    let copyitem = this.tempItem;
    if (this.indicator.items[this.indicator.stateParamsId].order.productType == "Print") {
      //Print with CS item required
      if (this.indicator.items[this.indicator.stateParamsId].requiresCopysheet == true) {

        if (copyitem && this.indicator.items[this.indicator.stateParamsId].copy
          && (copyitem.instructions || this.indicator.items[this.indicator.stateParamsId].copy.instructions)
          && copyitem.instructions != this.indicator.items[this.indicator.stateParamsId].copy.instructions) {
          this.logObject(this.indicator.stateParamsId, "Additional Instructions", this.indicator.items[this.indicator.stateParamsId].copy.instructions, copyitem.instructions);
        }
      } else if (this.indicator.items[this.indicator.stateParamsId].requiresCopysheet == false) {
        //Print with no CS item required
      }
      if (temporaryItemObj.LicenseNumber && modelData.LicenseNumber && temporaryItemObj.LicenseNumber != modelData.LicenseNumber) {
        this.logObject(this.indicator.stateParamsId, "License Number", modelData.LicenseNumber, temporaryItemObj.LicenseNumber);
      } else if (modelData.LicenseNumber && !temporaryItemObj.LicenseNumber) {
        this.logObject(this.indicator.stateParamsId, "License Number", modelData.LicenseNumber, null);
      }
      if (temporaryItemObj.ListingId && modelData.ListingId && temporaryItemObj.ListingId != modelData.ListingId) {
        let newListing = this.indicator.items[this.indicator.stateParamsId].listings.filter(add => add.listingId == modelData.ListingId)[0].listedName;
        // let oldListing= this.indicator.items[this.indicator.stateParamsId].listings.filter(add=> add.listingId == temporaryItemObj.ListingId)[0].listedName;
        let oldListing;

        if (
          this.indicator &&
          this.indicator.items &&
          this.indicator.items[this.indicator.stateParamsId] &&
          Array.isArray(this.indicator.items[this.indicator.stateParamsId].listings)
        ) {
          const filteredListings = this.indicator.items[this.indicator.stateParamsId].listings.filter(
            add => add.listingId == temporaryItemObj.ListingId
          );

          if (filteredListings.length > 0) {
            oldListing = filteredListings[0].listedName;
          } else {
            console.error('No matching listing found.');
          }
        } else {
          console.error('Properties are undefined or not in the expected format.');
        }

        this.logObject(this.indicator.stateParamsId, "Listing", newListing, oldListing);
      } else if (modelData.ListingId && !temporaryItemObj.ListingId) {
        let newListing = this.indicator.items[this.indicator.stateParamsId].listings.filter(add => add.listingId == modelData.ListingId)[0].listedName;
        this.logObject(this.indicator.stateParamsId, "Listing", newListing, null);
      }
      if (temporaryItemObj.BillingBasis && modelData.BillingBasis && temporaryItemObj.BillingBasis != modelData.BillingBasis) {
        let newBillingBasis = modelData.BillingBasis == 'M' ? 'Monthly' : 'Issue';
        let oldBillingBasis = temporaryItemObj.BillingBasis == 'M' ? 'Monthly' : 'Issue';
        this.logObject(this.indicator.stateParamsId, "Billing Basis", newBillingBasis, oldBillingBasis);
      } else if (modelData.BillingBasis && !temporaryItemObj.BillingBasis) {
        let newBillingBasis = modelData.BillingBasis == 'M' ? 'Monthly' : 'Issue';
        this.logObject(this.indicator.stateParamsId, "Billing Basis", newBillingBasis, null);
      }
      if (temporaryItemObj.OmitAddress && modelData.OmitAddress && temporaryItemObj.OmitAddress != modelData.OmitAddress) {

        let newOmitAddress = omitAddressObj.filter(add => add.value == modelData.OmitAddress)[0].name;
        let oldOmitAddress = omitAddressObj.filter(add => add.value == temporaryItemObj.OmitAddress)[0].name;
        this.logObject(this.indicator.stateParamsId, "Omit Address", newOmitAddress, oldOmitAddress);
      } else if (modelData.OmitAddress && !temporaryItemObj.OmitAddress) {
        let newOmitAddress = omitAddressObj.filter(add => add.value == modelData.OmitAddress)[0].name;
        this.logObject(this.indicator.stateParamsId, "Omit Address", newOmitAddress, null);
      }

    } else if (this.indicator.items[this.indicator.stateParamsId].order.productType == "Digital") {
      //Digital item with CS required
      if (this.indicator.items[this.indicator.stateParamsId].requiresCopysheet == true) {

        if (copyitem && this.indicator.items[this.indicator.stateParamsId].copy
          && (copyitem.instructions || this.indicator.items[this.indicator.stateParamsId].copy.instructions)
          && copyitem.instructions != this.indicator.items[this.indicator.stateParamsId].copy.instructions) {
          this.logObject(this.indicator.stateParamsId, "Additional Instructions", this.indicator.items[this.indicator.stateParamsId].copy.instructions, copyitem.instructions);
        }
      }
      //SEM
      else if (this.indicator.items[this.indicator.stateParamsId].order.productCode == "SEMP") {
        if (temporaryItemObj.hasOwnProperty("CampaignType") && modelData.hasOwnProperty("CampaignType") && temporaryItemObj.CampaignType.length != modelData.CampaignType.length) {
          if (modelData.CampaignType.length > 0) {
            this.logObject(this.indicator.stateParamsId, "Campaign Change", 'Yes', 'No');
          } else {
            this.logObject(this.indicator.stateParamsId, "Campaign Change", 'No', 'Yes');
          }
        }
      }
      //SEO
      else if (this.indicator.items[this.indicator.stateParamsId].order.productCode == "SEO") {

      }
      //SPPOW
      else if (this.indicator.items[this.indicator.stateParamsId].order.productCode == "DPM") {
        if (temporaryItemObj.BusinessPhone != modelData.BusinessPhone) {
          this.logObject(this.indicator.stateParamsId, "Phone", modelData.BusinessPhone, temporaryItemObj.BusinessPhone);
        }
        if (temporaryItemObj.HideAddress != modelData.HideAddress) {
          this.logObject(this.indicator.stateParamsId, "Hide Address", modelData.HideAddress, temporaryItemObj.HideAddress);
        }
      }
      //Other Digital items
      else {

      }
    }
    if (this.newActivityLogHistory.length > 0) {
      this.storageService.update("itemHistory", this.indicator.stateParamsId, this.newActivityLogHistory);
      this.newActivityLogHistory = [];
    } else {
      this.newActivityLogHistory = [];
    }
    this.temporaryItemObj = JSON.parse(JSON.stringify(this.indicator.items[this.indicator.stateParamsId].model));
    if (this.indicator.items[this.indicator.stateParamsId].copy) {
      this.tempItem = JSON.parse(JSON.stringify(this.indicator.items[this.indicator.stateParamsId].copy));
    }
  }
  logObject(itemId, fName, fValue, fOldValue, transactionId?, errorMsg?, apiStatus?, page?) {
    let localItemId = this.indicator.stateParamsId ? this.indicator.stateParamsId : itemId
    this.newActivityLogHistory.push({
      'displayName': this.indicator['user']['name'],
      'email': this.indicator['user']['email'],
      'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
      'itemId': localItemId,
      'itemStatus': this.indicator && this.indicator.items && this.indicator.items[localItemId] && this.indicator.items[localItemId].order && this.indicator.items[localItemId].order.status ? this.indicator.items[localItemId].order.status : "",
      'itemActionCode': this.indicator.items[localItemId].order.actionCode + "-" + this.indicator.items[localItemId].order.actionType,
      'fieldName': fName,
      'fieldOldValue': fOldValue,
      'fieldNewValue': fValue,
      'transactionId': transactionId,
      'errorMsg': errorMsg,
      'apiStatus': apiStatus,
      'page': page
    });
  }
  saveButton(args) {
    this.spinnerTextService.setSpinnerText('');
    this.spinnerService.show();
    this.spinnerTextService.setSpinnerText(this.constant.ITEM_SAVE);
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.indicator = { ...res };
    });
    this.indicator = JSON.parse(JSON.stringify(this.indicator));
    //Calling the logic to replace copysheet if uploaded
    if (
      this.indicator.items &&
      this.indicator.stateParamsId &&
      this.indicator.items[this.indicator.stateParamsId] &&
      this.indicator.items[this.indicator.stateParamsId].isCopySheetUploaded &&
      this.indicator.items[this.indicator.stateParamsId].copy &&
      this.indicator.items[this.indicator.stateParamsId].copy.uploadFile
    ) {
      let uploadObj = this.indicator.items[this.indicator.stateParamsId].copy.uploadFile;
      // Updating isCopySheetUploaded flag to false for replace copysheet
      this.indicator.items[this.indicator.stateParamsId].isCopySheetUploaded = false;
      // this.replaceUploadedCopySheet(uploadObj);
    } else {
      // Handle the case where properties are not defined or accessible
      console.error('Some properties are undefined or not accessible.');
    }

    let tempIndicator = JSON.parse(JSON.stringify(this.indicator));
    this.addAuditLog(this.existingIndicator.items[this.stateParamsId].model);
    //this.validateFeaturedMessage();
    if (tempIndicator.items[this.stateParamsId].model.Contact) {
      let obj = tempIndicator.items[this.stateParamsId].model.Contact;
      let arr = [];
      let errFlag = false;
      let keyValue = contactKeyValue;
      for (let key in tempIndicator.items[this.stateParamsId].model.Contact) {
        if (obj.hasOwnProperty(key) && keyValue.indexOf(key) >= 0) {
          if (obj[key] == "") {
            arr.push(key);
            errFlag = true;
          }
        }
      }
      if (errFlag) {
        let errorMsg = "Contact You have selected have missing required field: " + arr;
        this.logger.logError(errorMsg);
        tempIndicator.items[this.stateParamsId].model.Contact = "";
        //update Counter
        let tempData = this.requiredfieldcount.getNewItemCount(tempIndicator["items"][this.stateParamsId].requiredFields, tempIndicator["items"][this.stateParamsId].model, tempIndicator["items"][this.stateParamsId].order, tempIndicator["orderPercent"], tempIndicator["items"][this.stateParamsId].counter, true, tempIndicator["items"][this.stateParamsId]);
        this.updateIndictor(tempIndicator, tempData);

        this.spinnerService.hide();
        return false;
      }

    }
    //add SEMP related code
    if (this.indicator.items[this.indicator.stateParamsId].order.productCode == "SEMP" && this.indicator.items[this.indicator.stateParamsId].order.actionCode == "Add") {
      let one_day = 1000 * 60 * 60 * 24;
      let final;
      if (typeof this.indicator.items[this.indicator.stateParamsId].model.AppDateTime === 'object') {
        let date1 = this.indicator.items[this.indicator.stateParamsId].model.AppDateTime.date;
        let date2 = this.indicator.items[this.indicator.stateParamsId].model.AppDateTime.time;
        let date1_ms = new Date(date1);
        let hrs = Number(date2.match(/^(\d+)/)[1]);
        let mnts = Number(date2.match(/:(\d+)/)[1]);
        let format = date2.match(/\s(.*)$/)[1];

        if (format == "PM" && hrs != 12) {
          hrs = hrs + 12;
        }

        let hours = hrs.toString();
        let minutes = mnts.toString();
        let time = hours + ":" + minutes;
        let finaltime = time.split(':');
        let date2_ms = date1_ms.setHours(parseInt(finaltime[0]), parseInt(finaltime[1]), 0)
        date2_ms = new Date().getTime();//system current date
        let difference_ms = date1_ms.getTime() - date2_ms;// diff in ms
        final = Math.round(difference_ms / one_day); //shuld be greater than 72 hours
      } else {
        let date1 = this.indicator.items[this.indicator.stateParamsId].model.AppDateTime
        // let date1 = "2/17/2018";
        let date1_ms = new Date(date1);
        let date2_ms = new Date().getTime();//system current date
        let difference_ms = date1_ms.getTime() - date2_ms;// diff in ms
        final = Math.round(difference_ms / one_day);;

      }
      if (final < 3) {
        let errorMsg = "The SEM-Premium Appointment must be 72 hours or greater from the current activity date!";
        this.logger.logError(errorMsg);
        this.spinnerService.hide();
        return false;
      }
    }
    let index = this.utils.indexOf(
      tempIndicator.items,
      tempIndicator.items[this.stateParamsId]
    );
    //DE27282 Start
    if (tempIndicator.items[this.stateParamsId].model.copySheetIndicator &&
      tempIndicator.items[this.stateParamsId].model.copySheetIndicator == "Y") {
      if (
        typeof tempIndicator.items[this.stateParamsId].model.ManageCopysheet ===
        "object"
      ) {
        tempIndicator.items[
          this.stateParamsId
        ].model.ManageCopysheet.copySheetIndicator = "Y";
      } else {
        tempIndicator.items[this.stateParamsId].model.ManageCopysheet = "Y";
      }
    }
    if (tempIndicator.items[this.stateParamsId].model.ManageCopysheet && typeof tempIndicator.items[this.stateParamsId].model.ManageCopysheet === "object") {
      tempIndicator.items[this.stateParamsId].model.ManageCopysheet.manageCopyModified = false;
    } else {
      tempIndicator.items[this.stateParamsId].model.manageCopyModified = false;
    }

    tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
    tempIndicator.items[this.stateParamsId].isCopysheetSaved = true;
    this.storageService.updateField(
      "copyz",
      this.uniqItemId,
      { "data.isCopysheetSaved": true }
    )
      .subscribe(() => { }, err => { })
    //DE27282 End
    this.storageService.update("item", this.uniqItemId,
      tempIndicator.items[this.stateParamsId].model
    )
      .then((data) => {
        this.onSubmit(tempIndicator);
        if (tempIndicator.items[this.stateParamsId].order.productType === constant.ITEM_TYPE_PRINT || tempIndicator.items[this.stateParamsId].order.productCode === 'YPC') {
          tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
          tempIndicator.items[this.stateParamsId].isCopysheetSaved = true;
          tempIndicator.items[this.stateParamsId].isCoysheetSubmitted = false;
          tempIndicator.items[this.stateParamsId].isCoysheetAnnotated = false;
          this.indicatorStore.update((state) => {
            return {
              indicator: tempIndicator,
              updatedFrom: "[ItemComponent] UpdateCopySheetProperties",
              isloaded: true,
            };
          });
          this.storageService.updateField("copyz", this.stateParamsId + "v" + tempIndicator.items[this.stateParamsId].order.version, { "data.isCoysheetSubmitted": false })
            .subscribe(() => { }, err => { })
          this.storageService.updateField("copyz", this.stateParamsId + "v" + tempIndicator.items[this.stateParamsId].order.version, { "data.isCopysheetSaved": true })
            .subscribe(() => { }, err => { })
        }
      });
  }

  back() {
    let temp;
    this.indicatorQuery.getIndicator().subscribe(data => {
      temp = JSON.parse(JSON.stringify(data));
      if (temp.items[temp.stateParamsId].model.ManageCopysheet &&
        temp.items[temp.stateParamsId].model.ManageCopysheet.manageCopyModified) {
        this.logger.logError("You must Save the item to send the Copy Sheet/Attachment/Ad Instructions to graphics");
      } else {
        window.history.back();
      }
    });
  }
  convertBadKeys(obj) {
    let newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let trimmedKey = key.trim();
        newObj[trimmedKey] = obj[key];
      }

    }
    return newObj;
  };

  private updateIndictor(data, tempIndicator, onChangeFlag?) {
    let count = 0;
    /*for (let profileId in data.profiles) {
      if (data.profiles[profileId].schema[1].properties.hasOwnProperty("ConfirmHoursOfOperation")) {
        count = this.requiredfieldcount.get(data['profiles'][profileId].schema[1], data['profiles'][profileId].form[1], data['profiles'][profileId].model);
      }
    }
    if (onChangeFlag === undefined) {
      data.orderPercent.bpTotalCount += count;
    } else if(onChangeFlag && !this.bpCountUpdated){
      data.orderPercent.bpTotalCount += count;
      this.bpCountUpdated = true;
    }*/
    let counter = [];
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[this.stateParamsId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(data, tempIndicator.itemCount, data.orderPercent, data.items[this.stateParamsId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    //get BP count
    const { bpcount, bpcountMax, bpTotalCount } = this.requiredfieldcount.getBPCount(data.profiles);
    data.orderPercent.bpTotalCount = bpTotalCount

    //console.log("  data", data);
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[ItemComponent] updateIndictor()",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);
  }

  private getItemErrorCount(data, itemCount, orderPercent, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    //let latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    for (let i = 0; i < data.order["OrderItems"].length; i++) {
      if (data.items[data.order["OrderItems"][i].svItemId] && data.items[data.order["OrderItems"][i].svItemId].counter > 0) {
        counter = counter + 1
      }
    }
    orderPercent = this.updateCounter(productType, counter, orderPercent);
    return orderPercent;
  }

  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === constant.ITEM_TYPE_DIGITAL) {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === constant.ITEM_TYPE_PRINT) {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }
  async makeServiceCall(tempIndicator) {
    await this.itemService.update(
      tempIndicator.account,
      tempIndicator.order,
      tempIndicator.items[this.stateParamsId],
      tempIndicator.listing,
      this.stateParamsId,
      "item"
    ).then(success => {
      //console.log("success :",success);
      this.routeToNextItem();
    });
  }
  routeToNextItem() {
    let index = this.utils.indexOf(this.indicator.items, this.indicator.items[this.stateParamsId]);
    this.spinnerService.hide();
    if (this.indicator.items[this.stateParamsId].order.productType == constant.ITEM_TYPE_PRINT) {
      this.router.navigate([`/printitems`]);
    } else {
      if (index < Object.keys(this.indicator.items).length - 1) {
        if (this.indicator.items[this.utils.getKey(this.indicator.items, index + 1)].order) {
          let nextItemId = this.indicator.items[this.utils.getKey(this.indicator.items, index + 1)].order.svItemId;
          this.router.navigate([`/orderitems/item/${nextItemId}`]);
        }
      } else {
        if (this.indicator && this.indicator.orderPercent && (this.indicator.orderPercent.digitalCount + this.indicator.orderPercent.printCount) > 0) {
          if (Object.keys(this.indicator.items).length == 1) {
            this.router.navigate([`/orderitems`]);
          } else {
            let firstItem = Object.keys(this.indicator.items)[0];
            this.router.navigate([`/orderitems/item/${firstItem}`]);
          }

        } else {
          this.router.navigate([`/publish`]);
        }
        //this.router.navigate([`/publish`]);
      }
    }
  }
  updateIAGroupCounter(itemID, Indicator) {
    let id = itemID;
    let currentCount = Indicator.items[itemID].counter;
    var count = this.fieldCount.get(Indicator.items[itemID].schema, Indicator.items[itemID].form, Indicator.items[itemID].model, Indicator.items[itemID].order);
    Indicator.items[itemID].counter = count;
    if (Indicator.items[itemID].requiresCopysheet
      && !Indicator.items[itemID].hasCopysheet) {
      // Indicator.items[itemID].counter++;
      let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][itemID].requiredFields, Indicator["items"][itemID].model, Indicator["items"][itemID].order, Indicator["orderPercent"], Indicator["items"][itemID].counter, true, Indicator["items"][itemID]);
      this.updateIndictorforReplaceCS(itemID, Indicator, tempData, count, true);

    } else {
      //Indicator =indicatorVal; //JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      Indicator.items[itemID].counter = currentCount;
      let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][id].requiredFields, Indicator["items"][id].model, Indicator["items"][id].order, Indicator["orderPercent"], Indicator["items"][id].counter, true, Indicator["items"][id]);
      this.updateIndictorforReplaceCS(id, Indicator, tempData, currentCount, true);
    }
  }
  private updateIndictorforReplaceCS(itemId, data, tempIndicator, currentCount, updateFlag) {
    let counter = [];
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCountforReplaceCS(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    if (updateFlag == true) {
      this.updatCounterStore(data);
    }
  }
  private getItemErrorCountforReplaceCS(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;
    return orderPercent;
  }
  private updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[CopySheetComponent] updatCounterStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);

  }
  // async replaceUploadedCopySheet(data){
  //   var copysheet;
  //   if (data && data.uploadedFile && data.uploadedFile.copysheet) {
  //     this.indicator = JSON.parse(JSON.stringify(this.indicator));
  //     this.indicator.items[this.indicator.stateParamsId].hasCopysheet = true;
  //     this.tempCalltracking = this.indicator.items[this.indicator.stateParamsId].hasCopysheet;
  //     //this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet['manageCopyModified'] = true;
  //     if (this.indicator.items[this.indicator.stateParamsId].order.productType == "Digital" && this.indicator.items[this.indicator.stateParamsId].order.productCode == "YPC") {
  //       this.indicator.items[this.indicator.stateParamsId].model['manageCopyModified'] = true;
  //     } else {
  //       this.indicator.items[this.indicator.stateParamsId].model['ManageCopysheet']['manageCopyModified'] = true;
  //     }
  //     //Adding flag for adVersionIncInd of copysheet
  //     //this.indicator.items[this.indicator.stateParamsId]['adVersionIncInd'] = 'Y';
  //     this.storageService.updateField("item", this.indicator.stateParamsId, { "data.adVersionIncInd": "Y" }).subscribe(res => {
  //       console.log(res)
  //    });
  //     // this.ngAfterViewChecked();
  //     if (data && data.uploadedFile.copysheet != null) {
  //       if (data.fileType === 'copysheet') {
  //         this.indicator.items[this.indicator.stateParamsId].isCoysheetSubmitted = true;
  //         this.storageService.updateField("copyz",
  //           this.indicator.stateParamsId + "v" + this.indicator.items[this.indicator.stateParamsId].order.version,
  //           { "data.isCoysheetSubmitted": true }
  //         ).subscribe(data => { }, err => { })
  //       }

  //       let copsheetFileType = (data.uploadedFile.copysheet.split(".")[data.uploadedFile.copysheet.split(".").length - 1]).toLowerCase();
  //       let pdf_copysheet_url;
  //       console.log(copsheetFileType);            
  //       if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {

  //         await this.convertImageToPdf(data.uploadedFile.copysheet).then((pdfurl) => {
  //           console.log("pdfurl :: ", pdfurl);
  //           pdf_copysheet_url = pdfurl;
  //         }).catch((err) => {
  //           console.log('Exception in converting image to PDF : ', err)
  //         })
  //       } else {
  //         pdf_copysheet_url = data.uploadedFile.copysheet;
  //       }

  //       if (this.indicator.items[this.indicator.stateParamsId].order.statusCode >= 6000) {
  //         this.indicator.items[this.indicator.stateParamsId].needsNewCopysheet = true;
  //       }
  //       var oldCopysheet = (this.indicator.items[this.indicator.stateParamsId].hasCopysheet == true) ? this.indicator.items[this.indicator.stateParamsId].copy.copysheet : null;
  //       /*
  //       this.indicator.items[this.indicator.stateParamsId].copy.copysheet = data.uploadedFile.copysheet;
  //       this.indicator.items[this.indicator.stateParamsId].baseCopysheet = data.uploadedFile.copysheet;
  //       this.indicator.items[this.indicator.stateParamsId].actionListObj = [];
  //       copysheet = data.uploadedFile.copysheet;
  //       */
  //       this.indicator.items[this.indicator.stateParamsId].copy.copysheet = pdf_copysheet_url;
  //       this.indicator.items[this.indicator.stateParamsId].baseCopysheet = pdf_copysheet_url;
  //       copysheet = pdf_copysheet_url;

  //       if (!oldCopysheet) {
  //         this.updateIAGroupCounter(this.indicator.stateParamsId, this.indicator);
  //       }
  //       this.indicator = JSON.parse(JSON.stringify(this.indicator));
  //       this.indicator.items[this.indicator.stateParamsId].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
  //       /*
  //       if (this.indicator.items[this.indicator.stateParamsId].copy.fileType.toLowerCase() === 'png' || this.indicator.items[this.indicator.stateParamsId].copy.fileType.toLowerCase() === 'jpeg' || this.indicator.items[this.indicator.stateParamsId].copy.fileType.toLowerCase() === 'jpg') {
  //         if (!this.indicator.items[this.indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
  //           this.indicator.items[this.indicator.stateParamsId].copy.attachments = [];
  //           this.indicator.items[this.indicator.stateParamsId].copy.attachments.push(this.indicator.items[this.indicator.stateParamsId].copy.copysheet);
  //         }
  //         else {
  //           this.indicator.items[this.indicator.stateParamsId].copy['attachments'].push(this.indicator.items[this.indicator.stateParamsId].copy.copysheet);
  //         }
  //       }
  //       */
  //       if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {
  //         if (!this.indicator.items[this.indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
  //           this.indicator.items[this.indicator.stateParamsId].copy.attachments = [];
  //           this.indicator.items[this.indicator.stateParamsId].copy.attachments.push(data.uploadedFile.copysheet);
  //         }
  //         else {
  //           this.indicator.items[this.indicator.stateParamsId].copy['attachments'].push(data.uploadedFile.copysheet);
  //         }
  //       }
  //       this.Copysheet.updateCopysheet(this.indicator.stateParamsId + "v" + this.indicator.items[this.indicator.stateParamsId].order.version, this.indicator.items[this.indicator.stateParamsId].copy)
  //         .then((response) => { 
  //           console.log('response is ***********--------',response);
  //           console.log('indicator object',this.indicator);
  //           var activityLogHistoryObj = {
  //             'displayName': this.indicator.user.name,
  //             'email': this.indicator.user.email,
  //             'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
  //             'itemId': this.indicator.stateParamsId,
  //             'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
  //             'itemActionCode':this.indicator.items[this.indicator.stateParamsId].order.actionCode + "-" + this.indicator.items[this.indicator.stateParamsId].order.actionType,
  //             'fieldName': 'Copysheet Value',
  //             'fieldOldValue': oldCopysheet,
  //             'fieldNewValue': this.indicator.items[this.indicator.stateParamsId].copy.copysheet,
  //             'apiStatus' : 'Success'
  //           }
  //           this.storageService.update("itemHistory", this.indicator.stateParamsId, activityLogHistoryObj);
  //         }
  //           , err => {
  //             this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //             var activityLogHistoryObj = {
  //               'displayName': this.indicator.user.name,
  //               'email': this.indicator.user.email,
  //               'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
  //               'itemId': this.indicator.stateParamsId,
  //               'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
  //               'itemActionCode':this.indicator.items[this.indicator.stateParamsId].order.actionCode + "-" + this.indicator.items[this.indicator.stateParamsId].order.actionType,
  //               'fieldName': 'Copysheet Value',
  //               'fieldOldValue': oldCopysheet,
  //               'fieldNewValue': this.indicator.items[this.indicator.stateParamsId].copy.copysheet,
  //               'apiStatus' : 'Failure'
  //             }
  //             this.storageService.update("itemHistory", this.indicator.stateParamsId, activityLogHistoryObj);
  //           });

  //       this.indicator.items[this.indicator.stateParamsId].hasCopysheet = true;
  //       //Update CopysheetIndicatorValue
  //       //this.indicator.items[this.indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //       this.indicator.items[this.indicator.stateParamsId].model['digitalApiFlag'] = true;
  //       this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
  //         .subscribe(() => { }, err => { })
  //       if(this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet && typeof this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet === "object"){
  //           this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet.manageCopyModified = true;
  //           this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';                    
  //       } else {
  //         this.indicator.items[this.indicator.stateParamsId].model.manageCopyModified = true;
  //         this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet = 'Y';                    
  //       }
  //       this.indicator.items[this.indicator.stateParamsId].isCopysheetSaved = true;  
  //       var activityLogHistoryObj = {
  //         'displayName': this.indicator.user.name,
  //         'email': this.indicator.user.email,
  //         'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
  //         'itemId': this.indicator.stateParamsId,
  //         //'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
  //         'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
  //         'itemActionCode':this.indicator.items[this.indicator.stateParamsId].order.actionCode + "-" + this.indicator.items[this.indicator.stateParamsId].order.actionType,
  //         'fieldName': 'Copysheet Value',
  //         'fieldOldValue': oldCopysheet,
  //         'fieldNewValue': this.indicator.items[this.indicator.stateParamsId].copy.copysheet
  //       }
  //       this.storageService.update("itemHistory", this.indicator.stateParamsId, activityLogHistoryObj);
  //       // var activityLogHistoryObj = {
  //       //   'displayName': this.indicator.user.name,
  //       //   'email': this.indicator.user.email,
  //       //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
  //       //   'itemId': this.indicator.stateParamsId,
  //       //   'fieldName': 'Copysheet Value',
  //       //   'fieldOldValue': oldCopysheet,
  //       //   'fieldNewValue': this.indicator.items[this.indicator.stateParamsId].copy.copysheet
  //       // }
  //       // this.storageService.update("itemHistory", this.indicator.stateParamsId, activityLogHistoryObj);
  //       this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
  //         .subscribe(() => { }, err => { })
  //       this.logger.log('Copysheet uploaded successfully.');        
  //       //Updating isCopySheetUploaded flag to false for replace copysheet
  //       this.indicator.items[this.indicator.stateParamsId].isCopySheetUploaded = false;
  //       let stateParamsId = this.indicator.stateParamsId;
  //       if (this.indicator.items[stateParamsId].requiresCopysheet) {
  //         let tempData = this.requiredfieldcount.getNewItemCount(this.indicator["items"][stateParamsId].requiredFields, this.indicator["items"][stateParamsId].model, this.indicator["items"][stateParamsId].order, this.indicator["orderPercent"], this.indicator["items"][stateParamsId].counter, true, this.indicator["items"][stateParamsId]);
  //         this.updateIndictorforReplaceCS(stateParamsId, this.indicator, tempData, this.indicator.items[stateParamsId].counter, true);
  //       }
  //       // this.loadingUpload = false;
  //       // this.ngAfterViewChecked();
  //       // this.indicatorStore.update(state => {
  //       //   return {
  //       //     indicator: this.indicator,
  //       //     updatedFrom: "[CopySheetComponent] openUploadDialog"
  //       //   }
  //       // })
  //       //Reverting the code for IA grp
  //       //if the item is part of an IA group
  //       if (this.indicator.items[this.indicator.stateParamsId].data
  //         && this.indicator.items[this.indicator.stateParamsId].data.iaGroupId) {
  //         //go through the other items on the order
  //         for (var key in this.indicator.items) {
  //           this.indicator = JSON.parse(JSON.stringify(this.indicator));
  //           //if the other item has the matching ia group id & isn't the current item
  //           if (this.indicator.items[key].data
  //             && this.indicator.items[key].data.iaGroupId === this.indicator.items[this.indicator.stateParamsId].data.iaGroupId
  //             && this.indicator.items[key].order.svItemId !== this.indicator.stateParamsId) {
  //             //add the copysheet to the other item
  //             this.indicator.items[key].copy.copysheet = data.uploadedFile.copysheet;
  //             this.indicator.items[key].baseCopysheet = data.uploadedFile.copysheet;
  //             this.indicator.items[key].hasCopysheet = true;
  //             //Adding flag for adVersionIncInd of copysheet
  //             //this.indicator.items[key]['adVersionIncInd'] = 'Y';
  //             this.storageService.updateField("item", key, { "data.adVersionIncInd": "Y" }).subscribe(res => {
  //               console.log(res)
  //            });
  //             // update copysheetIndicator value 
  //             if (this.indicator.items[key].order.productType === "Print"){
  //               if (this.indicator.items[key].model.hasOwnProperty('ManageCopysheet')){
  //                   this.indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //                   this.indicator.items[this.indicator.stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //               }else{
  //                   this.indicator.items[key].model['ManageCopysheet'] = {};
  //                   this.indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //                   this.indicator.items[this.indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //               }
  //               this.indicator.items[key].model['digitalApiFlag'] = true;
  //             }else{
  //                 this.indicator.items[key].model['copySheetIndicator'] = 'Y';
  //                 this.indicator.items[this.indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
  //                 this.indicator.items[key].model['digitalApiFlag'] = true;
  //             }
  //             this.indicator.items[key].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
  //             if (this.indicator.items[key].copy.fileType.toLowerCase() === 'png' || this.indicator.items[key].copy.fileType.toLowerCase() === 'jpeg' || this.indicator.items[key].copy.fileType.toLowerCase() === 'jpg') {
  //               if (!this.indicator.items[key].copy.hasOwnProperty('attachments')) {
  //                 this.indicator.items[key].copy.attachments = [];
  //                 this.indicator.items[key].copy.attachments = this.indicator.items[this.indicator.stateParamsId].copy.attachments;
  //               }
  //               else {
  //                 this.indicator.items[key].copy['attachments'] = this.indicator.items[this.indicator.stateParamsId].copy.attachments;
  //               }
  //             }
  //             // if (this.indicator.items[key].hasOwnProperty('actionListObj')) {
  //             //   this.indicator.items[key].actionListObj = this.indicator.items[this.indicator.stateParamsId].actionListObj;
  //             // }
  //             this.updateIAGroupCounter(key, this.indicator);
  //             //update hasCopysheet flag in storage call
  //                 let currentStorageId = key + 'v'+ this.indicator.items[key].order.version;
  //                 this.storageService.updateField("copyz", currentStorageId, { "data.hasCopysheet": true}).subscribe(()=>{},err=>{});

  //             this.indicator = JSON.parse(JSON.stringify(this.indicator));
  //             this.indicator.items[this.indicator.stateParamsId].copy['directoryNumber'] = this.indicator.items[this.indicator.stateParamsId].order.directoryNumber;
  //             this.indicator.items[this.indicator.stateParamsId].copy['directoryIssueNumber'] = this.indicator.items[this.indicator.stateParamsId].order.directoryIssueNumber;
  //             this.Copysheet.updateCopysheet(this.indicator.items[key].order.svItemId + "v" + this.indicator.items[key].order.version, this.indicator.items[key].copy)
  //               .then((response) => { })
  //               .catch(
  //                 err => {
  //                   this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //                 });
  //           }
  //         }
  //       }
  //     }
  //   } else { }
  // }
  convertImageToPdf(imageUrl: any) {
    let pdf_Base64: any;
    let copySheetUrl: string;
    let fd = new FormData();
    let filename = Math.round(Math.random() * 1000000000000) + "";

    console.log('Inside convertImageToPdf method ', imageUrl);
    return new Promise(async (resolve, reject) => {
      try {
        // Trigger the file service to fetch pdf in base64 format
        pdf_Base64 = await this.file.imageToPdf(imageUrl);
        // Convert the pdf in base64 format to blob
        console.log(pdf_Base64);
        const base64Response = await fetch(`data:image/jpeg;base64,${pdf_Base64}`);
        // const base64Response = await fetch(pdf_Base64);
        const blob = await base64Response.blob();
        // Trigger the service to save the pdf as copysheet in mongo database
        fd.append("files", blob, filename + '.' + 'pdf');
        fd.append("user", JSON.parse(JSON.stringify({ parameters: { accountId: this.indicator.order.accountId, svOrderId: this.indicator.order.orderId } })));
        this.file.savePdf(fd).then(resp => {
          console.log('resp :: ', resp);
          resolve(resp['copysheet']);
        });
      } catch (error) {
        reject(error)
      }
    });
  }
}
