import { Query } from '@datorama/akita';
import { campaignState, CampaignStore } from './campaignstore';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CampaignClass } from '../interface/campaign';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn : 'root'
})
export class CampaignQuery extends Query<campaignState>{

    constructor(protected store: CampaignStore) {
        super(store);
      }

      getCampaign(): Observable<CampaignClass>{
          return this.select( state => state.campaign).pipe(take(1));
      }

      getCampign(): Observable<CampaignClass>{
        return this.select( state => state.campaign)
      }

      getIsLoading(): Observable<boolean>{
        return this.selectLoading();
    }

}
