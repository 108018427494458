import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IndicatorQuery } from 'app/state/query';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { StorageService } from '@app/service/storage/storage.service';
import { CopyService } from '@app/service/copy/copy.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorStore } from 'app/state/store';
import { MatTabChangeEvent } from '@angular/material';
import { AttachmentsComponent } from './attachments/attachments.component';
import { BrowseComponent } from './browse/browse.component';
import { truncate } from 'fs';

@Component({
  selector: 'app-copysheet-file',
  templateUrl: './copysheet-file.component.html',
  styleUrls: ['./copysheet-file.component.scss']
})
export class CopysheetFileComponent implements OnInit {

  Indicator: any;
  browseFiles: any = [];
  fileItem: any = [];
  uploader: FileUploader;
  dialogRef: any;
  subscription: any;
  attachments: any = [];
  disableSave: boolean = false;
  selectedItems: any = [];
  @ViewChild(AttachmentsComponent, { static: false }) attachemnetsComponent: AttachmentsComponent
  @ViewChild(BrowseComponent, { static: false }) browseComponent: BrowseComponent

  constructor(private attachment: PhotoService,
    private indicatorQuery: IndicatorQuery,
    private StorageService: StorageService,
    private Copysheet: CopyService,
    private Logger: LoggerService,
    private indicatorStore: IndicatorStore,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data))
    })
    this.subscription = this.attachment.getBehaviorView().subscribe(result => {
      this.browseFiles = result.browseFilesObj
      this.attachments = result.attachmentsObj
      this.fileItem = result.fileItemObj
      this.uploader = result.uploaderObj;
    })
    this.dialogRef = this.data.dialogform.dialogRef
    this.selectedItems = this.data.dialogform.selectedItems
  }

  onSubmit() {
    var oldFiles = this.Indicator.items[this.Indicator.stateParamsId].copy.attachments;
    var addFile = true;
    var newActivityLogHistory = [];
    this.browseFiles = this.arrayUnique(this.browseFiles.concat(this.attachments))
    //add attachment
    if (this.browseFiles && this.browseFiles.length > 0) {
      for (var i = 0; i < this.browseFiles.length; i++) {
        addFile = true;
        if (oldFiles && oldFiles.length > 0) {
          for (var j = 0; j < oldFiles.length; j++) {
            if (this.browseFiles[i] == oldFiles[j]) {
              addFile = false;
              break;
            }
          }
        }
        if (addFile) {
          newActivityLogHistory.push({
            'displayName': this.Indicator.user.name,
            'email': this.Indicator.user.email,
            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
            'itemId': this.Indicator.stateParamsId,
            'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
            'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
            'fieldName': 'Attachment Added',
            'fieldOldValue': null,
            'fieldNewValue': this.browseFiles[i]
          });
        }
      }
    }

    //delete attachment
    if (oldFiles && oldFiles.length > 0) {
      for (var i = 0; i < oldFiles.length; i++) {
        addFile = true;
        if (this.browseFiles && this.browseFiles.length > 0) {
          for (var j = 0; j < this.browseFiles.length; j++) {
            if (oldFiles[i] == this.browseFiles[j]) {
              addFile = false;
              break;
            }
          }
        }
        if (addFile) {
          newActivityLogHistory.push({
            'displayName': this.Indicator.user.name,
            'email': this.Indicator.user.email,
            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
            'itemId': this.Indicator.stateParamsId,
            'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
            'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
            'fieldName': 'Attachment Deleted',
            'fieldOldValue': null,
            'fieldNewValue': oldFiles[i]
          });
        }
      }
    }
    if(this.selectedItems){
      for(let itemId of this.selectedItems){
        this.StorageService.update("item", itemId + "v" + this.Indicator.items[itemId].order.version, this.Indicator.items[itemId].model).then(result => { });
        this.Indicator.items[itemId].copy.attachments = this.browseFiles;
        if (this.Indicator.items[itemId].order.productType == "Digital" && this.Indicator.items[itemId].order.productCode == "YPC") {
          this.Indicator.items[itemId].model['manageCopyModified'] = true;
        } else {
          this.Indicator.items[itemId].model['ManageCopysheet']['manageCopyModified'] = true;
        }
        if (this.Indicator.items[itemId].order.statusCode >= 6000) {
          this.Indicator.items[itemId].needsNewCopysheet = true;
        }
        this.Copysheet.updateCopysheet(itemId + "v" + this.Indicator.items[itemId].order.version, this.Indicator.items[itemId].copy)
          .then(data => {
            this.Logger.logSuccess('Attachments updated Successfully');
          }, err => {
            console.log("Error occured : ", err);
          });
      }
    }else{
    this.StorageService.update("item", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model).then(result => { });
    this.Indicator.items[this.Indicator.stateParamsId].copy.attachments = this.browseFiles;
    //this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['manageCopyModified'] = true;
    if (this.Indicator.items[this.Indicator.stateParamsId].order.productType == "Digital" && this.Indicator.items[this.Indicator.stateParamsId].order.productCode == "YPC") {
      this.Indicator.items[this.Indicator.stateParamsId].model['manageCopyModified'] = true;
    } else {
      this.Indicator.items[this.Indicator.stateParamsId].model['ManageCopysheet']['manageCopyModified'] = true;
    }
    if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
      this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = true;
    }
    this.Copysheet.updateCopysheet(this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].copy)
      .then(data => {
        // $modalInstance.close(this.attachments);
        this.Logger.logSuccess('Attachments updated Successfully');
      }, err => {
        console.log("Error occured : ", err);
      });
    }
    this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[CopysheetFileComponent] onSubmit"
      }
    })
    this.dialogRef.close(this.attachments); //Added this.attachments  FUL-25028
  };

  onCancel() {
    this.dialogRef.close();
    for (let i = 0; i < this.fileItem.length; i++) {
      if (this.fileItem[i].isUploading)
        this.fileItem[i].remove();
    }
    if (this.uploader)
      this.uploader.clearQueue();
  }

  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.attachemnetsComponent.loadAttachments();
      this.disableSave = false;
    }
    else if (event.index === 1) {
      this.browseComponent.updateFileList();
      this.disableSave = false;
    }
    else if (event.index === 2) {
      this.disableSave = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  arrayUnique(array) {
    let a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }
    return a;
  }


  onAdditionRemoval(event){
    this.disableSave = event;
  }
}
