import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Photos } from 'app/interface/photos';

@Injectable()

export class PhotoService {
  photos: Photos = new Photos
  private photoSubject: BehaviorSubject<Photos> = new BehaviorSubject<Photos>(this.photos);
  photoObj = this.photoSubject.asObservable()

  isListing: boolean = false;
  private isListingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isListing);

  constructor() { }

  setBehaviorView(photoBehave: Photos) {
    this.photoSubject.next(photoBehave)
  }

  getBehaviorView(): Observable<Photos> {
    return this.photoSubject.asObservable();
  }
  setBehaviorViewListingValue(isListing: boolean) {
    this.isListingSubject.next(isListing)
  }

  getBehaviorViewListingValue(): Observable<boolean> {
    return this.isListingSubject.asObservable();
  }
}