import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LogoService } from '@app/service/logo/logo.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-deletelogo',
  templateUrl: './deletelogo.component.html',
  styleUrls: ['./deletelogo.component.scss']
})
export class DeletelogoComponent implements OnInit {

  deletedLogoArray: any = [];
  logoArray: any = [];
  message: string;
  photoType: string;
  loadingFlag: boolean;
  photos = {}
  listingId: string = '';
  showMessage: boolean = false;
  sharedLogoObj: Photos = new Photos
  sharedData:any;
  constructor(private _logoService: LogoService,
    private photoService: PhotoService, 
    private indicatorQuery: IndicatorQuery, 
    private indicatorStore: IndicatorStore) {

  }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      this.listingId = this.sharedData['stateParamsId'];
    })
    this.getPhotos(this.listingId);
  }



  deleteLogo(iconId) {
    this.deletedLogoArray.push(iconId);
    for (var i = 0; i < this.logoArray.length; i++) {
      if (this.logoArray[i].iconId == iconId) {
        this.logoArray[i].hideImg = true;
        this.logoArray.splice(i, 1);

      }
    }
    if (!this.logoArray.length) {
      this.showMessage = true;
      this.message = "Click OK to delete the Logo";
    }
    this.sharedLogoObj.deleteLogoarrObj = this.deletedLogoArray;
    this.photoService.setBehaviorView(this.sharedLogoObj);
  }

  getPhotos(listingID) {

    this._logoService.get(listingID).subscribe(Photos => {

      var tempArray = {
        hideImg: true,
        url: "",
        urlName: "",
        thumbImg: "",
        largeImg: "",
        iconId: ""
      }
      if (Photos && Photos["Photos"]) {
        for (let i = 0; i < Photos["Photos"].Photo.length; i++) {
          this.photoType = Photos["Photos"].Photo[i].imageType[0];
          if (Photos != undefined && this.photoType == "LOGO") {
            let imgUrlPrefix = Photos["Imgroot"];
            tempArray.hideImg = false;
            tempArray.url = Photos["Photos"].Photo[i].photoURL[0];
            let urlArray = tempArray.url.split("/");
            tempArray.urlName = urlArray.pop();

            tempArray.thumbImg = imgUrlPrefix + urlArray[6] + "/" + urlArray[7] + "/" + urlArray[8] + "/" + urlArray[9] + "/" + urlArray[10] + "/thumb/" + tempArray.urlName;
            tempArray.largeImg = imgUrlPrefix + urlArray[6] + "/" + urlArray[7] + "/" + urlArray[8] + "/" + urlArray[9] + "/" + urlArray[10] + "/images/" + tempArray.urlName;

            tempArray.iconId = Photos["Photos"].Photo[i].photoId[0];
            this.logoArray.push(tempArray);
            tempArray = {
              hideImg: true,
              url: "",
              urlName: "",
              thumbImg: "",
              largeImg: "",
              iconId: ""
            };

          }

        }
      }
    })
  }
}
