import  * as $ from 'jquery';
import { Router } from '@angular/router';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { StorageService } from '@app/service/storage/storage.service';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { FooterService } from '../components/footer/footer.service';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CallconfirmationComponent } from '../components/call-new/callconfirmation/callconfirmation.component';
//import { CopySheetComponent } from '../components/copy-sheet/copy-sheet.component';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
import { IagroupHelperService } from '@app/service/iagroup-helper/iagroup-helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from '@app/service/product/product.service';
import { GroupService } from '@app/service/group/group.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';

var that;
export class TabulatorModels implements OnInit {
  indicatorValue :Quickbp;
  subscribe: any;
  items: any = [];
  callItemIds:any = [];
  showToastr: boolean = true;
  oldIndData: any;
  redCounter: any;

  constructor(private router: Router,
              private params: ParamsService,
              private indicatorQuery: IndicatorQuery,
              private indicatorStore: IndicatorStore,
              private storageService: StorageService,
              private counterService: CounterService,
              private loggerService: LoggerService,
              private footerService : FooterService,
              private requiredfieldcount: RequiredfieldcountService,
              private indicatorService :IndicatorService,
              private updateIndicatorService :UpdateIndicatorService,
              private iaGroupHelper : IagroupHelperService,
              private matDialog :MatDialog,
              private cdr: ChangeDetectorRef,
              private spinnerService :NgxSpinnerService,
              private productService: ProductService,
              private grouper: GroupService,
              private sfService: SalesforceService,
             ) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  this.oldIndData = this.indicatorValue
                  if(this.indicatorValue && this.indicatorValue['params']) console.log('Token',this.indicatorValue['params']['token']);
                  console.log('Indicator',this.indicatorValue);
                  that = this;
                that.counterService.setUpdate(false);
                });
  }
  ngOnInit(): void { }

  callValue = function(){
    //console.log('this.indicaotr quesry avlue-----------*****>',this.indicatorValue);
  }

  getValue = ()=>{
    return new Array(

      { title: "",  width: 5, field: "counter", formatter:function(cell, formatterParams){
        var value = cell.getValue();
        //that.items= [];
        let itemId =cell.getRow().getData().itemId +'counter';
        if (value > 0){
          return `<div id=${itemId} class='item-order-error item-order-error-margin' ></div>`
        }
        return;
      }},
      { title: "Item",width: 320,  field: "item",cssClass:'tabulator-custom-title-Color',
          cellClick: (e, cell) => {
            this.spinnerService.show();
            setTimeout(() => {// timeout is added for spinner issue
              this.router.navigate([`/orderitems/item/${cell.getRow().getData().itemId}`]);
            }, 1000);

          }, tooltip:function(cell){
              return  cell.getData().itemId;
        },
      },
      { title: "UDAC", width: 150, field: "udac",
        tooltip:function(cell){
          return  cell.getData().itemId;
        },
      },
      { title: "Directory", width: 150, field: "directory" },
      { title: "Category/Heading", width:200, field: "category" },
      {
        title: "Business Profile/Listing",
        field: "businessProfile",
        width: 250,
        formatter: function(cell,onRendered, success, cancel) {
          var listingValue = cell.getValue();
          var initialListingName = '';
          let initialListingValue = '';
          var cellRealValue = cell.getRow().getData().listingArray;
          var selectedListingVal = cell.getRow().getData().businessProfile;

          //Create and append select list
          var selectList = document.createElement("select");

          selectList.style.width = "100%";
          selectList.style.boxSizing = "border-box";
          selectList.style.border = "none";
          selectList.style.background = "none";
          selectList.style.display = "inline-block";
          selectList.style.height = "auto";
          selectList.style.borderBottom ="1px solid grey";
          selectList.style.paddingBottom="5px";
          let parentId = cell.getRow().getData().parentId;
          let statusCode = cell.getRow().getData().statusCode;
          let readOnly = cell.getRow().getData().readOnly;
          let udac = cell.getRow().getData().udac;

          that.disabledCell(parentId,statusCode,readOnly,selectList, 'businessProfile', udac);
          async function onChange() {
            if (selectList.selectedOptions[0].value != initialListingName) { //selectedBilingVal
              // Store value to cell dataset
              cell.getElement().dataset.businessProfile = selectList.selectedOptions[0].value;
              cell.setValue(selectList.selectedOptions[0].value,true);
              // Updating Akita Store
              for (let i=0; i< cellRealValue.length; i++){
                if (selectList.selectedOptions[0].value == cellRealValue[i].name){
                  let updatedProfileId;
                  that.indicatorQuery.getIndicator().subscribe((res) => {
                    updatedProfileId = JSON.parse(JSON.stringify(res))
                  });
                  let  iaGrouppresent = that.iaGroupHelper.isIAGroupPresent(updatedProfileId,cell.getRow().getData().itemId);
                  let upadatedIndicator = JSON.parse(JSON.stringify(updatedProfileId));
                  if(iaGrouppresent === true){
                    let msg= "Updating the value for listing will move this item out of its current IA Group";
                    const dialogConfig = new MatDialogConfig();

                    dialogConfig.disableClose = true;
                    dialogConfig.autoFocus = true;
                    dialogConfig.data = {
                      message: msg,
                    }
                    dialogConfig.width = '600px';
                    const dialogRef = that.matDialog.open(CallconfirmationComponent,dialogConfig);
                    dialogRef.afterClosed().subscribe(async dialogResult => {
                      let result = dialogResult;
                      if(result){
                        let oldIAGroupId = updatedProfileId.items[cell.getRow().getData().itemId].data.iaGroupId;
                     //  that.indicatorQuery.updateModel("ListingId", cellRealValue[i].name, modelData, updatedProfileId);
                        that.iaGroupHelper.splitIAGroup(cell.getRow().getData().itemId);
                        cell.getRow().update({ia:false});
                        let secondItemInIA = that.checkIfAnotherItemHasSameGroupID(oldIAGroupId,cell.getRow().getData().itemId,updatedProfileId);
                        //return {shouldRemove:true,itemId:iaArray[0]};
                        if(secondItemInIA.shouldRemove){
                          let tabulatorData = cell.getTable().getRows();
                            for (let q = 0; q < tabulatorData.length; q++){
                              if(tabulatorData[q].getData().itemId == secondItemInIA.itemId){
                                tabulatorData[q].update({ia:false});
                                break;
                              }
                            }
                        }
                        updatedProfileId = JSON.parse(JSON.stringify(that.indicatorQuery.getValue().indicator))
                        updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                        updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;
                        cell.getRow().update({bPListing:cellRealValue[i].name})
                        //add/remove new profile to Indicator
                        let oldValue = that.oldIndData.items[cell.getRow().getData().itemId].model.BusinessProfile
                        if(oldValue && oldValue!=undefined && oldValue!=null && oldValue!=''){
                          that.indicatorService.removeProfileById(updatedProfileId, oldValue)
                          that.indicatorQuery.updateProfilesListingId(updatedProfileId, '[TabulatorModels] disabledCell-updateProfilesListingId-1');
                        }
                        await that.indicatorService.addProfileById(updatedProfileId, cellRealValue[i].value)
                          that.indicatorQuery.getIndicator().subscribe((res) => {
                            updatedProfileId = JSON.parse(JSON.stringify(res))
                          });
                          let existingListingIds = updatedProfileId.listingIds
                          if(existingListingIds!=undefined && existingListingIds!=null){
                            existingListingIds.push(cellRealValue[i].value)
                          }else{
                            existingListingIds = []
                            existingListingIds.push(cellRealValue[i].value)
                          }
                          that.indicatorQuery.updateProfiles(cellRealValue[i].value,existingListingIds,updatedProfileId, '[TabulatorModels] disabledCell-updateProfiles-1');

                        let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
                        let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
                        that.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
                        that.indicatorValue = updatedProfileId;

                      }else{
                        //TODO : Check if we dont want to split ia while BP change then stop and dont update store
                      }
                    })

                  }else{
                      updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                      updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;
                      cell.getRow().update({bPListing:cellRealValue[i].name})
                      //add/remove new profile to Indicator
                      let oldValue = that.oldIndData.items[cell.getRow().getData().itemId].model.BusinessProfile
                      if(oldValue && oldValue!=undefined && oldValue!=null && oldValue!=''){
                        that.indicatorService.removeProfileById(updatedProfileId, oldValue)
                        that.indicatorQuery.updateProfilesListingId(updatedProfileId, '[TabulatorModels] disabledCell-updateProfilesListingId-2');
                      }
                      await that.indicatorService.addProfileById(updatedProfileId, cellRealValue[i].value)
                        that.indicatorQuery.getIndicator().subscribe((res) => {
                          updatedProfileId = JSON.parse(JSON.stringify(res))
                        });
                        let existingListingIds = updatedProfileId.listingIds
                        if(existingListingIds!=undefined && existingListingIds!=null){
                          existingListingIds.push(cellRealValue[i].value)
                        }else{
                          existingListingIds = []
                          existingListingIds.push(cellRealValue[i].value)
                        }
                        that.indicatorQuery.updateProfiles(cellRealValue[i].value,existingListingIds,updatedProfileId);
                      //BP selected from Parent item should reflect in child item
                        updatedProfileId.bundles.forEach((bundle, index, bundles) => {
                          bundle.children.forEach((child, childIndex, children) => {
                            let newIndicator;
                            if (bundle.parent == cell.getRow().getData().itemId && updatedProfileId.items[child] && updatedProfileId.items[bundle.parent].model) {
                              if (updatedProfileId.items[bundle.parent].model["BusinessProfile"] || updatedProfileId.items[bundle.parent].model["ListingId"] ) {
                                //newIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                                updatedProfileId.items[child].model["BusinessProfile"]  = cellRealValue[i].value;
                                updatedProfileId.items[child].model["ListingId"]  = cellRealValue[i].value ;
                                //Update store
                                // this.indicatorQuery.updateModel(this['layoutNode'].name, value,  modelData, this.Indicator);
                              }

                             // updatedProfileId = this.watchHelper.setItemValuesFromBP(child, cellRealValue[i].value, updatedProfileId, "bp");
                              if (updatedProfileId.items[bundle.parent].order.productCode === "DPM") {
                                updatedProfileId.items[bundle.parent].model.initialDataSet = true;
                              }
                              let tabulatorData = cell.getTable().getRows();

                              for (let z = 0; z < tabulatorData.length; z++){
                                if(tabulatorData[z].getData().itemId == child){
                                  tabulatorData[z].update({businessProfile:cellRealValue[i].value});
                                  tabulatorData[z].update({bPListing:cellRealValue[i].name});
                                  break;
                                }
                              }

                            }
                          })
                        })
                      let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
                      let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
                      that.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
                      that.indicatorValue = updatedProfileId;
                      }

                      if(updatedProfileId.items[cell.getRow().getData().itemId].model.hasOwnProperty('ManageCopysheet') && updatedProfileId.items[cell.getRow().getData().itemId].model.ManageCopysheet){
                        that.iaGroupHelper.copysheetnotify(cell.getRow().getData().itemId);
                        updatedProfileId = that.indicatorQuery.getValue().indicator;
                        updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                        updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;
                        if (updatedProfileId.items[cell.getRow().getData().itemId].order.actionCode === 'Renew') {
                          updatedProfileId.items[cell.getRow().getData().itemId].model.renewalCopysheetRequiredFlag = true      
                        }
                        let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
                        let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
                        that.updateIndicatorService.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
                        that.indicatorValue = updatedProfileId;
                       // that.updateIndicatorService.updateIndictor( stateParamsId,  Indicator, tempData, Indicator.items[ stateParamsId].counter);
                        this.redCounter = tempData.itemCount;
                        cell.getRow().update({counter:this.redCounter});
                        that.cdr.detectChanges();
                      }

                      that.updateItemId(cell.getRow().getData().itemId);
                      that.updateData("item", cell.getRow().getData().itemId, cell.getRow().getData().version,  updatedProfileId.items[cell.getRow().getData().itemId].model, 'Business Profile');
                    break;
                  }
              }

            }
          }


          //submit new value on change
          selectList.addEventListener("change", onChange);
          function getSelectedBillingName(value,listingData){
              for (let i=0; i<listingData.length; i++){
                if (listingValue == listingData[i].value){
                  initialListingName = listingData[i].name;
                  initialListingValue = listingData[i].value;
                  cell.setValue(initialListingName,true);
                  break;
                }else if(listingValue ==  listingData[i].name){
                  initialListingName = listingData[i].name;
                  initialListingValue = listingData[i].value
                  break;
                }
              }
          }

          if (cellRealValue && cellRealValue.length > 0){
            getSelectedBillingName(listingValue,cellRealValue);
            for (var i=0; i< cellRealValue.length; i++){
              if (i==0){
                var option = document.createElement("option");
                option.value = initialListingName;//cellRealValue[value];
                option.text = initialListingName ? initialListingName + ' / '+initialListingValue : initialListingName;//cellRealValue[value];
                option.title = initialListingName + ' / '+initialListingValue;
                selectList.appendChild(option);
                if (!initialListingName || (initialListingName != cellRealValue[i].name)){
                  let option = document.createElement("option");
                  option.value = cellRealValue[i].name;
                  option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
                  option.title = cellRealValue[i].name + ' / '+cellRealValue[i].value;
                  selectList.appendChild(option);
                }
              }else{
                if ((listingValue !== cellRealValue[i].value) && (listingValue !== cellRealValue[i].name)){
                  var option = document.createElement("option");
                  option.value = cellRealValue[i].name;
                  option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
                  option.title = cellRealValue[i].name + ' / '+cellRealValue[i].value;
                  selectList.appendChild(option);
                }
              }
            }
          }
            return selectList;
        }
      },
      {
        title: "Billing Account",
        field: "billing",
        width: 200,
        formatter: function(cell,onRendered, success, cancel) {
          var value = cell.getValue();
          var initialBillingName = '';
          var cellRealValue = cell.getRow().getData().billArr;
          var selectedBilingVal = cell.getRow().getData().billing;

          //Create and append select list
          var selectList = document.createElement("select");

          selectList.style.width = "100%";
          selectList.style.boxSizing = "border-box";
          selectList.style.border = "none";
          selectList.style.background = "none";
          selectList.style.display = "inline-block";
          selectList.style.height = "auto";
          selectList.style.borderBottom ="1px solid grey";
          selectList.style.paddingBottom="5px";
          let parentId = cell.getRow().getData().parentId;
          let statusCode = cell.getRow().getData().statusCode;
          let readOnly = cell.getRow().getData().readOnly;
          let readOnlyBilling = cell.getRow().getData().readOnlybilling;
          let udac = cell.getRow().getData().udac;
          if(readOnlyBilling){
          that.disabledCell(parentId,statusCode,readOnlyBilling,selectList, 'billing',udac);
          }
          function onChange() {
           //console.log
            console.log('onchange called!!!!!',that);
           // callValue();
            if (selectList.selectedOptions[0].value != initialBillingName) { //selectedBilingVal
            // success(selectList.selectedOptions[0].value);
              // Store value to cell dataset
              cell.getElement().dataset.billing = selectList.selectedOptions[0].value;

              for  (let i=0; i< cellRealValue.length; i++){
                if (cell.getElement().dataset.billing  == cellRealValue[i].finalName){
                  let digAllowedText;
                  if(cellRealValue[i].type.toLowerCase() == 'invoice'){
                    digAllowedText =  cellRealValue[i].type + (cellRealValue[i].digitalItemInd && cellRealValue[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed');
                  } else{
                    digAllowedText = cellRealValue[i].type
                  }

                  cell.getRow().update({id:cellRealValue[i].value});
                  cell.getRow().update({entity:cellRealValue[i].businessEntity});
                  cell.getRow().update({lastBill:cellRealValue[i].lastBilledAmount});
                  cell.getRow().update({type:digAllowedText});
                  cell.getRow().update({btype:digAllowedText})
                  cell.getRow().update({updatedLastBill:cellRealValue[i].value +' - $'+ cellRealValue[i].lastBilledAmount })
                  // Updating Akita Store
                  let y;
                  that.indicatorQuery.getIndicator().subscribe((res) => {
                    y = JSON.parse(JSON.stringify(res))
                  });
                  y.items[cell.getRow().getData().itemId].model.BillingAccount = cellRealValue[i].value;
                  let currentCount= y.items[cell.getRow().getData().itemId].counter;
                  if(y["items"][cell.getRow().getData().itemId].order.actionCode == 'Renew') {
                    if(y.items[cell.getRow().getData().itemId].hasOwnProperty("hasCopysheet") ) {
                      y.items[cell.getRow().getData().itemId].model['renewHasCopysheet'] = y["items"][cell.getRow().getData().itemId]['hasCopysheet'];
                    }
                    y.items[cell.getRow().getData().itemId].model['digitalApiFlag'] = true;
                  }
                  y.bundles.forEach((bundle, index, bundles) => {
                    bundle.children.forEach((child, childIndex, children) => {
                      let newIndicator;
                      if (bundle.parent == cell.getRow().getData().itemId && y.items[child] && y.items[bundle.parent].model) {
                        if (y.items[bundle.parent].model["BillingAccount"]) {
                          //newIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                          // Changes for FUL-40719
                          y.items[child].model["BillingAccount"]  = cellRealValue[i].value;
                          if (y && y.items && y.items[child]) {
                            let childCurrentCount= y.items[child].counter;
                            let tempData = that.requiredfieldcount.getNewItemCount(y.items[child].requiredFields, y.items[child].model, y.items[child].order, y.orderPercent, childCurrentCount, true, y.items[child]);
                            that.updateIndictor(child, y, tempData, childCurrentCount);
                            that.indicatorQuery.getIndicator().subscribe((res) => {
                            y = JSON.parse(JSON.stringify(res))
                            });
                            that.updateItemId(child);
                            that.updateData("item", child, cell.getRow().getData().version, y.items[child].model, 'Billing Account');

                            // //on change of billing dropdown make digital billing content call for child items
                            // that.digitalBillingContent(child, y);
                          }
                         // y.items[child].model["ListingId"]  = cellRealValue[i].value ;
                          //Update store
                          // this.indicatorQuery.updateModel(this['layoutNode'].name, value,  modelData, this.Indicator);
                        }

                       // updatedProfileId = this.watchHelper.setItemValuesFromBP(child, cellRealValue[i].value, updatedProfileId, "bp");
                        // if (y.items[bundle.parent].order.productCode === "DPM") {
                        //   y.items[bundle.parent].model.initialDataSet = true;
                        // }
                        let tabulatorData = cell.getTable().getRows();

                        for (let z = 0; z < tabulatorData.length; z++){
                          if(tabulatorData[z].getData().itemId == child){
                            let digitaIndicator ='';
                            // if ( tabulatorData[z].getData().type =='Invoice') {
                            //   digitaIndicator = cellRealValue[i].digitalItemInd && cellRealValue[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                            // }
                            // else{
                            //   digAllowedText = cellRealValue[i].type
                            // }
                            cell.getRow().update({id:cellRealValue[i].value+' - $'+cellRealValue[i].lastBilledAmount});
                            cell.getRow().update({entity:cellRealValue[i].businessEntity});
                            cell.getRow().update({lastBill:cellRealValue[i].lastBilledAmount});
                            cell.getRow().update({btype:cellRealValue[i].type+''+digitaIndicator});
                            // Changes for FUL-40719
                            let errorCount = that.indicatorQuery.getValue().indicator['items'][child].counter;
                            tabulatorData[z].update({counter: errorCount});
                            tabulatorData[z].update({billing:cellRealValue[i].value});
                            //that.updateIndictor(cell.getRow().getData().itemId, y, tempData, currentCount);
                            break;
                          }
                        }

                      }
                    })
                  })
                  let tempData = that.requiredfieldcount.getNewItemCount(y.items[cell.getRow().getData().itemId].requiredFields, y.items[cell.getRow().getData().itemId].model, y.items[cell.getRow().getData().itemId].order, y.orderPercent, currentCount, true, y.items[cell.getRow().getData().itemId]);
                  that.updateIndictor(cell.getRow().getData().itemId, y, tempData, currentCount);
                  that.indicatorValue = y;

                  that.updateItemId(cell.getRow().getData().itemId);
                  that.updateData("item", cell.getRow().getData().itemId, cell.getRow().getData().version, that.indicatorValue.items[cell.getRow().getData().itemId].model, 'Billing Account');
                  
                  //on change of billing dropdown make digital billing content call for parent item
                  that.digitalBillingContent(cell.getRow().getData().itemId, that.indicatorValue);
                }
              }
              cell.setValue(selectList.selectedOptions[0].value,true);
              if(cell.getRow().getData().itemId) {
                let errorCount = that.indicatorQuery.getValue().indicator['items'][cell.getRow().getData().itemId].counter;
                cell.getRow().update({counter: errorCount});
              }
            } else { // No change
              //cancel();
            }
          }

          //submit new value on change
          selectList.addEventListener("change", onChange);

          function getSelectedBillingName(value,billingData){
              let digitaIndicator= '';
              for (let i=0; i<billingData.length; i++){
                if (value == billingData[i].value){
                  initialBillingName = billingData[i].finalName;
                  if ( billingData[i].type =='Invoice') {
                    digitaIndicator = billingData[i].digitalItemInd && billingData[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                  }
                  cell.setValue(initialBillingName,true);
                  cell.getRow().update({id:billingData[i].value+' - $'+billingData[i].lastBilledAmount});
                  cell.getRow().update({entity:billingData[i].businessEntity});
                  cell.getRow().update({lastBill:billingData[i].lastBilledAmount});
                  cell.getRow().update({type:billingData[i].type+''+digitaIndicator});
                  break;
                }else if(value ==  billingData[i].finalName){
                  initialBillingName = billingData[i].finalName;
                  if ( billingData[i].type =='Invoice') {
                    digitaIndicator = billingData[i].digitalItemInd && billingData[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                  }
                  cell.getRow().update({id:billingData[i].value+' - $'+billingData[i].lastBilledAmount});
                  cell.getRow().update({entity:billingData[i].businessEntity});
                  cell.getRow().update({lastBill:billingData[i].lastBilledAmount});
                  cell.getRow().update({type:billingData[i].type+''+digitaIndicator});
                  break;
                }
              }
          }

          if (cellRealValue && cellRealValue.length > 0){
            getSelectedBillingName(value,cellRealValue);
            for (let i=0; i< cellRealValue.length; i++){

              if (i==0){
                let option = document.createElement("option");
                option.value = initialBillingName;//cellRealValue[value];
                option.text = initialBillingName;//cellRealValue[value];
                selectList.appendChild(option);
                if (!initialBillingName || (initialBillingName != cellRealValue[i].finalName)){
                  let option = document.createElement("option");
                  option.value = cellRealValue[i].finalName;
                  option.text = cellRealValue[i].finalName;
                  selectList.appendChild(option);
                }
              }else{
                if ((value !== cellRealValue[i].value) &&(value !== cellRealValue[i].finalName)){
                  let option = document.createElement("option");
                  option.value = cellRealValue[i].finalName;
                  option.text = cellRealValue[i].finalName;
                  selectList.appendChild(option);
                }
              }
            }
            if(cell.getRow().getData().parentId){
              for(let i = 0;i<selectList.length;i++){

                if(selectList.options[i]['value'] == ""){

                  var indicator
                  that.indicatorQuery.getIndicator().subscribe((res) => {
                    indicator = JSON.parse(JSON.stringify(res))
                  });
                  if(indicator.items[parentId].model.BillingAccount){
                    let digitaIndicator = "";
                    for(let i = 0; i<indicator.items[parentId].billingArray.length;i++){
                      if(indicator.items[parentId].billingArray[i].value == indicator.items[parentId].model.BillingAccount){
                        var bilname = indicator.items[parentId].billingArray[i].finalName;
                        if ( indicator.items[parentId].billingArray[i].type =='Invoice') {
                          digitaIndicator = indicator.items[parentId].billingArray[i].digitalItemInd && indicator.items[parentId].billingArray[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                        }
                        cell.getRow().update({id:indicator.items[parentId].billingArray[i].value+' - $'+indicator.items[parentId].billingArray[i].lastBilledAmount});
                        cell.getRow().update({entity:indicator.items[parentId].billingArray[i].businessEntity});
                        cell.getRow().update({lastBill:indicator.items[parentId].billingArray[i].lastBilledAmount});
                        cell.getRow().update({type:indicator.items[parentId].billingArray[i].type+''+digitaIndicator});
                      }
                    }
                    selectList.options[i]['value'] = bilname;
                    selectList.options[i]['text'] = bilname;
                    
                  }
                  else{
                  selectList.options[i]['value'] = "N/A";
                  selectList.options[i]['text'] = "N/A"
                  }
                }
                else{
                  var indicator
                  that.indicatorQuery.getIndicator().subscribe((res) => {
                    indicator = JSON.parse(JSON.stringify(res))
                  });
                  if(indicator.items[parentId].model.BillingAccount){
                    let digitaIndicator = "";
                    for(let i = 0; i<indicator.items[parentId].billingArray.length;i++){
                      if(indicator.items[parentId].billingArray[i].value == indicator.items[parentId].model.BillingAccount){
                        var bilname = indicator.items[parentId].billingArray[i].finalName;
                        if ( indicator.items[parentId].billingArray[i].type =='Invoice') {
                          digitaIndicator = indicator.items[parentId].billingArray[i].digitalItemInd && indicator.items[parentId].billingArray[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                        }
                        cell.getRow().update({id:indicator.items[parentId].billingArray[i].value+' - $'+indicator.items[parentId].billingArray[i].lastBilledAmount});
                        cell.getRow().update({entity:indicator.items[parentId].billingArray[i].businessEntity});
                        cell.getRow().update({lastBill:indicator.items[parentId].billingArray[i].lastBilledAmount});
                        cell.getRow().update({type:indicator.items[parentId].billingArray[i].type+''+digitaIndicator});
                        cell.getRow().update({billing:bilname});
                        break;
                      }
                    }
                    selectList.options[i]['value'] = bilname;
                    selectList.options[i]['text'] = bilname;
                    
                  }
                  // else{
                  // selectList.options[i]['value'] = "N/A";
                  // selectList.options[i]['text'] = "N/A"
                  // }

                }
              }
            }
          }
          else{
            if(cell.getRow().getData().parentId){
              let option = document.createElement("option");
              option.value = "N/A";;
              option.text = "N/A";
              selectList.appendChild(option);
            }
          }
            return selectList;
        }
      },
      { title: "Billing Type", width: 175, field: "type" },
      { title: "Billing Id/Last Bill", width: 220, field: "id" },
      /* { title: "Last Bill", width:120, field: "lastBill" }, */
      // { title: "Entity", field: "entity",width: 100 },
      { title: "IA", field: "ia",formatter:function(cell, formatterParams){
        let isChecked = cell.getValue();
        if (isChecked){
          return `<input type="checkbox" checked= ${isChecked} style="pointer-events: none;"></input>`
        }else if (cell.getRow().getData().isIaElligible){
          return `<input type="checkbox"  style="pointer-events: none;"></input>`
        }
        return;
      }, width: 50, cellClick: (e, cell) => {
        if(cell.getData().isIaElligible){
          this.router.navigate([`/graphicitems`]);
        }
      }
     }
    )
  }

 columns: any =
{
  "orderitems":[
      {title:"counter", field:"counter", width:5},
      {title:"id", field:"id", width:50},
      {title:"firstName", field:"firstName", width:200},
      {title:"lastName", field:"lastName", width:200},
      {title:"state", field:"state", width:200},
      {title:"progress", field:"progress", sorter:"number", width:125,formatter:"progress", formatterParams:{
        min:0,
        max:100,
        color:["red", "orange", "green"],
        legendColor:"#000000",
        legendAlign:"center",
    }},
      {title:"billing", field:"billing", width:200},
  ],

  "digital":[
    {
      title: '',
      field: 'IsSelected',
      titleFormatter:"",
      width:30,
      formatter: function (cell, formatterParams, onRendered) {
        let svItemId = cell.getData().itemId;
        return `<input type="radio" class="select-row digi-tab-radio" name='digitalRadio' id= ${svItemId} readonly style="opacity: 1;"/>`;
      },
      headerSort: false,
      cssClass: 'text-center',
      frozen: true,
      cellClick: function (e, cell) {
        var $element = $(cell.getElement());
        var $radBtn = $element.find('.digi-tab-radio');
        $radBtn.prop('checked', true);
        let updatedData;
        that.indicatorQuery.getIndicator().subscribe((res) => {
          updatedData = JSON.parse(JSON.stringify(res))
        });
        var data = cell.getRow().getData();
        updatedData.stateParamsId = data.itemId;
        that.footerService.setCalltracking(data.itemId);
        that.updatStore(updatedData);
      }
    },

    { title: "", field: "counter",width:5, formatter:function(cell, formatterParams){
      var value = cell.getValue();
      let itemId =cell.getRow().getData().itemId +'counter';
      if (value > 0){
        return `<div id=${itemId}  class='item-order-error'></div>`
      } else {
        return `<div id=${itemId} class='' ></div>`;
      }
      return;
    } },
    { title: "Item",width:320, field: "item", cssClass:'tabulator-custom-title-Color',
      cellClick: (e, cell) => {
        this.spinnerService.show();
        setTimeout(() => {// timeout is added for spinner issue
          this.router.navigate([`/orderitems/item/${cell.getRow().getData().itemId}`]);
        }, 1000);

      }, tooltip:function(cell){
          return  cell.getData().itemId;
      },
    },
    { title: "UDAC",width:200, field: "udac",
      tooltip:function(cell){
        return  cell.getData().itemId;
      },
    },
    { title: "Category",width:250, field: "category"},

    {
      title: "Business Profile/Listing",
      field: "businessProfile",
      width:300,
     // editor: this.customNativeSelect,
     formatter: function(cell,onRendered, success, cancel) {
      var listingValue = cell.getValue();
      var initialListingName = '';
      let initialListingValue = '';
      //that.items= [];
      var cellRealValue = cell.getRow().getData().listingArray;
      var selectedListingVal = cell.getRow().getData().businessProfile;

      //Create and append select list
      var selectList = document.createElement("select");

      selectList.style.width = "100%";
      selectList.style.boxSizing = "border-box";
      selectList.style.border = "none";
      selectList.style.background = "none";
      selectList.style.display = "inline-block";
      selectList.style.height = "auto";
      selectList.style.borderBottom ="1px solid grey";
      selectList.style.paddingBottom="5px";
      let parentId = cell.getRow().getData().parentId;
      let statusCode = cell.getRow().getData().statusCode;
      let readOnly = cell.getRow().getData().readOnly;
      let udac = cell.getRow().getData().udac;
      that.disabledCell(parentId,statusCode,readOnly,selectList, 'businessProfile', udac);
      function onChange() {
        console.log('onchange called!!!!!');
        if (selectList.selectedOptions[0].value != initialListingName) { //selectedBilingVal
        // success(selectList.selectedOptions[0].value);
          // Store value to cell dataset
          cell.getElement().dataset.businessProfile = selectList.selectedOptions[0].value;
          cell.setValue(selectList.selectedOptions[0].value,true);
          for (let i=0; i< cellRealValue.length; i++){
            if (selectList.selectedOptions[0].value == cellRealValue[i].name){
              let updatedProfileId;
              that.indicatorQuery.getIndicator().subscribe((res) => {
                updatedProfileId = JSON.parse(JSON.stringify(res))
              });
              updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
              updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;
              cell.getRow().update({bPListing:cellRealValue[i].name})

               //BP selected from Parent item should reflect in child item
               updatedProfileId.bundles.forEach((bundle, index, bundles) => {
                bundle.children.forEach((child, childIndex, children) => {
                  let newIndicator;
                  if (bundle.parent == cell.getRow().getData().itemId && updatedProfileId.items[child] && updatedProfileId.items[bundle.parent].model) {
                    if (updatedProfileId.items[bundle.parent].model["BusinessProfile"] || updatedProfileId.items[bundle.parent].model["ListingId"] ) {
                      updatedProfileId.items[child].model["BusinessProfile"]  = cellRealValue[i].value;
                      updatedProfileId.items[child].model["ListingId"]  = cellRealValue[i].value ;
                    }
                    if (updatedProfileId.items[bundle.parent].order.productCode === "DPM") {
                      updatedProfileId.items[bundle.parent].model.initialDataSet = true;
                    }
                    let tabulatorData = cell.getTable().getRows();
                    for (let z = 0; z < tabulatorData.length; z++){
                      if(tabulatorData[z].getData().itemId == child){
                        tabulatorData[z].update({businessProfile:cellRealValue[i].value});
                        tabulatorData[z].update({bPListing:cellRealValue[i].name});
                        break;
                      }
                    }

                  }
                })
              })
              let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
              let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
              that.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
              if(updatedProfileId["items"][cell.getRow().getData().itemId].order.actionCode == 'Renew') {
                if(updatedProfileId.items[cell.getRow().getData().itemId].hasOwnProperty("hasCopysheet") ) {
                  updatedProfileId.items[cell.getRow().getData().itemId].model['renewHasCopysheet'] = updatedProfileId["items"][cell.getRow().getData().itemId]['hasCopysheet'];
                }
                updatedProfileId.items[cell.getRow().getData().itemId].model['dirtyFlag'] = true;
              }
              that.indicatorValue = updatedProfileId;

              that.updateItemId(cell.getRow().getData().itemId);
              that.updateData("item", cell.getRow().getData().itemId, cell.getRow().getData().version, that.indicatorValue.items[cell.getRow().getData().itemId].model, 'Business Profile');
                  break;
              }
          }
        } else { // No change
          //cancel();
        }
      }

      //submit new value on change
      selectList.addEventListener("change", onChange);
      function getSelectedBillingName(value,listingData){
          for (let i=0; i<listingData.length; i++){
            if (listingValue == listingData[i].value){
              initialListingName = listingData[i].name;
              initialListingValue = listingData[i].value;
              cell.setValue(initialListingName,true);
              break;
            }else if(listingValue ==  listingData[i].name){
              initialListingName = listingData[i].name;
              initialListingValue = listingData[i].value;
              break;
            }
          }
      }

      if (cellRealValue && cellRealValue.length > 0){
        getSelectedBillingName(listingValue,cellRealValue);
        for (var i=0; i< cellRealValue.length; i++){
          if (i==0){
            var option = document.createElement("option");
            option.value = initialListingName;
            option.text = initialListingName ? initialListingName + ' / '+initialListingValue : initialListingName;//cellRealValue[value];
            option.title = initialListingName + ' / '+initialListingValue;
            //option.title = initialListingName + ' / '+cellRealValue[i].value;
            selectList.appendChild(option);
            if (!initialListingName || (initialListingName != cellRealValue[i].name)){
              let option = document.createElement("option");
              option.value = cellRealValue[i].name;
              option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
              option.title = initialListingName + ' / '+cellRealValue[i].value;
              selectList.appendChild(option);
            }
          }else{
            if ((listingValue !== cellRealValue[i].value) && (listingValue !== cellRealValue[i].name)){
              var option = document.createElement("option");
              option.value = cellRealValue[i].name;
              option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
              option.title = initialListingName + ' / '+cellRealValue[i].value;
              selectList.appendChild(option);
            }
          }
        }
      }
        return selectList;
    }
    },
    { title: "Call Tracking Number",width:320, field: "ctn", formatter:function(cell) {
      let customeDiv = document.createElement("div");
      customeDiv.id=cell.getRow().getData().itemId+'trackNumber';
      customeDiv.className="call-tracking-cell";
      let a = cell.getValue()
      customeDiv.innerText= a;
      return customeDiv;
    }},
    { title: "CT Destination",width:300, field: "ctd", formatter:function(cell) {
      let customeDiv = document.createElement("div");
      customeDiv.id=cell.getRow().getData().itemId+'ctDestination';
      customeDiv.className="call-tracking-cell";
      let a = cell.getValue()
      customeDiv.innerText= a;
      return customeDiv;
    }},

  ],

  "callTracking":[
    {
      title: '',
      field: 'IsSelected',
      titleFormatter:"",
      width:'3%',
      formatter: function (cell, formatterParams, onRendered) {
        return '<input type="checkbox" class="select-row" readonly style="opacity: 1;"/>';
      },
      headerSort: false,
      cssClass: 'text-center',
      frozen: true,
      cellClick: function (e, cell) {
        console.log('inside cell clicked!!!');
        var $element = $(cell.getElement());
        var $chkbox = $element.find('.select-row');
        if (cell.getData().IsSelected) {
          that.footerService.setCalltracking(null);
          $chkbox.prop('checked', false);
          cell.getRow().deselect();
          cell.getData().IsSelected = false;
          let removedIndex = that.callItemIds.indexOf(cell.getRow().getData().itemId)
          if(removedIndex >-1){
             that.callItemIds.splice(removedIndex,1);
          }
        } else {
          $chkbox.prop('checked', true);
          cell.getRow().select();
          cell.getData().IsSelected = true;
          let updatedData;
          that.indicatorQuery.getIndicator().subscribe((res) => {
            updatedData = JSON.parse(JSON.stringify(res))
          });
          let data = cell.getRow().getData();
          updatedData.stateParamsId = data.itemId;
          that.callItemIds.push(data.itemId);
          that.footerService.setCalltracking(data.itemId);
          that.updatStore(updatedData);
        }
        if(that.callItemIds.length>1){
          that.footerService.setCalltracking(null);
        }else if(that.callItemIds.length == 1){
          that.footerService.setCalltracking(that.callItemIds[0]);
          let indicator;
          that.indicatorQuery.getIndicator().subscribe((res) => {
            indicator = JSON.parse(JSON.stringify(res))
          });
          indicator.stateParamsId = that.callItemIds[0];
          that.updatStore(indicator);
        }
        that.footerService.setSeletedItems(that.callItemIds);
        console.log('cell data is:',cell);
        console.log('cell data is, ',e);

      }
    },

    { title: "", field: "counter",width:'1%', formatter:function(cell, formatterParams){
      var value = cell.getValue();
      let itemId =cell.getRow().getData().itemId +'counter';
      if (value > 0){
        return `<div id=${itemId}  class='item-order-error'></div>`;
      } else {
        return `<div id=${itemId} class='' ></div>`;
      }
      return;
    } },
    { title: "Item", width:'15%', field: "item", cssClass:'tabulator-custom-title-Color',
      cellClick: (e, cell) => {
        this.spinnerService.show();
        setTimeout(() => {// timeout is added for spinner issue
          this.router.navigate([`/orderitems/item/${cell.getRow().getData().itemId}`]);
        },1000);

      }, tooltip:function(cell){
          return  cell.getData().itemId;
      },
    },
    { title: "UDAC", width:'10%', field: "udac",
      tooltip:function(cell){
        return  cell.getData().itemId;
      },
    },
    { title: "Category", width:'17%', field: "category" },
    {
      title: "Business Profile/Listing",
      field: "businessProfile",
      width:'19%',
     // editor: this.customNativeSelect,
     formatter: function(cell,onRendered, success, cancel) {
      var listingValue = cell.getValue();
      var initialListingName = '';
      let initialListingValue = '';
      //that.items= [];
      var cellRealValue = cell.getRow().getData().listingArray;
      var selectedListingVal = cell.getRow().getData().businessProfile;

      //Create and append select list
      var selectList = document.createElement("select");

      selectList.style.width = "100%";
      selectList.style.boxSizing = "border-box";
      selectList.style.border = "none";
      selectList.style.background = "none";
      selectList.style.display = "inline-block";
      selectList.style.height = "auto";
      selectList.style.borderBottom ="1px solid grey";
      selectList.style.paddingBottom="5px";
      let parentId = cell.getRow().getData().parentId;
      let statusCode = cell.getRow().getData().statusCode;
      let readOnly = cell.getRow().getData().readOnly;
      let udac = cell.getRow().getData().udac;
      that.disabledCell(parentId,statusCode,readOnly,selectList, 'businessProfile', udac);
      function onChange() {
        console.log('onchange called!!!!!');
        if (selectList.selectedOptions[0].value != initialListingName) { //selectedBilingVal
        // success(selectList.selectedOptions[0].value);
          // Store value to cell dataset
          cell.getElement().dataset.businessProfile = selectList.selectedOptions[0].value;
          cell.setValue(selectList.selectedOptions[0].value,true);
          for (let i=0; i< cellRealValue.length; i++){
            if (selectList.selectedOptions[0].value == cellRealValue[i].name){
              let updatedProfileId;
              that.indicatorQuery.getIndicator().subscribe((res) => {
                updatedProfileId = JSON.parse(JSON.stringify(res))
              });
              let  iaGrouppresent = that.iaGroupHelper.isIAGroupPresent(updatedProfileId,cell.getRow().getData().itemId);
              updatedProfileId = JSON.parse(JSON.stringify(updatedProfileId));
                  if(iaGrouppresent === true){
                    let msg= "Updating the value for listing will move this item out of its current IA Group";
                    const dialogConfig = new MatDialogConfig();

                    dialogConfig.disableClose = true;
                    dialogConfig.autoFocus = true;
                    dialogConfig.data = {
                      message: msg,
                    }
                    dialogConfig.width = '600px';
                    const dialogRef = that.matDialog.open(CallconfirmationComponent,dialogConfig);
                    dialogRef.afterClosed().subscribe(dialogResult => {
                      let result = dialogResult;
                      if(result){
                        let oldIAGroupId = updatedProfileId.items[cell.getRow().getData().itemId].data.iaGroupId;
                        let modelData = JSON.parse(JSON.stringify(that.indicatorQuery.getValue().indicator['items'][cell.getRow().getData().itemId].model))
                     //  that.indicatorQuery.updateModel("ListingId", cellRealValue[i].name, modelData, updatedProfileId);
                        that.iaGroupHelper.splitIAGroup(cell.getRow().getData().itemId);
                        cell.getRow().update({ia:false});
                        let secondItemInIA = that.checkIfAnotherItemHasSameGroupID(oldIAGroupId,cell.getRow().getData().itemId,updatedProfileId);
                        //return {shouldRemove:true,itemId:iaArray[0]};
                        if(secondItemInIA.shouldRemove){
                          let tabulatorData = cell.getTable().getRows();
                            for (let q = 0; q < tabulatorData.length; q++){
                              if(tabulatorData[q].getData().itemId == secondItemInIA.itemId){
                                tabulatorData[q].update({ia:false});
                                break;
                              }
                            }
                        }
                        updatedProfileId = JSON.parse(JSON.stringify(that.indicatorQuery.getValue().indicator));
                        updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                        updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;

                      }else{
                        //TODO : Check if we dont want to split ia while BP change then stop and dont update store
                      }
                    })
              }else{
                updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;

              }

              if(updatedProfileId.items[cell.getRow().getData().itemId].model.hasOwnProperty('ManageCopysheet') && updatedProfileId.items[cell.getRow().getData().itemId].model.ManageCopysheet){
                that.iaGroupHelper.copysheetnotify(cell.getRow().getData().itemId);
                updatedProfileId = JSON.parse(JSON.stringify(that.indicatorQuery.getValue().indicator));
                updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;
                if (updatedProfileId.items[cell.getRow().getData().itemId].order.actionCode === 'Renew') {
                  updatedProfileId.items[cell.getRow().getData().itemId].model.renewalCopysheetRequiredFlag = true      
                }
                let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
                let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
                //that.updateIndicatorService.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
                that.indicatorValue = updatedProfileId;
                this.redCounter = tempData.itemCount;
                cell.getRow().update({counter:this.redCounter});
                that.cdr.detectChanges();
              }
              //BP selected from Parent item should reflect in child item
              updatedProfileId.bundles.forEach((bundle, index, bundles) => {
                bundle.children.forEach((child, childIndex, children) => {
                  let newIndicator;
                  if (bundle.parent == cell.getRow().getData().itemId && updatedProfileId.items[child] && updatedProfileId.items[bundle.parent].model) {
                    if (updatedProfileId.items[bundle.parent].model["BusinessProfile"] || updatedProfileId.items[bundle.parent].model["ListingId"] ) {
                      //newIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                      updatedProfileId.items[child].model["BusinessProfile"]  = cellRealValue[i].value;
                      updatedProfileId.items[child].model["ListingId"]  = cellRealValue[i].value ;
                      //Update store
                      // this.indicatorQuery.updateModel(this['layoutNode'].name, value,  modelData, this.Indicator);
                    }

                   // updatedProfileId = this.watchHelper.setItemValuesFromBP(child, cellRealValue[i].value, updatedProfileId, "bp");
                    if (updatedProfileId.items[bundle.parent].order.productCode === "DPM") {
                      updatedProfileId.items[bundle.parent].model.initialDataSet = true;
                    }
                    let tabulatorData = cell.getTable().getRows();
                    for (let z = 0; z < tabulatorData.length; z++){
                      if(tabulatorData[z].getData().itemId == child){
                        tabulatorData[z].update({businessProfile:cellRealValue[i].value});
                        tabulatorData[z].update({bPListing:cellRealValue[i].name});
                        break;
                      }
                    }

                  }
                })
              })
              cell.getRow().update({bPListing:cellRealValue[i].name})
              let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
              let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
              //that.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
              if(updatedProfileId["items"][cell.getRow().getData().itemId].order.actionCode == 'Renew') {
                if(updatedProfileId.items[cell.getRow().getData().itemId].hasOwnProperty("hasCopysheet") ) {
                  updatedProfileId.items[cell.getRow().getData().itemId].model['renewHasCopysheet'] = updatedProfileId["items"][cell.getRow().getData().itemId]['hasCopysheet'];
                }
                updatedProfileId.items[cell.getRow().getData().itemId].model['dirtyFlag'] = true;
              }
              that.updateIndicatorService.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
              that.indicatorValue = updatedProfileId;

              that.updateItemId(cell.getRow().getData().itemId);
              that.updateData("item", cell.getRow().getData().itemId, cell.getRow().getData().version, that.indicatorValue.items[cell.getRow().getData().itemId].model, 'Billing Account');
                  break;
              }
          }
        } else { // No change
          //cancel();
        }
      }

      //submit new value on change
      selectList.addEventListener("change", onChange);
      function getSelectedBillingName(value,listingData){
          for (let i=0; i<listingData.length; i++){
            if (listingValue == listingData[i].value){
              initialListingName = listingData[i].name;
              initialListingValue = listingData[i].value;
              cell.setValue(initialListingName,true);
              break;
            }else if(listingValue ==  listingData[i].name){
              initialListingName = listingData[i].name;
              initialListingValue = listingData[i].value;
              break;
            }
          }
      }

      if (cellRealValue && cellRealValue.length > 0){
        getSelectedBillingName(listingValue,cellRealValue);
        for (var i=0; i< cellRealValue.length; i++){
          if (i==0){
            var option = document.createElement("option");
            option.value = initialListingName;
            option.text = initialListingName ? initialListingName + ' / '+initialListingValue : initialListingName;//cellRealValue[value];
            option.title = initialListingName + ' / '+initialListingValue;
            //option.title = initialListingName + ' / '+cellRealValue[i].value;
            selectList.appendChild(option);
            if (!initialListingName || (initialListingName != cellRealValue[i].name)){
              let option = document.createElement("option");
              option.value = cellRealValue[i].name;
              option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
              option.title = initialListingName + ' / '+cellRealValue[i].value;
              selectList.appendChild(option);
            }
          }else{
            if ((listingValue !== cellRealValue[i].value) && (listingValue !== cellRealValue[i].name)){
              var option = document.createElement("option");
              option.value = cellRealValue[i].name;
              option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
              option.title = initialListingName + ' / '+cellRealValue[i].value;
              selectList.appendChild(option);
            }
          }
        }
      }
        return selectList;
    }
    },
    { title: "Call Tracking Number", width:'20%', field: "ctn" , formatter:function(cell) {
      let customeDiv = document.createElement("div");
      customeDiv.id=cell.getRow().getData().itemId+'trackNumber';
      let a = cell.getValue()
      customeDiv.innerText= a;
      return customeDiv;
    }},
    { title: "CT Destination",width:'15%', field: "ctd" , formatter:function(cell) {
      let customeDiv = document.createElement("div");
      customeDiv.id=cell.getRow().getData().itemId+'ctDestination';
      let a = cell.getValue()
      customeDiv.innerText= a;
      return customeDiv;
    }},
    { title: "IA", field: "ia",formatter:function(cell, formatterParams){
      let isChecked = cell.getValue();
      if (isChecked){
        return `<input type="checkbox" checked= ${isChecked} style="pointer-events: none;"></input>`
      }else if (cell.getRow().getData().isIaElligible){
        return `<input type="checkbox"  style="pointer-events: none;"></input>`
      }
      return;
    }, width: '5%',cellClick: (e, cell) => {
      if(cell.getData().isIaElligible){
        this.router.navigate([`/graphicitems`]);
      }
    }
  }

  ],

  "adminGrid":[
    {title:"User Id", field:"user"},
    {title:"Password", field:"password",editor:"input"},
    {title:"Actions",formatter:function(value, data, cell, row, options){ //plain text value
      // var button = document.createElement("button");

      // button.value="Update";
      // button.style.height="30px"
      // button.style.width="30px"
      return `<div>
      <button id="update">Update</button>
      <button id="delete">Delete</button>
      </div>`
      // return button
  },cellClick:function(e, cell):object
  {var Btn = document.createElement('Button');
  Btn.id = "Btn_Id";
  var id1 = document.getElementsByTagName('button')[0].id;
  var id2 = document.getElementsByTagName('button')[1].id
  console.log("event called",Btn.id);
  console.log(id1);
  console.log(id2);
  return {id2, id1};
}
    },
    ],
  "configurationgrid":[
    {title:"Type", field:"type"},
    {title:"Name", field:"name"},
    {title:"Value", field:"value",},
    {title:"Actions", field:"action"}
    ]
};
  private checkIfAnotherItemHasSameGroupID(IAGroupID,itemid,localIndicator){
    let iaArray = [];
    for (let key in localIndicator.items) {
      if (localIndicator.items[key].data && localIndicator.items[key].data.iaGroupId
          && localIndicator.items[key].data.iaGroupId === IAGroupID
          && localIndicator.items[key].order.svItemId !== itemid){
            iaArray.push(localIndicator.items[key].order.svItemId);
          }
    }
    if (iaArray.length == 1){
      return {shouldRemove:true,itemId:iaArray[0]};
    }
    return {shouldRemove:false};
  }

  private updateData(moduleName: string, id: string, version: string, model: any, fieldName: any) {
    that.counterService.setUpdate(true);
    that.storageService.update(moduleName, id +'v'+ version, model);
    let oldValue;
    let newValue;
    if (fieldName === 'Billing Account') {
      oldValue = this.oldIndData.items[id].model.BillingAccount;
      newValue = model.BillingAccount
    } else if (fieldName === 'Business Profile') {
      oldValue = this.oldIndData.items[id].model.BusinessProfile;
      newValue = model.BusinessProfile
    }
    let activityLogHistoryObj = {
      'displayName': that.indicatorValue['user']['name'],
      'email': that.indicatorValue['user']['email'],
      'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
      'itemId': id,
      'itemStatus': that.indicatorValue['items'][id].order.status,
      'itemActionCode': that.indicatorValue['items'][id].order.actionCode + "-" + that.indicatorValue['items'][id].order.actionType,
      'fieldName': fieldName,
      'fieldOldValue': oldValue,
      'fieldNewValue': newValue
    }
    that.storageService.update("itemHistory", id, activityLogHistoryObj);
  }
  private disabledCell(parentId: string, statusCode: number,readOnly: boolean,selectList: any, rowName: string, udac?) {
    if(rowName === 'businessProfile' &&(statusCode >= 6000 || parentId !=null || readOnly || (udac && udac === 'SPPOW'))) {
      selectList.style.opacity =  '.3';
      selectList.style.cursor =  'not-allowed';
      selectList.style.pointerEvents = 'none';
      return selectList;
    } else if(rowName ==='billing' && (statusCode >= 6000 || parentId !=null || readOnly || (udac && udac === 'SPPOW')) ) {
      selectList.style.opacity =  '.3';
      selectList.style.cursor =  'not-allowed';
      selectList.style.pointerEvents = 'none';
      return selectList;
    } else {
      return;
    }
  }
  private updateItemId(itemId) {
    if(that.items && that.items.length > 0 ) {
      if(that.items.indexOf(itemId) === -1) {
        that.items.push(itemId);
      }
    } else {
      that.items.push(itemId);
    }
    that.items && that.items.length > 0 ? that.counterService.setItem(that.items) : '';
    if (that.showToastr){
      //shoeToastr
      // that.loggerService.logError('You must SAVE the item before switching to another Tab/Window.');
      that.loggerService.logItemSaveError('You must SAVE the item before switching to another Tab/Window.');
      // that.showToastr = false;
      $('#enableDisableLeftNavigationId').addClass('enable-disable-link');
      $('#oredrItemTabId').addClass('enable-disable-link');
      $('#oredrChangeViewId').addClass('enable-disable-link')
    }
  }
  private updateIndictor(itemId, data,tempIndicator, currentCount) {
    let counter=[];
    tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
    data = JSON.parse(JSON.stringify(data));
    data.orderPercent['currentCount'] = tempIndicator.tempCurrentCount ;
    data.items[itemId]['counter'] = tempIndicator.itemCount;
    data.orderPercent = that.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount,  data.items[itemId].order);
    let percentage = that.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent['percent'] = percentage;
    //get BP count
    const { bpcount, bpcountMax, bpTotalCount} = that.requiredfieldcount.getBPCount(data.profiles);
    data.orderPercent['bpTotalCount'] = bpTotalCount;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    that.counterService.setError(counter);
    that.indicatorStore.setLoading(true);
    that.updatStore(data);
  }
  private updatStore(data){
    that.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[TabulatorModels] updateIndictor",
        isloaded: true,
      };
    });
    that.indicatorStore.setLoading(false);

  }
  private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent= that.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent= that.updateCounter(productType, counter, orderPercent);
    }  else {
    //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
 }
  private updateCounter(productType, counter, orderPercent) {
  if(productType && productType === 'Digital') {
    orderPercent.digitalCount =   counter -  orderPercent.printCount;
  } else if(productType && productType === 'Print') {
    orderPercent.printCount =   counter -  orderPercent.digitalCount;
  }
  return orderPercent;
  }
  private async digitalBillingContent(itemId, currentIndicator) {
    
    let currentItem = currentIndicator.items[itemId];
    let stopPrintCallforItemStatus = (currentItem.order.statusCode == 10000 || currentItem.order.statusCode == 7000) ? true : false;
    let order = currentIndicator.order;
    let thryvOrNoBPItem = false;
    await this.sfService.getThryvProductDetails(currentItem.order.UDAC).then(res => {
      if((res["records"] && res["records"][0] && res["records"][0].hasBP__c == false && res["records"][0].isThryvLead__c == true) || (res["records"] && res["records"][0] && res["records"][0].Product_Code__c == 'Vivial - Digital')){
        thryvOrNoBPItem = true;
      }                
    });
    
    if(thryvOrNoBPItem){
      let billingId = currentItem.model["BillingAccount"] ? currentItem.model["BillingAccount"] : currentItem.model.BillingAccount || currentIndicator.billing.billing[0].billingAccountId;
      let itemSfData = {
        "eItemId": currentItem.order.svItemId,
        "SFDCItemId": currentItem.order.sfdcItemId,
        "billingAccountId": billingId,
        "billingBasis": currentItem.model["BillingBasis"],
        // "billingMapRequired": data.Billing_MAP_Required__c,
      };
      let sfresult = await this.sfService.savesfdata(itemSfData)

      

      if ((currentItem.order.productType === "Digital") && order.orderType !== "National" && !stopPrintCallforItemStatus) {
        let upsertMapping
        upsertMapping = {
          AuthData: {
            "apiSource": "CA"
          },
          ProductHeader: {},
          products: [{}]
        };
        upsertMapping['products'] = {
          product: {
            type: "updateItem",
            callerId: "UCC",
            correlationId: this.grouper.getAllItemsOfGroupButNotSelf(currentItem.order.svItemId, currentIndicator).toString(),
            sourceId: order.accountId,
            svOrderId: order.orderId,
            customerId: currentIndicator.account.kgenCustomerId,
            operatorId: order.opportunity.owner.operatorId,
            content: [{
              svItemId: currentItem.order.svItemId,
              billingAccountId: currentItem.model.BillingAccount
            }]
          }
        };
        let updateResult = await this.productService.updateSync("print", upsertMapping, "item");
      }
    }
  }

};
