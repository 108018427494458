import { Component, OnInit } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';


@Component({
  selector: 'app-emergencydropdown',
  templateUrl: './emergencydropdown.component.html',
  styleUrls: ['./emergencydropdown.component.scss']
})
export class EmergencydropdownComponent implements OnInit {

  sharedData: Quickbpclass;
  emergencyservice:any;
  content:any;
  options: any;
  titleContent: any;
  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    });
    this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][2]['properties']['EmergencyService']['title'];
    if (!this.sharedData['profiles'][this.sharedData.stateParamsId].model.hasOwnProperty("EmergencyService")) {
      this.sharedData['profiles'][this.sharedData.stateParamsId].model.EmergencyService = {
        "selected": "N",
        "_content_": "No"
      };
    }

    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["EmergencyService"]) {
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.EmergencyService.selected === undefined) {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.EmergencyService.selected = "N";
      } else {
        this.emergencyservice = this.sharedData['profiles'][this.sharedData.stateParamsId].model.EmergencyService.selected
      }
    }
  }

  emergencydropdownchange() {
    if (this.emergencyservice == "N") {
      this.content = "No";
    } else if (this.emergencyservice == "Y") {
      this.content = "Yes"
    }
    this.sharedData['profiles'][this.sharedData.stateParamsId].model.EmergencyService = {
      "selected": this.emergencyservice,
      "_content_": this.content
    }
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][ this.sharedData.stateParamsId].model))
    this.indicatorQuery.updateBPModel(this['layoutNode'].name,this.sharedData['profiles'][this.sharedData.stateParamsId].model.EmergencyService,modelData, this.sharedData, '[EmergencydropdownComponent] emergencydropdownchange');
  
    // this.indicatorStore.update(state =>{
    //   return{
    //     indicator: this.sharedData,
    //     isloaded: true
    //   }
    // });
  }

  }
