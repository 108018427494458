import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hovercard',
  templateUrl: './hovercard.component.html',
  styleUrls: ['./hovercard.component.scss']
})
export class HovercardComponent implements OnInit {
 
  showCard:boolean;
  @Input() hoverCardConfig:any;
  @Input() index:any;
  isIndex: any;
  
//@Input() copysheetUrl:string; 
  
  constructor() { }

  ngOnInit() {
    this.showCard = false;
    if(this.index == 0){
      this.isIndex = 'right';
    }else if(this.index == 1 || this.index == 2){
      this.isIndex = 'center';
    }else{
      this.isIndex = 'left';
    }

  }

}
