import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { CampaignSharedService } from './campaign.service';
import { IndicatorQuery } from 'app/state/query';
import Tabulator from 'tabulator-tables';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CampaignalertComponent } from '../campaignalert/campaignalert.component';
import { ComponentQuery } from 'app/state/component.query';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {

  ddlvalue: string = ''
  nationalType: string = 'National'
  Indicator: any;
  dataValue = new Array()
  gridData = new Array()
  model: string
  itemID: string = '';
  campaignTable: any
  campaignColumns: any
  emptyGrid: boolean = false;
  readOnly: boolean = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private campaignService: CampaignSharedService,
    private indicatorQuery: IndicatorQuery,
    private matDialog: MatDialog,
    private componentQuery: ComponentQuery,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.indicatorQuery.getIndicatorT().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data))
      this.itemID = this.Indicator.stateParamsId
    })
    this.readOnly = this.Indicator.items[this.itemID].schema.properties[this['layoutNode'].name].readonly
    if (this.Indicator.items[this.itemID].order.productCode.indexOf('SuperClick') !== -1 ||
      this.Indicator.items[this.itemID].order.productCode.indexOf('SEM') !== -1) {
      this.nationalType = 'Metro/State/National';
    }
    this.initCampaignGrid();
    this.componentQuery.getSemAccount().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
      this.initCampaignGrid();
    })
  }

  openCampaignDialog(ngModel, campaignType, selectedCampaign, index, modelClicktype) {
    this.campaignService.openCampaignDialog(ngModel, campaignType, selectedCampaign, index, modelClicktype, this['layoutNode'].name)
    .subscribe(data => {
      this.ddlvalue = '';
      if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();
      this.initCampaignGrid()
    })
  }

  initCampaignGrid() {
    this.constructGridData();
    this.Indicator.items[this.itemID].model.CampaignType ? this.gridData = this.campaignService.initCampaignGrid(this.Indicator.items[this.itemID].model.CampaignType) : this.gridData = [];
    this.drawGrid()
  }

  private drawGrid() {
    this.campaignTable = new Tabulator("#CampaignGrid", {
      height: 500,
      data: (this.gridData.length > 0) ? JSON.parse(JSON.stringify(this.gridData)): [],
      dataTree: true,
      dataTreeStartExpanded: true,
      layout: "fitColumns",
      columns: this.campaignColumns,
      movableColumns: true,
      tooltips: true,
      placeholder: 'No Data Available...',
      pagination: "local",
      paginationSize: 15,
      paginationSizeSelector: [15, 20, 25],
      columnMinWidth: 10,
      initialSort:[
        {column:"isComplete", dir:"desc"},
      ]
    });
     this.emptyGrid = this.gridData.length > 0 ? false : true;
  }

  constructGridData() {
    this.campaignColumns = [
      { title: '', width:20, field: 'isComplete', headerFilter: 'Campaign Indicator',formatter:function(cell, formatterParams){
        var value = cell.getValue();
        if (!value){
          return "<div style='min-width:6px;min-height:49px;background:#df2222;margin-top:-4px;margin-left:-8px;margin-right:2px;'></div>"
        }
        return;
      } },
      {
        title: 'Open', width:90, field: 'imageopen', headerFilter: 'Open/Edit Campaign',
        formatter: function (cell, formatterParams) {
          return "<i class='fa fa-folder-open'></i>";
        },
        cellClick: (e, cell) => {
          let rowData = cell.getRow().getData();
          this.campaignService.openCampaignDialog('', rowData.CampaignTypeText, rowData, rowData.index, 'edit', this['layoutNode'].name)
            .subscribe(data => {
              this.ddlvalue = '';
              if (!(<ViewRef>this.cdr).destroyed)
                this.cdr.detectChanges();
              this.initCampaignGrid()
            })
        }
      },
      {
        title: 'Copy', width:90, field: 'imagecopy', headerFilter: 'Copy Campaign',
        formatter: function (cell, formatterParams) {
          return "<i class='fa fa-clone'></i>";
        },
        cellClick: (e, cell) => {
          if (this.Indicator.items[this.Indicator.stateParamsId].order.productType === 'Digital' &&
            this.Indicator.items[this.Indicator.stateParamsId].order.status === 'Digital Fulfillment') {
            let msg = 'Cannot edit item as its in Digital Fulfillment';
            let heading = 'Warning!!';
            const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'call-tracking-confirmation-dialog';
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
              message: msg,
              heading: heading
            }
            dialogConfig.width = '300px';
            const dialogRef = this.matDialog.open(CampaignalertComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(dialogResult => { })
          }
          else {
            let rowData = cell.getRow().getData();
            this.campaignService.openCampaignDialog('', rowData.CampaignTypeText, rowData, rowData.index, 'copy', this['layoutNode'].name)
              .subscribe(data => {
                this.ddlvalue = '';
                if (!(<ViewRef>this.cdr).destroyed)
                  this.cdr.detectChanges();
                this.initCampaignGrid()
              })
          }
        }
      },
      { title: 'Type',  width:90, field: 'CampaignTypeText' },
      { title: 'Business Profile', width:190, field: 'BPText' },
      { title: 'Description', width:150, field: 'CampaignDescription' },
      { title: 'Geos', field: 'GeoListText' },
      { title: 'Categories', field: 'CategoryListText' }
    ]
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }

}
