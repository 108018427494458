import { Component, OnInit } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { UtilsService } from '@app/service/utils/utils.service';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss']
})
export class UrlComponent implements OnInit {

  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private _utils: UtilsService) { }

  sharedData: Quickbpclass;
  urlInput = '';
  isValidURL: boolean = true;
  options: any;
  titleContent: string = "";
  id:any;

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      // this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1].properties.YoutubeURL.title;

    })
    if (this['layoutNode'].name == "YoutubeURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1].properties.YoutubeURL.title;
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.YoutubeURL;
      if (this.urlInput != undefined && this.urlInput != null && this.urlInput) {
        this.isValidURL = this._utils.validateYoutubeURL(this.urlInput);
        if (this.isValidURL && this.urlInput.length > 200)
          this.isValidURL = false
      }
      this.id= "YoutubeURL"
    }
    if (this['layoutNode'].name == "InstagramURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1].properties.InstagramURL.title;
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.InstagramURL;
      if (this.urlInput != undefined && this.urlInput != null && this.urlInput) {
        this.isValidURL = this._utils.validateYoutubeURL(this.urlInput);
        if (this.isValidURL && this.urlInput.length > 200)
          this.isValidURL = false
      }
      this.id= "InstagramURL"
    }
  }
  sendValue(args: any) {
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    if (args != undefined && args != null && args) {
      this.isValidURL = this._utils.validateYoutubeURL(args);
      if (this.isValidURL && args.length > 200)
        this.isValidURL = false
    } else  {
      this.isValidURL = true
    }

    if (this.isValidURL) {
      if (this['layoutNode'].name === "YoutubeURL")
      modelData["YoutubeURL"] = args;
      if (this['layoutNode'].name === "InstagramURL")
      modelData["InstagramURL"] = args;
    } else {
      if (this['layoutNode'].name === "YoutubeURL")
      modelData['YoutubeURL'] = args;
      // modelData["YoutubeURL"] = args;
      if (this['layoutNode'].name === "InstagramURL")
      modelData['InstagramURL'] = args;
    }
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;


    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[UrlComponent] sendValue",
        isloaded: true
      }
    });
    this.indicatorStore.setLoading(false);
  }
}
