import { Component, OnInit } from '@angular/core';
import { LogoService } from '@app/service/logo/logo.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-showphoto',
  templateUrl: './showphoto.component.html',
  styleUrls: ['./showphoto.component.scss']
})
export class ShowphotoComponent implements OnInit {

  subscription: any;
  sharedData: any;
  photoSubscription: any;
  deleteArrayFromIH: any = [];
  logoArray: any = [];
  browsedeladdArray: any = [];
  deleteFromLogoarray: any = [];
  uploadlogoArray: any = [];
  // loadingFlag: boolean;
  filteredImage: any = [];
  listingId: string = '';
  photoType: string = '';
  totalItems: number = 0;
  itemsPerPage: number = 8;
  currentPage: number = 1;
  maxSize: number = 5;
  bigTotalItems: number = 175;
  bigCurrentPage: number = 1;
  sharedLogoObj: Photos = new Photos;
  page: any;
  showMessage: boolean = false;

  constructor(private _logoService: LogoService,
    private spinnerService: NgxSpinnerService,
    private photo: PhotoService, 
    private indicatorQuery: IndicatorQuery, 
    private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data}
      this.listingId = this.sharedData['stateParamsId'];
    })
    /* "commented spinner code for ExpressionChangedAfterItHasBeenCheckedError"
    this.sharedData.loadingText = "Loading photos...";
    this.dataService.setBehaviorView(this.sharedData);*/
    this.getPhotos(this.listingId);
    this.photoSubscription = this.photo.getBehaviorView().subscribe(result => {
      this.uploadlogoArray = result.uploadLogoArrObj
      this.browsedeladdArray = result.browsedeladdArrObj
    })
  }

  change(currentPage) {
    this.currentPage = currentPage
  }

  deleteImage(img) {
    if (img.iconId) {
      this.deleteArrayFromIH.push(img.iconId);
      this.sharedLogoObj.deleteArrFromIHObj = this.deleteArrayFromIH
      this.photo.setBehaviorView(this.sharedLogoObj);
      for (var i = 0; i < this.logoArray.length; i++) {
        if (this.logoArray[i].iconId === img.iconId) {
          var index = this.logoArray.indexOf(this.logoArray[i]);
          if (index > -1) {
            img.hideImg = true;
          }
        }
      }
    }
    if (img.isAttachedImg) {
      img.hideImg = true
      for (var i = 0; i < this.browsedeladdArray.length; i++) {
        if (this.browsedeladdArray[i].id === img.id) {
          var index = this.browsedeladdArray.indexOf(this.browsedeladdArray[i])
          if (index > -1)
            this.browsedeladdArray.splice(index, 1)
        }
      }

      for (var i = 0; i < this.deleteFromLogoarray.length; i++) {
        if (this.deleteFromLogoarray[i].id === img.id) {
          var index = this.deleteFromLogoarray.indexOf(this.deleteFromLogoarray[i]);
          if (index > -1) {
            this.deleteFromLogoarray.splice(index, 1);
          }
        }
      }

      for (var i = 0; i < this.uploadlogoArray.length; i++) {
        if (this.uploadlogoArray[i].id === img.id) {
          var index = this.uploadlogoArray.indexOf(this.uploadlogoArray[i]);
          if (index > -1) {
            this.uploadlogoArray.splice(index, 1);
          }
        }
      }
    }

    if (img.isNewImg) {
      img.hideImg = true;
      for (var i = 0; i < this.uploadlogoArray.length; i++) {
        if (this.uploadlogoArray[i].id === img.id) {
          var index = this.uploadlogoArray.indexOf(this.uploadlogoArray[i]);
          if (index > -1) {
            this.uploadlogoArray.splice(index, 1);
          }
        }
      }
    }
  }

  getPhotos(listingID) {
    //this.spinnerService.show();
    this._logoService.get(listingID).subscribe(Photos => {
      var tempArray = {
        hideImg: true,
        url: "",
        urlName: "",
        thumbImg: "",
        largeImg: "",
        iconId: ""
      }
      if (Photos && Photos["Photos"]) {
        for (let i = 0; i < Photos["Photos"].Photo.length; i++) {
          this.photoType = Photos["Photos"].Photo[i].imageType[0];
          if (Photos != undefined && this.photoType == "PHOTO") {
            let imgUrlPrefix = Photos["Imgroot"];
            tempArray.hideImg = false;
            tempArray.url = Photos["Photos"].Photo[i].photoURL[0];
            let urlArray = tempArray.url.split("/");
            tempArray.urlName = urlArray.pop();

            tempArray.thumbImg = imgUrlPrefix + urlArray[6] + "/" + urlArray[7] + "/" + urlArray[8] + "/" + urlArray[9] + "/" + urlArray[10] + "/thumb/" + tempArray.urlName;
            tempArray.largeImg = imgUrlPrefix + urlArray[6] + "/" + urlArray[7] + "/" + urlArray[8] + "/" + urlArray[9] + "/" + urlArray[10] + "/images/" + tempArray.urlName;

            tempArray.iconId = Photos["Photos"].Photo[i].photoId[0];
            this.logoArray.push(tempArray);
            this.sharedLogoObj.logoArrObj = this.logoArray
            tempArray = {
              hideImg: true,
              url: "",
              urlName: "",
              thumbImg: "",
              largeImg: "",
              iconId: ""
            };
          }
        }
        if (this.deleteArrayFromIH.length > 0) {
          for (var s = 0; s < this.logoArray.length; s++) {
            for (var t = 0; t < this.deleteArrayFromIH.length; t++) {
              if (this.logoArray[s].iconId === this.deleteArrayFromIH[t]) {
                var index = this.logoArray.indexOf(this.logoArray[s]);
                if (index > -1) {
                  this.logoArray.splice(index, 1);
                }
              }
            }
          }
        }
        if (this.uploadlogoArray.length > 0) {
          this.logoArray = this.logoArray.concat(this.uploadlogoArray);
        }
        this.filteredImage = this.logoArray
      } else {
        if (this.uploadlogoArray.length > 0) {
          this.logoArray = this.logoArray.concat(this.uploadlogoArray)
        }
        this.filteredImage = this.logoArray
      }

      if (!(this.filteredImage.length > 0))
        this.showMessage = true;
      else
        this.showMessage = false;
      //this.spinnerService.hide();
    },
      (err) => {
        if (!(this.filteredImage.length > 0))
          this.showMessage = true;
        else
          this.showMessage = false;
        //this.spinnerService.hide();
        console.log(err);
      },
      () => {
        if (!(this.filteredImage.length > 0))
          this.showMessage = true;
        else
          this.showMessage = false;
        //this.spinnerService.hide();
      })
  }

  ngOnDestroy() {
    this.photoSubscription.unsubscribe();
  }
}
