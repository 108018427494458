import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { FileUploader } from 'ng2-file-upload';
import { ParamsService } from '../params/params.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private API: ApiService, private http: HttpClient,private Params : ParamsService) {}
  baseUri: string = '/api/file';
  // uploader: FileUploader = new FileUploader({ url: this.API.get(this.baseUri),headers : [{name: "Authorization",value:"Bearer "+ this.Params.params.token},{name:"currentAccountId",value:sessionStorage.getItem("currentAccountId")},{name:"currentOrderId",value:sessionStorage.getItem("currentOrderId")},{name:"currentListingId",value:sessionStorage.getItem("currentListingId")}]});  
  // copysheetAdUploader: FileUploader = new FileUploader({ url: this.API.get(this.baseUri),headers : [{name: "Authorization",value:"Bearer "+ this.Params.params.token},{name:"currentAccountId",value:sessionStorage.getItem("currentAccountId")},{name:"currentOrderId",value:sessionStorage.getItem("currentOrderId")},{name:"currentListingId",value:sessionStorage.getItem("currentListingId")}]});
  uploader: FileUploader = new FileUploader({ url: this.API.get(this.baseUri),headers : [{name: "Authorization",value:"Basic "+ btoa("caccd" + ':' + "ExE@D915!")},{name:"currentAccountId",value:sessionStorage.getItem("currentAccountId")},{name:"currentOrderId",value:sessionStorage.getItem("currentOrderId")},{name:"currentListingId",value:sessionStorage.getItem("currentListingId")}]});
  copysheetAdUploader: FileUploader = new FileUploader({ url: this.API.get(this.baseUri),headers : [{name: "Authorization",value:"Basic "+ btoa("caccd" + ':' + "ExE@D915!")},{name:"currentAccountId",value:sessionStorage.getItem("currentAccountId")},{name:"currentOrderId",value:sessionStorage.getItem("currentOrderId")},{name:"currentListingId",value:sessionStorage.getItem("currentListingId")}]});
  

  get() {
    return this.http.get(this.API.get(this.baseUri));
  };

  removeFileChunkData(fileId: string) : Observable<any>{
    return this.http.delete(this.API.get(this.baseUri) + "/" + fileId);
  }

  put(id: string, data: string) {
    return this.http.put(this.API.get(this.baseUri) + "/" + id, data);
  };

  saveImage(id: string, data: object, type: string) {
    return this.http.post(this.API.get(this.baseUri) + "/saveImage/" + type + '/' + id, data);
  };

  saveImageToPromise(id: string, data: object, type: string) {
        return this.http.post(this.API.get(this.baseUri) + "/saveImage/" + type + '/' + id, data).toPromise();    
  }
  
  savePdf(data: FormData) {
    return new Promise( (resolve, reject)=>{
      try{
         this.http.post(this.API.get(this.baseUri), data).toPromise()
        .then((response)=> {
            response['copysheet'] = this.API.get('/api/file/'+response['_id'] + '/' + response['filename']);
            return resolve(response);
      }).catch(e=>{
        console.log(e);
      })
      }catch(e){
        return reject(e);
      }     
    })    
  };
  validatePDF(url: string): Observable<any> {
    let data = { "url": url };
    
    let endpoint = this.API.get(this.baseUri) + "/validpdf/1";
    try{
      return this.http.put(endpoint, data)
    }catch(e){
      console.log("error in validate pdf file ", e);
    }
  }
  validateImage(url: string) {
    let data = { "url": url };
    let endpoint = this.API.get(this.baseUri) + "/validImage/2";
    try{
      return this.http.put(endpoint, data)
    }catch(e){
      console.log("error in validate pdf file ", e);
    }    
  }
  
  imageToPdf(url: string){
    let reqBody ={}
    reqBody['url'] =url
    return this.http.post(this.API.get(this.baseUri) + "/imagetopdf", reqBody).toPromise();
  }
  pdftoimage(data: any) {
    // console.log('data from file.service.ts :', data);
    return this.http.post(this.API.get(this.baseUri) + "/pdftoimage", data).toPromise();
  }

  getUploadedFileSize(id: string){
    return this.http.get(this.API.get(this.baseUri)+"/"+id).toPromise();;
  }
}