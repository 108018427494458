import { Component, OnInit, ChangeDetectorRef, ViewRef, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
//import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import { DatasharingService } from '@app/service/datasharing/datasharing.service';
import { MatAccordion, MatChipInputEvent, MatDialog } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Subscription } from 'rxjs';
import { SharedService } from '@app/service/shared/shared.service';

@Component({
  selector: 'app-customcatkeys',
  templateUrl: './customcatkeys.component.html',
  styleUrls: ['./customcatkeys.component.scss']
})
export class CustomcatkeysComponent implements OnInit {

  sharedData;

  closeResult: any;
  recentAdditions;
  tags = [];
  tagsadded = {
    'catkeytype': '',
    'values': []
  };
  categoryTypes = [
    { 'key': 'Products', 'keysObject': [] },
    { 'key': 'Services', 'keysObject': [] },
    { 'key': 'Brands', 'keysObject': [] },
    { 'key': 'Certifications', 'keysObject': [] },
    { 'key': 'Affiliations', 'keysObject': [] },
    { 'key': 'Specialities', 'keysObject': [] }
  ];
  catKeyTypes = ['Products', 'Services', 'Brands', 'Certifications', 'Affiliations', 'Specialities']
  dialogRef: any;
  duplicateCat:boolean = false;
  minCatChar:boolean = false;
  isTagsLength:boolean = false;

  // Mat-chip
  removable:boolean = true;
  selectable:boolean = true;
  addOnBlur = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('accordion',{static:true}) Accordion: MatAccordion
  bpAccordianSub: Subscription;

  constructor(/*private modalService: NgbModal,*/ private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.bpAccordianSub = this.sharedService.getAccordianState().subscribe(state =>{
      if(state){
        this.Accordion.openAll();
      }else{
        this.Accordion.closeAll();
      }
    });
    let type
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data};
    });
    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.hasOwnProperty('CustomCatKeys') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys != null) {
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.hasOwnProperty('Products') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products != '') {
        this.categoryTypes[0].keysObject = this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products
      }
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.hasOwnProperty('Services') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services != '') {
        this.categoryTypes[1].keysObject = this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services
      }
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.hasOwnProperty('Brands') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands != '') {
        this.categoryTypes[2].keysObject = this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands
      }
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.hasOwnProperty('Certifications') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications != '') {
        this.categoryTypes[3].keysObject = this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications
      }
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.hasOwnProperty('Affiliations') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations != '') {
        this.categoryTypes[4].keysObject = this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations
      }
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.hasOwnProperty('Specialities') && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities != null && this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities != '') {
        this.categoryTypes[5].keysObject = this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities
      }
    }
  }

  open(content) {
    let existingCatKeys;;
    // console.log(" clicked on the open button and the code is commented out .");
    this.dialogRef = this.dialog.open(content, { disableClose: true , width: '800px', panelClass: 'custom-add-modal'});
    this.dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    });
    // this.dialog.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: "static" , windowClass: "catkeys-modal" })
    // .result.then((result) => {
    //   this.closeResult = `${result}`;
    //   if (this.closeResult == 'Add') {
    //     this.tags = [];
    //     this.addTags(this.tagsadded)
    //   }
    //   if (this.closeResult == 'Cancel') {
    //     this.tags = [];
    //   }
    // }, (reason) => {
    //   if (this.getDismissReason(reason) == 'ESCAPE_KEY')
    //     this.tags = [];
    // });
  }

  // private getDismissReason(reason: any): string {
  //   if (reason == ModalDismissReasons.ESC) {
  //     return 'ESCAPE_KEY';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  close(){
    this.tags = [];
    this.duplicateCat = false;
    this.minCatChar = false;
    this.isTagsLength = false;
    this.dialogRef.close();
  }

  removeTag(value): void {
    const index = this.tags.indexOf(value);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
  onTagsChanged(event: any, catKeyType, calledfromAddTags?:any) {
    let input;
    let value;
    if(!calledfromAddTags){
      input = event.input;
      value = event.value;
    } else{
      input = event;
      value = event.value;
    }

    this.duplicateCat = false;
    this.minCatChar = false;
    this.isTagsLength = false;
    this.tags.push(value);
    let tagToBeChecked
    let tagExists: boolean
    this.tagsadded = {
      'catkeytype': '',
      'values': []
    };

    //Length check
    this.tags = this.tags.filter((elem, i, arr) => {
      if (elem.length >= 3){
        this.minCatChar = false;
        return true
      }else{
        this.minCatChar = true;
        return false
      }
    })
    // Duplicate check
    if (this.tags.length > 1) {
      tagToBeChecked = this.tags[this.tags.length - 1]
      tagExists = false;
      for (let i = 0; i < this.tags.length - 1; i++) {
        if (tagToBeChecked.toUpperCase() == this.tags[i].toUpperCase()) {
          tagExists = true
          break;
        }
      }
      if (tagExists) {
        this.tags.splice(this.tags.length - 1)
        this.duplicateCat = true;
      }else{
        this.duplicateCat = false;
      }
    }
    if (this.tags.length > 0 && this.tags.length <= 10) {
      this.tagsadded.catkeytype = catKeyType
      this.tagsadded.values = this.tags;
      this.isTagsLength = false;
      if (input && !this.duplicateCat && !this.minCatChar) {
        input.value = '';
      }
    }else if (this.tags.length > 10) {
      this.tags.splice(this.tags.length - 1)
      this.isTagsLength = true;
      // if (input) {
      //   input.value = '';
      // }
    }
  }

  addTags(catKeyInput, catKeyType) {
    if(catKeyInput.value){
      this.onTagsChanged(catKeyInput, catKeyType, true)
    }
    if(!this.minCatChar && !this.duplicateCat && !this.isTagsLength)
    {
    this.duplicateCat = false;
    this.minCatChar = false;
    this.isTagsLength = false;
    this.tags = [];
    let customtags = [];
    let customValueExists
    let catkeysadded = this.tagsadded;

    // Add only new catkeys
    for (let loopcounter1 = 0; loopcounter1 < this.categoryTypes.length; loopcounter1++) {
      if (this.categoryTypes[loopcounter1].key == catkeysadded.catkeytype) {
        for (let j = 0; j < catkeysadded.values.length; j++) {
          customValueExists = false;
          for (let i = 0; i < this.categoryTypes[loopcounter1].keysObject.length; i++) {
            if (this.categoryTypes[loopcounter1].keysObject[i].text == catkeysadded.values[j]) {
              customValueExists = true;
              break;
            }
          }
          if (!customValueExists) {
            let customtag = { isSelected: false, text: '' };
            customtag.isSelected = true;
            customtag.text = catkeysadded.values[j];
            this.categoryTypes[loopcounter1].keysObject.push(customtag);
          }
        }
      }
    }
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    //Add the modified/catkeys set to sharedData
    for (let categoryType of this.categoryTypes) {
      switch (categoryType.key) {
        case 'Products': {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products = categoryType.keysObject
        }
        case 'Services': {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services = categoryType.keysObject
        }
        case 'Brands': {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands = categoryType.keysObject
        }
        case 'Certifications': {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications = categoryType.keysObject
        }
        case 'Affiliations': {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations = categoryType.keysObject
        }
        case 'Specialities': {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities = categoryType.keysObject
        }
      }
    }
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[CustomcatkeysComponent] addTags",
        isloaded: true
      }
    });
    this.ngAfterViewChecked();

    this.close();
    }
  }

  onSelectCheckbox(type, counter, evnt) {

    let selected;
    if (evnt)
      selected = true
    else
      selected = false
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = JSON.parse(JSON.stringify(data));
      })
    switch (type) {
      case 'Products': {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Products[counter].isSelected = selected
        break;
      }
      case 'Services': {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Services[counter].isSelected = selected
        break;
      }
      case 'Brands': {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Brands[counter].isSelected = selected
        break;
      }
      case 'Certifications': {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Certifications[counter].isSelected = selected
        break;
      }
      case 'Affiliations': {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Affiliations[counter].isSelected = selected
        break;
      }
      case 'Specialities': {
        this.sharedData['profiles'][this.sharedData.stateParamsId].model.CustomCatKeys.Specialities[counter].isSelected = selected
        break;
      }
    }
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[CustomcatkeysComponent] onSelectCheckbox"
      }
    })
  }
  ngAfterViewChecked() {
		if (!(<ViewRef>this.changeDetectorRef).destroyed)
		this.changeDetectorRef.detectChanges();
  }
  ngOnDestroy() {
    this.bpAccordianSub.unsubscribe();
  }
}
