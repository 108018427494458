import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessCategories {

  constructor(
    private http: HttpClient,
    private API: ApiService,
    private Params: ParamsService
  ) { }
  baseUri: string = '/api/category/search/';
  headersObject : object = {
    headers :new HttpHeaders({"authorization" :"bearer " + this.Params.params.token}),
  }
  getCategories(searchword: string) {
    return this.http.get(this.API.get(this.baseUri) + searchword, this.headersObject);
  }  
}