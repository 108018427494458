import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ComponentState, ComponentStore } from './component.state';

@Injectable({
    providedIn : 'root'
})
export class ComponentQuery extends Query<ComponentState>{

    constructor(protected store: ComponentStore) {
      super(store);
    }

    getComponent(): Observable<string>{
     return this.select( state => state.componentValue);
    }
    getSemAccount(): Observable<string>{
      return this.select( state => state.semAccount);
    }
}