import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewRef } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatAccordion } from '@angular/material';
import { BusinessCategories } from 'app/core/service/businessCategories/businessCategories.service';
import { LoggerService } from 'app/core/service/logger/logger.service';
import { ErrorhandlingService } from 'app/core/service/errorhandling/errorhandling.service';
import { CatKeysService } from 'app/core/service/CatKeys/CatKeys.service';
import { IndicatorQuery } from 'app/state/query';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignStore } from 'app/state/campaignstore';

@Component({
    selector: 'app-camcat',
    templateUrl: './camcat.component.html',
    styleUrls: ['./camcat.component.scss']
})
export class CamcatComponent implements OnInit {
    open_dd: boolean = false;
    fetched_categories: Promise<[any]>;
    hideSelected$: Subject<boolean>;
    Catkeys: boolean = true;
    selected_categories;
    catKeys: any;
    campaignModel: CampaignClass
    CamCat: any = {
        model: {},
        Categories: []
    }
    categoryName: string = '';
    atLeastOne: boolean = false;
    Indicator: any;
    checkboxReadonly: boolean = false;
    selectedCategory: any = []
    groupedArray: any = []
    shouldDisplay: boolean = false;
    affiliations = {};
    brands = {}
    certifications = {}
    products = {}
    services = {}
    readonly: boolean = false;

    @ViewChild('accordion', { static: true }) Accordion: MatAccordion
    constructor(private CatKeys: CatKeysService,
        private BusinessCategories: BusinessCategories,
        private Logger: LoggerService,
        private errorService: ErrorhandlingService,
        private indicatorQuery: IndicatorQuery,
        private campaignQuery: CampaignQuery,
        private cdr: ChangeDetectorRef,
        private campaignStore: CampaignStore
    ) { }

    ngOnInit() {
        const data = [];
        this.hideSelected$ = new BehaviorSubject(!!data.length);
        this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = { ...data }
            for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
                if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
                    this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
                    break;
                }
            }
        })
        this.campaignQuery.getCampaign().subscribe(data => {
            this.campaignModel = { ...data }
            if (this.campaignModel.model.CCCampaignCatKeys != undefined) {
                for (let campaignCatKey of this.campaignModel.model.CCCampaignCatKeys) {
                    let temp = {
                        categoryName: campaignCatKey.CategoryName,
                        categoryId: campaignCatKey.CategoryId
                    }
                    this.selectedCategory.push(temp)
                }
            }
            this.onLoad();
        })
    }
    callService(searchWord) {

        return new Promise<[any]>((resolve, reject) => {
            let categories, categoryDtls;
            this.BusinessCategories.getCategories(searchWord.term)
                .subscribe(data => {
                    categories = data;
                    resolve(categories);
                },
                    err => {
                        this.Logger.logError(this.errorService.handleApiError(err))
                        reject(err);
                    },
                    () => {
                        resolve(categories)
                    });
        });

    }

    getCategories(searchword) {
        let categories;
        this.open_dd = true;

        if (searchword.term.length > 0) {
            this.fetched_categories = this.callService(searchword)
            this.fetched_categories.then((data) => {
                this.hideSelected$.next(!!data.length);
            }, (err) => {
            })
        } else {
            this.fetched_categories = null;
        }
    }

    getCatKeys(categoryName, categoryId) {
        this.atLeastOne = true
        this.CatKeys.getCatKeys(categoryName)
            .subscribe(data => {
                let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
                this.catKeys = data;
                if (this.catKeys && this.catKeys != null && !(this.catKeys === undefined)) {
                    for (let j = 0; j < this.catKeys.length; j++) {
                        if (!(this.CamCat.model[categoryName] instanceof Array)) {
                            this.CamCat.model[categoryName] = [];
                        }

                        this.CamCat.model[categoryName].push({
                            catkeyType: this.catKeys[j].type,
                            catkeyId: this.catKeys[j].id,
                            categoryId: categoryId,
                            catkeyName: this.catKeys[j].name,
                            catkeySelected: false
                        })

                    }
                    let temp = {
                        CategoryId: categoryId,
                        CategoryName: categoryName,
                        MaxBid: "1.00",
                        CatKeyList: []
                    }

                    this.selected_categories = this.CamCat.model[categoryName]
                    this.Catkeys = false
                    if (!(<ViewRef>this.cdr).destroyed){
                    this.cdr.detectChanges()}
                    if (modelData.CCCampaignCatKeys) {
                        var pos = modelData.CCCampaignCatKeys.map(element => {
                            return element.CategoryId;
                        }).indexOf(temp.CategoryId);

                        if (pos === -1) { 
                            modelData.CCCampaignCatKeys.push(temp);
                        }
                        //If bad data "CatkeyId" is present replace it with "CatKeyId"
                        for (var i = 0; i < modelData.CCCampaignCatKeys.length; i++) {
                            for (var j = 0; j < modelData.CCCampaignCatKeys[i].CatKeyList.length; j++) {
                                if (modelData.CCCampaignCatKeys[i].CatKeyList[j].CatkeyId) {
                                    modelData.CCCampaignCatKeys[i].CatKeyList[j].CatKeyId = modelData.CCCampaignCatKeys[i].CatKeyList[j]['CatkeyId'];
                                    delete modelData.CCCampaignCatKeys[i].CatKeyList[j].CatkeyId;
                                }
                            }
                        }

                        modelData.CCCampaignCatKeys.forEach(currentValue => {
                            currentValue.CatKeyList.forEach((value, index, array) => {
                                var selectedCatKey = {
                                    categoryId: currentValue.CategoryId,
                                    catkeyId: value.CatKeyId,
                                    catkeyName: value.CatkeyName,
                                    catkeySelected: true
                                }
                                if (this.CamCat.model[currentValue.CategoryName]) {
                                    this.CamCat.model[currentValue.CategoryName].forEach((element, index) => {
                                        if (selectedCatKey.catkeyId === element.catkeyId) {
                                            this.CamCat.model[currentValue.CategoryName][index].catkeySelected = true
                                        }
                                    });
                                }


                            });
                        });
                    }
                    if(this.CamCat.model[categoryName]){
                        let group = this.CamCat.model[categoryName].reduce((r, a) => {
                            r[a.catkeyType] = [...r[a.catkeyType] || [], a];
                            return r;
                           }, {});
                        this.groupedArray[categoryName] = group
                        if(!(this.affiliations[categoryName] instanceof Array)){
                            this.affiliations[categoryName] = []
                            this.brands[categoryName] = []
                            this.certifications[categoryName] = []
                            this.services[categoryName] = []
                            this.products[categoryName] = []
                        }
                        this.affiliations[categoryName] = this.groupedArray[categoryName].Affiliations
                        this.brands[categoryName] = this.groupedArray[categoryName].Brands
                        this.certifications[categoryName] = this.groupedArray[categoryName].Certifications
                        this.products[categoryName] = this.groupedArray[categoryName].Products
                        this.services[categoryName] = this.groupedArray[categoryName].Services
                    }
                }
                let x = JSON.parse(JSON.stringify(this.campaignModel))
                x.model = modelData
                this.campaignStore.update(state => {
                    return {
                        campaign: x,
                        updatedFrom: '[CamcatComponent] getCatKeys'
                    }
                })
                this.campaignModel = x;
            }, err => {
                this.Logger.logError(this.errorService.handleApiError(err));
            });
    }
    selectType(status: any, th: any, type: any, val: any) {
        let allObj = this.CamCat.model[val];
        allObj.forEach((element, index) => {
            if (type === "All" || type === element.catkeyType) {
                element.catkeySelected = status;
                this.selectCatKey(element)
            }

        });
    }
    selectCatKey(selectedValue) {
        let shortArray = this.campaignModel.model.CCCampaignCatKeys.map((item, index, array) => {
            return item.CategoryId;
        });

        let position = shortArray.indexOf(selectedValue.categoryId);

        if (selectedValue.catkeySelected) {
            var temp = {
                CatKeyId: selectedValue.catkeyId,
                CatkeyName: selectedValue.catkeyName
            };
            if (this.campaignModel.model.CCCampaignCatKeys ) {
                this.campaignModel.model.CCCampaignCatKeys[position].CatKeyList.push(temp);
            }
            
        } else {
            for (var i = 0; i < this.campaignModel.model.CCCampaignCatKeys[position].CatKeyList.length; i++) {
                var indexValue = this.campaignModel.model.CCCampaignCatKeys[position].CatKeyList.findIndex(function (element, index, array) {
                    if (selectedValue.catkeyId === element.CatKeyId && selectedValue.catkeyName === element.CatkeyName)
                        return true;
                });

                if (indexValue > -1) {
                    this.campaignModel.model.CCCampaignCatKeys[position].CatKeyList.splice(indexValue, 1);
                }
            }
        }
    }

    onLoad() {
        if (this.campaignModel.model.CCCampaignCatKeys && (this.campaignModel.model.CCCampaignCatKeys instanceof Array)) {
            if (this.Indicator.items[this.Indicator.stateParamsId].order.status === 'Digital Fulfillment') {
                this.checkboxReadonly = true;
            }
            for (var i = 0; i < this.campaignModel.model.CCCampaignCatKeys.length; i++) {
                if (this.campaignModel.model.CCCampaignCatKeys[i].catKeyList) {
                    this.campaignModel.model.CCCampaignCatKeys[i].CatKeyList = this.campaignModel.model.CCCampaignCatKeys[i].catKeyList; /////////
                    delete this.campaignModel.model.CCCampaignCatKeys[i].catKeyList;
                }
            }
            var deDupe = this.campaignModel.model.CCCampaignCatKeys.filter((currentValue, index, array) => {
                return array.map(function (element) {
                    return element.CategoryId;
                }).indexOf(currentValue.CategoryId) == index;
            });
            this.campaignModel.model.CCCampaignCatKeys = deDupe;

            this.campaignModel.model.CCCampaignCatKeys.forEach((currentValue, index, array) => {
                var temp = {
                    categoryId: currentValue.CategoryId,
                    categoryName: currentValue.CategoryName
                }
                if (!this.selected_categories) this.selected_categories = [];

                var shortBlank = this.selected_categories.map(function (value) {
                    return value.categoryId;
                });
                if (shortBlank && (shortBlank.indexOf(temp.categoryId) < 0)) { //add categories to ui-select
                    this.selected_categories.push(temp);
                    this.categoryAdd(temp);
                }


            })
        }
    }

    categoryAdd(item) {
        if (!this.campaignModel.model.CCCampaignCatKeys) {
            this.campaignModel.model.CCCampaignCatKeys = [];
        }

        if (this.campaignModel.model.CCCampaignCatKeys.length <= 4) {   //allow maximum of 4 categories to be added
            if (this.CamCat.Categories.indexOf(item.categoryName) < 0) { //If a cetgory is already not selected only then add it
                this.CamCat.Categories.push(item.categoryName);
            }
            this.getCatKeys(item.categoryName, item.categoryId);
        }
    }

    categoryRemove(item) {
        this.atLeastOne = (this.selectedCategory.length === 0) ? false : true
        this.Catkeys = (this.selectedCategory.length === 0) ? true : false
        let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
        let position = modelData.CCCampaignCatKeys.map(function (element, index, array) {
            return element.CategoryId;
        }).indexOf(item.categoryId);

        modelData.CCCampaignCatKeys.splice(position, 1);
        if (this.CamCat.model[item.categoryName])
            delete this.CamCat.model[item.categoryName];
        if (this.catKeys && this.catKeys[item.categoryName])
            delete this.catKeys[item.categoryName];
        this.CamCat.Categories.splice(this.CamCat.Categories.indexOf(item.categoryName), 1);

        for (var key in this.catKeys) {
            if (key = item.categoryName) {
                delete this.catKeys[key];
            }
        }
        let x = JSON.parse(JSON.stringify(this.campaignModel))
        x.model = modelData
        this.campaignStore.update(state => {
            return {
                campaign: x,
                updatedFrom: '[CamcatComponent] categoryRemove'
            }
        })
    }

}
