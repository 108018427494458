import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Quickbpclass, Quickbp } from 'app/interface/quickbp';
//import { Quickbp, Quickbpclass } from '../../interface/quickbp';

@Injectable({
  providedIn: 'root'
})
export class DatasharingService {
  quickbp: Quickbpclass = new Quickbpclass;

  private quickBPSubject: BehaviorSubject<Quickbp> = new BehaviorSubject<Quickbp>(this.quickbp)

  constructor() { }

  setBehaviorView(quickBPBehave: Quickbp) {
    this.quickBPSubject.next(quickBPBehave)
  }

  getBehaviorView(): Observable<Quickbp> {
    return this.quickBPSubject.asObservable();
  }

}
