import { Injectable, OnInit } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';


import { Observable, forkJoin } from 'rxjs';
import { RequiredfieldcountService } from '../requiredfieldcount/requiredfieldcount.service';
import { StorageService } from '../storage/storage.service';
import { UpdateIndicatorService } from '../updateIndicato/update-indicator.service';


@Injectable({
    providedIn: 'root'
})
export class SEMPHoursService implements OnInit {
    sharedData;
    subscription: any;
    orderBPSubscription;
    indicator;
    ConfirmHoursOfOperationField;
    countSecondTile = 0;
    constructor(private StorageService: StorageService,
        private requiredFieldCount: RequiredfieldcountService,
        private indicatorQuery: IndicatorQuery,
        private indicatorStore: IndicatorStore,
        private updateIndicatorService: UpdateIndicatorService) {
    }
    ngOnInit() {
    }


    async setHOOConfirmationsInIndicator(Indicator) {

        this.makeAllSEMPHOOpRequired(Indicator);
        var profileHelpers = [];
        for (let profileId in Indicator.profiles) {
            if (profileId != "") {
                //('before required count in SEMP'+profileId)
                let result = await this.StorageService._get("profileHelper", profileId)
                if (result[profileId] != undefined) {
                    if (!result[profileId].hasOwnProperty("ConfirmHoursOfOperation")) {
                        result[profileId].ConfirmHoursOfOperation = "";
                    }
                    Indicator = JSON.parse(JSON.stringify(Indicator))
                    Indicator['profiles'][profileId].model.ConfirmHoursOfOperation = result[profileId].ConfirmHoursOfOperation;
                    //console.log('form added before required count'+JSON.stringify(Indicator.profiles[profileId].form[1][11])+'model is'+Indicator['profiles'][profileId].model.ConfirmHoursOfOperation)
                    var count = this.requiredFieldCount.get(Indicator['profiles'][profileId].schema[1], Indicator['profiles'][profileId].form[1], Indicator['profiles'][profileId].model);
                    Indicator['profiles'][profileId].menu[0][1].counter = count;
                    Indicator['profiles'][profileId].menuOrig[1].counter = count;
                    this.countSecondTile = this.requiredFieldCount.get(Indicator['profiles'][profileId].schema[1], Indicator['profiles'][profileId].form[1], Indicator['profiles'][profileId].model);
                    Indicator['profiles'][profileId].menuOrig[1].counter = this.countSecondTile;
                    //Get the current count and set it in right place
                    //let x = JSON.parse(JSON.stringify(Indicator));
                    // x = Indicator;
                    //console.log('what should come'+JSON.stringify(x.profiles))
                    this.indicatorStore.update(state => {
                        return {
                            indicator: JSON.parse(JSON.stringify(Indicator)),
                            updatedFrom: "[SEMPHoursService] setHOOConfirmationsInIndicator",
                            isloaded: true
                        }
                    });

                }



            }
        }
        return Indicator;
    }


    /**
     * @returns - A promise that resolves in an array of profileHelpers
     */
    getAllSEMPHOOConfirmations(Indicator) {

        var profileHelpers = [];
        var profileHelpArray = [];
        for (let profileId in Indicator.profiles) {
            if (profileId) {
                profileHelpers.push(this.StorageService._get("profileHelper", profileId).then(res => { console.log("priyanks", res) }));
            }
        }


        return forkJoin(profileHelpArray);

    }

    makeAllSEMPHOOpRequired(Indicator) {
        for (let profileId in Indicator.profiles) {
            if (Indicator.profiles[profileId]) {
                this.makeSEMPHourfOfOperationRequired(Indicator, profileId)
            }
        }
    }

    /**
     * Purpose of this method is to make Hours of operation field a required field on the BP if it associated with an SEMP item.
     */
    async makeSEMPHourfOfOperationRequired(Indicator, profileId) {
        let orderItems = [];
        let associatedToSEMP = false;
        orderItems = Indicator.order ? Indicator.order.OrderItems : [];

        if (!(orderItems && profileId)) return; //Tata bye bye! if no profileId or items

        //to make hours of operation required if that profile is associated with SEM Premium
        for (let c = 0; c < orderItems.length; c++) {
            //console.log('item id'+orderItems[c].svItemId)

            if (orderItems[c].productType === "Digital" && orderItems[c].actionCode !== "Cancel" && (orderItems[c].productCode === "SEMP" || orderItems[c].productCode === 'SuperClick')) {
                let listingId;
                let itemData = {};
                if (Indicator.items[orderItems[c].svItemId])
                    itemData = Indicator.items[orderItems[c].svItemId].model;
                    if (orderItems[c].actionCode === "Renew") {
                        listingId = itemData['BusinessProfile'];
                    } else {
                    if (itemData !== undefined && itemData.hasOwnProperty("BusinessProfile") && itemData['BusinessProfile'] != undefined && itemData['BusinessProfile'] != null && itemData['BusinessProfile'] != "") {
                        listingId = itemData['BusinessProfile'];
                    } else {
                        listingId = itemData['listingId'];
                    }
                }
                //console.log("listingId"+listingId+'profileId'+profileId)
                //If the Item itself has its BP set to this profile
                if ((orderItems[c].productCode === "SEMP" || orderItems[c].productCode === 'SuperClick') && listingId === profileId) {
                    associatedToSEMP = true;
                }
               //console.log('associatedToSEMP'+associatedToSEMP)
            }
            if(associatedToSEMP == true){
                break;
            }
        }

        if (associatedToSEMP) {
            //Indicator = JSON.parse(JSON.stringify(Indicator));
            //Make HoursOfOperation field mandataory && mark its Red indicator
            Indicator.profiles[profileId].schema[1].properties.HoursOfOperation.required = true;
            if (Indicator.profiles[profileId].form[1][11].key === "HoursOfOperation") {
                Indicator.profiles[profileId].form[1][11].htmlClass = " requiredField";
            }
            //Now create ConfirmHoursOfOperation field if it does not already exist
            if (!Indicator.profiles[profileId].schema[1].properties["ConfirmHoursOfOperation"]) {
                console.log('CHO not found in schema')
                //schema
                Indicator.profiles[profileId].schema[1].properties["ConfirmHoursOfOperation"] = {
                    readonly: false,
                    required: true,
                    title: "Confirm Hours Of Operation",
                    //description : profileAssociations + "<br>",
                    type: "string",
                    enum: ["true"]
                }

                //form
                this.ConfirmHoursOfOperationField = {
                    fieldHtmlClass: "col-xs-9",
                    htmlClass: " requiredField",
                    key: "ConfirmHoursOfOperation",
                    labelHtmlClass: "col-xs-3",
                    sortOrder: 11,
                    tibcoRequest: "",
                    tibcoResponse: "confirmHoursOfOperation",
                    type: "radiobuttons",
                    style: {
                        selected: "btn-success",
                        unselected: "btn-danger confirm-hours-delete"
                    },
                    titleMap: [
                        { value: "true", name: "I confirm the selected Hours of Operation" },
                        //{ value: "false", name: "I am yet to confirm the selected HOOp" }
                    ],
                    onChange: function (modelValue, form) {
                        let profileHelper = {
                            ConfirmHoursOfOperation: modelValue
                        }

                        //console.log("Value of confirm Hours of Opeartions is : ", modelValue);
                        this.StorageService.update("profileHelper", profileId, profileHelper).then(() => { });
                    }
                }
                Indicator.profiles[profileId].form[1].splice(11, 0, this.ConfirmHoursOfOperationField);
                console.log('form added'+JSON.stringify(Indicator.profiles[profileId].form[1][11]))
                //Indicator = JSON.parse(JSON.stringify(Indicator));
                let res = await this.StorageService._get("profileHelper", profileId)
                if (res[profileId].ConfirmHoursOfOperation == true) {
                    Indicator.profiles[profileId].model.ConfirmHoursOfOperation = true;
                    Indicator.profiles[profileId].form[1][11].style = "btn-success";
                }


                // console.log("Adding field to profile - " +  profileId)
            } else {
                console.log('CHO found in schema')
                /*this.StorageService._get("profileHelper", profileId).then((res) => {
                    //console.log('res' + res + 'stringify' + JSON.stringify(res) + 'profileId' + profileId)
                });*/
            }
        } else {//If profile is not associated with an SEMP then remove Confirm field if it exists
            //Indicator = JSON.parse(JSON.stringify(Indicator));
            if (Indicator.profiles[profileId].schema[1].properties.hasOwnProperty('HoursOfOperation'))
                Indicator.profiles[profileId].schema[1].properties.HoursOfOperation.required = false;
            if (Indicator.profiles[profileId].schema[1].properties["ConfirmHoursOfOperation"]) {
                //Remove from schema
                delete Indicator.profiles[profileId].schema[1].properties["ConfirmHoursOfOperation"];

                //Remove from Form
                Indicator.profiles[profileId].form[1].splice(11, 1);
            }
        }
    }

    /**
     * Verify if any of the campaigns of the item are associated to the said profile
     * @return - Array of capaigns associated to the profile
     */
    itemCampaignsAssociatedToProfile(campaignType, profileId) {
        //to make hours of operation required if that profile is associated with local campaign
        let campaignsAssociatedToProfile = [];
        if (campaignType != undefined && campaignType != null) {
            let localCampArray = campaignType;
            for (let i = 0; i < localCampArray.length; i++) {
                if (localCampArray[i].CampaignType && localCampArray[i].CampaignType.toLowerCase() === "local" && localCampArray[i].ListingId === profileId) {
                    //fix this in Angular8 phase2 as we do not have order information now//campaignsAssociatedToProfile.push(orderItem.order.description + " - Local Campaign - " + orderItem.order.adBudget + " - " + localCampArray[i].CampaignDescription)
                    campaignsAssociatedToProfile.push(" - Local Campaign - " + localCampArray[i].CampaignDescription)
                }
            }

        }

        return campaignsAssociatedToProfile;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
