import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class TradenameService {

  constructor(private http : HttpClient,
    private API :ApiService,
    private Params: ParamsService) { }

  baseUri: string = '/api/tradename/search/';
  headersObject : object = {
    headers :new HttpHeaders({"authorization" :"bearer " + this.Params.params.token}),
  }

  getTradeNames(searchword: string): Observable<any>{
    return this.http.get(this.API.get(this.baseUri) + searchword, this.headersObject);
  }
}
