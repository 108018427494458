import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

constructor(private http:HttpClient,private API: ApiService) { }
baseUri:string = '/api/geo/';
getByZip(type :any,zip :any) : Observable<any>{
    return this.http.get(this.API.get(this.baseUri)+type+"/"+zip);
}
getById(id :any) :Observable<any>{
    return this.http.get(this.API.get(this.baseUri)+id);
}
getByGeoId(geoId :any) :Observable<any>{
    return this.http.get(this.API.get(this.baseUri)+geoId);
}
getStateAndMetro(type :any) : Observable<any>{
    return this.http.get(this.API.get(this.baseUri)+"type/"+type);
}
}
