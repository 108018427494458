import { Component, OnInit } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-displaycheckbox',
  templateUrl: './displaycheckbox.component.html',
  styleUrls: ['./displaycheckbox.component.scss']
})
export class DisplaycheckboxComponent implements OnInit {

  flag: string = '';
  sharedData: Quickbpclass;
  options: any;
  titleContent: any;
  bpNonPublishEmail:boolean = false;
  constructor(private _storage: StorageService,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    if(this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['Non-PublishEmail']['title'])
    this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['Non-PublishEmail']['title'];

    // this.bpNonPublishEmail = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['title'] = 'Business Info'? true : false;
    this.bpNonPublishEmail = (this.sharedData['profiles'] && this.sharedData['profiles'][this.sharedData.stateParamsId] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'] && this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['title'] ) &&
     (this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['title'] = "Business Info") ? true : false;
    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["Non-PublishEmail"] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model["Non-PublishEmail"]) {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['Non-PublishEmail']['title'];
      this.flag = (this.sharedData['profiles'][this.sharedData.stateParamsId].model["Non-PublishEmail"]) ? 'Y' : 'N'
    }
  }

  nonPubEmail(event) {
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    //this.sharedData['profiles'][this.sharedData.stateParamsId].model["Non-PublishEmail"] = event.target.checked;
    modelData["Non-PublishEmail"] = event.target.checked;
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;


    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[DisplaycheckboxComponent] nonPubEmail"
      }
    })
  }

}
