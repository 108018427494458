import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CallnewComponent } from './callnew/callnew.component';
import { Observable } from 'rxjs';
import { IndicatorQuery } from 'app/state/query';
import { CalltrackingService } from '@app/service/call/calltracking.service';
import { IndicatorStore } from 'app/state/store';
import { LoggerService } from '@app/service/logger/logger.service';
import { StorageService } from '@app/service/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CallserviceService {
  indicatorValue:any;

  constructor(private matDialog: MatDialog,
    private indicatorQuery: IndicatorQuery,
    private calltracking:CalltrackingService,
    private indicatorStore: IndicatorStore,
    private storageService: StorageService,
    private logger: LoggerService) { }
  openmodel(data):Observable<any>{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'call-tracking-dialog';
    dialogConfig.data = {
    dialogform : data
    }
    const dialogRef = this.matDialog.open(CallnewComponent,dialogConfig); //, { disableClose: true }
    return dialogRef.afterClosed();

  }
  async callTrackingremove(){
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicatorValue = {...data};
      });
      let upadatedName = JSON.parse(JSON.stringify(this.indicatorValue));
      let callTrackingArr =upadatedName.items[upadatedName.stateParamsId].calltracking;
        for (let i = 0; callTrackingArr.length > i; i++) {
            let data = this.buildTerminateCTRequest(callTrackingArr[i]);
              let res = await this.calltracking.terminate(data)
                if(res.hasWarningOrError=="false"){
                  upadatedName = JSON.parse(JSON.stringify(upadatedName));
                  let tempIndicator = this.indicatorStore.getValue().indicator['orderPercent'];
                  let counter = this.indicatorStore.getValue().indicator['items'][upadatedName.stateParamsId].counter;
                  upadatedName['orderPercent'] = tempIndicator;
                  upadatedName.items[upadatedName.stateParamsId].counter = counter;
                  upadatedName.items[upadatedName.stateParamsId].calltracking = [];
                  upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
                  if(upadatedName.items[upadatedName.stateParamsId].order.productCode === 'YPC' || upadatedName.items[upadatedName.stateParamsId].order.productCode === 'Single IYP'){
                    upadatedName.items[upadatedName.stateParamsId].model.CallTracking = {"OptIntoCallTracking":false};
                  }else{
                    upadatedName.items[upadatedName.stateParamsId].model.CallTracking = [];
                  }
                  upadatedName.items[upadatedName.stateParamsId].model['modelValue'] = 'Yes';
                  if(upadatedName.items[upadatedName.stateParamsId].hasOwnProperty("hasCopysheet") ) {
                    upadatedName["items"][upadatedName.stateParamsId]['hasCopysheet'] = false;
                  }
                  if(upadatedName["items"][upadatedName.stateParamsId].order.actionCode == 'Renew') {
                    if(upadatedName.items[upadatedName.stateParamsId].hasOwnProperty("hasCopysheet") ) {
                      upadatedName.items[upadatedName.stateParamsId].model['renewHasCopysheet'] = upadatedName["items"][upadatedName.stateParamsId]['hasCopysheet'];
                    }
                    upadatedName.items[upadatedName.stateParamsId].model['dirtyFlag'] = true;
                  }
                  let uniqItemId = upadatedName.stateParamsId + "v" + upadatedName.items[upadatedName.stateParamsId].order.version;
                  this.storageService.update(
                    "item",
                    uniqItemId,
                    upadatedName.items[upadatedName.stateParamsId].model
                  );
                  this.indicatorStore.update((state) => {
                    return {
                    indicator:upadatedName,
                    updatedFrom: "[CallserviceService] callTrackingremove",
                    isloaded: true,
                    };
                    });
                }
        }



        //this.loadDigitalData()

  }
  buildTerminateCTRequest(callTrackingArr) {
    var data = {
        "callTrackingId": callTrackingArr.callTrackingId,
        "terminationNumber": callTrackingArr.terminationNumber,
        "callRecordEnabled": callTrackingArr.callRecordEnabled,
        "adAlertEnabled": callTrackingArr.adAlertEnabled,

    };
    return data;
 };
 buildUpdateCTSRequest(callTrackingId){
 this.indicatorQuery.getIndicator().subscribe(data => {
    this.indicatorValue = {...data};
  });
  let data = {
    "callTrackingId": callTrackingId,
    "itemDetails": {
        "itemDetail": [{
            "enterpriseItemId": this.indicatorValue.items[this.indicatorValue.stateParamsId].order.svItemId,
            "issueNumber": this.indicatorValue.items[this.indicatorValue.stateParamsId].order.directoryIssueNumber,
            "action": "remove"
        }]
    }
 }
 return data;

 }
editngModel(flag, ctNumber){
  this.indicatorQuery.getIndicator().subscribe(data => {
    this.indicatorValue = {...data};
  });
  let upadatedName = JSON.parse(JSON.stringify(this.indicatorValue));
  if (flag) {
    //Add checked trackingNumber into ngModel
    let modelvalue = upadatedName.items[upadatedName.stateParamsId].model.CallTracking;
    if (modelvalue != "" && modelvalue !== null && typeof modelvalue != 'object') {
        var trackingNum = modelvalue.split(',');
        if (trackingNum.indexOf(ctNumber) <= 0) {
            trackingNum.push(ctNumber);
        }
        upadatedName.items[upadatedName.stateParamsId].model.CallTracking = trackingNum.join(',');
    } else {
      upadatedName.items[upadatedName.stateParamsId].model.CallTracking = ctNumber;
    }
} else {
    //Remove unchecked trackingNumber from ngModel
    var modelvalue = upadatedName.items[upadatedName.stateParamsId].model.CallTracking;
    if (modelvalue != "" && modelvalue !== null && typeof modelvalue != 'object') {
        var trackingNum = modelvalue.split(',');
        trackingNum.splice(trackingNum.indexOf(ctNumber), 1);
        upadatedName.items[upadatedName.stateParamsId].model.CallTracking = trackingNum.join(',');
    } else {
      upadatedName.items[upadatedName.stateParamsId].model.CallTracking = ctNumber;
    }
}
this.indicatorStore.update((state) => {
  return {
    indicator:upadatedName,
    updatedFrom: "[CallserviceService] editngModel",
    isloaded: true,
  };
  });
}
copysheetnotify(){
  this.indicatorQuery.getIndicator().subscribe(data => {
    this.indicatorValue = {...data};
  });
  let upadatedName = JSON.parse(JSON.stringify(this.indicatorValue));
  upadatedName.items[this.indicatorValue.stateParamsId].hasCopysheet = false;
    if(upadatedName.items[this.indicatorValue.stateParamsId].order.productCode == 'YPC' ||  this.indicatorValue.items[this.indicatorValue.stateParamsId].order.productType == "Print"){
        if(upadatedName.items[this.indicatorValue.stateParamsId].order.productCode == 'YPC'){
          upadatedName.items[this.indicatorValue.stateParamsId].model.ManageCopysheet = 'Y';
        }else{
          upadatedName.items[this.indicatorValue.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
        }
        upadatedName.items[this.indicatorValue.stateParamsId].requiresCopysheet =  true;
        /* if (upadatedName.items[this.indicatorValue.stateParamsId].requiresCopysheet) {
          upadatedName.items[this.indicatorValue.stateParamsId].counter++;
        } */
    }

  this.indicatorStore.update((state) => {
    return {
      indicator:upadatedName,
      updatedFrom: "[CallserviceService] copysheetnotify",
      isloaded: true,
    };
  });

}

callTrackingRemovePrint(){
  this.indicatorQuery.getIndicator().subscribe(data => {
    this.indicatorValue = {...data};
  });
  let upadatedName = JSON.parse(JSON.stringify(this.indicatorValue));
  let callTrackingArr = upadatedName.items[upadatedName.stateParamsId].calltracking;
  for (let i = 0; callTrackingArr.length > i; i++) {
        let data = this.buildUpdateCTSRequest(callTrackingArr[i].callTrackingId);
        //Remove unchecked trackingNumber from ngModel
        //this.editngModel(false, callTrackingArr[i].trackingNumber);
        callTrackingArr[i].ctIdEnabled = 'N';
        this.calltracking.updateCTS(data).then(res=> {
            if(res.hasWarningOrError === "true"){
                var errorObject =res.esbServiceFault;
                var errorCode = errorObject.sourceSystem;
                var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                var errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);
            }
        });
        upadatedName = JSON.parse(JSON.stringify(upadatedName));
        upadatedName.items[this.indicatorValue.stateParamsId].calltracking = [];
        upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
        upadatedName.items[this.indicatorValue.stateParamsId].model.CallTracking = "";
        let tempIndicator = this.indicatorStore.getValue().indicator['orderPercent'];
        let counter = this.indicatorStore.getValue().indicator['items'][upadatedName.stateParamsId].counter;
        upadatedName['orderPercent'] = tempIndicator;
        upadatedName.items[upadatedName.stateParamsId].counter = counter;
        upadatedName.items[upadatedName.stateParamsId].model['modelValue'] = 'Yes';
        if(upadatedName.items[upadatedName.stateParamsId].hasOwnProperty("hasCopysheet") ) {
          upadatedName["items"][upadatedName.stateParamsId]['hasCopysheet'] = false;
        }
        if(upadatedName["items"][upadatedName.stateParamsId].order.actionCode == 'Renew') {
          if(upadatedName.items[upadatedName.stateParamsId].hasOwnProperty("hasCopysheet") ) {
            upadatedName.items[upadatedName.stateParamsId].model['renewHasCopysheet'] = upadatedName["items"][upadatedName.stateParamsId]['hasCopysheet'];
          }
          upadatedName.items[upadatedName.stateParamsId].model['dirtyFlag'] = true;
        }
        let uniqItemId = upadatedName.stateParamsId + "v" + upadatedName.items[upadatedName.stateParamsId].order.version;
        this.storageService.update(
          "item",
          uniqItemId,
          upadatedName.items[upadatedName.stateParamsId].model
        );
        // Indicator.items[$stateParams.id].model.CallTracking = vm.TrackNumberArray ;
        this.indicatorStore.setLoading(true);
        this.indicatorStore.update((state) => {
        return {
          indicator:upadatedName,
          updatedFrom: "[CallserviceService] callTrackingRemovePrint",
          isloaded: true,
        };
        });
        this.indicatorStore.setLoading(false);

}
}


}
