
import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface ComponentState{
    componentValue: string;
    semAccount: string;
}

export const initialState = () =>{
return {
    componentValue: null,
    semAccount: null
};
};

@Injectable({
    providedIn : 'root'
})
@StoreConfig({ name: 'comunication' })
export class ComponentStore extends Store<ComponentState> {
  constructor() {
    super(initialState());
  }
}