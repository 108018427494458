import { Query } from '@datorama/akita';
import { indicatorState, IndicatorStore } from './store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Quickbp } from '../interface/quickbp'; // koushik
import { take } from 'rxjs/operators';

@Injectable({
    providedIn : 'root'
})
export class IndicatorQuery extends Query<indicatorState>{

    constructor(protected store: IndicatorStore) {
        super(store);
      }

      getIndicator(): Observable<Quickbp>{
          return this.select( state => state.indicator).pipe(take(1));
      }

      getLoaded(): Observable<boolean>{
          return this.select(state =>state.isloaded);
      }

      getIndicatorT(): Observable<Quickbp>{
        return this.select( state => state.indicator);
    }

      getIsLoading(): Observable<boolean>{
          return this.selectLoading();
      }

    updateModel(name, modelValue, modelData, shareData, updatedFrom) {
        shareData = JSON.parse(JSON.stringify(shareData))
        modelData[name] = modelValue;
        shareData.items[shareData.stateParamsId].model = modelData;
        this.store.update(state => {
            return {
                indicator: shareData,
                updatedFrom: updatedFrom,
                isloaded: true
            }
        })
    }
    updateBPModel(name, modelValue, modelData, shareData, updatedFrom) {
        shareData = JSON.parse(JSON.stringify(shareData))
        modelData[name] = modelValue;
        shareData.profiles[shareData.stateParamsId].model = modelData;
        this.store.update(state => {
            return {
                indicator: shareData,
                updatedFrom: updatedFrom,
                isloaded: true
            }
        })
    }
    updateProfiles(key, listingIds, shareData, updatedFrom) {//method to add new profile to profiles and listingIds
        shareData = JSON.parse(JSON.stringify(shareData))
        shareData.listingIds = listingIds;
        this.store.update(state => {
            return {
                indicator: shareData,
                updatedFrom: updatedFrom,
                isloaded: true
            }
        })
    }
    updateProfilesListingId(shareData, updatedFrom) {//method to add new profile to profiles and listingIds
        shareData = JSON.parse(JSON.stringify(shareData))
        this.store.update(state => {
            return {
                indicator: shareData,
                updatedFrom: updatedFrom,
                isloaded: true
            }
        })
    }

}
