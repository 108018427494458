import { Component, OnInit, Input } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';


@Component({
  selector: 'app-displayaddress',
  templateUrl: './displayaddress.component.html',
  styleUrls: ['./displayaddress.component.scss']
})
export class DisplayaddressComponent implements OnInit {

  sharedData: Quickbpclass = new Quickbpclass;
  selectedAddress: string = '';
  // @Input() layoutNode: any;
  // @Input() layoutIndex: number[];
  // @Input() dataIndex: number[];
  options: any;
  titleMaps = [];
  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.options = this['layoutNode'].options || {};
    this.titleMaps = this.options.titleMap;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessAddressDisplayOptions"] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions != undefined &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions != '')
      this.selectedAddress = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions;
    else {
      this.selectedAddress = 'ALL';
      this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions = 'ALL'
    }
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[DisplayaddressComponent] ngOnInit"
      }
    })
  }

  sendValue(args: any) {
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    modelData["BusinessAddressDisplayOptions"] = args;
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;

    //this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessAddressDisplayOptions = args;
    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[DisplayaddressComponent] sendValue"
      }
    })
  }
}
