import { Component, OnInit, ChangeDetectorRef, ViewRef} from '@angular/core';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { StorageService } from '@app/service/storage/storage.service';
import { CallnewComponent } from '../callnew/callnew.component';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '@app/service/utils/utils.service';


@Component({
  selector: 'app-call-phonenumber',
  templateUrl: './call-phonenumber.component.html',
  styleUrls: ['./call-phonenumber.component.scss']
})
export class CallPhonenumberComponent implements OnInit {
  sharedData: any;
  options: any;
  titleContent: string;
  customField: any;
  errorFlag: boolean;
  productCode: any;
  storageID: string;
  existingDest:boolean = false;

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private storageservice :StorageService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CallnewComponent>,
    private utils: UtilsService) { }

  validateExistingTerminationNumber(callTrackingArray, newNumber){
    let numberExists = false
    if(callTrackingArray && callTrackingArray!=undefined && callTrackingArray!=null && callTrackingArray.length > 0){
      callTrackingArray.forEach((trackingObj, ind) => {
        if(trackingObj.terminationNumber == newNumber){
          numberExists = true
          return numberExists
        }
      })
    }
    return numberExists
  }  

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
      this.options = this['layoutNode'].options;
      this.productCode = this.sharedData.items[this.sharedData.stateParamsId].order.productCode;
      this.storageID = this.sharedData.stateParamsId + "v" + this.sharedData.items[this.sharedData.stateParamsId].order.version;

      if(this['layoutNode'].name === "destinationnumber"){
        this.titleContent = "Destination Number"
        if (this.productCode=== 'SEM' || this.productCode === 'SuperClick' || this.productCode === 'SEMP' || this.productCode === 'SEM Kicker') {
          this.storageservice.getField("call", this.storageID).then(data => {
            if (data[this.storageID] && Object.keys(data[this.storageID]).length != 0) {
              let currentValue = this.sharedData.account.phone.replace(/\D/g, "");
              if(data[this.storageID].callTracking && this.validateExistingTerminationNumber(data[this.storageID].callTracking, currentValue)){
                 this.existingDest = true;
                 this.ngAfterViewChecked();
                 this.saveDisable(true);
              }else{
                this.existingDest = false;
                this.ngAfterViewChecked();
                this.saveDisable(false);
              }
            }

          })
        }

        this.customField = this.sharedData.account.phone || this.utils.formatPhoneNumber(this.sharedData.items[this.sharedData.stateParamsId].order.listingTn);


      }
      this.sharedData.account.phone = this.customField
    });
    this.indicatorStore.update((state) => {
      return {
        indicator: this.sharedData,
        updatedFrom: "[CallPhonenumberComponent] ngOnInit",
        isloaded: true,
      };
    });
  }
  private saveDisable(isEnable){
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.OnEnable(isEnable);
    }
  }
  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }

  sendValue(args){
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
    this.customField = this.customField.replace(/[^0-9]/g, '')
      if (this.customField.length >= 1) {
        this.customField = ("(").concat(this.customField.slice(0))
      }
      if (this.customField.length >= 5) {
        this.customField = this.customField.slice(0, 4).concat(") ", this.customField.slice(4))
      }
      if (this.customField.length >= 10) {
        this.customField = this.customField.slice(0, 9).concat("-", this.customField.slice(9))
      }
    if(this['layoutNode'].name === "destinationnumber" ){
      if (args && args.length == 14) {
        this.errorFlag = false
        if (this.productCode=== 'SEM' || this.productCode === 'SuperClick' || this.productCode === 'SEMP' || this.productCode === 'SEM Kicker') {
          this.storageservice.getField("call", this.storageID).then(data => {
            if (data[this.storageID] !== undefined && Object.keys(data[this.storageID]).length != 0) {
              let currentValue = args.replace(/\D/g, "");
              if(data[this.storageID].callTracking && this.validateExistingTerminationNumber(data[this.storageID].callTracking, currentValue)){
                 this.existingDest = true;
                 this.ngAfterViewChecked();
                 this.saveDisable(true);
              }else{
                this.existingDest = false;
                this.ngAfterViewChecked();
                this.saveDisable(false);
              }
            }

          })
        }
        upadatedName.account.phone = args;
      }
      else {
        this.errorFlag = true;
        upadatedName.account.phone = "";
      }
    }
    this.indicatorStore.update((state) => {
      return {
        indicator:upadatedName,
        updatedFrom: "[CallPhonenumberComponent] sendValue",
        isloaded: true,
      };
    });
  }

}
