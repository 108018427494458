import { Injectable } from '@angular/core';
import { StorageService } from "../storage/storage.service";
//import { _ } from "underscore"
@Injectable({
  providedIn: 'root'
})
export class TransformerService {
  indicatorValue:any;

  constructor(private StorageService: StorageService) { }
  /**
    * getFromValue - takes in an request object and the object path to get the value
    * @param  {Object} obj      - the object from the request
    * @param  {String} getPaths - the string for the object path
    * @return {String}          - the value from the object path of the request
    */
  getFromValue(obj: any, getPaths: any): any {
    var getPaths = getPaths.split('.');
    // Iterate through each path separated by a '.' and traverse the tree
    for (var i = 0; i < getPaths.length - 1; i++) {
      // Check to see if the next value in the path is an array - if it is then parse for the value
      if (getPaths[i].indexOf("[") >= 0) { // listings[0]
        var open = getPaths[i].indexOf("[");
        var index = parseInt(getPaths[i].substring(open + 1, getPaths[i].indexOf("]")));
        var subPath = getPaths[i].substring(0, open);
        if (obj[subPath] == null) {
          return "";
        }
        obj = obj[subPath][index];
      } else {
        obj = obj[getPaths[i]];
      }
      if (obj == null) {
        return "";
      }
    }
    return obj[getPaths[i]];
  }

  /**
 * setToValue - sets a value to a previous received object
 * @param {Object} obj      - the previously received object from an api
 * @param {Object} value    - the value to set to that previously received object
 * @param {String} getPaths - the string for the object path of the set value
 */
  setToValue(obj: any, value: any, getPaths: any) {
    getPaths = getPaths.split('.');
    var i = 0;
    // Iterate through each path separated by a '.' and traverse the tree
    for (; i < getPaths.length - 1; i++) {
      // Check to see if the next value in the path is an array - if it is then parse for the value
      if (getPaths[i].indexOf("[") >= 0) {
        var open = getPaths[i].indexOf("[");
        var index = parseInt(getPaths[i].substring(open + 1, getPaths[i].indexOf("]")));
        var subPath = getPaths[i].substring(0, open);

        if (obj == null) {
          obj = {};
        }

        if (!obj.hasOwnProperty(subPath) || !(obj[subPath] instanceof Array)) {
          obj[subPath] = [{}];
        }
        obj = obj[subPath][index];
      } else {

        if (obj == null) {
          obj = {};
        }

        obj = (!obj.hasOwnProperty(getPaths[i]) || obj[getPaths[i]] == null) ? obj[getPaths[i]] = {} : obj[getPaths[i]];
      }
    }


    //Make object writable so that we do not get errors in some cases
    if (typeof obj === "object") {
      Object.defineProperty(obj, getPaths[i], {
        enumerable: true,
        configurable: true,
        writable: true,
        value: null
      });
    }
    obj[getPaths[i]] = (value != null) ? value : "";
  };
  /**
   * getMapping - get all the mappings for the previously received values in tibco
   * @param  {Object} data  - the data received from the tibco request
   * @param  {Object} form  - the form from the angular schema form
   * @param  {Object} model - the model object that is hooked into the form
   * @return {Object}       - return the previously established data object (has almost no use)
   */
  getMapping(data: object, form: any, model: object): any {
    var mapping = data;
    var shouldNotGetCounter = 0;
    for (var i = 0; i < form.length; i++) {
      if (form[i].tibcoRequest !== undefined) {
        var paths = form[i].tibcoRequest.split(",");
        var values = {};
        if (paths.length > 1) {
          for (var x = 0; x < paths.length; x++) {
            var attribute = paths[x].substring(paths[x].lastIndexOf('.') + 1, paths[x].length);
            values[attribute] = this.getFromValue(mapping, paths[x]);
          }
          model[form[i].key] = values;
        } else {
          values = this.getFromValue(mapping, paths[0]);
          model[form[i].key] = values;
        }


      } else if (form[i].key == 'ListingId' && !(model[form[i].key])) {
        model[form[i].key] = null;
      } else {
        shouldNotGetCounter++;
      }
    }

    return shouldNotGetCounter < form.length;
  };
/**
 * ctMapping - adds call tracking to the model
 * @param {Object} uniqueCTs    - the array of unique call tracking values
 * @param {Object} orderItem    - the order item data
 * @param {String} model        - the item model thus far
 */
  async ctMapping(uniqueCTs, orderItem, model) {
    model = JSON.parse(JSON.stringify(model));
    let storageID = orderItem.svItemId + "v" + orderItem.version;
    //check to see if NO(from CT dropdown) was selected
    await this.StorageService.getField("call", storageID).then(data => {
      //if (data[storageID] !== undefined && data[storageID].optout === true) {
      if (data[storageID] !== undefined && data[storageID].selectedOption === "No") {
        model["CallTracking"] = { "OptIntoCallTracking": false };
        
      } else {
        //if not opt-out, then map any CTs on the item
        var length = uniqueCTs.length;
        var tibcoCTarray = [];
        var tibcoCTstring = "";
        var destinationNums = [];
        var callTrackingEnabled = false;

        for (var i = 0; i < length; i++) {
          var terminationNumber = uniqueCTs[i].terminationNumber;
          var trackingNumber = uniqueCTs[i].trackingNumber;
          var callTrackingId = uniqueCTs[i].callTrackingId;
          //var callRecordEnabled = uniqueCTs[i].callRecordEnabled;
          //var adAlertEnabled = uniqueCTs[i].adAlertEnabled;
          //sonar fixing
          var OptIntoCallTracking = uniqueCTs[i].OptIntoCallTracking == undefined ? true : uniqueCTs[i].OptIntoCallTracking;

          destinationNums.push(terminationNumber);

          //if true 'Call Record' check-box appears on publish page
          if (uniqueCTs[i].callRecordEnabled === 'Y' || uniqueCTs[i].callRecordEnabled === 'true') {
            callTrackingEnabled = true;
          }

          if (uniqueCTs[i].distributionType !== null) {
            var source = uniqueCTs[i].distributionType;
          } else {
            source = null
          }

          //CT info for Tibco update/create requests, format depends on item type
          if (orderItem.productCode === 'Premium IYP' || orderItem.productCode === 'Single IYP' || orderItem.productCode === 'YPC') {
            var cTbody :any = {
              "TrackingId": callTrackingId,
              "Source": source,
              "OptIntoCallTracking": OptIntoCallTracking
            };
          } else if (orderItem.productCode === 'SEM' || orderItem.productCode === 'SuperClick' || orderItem.productCode === 'SEMP' || orderItem.productCode === 'SEM Kicker') {
            let bodySEM = {
              "TrackingId": callTrackingId,
              "Source": source,
              "DestinationNumber": terminationNumber
            };
            tibcoCTarray.push(bodySEM);
          } else if (orderItem.productType === "Print") {
            if (tibcoCTstring === "") {
              tibcoCTstring = trackingNumber;
            } else (
              tibcoCTstring += "," + terminationNumber
            );
             cTbody = tibcoCTstring;
          }
        }

        //create list of destination numbers to make sure none are reused
        this.StorageService.updateField("call", storageID, { "data.destinations": [...new Set(destinationNums)] })
        .subscribe(()=>{},err=>{});

        //this is what gets sent to tibco product update/create about CT
        if (orderItem.productType === "Print" && orderItem.actionCode === "Renew" && tibcoCTstring === "") {
          //renewal print items with no call tracking numbers default to opt-out
          // model["CallTracking"] = {};
          // StorageService.updateField("call", storageID, {"data.optout" : true});
          // StorageService.updateField("call", storageID, {"data.selectedOption" : "No"});
          model["CallTracking"] = tibcoCTarray;
          model.CallTracking.CallTrackingCount = tibcoCTarray.length;
          } else if (orderItem.productType === "Digital"
          && orderItem.actionCode === "Renew"
          && (orderItem.productCode === 'YPC' || orderItem.productCode === 'Single IYP')
          && length === 0
          ) {
          //renewal digital items with opt-out option with no call tracking numbers default to opt-out
          // model["CallTracking"] = {};
          // StorageService.updateField("call", storageID, {"data.optout" : true});
          // StorageService.updateField("call", storageID, {"data.selectedOption" : "No"})
          model["CallTracking"] = tibcoCTarray;
          model.CallTracking.CallTrackingCount = tibcoCTarray.length;
        } else {
          if (cTbody) {
            model["CallTracking"] = cTbody;
          } else {

            model["CallTracking"] = tibcoCTarray;
              model.CallTracking.CallTrackingCount = tibcoCTarray.length;
          }
        }
        //if true 'Call Record' check-box appears on publish page
        orderItem = JSON.parse(JSON.stringify(orderItem));
        orderItem.hasCallTracking = false;
        if (tibcoCTarray.length !== 0 || cTbody) {
          if (callTrackingEnabled) {
            orderItem.hasCallTracking = true;
          }
        }
      }
    });
    return model;
  };

/**
 * getSalesforceMapping - set all the current model object values to the object to send to tibco
 * @param  {Object} data  - the data received from the tibco request
 * @param  {Object} form  - the form from the angular schema form
 * @param  {Object} model - the model object that is hooked into the form
 * @return {Object}       - return the previously established data object with changes
 */
  getSalesforceMapping(sfData, model) {
    model = { ...sfData }
  };

/**
* upsertMapping - set all the current model object values to the object to send to tibco
* @param  {Object} data  - the data received from the tibco request
* @param  {Object} form  - the form from the angular schema form
* @param  {Object} model - the model object that is hooked into the form
* @return {Object}       - return the previously established data object with changes
*/
  upsertMapping(data: object, form, model, from): boolean {
    var shouldNotUpsertCounter = 0;
    for (var i = 0; i < form.length; i++) {
      if (form[i].tibcoResponse != null) {
        var paths = form[i].tibcoResponse.split(",");
        if (from == "ITEM" || (from == "PROFILE" && form[i].key[0] != "BusinessPhone" && form[i].key[0] != "PhoneTollFree" && form[i].key[0] != "PhoneAlternate" && form[i].key[0] != "BPBDEmail" && form[i].key[0] != "Fax")) { //FUL-8080 changes
          if (paths.length === 1) {
            let modelValue = model[form[i].key];
            if (!modelValue){
              let modelKey = Object.keys(model);
              for (let j=0; j< modelKey.length; j++){
                if (form[i].key.toLowerCase() == modelKey[j].toLowerCase()){
                    modelValue = model[modelKey[j]];
                    break;
                }
              }
            }
            if (from == "ITEM" || (from == "PROFILE" && model[form[i].key] != null && form[i].key != "BusinessPhone" && form[i].key != "PhoneTollFree" && form[i].key != "PhoneAlternate" && form[i].key != "BPBDEmail" && form[i].key != "Fax")) {//FUL-8080 changes
              //this.setToValue(data, model[form[i].key], paths[0]) 
              this.setToValue(data,modelValue, paths[0]);
            }
          } else {
            for (var x = 0; x < paths.length; x++) {
              var attribute = paths[x].substring(paths[x].lastIndexOf('.') + 1, paths[x].length);
              //DE29603 - zip code not sent in request - "model[form[i].key[0]]" was not working for some cases hence used "model[form[i].key"
              if (model[form[i].key] && (attribute === "zip" || attribute === "zip4" ||attribute === "CategoryList" )) {
                if (model[form[i].key][attribute] != null) {
                  this.setToValue(data, model[form[i].key][attribute], paths[x])
                }
              } else if (model[form[i].key[0]]) {
                if (model[form[i].key[0]][attribute] != null) {
                  this.setToValue(data, model[form[i].key[0]][attribute], paths[x])
                }
              }
            }
          }
        }
      } else {
        shouldNotUpsertCounter++;
      }
    }
    return shouldNotUpsertCounter < form.length;
  };

}
