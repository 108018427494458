import { Component, OnInit, Inject } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { FileService } from '@app/service/file/file.service';
import { Photos } from 'app/interface/photos';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { ApiService } from '@app/service/api/api.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { LoggerService } from '@app/service/logger/logger.service';

import { PDFDocument } from 'pdf-lib';


@Component({
  selector: 'app-copysheet-upload',
  templateUrl: './copysheet-upload.component.html',
  styleUrls: ['./copysheet-upload.component.scss']
})
export class CopysheetUploadComponent implements OnInit {

  uploader: FileUploader;
  showErrMsg: boolean;
  showSizeErrMsg: boolean;
  disableSave: boolean = true;
  count: number = 0;
  sharedCopySheetObj: Photos = new Photos;
  configObj = {
    fileType: 'copysheet',
    uploadLimit: '',
    fileNameLength: 1,
    uploadedFile: {},
    fileObj: {},
  };
  dialogRef: any;
  fileItem: FileItem
  hasDropZoneOver: boolean;
  FileNameSpecial: any;
  constructor(private API: ApiService,
    private File: FileService,
    private Logger: LoggerService,
    private copySheet: PhotoService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hasDropZoneOver = false;

    this.uploader = this.File.uploader;
    this.uploader.onAfterAddingFile = async (file) => {
      // let newName ='xyz.pdf';
      // file.file.name = newName;
      if (!file.file.type.toLowerCase().match("/(pdf|jpg|jpeg)")) {
        this.showErrMsg = true;
        this.uploader.removeFromQueue(file);
      } else {
        this.showErrMsg = false;
        let fileFileName = file.file.name;
        // Replace spaces, % or # in filename with underscore
        fileFileName = fileFileName.replace(/[ #%]/g, "_");
        var FileName = fileFileName.substring(0, fileFileName.lastIndexOf('.'))
        // FileName = FileName.trim();
        //If the filename is grater than 10 characters it will go inside of this if condition
        if (FileName && FileName.length > 10) {
          // FUL-38942 Upload filename with special characters 
          this.FileNameSpecial = /[!$&'(),-.+;@^_`~(a-zA-Z0-9)\[\]]+/
          for (var i = 0; i < FileName.length; ++i) {
            let fileNameArray = FileName.split('.');
            if (!FileName[i].match(this.FileNameSpecial) || (fileNameArray && fileNameArray.length > 1)) {
              Logger.log("A PDF document name cannot have special characters (such as; #, % and etc.) and spaces. Please remove them and save again.")
              this.uploader.removeFromQueue(file);
              break;
            }
          }
          //as per the user story FUL-36593 is there any spaces in between filename is replaced with %20
          // fileFileName = fileFileName.replace(/\s/g, "%20")
          let firstPart = fileFileName.slice(0, 4);
          let secondPart = fileFileName.slice(fileFileName.length - 5, fileFileName.length);
          file.file['name'] = fileFileName;
          file.file['name1'] = firstPart + '...' + secondPart;
        }
        else if (fileFileName) {
          // FUL-38942 Upload filename with special characters 
          this.FileNameSpecial = /[!$&'(),-.+;@^_`~(a-zA-Z0-9)\[\]]+/
          let fileNameArray = FileName.split('.');
          for (var i = 0; i < FileName.length; ++i) {
            if (fileNameArray && fileNameArray.length > 1 || (!FileName[i].match(this.FileNameSpecial))) {
              Logger.log("A PDF document name cannot have special characters (such as; #, % and etc.) and spaces. Please remove them and save again.")
              this.uploader.removeFromQueue(file);
              break;

            }
            // FUL-38942 Ends
          }
          //as per the user story FUL-36593 spaces are replacing with %20  
          //  fileFileName = fileFileName.replace(/\s/g, "%20");
          var FileName = fileFileName.substring(0, fileFileName.lastIndexOf('.'))
          FileName = FileName.trim();
          //if the filename lessthan 3 characters throwing an error messages and removing from queue
          if (FileName.length <= 2) {
            this.Logger.log('File name must be atleast 3 characters.');
            this.uploader.removeFromQueue(file);
          }
          file.file['name'] = fileFileName;
          return file.file['name1'] = fileFileName;
        }
        if (this.uploader.queue.length > 0) {
          if (file.file.size > 5 * 1024 * 1024) {
            this.showSizeErrMsg = true;
            this.uploader.removeFromQueue(file);
          } else {
            this.showSizeErrMsg = false;
          }
          if (!(this.showSizeErrMsg || this.showErrMsg)) {
            this.count = this.count + 1;
            if (this.count > 1) {
              this.uploader.removeFromQueue(file);
            }
          }
        }
        this.sharedCopySheetObj.uploaderObj = this.uploader;
        this.copySheet.setBehaviorView(this.sharedCopySheetObj);
      }
    };

    this.uploader.onCompleteItem = async (fileItem, response: any, status, headers) => {
      if (response) {
        response = JSON.parse(response);
        if (status !== 200 || !response._id || !response.filename) {
    
          this.uploader.queue.splice(this.uploader.queue.length - 1, 1);
        } else {
          let filePath = this.getFilePath(response);
         if( this.configObj &&
          this.configObj.uploadedFile &&
          this.configObj.fileType !== undefined) {
          this.configObj.uploadedFile[this.configObj.fileType] = filePath;
          }
          if (fileItem.file.type.toLowerCase().match("/(jpg|jpeg)")) {
            var file: any = fileItem.file.rawFile;
            this.validateImage(file, fileItem);
            var io = new Image();
            io.src = URL.createObjectURL(file);
            this.configObj.fileObj['src'] = io.src;
            this.configObj.fileObj['type'] = "image";
          }
          else if (fileItem.file.type.toLowerCase().match("/(pdf)")) {
            var file2 = new Blob([fileItem.file.rawFile], { type: 'application/pdf' });
            await this.validatePdf(file2, fileItem);
            var base64data;
            const blobToData = (blob: Blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.readAsDataURL(blob)
              })
            }
            base64data = blobToData(file2);
            this.configObj.fileObj['src'] = base64data;
            this.configObj.fileObj['type'] = "pdf";
          }
        }
        this.sharedCopySheetObj.configObj = this.configObj;
        this.fileItem = fileItem;
        this.copySheet.setBehaviorView(this.sharedCopySheetObj);
      }
      else {
        fileItem.remove();
        this.uploader.progress = 0;
      }
      this.enableSave();
      // this.Logger.log("Please do view annotate copysheet for the file to be uploaded to copysheet");
    }
  }
  ngOnInit() {
    this.dialogRef = this.data.dialogform.dialogRef
    if (this.uploader)
      this.uploader.clearQueue();
  }

  cancel() {
    this.dialogRef.close()
    if (this.fileItem && this.fileItem.isUploading)
      this.fileItem.remove();
    if (this.uploader)
      this.uploader.clearQueue();
  }

  // Function for validating image files
  validateImage(file, fileItem) {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onerror = () => {
      const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
      this.Logger.logFormattedError(errorMsg);
      fileItem.remove();
      this.uploader.progress = 0;
      this.enableSave();
    };
    img.onload = () => {
      // The image is valid
    };
  }

  // Function for validating PDF files
  async validatePdf(file, fileItem) {
    let reader = new FileReader();
    reader.onload = async (event: any) => {
      try {

       // const pdfDoc = await PDFDocument.load(event.target.result);
          const pdfDoc = await PDFDocument.load(event.target.result, { ignoreEncryption: true });

        // If the PDFDocument loads successfully, the PDF is valid.
      } catch (error) {
 
        // If the PDFDocument fails to load, the PDF is invalid.
        const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
        this.Logger.logFormattedError(errorMsg);
        fileItem.remove();
        this.uploader.progress = 0;
        this.enableSave();
      }
    };
    reader.readAsArrayBuffer(file);
  }

  onSubmit() {
    this.dialogRef.close(this.configObj);
  }

  getFilePath(file) {
    let path = this.API.get("/api/file/") + file._id;
    if (file.filename) {
      path += "/" + file.filename;
    }
    return path;
  }

  remove(file) {
    if (this.configObj.uploadedFile["copysheet"]) {
      let index = this.configObj.uploadedFile["copysheet"].lastIndexOf("/");
      let filename = this.configObj.uploadedFile["copysheet"].slice(index + 1);
      if (file.file.name === filename) {
        this.configObj.uploadedFile = {};
        this.uploader.progress = 0;
        this.count = 0;
        this.sharedCopySheetObj.configObj = this.configObj;
        this.copySheet.setBehaviorView(this.sharedCopySheetObj);
      }
    } else {
      this.configObj.uploadedFile = {};
      this.count = 0;
    }
    this.enableSave();
  }
  photoOverOnDropZone(e: any): void {
    this.hasDropZoneOver = e;
  }

  onChange(event: any): void {
    event.srcElement.value = '';
  }
  
  enableSave() {
    let disableValue = true;
    for (let item of this.uploader.queue) {
      if (item.isSuccess == true) {
        disableValue = false;
        break;
      }
    }
    this.disableSave = disableValue; 
  }

}

