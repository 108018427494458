//import license from './license-key';
import * as UIExtension from '../../../../foxit-lib/UIExtension.full.js';
//import * as Addons from '../../../../merged-foxit-addons.js';
import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { FileService } from '@app/service/file/file.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParamsService } from '@app/service/params/params.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { StorageService } from '@app/service/storage/storage.service';
import { CounterService } from '@app/service/counter/counter.service';
//import { CallconfirmationComponent } from '../call-new/callconfirmation/callconfirmation.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//import { CopysheetalertComponent } from '../copysheetalert/copysheetalert.component';
import { Constants } from '@app/service/datasource/constants';
import { ApiService } from '@app/service/api/api.service';
import { GroupService } from '@app/service/group/group.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { constant } from 'app/data/constants';
import license from './license-key';
//import * as UIExtension from '../../../foxit-lib/UIExtension.full.js';
import { CopysheetalertComponent } from '../../../shared/components/copysheetalert/copysheetalert.component';
import { CallconfirmationComponent } from '../../../shared/components/call-new/callconfirmation/callconfirmation.component';
// import { AdService } from '@app/service/ad/ad.service';
// import { AccountService } from '@app/service/account/account.service';
// import { ManagecopysheetService } from '@app/service/managecopysheet/managecopysheet.service';
// import { FooterService } from '../../../shared/components/footer/footer.service';
import { ImageDialogComponent } from '../../../shared/components/image-dialog/image-dialog.component';
import { Router } from '@angular/router';
import { HistoryDialogComponent } from '../../../shared/components/history-dialog/history-dialog.component'
import { async } from '@angular/core/testing';

// var self: any;
var imageFromKgen: any;
var refresh: boolean = false;
var that1;
var setBaseBoolean = false;
var setBaseImageAdId;
declare const pdfui_g: any;

/*@Component({
  selector: "app-pdf",
  templateUrl: "./pdf.component.html",
  styleUrls: ["./pdf.component.scss"],
										  
})*/
@Component({
  selector: 'app-foxitpdfviewer',
  template: '',
  styleUrls: ['./pdfviewer.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
//export class PDFNewViewerComponent implements OnInit {
export class PDFViewerComponent implements OnInit {
  Indicator;
  pdfui: any;
  isApply: boolean = false;
  enableButtons: boolean = false;
  destroySubject$: Subject<void> = new Subject();
  applycomponent;
  saveComponent;
  openFolder: number = 0;
  stampIcon: boolean = false;
  stampComponent;
  previousComponent;
  customImageComponent;
  addresponse: any;
  kgenCustomerId: any;
  adImageList: [];
  ucs_flag = false;
  uploadObj;
  selectedAdId: any;

  constructor(
    private router: Router,
    private element: ElementRef,
    private indicatorQuery: IndicatorQuery,
    private storageService: StorageService,
    private file: FileService,
    public copysheet: CopysheetService,
    private indicatorStore: IndicatorStore,
    private ngxspinner: NgxSpinnerService,
    private params: ParamsService,
    private logger: LoggerService,
    private requiredfieldcountservice: RequiredfieldcountService,
    private counterService: CounterService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<PDFViewerComponent>,
    //  public dialogRef: MatDialogRef<HistoryDialogComponent>,
    private ConstantText: Constants,
    private api: ApiService,
    private groupService: GroupService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
     this.indicatorQuery.getIndicator().subscribe((res) => {
       this.Indicator = { ...res };
       console.log("ngonit Indicator is ", this.Indicator);
     });
    /*this.ngxspinner.show()
    console.log(' Inside ngOnInit of new pdf component component');
    console.log(' Data from parent :: ', window['parentIndicator']);
    this.footerService.setCopySheetPageValue(true);
    // Update the indicator store with the indicatore received from parent window
    this.indicatorStore.update(() => {
      return {
        indicator: window['parentIndicator'],
        updatedFrom: "[PDFNewViewerComponent] ngOnInit",
        isloaded: true,
      };
    });
    // Fetch the indicator from the store , this should be the same as the one used above to update the indicator store
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });*/
    this.counterService.getbuttonEnableFlag().pipe(takeUntil(this.destroySubject$)).subscribe(data => {
      this.enableButtons = data;
    });
    console.log(' Base copy sheet :: ', this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet);
    console.log(' Current copy sheet :: ', this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet);
    console.log(' Previous copy sheet :: ', this.Indicator.items[this.Indicator.stateParamsId].copy.previousCopySheet);
    if (this.Indicator.items[this.Indicator.stateParamsId].isCopySheetUploaded) {
      this.uploadObj = this.Indicator.items[this.Indicator.stateParamsId].copy.uploadFile.fileObj;
      console.log("uploaded file:", this.uploadObj); 
    }

    this.foxit(this.indicatorQuery,
      this.Indicator, 
      this.storageService,
      this.file, 
      this.copysheet, 
      this.indicatorStore, 
      this.dialogRef, 
      this.ngxspinner, 
      this.params, 
      this.logger, 
      this.requiredfieldcountservice,
      this.counterService,
      this.matDialog,
      this.groupService,
      this.utilsService,
      this.isApply,
      this.api,
      this.openFolder,
      this.ucs_flag,
      this.uploadObj,
      this.previousComponent,
      this.selectedAdId,
      this.stampComponent,
      this.customImageComponent
    );
  }

 async foxit(indicatorQuery, 
    indicator, 
    storageService, 
    filenew, 
    copysheetnew, 
    indicatorStore, 
    dialogRef, 
    ngxspinner, 
    params, 
    logger, 
    requiredfieldcountservice,
    counterService,
    matDialog,
    groupService, 
    utilsService,
    isApply,
    api,
    openFolder,
    ucs_flag,
    uploadObj,
    previousComponent,
    selectedAdId,
    stampComponent,
    customImageComponent
    ) {
    
    var CustomAppearance = UIExtension.appearances.RibbonAppearance.extend({
      beforeMounted: (root) => {
        ngxspinner.show();
      },
      afterMounted: async (root) => {
        await storageService.getField("copyz",indicator.stateParamsId +'v'+indicator.items[indicator.stateParamsId].order.version).then(res =>{
          console.log('resid',res)
          selectedAdId = res[indicator.stateParamsId +'v'+indicator.items[indicator.stateParamsId].order.version].setBaseImageAdId;
       })
        ngxspinner.hide();
      },
      getLayoutTemplate: function () {
        return [
          '<webpdf>',
          '<div class="tools-wrap">',
          '<div class="tab-bodies" style="display:flex;flex-direction:row;flex-wrap:wrap">',
          '<group-list> <group name="copyright">',
          '</group> </group-list>',
          '<group-list> <group>',
          '<xbutton  name="pencil-tool" @tooltip tooltip-description="Allows freehand drawing capability within the Copysheet"  @tooltip tooltip-title="toolbar.tooltip.pencil.title" tooltip-placement="right" @controller="states:CreatePencilController" icon-class="fv__icon-toolbar-pencil">toolbar.buttons.pencil</xbutton>',
          '<xbutton name="eraser-tool" @tooltip tooltip-description="Allows freehand drawing to be erased by clicking and dragging over the area"  @tooltip tooltip-title="toolbar.tooltip.eraser.title" tooltip-placement="right" @controller="states:EraserController" icon-class="fv__icon-toolbar-eraser">toolbar.buttons.eraser</xbutton>',
          '</group> </group-list>',
          '<group-list> <group>',
          '<xbutton name="create-area-highlight" @tooltip tooltip-description="Allows areas anywhere on the Copysheet to be highlighted in yellow"  @tooltip tooltip-title="toolbar.tooltip.areaHighlight.title"  tooltip-placement="right" @controller="states:CreateAreaHighlightController" icon-class="fv__icon-toolbar-area-highlight">toolbar.create.areahighlight</xbutton>',
          '</group> </group-list>',
          '<group-list> <group>',
          '<create-text-field-button></create-text-field-button>',
          '<xbutton name="freetext-textbox" @tooltip tooltip-description="Black border box with Black text"  @tooltip tooltip-title="toolbar.tooltip.textbox.title" tooltip-placement="right" @controller="states:CreateTextboxController" icon-class="fv__icon-toolbar-textbox" >toolbar.create.textbox</xbutton>',
          '<xbutton name="freetext-callout" @tooltip tooltip-description="Black border box with Black text with directional arrow" @tooltip tooltip-title="toolbar.tooltip.callout.title" tooltip-placement="right" @controller="states:CreateCalloutController" icon-class="fv__icon-toolbar-callout" >toolbar.create.callout</xbutton>',
          '</group> </group-list>',
          '<group-list name="edit-toolbar-group-list">',
          '   <group name="edit-tab-group-font" retain-count="5" @require-modules="edit-text-object">',
          '<xbutton name="create-highlight" @tooltip tooltip-description="Allows text to be highlighted in yellow by clicking & dragging over the area"  @tooltip tooltip-title="toolbar.tooltip.highlight.title" tooltip-placement="right" tooltip-placement="bottom" @controller="states:CreateHighlightController" icon-class="fv__icon-toolbar-text-highlight">toolbar.create.highlight</xbutton>',
          '<xbutton name="create-strikeout" @tooltip tooltip-description="Strikethrough of text only when clicking & dragging over the area"  @tooltip tooltip-title="toolbar.tooltip.strikeout.title" tooltip-placement="right" @controller="states:CreateStrikeoutController" icon-class="fv__icon-toolbar-strikeout">toolbar.create.strikeout</xbutton>',
          // '<edit-text-object:add-text-button  @async @tooltip tooltip-description="Black text with no Boarder"></edit-text-object:add-text-button>',
          // '       <edit-text-object:text-bold-style-button @tooltip tooltip-description="Presents Bold font when typing new text using the Add Text feature only"></edit-text-object:text-bold-style-button>',
          // '       <edit-text-object:text-italic-style-button @tooltip tooltip-description="Presents Italic font when typing new text using the Add Text feature only"></edit-text-object:text-italic-style-button>',
          // '       <edit-text-object:font-color-picker @tooltip tooltip-description="Change Font style when using Add Text feature only"></edit-text-object:font-color-picker>',
          // '       <edit-text-object:font-style-dropdown @tooltip tooltip-description="Change Font size when using Add Text feature only"></edit-text-object:font-style-dropdown>',
          '   </group>',
          '</group-list>',
          '<group-list> <group>',
          // '<open-file-dropdown></open-file-dropdown>',
          '<div name="custom-add-image"></div>',
          // '<div name="custom-file-open"></div>',
          '<open-localfile-button @controller="file:OpenLocalFileController" @tooltip tooltip-description="Upload a Document that will be inserted and displayed on the Copysheet" tooltip-placement="right"></open-localfile-button>',
          '</group> </group-list>',
          '<group-list> <group>',
          '<hand-button @tooltip tooltip-description="Ability to move annotated objects around on the Copysheet"  tooltip-placement="right"></hand-button>',
          '</group> </group-list>',
          '<group-list> <group>',
          '<loupe-tool-button @tooltip tooltip-description="Magnify Glass will display the area on the Copysheet zoomed in when hovered over" tooltip-placement="right"></loupe-tool-button>',
          '<template><loupe-tool-dialog></loupe-tool-dialog></template>',
          '</group> </group-list>',
          // '<group-list> <group>',
          // '<xbutton @tooltip tooltip-title="Content Assistant PDF Reader Powered by Foxit. Copyright (C) 2003-2021 by Foxit Software Incorporated" icon-class="fv__icon-dialog-level-prompt"></xbutton>',
          // '</group-list> </group>',
          '<group-list> <group>',
          '<zoom-out-button  @tooltip tooltip-description="Decreases the size of the Copysheet image"  tooltip-placement="right"></zoom-out-button>',
          '<zoom-in-button @tooltip tooltip-description="Increases the size of the Copysheet image" tooltip-placement="right"></zoom-in-button>',
          '<editable-zoom-dropdown class="edit-zoom-dropdown"></editable-zoom-dropdown>',
          '</group> </group-list>',
          '<group-list> <group>',
          '<create-square-button  @tooltip tooltip-title="Square" @tooltip tooltip-description="Insert a Black Border Square shape on the Copysheet that can be repositioned when clicked on" tooltip-placement="right"></create-square-button>',
          '<create-circle-button @tooltip tooltip-title="Circle" @tooltip tooltip-description="Insert a Black Border Circle shape on the Copysheet that can be repositioned when clicked on" tooltip-placement="right"></create-circle-button>',
          '<create-line-button @tooltip tooltip-title="Line" @tooltip tooltip-description="Insert a Black Line on the Copysheet that can be repositioned when clicked on" tooltip-placement="right"></create-line-button>',
           '<create-arrow-button @tooltip tooltip-title="Arrow" @tooltip tooltip-description="Insert a Black Arrow on the Copysheet that can be repositioned when clicked on" tooltip-placement="right"></create-arrow-button>',
          ' <create-polygon-button @tooltip tooltip-title="Polygon" @tooltip tooltip-description="Insert a Black Border Polygon shape on the Copysheet that can be repositioned when clicked on" tooltip-placement="right"></create-polygon-button>',
          '</group> </group-list>',
          // '<group-list> <group>',
          // '<goto-first-page-button></goto-first-page-button>',
          // '<goto-prev-page-button></goto-prev-page-button>',
          // '<goto-page-input></goto-page-input>',
          // '<goto-next-page-button></goto-next-page-button>',
          // '<goto-last-page-button></goto-last-page-button>',
          // '</group> </group-list>',
          '<group-list> <group>',
          '<div name="custom-file-attachment"></div>', //need to check merge issue****
          ' <fpmodule:file-property-button @tooltip tooltip-description="Provides Details of the document (such as; Create & Modify Date)"  tooltip-placement="right"></fpmodule:file-property-button> <template>',
          ' <fpmodule:file-property-dialog></fpmodule:file-property-dialog> </template>',
          '</group> </group-list>',
          '<group-list> <group>',
          '<download-file-button @tooltip tooltip-description="Download PDF version of the Copysheet to your machine" tooltip-placement="right" ></download-file-button>',
          '<print:print-button @tooltip tooltip-description="Print PDF version of the Copysheet to your machine" tooltip-placement="right" ></print:print-button>',
          '<template class="fv__ui-layer.center"><print:print-dialog></print:print-dialog></template>',
          '</group> </group-list>',
          '<group-list> <group name="custom-undoredo-btn">',
          '</group> </group-list>',
          '<div name="custom-image"></div>',
          '</div>',
          // '<div class="tab-bodies-middle" name="custom-btn-thumbnail">',
          // '</div>',
          '<div class="tab-bodies-right" name="custom-btn">',
          // '<a class="grouping-list"></a>',
          '</div>',
          '</div>',
          '</div>',
          '<div class="fv__ui-body">',
          '<sidebar>',
          '<thumbnail-sidebar-panel  fv__ui-hideclass="fv__cursor-loupe"style="touch-action: none;" ></thumbnail-sidebar-panel>',
          '<commentlist-sidebar-panel></commentlist-sidebar-panel>',
          '<search-sidebar-panel></search-sidebar-panel>',
          '<attachment-sidebar-panel></attachment-sidebar-panel>',
          '</sidebar>',
          '<viewer></viewer>',
          '</div>',
          '</webpdf>'
        ].join('');
      },
      // getDefaultFragments: function () {
      //   return [{
      //          // Add a component
      //       action: FRAGMENT_ACTION.APPEND,
      //       // Specify the name of the target component that the new components defined in the above template will be appended to. All the target names of fragments are defined in the layout template. 
      //       target: 'custom-btn',
      //       // Define the properties of the added component, such as icon, text, and css style. 
      //       template: [
      //         '<div>',
      //         '<xbutton name="apply-btn" icon-class="fv__icon-toolbar-add-stamp" text="Apply"></xbutton>',
      //         '<xbutton name="saveclose-btn" icon-class="fv__icon-toolbar-close" text="Save&Close"></xbutton>',
      //         '<xbutton name="cancel-btn" icon-class="fv__icon-toolbar-close" text="Cancel"></xbutton>',
      //       '</div>',
      //       ].join(''),
      //       // Define the interaction logic of the added component. 
      //       config:[]
      //     }]
      //  }
    })

    var FRAGMENT_ACTION = UIExtension.UIConsts.FRAGMENT_ACTION;
    this.pdfui = new UIExtension.PDFUI({
      viewerOptions: {
        libPath: '/foxit-lib',
        jr: {
          ...license,
          fontPath: location.origin + '/foxit-lib/assets/external/brotli/'
        }
      },
      renderTo: this.element.nativeElement,
      fragments: [
        {
          target: 'custom-add-image',
          template:[
              '<div class="custom-btn-flex-wrap">',
              '<file-selector accept=".png;.jpg;.bmp" name="addFile" class="fv__ui-button-icon fv__icon-edit-add-image" @tooltip tooltip-title="Add Image" tooltip-description="Upload an image that will be inserted to the Copysheet and can be resized" tooltip-placement="right"></file-selector>',
            '</div>',
          ].join(''),
          action: FRAGMENT_ACTION.APPEND,
          config: [
            {
              target: ['addFile'],
              callback: async function (img) {
                console.log('File', img);

                const validateImage = (file) => {
                  return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onerror = () => reject(false); // Image is invalid or corrupted
                    img.onload = () => resolve(true); // Image is valid
                  });
                };


                try {
                  var pdfUI = await this.getPDFUI();
                  var pdfViewer = await pdfUI.getPDFViewer();
                  var pdfDoc = await pdfViewer.getCurrentPDFDoc();
                  var pdfPage = await pdfDoc.getPageByIndex(0);

                  if (img && img.size < 2097152) {
                    console.log('Filetype', img);
                    const filearray = img.type.split("/");
                    let allowedExtensions = ['jpg', 'jpeg', 'png'];
                    if (!allowedExtensions.includes(filearray[1].toLowerCase())) {
                      logger.logError('Please select a valid file type (JPG, JPEG, PNG).');
                      return;
                    }
                    try {
                      const isValidImage = await validateImage(img); // Use validateImage function here
                      if (!isValidImage) {
                        const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
                        logger.logFormattedError(errorMsg);
                        return;
                      }
                    } catch (error) {
                      // Handle the rejection from the validateImage function
                      const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
                      logger.logFormattedError(errorMsg);
                      return;
                    }
                    var io = new Image();
                    io.src = URL.createObjectURL(img);
                    io.onload = async function (t) {
                      let co = document.createElement("canvas");
                      co['width'] = this['width'],
                        co['height'] = this['height'],
                        co['srcWidth'] = this['width'],
                        co['srcHeight'] = this['height'];
                      const ctxt = co.getContext("2d");
                      ctxt.drawImage(io, 0, 0, co.width, co.height);
                      const view = await imageToUint8Array(ctxt);
                      const iblob = co.toDataURL('image/png', 1.0);

                      await addAnnotation(view, co);
                      saveAttachment(iblob);
                    }
                  }

                  function imageToUint8Array(ctxt) {

                    return new Promise((resolve, reject) => {
                      ctxt.canvas.toBlob(blob => blob.arrayBuffer()
                        .then(buffer => resolve(new Uint8Array(buffer))).catch(reject)
                      )
                    });
                  }
                  async function addAnnotation(view, co) {
                    const annotJson = {
                      type: 'screen',
                      buffer: view,
                      rect: {
                        bottom: 501,
                        left: 47,
                        right: 189,
                        top: 600,
                      }
                    }
                    pdfPage.addAnnot(annotJson);
                  }
                  function saveAttachment(iblob) {
                    try {
                      filenew.saveImageToPromise(indicator.stateParamsId, { 'data': iblob }, "attachment")
                        .then((response) => {
                          let attachmentUrl = api.get('/api/file/' + response._id + '/' + response.filename);
                          console.log(" attachment url generated is : ", attachmentUrl);
                          if (!indicator.items[indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                            indicator.items[indicator.stateParamsId].copy.attachments = [];
                            indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
                          }
                          else {
                            indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
                          }
                          console.log("saveAttachment", indicator);
                        }, err => {
                          console.log("error", err)
                        }).catch(e => {
                          console.log(" error in saveImageToPromise : " + e);
                        })
                    } catch (error) {
                      console.log("error", error)
                    }
                  };
                } catch (error) {
                  console.log("error", error)
                }
              }
            },
          ]
        },   
        {
          target: 'custom-file-attachment',
          template: [
            '<div class="custom-btn-flex-wrap">',
            '<file-selector accept=".png;.jpg;.bmp" name="attachFile" class="fv__ui-button-icon fv__icon-toolbar-attachment" @tooltip tooltip-title="File Attachment" tooltip-description="Upload an attachment that is stored on the left sidebar (paperclip) with a pushpin that can be positioned on the Copysheet" tooltip-placement="right"></file-selector>',
            //'<xbutton name="create-fileattachment" @tooltip tooltip-description="Upload an attachment that is stored on the left sidebar (paperclip) with a pushpin that can be positioned on the Copysheet"  @tooltip tooltip-title="toolbar.tooltip.fileattachment.title" tooltip-placement="right" @controller="states:CreateFileAttachmentController" icon-class="fv__icon-toolbar-attachment"></xbutton>',
            '</div>',
          ].join(''),
          action: FRAGMENT_ACTION.APPEND,
          config: [
            {
              target: ['attachFile'],
              callback: async function (file) {
                console.log('File', file);

                var pdfui = await this.getPDFUI();
                var pdfViewer = await pdfui.getPDFViewer();
                var pdfDoc = await pdfViewer.getCurrentPDFDoc();
                var pdfPage = await pdfDoc.getPageByIndex(0);

                if (file && file.size < 2097152) {
                  var io = new Image();
                  io.src = URL.createObjectURL(file);
                  io.onload = async function (t) {
                    let co = document.createElement("canvas");
                    co['width'] = this['width'],
                      co['height'] = this['height'],
                      co['srcWidth'] = this['width'],
                      co['srcHeight'] = this['height'];
                    const ctxt = co.getContext("2d");
                    ctxt.drawImage(io, 0, 0, co.width, co.height);
                    const view = await imageToUint8Array(ctxt);
                    const iblob = co.toDataURL('image/png', 1.0);

                    await addAnnotation(view, co);
                    saveAttachment(iblob);
                  }
                }

                function imageToUint8Array(ctxt) {

                  return new Promise((resolve, reject) => {
                    ctxt.canvas.toBlob(blob => blob.arrayBuffer()
                      .then(buffer => resolve(new Uint8Array(buffer))).catch(reject)
                    )
                  });
                }
                async function addAnnotation(view, co) {
                  const annotJson = { //Need to check json obj merge issue***
                    type: 'fileattachment',
                    buffer: view,
                    rect: {
                      bottom: 550,
                      left: 90,
                      right: 110,
                      top: 580,
                    },
                    fileName: file.name
                  }
                  pdfPage.addAnnot(annotJson);
                }
                function saveAttachment(iblob) {
                  try {
                    filenew.saveImageToPromise(indicator.stateParamsId, { 'data': iblob }, "attachment")
                      .then((response) => {
                        let attachmentUrl = api.get('/api/file/' + response._id + '/' + response.filename);
                        console.log(" attachment url generated is : ", attachmentUrl);
                        if (!indicator.items[indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                          indicator.items[indicator.stateParamsId].copy.attachments = [];
                          indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
                        }
                        else {
                          indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
                        }
                        //set based changes begins
                        indicatorStore.setLoading(true);
                        indicatorStore.update(() => {
                          return {
                            indicator: indicator,
                            updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                            isloaded: true,
                          };
                        });
                        indicatorStore.setLoading(false);
                        indicatorQuery.getIndicator().subscribe(data => {
                          indicator = JSON.parse(JSON.stringify(data));
                        });
                        //set based changes ends
                        console.log("saveAttachment", indicator);
                      }, err => {
                        console.log("error", err)
                      }).catch(e => {
                        console.log(" error in saveImageToPromise : " + e);
                      })
                  } catch (error) {
                    console.log("error", error)
                  }
                };


              }
            },
          ]
        },
        {
          target: 'custom-image',
          template: [
            '<div  class="custom-btn-flex-wrap">',
            //'<xbutton  name ="popupimage"  icon-class="fv__icon-toolbar-stamp"></xbutton>',
            '<xbutton  name ="popupimage" @tooltip tooltip-title="Based On Ad" @tooltip tooltip-description="Choose a Based On Ad image to add to the copysheet" class="fa fa-file-image-o" tooltip-placement="right" text="Based On Ad" ></xbutton>',
            '</div>',
          ].join(''),
          action: FRAGMENT_ACTION.APPEND,
          config: [
            {
              target: ['popupimage'],
              callback: async function () {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'confirmaton-image-setbased';
                dialogConfig.data = {
                  'selectedAdId': selectedAdId
                }
                // dialogConfig.width = '600px';

                const dialogRefConfirm = matDialog.open(ImageDialogComponent, dialogConfig);
                dialogRefConfirm.afterClosed().subscribe(async dialogResult => {

                  /* Hide the spinner */
                  ngxspinner.hide();
                  /* Fetch the latest indicator to get the new copy sheet */
                  indicatorQuery.getIndicator().subscribe(data => {
                    indicator = JSON.parse(JSON.stringify(data));
                  });
                  console.log(indicator.items[indicator.stateParamsId].copy.copysheet)

                  if (dialogResult && (dialogResult.closingValue == true || dialogResult == true)) {
                    setBaseBoolean = true;
                    setBaseImageAdId = dialogResult.adid
                    selectedAdId = dialogResult.adid
                    /* Add the selected setBase image as an attachment */
                    if (dialogResult.blob && dialogResult.blob.size < 2097152) {
                      var io = new Image();
                      io.src = URL.createObjectURL(dialogResult.blob);
                      io.onload = async function (t) {
                        let co = document.createElement("canvas");
                        co['width'] = this['width'],
                          co['height'] = this['height'],
                          co['srcWidth'] = this['width'],
                          co['srcHeight'] = this['height'];
                        const ctxt = co.getContext("2d");
                        ctxt.drawImage(io, 0, 0, co.width, co.height);
                        const iblob = co.toDataURL('image/png', 1.0);
                        console.log('iblob', iblob);
                        saveAttachment(iblob);
                      }
                    }
                    /* Display the new copy sheet on the UI */
                    pdfui_g.openPDFByHttpRangeRequest({
                      range: {
                        url: indicator.items[indicator.stateParamsId].copy.copysheet,
                      }
                    })
                  }
                  /* Add the image selected by the user to new copy sheet */
                  pdfui_g.addViewerEventListener("open-file-success", async function (pdfDoc) {
                    console.log('Triggering the code for adding the image to PDF')
                    console.log('Inside event')
                    var pdfPage = await pdfDoc.getPageByIndex(0);
                    const annotJson1 = {
                      type: 'screen',
                      buffer: dialogResult.buffer,
                      // subject: 'customAdImage',
                      // name: 'cstm-screen-sbAd',

                      rect: {
                        bottom: 470,
                        left: 250,
                        right: 420,
                        top: 600,
                      }
                    }
                    // pdfPage.addAnnot(annotJson);
                    await pdfPage.addAnnot(annotJson1).then(res => {
                      console.log('SetBased added :: ', res)
                      indicator.items[indicator.stateParamsId].copy['setBaseAdImgAnnotId'] = res[0].id
                      indicatorStore.update(() => {
                        return {
                          indicator: indicator,
                          updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                          isloaded: true,
                        };
                      });
                      indicatorStore.setLoading(false);
                      indicatorQuery.getIndicator().subscribe(data => {
                        indicator = JSON.parse(JSON.stringify(data));
                      });
                    });
                  })

                  function saveAttachment(iblob) {
                    try {
                      filenew.saveImageToPromise(indicator.stateParamsId, { 'data': iblob }, "attachment")
                        .then((response) => {
                          let attachmentUrl = api.get('/api/file/' + response._id + '/' + response.filename);
                          console.log(" attachment url generated is : ", attachmentUrl);
                          if (!indicator.items[indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                            indicator.items[indicator.stateParamsId].copy.attachments = [];
                            indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
                          }
                          else {
                            indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
                          }
                          indicatorStore.setLoading(true)
                          indicatorStore.update(() => {
                            return {
                              indicator: indicator,
                              updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                              isloaded: true,
                            };
                          });
                          indicatorStore.setLoading(false);
                          indicatorQuery.getIndicator().subscribe(data => {
                            indicator = JSON.parse(JSON.stringify(data));
                          });
                          console.log("saveAttachment", indicator);
                        }, err => {
                          console.log("error", err)
                        }).catch(e => {
                          console.log(" error in saveImageToPromise : " + e);
                        })
                    } catch (error) {
                      console.log("error", error)
                    }
                  };
                })
              }
            }
          ]
        },
        // {
        //   target: 'custom-file-open',
        //   template: [
        //     '<div class="custom-btn-flex-wrap">',
        //     '<file-selector accept="application/pdf, image/*" name="openFile" class="fv__ui-dropdown-icon fv__icon-toolbar-open" @tooltip tooltip-title="Open Local File" tooltip-description="Upload a Document that will be inserted and displayed on the Copysheet" tooltip-placement="right"></file-selector>',
        //     '</div>',
        //   ].join(''),
        //   action: FRAGMENT_ACTION.APPEND,
        //   config: [
        //     {
        //       target: ['openFile'],
        //       callback: async function (file) {
        //         ngxspinner.show();
        //         console.log('File', file);
        //         var pdfUI = await this.getPDFUI();
        //         await fileopen(file, pdfUI);
        //         ngxspinner.hide();
        //       }
        //     }
        //   ]
        // },
        {
          target: 'custom-undoredo-btn',
          template:[
              '<div class="custom-btn-flex-wrap">',
              '<xbutton name="undo" @tooltip tooltip-title="Undo" @tooltip tooltip-description="Each click will remove Annotation activity on the Copysheet from latest to earliest prior to saving changes" class="bi bi-arrow-counterclockwise" tooltip-placement="right" text="Undo"></xbutton>',
              '<xbutton name="redo"   @tooltip tooltip-title="Redo" @tooltip tooltip-description="Each click will readd Annotation activity on the Coopysheet from latest to earliest prior to saving changes" class="bi bi-arrow-clockwise" tooltip-placement="right" text="Redo"></xbutton>',
              '<xbutton name="undoall"  @tooltip tooltip-title="Undoall"  @tooltip tooltip-description="One click will remove all Annotation activity on the Copysheet prior to saving changes"class="bi bi-arrow-repeat" text="Undo All" tooltip-placement="right"></xbutton>',
            '</div>',
          ].join(''),
          action: FRAGMENT_ACTION.APPEND,
          config: [
            {
              target: ['undo'],
              callback: function () {
                (window as any).pdfui.callAddonAPI("UndoRedoAddon", "undo")
              }
            },
            {
              target: ['redo'],
              callback: function () {
                (window as any).pdfui.callAddonAPI("UndoRedoAddon", "redo")
              }
            },
            {
              target: ['undoall'],
              callback: function () {
                (window as any).pdfui.callAddonAPI("UndoRedoAddon", "undoAll")
              }
            }
          ]
        },
        // History thumbnails
        // {
        //   target: 'custom-btn-thumbnail',
        //   template: [
        //     '<div class="custom-btn-flex-wrap">',
        //     '<div class="custom-btn-flex">',
        //     '<xbutton name="default" class="basecs-button" text="Base CopySheet"></xbutton>',
        //     '<xbutton name="previousversion"  class="previous-button" text="Previous Version"></xbutton>',
        //     '</div>',
        //     '</div>',
        //   ].join(''),
        //   action: FRAGMENT_ACTION.APPEND,
        //   config: [
        //     {
        //       target: ['default'],
        //       callback: function () {

        //         let msg = 'Are you sure you want to, proceed the BaseCopySheet';
        //         const dialogConfig = new MatDialogConfig();

        //         dialogConfig.disableClose = true;
        //         dialogConfig.autoFocus = true;
        //         dialogConfig.panelClass = 'default-dialog';
        //         dialogConfig.data = {
        //           message: msg,
        //           type: 'baseCopySheet',
        //         }
        //         dialogConfig.width = '600px';
        //         const dialogRefConfirm = matDialog.open(HistoryDialogComponent, dialogConfig);
        //         dialogRefConfirm.afterClosed().subscribe(dialogResult => {

        //           indicatorQuery.getIndicator().subscribe(data => {
        //             indicator = JSON.parse(JSON.stringify(data));
        //           });
        //           if (dialogResult) {
        //             // dialogRef.close(true)
        //             console.log(indicator.items[indicator.stateParamsId].baseCopysheet)
        //             pdfui_g.openPDFByHttpRangeRequest({
        //               range: {
        //                 url: indicator.items[indicator.stateParamsId].baseCopysheet,
        //               }
        //             })

        //           }
        //         });

        //       }
        //     },
        //     {
        //       target: ['previousversion'],
        //       callback: function () {
        //         console.log("Previous version")
        //         let msg = 'Are you sure you want to, proceed the Previous Version';
        //         const dialogConfig = new MatDialogConfig();

        //         dialogConfig.disableClose = true;
        //         dialogConfig.autoFocus = true;
        //         dialogConfig.panelClass = 'default-dialog';
        //         dialogConfig.data = {
        //           message: msg,
        //           type: 'previousCopySheet',
        //         }
        //         dialogConfig.width = '600px';
        //         const dialogRefConfirm = matDialog.open(HistoryDialogComponent, dialogConfig);
        //         dialogRefConfirm.afterClosed().subscribe(dialogResult => {
        //           indicatorQuery.getIndicator().subscribe(data => {
        //             indicator = JSON.parse(JSON.stringify(data));
        //           });
        //           if (dialogResult) {
        //             console.log(indicator.items[indicator.stateParamsId].copy.previousCopySheet)
        //             pdfui_g.openPDFByHttpRangeRequest({
        //               range: {
        //                 url: indicator.items[indicator.stateParamsId].copy.previousCopySheet,
        //               }
        //             })

        //           }
        //         });
        //       }
        //     },
        //   ]
        // },
        {
          target: 'custom-btn',
          template:[
            '<div class="custom-btn-flex-wrap">',
              '<div class="custom-btn-flex">',
                '<xbutton name="apply-btn" class="apply-button" text="Apply"></xbutton>',
                '<xbutton name="saveclose-btn"  class="save-close-button" text="Save & Close"></xbutton>',
                '<xbutton name="cancel-btn" class="cancel" text="Cancel"></xbutton>',
              '</div>',
            '</div>',
          ].join(''),
          action: FRAGMENT_ACTION.APPEND,
          config: [
            {
              // specify the component in the above template that the configuration will be applied to. // For example, the configuration will be applied to the component with the name of "show-hello-button". 
              target: ['cancel-btn'],
              callback: function () {
                indicatorQuery.getIndicator().subscribe(data => {
                  indicator = JSON.parse(JSON.stringify(data));
                });

                let msg = 'Are you sure you want to discard all your changes? ';
                const dialogConfig = new MatDialogConfig();
            
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'confirmaton-dialog';
                dialogConfig.data = {
                  message: msg,
                }
                dialogConfig.width = '600px';
                const dialogRefConfirm = matDialog.open(CallconfirmationComponent, dialogConfig);
                dialogRefConfirm.afterClosed().subscribe(async dialogResult => {
                  ngxspinner.hide();
                  if (dialogResult) {
                    // isApply ? dialogRef.close(true) : dialogRef.close(false);
                    if(isApply){
                      dialogRef.close(true)
                      // window['childIndicator'] = indicator;
                      // window['closingBoolean'] = true;
                      // window.close();
                    } else{
                      // copysheetnew.discardCopysheetChanges(indicator)
                      // window['childIndicator'] = indicator;
                      // window['closingBoolean'] = false;
                      // window.close();
                     dialogRef.close(false)
                    }
                    stampComponent.disable();
                    customImageComponent.disable();
                  }
                  logger.clearAll();
                });
              }
            },
            {
              // specify the component in the above template that the configuration will be applied to. // For example, the configuration will be applied to the component with the name of "show-hello-button". 
                
              target: ['apply-btn', 'saveclose-btn'],
              callback: function () {
                let isSaveClose: boolean = false;
                let that = this;
                indicatorQuery.getIndicator().subscribe(data => {
                  indicator = JSON.parse(JSON.stringify(data));
                });
                if (this.component.name == "saveclose-btn") {
                  isSaveClose = true;
                  try {
                    let headingOk = 'Selecting "OK" will send additional Copysheet transaction to Graphics whether changes have occurred or not!';
                    let headingCancel = 'Selecting "CANCEL" will not send any additional Copysheet transaction to Graphics whether changes have occurred or not!';
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.panelClass = 'call-tracking-confirmation-dialog';
                    dialogConfig.disableClose = true;
                    dialogConfig.autoFocus = true;
                    dialogConfig.data = {
                      headingOk: headingOk,
                      headingCancel: headingCancel,
                    }
                    dialogConfig.width = '910px';
                    const dialogRefConfirmSave = matDialog.open(CopysheetalertComponent, dialogConfig);
                    dialogRefConfirmSave.afterClosed().subscribe(dialogResult => {

                      if (dialogResult) {
                        ngxspinner.show();
                        saveAnnotation(that);
                        stampComponent.disable();
                        customImageComponent.disable();
                      } else {
                        // isApply ? dialogRef.close(true) : dialogRef.close(false);
                        if(isApply){
                          dialogRef.close(true)
                         /*window['childIndicator'] = indicator;
                          window['closingBoolean'] = true;
                          window.close()*/
                        } else{
                          copysheetnew.discardCopysheetChanges(indicator)
                          dialogRef.close(false)
                         /*window['childIndicator'] = indicator;
                          window['closingBoolean'] = false;
                          window.close();*/
                        }
                        stampComponent.disable();
                        customImageComponent.disable();
                      }
                    })
                  } catch (e) {
                    ngxspinner.hide();
                    console.log(e, " exception in the save method");
                  }
                } else {
                  ngxspinner.show();
                  // spinnerText = ConstantText.COPYSHEET_SAVE;
                  setTimeout(() => {
                    try {
                      saveAnnotation(that);
                    } catch (e) {
                      ngxspinner.hide();
                      console.log(e, " exception in the save method");
                    }
                  }, 0);
                }
                // if (indicator.items[indicator.stateParamsId].copy) {
                //   previousComponent.enable();
                // }
                function saveAnnotation(that){
                that.getPDFUI()
                  .getPDFViewer()
                  .then(pdfViewer => {
                    ngxspinner.show();
                    let bufferArray = [];
                    if(pdfViewer && pdfViewer.currentPDFDoc){
                      return pdfViewer.currentPDFDoc.getStream(function ({ arrayBuffer, offset, size }) {
                        bufferArray.push(arrayBuffer);
                      }).then(function (size) {
                        console.log('The total size of the stream', size)
                        const blob = new Blob(bufferArray, { type: 'application/pdf' });
                        console.log("The blob is ", blob);
                        let fd = new FormData();
                        let fileName = "";
                        let attachmentURL = [];
                        params['saving'] = true;
                        params['loadingbar'] = true;
                        //form the filename
                        if (indicator.items[indicator.stateParamsId].copy && indicator.items[indicator.stateParamsId].copy.copysheet != null) {
                          fileName = indicator.items[indicator.stateParamsId].copy.copysheet.slice(indicator.items[indicator.stateParamsId].copy.copysheet.lastIndexOf("/") + 1, indicator.items[indicator.stateParamsId].copy.copysheet.lastIndexOf("."));
                          var isnum = /^\d+$/.test(fileName);
                          if (!isnum) {
                            fileName = Math.round(Math.random() * 1000000000000) + "";
                          }
                        } else {
                          fileName = Math.round(Math.random() * 1000000000000) + "";
                        }
                        //apend the blob and filename to send to mongo
                        fd.append("files", blob, fileName + '.' + indicator.items[indicator.stateParamsId].copy.fileType);
                        fd.append("user", JSON.parse(JSON.stringify({ parameters: { accountId: indicator.order.accountId, svOrderId: indicator.order.orderId,}})));
                          fd.append("svItemId", indicator.stateParamsId);
                          fd.append( "correlationId", groupService.getAllItemsOfGroupButNotSelf(indicator.stateParamsId, indicator).toString());
                          fd.append("customerId", indicator.account.kgenCustomerId);
                          var productCode = (indicator.items[indicator.stateParamsId].order.productType === "Digital") ? indicator.items[indicator.stateParamsId].order.productCode : indicator.items[indicator.stateParamsId].order.directoryNumber;
                          var copySheetIndicator = (indicator.items[indicator.stateParamsId].order.productType === "Digital") ? indicator.items[indicator.stateParamsId].model.copySheetIndicator : indicator.items[indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator;
                          var manageCopyModified = (indicator.items[indicator.stateParamsId].order.productType === "Digital") ? indicator.items[indicator.stateParamsId].model.manageCopyModified : indicator.items[indicator.stateParamsId].model.ManageCopysheet.manageCopyModified;
                          var manageCopyModifiedGraphic = (indicator.items[indicator.stateParamsId].order.productType === "Digital") ? indicator.items[indicator.stateParamsId].model.manageCopyModifiedGraphic : indicator.items[indicator.stateParamsId].model.ManageCopysheet.manageCopyModifiedGraphic;
                          var listingId = (indicator.items[indicator.stateParamsId].order.productType === "Digital") ? indicator.items[indicator.stateParamsId].order.listingId : indicator.items[indicator.stateParamsId].model.ListingId;
                          var documentType = (indicator.items[indicator.stateParamsId].order.actionCode === "Renew") ? "A" : "N";
                          var date = utilsService.getKgenDate(new Date());
                          fd.append("productCode", productCode);
                          fd.append("productIssueNum", indicator.items[indicator.stateParamsId].order.directoryIssueNumber);
                          fd.append("documentType", documentType);
                          fd.append("operatorId", indicator.order['opportunity'].owner.operatorId);
                          fd.append("version", indicator.items[indicator.stateParamsId].order.version);
                          fd.append("date", date);
                          fd.append("billingAccount", indicator.items[indicator.stateParamsId].model.BillingAccount);
                          fd.append("listingId", listingId);
                          fd.append("callTracking", indicator.items[indicator.stateParamsId].model.CallTracking);
                          fd.append("billingBasis", indicator.items[indicator.stateParamsId].model.BillingBasis);
                          fd.append("copySheetIndicator", copySheetIndicator);
                          fd.append("manageCopyModified", manageCopyModified);
                          fd.append("manageCopyModifiedGraphic", manageCopyModifiedGraphic);
                          fd.append("businessProfile", indicator.items[indicator.stateParamsId].model.BusinessProfile);
                          fd.append("modelValue", indicator.items[indicator.stateParamsId].model.modelValue);
                          fd.append("advertiserBudget", indicator.items[indicator.stateParamsId].model.AdvertiserBudget);
                          fd.append("itemVisited", indicator.items[indicator.stateParamsId].model.itemVisited);
                          fd.append("digitalApiFlag", indicator.items[indicator.stateParamsId].model.digitalApiFlag);
                          fd.append("coOpType", indicator.items[indicator.stateParamsId].model.CoOpType);
                          fd.append("isFirstLoadForSEO", indicator.items[indicator.stateParamsId].model.isFirstLoadForSEO);
                          fd.append("attachments", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.attachments);
                          fd.append("copysheetSavePoint", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.copysheetSavePoint);
                          fd.append("actionListObj", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.actionListObj);
                          fd.append("hasCopysheet", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.hasCopysheet);
                          fd.append("isCorrupted", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.isCorrupted);
                          fd.append("isCoysheetSubmitted", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted);
                          fd.append("generatedCopysheet", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.generatedCopysheet);
                          fd.append("isCopysheetSaved", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.isCopysheetSaved);
                          fd.append("needsNewCopysheet", indicator.items[indicator.stateParamsId].model.copySheetSavePoints.needsNewCopysheet);
                          fd.append("dirtyFlag", indicator.items[indicator.stateParamsId].model.dirtyFlag)
                          fd.append("displayName", indicator.user.name);
                          fd.append("email", indicator.user.email);
                          fd.append("historyDate", new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }));
                          fd.append("itemStatus", indicator.items[indicator.stateParamsId].order.status);
                          fd.append("itemActionCode", indicator.items[indicator.stateParamsId].order.actionCode + "-" + indicator.items[indicator.stateParamsId].order.actionType);
                          fd.append("fieldName", 'Copysheet Value');
                          var historyOldCopysheet = (indicator.items[indicator.stateParamsId].hasCopysheet == true) ? indicator.items[indicator.stateParamsId].copy.copysheet : null;
                          fd.append("fieldOldValue",historyOldCopysheet);
                          fd.append("fieldNewValue", null);
                          fd.append("apiStatus", 'Success');
                          fd.append("SFDCItemId", indicator.items[indicator.stateParamsId].order.sfdcItemId)
                          fd.append("listing", indicator.items[indicator.stateParamsId].order.listingTn);
                          fd.append("listingName", indicator.items[indicator.stateParamsId].order.listingName);
                          fd.append("billingMapRequired", 'false');
                          fd.append("producttype", indicator.items[indicator.stateParamsId].order.productType);
                          // fd.append("billingArr",indicator.billing.billing);
                          // fd.append("billingAccountSystem", indicator.account.billingAccountSystem);
                          fd.append("coOpNIAmount", indicator.items[indicator.stateParamsId].model.CoOpNIAmount);
                          let confirmCTN;
                          if (indicator.items[indicator.stateParamsId].order.productType === "Print" || productCode == "YPC") {
                            if (indicator.items[indicator.stateParamsId].model && indicator.items[indicator.stateParamsId].model.CTNconf) {
                              confirmCTN = indicator.items[indicator.stateParamsId].model.CTNconf;
                            } else {
                                /*indicator.items[this.indicator.stateParamsId].model.ManageCopysheet.CTNconf=false;*/
                                confirmCTN = false;
                            }
                        }
                          fd.append("confirmCTN", confirmCTN);
                          let mapRequired = false;
                          if (indicator.billing && indicator.billing.billing) {
                            //console.log(this.indicator.billing, item.model["BillingAccount"]);
                            for (let i = 0; i < indicator.billing.billing.length; i++) {
                                if (indicator.account.billingAccountSystem == constant.BRM_BILLING_DETAILS) {
                                    if (indicator.items[indicator.stateParamsId].model["BillingAccount"] == indicator.billing.billing[i].billing_account_id && indicator.billing.billing[i].profile.is_map == "true") {
                                        mapRequired = true;
                                        break;
                                    }
                                } else {
                                    if (indicator.items[indicator.stateParamsId].model["BillingAccount"] == indicator.billing.billing[i].billingAccountId &&
                                        indicator.billing.billing[i].digitalAdvPayInd == "Y") {
                                        mapRequired = true;
                                        break;
                                    }
                                }
            
                            }
                          }
                          fd.append("billingMapRequired", mapRequired.toString());
                          fd.append("cancelCopySheet", indicator.items[indicator.stateParamsId].copy.cancelCopySheet);
                          fd.append("directoryName", indicator.items[indicator.stateParamsId].order.directoryName);
                          fd.append("heading", indicator.items[indicator.stateParamsId].order.heading);
                          fd.append("headingCode", indicator.items[indicator.stateParamsId].order.headingCode);
                          let retryTimeout;
                          for (let i in indicator.configuration) {
                            if (indicator.configuration[i].name == "COPYSHEET_RETRY_TIMEOUT") {
                              retryTimeout = indicator.configuration[i].value
                            }
                          }
                          fd.append("retryTimeout", retryTimeout);
                          fd.append("categoryCode", indicator.items[indicator.stateParamsId].model.Category);
                          fd.append("geoId", indicator.items[indicator.stateParamsId].model.GeoID);
                          fd.append("geoName", indicator.items[indicator.stateParamsId].model.GeographyName);
                          fd.append("enterpriseAcctId", indicator.account.enterpriseAccountId);
                          fd.append("trackingId", indicator.items[indicator.stateParamsId].model.CallTracking.TrackingId);
                          fd.append("source", indicator.items[indicator.stateParamsId].model.CallTracking.Source);
                          fd.append("optIntoCallTracking", indicator.items[indicator.stateParamsId].model.CallTracking.OptIntoCallTracking);
                          fd.append("SignatureDate", indicator.items[indicator.stateParamsId].model.SignatureDate);
                          // File save call
                        filenew.savePdf(fd).then(resp => {
                          console.log('Saved in Mongo from PDF Tron Lib --> file.safePDF called');
                          let copysheet;
                          let storageID;
                          indicator = JSON.parse(JSON.stringify(indicator));
                          indicator.items[indicator.stateParamsId].isCoysheetSubmitted = true;
                          storageService.updateField("copyz", indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, { "data.isCoysheetSubmitted": true, "data.setBaseImageAdId":setBaseImageAdId  })
                            .subscribe(() => { }, err => { console.log("error in storage update field",err)})

                          if (indicator.items[indicator.stateParamsId].order.statusCode >= 6000) {
                            indicator.items[indicator.stateParamsId].needsNewCopysheet = true;
                          }
                          //Update the new CS in the mongo
                          var oldCopysheet = (indicator.items[indicator.stateParamsId].hasCopysheet == true) ? indicator.items[indicator.stateParamsId].copy.copysheet : null;
                          indicator.items[indicator.stateParamsId].copy.copysheet = resp['copysheet'];
                            indicator.items[indicator.stateParamsId].copy['previousCopySheet'] = resp['copysheet'];
                            console.log('oldCopysheet', oldCopysheet)
                            console.log('resp[copysheet]', resp['copysheet'])
                            /* Update indicator with new copy sheet and get a fresh reference of the indicator */
                            indicatorStore.update(() => {
                              return {
                                indicator: indicator,
                                updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                                isloaded: true,
                              };
                            });
                            indicatorQuery.getIndicator().subscribe(data => {
                              indicator = JSON.parse(JSON.stringify(data));
                            });
                            //console.log('indicator from child window after update ', indicator)
                            copysheet = resp['copysheet'];
                            indicator.items[indicator.stateParamsId].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                          // copysheet = resp['copysheet'];
                          // indicator.items[indicator.stateParamsId].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                          copysheetnew.updateCopysheet(indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, indicator.items[indicator.stateParamsId].copy)
                            .then(response => { 
                              console.log('******response is!!!!!',response);
                              var activityLogHistoryObj = {
                                'displayName': indicator.user.name,
                                'email': indicator.user.email,
                                'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                                'itemId': indicator.stateParamsId,
                                'itemStatus': indicator.items[indicator.stateParamsId].order.status,
                                'itemActionCode':indicator.items[indicator.stateParamsId].order.actionCode + "-" + indicator.items[indicator.stateParamsId].order.actionType,
                                'fieldName': 'Copysheet Value',
                                'fieldOldValue': oldCopysheet,
                                'fieldNewValue': indicator.items[indicator.stateParamsId].copy.copysheet,
                                'apiStatus' : 'Success'
                              }
                              storageService.update("itemHistory", indicator.stateParamsId, activityLogHistoryObj);
                            },err=>{
                              console.log("Error in Update Copysheet",err);
                            })
                            .catch(err => {
                              console.log("Failed to update copysheet :" + JSON.stringify(err));
                              var activityLogHistoryObj = {
                                'displayName': indicator.user.name,
                                'email': indicator.user.email,
                                'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                                'itemId': indicator.stateParamsId,
                                'itemStatus': indicator.items[indicator.stateParamsId].order.status,
                                'itemActionCode':indicator.items[indicator.stateParamsId].order.actionCode + "-" + indicator.items[indicator.stateParamsId].order.actionType,
                                'fieldName': 'Copysheet Value',
                                'fieldOldValue': oldCopysheet,
                                'fieldNewValue': indicator.items[indicator.stateParamsId].copy.copysheet,
                                'apiStatus' : 'Failure'
                              }
                              storageService.update("itemHistory", indicator.stateParamsId, activityLogHistoryObj)
                                .subscribe(()=>{},err=>{console.log(" Error in storage itemhistory update field",err);});
                            });

                          if(isSaveClose){
                            indicator.items[indicator.stateParamsId].hasCopysheet = true;
                          }
                          storageID = indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version;
                          storageService.updateField("copyz", storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.baseCopysheet": indicator.items[indicator.stateParamsId].baseCopysheet, "data.previousCopySheet": indicator.items[indicator.stateParamsId].copy.copysheet })
                            .subscribe(() => { }, err => { console.log("error in storage update field",err) })
                          if (indicator.items[indicator.stateParamsId].hasOwnProperty("generatedCopysheet") && !indicator.items[indicator.stateParamsId]['generatedCopysheet'] ){
                              indicator.items[indicator.stateParamsId].generatedCopysheet = true;
                              storageService.updateField("copyz", storageID, { "data.generatedCopysheet": true })
                                .subscribe(() => { }, err => { console.log(" Error in update filed service ",err); });
                          }
                          logger.logSuccess('Copysheet uploaded successfully.');
                          params['saving'] = false;
                          params['loadingbar'] = false;
                          indicator.items[indicator.stateParamsId].isCoysheetAnnotated = true;
                          //Adding flag for adVersionIncInd of copysheet
                          //indicator.items[indicator.stateParamsId]['adVersionIncInd'] = 'Y';
                          storageService.updateField("item", indicator.stateParamsId, { "data.adVersionIncInd": "Y" }).subscribe(res => {
                            console.log(res)
                        });
                          if (isSaveClose) {
                            dialogRef.close(true);
                          } else {
                            isApply = true;
                          }

                          if (indicator.items[indicator.stateParamsId].model.ManageCopysheet && typeof indicator.items[indicator.stateParamsId].model.ManageCopysheet === "object") {
                            indicator.items[indicator.stateParamsId].model.ManageCopysheet.manageCopyModified = true;
                            //set manageCopyModifiedGraphic value after change in copysheet
                            indicator.items[indicator.stateParamsId].model.ManageCopysheet.manageCopyModifiedGraphic = true;
                            indicator.items[indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
                            storageService.updateField("item", 
                            indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, 
                            { "data.ManageCopysheet.manageCopyModifiedGraphic": indicator.items[indicator.stateParamsId].model.ManageCopysheet.manageCopyModifiedGraphic })
                            .subscribe(res => {});
                          } else {
                            indicator.items[indicator.stateParamsId].model.manageCopyModified = true;
                            //set manageCopyModifiedGraphic value after change in copysheet
                            indicator.items[indicator.stateParamsId].model.manageCopyModifiedGraphic = true;
                            indicator.items[indicator.stateParamsId].model.ManageCopysheet = 'Y';
                            storageService.updateField("item", 
                            indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, 
                            { "data.manageCopyModifiedGraphic": indicator.items[indicator.stateParamsId].model.manageCopyModifiedGraphic })
                            .subscribe(res => {});
                          }
                          indicator.items[indicator.stateParamsId].isCopysheetSaved = true;
                          storageService.updateField("copyz", indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, { "data.isCopysheetSaved": true })
                            .subscribe(() => { }, err => { console.log("Error in storage update field",err)})
                          // var activityLogHistoryObj = {
                          //   'displayName': indicator.user.name,
                          //   'email': indicator.user.email,
                          //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
                          //   'itemId': indicator.stateParamsId,
                          //   'fieldName': 'Copysheet Value',
                          //   'fieldOldValue': oldCopysheet,
                          //   'fieldNewValue': indicator.items[indicator.stateParamsId].copy.copysheet
                          // }
                          // storageService.update("itemHistory", indicator.stateParamsId, activityLogHistoryObj);
                    
                          indicator.items[indicator.stateParamsId].hasCopysheet = true;
                          //set dirtyFlag and persist it
                          indicator.items[indicator.stateParamsId].model.dirtyFlag = true;
                          if (indicator.items[indicator.stateParamsId].requiresCopysheet) {
                            let stateParamsId = indicator.stateParamsId;
                            let tempData = requiredfieldcountservice.getNewItemCount(indicator["items"][stateParamsId].requiredFields, indicator["items"][stateParamsId].model, indicator["items"][stateParamsId].order, indicator["orderPercent"], indicator["items"][stateParamsId].counter, true, indicator["items"][stateParamsId]);
                            updateIndictor(indicator.stateParamsId, indicator, tempData, indicator.items[indicator.stateParamsId].counter);
                            try {
                              if (document.getElementById(stateParamsId + 'counter')) {
                                if (indicator['items'][stateParamsId].counter == 0) {
                                  document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
                                } else if (indicator['items'][stateParamsId].counter != 0) {
                                  document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
                                }
                              }
                            } catch (e) {
                              console.log(e, " exception in the class counter");
                            }
                          }
                          updatCounterStore(indicator);
                          storageService.update("item",
                          indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version,
                          indicator.items[indicator.stateParamsId].model)
                            .then(res => { }).catch(err => { console.log("error in storage update ",err)});
                          ngxspinner.hide();
                          /*if (isSaveClose) {
                            //dialogRef.close(true);                            
                            console.log('Just before closign child window ', indicator)
                            window['childIndicator'] = indicator;
                            window['closingBoolean'] = true;
                            window.close();
                          } else {
                            isApply = true;
                          }*/
                        }).catch(err => { //need to check merge issue***
                          console.log("")
                        },err=>{console.log("error in savepdf",err)}).catch(err => {
                          console.log('error:', err);
                          ngxspinner.hide();
                          logger.logError('Error while saving copysheet.');
                        })

                        // return blob;
                      })
                    }
                    //Below update the counter code
                    function updateIndictor(itemId, data, tempIndicator, currentCount) {
                      let counter = [];
                      console.log('update Indicator');
                      data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
                      data.items[itemId].counter = tempIndicator.itemCount;
                      data.orderPercent = getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
                      let percentage = requiredfieldcountservice.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
                      data.orderPercent.percent = percentage;
                      counter.push(data.orderPercent.digitalCount);
                      counter.push(data.orderPercent.printCount);
                      counter.push(data.orderPercent.bpTotalCount);
                      counterService.setError(counter);
                      updatCounterStore(data);
                    }
                    function updatCounterStore(data) {
                      indicatorStore.setLoading(true);
                      indicatorStore.update(() => {
                        return {
                          indicator: data,
                          updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                          isloaded: true,
                        };
                      });
                      indicatorStore.setLoading(false);
                      indicatorQuery.getIndicator().subscribe(data => {
                      indicator = JSON.parse(JSON.stringify(data));
                      });
                    }

                    function getItemErrorCount(itemCount, orderPercent, currentCount, order) {
                      let counter = 0;
                      let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
                      let productType = order && order.productType ? order.productType : '';
                      if (itemCount == 0 && currentCount != 0) {
                        counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
                        orderPercent = updateCounter(productType, counter, orderPercent);
                      } else if (itemCount != 0 && currentCount == 0) {
                        counter = totalItemCount + 1;
                        orderPercent = updateCounter(productType, counter, orderPercent);
                      } else {
                        //  counter = totalItemCount;
                      }
                      currentCount = counter;

                      return orderPercent;
                    }
                    function updateCounter(productType, counter, orderPercent) {
                      if (productType && productType === 'Digital') {
                        orderPercent.digitalCount = counter - orderPercent.printCount;
                      } else if (productType && productType === 'Print') {
                        orderPercent.printCount = counter - orderPercent.digitalCount;
                      }
                      return orderPercent;
                    }
                  })
                }
              }
            }
          ]
        },
        {
          target: 'copyright',
          template: [
            '<div class="custom-btn-flex-wrap">',
            '<xbutton name="copyright-btn" @tooltip tooltip-title="Copyright" tooltip-placement="right" icon-class="fv__icon-dialog-level-prompt"></xbutton>',
            '<template>',
            '<layer name="copyright-layer" class="center my-layer">',
            '<layer-header title="Copyright"></layer-header>',
            '<text>Content Assistant PDF Reader Powered by Foxit. Copyright (C) 2003-2021 by Foxit Software Incorporated</text>',
            '</layer>',
            '</template>',
            '</div>',
          ].join(''),
          action: FRAGMENT_ACTION.APPEND,
          config: [
            {
              target: ['copyright-btn'],
              callback: () => {
                this.pdfui.getRootComponent().then(root => {
                  const layer = root.getComponentByName('copyright-layer');
                  layer.show();
                });
              }
            }
          ]
        }],
      addons: [
        '../../../foxit-lib/uix-addons/edit-graphics',
        '../../../foxit-lib/uix-addons/export-form',
        '../../../foxit-lib/uix-addons/file-property',
        '../../../foxit-lib/uix-addons/form-designer',
        '../../../foxit-lib/uix-addons/form-to-sheet',
        '../../../foxit-lib/uix-addons/full-screen',
        '../../../foxit-lib/uix-addons/h-continuous',
        '../../../foxit-lib/uix-addons/h-facing',
        '../../../foxit-lib/uix-addons/h-single',
        '../../../foxit-lib/uix-addons/import-form',
        '../../../foxit-lib/uix-addons/multi-media',
        '../../../foxit-lib/uix-addons/page-template',
        '../../../foxit-lib/uix-addons/password-protect',
        '../../../foxit-lib/uix-addons/path-objects',
        '../../../foxit-lib/uix-addons/print',
        '../../../foxit-lib/uix-addons/read-aloud',
        '../../../foxit-lib/uix-addons/recognition-form',
        '../../../foxit-lib/uix-addons/redaction',
        '../../../foxit-lib/uix-addons/text-object',
        '../../../foxit-lib/uix-addons/thumbnail',
        '../../../foxit-lib/uix-addons/undo-redo',
        '../../../foxit-lib/uix-addons/xfa-form'
      ],
      appearance: CustomAppearance
    });
    (window as any).pdfui = this.pdfui;
    (window as any).pdfui_g = this.pdfui;
    this.pdfui.getRootComponent().then(root => {
      this.applycomponent = root.getComponentByName('apply-btn')
      this.saveComponent = root.getComponentByName('saveclose-btn')
      stampComponent = root.getComponentByName('popupimage')
      customImageComponent = root.getComponentByName('addFile')
      previousComponent = root.getComponentByName('previousversion')

      if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew' && this.Indicator.items[this.Indicator.stateParamsId].order.actionType != "Update") {
        //&&  !this.Indicator.items[this.Indicator.stateParamsId].model['digitalApiFlag']
        this.enableButtons = true;
      }
      if (this.enableButtons) {
        this.applycomponent.disable();
        this.saveComponent.disable();
      }
      if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
          stampComponent.disable();
      }
      // if (this.Indicator.items[this.Indicator.stateParamsId].copy.previousCopySheet == null) {
      //   previousComponent.disable();
      // }
    }),
    this.pdfui.eventEmitter.on("annotation-removed", async (removedAnnots) => {
      var pdfUI = this.pdfui;
      var pdfViewer = await pdfUI.getPDFViewer();
      var pdfDoc = await pdfViewer.getCurrentPDFDoc();
      var pdfPage = await pdfDoc.getPageByIndex(0);

      console.log("annotation removed", removedAnnots)
      this.counterService.setbuttonEnableFlag(false);
      if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew' && this.Indicator.items[this.Indicator.stateParamsId].order.actionType != 'Update') {
        this.applycomponent.enable();
        this.saveComponent.enable();
      }
      if (removedAnnots && removedAnnots[0] &&
        indicator &&
        indicator.items &&
        indicator.stateParamsId !== undefined &&
        indicator.items[indicator.stateParamsId] &&
        indicator.items[indicator.stateParamsId].copy &&
        removedAnnots[0].id !== undefined &&
        indicator.items[indicator.stateParamsId].copy.setBaseAdImgAnnotId !== undefined &&
        removedAnnots[0].id === indicator.items[indicator.stateParamsId].copy.setBaseAdImgAnnotId) {        
        console.log('Removal of set based image detected')
        let msg = 'You are trying to remove setbased image, please confirm to proceed';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'confirmaton-dialog';
        dialogConfig.width = '600px';
        dialogConfig.data = { message: msg }

        const dialogRefConfirm = this.matDialog.open(CallconfirmationComponent, dialogConfig);
        dialogRefConfirm.afterClosed().subscribe(async dialogResult => {          
          console.log(dialogResult)
          if (dialogResult) {
            let pdfpage = removedAnnots[0].getPage();
            // Logic to remove the AdID associated to the setbased image
            if (pdfpage.getAnnotCount() > 0) {
              await pdfpage.getAnnots().then(async (res) => {
                console.log('All annotations : ', res);
                console.log('setBaseAdIdAnnotId : ', indicator.items[indicator.stateParamsId].copy.setBaseAdIdAnnotId);
                if (res != null && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    console.log("Annotation :: ", res[i].id);
                    if (res[i].id == indicator.items[indicator.stateParamsId].copy.setBaseAdIdAnnotId ) {
                      //res[i].id == indicator.items[indicator.stateParamsId].copy.SetBaseAdIdBody
                      console.log('Removing free text of setBased image') 
                      await pdfpage.removeAnnotById(res[i].id);
                      // (window as any).pdfui.callAddonAPI("UndoRedoAddon", "undo");
                    } 
                  }
                }
                setBaseImageAdId = "";
                selectedAdId = "";
                storageService.updateField("copyz", 
                indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, 
                { "data.setBaseImageAdId": "" })
                              .subscribe(() => { }, err => { console.log("Error in storage update field",err)})
                
                indicator.items[indicator.stateParamsId].copy['setBaseAdImgAnnotId'] = "";
                indicator.items[indicator.stateParamsId].copy['setBaseAdIdAnnotId'] = "";
                indicatorStore.update(() => {
                  return {
                    indicator: indicator,
                    updatedFrom: "[pdfViewerComponent] annotation-removed",
                    isloaded: true,
                  };
                });
                indicatorStore.setLoading(false);
                indicatorQuery.getIndicator().subscribe(data => {
                  indicator = JSON.parse(JSON.stringify(data));
                });
              })
            }
          } else {
            (window as any).pdfui.callAddonAPI("UndoRedoAddon", "undo");
          }
        });
      }
      if (indicator.items[indicator.stateParamsId].copy.setBaseAdIdAnnotId &&
        removedAnnots[0].info.subject == 'customFreeText') {
        // (window as any).pdfui.callAddonAPI("UndoRedoAddon", "undo");

          const annotJson2 = {
            type: 'freetext',
            contents: setBaseImageAdId,
            rect: {
              bottom: 728,
              left: 530,
              right: 606,
              top: 738,
            },
            subject: 'customFreeText',
            name: 'cstm-freeText-sbAd',
            borderInfo: {
              width: 0,
            },
            defaultAppearance: {
              textSize: 9,
            }
          }
  
          await pdfPage.addAnnot(annotJson2).then(async res => {
            console.log('SetBased free text added :: ', res)
            indicator.items[indicator.stateParamsId].copy['setBaseAdIdAnnotId'] = res[0].id
            let msg = 'Ad Id can not be deleted';
            logger.annotwarning(msg);
            indicatorStore.update(() => {
              return {
                indicator: indicator,
                updatedFrom: "[pdfViwerComponent] annotation-removed",
                isloaded: true,
              };
            });          
            indicatorQuery.getIndicator().subscribe(data => {
              indicator = JSON.parse(JSON.stringify(data));
            });
          });
      }
    })
    this.pdfui.eventEmitter.on("open-file-success",  (pdfDoc) =>{
      openFolder= openFolder+1;
      if(openFolder>1){
      this.counterService.setbuttonEnableFlag(false);
      if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew' && this.Indicator.items[this.Indicator.stateParamsId].order.actionType != 'Update') {
        //&&  !this.Indicator.item.model['digitalApiFlag']
        this.applycomponent.enable();
        this.saveComponent.enable();
      }
    }
    })

      // this.pdfui.eventEmitter.on("annotation-add", (annotations) => {
      //   console.log("annotation done", annotations)
      //   this.counterService.setbuttonEnableFlag(false);
      //   if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew' && this.Indicator.items[this.Indicator.stateParamsId].order.actionType != 'Update') {
      //     //&&  !this.Indicator.item.model['digitalApiFlag']
      //     this.applycomponent.enable();
      //     this.saveComponent.enable();
      //   }
      // })
      this.pdfui.eventEmitter.on("annotation-add", (annotations) => {
        console.log("annotation done", annotations)
        annotations.forEach(async function (annotation) {
          console.log(annotation);
        })
        this.counterService.setbuttonEnableFlag(false);
        if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew' && this.Indicator.items[this.Indicator.stateParamsId].order.actionType != 'Update') {
          //&&  !this.Indicator.item.model['digitalApiFlag']
          this.applycomponent.enable();
          this.saveComponent.enable();
        }
        if(this.Indicator.items[this.Indicator.stateParamsId].copy['headercopysheet'] ){
        
        //Requirement change after demo for header annotation
        if (annotations[0].info.rect.bottom <= 124) {
        // if ((annotations[0].info.rect.top >= 626) || (annotations[0].info.rect.bottom <= 124)) {
          var alert_flag = false;
          console.log("inside copysheet header/footer , annotation-add");
          annotations.forEach(async function (annotation) {
            if ((annotation.info.rect.top != 0) && (annotation.info.rect.bottom != 0)) {
              if (annotation.info.subject != 'customFreeText') {
                var pdfpage = annotation.getPage();
                pdfpage.removeAnnotById(annotation.getUniqueID());
                alert_flag = true;
              }
            }
          });
          if (alert_flag) {
            let msg = 'Do not draw in footer';
            logger.annotwarning(msg);
          }
        }
        else {
          console.log("inside copysheet body");
        }
      }
      })
      //need to check merge issue*** 
      console.log(indicator.items[indicator.stateParamsId].copy.copysheet);
    // this.pdfui.openPDFByHttpRangeRequest({
    //   range: {
    //     url: indicator.items[indicator.stateParamsId].copy.copysheet,
    //   }
    // })
    this.pdfui.eventEmitter.on("annotation-appearance-updated", (movedAnnots, page, trigger) => {
      console.log('Inside annotation appearance updated');

      //Requirement change after demo for header annotation
      if (movedAnnots[0].info.rect.bottom <= 124) {
      // if ((movedAnnots[0].info.rect.top >= 626) || (movedAnnots[0].info.rect.bottom <= 124)) {
        var alert_flag = false
        console.log("inside copysheet header/footer");
        movedAnnots.forEach(async function (annotation) {
          // if(annotation.info.type == 'screen'){
          //(window as any).pdfui.callAddonAPI("UndoRedoAddon", "undo");
          //annotation.setRect(500,90,190,600);
          //}
          // if (annotation.info.subject != 'customFreeText') {
          //   var pdfpage = annotation.getPage();
          //   pdfpage.removeAnnotById(annotation.getUniqueID());
          //   alert_flag = true;
          // }
        });
        if (alert_flag) {
          let msg = 'Do not draw in footer';
          logger.annotwarning(msg);
        }
      }
    })
    this.pdfui.eventEmitter.on("doc-modified", (PDFpage) => {
      console.log('Inisde doc-modified');
      var alert_flag = false;
      if(this.Indicator.items[this.Indicator.stateParamsId].copy['headercopysheet'] ){ 
      PDFpage.pageCache[0].annots.forEach(async function (annotation) {
        if ((annotation.info.rect.top != 0) && (annotation.info.rect.bottom != 0)) {

          //Requirement change after demo for header annotation
          if ((annotation.info.rect.bottom <= 124) && (annotation.info.subject != 'customFreeText')) {
          // if (((annotation.info.rect.top >= 626) || (annotation.info.rect.bottom <= 124)) && (annotation.info.subject != 'customFreeText')) {
            console.log("inside copysheet header/footer");
            (window as any).pdfui.callAddonAPI("UndoRedoAddon", "undo");
            //if (annotation.info.subject != 'customFreeText' && annotation.info.subject != 'Borderline') {
            // var pdfpage = annotation.getPage();
            // pdfpage.removeAnnotById(annotation.getUniqueID());
            alert_flag = true;
            // }
          }
        }
      });
      if (alert_flag) {
        let msg = 'Do not draw in footer';
        logger.annotwarning(msg);
      }
    }
    })
    this.pdfui.eventEmitter.on("render-page-success", async (page) => {
      var pdfUI = this.pdfui;
      var pdfViewer = await pdfUI.getPDFViewer();
      var pdfDoc = await pdfViewer.getCurrentPDFDoc();
      var pdfPage = await pdfDoc.getPageByIndex(0);

      if (pdfPage.getAnnotCount() > 0) {
        await pdfPage.getAnnots().then(async (res) => {
          if (res != null && res.length > 0 && res.length > 1) {
            for (let i = 0; i < res.length; i++) {
              console.log('---------------------------------------------');
              console.log('Array index : ', i)
              console.log('Annotation : ', res[i])
              console.log('Annotation ID : ', res[i].id)
              console.log('Annotation type  : ', res[i].info.type)
              console.log('---------------------------------------------');
            }
          }
        })
      }

      if (setBaseBoolean) {
        console.log('Inside renderpage success');
        console.log('pdfPage after generating new copy sheet with set based ad ', pdfPage);
        console.log('Annotations count : ', pdfPage.getAnnotCount())
        if (pdfPage.getAnnotCount() > 0) {
          await pdfPage.getAnnots().then(async (res) => {
            if (res != null && res.length > 0 && res.length > 1) {
              for (let i = 0; i < res.length; i++) {
                console.log('Array index : ', i)
                console.log('Annotation : ', res[i])
                console.log('Annotation ID : ', res[i].id)
                console.log('Annotation type  : ', res[i].info.type)
                if (i != res.length - 1)
                  await pdfPage.removeAnnotById(res[i].id);
              }
            }
          })
        }
        const annotJson2 = {
          type: 'freetext',
          contents: setBaseImageAdId,
          rect: {
            bottom: 728,
            left: 530,
            right: 606,
            top: 738,
          },
          subject: 'customFreeText',
          // name: 'cstm-freeText-sbAd',
          borderInfo: {
            width: 0,
          },
          defaultAppearance: {
            textSize: 9,
          }
        }
        // const annotJson3 = {
        //   type: 'freetext',
        //   contents: setBaseImageAdId,
        //   rect: {
        //     bottom: 601,
        //     left: 290,
        //     right: 368,
        //     top: 612,
           
        //   },
        //   subject: 'customFreeText',
        //   borderInfo: {
        //     width: 0,
        //   },
        //   defaultAppearance: {
        //     textSize: 9,
        //   }
        // }
        
        // await pdfPage.addAnnot(annotJson3).then(async res => {
        //   console.log('SetBased free text  json3 :: ', res)
        //   indicator.items[indicator.stateParamsId].copy['SetBaseAdIdBody'] = res[0].id
        //   indicatorStore.update(() => {
        //     return {
        //       indicator: indicator,
        //       updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
        //       isloaded: true,
        //     };
        //   });          
        //   indicatorQuery.getIndicator().subscribe(data => {
        //     indicator = JSON.parse(JSON.stringify(data));
        //   });
        // });
        // setBaseBoolean = false;

        await pdfPage.addAnnot(annotJson2).then(async res => {
          console.log('SetBased free text added :: ', res)
          indicator.items[indicator.stateParamsId].copy['setBaseAdIdAnnotId'] = res[0].id
          indicatorStore.update(() => {
            return {
              indicator: indicator,
              updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
              isloaded: true,
            };
          });          
          indicatorQuery.getIndicator().subscribe(data => {
            indicator = JSON.parse(JSON.stringify(data));
          });
        });
        setBaseBoolean = false;
      }
      if (indicator.items[indicator.stateParamsId].isCopySheetUploaded) {
        if (!(ucs_flag) && (uploadObj.src)) {
          console.log("Upload object", uploadObj)
          ngxspinner.show();
          uploadCS(pdfUI, uploadObj);
          ngxspinner.hide();
          ucs_flag = true;
        }
      }
    });
    // this.pdfui.eventEmitter.on("open-file-success", async (pdfpage) =>{
    //   border_flag = false;
    //   var pdfUI = this.pdfui;
    //   var pdfViewer = await pdfUI.getPDFViewer();
    //   var pdfDoc = await pdfViewer.getCurrentPDFDoc();
    //   var pdfPage = await pdfDoc.getPageByIndex(0);
    //   this.pdfui.eventEmitter.on("render-page-success", async (page) => {
    //     if (pdfPage.getAnnotCount() > 0) {
    //       await pdfPage.getAnnots().then(async (res) => {
    //         if (res != null && res.length > 0 && res.length > 1) {
    //           for (let i = 0; i < res.length; i++) {
    //             if(res[i].info.subject === "Borderline"){
    //               border_flag = true;
    //               break;
    //             }
    //           }
    //         }
    //       })
    //     }
    //     if (!(border_flag)) {
    //     //  addMargin(pdfUI);
    //      border_flag = true;
    //     }
    //   })

    // })
    // async function addMargin(pdfUI) {
    //   var pdfUI = pdfUI;
    //   var pdfViewer = await pdfUI.getPDFViewer();
    //   var pdfDoc = await pdfViewer.getCurrentPDFDoc();
    //   var pdfPage = await pdfDoc.getPageByIndex(0);

    //   const annotJson = {
    //     type: 'line',
    //     rect: {
    //       bottom: 625,
    //       left: 0,
    //       right: 700,
    //       top: 625,
    //     },
    //     subject: 'Borderline',
    //     borderInfo: {
    //       width: 1,
    //     }
    //   }
    //   const annotJson1 = {
    //     type: 'line',
    //     rect: {
    //       bottom: 125,
    //       left: 0,
    //       right: 700,
    //       top: 125,
    //     },
    //     subject: 'Borderline',
    //     borderInfo: {
    //       width: 1,
    //     }
    //   }
    //   pdfPage.addAnnot(annotJson);
    //   pdfPage.addAnnot(annotJson1);
    // }
    async function fileopen(file, pdfUI) {
      var pdfUI = pdfUI;
      var pdfViewer = await pdfUI.getPDFViewer();
      var pdfDoc = await pdfViewer.getCurrentPDFDoc();
      var pdfPage = await pdfDoc.getPageByIndex(0);
      if (file.type == 'application/pdf') {
        var base64: any;
        var file2 = new Blob([file], { type: 'application/pdf' });
        var base64data;

        const blobToData = (blob: Blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
          })
        }
        console.log("before calling API")
        base64data = await blobToData(file2);
        await filenew.pdftoimage({ 'data': base64data }).then((res) => {
          console.log("PDFtoImage API call response:", res);
          base64 = res;
        })
        var unit8Arr = await _base64ToArrayBuffer(base64);
        await addAnnotation(unit8Arr);
        saveAttachment(base64);
        function _base64ToArrayBuffer(base64) {
          var binary_string = window.atob(base64);
          var len = binary_string.length;
          var bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          return bytes.buffer;
        }
      }
      else if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/bmp') {
        var img = file;
        if (img && img.size < 2097152) {
          var io = new Image();
          io.src = URL.createObjectURL(img);
          io.onload = async function (t) {
            let co = document.createElement("canvas");
            co['width'] = this['width'],
              co['height'] = this['height'],
              co['srcWidth'] = this['width'],
              co['srcHeight'] = this['height'];
            const ctxt = co.getContext("2d");
            ctxt.drawImage(io, 0, 0, co.width, co.height);
            var iblob = co.toDataURL('image/png', 1.0);
            const view = await imageToUint8Array(ctxt);
            await addAnnotation(view);
            saveAttachment(iblob);
          }
        }
        function imageToUint8Array(ctxt) {
          return new Promise((resolve, reject) => {
            ctxt.canvas.toBlob(blob => blob.arrayBuffer()
              .then(buffer => resolve(new Uint8Array(buffer))).catch(reject)
            )
          });
        }
      }
      else {
        logger.logError("Only image and pdf files are supported!");
      }
      async function addAnnotation(view) {
        const annotJson = {
          type: 'screen',
          buffer: view,
          rect: {
            bottom: 150,
            left: 80,
            right: 560,
            top: 600,
          }
        }
        pdfPage.addAnnot(annotJson);
      }
      function saveAttachment(iblob) {
        try {
          filenew.saveImageToPromise(indicator.stateParamsId, { 'data': iblob }, "attachment")
            .then((response) => {
              let attachmentUrl = api.get('/api/file/' + response._id + '/' + response.filename);
              console.log(" attachment url generated is : ", attachmentUrl);
              if (!indicator.items[indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                indicator.items[indicator.stateParamsId].copy.attachments = [];
                indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
              }
              else {
                indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
              }
              indicatorStore.setLoading(true);
              indicatorStore.update(() => {
                return {
                  indicator: indicator,
                  updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                  isloaded: true,
                };
              });
              indicatorStore.setLoading(false);
              indicatorQuery.getIndicator().subscribe(data => {
                indicator = JSON.parse(JSON.stringify(data));
              });
              console.log("saveAttachment", indicator);
            }, err => {
              console.log("error", err)
            }).catch(e => {
              console.log(" error in saveImageToPromise : " + e);
            })
        } catch (error) {
          console.log("error", error)
        }
      };
    }
    async function uploadCS(pdfUI, uploadObj) {
      var pdfUI = pdfUI;
      var pdfViewer = await pdfUI.getPDFViewer();
      var pdfDoc = await pdfViewer.getCurrentPDFDoc();
      var pdfPage = await pdfDoc.getPageByIndex(0);
      if (uploadObj.type == 'pdf') {
        var base64;
        console.log("Base64", uploadObj.src.__zone_symbol__value)
        var base64val = uploadObj.src.__zone_symbol__value;
        await filenew.pdftoimage({ 'data': base64val }).then((res) => {
          console.log("PDFtoImage API call response:", res);
          base64 = res;
        })
        var unit8Arr = await _base64ToArrayBuffer(base64);
        await addAnnotation(unit8Arr);
        saveAttachment(base64);
        function _base64ToArrayBuffer(base64) {
          var binary_string = window.atob(base64);
          var len = binary_string.length;
          var bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          return bytes.buffer;
        }
      }
      else if (uploadObj.type == 'image') {
        var img = uploadObj.src;
        var io = new Image();
        io.src = img;
        io.onload = async function (t) {
          let co = document.createElement("canvas");
          co['width'] = this['width'],
            co['height'] = this['height'],
            co['srcWidth'] = this['width'],
            co['srcHeight'] = this['height'];
          const ctxt = co.getContext("2d");
          ctxt.drawImage(io, 0, 0, co.width, co.height);
          var iblob = co.toDataURL('image/png', 1.0);
          const view = await imageToUint8Array(ctxt);
          await addAnnotation(view);
        }
        function imageToUint8Array(ctxt) {
          return new Promise((resolve, reject) => {
            ctxt.canvas.toBlob(blob => blob.arrayBuffer()
              .then(buffer => resolve(new Uint8Array(buffer))).catch(reject)
            )
          });
        }
      }
      async function addAnnotation(view) {
        const annotJson = {
          type: 'screen',
          buffer: view,
          rect: {
            bottom: 150,
            left: 80,
            right: 560,
            top: 600,
          },
          subject: 'UploadCS',
        }
        pdfPage.addAnnot(annotJson);
      }
      function saveAttachment(iblob) {
        try {
          filenew.saveImageToPromise(indicator.stateParamsId, { 'data': iblob }, "attachment")
            .then((response) => {
              let attachmentUrl = api.get('/api/file/' + response._id + '/' + response.filename);
              console.log(" attachment url generated is : ", attachmentUrl);
              if (!indicator.items[indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                indicator.items[indicator.stateParamsId].copy.attachments = [];
                indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
              }
              else {
                indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
              }
              indicatorStore.setLoading(true);
              indicatorStore.update(() => {
                return {
                  indicator: indicator,
                  updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
                  isloaded: true,
                };
              });
              indicatorStore.setLoading(false);
              indicatorQuery.getIndicator().subscribe(data => {
                indicator = JSON.parse(JSON.stringify(data));
              });
              console.log("saveAttachment", indicator);
            }, err => {
              console.log("error", err)
            }).catch(e => {
              console.log(" error in saveImageToPromise : " + e);
            })
        } catch (error) {
          console.log("error", error)
        }
      };
      //Setting isCopySheetUploaded flag to false
      indicator.items[indicator.stateParamsId].isCopySheetUploaded = false;
      //Commented Remove upload object for view annotate
      // indicator.items[indicator.stateParamsId].copy.uploadFile.fileObj = {};  
      // if (indicator.items[indicator.stateParamsId].data
      //   && indicator.items[indicator.stateParamsId].data.iaGroupId) {
      //   //go through the other items on the order
      //   for (var key in indicator.items) {
      //     //if the other item has the matching ia group id & isn't the current item                
      //     if (indicator.items[key].data
      //       && indicator.items[key].data.iaGroupId === indicator.items[indicator.stateParamsId].data.iaGroupId
      //       && indicator.items[key].order.svItemId !== indicator.stateParamsId) {
      //         if(indicator.items[key].copy.uploadFile){
      //           indicator.items[key].copy.uploadFile.fileObj = {};
      //         }
      //       }
      //     }
      //   }
      indicatorStore.update(() => {
        return {
          indicator: indicator,
          updatedFrom: "[CopysheetAnnotateComponent] uploadcopysheet",
          isloaded: true,
        };
      });
      indicatorQuery.getIndicator().subscribe(data => {
        indicator = JSON.parse(JSON.stringify(data));
      });
    }
      
    this.pdfui.eventEmitter.on('add-edit-image', (pdfDoc) => {
        let base64;
        var xhr = new XMLHttpRequest();
               xhr.open('GET', pdfDoc, true);
               xhr.responseType = 'blob';
               xhr.onload =  function(e) {
              if (this.status == 200) {
                let myblob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(myblob); 
                reader.onloadend = function(){ //conversion to base64
                  base64 = reader.result;
                  console.log("Base 64",base64)
                  saveAttachment(base64);
                  return;
                } 
              }
               };
               xhr.send();                     
    })

    function saveAttachment (imageBlobObj) {
      try {
          filenew.saveImageToPromise(indicator.stateParamsId, { 'data': imageBlobObj}, "attachment")
            .then((response) => {
              let attachmentUrl = api.get('/api/file/' + response._id + '/' + response.filename);
              console.log(" attachment url generated is : ", attachmentUrl);
              if (!indicator.items[indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
                indicator.items[indicator.stateParamsId].copy.attachments = [];
                indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
              }
              else {
                indicator.items[indicator.stateParamsId].copy.attachments.push(attachmentUrl);
              }
              console.log("saveAttachment",indicator);
            },err=>{
              console.log("error",err)
            }).catch(e => { 
              console.log(" error in saveImageToPromise : " + e);
            })
      } catch (error) {
        console.log("error",error)
      }
    };
    this.pdfui.eventEmitter.on("open-file-failed", (e) => {
      if (e && typeof e === 'object') {
        e.error = 0;
        console.log(e);
    } else {
        console.error('Event object (e) is null or not an object.');
    }

      const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
      logger.logFormattedError(errorMsg);

      let msg = 'Please select a valid file type (PDF)';
      logger.filewarning(msg);
      this.applycomponent.disable(); 
      this.saveComponent.disable();
    })
    // this.pdfui.eventEmitter.on("add-annot-success", (e) => {
    //   console.log(e)
    //   if(e[0].fileSpec.fileSpec){
    //   const filearray = e[0].fileSpec.fileSpec.fileName.split(".");
    //   let allowedExtensions = ['pdf'];
    //   if (!allowedExtensions.includes(filearray[1].toLowerCase())) {
    //     logger.logError('Please select a valid file type (pdf).');
    //     var pdfpage = e[0].getPage();
    //     pdfpage.removeAnnotById(e.getUniqueID());
    //     return;
    //   }
    // }
    // })
    this.pdfui.eventEmitter.on('image-added', (e) => {
      e.error = 0;
      console.log('file', e);
      let msg = 'Please select a valid file type (pdf)';
      logger.filewarning(msg);
      e.graphicObjects[0].remove();
    })


    this.pdfui.openPDFByHttpRangeRequest({
      range: {
        url: indicator.items[indicator.stateParamsId].copy.copysheet,
      }
    })
   
    this.pdfui.setDefaultAnnotConfig(function(type,intent){
      let config : any ={};
      config.borderInfo = {};
      config.defaultAppearance = {};
      switch (type) {
          case 'square':
              config.color=0x000000;
              // config.borderInfo.style = 1;
              config.borderInfo.width = 1;
              break;
          case 'circle':
              config.color=0x000000;
              // config.borderInfo.style = 1;
              config.borderInfo.width = 1;
              break;
          case 'line':
              config.color=0x000000;
              // config.borderInfo.style = 1;
              config.borderInfo.width = 1;
                  break;  
          case 'arrow':
               config.color=0x000000;
              //  config.borderInfo.style = 1;
                  break;
          case 'polygon':
                config.color=0x000000;
                // config.borderInfo.style = 1;
                config.borderInfo.width = 1;
                   break;                  
          case 'ink'://the name is  pencil
            config.color= 0x000000;  
             config.borderInfo.style = 1;
            config.borderInfo.width = 1;
              break;      
          case 'strikeout':
                config.color='black';
                config.borderInfo.style = 1;
                config.borderInfo.width = 1;
                break;          
          case 'freetext':
             config.color=0x000000;//the border color black
            config.opacity = 1;
            config.borderInfo.width = 1;
            config.defaultAppearance.textColor = 0x000000; //the text color yellow
            config.defaultAppearance.textSize = 11;
             break;                  
      }
  
      return config;
  
  });
  
  }

}