import { Component, OnInit } from '@angular/core';
import { BillingService } from '@app/service/billing/billing.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';
import { StorageService } from '@app/service/storage/storage.service';
import { UtilsService } from '@app/service/utils/utils.service';

import {dexHubItems,dexHubAddOn} from 'app/data/constants'
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import * as _ from 'lodash';

@Component({
  selector: 'app-digital-item',
  templateUrl: './digital-item.component.html',
  styleUrls: ['./digital-item.component.scss']
})
export class DigitalItemComponent implements OnInit {

  parentId;
  isParent :boolean= false;
  productCode;
  productName ;
  Indicator;
  stateParamsId;
  renewDigital:boolean = false;
  constructor(private storageService :StorageService,
    private utils :UtilsService,
    private indicatorQuery :IndicatorQuery,
    private indicatorStore :IndicatorStore,
    private salesforceService :SalesforceService ,
    private logger :LoggerService,
    private billingService:BillingService) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
        this.Indicator = JSON.parse(JSON.stringify(res));
        this.stateParamsId = this.Indicator.stateParamsId;
        this.productCode =this.Indicator.items[this.stateParamsId].order.productCode ;
        this.productName =this.Indicator.items[this.stateParamsId].order.productName;
    });
    let itemsData = this.Indicator.items;
    let itemsArr = Object.keys(itemsData);
    itemsArr.forEach((item, index) => {
      if(itemsData[item].order.actionCode == "Renew" && itemsData[item].order.productType.toLowerCase() == "digital"){
        //alert("Renew")
        this.renewDigital = true;
      }
    })
  }
  onSubmit(indicator){
    let  tempIndicator =JSON.parse(JSON.stringify(indicator));
    if (this.productCode === "SEOG Add On") {
      if (tempIndicator.items[this.stateParamsId].order.MarketMRC) {
          let marketFloor = tempIndicator.items[this.stateParamsId].order.MarketMRC;
          if (marketFloor < 2500) {
              tempIndicator.items[this.stateParamsId].model.ConfirmationNumber = tempIndicator.items[this.stateParamsId].model.ProposalID;
             // tempIndicator.items[this.stateParamsId].model.ConfirmationNumber = tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID;
             // tempIndicator.items[this.stateParamsId].model.ProposalID = null;
              //delete tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID;
          } else if (marketFloor >= 2500) {
              tempIndicator.items[this.stateParamsId].model.ConfirmationNumber = null;
              tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID = tempIndicator.items[this.stateParamsId].model.ProposalID;
              //tempIndicator.items[this.stateParamsId].model.ProposalID = tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID;
              // if(tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID && tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID.length <5){
              //     let errorMsg = "<strong> ProposalID for this item id- '" +this.stateParamsId +" 'is less than 5 digit.Please update ProposalID for 5 digit.</strong><br>" ;
              //     logger.logError(errorMsg);
              // }
              //delete tempIndicator.items[this.stateParamsId].model.ConfirmationNumberProposalID;
          }
      }
    }
    if (this.productCode === "DPM") {
      let storageID = this.stateParamsId + "v" + tempIndicator.items[this.stateParamsId].order.version;
      this.storageService.updateField("DPMBP", storageID, { "data.firstTimeLoaded": true })
      .subscribe(()=>{},err=>{})
      if (tempIndicator.items[this.stateParamsId].model.Products) {
          for (let i = 0; i < tempIndicator.items[this.stateParamsId].model.Products.length; i++) {
              if ('isSelected' in tempIndicator.items[this.stateParamsId].model.Products[i]) {
                  delete tempIndicator.items[this.stateParamsId].model.Products[i]['isSelected'];
              }
              if ('catkeySelected' in tempIndicator.items[this.stateParamsId].model.Products[i]) {
                  delete tempIndicator.items[this.stateParamsId].model.Products[i]['catkeySelected'];
              }
          }
      }
      if (tempIndicator.items[this.stateParamsId].model.Services) {
          for (let i = 0; i < tempIndicator.items[this.stateParamsId].model.Services.length; i++) {
              if ('isSelected' in tempIndicator.items[this.stateParamsId].model.Services[i]) {
                  delete tempIndicator.items[this.stateParamsId].model.Services[i]['isSelected'];
              }
              if ('catkeySelected' in tempIndicator.items[this.stateParamsId].model.Services[i]) {
                  delete tempIndicator.items[this.stateParamsId].model.Services[i]['catkeySelected'];
              }
          }
      }
      if (tempIndicator.items[this.stateParamsId].model.Brands) {
          for (let i = 0; i < tempIndicator.items[this.stateParamsId].model.Brands.length; i++) {
              if ('isSelected' in tempIndicator.items[this.stateParamsId].model.Brands[i]) {
                  delete tempIndicator.items[this.stateParamsId].model.Brands[i]['isSelected'];
              }
              if ('catkeySelected' in tempIndicator.items[this.stateParamsId].model.Brands[i]) {
                  delete tempIndicator.items[this.stateParamsId].model.Brands[i]['catkeySelected'];
              }
          }
      }
    }

    //Check if MC has entered correct Destination and Display URL
    if (tempIndicator.items[this.stateParamsId].model.CampaignType) {
      for (let i = 0; i < tempIndicator.items[this.stateParamsId].model.CampaignType.length; i++) {
          if (tempIndicator.items[this.stateParamsId].model.CampaignType[i].DisplayURL && tempIndicator.items[this.stateParamsId].model.CampaignType[i].DestinationURL) {
              if (!(this.utils.validateURL(tempIndicator.items[this.stateParamsId].model.CampaignType[i].DestinationURL,''))) {
                  let errorMsg = "<strong>Please Enter Correct Destination URL</strong><br>" + "for " + tempIndicator.items[this.stateParamsId].model.CampaignType[i].CampaignType + " Campaign " + tempIndicator.items[this.stateParamsId].model.CampaignType[i].CampaignDescription;
                  //logger.logError(errorMsg);
                  //reject();
                 // return;
              }
          }
          if (tempIndicator.items[this.stateParamsId].model.CampaignType[i].DisplayURL === "") {
              let errorMsg = "<strong>Please Enter a Display URL</strong><br>" + "for " + tempIndicator.items[this.stateParamsId].model.CampaignType[i].CampaignType + " Campaign " + tempIndicator.items[this.stateParamsId].model.CampaignType[i].CampaignDescription;
              //logger.logError(errorMsg);
              //reject();
              //return;
          }
          if (tempIndicator.items[this.stateParamsId].model.CampaignType[i].DestinationURL === "") {
              let errorMsg = "<strong>Please Enter a DestinationURL URL</strong><br>" + "for " + tempIndicator.items[this.stateParamsId].model.CampaignType[i].CampaignType + " Campaign " + tempIndicator.items[this.stateParamsId].model.CampaignType[i].CampaignDescription;
              // logger.logError(errorMsg);
              // reject();
              //return;
          }
      }
  }
    return tempIndicator ;
  }
 async  processDigitalItemSchema(indicator){
     let isPrerequisiteItem;
     let prerequisiteItemID ;
     let  billingAcct;
      //US33864 -Lock the billing if its a Dexhub Parent and Child
    if (dexHubItems.indexOf( this.productName) > -1) {
        if (indicator.items[this.stateParamsId].order.parentId == null) {
            this.parentId = indicator.items[indicator.stateParamsId].order.svItemId;
            this.isParent = true;
        }else{
            this.parentId = indicator.items[this.stateParamsId].order.parentId;
        }
        for (let j = 0; j < indicator.items[this.stateParamsId].form.length; j++) {
            if (indicator.items[this.stateParamsId].form[j].key === "BusinessProfile" && this.parentId === indicator.items[this.stateParamsId].order.parentId || this.isParent) {
                indicator.items[this.stateParamsId].schema.properties.BusinessProfile.readonly = true;
            }
        }
    }
    // if(this.renewDigital){
    //     await this.billingService.getESB(indicator.order.orderId).then((res:any)=>{
    //         console.log("response",res)
    //         let billing = [];
    //         billing = indicator.digitalBilling;
    //         _.map(res.billAccountId, (element)=>{
    //             console.log("Element",element)
    //             let billingAccountId;
    //             if(element.svitemId == this.stateParamsId){
    //                 billingAccountId = element.ID ;
    //                 let bilIdPresent = _.find(indicator.digitalBilling, (bil)=>{
    //                     if(bil.value == billingAccountId)
    //                     {
    //                       return bil;
    //                     }
    //                   })
    //                   if(bilIdPresent || indicator.items[this.stateParamsId].order.productType.toLowerCase() == "print"){
    //                 if (billingAccountId != indicator.items[this.stateParamsId].order.billingAccountId) {
    //                     indicator.items[this.stateParamsId].order.billingAccountId = billingAccountId;
    //                     indicator.items[this.stateParamsId].model.BillingAccount = billingAccountId;
    //                    }
    //                 }
    //             }
    //         })
    //     });
    // }

     //Start : US33862,US33863,US33919     
     if (dexHubAddOn.indexOf(this.productCode) > -1 ||this.productCode==='SEM Kicker') {
        for (let i = 0; i <indicator.order.OrderItems.length; i++) {
            if (indicator.order.OrderItems[i].parentId === null &&indicator.order.OrderItems[i].prerequisiteId === null) {
                isPrerequisiteItem = true;
                 prerequisiteItemID =indicator.order.OrderItems[i].svItemId;
                 billingAcct =indicator.items[indicator.stateParamsId].model.BillingAccount;
            }
        }        
        for (let i = 0; i <indicator.order.OrderItems.length; i++) {
            let prerequisiteId =indicator.order.OrderItems[i].prerequisiteId;            
            if (prerequisiteId != null) {                
                if (isPrerequisiteItem) {                    
                    for (let j = 0; j <indicator.items[indicator.stateParamsId].form.length; j++) {
                        if (indicator.items[indicator.stateParamsId].form[j].key === "BusinessProfile") {
                          indicator.items[indicator.stateParamsId].schema.properties.BusinessProfile.readonly = true;
                        }
                        if (billingAcct ===indicator.items[indicator.stateParamsId].model.BillingAccount &&indicator.items[prerequisiteItemID]) {
                          indicator.items[indicator.stateParamsId].model.BillingAccount =indicator.items[prerequisiteItemID].model.BillingAccount;
                           indicator.items[indicator.stateParamsId].model.billingAccountId =indicator.items[prerequisiteItemID].model.BillingAccount;
                        }
                        if (prerequisiteItemID === prerequisiteId &&indicator.items[indicator.stateParamsId].form[j].key === "BillingAccount") {
                          indicator.items[indicator.stateParamsId].schema.properties.BillingAccount.readonly = true;
                        }
                    }
                } else {
                    let prId = prerequisiteId;
                    let billingAcctIDResponse = this.salesforceService.getBiilingAccountId(prId,indicator.account.id);                    

                    let billingAccountId;
                                        
                    billingAcctIDResponse.subscribe((res)  =>{                                                
                        if (res['data']['records'].length > 0) {                            
                            billingAccountId = res['data']['records'][0]['Billing_Account_ID__c'];                            
                        } else {
                            this.salesforceService.getBiilingAccountIdFromItemOrder(prId).subscribe( (res1) =>{
                                billingAccountId = res1['records[0]']['Billing_Account_ID__c'];
                              // indicator  = this.lockField(billingAccountId,indicator);
                            },err =>{
                                this.logger.logError("<strong>Failed to get Billing account id from itemOrder :</strong><br>Source System : Salesforce<br> " +JSON.stringify(err));
                            })
                        }
                        console.log(billingAccountId)
                        for (let j = 0; j < indicator.items[this.stateParamsId].form.length; j++) {
                            if (indicator.items[this.stateParamsId].form[j].key == "BusinessProfile") {
                              indicator.items[this.stateParamsId].schema.properties.BusinessProfile.readonly = true;
                            }
                            if (billingAccountId != indicator.items[this.stateParamsId].order.billingAccountId) {
                             indicator.items[this.stateParamsId].order.billingAccountId = billingAccountId;
                             indicator.items[this.stateParamsId].model.BillingAccount = billingAccountId;
                            }
                            if (
                              billingAccountId == indicator.items[this.stateParamsId].order.billingAccountId &&
                             indicator.items[this.stateParamsId].form[j].key == "BillingAccount"
                            ) {
                              indicator.items[this.stateParamsId].schema.properties.BillingAccount.readonly = true;
                            }
                          }

                    });
                }
            }
        }
    }
    //END : US33862,US33863,US33919
    

    this.indicatorStore.update((state) => {
        return {
          indicator: indicator,
          updatedFrom: "[DigitalItemComponent] processDigitalItemSchema",
          isloaded: true,
        };
      });
      return indicator;
  }

}
