import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminuserService {
  baseUri:string = '/api/adminuser';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService,) { }
  

        get(userId:any,password:any): Observable<any> {
          return this.http.get(this.API.get(this.baseUri) + "/" + userId + "/" + password)
          //return $http.get(API.get(vm.PATH) + "/" + userId + "/" + password, { cache : true });
        };
        getMe(userId,password): Observable<any> {
          return this.get(userId,password);
        }
        getAll(): Observable<any> {
          return this.http.get(this.API.get(this.baseUri))
          //return $http.get(API.get(vm.PATH));
        };
        updateUser(data: any): Observable<any>{
          return this.http.put(this.API.get(this.baseUri),data)
          //return $http.put(API.get(vm.PATH), data);
        };
        deleteUser(userId: any): Observable<any>{ 
          return this.http.put(this.API.get(this.baseUri) + "/" + userId,null)
          //return $http.put(API.get(vm.PATH)+ "/" + userId);
        };
        createUser(data: any): Observable<any>{
          return this.http.post(this.API.get(this.baseUri),data)
            //return $http.post(API.get(vm.PATH), data);
        };
}
