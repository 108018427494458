import { ChangeDetectorRef, Component, OnDestroy, OnInit,ViewRef } from '@angular/core';
import { IndicatorQuery } from "app/state/query";
import { IndicatorStore } from "app/state/store";
import { Observable, Subscription } from 'rxjs';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from 'app/core/service/requiredfieldcount/requiredfieldcount.service';

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  result = 0;
  maxCount;
  totalCount;
  progressBar = document.querySelector(".progress-bar");
  intervalId;
  indicator;
  subscription: Subscription;
  resultsubscription;
  //public progress:number =30;

  constructor(
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private counterService: CounterService,
    private requiredfieldcountService: RequiredfieldcountService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {

    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.indicator = { ...res };
      //let items = Object.keys(this.indicator.items);
      const { result, maxCount , totalCount, digitalCount, printCount, bpTotalCount} = this.requiredfieldcountService.calculatePercent(this.indicator["items"], this.indicator["profiles"]);
      //add result in the indicator so thet it will be accessed from anywhere
      //this.indicator.result = this.result;
      this.indicator['orderPercent'] = {};
      this.indicator['orderPercent'].percent = result;
      this.indicator['orderPercent'].currentCount = maxCount - totalCount;
      this.indicator['orderPercent'].maxCount = maxCount;
      this.indicator['orderPercent'].digitalCount = digitalCount ? digitalCount : 0;
      this.indicator['orderPercent'].printCount = printCount ? printCount : 0;
      this.indicator['orderPercent'].bpTotalCount = bpTotalCount ? bpTotalCount : 0;
      this.indicatorStore.update((state) => {
        return {
          indicator: this.indicator,
          updatedFrom: "[ProgressBarComponent] ngOnInit",
          isloaded: true,
        };
      });
    });


  }
  ngAfterViewInit() {
    this.subscription = this.counterService.getPercent().subscribe(count => {
      this.result = count;
      if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();

    });
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    this.subscription.unsubscribe();
  }
}
