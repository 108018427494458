import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from '@app/service/utils/utils.service';
import { IndicatorQuery } from 'app/state/query';
import { BillingnewComponent } from '../billing-new/billingnew/billingnew.component';

@Component({
  selector: 'app-billingemail',
  templateUrl: './billingemail.component.html',
  styleUrls: ['./billingemail.component.scss']
})
export class BillingemailComponent implements OnInit {
  options: any;
  titleContent: string;
  invalidEmail : boolean= false;
  customField: any = '';
  sharedData: any;

  constructor( private utils: UtilsService,
    private indicatorQuery: IndicatorQuery,
    private dialogRef: MatDialogRef<BillingnewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    this.options = this['layoutNode'].options;
    this.titleContent = "Email Address";
    if(this.data && this.data.title === "Update"){
       this.customField = this.data.dialogform._cell.row.data.emailAddress;
    } else{
      this.customField = this.sharedData.account.emailAddress;
     }
    });
  }
  sendValue(args){​
    if (!this.utils.isValidEmail(args) && args != '') {​​
    this.invalidEmail = true;
    }​​ else {
      if (args.length > 75 && args.length != 0) {​​
      this.invalidEmail = true;
      }​​else{​​
      this.invalidEmail = false;
      }
    }
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.onUpdateBillingemail(args);
    }
  }
}