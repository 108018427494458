import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class HeadingService {
  baseUri:string = '/api/heading';

  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService
  ) { }
  get(id: String): Promise<any>{
    return this.http.get(this.API.get(this.baseUri) + "/" + id).toPromise()
  }
}
