import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UtilsService } from 'app/core/service/utils/utils.service';
import { ValidateService } from '@app/service/validate/validate.service';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignStore } from 'app/state/campaignstore';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorQuery } from 'app/state/query';
import { FormControl } from '@angular/forms';
// import { CampaignSharedService } from '../campaign/campaign.service';
import { PhotoService } from '@app/service/photos/photosharing.service';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {

  disablelist: boolean;
  disableprofile: boolean;
  disableBPChkbx: boolean = false;
  destinationurl: string = '';
  invalidDestination: boolean = false;
  msgFlagDestination: boolean = false;
  msgDestination: string = '';
  msgEnum: any = {}
  displayurl: string = '';
  ngModel: any;
  titleContent: any;
  campaignModel: CampaignClass;
  readonly: boolean = false;
  Indicator: any;
  checkboxFormControl;
  isListingValueSubscription: any;

  constructor(private utils: UtilsService,
    private Validate: ValidateService,
    private campaignQuery: CampaignQuery,
    private campaignStore: CampaignStore,
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private indicatorQuery: IndicatorQuery,
    private campaignService: PhotoService, 
    // private campaignService: CampaignSharedService
    ) { }

  ngOnInit() {
    this.msgEnum = {
      contentSizeWarning: "content size is less than the threshhold of",
      pinkContent: "PINK content",
      failedMoved: "failed: moved permanently",
      moved: "moved permanently"
    }

    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data };
    })
    this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title
    if (this.campaignModel.model.LocalWebsite === undefined) {
      this.campaignModel.model.LocalWebsite = {
        disablelist:true,
        // disableprofile:true,
        disableprofile:false,
        destinationurl:'',
        displayurl:''
      };
      this.disablelist = true;
      this.campaignModel.model.LocalWebsite.disablelist = true
      // this.disableprofile = true;
      // this.campaignModel.model.LocalWebsite.disableprofile = true
      this.disableprofile = false;
      this.campaignModel.model.LocalWebsite.disableprofile = false
    } else {
      if (this.campaignModel.model.LocalWebsite.disablelist !== undefined) {
        this.disablelist = this.campaignModel.model.LocalWebsite.disablelist
        // this.disableprofile = this.campaignModel.model.LocalWebsite.disableprofile;
      } else {
        this.disablelist = this.campaignModel.model.disablelist;
      }
      if(this.campaignModel.model.ListingId){
        this.disableprofile = this.campaignModel.model.LocalWebsite.disableprofile;
        this.disableBPChkbx = false;
      } else {
        this.disableprofile = false;
        this.disableBPChkbx = true;
      }
      this.destinationurl = this.utils.validateURL(this.campaignModel.model.LocalWebsite.destinationurl, false) ? this.campaignModel.model.LocalWebsite.destinationurl : "";
      this.displayurl = this.campaignModel.model.LocalWebsite.displayurl;
    }
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
      for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
        if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
          break;
        }
      }
    })
    this.checkboxFormControl = new FormControl(this.disablelist);

    this.isListingValueSubscription = this.campaignService.getBehaviorViewListingValue()
    .subscribe(result => {
      console.log(result);
      let modelData;
      if(result){
        modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))

        this.disableprofile = false;
        this.disableBPChkbx = false;
      } else {
        modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))

        this.disableprofile = false;
        this.disableBPChkbx = true;
      }
      if(modelData.LocalWebsite){
        modelData.LocalWebsite.disableprofile = this.disableprofile;
      }

      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[WebsiteComponent] changeDestination'
        }
      })
    })
  }

  changeFn() {
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data}
    })
    if(!this.disablelist && !this.disableprofile){
      this.disablelist = true;
    }
    this.checkboxFormControl = new FormControl(this.disablelist);
    this.campaignModel.model.LocalWebsite.disablelist = this.disablelist
    this.campaignModel.model.LocalWebsite.disableprofile = this.disableprofile
    this.campaignStore.update(state => {
      return {
        campaign: this.campaignModel,
        updatedFrom: '[WebsiteComponent] changeFn'
      }
    })
    } 


  changeDestination(destinationurl) {
    this.invalidDestination = true;
    this.msgFlagDestination = false;
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    if (destinationurl) {
      if (this.utils.validateURL(destinationurl, false)) {
        this.invalidDestination = false;
        this.destinationurl = destinationurl;
      }
    }
    modelData.LocalWebsite.destinationurl = destinationurl
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[WebsiteComponent] changeDestination'
      }
    })
  }

  changeDisplay(event) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    modelData.LocalWebsite.displayurl = this.displayurl
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[WebsiteComponent] changeDisplay'
      }
    })
  };

  callValidateURL(url) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    if (this.utils.validateURL(url, false)) {
      this.msgFlagDestination = true;
      this.msgDestination = 'URL Validation in Progress';
      this.logger.timedLogWarning(this.msgDestination);
      this.Validate.validateurl(url).subscribe((data) => {
        this.Validate.setResponse(data.hasWarningorError);
        if(data.hasWarningorError === 'false'){
        if (data.status == "OK") {
          this.logger.logSuccess('URL Validation Successfull.');
          this.msgFlagDestination = false;
        }

        else if (((data.status == "FAIL" || data.status == "FAILED") && (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.contentSizeWarning.toLowerCase()) > -1)) || (data.status == "PINK" && (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.pinkContent.toLowerCase()) > -1) || (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.failedMoved.toLowerCase()) > -1) || (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.moved.toLowerCase()) > -1))) {
          this.msgFlagDestination = false;
          this.msgDestination = "";
          this.destinationurl = "";
          //Sentry fix
          if( this.campaignModel.model &&  this.campaignModel.model.nationalCampaign){
            this.campaignModel.model.nationalCampaign['isOverrideClicked'] = false;
          }else if(this.campaignModel.model && !this.campaignModel.model.nationalCampaign){
            this.campaignModel.model['nationalCampaign'] = {};
            this.campaignModel.model.nationalCampaign['isOverrideClicked'] = false;
          }
          
          let overrideButton = '<input type = "button" id="overRideBtn" class="btn btn-primary Override" style="float:right" value="Approve URL"></input>';
          let cancelButton = '<br><br><input type = "button" id = "btnCancel" class="btn btn-primary" value="Cancel"></input>';
          this.logger.log("<div class ='urlGrid'>Step 1" + "<ol type ='A' style='padding-left: 45px;'>" + "<li>" + "  Verify the destination URL is accurately entered." + "</li>"
            + "<li>" + "  Also verify that the URL is not redirected to a different URL. If true, the Destination URL should be entered. " + "</li>" + "</ol>" + "If either of the above in Step 1 is inaccurate, click &#34;Cancel&#34; to update the Destination URL." + "<br><br>"
            + "Step 2" + "<br>" + "Verify that the Destination URL:</strong><br>" + "<ol type = 'A' style='padding-left: 50px;'>" + "<li>" + "  Launches Successfully." + "</li>" + "<li>" + "  Has enough content.</strong>" + "</li>" + "</ol>"
            + 'If these conditions are met, and the URL is accurate, click &#34;Approve URL&#34; to force acceptance of the URL.'
            + cancelButton + overrideButton) + '</div>';
        }

        else {
          this.destinationurl = "";
          this.msgFlagDestination = true;
          this.destinationurl = "";
          this.msgDestination = 'Please enter a valid URL';
          this.campaignModel.model.LocalWebsite.destinationurl = ""
          this.logger.logError("<br>" + "1. Entered URL format is incorrect." + "<br>" +
            " 2. Website server is down." + "<br> " + "3. Website content is not safe." + "<br>" + "<br>"
            + "Please validate and try again.");
        }
      }
      else {
        this.msgFlagDestination = false;
        this.msgDestination = '';
        this.destinationurl = '';
        this.campaignModel.model.LocalWebsite.destinationurl = '';
        let errorObject = data.esbServiceFault;
        let errorCode = errorObject.sourceSystem;
        let errorDesc = errorObject.exceptionDetail[0].errorDescription;
        let errorMsg = "<strong>Transaction Id : " + data.transactionId + "</strong><br><strong> Source System : " + errorCode + "</strong><br>" + errorDesc;
        this.logger.logError(errorMsg);
      }
        if (!(<ViewRef>this.cdr).destroyed){
        this.cdr.detectChanges()}
        let x = JSON.parse(JSON.stringify(this.campaignModel))
        x.model = modelData
        this.campaignStore.update(state => {
          return {
            campaign: x,
            updatedFrom: '[WebsiteComponent] callValidateURL'
          }
        })
      });
    }
  }
  ngOnDestroy(){
  this.isListingValueSubscription.unsubscribe();
  }
}
