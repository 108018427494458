import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { finalize, map, catchError } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    constructor(public spinnerService: SpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // this.spinnerService.show();
        let data = {};
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                  //  console.log('event--->>>', event);
                    data = {
                        url: event && event.url ? event.url : '',
                        status: event.status,
                        warningMessage: event && event.body && event.body ? Object.values(event.body)[0]:''
                    };
                   // this.spinnerService.addData(data)
                    this.spinnerService.setSpinner(data)
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    url: error && error.url ? error.url : '',
                    status: error.status
                };
                //console.log("add data--> error ",data);
               // this.spinnerService.addData(data);
                this.spinnerService.setSpinner(data)
                return throwError(error);
            }),
            finalize(() => {
               // this.spinnerService.hide()
            }));
    }
}