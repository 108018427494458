import { Component, OnInit } from '@angular/core';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignStore } from 'app/state/campaignstore';


@Component({
  selector: 'app-phonenumberfield',
  templateUrl: './phonenumberfield.component.html',
  styleUrls: ['./phonenumberfield.component.scss']
})
export class PhonenumberfieldComponent implements OnInit {

  errorFlag: boolean = false;
  phoneNumber: string = '';
  Indicator;
  notRequired: boolean = false;
  id: any;
  campaignModel: CampaignClass;
  options: any;
  titleContent: any;
  readonly: boolean = false;
  constructor(private indicatorQuery :IndicatorQuery,
     private indicatorStore: IndicatorStore,
     private campaignQuery: CampaignQuery,
     private campaignStore: CampaignStore) { }

  ngOnInit() {

    this.options =this['layoutNode'].options;
    this.id = this['layoutNode'].name

    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
    })
    if (this['layoutNode'].name == "BusinessPhone") {
      this.phoneNumber =this.Indicator.profiles[this.Indicator.stateParamsId].model.BusinessPhone;
      this.notRequired = false;
      this.titleContent = this.Indicator['profiles'][this.Indicator.stateParamsId]['schema'][0]['properties']['BusinessPhone']['title'];
    }
    if (this['layoutNode'].name == "PhoneTollFree") {
      this.phoneNumber =this.Indicator.profiles[this.Indicator.stateParamsId].model.PhoneTollFree;
      this.notRequired = true;
      this.titleContent = this.Indicator['profiles'][this.Indicator.stateParamsId]['schema'][1]['properties']['PhoneTollFree']['title'];
    }
    if (this['layoutNode'].name == "PhoneAlternate") {
      this.phoneNumber =this.Indicator.profiles[this.Indicator.stateParamsId].model.PhoneAlternate;
      this.notRequired = true;
      this.titleContent = this.Indicator['profiles'][this.Indicator.stateParamsId]['schema'][1]['properties']['PhoneAlternate']['title'];
    }
    if (this['layoutNode'].name == "Fax") {
      this.phoneNumber =this.Indicator.profiles[this.Indicator.stateParamsId].model.Fax;
      this.notRequired = true;
      this.titleContent = this.Indicator['profiles'][this.Indicator.stateParamsId]['schema'][1]['properties']['Fax']['title'];
    }
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data }
    })
    this.options = this['layoutNode'].options;
    if (this.campaignModel && this.campaignModel.campaignFlag) {
      if (this['layoutNode'].name == "CampaignPhone") {
        for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
          if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
            this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
            break;
          }
        }
        this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title
        this.id = this['layoutNode'].name
        this.phoneNumber = this.campaignModel.model.CampaignPhone
        this.notRequired = false;
      }
      if(this.campaignModel.campaignSchema && this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex] && this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties && this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name])
      this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title;
    }
    this.formatPhoneNumber('Y');
  }

  formatPhoneNumber(calledFromOnInit) {

    //let layoutNodeName = this['layoutNode'].name;
    let modelData;
    if(!this.campaignModel.campaignFlag)
      modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.Indicator.stateParamsId].model));
    else if(this.campaignModel.campaignFlag)
      modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    else{}
//    const res=  this.indicatorQuery.getValue();
    //modelData[layoutNodeName]=this.phoneNumber.replace(/[^0-9]/g, '');
   // this.Indicator['profiles'][this.Indicator.stateParamsId].model= modelData;

    if (this.phoneNumber != undefined && this.phoneNumber !=null) {
      this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, '')
      if (this.phoneNumber.length >= 1) {
        this.phoneNumber = ("(").concat(this.phoneNumber.slice(0))
      }
      if (this.phoneNumber.length >= 5) {
        this.phoneNumber = this.phoneNumber.slice(0, 4).concat(") ", this.phoneNumber.slice(4))
      }
      if (this.phoneNumber.length >= 10) {
        this.phoneNumber = this.phoneNumber.slice(0, 9).concat("-", this.phoneNumber.slice(9))
      }
      if (this['layoutNode'].name == "BusinessPhone") {
        if (this.phoneNumber == undefined || this.phoneNumber.length != 14)
          this.errorFlag = true
        else
          this.errorFlag = false;
       modelData.BusinessPhone = this.phoneNumber.replace(/[^0-9]/g, '');
      }
      // Toll free phone
      if (this['layoutNode'].name == "PhoneTollFree") {
        if (this.phoneNumber.length > 0) {
          if (this.phoneNumber == undefined || this.phoneNumber.length != 14) {
            this.errorFlag = true
           modelData.PhoneTollFree = this.phoneNumber.replace(/[^0-9]/g, '');
          }
          else {
            this.errorFlag = false;
           modelData.PhoneTollFree = this.phoneNumber.replace(/[^0-9]/g, '');
          }
        }
        else {
          this.errorFlag = false;
         modelData.PhoneTollFree = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }
      // Alternate phone
      if (this['layoutNode'].name == "PhoneAlternate") {
        if (this.phoneNumber.length > 0) {
          if (this.phoneNumber == undefined || this.phoneNumber.length != 14) {
            this.errorFlag = true
           modelData.PhoneAlternate = this.phoneNumber.replace(/[^0-9]/g, '');
          }
          else {
            this.errorFlag = false;
           modelData.PhoneAlternate = this.phoneNumber.replace(/[^0-9]/g, '');
          }
        }
        else {
          this.errorFlag = false;
         modelData.PhoneAlternate = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }
      // Fax
      if (this['layoutNode'].name == "Fax") {
        if (this.phoneNumber.length > 0) {
          if (this.phoneNumber == undefined || this.phoneNumber.length != 14) {
            this.errorFlag = true
           modelData.Fax = this.phoneNumber.replace(/[^0-9]/g, '');
          }
          else {
            this.errorFlag = false;
           modelData.Fax = this.phoneNumber.replace(/[^0-9]/g, '');
          }
        }
        else {
          this.errorFlag = false;
         modelData.Fax = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }

      if (!this.campaignModel.campaignFlag && calledFromOnInit != 'Y') {
      //  this.Indicator.profiles[this.Indicator.stateParamsId].model = modelData
          let x = JSON.parse(JSON.stringify(this.Indicator));
          x['profiles'][this.Indicator.stateParamsId].model = modelData;
          this.indicatorStore.update(state =>{
            return{
              indicator: x,
              updatedFrom: "[PhonenumberfieldComponent] Fax",
              isloaded: true
            }
          });
      }
    }
    if (this.campaignModel.campaignFlag) {
      let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
      if (this['layoutNode'].name == "CampaignPhone") {
        if (this.phoneNumber == undefined || this.phoneNumber.length != 14){
          modelData.CampaignPhone = ''
          this.errorFlag = true
        }
        else{
          this.errorFlag = false;
         modelData.CampaignPhone = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }
      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[PhonenumberfieldComponent] formatPhoneNumber'
        }
      })
    } else{}
  }
}
