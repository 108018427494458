import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { Quickbpclass } from 'app/interface/quickbp';
import { StorageService } from '@app/service/storage/storage.service';

import { UtilsService } from '@app/service/utils/utils.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { ParamsService } from '@app/service/params/params.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { Constants } from '@app/service/datasource/constants';
import { SharedService } from '@app/service/shared/shared.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeleteBusinessProfileComponent } from '../delete-business-profile/delete-business-profile.component';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  title;
  htmlClass;
  style;
  page = "profile";
  sharedData: Quickbpclass;
  sdata = { street: 'Y', zip: 'Y', map: 'Y' };
  updateDA = [];
  deleteBPFlag: boolean = false;
  confirmDeleteBP: boolean = false;
  goldenRecordFlag: boolean = false;
  form: FormGroup;
  reasons = [];
  selectedReason = {
    'Reason': '',
    'Code': ''
  };

  id: any;
  reasonValue: boolean = true;
  DuplicateNoValue;
  dupToLid = "";
  deleteBPresponse;
  public mydata = new Array();
  matchedListing;
  currentAccountID = this.Params.params.eaId;
  goldenDataListingId;
  saveText = this.constants.saveText;
  deleteBPClass: boolean = false;
  dupToLidflag: boolean = false;
  buttonSpinner: boolean = false;
  public customClass = 'final-modal-container';
  bpFlag:boolean = false;
  spinnerText:string;
  currentMode: string = null;
  constructor(private StorageService: StorageService, private dialog: MatDialog,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private Utils: UtilsService,
    private Profile: ProfileService,
    private Params: ParamsService,
    private formBuilder: FormBuilder,
    private salesforceService: SalesforceService,
    private router: Router,
    private Shared: SharedService,
    private Logger: LoggerService,
    private constants: Constants,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,  ) {
    this.form = this.formBuilder.group({
      reasons: ['']
    });

    // async res
    // of(this.getReasons()).subscribe(res => {
    //   this.reasons = res;
    //   //this.form.patchValue(this.reasons[0].code);

    // });

  }
  //@ViewChild('deleteBpConfirmation', { static: false }) deleteBpConfirmation: DialogComponent;
  //@ViewChild('ejDialog', { static: false }) ejDialog: DialogComponent;

  ngOnInit() {

    this.id = this['layoutNode'].options.title
    this.updateDA['ALL'] = { street: 'Y', zip: 'Y', map: 'Y' };
    this.updateDA['CITY'] = { street: 'N', zip: 'Y', map: 'Y' };
    this.updateDA['HIDE'] = { street: 'N', zip: 'N', map: 'N' };

    this.currentMode = sessionStorage.getItem('mode');

    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    this.Params.params.mode = sessionStorage.getItem("mode");
    // var name = "PROFILE_DELETEBP";
    // //check if dexmedia search mode listing is associated with any account and if yes then don't show deleteBP Button
    // var freeListingFlag: boolean = false;

    // try{
    //   if (this.Params.params.mode === "listing") {
    //   if (this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds != null && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0] != null)
    //     this.Params.params.eaId = this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0];
    //   freeListingFlag = (this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds == null) ? true : false;
    // }
    // for (let i in this.sharedData.configuration) {
    //   if (this.sharedData.configuration[i].name == name) {
    //     if (this.sharedData['user']["profileName"] && this.sharedData.configuration[i].value.indexOf(this.sharedData['user']["profileName"]) >= 0 && (this.Params.params.mode === "ManageBP" || freeListingFlag) && (this.sharedData.stateParamsId !== "")) {
    //       this.deleteBPClass = true;
    //     }
    //   }
    // }}catch(e){
    //   console.log( "error in listing mode button component :",e);
    // }




    if (this['layoutNode'].options.title == "Delete Business Profile") {
      this.title = "Delete Business Profile";
      this.style = "deleteBtnCenter btn-danger";
      this.htmlClass = "col-xs-6 deleteBtn"
    }

    if (this['layoutNode'].options.title == "BACK") {
      this.title = "BACK";
      this.style = "btn-info special";
      // this.htmlClass = "backbtnitem col-xs-6"
    }
    if ( this.currentMode === 'QuickBP' && this['layoutNode'].options.title == "CREATE") {
      this.id = "CREATE"
      this.title = "CREATE";
      this.style = "btn-info float-right special";
      // this.htmlClass = "float-right col-xs-6"
    }

    if (this['layoutNode'].options.title == "CONTINUE") {
      if (this.sharedData.businessProfilePageIndex == 0 && this.Params.params.mode === 'QuickBP') {
        this.id = "CREATE"
        this.title = "CREATE";
      } else {
        this.title = "CONTINUE";
      }
      this.style = "btn-info float-right special";
      // this.htmlClass = "float-right col-xs-6"
    }

    if (this['layoutNode'].options.title == "SAVE") {
      this.title = "SAVE";
      this.style = "btn-info pull-right special";
      // this.htmlClass = "pull-right col-xs-6"
    }

    if (this['layoutNode'].options.title == "CANCEL") {
      this.title = "CANCEL";
      this.style = "btn-info pull-left special";
      // this.htmlClass = "pull-left col-xs-6"
    }

  }
  cuttonClass(title) {
    if(!title){
      return 'btn-hide';
    }
    if (this.deleteBPClass == true && title == 'Delete Business Profile') {
      return 'btn btn-danger delete-profile';
    }
    if (title == 'CREATE') {
      return 'btn btn-primary create-btn';
    }
    if (title == 'CONTINUE') {
      return 'btn btn-primary continue-btn';
    }
    if (title == 'SAVE') {
      return 'btn btn-primary save-btn';
    }
    if (title == 'CANCEL') {
      return 'btn btn-primary cancel-btn';
    }
    if (title == 'BACK') {
      return 'btn btn-primary back-btn';
    }
    if (title != 'Delete Business Profile') {
      return 'btn btn-primary';
    }
    if (this.deleteBPClass == false && title == 'Delete Business Profile') {
      return 'hidebtn';
    }
    // (this.deleteBPClass = && title=='Delete Business Profile')?'btn btn-danger delete-profile':'btn btn-primary'
  }
  // public onOpenDialog(content) {
  //   this.dialog.open(content,{width:'598px'})
  //   this.confirmDeleteBP = true;
  // }
  // Hide the Dialog when click the footer button.
  // public hideDialog() {
  //   this.reasonValue = false;
  //   // this.ejDialog.hide();
  //   this.confirmDeleteBP = false;
  // }

  // deleteBP() {
  //   this.buttonSpinner = true;
  //   //this.spinnerService.show();
  //   //var listingEaid = this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId["0"];
  //   if (this.Params.params.mode == "listing") {
  //     this.Profile.delete(this.Utils.sourcetype, "listing", this.sharedData.stateParamsId, this.selectedReason.Code, this.dupToLid)
  //       .then(response => {
  //         this.deleteBPresponse = response;
  //         // for (let key in this.sharedData['profiles']){
  //         //     if(this.sharedData.stateParamsId==this.sharedData['profiles'][key].data.listings.listing["0"].listingId){
  //         //         delete this.sharedData['profiles'][key];
  //         //         break;
  //         //     }
  //         // }
  //         this.confirmDeleteBP = false;
  //         this.deleteBPFlag = false;
  //         // this.ejDialog.hide();
  //         // this.deleteBpConfirmation.hide();
  //         //this.spinnerService.hide();
  //         this.Logger.logSuccess("Business Profile Deleted will be removed from Publishing in the nightly batch");

  //       }, error => {
  //         this.confirmDeleteBP = false;
  //         this.deleteBPFlag = false;
  //         // this.ejDialog.hide();
  //         // this.deleteBpConfirmation.hide();
  //         var respObj = error.error.response;
  //         //this.spinnerService.hide();

  //         if (respObj.hasWarningOrError === "true") {
  //           var errorObject = respObj.esbServiceFault[0];
  //           var errorCode = errorObject.exceptionDetail[0].errorCode;
  //           var errorDesc = errorObject.exceptionDetail[0].errorDescription;
  //           var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
  //           this.Logger.logError(errorMsg);

  //         }
  //       });
  //   } else {
  //     this.salesforceService.deleteBPitems(this.sharedData.stateParamsId, this.Params.params.eaId).then(result => {
  //       if (result.totalSize >= 1) {
  //         this.router.navigate(['/businessprofilegrid'], { queryParams: this.Params.params.values });
  //         // this.ejDialog.hide();
  //         // this.deleteBpConfirmation.hide();
  //         //this.spinnerService.hide();
  //         this.Logger.logError("Deletion not allowed. Business Profile is associated with active or pending advertising");
  //       } else {
  //         this.salesforceService.deleteBPassets(this.sharedData.stateParamsId, this.Params.params.eaId).then(result => {
  //           if (result.totalSize >= 1) {
  //             this.router.navigate(['/businessprofilegrid'], { queryParams: this.Params.params.values });
  //             // this.ejDialog.hide();
  //             // this.deleteBpConfirmation.hide();
  //             //this.spinnerService.hide();
  //             this.Logger.logError("Deletion not allowed. Business Profile is associated with active or pending advertising");
  //           } else {
  //             this.Profile.delete(this.Utils.sourcetype, "listing", this.sharedData.stateParamsId, this.selectedReason.Code, this.dupToLid)
  //               .then(response => {
  //                 this.deleteBPresponse = response;
  //                 for (let key in this.sharedData['profiles']) {
  //                   if (this.sharedData.stateParamsId == this.sharedData['profiles'][key].data.listings.listing["0"].listingId) {
  //                     delete this.sharedData['profiles'][key];
  //                     break;
  //                   }
  //                 }
  //                 if (this.Params.params.mode != "listing") {
  //                   this.router.navigate(['/businessprofilegrid'], { queryParams: this.Params.params.values });
  //                 }
  //                 // this.ejDialog.hide();
  //                 // this.deleteBpConfirmation.hide();
  //                 //this.spinnerService.hide();
  //                 this.Logger.logSuccess("Business Profile Deleted will be removed from Publishing in the nightly batch");

  //               }, error => {
  //                 this.deleteBPFlag = false;
  //                 // this.ejDialog.hide();
  //                 // this.deleteBpConfirmation.hide();
  //                 this.confirmDeleteBP = false;
  //                 var respObj = error.error.response;
  //                 //this.spinnerService.hide();


  //                 if (respObj.hasWarningOrError === "true") {
  //                   var errorObject = respObj.esbServiceFault[0];
  //                   var errorCode = errorObject.exceptionDetail[0].errorCode;
  //                   var errorDesc = errorObject.exceptionDetail[0].errorDescription;
  //                   var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
  //                   this.Logger.logError(errorMsg);

  //                 }
  //               });
  //           }
  //         })
  //       }
  //     });
  //   }

  // }
  onRadioClick(data) {
    this.goldenDataListingId = data;
  }

  dismiss() {
    this.deleteBPFlag = false;
    this.reasonValue = true;
    this.DuplicateNoValue = false;
    this.form.reset();
    // this.deleteBpConfirmation.hide();
  }
  // getReasons() {
  //   return [{ Reason: "Out of Business", Code: "OOB" },
  //   { Reason: "Incorrect Information", Code: "BAD" },
  //   { Reason: "Opt Out Requested", Code: "OUT" },
  //   { Reason: "Duplicate to", Code: "DUP" },
  //   { Reason: "Created in Error", Code: "ERR" }];
  // }
  // changeReason(e) {
  //   this.selectedReason.Code = e.target.value;
  //   if (this.selectedReason.Code == "DUP") {
  //     this.DuplicateNoValue = true;
  //     this.reasonValue = true;
  //   } else {
  //     this.reasonValue = false;
  //     this.DuplicateNoValue = false;
  //   }

  // }
  // selectedReasonValue(e) {
  //   this.dupToLidflag = true;
  //   this.dupToLid = e.target.value;
  //   if (this.selectedReason.Code == "DUP") {
  //     this.reasonValue = true;
  //     this.DuplicateNoValue = true;
  //     if (this.dupToLid.length == 0) {
  //       this.reasonValue = true;
  //       this.dupToLidflag = false;
  //       this.dupToLid = "";
  //     } else if (this.dupToLid.length < 10) {
  //       this.reasonValue = true;
  //     } else {
  //       this.reasonValue = false;
  //       this.dupToLidflag = true;
  //     }
  //   } else {
  //     this.DuplicateNoValue = false;
  //     this.reasonValue = false;
  //     this.dupToLid = "";
  //   }
  // }
  delete() {
    this.confirmDeleteBP = true;
  }
  getSourceType() {
    var sourcetype;
    if (this.Params.params.mode === "listing") {
      sourcetype = "SFDC";
    } else {
      sourcetype = ((this.sharedData['orders']["orderType"] && this.sharedData['orders']["orderType"].toLowerCase() == "national") || (this.sharedData['account'] && this.sharedData['account']["accountRecordType"] == "National Account")) ? "CMR" : "SFDC";
    }
    this.Utils.sourcetype = sourcetype;
  }
  getCatkeyObj(catObj) {
    var catKeyObj = {};
    for (var key in catObj) {
      var obj;
      Object.assign(obj, catObj[key]);
      //var obj = angular.copy(catObj[key]);
      for (var item in obj) {
        var itemKey = 'catkey' + item;
        if (catKeyObj.hasOwnProperty(itemKey)) {
          for (var catItem in obj[item]) {
            catKeyObj[itemKey].catkey.push(obj[item][catItem]);
          }
        } else {
          catKeyObj[itemKey] = {
            'catkey': obj[item]
          }
        }
      }
    }
    return catKeyObj;
  };
  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();
}
  buttonMethods() {
    if (this.title === "CONTINUE") {

      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data}
      });
      this.sharedData.spinnerClass = "business-profile-spinner"
      this.sharedData.loadingText = "Saving Business Profile. Please wait ...";
      this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[ButtonComponent] buttonMethods"
        }
      })

      this.Shared.continueMethod("");
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data}
      });
      this.ngAfterViewChecked();
    } else if (this.title === "BACK") {
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data}
      });
      this.StorageService.update(this.page, this.sharedData.stateParamsId, this.sharedData['profiles'][this.sharedData.stateParamsId].model)
      var activityLogHistoryObj = {
        'displayName': this.sharedData['user']["name"],
        'email': this.sharedData['user']["email"],
        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
        'itemId': this.sharedData.stateParamsId,
        'fieldName': 'Business Profile',
        'fieldValue': 'Updated'
      }
      this.StorageService.update("profileHistory", this.sharedData.stateParamsId, activityLogHistoryObj);
      var pageIndex = this.sharedData.businessProfilePageIndex - 1;
      this.sharedData.businessProfilePageIndex = pageIndex;
      if (pageIndex == 0) {
        this.sharedData.tileFlag1 = true;
        this.sharedData.tileFlag2 = false;
        this.sharedData.tileFlag3 = false;

      } else if (pageIndex == 1) {
        this.sharedData.tileFlag1 = false;
        this.sharedData.tileFlag2 = true;
        this.sharedData.tileFlag3 = false;
      }
      this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[ButtonComponent] buttonMethods1"
        }
      })
    } else if (this.title === "CANCEL") {
      //remove any newly added profiles from indicator
      if (this.Params.params.mode === "OrderBP") {
        for (var key in this.sharedData['profiles']) {
          if (key != "") {
            if (this.sharedData['listingIds'] != undefined && this.sharedData['listingIds'] != null) {
              if (this.sharedData['listingIds'].indexOf(key) < 0) {
                delete this.sharedData['profiles'][key];
              }
            }
          }
        }
      }
      this.router.navigate(['/business']);
    } else if (this.title === "SAVE") {
      if (this.Params.params.mode === "OrderBP") {
      }
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data}
      });
      this.Params.params.bpSaved =true;
      this.sharedData.spinnerClass = "business-profile-spinner"
      this.sharedData.loadingText = "Saving Business Profile. Please wait ...";
      this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[ButtonComponent] buttonMethods2"
        }
      })

      this.Shared.submitMethod("");
      //this.buttonSpinner = true;
      //this.spinnerService.show();
      //window.scrollTo(0, 0);
    } else if (this.title === 'CREATE') {
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data}
      });
      // this.sharedData.spinnerClass = "quick-bp-spinner"
      // this.sharedData.loadingText = "Saving Business Profile. Please wait ...";
      this.Params.params.loadingbartext = "Saving Business Profile. Please wait ...";
      this.Shared.spinnerText = this.constants.saveText;
      this.Params.params.loadingBP = "quick-bp-spinner";
      this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[ButtonComponent] buttonMethods3"
        }
      })

      this.Shared.submitMethod("");
      //this.buttonSpinner = true;
      //this.spinnerService.show();
      //window.scrollTo(0, 0);
      this.goldenRecordFlag = true;
    }
    // else if (this.title === "Delete Business Profile") {
    //   // this.indicatorQuery.getIndicator().subscribe(data => {
    //   //   this.sharedData = {...data}
    //   // });
    //   // this.sharedData.spinnerClass = "business-profile-spinner"
    //   // this.sharedData.loadingText = "Deleting Business Profile. Please wait ...";
    //   // this.indicatorStore.update(state =>{
    //   //   return{
    //   //     indicator: this.sharedData
    //   //   }
    //   // })

    //   // this.deleteBPFlag = true;
    //   const dialogConfig = new MatDialogConfig();
    //   dialogConfig.disableClose = true;
    //   dialogConfig.autoFocus = true;
    //   dialogConfig.panelClass = 'delete-bp-modal'
    //   dialogConfig.width = '800px';
    //   dialogConfig.minHeight = '195px';
    //   const dialogRef = this.matDialog.open(DeleteBusinessProfileComponent, dialogConfig);
    // }
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
    this.buttonSpinner = false;
  }
}
