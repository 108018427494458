import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HeadingService } from "../heading/heading.service";
import { CallService } from "../call/call.service";
import { CopysheetService } from "../copysheet/copysheet.service";
import { ItemschemaService } from "../itemschema/itemschema.service";
import { ProductService } from "../product/product.service";
import { GroupService } from "../group/group.service";
import { BillingService } from "../billing/billing.service";
import { UtilsService } from "../utils/utils.service";
import { StorageService } from "../storage/storage.service";
import { AccountService } from "../account/account.service";
import { ParamsService } from "../params/params.service";
import { ProfileService } from "../profile/profile.service";
import { ConfigurationService } from "../configuration/configuration.service";
import { ProfileschemaService } from "../profileschema/profileschema.service";
import { OrderService } from "../order/order.service";
import { UserService } from "../user/user.service";
import { SalesforceService } from "../salesforce/salesforce.service";
import { LoggerService } from '../logger/logger.service';
import { RequiredfieldcountService } from '../requiredfieldcount/requiredfieldcount.service';
import { TransformerService } from '../transformer/transformer.service';
import { CopysheetindicatorService } from '../copysheetindicator/copysheetindicator.service';
import { AdService } from '@app/service/ad/ad.service';
import { FileService } from '@app/service/file/file.service';
import { SetbaseService } from '@app/service/setbase/setbaseservice';
import { SetBaseCollectionService } from '@app/service/setBaseCollection/setBaseCollectionservice';
import { ApiService } from '../api/api.service';
import { IndicatorModel } from './indicator.model';
import { IndicatorStore } from 'app/state/store';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';

import * as _ from 'lodash';
import { SEMPHoursService } from '../semphours/semphours.service';
import { IndicatorQuery } from 'app/state/query';


@Injectable()
export class IndicatorService {
  public indicator: IndicatorModel = new IndicatorModel();
  profileSchema: any;
  isOrderTypeNational: boolean = false;
  isMCProfile: boolean = false;
  cancelledOrInful: boolean = false;
  csprogress = {
    load: false
  }
  iaGroupIds = [];
  billingArr = {};
  listingArr = {};
  displayedMissingDataError: boolean = false;
  items = {};
  bundles = [];
  createTaskTriggerDate;
  directoriesString;
  country: any;

  submitButton = {
    "type": "button",
    "title": "SAVE",
    "style": "btn-info special",
    "htmlClass": "pull-right"
  };

  continueButton = {
    "type": "button",
    "title": "CONTINUE",
    "htmlClass": "pull-right col-xs-6",
    "style": "btn-info pull-right special",
    "onClick": "vm.continueButton(modelValue,form)"
  };

  saveButton = {
    "type": "button",
    "title": "SAVE",
    "htmlClass": "pull-right col-xs-6",
    "style": "btn-info pull-right special",
    "onClick": "vm.continueButton(modelValue,form)"
  };

  backButton = {
    "type": "button",
    "title": "BACK",
    "htmlClass": "backbtnitem col-xs-6",
    "style": "btn-info special",
    "onClick": "vm.backButton(modelValue,form)"
  };

  cancelButton = {
    "type": "button",
    "title": "CANCEL",
    "htmlClass": "pull-left col-xs-6",
    "style": "btn-info pull-left special",
    "onClick": "vm.cancelButton(modelValue,form)"
  };

  deleteButton = {
    "type": "button",
    "title": "Delete Business Profile",
    "htmlClass": "col-xs-6 deleteBtn",
    "style": " deleteBtnCenter btn-danger",
    "onClick": "vm.openDeletetDialog()"
  };
  profiles: any = {};
  Indicator;
  ads = [];
  kgenCustomerId: any;
  accountId;
  orderId;
  promiseArr = [];
  payLoadArray = [];
  userName;


  constructor(
    private Itemschema: ItemschemaService,
    private Call: CallService,
    private Heading: HeadingService,
    private Copysheet: CopysheetService,
    private Product: ProductService,
    private Group: GroupService,
    private Billing: BillingService,
    private Params: ParamsService,
    private account: AccountService,
    private ProfileSchema: ProfileschemaService,
    private Order: OrderService,
    private Configuration: ConfigurationService,
    private User: UserService,
    private salesforceService: SalesforceService,
    private Logger: LoggerService,
    private Storage: StorageService,
    private Utils: UtilsService,
    private Profile: ProfileService,
    private RequiredFieldCount: RequiredfieldcountService,
    private Transformer: TransformerService,
    private CopysheetIndicator: CopysheetindicatorService,
    private SEMPHours: SEMPHoursService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private adService: AdService,
    private fileservice: FileService,
    private setbaseService: SetbaseService,
    private setBaseCollectionService: SetBaseCollectionService,
    private API: ApiService,
    private http: HttpClient
  ) {
    console.log('From contructor of indicator service :: ',)
    this.loadSessionId();
  }
  loadSessionId(){
    const sessionId = this.Profile.getSessionIdSync();
    if (!sessionId) {
     setTimeout(() => {
     this.loadSessionId();
     }, 100);
    }
  }
  ngOnInit() {

  };


  handleApiError(error) {
    let errorMsg;
    if (error && error != undefined && error != null && error.hasOwnProperty("error")) {
      errorMsg = JSON.stringify(error.error);
    } else {
      errorMsg = error;
    }
    return errorMsg;
  }

  async processIndicator(listingId): Promise<any> {
    // if(!sessionStorage.oauthToken){
      // await this.User.getSessionId().then((res) => {
      //   sessionStorage.setItem("oauthToken", res);
      //   localStorage.setItem("oauthToken", res);
      // })
    // }
    await this.User.get().then(data => {
      let userData = {};
      userData["alias"] = data.Alias;
      userData["email"] = data.Email;
      userData["federationIdentifier"] = data.FederationIdentifier;
      userData["Id"] = data.Id;
      userData["_id"] = data.Id;
      userData["name"] = data.Name;
      userData["phone"] = data.Phone;
      userData["profileName"] = data.Profile__c;
      userData["username"] = data.Username;
      userData["requestStartTime"] = data.accessed;
      userData["parameters"] = data.parameters;
      userData["locked"] = data.locked;
      userData["deploymentDate"] = data.deploymentDate;
      userData["nodeserver"] = data.nodeserver;
      this.indicator.user = userData;
      this.userName = data.Username;
      sessionStorage.setItem("federationidentifier", data.FederationIdentifier);
      sessionStorage.setItem("email", data.Email);
      sessionStorage.setItem("username", data.Username);
      sessionStorage.setItem("locked", data.locked);
      sessionStorage.setItem("nodeServer",data.nodeserver );
    }, userErr => {
      let errorMsg = this.handleApiError(userErr);
      return Promise.reject(errorMsg);
    });
    //setTimeout(() => { }, 4000);
    await this.ProfileSchema.get("").then(data => {
      this.indicator.profileSchema = data;
      this.profileSchema = data;
    }, profileSchemaErr => {
      let errorMsg = this.handleApiError(profileSchemaErr);
      return Promise.reject(errorMsg);
    });
    this.indicator.params = this.Params.params;
    this.indicator.account = null;
    if (this.Params.params.account != null && this.Params.params.account != "null") {
      await this.account.get(this.Params.params.account).toPromise().then(accountData => {
        if (accountData) {
          console.log("accountdata", accountData)
          this.kgenCustomerId = accountData.kgenCustomerId; 
          this.country = accountData.address.country;  

           //Service call to get setBasedAd images from Kgen
           this.adService.getItems(this.kgenCustomerId, "Ad-GET-ITEMPAGE")
           .subscribe(ads => {
            if(ads && ads['details'] && ads['details'].length>0){
                let tempAds = [];
                tempAds.push(ads['details'][0]);
                ads['details'].forEach((adDetail)=>{
                  if(!tempAds.find(x=> x.displayAdId === adDetail['displayAdId'] )){
                    tempAds.push(adDetail);
                  }else{
                    let filteredAd = tempAds.find(x=> x.displayAdId === adDetail['displayAdId'] );
                    if(filteredAd.itemId < adDetail['itemId']){
                      tempAds = tempAds.filter(x=> x.displayAdId !== adDetail['displayAdId'] );
                      tempAds.push(adDetail);
                    }
                  }
                });
                ads['details'] = tempAds;
              this.ads = ads['details'];            
            }
           }, err => {
                console.log("Error in calling the ad service ", err);
           });
          this.accountId = accountData.id;
          if (accountData.hasWarningAndError && accountData.errorException.message && accountData.errorException.message == 'INVALID_SESSION_ID') {
            //Since session id is invalid so getting new session and storing to sessionStorage
            this.User.getSessionId().then((res) => {
              //here session id is invalid so making again the account details call
              sessionStorage.setItem("oauthToken", res);
              localStorage.setItem("oauthToken", res);
              this.account.get(this.Params.params.account).toPromise().then(accountData => {
                if (accountData) {
                  if (accountData.hasWarningAndError) {
                    return Promise.reject(accountData.errorException.message);
                  }
                  accountData['sourceType'] = accountData.accountRecordType == "National Account" ? "CMR" : "SFDC";  // this will be used for profile api calls to pass CMR/SFDC parameter
                  this.indicator.account = accountData;
                } else {
                  this.Logger.logError("Salesforce error Accounts table  ErrorCode:  " + accountData[0].errorCode + "  error message : " + accountData[0].message);
                }
              }, accountErr => {
                let errorMsg = this.handleApiError(accountErr);
                return Promise.reject(errorMsg);
              })
            })
          } else if (accountData.hasWarningAndError) {
            return Promise.reject(accountData.errorException.message);
          }
          accountData['sourceType'] = accountData.accountRecordType == "National Account" ? "CMR" : "SFDC";  // this will be used for profile api calls to pass CMR/SFDC parameter
          this.indicator.account = accountData;
        } else {
          this.Logger.logError("Salesforce error Accounts table  ErrorCode:  " + accountData[0].errorCode + "  error message : " + accountData[0].message);
        }
      }, accountErr => {
        let errorMsg = this.handleApiError(accountErr);
        return Promise.reject(errorMsg);
      })
    }
    if (this.Params.params.order != "null" && this.Params.params.order != null) {
      await this.Order.getMe().then(data => {
        this.orderId = data.orderId;
        if (data && data.hasWarningAndError && data.errorException.message && data.errorException.message == 'INVALID_SESSION_ID') {
          //Since session id is invalid so getting new session and storing to sessionStorage
          this.User.getSessionId().then((res) => {
            //here session id is invalid so making again the account details call
           sessionStorage.setItem("oauthToken",res);
           localStorage.setItem("oauthToken",res);
          this.Order.getMe().then(data => {
        if (data && !data[0]) {
          let itemStatusOpen = [];
          sessionStorage.setItem("orderId",data.orderId);
         if(data.OrderItems){
          data.OrderItems.forEach((itemNumber, ind) => {
            if (itemNumber.status == "Open" || itemNumber.status == "") {
              itemStatusOpen.push(itemNumber.svItemId);
            }
          })
         }

                if (data.status && (data.status.toLowerCase() == "open" || data.status == "")) {
                  if (itemStatusOpen.length > 0) {
                    this.Logger.logError("An order " + data.orderId + " and items " + itemStatusOpen + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
                  }
                  else {
                    this.Logger.logError("An order " + data.orderId + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
                  }
                }
                else if (data.status && data.status.toLowerCase() == "rejected") {
                  this.Logger.logError("An order " + data.orderId + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
                }
                else if (itemStatusOpen.length > 0) {
                  this.Logger.logError("An order " + data.orderId + " and items " + itemStatusOpen + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
                }
                if (data.hasOwnProperty('hasWarningAndError')) {
                  return Promise.reject("Data such as Item ID, Status, or other data discrepancies exist on at least one item.  Please review and/or contact the Solution Center");
                }
                this.indicator.order = data;
                // sessionStorage.setItem("currentOrderId",data.orderId);
                // sessionStorage.setItem("currentAccountId",data.accountId);
              } else {
                if (data) {
                  this.Logger.logError("Salesforce error from order table.  ErrorCode:  " + data[0].errorCode + "  error message : " + data[0].message);
                  //console.log("Salesforce error getting orders :", data[0]);
                }
                else {
                  return Promise.reject("Data such as Item ID, Status, or other data discrepancies exist on at least one item.  Please review and/or contact the Solution Center");
                  // TODO: Added to remove hour glass when salesforce returns no item for order
                  //this.Indicator.itemLoading = false;
                  //this.Indicator.bpLoading = false;
                }

              }
            }, orderErr => {
              let errorMsg = this.handleApiError(orderErr);
              return Promise.reject(errorMsg);
            })
          });
        } else if (data && !data[0]) {
          let itemStatusOpen = [];
          sessionStorage.setItem("orderId", data.orderId);
          data.OrderItems.forEach((itemNumber, ind) => {
            if (itemNumber.status == "Open" || itemNumber.status == "") {
              itemStatusOpen.push(itemNumber.svItemId);
            }
          })

          if (data.status && (data.status.toLowerCase() == "open" || data.status == "")) {
            if (itemStatusOpen.length > 0) {
              this.Logger.logError("An order " + data.orderId + " and items " + itemStatusOpen + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
            }
            else {
              this.Logger.logError("An order " + data.orderId + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
            }
          }
          else if (data.status && data.status.toLowerCase() == "rejected") {
            this.Logger.logError("An order " + data.orderId + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
          }
          else if (itemStatusOpen.length > 0) {
            this.Logger.logError("An order " + data.orderId + " and items " + itemStatusOpen + " in OPEN or REJECTED status cannot be processed in Content Assistant until the order status is Accepted.");
          }
          if (data.hasOwnProperty('hasWarningAndError')) {
            return Promise.reject("Data such as Item ID, Status, or other data discrepancies exist on at least one item.  Please review and/or contact the Solution Center");
          }
          this.indicator.order = data;
          // sessionStorage.setItem("currentOrderId",data.orderId);
          // sessionStorage.setItem("currentAccountId",data.accountId);
        } else {
          if (data) {
            this.Logger.logError("Salesforce error from order table.  ErrorCode:  " + data[0].errorCode + "  error message : " + data[0].message);
            //console.log("Salesforce error getting orders :", data[0]);
          }
          else {
            return Promise.reject("Data such as Item ID, Status, or other data discrepancies exist on at least one item.  Please review and/or contact the Solution Center");
            // TODO: Added to remove hour glass when salesforce returns no item for order
            //this.Indicator.itemLoading = false;
            //this.Indicator.bpLoading = false;
          }

        }
      }, orderErr => {
        let errorMsg = this.handleApiError(orderErr);
        return Promise.reject(errorMsg);
      })
    } else {
      this.indicator.order = null;
    }



    await this.Configuration.getAll().then(data => {
      this.indicator.configuration = data;
    }, configurationErr => {
      let errorMsg = this.handleApiError(configurationErr);
      this.Logger.logError(errorMsg);
    });
    if (this.Params.params.account != undefined && this.Params.params.account != null) {
      await this.salesforceService.getPrimaryContact(this.Params.params.account).then(result => {
        /*if (result && result["records"] && result["records"].length > 0) {
          this.indicator.primaryContactEmail = result["records"][0].Email;
        }*/
        if (result && result['Email'] && result['Email'].length > 0) {
          this.indicator.primaryContactEmail = result['Email'];
        }
      }, primaryContactErr => {
        let errorMsg = this.handleApiError(primaryContactErr);
        return Promise.reject(errorMsg);
      });
    }

    //processing starts here
    if (this.Params.params.account != null && this.Params.params.account != undefined && this.Params.params.account != '' && this.Params.params.account != 'null') {
      //get Contact Information from Parent Account for CPQ National Orders
      if (this.Params.params.sforg === 'national' && this.indicator.order && this.indicator.order != null && this.indicator.order["orderType"] && this.indicator.order["orderType"].toLowerCase() === 'national' && this.indicator.account) {
        await this.salesforceService.getContactInfo(this.indicator.account["id"]).then(result => {
          if (result && result["records"] && result["records"][0] && result["records"][0].Contacts && result["records"][0].Contacts.records[0]) {
            var contacts = result["records"][0].Contacts.records;
            for (var index in contacts) {
              var contact = {
                firstName: contacts[index].FirstName,
                lastName: contacts[index].LastName,
                phone: contacts[index].Phone,
                email: contacts[index].Email,
                address: {
                  city: contacts[index].MailingCity,
                  state: contacts[index].MailingStateCode,
                  zip: contacts[index].MailingPostalCode,
                  country: contacts[index].MailingCountryCode,
                  street1: null,
                  street2: null
                }
              };
              if (contacts[index].MailingStreet !== null) {
                var contactStreets = contacts[index].MailingStreet.split("\r\n");
                contact.address.street1 = contactStreets[0];
                contact.address.street2 = (contactStreets[1]) ? contactStreets[1] : null;
              } else {
                contact.address.street1 = null;
                contact.address.street2 = null;
              }
              this.indicator.account["contacts"].push(contact);
            }
          } else {
            return Promise.reject("No Contact details found from Parent Account for Child Account");
          }
        }, err => {
          return Promise.reject("<strong>Error get Contact Information from Parent Account for CPQ National Orders<strong><br>Source System : Salesforce <br>");
        })
      }

      await this.Storage.getAll("order")
        .then(data => {
          var storageOrder = data;
          if (this.indicator.order && storageOrder && storageOrder.length != 0) {
            for (var s = 0; s < storageOrder.length; s++) {
              var eachData = storageOrder[s].data;
              for (var i = 0; i < this.indicator.order["OrderItems"].length; i++) {
                if (this.indicator.order["OrderItems"][i].svItemId + 'v' + this.indicator.order["OrderItems"][i].version === eachData.svItemId + 'v' + eachData.version) {
                  this.indicator.order["OrderItems"][i].heading = storageOrder[s].data.heading;
                  this.indicator.order["OrderItems"][i].directoryName = storageOrder[s].data.directoryName;
                  this.indicator.order["OrderItems"][i].listingId = storageOrder[s].data.listingId;
                  this.indicator.order["OrderItems"][i].listingName = storageOrder[s].data.listingName;
                  this.indicator.order["OrderItems"][i].listingTn = storageOrder[s].data.listingTn;
                }
              }
            }
          }
        }, error => {
          //console.log("getAll Error:", error);
        });

      if ((this.indicator.order && this.indicator.order["orderType"] && this.indicator.order["orderType"].toLowerCase() === "national") || this.Params.params.mode === "bp" || this.Params.params.mode === "quickbp" && this.indicator.account) {
        if (this.indicator.order && this.indicator.order["orderType"] && this.indicator.order["orderType"].toLowerCase() === "national") {
          this.isOrderTypeNational = true;
        }
        let partneraccountID = sessionStorage.getItem("partneraccountID");
        if (partneraccountID != null && partneraccountID != undefined && partneraccountID != 'undefined') {
          this.indicator.account["kgenCustomerId"] = partneraccountID;
          this.indicator.account["parentId"] = partneraccountID;
        }
        // await this.salesforceService.getPartnerParentKGenId(this.indicator.account["id"]).then(result => {
        //   /* SOQL to API  changes, begins */
        //   /*
        //   if (result && result.records && result.records[0] && result.records[0].Parent_Account__r) {
        //     this.indicator.account["kgenCustomerId"] = result.records[0].Parent_Account__r.KGEN_Customer_Id__c;
        //     this.indicator.account["parentId"] = result.records[0].Parent_Account__r.KGEN_Customer_Id__c;
        //   }
        //   */         
        //   this.indicator.account["kgenCustomerId"] = result;
        //   this.indicator.account["parentId"] = result;
        //   /* SOQL to API changes, Ends */
        // }, err => {
        //   //commenting the below code as this was creating so much of noise in production
        //   // Logger.logError("<strong>Failed to to get kgen_customer_id__c form Partner Account :</strong><br>Source System : Salesforce <br>" +JSON.stringify(error));

        // })
      }

      if (this.indicator.account && this.indicator.account["kgenCustomerId"] != null && this.indicator.account["kgenCustomerId"] != "" && this.Params.params.mode !== "listing" || this.Params.params.mode !== "QuickBP") {
        if (this.Params.params.mode != "listing" && this.Params.params.mode != "QuickBP" && this.Params.params.mode != "ManageBP") {
          let displayBillingLogger = false;
          if (this.indicator && this.indicator.order && this.indicator.order['OrderItems']) {
            if (this.indicator.order['OrderItems'].length > 0) {
              for (let i = 0; i < this.indicator.order['OrderItems'].length; i++) {
                if (this.indicator.order['OrderItems'][i].actionCode !== "Cancel") {
                  displayBillingLogger = true;
                  break;
                }
              }
            }
          }
          await this.Billing.get(this.indicator.account["kgenCustomerId"]).then(billings => {
            if (billings.hasWarningOrError === "true") {
              var errorObject = billings.esbServiceFault;
              var errorCode = errorObject.sourceSystem;
              var errorDesc = errorObject.exceptionDetail[0].errorDescription;
              var errorMsg = "<strong>Error Getting Billing data :</strong><br><strong>Transaction Id : " + billings.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              if (displayBillingLogger) {
                this.Logger.logError(errorMsg);
              }
            } else {
              this.indicator.billing = billings;
            }
          }, error => {
            this.Logger.logError("</strong>Error Getting Billing  Details :</strong><br>" + JSON.stringify(error))
          });
        }
      }
      await this.processProfileschema();
      await this.processCalls('');

      //LateAdPublished - revisit and test
      var userProfilesLateAdPublishEnabled = ['AM Helpdesk', 'AM Manager', 'AM Supervisor'];
      this.indicator.isMCProfile = false;
      this.isMCProfile = false;
      if (this.indicator.order && this.indicator.order["OrderItems"] && this.indicator.order["OrderItems"].length > 0) {
        //To verify if User is Account Management Team or other user
        if (this.indicator.user && this.indicator.user["profileName"] && userProfilesLateAdPublishEnabled.indexOf(this.indicator.user["profileName"]) >= 0) {
          this.isMCProfile = false;
          this.indicator.isMCProfile = false;
        } else {
          this.isMCProfile = true;
          this.indicator.isMCProfile = true;
        }

        if (this.isMCProfile && this.Params.params.sforg.toLowerCase() == "national") {
          var tempArr = [];
          this.directoriesString = "";
          let tempUniqueArr = [];
          this.createTaskTriggerDate = "";
          for (var item in this.indicator.order["OrderItems"]) {
            if (this.indicator.order["OrderItems"] && this.indicator.order["OrderItems"][item] && this.indicator.order["OrderItems"][item].productType == "Print" && this.indicator.order["OrderItems"][item].directoryNumber) {
              if (this.indicator.order["OrderItems"][item].lastSFAKeyDate && this.indicator.order["OrderItems"][item].brmFulfillmentDate) {
                //convert the dates to a format to be compared
                var currentDate = this.Utils.getKgenUTCDate(new Date());
                var extractDate = this.Utils.getKgenUTCDate(this.indicator.order["OrderItems"][item].lastSFAKeyDate);
                var triggerToBillDate = new Date(this.indicator.order["OrderItems"][item].brmFulfillmentDate);
                triggerToBillDate.setUTCDate(triggerToBillDate.getUTCDate() - 1);
                var triggerDateMinusOne = this.Utils.getKgenUTCDate(triggerToBillDate);

                if (extractDate < currentDate && currentDate < triggerDateMinusOne) {
                  this.Params.params.isLateAd = true;
                  tempArr.push(this.indicator.order["OrderItems"][item].directoryName);
                  if (this.createTaskTriggerDate == "") {
                    this.createTaskTriggerDate = this.indicator.order["OrderItems"][item].brmFulfillmentDate;
                  }
                  else {
                    var tempCreateTaskDate = this.Utils.getKgenUTCDate(this.createTaskTriggerDate);
                    var currentItemDate = this.Utils.getKgenUTCDate(this.indicator.order["OrderItems"][item].brmFulfillmentDate);
                    if (tempCreateTaskDate > currentItemDate) {
                      this.createTaskTriggerDate = this.indicator.order["OrderItems"][item].brmFulfillmentDate;
                    }
                  }
                }
              }

            }
          }
          //To eliminate duplicate records of same directories and display message ofr late Ad directories
          if (tempArr.length > 0) {
            tempUniqueArr = [];
            for (var i = 0; i < tempArr.length; i++) {
              if (tempUniqueArr.indexOf(tempArr[i]) == -1) {
                tempUniqueArr.push(tempArr[i]);
                if (this.directoriesString == "") {
                  this.directoriesString = "'" + tempArr[i] + "'"
                } else {
                  this.directoriesString = this.directoriesString + ",'" + tempArr[i] + "'";
                }
              }
            }
            this.Logger.log('Directory ' + this.directoriesString + ' has exceeded the last sales key date. After you publish, your order will be routed to the Account Management team.  They will attempt to process only if you publish before the "trigger to bill" date for this directory.');
          }
          let items: string = "";
          let canceled_Item: string = "";
          let orderId: string = "";
          let UDAC: string = '';
          for (var i = 0; i < this.indicator.order["OrderItems"].length; i++) {
            items = items.concat("'");
            items = items.concat((this.indicator.order["OrderItems"][i].svItemId).toString());
            items = items.concat("'");
            items = items.concat(",");
          }
          items = items.slice(0, -1);
          this.salesforceService.getItemStatus(items).then(result => {
            console.log("Assettable", result);
            if (result && result['records'] && result['records'].length > 0) {
              for (let i = 0; i < result['records'].length; i++) {
                var status_item = result['records'][i].Item_ID__c;
                if ((result['records'][i].Status).toLowerCase() == "cancelled") {
                  for (let j = 0; j < this.indicator.order["OrderItems"].length; j++) {
                    if ((this.indicator.order["OrderItems"][j]["svItemId"] == status_item) && this.indicator.order["OrderItems"][j]["statusCode"] <= 6000) {
                      this.cancelledOrInful = true;
                      UDAC = UDAC.concat(this.indicator.order["OrderItems"][j].UDAC, ',');
                      canceled_Item = canceled_Item.concat(result['records'][i].Item_ID__c, ',');
                    }
                  }
                }
              }
              UDAC = UDAC.slice(0, -1);
              canceled_Item = canceled_Item.slice(0, -1);
              if (this.cancelledOrInful) {
                this.Logger.logError('At least one item you are trying to Publish has already been Cancelled (' + UDAC + '),(' + canceled_Item + ') . Please reach out to your local Office Support (ROM) for assistance as the cancelled item cannot be removed from the Quote once an Order is created. ');
              }
            }
          })
        }
      }
       //storing set base image count in indicator object
       this.indicator.account['setBaseImageCount'] = this.ads.length;
       this.indicator.account['setbasedarray']= this.ads;
      this.indicator.directoriesString = this.directoriesString
      this.indicator.createTaskTriggerDate = this.createTaskTriggerDate
      this.populateItemsAfterProfiles()
      //add the indicator to store
      this.indicatorStore.setLoading(true);
      this.indicator.isAlreadyLoaded = true;
      this.indicatorStore.update((state) => {
        return {
          indicator: JSON.parse(JSON.stringify(this.indicator)),
          updatedFrom: "[IndicatorService] Initial Load",
          isloaded: true,
        };
      });
      this.indicatorStore.setLoading(false);

    } else {//Thryv search bp execution
      if (this.Params.params.mode === "listing") {
        await this.processProfileschema();
        await this.processCalls(listingId);

        //add the indicator to store
        this.indicatorStore.setLoading(true);
        this.indicator.isAlreadyLoaded = true;
        this.indicatorStore.update((state) => {
          return {
            indicator: JSON.parse(JSON.stringify(this.indicator)),
            updatedFrom: "[IndicatorService] Initial Load for listing",
            isloaded: true,
          };
        });
        this.indicatorStore.setLoading(false);
      }
    }
    //Function call for fetching the setbasedAd images from Kgen and storing to mongoDB
    this.fetchSetBasedAd(this.kgenCustomerId);
    return this.indicator;

  };

  async processCalls(listingId): Promise<any> {
    if (this.Params.params.order != null) {
      let products = {};
      if (this.indicator.order != null) { // this will work only for order bp mode
        products = await this.getItemData();
        // console.log('products is' ,products);
        await this.processItems(this.indicator.order, products)
      };
    }
    await this.getProfileData(listingId);
    await this.getProfileList(this.indicator.listingIds);
    //console.log('indicator listing ids is' + this.indicator.listingIds);
    // here i need to call the processProfileschema
    await this.processProfiles(this.indicator.profileSchema, this.indicator.profiles)
    this.indicator = await this.SEMPHours.setHOOConfirmationsInIndicator(this.indicator);
    //console.log('indicator after process is' + JSON.stringify(this.indicator.profiles));
    this.indicator['isIndicatorLoaded'] = true;
    this.indicator['businessProfilePageIndex'] = 0;
    this.indicator['tileFlag1'] = true;
    this.indicator['tileFlag2'] = false;
    this.indicator['tileFlag3'] = false;
    this.indicator['bpDirtyFlag'] = false;
    this.indicator['profiles'][""].model.BusinessAddressZip = { "zip": "", "zip4": "" };

    /*this.indicatorStore.setLoading(true);
    this.indicator.isAlreadyLoaded = true;
    this.indicatorStore.update((state) => {
      return {
        indicator: JSON.parse(JSON.stringify(this.indicator)),
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);
    return this.indicator;*/

  }
  async processProfileschema() {
    let itemstoremove = [
      "Products",
      "Services",
      "Brands",
      "Affiliations",
      "Specialities",
      "Certifications",
      "SpecialtiesBP",
    ];
    for (let loopcounter = 0; loopcounter < itemstoremove.length; loopcounter++) {
      for (let loopcounter1 = 0; loopcounter1 < this.indicator.profileSchema[2].form.length; loopcounter1++) {
        if (this.indicator.profileSchema && this.indicator.profileSchema[2] && this.indicator.profileSchema[2].form && (this.indicator.profileSchema[2].form[loopcounter1].key === itemstoremove[loopcounter])) {
          this.indicator.profileSchema[2].form[loopcounter1]['type'] = "hidden"
          this.indicator.profileSchema[2].form[loopcounter1]['notitle'] = true
        }
      }
    }
    for (let loopcounter1 = 0; loopcounter1 < this.indicator.profileSchema[2].form.length; loopcounter1++) {
      if (this.indicator.profileSchema[2] && this.indicator.profileSchema[2].form && this.indicator.profileSchema[2].form[loopcounter1].key == "CatKeys" || this.indicator.profileSchema[2].form[loopcounter1].key == "CustomCatKeys") {
        this.indicator.profileSchema[2].form[loopcounter1]['notitle'] = true
      }
    }
    for (let loopcounter1 = 0; loopcounter1 < this.indicator.profileSchema[1].form.length; loopcounter1++) {
      if (this.indicator.profileSchema && this.indicator.profileSchema[1] && this.indicator.profileSchema[1].form && this.indicator.profileSchema[1].form[loopcounter1] && this.indicator.profileSchema[1].form[loopcounter1].key == "assets") {
        this.indicator.profileSchema[1].form[loopcounter1]['notitle'] = true
      }
      if (this.indicator.profileSchema && this.indicator.profileSchema[1] && this.indicator.profileSchema[1].form && this.indicator.profileSchema[1].form[loopcounter1] && this.indicator.profileSchema[1].form[loopcounter1].key == "calltoactionurl") {
        this.indicator.profileSchema[1].form[loopcounter1]['type'] = "hidden"
        this.indicator.profileSchema[1].form[loopcounter1]['notitle'] = true
      }
    }
    for (let loopcounter = 0; loopcounter < this.indicator.profileSchema[0].form.length; loopcounter++) {
      if (this.indicator.profileSchema && this.indicator.profileSchema[0] && this.indicator.profileSchema[0].form && this.indicator.profileSchema[0].form[loopcounter] && this.indicator.profileSchema[0].form[loopcounter].key == "Non-PublishPhone") {
        this.indicator.profileSchema[0].form.splice(loopcounter, 1);
      }
      if (this.indicator.profileSchema && this.indicator.profileSchema[0] && this.indicator.profileSchema[0].form && this.indicator.profileSchema[0].form[loopcounter] && this.indicator.profileSchema[0].form[loopcounter].key == "BPGeos") {
        this.indicator.profileSchema[0].form[loopcounter]['notitle'] = true
      }
      if (this.indicator.profileSchema && this.indicator.profileSchema[0] && this.indicator.profileSchema[0].form && this.indicator.profileSchema[0].form[loopcounter] && this.indicator.profileSchema[0].form[loopcounter].key == "LocationDescription") {
        this.indicator.profileSchema[0].form[loopcounter]['type'] = "hidden"
        this.indicator.profileSchema[0].form[loopcounter]['notitle'] = true
      }
      if (this.indicator.profileSchema && this.indicator.profileSchema[0] && this.indicator.profileSchema[0].form && this.indicator.profileSchema[0].form[loopcounter] && this.indicator.profileSchema[0].form[loopcounter].key == "BusinessAddress1") {
        this.indicator.profileSchema[0].form[loopcounter]['sortOrder'] = 4
      }
    }
    delete this.indicator.profileSchema[0].schema.properties["Non-PublishPhone"];

  }

  async getProfileData(listingId) {
    let profileIds = [];
    if (this.indicator.order != null) {
      if (this.indicator.order["OrderItems"] == null) { return; };
      for (let i = 0; i < this.indicator.order["OrderItems"].length; i++) {
        //  console.log('items type' + this.indicator.order["OrderItems"][i].productType + 'actioncode' + this.indicator.order["OrderItems"][i].actionCode);
        if (this.indicator.order["OrderItems"][i] && this.indicator.order["OrderItems"][i].productType === "Digital" && this.indicator.order["OrderItems"][i].actionCode !== "Cancel" && this.indicator.order["OrderItems"][i].actionCode !== "Renew") {
          //console.log('item' + this.indicator.order["OrderItems"][i].svItemId);
          let data = {};
          if (this.indicator.items && this.indicator.order["OrderItems"][i].svItemId && this.indicator.items[this.indicator.order["OrderItems"][i].svItemId])
            data = this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model;
          if (this.indicator.order["OrderItems"][i].productCode != "SEM") {
            if (data.hasOwnProperty("BusinessProfile") && data['BusinessProfile'] != undefined && data['BusinessProfile'] != null && data['BusinessProfile'] != "") {
              //console.log('business profile' + data['BusinessProfile']);
              profileIds.push(data['BusinessProfile']);
            } else {
              if (data.hasOwnProperty("listingId") && data['listingId'] != undefined && data['listingId'] != null && data['listingId'] != "") {
                //console.log('listing id' + data['listingId']);
                profileIds.push(data['listingId']);
              }
            }
          }
          if (data.hasOwnProperty("CampaignType")) {
            for (let j = 0; j < data['CampaignType'].length; j++) {
              if (data['CampaignType'][j].hasOwnProperty("ListingId") && data['CampaignType'][j].ListingId && data['CampaignType'][j].ListingId != undefined && data['CampaignType'][j].ListingId != null && data['CampaignType'][j].ListingId.trim() !== "") {
                profileIds.push(data['CampaignType'][j].ListingId);
              }
            }
          }
        }
        if (this.indicator.order["OrderItems"][i] && this.indicator.order["OrderItems"][i].productType === "Digital" && this.indicator.order["OrderItems"][i].actionCode === "Renew") {
          let itemModel = this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model;
          let itemData = this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].data;
          //check if there is any data in storage
          let storageId = this.indicator.order["OrderItems"][i].svItemId + "v" + this.indicator.order["OrderItems"][i].version
          let storageItemData = await this.Storage.getByAccountIdOrderId("item", storageId, this.indicator.account["id"], this.indicator.order["orderId"])
          //check if there is any data in storage then use BP from storage otherwise get it from salesforce/Vision
          if (this.indicator.order["OrderItems"][i].productCode != "SEM") {
            if (storageItemData[storageId] != undefined && storageItemData[storageId] != null && storageItemData[storageId].BusinessProfile) {
              this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile = storageItemData[storageId].BusinessProfile
              profileIds.push(storageItemData[storageId].BusinessProfile)
            } else {
              //get ListingId from salesforce, if listing id is blank in salesforce then get the listing id from content call
              if (this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].order.listingId != undefined && this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].order.listingId != null && this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].order.listingId != '') {
                this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile = this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].order.listingId
                profileIds.push(this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].order.listingId)
              } else {//get listing id from content call
                if (itemData.ProductHeader) {
                  if (itemData.ProductHeader.listingId) {
                    this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile = itemData.ProductHeader.listingId;
                    profileIds.push(itemData.ProductHeader.listingId)
                  } else if (itemData.ProductHeader.businessLocationId) {
                    this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile = itemData.ProductHeader.businessLocationId;
                    profileIds.push(itemData.ProductHeader.businessLocationId)
                  } else {
                    this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile = ""
                  }
                }
              }
            }
          }
          if (itemModel && itemModel.hasOwnProperty("dirtyFlag") && itemModel.dirtyFlag === true) {
            if (itemModel.hasOwnProperty("CampaignType")) {
              for (let k = 0; k < itemModel.CampaignType.length; k++) {
                if (itemModel.CampaignType[k].hasOwnProperty("ListingId") && itemModel.CampaignType[k].ListingId && itemModel.CampaignType[k].ListingId != undefined && itemModel.CampaignType[k].ListingId != null && itemModel.CampaignType[k].ListingId.trim() !== "") {
                  profileIds.push(itemModel.CampaignType[k].ListingId);
                }
              }
            }
          } else {
            if (itemData !== undefined && itemData.products && itemData.products[0] && itemData.products[0].hasOwnProperty("CampaignList")) {
              for (let m = 0; m < itemData.products[0].CampaignList.length; m++) {
                if (itemData.products[0].CampaignList[m].hasOwnProperty("ListingId") && itemData.products[0].CampaignList[m].ListingId && itemData.products[0].CampaignList[m].ListingId != undefined && itemData.products[0].CampaignList[m].ListingId != null && itemData.products[0].CampaignList[m].ListingId.trim() !== "") {
                  profileIds.push(itemData.products[0].CampaignList[m].ListingId);
                }
              }
            }
          }

        }
      }
      this.indicator.listingIds = profileIds;
    } else if (this.Params.params.mode === "listing") {
      profileIds.push(listingId);
      this.indicator.listingIds = profileIds;
    } else {
      if(this.indicator.account && this.indicator.account['address'] && this.indicator.account['address']['country'] != 'NZ'){
      await this.Profile.getByEid(this.Params.params.eaId)
        .then(profiles => {
          var profile = profiles[this.Params.params.eaId];
          if (profile.hasWarningOrError != "true" && profile.listings && profile.listings.listing) {
            var ids = [];
            for (var i = 0; i < profile.listings.listing.length; i++) {
              ids.push(profile.listings.listing[i].listingId);
            }
            this.indicator.listingIds = ids;
            this.indicator.getprofiledata = profile.listings.listing;
          } else {
            if (profile.hasWarningOrError === 'true') {
              var errorObject = profile.esbServiceFault;
              var errorCode = errorObject[0].sourceSystem;
              var errorDesc = errorObject[0].exceptionDetail[0].errorDescription;
              var errorMsg = "<strong>Transaction Id : " + profile.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              if (errorDesc === "Object not found") {
                errorDesc = "Business Profile does not exist for this Account"
                var errorMDB = "<strong>Transaction Id : " + profile.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.Logger.log(errorMDB)
              } else {
                this.Logger.logError(errorMsg);
              }
              console.log("CA did not find any profiles that are for digital items. Order was null and we checked using enterpriseAccountId. did not help :( ")
            }
          }
        }, err => {
          this.Logger.logError("Error when retrieving digtal profiles");
        });
    }
  }
  };

  async getProfileList(ids): Promise<any> {
    if (ids != undefined && ids != null && ids.length > 0) {
      await this.Profile.getByDigitalListing(this.Utils.getUniqueArray(ids).join()).then(profiles => {
        this.indicator.profiles = profiles;
      }, err => {
        this.Logger.logError("Error when retrieving digtal profiles listings");
      });
    } else {
      this.indicator.profiles = {}
    }
  }
  async processProfile(profiles: any, key: any, forms: any, schemas: any, sortOrder: any): Promise<any> {
    if (profiles[key] && profiles[key].hasWarningOrError === 'true') {
      return;
    } else {
      var profile = {
        data: (key !== "" && profiles != null) ? profiles[key] : {},
        model: {},
        form: forms,
        schema: schemas,
        sortOrder: sortOrder,
        menuOrig: [],
        menu: [],
        BPCatkey: {}
      };
      if (!_.isEqual(profile.data, {})) {
        for (var i = 0; i < profile.form.length; i++) {
          this.Transformer.getMapping(profile.data, profile.form[i], profile.model);
        }
      }

      /*if (profile.model.hasOwnProperty("ConfirmHoursOfOperation")) {
        this.SEMPHours.setHOOConfirmationsInIndicator(this.indicator);
      }*/

      for (var i = 0; i < profile.schema.length; i++) {
        if (profile && profile.data && profile.data.listings && profile.model) {
          if (profile.model["BusinessAddressDisplayOptions"] === undefined && profile.data.listings.listing[0] && profile.data.listings.listing[0].businessAddress) {
            //getData == undefined ? getData = "ALL" : getData;
            profile.model["BusinessAddressDisplayOptions"] = this.getData(profile.data.listings.listing[0].businessAddress.showStreet + profile.data.listings.listing[0].businessAddress.showZip + profile.data.listings.listing[0].businessAddress.showMap);
          }
          if (profile.data.listings.listing[0]) {
            if (profile.data.listings.listing[0].primaryPhone) {
              profile.model["BusinessPhone"] = profile.data.listings.listing[0].primaryPhone.value;
              profile.model["Non-PublishPhone"] = (profile.data.listings.listing[0].primaryPhone.show == "N") ? true : false;
            }
            if (profile.data.listings.listing[0].fax) {
              profile.model["Fax"] = profile.data.listings.listing[0].fax.value;
            }
            if (profile.data.listings.listing[0].alternatePhone) {
              profile.model["PhoneAlternate"] = profile.data.listings.listing[0].alternatePhone.value;
            }
            if (profile.data.listings.listing[0].tollFreePhone) {
              profile.model["PhoneTollFree"] = profile.data.listings.listing[0].tollFreePhone.value;
            }
            if (profile.data.listings.listing[0].websiteUrl) {
              profile.model["BusinessWebsiteURL"] = profile.data.listings.listing[0].websiteUrl.value;
            }
          }
          if (profile.data.listings.listing[0] && profile.data.listings.listing[0].email) {
            profile.model["BPBDEmail"] = profile.data.listings.listing[0].email.value;
            profile.model["Non-PublishEmail"] = (profile.data.listings.listing[0].email.show == "N") ? true : false;
          } else if (this.indicator.account && this.indicator.account["email"]) {
            profile.model["BPBDEmail"] = this.indicator.account["email"];
          } else if (this.indicator.primaryContactEmail) {
            profile.model["BPBDEmail"] = this.indicator.primaryContactEmail;
          }


        }

        var counter = this.RequiredFieldCount.get(profile.schema[i], profile.form[i], profile.model);
        var required = 0;

        for (var property in profile.schema[i].properties) {
          if (profile.schema[i].properties[property].required) {
            required++;
          }
        }

        profile.menuOrig.push({
          content: "Business Profile : " + profile.schema[i].title,
          current: i === 0,
          canceled: false,
          counter: counter,
          required: required,
          state: i + ""
        });
      }

      // Read custom catkeys into model
      let catKeyTypes = ['catkeyAffiliations', 'catkeyBrands', 'catkeyCertifications', 'catkeyProducts', 'catkeyServices', 'catkeySpecialities']
      for (let catKeyType of catKeyTypes) {
        if (profile.data.businessProfiles != undefined &&
          profile.data.businessProfiles != null &&
          profile.data.businessProfiles.businessProfile != undefined &&
          profile.data.businessProfiles.businessProfile != null &&
          profile.data.businessProfiles.hasOwnProperty('businessProfile') &&
          profile.data.businessProfiles.businessProfile.genericData != undefined &&
          profile.data.businessProfiles.businessProfile.genericData != null &&
          profile.data.businessProfiles.businessProfile.hasOwnProperty('genericData') &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType] != undefined &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType] != null &&
          profile.data.businessProfiles.businessProfile.genericData.hasOwnProperty(catKeyType) &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra != undefined &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra != null &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType].hasOwnProperty('catkeyExtra') &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra._content_ != undefined &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra._content_ != null &&
          profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra.hasOwnProperty('_content_')) {
          let extraCatKeys = [];
          let customKeys = [];
          extraCatKeys = profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra._content_.split(';')
          for (let extraCatKey of extraCatKeys) {
            if (extraCatKey != "") {
              let catKeyObj = {};
              catKeyObj['isSelected'] = true
              catKeyObj['text'] = extraCatKey
              catKeyObj['type'] = catKeyType.substring(6)
              customKeys.push(catKeyObj)
            }
          }
          profile.model['CustomCatKeys'][catKeyType.substring(6)] = customKeys
        }
      }

      profile.menu = this.Utils.getSets(profile.menuOrig, profile.menuOrig.length);

      if (key !== 'undefined' && key !== undefined) {
        profiles[key] = profile;
        this.profiles[key] = profile;
        if (this.indicator['profiles'] != undefined && this.indicator['profiles'] != null) {
          this.indicator.profiles[key] = profile;

          /*if (this.indicator.profiles[key].model.hasOwnProperty("ConfirmHoursOfOperation")) {
            this.SEMPHours.setHOOConfirmationsInIndicator(this.indicator);
          }*/
          return profile;
        } else {
          let shared: any;
          this.indicatorQuery.getIndicator().subscribe(data => {
            if (data) {
              shared = { ...data };
              shared['profiles'][key] = profile;
              /*if (shared['profiles'][key].model.hasOwnProperty("ConfirmHoursOfOperation")) {
                this.SEMPHours.setHOOConfirmationsInIndicator(shared);
              }*/
              this.indicatorStore.update(state => {
                return {
                  indicator: shared,
                  updatedFrom: "[IndicatorService] ConfirmHoursOfOperation Indicator Load",
                }
              })
              return profile;
            }
          });
        }
      }
    }
  };

  processPromiseProfile(profiles: any, key: any, forms: any, schemas: any, sortOrder: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (profiles[key] && profiles[key].hasWarningOrError === 'true') {
        return;
      } else {
        var profile = {
          data: (key !== "" && profiles != null) ? profiles[key] : {},
          model: {},
          form: forms,
          schema: schemas,
          sortOrder: sortOrder,
          menuOrig: [],
          menu: [],
          BPCatkey: {}
        };
        if (!_.isEqual(profile.data, {})) {
          for (var i = 0; i < profile.form.length; i++) {
            this.Transformer.getMapping(profile.data, profile.form[i], profile.model);
          }
        }

        /*if (profile.model.hasOwnProperty("ConfirmHoursOfOperation")) {
          this.SEMPHours.setHOOConfirmationsInIndicator(this.indicator);
        }*/

        for (var i = 0; i < profile.schema.length; i++) {

          if (profile && profile.data && profile.data.listings && profile.model) {
            if (profile.model["BusinessAddressDisplayOptions"] === undefined) {
              //getData == undefined ? getData = "ALL" : getData;
              profile.model["BusinessAddressDisplayOptions"] = this.getData(profile.data.listings.listing[0].businessAddress.showStreet + profile.data.listings.listing[0].businessAddress.showZip + profile.data.listings.listing[0].businessAddress.showMap);
            }
            if (profile.data.listings.listing[0]) {
              if (profile.data.listings.listing[0].primaryPhone) {
                profile.model["BusinessPhone"] = profile.data.listings.listing[0].primaryPhone.value;
                profile.model["Non-PublishPhone"] = (profile.data.listings.listing[0].primaryPhone.show == "N") ? true : false;
              }
              if (profile.data.listings.listing[0].fax) {
                profile.model["Fax"] = profile.data.listings.listing[0].fax.value;
              }
              if (profile.data.listings.listing[0].alternatePhone) {
                profile.model["PhoneAlternate"] = profile.data.listings.listing[0].alternatePhone.value;
              }
              if (profile.data.listings.listing[0].tollFreePhone) {
                profile.model["PhoneTollFree"] = profile.data.listings.listing[0].tollFreePhone.value;
              }
              if (profile.data.listings.listing[0].websiteUrl) {
                profile.model["BusinessWebsiteURL"] = profile.data.listings.listing[0].websiteUrl.value;
              }
            }
            if (profile.data.listings.listing[0] && profile.data.listings.listing[0].email) {
              profile.model["BPBDEmail"] = profile.data.listings.listing[0].email.value;
              profile.model["Non-PublishEmail"] = (profile.data.listings.listing[0].email.show == "N") ? true : false;
            } else if (this.indicator.account && this.indicator.account["email"]) {
              profile.model["BPBDEmail"] = this.indicator.account["email"];
            } else if (this.indicator.primaryContactEmail) {
              profile.model["BPBDEmail"] = this.indicator.primaryContactEmail;
            }


          }
          var counter = this.RequiredFieldCount.get(profile.schema[i], profile.form[i], profile.model);
          var required = 0;

          for (var property in profile.schema[i].properties) {
            if (profile.schema[i].properties[property].required) {
              required++;
            }
          }

          profile.menuOrig.push({
            content: "Business Profile : " + profile.schema[i].title,
            current: i === 0,
            canceled: false,
            counter: counter,
            required: required,
            state: i + ""
          });
        }

        // Read custom catkeys into model
        let catKeyTypes = ['catkeyAffiliations', 'catkeyBrands', 'catkeyCertifications', 'catkeyProducts', 'catkeyServices', 'catkeySpecialities']
        for (let catKeyType of catKeyTypes) {
          if (profile.data.businessProfiles != undefined &&
            profile.data.businessProfiles != null &&
            profile.data.businessProfiles.businessProfile != undefined &&
            profile.data.businessProfiles.businessProfile != null &&
            profile.data.businessProfiles.hasOwnProperty('businessProfile') &&
            profile.data.businessProfiles.businessProfile.genericData != undefined &&
            profile.data.businessProfiles.businessProfile.genericData != null &&
            profile.data.businessProfiles.businessProfile.hasOwnProperty('genericData') &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType] != undefined &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType] != null &&
            profile.data.businessProfiles.businessProfile.genericData.hasOwnProperty(catKeyType) &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra != undefined &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra != null &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType].hasOwnProperty('catkeyExtra') &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra._content_ != undefined &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra._content_ != null &&
            profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra.hasOwnProperty('_content_')) {
            let extraCatKeys = [];
            let customKeys = [];
            extraCatKeys = profile.data.businessProfiles.businessProfile.genericData[catKeyType].catkeyExtra._content_.split(';')
            for (let extraCatKey of extraCatKeys) {
              if (extraCatKey != "") {
                let catKeyObj = {};
                catKeyObj['isSelected'] = true
                catKeyObj['text'] = extraCatKey
                customKeys.push(catKeyObj)
              }
            }
            profile.model['CustomCatKeys'][catKeyType.substring(6)] = customKeys
          }
        }

        profile.menu = this.Utils.getSets(profile.menuOrig, profile.menuOrig.length);
        if (key !== 'undefined' && key !== undefined) {
          profiles[key] = profile;
          if (this.indicator['profiles'] != undefined && this.indicator['profiles'] != null) {
            this.indicator.profiles[key] = profile;
            /*if (this.indicator.profiles[key].model.hasOwnProperty("ConfirmHoursOfOperation")) {
              this.SEMPHours.setHOOConfirmationsInIndicator(this.indicator);
            }*/
            return resolve(profile);
          } else {
            let shared: any;
            this.indicatorQuery.getIndicator().subscribe(data => {
              if (data) {
                shared = JSON.parse(JSON.stringify(data));

                shared['profiles'][key] = profile;
                /*if (shared['profiles'][key].model.hasOwnProperty("ConfirmHoursOfOperation")) {
                  this.SEMPHours.setHOOConfirmationsInIndicator(shared);
                }*/
                this.indicatorStore.update(state => {
                  return {
                    indicator: shared,
                    updatedFrom: "[IndicatorService] Process Profile",
                  }
                })
                return resolve(profile);
              }
            });
          }
        }
      }
    })

  };
  async processProfiles(profileSchema: any, profiles: any) {
    var forms = [];
    var schemas = [];
    for (var i = 0; i < profileSchema.length; i++) {
      forms.push(profileSchema[i].form);
      schemas.push(profileSchema[i].schema);
    }
    for (var i = 0; i < forms.length; i++) {
      this.getButtons(i, forms.length - 1, forms[i]);
    }
    var funcs = [];
    for (var key in profiles) {
      if (profiles[key] && profiles[key].hasWarningOrError !== 'true') {
        funcs.push(await this.processProfile(profiles, key, _.cloneDeep(forms), _.cloneDeep(schemas), null));
      } else if (profiles[key].hasWarningOrError === 'true') {
        if (this.Params.params.mode !== "OrderBP") {
          var errorObject = profiles[key].esbServiceFault;
          var errorCode = errorObject[0].sourceSystem;
          var errorDesc = errorObject[0].exceptionDetail[0].errorDescription;
          var errorMsg = "<strong>Transaction Id : " + profiles[key].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
          this.Logger.logError(errorMsg);
        }
        delete this.indicator.profiles[key];
      }

    }
    funcs.push(await this.processProfile({}, "", _.cloneDeep(forms), _.cloneDeep(schemas), null));
    //this.indicator.profiles = funcs;
    if (this.indicator.params.mode == "listing") {
      sessionStorage.setItem("currentListingId", this.indicator.listingIds[0]);
    } else if (this.indicator.params.mode == "ManageBP") {
      sessionStorage.setItem("currentAccountId", this.indicator.params.account);
    }
    return this.indicator;
  };

  async getItemData() {
    let contentDigitalIds = [];
    let contentPrintIds = [];
    let tempPrintDir = [];
    let tempBillingDir = [];
    let isDigitalItemPresent = false;
    this.billingArr = {};
    this.listingArr = {};
    let ids = [];
    let svItemIds = [];
    // Seperate child and parent items  DE31889
    let childDigitalItems = ["Custom Website", "Video Add On", "GVV Add On",
      "Logo Add On", "SEOG Add On", "Social Premium Facebook Add On",
      "Websites Adv Features", "Photo Package Add On", "DPM", "Google Plus Local", "Setup"];
    let updatedsvItemIds = [];
    let productCodes = [];
    let funcsIAgroupIds = [];
    var funcs = {};
    if (this.indicator.order["OrderItems"] === undefined) return;
    for (let i = 0; i < this.indicator.order["OrderItems"].length; i++) {
      //validation for productType and productCode being null
      if (this.indicator.order["OrderItems"][i]) {
        if (!this.indicator.order["OrderItems"][i].productType || !this.indicator.order["OrderItems"][i].productCode) {
          this.Logger.logError("</strong>Error Getting ProductType or ProductCode from SalesForce</strong>");
          return;

        }
        // Seperate child and parent items
        if (childDigitalItems.indexOf(this.indicator.order["OrderItems"][i].productCode) == -1) {
          updatedsvItemIds.push(this.indicator.order["OrderItems"][i].svItemId);
        }
        ids.push(this.indicator.order["OrderItems"][i].svItemId + "v" + this.indicator.order["OrderItems"][i].version);
        svItemIds.push(this.indicator.order["OrderItems"][i].svItemId);
        if (this.indicator.order["OrderItems"][i].productType === "Digital" && (this.indicator.order["OrderItems"][i].actionCode === "Renew" || this.indicator.order["OrderItems"][i].statusCode >= 6000)) { //Added status code condtion for FUL-17406
          if (this.indicator.order["OrderItems"][i].fulfillmentSystem == "Vision") {
            contentDigitalIds.push(this.indicator.order["OrderItems"][i].svItemId);
          }
          if (!isDigitalItemPresent) {
            //Digital Billing
            await this.Billing.get(this.indicator.account["kgenCustomerId"])
              .then(data => {
                //console.log('digital billing arr' + JSON.stringify(data))
                funcs["digitalBilling"] = data;
                this.billingArr["digitalBilling"] = data;
              }, error => {
                this.Logger.logError("</strong>Error Getting Billing  Details :</strong><br>" + JSON.stringify(error))
              });
            //Digital Listing
            if (this.indicator.order["OrderItems"][i].isFYPDigital === false) {
              await this.Profile.getByEid(this.indicator.account["enterpriseAccountId"])
                .then(response => {
                  funcs["digitalListing"] = response;
                  this.listingArr["digitalListing"] = response;
                  //console.log('listing Arr after digital listing' + JSON.stringify(this.listingArr))
                }, err => {
                  this.Logger.logError("</strong>Error Getting Digital Listing  Details :</strong><br>" + JSON.stringify(err))
                });
              isDigitalItemPresent = true;
            }

          }
        } else if (this.indicator.order["OrderItems"][i].productType === "Print") {
          funcsIAgroupIds.push(this.Group.getIAGroupID(this.indicator.order["OrderItems"][i].svItemId, this.indicator.account["kgenCustomerId"], this.indicator.order["OrderItems"][i].directoryNumber));
          // this.Group.getIAGroupID(this.indicator.order["OrderItems"][i].svItemId, this.indicator.account["kgenCustomerId"], this.indicator.order["OrderItems"][i].directoryNumber)
          // .subscribe((data) => {
          //   funcsIAgroupIds.push(data);
          // }, error => {
          //   let message= error && error.error && error.error.message ?  error.error.message :'';
          //   this.Logger.logError("</strong>Error Getting Group Details :</strong><br>" + JSON.stringify(message))
          // });

          contentPrintIds.push(this.indicator.order["OrderItems"][i].svItemId);

          if (tempBillingDir.indexOf(this.indicator.order["OrderItems"][i].directoryNumber) == -1) {
            tempBillingDir.push(this.indicator.order["OrderItems"][i].directoryNumber);
            //Print Billing
            await this.Billing.getItemBilling(this.indicator.account["kgenCustomerId"], this.indicator.order["OrderItems"][i].directoryNumber).toPromise()
              .then(response => {
                //console.log('print billing arr' + JSON.stringify(response))
                funcs["Billing-" + this.indicator.order["OrderItems"][i].directoryNumber] = response;
                this.billingArr[this.indicator.order["OrderItems"][i].directoryNumber] = response;
              }, error => {
                if (error && error.message) {
                  if (error.message == "read ECONNRESET") {
                    this.Logger.log("Service took long time to respond" + JSON.stringify(error));
                  }
                }
                this.Logger.logError("</strong>Error Getting Billing  Details :</strong><br>" + JSON.stringify(error))
              });
          }
          let directoryNumberIssueNumberSection = this.indicator.order["OrderItems"][i].directoryNumber + '-' + this.indicator.order["OrderItems"][i].directoryIssueNumber + '-' + this.indicator.order["OrderItems"][i].section
          if (tempPrintDir.indexOf(directoryNumberIssueNumberSection) == -1) {
            tempPrintDir.push(directoryNumberIssueNumberSection);
            //Print Listing
            await this.Profile.getAllPrintListing(this.indicator.account["kgenCustomerId"], {
              productcode: this.indicator.order["OrderItems"][i].directoryNumber,
              productissuenum: this.indicator.order["OrderItems"][i].directoryIssueNumber,
              sectioncode: this.indicator.order["OrderItems"][i].section
            }).then(response => {
              //console.log('print listing array' + JSON.stringify(response))
              funcs["Listing-" + directoryNumberIssueNumberSection] = response
              this.listingArr[directoryNumberIssueNumberSection] = response[this.indicator.account["kgenCustomerId"]];
              //console.log('listing Arr after print' + JSON.stringify(this.listingArr))
            }, error => {
              this.Logger.logError("</strong>Error Getting Billing  Details :</strong><br>" + JSON.stringify(error))
            });
          }

        } else if (this.indicator.order["OrderItems"][i].productType === "Digital") {
          if (!isDigitalItemPresent && this.indicator.account && this.indicator.account["kgenCustomerId"]) {
            //Digital Billing
            await this.Billing.get(this.indicator.account["kgenCustomerId"])
              .then(data => {
                funcs["digitalBilling"] = data; this.billingArr["digitalBilling"] = data;
              }, error => {
                this.Logger.logError("</strong>Error Getting Billing  Details :</strong><br>" + JSON.stringify(error))
              });
            //Digital Listing
            if (this.indicator.order["OrderItems"][i].isFYPDigital === false) {
              await this.Profile.getByEid(this.indicator.account["enterpriseAccountId"])
                .then(response => { funcs["digitalListing"] = response; this.listingArr["digitalListing"] = response; }
                  , err => {
                    this.Logger.logError("</strong>Error Getting Digital Listing  Details :</strong><br>" + JSON.stringify(err))
                  });
              isDigitalItemPresent = true;
            }

          }
        }
        productCodes.push(this.indicator.order["OrderItems"][i].productCode.replace("/", "_"));
      }
    }
    if (funcsIAgroupIds && funcsIAgroupIds.length != 0) {
      await forkJoin(funcsIAgroupIds).subscribe((resultiagroupids) => {
        this.iaGroupIds = resultiagroupids;
        //console.log('iagroupids' + JSON.stringify(this.iaGroupIds));
        if (contentPrintIds.length != 0 && contentPrintIds.length === this.iaGroupIds.length)
          for (var k = 0; k < contentPrintIds.length; k++) {
           // this.iaGroupIds[k]['svItemId'] = contentPrintIds[k];
            if (typeof this.iaGroupIds[k] === 'object' && this.iaGroupIds[k] !== null) {
              this.iaGroupIds[k]['svItemId'] = contentPrintIds[k];
            } else {
              // Handle the case where this.iaGroupIds[k] is not an object
              console.error('this.iaGroupIds[k] is not an object.');
            }        
          }
        //console.log('iagroupids' + JSON.stringify(this.iaGroupIds));
      }, error => {
        //console.log('funcsIAgroupIds Error ', error);
        let message = error && error.error && error.error.message ? error.error.message : '';
        this.Logger.logError("</strong>Error Getting Group Details :</strong><br>" + JSON.stringify(message))
      });
    }
    if (contentDigitalIds.length > 0) {
      await this.Product.getContent("digital", this.Utils.getUniqueArray(contentDigitalIds).join())
        .then(response => {
          funcs["digitalContent"] = response;
          /*for (let digitalId in contentDigitalIds) {
            if (response[contentDigitalIds[digitalId]].hasWarningOrError === "true") {
              let errorObject = response[contentDigitalIds[digitalId]].esbServiceFault;
              let errorCode = errorObject.sourceSystem;
              let errorDesc = errorObject.exceptionDetail[0].errorDescription;
              let errorMsg = "<strong>Error Getting Digital ProductService Details :</strong><br><strong> Transaction Id : " + response[contentDigitalIds[digitalId]].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              //Logger.logError(errorMsg);
            }
          }*/
        }, error => {
          if (error && error.status != 0) {
            let message = error && error.error && error.error.message ? error.error.message : '';
            this.Logger.logError("</strong>Error Getting Product Details :</strong><br>" + JSON.stringify(message))
          }
        });

      await this.Billing.getESB(this.indicator.order["orderId"])
        .then(response => {
          funcs["KGENBilling"] = response
        })
    }

    if (contentPrintIds.length > 0) {
      await this.Product.getContent("print", this.Utils.getUniqueArray(contentPrintIds).join())
        .then(response => {
          funcs["printContent"] = response;
          /*for (let printID in contentPrintIds) {
            if (response[contentPrintIds[printID]].hasWarningOrError === "true") {
              let errorObject = response[contentPrintIds[printID]].esbServiceFault;
              let errorCode = errorObject.sourceSystem;
              let errorDesc = errorObject.exceptionDetail[0].errorDescription;
              let errorMsg = "<strong>Error Getting Print ProductService Details :</strong><br><strong> Transaction Id : " + response[contentPrintIds[printID]].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              //Logger.logError(errorMsg);
            }
          }*/
        }, error => {
          if (error && error.status != 0) {
            let message = error && error.error && error.error.message ? error.error.message : '';
            this.Logger.logError("</strong>Error Getting Product Details :</strong><br>" + JSON.stringify(message))
          }
        });
    }

    await this.Itemschema.get(encodeURIComponent(this.Utils.getUniqueArray(productCodes).join()))
      .then(response => { funcs["itemSchemas"] = response }
        , error => {
          this.Logger.logError("</strong>Error loading Product Schema :</strong><br>Source system : Salesforce<br>" + JSON.stringify(error))
        });

    if (ids.length) {
      //await this.Storage.get("item", this.Utils.getUniqueArray(ids).join())
      await this.Storage.getByAccountIdOrderId("item", this.Utils.getUniqueArray(ids).join(), this.indicator.account["id"], this.indicator.order["orderId"])
        .then(response => {
          funcs["storageData"] = response;
        }, error => {
          //console.log("get item Error:", error);
        });
      //await this.Storage.get("copyz", this.Utils.getUniqueArray(ids).join())
      await this.Storage.getByAccountIdOrderId("copyz", this.Utils.getUniqueArray(ids).join(), this.indicator.account["id"], this.indicator.order["orderId"])
        .then(response => {
          funcs["storageCopyData"] = response;
        }, error => {
          //console.log("get copyz Error:", error);
        });
    }

    if (svItemIds.length) {
      await this.Copysheet.get(this.Utils.getUniqueArray(ids).join())
        .then(response => { funcs["copysheet"] = response }
          , error => {
            this.Logger.logError("</strong>Error Getting Copysheet Details :</strong><br> Source system : Salesforce<br>" + JSON.stringify(error))
          });
    }

    if (svItemIds.length) {
      await this.Call.getByItemId(this.Utils.getUniqueArray(svItemIds).join())
        .then(response => { funcs["callTracking"] = response }
          , error => {
            this.Logger.logError("</strong>Error Getting Call tracking  Details :</strong><br>" + JSON.stringify(error))
          });
    }

    await this.Heading.get(this.Utils.getUniqueArray(updatedsvItemIds).join())
      .then(response => { funcs["heading"] = response }
        , error => {
          this.Logger.logError("</strong>Error Getting Heading Details :</strong><br> Source system : Salesforce<br>" + JSON.stringify(error))
        });

    funcs["sfData"] = await this.getSfData(this.indicator.order, this.indicator.account, funcs["itemSchemas"])
    //console.log('funcs is' + JSON.stringify(funcs["sfdata"]));
    return funcs;
  };

  getButtons(sortOrder, total, form) {
    if (sortOrder === total) {
      form.push(this.backButton);
      form.push(this.submitButton);
    } else if (sortOrder === 0) {
      form.push(this.cancelButton)
      form.push(this.continueButton);
      form.push(this.deleteButton);
    } else {
      form.push(this.backButton);
      form.push(this.continueButton);
    }
  };

  getData(value) {
    var option;
    if (value === 'YYY' || value === 'YNY') {
      option = 'ALL';
    } else if (value === 'NNN' || value === 'YNN' || value === 'NNY') {
      option = 'HIDE';
    } else if (value === 'NYY' || value === 'NYN') {
      option = 'CITY';
    }
    return option;
  };
  /**
 * getSfData - Gets all the salesforce data for salesforce fields defined in productAttributes object (in salesforce)
 * @param  {Object} order - contains order details
 * @param  {Object} account - contains account details
 * @param  {Object} ItemSchemas - contains schemas for all items
 * @return {$promise}     - the promise that has all returned data
 */
  /* istanbul ignore next */
  getSfData(order, account, ItemSchemas) {
    // Show error message if itemnumber is issing
    order.OrderItems.forEach(function (itm, index) {
      if (!itm.svItemId) {
        this.Logger.logError("An order without an active order item cannot be published in Content Assistant. Update the Salesforce Item id before launching CA");
      }
    })
    //data for large order
    this.Storage.update("order", order.orderId, order).then(function (success) {
    }, error => {
      console.log("get order Error:", error);
    });
    this.Storage.update("account", account.id, account).then(function (success) { });

    let model = {};
    for (let item in ItemSchemas) {  //Run through all schemas
      if (ItemSchemas.hasOwnProperty(item) && ItemSchemas[item].length > 0) {
        //Get the itemId
        var itemId = "";
        var itemIdCounter = -1;
        for (let m = 0; m < order.OrderItems.length; m++) {
          if (ItemSchemas[item][0].productCode === order.OrderItems[m].productCode) {
            itemId = order.OrderItems[m].svItemId;
            itemIdCounter = m;
          }
          model[itemId] = {};
          let form = ItemSchemas[item][0].form;
          let date = '';
          if (itemIdCounter >= 0) {
            for (var k = 0; k < form.length; k++) {    //Run through all fields in the schema
              if (form[k].salesforceField && form[k].salesforceObject) {
                if (form[k].salesforceObject === "Item_Order__c") {
                  if (form[k].salesforceField === "CreatedDate") {
                    date = order.OrderItems[itemIdCounter][this.ItemfieldMapper(form[k].salesforceField)];
                    model[itemId][form[k].key] = date.split('T')[0];
                  } else {
                    var value = order.OrderItems[itemIdCounter][this.ItemfieldMapper(form[k].salesforceField)];
                    if (value) {
                      model[itemId][form[k].key] = "" + value; //Fix for DE13883
                    } else {
                      model[itemId][form[k].key] = "";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return model;
  }

  /**
 * ItemfieldMapper - maps salesforce fieldName coming in schemaForm to what we have in CA order object
 * @param  {Object} order - the salesforce order
 * @return {$promise}     - the promise that has all returned data
 */

  ItemfieldMapper(salesforceField) {
    let map = {
      Geo_Id__c: "geoId",
      Geography_Level__c: "geographyLevel",
      Geography_Name__c: "geoName",
      Geography__c: "geo",
      Estimated_Actions__c: "estimateAction",
      Contract_Term__c: "contractTerm",
      Category__c: "category",
      Category_Display__c: "displayCategory",
      Listing_Id__c: "listingId",
      Listing_Name__c: "listingName",
      Listing__c: "listingTn",
      CreatedDate: "createdDate",
      Bundle_Id__c: "bundleId",
      Advertiser_Budget__c: "adBudget"
    };

    return map[salesforceField];
  }

  /**
 * [processItems description]
 * @param  {[type]} order    [description]
 * @param  {[type]} products [description]
 * @return {[type]}          [description]
 */
  /* istanbul ignore next */
  async processItems(order, products) {
    var items = order.OrderItems;
    var funcs = [];
    for (var i = 0; i < items.length; i++) {
      if (!this.Utils.cancelItem(items[i])) {
        funcs.push(await this.processItem(order, items[i], products.itemSchemas, products.digitalContent, products.printContent, products.sfData, products.storageData, i, products.heading, products.copysheet, products.callTracking, products.storageCopyData, products.KGENBilling));
      }
    }
    this.createBundles(items);
    this.populateItemsFromProfiles();    //This function requires data, both from profiles and items. Hence wrapped in $q.all
    //this.SEMPHours.setHOOConfirmationsInIndicator();
    this.indicator.items = this.items;
  };

  /**
 * [processItem description]
 * @param  {[type]} orderItem      [description]
 * @param  {[type]} itemSchemas    [description]
 * @param  {[type]} digitalContent [description]
 * @param  {[type]} printContent   [description]
 * @return {[type]}                [description]
 */
  async processItem(order, orderItem, itemSchemas, digitalContent, printContent, sfData, storageData, index, heading, copy, calltracking, storageCopyData, kGENBilling) {
    let process = true;
    let uniqueCTs = [];
    let itemCTarray = [];
    let localHeading = {};
    let storageId = orderItem.svItemId + "v" + orderItem.version;
    let data;
    let funcsCopysheet = [];
    let origData = {}
    let requiredFields = [];
    if (orderItem.productType === "Digital" && orderItem.actionCode === "Renew") {
      origData = digitalContent ? digitalContent[orderItem.svItemId] : '';

      if (origData != undefined && origData["hasWarningOrError"] !== "true") {
        data = origData
      } else {
        data = {};
        data.products = [];
        data.products[1] = [];
        data.products[1].trackingLines = {};
        data.products[1].trackingLines.trackingLine = [];

        process = false;
      }
    } else if (orderItem.productType === "Print" && orderItem.actionCode === "Renew") {
      origData = printContent ? printContent[orderItem.svItemId] : '';
      if (origData && origData["hasWarningOrError"] !== "true") {
        data = origData;
        if (this.iaGroupIds && this.iaGroupIds.length != 0) {
          for (var i = 0; i < this.iaGroupIds.length; i++) {
            if (data && this.iaGroupIds[i].svItemId && orderItem.svItemId === this.iaGroupIds[i].svItemId) {
              data.iaGroupId = this.iaGroupIds[i].groupId;
            }
          }
        }
      } else {
        data = {};
        data.products = [];
        data.products[1] = [];
        data.products[1].trackingLines = {};
        data.products[1].trackingLines.trackingLine = [];

        process = false;
      }

    } else if (orderItem.productType === "Print" && orderItem.actionCode === "Add" && orderItem.actionType === "New") {
      data = {};
      data.products = [];
      data.products[1] = [];
      data.products[1].trackingLines = {};
      data.products[1].trackingLines.trackingLine = [];
      process = false;
      if (this.iaGroupIds && this.iaGroupIds.length != 0) {
        for (let i = 0; i < this.iaGroupIds.length; i++) {
          if (this.iaGroupIds[i].svItemId && orderItem.svItemId === this.iaGroupIds[i].svItemId) {
            data.iaGroupId = this.iaGroupIds[i].groupId;
          }
        }
      }
    } else {
      data = {};
      data.products = [];
      data.products[1] = [];
      data.products[1].trackingLines = {};
      data.products[1].trackingLines.trackingLine = [];

      process = false;
    }

    heading.forEach(function (head, headIndex, heads) {
      if (head[orderItem.svItemId]) {
        localHeading = head[orderItem.svItemId]

        if (localHeading["response"] && localHeading["response"].headings && localHeading["response"].headings.heading) {
          localHeading = localHeading["response"].headings.heading[0];
        }
      }

    });
    //if the tibco get CT call returned anything useful add that
    let tempCallArr = [];
    let isItemUnique = true;
    if (calltracking.hasWarningOrError !== "true" && calltracking.trackingLines !== "" && calltracking.trackingLines.trackingLine) {
      let ctLength = calltracking.trackingLines.trackingLine.length;

      for (let j = 0; j < ctLength; j++) {
        isItemUnique = true;
        if (calltracking.trackingLines.trackingLine[j].enterpriseItemId === orderItem.svItemId) {
          if (tempCallArr.length > 0) {
            for (let k = 0; k < tempCallArr.length; k++) {
              if (tempCallArr[k].terminationNumber == calltracking.trackingLines.trackingLine[j].terminationNumber
                && tempCallArr[k].distributionType && calltracking.trackingLines.trackingLine[j].distributionType
                && tempCallArr[k].distributionType == calltracking.trackingLines.trackingLine[j].distributionType) {
                isItemUnique = false;
                break;
              }
            }
            if (isItemUnique) {
              itemCTarray.push(calltracking.trackingLines.trackingLine[j]);
              tempCallArr.push(calltracking.trackingLines.trackingLine[j]);
            }
          } else {
            itemCTarray.push(calltracking.trackingLines.trackingLine[j]);
            tempCallArr.push(calltracking.trackingLines.trackingLine[j]);
          }
        }
      }
    } else if (calltracking.hasWarningOrError === "true" && calltracking.esbServiceFault) {
    }
    //console.log('itemCTarray'+JSON.stringify(itemCTarray))
    //check the vision data call for old call tracking lines
    if (orderItem.productType === "Digital" && process === true && data && data.products && data.products[1] && data.products[1].trackingLines) {
      let ctValue = _.filter(data.products[1].trackingLines, (CT) => {
        if (CT) {
          return CT;
        }
      })
      if (ctValue.length > 0) {
        if (_.isArray(data.products[1].trackingLines)) {
          //console.log('inside trackingLines array')
          let oldArray = data.products[1].trackingLines;
          let newArray = _.map(oldArray, function (obj) {
            return obj.trackingLine;
          });

          itemCTarray.push.apply(itemCTarray, newArray);
          //filter duplicates
          let uniqueLines = []
          itemCTarray.map(x => uniqueLines.filter(a => a.callTrackingId == x.callTrackingId).length > 0 ? null : uniqueLines.push(x));
          itemCTarray = uniqueLines

        }
      } else if (_.isArray(data.products[1].trackingLines.trackingLine)) {
        //console.log('second else if')
        var arghArray = data.products[1].trackingLines.trackingLine;

        itemCTarray.push.apply(itemCTarray, arghArray);
        //filter duplicates
        let uniqueLines = []
        itemCTarray.map(x => uniqueLines.filter(a => a.callTrackingId == x.callTrackingId).length > 0 ? null : uniqueLines.push(x));
        itemCTarray = uniqueLines

      } else if (_.isObject(data.products[1].trackingLines.trackingLine)) {
        //console.log('inside tracking line is object not array')
        //FUL-24398
        if (itemCTarray.length > 0) {
          for (index = 0; index < itemCTarray.length; index++) {
            if (itemCTarray[index].callTrackingId !== data.products[1].trackingLines.trackingLine.callTrackingId) {
              itemCTarray.push(data.products[1].trackingLines.trackingLine);
            }
          }
        } else {
          itemCTarray.push(data.products[1].trackingLines.trackingLine);
        }
        //filter duplicates
        let uniqueLines = []
        itemCTarray.map(x => uniqueLines.filter(a => a.callTrackingId == x.callTrackingId).length > 0 ? null : uniqueLines.push(x));
        itemCTarray = uniqueLines

      }
    }
    //console.log('new itemCTarray'+JSON.stringify(itemCTarray))
    //produce set of unique call tracking based on id
    /* istanbul ignore next */
    if (itemCTarray.length !== 0) {
      //get the unique CTS
      let uniqueCTsBeta = _.uniq(itemCTarray, function (item, key, a) {
        if (item && item.callTrackingId) {
          return item.callTrackingId;
        }
      });
      //var uniqueCTsBeta = _.uniq(itemCTarray);

      //remove any undefined because tibco passes empty strings
      uniqueCTs = _.filter(uniqueCTsBeta, function (item) {
        return item !== undefined;
      })
    }
    let item = {
      data: data,
      order: orderItem,
      model: {},
      form: (itemSchemas[orderItem.productCode.replace("/", "_")].length > 0) ? _.cloneDeep(itemSchemas[orderItem.productCode.replace("/", "_")][0].form) : [],
      schema: (itemSchemas[orderItem.productCode.replace("/", "_")].length > 0) ? _.cloneDeep(itemSchemas[orderItem.productCode.replace("/", "_")][0].schema) : {},
      counter: 0,
      required: 0,
      requiredFields: [],
      heading: localHeading,
      calltracking: uniqueCTs,
      sfData: {},
    };
    //console.log('uniqueCTs'+JSON.stringify(uniqueCTs))
    //If YPC item is Mobile then delete the entry from scema and form
    /* istanbul ignore next */
    // if (item.order.UDAC && item.order.UDAC === "ML") {
    //   if (item.schema.properties.hasOwnProperty("ManageCopysheet")) {
    //     delete item.schema.properties["ManageCopysheet"];
    //     for (var i = 0; i < item.form.length; i++) {
    //       if (item.form[i].key === "ManageCopysheet") {
    //         item.form.splice(i, 1);
    //       }
    //     }
    //   }
    // }

    if (item.heading && (item.heading["licenseRequiredTyp"] !== "" || item.heading["licenseRequiredTyp"] !== null || item.heading["licenseRequiredTyp"] !== undefined) &&
      ((item.heading["licenceRequiredInd"] === 'Y' && item.heading["licenseRequiredTyp"] !== 'C') ||
        (item.heading["licenceRequiredInd"] === 'N' && item.heading["licenseRequiredTyp"] === 'C') ||
        (item.heading["licenceRequiredInd"] === 'Y' && item.heading["licenseRequiredTyp"] === 'C'))) {
      for (var key in item.form) {    //If License number field is present then make it mandatory DE24074
        if (item.form[key].key === "LicenseNumber") {
          item.form[key].htmlClass = " requiredField";
          item.schema.properties.LicenseNumber.required = true;
          item.form[key].$validators = {//Question:check this
            mandatoryField: function (value) {
              if (value.length > 0) return true;
              return false;
            }
          };
          break;
        }
      }

    }
    item.sfData = sfData[orderItem.svItemId];
    if (process) {
      this.Transformer.getMapping(item.data, item.form, item.model);
    }
    //this.Transformer.getSalesforceMapping(item.sfData, item.model);
    //console.log('item model after mapping'+JSON.stringify(item.model))
    item.model = { ...item.sfData, ...item.model };
    // let tempItemModel = Object.assign({},item.model);


    // if(item.model['CampaignType'] && item.model['CampaignType'].length > 0){
    //   let modelCampTypeLength = item.model['CampaignType'].length;
    //   for(let j = 0; j< modelCampTypeLength; j++){
    //     if(!item.model['CampaignType'][j]['TargetZIPcode'] || !(item.model['CampaignType'][j]['TargetZIPcode'].trim().length > 0)){
    //       item.model['CampaignType'][j]['TargetZIPcode'] = item.model['CampaignType'][j]['Zip']
    //     }
    //   }
    // }
    if (storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'] &&
      storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'].length > 0) {
      let campTypeLength = storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'].length;
      for (let i = 0; i < campTypeLength; i++) {
        if (storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] == '0') {
          if (item.model['CampaignType'] && item.model['CampaignType'].length > 0) {
            let modelCampTypeLength = item.model['CampaignType'].length;
            for (let j = 0; j < modelCampTypeLength; j++) {
              if (storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['CampaignId'] ==
                item.model['CampaignType'][j]['CampaignId']) {
                storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] = item.model['CampaignType'][j]['TargetZIPcode']
                break;
              }
            }
          }
        }
        // else if(storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] && 
        // !(storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'].trim().length > '0')){
        //   if(item.model['CampaignType'] && item.model['CampaignType'].length > 0){
        //     let modelCampTypeLength = item.model['CampaignType'].length;
        //     for(let j = 0; j< modelCampTypeLength; j++){
        //       if(storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['CampaignId'] == 
        //       item.model['CampaignType'][j]['CampaignId']){
        //         storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] = 
        //         item.model['CampaignType'][j]['TargetZIPcode'] ? item.model['CampaignType'][j]['TargetZIPcode'] : item.model['CampaignType'][j]['Zip']
        //         break;
        //       }
        //     }
        //   }
        // } 
        else if ((storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] &&
          !(storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'].trim().length > '0')) ||
          !storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode']) {
          if (item.model['CampaignType'] && item.model['CampaignType'].length > 0) {
            let modelCampTypeLength = item.model['CampaignType'].length;
            for (let j = 0; j < modelCampTypeLength; j++) {
              if (storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['CampaignId'] ==
                item.model['CampaignType'][j]['CampaignId']) {
                if (item && item.model && item.model['CampaignType'][i] && ((item.model['CampaignType'][i]['TargetZIPcode'] && !(item.model['CampaignType'][i]['TargetZIPcode'].trim().length > '0')) ||
                  !item.model['CampaignType'][i]['TargetZIPcode'])) {
                  storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] =
                    item.model['CampaignType'][j]['TargetZIPcode'] ? item.model['CampaignType'][j]['TargetZIPcode'] : item.model['CampaignType'][j]['Zip']
                  break;
                } else {
                  storageData[orderItem.svItemId + "v" + orderItem.version]['CampaignType'][i]['TargetZIPcode'] =
                    item.model['CampaignType'][j]['TargetZIPcode'] ? item.model['CampaignType'][j]['TargetZIPcode'] : item.model['CampaignType'][j]['Zip']
                  break;
                }

              }
            }
          }
        }
      }
    }

    item.model = Object.assign({}, item.model, storageData[orderItem.svItemId + "v" + orderItem.version]);
    // item.model = _.merge(item.model,  storageData[orderItem.svItemId + "v" + orderItem.version]);


    //console.log('item model after storage'+JSON.stringify(item.model))
    // fix for FUL-30947. Geoid was in the model was getting updted from vision. So need to use the data from SF itemorder 
    if (item.order && item.order.productCode === 'Single IYP' && item.order.actionCode == "Renew" && item.order.actionType == "Update") {
      item.model["GeoID"] = item.order.geoId;
    }
    if (!item.model["BillingAccount"] && orderItem.actionCode != 'Renew' && item.order.statusCode < 6000) {
      item.model["BillingAccount"] = ""; //Initializing billingAccount to empty string
    }
    if (item.model["BillingAccount"] == '' && item.order.statusCode >= 6000) {
      item.model["BillingAccount"] = item.order.billingAccountId; //Initializing billingAccount to empty string
    }

    // Add ListingId for print items in model
    if (orderItem.productType == 'Print' && !item.model.hasOwnProperty('ListingId')) {
      item.model['ListingId'] = orderItem.listingId;
    } else if (orderItem.productType == 'Print' && item.model.hasOwnProperty('ListingId') && orderItem.actionCode === "Renew" && item.model['ListingId'] == "") {
      item.model['ListingId'] = orderItem.listingId;
    }
    /* if( item.model.hasOwnProperty('BillingAccount') && orderItem.actionCode === "Renew" && item.model['BillingAccount'] == ""){
      item.model['BillingAccount'] = orderItem.billingAccountId;
    } */

    if (item.schema.properties && item.schema.properties.hasOwnProperty('CallTracking')) {
      var productCTArray = [];
      //Condition for print renew item
      if (orderItem.productType == 'Print' && orderItem.actionCode === "Renew" && item.model["CallTracking"] === "false") {
        item.model["modelValue"] = "No";
      }
      if (orderItem.productType == 'Print' && !item.model.hasOwnProperty('CallTracking')) {
        item.model["CallTracking"] = "";
      } else if (orderItem.productType == 'Digital' && !item.model.hasOwnProperty('CallTracking')) {
        item.model["CallTracking"] = [];
      }
      if (orderItem.productType === "Digital" && orderItem.actionCode === "Renew") {
        if (item.data && item.data.products && item.data.products[1] && item.data.products[1].hasOwnProperty('trackingLines')) {
          productCTArray = item.data.products[1].trackingLines;
        }

        let visionData = [];

        let ctValue = _.filter(productCTArray, (CT) => {
          if (CT) {
            return CT;
          }
        })
        if (_.isArray(productCTArray)) {
          if (productCTArray.length > 0 && ctValue.length > 0) {
            for (let ctLine in uniqueCTs) {
              for (let i = 0; i < productCTArray.length; i++) {
                if (uniqueCTs[ctLine].callTrackingId === productCTArray[i].trackingLine.callTrackingId) {
                  visionData.push(uniqueCTs[ctLine]);
                }
              }
            }
          } else if (productCTArray.length == 0) {
            visionData = uniqueCTs
          }

        } else if (productCTArray) {
          if (_.isObject(productCTArray["trackingLine"])) {
            for (let ctLine in uniqueCTs) {
              if (uniqueCTs[ctLine].callTrackingId === productCTArray['trackingLine'].callTrackingId) {
                visionData.push(uniqueCTs[ctLine]);
              }
            }
          }
        }
        //console.log('vision data'+JSON.stringify(visionData))
        item.calltracking = [];
        uniqueCTs = [];
        uniqueCTs = visionData;
        item.calltracking = uniqueCTs;
      }
      if (orderItem.actionCode === "Renew" || (orderItem.actionCode === "Add" && orderItem.actionType === "Update")) {
        item.model = await this.Transformer.ctMapping(uniqueCTs, item.order, item.model);
        //console.log('model after cTMapping is'+JSON.stringify(item.model))
      } else if (orderItem.actionCode === "Add" && orderItem.actionType === "New") {
        if (uniqueCTs && uniqueCTs.length !== 0) {
          item.model = await this.Transformer.ctMapping(uniqueCTs, item.order, item.model);
          //console.log('model after cTMapping is'+JSON.stringify(item.model))
        }
      }
      //console.log('uniqueCTs'+JSON.stringify(uniqueCTs))
      //console.log('model is'+JSON.stringify(item.model))
      //console.log('storageid'+storageId)
      if (orderItem.actionCode === "Renew" || (orderItem.actionCode === "Add" && orderItem.actionType === "Update")) {
        await this.Storage.getByAccountIdOrderId("item", storageId, this.indicator.account["id"], this.indicator.order["orderId"])
          .then(async data => {
            if (data[storageId] != undefined && data[storageId].modelValue) {
              item.model["modelValue"] = data[storageId].modelValue
              //console.log('inside storage defined')
              if (item.model["modelValue"] === "Yes") {
                await this.Storage.getByAccountIdOrderId("call", storageId, this.indicator.account["id"], this.indicator.order["orderId"])
                  .then(async response => {
                    //console.log('inside call result'+JSON.stringify(response[storageId]))
                    if (response && response[storageId] != undefined && response[storageId].callTracking && response[storageId].callTracking != undefined && response[storageId].callTracking != null && response[storageId].callTracking.length > 0) {
                      let tempCtArray=[];
                      if (productCTArray && productCTArray.length > 0){
                        for (let ctLine in productCTArray) {
                          if(!response[storageId].callTracking.find(x=> x.callTrackingId === productCTArray[ctLine].trackingLine.callTrackingId )){
                            tempCtArray.push(productCTArray[ctLine].trackingLine);
                          }
                          
                        }
                      }
                      item.calltracking = [...response[storageId].callTracking, ...tempCtArray];
                      item.model = await this.Transformer.ctMapping(item.calltracking, item.order, item.model);
                    }/*else{
                  item.model["CallTracking"] = []
                  item.calltracking = []                  
                }*/
                  });
              } else {
                item.model["CallTracking"] = []
                item.calltracking = []
              }
            } else {
              if (orderItem.productType === "Digital") {
                if (orderItem.productCode === "SEM" || orderItem.productCode === "SEMP" || orderItem.productCode === "SEM Kicker") {
                  if (item.data && item.data.products && item.data.products[0]) {
                    if (item.data.products[0]["CallTrackingEnabled"] && item.data.products[0]["CallTrackingEnabled"].toLowerCase() == "true") {
                      item.model["modelValue"] = "Yes"
                      //console.log('modelValue for digital renewal'+item.model["modelValue"])
                    } else if (item.data.products[0]["CallTrackingEnabled"] && item.data.products[0]["CallTrackingEnabled"].toLowerCase() == "false") {
                      item.model["modelValue"] = "No"
                      item.model["CallTracking"] = []
                      item.calltracking = []
                    }
                  } else {
                    item.model["modelValue"] = "No"
                    item.model["CallTracking"] = []
                    item.calltracking = []
                  }
                } else {
                  if (item.data && item.data.products && item.data.products[0] && item.data.products[0].CallTracking) {
                    if (item.data.products[0].CallTracking != undefined && item.data.products[0].CallTracking == 'N') {
                      item.model["modelValue"] = "No"
                      item.model["CallTracking"] = []
                      item.calltracking = []
                    } else {
                      item.model["modelValue"] = "Yes"
                    }
                  }
                }
              } else {//for print
                if (item.data && item.data.products && item.data.products.product) {
                  let callTrackingArray = item.data.products.product.trackingLines
                  if (_.isArray(callTrackingArray)) {
                    if (item.data.products.product.trackingLines && item.data.products.product.trackingLines.length > 0) {
                      item.model["modelValue"] = "Yes"
                    } else {
                      item.model["modelValue"] = "No"
                      item.model["CallTracking"] = ""
                      item.calltracking = []
                    }
                  } else if (_.isObject(callTrackingArray)) {
                    if (item.data.products.product.trackingLines.trackingLine && _.isArray(item.data.products.product.trackingLines.trackingLine)) {
                      if (item.data.products.product.trackingLines.trackingLine.length > 0) {
                        item.model["modelValue"] = "Yes"
                      } else {
                        item.model["modelValue"] = "No"
                        item.model["CallTracking"] = ""
                        item.calltracking = []
                      }
                    } else if (item.data.products.product.trackingLines.trackingLine && _.isObject(item.data.products.product.trackingLines.trackingLine)) {
                      if (item.data.products.product.trackingLines.trackingLine && item.data.products.product.trackingLines.trackingLine.callTrackingId && item.data.products.product.trackingLines.trackingLine.callTrackingId != undefined && item.data.products.product.trackingLines.trackingLine.callTrackingId != null && item.data.products.product.trackingLines.trackingLine.callTrackingId != '') {
                        item.model["modelValue"] = "Yes"
                      } else {
                        item.model["modelValue"] = "No"
                        item.model["CallTracking"] = ""
                        item.calltracking = []
                      }
                    }
                  } else {
                    item.model["modelValue"] = "No"
                    item.model["CallTracking"] = ""
                    item.calltracking = []
                  }
                }
              }
            }
          })

      }
    }
    //Listing Array for Print and Digital Items
    if (item.order.productType == "Digital") {
      if (this.listingArr["digitalListing"] && item.order.productCode != 'Thryv RecBIY' && item.order.productCode != 'Vivial - Digital') {
        let result = this.listingArr["digitalListing"][this.indicator.account["enterpriseAccountId"]];
        let listings = [];
        if (result && result.hasWarningOrError === "false" && result.listings) {
          let lists = result.listings.listing;
          item['listings'] = lists;
          let phoneNumLocal;
          for (let i = 0; i < lists.length; i++) {
            if ((item.order.listingId && item.order.listingId == lists[i].listingId)
              || (item.model["BusinessProfile"] && item.model["BusinessProfile"] == lists[i].listingId) || (item.order.listingId == null && item.schema && item.schema.properties && item.schema.properties.hasOwnProperty('BusinessProfile'))) {
              if (lists[i].primaryPhone) {
                // phoneNumLocal = this.Utils.transformTelephone(lists[i].primaryPhone.value);
                phoneNumLocal = lists[i].primaryPhone.value;
              } else {
                if (!this.displayedMissingDataError) {
                  this.Logger.logError("An order without a primary phone number cannot be published in Content Assistant.  Update the Account before launching CA");
                  this.displayedMissingDataError = true;
                }
              }
            } else {
              if (lists[i].primaryPhone) {
                phoneNumLocal = this.Utils.transformTelephone(lists[i].primaryPhone.value);
                phoneNumLocal = lists[i].primaryPhone.value;
              }
            }

            // let name = lists[i].businessName + "," + phoneNumLocal + " " + lists[i].businessAddress.streetAddress + " " + lists[i].businessAddress.city + ", " + lists[i].businessAddress.state + " " + lists[i].businessAddress.zip;
            // if (lists[i].businessAddress.zip4 && lists[i].businessAddress.zip4.trim() !== '') {
            //   name += '-' + lists[i].businessAddress.zip4
            // }
            let name = lists[i].businessName + "," +
              phoneNumLocal + " " +
              lists[i].businessAddress.streetAddress + " " +
              lists[i].businessAddress.city + " " +
              lists[i].businessAddress.state + " ";

            // Check if both zip and zip4 exist, and concatenate accordingly
            if (lists[i].businessAddress.zip && lists[i].businessAddress.zip4) {
              name += lists[i].businessAddress.zip + "-" + lists[i].businessAddress.zip4.trim();
            } else if (lists[i].businessAddress.zip) {
              name += lists[i].businessAddress.zip.trim();
            } else if (lists[i].businessAddress.zip4 && lists[i].businessAddress.zip4.trim() !== '') {
              name += lists[i].businessAddress.zip4.trim();
            }

            // name += "," + this.country;

            /*if (this.indicator.profiles[lists[i].listingId] != null) {//Question:uncomment it
              //phoneNumLocal = $filter('telephone')(this.indicator.profiles[lists[i].listingId].model.BusinessPhone, false);
              phoneNumLocal = this.indicator.profiles[lists[i].listingId].model.BusinessPhone
              name = this.indicator.profiles[lists[i].listingId].model.BusinessName + "," + phoneNumLocal + " " + this.indicator.profiles[lists[i].listingId].model.BusinessAddress1 + " " + this.indicator.profiles[lists[i].listingId].model.BusinessAddressCity + ", " + this.indicator.profiles[lists[i].listingId].model.BusinessAddressState + " " + this.indicator.profiles[lists[i].listingId].model.BusinessAddressZip.zip;
              if (lists[i].BusinessAddressZip !== undefined && lists[i].BusinessAddressZip.zip4.trim() !== '') {
                name += '-' + lists[i].BusinessAddressZip.zip4
              }
            }*/
            var listing = {
              name: name,
              value: lists[i].listingId
            };
            listings.push(listing);
          }
          item['listingArray'] = listings;
        } else {
          if(this.indicator.account && this.indicator.account['address'] && this.indicator.account['address']['country'] != 'NZ'){
          if (result && result.esbServiceFault) {
            let errorObject = result.esbServiceFault[0];
            let errorCode = errorObject.exceptionDetail[0].errorCode;
            let errorDesc = errorObject.exceptionDetail[0].errorDescription;
            if (errorDesc && errorDesc == "Object not found") {// FUL-19139 in angualr 1.5
              let updatedErrorDesc = "Business Profile does not exist for this Account";
              this.Logger.log(errorCode + ' - ' + updatedErrorDesc);
            } else {
              this.Logger.logError(errorCode + ' - ' + errorDesc);
            }
          } else {
            this.Logger.logError("Error while fetching listing data.11")
          }
        }
      }
      }
    }
    else {
      let directoryNumberIssueNumberSection = item.order.directoryNumber + '-' + item.order.directoryIssueNumber + '-' + item.order.section
      let result = this.listingArr[directoryNumberIssueNumberSection];
      let listings = [];
      if (result.hasWarningOrError === "false" && result.listings) {
        let lists = result.listings.listing;
        item['listings'] = lists;
        let directory_code = null;
        for (let i = 0; i < lists.length; i++) {
          directory_code = item.order.directoryNumber;
          let listing = {
            name: lists[i].listedName + "," + lists[i].listedTN + " " + lists[i].address + " " + directory_code,
            // name: lists[i].listedName + "," + lists[i].listedTN + " " + lists[i].address + ","  + this.country  + "," + directory_code,
            value: lists[i].listingId
          };
          listings.push(listing);
        }
        item['listingArray'] = listings;

      } else {
        if (result && result.esbServiceFault && result.esbServiceFault[0] && result.esbServiceFault[0].exceptionDetail && result.esbServiceFault[0].exceptionDetail[0]) {
          let errorObject = result.esbServiceFault[0];
          let errorCode = errorObject.exceptionDetail[0].errorCode;
          let errorDesc = errorObject.exceptionDetail[0].errorDescription;
          this.Logger.logError(errorCode + ' - ' + errorDesc);
        } else {
          this.Logger.logError("Error while fetching listing data.");
        }
      }
    }

    //Billing Array for Print and Digital items
    let billAccount = [];
    try {
      if (item.order.productType === "Print" && this.indicator.account["kgenCustomerId"]) {
        //Get billing accounts for Print products
        for (let key in this.billingArr) {
          if (key == item.order.directoryNumber) {
            let billings = this.billingArr[key];
            if (billings && billings.billing) {
              if (billings.hasWarningOrError === "false") {
                for (let i = 0; i < billings.billing.length; i++) {
                  if (billings.billing[i].digitalAdvPayInd === "N" && billings.billing[i].digitalBcBillerInd === "N" && billings.billing[i].billingAddress) {
                    // let name = billings.billing[i].billingName + " " + billings.billing[i].billingAddress.streetAddress + " " + billings.billing[i].billingAddress.cityName + " " + billings.billing[i].billingAddress.stateAbbr + " " + billings.billing[i].billingAddress.zip4;
                    // let billAddr = billings.billing[i].billingAddress.streetAddress + ',' + billings.billing[i].billingAddress.cityName + ',' + billings.billing[i].billingAddress.stateAbbr + ',' + billings.billing[i].billingAddress.zip5;
                    let name = billings.billing[i].billingName + " " + billings.billing[i].billingAddress.streetAddress + " " + billings.billing[i].billingAddress.cityName + " " + billings.billing[i].billingAddress.stateAbbr + " " + billings.billing[i].billingAddress.zip4 + " " + this.country;
                    let billAddr = billings.billing[i].billingAddress.streetAddress + ',' + billings.billing[i].billingAddress.cityName + ',' + billings.billing[i].billingAddress.stateAbbr + ',' + billings.billing[i].billingAddress.zip5 + "," + this.country;
                    let billAccType = "";
                    let eachAccount = {};
                    eachAccount["value"] = billings.billing[i].billingAccountId;
                    eachAccount["name"] = name + ' - ' + billings.billing[i].billingAccountId;
                    eachAccount["businessEntity"] = billings.billing[i].businessEntity;
                    eachAccount["lastBilledAmount"] = this.Utils.showtwoDecimalDigit(billings.billing[i].lastBilledAmount);
                    eachAccount["digitalItemInd"] = billings.billing[i].digitalItemInd;
                    if (billings.billing[i].digitalAdvPayInd === "N" && billings.billing[i].digitalBcBillerInd === "N") {
                      eachAccount["type"] = "Invoice";
                    }

                    if (eachAccount["type"] == "Invoice") {
                      billAccType = "I"
                    }
                    else {
                      billAccType = "M";
                    }
                    // eachAccount["finalName"] = billings.billing[i].billingName + '/' + billings.billing[i].btn.telephoneType.NPA + '-' + billings.billing[i].btn.telephoneType.COP + '-' + billings.billing[i].btn.telephoneType.LINE + '/' + billAddr + '/' + billAccType + '/' + '$' + this.Utils.showtwoDecimalDigit(billings.billing[i].lastBilledAmount) + '/' + billings.billing[i].businessEntity + ' - ' + billings.billing[i].billingAccountId;
                    eachAccount["finalName"] = billings.billing[i].billingName + '/' + billings.billing[i].btn + '/' + billAddr + '/' + billAccType + '/' + '$' + this.Utils.showtwoDecimalDigit(billings.billing[i].lastBilledAmount) + '/' + billings.billing[i].businessEntity + ' - ' + billings.billing[i].billingAccountId;
                    billAccount.push(eachAccount);
                  }
                }
                item['billingArray'] = billAccount;
                this.indicator.printBilling = billAccount;
                if (item.model.hasOwnProperty("BillingAccount") && item.model['BillingAccount']) {
                  let tempBillingAccount = billAccount ? billAccount.find(billing => billing.value == item.model['BillingAccount']) : '';
                  item.model['BillingAccount'] = tempBillingAccount ? tempBillingAccount.value : '';
                }
              } else if (billings.esbServiceFault && billings.esbServiceFault.exceptionDetail && billings.esbServiceFault.exceptionDetail[0]) {
                let errorObject = billings.esbServiceFault;
                let errorCode = errorObject.sourceSystem;
                let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                let errorMsg = "<strong>Transaction Id : " + billings.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.Logger.logError(errorMsg);
                item['billingError'] = errorMsg;
              }
            }
          }
        }
      } else {
        //Get billing accounts for Digital products
        let billings = this.billingArr["digitalBilling"];
        if (billings && billings.hasWarningOrError === "true" && billings.esbServiceFault && billings.esbServiceFault.exceptionDetail && billings.esbServiceFault.exceptionDetail[0]) {
          let errorObject = billings.esbServiceFault;
          let errorCode = errorObject.sourceSystem;
          let errorDesc = errorObject.exceptionDetail[0].errorDescription;
          let errorMsg = "<strong>Transaction Id : " + billings.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
          this.Logger.logError(errorMsg);
          item['billingError'] = errorMsg;
        } else if (billings && billings.hasWarningOrError === "false" && billings.billing) {
          billAccount = [];
          let status = ['suspended', 'pre-suspend', 'canceled'];
          for (let i = 0; i < billings.billing.length; i++) {
            if (((status.indexOf(billings.billing[i].fulfillmentCtrlStatus.toLowerCase()) == -1) &&
              ((billings.billing[i].billingAccountId === item.order.billingAccountId && billings.billing[i].digitalItemInd === "Y")
                || (billings.billing[i].digitalAdvPayInd === "Y" || billings.billing[i].digitalItemInd === "Y"))) || (item.order.statusCode >= 6000)) {
              // let name = billings.billing[i].billingName + " " + billings.billing[i].billingAddress.streetAddress + " " + billings.billing[i].billingAddress.cityName + " " + billings.billing[i].billingAddress.stateAbbr + " " + billings.billing[i].billingAddress.zip4;
              // let billAddr = billings.billing[i].billingAddress.streetAddress + ',' + billings.billing[i].billingAddress.cityName + ',' + billings.billing[i].billingAddress.stateAbbr + ',' + billings.billing[i].billingAddress.zip5;
              let name = billings.billing[i].billingName + " " + billings.billing[i].billingAddress.streetAddress + " " + billings.billing[i].billingAddress.cityName + " " + billings.billing[i].billingAddress.stateAbbr + " " + billings.billing[i].billingAddress.zip4 + " " + this.country;
              let billAddr = billings.billing[i].billingAddress.streetAddress + ',' + billings.billing[i].billingAddress.cityName + ',' + billings.billing[i].billingAddress.stateAbbr + ',' + billings.billing[i].billingAddress.zip5 + "," + this.country;
              let billAccType = "";
              let eachAccount = {};
              eachAccount["value"] = billings.billing[i].billingAccountId;
              eachAccount["name"] = name + ' - ' + billings.billing[i].billingAccountId;
              eachAccount["businessEntity"] = billings.billing[i].businessEntity;
              eachAccount["lastBilledAmount"] = this.Utils.showtwoDecimalDigit(billings.billing[i].lastBilledAmount);
              eachAccount["digitalItemInd"] = billings.billing[i].digitalItemInd;
              eachAccount["fulfillmentCtrlStatus"] = billings.billing[i].fulfillmentCtrlStatus;
              if (billings.billing[i].digitalAdvPayInd === "N" && billings.billing[i].digitalBcBillerInd === "N") {
                eachAccount["type"] = "Invoice";
              } else if (billings.billing[i].digitalAdvPayInd === "Y" && billings.billing[i].digitalBcBillerInd === "N") {
                eachAccount["type"] = "MAP";
              } else if (billings.billing[i].digitalAdvPayInd === "N" && billings.billing[i].digitalBcBillerInd === "Y") {
                eachAccount["type"] = "CC";
              } else {
                eachAccount["type"] = "MAP";
              }

              if (eachAccount["type"] == "Invoice") {
                billAccType = "I"
              }
              else {
                billAccType = "M"
              }
              // eachAccount["finalName"] = billings.billing[i].billingName + '/' + billings.billing[i].btn.telephoneType.NPA + '-' + billings.billing[i].btn.telephoneType.COP + '-' + billings.billing[i].btn.telephoneType.LINE + '/' + billAddr + '/' + billAccType + '/' + '$' + this.Utils.showtwoDecimalDigit(billings.billing[i].lastBilledAmount) + '/' + billings.billing[i].businessEntity + ' - ' + billings.billing[i].billingAccountId;
              eachAccount["finalName"] = billings.billing[i].billingName + '/' + billings.billing[i].btn + '/' + billAddr + '/' + billAccType + '/' + '$' + this.Utils.showtwoDecimalDigit(billings.billing[i].lastBilledAmount) + '/' + billings.billing[i].businessEntity + ' - ' + billings.billing[i].billingAccountId;
              // if((item.order.UDAC != "PRINTOLN" )||(item.order.UDAC == "PRINTOLN" &&  eachAccount["type"] != "MAP")){
              billAccount.push(eachAccount);
              // }             
            }
          }
          //Added it to Billing Array
          item['billingArray'] = billAccount;
          this.indicator.digitalBilling = billAccount;
          if (item.model.hasOwnProperty("BillingAccount") && item.model['BillingAccount']) {
            let tempBillingAccount = billAccount ? billAccount.find(billing => billing.value == item.model['BillingAccount']) : '';
            item.model['BillingAccount'] = tempBillingAccount ? tempBillingAccount.value : '';
          }
        }
      }
    }
    catch (error) {
      this.Logger.logError("Please check the billing account values coming from KGEN.It may have missing one of the value - 1.Billing Account Id - 2./Business Name/TN/Address (house number, street, city, state and zip code) /Type (M or I)/Last Bill Amount/Entity")
    }

    if (item.order.actionCode === "Renew" && item.order.productType === "Digital") {
      if (kGENBilling != null && kGENBilling.billAccountId && kGENBilling.billAccountId != null) {
        _.map(kGENBilling.billAccountId, async (element) => {
          let billingAccountId;
          if (element != null) {
            if (element.svitemID == orderItem.svItemId) {
              billingAccountId = element.ID;
              let bilIdPresent = _.find(this.indicator.digitalBilling, (bil) => {
                if (bil.value == billingAccountId) {
                  return bil;
                }
              })
              if (bilIdPresent) {
                item.model['BillingAccount'] = billingAccountId
              }
            }
          }
        })
      }

    }

    for (let i = 0; i < item.form.length; i++) {
      if (item.schema && item.schema.properties && item.schema.properties[item.form[i].key] != null &&
        item.schema.properties[item.form[i].key].hasOwnProperty("required") &&
        item.schema.properties[item.form[i].key].required) {
        if (item.form[i].key !== 'BillingBasisBRM') {
          if (item.order.type == "Mobile" && item.order.UDAC && item.order.UDAC === "ML") {
            if (!(item.form[i].key == 'CallTracking' || item.form[i].key == 'ManageCopysheet')) {
              item.required++;
              item.requiredFields.push(item.form[i].key);
            }
          } else {
            item.required++;
            item.requiredFields.push(item.form[i].key);
          }
        }
        //item.requiredFields.push(item.schema.properties[item.form[i].key].title);
        // if(!item.model[item.form[i].key]){
        //   item.model[item.form[i].key] = "";
        // }
        // Temp code to populate modelValue in indicator
        /*if (item.form[i].key == 'CallTracking' && !item.model['modelValue']) {
          item.model['modelValue'] = 'Yes';
        }*/

      }
      if ((orderItem.statusCode >= 6000 && orderItem.productType == "Print") ||
        (orderItem.statusCode >= 7000 && orderItem.productType == "Digital")) {
        let index = item.form[i].key;
        if (index != null) {
          if (item.form[i].type == "managecopy") {
            item.schema.properties[index].readonly = false;
            item.form[i].readonly = false;
            item["requiresCompiled"] = true;
          } else {
            item.schema.properties[index].readonly = true;
            item.form[i].readonly = true;
          }
        }
      }
      if (item.form[i].type === "managecopy") {
        if (!item.model["ManageCopysheet"]) {
          item.model["ManageCopysheet"] = {}
        }
        if (item.order.actionCode === "Add" && item.order.type !== "Mobile") {
          //all new items with copysheet field require copysheets
          item["requiresCopysheet"] = true;
          //item.required++;

        } else if (item.order.actionCode === "Renew" && (item.order.requireContent === true ||
          (item.order.requireContent === false && storageCopyData[storageId] && storageCopyData[storageId].requiresCopysheet == true))) {
          //renewal items changed in PB require copysheets
          if(item.order.UDAC != "ML"){
          item["requiresCopysheet"] = true;
          item.order.requireContent = true;
          // item.required++;
          item.order.productType === "Print" ? item.model["ManageCopysheet"].copySheetIndicator = "Y" : item.model["copySheetIndicator"] = "Y";
          }
        } else if (item.order.actionCode === "Renew" && item.order.type !== "Mobile") {
          //renewal items that were changed previously in CA require copysheets
          if (item.order.productType === "Print"
            && item.model["ManageCopysheet"]
            && item.model["ManageCopysheet"].copySheetIndicator === "Y"
            && item.order.requireContent === true) {
            item["requiresCopysheet"] = true;
            //  item.required++;
          } else if (item.order.productType === "Digital"
            && item.model["ManageCopysheet"]
            && item.model["ManageCopysheet"] === "Y"
            && item.order.requireContent === true) {
            item["requiresCopysheet"] = true;
            //  item.required++;
          } else {
            //renewal items that meet none of the other conditions do not require copysheets
            item["requiresCopysheet"] = false;
            item.order.productType === "Print" ? item.model["ManageCopysheet"].copySheetIndicator = "N" : item.model["ManageCopysheet"] = "N";
          }
          if (item.order.actionCode === "Renew" && item.model.hasOwnProperty("dirtyFlag") && item.model['dirtyFlag']) {
            if (item.hasOwnProperty("hasCopysheet") || item.hasOwnProperty("requiresCopysheet")) {
              item["requiresCopysheet"] = true;
              item.order.productType === "Print" ? item.model["ManageCopysheet"].copySheetIndicator = "Y" : item.model["copySheetIndicator"] = "Y";
            }
          }
        }
        if (order.OrderItems.length) {
          for (let j = 0; j < order.OrderItems.length; j++) {
            if (order.OrderItems[j].svItemId === item.order.svItemId && item["requiresCopysheet"] !== true) {
              funcsCopysheet[j] = this.CopysheetIndicator.getIndicator(order.orderId, order.OrderItems[j].svItemId)
            }
          }
        }
        if (funcsCopysheet.length != 0) {
          Promise.all(funcsCopysheet).then((resultcopysheet) => {
            let datacopysheet = {};
            for (let key in resultcopysheet) {
              //datacopysheet[key] = (resultcopysheet[key].hasOwnProperty("data")) ? resultcopysheet[key].data : resultcopysheet[key];
              if (resultcopysheet[key] && resultcopysheet[key].copysheetRequiredInd) {
                order.OrderItems[key].copysheetRequiredInd = resultcopysheet[key].copysheetRequiredInd;
              } else {
                order.OrderItems[key].copysheetRequiredInd = null;
              }
            }
            for (let key2 in order.OrderItems) {
              if (item.order.actionCode === "Renew" && order.OrderItems[key2].svItemId === item.order.svItemId && order.OrderItems[key2].copysheetRequiredInd === "Y") {
                //renewal items whose UDACs were changed in conversion require copysheets
                item["requiresCopysheet"] = true;
                //  item.required++;
                item.order.productType === "Print" ? item.model["ManageCopysheet"].copySheetIndicator = "Y" : item.model["copySheetIndicator"] = "Y";
                if((item.order.productType === "Digital" && item.order.requireContent === false && item.order.productCode === "YPC" && ((item.order.UDAC === "PL") || (item.order.UDAC === "PB")))|| (item.order.UDAC === "ML")){
                  item["requiresCopysheet"] = false;
                  item.model["copySheetIndicator"] = "N";
                }
              } else if (item.order.actionCode === "Renew" && order.OrderItems[key2].svItemId === item.order.svItemId && order.OrderItems[key2].copysheetRequiredInd === "N") {
                item["requiresCopysheet"] = false;
                item.order.productType === "Print" ? item.model["ManageCopysheet"].copySheetIndicator = "N" : item.model["copySheetIndicator"] = "N";
              }
            }
          }, error => {
            //console.log("funcsCopysheet Error:", error);
          });
        }

      } else if (item.form[i].type === "profilepicklist" && (item.order.description === "Enhanced Listing Bundle" || item.order.description.indexOf("Start Smart Bundle") > -1)) {
        item.schema.properties["BusinessProfile"].readonly = true;
      }
      if (item.order.productCode === "YPC") {
        if (item.form[i].key === "BusinessProfile") {
          item.model["BusinessProfile"] = item.model["BusinessProfile"] || item.order.listingId;
        }
        if (!item.model["SignatureDate"]) {
          item.model["SignatureDate"] = item.model["PromotionDate"];
        }
        item.model["Category"] = JSON.parse(item.order.category)[0].value || JSON.parse(item.order.category)[0].Value;
      }
      if ((item.order.actionCode == 'Renew' && item.order.actionType == 'Update') && item.form[i].key === "BusinessProfile" && item.order.requireContent)//For Renew-Update the BP comes from salesforce. For New orders the BP should be taken from mongo
      {
        item.model["BusinessProfile"] = item.order.listingId;
      }

      if (item.form[i].type === "billing") {
        if (!item.model.hasOwnProperty('BillingAccount')) { //Added && condition to not populate billing account for rehandle new order
          let tempBillingAccount = billAccount ? billAccount.find(billing => billing.value == item.model['BillingAccount']) : '';
          if ((item.order.actionCode !== 'Add' || item.order.statusCode >= 6000) && (tempBillingAccount === undefined || tempBillingAccount === null || tempBillingAccount === '')) {
            item.model["BillingAccount"] = item.order.billingAccountId;
          } else {
            item.model["BillingAccount"] = "";
          }

        }

      }
      if (printContent && printContent[orderItem.svItemId] && printContent[orderItem.svItemId].hasWarningOrError != true) {
        if (item.form[i].key == "BillingBasis") {
          if ((item.order.actionType == "Update" || item.order.actionCode == "Renew") && printContent[orderItem.svItemId] && printContent[orderItem.svItemId].products && printContent[orderItem.svItemId].products.product && printContent[orderItem.svItemId].products.product.content) {
            let contentArray = printContent[orderItem.svItemId].products.product.content;
            contentArray.forEach(function (val, ind) {
              item.model["BillingBasis"] = val.billingBasis;
            });
          }
        }
      }
      // temp fix for billingBasis
      if (item.form[i].key == "BillingBasis") {
        if (!item.model["BillingBasis"]) {
          item.model["BillingBasis"] = "M";
        }
      }
    }
    if (item.form.length > 0 && item.form[item.form.length - 1].title !== "CONTINUE" && item.form[item.form.length - 1].title !== "BACK") {
      if (index = 0) {
        item.form.push(this.cancelButton);
      }

      if (index > 0) {
        item.form.push(this.backButton);
      }

      item.form.push(this.saveButton)
    }
    //commenting this as CampaignType Listing Ids are already added to profiles in getProfileData method
    /*if (item.model.hasOwnProperty("CampaignType") && item.model["CampaignType"] instanceof Array) {
      for (let i = 0; i < item.model["CampaignType"].length; i++) {
        if (item.model["CampaignType"][i].hasOwnProperty("ListingId") && item.model["CampaignType"][i].ListingId && item.model["CampaignType"][i].ListingId.trim() !== "") {
          this.addProfileById('',item.model["CampaignType"][i].ListingId);
        }
      }

    }*/
    if (item.order.productCode === "SEO") {
      // think how to populate contact fielda
      if (item.data && item.data.products && item.data.products.length > 0) {
        if (item.data.products[0] && item.data.products[0].hasOwnProperty("ProposalID")) {
          item.model["ProposalID"] = item.data.products[0].ProposalID;
        }

      }

      if (item.data.ProductHeader) {
        if (item.data.ProductHeader.listingId) {
          item.model["BusinessProfile"] = item.data.ProductHeader.listingId;
        } else if (item.data.ProductHeader.businessLocationId) {
          item.model["BusinessProfile"] = item.data.ProductHeader.businessLocationId;
        } else {
          item.model["BusinessProfile"] = ""
        }
      }
      if (item.data.products[0] && item.data.products[0].CategoryList) {
        if (item.data.products[0].CategoryList != null) {
          item.model["BusinessCategories"].CategoryList = [];
          item.data.products[0].CategoryList.forEach(function (value, index) {
            var myObj = {
              'CategoryId': value.CategoryId,
              'CategoryName': value.CategoryName
            }
            item.model["BusinessCategories"].CategoryList.push(myObj);
            item.model["BusinessCategories"].PrimaryCatId = item.data.products[0].PrimaryCatId;
          });
        }

      } else {
        item.model["BusinessCategories"] = {};
        item.model["BusinessCategories"]['CategoryList'] = [];
        item.model["BusinessCategories"]['PrimaryCatId'] = {};

      }
      if (item.data.WebsiteUrlList) {
        item.model["WebsiteUrl"]["isDexmediaSite"] = item.data.WebsiteUrlList[0].isDexmediaSite;
        item.model["WebsiteUrl"]["isNew"] = item.data.WebsiteUrlList[0].isNew;
        item.model["WebsiteUrl"]["isPrimary"] = item.data.WebsiteUrlList[0].isPrimary;
        item.model["WebsiteUrl"]["url"] = item.data.WebsiteUrlList[0].url;
        item.model["WebsiteUrl"]["username"] = item.data.WebsiteUrlList[0].username;
        item.model["WebsiteUrl"]["password"] = item.data.WebsiteUrlList[0].password;
      }
    }
    //get call tracking numbers from storage which were added because of SEM account change and not in CTS
    if (orderItem.actionCode === "Add" && orderItem.actionType === "New" && (orderItem.productCode === "SEM" || orderItem.productCode === "SEMP" || orderItem.productCode === "SEM Kicker")) {
      await this.Storage.getByAccountIdOrderId("call", storageId, this.indicator.account["id"], this.indicator.order["orderId"])
        .then(response => {
          //console.log('call storage result'+JSON.stringify(response))
          if (response != undefined && response[storageId] != undefined) {
            if (response[storageId].callTracking) {
              //console.log('inside call result')
              item.calltracking.push.apply(item.calltracking, response[storageId].callTracking)
              //remove duplicates
              //let uniqueLines = item.calltracking.filter((x, i, a) => a.indexOf(x.callTrackingId) === index)
              let uniqueLines = []
              item.calltracking.map(x => uniqueLines.filter(a => a.callTrackingId == x.callTrackingId).length > 0 ? null : uniqueLines.push(x));
              //console.log('uniquelines '+JSON.stringify(uniqueLines))
              item.calltracking = uniqueLines
            }
          }
        }, error => {
          //console.log("get item Error:", error);
        });
    }
  // Terminate Google CTS lines for SEM plus if we are getting from MongoDB or from Vision
  // Terminate Bing CTS lines for SEM plus if we are getting from MongoDB or from Vision
console.log(item.calltracking);
console.log(item.model['CallTracking']);
    if(item.order.productCode == "SEM"){
      let semCT = [];
      let semCTModel = [];
      if (item.calltracking && item.calltracking.length > 0) {
        let CTlength = item.calltracking.length;
        for(let i = 0; i< CTlength; i++){
          if(item.calltracking[i].distributionType == "GOOGLE" || item.calltracking[i].distributionType == "BING"){
            let terminateCTData = {
              "callTrackingId": item.calltracking[i].callTrackingId,
              "terminationNumber": item.calltracking[i].terminationNumber,
              "callRecordEnabled": item.calltracking[i].callRecordEnabled,
              "adAlertEnabled": item.calltracking[i].adAlertEnabled,
              "callVerifyEnabled": item.calltracking[i].callVerifyEnabled
            };
            console.log('terminateCTData', terminateCTData)
            // let res = await this.calltracking.terminate(terminateCTData)
            await this.Call.terminate(terminateCTData).then((res) => {
            }, error => {
              console.log()
              // this.spinnerService.hide();
            });
          } else {
            semCT.push(item.calltracking[i]);
          }
        }
        item.calltracking = semCT;
        let storageID = item.order.svItemId + "v" + item.order.version
        this.Storage.updateField('call', storageID, { "data.callTracking": item.calltracking })
          .subscribe(() => { }, error => {
            //console.log("error is:",error);
          });
      }

      if (item.model['CallTracking'] && item.model['CallTracking'].length > 0) {
        let CTModellength = item.model['CallTracking'].length;
        for(let i = 0; i< CTModellength; i++){
          if(item.model['CallTracking'][i].Source != "GOOGLE" || item.model['CallTracking'][i].Source != "BING"){
            semCTModel.push(item.model['CallTracking'][i]);
          }
        }
        item.model['CallTracking'] = semCTModel;
        item.model['CallTracking']['CallTrackingCount'] = semCTModel.length;
      }
      console.log(item.calltracking);
      console.log(item.model['CallTracking']);
    }
  //If TargetZip not there then add 
  if (item.model['CampaignType'] && Array.isArray(item.model['CampaignType']) && item.model['CampaignType'].length > 0) {
    let CampTypeLength = item.model['CampaignType'].length;
    for (let i = 0; i < CampTypeLength; i++) {
      // let zipCode = (item.model['CampaignType'][i]['TargetZIPcode']).trim();
      // if (!zipCode) {
      //   item.model['CampaignType'][i]['TargetZIPcode'] = item.model['CampaignType'][i].Zip ? item.model['CampaignType'][i].Zip : item.model['CampaignType'][i].CampaignAddressZip;
      // }
      if (item.model['CampaignType'][i] && item.model['CampaignType'][i]['TargetZIPcode']) {
        let zipCode = (item.model['CampaignType'][i]['TargetZIPcode']).trim();
        if (!zipCode) {
          item.model['CampaignType'][i]['TargetZIPcode'] = item.model['CampaignType'][i].Zip ? item.model['CampaignType'][i].Zip : item.model['CampaignType'][i].CampaignAddressZip;
        }
      }
    }
  }


    //Temporary code for hardcoding targetZip till visiion service available

    // if(item.model['CampaignType'] && item.model['CampaignType'].length > 0){
    //   let CampTypeLength = item.model['CampaignType'].length;
    //   for(let i = 0; i< CampTypeLength; i++){
    //     item.model['CampaignType'][i]['targetZip'] = '51222';
    //   }
    // }


    // Check to see if the item is a renew item
    /* istanbul ignore next */
    //item.counter = requiredFieldCount.get(item.schema, item.form, item.model);
    //temp fix need to validate it for all items.
    /*  if (item.order && item.order.actionCode === "Renew") {
       //item.counter = requiredFieldCount.get(item.schema, item.form, item.model, item.order);
       //FUL-15039
       //item.counter = this.RequiredFieldCount.get(item.schema, item.form, item.model);
       let itemData= this.RequiredFieldCount.getNewItemCount(item.requiredFields, item.model, item.order, '', 0, true);
       item.counter= itemData.itemCount;
     } else {
       //item.counter = this.RequiredFieldCount.get(item.schema, item.form, item.model);
       let itemData= this.RequiredFieldCount.getNewItemCount(item.requiredFields, item.model, item.order, '', 0, true);
       item.counter= itemData.itemCount;
     } */

    //listing ids come from all sorts of places
    /* istanbul ignore next */
    if (!item.model["BusinessProfile"] && (item.model["listingId"] || item.model["ListingId"])) {
      //digital items check salesforce first
      item.model["BusinessProfile"] = item.order.listingId;
    } else if (item.model["BusinessProfile"] != null && item.model["BusinessProfile"].listingId) {
      //digital items check storage second
      item.model["BusinessProfile"] = item.model["BusinessProfile"].listingId;
    } else if (item.model.hasOwnProperty('ListingId') && item.model["ListingId"] == null) {
      //if print items don't have a listing already, check salesforce
      if (item.order.listingId) {
        item.model["ListingId"] = item.order.listingId;
      }
    } else if (item.model["BusinessProfile"] == "" && item.order.listingId != null) {
      //this looks redundant
      item.model["BusinessProfile"] = item.order.listingId;
    } else if (item && item.order && item.order.listingId) {
      item.model["BusinessProfile"] = item.model["BusinessProfile"] || item.order.listingId;
    }
    //if Bussinessprofile or listingId is not avlainlabe in listingArray
    if (item.order.productType == 'Digital') {
      if (item.model.hasOwnProperty("BusinessProfile") && item.model['BusinessProfile']) {
        let tempBussinessProfile = item['listingArray'] ? item['listingArray'].find(listing => listing.value == item.model['BusinessProfile']) : '';
        item.model['BusinessProfile'] = tempBussinessProfile ? tempBussinessProfile.value : '';
      }
    } else if (item.order.productType == 'Print') {
      if (item.model.hasOwnProperty("ListingId") && item.model['ListingId']) {
        let tempListingId = item['listingArray'] ? item['listingArray'].find(listing => listing.value == item.model['ListingId']) : '';
        item.model['ListingId'] = tempListingId ? tempListingId.value : '';
      }
    }
    /* istanbul ignore next */
    if (item["requiresCopysheet"] == null) {
      item["requiresCopysheet"] = false;
    } else {
      var found = false;
      if (copy instanceof Array) {
        for (var i = 0; i < copy.length; i++) {
          if (copy[i].svItemId === orderItem.svItemId + "v" + orderItem.version) {
            item["copy"] = copy[i];
            found = true;
            break;
          }
        }
      } else if (copy.svItemId === orderItem.svItemId + "v" + orderItem.version) {
        item["copy"] = copy;
        found = true;
      }
      /* istanbul ignore next */
      if (!found) {
        item["copy"] = {};
      }
      if (storageCopyData[storageId]) {
        if (storageCopyData[storageId].hasCopysheet) {
          item["hasCopysheet"] = storageCopyData[storageId].hasCopysheet;
          item["KGENCopysheet"] = storageCopyData[storageId].KGENCopysheet || "";
          item["thumbnail"] = storageCopyData[storageId].thumbnail;
          item["baseCopysheet"] = storageCopyData[storageId].baseCopysheet;
          item["isCoysheetSubmitted"] = storageCopyData[storageId].isCoysheetSubmitted;
          item["isCopysheetSaved"] = storageCopyData[storageId].isCopysheetSaved;
          item["actionListObj"] = storageCopyData[storageId].actionListObj;
        } else {
          if (item.order.actionCode === "Renew" && item.model.hasOwnProperty("dirtyFlag") && item.model['dirtyFlag']) {
            if (item.hasOwnProperty("hasCopysheet") || item.hasOwnProperty("requiresCopysheet")) {
              item["hasCopysheet"] = item.model['renewHasCopysheet'] || false;
              item["requiresCopysheet"] = true;
            }

          } else if (item.order.actionCode === "Renew") {
            item["hasCopysheet"] = (item["copy"].copysheet != null && item["copy"].copysheet != "") || false;
          } else {
            //if there is no copysheet in storage then make hasCopysheet false for Add New
            if (!item["copy"].copysheet) {
              item["hasCopysheet"] = false;
            } else {
              item["hasCopysheet"] = true;
            }
            /*if(storageCopyData[storageId]!=null && storageCopyData[storageId].hasOwnProperty("hasCopysheet")){
              item["hasCopysheet"] = storageCopyData[storageId].hasCopysheet
            }else{
              if(!item["copy"].copysheet){
                item["hasCopysheet"] = false;
              }else{
                item["hasCopysheet"] = true;
              }              
            }*/


          }
        }

        if (storageCopyData[storageId].generatedCopysheet) {
          item["generatedCopysheet"] = storageCopyData[storageId].generatedCopysheet;
        } else {
          if (item["copy"].copysheet && item.data.iaGroupId) {
            item["generatedCopysheet"] = true;
          } else {
            item["generatedCopysheet"] = false;
          }
        }
      }
      if (item.order && item.order.actionCode === "Renew" && item.order.actionType === "New") {
        if (item["generatedCopysheet"] == false) {
          item["copy"].copysheet = null
        }
      }
      //console.log("Testing  :", storageId, JSON.stringify(storageCopyData[storageId]));
      if (!item["hasCopysheet"] && item["requiresCopysheet"]) {
        item.counter++;
      }
      if (storageCopyData[storageId] && storageCopyData[storageId].hasOwnProperty("isCorrupted") && storageCopyData[storageId].isCorrupted == true) {
        item.counter++;
      }
    }
    //check for CampaignType field and add isComplete for renewal
    if (item.model["CampaignType"] && item.model["CampaignType"].length > 0 && item.order && item.order.actionCode === "Renew") {
      for (let i = 0; i < item.model["CampaignType"].length; i++) {
        if (item.model["CampaignType"][i].CampaignType.toUpperCase() === 'NATIONAL') {
          var maxCampaignNational = 8;
          var ignoreKeys = ['index', 'Address1', 'Address2', 'TargetZIPcode', 'BPText', 'CampaignId', 'CampaignType', 'CampaignTypeText', 'CategoryListText', 'City', 'DisplayAddress', 'DisplayEmail', 'Email', 'GeoListText', 'ListingId', 'State', 'Zip', 'campaignFlag', 'imagecopy', 'imageopen', 'isComplete', 'modelClicktypes', 'nationalCampaign', 'CCCampaignCatKeys'];
          for (var nationalkey in item.model["CampaignType"][i]) {
            var bool = (ignoreKeys.indexOf(nationalkey) > -1);
            if (bool === false && nationalkey != 'UseBusinessProfile' && (item.model["CampaignType"][i][nationalkey] !== undefined && item.model["CampaignType"][i][nationalkey] && item.model["CampaignType"][i][nationalkey].length > 0)) {
              maxCampaignNational--;
            }
          }
          if (maxCampaignNational === 0) {
            item.model["CampaignType"][i]['isComplete'] = true;
          } else {
            item.model["CampaignType"][i]['isComplete'] = false;
          }
        }
        if (item.model["CampaignType"][i].CampaignType.toUpperCase() === 'LOCAL') {
          var maxCampaignLocal = 13;
          var ignoreKeys = ['index', 'ListingId', 'Address2', 'TargetZIPcode', 'BPText', 'CCCampaignCatKeys', 'CampaignId', 'CampaignType', 'CampaignTypeText', 'CategoryListText', 'DisplayEmail', 'disablelist', 'Email', 'GeoListText', 'LocalWebsite', 'campaignFlag', 'imagecopy', 'imageopen', 'isComplete', 'modelClicktypes', 'UseBusinessProfile'];
          for (var localkey in item.model["CampaignType"][i]) {
            if (localkey === 'disablelist' && item.model["CampaignType"][i][localkey] === false) {
              maxCampaignLocal--;
            }
            if (!item.model["CampaignType"][i].hasOwnProperty('DestinationURL') && !item.model["CampaignType"][i].hasOwnProperty('DisplayURL') && localkey === 'UseBusinessProfile' && (item.model["CampaignType"][i][localkey] === 'Y' || item.model["CampaignType"][i][localkey] === true)) {
              maxCampaignLocal--;
            }
            var bool = (ignoreKeys.indexOf(localkey) > -1);
            if (bool === false && (item.model["CampaignType"][i][localkey] !== undefined && item.model["CampaignType"][i][localkey] && item.model["CampaignType"][i][localkey].length > 0)) {
              maxCampaignLocal--;
            }
          }
          if (maxCampaignLocal === 0) {
            item.model["CampaignType"][i]['isComplete'] = true;
          } else {
            item.model["CampaignType"][i]['isComplete'] = false;
          }
        }
      }
    }

    let billingDataArr = item.order.productType.toLowerCase() == "digital" ? this.indicator.digitalBilling : this.indicator.printBilling;
    let isValidBillingId = item.model['BillingAccount'] ? this.isBillingAvailable(item.model['BillingAccount'], billingDataArr) : false;
    if (!isValidBillingId) {
      item.model['BillingAccount'] = '';
    }
    if (item.order && item.order.actionCode === "Renew" && !item.model.hasOwnProperty("dirtyFlag") && !item.model['dirtyFlag']) {
      item.model['digitalApiFlag'] = false;
      let isLicenseRequired = false;
      let requiredFields = item.requiredFields;
      if (requiredFields.indexOf('LicenseNumber') > -1) {
        isLicenseRequired = true;
      }
      if (isLicenseRequired && item.model.hasOwnProperty("LicenseNumber")) {
        item.model['LicenseNumber'] = item.model['LicenseNumber'] || '0';
      } 
      if (item.model.hasOwnProperty("CallTracking") && item.model["CallTracking"] && Object.keys(item.model["CallTracking"]).length == 0) {
        item.model['CallTracking']['CallTrackingCount'] = 0;
      } else if (item.model.hasOwnProperty("CallTracking") && typeof item.model["CallTracking"] === 'object' && !item.model["CallTracking"]) {
        item.model['CallTracking'] = { CallTrackingCount: 0 };
      } else if (item.model.hasOwnProperty("CallTracking") && typeof item.model["CallTracking"] === 'string' && !item.model["CallTracking"]) {
        item.model['CallTracking'] = '0';
      }
      let itemData = this.RequiredFieldCount.getNewItemCount(item.requiredFields, item.model, item.order, '', 0, false, item);
      item.counter = itemData.itemCount;
    } else {
      if (item.model.hasOwnProperty("CallTracking")) {
        item.model["modelValue"] = item.model.hasOwnProperty("modelValue") ? item.model["modelValue"] : "Yes";
      }
      if(this.indicator.account && this.indicator.account['address'] && this.indicator.account['address']['country'] == 'NZ'){
        item.model["modelValue"] = "No"
      }
      let itemData = this.RequiredFieldCount.getNewItemCount(item.requiredFields, item.model, item.order, '', 0, false, item);
      item.counter = itemData.itemCount;
    }
    // Check to see if the item is a renew item and requireContent is false.
    if (item.order.actionCode === "Renew" && item.order.requireContent === false) {
      //check to see if it's been edited before
      if (item.model && item.model["dirtyFlag"] && item.model["dirtyFlag"] !== true) {
        item.counter = 0;
      }
    }
    this.items[orderItem.svItemId] = item;

  };
  isBillingAvailable(billingId, billingDataArray) {
    if (billingDataArray) {
      for (let i = 0; i < billingDataArray.length; i++) {
        if (billingDataArray[i].value == billingId) {
          return true;
        }
      }
    }
    return false;
  }
  async addProfileById(indicator, id): Promise<any> {
    var forms = [];
    var schemas = [];
    let tempProfile: any;
    //let indicatorresult = indicator;
    try {
      let profile = await this.Profile.getByDigitalListing(id)
      tempProfile = profile;
      if (profile && profile[id] && profile[id].hasWarningOrError !== "true") {
        if (this.profileSchema != undefined && this.profileSchema != null) {
          for (var i = 0; i < this.profileSchema.length; i++) {
            forms.push(this.profileSchema[i].form);
            schemas.push(this.profileSchema[i].schema);
          }
          let result = await this.processProfile(profile, id, forms, schemas, null)
          if (result && indicator.profiles) {
            indicator.profiles[id] = result;
            if (this.Params.params.mode != "ManageBP") {
              indicator = await this.SEMPHours.setHOOConfirmationsInIndicator(indicator);
            }
            return indicator.profiles;
          }

        } else {
          for (let i = 0; i < indicator['profileSchema'].length; i++) {
            forms.push(indicator['profileSchema'][i].form);
            schemas.push(indicator['profileSchema'][i].schema);
          }
          let result = await this.processPromiseProfile(tempProfile, id, forms, schemas, null)
          indicator.profiles[id] = result;
          if (this.Params.params.mode != "ManageBP") {
            indicator = await this.SEMPHours.setHOOConfirmationsInIndicator(indicator);
          }
          return indicator.profiles;

        }
      }
    } catch (error) {
      this.Logger.logError("Error when retrieving digtal profiles");
    };
    return indicator.profiles;
  };

  /************************
 * Create bundles of items
 */
  createBundles(items) {

    if (items.length <= 0) return;
    let bundles = [];

    items.forEach(function (item, index, array) {
      var bundle = {};

      if (!item.parentId) {    //If item is a parent
        bundle["parent"] = item.svItemId;
        bundle["children"] = [];

        bundles.push(bundle);
      } else {    //If item is a child
        //check if its parent exist
        var parentList = bundles.map(function (bundle) {
          return bundle.parent;
        });

        var parentIndex = parentList.indexOf(item.parentId);

        if (parentIndex >= 0) { //If parent exists
          bundles[parentIndex]["children"].push(item.svItemId);
        } else {    //parent does not exist
          bundle["parent"] = item.parentId;
          bundle["children"] = [];
          bundle["children"].push(item.svItemId);

          bundles.push(bundle);
        }
      }
    });
    this.indicator.bundles = bundles;
  }

  populateItemsAfterProfiles() {
    if (this.indicator && this.indicator.order && this.indicator.order["OrderItems"]) {
      for (let i = 0; i < this.indicator.order["OrderItems"].length; i++) {
        if (this.indicator.order["OrderItems"][i].productType === "Digital" && this.indicator.order["OrderItems"][i].actionCode !== "Cancel" && this.indicator.order["OrderItems"][i].actionType !== "Cancel") {
          if (this.indicator.order["OrderItems"][i].productCode === "SEO") {
            if (this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile) {
              let categories = []
              let primaryCategory = {}
              categories = this.indicator.profiles[this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessProfile].model.BusinessCategories;
              this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessCategories = {}
              this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessCategories["CategoryList"] = []
              this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessCategories["PrimaryCatId"] = {}
              if (categories != undefined && categories != null) {
                this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessCategories["CategoryList"] = categories
                if (categories.length > 0) {
                  for (let i = 0; i < categories.length; i++) {
                    if (categories[i].primary == 'Yes')
                      primaryCategory['categoryName'] = categories[i].categoryName;
                    primaryCategory['categoryId'] = categories[i].categoryId;
                  }
                  this.indicator.items[this.indicator.order["OrderItems"][i].svItemId].model.BusinessCategories["PrimaryCatId"] = primaryCategory
                }
              }
            }
          }
        }
      }
    }
  }

  /**
 * [Populate items pertaining to profile for child items from parent item]
 */
  /* istanbul ignore next */
  populateItemsFromProfiles() {
    // bundle.children.forEach((child, childIndex, children) => {
    this.indicator.bundles.forEach((bundle, index, bundles) => {
      //Check if parent exists, item for parent exists and parent has a businessprofile
      if (bundle.parent && this.items[bundle.parent]) {
        if (this.items[bundle.parent].model && this.items[bundle.parent].model.BusinessProfile) {
          bundle.children.forEach((child, childIndex, children) => {
            //Set each child item's profile as that of parent
            if (this.items[child]) {//checking this condition because bundle was having cancel item.Cancel item can not be in list of vm.items so it will throw error
              this.items[child].model.BusinessProfile = this.items[bundle.parent].model.BusinessProfile;
              // Need to check here to see if there is any existing data
              if (!this.items[child].model.hasOwnProperty("BusinessAddress1")) {
                //watchHelper.setItemValuesFromBP(child, this.items[bundle.parent].model.BusinessProfile, vm);
              }
              if (this.items[child].schema.properties.BusinessProfile) {
                this.items[child].schema.properties.BusinessProfile.readonly = true;  //Make the child's BP field as readonly
              }
            }
          })
        }
        if (this.items[bundle.parent].model && this.items[bundle.parent].model.ListingId) {
          bundle.children.forEach((child, childIndex, children) => {
            //Set each child item's profile as that of parent
            if (this.items[child]) {//checking this condition because bundle was having cancel item.Cancel item can not be in list of vm.items so it will throw error
              this.items[child].model.ListingId = this.items[bundle.parent].model.ListingId;
              // Need to check here to see if there is any existing data
              if (!this.items[child].model.hasOwnProperty("BusinessAddress1")) {
                //watchHelper.setItemValuesFromBP(child, this.items[bundle.parent].model.ListingId, vm);
              }
              if (this.items[child].schema.properties.ListingId) {
                this.items[child].schema.properties.ListingId.readonly = true;  //Make the child's Listing field as readonly
              }
            }


          })
        }
      }
    })

  }

  removeProfileById(indicator, id) {
    return new Promise((resolve, reject) => {
      var hasOtherPlaces = false;
      for (var key in indicator.items) {
        if (indicator.items[key].model["BusinessProfile"] == id || indicator.items[key].model["ListingId"] == id) {
          hasOtherPlaces = true;
          break;
        }
        if (indicator.items[key].model.hasOwnProperty("CampaignType")) {
          for (let j = 0; j < indicator.items[key].model['CampaignType'].length; j++) {
            if (indicator.items[key].model['CampaignType'][j].hasOwnProperty("ListingId") && indicator.items[key].model['CampaignType'][j].ListingId && indicator.items[key].model['CampaignType'][j].ListingId != undefined && indicator.items[key].model['CampaignType'][j].ListingId != null && indicator.items[key].model['CampaignType'][j].ListingId.trim() !== "" && indicator.items[key].model['CampaignType'][j].ListingId.trim() == id) {
              hasOtherPlaces = true;
              break;
            }
          }
        }
      }
      if (!hasOtherPlaces) {
        delete indicator.profiles[id];
        let existingListingIds = indicator.listingIds
        if (existingListingIds) {
          let newArray = existingListingIds.filter(function (el) {
            return el != id;
          });
          indicator.listingIds = newArray
        } else {
          existingListingIds = []
          indicator.listingIds = existingListingIds
        }
      }
    });
  };

  removeProfileByIdFromCampaign(indicator, id, itemKey) {
    return new Promise((resolve, reject) => {
      var hasOtherPlaces = false;
      for (var key in indicator.items) {
        if (indicator.items[key].model["BusinessProfile"] == id || indicator.items[key].model["ListingId"] == id) {
          hasOtherPlaces = true;
          break;
        }
        if (key != itemKey) {
          if (indicator.items[key].model.hasOwnProperty("CampaignType")) {
            for (let j = 0; j < indicator.items[key].model['CampaignType'].length; j++) {
              if (indicator.items[key].model['CampaignType'][j].hasOwnProperty("ListingId") && indicator.items[key].model['CampaignType'][j].ListingId && indicator.items[key].model['CampaignType'][j].ListingId != undefined && indicator.items[key].model['CampaignType'][j].ListingId != null && indicator.items[key].model['CampaignType'][j].ListingId.trim() !== "" && indicator.items[key].model['CampaignType'][j].ListingId.trim() == id) {
                hasOtherPlaces = true;
                break;
              }
            }
          }
        }
      }
      //console.log('hasOtherPlaces'+hasOtherPlaces)
      if (!hasOtherPlaces) {
        delete indicator.profiles[id];
        let existingListingIds = indicator.listingIds
        if (existingListingIds) {
          let newArray = existingListingIds.filter(function (el) {
            return el != id;
          });
          indicator.listingIds = newArray
        } else {
          existingListingIds = []
          indicator.listingIds = existingListingIds
        }
        //console.log('listingids in remove'+JSON.stringify(indicator.listingIds))
      }
    });
  };
  async convertBlobToBase64(blob) {

    return new Promise((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  async fetchSetBasedAd(Id) {
    let ad;
    this.kgenCustomerId = Id;
    let kgencId = "12345789";
    let adjson = {
      "AdDetails":      
      { "product": "AAY2 04/2023", "productCode": "AAY2", "productIssueNum": "46", "headingName": "NEW TEST123", "listedName": "SOUTH; HILLS MOVERS", "listedTn": "(800)865-6304", "udac": "DQCS", "itemId": "13", "displayAdId": "9783034041250", "displayAdIdPrefix": "S", "adId": "S 9783034041250", "csGroupId": "14738835", "brandCode": "5", "graphicRegion": "SN", "ad": "F-N-S-9783034041250- - -AAY2-46", "EsbHash": "Hash received from ESB service", "fileConversionStatus": "Success", "fileConversionError": "", "FilePath": "File path in the server where set based image will be stored" }
    }
    // this.setBaseCollectionService.get(this.kgenCustomerId).then(res =>{
    //   console.log("setbase get call",res);
    // })
    // this.setBaseCollectionService.upsertsetBaseImage("777777777","BBGKN88744","00002NJFHZ","Tabassum",adjson).then(res =>{
    //   console.log("setbase post call",res);
    // })
    //Service call to get setBasedAd items from Kgen
    this.adService.getItems(this.kgenCustomerId, "Ad-GET-ITEMPAGE")
      .subscribe(async ads => {
       if(ads && ads['details'] && ads['details'].length>0){
        let tempAds = [];
        tempAds.push(ads['details'][0]);
        ads['details'].forEach((adDetail)=>{
          if(!tempAds.find(x=> x.displayAdId === adDetail['displayAdId'] )){
            tempAds.push(adDetail);
          }else{
            let filteredAd = tempAds.find(x=> x.displayAdId === adDetail['displayAdId'] );
            if(filteredAd.itemId < adDetail['itemId']){
              tempAds = tempAds.filter(x=> x.displayAdId !== adDetail['displayAdId'] );
              tempAds.push(adDetail);
            }
          }
        });
        ads['details'] = tempAds;  
      }
      
        //console.log('Inside setbased KGEN service call back ')
       // this.ads = ads['details'];
       if (ads && ads.hasOwnProperty('details')) {
        this.ads = ads['details'];
        // Now you can proceed to use this.ads
    } else {
        // Handle the case where ads or its 'details' property is undefined
        console.error('Ads or details are undefined.');
    }
        //New setbase service call to get images from kgen and update in mongo
        this.setBaseCollectionService.upsertsetBaseAdfromKgen(this.kgenCustomerId,null,this.orderId,this.userName,this.ads).then(res=>{
          console.log("Response:",res)
        })
        // for (let setbase in this.ads) {
        //   ad = this.ads[setbase];
        //   //Forming the ESB endpoint URL for each setbasedAd item
        //   // this.ads[setbase]['setbasedUrl'] = this.adService.getAd(ad.displayAdId, ad.displayAdIdPrefix, ad.graphicRegion);
        //  await this.adService.getAd(ad.displayAdId, ad.displayAdIdPrefix, ad.graphicRegion).then(async res => {
        //     // console.log(res['body']);
        //     // console.log((res['body']).slice());
        //     // console.log((res['body']).arrayBuffer());            
        //     const base64String_var = await this.convertBlobToBase64(res['body']);
        //     // console.log(base64String_var);
        //     this.ads[setbase]['setbasedData'] = base64String_var;            
        //   })

        // }
        console.log("SetbasedAd data from kgen adservice call:", this.ads); 
        // Forming the promise array looping through each setBasedAd item       
        // for (let i = 0; i<this.ads.length; i++) { 
        //   // console.log('Inside for loop', this.ads[i]);
        //   var imagePromise = this.addImagetoMongo(this.ads[i])
        //   this.promiseArr.push(imagePromise)
        // }
        // //Trigerring the promises in parallel
        // await this.setBasePromise().then((res) => {
        // });
        // console.log("SetbaseAd Image array before saving to mongo:", this.payLoadArray)
        // //Service call to save the setBasedAd images to mongoDB
        // this.setbaseService.setBaseImagesave(this.accountId,this.orderId,this.payLoadArray);
      }, err => {
        console.log("Error in calling the ad service", err);
      });
  }
  addImagetoMongo(ad) {
    let baseUri: string = '/api/file';
    let Images = {};
    let arr = [];
    let res;
    let setbaseImage;
    let saveResp;
    let apiUrl = this.API.get(baseUri);
    // let url = ad.setbasedUrl;
    let base64DataOfepsFile;
    //console.log('From addImagetoMongo :: ', base64DataOfepsFile);
    //Creating promise
    return new Promise(async (resolve) => { 
      await this.adService.getAd(ad.displayAdId, ad.displayAdIdPrefix, ad.graphicRegion).then(async res => {
        const base64String_var = await this.convertBlobToBase64(res['body']);
        // console.log(base64String_var);
        base64DataOfepsFile = base64String_var;            
      })           
      // fetch(url)        
      //   .then(async response => {        
          // const blob = await response.blob();          
          // const reader = new FileReader();
          // reader.readAsDataURL(blob);
          // reader.onload = async event => {
          //   res = event.target;
            //Service call to convert pdf to imagegetAd
            await this.fileservice.pdftoimage({ 'data': base64DataOfepsFile }).then(async (res) => {
              setbaseImage = res;
              //Service call to save image to mongoDB
              await this.fileservice.saveImageToPromise("", { 'data': setbaseImage }, "attachment")
                .then((response) => {
                  Images = {};
                  saveResp = response;
                  let url = apiUrl + '/' + saveResp._id + '/' + saveResp.filename;
                  Images['img'] = url;
                  Images['title'] = ad.adId;
                  Images['details'] = ad;
                  // ad.product + ',' + ad.headingName + ',' + ad.udac + ',' +
                  //   ad.listedTn + ',' + ad.listedName + ',' + ad.displayAdId;
                  arr.push(Images);
                  //Forming the payload array with setbased image details
                  this.payLoadArray.push(Images);
                  resolve(true);
                }).catch((err) => {
                  console.log("saving image to mongo failed ", err)
                  resolve(false);
                });
            }).catch((err => {
              console.log("pdf to image call failed", err)
              resolve(false)
            }))
          // }
        // })
        // .catch(function (err) {
        //   console.log("Something went wrong in fetch call!", err);
        //   resolve(false);
        // });
    })
  }
  setBasePromise() {
    return new Promise(resolve => {
      Promise.all(this.promiseArr).then((res) => {
        resolve(true);
      }).catch(err => {
        console.log('Catch block of promise all ', err);
        resolve(false);
      })
    })
  }

}
