import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';
import { UtilsService } from '../utils/utils.service';
@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  baseUri1:string = '/api/sem';
  baseUri2:string = '/api/campaign';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService,
    private utils: UtilsService
  ) { }
        
  // vm.accountURL = '/api/sem';
  // vm.dataURL = '/api/campaign';

  getCampaign(id: any): Observable<any> {
    return this.http.get(this.API.get(this.baseUri1) + "/" + id)
      //return $http.get(API.get(vm.accountURL) + "/" + id, { cache : false });
  };

  getData(id :any): Observable<any> {   
    return this.http.get(this.API.get(this.baseUri2) + "/data/" + id)
      //return $http.get(API.get(vm.dataURL) + "/data/" + id, { cache : false });
  };

  getCancelled(eAccountId: any, productCode: any): Observable<any> {
    return this.http.get(this.API.get(this.baseUri2) + "/accounts/" + eAccountId+ "/" + productCode)
      //return $http.get(API.get(vm.dataURL) + "/accounts/" + eAccountId+ "/" + productCode, { cache : false });
  };
}
