import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/Rx';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  baseUri : string = '/api/product/'
  constructor(private Params : ParamsService,
    private http : HttpClient,
    private API :ApiService
    ) { }

  get(itemId : string) : Promise<any>{
    this.baseUri = this.baseUri + 'content/digital/' +itemId;
    return this.http.get(this.API.get(this.baseUri)).toPromise();
  }
  getContent(source : string, itemId : string) : Promise<any> {
    return this.http.get(this.API.get(this.baseUri) + "content/" + source + "/" + itemId).toPromise(); //details will not changein a session. caching true
};
  getDigitalContent (itemId : string): Promise<any>  {
    return this.getContent("digital", itemId);
};
  updateSync(source: any, data: any, page: string):Promise<any> {
  data.sforg = this.Params.params.sforg;
  data.page = page;
  if(page == 'publish') {
    return this.http.put(this.API.get(this.baseUri) + source,data).toPromise().catch( error => {
      return error;
    });
  } else {
    return this.http.put(this.API.get(this.baseUri) + source,data).toPromise(); //details will not changein a session. caching true
    //http.put(API.get(vm.PATH) + "/" + source, data, callback);
  }
};
  update(source: any, data: any):Observable<any> {
    data.sforg = this.Params.params.sforg;
    return this.http.put(this.API.get(this.baseUri) + source,data);
    //return $http.put(API.get(PATH) + "/" + source, data);
  };

getLines(itemId: any) {
  return this.http.get(this.API.get(this.baseUri) + "/line/" + itemId);
  //return $http.get(API.get(PATH) + "/line/" + itemId);
};

getPrintContent(itemId: any) {
  return this.getContent("print", itemId);
};

getLineItems(itemId: any) {
  return this.http.get(this.API.get(this.baseUri) + "/line/" + itemId);
  //return $http.get(API.get(PATH) + "/line/" + itemId);
};

updateItem(svOrderId: any, svItemId: any) {
  return this.http.get(this.API.get(this.baseUri) + "/" + svOrderId + "/" + svItemId);
  //return $http.get(API.get(PATH) + "/" + svOrderId + "/" + svItemId);
};
}
