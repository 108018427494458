import  * as $ from 'jquery';
import { Router } from '@angular/router';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
var that;
export class PublishModel implements OnInit{
    indicatorValue :Quickbp;
    subscribe: any;
    orderStatusCancel = ['cancel','completed - cancelled', 'cancelled'];
    constructor(private router: Router,
              private params: ParamsService, 
              private indicatorQuery: IndicatorQuery, 
              private indicatorStore: IndicatorStore,
              private spinnerService :NgxSpinnerService) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  that = this;
                });
    }
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }
    columns: any ={
        "publishGridPrint":[
            {title:"TogglePrint", field: "ToggleIcon",
                titleFormatter:function(cell, formatterParams, onRendered){ //plain text value
                    // return "<div id='publishPrintToggleWrap' class='showorhide-publish-minus'><i class='fa fa-plus-square' id ='publishTogglePlusDigital'></i><i class='fa fa-minus-square' id ='publishToggleMinusDigital'></i></div>";
                }, 
                cssClass: 'publish-tree-branch', 
                width:'30',
                resizable:false,
                frozen:true,
                headerFilter:false,
                headerSort: false
            },
            {title:"PRINT PRODUCTS", field:"name",responsive:0, cellClick: (e, cell) => {
                if(!cell.getRow().getData().hasOwnProperty("_children") && !this.orderStatusCancel.includes(cell.getRow().getData().itemStatus.toLowerCase())){
                    this.spinnerService.show();
                    setTimeout(() => {// timeout is added for spinner issue
                        this.router.navigate([`/orderitems/item/${cell.getRow().getData().itemId}`]);
                    }, 1000);
                }               
              },
              width:'13%'
            },
            {title:"DIRECTORY", field: "directory", width:'13%'},
            {title:"DIRECTORY CODE", field: "directoryCode", width:'12%'},
            {title:"PRINT ITEM ID", field:"itemId", width:'11%'},
            {title:"ITEM STATUS", field:"itemStatus", responsive:2,
                formatter:function(cell){
                    let value = cell.getValue();

                    if(value === 'Accepted / Cancelled'){
                        return "<div >Item Status</div>"
                    }
                    let progress = cell.getData().progress;
                    if(progress < 100){
                        return "<div style='min-height: 11px;min-width: 11px;margin-right:5px;border-radius: 11px;max-width: 11px;display: inline-block;background-color: #af272f;'></div>"+value
                    } else {
                            return "<div style='min-height: 11px;min-width: 11px;margin-right:5px;border-radius: 11px;max-width: 11px;display: inline-block;background-color: #97d700;'></div>"+value
                          }
                }, width:'10%'
            },
            {title:"ACTION-ACTION TYPE", field:"actionType", width:'14%'},
            {title:"VERSION-ACTIVE", field:"versionActive", width:'12%'},
            {title:" ", field:"progress",
            titleFormatter: function(cell){
                let printProgress = that.indicatorQuery.getValue().indicator.printProgress;
                return "<div style='width:74%;display:inline-block;background-color:#e8e8e8;border-radius:5px;'><div style='min-height:6px;height:6px;line-height:10px;border-radius: 5px;background:#749edd;width:"+printProgress+"%'></div></div><div style='width:20%;display:inline-block;'><span style='font-size:12px;color:#4a4a4a;padding-left:8px'>"+printProgress+"%<span></div>";
            },
            
            formatter:function(cell) {
                let value = cell.getValue();
                value = value ? value : 0;
                if(value == "PublishParentBranch"){
                    return "<div></div>"
                }else{
                    return "<div style='width:74%;display:inline-block;background-color:#e8e8e8;border-radius:5px;'><div style='min-height:6px;height:6px;line-height:10px;border-radius: 5px;background:#749edd;width:"+value+"%'></div></div><div style='width:20%;display:inline-block;'><span style='font-size:12px;color:#4a4a4a;padding-left:8px'>"+value+"%<span></div>";
                }
            }, width:'12%'
            }
        ],
        "publishGridDigital":[
            {title:"ToggleDigital", field: "ToggleIcon",
                titleFormatter:function(cell, formatterParams, onRendered){ //plain text value
                    // return "<div id='publishDigitalToggleWrap' class='showorhide-publish-minus'><i class='fa fa-plus-square' id ='publishTogglePlus'></i><i class='fa fa-minus-square' id ='publishToggleMinus'></i></div>";
                }, 
                cssClass: 'publish-tree-branch', 
                width:'30',
                resizable:false,
                frozen:true,
                headerFilter:false,
                headerSort: false
            },
            {title:"DIGITAL ITEMS", field:"name",responsive:0, cellClick: (e, cell) => {
                if(!cell.getRow().getData().hasOwnProperty("_children") && !this.orderStatusCancel.includes(cell.getRow().getData().itemStatus.toLowerCase())){
                    this.spinnerService.show();
                    setTimeout(() => {// timeout is added for spinner issue
                        this.router.navigate([`/orderitems/item/${cell.getRow().getData().itemId}`]);
                    }, 1000); 
                }              
              },
              width:'13%'
            },
            {title:"DIRECTORY", field: "directory", width:'13%'},
            {title:"DIRECTORY CODE", field: "directoryCode", width:'12%'},
            {title:"DIGITAL ITEM ID", field:"itemId", width:'11%'},
            {title:"ITEM STATUS", field:"itemStatus", responsive:2,
                formatter:function(cell){
                    let value = cell.getValue();

                    if(value === 'Accepted / Cancelled'){
                        return "<div >Item Status</div>"
                    }
                    let progress = cell.getData().progress;
                    if(progress < 100){
                        return "<div style='min-height: 11px;min-width: 11px;margin-right:5px;border-radius: 11px;max-width: 11px;display: inline-block;background-color: #af272f;'></div>"+value
                    } else {
                            return "<div style='min-height: 11px;min-width: 11px;margin-right:5px;border-radius: 11px;max-width: 11px;display: inline-block;background-color: #97d700;'></div>"+value
                          }
                }, width:'10%'
            },
            {title:"ACTION-ACTION TYPE", field:"actionType", width:'14%'},
            {title:"VERSION-ACTIVE", field:"versionActive", width:'12%'},
            {title:" ", field:"progress",
            titleFormatter: function(cell){
                let digitalProgress = that.indicatorQuery.getValue().indicator.digitalProgress;
                return "<div style='width:74%;display:inline-block;background-color:#e8e8e8;border-radius:5px;'><div style='min-height:6px;height:6px;line-height:10px;border-radius: 5px;background:#749edd;width:"+digitalProgress+"%'></div></div><div style='width:20%;display:inline-block;'><span style='font-size:12px;color:#4a4a4a;padding-left:8px'>"+digitalProgress+"%<span></div>";
            },
            formatter:function(cell) {
                let value = cell.getValue();
                value = value ? value : 0;
                if(value == "PublishParentBranch"){
                    return "<div></div>"
                }else{
                    return "<div style='width:74%;display:inline-block;background-color:#e8e8e8;border-radius:5px;'><div style='min-height:6px;height:6px;line-height:10px;border-radius: 5px;background:#749edd;width:"+value+"%'></div></div><div style='width:20%;display:inline-block;'><span style='font-size:12px;color:#4a4a4a;padding-left:8px'>"+value+"%<span></div>";
                }
            }, width:'12%',
            }
        ],
    }
}
