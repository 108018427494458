import { Component, OnInit, Input } from '@angular/core';
//import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
//import { RowDataBoundEventArgs } from '@syncfusion/ej2-angular-grids';
import { NgxSpinnerService } from 'ngx-spinner';
import { Quickbpclass } from 'app/interface/quickbp';
import { ParamsService } from '@app/service/params/params.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialogRef } from '@angular/material';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { Location } from "@angular/common";
@Component({
  selector: 'app-goldenlistingmodal',
  templateUrl: './goldenlistingmodal.component.html',
  styleUrls: ['./goldenlistingmodal.component.scss']
})
export class GoldenlistingmodalComponent implements OnInit {

  customAttributesSelected: object;
  customAttributesBusiness: object;
  customAttributesBtn: object;
  public goldenListingGrid = new Array();
  sharedData: Quickbpclass;
  goldenDataListingId;
  businessprofile;
  myListing;
  matchedListing;
  rowid;
  flag;
  account;
  currentAccountID;
  isLoaded: boolean = false;
  model: string;
  constructor(/*public activeModal: NgbActiveModal,
    private dataService: DatasharingService,*/
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private Params: ParamsService,
    private Profile: ProfileService,
    private indicator: IndicatorService,
    private Logger: LoggerService,
    private _router: Router,
    public dialogRef: MatDialogRef<GoldenlistingmodalComponent>,
    private spinnerService: NgxSpinnerService,
    private spinnerTextService: SpinnerService,
    private activatedRoute : ActivatedRoute,
    private location: Location,
    private Utils: UtilsService) { }

  public targetElement: HTMLElement;
  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      this.myListing = this.sharedData.goldenListingData.listings.listing;
      this.rowid = this.sharedData.goldenListingData.goldenRecord;
      this.account = this.sharedData['account'];
      this.currentAccountID = this.sharedData['account'].enterpriseAccountId;
      this.loadGridData(this.sharedData.goldenListingData);
      this.model ="goldenListingGrid";
      this.isLoaded = true;
    });
    this.Params.params.mode = sessionStorage.getItem("mode");
    this.flag = this.Params.params.mode;
    this.customAttributesSelected = {class: 'SelectedCSS'};
    this.customAttributesBusiness = {class: 'businessNameCSS'};
    this.customAttributesBtn = {class: 'btnCSS'}
  }
  // tooltip(args: QueryCellInfoEventArgs) {
  //   const tooltip: Tooltip = new Tooltip({
  //       content: args.data[args.column.field]
  //   }, args.cell as HTMLTableCellElement);
  // }
  public loadGridData(data) {
    var data = data.listings.listing;
    for (let record in data) {
      var address;
      if (data[record].businessAddress) {
        address = data[record].businessAddress.streetAddress + ', ' + data[record].businessAddress.city + ',' + data[record].businessAddress.state + ',' + data[record].businessAddress.zip;
      }
      var accountIdList = "";
      if (data[record].enterpriseAccountIds && data[record].enterpriseAccountIds.enterpriseAccountId) {
        var eaIdList = data[record].enterpriseAccountIds.enterpriseAccountId;
        if (eaIdList.length > 0) {
          for (let i in eaIdList) {
            accountIdList += eaIdList[i] + ",";
          }
        }
        accountIdList = accountIdList.replace(/,(?=[^,]*$)/, '');
      }
      let phone = "";
      if (data[record].primaryPhone && data[record].primaryPhone.value) {
        phone = data[record].primaryPhone.value
      }
      let businessName = "";
      if (data[record].businessName) {
        businessName = data[record].businessName
      }
      let disabledFlag = this.disableRow(accountIdList);

      this.goldenListingGrid.push({
        id: data[record].listingId,
        BTN: phone,
        businessName: businessName,
        address: address,
        accountId: accountIdList,
        listingId: data[record].listingId,
        disabledFlag: disabledFlag,
        selectedFlag: false
      });

    }
  }

  claim() {
    this.businessprofile = true;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData['goldenlistingSelected'] = data['goldenlistingSelected'];
      this.rowid = data['goldenlistingSelected'];
    });
    if (this.sharedData['goldenlistingSelected'] == undefined || this.sharedData['goldenlistingSelected'] == null || this.sharedData['goldenlistingSelected'] == "") {
      this.Logger.logError("Please select atleast one listing id");
    } else {
      // this.sharedData.spinnerClass = "business-profile-spinner";
      this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[GoldenlistingmodalComponent] claim",
        }
      })
      this.Params.params.loadingBP = "goldenListing-spinner";
      this.Params.params.loadingbartext = "Saving Business Profile. Please wait ...";
      this.spinnerTextService.setSpinnerText(this.Params.params.loadingbartext);
      this.spinnerService.show();
      let key = this.rowid;
      let flag = this.flag;
      if (key !== "") {
        for (var selected in this.myListing) {
          if (this.myListing[selected].listingId === key) {
            this.matchedListing = selected;   // bring listing if it is matching
            if (!this.sharedData['profiles'].hasOwnProperty(this.myListing[selected].listingId)) {
              if (!this.myListing[this.matchedListing].hasOwnProperty("email") || this.myListing[this.matchedListing].email.value == undefined || this.myListing[this.matchedListing].email.value == "") {
                this.Profile.getByListing('digital', this.myListing[this.matchedListing].listingId).then((resp) => {
                  var reqData = {
                    listings: resp[this.myListing[this.matchedListing].listingId].listings,
                    businessProfiles: resp[this.myListing[this.matchedListing].listingId].businessProfiles
                  };
                  if ( reqData && reqData.listings && reqData.listings.listing[0] && reqData.listings.listing[0].hasOwnProperty("email")) {
                    reqData.listings.listing[0].email.value = (this.sharedData.frmEmail != undefined && this.sharedData.frmEmail != '' && this.sharedData.frmEmail != null) ? this.sharedData.frmEmail : this.account.email;  // If email id is not present in search response and never updated by MC then pick from account
                  } else {
                    if(reqData && reqData.listings && reqData.listings.listing){
                    reqData.listings.listing[0]["email"] = {};
                    reqData.listings.listing[0].email["value"] = (this.sharedData.frmEmail != undefined && this.sharedData.frmEmail != '' && this.sharedData.frmEmail != null) ? this.sharedData.frmEmail : this.account.email;  // If email id is not present in search response and never updated by MC then pick from account
                    reqData.listings.listing[0].email["show"] = "N";
                    }
                  }
                  reqData.listings.listing[0].source = "SFDC";
                  let catKeysCount = 0;
                  let catKeysProducts = 0;
                  if (reqData && reqData.businessProfiles && reqData.businessProfiles.businessProfile.genericData) {
                    let keyType = ["catkeyProducts", "catkeyBrands", "catkeyServices", "catkeyAffiliations", "catkeyCertifications", "catkeySpecialities"]
                    for (var key in keyType) {
                      if (reqData.businessProfiles.businessProfile.genericData[keyType[key]] && reqData.businessProfiles.businessProfile.genericData[keyType[key]].catkey) {
                        //Read catkeys
                        var catKey = reqData.businessProfiles.businessProfile.genericData[keyType[key]].catkey;
                        var catKeyObj = [];
                        if (catKey != undefined && catKey != null) {
                          for (let i = 0; i < catKey.length; i++) {
                            if (catKey[i].selected == 'Y' && (key == 'catkeyProducts' && catKeysProducts < 30 || key != 'catkeyProducts' && catKeysCount < 75)) {
                              catKeyObj.push(catKey[i]);
                              catKeysCount = catKeysCount + 1
                              if (key == 'catkeyProducts')
                                catKeysProducts = catKeysProducts + 1
                            }
                          }
                        }
                        let custCatKeyExtra;
                        if (reqData.businessProfiles.businessProfile.genericData[keyType[key]].catkeyExtra) {
                          custCatKeyExtra = reqData.businessProfiles.businessProfile.genericData[keyType[key]].catkeyExtra;
                        }
                        reqData.businessProfiles.businessProfile.genericData[keyType[key]] = {};
                        reqData.businessProfiles.businessProfile.genericData[keyType[key]]['catkey'] = catKeyObj;
                        reqData.businessProfiles.businessProfile.genericData[keyType[key]]['catkeyExtra'] = custCatKeyExtra;
                      }
                    }
                  }
                  this.Profile.update(this.myListing[this.matchedListing].listingId, reqData).then(() => {
                    this.updateClaim(this.account, this.myListing[this.matchedListing].listingId, reqData.listings.listing[0].email.value, flag);
                  }, error => {
                    var errorObject = error.error.response.esbServiceFault[0] ? error.error.response.esbServiceFault[0] : null;
                    if (errorObject) { //fix for sonar issue
                      var errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                      var errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                      var errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                      this.Logger.logError(errorMsg);
                      this.spinnerService.hide();
                      // Params.saving = false;
                    }
                  }
                  );

                }, error => {
                  var errorObject = error.error.response.esbServiceFault[0] ? error.error.response.esbServiceFault[0] : null;
                  //fix for sonar issue
                  var errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                  var errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                  var errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                  this.spinnerService.hide();
                  this.Logger.logError(errorMsg);
                });
              } else {
                this.updateClaim(this.account, this.myListing[this.matchedListing].listingId, this.myListing[this.matchedListing].email.value, flag);
              }
              // });
            } else {
              this.sharedData.stateParamsId = this.myListing[this.matchedListing].listingId;
              this.sharedData.businessProfilePageIndex = 0;
              this.sharedData.tileFlag1 = true;
              this.sharedData.tileFlag2 = false;
              this.sharedData.tileFlag3 = false;
              this.indicatorStore.update(state =>{
                return{
                  indicator: this.sharedData,
                  updatedFrom: "[GoldenlistingmodalComponent] profileupdate",
                }
              })
              this.spinnerService.hide();
              if (flag == 'QuickBP') {
                // this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: this.account.enterpriseAccountId, accountId: this.Params.params.account } });

                this.Params.params.mode = "ManageBP";
                this.Params.params.order = null;
                let bpEaId = this.activatedRoute.snapshot.queryParams['eaId'];
                let bpAccount = this.activatedRoute.snapshot.queryParams['account'];
                setTimeout(() => {
                sessionStorage.setItem("mode","ManageBP");
              }, 2000);
                this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: bpEaId, account: bpAccount}, queryParamsHandling: 'merge'});
                setTimeout(() => {
                  sessionStorage.setItem("caangulareffererPath",this.location.path());
                  sessionStorage.setItem("okta-app-url",this.location.path());
                 }, 2000);
              }
              // else
              // this._router.navigate(['/business/business/' + this.myListing[this.matchedListing].listingId],  { queryParams: this.Params.params.values });
              //this.activeModal.close();
              this.dialogRef.close();
            }
            break;
          }
        }
      }
    }
  };
  updateClaim(account, listingId, emailAddress, flag) {
    //If we get any of the following field as null,then don't claim BP and show warning
    if (account.enterpriseAccountId && listingId != undefined && listingId != null && listingId != '' && emailAddress != undefined && emailAddress != null && emailAddress != '') {
      var data = {
        "enterpriseAccountId": account.enterpriseAccountId,
        "listingId": listingId,
        "emailAddress": emailAddress
      };

      forkJoin([
        // Indicator.addProfileById(myListing[matchedListing].listingId),
        this.Profile.claim(data)
      ])
        .subscribe((results) => {
          this.Logger.logSuccess("Successfully updated business profile");
          if(this.Params.params.mode != "ManageBP" && this.Params.params.mode != "QuickBP" && this.Params.params.mode != "listing" ){
          this.Profile.getByDigitalListing(listingId).then((res)=>{
            if(res[listingId].hasWarningOrError == "false"){
              this.sharedData["order"].OrderItems.forEach((item, index) => {
                if(item.productType == "Digital" && item.actionCode != "Cancel" && item.actionType != "Cancel" ){
                  let name = res[listingId].listings.listing[0].businessName;
                  let  phoneNumLocal = "";
                  if(res[listingId].listings.listing[0].primaryPhone.value){
                     phoneNumLocal = this.Utils.transformTelephone(res[listingId].listings.listing[0].primaryPhone.value);
                  }
                  name += "," + phoneNumLocal + " " +  res[listingId].listings.listing[0].businessAddress.streetAddress + " " + res[listingId].listings.listing[0].businessAddress.city ;
                  if (res[listingId].listings.listing[0].businessAddress.zip !== undefined ) {
                    name += '-' + res[listingId].listings.listing[0].businessAddress.zip;
                  }
                  if (res[listingId].listings.listing[0].businessAddress.zip4 !== undefined ) {
                    name += '-' + res[listingId].listings.listing[0].businessAddress.zip4;
                  }

                  if(!this.sharedData["items"][item.svItemId]['listingArray']){
                    this.sharedData["items"][item.svItemId]['listingArray'] = [];
                  }
                  if(!this.sharedData["items"][item.svItemId]['listings']){
                    this.sharedData["items"][item.svItemId]['listings'] = [];
                  }   
                  this.sharedData["items"][item.svItemId]['listings'].push(res[listingId].listings.listing[0]);               
                  let listing = {
                    name: name,
                    value: res[listingId].listings.listing[0].listingId,
                    index: this.sharedData["items"][item.svItemId]['listingArray'].length
                  };
                  if(this.sharedData["items"][item.svItemId]['listingArray'].length > 0){
                    let isListingInItem = false;
                    for(let i = 0; i < this.sharedData["items"][item.svItemId]['listingArray'].length; i++){
                        if(this.sharedData["items"][item.svItemId]['listingArray'][i].value == listingId){
                          isListingInItem = false;
                          break;
                        } else{
                          isListingInItem = true;
                        }
                    }
                    if(isListingInItem){
                      this.sharedData["items"][item.svItemId]['listingArray'].push(listing);
                    }
                  }else{
                    this.sharedData["items"][item.svItemId]['listingArray'].push(listing);
                  }
                }})
            }
          })
          }
          this.indicator.addProfileById(this.sharedData, listingId)
            .then(profileResult => {
              if (flag == 'QuickBP') {
                this.sharedData['profiles'] = profileResult;
                this.indicatorStore.update(state =>{
                  return{
                    indicator: this.sharedData,
                    updatedFrom: "[GoldenlistingmodalComponent] addProfileID",
                  }
                })
                this.spinnerService.hide();
                // this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: this.account.enterpriseAccountId, accountId: this.Params.params.account } });
                this.Params.params.mode = "ManageBP";
                this.Params.params.order = null;
                let bpEaId = this.activatedRoute.snapshot.queryParams['eaId'];
                let bpAccount = this.activatedRoute.snapshot.queryParams['account'];
                setTimeout(() => {
                  sessionStorage.setItem("mode","ManageBP");
                }, 2000);
                this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: bpEaId, account: bpAccount}, queryParamsHandling: 'merge'});
                setTimeout(() => {
                  sessionStorage.setItem("caangulareffererPath",this.location.path());
                  sessionStorage.setItem("okta-app-url",this.location.path());
                 }, 2000);
              } else {
                this.sharedData['profiles'] = profileResult;
                this.sharedData.stateParamsId = listingId;
                this.sharedData['tempModel'] = this.sharedData['profiles'][this.sharedData.stateParamsId].model;
                this.sharedData['bpDirtyFlag'] = true;
                this.sharedData.businessProfilePageIndex = 1;
                this.sharedData.tileFlag1 = false;
                this.sharedData.tileFlag2 = true;
                this.sharedData.tileFlag3 = false;
                this.indicatorStore.update(state =>{
                  return{
                    indicator: this.sharedData,
                    updatedFrom: "[GoldenlistingmodalComponent] navigate",
                  }
                })
                this.spinnerService.hide();
               // this._router.navigate(['/business/managebp'], { queryParams: { mode: "ManageBP", eaId: this.account.enterpriseAccountId, accountId: this.Params.params.account }, queryParamsHandling: 'merge'});
                // this._router.navigate(['/business/business/' + listingId], { queryParams: this.Params.params.values });
              }
              this.dialogRef.close();
              //this.activeModal.close();
            }, err => {
              var respObj = err.error.response;
              var errorObject = respObj.esbServiceFault[0];
              var errorCode = errorObject.exceptionDetail[0].errorCode;
              var errorDesc = errorObject.exceptionDetail[0].errorDescription;
              var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              this.Logger.logError(errorMsg);
              this.dialogRef.close();
              this.spinnerService.hide();
              //this.activeModal.close();
            })
        }, (error) => {
          var errorObject = error.error.response.esbServiceFault[0] ? error.error.response.esbServiceFault[0] : null;
          if (errorObject) { //fix for sonar issue
            var errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
            var errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
            var errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            this.Logger.logError("Error while updating business profile" + errorMsg);
            this.spinnerService.hide();
          }
        });
    } else {
      var error = " is not present";
      error = (!account.enterpriseAccountId) ? "enterpriseAccountId" + error : error;
      (!listingId) ? "listingId" + error : error;
      (!emailAddress) ? "emailAddress" + error : error;
      this.spinnerService.hide();
      this.Logger.logError("Unable to claim business profile, " + error);
    }
  };

  change(data1, event) {
    this.goldenDataListingId = data1.listingId;
    this.rowid = data1.listingId;
  }


  disableRow(enterpriseAccountIdList) {
    if (enterpriseAccountIdList === null || enterpriseAccountIdList === undefined || enterpriseAccountIdList === "") {
      return false;
    }
    var disableRowFlag = false;
    if (enterpriseAccountIdList != null && enterpriseAccountIdList.indexOf(",") > 0) {
      let accountIds = enterpriseAccountIdList.split(",");
      for (var accountIdIndex in accountIds) {
        if (accountIds[accountIdIndex] === "") {
          disableRowFlag = false;
        } else if (accountIds[accountIdIndex] == this.currentAccountID) {
          disableRowFlag = false;
          break;
        } else {
          disableRowFlag = true;
        }
      }
    } else {
      if (enterpriseAccountIdList === "") {
        disableRowFlag = false;
      } else if (enterpriseAccountIdList == this.currentAccountID) {
        disableRowFlag = false;
      } else {
        disableRowFlag = true;
      }
    }
    return disableRowFlag;
  }
}
