import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';

import { CoreModule } from '@app/core.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainModule } from './layout/main/main.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from  './core/interceptor/token.interceptor';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { Bootstrap4FrameworkModule } from '@ajsf/bootstrap4';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatDialogModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatIconModule
} from "@angular/material";
import { SpinnerComponent } from './layout/main/spinner/spinner.component';
import { SpinnerService } from './layout/main/spinner/spinner.service';
import { SpinnerInterceptor } from './layout/main/spinner/spinner.interceptor';
import { Constants } from '@app/service/datasource/constants';
import { ParamsService } from '../app/core/service/params/params.service';

//Added for sentry
import * as Sentry from "@sentry/browser";
import { CallbackComponent } from './callback.components';
let currentEnvURL = window.location.href;//environment.apiEndpointUrl;
let currentEnv = 'Production'
if (currentEnvURL){
  currentEnvURL = currentEnvURL.substring(0,30);
  if (currentEnvURL.includes('-ppt')){
    currentEnv = "ppt"
  } else if(currentEnvURL.includes('-cfit')){
    currentEnv = "cfit";
  } else if (currentEnvURL.includes('-pm')){
    currentEnv = "pm";
  } else if (currentEnvURL.includes('-train')){
    currentEnv = "train";
  } else if (currentEnvURL.includes('-dev')){
    currentEnv = "dev01";
  } else if (currentEnvURL.includes('localhost')){
    currentEnv = "localhost";
  }
}

const serverErrorRegex = new RegExp(`500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
'mi'); // Added temporarily to ignore internal server error getting logged in sentry
const nonErrorRegex = new RegExp(`Non-Error exception captured`, 'i');
Sentry.init({
  environment: "CA-Phase2-"+currentEnv,
  dsn: 'https://1555affca67642fe8d1311121fe55ab6@sentry.io/2447820',
  ignoreErrors: ['ResizeObserver loop limit exceeded', /HttpErrorResponse:.*/, nonErrorRegex]
});

Sentry.configureScope(scope => {
  scope.setTag('nodeServer', sessionStorage.getItem('nodeServer'));
  scope.setTag( 'userName', sessionStorage.getItem('username') );
  // scope.clear();
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    //Sentry.showReportDialog({ eventId });
  }
}


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MainModule,
    ToastrModule.forRoot({
      preventDuplicates: false,
      closeButton : true
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    Bootstrap4FrameworkModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  providers: [Constants, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }}
,{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
 {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},{ provide: ErrorHandler, useClass: SentryErrorHandler },ParamsService],
  bootstrap: [AppComponent],
  entryComponents:[SpinnerComponent]
})
export class AppModule { }
