import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute } from '@angular/router';
import { IndicatorStore } from 'app/state/store';
import { FooterService } from '../footer/footer.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { StorageService } from '@app/service/storage/storage.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { CounterService } from '@app/service/counter/counter.service';
import { constant } from 'app/data/constants';
import { GroupService } from '@app/service/group/group.service';
import { ProductService } from '@app/service/product/product.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  customField;
  options;
  Indicator;
  id;
  accounts =[];
  billingAccounts =[] ;
  private routeSub: Subscription;
  titleContent: any;
  schemaObj;
  isReadonly :boolean=false;
  productType: string;
  toolTipValue:string;
  constructor(private indicatorQuery :IndicatorQuery,
    private indicatorStore :IndicatorStore,
    private footerService: FooterService,
    private cdr: ChangeDetectorRef,
    private Logger :LoggerService,
    private storageService: StorageService,
    private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService,
    private productService: ProductService,
    private grouper: GroupService,
    private sfService: SalesforceService,
    ) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = { ...res };
      let  tempObj =JSON.parse(JSON.stringify(this.Indicator));
      this.schemaObj= this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name];
      this.titleContent = this.schemaObj.title;
      //this.isReadonly =this.schemaObj.readonly;
      this.isReadonly = this.Indicator.items[this.Indicator.stateParamsId].order.parentId ? true : this.schemaObj.readonly;
      if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
        this.isReadonly = true;
      }
      //this.addBillingValues();
      if (this['layoutNode'].name == "BillingAccount"){
        this.id = this['layoutNode'].name;
        if(this.Indicator.items[this.Indicator.stateParamsId].order.parentId){
          //this.customField = "N/A"
          let parentId = this.Indicator.items[this.Indicator.stateParamsId].order.parentId;
          this.customField = this.Indicator.items[parentId].model.BillingAccount ? this.Indicator.items[parentId].model.BillingAccount : "N/A";
          if(this.customField != "N/A"){
            this.Indicator.items[this.Indicator.stateParamsId].model.BillingAccount = this.customField;
            this.indicatorStore.update((state) => {
              return {
                indicator: this.Indicator,
                updatedFrom: "[BillingComponent] ngOnInit",
                isloaded: true,
              };
            });
          }
        }
        else{
          this.customField = tempObj.items[this.Indicator.stateParamsId].model.BillingAccount;
        }
        this.productType = tempObj.items[this.Indicator.stateParamsId].order.productType;
      }
      this.addBillingValues();
    });

  }
  sendValue(args) {
    let oldValue = this.Indicator.items[this.Indicator.stateParamsId].model.BillingAccount
    if(this['layoutNode'].name === "BillingAccount") {
      this.indicatorQuery.getIndicator().subscribe((res) => {
        this.Indicator = { ...res };
      });
      if(args == 'Add New Billing Account') {
        let  tempObj =JSON.parse(JSON.stringify(this.Indicator));
        this.updateBillingId(this.Indicator.stateParamsId, tempObj);
        this.footerService.matDialogFunction(null, this.productType,"CREATE-BILLING-ACCOUNT-ITEMPAGE").subscribe((data)=> {
          this.indicatorQuery.getIndicator().subscribe((res) => {
            this.Indicator = { ...res };
            tempObj= JSON.parse(JSON.stringify(this.Indicator));
            if(data) {
              tempObj.items[this.Indicator.stateParamsId].model.BillingAccount = data.accountId;
            } else {
              tempObj.items[this.Indicator.stateParamsId].model.BillingAccount = null;
            }
            this.updateBillingId(null,tempObj);
            this.addBillingValues();
            this.customField = tempObj.items[this.Indicator.stateParamsId].model.BillingAccount;
            this.updateOtherModels(this.customField,oldValue)
            if (!(<ViewRef>this.cdr).destroyed)
              this.cdr.detectChanges();
          });
        });
      } else {
        let  tempObj =JSON.parse(JSON.stringify(this.Indicator));
        let modelOldValue = tempObj.items[tempObj.stateParamsId].model.BillingAccount
        tempObj.items[this.Indicator.stateParamsId].model.BillingAccount = args;
       //Code from old Item controller watcher
        if (args != null &&   tempObj.items[this.Indicator.stateParamsId].model.hasOwnProperty("BillingBasis")) {
          for (let key in  tempObj.items) {
            if (  tempObj.items[this.Indicator.stateParamsId].model["BillingAccount"] ===  tempObj.items[key].model["BillingAccount"]) {
              if ( tempObj.items[key].model.hasOwnProperty("BillingBasis")) {
                tempObj.items[this.Indicator.stateParamsId].model["BillingBasis"] =  tempObj.items[key].model["BillingBasis"];
                  break;
              }
            }
          }
        }
          this.indicatorStore.update((state) => {
            return {
              indicator:tempObj,
              updatedFrom: "[BillingComponent] sendValue",
              isloaded: true,
            };
        });
        this.updateOtherModels(args,oldValue)
        let activityLogHistoryObj = {
          'displayName':tempObj['user']['name'],
          'email':tempObj['user']['email'],
          'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
          'itemId':tempObj.stateParamsId,
          'itemStatus': tempObj.items[tempObj.stateParamsId].order.status,
          'itemActionCode': this.Indicator.items[tempObj.stateParamsId].order.actionCode + "-" + this.Indicator.items[tempObj.stateParamsId].order.actionType,
          'fieldName':'Billing Account',
          'fieldOldValue':modelOldValue,
          'fieldNewValue': args
      }
      this.storageService.update("itemHistory", tempObj.stateParamsId, activityLogHistoryObj);
      }
      this.digitalBillingContent();
    }
  }
  async digitalBillingContent(){
    let latestIndicator
      this.indicatorQuery.getIndicator().subscribe((res) => {
        latestIndicator = { ...res };
      });

      let currentItem = latestIndicator.items[latestIndicator.stateParamsId];
      let stopPrintCallforItemStatus = (currentItem.order.statusCode == 10000 || currentItem.order.statusCode == 7000) ? true : false;
      let order = latestIndicator.order;
      let thryvOrNoBPItem = false;
      await this.sfService.getThryvProductDetails(currentItem.order.UDAC).then(res => {
        if((res["records"] && res["records"][0] && res["records"][0].hasBP__c == false && res["records"][0].isThryvLead__c == true) || (res["records"] && res["records"][0] && res["records"][0].Product_Code__c == 'Vivial - Digital')){
          thryvOrNoBPItem = true;
        }                
      });

      if(thryvOrNoBPItem){
        //let billingId = currentItem.model["BillingAccount"] ? currentItem.model["BillingAccount"] : currentItem.model.BillingAccount || latestIndicator.billing.billing[0].billingAccountId;
        let billingId;
        if(currentItem && currentItem.model && currentItem.model["BillingAccount"]){
          billingId = currentItem.model["BillingAccount"];
        }else{
          if(currentItem && currentItem.model && currentItem.model.BillingAccount){
            billingId = currentItem.model.BillingAccount
          }else if(latestIndicator && latestIndicator.billing && latestIndicator.billing.billing && latestIndicator.billing.billing[0] && latestIndicator.billing.billing[0].billingAccountId){
            billingId = latestIndicator.billing.billing[0].billingAccountId;
          }
        }

        let itemSfData = {
          "eItemId": currentItem.order.svItemId,
          "SFDCItemId": currentItem.order.sfdcItemId,
          "billingAccountId": billingId,
          "billingBasis": currentItem.model["BillingBasis"],
          // "billingMapRequired": data.Billing_MAP_Required__c,
        };
        let sfresult = await this.sfService.savesfdata(itemSfData)

        if ((currentItem.order.productType === "Digital") && order.orderType !== "National" && !stopPrintCallforItemStatus) {
          let upsertMapping
          upsertMapping = {
            AuthData: {
              "apiSource": "CA"
            },
            ProductHeader: {},
            products: [{}]
          };
          upsertMapping['products'] = {
            product: {
              type: "updateItem",
              callerId: "UCC",
              correlationId: this.grouper.getAllItemsOfGroupButNotSelf(currentItem.order.svItemId, latestIndicator).toString(),
              sourceId: order.accountId,
              svOrderId: order.orderId,
              customerId: latestIndicator.account.kgenCustomerId,
              operatorId: order.opportunity.owner.operatorId,
              content: [{
                svItemId: currentItem.order.svItemId,
                billingAccountId: currentItem.model.BillingAccount
              }]
            }
          };
          let updateResult = await this.productService.updateSync("print", upsertMapping, "item");
        }
      }
  }
  addBillingValues(){
    let name;
    //let billingArray =this.Indicator.items[this.Indicator.stateParamsId].billingArray;
    let billingArray=[];
    this.accounts=[];
    if (this.productType && this.productType.toLowerCase() == 'digital') {
      // if (this.Indicator && this.Indicator.stateParamsId){
        // if (this.Indicator.items[this.Indicator.stateParamsId].order.UDAC == "PRINTOLN"){
        //   billingArray = this.Indicator.items[this.Indicator.stateParamsId].billingArray.filter((billingDetails)=>{
        //     return billingDetails.type != "MAP";
        //   })
        // }else{
          // billingArray = this.Indicator.items[this.Indicator.stateParamsId].billingArray;
        // }
      // }else{
        billingArray = this.Indicator.items[this.Indicator.stateParamsId].billingArray;
      // }
      
    } else if(this.productType && this.productType.toLowerCase() == 'print') {
      billingArray = this.Indicator.printBilling;
    } else {
      billingArray =this.Indicator.items[this.Indicator.stateParamsId].billingArray;
    }
    if(!billingArray &&  this.Indicator.items[this.Indicator.stateParamsId].billingError){
      this.Logger.logError(this.Indicator.items[this.Indicator.stateParamsId].billingError);
    }else{
      let finalName = [];
      if (billingArray && billingArray.length > 0){
        for (let i = 0; i <billingArray.length; i++) {
          let eachAccount = {};   
          eachAccount["value"] =billingArray[i].value;
          finalName = billingArray[i].finalName.split(" - ");
          eachAccount["finalName"] = billingArray[i].value +"-" + finalName[0];
          if(this.Indicator.items[this.Indicator.stateParamsId].order.parentId){
            if(eachAccount['value'] == ""){
              eachAccount['value']= "N/A"
              eachAccount['finalName']= "N/A"
              this.customField  = "N/A"
            }
          }
          this.accounts.push(eachAccount);
        }
        if(this.customField  == "N/A"){
          this.accounts.push({value: 'N/A', finalName: 'N/A'})
        }
        this.accounts.forEach(element => {
           if(element.value == this.customField){
            this.toolTipValue = element.finalName;
          }
        });
      }
      else{
        let eachAccount = {};
        if(this.Indicator.items[this.Indicator.stateParamsId].order.parentId){
          if(this.customField  == "N/A"){
            this.accounts.push({value: 'N/A', finalName: 'N/A'})
          }
        }
      }

    }

  }
  updateBillingId(stateParamsId, tempObj) {
    tempObj['stateParamItemId'] = stateParamsId;
    this.indicatorStore.update((state) => {
      return {
        indicator: tempObj,
        updatedFrom: "[BillingComponent] updateBillingId",
        isloaded: true,
      };
    });
  }
  async updateOtherModels(value, oldValue) {
    this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    modelData.items[this.Indicator.stateParamsId].model[this['layoutNode'].name] = value;
    //modelData = await this.watchHelper.setItemValuesFromBP(this.Indicator.stateParamsId, value, modelData, "bp");
    modelData.bundles.forEach((bundle, index, bundles) => {
      bundle.children.forEach((child, childIndex, children) => {
        let newIndicator;
        if (bundle.parent == modelData.stateParamsId && modelData.items[child] && modelData.items[bundle.parent].model) {
          if (modelData.items[bundle.parent].model[this['layoutNode'].name]) {
            newIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            newIndicator.items[child].model[this['layoutNode'].name] = modelData.items[bundle.parent].model[this['layoutNode'].name];
            // this.indicatorQuery.updateModel(this['layoutNode'].name, value, newIndicator.items[child].model, newIndicator, '[billing] updatbillingChild-updateOtherModels');
            // Changes for FUL-40719
            let tempData = this.requiredfieldcount.getNewItemCount(newIndicator.items[child].requiredFields, newIndicator["items"][child].model, newIndicator["items"][child].order, newIndicator["orderPercent"], newIndicator["items"][child].counter, true, newIndicator["items"][child]);
            if (tempData) {
              let counter = [];
              newIndicator.orderPercent.currentCount = tempData.tempCurrentCount;
              newIndicator.items[child].counter = tempData.itemCount;
              newIndicator.orderPercent = this.getItemErrorCount(newIndicator, tempData.itemCount, newIndicator.orderPercent, newIndicator.items[child].order);
              let percentage = this.requiredfieldcount.getPercent(newIndicator.orderPercent.currentCount, newIndicator.orderPercent.maxCount);
              newIndicator.orderPercent.percent = percentage;
              //get BP count
              const { bpcount, bpcountMax, bpTotalCount } = this.requiredfieldcount.getBPCount(newIndicator.profiles);
              newIndicator.orderPercent.bpTotalCount = bpTotalCount

              counter.push(newIndicator.orderPercent.digitalCount);
              counter.push(newIndicator.orderPercent.printCount);
              counter.push(newIndicator.orderPercent.bpTotalCount);
              this.counterService.setError(counter);
              this.updateBillingId(child, newIndicator);
            }
          }
          else {
            modelData.items[child].model[this['layoutNode'].name] = modelData.items[bundle.parent].model[this['layoutNode'].name];
            this.updateBillingId(child, modelData);
          }
        }
      })
    })

  }

  getItemErrorCount(data, itemCount, orderPercent, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    for(let i = 0; i < data.order["OrderItems"].length; i++){
      if(data && data.items && data.items[data.order["OrderItems"][i].svItemId] && data.items[data.order["OrderItems"][i].svItemId].counter > 0){
        counter = counter + 1
      }
    }
    orderPercent= this.updateCounter(productType, counter, orderPercent);
    return orderPercent;
  }

  updateCounter(productType, counter, orderPercent) {
    if(productType && productType === constant.ITEM_TYPE_DIGITAL) {
      orderPercent.digitalCount =   counter -  orderPercent.printCount;
    } else if(productType && productType === constant.ITEM_TYPE_PRINT) {
      orderPercent.printCount =   counter -  orderPercent.digitalCount;
    }
    return orderPercent;
  }


}
