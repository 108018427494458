import  * as $ from 'jquery';
import { Router } from '@angular/router';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { OnInit } from '@angular/core';
import { CounterService } from '@app/service/counter/counter.service';
import { StorageService } from '@app/service/storage/storage.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
var that;
export class DigitalModel implements OnInit{
    indicatorValue :Quickbp
    subscribe: any;
    items: any = [];
    showToastr: boolean = true;
    constructor(private router: Router,
                private params: ParamsService,
                private indicatorQuery: IndicatorQuery,
                private indicatorStore: IndicatorStore,
                private counterService: CounterService,
                private storageService: StorageService,
                private requiredfieldcount: RequiredfieldcountService,
                private Logger: LoggerService,
                private indicatorService :IndicatorService,
                private spinnerService :NgxSpinnerService
                ) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  //console.log('Indicator value from digital.ts is*******',this.indicatorValue);
                  that = this;
                });
                that.counterService.setUpdate(false);
    }

    ngOnInit(): void {
       // throw new Error("Method not implemented.");
    }
    columns: any =
    {
        "Digital-main":[
            { title: "", width:'1%',field: "counter",formatter:function(cell, formatterParams){
                var value = cell.getValue();
                if (value > 0){
                  return "<div style='min-width:6px;min-height:49px;background:#df2222;margin-top:-4px;margin-left:-8px;margin-right:2px;'></div>"
                }
                return;
              }
            },
            {title: "ITEM", field: "item" ,width:'10%', cssClass:'tabulator-custom-title-Color',
              cellClick: (e, cell) => {
                this.spinnerService.show();
                setTimeout(() => {// timeout is added for spinner issue
                  this.router.navigate([`/digitalitems/item/${cell.getRow().getData().itemId}`]);
                }, 1000);
              },tooltip:function(cell){
                return  cell.getData().itemId;
            },
            },
            {title: "UDAC", field: "udac", width:'9%',tooltip:function(cell){
              return  cell.getData().itemId;
            }
          },
            {title: "Geography", field: "geography",width:'10%',},
            {title: "CATEGORY", field: "category",width:'10%',},
            {
             title: "Business Profile/Listing",
             field: "businessprofile",
             width:'15%',
            formatter: function(cell,onRendered, success, cancel) {
             var listingValue = cell.getValue();
             var initialListingName = '';
             let initialListingValue = '';
             var cellRealValue = cell.getRow().getData().listingArray;
             var selectedListingVal = cell.getRow().getData().businessprofile;

             //Create and append select list
             var selectList = document.createElement("select");

             selectList.style.width = "100%";
             selectList.style.boxSizing = "border-box";
             selectList.style.border = "none";
             selectList.style.background = "none";
             selectList.style.display = "inline-block";
             selectList.style.height = "auto";
             selectList.style.borderBottom ="1px solid grey";
             selectList.style.paddingBottom="5px";
             let parentId = cell.getRow().getData().parentId;
             let statusCode = cell.getRow().getData().statusCode;
             let readOnly = cell.getRow().getData().readOnly;
             let udac = cell.getRow().getData().udac;
             let readOnlyBilling = cell.getRow().getData().readOnlybilling;
            // that.disabledCell(parentId,statusCode,readOnly,selectList, 'businessProfile',udac);
             if(readOnlyBilling){
              that.disabledCell(parentId,statusCode,readOnlyBilling,selectList, 'billing',udac);
              }
             async function onChange() {
               console.log('onchange called!!!!!');
              try{
               if (selectList.selectedOptions[0].value != initialListingName) { //selectedBilingVal
                 cell.getElement().dataset.businessProfile = selectList.selectedOptions[0].value;
                 cell.setValue(selectList.selectedOptions[0].value,true);
                 for (let i=0; i< cellRealValue.length; i++){
                  if (selectList.selectedOptions[0].value == cellRealValue[i].name){

                    let updatedProfileId;
                    that.indicatorQuery.getIndicator().subscribe((res) => {
                      let val = {...res}
                      updatedProfileId = JSON.parse(JSON.stringify(res))
                    });
                    let oldValue = updatedProfileId.items[cell.getRow().getData().itemId].model.BusinessProfile
                    updatedProfileId.items[cell.getRow().getData().itemId].model["BusinessProfile"] = cellRealValue[i].value;
                    updatedProfileId.items[cell.getRow().getData().itemId].model["ListingId"] = cellRealValue[i].value;
                    cell.getRow().update({bPListing:cellRealValue[i].name})
                    //add/remove new profile to Indicator
                    if(oldValue && oldValue!=undefined && oldValue!=null && oldValue!=''){
                      that.indicatorService.removeProfileById(updatedProfileId, oldValue)
                      that.indicatorQuery.updateProfilesListingId(updatedProfileId, '[DigitalModel] disabledCell-updateProfilesListingId');
                    }
                    await that.indicatorService.addProfileById(updatedProfileId, cellRealValue[i].value)
                      that.indicatorQuery.getIndicator().subscribe((res) => {
                        updatedProfileId = JSON.parse(JSON.stringify(res))
                      });
                      let existingListingIds = updatedProfileId.listingIds
                      if(existingListingIds!=undefined && existingListingIds!=null){
                        existingListingIds.push(cellRealValue[i].value)
                      }else{
                        existingListingIds = []
                        existingListingIds.push(cellRealValue[i].value)
                      }
                      that.indicatorQuery.updateProfiles(cellRealValue[i].value,existingListingIds,updatedProfileId, '[DigitalModel] disabledCell-updateProfiles');

                       //BP selected from Parent item should reflect in child item
                    updatedProfileId.bundles.forEach((bundle, index, bundles) => {
                      bundle.children.forEach((child, childIndex, children) => {
                        let newIndicator;
                        if (bundle.parent == cell.getRow().getData().itemId && updatedProfileId.items[child] && updatedProfileId.items[bundle.parent].model) {
                          if (updatedProfileId.items[bundle.parent].model["BusinessProfile"] || updatedProfileId.items[bundle.parent].model["ListingId"] ) {
                            updatedProfileId.items[child].model["BusinessProfile"]  = cellRealValue[i].value;
                            updatedProfileId.items[child].model["ListingId"]  = cellRealValue[i].value ;

                          }

                          if (updatedProfileId.items[bundle.parent].order.productCode === "DPM") {
                            updatedProfileId.items[bundle.parent].model.initialDataSet = true;
                          }
                          let tabulatorData = cell.getTable().getRows();
                          for (let z = 0; z < tabulatorData.length; z++){
                            if(tabulatorData[z].getData().itemId == child){
                              tabulatorData[z].update({businessProfile:cellRealValue[i].value});
                              tabulatorData[z].update({bPListing:cellRealValue[i].name});
                              break;
                            }
                          }

                        }
                      })
                    })
                    let currentCount= updatedProfileId.items[cell.getRow().getData().itemId].counter;
                    let tempData = that.requiredfieldcount.getNewItemCount(updatedProfileId.items[cell.getRow().getData().itemId].requiredFields, updatedProfileId.items[cell.getRow().getData().itemId].model, updatedProfileId.items[cell.getRow().getData().itemId].order, updatedProfileId.orderPercent, currentCount, true, updatedProfileId.items[cell.getRow().getData().itemId]);
                    that.updateIndictor(cell.getRow().getData().itemId, updatedProfileId,tempData, currentCount);
                    if(updatedProfileId["items"][cell.getRow().getData().itemId].order.actionCode == 'Renew') {
                      if(updatedProfileId.items[cell.getRow().getData().itemId].hasOwnProperty("hasCopysheet") ) {
                        updatedProfileId.items[cell.getRow().getData().itemId].model['renewHasCopysheet'] = updatedProfileId["items"][cell.getRow().getData().itemId]['hasCopysheet'];
                      }
                      updatedProfileId.items[cell.getRow().getData().itemId].model['dirtyFlag'] = true;
                    }
                    that.indicatorValue = updatedProfileId;
                    that.counterService.setUpdate(true);
                    that.updateItemId(cell.getRow().getData().itemId);
                    that.updateData("item", cell.getRow().getData().itemId, cell.getRow().getData().version,  updatedProfileId.items[cell.getRow().getData().itemId].model, 'Business Profile');
                  break;
                    }
                }

               } else { // No change
                 //cancel();
               }
              }catch (error){
                console.log("Error in onchange:",error);
              }
             }

             //submit new value on change
             selectList.addEventListener("change", onChange);
             function getSelectedBillingName(value,listingData){
                 for (let i=0; i<listingData.length; i++){
                   if (listingValue == listingData[i].value){
                     initialListingName = listingData[i].name;
                     initialListingValue = listingData[i].value;
                     cell.setValue(initialListingName,true);
                     break;
                   }else if(listingValue ==  listingData[i].name){
                     initialListingName = listingData[i].name;
                     initialListingValue = listingData[i].value;
                     break;
                   }
                 }
             }

             if (cellRealValue && cellRealValue.length > 0){
               getSelectedBillingName(listingValue,cellRealValue);
               for (var i=0; i< cellRealValue.length; i++){

                if (i==0){
                  var option = document.createElement("option");
                  option.value = initialListingName;//cellRealValue[value];
                  option.text = initialListingName ? initialListingName + ' / '+initialListingValue : initialListingName;//cellRealValue[value];
                  option.title = initialListingName + ' / '+initialListingValue;
                  selectList.appendChild(option);
                  if (!initialListingName || (initialListingName != cellRealValue[i].name)){
                    let option = document.createElement("option");
                    option.value = cellRealValue[i].name;
                    option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
                    option.title = cellRealValue[i].name + ' / '+cellRealValue[i].value;
                    selectList.appendChild(option);
                  }
                }else{
                  if ((listingValue !== cellRealValue[i].value) && (listingValue !== cellRealValue[i].name)){
                    var option = document.createElement("option");
                    option.value = cellRealValue[i].name;
                    option.text = cellRealValue[i].name ? cellRealValue[i].name + ' / '+cellRealValue[i].value : cellRealValue[i].name;
                    option.title = cellRealValue[i].name + ' / '+cellRealValue[i].value;
                    selectList.appendChild(option);
                  }
                }
               }
             }
               return selectList;
           }
           },
            {title: "BILLING TYPE", field: "btype", width:'13%'},
            {title: "BILLING ACCOUNT ID", field: "billingaccountid",
            width:'15%',
            formatter: function(cell,onRendered, success, cancel) {
              var value = cell.getValue();
              var initialBillingName = '';

              var cellRealValue = cell.getRow().getData().billArr;
              var selectedBilingVal = cell.getRow().getData().billing;

              //Create and append select list
              var selectList = document.createElement("select");

              selectList.style.width = "100%";
              selectList.style.boxSizing = "border-box";
              selectList.style.border = "none";
              selectList.style.background = "none";
              selectList.style.display = "inline-block";
              selectList.style.height = "auto";
              selectList.style.borderBottom ="1px solid grey";
              selectList.style.paddingBottom="5px";
              let parentId = cell.getRow().getData().parentId;
              let statusCode = cell.getRow().getData().statusCode;
              let readOnly = cell.getRow().getData().readOnly;
              let udac = cell.getRow().getData().udac;
              let readOnlyBilling = cell.getRow().getData().readOnlybilling;
              if(readOnlyBilling){
                that.disabledCell(parentId,statusCode,readOnlyBilling,selectList, 'billing',udac);
                }
              //that.disabledCell(parentId,statusCode,readOnly,selectList, 'billing');
              function onChange() {
                console.log('onchange called!!!!!');
                if (selectList.selectedOptions[0].value != initialBillingName) {
                  let digitaIndicator ='';
                  cell.getElement().dataset.billing = selectList.selectedOptions[0].value;
                  for  (i=0; i< cellRealValue.length; i++){
                   if (cell.getElement().dataset.billing  == cellRealValue[i].finalName){
                    // if ( cellRealValue[i].type =='Invoice') {
                    //   digitaIndicator = cellRealValue[i].digitalItemInd && cellRealValue[i].digitalItemInd =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                    // }
                    let digAllowedText;
                    if(cellRealValue[i].type.toLowerCase() == 'invoice'){
                      digAllowedText =  cellRealValue[i].type + (cellRealValue[i].digitalItemInd && cellRealValue[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed');
                    } else{
                      digAllowedText = cellRealValue[i].type
                    }
                     cell.getRow().update({id:cellRealValue[i].value+' - $'+cellRealValue[i].lastBilledAmount});
                     cell.getRow().update({entity:cellRealValue[i].businessEntity});
                     cell.getRow().update({lastBill:cellRealValue[i].lastBilledAmount});
                     cell.getRow().update({type:cellRealValue[i].type});
                     cell.getRow().update({btype:digAllowedText});
                     // Updating Akita Store
                     //let res = {...that.indicatorValue}
                     let y = JSON.parse(JSON.stringify(that.indicatorValue));
                     y.items[cell.getRow().getData().itemId].model.BillingAccount = cellRealValue[i].value;
                     if(y["items"][cell.getRow().getData().itemId].order.actionCode == 'Renew') {
                      if(y.items[cell.getRow().getData().itemId].hasOwnProperty("hasCopysheet") ) {
                        y.items[cell.getRow().getData().itemId].model['renewHasCopysheet'] = y["items"][cell.getRow().getData().itemId]['hasCopysheet'];
                      }
                      y.items[cell.getRow().getData().itemId].model['digitalApiFlag'] = true;
                    }
                    let currentCount = y.items[cell.getRow().getData().itemId].counter
                    // let currentCount= JSON.parse(JSON.stringify(y.items[cell.getRow().getData().itemId].counter));
                     if (that.requiredfieldcount){
                        let tempData = that.requiredfieldcount.getNewItemCount(y.items[cell.getRow().getData().itemId].requiredFields, y.items[cell.getRow().getData().itemId].model, y.items[cell.getRow().getData().itemId].order, y.orderPercent, currentCount, true, y.items[cell.getRow().getData().itemId]);
                        that.updateIndictor(cell.getRow().getData().itemId, y, tempData, currentCount);
                     }
                    // let res1 = {...that.indicatorValue}
                     let res = {...y};
                     y = JSON.parse(JSON.stringify(res));
                     y.bundles.forEach((bundle, index, bundles) => {
                      bundle.children.forEach((child, childIndex, children) => {
                        let newIndicator;
                        if (bundle.parent == cell.getRow().getData().itemId && y.items[child] && y.items[bundle.parent].model) {
                          if (y.items[bundle.parent].model["BillingAccount"] ) {
                            //newIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                            y.items[child].model["BillingAccount"]  = cellRealValue[i].value;
                            //that.updateBilling(y);
                            let tempData = that.requiredfieldcount.getNewItemCount(y.items[cell.getRow().getData().itemId].requiredFields, y.items[cell.getRow().getData().itemId].model, y.items[cell.getRow().getData().itemId].order, y.orderPercent, currentCount, true, y.items[cell.getRow().getData().itemId]);
                            that.updateIndictor(cell.getRow().getData().itemId, y, tempData, currentCount);
                           // y.items[child].model["ListingId"]  = cellRealValue[i].value ;
                            //Update store
                            // this.indicatorQuery.updateModel(this['layoutNode'].name, value,  modelData, this.Indicator);
                          }
  
                         // updatedProfileId = this.watchHelper.setItemValuesFromBP(child, cellRealValue[i].value, updatedProfileId, "bp");
                          // if (y.items[bundle.parent].order.productCode === "DPM") {
                          //   y.items[bundle.parent].model.initialDataSet = true;
                          // }
                          let tabulatorData = cell.getTable().getRows();
                          for (let z = 0; z < tabulatorData.length; z++){
                            if(tabulatorData[z].getData().itemId == child){
                              tabulatorData[z].update({billingaccountid:cellRealValue[i].value});
                              //tabulatorData[z].update({bPListing:cellRealValue[i].name});
                              break;
                            }
                          }
  
                        }
                      })
                    })
                     that.indicatorValue = y;
                     that.counterService.setUpdate(true);
                     that.updateItemId(cell.getRow().getData().itemId);
                     that.updateData("item", cell.getRow().getData().itemId, cell.getRow().getData().version, that.indicatorValue.items[cell.getRow().getData().itemId].model);
                   }
                 }
                  if(cell.getRow().getData().itemId) {
                    let errorCount = that.indicatorQuery.getValue().indicator['items'][cell.getRow().getData().itemId].counter;
                    cell.getRow().update({counter: errorCount});
                  }
                  cell.setValue(selectList.selectedOptions[0].value,true);
                } else { // No change
                  //cancel();
                }
              }

              //submit new value on change
              selectList.addEventListener("change", onChange);
              function getSelectedBillingName(value,billingData){
                let digitaIndicator= '';
               try{
                for (let i=0; i<billingData.length; i++){
                    if (value == billingData[i].value){
                      initialBillingName = billingData[i].finalName;
                      cell.setValue(initialBillingName,true);
                      if ( billingData[i].type =='Invoice') {
                        digitaIndicator = billingData[i].digitalItemInd && billingData[i].digitalItemInd =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                      }
                      cell.getRow().update({id:billingData[i].value+' - $'+cellRealValue[i].lastBilledAmount});
                      cell.getRow().update({entity:billingData[i].businessEntity});
                      cell.getRow().update({lastBill:billingData[i].lastBilledAmount});
                      cell.getRow().update({type:billingData[i].type+''+digitaIndicator});
                      break;
                    }else if(value ==  billingData[i].finalName){
                      initialBillingName = billingData[i].finalName;
                      if ( billingData[i].type =='Invoice') {
                        digitaIndicator = billingData[i].digitalItemInd && billingData[i].digitalItemInd =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                      }
                      cell.getRow().update({id:billingData[i].value+' - $'+cellRealValue[i].lastBilledAmount});
                      cell.getRow().update({entity:billingData[i].businessEntity});
                      cell.getRow().update({lastBill:billingData[i].lastBilledAmount});
                      cell.getRow().update({type:billingData[i].type+''+digitaIndicator});
                      break;
                    }
                  }
                }catch (err){
                  console.log("Error in getSelectedBillingName:",err);
                }
              }

              if (cellRealValue && cellRealValue.length > 0){
                getSelectedBillingName(value,cellRealValue);
                for (var i=0; i< cellRealValue.length; i++){

                  if (i==0){
                    var option = document.createElement("option");
                    option.value = initialBillingName;//cellRealValue[value];
                    option.text = initialBillingName;//cellRealValue[value];
                    selectList.appendChild(option);
                    if (!initialBillingName || (initialBillingName != cellRealValue[i].finalName)){
                      var option = document.createElement("option");
                      option.value = cellRealValue[i].finalName;
                      option.text = cellRealValue[i].finalName;
                      selectList.appendChild(option);
                    }
                  }else{
                    if ((value !== cellRealValue[i].value) && (value !== cellRealValue[i].finalName)){
                      var option = document.createElement("option");
                      option.value = cellRealValue[i].finalName;
                      option.text = cellRealValue[i].finalName;
                      selectList.appendChild(option);
                    }
                  }
                }
                if(cell.getRow().getData().parentId){
                  for(let i = 0;i<selectList.length;i++){

                    if(selectList.options[i]['value'] == ""){
    
                      var indicator
                that.indicatorQuery.getIndicator().subscribe((res) => {
                  indicator = JSON.parse(JSON.stringify(res))
                });      
                      if(indicator.items[parentId].model.BillingAccount){
                        let digitaIndicator;
                        for(let i = 0; i<indicator.items[parentId].billingArray.length;i++){
                          if(indicator.items[parentId].billingArray[i].value == indicator.items[parentId].model.BillingAccount){
                            var bilname = indicator.items[parentId].billingArray[i].finalName;
                            if ( indicator.items[parentId].billingArray[i].type =='Invoice') {
                              digitaIndicator = indicator.items[parentId].billingArray[i].digitalItemInd && indicator.items[parentId].billingArray[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                            }
                            cell.getRow().update({id:indicator.items[parentId].billingArray[i].value+' - $'+indicator.items[parentId].billingArray[i].lastBilledAmount});
                            cell.getRow().update({entity:indicator.items[parentId].billingArray[i].businessEntity});
                            cell.getRow().update({lastBill:indicator.items[parentId].billingArray[i].lastBilledAmount});
                            cell.getRow().update({btype:indicator.items[parentId].billingArray[i].type+''+digitaIndicator});
                          }
                        }
                        selectList.options[i]['value'] = bilname;
                        selectList.options[i]['text'] = bilname;
                        
                      }
                      else{
                      selectList.options[i]['value'] = "N/A";
                      selectList.options[i]['text'] = "N/A"
                      }
                    }
                        else{
                      var indicator
                      that.indicatorQuery.getIndicator().subscribe((res) => {
                        indicator = JSON.parse(JSON.stringify(res))
                      });            
                      if(indicator.items[parentId].model.BillingAccount){
                        try{
                        let digitaIndicator;
                        for(let i = 0; i<indicator.items[parentId].billingArray.length;i++){
                          if(indicator.items[parentId].billingArray[i].value == indicator.items[parentId].model.BillingAccount){
                            var bilname = indicator.items[parentId].billingArray[i].finalName;
                            if ( indicator.items[parentId].billingArray[i].type =='Invoice') {
                              digitaIndicator = indicator.items[parentId].billingArray[i].digitalItemInd && indicator.items[parentId].billingArray[i].digitalItemInd.toLowerCase() =='y' ? '-Digital Allowed' : '-Digital Not Allowed';
                            }
                            cell.getRow().update({id:indicator.items[parentId].billingArray[i].value+' - $'+indicator.items[parentId].billingArray[i].lastBilledAmount});
                            cell.getRow().update({entity:indicator.items[parentId].billingArray[i].businessEntity});
                            cell.getRow().update({lastBill:indicator.items[parentId].billingArray[i].lastBilledAmount});
                            cell.getRow().update({btype:indicator.items[parentId].billingArray[i].type}+'-'+digitaIndicator);
                            break;
                          }
                        }
                        selectList.options[i]['value'] = bilname;
                        selectList.options[i]['text'] = bilname;
                      } catch(error){
                        console.log(error)
                   }
                    } 
                      // else{
                      // selectList.options[i]['value'] = "N/A";
                      // selectList.options[i]['text'] = "N/A"
                      // }
                    }
                  }
                }
              }
              else{
                if(cell.getRow().getData().parentId){
                  let option = document.createElement("option");
                  option.value = "N/A";;
                  option.text = "N/A";
                  selectList.appendChild(option);
                }
              }
                return selectList;
            },

            },
            {title: "BILLIING ID / LAST BILL", field: "id", width:'15%'},
            /* {title: "LAST BILL", field: "lastbill", width:116,}, */
            {title: "STATUS", field: "action", width:'6%'},
            {title: "ITEM ID", field: "itemId",width:'6%',},
            // {title: "Geography", field: "geography",width:135,}
          ]
    }

    private updateData(moduleName: string, id: string, version: string, model: any) {
      that.storageService.update(moduleName, id +'v'+ version, model);
    }

    private disabledCell(parentId: string, statusCode: number,readOnly: boolean,selectList: any, rowName: string,udac) {
      if(rowName === 'businessProfile' &&(statusCode >= 6000 || parentId !=null || readOnly || (udac && udac === 'SPPOW'))) {
        selectList.style.opacity =  '.3';
        selectList.style.cursor =  'not-allowed';
        selectList.style.pointerEvents = 'none';
        return selectList;
      } 
      // else if(rowName ==='billing' && statusCode >= 6000 ) {
      //   selectList.style.opacity =  '.3';
      //   selectList.style.cursor =  'not-allowed';
      //   selectList.style.pointerEvents = 'none';
      //   return selectList;
      // } 
      else if(rowName ==='billing' && (statusCode >= 6000 || parentId !=null || readOnly || (udac && udac === 'SPPOW')) ) {
        selectList.style.opacity =  '.3';
        selectList.style.cursor =  'not-allowed';
        selectList.style.pointerEvents = 'none';
        return selectList;
      }else {
        return;
      }
    }
    private updateItemId(itemId) {
      if(that.items && that.items.length > 0 ) {
        if(that.items.indexOf(itemId) === -1) {
          that.items.push(itemId);
        }
      } else {
        that.items.push(itemId);
      }
      that.items && that.items.length > 0 ? that.counterService.setItem(that.items) : '';
      if (that.showToastr){
        //shoeToastr
        // that.Logger.logError('You must SAVE the item before switching to another Tab/Window.');
        that.Logger.logItemSaveError('You must SAVE the item before switching to another Tab/Window.');
        // that.showToastr = false;
        $('#enableDisableLeftNavigationId').addClass('enable-disable-link');
        $('#oredrItemTabId').addClass('enable-disable-link');

      }
    }
    private updateIndictor(itemId, data,tempIndicator, currentCount) {
      let counter=[];
      data.orderPercent.currentCount = tempIndicator.tempCurrentCount ;
      data.items[itemId].counter = tempIndicator.itemCount;
      data.orderPercent = that.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount);
      let percentage = that.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
      data.orderPercent.percent = percentage;
      counter.push(data.orderPercent.digitalCount);
      counter.push(data.orderPercent.printCount);
      counter.push(data.orderPercent.bpTotalCount);
      that.counterService.setError(counter);
      that.indicatorStore.setLoading(true);
      that.indicatorStore.update(() => {
        return {
          indicator: data,
          updatedFrom: "[DigitalModel] updateIndictor",
          isloaded: true,
        };
      });
      that.indicatorStore.setLoading(false);
    }
    private updateBilling(data){
      that.indicatorStore.setLoading(true);
      that.indicatorStore.update(() => {
        return {
          indicator: data,
          updatedFrom: "[DigitalModel] updateIndictor",
          isloaded: true,
        };
      });
      that.indicatorStore.setLoading(false);
    }
    private getItemErrorCount(itemCount, orderPercent, currentCount) {
      let counter = 0;
      let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
      if (itemCount == 0 && currentCount != 0) {
        counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
        orderPercent= that.updateCounter( counter, orderPercent);
      } else if (itemCount != 0 && currentCount == 0) {
        counter = totalItemCount + 1;
        orderPercent= that.updateCounter( counter, orderPercent);
      }  else {
      //  counter = totalItemCount;
      }
      currentCount = counter;

      return orderPercent;
   }
   private updateCounter( counter, orderPercent) {
      orderPercent.digitalCount =   counter -  orderPercent.printCount;
    return orderPercent;
  }
}
