import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigsService {
  configs: any =  null;

  constructor(private http: HttpClient) {
    if(this.configs === null) {
      this.initConfigData();
    }
  }

  initConfigData() {
    this.configs = window["configs"];
    // this.http.get('/ng-app-configs')
    //   .subscribe(response => {
    //     this.configs = response;
    //   });
  }
}
