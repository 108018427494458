import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-callconfirmation',
  templateUrl: './callconfirmation.component.html',
  styleUrls: ['./callconfirmation.component.scss']
})
export class CallconfirmationComponent implements OnInit {
  content: any;

  constructor(private dialogRef: MatDialogRef<CallconfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private indicatorQuery: IndicatorQuery,private indicatorStore: IndicatorStore, private ngxSpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.ngxSpinnerService.hide();
    this.content = this.data.message;
  }
  onSubmit(){
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  close(){
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
