import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



@Component({
  selector: 'app-copysheetalert',
  templateUrl: './copysheetalert.component.html',
  styleUrls: ['./copysheetalert.component.scss']
})
export class CopysheetalertComponent implements OnInit {

  headingOk: any;
  headingCancel: any;

  constructor(private dialogRef: MatDialogRef<CopysheetalertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.headingOk = this.data.headingOk;
    this.headingCancel = this.data.headingCancel;
  }

  close(){
    this.dialogRef.close(false);
  }

  submit(){
    this.dialogRef.close(true);
  }



}
