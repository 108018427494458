
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { ComponentQuery } from 'app/state/component.query';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CampaignStore } from 'app/state/campaignstore';
import { statesService } from '@app/service/states/states.service';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit, OnDestroy {

  //Indicator: Quickbpclass = new Quickbpclass;
  Indicator;
  selectedState: String = '';
  id: any;
  options;
  isItemPage: boolean = false;
  isReadonly: boolean = false;
  campaignModel: CampaignClass
  isRequired: boolean = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private campaignQuery: CampaignQuery,
    private componentQuery: ComponentQuery,
    private campaignStore: CampaignStore,
    private cdr: ChangeDetectorRef,
    private states : statesService) { }
  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
      if (this.Indicator.items) {
        let items = Object.keys(this.Indicator.items);
        items.forEach((item, index) => {
          if (item == this.Indicator.stateParamsId) {
            this.isItemPage = true;
          }
        });
      }

     this.selectedState = this.isItemPage ? this.Indicator.items[this.Indicator.stateParamsId].model.BusinessAddressState : this.Indicator.profiles[this.Indicator.stateParamsId].model.BusinessAddressState;
     
      if (this.isItemPage) {
        this.selectedState = this.Indicator.items[this.Indicator.stateParamsId].model.BusinessAddressState
      } else {
        this.selectedState = this.Indicator.profiles[this.Indicator.stateParamsId].model.BusinessAddressState;
        this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
          let customField = '';
          if (data && !(data == "Yes" || data == "No")) {
            customField = data[2];
          }
          this.selectedState = customField ? customField : this.Indicator.profiles[this.Indicator.stateParamsId].model.BusinessAddressState;
        }, err => {
          console.log(err)
        }, () => {
          //  console.log('Completed Address1 update!!!!!')
        });

      }
      if (!this.Indicator.campaignFlag) {
        if (this.isItemPage) {
          this.isReadonly = (this['layoutNode'].name && this.Indicator.stateParamsId && this.Indicator.items[this.Indicator.stateParamsId] && this.Indicator.items[this.Indicator.stateParamsId].schema && this.Indicator.items[this.Indicator.stateParamsId].schema.properties && this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name]) ? this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name].readonly : false;
        } else {
          this.isReadonly = this.Indicator.profiles[this.Indicator.stateParamsId].schema[0].properties[this['layoutNode'].name].readonly;
        }
        this.isRequired = true;
      } else {
        this.isRequired = false;
        this.isReadonly = true;
      }

    

  })

    this.options = this['layoutNode'].options;
    this.id = this['layoutNode'].name
    if (this['layoutNode'].name === 'CampaignAddressState') {
      this.isRequired = true;
      this.campaignQuery.getCampaign().subscribe(data => {
        this.campaignModel = { ...data }
      })
      this.isReadonly = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].readonly
      this.options = this['layoutNode'].options

      this.selectedState = (this.campaignModel.model.BPText) ? this.campaignModel.model.State : this.campaignModel.model.CampaignAddressState
      // if(this.selectedState && this.campaignModel.model.ListingId){
      //   this.isReadonly = true;
      // } else {
      //   this.isReadonly = false;
      // }
      // if(this.campaignModel.selectedCampaign && this.campaignModel.selectedCampaign.isCampaignFromVision && this.campaignModel.model['isSelectedEmpty']) {
      //   this.selectedState = this.campaignModel.selectedCampaign.CampaignAddressState;
      //   this.isReadonly = true;
      // } 
      if (this.campaignModel.selectedCampaign &&
        this.campaignModel.selectedCampaign['modelClicktypes'] &&
        this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit') {
        if (this.campaignModel.model['CampaignId']) {
          this.isReadonly = true;
        } else {
          if (this.campaignModel.model.ListingId) {
            this.isReadonly = true;
          } else {
            this.isReadonly = false;
          }
        }
      } else if (this.campaignModel.selectedCampaign &&
        this.campaignModel.selectedCampaign['modelClicktypes'] &&
        this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy') {
        // this.isReadonly = false;
        if (this.campaignModel.model.ListingId) {
          this.isReadonly = true;
        } else {
          this.isReadonly = false;
        }
      } else {
        if (this.campaignModel.model.ListingId) {
          this.isReadonly = true;
        } else {
          this.isReadonly = false;
        }
      }
    }
  }

  // async loadStates() {
  //   const country = this.Indicator.account.address.country;
  //   const response = await this.states.getStates(country);
  //   if (response && response.territories) {
  //     // Assign the retrieved states to options
  //     this.options = response.territories;
  //   } else {
  //     console.error("Error: Error in getting States/Territories");
  //   }
  // }
  sendValue(args) {
    if (this['layoutNode'].name != 'CampaignAddressState') {
      //let  tempObj =JSON.parse(JSON.stringify(this.Indicator));
      let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.Indicator.stateParamsId].model));
      if (this.isItemPage) {
        modelData.BusinessAddressState = args;
      } else {
        modelData.BusinessAddressState = args;
      }
      let x = JSON.parse(JSON.stringify(this.Indicator));
      x['profiles'][this.Indicator.stateParamsId].model = modelData;

      this.indicatorStore.update(state => {
        return {
          indicator: x,
          updatedFrom: "[StateComponent] sendvalue",
          isloaded: true,
        };
      })
    }
    if (this['layoutNode'].name == 'CampaignAddressState' && this.campaignModel.campaignFlag) {
      let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
      modelData.CampaignAddressState = args;

      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[StateComponent] sendvalue'
        }
      })
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}