import { Injectable } from '@angular/core';
import { ToastrService, Toast} from 'ngx-toastr';
import { findIndex, take } from 'rxjs/operators';
import { IndicatorQuery } from 'app/state/query';
import { Router } from '@angular/router';
import  * as $ from 'jquery';
import { IndicatorStore } from 'app/state/store';
@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    sharedData : any;

    ToastConfig = {
        enableHtml: true
    }
    annotConfig = {
        timeOut: 3000,
        enableHtml: true,
        closeButton: true,
        positionClass: 'toast-top-center',
        toastClass: 'ngx-toastr toast-show annot-class'
    }
    ToastErrorConfig = {
        enableHtml: true,
        closeButton: true,
        disableTimeOut: true
    }
    fileConfig = {
        disableTimeOut: true,
        enableHtml: true,
        closeButton: true,
        positionClass: 'toast-center-center',
        toastClass: 'ngx-toastr toast-show file-class'
    }
    infoConfig = {
        timeOut: 20,
        extendedTimeOut: 0,
        enableHtml: true,
        closeButton: true,
        disableTimeOut: true
    }
    ToastSuccessConfig = {
        timeOut: 5000,
        enableHtml: true,
        closeButton: true,
    }
    ItemSaveErrorConfig = {
        timeOut: 0,
        enableHtml: true,
        closeButton: true,
        disableTimeOut: true,
        titleClass: 'toast-r',
    }

    indicatorValue: any;
    toastRef;
    toasterOverLayContainer;
    constructor(private toastr: ToastrService,
        private indicatorQuery: IndicatorQuery,
        private router: Router,
        private indicatorStore: IndicatorStore,
        ) { }

        clearErrorToast(){
            document.querySelectorAll('.toast-error').forEach(a => {
                console.log(a);
                a.remove();
            });
        }

        logItemSaveError(message: string) {
            this.toastr.error(message, '', this.ItemSaveErrorConfig);
        }

    SEMToastContainerInit(toastContainer){
        // this.toasterOverLayContainer = toastContainer;
        this.toastr.toastrConfig.positionClass = 'inline';
        this.toastr.overlayContainer = toastContainer;
    }

    SEMToastContainerDestroy(){
        this.toastr.toastrConfig.positionClass = 'toast-top-right';
        // this.toastr.overlayContainer = undefined;
        if(this.toastr.overlayContainer){
            delete this.toastr.overlayContainer;
        }
    }
    
    log(message: string) {
        return this.toastr.info(message, '', this.infoConfig);
    }

    logWarning(message: string) {
        return this.toastr.warning(message, '', this.ToastErrorConfig);
    }

    timedLogWarning(message: string) {
        return this.toastr.warning(message, '', this.ToastConfig);
    }

    logSuccessPublish(message: string) {
        this.ToastConfig['timeOut'] = 15000;
        return this.toastr.success(message, '', this.ToastConfig);
    }

    logSuccess(message: string) {
        return this.toastr.success(message, '', this.ToastConfig);
    }
    logItemSuccess(message: string) {
        this.toastr.toastrConfig.preventDuplicates = true;
       return this.toastr.success(message, '', this.ToastSuccessConfig);
    }

    logError(message: string) {
        return this.toastr.error(message, '', this.ToastErrorConfig);
    }
    logCopyBPError(message: string) {
        this.toastr.toastrConfig.timeOut = 5000;
        return this.toastr.error(message, '', this.ToastConfig);
    }

    logmultiItemError(message:string, current,transactionId){
       this.toastRef = this.toastr.error(message, '',
       {enableHtml: true,
        timeOut: 0,
        extendedTimeOut: 0,
        closeButton: true,
        })
          .onTap
          //.pipe(take(2))
          .subscribe(() => this.toasterClickedHandler(current,transactionId),err=>{
              console.log("error in logger service",err);
          });
      }

      toasterClickedHandler(current,transactionId) {
        this.indicatorQuery.getIndicator().subscribe((res) => {
                        this.indicatorValue = { ...res };
                        this.indicatorValue.errArray.forEach((element,index) => {
                            if(element.transactionId == transactionId){
                                this.toastr.clear();

                            }
                            let id = current;
                            //console.log("Inside  Logger onclick",current);
                            this.router.navigate(['/orderitems/item/'+id])
                        });
      })
    }
    logFormattedError(message: string) {
        // Replace newline characters with HTML line break tags
        message = message.replace(/\n/g, '<br/>');
        return this.toastr.error(message, '', this.ToastErrorConfig);
    }
    
    logAllErrorMsg(message) {
        this.toastr.info(message, '',{
            closeButton: true,
            timeOut: 0,
            extendedTimeOut: 0,
            positionClass: 'toast-top-center',
            enableHtml: true
        })

    }
    filewarning(message: string) {
        return this.toastr.show(message, '', this.fileConfig);
    }
    clearAll()
    {
        this.toastr.clear();
    }
    annotwarning(message:string){
        return this.toastr.show(message, '', this.annotConfig);  

    }
}



