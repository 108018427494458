import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Location } from "@angular/common";
import { ApiService } from '@app/service/api/api.service';

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public sideBarOpen = false;
  public sideOpen = true;
  displayFlag: boolean = false;
  sharedData: any;
  locked;
  userName;
  environment;
  appVersion = 2.0;
  deploymentDate = new Date().toJSON().slice(0,10);
  storageMode: any = null;
  constructor( private params: ParamsService,
     private indicatorQuery: IndicatorQuery,
     private cdr: ChangeDetectorRef,
     private indicatorStore: IndicatorStore,
     private API:ApiService,
     private location: Location) {}

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      console.log("shareddata-->",this.sharedData);
      if(this.sharedData && this.sharedData.user){
        this.userName = this.sharedData.user.name;
      }else{
        this.userName ='';
      }
      if(this.sharedData && this.sharedData.params && this.sharedData.params.api && this.sharedData.params.api.split("-")){
        if(this.sharedData.params.api.split("-")[1]){
          this.environment = this.sharedData.params.api.split("-")[1].split(".")[0];
        }else{
          this.environment = "Production";
        }
      }else{
        this.environment = "Production";
      }
    
      if (this.sharedData && this.sharedData.user) {
        this.deploymentDate = this.sharedData.user.deploymentDate;
      } else {
        console.error('this.sharedData or this.sharedData.user is undefined.');
      }

    });
     // new window to show the user  details  
     if(window['parentIndicator']){
      var apiUrl = this.API.apiUrl.replace(/(\/)/g, "%2F")
      if (this.location.path() == "/pdf?api=" + apiUrl + "&account="+ window['parentIndicator'].account.id  +"&order=" + window['parentIndicator'].order.sfdcOrderId+"&mode=copysheet") {
        this.userName = window['parentIndicator'].user.name;
        this.environment = window['parentIndicator'].params.api.split("-") ? window['parentIndicator'].params.api.split("-")[1].split(".")[0] : "Production";
        this.deploymentDate = window['parentIndicator'].user.deploymentDate;
      }
     }
     
    if(this.sharedData && !this.sharedData['leftNavigation']) {
      this.sharedData['leftNavigation'] = this.sideBarOpen;
      this.updateStore(this.sharedData)
    }
    this.sideBarOpen = this.sharedData ? this.sharedData['leftNavigation']  : false;
    this.locked = sessionStorage.getItem("locked")?sessionStorage.getItem("locked"):false;
    console.log("Location in main component",this.location.path());
    if(this.location.path().indexOf('mode=ManageBP') > 0){
      this.storageMode = 'ManageBP'
    }else{
      this.storageMode = sessionStorage.getItem('mode') ? sessionStorage.getItem('mode') : "null" ;
    }
    
    console.log('this.storageMode main', this.storageMode);

    // if (this.params.params.mode == 'QuickBP' || this.params.params.mode == 'listing')
    if (this.storageMode == 'QuickBP' || this.storageMode == 'listing' || this.storageMode == 'copysheet')
      this.displayFlag = false;
    else
    this.displayFlag = true;
  }
  ngAfterViewInit() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }

  sideBarToggler() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));

    });
    this.sharedData ['leftNavigation']= !this.sharedData ['leftNavigation'];
    this.sideBarOpen = this.sharedData ['leftNavigation'];
    this.updateStore(this.sharedData)
  }
  updateStore(sharedData) {
    this.indicatorStore.update(() => {
      return {
        indicator: sharedData,
        updatedFrom: "[MainComponent] updatStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);
  }
  mouseOver(){

  }
  mouseOut(){

  }
}
