import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { FileService } from '@app/service/file/file.service';
import { IndicatorQuery } from 'app/state/query';
//import { PDFJS } from './pdf';
//import { PDFJS } from 'pdfjs-dist';
import * as PDFJS from '../../../../app/core/pdfannoatate/js/pdf';
import { jsPDF } from "jspdf";
import { ApiService } from '@app/service/api/api.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from '@app/service/logger/logger.service';


@Component({
  selector: 'app-copysheet-thumbnail',
  templateUrl: './copysheet-thumbnail.component.html',
  styleUrls: ['./copysheet-thumbnail.component.scss']
})
export class CopysheetThumbnailComponent implements OnInit {
  Indicator: any;
  @Input() itemId: string;
  @Input() copysheetUrl: string;
  @ViewChild('thumbnail', { static: true }) thumbnail: ElementRef<HTMLCanvasElement>;
  @ViewChild('thumbnailZoom', { static: true }) thumbnailZoom: ElementRef<HTMLCanvasElement>;
  canvas: any;
  context: any;
  canvasZoom: any;
  contextZoom: any;
  zoomThumbnail: boolean;

  @Input() index: any;
  isIndex: any;

  constructor(private fileService: FileService,
    private indicatorQuery: IndicatorQuery,
    private API: ApiService,
    private storageService: StorageService,
    private indicatorStore: IndicatorStore,
    private spinnerService: NgxSpinnerService,
    private loggerService: LoggerService) {
  }

  ngOnInit() {
    if (this.index == 0) {
      this.isIndex = 'right';
    } else
      if (this.index == 1 || this.index == 2) {
        this.isIndex = 'center';
      } else {
        this.isIndex = 'left';
      }

    this.zoomThumbnail = false;
    this.spinnerService.show();
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
      this.canvas = this.thumbnail.nativeElement;
      this.context = this.canvas.getContext('2d');
      this.canvasZoom = this.thumbnailZoom.nativeElement;
      this.contextZoom = this.canvasZoom.getContext('2d');
      this.loadPDF();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes && changes['copysheetUrl'] && changes['copysheetUrl']['previousValue']) {
      this.copysheetUrl = changes['copysheetUrl']['currentValue']
      console.log("copysheet", this.copysheetUrl)
      this.loadPDF();
    }
  }
  // Indicator = Indicator;
  async loadPDF() {
    //PDFJS.GlobalWorkerOptions.workerSrc = "pdf.worker.js";
    PDFJS.workerSrc = "pdf.worker.js";
    try {
      if (PDFJS) {
        await PDFJS.getDocument(this.copysheetUrl).then((_pdfDoc) => {
          let pdfDoc = _pdfDoc;
          //console.warn("pdf doc is",pdfDoc);
          pdfDoc.getPage(1).then((page) => {
            var scale = 1;
            var viewport = page.getViewport(scale);
            //
            // Prepare canvas using PDF page dimensions
            //
            // var canvas = <HTMLCanvasElement> document.getElementById('thumbnail');
            // var context = canvas.getContext('2d');
            this.canvas.height = viewport.height;//128;
            this.canvas.width = viewport.width;//128

            this.canvasZoom.height = viewport.height;//128;
            this.canvasZoom.width = viewport.width;//128


            //
            // Render PDF page into canvas context
            //
            var renderContext = {
              canvasContext: this.context,
              viewport: viewport
            };
            let renderContextZoom = {
              canvasContext: this.contextZoom,
              viewport: viewport
            }
            page.render(renderContextZoom);
            // .promise.then(()=>{
            //   console.log('resolved promise render context zoom');
            // });
            this.indicatorQuery.getIndicator().subscribe((res) => {
              this.Indicator = JSON.parse(JSON.stringify(res))
          });
            var task = page.render(renderContext);
            let storageID = this.itemId + "v" + this.Indicator.items[this.itemId].order.version;
            task.promise.then(() => {  
              this.indicatorQuery.getIndicator().subscribe((res) => {
                this.Indicator = JSON.parse(JSON.stringify(res));
                })            
              this.fileService.saveImage(this.itemId, { 'data': this.canvas.toDataURL('image/png') }, "thumbnail").subscribe((response: any) => {
                console.timeEnd("generateThumbnail");
                console.log('storage id is', storageID);
                console.log('response**************', response);
                console.log('response**************', response.filename);
                console.log('response**************', response._id);
                this.Indicator.items[this.itemId].thumbnail = {
                  "urlThumbnail": this.API.get('/api/file/' + response._id + '/' + response.filename),
                  "srcCopysheet": this.Indicator.items[this.itemId].copy.copysheet
                };

                this.indicatorStore.update(() => {
                  return {
                    indicator: this.Indicator,
                    updatedFrom: "[CopysheetThumbnailComponent] loadPDF",
                    isloaded: true,
                  };
                });
                this.storageService.updateField("copyz", storageID, {
                  "data.thumbnail": {
                    "urlThumbnail": this.API.get('/api/file/' + response._id + '/' + response.filename),
                    "srcCopysheet": this.Indicator.items[this.itemId].copy.copysheet
                  }
                }).subscribe(res => {
                  this.spinnerService.hide();
                });
              },
              (err)=>{
                console.log('Thumbnail call failed :: ', err);
              })                
            });
          });
        });
      }
    } catch (e) {
      this.spinnerService.hide();
      this.loggerService.logError(e.message + ' for ItemId ' + this.itemId);
    }
  };

}
