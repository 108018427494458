import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Input, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { FileService } from '@app/service/file/file.service';
import { IndicatorQuery } from 'app/state/query';
import * as PDFJS from '../../../../app/core/pdfannoatate/js/pdf';
import { jsPDF } from "jspdf";
import { ApiService } from '@app/service/api/api.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from '@app/service/logger/logger.service';

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss']
})
export class HistoryDialogComponent implements OnInit {
  content: any;
  copySheetType: any;
  Indicator:any;
  @ViewChild('thumbnail', { static: true }) thumbnail: ElementRef<HTMLCanvasElement>;
   @ViewChild('thumbnailZoom', { static: true }) thumbnailZoom: ElementRef<HTMLCanvasElement>;
  canvas : any;
  context : any;
  canvasZoom : any;
  contextZoom : any;
  zoomThumbnail : boolean
  @Input() index:any;
  isIndex: any;
  base64data:any;
  base64:any;
  copySheetHeader:any;

  constructor(private dialogRef: MatDialogRef<HistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngxSpinnerService: NgxSpinnerService,
    private fileService: FileService,
    private API:ApiService,
    private indicatorQuery:IndicatorQuery,
    private storageService:StorageService,
    private indicatorStore: IndicatorStore,
    private spinnerService: NgxSpinnerService,
    private loggerService: LoggerService,
   ) { }


   ngOnInit() {
     this.ngxSpinnerService.hide();
     this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
    this.content = this.data.message;
    this.copySheetType = this.data.type;         
    if(this.index == 0){
      this.isIndex = 'right';
    }else 
    if(this.index == 1 || this.index == 2){
      this.isIndex = 'center';
    }else{
      this.isIndex = 'left';
    }
    this.zoomThumbnail = false;
    this.spinnerService.show();
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
      this.canvas =  this.thumbnail.nativeElement;
      this.context = this.canvas.getContext('2d');
       this.canvasZoom =  this.thumbnailZoom.nativeElement;
      this.contextZoom = this.canvasZoom.getContext('2d');
       this.loadPDF();
    })
    
  }

  onSubmit(){
   
    this.dialogRef.close(true);
  }

  close(){
   
    this.dialogRef.close(false);
  }

  

  async loadPDF() {
    //PDFJS.GlobalWorkerOptions.workerSrc = "pdf.worker.js";
    PDFJS.workerSrc = "pdf.worker.js";
    // try {
      if (PDFJS){
        let copysheetType;
        if(this.copySheetType=='baseCopySheet'){
          copysheetType = this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet;
          this.copySheetHeader ='Base CopySheet';
        }
        else {
          copysheetType = this.Indicator.items[this.Indicator.stateParamsId].copy.previousCopySheet;
          this.copySheetHeader ='Previous Version';
        }
        await PDFJS.getDocument(copysheetType).then((_pdfDoc)=> {
          let pdfDoc = _pdfDoc;
          //console.warn("pdf doc is",pdfDoc);
          pdfDoc.getPage(1).then((page)=> {
            var scale = 1;
            var viewport = page.getViewport(scale);
            this.canvas.height = viewport.height;//128;
            this.canvas.width = viewport.width;//128

            this.canvasZoom.height = viewport.height;//128;
            this.canvasZoom.width = viewport.width;//128

            // Render PDF page into canvas contex
            var renderContext = {
              canvasContext: this.context,
              viewport: viewport
            };
            let renderContextZoom = {
              canvasContext: this.contextZoom,
              viewport: viewport
            }
            page.render(renderContextZoom);

             page.render(renderContext);
            
          });
        });
      }
    // } catch(e) {
    //   this.spinnerService.hide();
    //   this.loggerService.logError(e.message +' for ItemId '+this.itemId);
    // }
  };


}
