import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';
@Injectable({
  providedIn: 'root'
})
export class AdService {
  baseUri1: string = '/api/copyimage';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  params = new HttpParams();
  constructor(private http: HttpClient,
    private Params: ParamsService,
    private API: ApiService,
  ) { }

  getAdUrl(id: any) {
    return this.http.get(this.API.get(this.baseUri1) + "/file/" + id + "?accessToken=" + sessionStorage.getItem("oauthToken"))
    //return API.get(vm.PATH) + "/file/" + id + "?accessToken=" + Params.token;
  };

  async getAd(adId, adPrefix, graphicRegion) {
    // return this.API.get(this.baseUri1) + "/" +  adId + "/" + adPrefix + "/" + graphicRegion + "?accessToken=" + sessionStorage.getItem("oauthToken");
    // console.log("From ad service :: ", this.API.get(this.baseUri1) + "/" + adId + "/" + adPrefix + "/" + graphicRegion);
    return new Promise(async (resolve, reject)=>{
      await this.http.get(this.API.get(this.baseUri1) + "/" + adId + "/" + adPrefix + "/" + graphicRegion,{observe: 'response', responseType: 'blob'}).toPromise().then((response)=>{
        // console.log('From  ad.service.ts promise ::', response);      
        resolve(response);
      }).catch(err=>{
        console.log(err);
        reject(err);
      })
    })    
    // return this.API.get(this.baseUri1) + "/" + adId + "/" + adPrefix + "/" + graphicRegion;
  };
  getAdSet(adId, adPrefix, graphicRegion) {
    return this.API.get(this.baseUri1) + "/" +  adId + "/" + adPrefix + "/" + graphicRegion;
  };
  getSetbasedImage(imageUrl){
    return this.http.get(imageUrl,{observe: 'response', responseType: 'blob'});
   
   }
  getItemUrl(id): Observable<any> {
    return this.http.get(this.API.get(this.baseUri1) + "/" + "/file/" + id)
    //return $http.get(API.get(vm.PATH) + "/file/" + id);
  };

  getItems(id, page?): Observable<any> {
    if (page) {
      this.params = this.params.append('page', page);
      return this.http.get(this.API.get(this.baseUri1) + "/" + id, { params: this.params });
    } else {
      return this.http.get(this.API.get(this.baseUri1) + "/" + id)
    }

    //return $http.get(API.get(vm.PATH) + "/" + id, { cache : true });
  };
}
