import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BillingnewComponent } from '../billing-new/billingnew/billingnew.component';
import { Observable, of, Subject } from 'rxjs';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorQuery } from 'app/state/query';
import { StorageService } from '@app/service/storage/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@app/service/datasource/constants';
import { IagroupComponent } from 'app/shared/components/iagroup/iagroup.component';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private activeTab = new BehaviorSubject<string>('0');
  private activeView = new BehaviorSubject<string>('list');
  private activeData = new BehaviorSubject<boolean>(false);
  private copySheetBool = new BehaviorSubject<boolean>(false);

  billingForm: boolean=false;
  SpinnerText;
  tabName = this.activeTab.asObservable();
  viewName = this.activeView.asObservable();
  availableData = this.activeData.asObservable();
  copySheetBoolValue= this.copySheetBool.asObservable();
  private subject = new Subject<any | null>();
  private itemIds = new Subject<string[] | null>();
  private history = new BehaviorSubject<string>('')
  //history:string;
  IA_history:string;
  constructor(private matDialog: MatDialog,
    private Logger: LoggerService,
    private indicatorQuery: IndicatorQuery,
    private spinnerService: NgxSpinnerService,
    private storageService: StorageService,
    private spinnerTextervice: SpinnerService,
    private constant:Constants) { }

  setCalltracking(counter: any) {
      this.subject.next(counter);
  }

  getCalltracking(): Observable<any> {
      return this.subject.asObservable();
  }

  setSeletedItems(svItemId: string[]) {
    this.itemIds.next(svItemId);
  }

  getSeletedItems(): Observable<string[]> {
    return this.itemIds.asObservable();
  }

  changeTab(tabName: string) {
    this.activeTab.next(tabName);
  }
  changeView(viewName: string){
    this.activeView.next(viewName);
  }
  isData(availableData: boolean){
    this.activeData.next(availableData);
  }
  setCopySheetPageValue(copySheetBoolValue: boolean){
    this.copySheetBool.next(copySheetBoolValue);
  }


   matDialogFunction(billingType, productType,page?): Observable<any>{
    let title = billingType? 'Update':'Create';
    let data = billingType? billingType:null;
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: title,
      dialogform : data,
      productType: productType,

    }
   if(page) dialogConfig.data['page'] = page;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'billing-modal';
    //dialogConfig.height = '450px';
    const dialogRef = this.matDialog.open(BillingnewComponent,dialogConfig); //, { disableClose: true }
    return dialogRef.afterClosed();
  }

  openChangeHistoryDialog(chnageHistoryName,componentName): any {
    let indicator;
    let sharedData;
    let data;
    let storageName = chnageHistoryName;
    let bpModel = false;
    let itemStatusFlag = false;
    let id = '';
    this.indicatorQuery.getIndicator().subscribe((res) => {
      indicator = { ...res };
      sharedData = JSON.parse(JSON.stringify(res));
    });
    if (storageName == 'publishHistory') {
      bpModel = false;
      itemStatusFlag = false;
      id = sharedData.order.orderId;
    } else if (storageName == 'itemHistory') {
      this.SpinnerText = '';
      bpModel = false;
      itemStatusFlag = true;
      id = sharedData.stateParamsId;
    } else if (storageName == 'profileHistory') {
      bpModel = true;
      itemStatusFlag = false;
      id = sharedData.stateParamsId ? sharedData.stateParamsId : '0';
    }
    this.spinnerTextervice.setSpinnerText('');
    this.spinnerService.show();
    this.storageService.get(storageName, id).then(historyData => {
      let tableData = historyData[id];
      if (tableData && tableData.length > 0) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          chnageHistoryName: chnageHistoryName,
          storageDate: tableData,
          bpModel:bpModel,
          itemStatusFlag:itemStatusFlag
        }
        dialogConfig.width = '1300px';
        dialogConfig.panelClass = 'history-modal'
        const dialogRef = this.matDialog.open(componentName, dialogConfig);
        this.spinnerService.hide();
         dialogRef.afterClosed().subscribe(() => {
          //console.log('Dialog is closed');
         });
      } else {
        this.Logger.logError('No History Data Available!!!');
        this.spinnerService.hide();
      }
    });
  }
  openIADialogbox(itemId):any{
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          svItemId: itemId,
          footerFlag: true
        }
        dialogConfig.width = '1300px';
        dialogConfig.panelClass = 'ia-group-modal'
        const dialogRef = this.matDialog.open(IagroupComponent,dialogConfig);
         dialogRef.afterClosed().subscribe(() => {
          //console.log('Dialog is closed');
         });
  }
  sethistoryPage(history: string) {
    this.history.next(history);
  }

  gethistoryPage(){
    return this.history.value
  }
}
