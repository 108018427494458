
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MAT_DIALOG_DATA } from '@angular/material';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { ComponentQuery } from 'app/state/component.query';
import { Subject } from 'rxjs';
import { statesService } from '@app/service/states/states.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-billingstate',
  templateUrl: './billingstate.component.html',
  styleUrls: ['./billingstate.component.scss']
})
export class BillingstateComponent implements OnInit, OnDestroy {
  options: any;
  id: any;
  sharedData;
  selectedState = '';
  titleContent: any;
  destroySubject$: Subject<void> = new Subject();
  country: any;

  constructor(
    private indicatorQuery: IndicatorQuery, 
    private indicatorStore: IndicatorStore, 
    private states: statesService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private componentQuery: ComponentQuery) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = { ...data };
      this.titleContent = "State / Subdivision";
      // Load states and then set selectedState
      this.loadStates().then(() => {
      if (this.data.title === "Update") {
        this.selectedState = this.data.dialogform._cell.row.data.state;
      } else {
        this.selectedState = this.sharedData.account.address.state;
      }
      console.log("selectedState", this.selectedState);
      this.cdr.detectChanges();
    });
      this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
        let customField = '';
        if (data && !(data == "Yes" || data == "No")) {
          customField = data[2];
        }
        this.selectedState = customField ? customField : this.sharedData.account.address.state;
      }, err => {
        console.log(err)
      }, () => {
        //  console.log('Completed Address1 update!!!!!')
      });

    })
    this.id = this['layoutNode'].name
  }

  async loadStates() {
    const country = this.sharedData.account.address.country;
    const response = await this.states.getStates(country);
    if (response && response.territories) {
      // Assign the retrieved states to options
      this.options = response.territories;
    } else {
      console.error("Error: Error in getting States/Territories");
    }
  }

  sendValue(args) {
    let x;
    this.indicatorQuery.getIndicator().subscribe(data => {
      x = { ...data };
      x.account.address.state = args;
      this.indicatorStore.update(state => {
        return {
          indicator: x,
          updatedFrom: "[BillingstateComponent] sendValue",
          isloaded: true,
        };
      })
    })
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }

}