import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Observer } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SalesforceService {
  submitAction: any;

  constructor(private http: HttpClient, private API: ApiService, private Params: ParamsService, private spinnerService: NgxSpinnerService) { }
  baseUri: string = "/api/salesforce";
  baseUri1: string = "/api/salesforce1/rest";
  headers = new HttpHeaders();

  itemOrder = "OrderItem";
  getValue(fieldName: any, objectName: any, filterName: any, filterValue: any) {

    let soql = "select " + fieldName + " from " + objectName +
      " where " + filterName + "=" + filterValue;
    return this.http.get(this.API.get(this.baseUri) + '/' + soql);
  }

  async deleteBPitems(listingId: any, accountid: any, acctRecordId: any): Promise<any> {
    /*
    let soql = "SELECT COUNT() from Orderitem where  Order__r.Account__r.SV_Account_Id__c = '" + accountid + "' and Listing_Id__c = '" + listingId + "'and Order__r.IsActive__c = true AND (Status_Code__c = 6000 OR Status_Code__c = 7000) AND Active__c = true AND Product__r.Product_Family__c!= 'Print'";
    return this.http.get(this.API.get(this.baseUri) + '/' + soql).toPromise();
    */
    
    let countOfItems: number = 0;
    try {      
      let AcctParamsetter = new HttpParams();
      AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + acctRecordId + '/Orders');
      AcctParamsetter = AcctParamsetter.append('fields', 'Id,IsActive__c');      
      let result = await this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise()      
      if (result['totalSize'] > 0 && result['records'] && result['records'].length > 0) {
        let Orders = result['records'];
        for (let Order of Orders) {          
          if (Order['IsActive__c'] == true) {
            let OrderRecordId = Order['Id']
            let OrderParamsetter = new HttpParams();
            OrderParamsetter = OrderParamsetter.append('urlsuffix', '/Order/' + OrderRecordId + '/Orderitems');
            OrderParamsetter = OrderParamsetter.append('fields', 'Listing_Id__c,Status_Code__c,Active__c,Product_Family__c,Item_ID__c');            
            let OrderItemResults = await this.http.get(this.API.get(this.baseUri1), { params: OrderParamsetter }).toPromise()            
            for (let OrderItem of OrderItemResults['records']) {              
              if (OrderItem['Listing_Id__c'] == listingId && (OrderItem['Status_Code__c'] == '6000' || OrderItem['Status_Code__c'] == '7000') && OrderItem['Active__c'] == true && OrderItem['Product_Family__c'] != 'Print') {
                countOfItems = countOfItems + 1;                
              }
            }
          }
        }
      }
      return new Promise((resolve) => {
        resolve(countOfItems);
      })
    }
    catch (exception) {
      return new Promise((resolve) => {
        resolve(countOfItems);
      })
    }
  }

  async deleteBPassets(listingId: any, accountid: any, acctRecordId: any): Promise<any> {
    /*
    let soql = "SELECT COUNT() FROM Asset WHERE Account.SV_Account_Id__c ='" + accountid + "' AND Listing_Id__c ='" + listingId + "' AND Status != 'Cancelled' AND Pending_Status__c != 'Cancelled' AND Product_Family__c!= 'Print'";
    return this.http.get(this.API.get(this.baseUri) + '/' + soql).toPromise();
    */    
    let CountOfAssets: number = 0;
    try {
      let AcctParamsetter = new HttpParams();
      AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + acctRecordId + '/Assets');
      AcctParamsetter = AcctParamsetter.append('fields', 'Listing_Id__c,Status,Pending_Status__c,Product_Family__c,Item_ID__c');      
      let result = await this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise()      
      if (result['totalSize'] > 0 && result['records'] && result['records'].length > 0) {
        //let assets = result['records'];
        for (let asset of result['records']) {           
          if (asset['Listing_Id__c'] == listingId && asset['Status'] != 'Cancelled' && asset['Pending_Status__c'] != 'Cancelled' && asset['Product_Family__c'] != 'Print') {
            CountOfAssets = CountOfAssets + 1;            
          }
        }
      }
      return new Promise((resolve) => {
        resolve(CountOfAssets);
      });
    } catch (exception) {
      return new Promise((resolve) => {
        resolve(CountOfAssets);
      })
    }
  }

  contactBrm(accountid: any) {
    let soql = "SELECT Id, First_Name__c,Last_Name__c,email,MailingAddress,Do_Not_Mail__c, (SELECT id,Contact_Account__c, ebill_Notify__c,Billing_Account_Id__c  FROM Billing_contact__r ),(select id, Type__c from Contact_Roles__r) FROM contact WHERE Account.SV_Account_Id__c ='" + accountid + "'"; //"+accountid+"
    return this.http.get(this.API.get(this.baseUri) + '/' + soql);
  }

  contactBrmUpdate(accountid: any, eid: any) {
    let soql = "SELECT Contact__c FROM Contact_Role__c WHERE Billing_Account__c = '" + accountid + "'and Contact_Account__r.SV_Account_Id__c='" + eid + "'";
    return this.http.get(this.API.get(this.baseUri) + '/' + soql);
  }
  /**
   * Get primary contact email.
   * US35188
   */
  getPrimaryContact(accountid: any) {
    /*
    let soql = "SELECT Email FROM Contact where accountid = '" + accountid + "' AND primary__c = true";    
    return this.http.get(this.API.get(this.baseUri) + "/" + soql).toPromise();    
    */
    let AcctParamsetter = new HttpParams();
    AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + accountid);
    AcctParamsetter = AcctParamsetter.append('fields', 'Primary_Contact_Reference__c');
    return this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise().then((result) => {
      let primaryContactReference = result['Primary_Contact_Reference__c'];
      let ContactParamsetter = new HttpParams();
      ContactParamsetter = ContactParamsetter.append('urlsuffix', '/Contact/' + primaryContactReference);
      ContactParamsetter = ContactParamsetter.append('fields', 'Email');
      return this.http.get(this.API.get(this.baseUri1), { params: ContactParamsetter }).toPromise();
    });
  }

  /**
   * Get kgen id from parent.
   * US36552. this is obsolete after implementation of US39936
   */
  getParentKGenId(parentId: any) {
    let soql = "SELECT KGEN_Customer_Id__c FROM Account where Id = '" + parentId + "'";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
  }

  /**
   * @description US39936 - get parent account id from partner account
   */
  getPartnerParentKGenId(accountId: any): Promise<any> {
    /*
    let soql = "select Parent_Account__r.kgen_customer_id__c " +
      " from Partner_Account__c " +
      " where child_account__c='" + accountId + "'"  // -- This is acctid not parentid
      */
    /**
     * removing this field as SF team has removed it.
     In future we may need to change this according to type
     */
    // " and related_account__c='" + accountId + "'"     // -- This is acctid not parentid"
    //return this.http.get(this.API.get(this.baseUri) + "/" + soql).toPromise();    
    let parentAccountId: any;
    let AcctParamsetter = new HttpParams();
    AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + accountId + '/Partner_Accounts__r');
    AcctParamsetter = AcctParamsetter.append('fields', 'Parent_Account__c');
    return this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise().then((result) => {
      if (result['totalSize'] > 0 && result['records'] && result['records'].length > 0) {
        parentAccountId = result['records'][0]['Parent_Account__c']
        let ParentAcctParamsetter = new HttpParams();
        ParentAcctParamsetter = ParentAcctParamsetter.append('urlsuffix', '/Account/' + parentAccountId);
        ParentAcctParamsetter = ParentAcctParamsetter.append('fields', 'KGEN_Customer_Id__c');
        return this.http.get(this.API.get(this.baseUri1), { params: ParentAcctParamsetter }).toPromise().then((res) => {
          return new Promise((resolve) => {
            resolve(res['KGEN_Customer_Id__c']);
          });
        });
      }
    });
  }

  async fetchIypAssetDetail(listingId: any, accountId: any) {

    let iypAssets: any[] = new Array;
    let helperObj;
    let AcctParamsetter = new HttpParams();
    AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + accountId + '/Assets');
    AcctParamsetter = AcctParamsetter.append('fields', 'status,listing_id__c,accountid,id,name,Geography_Level__c,Geography_Name__c,Product2Id,Product_Code__c');
    let assets = await this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise();
    if( assets && assets['records'] ){
      for (let asset of Object.entries(assets['records'])) {
        if (asset[1]['Listing_Id__c'] == listingId && (asset[1]['Status'] == 'Published' || asset[1]['Status'] == 'Pending')) {
          let ProdParamsetter = new HttpParams();
          ProdParamsetter = ProdParamsetter.append('urlsuffix', '/Product2/' + asset[1]['Product2Id']);
          ProdParamsetter = ProdParamsetter.append('fields', 'Product_Code__c');
          let productDetails = await this.http.get(this.API.get(this.baseUri1), { params: ProdParamsetter }).toPromise();
          if (productDetails['Product_Code__c'] == 'Premium IYP' || productDetails['Product_Code__c'] == 'Single IYP') {
            helperObj = {};
            helperObj['Product_Code__c'] = productDetails['Product_Code__c'];
            asset[1]['Product2'] = helperObj;
            iypAssets.push(asset[1]);
          }
        }
      }
    }
    return new Promise((resolve) => {
      resolve(iypAssets);
    });
  }

  /**
   * Get listings associated to IYP items using salesforce.
   * This replaces vision iypvalidation service
   */
  listingAssociatedIypEss(listingId: any, accountId: any) {

    /*
    let soql: string;
    if (this.Params.params.account == undefined || this.Params.params.account == null || this.Params.params.account.trim() == '')
      soql = "select status, listing_id__c, accountid, id , name,Geography_Level__c ,Geography_Name__c ,   product2.Product_Code__c from asset where accountid in (select id from account where SV_Account_Id__c ='" + this.Params.params.eaId + "') and product2.Product_Code__c in ('Premium IYP', 'Single IYP') and listing_id__c = '" + listingId + "' and status in('Published', 'Pending') limit 100";
    else
      soql = "select status, listing_id__c, accountid, id , name,Geography_Level__c ,Geography_Name__c ,   product2.Product_Code__c from asset where accountid ='" + this.Params.params.account + "' and product2.Product_Code__c in ('Premium IYP', 'Single IYP') and listing_id__c = '" + listingId + "' and status in('Published', 'Pending') limit 100";
    */

    //get the data and cache it. Its not going to change within the session
    //return this.http.get(this.API.get(this.baseUri) + "/" + soql);    
    return new Observable((observer: Observer<any>) => {

      this.fetchIypAssetDetail(listingId, accountId).then((res) => {        
        let FinalResult = {}
        FinalResult['records'] = res;        
        observer.next(FinalResult);
      });
    })
  }

  //Fix for DE15326
  getAssetListing(itemId: any, accountId: any) {
    /*
    let soql = "select id, Digital_Listing__c, Digital_Listing_Id__c, Listing_Id__c, Listing_Name__c, Listing__c from asset " +
      "where Item_ID__c = " + "'" + itemId + "'";
    return this.http.get(this.API.get(this.baseUri) + '/' + soql);
    */
    return new Observable((observer: Observer<any>) => {
      let assetDetails: any[] = new Array;
      let FinalResult = {};
      let AcctParamsetter = new HttpParams();
      AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + accountId + '/Assets');
      AcctParamsetter = AcctParamsetter.append('fields', 'id,Item_ID__c,Listing_Id__c,Listing_Name__c,Listing__c');
      this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise().then((res) => {
        for (let asset of res['records']) {
          if (asset['Item_ID__c'] == itemId) {
            assetDetails.push(asset)
            break;
          }
        }
        FinalResult['records'] = assetDetails;
        observer.next(FinalResult);
      });
    });
  }
  getItemStatus(itemId : any){
    let soql = "SELECT Status,Item_ID__c FROM Asset " +  
    "where Item_ID__c  in "+ "(" + itemId + ")";
    // let soql = " SELECT Item_ID__c,status FROM Asset where Item_ID__c='BBDHV84269' , 'BBDHV84270', 'BBDHV84271';"
     return this.http.get(this.API.get(this.baseUri) + '/' + soql).toPromise();
    };
  
  
  //Fix for DE15326

  //sfData is expected to be an object
  savesfdata(sfData: any): Promise<any> {
    // Sonar fix
    var localsfdata: any = "";
    if (sfData instanceof Array) {
      localsfdata = sfData;
    } else {
      localsfdata = [sfData];
    }

    let data = {
      request: {
        items: {
          item: localsfdata
        }
      },
      sforg: this.Params.params.sforg
    };

    //console.log(sfData);
    //var data = sfData;
    return this.http.post(this.API.get(this.baseUri + "/update"), data).toPromise();
  }

  savecoopdata(data) {
    let dataSend = {
      request: data
    };

    return this.http.post(this.API.get(this.baseUri + "/updatecoop"), dataSend).toPromise();
  }

  //sfData is expected to be an array
  saveMultipleSFdata(sfData: any) {
    let data = {
      request: {
        items: {
          item: sfData
        }
      },
      sforg: this.Params.params.sforg
    }
    return this.http.post(this.API.get(this.baseUri + "/update"), data);
  }

  async fetchAssetBillingAccount(itemId: any, accountRecordId: any) {
    let billingAcount: any;    
    let AcctParamsetter = new HttpParams();
    AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + accountRecordId + '/Assets');
    AcctParamsetter = AcctParamsetter.append('fields', 'Billing_Account_ID__c,Item_ID__c');
    let result = await this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise();
    for (let asset of result['records']) {
      if (itemId == asset['Item_ID__c']) {
        billingAcount = asset['Billing_Account_ID__c']
        break;
      }
    }
    return new Promise((resolve) => {
      resolve(billingAcount);
    })
  }

  //US33863
  getBiilingAccountId(itemId: any, accountRecordId: any) {
    /*
    let soql = "select Billing_Account_ID__c from asset " +
      "where Item_ID__c = " + "'" + itemId + "'";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
    */
    return new Observable((observer: Observer<any>) => {


      this.fetchAssetBillingAccount(itemId, accountRecordId).then((billingAcount) => {
        let Finalresult = {};
        let ApiResults: any[] = new Array;
        let obj = {};
        let dataObj = {};
                
        obj['Billing_Account_ID__c'] = billingAcount;
        ApiResults.push(obj);
        dataObj['records'] = ApiResults;
        Finalresult['data'] = dataObj;
        observer.next(Finalresult);
      })
    })
  }

  getBiilingAccountIdFromItemOrder(itemId: string) {
    /*var soql = "select Billing_Account_ID__c from OrderItem " +
        "where Item_ID__c = " + "'" + itemId + "'";*/
    let soql = "select Billing_Account_ID__c from  " + this.itemOrder +
      " where Item_ID__c = " + "'" + itemId + "'";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
  }
  //US38103- 106 - call salesforce service to get CoOp field values and populate in model
  getCoOp_NI_Amount(itemId: string, orderId: string): Observable<any> {
    this.spinnerService.show();
    //var soql = "select CoOp_Type__c,CoOp_PI_Amount__c,CoOp_PI_Status__c,CoOp_NI_Amount__c,MRC__c from OrderItem " +
    //"where Item_ID__c = " + "'" + itemId + "'"+ " and Active__c = true  and Order__r.Order_ID__c = "+"'" + orderId+ "'";
    /*
    let soql = "select CoOp_Type__c,CoOp_PI_Amount__c,CoOp_PI_Status__c,CoOp_NI_Amount__c,MRC__c from " + this.itemOrder +
      " where Item_ID__c = " + "'" + itemId + "'" + " and Active__c = true  and Order__r.Order_ID__c = " + "'" + orderId + "'";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
    */
    return new Observable((observer: Observer<any>) => {
      let OrderParamsetter = new HttpParams();
      OrderParamsetter = OrderParamsetter.append('urlsuffix', '/Order/' + orderId + '/Orderitems');
      OrderParamsetter = OrderParamsetter.append('fields', 'CoOp_Type__c,CoOp_PI_Amount__c,CoOp_PI_Status__c,CoOp_NI_Amount__c,MRC__c,Item_ID__c,Active__c');
      this.http.get(this.API.get(this.baseUri1), { params: OrderParamsetter }).toPromise().then((res) => {

        let item = {};
        let finalResult = {}
        let itemDetails: any[] = new Array;

        if (res && res['records']) {
        for (let orderitem of res['records']) {
          if (orderitem['Item_ID__c'] == itemId && orderitem['Active__c'] == true) {
            item = orderitem;
            break;
          }
        }
      }
        itemDetails.push(item);
        finalResult['records'] = itemDetails
        observer.next(finalResult);
      })
    })
  }
  //US27344 --
  getAssetData(itemObject: any) {
    if (this.Params.params.sforg && this.Params.params.sforg === "national") {//US45821
      if (itemObject.actionType === "Update") {
        var versionMinusOne = itemObject.version - 1;
        var soql = "Select id, Listing_Id__c, Listing_Name__c, Listing__c, Category__c, Category_Display__c, Geography_Level__c, Geography_Name__c, Geo_Id__c, Advertiser_Budget__c, Billing_Account_ID__c From OrderItem " +
          "where Item_Id__c = " + "'" + itemObject.itemId + "' and Order.Order_Id__c = " + "'" + itemObject.orderId + "' and Version__c = " + versionMinusOne;
      } else {
        var soql = "Select id, Listing_Id__c, Listing_Name__c, Listing__c, Category_Id__c, Category__c, Geography_Level__c, Geography_Name__c, Geography__c, Geo_Id__c, Advertiser_Budget__c, Billing_Account_ID__c, Product2.ProductCode From Asset " +
          "where Item_ID__c = " + "'" + itemObject.itemId + "'";
      }

      return this.http.get(this.API.get(this.baseUri) + '/' + soql);
    } else {
      let soql = "select id, Digital_Listing__c, Digital_Listing_Id__c, Listing_Id__c, Listing_Name__c, Listing__c, Category__c,Geography_Level__c,Geography_Name__c,Geography__c,Geo_Id__c, Advertiser_Budget__c, UDAC_Mapping__c, Billing_Account_ID__c  from asset " +
        "where Item_ID__c = " + "'" + itemObject.itemId + "'";
      return this.http.get(this.API.get(this.baseUri) + '/' + soql);
    }
  }
  /**
   * @description US44473 - get Contact Information from Parent Account for CPQ National Orders
   */
  getContactInfo(accountId: string): Promise<any> {
    /*
    let soql = "Select Id,(Select FirstName, LastName, Phone, Email, MailingStreet, MailingCity, MailingStateCode, MailingPostalCode, MailingCountryCode from Contacts) from Account where id IN (SELECT Parent_Account__c FROM Partner_Account__c WHERE Child_Account__c = '" + accountId + "' And Relationship_Type__c = 'National')";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql).toPromise();
    */
    let parentAccountId: any;
    let AcctParamsetter = new HttpParams();
    AcctParamsetter = AcctParamsetter.append('urlsuffix', '/Account/' + accountId + '/Partner_Accounts__r');
    AcctParamsetter = AcctParamsetter.append('fields', 'Parent_Account__c,Relationship_Type__c');
    /* Call to fetch parent account of the given child account */
    return this.http.get(this.API.get(this.baseUri1), { params: AcctParamsetter }).toPromise().then((result) => {
      if (result['totalSize'] > 0 && result['records'] && result['records'].length > 0 && result['records'][0]['Relationship_Type__c'] && result['records'][0]['Relationship_Type__c'] == 'National') {
        parentAccountId = result['records'][0]['Parent_Account__c']
        let ParentAcctParamsetter = new HttpParams();
        ParentAcctParamsetter = ParentAcctParamsetter.append('urlsuffix', '/Account/' + parentAccountId + '/Contacts');
        ParentAcctParamsetter = ParentAcctParamsetter.append('fields', 'FirstName, LastName, Phone, Email, MailingStreet, MailingCity, MailingStateCode, MailingPostalCode, MailingCountryCode');
        /* Call to fetch contact details of the parent account */
        return this.http.get(this.API.get(this.baseUri1), { params: ParentAcctParamsetter }).toPromise().then((res) => {
          /* Wrapping the result, in the format expected in Indicator.service.ts */
          let FinalResult = {};
          let AccountObj = {}
          let contactWrapper = {}
          let AccountObjects: any[] = new Array;

          contactWrapper['records'] = res['records']
          AccountObj['Contacts'] = contactWrapper
          AccountObjects.push(AccountObj);
          FinalResult['records'] = AccountObjects

          return new Promise((resolve) => {
            resolve(FinalResult);
          })
        });
      }
      else {
        /* When no parent account is found send null value */
        return new Promise((resolve) => {
          resolve(null);
        })
      }
    });
  }

  /**
   * @description US45561 - get owner Id for Integration ESb iser for late Ad create Task
   */
  getOwnerIdLateAd() {
    let soql = "SELECT Id FROM User where name='Integration ESB'";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
  }

  /**
   * @description US45561 - get YP Unload Dates for directoriesin Old org for late Ad
   */
  getYPUnloadDateLateAd(directoryString: string) {
    let soql = "SELECT Yp_Unload_Date__c, Directory_Code__c FROM PB_Dir_Curr_Issue__c Where Directory_Code__c in (" + directoryString + ")";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
  }
  /**
    * @description FUL-8180 - get Thryv values to check if BP is needed or Not
    */
  getThryvProductDetails(udacCode : string) {
    let soql = "Select ProductCode, Product_Code__c, Description, hasBP__c,  isThryvLead__c, Id From Product2 Where  isProduct__c = true And IsActive = true And ProductCode = '" + udacCode + "'";
    return this.http.get(this.API.get(this.baseUri) + "/" + soql).toPromise();;
  }
  /**
    * @description FUL-38510 - October2022 - get Child_Account__r.Owner.Id and Parent_Account__r.Owner.Id
    * https://national.my.salesforce.com/services/data/v50.0/query/?q=Select+Child_Account__c+,+Parent_Account__c+,+Child_Account__r.Owner.Id+,+Parent_Account__r.Owner.Id+FROM+Partner_Account__c+Where+Relationship_Type__c='Work With'+And+(+Child_Account__c='0013900001ZzkeSAAR'+or+Parent_Account__c='0013900001ZzkeSAAR')
    */
  getPartnerAccountdata(accountId: string) {
    let soql = "Select Child_Account__c, Parent_Account__c, Child_Account__r.Owner.Id, Parent_Account__r.Owner.Id FROM Partner_Account__c Where Relationship_Type__c = 'Work With' And (Child_Account__c = '" + accountId +"' or Parent_Account__c = '" + accountId +"')" 
    let uri = this.API.get(this.baseUri);
    return this.http.get(this.API.get(this.baseUri) + "/" + soql);
  }
}
