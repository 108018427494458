import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { ValidateService } from '@app/service/validate/validate.service';
import { StorageService } from '@app/service/storage/storage.service';

import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-bp-geomap',
  templateUrl: './bp-geomap.component.html',
  styleUrls: ['./bp-geomap.component.scss']
})
export class BpGeomapComponent implements OnInit {

  iyp: boolean = false;
  iyparray = [];
  sharedData: Quickbpclass;
  constructor(private validate: ValidateService,
    private storgaeService: StorageService,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private Params: ParamsService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    });
    this.Params.params.mode = sessionStorage.getItem("mode");

    if (this.Params.params.mode === "listing") {
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds != null && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0] != null)
        this.Params.params.eaId = this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0];
    }
    console.log("this.sharedData-->",this.sharedData);
    if(this.sharedData['account'] && this.sharedData['account']['id']){
    this.validate.get(this.sharedData.stateParamsId,this.sharedData['account']['id']).then((data: any) => {
      if (data && (data.hasIYPPremium || data.hasIYPBasic) && this.sharedData.stateParamsId) {
        //show geos using validate API that gets data from asset in salesforce
        this.iyparray = data.geographies;
        this.iyp = true;
        //  this.changeDetectorRef.detectChanges();
        this.ngAfterViewChecked();
      }
      else {
        this.storgaeService.getAll("item")
          .then((data: any) => {
            if (data.length > 0) {
              //var items = []; fix for sonar issue

              for (let Data of data) {
                if (Data.data.hasOwnProperty('productCode') &&
                  Data.data.hasOwnProperty('printListingId') &&
                  Data.data.hasOwnProperty('actionCode') &&
                  Data.data.productCode === "Premium IYP" &&
                  Data.data.printListingId === this.sharedData.stateParamsId &&
                  Data.data.actionCode !== "Cancel") {
                  //show call to action on this item
                  this.iyp = true;
                  // this.changeDetectorRef.detectChanges();
                  this.ngAfterViewChecked();

                }
              }
            }
          });
      }
      if (this.sharedData.hasOwnProperty('order') && this.sharedData['order'] != null && !(this.sharedData['order'] === undefined)) {
        if (this.sharedData['order'].hasOwnProperty('OrderItems') && this.sharedData['order'].OrderItems && this.sharedData['order'].OrderItems != null && this.sharedData['order'].OrderItems !== undefined) {
          this.sharedData['order'].OrderItems.forEach((item: any) => {
            if (item.productType === "Digital" && item.actionCode !== "Cancel") {
              let listingId;
              let itemData = this.sharedData['items'][item.svItemId].model;
              if (this.sharedData['items'][item.svItemId].order.actionCode === "Renew") {
                /*let itemGetContentData = this.sharedData['items'][item.svItemId].data;
                if(this.sharedData['items'][item.svItemId].order.listingId!=undefined && this.sharedData['items'][item.svItemId].order.listingId!=null && this.sharedData['items'][item.svItemId].order.listingId!=''){
                    //get ListingId from salesforce for renewal items
                    listingId = this.sharedData['items'][item.svItemId].order.listingId
                }else{
                  if (itemGetContentData.ProductHeader) {
                    if (itemGetContentData.ProductHeader.listingId) {
                        listingId = itemGetContentData.ProductHeader.listingId
                    } else if (itemGetContentData.ProductHeader.businessLocationId) {
                        listingId = itemGetContentData.ProductHeader.businessLocationId
                    } else {
                        listingId = ""
                    }
                  }
                }*/
                listingId = itemData['BusinessProfile'];
              } else {
                if (itemData !== undefined && itemData.hasOwnProperty("BusinessProfile") && itemData['BusinessProfile'] != undefined && itemData['BusinessProfile'] != null && itemData['BusinessProfile'] != "") {
                  listingId = itemData['BusinessProfile'];
                } else {
                  listingId = itemData['listingId'];
                }
              }
              if (this.sharedData.stateParamsId && this.sharedData.stateParamsId === listingId && (item.productCode === "Premium IYP" || item.productCode === "Single IYP")) {
                this.iyp = true;
                // this.changeDetectorRef.detectChanges();
                this.ngAfterViewChecked();

                let levelAndName = "";
                if (item.geographyLevel != null && item.geoName != null) {
                  levelAndName = item.geographyLevel + " of " + item.geoName;
                } else if (item.geographyLevel != null && item.geoName === null) {
                  levelAndName = item.geographyLevel;
                }
                else if (item.geographyLevel === null && item.geoName != null) {
                  levelAndName = item.geoName;
                }
                else if (item.geographyLevel === null && item.geoName === null) {
                  levelAndName = null;
                }
                if (this.iyparray.indexOf(levelAndName) === -1) {
                  this.iyparray.push(levelAndName);
                  // this.changeDetectorRef.detectChanges();
                  this.ngAfterViewChecked();
                }
              }
            }
          });
        }
      }
    })
  }
  }

  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed) {
      this.cdr.detectChanges();
    }
  }
}
