import { Directive,  OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TabulatorModels } from './model';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { DigitalModel } from './digital-model';
import {PublishModel} from './publish-model';
import {BusinessprofileModel} from './businessprofile-model';
import { ToolModel } from './tool-model';
import { ConfigurationService } from '@app/service/configuration/configuration.service';
import { AdminuserService } from '@app/service/adminuser/adminuser.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { ConfigurationModel } from './configuration-model';
import { CounterService } from '@app/service/counter/counter.service';
import { StorageService } from '@app/service/storage/storage.service';
import { showHistoryModel } from '../../shared/components/show-history/show-history-model';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { FooterService } from '../components/footer/footer.service';
import { GoldenlistingModel } from './goldenlisting-model';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
import { IagroupHelperService } from '@app/service/iagroup-helper/iagroup-helper.service';
import { CopySheetComponent } from '../components/copy-sheet/copy-sheet.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from '@app/service/product/product.service';
import { GroupService } from '@app/service/group/group.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';

@Directive({
  selector: '[appTabulatorgrid]'
})
export class TabulatorgridDirective implements OnInit{
  importedData = []; // Array Data - JSON
  importedHeadersColumns: any[] = []; // Array columns headers names
  heightTable: string = "50%";
  showHistoryFlag: boolean = false;
  isPublishTabulator: boolean = false;
  publishDigital: boolean = false;
  @Input() dataSource: [];
  @Input() model: string;
  @Input() bpModel: string;
  @Input() itemStatusFlag: string;
  constructor(private router: Router,
    private params: ParamsService,
    private indicatorQuery:IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private configurationService:ConfigurationService,
    private adminuser:AdminuserService,
    private logger:LoggerService,
    private counterService: CounterService,
    private storageService: StorageService,
    private requiredService: RequiredfieldcountService,
    private loggerService: LoggerService,
    private footerService : FooterService,
    private matDialog: MatDialog,
    private indicatorService :IndicatorService,
    private updateIndicatorService :UpdateIndicatorService,
    private iaGroupHelper : IagroupHelperService,
    private cdr: ChangeDetectorRef,
    private spinnerService :NgxSpinnerService,
    private productService: ProductService,
    private grouper: GroupService,
    private sfService: SalesforceService) {};

  modelObj: TabulatorModels = new TabulatorModels(this.router, this.params, this.indicatorQuery, this.indicatorStore, this.storageService,this.counterService,this.loggerService,this.footerService, this.requiredService, this.indicatorService,this.updateIndicatorService,this.iaGroupHelper,this.matDialog,this.cdr,this.spinnerService, this.productService, this.grouper, this.sfService);
  digitalModelObj: DigitalModel =  new DigitalModel(this.router, this.params, this.indicatorQuery, this.indicatorStore, this.counterService, this.storageService, this.requiredService,this.logger, this.indicatorService,this.spinnerService);
  //billinModelObj: BillingModel = new BillingModel(this.footer,this.router, this.params, this.indicatorQuery, this.indicatorStore);
  publishModelObj: PublishModel = new PublishModel(this.router, this.params, this.indicatorQuery, this.indicatorStore,this.spinnerService);
  businessprofileModelObj: BusinessprofileModel = new BusinessprofileModel(this.router, this.params, this.indicatorQuery, this.indicatorStore, this.storageService, this.matDialog);
  toolModelObj: ToolModel = new ToolModel(this.router, this.params, this.indicatorQuery, this.indicatorStore,this.adminuser,this.logger);
  configurationModelObj: ConfigurationModel = new ConfigurationModel(this.router, this.params, this.indicatorQuery, this.indicatorStore,this.configurationService,this.logger);
  showHistoryObj: showHistoryModel = new showHistoryModel(this.indicatorQuery);
  goldenListingObj: GoldenlistingModel = new GoldenlistingModel(this.router, this.indicatorQuery, this.indicatorStore)
  ngOnInit() {
    this.showHistoryFlag = false;
    }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource'] || changes['model']) {
      this.importedData = this.dataSource;
      if (this.model =='orderitems') {
        this.importedHeadersColumns = this.modelObj.columns['orderitems'];
      } else if(this.model =='billing'){
        this.importedHeadersColumns = this.modelObj.getValue();//this.modelObj.columns['billing'];
      } else if(this.model =='digital'){
        this.importedHeadersColumns = this.modelObj.columns['digital'];
      } else if(this.model =='callTracking'){
        this.importedHeadersColumns = this.modelObj.columns['callTracking'];
      }else if(this.model =='publishGridPrint'){
        this.isPublishTabulator = true;
        this.importedHeadersColumns = this.publishModelObj.columns['publishGridPrint'];
      }else if(this.model =='publishGridDigital'){
        this.isPublishTabulator = true;
        this.publishDigital = true;
        this.importedHeadersColumns = this.publishModelObj.columns['publishGridDigital'];
      }else if(this.model =='adminGrid'){
        this.importedHeadersColumns = this.toolModelObj.columns['adminGrid'];
      } else if(this.model =='configurationgrid'){
        this.importedHeadersColumns = this.configurationModelObj.columns['configurationgrid'];
      } else if(this.model =='businessProfileGrid'){
        this.importedHeadersColumns = this.businessprofileModelObj.columns['businessProfileGrid'];
      /* }else if(this.model == 'billing-main'){
        this.importedHeadersColumns = this.billinModelObj.columns['billing-main'] */
      }else if(this.model == 'Digital-main'){
        this.importedHeadersColumns = this.digitalModelObj.columns['Digital-main'];
      // } else if(this.model == 'campaignGrid'){
      //   this.importedHeadersColumns = this.campaignModelObj.columns['campaignGrid']
      } else if(this.model == 'footerChangeHistory'){
        this.showHistoryFlag = true;
        let tempColumn = this.showHistoryObj.getColumn(this.bpModel,this.itemStatusFlag);
        this.importedHeadersColumns = tempColumn['footerChangeHistory'];
       }else if(this.model == 'goldenListingGrid'){
        this.importedHeadersColumns = this.goldenListingObj.columns['goldenListingGrid'];
       }
             else {
        this.importedHeadersColumns = [];
      }
        this.drawTable();
    }
  }

  drawTable() {
    //const tab = document.getElementById('TabulatorGrid');
    // const tab = this.showHistoryFlag ? document.getElementById('HistiryTabulatorGrid') : document.getElementById('TabulatorGrid');
    let tab;
    let tabConfigs
    if(this.showHistoryFlag){
       tab = document.getElementById('HistiryTabulatorGrid');
    } else if (this.publishDigital){
       tab = document.getElementById('TabulatorGridPublishDigital');
    } else {
       tab = document.getElementById('TabulatorGrid');
    }
    tabConfigs = {
      width: '100%',
      data: this.importedData,
      dataTree: true,
      dataTreeStartExpanded:true,
      layout: 'fitColumns',
      columns: this.importedHeadersColumns,
      placeholder: 'No Data Available',
      pagination:"local",
      paginationSize:15,
      paginationSizeSelector:[15, 20, 25],
      movableColumns: true,
      tooltips:true,
      columnMinWidth:10,
      initialSort:[
        {column:"counter", dir:"desc"},
      ],
      dataLoaded: function(data) {
        let el = document.getElementById("total_count");
        if(el) {
          if(data.length > 0) {
            el.innerHTML ='Total Count : '+ data.length;
          } else {
            el.innerHTML='';
          }
        }
      },
    };
    if(this.isPublishTabulator){
      tabConfigs['maxHeight'] = '358';
    }else{
      tabConfigs['height'] = '500';
    }

    new Tabulator(tab, tabConfigs);
  }
}

