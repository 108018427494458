import { Component, OnInit, Inject, ChangeDetectorRef, ViewRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { CallconfirmationComponent } from '../../../shared/components/call-new/callconfirmation/callconfirmation.component';
// import { ManagecopysheetService } from '@app/service/managecopysheet/managecopysheet.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { SetbaseService } from '@app/service/setbase/setbaseservice';
import { forEach } from 'jszip';
import { AdService } from '@app/service/ad/ad.service';
import { AccountService } from '@app/service/account/account.service';
import { StorageService } from '@app/service/storage/storage.service';

import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { SetBaseCollectionService } from '@app/service/setBaseCollection/setBaseCollectionservice';
import Tabulator from 'tabulator-tables';
import { ApiService } from '@app/service/api/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';
import { Constants } from '@app/service/datasource/constants';
import { async } from 'rxjs/internal/scheduler/async';
import { ImageDialogService } from './image-dialog.service';

@Component({
    selector: 'app-image-dialog',
    templateUrl: './image-dialog.component.html',
    styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {
    content: any;
    image: any;
    Indicator: any;
    addSearch: any;
    filteredAds: any;
    imageList = [];
    imageListDetails = [];
    titlelist = [];
    pageCount: any;
    svItemId: any;
    accountId: any;
    svOrderId: any;
    imageListTooltip: any;
    isListGrid: boolean;
    isThumbnailView: boolean;
    // isImageBorderEnable: string;
    selectedImage: any;
    okButton:boolean;
    paginationThumbnail:boolean;
    paginationGridView:boolean;
    totalItems: any; itemsPerPage: any; currentPage: any; maxSize: any;
    oldRef: any;
    // selectedAdid:any;
    //  Indicator: any;
    addSearchlist: any;
    ads = [];
    kgenCustomerId: any;
    pageCountlist: any;
    totalItems1: any; itemsPerPageList: any; currentPageList: any; maxSize1: any;
    filteredAdsList: any;
    result: string = '';
    imageChecked: any;
    listDetails = [];
    istableselected:string; 

    // Tabulator implementation
    tableThumbnailView: any;
    tableListView: any;
    tabulatorDataObj: any = [];
    radiobutton: any;
    tabThumbnailViewId: any;
    tabListViewId: any;
    pendingList:any=[];
    spinnerText: string;
    setbasedTimeoout:any;
    listArray:any;
    indicatorSetbaseList:any=[];

    constructor(
        private setbaseService: SetbaseService,
        private indicatorQuery: IndicatorQuery,
        // private manageCopysheetService: ManagecopysheetService,
        private dialogRef: MatDialogRef<ImageDialogComponent>,
        private http: HttpClient, private matDialog: MatDialog,
        private AdService: AdService,
        private ngxspinner: NgxSpinnerService,
        private indicatorstore: IndicatorStore,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private accountService: AccountService,
        private adService: AdService,
        private Copysheet: CopysheetService,
        private Logger: LoggerService,
        private indicatorStore: IndicatorStore,
        private cdr: ChangeDetectorRef,
        private storageService: StorageService,
        private setBaseCollectionService: SetBaseCollectionService,
        private api: ApiService,
        private ConstantText: Constants,
        private spinnerTextService: SpinnerService,
        private imageDialogService: ImageDialogService,


    ) { }

    ngOnInit() {
        // thumnail view started
        this.isThumbnailView = false;
        this.isListGrid = true;
        // this.isImageBorderEnable = '';
        this.imageChecked = '';
        this.okButton = true;
        this.paginationThumbnail = true;
        this.paginationGridView = true;
        this.istableselected = ''
        this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify(data));
        });
        this.accountId = this.Indicator.account.id;
        this.svOrderId = this.Indicator.order.orderId;
        this.svItemId = this.Indicator.stateParamsId;
        this.kgenCustomerId = this.Indicator.account.kgenCustomerId;
        this.tabThumbnailViewId = document.getElementById('Thumbnail-table');
        this.tabListViewId = document.getElementById('listGridView');

        this.tabThumbnailViewId.style.display = "none";
        this.tabListViewId.style.display = "none";
        this.Indicator.configuration.forEach((eachConfig) => {
            if (eachConfig.type == "setbased") {
                this.setbasedTimeoout = eachConfig.value
            }
            console.log('setbasedTimeoout', this.setbasedTimeoout)
        })
        this.setBaseCollectionService.get(this.kgenCustomerId).then(async t => {
            console.log('ad-content', t);
            this.imageList = t['setBaseAdImages'];
            this.imageList.forEach((image, i) => {
                if (this.imageList[i].AdDetails.fileConversionStatus != "Failure") {
                    this.imageList[i].AdDetails.FilePath = this.api.get('/api/file/' + image.AdDetails.FilePath + '/' + image.AdDetails.fileName);
                }
            })
            this.listArray = this.Indicator.account['setbasedarray'];
            console.log('data', this.listArray)
            let pendingCount = this.Indicator.account['setBaseImageCount'] - this.imageList.length;
            console.log('pendingCount', pendingCount)
            if (pendingCount > 0) {
                this.ngxspinner.show();
                this.spinnerText = this.ConstantText.setbaseText;
                this.spinnerTextService.setSpinnerText(this.spinnerText);
                this.pendingCountCallback();
            }
           
            this.imgaeLIstSetbased()
            this.displayView('list');
        })
    }

    imgaeLIstSetbased(){  
        this.indicatorSetbaseList = [];
        this.imageList.map(setList => {
            let setbaseObj = {};
            let matchingAdid = this.listArray.find(img => img.adId === setList.AdDetails.adId);
            if (matchingAdid) {
                setbaseObj['AdDetails'] = matchingAdid;
                setbaseObj['AdDetails'].FilePath = setList.AdDetails.FilePath;
                setbaseObj['AdDetails'].fileConversionStatus = setList.AdDetails.fileConversionStatus;
                this.indicatorSetbaseList.push(setbaseObj)
            }
        })
        console.log('manu', this.imageList)
        this.filteredAdsList = this.indicatorSetbaseList;
        console.log('indicatorSetbaseList', this.indicatorSetbaseList)
        this.tabulatorDataObj = [];
        this.filteredAdsList.forEach((field, index) => {
            let adDetails = field.AdDetails;
            let isAdId = false;
            let dataObj
             if(field.AdDetails && field.AdDetails.adId) {
                if (field.AdDetails.adId == this.dialogData.selectedAdId) {
                    isAdId = true;
                }
                dataObj = {
                    image: adDetails.FilePath,
                    product: adDetails.product,
                    headingName: adDetails.headingName,
                    udac: adDetails.udac,
                    listedTn: adDetails.listedTn,
                    listedName: adDetails.listedName,
                    displayAdId: adDetails.displayAdId,
                    select: field,
                    AdDetails: field.AdDetails,
                    SelectedAd: isAdId
                }
            } else {
                dataObj = field;
            }
            this.tabulatorDataObj.push(dataObj)
        });
    }
     async pendingCountCallback() {
        setTimeout(async() => {
            console.log('insidecallbackfunction')
            let pendingCount
           await this.setBaseCollectionService.get(this.kgenCustomerId).then(async t => {
                this.imageList = t['setBaseAdImages'];
                this.imageList.forEach((image, i) => {
                    if (this.imageList[i].AdDetails.fileConversionStatus != "Failure") {
                        this.imageList[i].AdDetails.FilePath = this.api.get('/api/file/' + image.AdDetails.FilePath + '/' + image.AdDetails.fileName);
                    }
                })
                pendingCount = this.Indicator.account['setBaseImageCount'] - this.imageList.length;
                console.log('insidependingCount',pendingCount)
            })
            if (pendingCount == 0) {
                this.ngxspinner.hide();
                this.imgaeLIstSetbased()
                this.displayView('list')
            }else{
                this.pendingCountCallback();
            }
        },  this.setbasedTimeoout)
    }
    // Thumbnailview serach tabulator
    thumbnailSearchFilter() {
        if (this.addSearchlist) {
            this.tableThumbnailView.setFilter([[
                { field: "product", type: "like", value: this.addSearchlist },
                { field: "headingName", type: "like", value: this.addSearchlist },
                { field: "udac", type: "like", value: this.addSearchlist },
                { field: "listedTn", type: "like", value: this.addSearchlist },
                { field: "listedName", type: "like", value: this.addSearchlist },
                { field: "displayAdId", type: "like", value: this.addSearchlist },
                
            ]])
        } else {
            this.tableThumbnailView.clearFilter(true);
        }
    }

    // Listview serach tabulator
    listSearchFilter() {
        if (this.addSearchlist) {
            this.tableListView.setFilter([[
                { field: "product", type: "like", value: this.addSearchlist },
                { field: "headingName", type: "like", value: this.addSearchlist },
                { field: "udac", type: "like", value: this.addSearchlist },
                { field: "listedTn", type: "like", value: this.addSearchlist },
                { field: "listedName", type: "like", value: this.addSearchlist },
                { field: "displayAdId", type: "like", value: this.addSearchlist },
            ]])
        } else {
            this.tableListView.clearFilter(true);
        }
    }

    // toggle display view - list view and Thumbnail view
    displayView(viewType) {
        if (viewType == 'thumbnail') {
            this.isThumbnailView = true;
            this.isListGrid = false;
            this.tabThumbnailViewId.style.display = "block";
            this.tabListViewId.style.display = "none";
            this.loadTabulatorThumbanailView();
        } else if (viewType == 'list') {
            this.isThumbnailView = false;
            this.isListGrid = true;
            this.tabThumbnailViewId.style.display = "none";
            this.tabListViewId.style.display = "block";
            this.loadTabulatorListView();
        }
    }

    // Thumnail view tabulator 
    loadTabulatorThumbanailView() {
        this.tableThumbnailView = new Tabulator("#Thumbnail-table", {
            width: '100%',
            layout: "fitColumns",
            placeholder: 'No Data Available...',
            pagination: "local",
            paginationSize: 12,
            headerVisible: false,
            paginationSizeSelector: [12, 15, 25, 35],
            data: this.tabulatorDataObj,
            columns: [
                { title: "", field: 'udac', cssClass: 'basedOn-Ad-clmn' },
                {
                    title: "", field: "",
                    formatter: (cell, formatterParams, onRendered) => {
                        let cellData = cell.getData();
                        console.log('cellData', cellData)
                        let cardElem;
                        let cardImgElem;                
                        if(cellData.SelectedAd){
                            cardElem = '<div>' +
                            '<div id="thumbRow'+cellData.AdDetails.adId +'" class="ad-card active-ad-card">'
                            this.dialogData['selectedAdId'] = cellData.AdDetails.adId;
                        } else {
                            cardElem = '<div>' +
                            '<div id="thumbRow'+cellData.AdDetails.adId +'" class="ad-card">'
                        }
                        if (cellData.AdDetails.fileConversionStatus == "Failure") {
                            cardImgElem =
                                '<div class="ad-image img-notfound">' +
                                '<a class="bi bi-image"></a>' +
                                '<label>Image not found</label>' +
                                '</div>'
                        } else { 
                            cardImgElem =
                            //    "<img class=ad-image  id='imgZoom'  onmousemove='zoomIn(event)' onmouseout='zoomOut()' src ='" + cellData.image + "' >"
                              "<img class=ad-image src ='" + cellData.image + "' >"                     
                        }
                        return cardElem +
                            '<div class="ad-image-wrap-scaled inactive-img-hover" id ="img-'+ cellData.AdDetails.adId +'">' +
                            "<img class=ad-image-scaled src ='" + cellData.image + "' >" +
                            // "<img class=ad-image ad-image-scaled src ='" + cellData.image + "' >" +
                            '</div>' +
                            '<div class="ad-card-ribbon"></div>' +
                            '<a class=tick-mark-after> </a>' +
                            '<div class="ad-image-wrap">' +
                            cardImgElem +
                            '</div>' +
                            '<div class="ad-alldetails">'+
                            '<div class="ad-content-items">' +
                            '<div class="ad-item ">' +
                            '<div class="ad-item-key">Product</div>' +
                            '<div class="ad-item-divider"> : </div>'+
                            '<div class="ad-item-value">' + cellData.product + ' </div>' +
                            '</div>' +
                            '<div class="ad-item">' +
                            '<div class="ad-item-key">Heading Name</div>' +
                            '<div class="ad-item-divider"> : </div>'+
                            ' <div class="ad-item-value">' + cellData.headingName + '</div>' +
                            '</div>' +
                            '<div class="ad-item">' +
                            '<div class="ad-item-key">UDAC</div>' +
                            '<div class="ad-item-divider"> : </div>'+
                            '<div class="ad-item-value">' + cellData.udac + '</div>' +
                            '</div>' +
                            '<div class="ad-item">' +
                            '<div class="ad-item-key">Listed TN</div>' +
                            '<div class="ad-item-divider"> : </div>'+
                            '<div class="ad-item-value">' + cellData.listedTn + '</div>' +
                            '</div>' +
                            '<div class="ad-item">' +
                            '<div class="ad-item-key">Listed Name</div>' +
                            '<div class="ad-item-divider"> : </div>'+
                            '<div class="ad-item-value">' + cellData.listedName + '</div>' +
                            '</div>' +
                            '<div class="ad-item">' +
                            '<div class="ad-item-key">Display Ad Id</div>' +
                            '<div class="ad-item-divider"> : </div>'+
                            '<div class="ad-item-value">' + cellData.displayAdId + '</div>' +
                            '</div>' +
                            '</div>' +
                            '</div>'+
                            
                            
                            // '<div class="ad-content-container">' +
                            // '<div class="ad-title-wrap">' +
                            // '<ul class="ad-title">' + cellData.AdDetails.adId + '</ul>' +
                            // '</div>' +                   
                            // '<div class="ad-content-items">' +
                            // '<div class="ad-item ">' +
                            // '<div class="ad-item-key">Product</div>' +
                            // '<div class="ad-item-divider"> : </div>'+
                            // '<div class="ad-item-value">' + cellData.product + ' </div>' +
                            // '</div>' +
                            // '<div class="ad-item">' +
                            // '<div class="ad-item-key">HeadingName</div>' +
                            // '<div class="ad-item-divider"> : </div>'+
                            // ' <div class="ad-item-value">' + cellData.headingName + '</div>' +
                            // '</div>' +
                            // '<div class="ad-item">' +
                            // '<div class="ad-item-key">UDAC</div>' +
                            // '<div class="ad-item-divider"> : </div>'+
                            // '<div class="ad-item-value">' + cellData.udac + '</div>' +
                            // '</div>' +
                            // '<div class="ad-item">' +
                            // '<div class="ad-item-key">Listed TN</div>' +
                            // '<div class="ad-item-divider"> : </div>'+
                            // '<div class="ad-item-value">' + cellData.listedTn + '</div>' +
                            // '</div>' +
                            // '<div class="ad-item">' +
                            // '<div class="ad-item-key">ListedName</div>' +
                            // '<div class="ad-item-divider"> : </div>'+
                            // '<div class="ad-item-value">' + cellData.listedName + '</div>' +
                            // '</div>' +
                            // '<div class="ad-item">' +
                            // '<div class="ad-item-key">Display Ad Id</div>' +
                            // '<div class="ad-item-divider"> : </div>'+
                            // '<div class="ad-item-value">' + cellData.displayAdId + '</div>' +
                            // '</div>' +
                            // '<div>' +
                            // '<div>' + 
                            '</div>' +
                            '</div>' +
                            '</div>'       
                    },
                    cellClick: async (e, cell, row) => {
                        let rowData = cell.getRow().getData();         
                        if (rowData.AdDetails.fileConversionStatus == "Failure") {

                        } else {
                            this.okButton = false;
                            let crntEl = document.getElementById("thumbRow" + rowData.AdDetails.adId);
                            if (crntEl) {
                                crntEl.classList.add("active-ad-card");
                            }
                            this.dialogData['selectedAdId'] = rowData.AdDetails.adId
                            let tableData = cell.getTable().getData();
                            let tableDataArray = tableData.map(el => {
                                if (rowData.AdDetails.adId == el.AdDetails.adId) {
                                    el.SelectedAd = true;
                                } else {
                                    el.SelectedAd = false;
                                }
                                return el;
                            });
                            this.tabulatorDataObj = tableDataArray;
                            console.log('before replace table data', this.tabulatorDataObj);
                            cell.getTable().replaceData(this.tabulatorDataObj)
                            console.log('after replace table data', cell.getTable().getData());
                            this.selectImage(rowData)
                        }
                    },
                    cellMouseOver: function(e, cell){
                        //e - the event object
                        //cell - cell component
                        console.log('rowMouseEnter - e', e);
                        console.log('rowMouseEnter - e', cell);
                        let rowData = cell.getRow().getData(); 
                        console.log('rowMouseEnter - rowData', rowData);

                        let crntEl = document.getElementById("img-" + rowData.AdDetails.adId);
                            if (crntEl) {
                                crntEl.classList.add("active-img-hover");
                                crntEl.classList.remove("inactive-img-hover");
                                // crntEl.style.display = "block";
                                // crntEl.innerHTML = "<img class= 'ad-image ad-image-scaled' src ='" + rowData.image + "' >";
                            }
                    },
                    cellMouseOut: function(e, cell){
                        //e - the event object
                        //cell - cell component
                        console.log('rowMouseEnter - e', e);
                        console.log('rowMouseEnter - e', cell);
                        let rowData = cell.getRow().getData(); 
                        let crntEl = document.getElementById("img-" + rowData.AdDetails.adId);
                            if (crntEl) {
                                crntEl.classList.add("inactive-img-hover");
                                crntEl.classList.remove("active-img-hover");
                                // crntEl.classList.remove("active-img-hover");
                                // crntEl.style.display = "none";
                                // while (crntEl.firstChild) {
                                //     crntEl.removeChild(crntEl.firstChild);
                                // }
                            }
                    },
                },
            ],
            initialSort:[
                {column:"udac", dir:"asc"},
            ]
        });
    }
 
    // listview tabulator 
    loadTabulatorListView() {
        this.tableListView = new Tabulator("#listGridView", {
            width: '100%',
            data: this.tabulatorDataObj,
            dataTree: true,
            dataTreeStartExpanded: true,
            layout: "fitColumns",
            movableColumns: true,
            selectable: false,
            placeholder: 'No Data Available...',
            pagination: "local",
            paginationSize: 12,
            paginationSizeSelector: [12, 15, 25, 35],
            columnMinWidth: 10,
            columns: [
                { title: "Product", field: 'product', },
                { title: "Heading Name", field: 'headingName', },
                { title: "UDAC", field: 'udac' },
                { title: "Listed TN", field: 'listedTn', },
                { title: "Listed Name", field: 'listedName' },
                {
                    title: "View Ad", field: "adDetails", align: "center", formatter: function (cell, formatterParams) {
                        let viewad = cell.getData();
                        return '<i class="fa fa-eye view-Ad"></i>'
                    },
                    cellClick: async (e, cell) => {
                        // this.getAdUrl(viewad)
                        let viewad = cell.getData();
                        window.open(this.getAdUrl(viewad.AdDetails))
                    },
                },
                { title: "Display Ad Id", field: 'displayAdId', },
                {
                    title: "Select", field: 'select', align: "center",
                    formatter: (cell, formatterParams, onRendered) => {
                        let cellData = cell.getData();
                        cell.getElement().addClass('list-view-select-cell')
                        cell.getRow().getElement().setAttribute('id', 'listRow'+cellData.AdDetails.adId);
                        
                        if(cellData.SelectedAd){
                            cell.getRow().getElement().addClass('active-ad-row')
                            this.dialogData['selectedAdId'] = cellData.AdDetails.adId;
                        }
                        
                        return ` <div class="bi bi-circle"></div>
                                 <div class="bi bi-circle-fill"></div> `;
                        // return `<i class="fa fa-share-square-o" aria-hidden="true"></i>`     
                    },
                    cellClick: async (e, cell, row) => {
                        let cellData = cell.getData();
                        let rowData = cell.getRow().getData();
                        if (rowData.AdDetails.fileConversionStatus == "Failure") {

                        } else {
                            this.okButton = false;
                            cell.getRow().getElement().addClass('active-ad-row')
                            this.dialogData['selectedAdId'] = rowData.AdDetails.adId;
                            let tableData = cell.getTable().getData();
                            let tableDataArray = tableData.map(el => {
                                if (rowData.AdDetails.adId == el.AdDetails.adId) {
                                    el.SelectedAd = true;
                                } else {
                                    el.SelectedAd = false;
                                }
                                return el;
                            });
                            this.tabulatorDataObj = tableDataArray;
                            console.log('before replace table data', this.tabulatorDataObj);
                            cell.getTable().replaceData(this.tabulatorDataObj)
                            console.log('after replace table data', cell.getTable().getData());
                            this.selectImage(rowData)
                        }
                    },
                    tooltip: function(cell){
                        //cell - cell component
                        let rowData = cell.getRow().getData(); 
                        if (rowData.AdDetails.fileConversionStatus == "Failure") {
                            return "Image not found"
                        }
                    },
                },
            ],
            initialSort:[
                {column:"udac", dir:"asc"},
            ]
        });
    }

    /* select the images for setbased ad  */
    async selectImage(add) {
        console.log('selectimage', add)
        this.selectedImage = add;
        this.okButton = false;
    }

    /* when select the setbased ad images to show the  dialog  and New Generate copysheet generated */
    async oKGenerate() {
        console.log('Inside generateNewCopyheet')
        console.log('Triggering newGenerateCopysheet ')
        await this.imageDialogService.newGenerateCopysheet(this.Indicator.stateParamsId, this.Indicator, "annotate", false, 'SAVE-PRINT-ITEM-GENERATE-COPYSHEET-ITEMPAGE').then(res =>{
            if(res){
                console.log('newGenerateCopysheet is completed');
                fetch(this.selectedImage.AdDetails.FilePath)
                    .then(res => res.blob()) // Gets the response and returns it as a blob
                    .then(async blob => {   
                    console.log("Blobimg:",blob);
                    var image = {}
                    // Here, I use it to make an image appear on the page
                    let uint8ArrayNew = await new Response(blob).arrayBuffer();
                    console.log('uint8Array', uint8ArrayNew);
                    image['buffer'] = uint8ArrayNew;
                    image['adid'] = this.selectedImage.AdDetails.adId;
                    image['closingValue'] = true;
                    image['blob'] = blob;
                    this.dialogRef.close(image);
                })

            }
            else{
                this.dialogRef.close(false);
                console.log("Error generating copysheet")
                this.Logger.logError("Error generating copysheet, Please try again!")
            }
            
        }).catch(err => { 
            console.log("Error generating copysheet:",err) 
        })
        
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    getAdUrl(ad) {
        return this.adService.getAdSet(ad.displayAdId, ad.displayAdIdPrefix, ad.graphicRegion);
    };

    ngAfterViewChecked() {
        if (!(<ViewRef>this.cdr).destroyed)
            this.cdr.detectChanges();
    }

    closeModal() {
        this.oldRef.close();
    };
}
