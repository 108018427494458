import { Component, OnInit, OnDestroy } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { ComponentQuery } from 'app/state/component.query';
import { Subject } from 'rxjs';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { CallnewComponent } from '../callnew/callnew.component';
import { MatDialogRef } from '@angular/material';
import { ComponentStore } from 'app/state/component.state';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
//import { CallserviceService } from '../callservice.service';

@Component({
  selector: 'app-callinput',
  templateUrl: './callinput.component.html',
  styleUrls: ['./callinput.component.scss']
})
export class CallinputComponent implements OnInit,OnDestroy {
  options: any;
  titleContent: string;
  sharedData;
  show:boolean= true;
  check:boolean= true;
  text:boolean= false;
  dropdownValue: string;
  callRecordTitle: string;
  callInterceptTitle: string;
  titleTrackingNumber: string;
  destroySubject$: Subject<void> = new Subject();
  areacode: any;
  prefix: any;
  callrecord: boolean;
  callintercept: boolean;
  isReadonly:boolean = true;
  customField: any;
  errorFlag: boolean;

  constructor(private componentQuery :ComponentQuery ,
    private indicatorQuery :IndicatorQuery,
    private dialogRef: MatDialogRef<CallnewComponent>,
    private componnentStore: ComponentStore,
    private callupdateIndicatorService ?: UpdateIndicatorService
    ) { }
  ngOnInit() {
    this.check = true;
    this.text= false;
    this.dropdownValue = "Local Number";
    this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data =>{
          this.dropdownValue = data;
          if(data === "Toll-Free Number"){
            this.check = false;
            this.show = true;
            this.text = false;
          }else if( data === "Market C Number"){
            this.text = true;
            this.check = false;
            this.show = false;
          }else if(data === "Local Number"){
            this.text = false;
            this.check = true;
            this.show = true;
          }
            
    })
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data}

    });
    this.options = this['layoutNode'].options;
    let num = this.sharedData.items[this.sharedData.stateParamsId].order.listingTn && this.sharedData.items[this.sharedData.stateParamsId].order.listingTn.replace(/\D/g, "");
    if(this['layoutNode'].items[0].name === "callrecord"){
       this.callRecordTitle = "Call Record"
       this.callrecord = true;
    }
    if(this['layoutNode'].items[1].name === "callintercept"){
      this.callInterceptTitle = "Call Intercept"
      this.callintercept = true;
    }
    if(this['layoutNode'].items[2].name === "areacode"){
      this.titleContent = "Targeted Area Code-Prefix"
      this.areacode = this.sharedData.account.phone.substring(1, 4) || num.substring(0, 3);
    }
    if(this['layoutNode'].items[3].name === "prefix"){
      this.prefix = this.sharedData.account.phone.substring(6,9) || num.substring(3, 6);
    }
    if(this['layoutNode'].items[4].name === "trackingNumber"){
      this.titleTrackingNumber= "Telephone Number";
    }
    this.callupdateIndicatorService.prefixVal.next(this.prefix);
    this.callupdateIndicatorService.areaCodeVal.next(this.areacode);
    
  }
  sendValue(args){
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
    if(this['layoutNode'].items[4].name === "trackingNumber" && this.dropdownValue === "Market C Number"){
      this.customField = this.customField.replace(/[^0-9]/g, '')
      if (this.customField.length >= 1) {
        this.customField = ("(").concat(this.customField.slice(0))
      }
      if (this.customField.length >= 5) {
        this.customField = this.customField.slice(0, 4).concat(") ", this.customField.slice(4))
      }
      if (this.customField.length >= 10) {
        this.customField = this.customField.slice(0, 9).concat("-", this.customField.slice(9))
      }
      if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.componentInstance.onUpdatetrackingNumber(args);
      }
        if (args && args.length == 14) {
          this.errorFlag = false
        }else{
          this.errorFlag = true;
        }
      }
      if(this['layoutNode'].items[3].name === "prefix"){
        if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.componentInstance.onUpdateDataFields(args);
        }
      }
      
      this.callupdateIndicatorService.prefixVal.next(this.prefix);
      this.callupdateIndicatorService.areaCodeVal.next(this.areacode);
 
  }
  ngOnDestroy() {
    this.destroySubject$.next();
    this.componnentStore.update(()=>{
      return {
        componentValue:''
      }
    })
  }
}
