import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from './app.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({ template: `` })
export class CallbackComponent implements OnInit {

  constructor(private okta: OktaAuthService, private router: Router) { }

  ngOnInit(): void {
    console.log("inside callback component->");
    // Handles the response from Okta and parses tokens
    this.okta.handleAuthentication().then(data => {
      let navigateUrl = decodeURI(sessionStorage.getItem("okta-app-url"));
      console.log("inside callback--> caangulareffererPath : ", navigateUrl);
      //navigateUrl = navigateUrl.split("?")[1];
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      };
      /**
       * Authentication done by okta. Now navigate to CA url
       */
      this.router.navigateByUrl(navigateUrl, navigationExtras);
    })
      .catch(err => {
        console.log(" error in redirecting to the accessable page : " + err);
        this.router.navigate(['accessdenied']);
      })
  }
}