import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';
import { LoggerService } from '../logger/logger.service';
import { AccountService } from '../account/account.service';
import { UtilsService } from '../utils/utils.service';
import { OrderService } from '../order/order.service';
import { ProfileService } from '../profile/profile.service';
import { ItemService } from '../item/item.service';
import { GroupService } from '../group/group.service';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { StorageService } from '../storage/storage.service';
import { FieldcountService } from '../fieldcount/fieldcount.service';
import { CopysheetFileComponent } from 'app/shared/components/copysheet-file/copysheet-file.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CopysheetUploadComponent } from 'app/shared/components/copysheet-upload/copysheet-upload.component';
import { CopysheetAnnotateComponent } from 'app/shared/components/copysheet-annotate/copysheet-annotate.component';
import { FileService } from '../file/file.service';
import { CopysheetService } from '../copysheet/copysheet.service';
import { AdBasedComponent } from 'app/shared/components/ad-based/ad-based.component';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { CounterService } from '../counter/counter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PDFViewerComponent } from 'app/shared/components/pdfviewer/pdfviewer.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CallconfirmationComponent } from '../../../shared/components/call-new/callconfirmation/callconfirmation.component';

var timer;
var winReference;
var self;

@Injectable({
  providedIn: 'root'
})
export class ManagecopysheetService {

  loadingCopysheet: boolean;
  loadingUpload: boolean;
  loadingAnnotate: boolean;
  storageID: string;
  dialogData: any = {};
  isInd: boolean = false;
  private isIndSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isInd);
  isGraphicArray: boolean = false;
  private isGraphickItemSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isGraphicArray);
  isSaveBtnEnable: boolean = false;
  private isSaveBtnEnableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isSaveBtnEnable);

  constructor(private http: HttpClient,
    private API: ApiService,
    private Params: ParamsService,
    private Logger: LoggerService,
    private accountService: AccountService,
    private utilsService: UtilsService,
    private orderService: OrderService,
    private profileService: ProfileService,
    private itemService: ItemService,
    private groupService: GroupService,
    private indicatorStore: IndicatorStore,
    private indicatorQuery: IndicatorQuery,
    private requiredFieldCount: FieldcountService,
    private matDialog: MatDialog,
    private file: FileService,
    private CopysheetService: CopysheetService,
    private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService,
    private spinnerService: NgxSpinnerService,
    private StorageService: StorageService,
    private cookieService: CookieService) { self = this }

  setBehaviorViewGraphic(isInd: boolean) {
    this.isIndSubject.next(isInd)
  }

  getBehaviorViewGraphic(): Observable<boolean> {
    return this.isIndSubject.asObservable();
  }

  setBehaviorRequiredItemGraphic(isGraphicArray: any) {
    this.isGraphickItemSubject.next(isGraphicArray)
  }
  getBehaviorRequiredItemGraphic(): Observable<any> {
    return this.isGraphickItemSubject.asObservable();
  }
  setBehaviorIsSaveBtnEnable(isSaveBtnEnable: any) {
    this.isSaveBtnEnableSubject.next(isSaveBtnEnable)
  }
  getBehaviorIsSaveBtnEnable(): Observable<any> {
    return this.isSaveBtnEnableSubject.asObservable();
  }

  getCopySheetData(action, stateId, indicatorValue, page?) {
    if (indicatorValue && indicatorValue.items && indicatorValue.items[stateId] && indicatorValue.items[stateId].order) {
      this.storageID = stateId + "v" + indicatorValue.items[stateId].order.version;
    }
    indicatorValue.stateParamsId = stateId
    this.updatCounterStore(indicatorValue)
    indicatorValue = JSON.parse(JSON.stringify(indicatorValue));
    // Add check for Sentry issue
    if (indicatorValue.items[stateId] && indicatorValue.items[stateId].order.actionCode === "Renew" && indicatorValue.items[stateId].order.actionType === "New") {
      if (indicatorValue.items[stateId].generatedCopysheet == false && !indicatorValue.items[stateId].copy.copysheet) {
        indicatorValue.items[stateId].copy.copysheet = null
      }
    }
    if (indicatorValue.items[stateId] && indicatorValue.items[stateId].copy && !indicatorValue.items[stateId].copy.hasOwnProperty("copysheet")
      ||(indicatorValue.items[stateId] && indicatorValue.items[stateId].copy && indicatorValue.items[stateId].copy.copysheet == "")
      ||(indicatorValue.items[stateId] && indicatorValue.items[stateId].copy && indicatorValue.items[stateId].copy.copysheet === null) || (indicatorValue.items[stateId] && indicatorValue.items[stateId]['isCopySheetUploaded'])) {
      this.Params['saving'] = true;
      this.generateCopysheet(stateId, indicatorValue, action, null, page);

    } else {
      this.Params['saving'] = true;
      if (action == 'view') {
        var win = window.open(indicatorValue.items[stateId].copy.copysheet);
        if (win) {
          win.focus();
        }
      } else if (action == 'annotate') {
        this.annotateCopysheet(indicatorValue, stateId, page);
      }
      this.Params['saving'] = false;
      this.Params['loadingbar'] = false;
    }
  }

  generateCopysheet(stateParamsId, indicatorValue, genType, openAttachment?, page?) {
    if (genType === "view") {
      this.loadingCopysheet = true;
    } else if (genType === "upload") {
      this.loadingUpload = true;
    } else if (genType === "annotate") {
      this.loadingAnnotate = true;
    }

    Promise.all([
      this.accountService.getMetoPromise(),
      this.orderService.getMeToPromise(indicatorValue.order['sfdcOrderId'])
    ]).then((results) => {
      let account = results[0];
      let order = results[1];
      let promise = null;
      let id = null;
      if ((account.parentId != null && account.kgenCustomerId == null) || indicatorValue.account.kgenCustomerId) {
        account.kgenCustomerId = indicatorValue.account.kgenCustomerId;
      }

      if (indicatorValue.items[stateParamsId].order.productType === "Digital") {
        promise = this.profileService.getByEid(account.enterpriseAccountId);
        id = account.enterpriseAccountId;
      } else if (indicatorValue.items[stateParamsId].order.productType === "Print") {
        promise = this.profileService.getAllPrintListing(account.kgenCustomerId, {
          productcode: indicatorValue.items[stateParamsId].order.directoryNumber,
          productissuenum: indicatorValue.items[stateParamsId].order.directoryIssueNumber,
          sectioncode: indicatorValue.items[stateParamsId].order.section
        });
        id = account.kgenCustomerId;
      }
      if (promise) {

        promise.then((response) => {
          let data = response[id];
          if (data.hasWarningOrError !== "true") {
            let pageRef = page == "COPYSHEET-UPLOAD-ORDERITEMPAGE" ? "UPLOADCOPYSHEET-ORDERITEMPAGE" : "copysheet";

            this.itemService.update(account, order, indicatorValue.items[stateParamsId], data.listings.listing, stateParamsId, pageRef)
              .then((printRes) => {
                //console.log(" Response from print service : " + printRes);
                //this.spinnerService.hide();
                //checkfor erro rmessage and get out of the controll once failure response
                let copySheetReqData = {
                  "type": "generateCopySheet",
                  "id": "",
                  "callerId": "UCC",
                  "correlationId": this.groupService.getAllItemsOfGroupButNotSelf(stateParamsId, indicatorValue).toString(),
                  "sourceId": "",
                  "copySheet": {
                    "svOrderId": order['orderId'],
                    "customerId": account.kgenCustomerId,
                    "svItemId": stateParamsId,
                    "productCode": (indicatorValue.items[stateParamsId].order.productType === "Digital") ? indicatorValue.items[stateParamsId].order.productCode : indicatorValue.items[stateParamsId].order.directoryNumber,
                    "productIssueNum": indicatorValue.items[stateParamsId].order.directoryIssueNumber,
                    "documentType": (indicatorValue.items[stateParamsId].order.actionCode === "Renew") ? "A" : "N",
                    "operatorId": order['opportunity'].owner.operatorId,
                    "date": this.utilsService.getKgenDate(new Date())
                  }
                };
                this.CopysheetService.getCopySheets(copySheetReqData, stateParamsId, indicatorValue.items[stateParamsId].order.version, page)
                  .then((data) => {
                    // added flag for header and footer validation 
                    indicatorValue.items[stateParamsId].copy['headercopysheet'] = true;
                    this.loadingCopysheet = false;
                    this.loadingUpload = false;
                    this.loadingAnnotate = false;

                    if (data && data['response'] && data['response'] == null || data['response'] == undefined) {
                      if (indicatorValue.items[stateParamsId].order.statusCode >= 6000) {
                        indicatorValue.items[stateParamsId].needsNewCopysheet = true;
                      }

                      indicatorValue.items[stateParamsId].copy.copysheet = data['copysheet'];
                      /*
                      This is added to avoid the blank screen when clicked on view/annotate from IA group of footer
                      */
                      if (!indicatorValue.items[stateParamsId].hasOwnProperty("baseCopysheet") || indicatorValue.items[stateParamsId]['baseCopysheet'] == null || indicatorValue.items[stateParamsId]['baseCopysheet'] == undefined || indicatorValue.items[stateParamsId]['baseCopysheet'] == '') {
                        indicatorValue.items[stateParamsId]['baseCopysheet'] = data['copysheet'];
                      }
                      indicatorValue.items[stateParamsId].copy.fileType = 'pdf';
                      //Adding flag for cancel copysheet FUL-37656,37657,FUL-37832
                      if (genType === "annotate") {
                        indicatorValue.items[stateParamsId].copy['cancelCopySheet'] = false;
                      }
                      let tempStorageId = stateParamsId + "v" + indicatorValue.items[stateParamsId].order.version;
                      this.CopysheetService.updateCopysheet(tempStorageId, indicatorValue.items[stateParamsId].copy)
                        .then(() => { }).catch((err) => {
                          this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                        });
                      /*indicatorValue.items[stateParamsId].generatedCopysheet = true;
                      this.StorageService.updateField("copyz", this.storageID, { "data.generatedCopysheet": true })
                        .subscribe(() => { }, err => { console.log(" Error in update filed service "); });*/

                      if (genType === "view") {
                        var win = window.open(data['copysheet']);
                        if (win) {
                          win.focus();
                        }
                      } else if (genType === "upload") {
                        openAttachment ? this.openUploadAttachmentDialog(stateParamsId, indicatorValue, null) : this.openUploadDialog(indicatorValue, stateParamsId);
                      } else if (genType === 'annotate') {

                        this.indicatorStore.update(state => {
                          return {
                            indicator: indicatorValue,
                            updatedFrom: "[ManagecopysheetService] getCopysheets",
                          }
                        })
                        this.indicatorQuery.getIndicator().subscribe(data => {
                          indicatorValue = { ...data }
                        });
                        this.annotateCopysheet(indicatorValue, stateParamsId);
                      }
                      this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": false })
                        .subscribe(() => { }, err => { console.log(" erro in copy z call") });
                      //indicatorValue.items[stateParamsId].hasCopysheet = true;
                      if (openAttachment !== undefined) {
                        console.log(" item componenet save button");
                        //var itemController = $controller('ItemController', { $scope: $scope });
                        //itemController.continueButton(); // save button in the item.components
                        // this.ItemComponent.saveButton();
                      }

                      //Reverting the code for IA grp
                      if (indicatorValue.items[stateParamsId].data
                        && indicatorValue.items[stateParamsId].data.iaGroupId) {
                        //go through the other items on the order
                        for (let key in indicatorValue.items) {
                          indicatorValue = JSON.parse(JSON.stringify(indicatorValue));
                          if (indicatorValue.items[key].data
                            && indicatorValue.items[key].data.iaGroupId === indicatorValue.items[stateParamsId].data.iaGroupId
                            && indicatorValue.items[key].order.svItemId !== stateParamsId) {
                            //add the copysheet to the other item
                            indicatorValue.items[key].copy.copysheet = data['copysheet'];
                            indicatorValue.items[key].copy.fileType = 'pdf';
                            indicatorValue.items[key].hasCopysheet = true;
                            // update copysheetIndicator value
                            if (indicatorValue.items[key].order.productType === "Print") {
                              if (indicatorValue.items[key].model.hasOwnProperty('ManageCopysheet')) {
                                indicatorValue.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                                indicatorValue.items[stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                              } else {
                                indicatorValue.items[key].model['ManageCopysheet'] = {};
                                indicatorValue.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                                indicatorValue.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                              }
                              indicatorValue.items[key].model['digitalApiFlag'] = true;
                            } else {
                              indicatorValue.items[key].model['copySheetIndicator'] = 'Y';
                              indicatorValue.items[stateParamsId].model['copySheetIndicator'] = 'Y';
                              indicatorValue.items[key].model['digitalApiFlag'] = true;
                            }

                            this.CopysheetService.updateCopysheet(indicatorValue.items[key].order.svItemId + "v" + indicatorValue.items[key].order.version, indicatorValue.items[key].copy)
                              .then(res => { }).catch(err => { console.log(" Error in update copy sheet ", err) });
                            this.updateIAGroupCounter(key, indicatorValue);
                          }
                        }
                      }
                    } else if (data['response'].hasWarningOrError === "true") {
                      this.Params['saving'] = false;
                      this.Params['loadingbar'] = false;
                      this.loadingUpload = false;
                      this.loadingAnnotate = false;
                      var errorObject = data['response'].esbServiceFault;
                      var errorCode = errorObject.sourceSystem;
                      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                      var errorMsg = "<strong>Transaction : " + data['response'].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                      this.Logger.logError(errorMsg);
                      this.spinnerService.hide();
                      let tempStorageId = stateParamsId + "v" + indicatorValue.items[stateParamsId].order.version;
                      if (indicatorValue.items[stateParamsId].hasOwnProperty("generatedCopysheet") && !indicatorValue.items[stateParamsId]["generatedCopysheet"]) {
                        indicatorValue.items[stateParamsId].copy.copysheet = null;
                        this.CopysheetService.updateCopysheet(tempStorageId, indicatorValue.items[stateParamsId].copy)
                          .then(() => { }).catch((err) => {
                            this.Logger.logError("Failed to update copysheetII :" + JSON.stringify(err));
                          });
                      }

                    } else {
                      this.Params['saving'] = false;
                      this.Params['loadingbar'] = false;
                      this.loadingAnnotate = false;
                      this.loadingUpload = false;
                      //console.log("into error condition while annotate copy sheet", data);
                      this.Logger.logError(data['message']);
                    }
                  })
                  .catch((response) => {
                    this.loadingUpload = false;
                    this.loadingCopysheet = false;
                    this.loadingAnnotate = false;
                    this.spinnerService.hide();
                    if (response.response && response.response.hasWarningOrError == "true") {
                      //   vm.saving = false;
                      var errorObject = response.response.esbServiceFault;
                      var errorCode = errorObject.sourceSystem;
                      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                      var errorMsg = "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                      this.Logger.logError(errorMsg);
                    } else {

                      this.Logger.logError(response.message);
                    }
                  });
              })
              .catch(err => {
                // this.spinnerService.hide();
                this.loadingUpload = false;
                this.loadingAnnotate = false;
                this.Params['saving'] = false;
                this.Params['loadingbar'] = false;
              });
          } else {
            this.loadingUpload = false;
            this.loadingAnnotate = false;
            this.Params['saving'] = false;
            this.Params['loadingbar'] = false;
            var errorObject = data.esbServiceFault[0];
            var errorCode = errorObject.sourceSystem;
            var errorDesc = errorObject.exceptionDetail[0].errorDescription;
            var errorMsg = "<strong>Transaction : " + data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            this.Logger.logError(errorMsg);
          }
        }).catch((response) => {
          this.loadingUpload = false;
          this.loadingAnnotate = false;
          //console.log(" error : ", response);
          this.Params['saving'] = false;
          this.Params['loadingbar'] = false;
        });
      } else { }
    }).catch(err => {
      //console.log(" erro 2 : ", err);
      this.loadingUpload = false;
      this.loadingAnnotate = false;
      this.Params['saving'] = false;
      this.Params['loadingbar'] = false;
    });

  };
  updateIAGroupCounter(itemID, Indicator) {

    let id = itemID;
    let currentCount = Indicator.items[itemID].counter;
    var count = this.requiredFieldCount.get(Indicator.items[itemID].schema, Indicator.items[itemID].form, Indicator.items[itemID].model, Indicator.items[itemID].order);
    Indicator.items[itemID].counter = count;



    if (Indicator.items[itemID].requiresCopysheet
      && !Indicator.items[itemID].hasCopysheet) {
      // Indicator.items[itemID].counter++;
      let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][itemID].requiredFields, Indicator["items"][itemID].model, Indicator["items"][itemID].order, Indicator["orderPercent"], Indicator["items"][itemID].counter, true, Indicator["items"][itemID]);
      this.updateIndictor(itemID, Indicator, tempData, count);
      if (Indicator['items'][itemID].counter == 0) {
        document.getElementById(itemID + 'counter').classList.remove('item-order-error');
      } else if (Indicator['items'][itemID].counter != 0) {
        document.getElementById(itemID + 'counter').classList.add('item-order-error');
      }
    } else {

      //Indicator =indicatorVal; //JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      Indicator.items[itemID].counter = currentCount;
      let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][id].requiredFields, Indicator["items"][id].model, Indicator["items"][id].order, Indicator["orderPercent"], Indicator["items"][id].counter, true, Indicator["items"][id]);
      this.updateIndictor(id, Indicator, tempData, currentCount);
      if (Indicator['items'][itemID].counter == 0) {
        document.getElementById(itemID + 'counter').classList.remove('item-order-error');
      } else if (Indicator['items'][itemID].counter != 0) {
        document.getElementById(itemID + 'counter').classList.add('item-order-error');
      }
    }
  }

  openUploadAttachmentDialog(stateParamsId, Indicator, stateParamsIds) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.height = '300px';
    dialogConfig.panelClass = 'attachment-dialog'
    this.dialogData.selectedItems = stateParamsIds;
    dialogConfig.data = {
      dialogform: this.dialogData
    }
    let dialogRef = this.matDialog.open(CopysheetFileComponent, dialogConfig);
    this.dialogData.dialogRef = dialogRef;
   var oldAttachment = JSON.parse(JSON.stringify(Indicator.items));
    dialogRef.afterClosed().subscribe(data => {
      //console.log(Indicator);
      if(stateParamsIds){
        for (let key in stateParamsIds) {
          let copyData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][stateParamsIds[key]].copy));
          Indicator['items'][stateParamsIds[key]].copy = copyData;
          let updatedNeedsNewCopysheet = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][stateParamsIds[key]]['needsNewCopysheet']));
          Indicator['items'][stateParamsIds[key]]['needsNewCopysheet'] = updatedNeedsNewCopysheet;
        }
      }else{
        let copyData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][stateParamsId].copy));
        Indicator['items'][stateParamsId].copy = copyData;
        let updatedNeedsNewCopysheet = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][stateParamsId]['needsNewCopysheet']));
        Indicator['items'][stateParamsId]['needsNewCopysheet'] = updatedNeedsNewCopysheet;
      }
      this.setBehaviorIsSaveBtnEnable(true);
      // update IA related code!!
      if (data) {
        if (Indicator.items[Indicator.stateParamsId].data
          && Indicator.items[Indicator.stateParamsId].data.iaGroupId) {
          //go through the other items on the order
          for (let key in Indicator.items) {
              //if the other item has the matching ia group id & isn't the current item
              if (Indicator.items[key].data
                  && Indicator.items[key].data.iaGroupId === Indicator.items[Indicator.stateParamsId].data.iaGroupId
                  && Indicator.items[key].order.svItemId !== Indicator.stateParamsId)
              {
                  //add the copysheet to the other item
                  if (Indicator.items[key].copy.attachments){
                    let attchemntArr = Indicator.items[key].copy.attachments;
                    let updatedAttachmentArr = [...new Set([...attchemntArr,...data])];
                    Indicator.items[key].copy.attachments = updatedAttachmentArr;
                  }else{
                    Indicator.items[key].copy['attachments'] = data;
                  }
                  Indicator['items'][key]['needsNewCopysheet'] = Indicator['items'][Indicator.stateParamsId]['needsNewCopysheet'];
       
                  this.CopysheetService.updateCopysheet(Indicator.items[key].order.svItemId + "v" + Indicator.items[key].order.version, Indicator.items[key].copy)
                  .then((response:any)=> {
                  }).catch(function (err) {
                      this.Logger.logError("Failed to update copysheet :" +JSON.stringify(err));
                  });
              }
              let oldAttachmentValue
              if(oldAttachment && oldAttachment[key] && oldAttachment[key].copy && oldAttachment[key].copy['attachments']){
                oldAttachmentValue = oldAttachment[key].copy['attachments'];
              }
              if(oldAttachmentValue && Indicator.items[key].copy.attachments && oldAttachmentValue.length !== Indicator.items[key].copy.attachments.length){
                var activityLogHistoryObjIa = {
                  'displayName': Indicator.user.name,
                  'email': Indicator.user.email,
                  'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                  'itemId': key,
                  'itemStatus': Indicator.items[key].order.status,
                  'itemActionCode':Indicator.items[key].order.actionCode + "-" + Indicator.items[key].order.actionType,
                  'fieldName': 'Copysheet Value',
                  'fieldOldValue': oldAttachmentValue.join(','),
                  'fieldNewValue': Indicator.items[key].copy.attachments.join(','),
                  'apiStatus' : 'Success'
                }
                this.StorageService.update("itemHistory", key, activityLogHistoryObjIa);
              }else if(!oldAttachmentValue && Indicator.items[key].copy.attachments){
                var activityLogHistoryObjIa = {
                  'displayName': Indicator.user.name,
                  'email': Indicator.user.email,
                  'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                  'itemId': key,
                  'itemStatus': Indicator.items[key].order.status,
                  'itemActionCode':Indicator.items[key].order.actionCode + "-" + Indicator.items[key].order.actionType,
                  'fieldName': 'Copysheet Value',
                  'fieldOldValue': null,
                  'fieldNewValue': Indicator.items[key].copy.attachments.join(','),
                  'apiStatus' : 'Success'
                }
                this.StorageService.update("itemHistory", key, activityLogHistoryObjIa);
              }
          }
          //update Indicator store
          this.indicatorStore.update(state => {
            return {
              indicator: Indicator,
              updatedFrom: "[ManagecopysheetService] openUploadAttachmentDialog",
            }
          })
         Indicator = JSON.parse(JSON.stringify(Indicator));
      }else {
        for (let key in Indicator.items) {
          let oldAttachmentValue
              if(oldAttachment && oldAttachment[key] && oldAttachment[key].copy && oldAttachment[key].copy['attachments']){
                oldAttachmentValue = oldAttachment[key].copy['attachments'];
              }
              if(oldAttachmentValue && Indicator.items[key].copy.attachments && oldAttachmentValue.length !== Indicator.items[key].copy.attachments.length){
                var activityLogHistoryObjIa = {
                  'displayName': Indicator.user.name,
                  'email': Indicator.user.email,
                  'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                  'itemId': key,
                  'itemStatus': Indicator.items[key].order.status,
                  'itemActionCode':Indicator.items[key].order.actionCode + "-" + Indicator.items[key].order.actionType,
                  'fieldName': 'Copysheet Value',
                  'fieldOldValue': oldAttachmentValue.join(','),
                  'fieldNewValue': Indicator.items[key].copy.attachments.join(','),
                  'apiStatus' : 'Success'
                }
                this.StorageService.update("itemHistory", key, activityLogHistoryObjIa);
              }else if(!oldAttachmentValue && Indicator.items[key].copy.attachments){
                var activityLogHistoryObjIa = {
                  'displayName': Indicator.user.name,
                  'email': Indicator.user.email,
                  'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                  'itemId': key,
                  'itemStatus': Indicator.items[key].order.status,
                  'itemActionCode':Indicator.items[key].order.actionCode + "-" + Indicator.items[key].order.actionType,
                  'fieldName': 'Copysheet Value',
                  'fieldOldValue': null,
                  'fieldNewValue': Indicator.items[key].copy.attachments.join(','),
                  'apiStatus' : 'Success'
                }
                this.StorageService.update("itemHistory", key, activityLogHistoryObjIa);
              }
        }
      }
      }

    });
  }

  convertImageToPdf(imageUrl: any, Indicator: any) {
    let pdf_Base64: any;
    let copySheetUrl: string;
    let fd = new FormData();
    let filename = Math.round(Math.random() * 1000000000000) + "";

    console.log('Inside convertImageToPdf method ', imageUrl);
    return new Promise(async (resolve, reject) => {
      try {
        // Trigger the file service to fetch pdf in base64 format
        pdf_Base64 = await this.file.imageToPdf(imageUrl);
        // Convert the pdf in base64 format to blob
        const base64Response = await fetch(`data:image/jpeg;base64,${pdf_Base64}`);
        const blob = await base64Response.blob();
        // Trigger the service to save the pdf as copysheet in mongo database
        fd.append("files", blob, filename + '.' + 'pdf');
        fd.append("user", JSON.parse(JSON.stringify({ parameters: { accountId: Indicator.order.accountId, svOrderId: Indicator.order.orderId } })));
        this.file.savePdf(fd).then(resp => {
          console.log('resp :: ', resp);
          resolve(resp['copysheet']);
        });
      } catch (error) {
        reject(error)
      }
    });
  }

  openUploadDialog(Indicator, stateParamsId) {
    let copyDialogData: any = {};
    if (Indicator.items[stateParamsId].isCoysheetAnnotated) {
      this.Logger.logError('Please save annotated copysheet.');
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = 'upload-copysheet-modal';
      dialogConfig.data = {
        dialogform: copyDialogData
      }
      this.spinnerService.hide();
      let dialogRef = this.matDialog.open(CopysheetUploadComponent, dialogConfig);
      copyDialogData.dialogRef = dialogRef
      var copysheet;

      dialogRef.afterClosed().subscribe(async data => {
        if (data) {
          let resData = { ...data };
          console.log("Response:", resData)
          let fileData: any = resData.fileObj;
          console.log("File obj", fileData)
          Indicator = JSON.parse(JSON.stringify(Indicator));
          Indicator.items[Indicator.stateParamsId].copy['uploadFile'] = {};
          Indicator.items[Indicator.stateParamsId].copy['uploadFile'] = resData;
          Indicator.items[Indicator.stateParamsId]['isCopySheetUploaded'] = true;
          // Adding flag for Header and footer copysheet
          Indicator.items[Indicator.stateParamsId].copy['headercopysheet'] = false;          
          //Adding Managecopymodified flag for copysheet-upload
          if (Indicator.items[stateParamsId].order.productType == "Digital" && Indicator.items[stateParamsId].order.productCode == "YPC") {
            Indicator.items[stateParamsId].model['manageCopyModified'] = true;
          } else {
            Indicator.items[stateParamsId].model['ManageCopysheet']['manageCopyModified'] = true;
          }
          console.log("UploadFile", Indicator.items[stateParamsId].copy['uploadFile']);
          this.indicatorStore.update(state => {
            return {
              indicator: Indicator,
              updatedFrom: "[ManageCopySheetService] openUploadDialog"
            }
          })
          this.indicatorQuery.getIndicator().subscribe(data => {
            Indicator = JSON.parse(JSON.stringify(data));
          });
          // let msg = "Please click on 'OK' for the copysheet to be replaced, Else click on 'CANCEL' and view/annotate for the copysheet to be added as annotation.";
          // const dialogConfig = new MatDialogConfig();
          // dialogConfig.disableClose = true;
          // dialogConfig.autoFocus = true;
          // dialogConfig.panelClass = 'confirmaton-dialog';
          // dialogConfig.data = {
          //   message: msg,
          // }
          // dialogConfig.width = '600px';
          // const dialogRefConfirm = this.matDialog.open(CallconfirmationComponent, dialogConfig);
          // dialogRefConfirm.afterClosed().subscribe(async dialogResult => {
          //   console.log(dialogResult)
          //   if(dialogResult){
          //     Indicator = JSON.parse(JSON.stringify(Indicator));
          //     Indicator.items[stateParamsId]['isCopySheetUploaded'] = false;
          //     this.indicatorStore.update(state => {
          //         return {
          //           indicator: Indicator,
          //           updatedFrom: "[ManageCopysheet Service] Dialog result upload copysheet"
          //         }
          //       })
          //       this.indicatorQuery.getIndicator().subscribe(data => {
          //       Indicator = JSON.parse(JSON.stringify(data));
          //     }); 
          //   }
          // });
            //if (dialogResult && Indicator.items[Indicator.stateParamsId].isCopySheetUploaded) {
              Indicator = JSON.parse(JSON.stringify(Indicator));
              if (data && data.uploadedFile.copysheet != null) {
                if (data.fileType === 'copysheet') {
                  Indicator.items[stateParamsId].isCoysheetSubmitted = true;
                  this.StorageService.updateField("copyz",
                    stateParamsId + "v" + Indicator.items[stateParamsId].order.version,
                    { "data.isCoysheetSubmitted": true }
                  ).subscribe(data => { }, err => { })
                }
                //Adding flag for view annotate and cancel for the first time => FUL-37657,37656  
                if (!Indicator.items[stateParamsId].hasCopysheet) {
                  Indicator.items[stateParamsId].copy['cancelCopySheet'] = true;
                }
                Indicator.items[stateParamsId].hasCopysheet = true;
                //Adding flag for adVersionIncInd of copysheet
                //Indicator.items[stateParamsId]['adVersionIncInd'] = 'Y';
                this.StorageService.updateField("item", stateParamsId, { "data.adVersionIncInd": "Y" }).subscribe(res => {
                  console.log(res)
                });

                let copsheetFileType = (data.uploadedFile.copysheet.split(".")[data.uploadedFile.copysheet.split(".").length - 1]).toLowerCase();
                let pdf_copysheet_url;
                console.log(copsheetFileType);
                if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {

                  await this.convertImageToPdf(data.uploadedFile.copysheet, Indicator).then((pdfurl) => {
                    console.log("pdfurl :: ", pdfurl);
                    pdf_copysheet_url = pdfurl;
                  }).catch((err) => {
                    console.log('Exception in converting image to PDF : ', err)
                  })
                } else {
                  pdf_copysheet_url = data.uploadedFile.copysheet;
                }

                if (Indicator.items[stateParamsId].order.statusCode >= 6000) {
                  Indicator.items[stateParamsId].needsNewCopysheet = true;
                }
                var oldCopysheet = (Indicator.items[stateParamsId].hasCopysheet == true) ? Indicator.items[stateParamsId].copy.copysheet : null;
                /*
                Indicator.items[stateParamsId].copy.copysheet = data.uploadedFile.copysheet;
                Indicator.items[stateParamsId].baseCopysheet = data.uploadedFile.copysheet;
                */
                Indicator.items[stateParamsId].copy.copysheet = pdf_copysheet_url;
                Indicator.items[stateParamsId].baseCopysheet = pdf_copysheet_url;
                // Indicator.items[stateParamsId].actionListObj = [];
                // copysheet = data.uploadedFile.copysheet;
                copysheet = pdf_copysheet_url;
                Indicator.items[stateParamsId].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                if (!oldCopysheet) {
                  this.updateIAGroupCounter(stateParamsId, Indicator);
                }
                Indicator = JSON.parse(JSON.stringify(Indicator));
                /*
                if (Indicator.items[stateParamsId].copy.fileType.toLowerCase() === 'png' || Indicator.items[stateParamsId].copy.fileType.toLowerCase() === 'jpeg' || Indicator.items[stateParamsId].copy.fileType.toLowerCase() === 'jpg') {
                  if (!Indicator.items[stateParamsId].copy.hasOwnProperty('attachments')) {
                    Indicator.items[stateParamsId].copy.attachments = [];
                    Indicator.items[stateParamsId].copy.attachments.push(Indicator.items[stateParamsId].copy.copysheet);
                  }
                  else {
                    Indicator.items[stateParamsId].copy['attachments'].push(Indicator.items[stateParamsId].copy.copysheet);
                  }
                }*/
                if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {
                  if (!Indicator.items[stateParamsId].copy.hasOwnProperty('attachments')) {
                    Indicator.items[stateParamsId].copy.attachments = [];
                    Indicator.items[stateParamsId].copy.attachments.push(data.uploadedFile.copysheet);
                  }
                  else {
                    Indicator.items[stateParamsId].copy['attachments'].push(data.uploadedFile.copysheet);
                  }
                }
                this.CopysheetService.updateCopysheet(stateParamsId + "v" + Indicator.items[stateParamsId].order.version, Indicator.items[stateParamsId].copy)
                  .then(response => {
                    var activityLogHistoryObj = {
                      'displayName': Indicator.user.name,
                      'email': Indicator.user.email,
                      'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                      'itemId': stateParamsId,
                      'itemStatus': Indicator.items[stateParamsId].order.status,
                      'itemActionCode': Indicator.items[stateParamsId].order.actionCode + "-" + Indicator.items[stateParamsId].order.actionType,
                      'fieldName': 'Copysheet Value',
                      'fieldOldValue': oldCopysheet,
                      'fieldNewValue': Indicator.items[stateParamsId].copy.copysheet,
                      'apiStatus': 'Success'
                    }
                    this.StorageService.update("itemHistory", stateParamsId, activityLogHistoryObj);
                  }
                    , err => {
                      this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                      var activityLogHistoryObj = {
                        'displayName': Indicator.user.name,
                        'email': Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                        'itemId': stateParamsId,
                        'itemStatus': Indicator.items[stateParamsId].order.status,
                        'itemActionCode': Indicator.items[stateParamsId].order.actionCode + "-" + Indicator.items[stateParamsId].order.actionType,
                        'fieldName': 'Copysheet Value',
                        'fieldOldValue': oldCopysheet,
                        'fieldNewValue': Indicator.items[stateParamsId].copy.copysheet,
                        'apiStatus': 'Failure'
                      }
                      this.StorageService.update("itemHistory", stateParamsId, activityLogHistoryObj);
                    });

                Indicator.items[stateParamsId].hasCopysheet = true;
                Indicator.items[stateParamsId].model['digitalApiFlag'] = true;
                // this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
                this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.baseCopysheet": data.uploadedFile.copysheet })
                  .subscribe(() => { }, err => { })
                // var activityLogHistoryObj = {
                //   'displayName': Indicator.user.name,
                //   'email': Indicator.user.email,
                //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
                //   'itemId': stateParamsId,
                //   'fieldName': 'Copysheet Value',
                //   'fieldOldValue': oldCopysheet,
                //   'fieldNewValue': Indicator.items[stateParamsId].copy.copysheet
                // }
                // this.StorageService.update("itemHistory", stateParamsId, activityLogHistoryObj);
                this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
                  .subscribe(() => { }, err => { })
                this.Logger.log('Copysheet uploaded successfully.');
                if (Indicator.items[stateParamsId].requiresCopysheet) {
                  let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
                  this.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
                  if (Indicator['items'][stateParamsId].counter == 0) {
                    document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
                  } else if (Indicator['items'][stateParamsId].counter != 0) {
                    document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
                  }
                }
                this.indicatorStore.update(state => {
                  return {
                    indicator: Indicator,
                    updatedFrom: "[ManagecopysheetService] storageupdate",
                  }
                })
                //Reverting the code for IA grp
                //if the item is part of an IA group
                if (Indicator.items[stateParamsId].data
                  && Indicator.items[stateParamsId].data.iaGroupId) {
                  Indicator = JSON.parse(JSON.stringify(Indicator));
                  //go through the other items on the order
                  for (var key in Indicator.items) {
                    //if the other item has the matching ia group id & isn't the current item
                    Indicator = JSON.parse(JSON.stringify(Indicator));
                    if (Indicator.items[key].data
                      && Indicator.items[key].data.iaGroupId === Indicator.items[stateParamsId].data.iaGroupId
                      && Indicator.items[key].order.svItemId !== stateParamsId) {
                      //add the copysheet to the other item
                      Indicator.items[key].copy.copysheet = data.uploadedFile.copysheet;
                      Indicator.items[key].baseCopysheet = data.uploadedFile.copysheet;
                      Indicator.items[key].hasCopysheet = true;
                      //Adding flag for adVersionIncInd of copysheet
                      //Indicator.items[key]['adVersionIncInd'] = 'Y';
                      this.StorageService.updateField("item", key, { "data.adVersionIncInd": "Y" }).subscribe(res => {
                        console.log(res)
                      });
                      // update copysheetIndicator value
                      if (Indicator.items[key].order.productType === "Print") {
                        if (Indicator.items[key].model.hasOwnProperty('ManageCopysheet')) {
                          Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                          Indicator.items[stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                        } else {
                          Indicator.items[key].model['ManageCopysheet'] = {};
                          Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                          Indicator.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                        }
                        Indicator.items[key].model['digitalApiFlag'] = true;
                      } else {
                        Indicator.items[key].model['copySheetIndicator'] = 'Y';
                        Indicator.items[stateParamsId].model['copySheetIndicator'] = 'Y';
                        Indicator.items[key].model['digitalApiFlag'] = true;
                      }
                      Indicator.items[key].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                      if (Indicator.items[key].copy.fileType.toLowerCase() === 'png' || Indicator.items[key].copy.fileType.toLowerCase() === 'jpeg' || Indicator.items[key].copy.fileType.toLowerCase() === 'jpg') {
                        if (!Indicator.items[key].copy.hasOwnProperty('attachments')) {
                          Indicator.items[key].copy.attachments = [];
                          Indicator.items[key].copy.attachments = Indicator.items[stateParamsId].copy.attachments;
                        }
                        else {
                          Indicator.items[key].copy['attachments'] = Indicator.items[stateParamsId].copy.attachments;
                        }
                      }
                      // if (Indicator.items[key].hasOwnProperty('actionListObj')) {
                      //   Indicator.items[key].actionListObj = Indicator.items[stateParamsId].actionListObj;
                      // }
                      this.updateIAGroupCounter(key, Indicator);
                      //update hasCopysheet flag in storage call
                      let currentStorageId = key + 'v' + Indicator.items[key].order.version;
                      this.StorageService.updateField("copyz", currentStorageId, { "data.hasCopysheet": true }).subscribe(() => { }, err => { });

                      this.CopysheetService.updateCopysheet(Indicator.items[key].order.svItemId + "v" + Indicator.items[key].order.version, Indicator.items[key].copy)
                        .then((response) => { })
                        .catch(
                          err => {
                            this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                          });
                          //fix for FUL-40365 --start
                      var activityLogHistoryObj = {
                        'displayName': Indicator.user.name,
                        'email': Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                        'itemId': key,
                        'itemStatus': Indicator.items[key].order.status,
                        'itemActionCode':Indicator.items[key].order.actionCode + "-" + Indicator.items[key].order.actionType,
                        'fieldName': 'Copysheet Value',
                        'fieldOldValue': oldCopysheet,
                        'fieldNewValue': Indicator.items[key].copy.copysheet,
                        'apiStatus' : 'Success'
                      }
                      this.StorageService.update("itemHistory", key, activityLogHistoryObj);
                    }
                  }
                }
              }
            //}
        }
        else if(!data){
          if (Indicator.items[stateParamsId].hasOwnProperty("hasCopysheet")  && !Indicator.items[stateParamsId]["hasCopysheet"]){
            Indicator.items[stateParamsId].copy.copysheet = null;
            let tempStorageId = stateParamsId + "v" + Indicator.items[stateParamsId].order.version;
            //this.Indicator.items[this.Indicator.stateParamsId]['baseCopysheet'] =null;
            this.CopysheetService.updateCopysheet(tempStorageId, Indicator.items[stateParamsId].copy)
                        .then(() => { }).catch((err) => {
                          this.Logger.logError("Failed to update copysheetII :" + JSON.stringify(err));
                        });
          }
        }
      });
    }
  }

  annotateCopysheet(Indicator, stateParamsId, page?) {
    let copysheet;
    let fileType;
    if (Indicator.items[stateParamsId].copy && Indicator.items[stateParamsId].copy.copysheet != 'null') {
      if (!Indicator.items[stateParamsId].model.copySheetSavePoints) {
        Indicator.items[stateParamsId].model.copySheetSavePoints = {}
      }
      if (Indicator.items[stateParamsId].copy.hasOwnProperty('attachments')) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.attachments = Indicator.items[stateParamsId].copy.attachments
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.attachments = []
      }
      Indicator.items[stateParamsId].model.copySheetSavePoints.copysheetSavePoint = Indicator.items[stateParamsId].copy.copysheet
      if (Indicator.items[stateParamsId].actionListObj) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.actionListObj = Indicator.items[stateParamsId].actionListObj
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.actionListObj = []
      }
      //Indicator.items[stateParamsId].model.copySheetSavePoints.ManageCopysheet = Indicator.items[stateParamsId].model.ManageCopysheet
      if (typeof (Indicator.items[stateParamsId].model.ManageCopysheet) == 'object') {
        Indicator.items[stateParamsId].model.copySheetSavePoints.ManageCopysheet = Object.assign({}, Indicator.items[stateParamsId].model.ManageCopysheet)
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.ManageCopysheet = Indicator.items[stateParamsId].model.ManageCopysheet
      }
      if (Indicator.items[stateParamsId].hasCopysheet) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.hasCopysheet = Indicator.items[stateParamsId].hasCopysheet
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.hasCopysheet = false
      }
      if (Indicator.items[stateParamsId].isCorrupted) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.isCorrupted = Indicator.items[stateParamsId].isCorrupted
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.isCorrupted = false
      }
      if (Indicator.items[stateParamsId].isCoysheetSubmitted) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = Indicator.items[stateParamsId].isCoysheetSubmitted
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = false
      }
      if (Indicator.items[stateParamsId].generatedCopysheet) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.generatedCopysheet = Indicator.items[stateParamsId].generatedCopysheet
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.generatedCopysheet = false
      }
      if (Indicator.items[stateParamsId].isCopysheetSaved) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.isCopysheetSaved = Indicator.items[stateParamsId].isCopysheetSaved
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.isCopysheetSaved = false
      }
      if (Indicator.items[stateParamsId].needsNewCopysheet) {
        Indicator.items[stateParamsId].model.copySheetSavePoints.needsNewCopysheet = Indicator.items[stateParamsId].needsNewCopysheet
      } else {
        Indicator.items[stateParamsId].model.copySheetSavePoints.needsNewCopysheet = false
      }

      this.indicatorStore.update(state => {
        return {
          indicator: Indicator,
          updatedFrom: "[ManagecopysheetService] annotateCopysheet savePoint",
        }
      })
      var CSfileType = "";
      copysheet = Indicator.items[stateParamsId].baseCopysheet ? Indicator.items[stateParamsId].baseCopysheet : Indicator.items[stateParamsId].copy.copysheet;
      if (copysheet) {
        var CSURL = copysheet;
        CSfileType = CSURL.slice(CSURL.lastIndexOf(".") + 1, CSURL.length);
      }
      if (copysheet) {
        //updateCopysheet indicator
        if (Indicator.items[stateParamsId].model['ManageCopysheet'] && typeof (Indicator.items[stateParamsId].model['ManageCopysheet']) == "object") {
          Indicator.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
        }
        else {
          Indicator.items[stateParamsId].model['ManageCopysheet'] = 'Y';
        }
        Indicator.items[stateParamsId].model['digitalApiFlag'] = true;
        var fileNameLocal = copysheet.split('/').pop();
        if (CSfileType == 'pdf') {
          try {
            this.file.validatePDF(fileNameLocal).subscribe(res => {
              if (res['data'] == "Invalid PDF structure") {
                this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");

                Indicator.items[stateParamsId].hasCopysheet = false;
                this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
                  .subscribe(() => { }, err => { })

                Indicator.items[stateParamsId].copy.copysheet = null;
                Indicator.items[stateParamsId].copy.copysheet = null;
                Indicator.items[stateParamsId].copy.fileType = '';
                if (Indicator.items[stateParamsId].requiresCopysheet) {
                  // Indicator.items[stateParamsId].counter++;
                  let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
                  this.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
                  if (Indicator['items'][stateParamsId].counter == 0) {
                    document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
                  } else if (Indicator['items'][stateParamsId].counter != 0) {
                    document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
                  }
                }
                // pdfModalInstance.afterClosed().subscribe(dialogResult => {
                //   //console.log('dialogue has benn close with error : ', dialogResult);
                // });
              } else { }
            }, err => {
              //console.log("error in vlaidating the pdf file ", err);
              this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");
              return false;
            });
          } catch (e) {
            //console.log(" error in annotate copy sheet ", e)
            this.Logger.logError("Timeout Error");
          }
        } else if (CSfileType == 'png' || CSfileType == 'jpg' || CSfileType == 'jpeg') {
          this.file.validateImage(fileNameLocal).subscribe((res) => {
            //console.log("Image validation", res['data']);
            if (res['data'] == false) {
              //console.log("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed!");

              this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
                .subscribe(() => { }, err => { })
              Indicator.items[stateParamsId].copy.copysheet = null;
              Indicator.items[stateParamsId].copy.copysheet = null;
              Indicator.items[stateParamsId].copy.fileType = '';
              Indicator.items[stateParamsId].hasCopysheet = false;
              if (Indicator.items[stateParamsId].requiresCopysheet) {
                // Indicator.items[stateParamsId].counter++;
                let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
                this.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
                if (Indicator['items'][stateParamsId].counter == 0) {
                  document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
                } else if (Indicator['items'][stateParamsId].counter != 0) {
                  document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
                }
              }
              // this.pdfModalInstance.close();
            }
          });
        }
      }
      fileType = Indicator.items[stateParamsId].copy.fileType;
      if ((fileType && CSfileType && fileType !== CSfileType)
        || (!fileType && CSfileType)) {
        fileType = CSfileType;
      } else if (!fileType && !CSfileType) {
        this.Logger.logError("Failed to open copysheet. Please upload the copysheet again.");
        return;
      }
      Indicator.items[stateParamsId].baseCopysheet = copysheet;
      // let actionListObj = Indicator.items[stateParamsId].actionListObj;

      // const dialogConfig = new MatDialogConfig();
      // dialogConfig.disableClose = true;
      // dialogConfig.panelClass = 'view-annotate-dialog';
      // dialogConfig.data = {
      //   dialogform: this.dialogData,
      //   stateParmId: stateParamsId
      // }

      // let pdfModalInstance: any;
      // this.spinnerService.hide();
      // pdfModalInstance = this.matDialog.open(CopysheetAnnotateComponent, dialogConfig);

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'confirmaton-dialog-foxit';
      let pdfModalInstance: any;
      this.spinnerService.hide();

      pdfModalInstance = this.matDialog.open(PDFViewerComponent, dialogConfig);

      this.dialogData['dilogReference'] = pdfModalInstance;
      pdfModalInstance.afterClosed().subscribe(dialogResult => {
      console.log('Annotate dialogue has benn close : ', dialogResult);
      /*
       Need to update the indicator while coming back from copysheet annotate to avoid console errors,
       while modifying copysheet again.
      */
        this.setBehaviorViewGraphic(true);
        this.indicatorQuery.getIndicator().subscribe(data => {
          Indicator = JSON.parse(JSON.stringify(data));
        });
        if (dialogResult){
          //let itemCopysheet = Indicator.items[Indicator.stateParamsId].copy.copySheet;
          if (Indicator.items[Indicator.stateParamsId].data
            && Indicator.items[Indicator.stateParamsId].data.iaGroupId) {
            //go through the other items on the order
            for (var key in Indicator.items) {
              //if the other item has the matching ia group id & isn't the current item
              // Indicator = JSON.parse(JSON.stringify(Indicator));
              if (Indicator.items[key].data
                && Indicator.items[key].data.iaGroupId === Indicator.items[Indicator.stateParamsId].data.iaGroupId
                && Indicator.items[key].order.svItemId !== Indicator.stateParamsId) {
                //add the copysheet to the other item
                //Indicator.items[key].copy.instructions = this.addlInstructions;
                Indicator.items[key].copy['copysheet'] = Indicator.items[Indicator.stateParamsId].copy['copysheet'];
                Indicator.items[key].copy['fileType'] = Indicator.items[Indicator.stateParamsId].copy['fileType'];

                Indicator.items[key]['baseCopysheet'] = Indicator.items[Indicator.stateParamsId].baseCopysheet;
                // Indicator.items[key].actionListObj = Indicator.items[Indicator.stateParamsId].actionListObj;
                Indicator.items[key]['hasCopysheet'] = true;
                // update copysheetIndicator value 
                // set manageCopyModifiedGraphic valur ot true after view annotate
                if (Indicator.items[key].order.productType === "Print"){
                  if (Indicator.items[key].model.hasOwnProperty('ManageCopysheet')){
                      Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                      Indicator.items[Indicator.stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                      Indicator.items[key].model['ManageCopysheet']['manageCopyModifiedGraphic'] = true;
                  }else{
                      Indicator.items[key].model['ManageCopysheet'] = {};
                      Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                      Indicator.items[Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                      Indicator.items[key].model['ManageCopysheet']['manageCopyModifiedGraphic'] = true;
                  }
                  Indicator.items[key].model['digitalApiFlag'] = true;
                  this.StorageService.updateField("item", 
                  key + "v" + Indicator.items[key].order.version, 
                  { "data.ManageCopysheet.manageCopyModifiedGraphic": Indicator.items[key].model.ManageCopysheet.manageCopyModifiedGraphic })
                  .subscribe(res => {});
                }else{
                      Indicator.items[key].model['copySheetIndicator'] = 'Y';
                      Indicator.items[Indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
                      Indicator.items[key].model['digitalApiFlag'] = true;
                  Indicator.items[key].model['manageCopyModifiedGraphic'] = true;
                  this.StorageService.updateField("item", 
                  key + "v" + Indicator.items[key].order.version, 
                  { "data.manageCopyModifiedGraphic": Indicator.items[key].model.manageCopyModifiedGraphic })
                  .subscribe(res => {});
                }
                Indicator.items[Indicator.stateParamsId].copy['directoryNumber'] = Indicator.items[Indicator.stateParamsId].order.directoryNumber;
                Indicator.items[Indicator.stateParamsId].copy['directoryIssueNumber'] = Indicator.items[Indicator.stateParamsId].order.directoryIssueNumber;
                this.CopysheetService.updateCopysheet(Indicator.items[key].order.svItemId + "v" + Indicator.items[key].order.version, Indicator.items[key].copy)
                  .then(function () { })
                  .catch(function (err) {
                    this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                  });
                // storage update--Ashutosh
                this.StorageService.updateField("copyz", key + "v"+ Indicator.items[key].order.version, {"data.hasCopysheet" : true}).subscribe(data =>{},err =>{});
                this.updateIAGroupCounter(key, Indicator);
                Indicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
              }
            }
          }
          Indicator.items[Indicator.stateParamsId].copy['cancelCopySheet']  = false;
          this.CopysheetService.updateCopysheet(this.storageID, Indicator.items[Indicator.stateParamsId].copy)
                      .then(() => { }).catch((err) => {
                        this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                      });
        }
        else {
          if(Indicator.items[Indicator.stateParamsId].copy.hasOwnProperty('cancelCopySheet') && !Indicator.items[Indicator.stateParamsId].copy['cancelCopySheet']  && !Indicator.items[Indicator.stateParamsId].hasCopysheet){
            Indicator.items[Indicator.stateParamsId].copy.copysheet = null;
            //Indicator.items[Indicator.stateParamsId]['baseCopysheet'] =null;
            this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
            .subscribe(() => { }, err => { })
            this.CopysheetService.updateCopysheet(this.storageID, Indicator.items[Indicator.stateParamsId].copy)
                      .then(() => { }).catch((err) => {
                        this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                      });
            if (Indicator.items[Indicator.stateParamsId].model.ManageCopysheet && typeof Indicator.items[Indicator.stateParamsId].model.ManageCopysheet === "object") {
              Indicator.items[Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'N';
            } else {
              Indicator.items[Indicator.stateParamsId].model.ManageCopysheet = 'N';
            }
          }
          if (!Indicator.items[Indicator.stateParamsId].copy.copysheet) {
            Indicator.items[Indicator.stateParamsId].copy['cancelCopySheet'] = false;
            this.CopysheetService.updateCopysheet(this.storageID, Indicator.items[Indicator.stateParamsId].copy)
              .then(() => { }).catch((err) => {
                this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
              });
          }            
        }
        this.indicatorStore.update(state => {
          return {
            indicator: Indicator,
            updatedFrom: "[ManagecopysheetService] annotateCopysheet",
          }
        })
      });
      console.log('Inside opening new window')
      console.log('Cookies before opening child window : ', this.cookieService.getAll());
      console.log('Params before opening child window : ', this.Params.params);
      // if (!(this.cookieService.get('token').length > 0)) {        
      //   for (let key in window['cookies']) {          
      //     this.cookieService.set(key, window['cookies'].key)
      //   }
      // }      
      //let childWindowUrl = this.API.pdfUrl + "/pdf?token=" + this.cookieService.get('token') + "&locked=false&admin=false&api=" + this.API.apiUrl + "&payNow=" + this.cookieService.get('payNow') + "&sfaUrl=" + this.cookieService.get('sfaUrl') + "&sideMenuPayNow=" + this.cookieService.get('sideMenuPayNow') + "&sforg=national&account= " + Indicator.account.id + "&order=" + Indicator.order.sfdcOrderId + "&mode=QuickBP&listingId=";
      // let childWindowUrl = this.API.pdfUrl + "/pdf?&api=" + this.API.apiUrl + "&account=" + Indicator.account.id + "&order=" + Indicator.order.sfdcOrderId + "&mode=copysheet"
      // console.log('childWindowUrl :: ', childWindowUrl);
      // this.spinnerService.show();
      // winReference = window.open(childWindowUrl);
      // console.log(winReference)
      // winReference['parentIndicator'] = Indicator;
      // // if (this.cookieService.get('token').length > 0) {
      // //   winReference['cookies'] = this.cookieService.getAll();
      // //   window['cookies'] = this.cookieService.getAll();
      // // }
      // timer = setInterval(this.checkPdfWindow, 500);
    }
  }

  // checkPdfWindow() {
  //   if (winReference.closed) {
  //     // logic to enable the current window elemnents
  //     console.log("PDF window is closed");
  //     // console.log(winReference['childValue']);// Data fetched from child window                  
  //     clearInterval(timer);// remove the logic to check for closure at regular intervals
  //     self.closeViewAnnotate();
  //   }
  // }
  // closeViewAnnotate() {
  //   let Indicator;
  //   console.log(winReference['childIndicator']);
  //   // Close the spinner
  //   this.spinnerService.hide();
  //   //this.setBehaviorViewGraphic(true);
  //   // Update the indicator store with the indicator received from child window
  //   if (winReference['childIndicator']) {
  //     console.log('Indicator is updated from the child window');
  //     console.log(' Indicator after closing child window  : ', winReference['childIndicator'])
  //     this.indicatorStore.update(() => {
  //       return {
  //         indicator: winReference['childIndicator'],
  //         updatedFrom: "[ManagecopysheetService] annotateCopysheet",
  //       };

  //     });
  //   }
  //   this.setBehaviorViewGraphic(true);
  //   this.indicatorQuery.getIndicator().subscribe(data => {
  //     Indicator = JSON.parse(JSON.stringify(data));
  //   });
  //   if (winReference['closingBoolean']) {
  //     //let itemCopysheet = Indicator.items[Indicator.stateParamsId].copy.copySheet;
  //     if (Indicator.items[Indicator.stateParamsId].data
  //       && Indicator.items[Indicator.stateParamsId].data.iaGroupId) {
  //       //go through the other items on the order
  //       for (var key in Indicator.items) {
  //         //if the other item has the matching ia group id & isn't the current item
  //         // Indicator = JSON.parse(JSON.stringify(Indicator));
  //         if (Indicator.items[key].data
  //           && Indicator.items[key].data.iaGroupId === Indicator.items[Indicator.stateParamsId].data.iaGroupId
  //           && Indicator.items[key].order.svItemId !== Indicator.stateParamsId) {
  //           //add the copysheet to the other item
  //           //Indicator.items[key].copy.instructions = this.addlInstructions;
  //           Indicator.items[key].copy['copysheet'] = Indicator.items[Indicator.stateParamsId].copy['copysheet'];
  //           Indicator.items[key].copy['fileType'] = Indicator.items[Indicator.stateParamsId].copy['fileType'];

  //           Indicator.items[key]['baseCopysheet'] = Indicator.items[Indicator.stateParamsId].baseCopysheet;
  //           // Indicator.items[key].actionListObj = Indicator.items[Indicator.stateParamsId].actionListObj;
  //           Indicator.items[key]['hasCopysheet'] = true;
  //           // update copysheetIndicator value 
  //           if (Indicator.items[key].order.productType === "Print") {
  //             if (Indicator.items[key].model.hasOwnProperty('ManageCopysheet')) {
  //               Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //               Indicator.items[Indicator.stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //               Indicator.items[key].model['ManageCopysheet']['manageCopyModifiedGraphic'] = true;
  //             } else {
  //               Indicator.items[key].model['ManageCopysheet'] = {};
  //               Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //               Indicator.items[Indicator.stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //               Indicator.items[key].model['ManageCopysheet']['manageCopyModifiedGraphic'] = true;
  //             }
  //             Indicator.items[key].model['digitalApiFlag'] = true;
  //             this.StorageService.updateField("item",
  //               key + "v" + Indicator.items[key].order.version,
  //               { "data.ManageCopysheet.manageCopyModifiedGraphic": Indicator.items[key].model.ManageCopysheet.manageCopyModifiedGraphic })
  //               .subscribe(res => { });
  //           } else {
  //             Indicator.items[key].model['copySheetIndicator'] = 'Y';
  //             Indicator.items[Indicator.stateParamsId].model['copySheetIndicator'] = 'Y';
  //             Indicator.items[key].model['digitalApiFlag'] = true;
  //             Indicator.items[key].model['manageCopyModifiedGraphic'] = true;
  //             this.StorageService.updateField("item",
  //               key + "v" + Indicator.items[key].order.version,
  //               { "data.manageCopyModifiedGraphic": Indicator.items[key].model.manageCopyModifiedGraphic })
  //               .subscribe(res => { });
  //           }
  //           Indicator.items[Indicator.stateParamsId].copy['directoryNumber'] = Indicator.items[Indicator.stateParamsId].order.directoryNumber;
  //           Indicator.items[Indicator.stateParamsId].copy['directoryIssueNumber'] = Indicator.items[Indicator.stateParamsId].order.directoryIssueNumber;
  //           this.CopysheetService.updateCopysheet(Indicator.items[key].order.svItemId + "v" + Indicator.items[key].order.version, Indicator.items[key].copy)
  //             .then(function () { })
  //             .catch(function (err) {
  //               this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //             });
  //           // storage update--Ashutosh
  //           this.StorageService.updateField("copyz", key + "v" + Indicator.items[key].order.version, { "data.hasCopysheet": true }).subscribe(data => { }, err => { });
  //           this.updateIAGroupCounter(key, Indicator);
  //           Indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
  //         }
  //         Indicator.items[Indicator.stateParamsId].copy['cancelCopySheet'] = false;
  //         this.CopysheetService.updateCopysheet(this.storageID, Indicator.items[Indicator.stateParamsId].copy)
  //           .then(() => { }).catch((err) => {
  //             this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //           });
  //       }        
  //     }      
  //   }
  //   else {
  //     if (Indicator.items[Indicator.stateParamsId].copy.hasOwnProperty('cancelCopySheet') && !Indicator.items[Indicator.stateParamsId].copy['cancelCopySheet'] && !Indicator.items[Indicator.stateParamsId].hasCopysheet) {
  //       Indicator.items[Indicator.stateParamsId].copy.copysheet = null;
  //       //Indicator.items[Indicator.stateParamsId]['baseCopysheet'] =null;
  //       this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
  //         .subscribe(() => { }, err => { })
  //       this.CopysheetService.updateCopysheet(this.storageID, Indicator.items[Indicator.stateParamsId].copy)
  //         .then(() => { }).catch((err) => {
  //           this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //         });
  //       if (Indicator.items[Indicator.stateParamsId].model.ManageCopysheet && typeof Indicator.items[Indicator.stateParamsId].model.ManageCopysheet === "object") {
  //         Indicator.items[Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'N';
  //       } else {
  //         Indicator.items[Indicator.stateParamsId].model.ManageCopysheet = 'N';
  //       }
  //     }
  //     if (!Indicator.items[Indicator.stateParamsId].copy.copysheet) {
  //       Indicator.items[Indicator.stateParamsId].copy['cancelCopySheet'] = false;
  //       this.CopysheetService.updateCopysheet(this.storageID, Indicator.items[Indicator.stateParamsId].copy)
  //         .then(() => { }).catch((err) => {
  //           this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //         });
  //     }
  //   }
  //   this.indicatorStore.update(state => {
  //     return {
  //       indicator: Indicator,
  //       updatedFrom: "[ManagecopysheetService] annotateCopysheetII",
  //     }
  //   })
  //   /* Reset the cookies */
  //   // if (!(this.cookieService.get('token') && this.cookieService.get('token').length > 0)){
  //   // console.log('calling params from manageCopySheet service')
  //   // this.Params.params.mode =  null;
  //   // this.Params.getParamsData(this.Params.params, this.Params.secFlag)
  //   // }
  //   // console.log('cookie service after child window is closed : ', this.cookieService.getAll());
  //   // console.log('params after child window is closed : ', this.Params.params);
  // }
  uploadCopysheetData(action, stateId, indicatorValue, page?) {
    if (!indicatorValue.items[stateId].generatedCopysheet) {
      this.generateCopysheet(stateId, indicatorValue, action, null, page);
    } else {
      this.openUploadDialog(indicatorValue, stateId);
    }
  }

  openAdBasedDialog(stateParamsId, Indicator) {
    //this.valueToPassAdBased = "I must be passed";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'set-based-modal';
    dialogConfig.data = {
      dialogform: this.dialogData,
    }
    let modalInstance = this.matDialog.open(AdBasedComponent, dialogConfig);
    this.dialogData['dilogReference'] = modalInstance;
    modalInstance.afterClosed().subscribe(data => {
      this.indicatorQuery.getIndicator().subscribe(data => {
        Indicator = JSON.parse(JSON.stringify((data)));
      });
      /* if (data) {
        if (data.displayAdId) {
          this.clickedAd = true;
        } else {
          this.clickedAd = false;
        }
      } */
      if (Indicator.items[stateParamsId].order.statusCode >= 6000) {
        Indicator.items[stateParamsId].needsNewCopysheet = true;
        this.indicatorStore.update(state => {
          return {
            indicator: Indicator,
            updatedFrom: "[ManagecopysheetService] openAdBasedDialog",
          }
        })
      }
    });
  }

  updateStore(addlInstructions, stateParamsId, indicator) {

    if (addlInstructions != null) {
      indicator.items[stateParamsId].copy.instructions = addlInstructions;
      this.CopysheetService.updateCopysheet(stateParamsId + "v" + indicator.items[stateParamsId].order.version, indicator.items[stateParamsId].copy)
        .then(function () { })
        .catch(function (err) {
          this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
        });

      indicator.items[stateParamsId].needsNewCopysheet = true;

      //Reverting the code for IA grp
      //if the item is part of an IA group
      if (indicator.items[stateParamsId].data
        && indicator.items[stateParamsId].data.iaGroupId) {
        //go through the other items on the order
        for (var key in indicator.items) {
          //if the other item has the matching ia group id & isn't the current item
          indicator = JSON.parse(JSON.stringify(indicator));
          if (indicator.items[key].data
            && indicator.items[key].data.iaGroupId === indicator.items[stateParamsId].data.iaGroupId
            && indicator.items[key].order.svItemId !== stateParamsId) {
            //add the copysheet to the other item
            indicator.items[key].copy.instructions = addlInstructions;
            this.CopysheetService.updateCopysheet(indicator.items[key].order.svItemId + "v" + indicator.items[key].order.version, indicator.items[key].copy)
              .then(function () { })
              .catch(function (err) {
                this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
              });
          }
        }
      }
      this.indicatorStore.update(state => {
        return {
          indicator: indicator,
          updatedFrom: "[ManagecopysheetService] updateStore",
        }
      });
    }
  }
  //Below update the counter code
  private updateIndictor(itemId, data, tempIndicator, currentCount) {
    let counter = [];
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    this.updatCounterStore(data);
  }
  private updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[ManagecopysheetService] updateIndictor",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);

  }

  private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
  }
  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }
  // newGenerateCopysheet(stateParamsId, indicatorValue, genType, openAttachment?, page?) {

  //   return new Promise(async (resolve) => {
  //     if (genType === "view") {
  //       this.loadingCopysheet = true;
  //     } else if (genType === "upload") {
  //       this.loadingUpload = true;
  //     } else if (genType === "annotate") {
  //       this.loadingAnnotate = true;
  //     }

  //     Promise.all([
  //       this.accountService.getMetoPromise(),
  //       this.orderService.getMeToPromise(indicatorValue.order['sfdcOrderId'])
  //     ]).then((results) => {
  //       let account = results[0];
  //       let order = results[1];
  //       let promise = null;
  //       let id = null;
  //       if ((account.parentId != null && account.kgenCustomerId == null) || indicatorValue.account.kgenCustomerId) {
  //         account.kgenCustomerId = indicatorValue.account.kgenCustomerId;
  //       }
  //       if (indicatorValue.items[stateParamsId].order.productType === "Digital") {
  //         promise = this.profileService.getByEid(account.enterpriseAccountId);
  //         id = account.enterpriseAccountId;
  //       } else if (indicatorValue.items[stateParamsId].order.productType === "Print") {
  //         promise = this.profileService.getAllPrintListing(account.kgenCustomerId, {
  //           productcode: indicatorValue.items[stateParamsId].order.directoryNumber,
  //           productissuenum: indicatorValue.items[stateParamsId].order.directoryIssueNumber,
  //           sectioncode: indicatorValue.items[stateParamsId].order.section
  //         });
  //         id = account.kgenCustomerId;
  //       }
  //       if (promise) {
  //         promise.then((response) => {
  //           let data = response[id];
  //           if (data.hasWarningOrError !== "true") {
  //             let pageRef = page == "UPLOAD-COPYSHEET-ORDERITEMPAGE" ? "UPLOADCOPYSHEET-ORDERITEMPAGE" : "copysheet";

  //             this.itemService.update(account, order, indicatorValue.items[stateParamsId], data.listings.listing, stateParamsId, pageRef)
  //               .then((printRes) => {
  //                 let copySheetReqData = {
  //                   "type": "generateCopySheet",
  //                   "id": "",
  //                   "callerId": "UCC",
  //                   "correlationId": this.groupService.getAllItemsOfGroupButNotSelf(stateParamsId, indicatorValue).toString(),
  //                   "sourceId": "",
  //                   "copySheet": {
  //                     "svOrderId": order['orderId'],
  //                     "customerId": account.kgenCustomerId,
  //                     "svItemId": stateParamsId,
  //                     "productCode": (indicatorValue.items[stateParamsId].order.productType === "Digital") ? indicatorValue.items[stateParamsId].order.productCode : indicatorValue.items[stateParamsId].order.directoryNumber,
  //                     "productIssueNum": indicatorValue.items[stateParamsId].order.directoryIssueNumber,
  //                     "documentType": (indicatorValue.items[stateParamsId].order.actionCode === "Renew") ? "A" : "N",
  //                     "operatorId": order['opportunity'].owner.operatorId,
  //                     "date": this.utilsService.getKgenDate(new Date())
  //                   }
  //                 };
  //                 this.CopysheetService.getCopySheets(copySheetReqData, stateParamsId, indicatorValue.items[stateParamsId].order.version, page)
  //                   .then(async (data) => {
  //                     this.loadingCopysheet = false;
  //                     this.loadingUpload = false;
  //                     this.loadingAnnotate = false;

  //                     if (data && data['response'] && data['response'] == null || data['response'] == undefined) {
  //                       if (indicatorValue.items[stateParamsId].order.statusCode >= 6000) {
  //                         indicatorValue.items[stateParamsId].needsNewCopysheet = true;
  //                       }

  //                       indicatorValue.items[stateParamsId].copy.copysheet = data['copysheet'];
  //                       /*
  //                       This is added to avoid the blank screen when clicked on view/annotate from IA group of footer
  //                       */
  //                       if (!indicatorValue.items[stateParamsId].hasOwnProperty("baseCopysheet") || indicatorValue.items[stateParamsId]['baseCopysheet'] == null || indicatorValue.items[stateParamsId]['baseCopysheet'] == undefined || indicatorValue.items[stateParamsId]['baseCopysheet'] == '') {
  //                         indicatorValue.items[stateParamsId]['baseCopysheet'] = data['copysheet'];
  //                       }
  //                       indicatorValue.items[stateParamsId].copy.fileType = 'pdf';
  //                       await this.CopysheetService.updateCopysheet(this.storageID, indicatorValue.items[stateParamsId].copy)
  //                         .then(() => { }).catch((err) => {
  //                           this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
  //                           resolve(false);
  //                         });
  //                       console.log("genType : ", genType)
  //                       if (genType === "view") {
  //                         var win = window.open(data['copysheet']);
  //                         if (win) {
  //                           win.focus();
  //                         }
  //                       } else if (genType === "upload") {
  //                         openAttachment ? this.openUploadAttachmentDialog(stateParamsId, indicatorValue, null) : this.openUploadDialog(indicatorValue, stateParamsId);
  //                       } else if (genType === 'annotate') {

  //                         this.indicatorStore.update(state => {
  //                           return {
  //                             indicator: indicatorValue,
  //                             updatedFrom: "[ManagecopysheetService] getCopysheets",
  //                           }
  //                         })
  //                         this.indicatorQuery.getIndicator().subscribe(data => {
  //                           indicatorValue = { ...data }
  //                         });
  //                         await this.newAnnotateCopysheet(indicatorValue, stateParamsId)
  //                       }
  //                       this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": false })
  //                         .subscribe(() => { }, err => { console.log(" erro in copy z call") });
  //                       if (openAttachment !== undefined) {
  //                         console.log(" item componenet save button");
  //                       }

  //                       //Reverting the code for IA grp
  //                       if (indicatorValue.items[stateParamsId].data
  //                         && indicatorValue.items[stateParamsId].data.iaGroupId) {
  //                         //go through the other items on the order
  //                         for (let key in indicatorValue.items) {
  //                           indicatorValue = JSON.parse(JSON.stringify(indicatorValue));
  //                           if (indicatorValue.items[key].data
  //                             && indicatorValue.items[key].data.iaGroupId === indicatorValue.items[stateParamsId].data.iaGroupId
  //                             && indicatorValue.items[key].order.svItemId !== stateParamsId) {
  //                             //add the copysheet to the other item
  //                             indicatorValue.items[key].copy.copysheet = data['copysheet'];
  //                             indicatorValue.items[key].copy.fileType = 'pdf';
  //                             indicatorValue.items[key].hasCopysheet = true;
  //                             // update copysheetIndicator value
  //                             if (indicatorValue.items[key].order.productType === "Print") {
  //                               if (indicatorValue.items[key].model.hasOwnProperty('ManageCopysheet')) {
  //                                 indicatorValue.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //                                 indicatorValue.items[stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
  //                               } else {
  //                                 indicatorValue.items[key].model['ManageCopysheet'] = {};
  //                                 indicatorValue.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //                                 indicatorValue.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //                               }
  //                               indicatorValue.items[key].model['digitalApiFlag'] = true;
  //                             } else {
  //                               indicatorValue.items[key].model['copySheetIndicator'] = 'Y';
  //                               indicatorValue.items[stateParamsId].model['copySheetIndicator'] = 'Y';
  //                               indicatorValue.items[key].model['digitalApiFlag'] = true;
  //                             }

  //                             await this.CopysheetService.updateCopysheet(indicatorValue.items[key].order.svItemId + "v" + indicatorValue.items[key].order.version, indicatorValue.items[key].copy)
  //                               .then(res => { }).catch(err => { console.log(" Error in update copy sheet ", err) });
  //                             this.updateIAGroupCounter(key, indicatorValue);
  //                           }
  //                         }
  //                       }
  //                       resolve(true);
  //                     } else if (data['response'].hasWarningOrError === "true") {
  //                       this.Params['saving'] = false;
  //                       this.Params['loadingbar'] = false;
  //                       this.loadingUpload = false;
  //                       var errorObject = data['response'].esbServiceFault;
  //                       var errorCode = errorObject.sourceSystem;
  //                       var errorDesc = errorObject.exceptionDetail[0].errorDescription;
  //                       var errorMsg = "<strong>Transaction : " + data['response'].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
  //                       this.Logger.logError(errorMsg);
  //                       resolve(false);
  //                     } else {
  //                       this.Params['saving'] = false;
  //                       this.Params['loadingbar'] = false;
  //                       this.loadingUpload = false;
  //                       this.Logger.logError(data['message']);
  //                       resolve(false);
  //                     }
  //                   })
  //                   .catch((response) => {
  //                     this.loadingUpload = false;
  //                     this.loadingCopysheet = false;
  //                     this.loadingUpload = false;
  //                     if (response.response && response.response.hasWarningOrError == "true") {
  //                       var errorObject = response.response.esbServiceFault;
  //                       var errorCode = errorObject.sourceSystem;
  //                       var errorDesc = errorObject.exceptionDetail[0].errorDescription;
  //                       var errorMsg = "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
  //                       this.Logger.logError(errorMsg);
  //                       resolve(false);
  //                     } else {
  //                       this.Logger.logError(response.message);
  //                       resolve(false);
  //                     }
  //                   });
  //               })
  //               .catch(err => {
  //                 this.loadingUpload = false;
  //                 this.Params['saving'] = false;
  //                 this.Params['loadingbar'] = false;
  //                 resolve(false);
  //               });
  //           } else {
  //             this.loadingUpload = false;
  //             this.Params['saving'] = false;
  //             this.Params['loadingbar'] = false;
  //             var errorObject = data.esbServiceFault[0];
  //             var errorCode = errorObject.sourceSystem;
  //             var errorDesc = errorObject.exceptionDetail[0].errorDescription;
  //             var errorMsg = "<strong>Transaction : " + data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
  //             this.Logger.logError(errorMsg);
  //             resolve(false);
  //           }
  //         }).catch((response) => {
  //           this.loadingUpload = false;
  //           this.Params['saving'] = false;
  //           this.Params['loadingbar'] = false;
  //           resolve(false);
  //         });
  //       } else {
  //         resolve(false);
  //       }
  //     }).catch(err => {
  //       this.loadingUpload = false;
  //       this.Params['saving'] = false;
  //       this.Params['loadingbar'] = false;
  //       resolve(false);
  //     });
  //   })
  // }
  // newAnnotateCopysheet(Indicator, stateParamsId, page?) {
  //   let copysheet;
  //   let fileType;

  //   return new Promise(async (resolve) => {

  //     if (Indicator.items[stateParamsId].copy && Indicator.items[stateParamsId].copy.copysheet != 'null') {
  //       if (!Indicator.items[stateParamsId].model.copySheetSavePoints) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints = {}
  //       }
  //       if (Indicator.items[stateParamsId].copy.hasOwnProperty('attachments')) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.attachments = Indicator.items[stateParamsId].copy.attachments
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.attachments = []
  //       }
  //       Indicator.items[stateParamsId].model.copySheetSavePoints.copysheetSavePoint = Indicator.items[stateParamsId].copy.copysheet
  //       if (Indicator.items[stateParamsId].actionListObj) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.actionListObj = Indicator.items[stateParamsId].actionListObj
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.actionListObj = []
  //       }
  //       Indicator.items[stateParamsId].model.copySheetSavePoints.ManageCopysheet = Indicator.items[stateParamsId].model.ManageCopysheet
  //       if (Indicator.items[stateParamsId].hasCopysheet) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.hasCopysheet = Indicator.items[stateParamsId].hasCopysheet
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.hasCopysheet = false
  //       }
  //       if (Indicator.items[stateParamsId].isCorrupted) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.isCorrupted = Indicator.items[stateParamsId].isCorrupted
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.isCorrupted = false
  //       }
  //       if (Indicator.items[stateParamsId].isCoysheetSubmitted) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = Indicator.items[stateParamsId].isCoysheetSubmitted
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = false
  //       }
  //       if (Indicator.items[stateParamsId].generatedCopysheet) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.generatedCopysheet = Indicator.items[stateParamsId].generatedCopysheet
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.generatedCopysheet = false
  //       }
  //       if (Indicator.items[stateParamsId].isCopysheetSaved) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.isCopysheetSaved = Indicator.items[stateParamsId].isCopysheetSaved
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.isCopysheetSaved = false
  //       }
  //       if (Indicator.items[stateParamsId].needsNewCopysheet) {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.needsNewCopysheet = Indicator.items[stateParamsId].needsNewCopysheet
  //       } else {
  //         Indicator.items[stateParamsId].model.copySheetSavePoints.needsNewCopysheet = false
  //       }

  //       this.indicatorStore.update(state => {
  //         return {
  //           indicator: Indicator,
  //           updatedFrom: "[ManagecopysheetService] annotateCopysheet savePoint",
  //         }
  //       })
  //       var CSfileType = "";
  //       copysheet = Indicator.items[stateParamsId].baseCopysheet ? Indicator.items[stateParamsId].baseCopysheet : Indicator.items[stateParamsId].copy.copysheet;
  //       if (copysheet) {
  //         var CSURL = copysheet;
  //         CSfileType = CSURL.slice(CSURL.lastIndexOf(".") + 1, CSURL.length);
  //       }
  //       if (copysheet) {
  //         //updateCopysheet indicator
  //         if (Indicator.items[stateParamsId].model['ManageCopysheet'] && typeof (Indicator.items[stateParamsId].model['ManageCopysheet']) == "object") {
  //           Indicator.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
  //         }
  //         else {
  //           Indicator.items[stateParamsId].model['ManageCopysheet'] = 'Y';
  //         }
  //         Indicator.items[stateParamsId].model['digitalApiFlag'] = true;
  //         var fileNameLocal = copysheet.split('/').pop();
  //         if (CSfileType == 'pdf') {
  //           try {
  //             this.file.validatePDF(fileNameLocal).subscribe(res => {
  //               if (res['data'] == "Invalid PDF structure") {
  //                 this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");

  //                 Indicator.items[stateParamsId].hasCopysheet = false;
  //                 this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
  //                   .subscribe(() => { }, err => { })

  //                 Indicator.items[stateParamsId].copy.copysheet = null;
  //                 Indicator.items[stateParamsId].copy.copysheet = null;
  //                 Indicator.items[stateParamsId].copy.fileType = '';
  //                 if (Indicator.items[stateParamsId].requiresCopysheet) {
  //                   // Indicator.items[stateParamsId].counter++;
  //                   let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
  //                   this.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
  //                   if (Indicator['items'][stateParamsId].counter == 0) {
  //                     document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
  //                   } else if (Indicator['items'][stateParamsId].counter != 0) {
  //                     document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
  //                   }
  //                 }
  //               } else { }
  //             }, err => {
  //               //console.log("error in vlaidating the pdf file ", err);
  //               this.Logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");
  //               return false;
  //             });
  //           } catch (e) {
  //             //console.log(" error in annotate copy sheet ", e)
  //             this.Logger.logError("Timeout Error");
  //           }
  //         } else if (CSfileType == 'png' || CSfileType == 'jpg' || CSfileType == 'jpeg') {
  //           this.file.validateImage(fileNameLocal).subscribe((res) => {
  //             if (res['data'] == false) {

  //               this.StorageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
  //                 .subscribe(() => { }, err => { })
  //               Indicator.items[stateParamsId].copy.copysheet = null;
  //               Indicator.items[stateParamsId].copy.copysheet = null;
  //               Indicator.items[stateParamsId].copy.fileType = '';
  //               Indicator.items[stateParamsId].hasCopysheet = false;
  //               if (Indicator.items[stateParamsId].requiresCopysheet) {
  //                 let tempData = this.requiredfieldcount.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
  //                 this.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
  //                 if (Indicator['items'][stateParamsId].counter == 0) {
  //                   document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
  //                 } else if (Indicator['items'][stateParamsId].counter != 0) {
  //                   document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
  //                 }
  //               }
  //             }
  //           });
  //         }
  //       }
  //       fileType = Indicator.items[stateParamsId].copy.fileType;
  //       if ((fileType && CSfileType && fileType !== CSfileType)
  //         || (!fileType && CSfileType)) {
  //         fileType = CSfileType;
  //       } else if (!fileType && !CSfileType) {
  //         this.Logger.logError("Failed to open copysheet. Please upload the copysheet again.");
  //       }
  //       Indicator.items[stateParamsId].baseCopysheet = copysheet;
  //       resolve(true);
  //     }
  //   })
  // }
}
