import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@app/service/api/api.service';
import { FileService } from '@app/service/file/file.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-browsephoto',
  templateUrl: './browsephoto.component.html',
  styleUrls: ['./browsephoto.component.scss']
})
export class BrowsephotoComponent implements OnInit {

  subscription: any;
  sharedData: any;
  attachments = [];
  browseFilesArraylist: any = [];
  browsedeladdArray: any = [];
  uploadlogoArray: any = [];
  logoArray: any = [];
  browseFiles: any = [];
  filteredFiles: any = [];
  files: any = [];
  totalItems: number = 0;
  itemsPerPage: number = 8;
  currentPage: number = 1;
  maxSize: number = 5;
  page: any;
  sharedLogoObj: Photos = new Photos;
  dupPhoto: boolean = false;
  errorMsg: string;
  emptyText: string = 'Add a description';
  showNoPhotos: boolean = false;
  // showBtn:boolean = false;
  editMode:boolean = false;
  editModeDesc:boolean =false;
  inputIndex: any = "";
  inputIndexDesc: any = "";

  @ViewChild("inlineInput", {static: false}) inlineInputEl : ElementRef;
  @ViewChild("inlineDesc", {static: false}) inlineDescEl : ElementRef;

  constructor(private API: ApiService,
    private _file: FileService,
    private photoService: PhotoService,
    private spinnerService: NgxSpinnerService,
    private Logger: LoggerService,
    private indicatorQuery: IndicatorQuery, 
    private indicatorStore: IndicatorStore) {
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data}
    })
    this.updateFileList();
  }


  ngOnInit() {
    this.subscription = this.photoService.getBehaviorView().subscribe(result => {
      this.uploadlogoArray = result.uploadLogoArrObj
      this.logoArray = result.logoArrObj
    })
  }

  change(currentPage) {
    this.currentPage = currentPage;
  }

  search(event) {
    this.filteredFiles = this.browseFilesArraylist;
    if (event) {
      this.filteredFiles = this.filteredFiles.filter((ele, i, array) => {
        let arrayElement = ele.filename.toLowerCase()
        return arrayElement.includes(event)
      })
    }
    this.currentPage = 1;
  }

  getFilePath(file) {
    return this.API.get("/api/file/") + file._id + "/" + file.filename;
  }

  updateFilename(file, data) {
    file.showErr = false
    var fileExtnBefore = file.filename.substring(file.filename.lastIndexOf("."), file.filename.length);
    var fileExtnAfter = data.value.substring(data.value.lastIndexOf("."), data.value.length);
    if (fileExtnBefore === fileExtnAfter) {
      var manageUploadData = file;
      manageUploadData.filename = data.value;
      this._file.put(file._id, manageUploadData).subscribe(() => {

      })
      for (var i = 0; i < this.attachments.length; i++) {
        if (this.attachments[i] === this.getFilePath(file)) {
          this.attachments[i] = this.getFilePath(file).substring(0, this.getFilePath(file).lastIndexOf("/") + 1) + data;
          break;
        }
      }
      this.cancelFileName(file);
    } else {
      file.showErr = true;
      this.errorMsg = "Please don't change the extension of file -- " + "File extension should be " + fileExtnBefore;
    }
  }

  updateDescription(file, data) {
    file.showErrDesc = false
    var manageUploadData = file;
    if (data.value.length <= 140) {
      manageUploadData.metadata.description = data.value;
      this._file.put(manageUploadData._id, manageUploadData).subscribe(() => {

      });
      this.cancelDescription(file);
    } else {
      file.showErrDesc = true;
      this.errorMsg = "Description length exceeded 140 character- Please enter description within 140 character";
    }
  }

  addToAttachments(attachment, id) {
    for (var i = 0; i < this.logoArray.length; i++) {
      if (this.logoArray[i] === attachment) {
        this.dupPhoto = true;
        break;
      }
    }
    if (!this.dupPhoto) {
      var attachObj = {
        url: '',
        isAttachedImg: false,
        largeImg: '',
        showImg: false,
        thumbImg: '',
        urlName: '',
        id: '',
        orgObj: ''
      }
      var urlName = "";
      var url = attachment.split("/");
      urlName = url.pop();
      attachObj['url'] = attachment;
      attachObj['isAttachedImg'] = true;
      attachObj['largeImg'] = attachment;
      attachObj['showImg'] = true;
      attachObj['thumbImg'] = attachment;
      attachObj['urlName'] = urlName;
      attachObj['id'] = id;
      for (var i = 0; i < this.browseFilesArraylist.length; i++) {
        if (this.browseFilesArraylist[i]._id && (this.browseFilesArraylist[i]._id === id)) {
          attachObj['orgObj'] = this.browseFilesArraylist[i];
        }
      }
      this.browsedeladdArray.push(attachObj);
      this.uploadlogoArray.push(attachObj);
      if (this.uploadlogoArray.length > 0) {
        this.logoArray = this.logoArray.concat(this.uploadlogoArray);
      }
      this.browseFiles = this.uploadlogoArray;
      this.updateFileList();
      this.sharedLogoObj.uploadLogoArrObj = this.uploadlogoArray
      this.sharedLogoObj.browsedeladdArrObj = this.browsedeladdArray
      this.photoService.setBehaviorView(this.sharedLogoObj)
      //this.filteredAttachments = this.attachments;
    } else {
      this.Logger.logWarning(attachment.substring(attachment.lastIndexOf("/") + 1, attachment.length) + ' already present in attachments')
    }
  }

  updateFileList() {
    /* "commented spinner code for ExpressionChangedAfterItHasBeenCheckedError"    
    this.sharedData.loadingText = "Loading photos...";
    this.dataService.setBehaviorView(this.sharedData);
    this.spinnerService.show();*/
    this._file.get().subscribe(fileList => {
      this.files = fileList;
      this.browseFilesArraylist.splice(0, this.browseFilesArraylist.length);
      for (var k = 0; k < this.files.length; k++) {
        if (this.files[k].filename.substring(this.files[k].filename.lastIndexOf("."), this.files[k].filename.length).toLowerCase().match(/^(.jpg|.png|.jpeg|.gif)$/))
          this.browseFilesArraylist.push(this.files[k]);
      }
      //below for loop is to remove the selected (click on add) from Library
      for (var i = 0; i < this.browseFilesArraylist.length; i++) {
        for (var j = 0; j < this.browseFiles.length; j++) {
          if (this.browseFilesArraylist[i]._id && (this.browseFilesArraylist[i]._id === this.browseFiles[j].id)) {
            var index = this.browseFilesArraylist.indexOf(this.browseFilesArraylist[i]);
            if (index > -1) {
              this.browseFilesArraylist.splice(index, 1);
            }
          }
        }
      }
      //this.totalItems = this.browseFilesArraylist.length
      //this.pageCount = Math.floor(this.browseFilesArraylist.length / this.itemsPerPage);
      if (this.browsedeladdArray.length > 0) {
        for (var s = 0; s < this.browseFilesArraylist.length; s++) {
          for (var t = 0; t < this.browsedeladdArray.length; t++) {
            if (this.browseFilesArraylist[s]._id && (this.browseFilesArraylist[s]._id === this.browsedeladdArray[t].id)) {
              var index = this.browseFilesArraylist.indexOf(this.browseFilesArraylist[s]);
              if (index > -1) {
                this.browseFilesArraylist.splice(index, 1);
              }
            }
          }
        }
      }
      if (this.uploadlogoArray.length > 0) {
        for (var s = 0; s < this.browseFilesArraylist.length; s++) {
          for (var t = 0; t < this.uploadlogoArray.length; t++) {
            if (this.browseFilesArraylist[s] && this.browseFilesArraylist[s]._id && (this.browseFilesArraylist[s]._id === this.uploadlogoArray[t].id)) {
              var index = this.browseFilesArraylist.indexOf(this.browseFilesArraylist[s]);
              if (index > -1) {
                this.browseFilesArraylist.splice(index, 1);
              }
            }
          }
        }
      }

      // var begin = ((this.currentPage - 1) * this.itemsPerPage),
      //   end = begin + this.itemsPerPage;
      this.filteredFiles = this.browseFilesArraylist//.slice(begin, end);

      if (!(this.filteredFiles.length > 0))
        this.showNoPhotos = true;
      else
        this.showNoPhotos = false;
      //this.spinnerService.hide();
    }, (err) => {
      if (!(this.filteredFiles.length > 0))
        this.showNoPhotos = true;
      else
        this.showNoPhotos = false;
      //this.spinnerService.hide();
      console.log(err);
    }, () => {
      if (!(this.filteredFiles.length > 0))
        this.showNoPhotos = true;
      else
        this.showNoPhotos = false;
      //this.spinnerService.hide();
    })
  }

  myFilesFilter(file) {
    return file.filename.substring(file.filename.lastIndexOf("."), file.filename.length)
      .toLowerCase().match(/^(.jpg|.png|.jpeg|.gif)$/);
  };
   // onFocus(){
  //   this.showBtn = true;
  // }
  // onFocusOut(){
  //   // this.showBtn = false;
  //   this.editMode = false;
  //   this.editModeDesc = false;
  // }
  editName(i){
    this.inputIndex = i;
    this.editMode = true;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.inlineInputEl.nativeElement.focus();
    },0);
  }
  editDescription(i){
    this.inputIndexDesc = i;
    this.editModeDesc = true;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.inlineDescEl.nativeElement.focus();
    },0);
  }
 
  cancelFileName(file){
    // this.showBtn = false;
    this.editMode = false;
    file.showErr = false;
  }
  cancelDescription(file){
    // this.showBtn = false;
    this.editModeDesc = false;
    file.showErrDesc = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
