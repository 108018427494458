import { Component, OnInit, Optional, Inject } from '@angular/core';
import { SplitComponent, SplitAreaDirective } from 'angular-split';
import { GraphicContentGridServiceService } from 'app/modules/graphic-items/graphic-content-grid-service.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { FooterService } from 'app/shared/components/footer/footer.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GroupService } from '@app/service/group/group.service';
import { StorageService } from 'app/core/service/storage/storage.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AccountService } from '@app/service/account/account.service';
import { OrderService } from '@app/service/order/order.service';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog,MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CopysheetFileComponent } from 'app/shared/components/copysheet-file/copysheet-file.component';
import { ArtistinstructionComponent } from 'app/shared/components/artistinstruction/artistinstruction.component';
import { CopysheetUploadComponent } from 'app/shared/components/copysheet-upload/copysheet-upload.component';
import { FieldcountService } from '@app/service/fieldcount/fieldcount.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { ItemService } from '@app/service/item/item.service';
import { ManagecopysheetService } from '@app/service/managecopysheet/managecopysheet.service';
import { CounterService } from '@app/service/counter/counter.service';
import { CopyService } from '@app/service/copy/copy.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';
import { FileService } from '@app/service/file/file.service';
let currentObj;
let tempIndicator;
@Component({
  selector: 'app-iagroup',
  templateUrl: './iagroup.component.html',
  styleUrls: ['./iagroup.component.scss']
})
export class IagroupComponent implements OnInit {

    split: SplitComponent;
    area1: SplitAreaDirective;
    area2: SplitAreaDirective;
    graphicItems;
    Indicator;
    pdf_copysheet_url:any;
    IaGroup_pdf:any;
    itemsInIA: any;
    basket : any;
    newgroupItemsList = [];
    splitData:any;
    mergeData:any;
    newgroupId: String;
    isCopysheetAvailable: boolean = false;
    buttonLabel: object;
    dialogData: any = {};
    copyDialogData: any = {};
    serviceCallCount : any = 0;
    isInsideItemBUttonClicked : boolean = false;
    direction: string = 'vertical'
    footerFlag: boolean = false;
    sizes = {
        percent: {
            area1: 35,
            area2: 65,
        },
        pixel: {
            area1: 120,
            area2: '*',
            area3: 160,
        },
    }

    isIndGraphicSubscription: any;
    constructor( private graphicContentGridServiceService: GraphicContentGridServiceService,
        //private footerService: FooterService,
        private indicatorQuery: IndicatorQuery,
        private indicatorStore: IndicatorStore,
        private copysheetService: CopysheetService,
        private StorageService: StorageService,
        private Logger: LoggerService,
        private accountService:AccountService,
        private orderService: OrderService,
        private router: Router,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<IagroupComponent>,
        private Copysheet: CopysheetService,
        private requiredFieldCount: FieldcountService,
        private spinnerService: NgxSpinnerService,
        private requiredfieldcountService : RequiredfieldcountService,
        private itemService :ItemService,
        @Optional() @Inject(MAT_DIALOG_DATA) public matData: any,
        private manageCopySheetService: ManagecopysheetService,
        private counterService: CounterService,
        private copyService :CopyService,
        private utils: UtilsService,
        private salesforceService: SalesforceService,
        private grouper: GroupService,
        private file: FileService) {
        //footerService.changeTab("GraphicItem");
    }

    ngOnInit() {
        this.indicatorQuery.getIndicator().subscribe((res) => {
            this.Indicator = { ...res };
        });
        this.buttonLabel ={};
        let checkedItemId = this.matData ? this.matData.svItemId : "";
        this.footerFlag = this.matData && this.matData.footerFlag ? this.matData.footerFlag : false;
        this.graphicItems = this.graphicContentGridServiceService.buildGraphicProducts(this.Indicator,checkedItemId);
        currentObj = this;
        currentObj.itemsInIA = [];
        currentObj.basket = [];
        currentObj.status = {
            openNIA: true,
            openIA: true
        };
        currentObj.row = {};
        currentObj.itemObject = {};
        currentObj.itemObject.iaGroupId = "";
        currentObj.showButton = false;
        currentObj.dragged = false;
        currentObj.Indicator = this.Indicator;

        currentObj.account = this.Indicator.account;
        currentObj.order = this.Indicator.order;

        this.getCopySheetDetails();
        this.isIndGraphicSubscription = this.manageCopySheetService.getBehaviorViewGraphic().subscribe(result => {
            console.log(result);
            if(result){
                this.indicatorQuery.getIndicator().subscribe((res) => {
                    this.Indicator = res;
                });
            }
          })
    }
    ngOnDestroy(){
        //Remove stateparamsID valuse after coming out of item page
       // debugger;
        if (!this.isInsideItemBUttonClicked){
            this.indicatorQuery.getIndicator().subscribe(async (res) => {
                let updatedIndVal =JSON.parse(JSON.stringify(res));
                updatedIndVal.stateParamsId = "";
                this.indicatorStore.update((state) => {
                return {
                    indicator: updatedIndVal,
                    updatedFrom: "[IagroupComponent] ngOnDestroy",
                    isloaded: true,
                };
                });
            });
        }
        this.isIndGraphicSubscription.unsubscribe();
    }
    getCopySheetDetails(){
        for (var i in this.Indicator.items) {
            let itemVal = JSON.parse(JSON.stringify(this.Indicator.items[i]));
            if (itemVal && itemVal.order && (itemVal.order.productType === 'Print' || (itemVal.order.productCode === 'YPC'  && itemVal.order.UDAC != "ML"))) {
                if (!this.isCopysheetAvailable &&  (this.graphicItems.graphicItemsArray.length > 0 || this.graphicItems.iaItemslist.length > 0)){
                    this.isCopysheetAvailable = true;
                }
                if(itemVal && itemVal.schema && (itemVal.schema.properties && itemVal.schema.properties.ManageCopysheet)) {
                    if(itemVal.order.statusCode >= 6000 ) {
                        this.buttonLabel[itemVal.order.svItemId] = 'Resend Copysheet';
                    } else {
                        this.buttonLabel[itemVal.order.svItemId] = 'Save';
                    }
                    let copysheetURL = '';
                    if(itemVal.order.statusCode >= 6000 ) {
                        this.buttonLabel[itemVal.order.svItemId] = 'Resend Copysheet';
                    } else {
                        this.buttonLabel[itemVal.order.svItemId] = 'Save';
                    }
                    itemVal.order.directoryName = (itemVal.order.directoryName) ?  itemVal.order.directoryName : 'Digital';
                    itemVal.order.heading = (itemVal.order.heading && itemVal.order.heading !== "0") ? itemVal.order.heading : itemVal.order.description;
                    if(itemVal.copy) {
                        copysheetURL = itemVal.copy.copysheet;
                        itemVal.copy.fileType = '';
                    }
                    // To do: update this for copysheet
                    // if(copysheetURL) {
                    //     itemVal.copy.fileType = copysheetURL.slice(copysheetURL.lastIndexOf(".")+1, copysheetURL.length);
                    // }
                    // if (self.row[itemVal.order.directoryName]) {
                    //     self.row[itemVal.order.directoryName].push(itemVal);
                    // } else {
                    //     self.row[itemVal.order.directoryName] = [itemVal];
                    // }
                    // this.record.norecordFlag = true;
                    // this.count++;
                }
            }
        }
    }
    loadNonIAGroupTable() {
        if (this.graphicItems && this.graphicItems.listOfUniqueDirectoryNames.length > 0) {
            let cellCount = 0;
            currentObj.cellWidth = Math.ceil(100/6);

            if(this.graphicItems.listOfUniqueDirectoryNames.length >= 6) {
                cellCount = 6;
            } else {
                cellCount = this.graphicItems.listOfUniqueDirectoryNames.length;
            }
            cellCount = cellCount+1;
            currentObj.tableWidth = currentObj.cellWidth * cellCount;
        }
        // let updatedValu = this.graphicContentGridServiceService.buildGraphicProducts();
        // updatedValu.listOfUniqueDirectoryNames.length > 1 ?  this.ngOnInit():'';
    }
    dragEnd(unit, {sizes}) {
        if(unit === 'percent') {
            this.sizes.percent.area1 = sizes[0];
            this.sizes.percent.area2 = sizes[1];
        }
        else if(unit === 'pixel') {
            this.sizes.pixel.area1 = sizes[0];
            this.sizes.pixel.area2 = sizes[1];
            this.sizes.pixel.area3 = sizes[2];
        }
    }

        onIADrop(event,iaGroupId){
            this.indicatorQuery.getIndicator().subscribe((res) => {
                tempIndicator = { ...res };
            });
            // if (!tempIndicator){
            //     tempIndicator = JSON.parse(JSON.stringify(this.Indicator));
            // }else{
            //     tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
            // }
            //console.log('inside onIA drop!!!!!',iaGroupId);
                let itemsInIA = [];
                let getAllItems = Object.keys(tempIndicator.items);
                let draggedEleItemId = event.item.data ? event.item.data.svItemId : event.previousContainer.data[0].svItemId;
                let draggedEleIAGrp = tempIndicator.items[draggedEleItemId].data ? tempIndicator.items[draggedEleItemId].data.iaGroupId : '';
                let dropEleIAGrp = iaGroupId;

                getAllItems.forEach(item=>{
                    if (tempIndicator.items[item].data && tempIndicator.items[item].data.iaGroupId == iaGroupId){
                        itemsInIA.push(item);
                    }
                })

                if(dropEleIAGrp !== draggedEleIAGrp ){//&& draggedEleItemId !== self.itemObject.svItemId
                    currentObj.spinnerService.show();
                    //self.showHourGlass = true;
                    this.mergeData = {
                        "groupId": dropEleIAGrp,
                        "eItemId": draggedEleItemId,
                        "eAccountId": currentObj.account.kgenCustomerId,
                        "consumingSystem": "CA"
                    }

                    // Do not merge when Call tracking is missing.
                    this.splitData = {
                        "consumingSystem": "CA",
                        "eAccountId": currentObj.account.kgenCustomerId,
                        "eItemId": draggedEleItemId
                    }

                    let splitid = tempIndicator.items[this.splitData.eItemId].calltracking;
                    let mergeid = tempIndicator.items[this.mergeData.eItemId].calltracking;
                    let isCTenabled = tempIndicator.items[this.splitData.eItemId].model.modelValue === tempIndicator.items[this.mergeData.eItemId].model.modelValue;
                    let isCTmodelvalueNo = tempIndicator.items[this.splitData.eItemId].model.modelValue === "No" && tempIndicator.items[this.mergeData.eItemId].model.modelValue == "No";
                    let isMergeAllowed =  false;
                    // if((!(splitid.length == 0 && mergeid.length == 0) && !isCTmodelvalueNo ) || Indicator.items[mergeData.eItemId].model.modelValue == "No"){
                    //     self.showHourGlass = false;
                    //     Logger.logIAError("No Call tracking reserved for the items.");
                    //     return;
                    // }

                    // Temp fix
                    if (((splitid.length > 0 && mergeid.length == 0) || (mergeid.length > 0 && splitid.length == 0)) && !(splitid.length == 0 && mergeid.length == 0))
                    {
                       // self.showHourGlass = false;
                       currentObj.spinnerService.hide();
                       currentObj.Logger.logError("No Call tracking reserved for the items.");
                        return;
                    }

                    //Do not merge IA if call tracking is different
                    let isIAMergedAllowed = false;

                    let callTrackingDetails = this.indicatorQuery.getValue().indicator['items'][draggedEleItemId].calltracking;//tempIndicator.items[draggedEleItemId].calltracking;
                    let callTrackingDetailsArr = [];
                    callTrackingDetails.forEach((callTrackingDetailsVal,inx)=>{
                        callTrackingDetailsArr.push(callTrackingDetailsVal.terminationNumber.replace(/\D/g, ""));
                    })
                    if (itemsInIA.length > 0)
                    {
                        itemsInIA.forEach((value,indx)=>{
                            tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                            var isSameUdac = tempIndicator.items[this.splitData.eItemId].order.UDAC === tempIndicator.items[value].order.UDAC;
                            if (tempIndicator.items[value].calltracking.length > 0)
                            {
                                tempIndicator.items[value].calltracking.forEach(function(itemVal,indVal){
                                    if (callTrackingDetailsArr.indexOf(itemVal.terminationNumber.replace(/\D/g, "")) >-1 )
                                    {
                                        isIAMergedAllowed = true;
                                    }
                                })
                            }
                            else if (tempIndicator.items[value].calltracking.length == 0 && callTrackingDetailsArr.length == 0 )
                            {
                                tempIndicator.items[value].model.modelValue == undefined ? tempIndicator.items[value].model.modelValue = "Yes" : tempIndicator.items[value].model.modelValue = tempIndicator.items[value].model.modelValue;
                                tempIndicator.items[draggedEleItemId].model.modelValue == undefined ? tempIndicator.items[draggedEleItemId].model.modelValue = "Yes" : tempIndicator.items[draggedEleItemId].model.modelValue = tempIndicator.items[draggedEleItemId].model.modelValue;
                                if (tempIndicator.items[value].model.modelValue == tempIndicator.items[draggedEleItemId].model.modelValue || !isSameUdac)
                                {
                                    isIAMergedAllowed = true;
                                }
                                // else if (Indicator.items[value].model.modelValue == Indicator.items[draggedEleItemId].model.modelValue && !isSameUdac)
                                // {
                                //     isIAMergedAllowed = false;
                                // }

                            }
                        })
                        if (!isIAMergedAllowed)
                        {
                            //self.showHourGlass = false;
                            currentObj.spinnerService.hide();
                            currentObj.Logger.logError("Items must have the same Call tracking number.");
                            return;
                        }
                }

                    this.grouper.mergeIAItems(this.mergeData,"IA-MERGE-ORDERITEMPAGE").subscribe((responseMerge : any)=>{
                        tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                        if(responseMerge.hasWarningOrError === "false"){
                            //FUL-24743
                            if (event.previousContainer.id == "IACreateGroup"){
                                // this.newgroupItemsList = [];
                                currentObj.itemsInIA= [];
                            }
                            currentObj.spinnerService.hide();
                            this.newgroupId = responseMerge.groupId;
                            // transferArrayItem(event.previousContainer.data,
                            //     event.container.data,
                            //     event.previousIndex,
                            //     event.currentIndex);

                            //self.showHourGlass = false;
                            let oldgroupid = tempIndicator.items[draggedEleItemId].data.iaGroupId;

                            let activityLogHistoryObj = {
                                'displayName':tempIndicator.user.name,
                                'email':tempIndicator.user.email,
                                'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                                'itemId':draggedEleItemId,
                                'itemStatus': tempIndicator.items[draggedEleItemId].order.status,
                                'itemActionCode':tempIndicator.items[draggedEleItemId].order.actionCode + "-" + tempIndicator.items[draggedEleItemId].order.actionType,
                                'fieldName':'IA Group',
                                'fieldOldValue':oldgroupid,
                                'fieldNewValue': this.newgroupId
                               }

                            this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                            tempIndicator.items[draggedEleItemId].data.iaGroupId =  this.newgroupId;

                            this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator);
                            currentObj.loadNonIAGroupTable();
                            // self.loadNonIAGroupTable();
                            // //go through the other items on the order
                            for (let key in tempIndicator.items) {
                                //if the other item has the matching ia group id & isn't the current item
                                if (tempIndicator.items[key].data && draggedEleItemId != key
                                    && tempIndicator.items[key].data.iaGroupId === dropEleIAGrp)
                                {
                                    //add the copysheet to the other item
                                    tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                                    tempIndicator.items[draggedEleItemId].copy.copysheet = tempIndicator.items[key].copy.copysheet;
                                    tempIndicator.items[draggedEleItemId]['baseCopysheet'] = tempIndicator.items[key].baseCopysheet;
                                   //tempIndicator.items[draggedEleItemId].copy['previousCopySheet'] = tempIndicator.items[key].copy.previousCopySheet;
                                    tempIndicator.items[draggedEleItemId].hasCopysheet = tempIndicator.items[key].hasCopysheet;
                                    //Commented Changes added for upload copysheet 
                                    // if(tempIndicator.items[key].copy.uploadFile){
                                    //     if(tempIndicator.items[draggedEleItemId].copy.hasOwnProperty('uploadFile')){
                                    //     tempIndicator.items[draggedEleItemId].copy.uploadFile = tempIndicator.items[key].copy.uploadFile;
                                    //     }
                                    // else{
                                    //     tempIndicator.items[draggedEleItemId].copy['uploadFile'] = {};
                                    //     tempIndicator.items[draggedEleItemId].copy.uploadFile = tempIndicator.items[key].copy.uploadFile;
                                    //     }
                                    // }
                                    
                                    // update copysheetIndicator value
                                if (tempIndicator.items[draggedEleItemId].order.productType === "Print"){
                                    if (tempIndicator.items[draggedEleItemId].model.hasOwnProperty('ManageCopysheet')){
                                        tempIndicator.items[draggedEleItemId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                                    }else{
                                        tempIndicator.items[draggedEleItemId].model['ManageCopysheet'] = {};
                                        tempIndicator.items[draggedEleItemId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                                    }
                                    tempIndicator.items[draggedEleItemId].model['digitalApiFlag'] = true;
                                }else{
                                    tempIndicator.items[draggedEleItemId].model['copySheetIndicator'] = 'Y';
                                    tempIndicator.items[draggedEleItemId].model['digitalApiFlag'] = true;
                                }
                                    this.Copysheet.updateCopysheet(draggedEleItemId + "v" + tempIndicator.items[draggedEleItemId].order.version, tempIndicator.items[draggedEleItemId].copy)
                                    .then(function (response) {  })
                                    .catch(function (err) {
                                        currentObj.Logger.logError("Failed to update copysheet :" +JSON.stringify(err));
                                    });
                                    //self.itemCounterChange(draggedEleItemId);
                                    this.updateIAGroupCounter(draggedEleItemId, tempIndicator);
                                    this.indicatorQuery.getIndicator().subscribe((res) => {
                                        tempIndicator = JSON.parse(JSON.stringify(res));
                                    });
                                    break;
                                }
                            }
                            //Adding adVersionIncInd flag for copysheet
                        for (let key in tempIndicator.items) {
                            if (tempIndicator.items[key].data && tempIndicator.items[key].data.iaGroupId === tempIndicator.items[draggedEleItemId].data.iaGroupId){
                                this.StorageService.updateField("item", key, { "data.adVersionIncInd": "Y" }).subscribe(res => {
                                    console.log(res)
                                });
                            }
                        }
                            currentObj.indicatorStore.setLoading(true);
                            currentObj.indicatorStore.update((state) => {
                              return {
                                indicator : tempIndicator,
                                updatedFrom: "[IagroupComponent] mergeIAItems",
                                isloaded: true,
                              }
                            });
                            currentObj.indicatorStore.setLoading(false);
                        }
                    },function(error){
                        currentObj.spinnerService.hide();
                       // currentObj.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts();
                        //currentObj.loadNonIAGroupTable();
                        var errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
                        if(errorObject){
                        var errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                        var errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                        var errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        }
                        currentObj.Logger.logError(errorMsg);
                    });
                }
        }
    drop(event: any) {
        if (event.container.id){
            let containerIdSplit = event.container.id.split('-');
            if (containerIdSplit.length > 0 && containerIdSplit[0] == "container"){
                let groupId = containerIdSplit[1];
                if (event.container.id == event.previousContainer.id){
                    this.nonIADroppableConfig(event,true);
                }else{
                    this.onIADrop(event,groupId);
                }
                return;
            }else if(event.container.id == 'IACreateGroup' && (event.container.id == event.previousContainer.id)){
                this.nonIADroppableConfig(event,true);
                return;
            }
        }
        this.indicatorQuery.getIndicator().subscribe((res) => {
            tempIndicator = JSON.parse(JSON.stringify(res));
        });
    let svItemId = "";
    // if (!tempIndicator){
    //     tempIndicator = JSON.parse(JSON.stringify(this.Indicator));
    // }else{
    //     tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
    // }
    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }else {
        currentObj.spinnerService.show(); //Added spinner
        let draggedEleItemId = event.item.data ? event.item.data.svItemId : event.previousContainer.data[0].svItemId;
        //   transferArrayItem(event.previousContainer.data,
        //   event.container.data,
        //   event.previousIndex,
        //   event.currentIndex);
        if(this.newgroupItemsList.indexOf(draggedEleItemId) === -1){
            this.newgroupItemsList.push(draggedEleItemId);
        }else if(this.newgroupItemsList.indexOf(draggedEleItemId) !== -1 && currentObj.itemObject.svItemId && draggedEleItemId === currentObj.itemObject.svItemId){
            this.newgroupItemsList.push(draggedEleItemId);
        }

        if(this.newgroupItemsList && this.newgroupItemsList.length === 1){
           // ui.draggable.remove();
            currentObj.itemObject = JSON.parse(JSON.stringify(currentObj.Indicator.items[draggedEleItemId].order));
            currentObj.showButton = true;
            this.splitData = {
                "consumingSystem": "CA",
                "eAccountId": currentObj.account.kgenCustomerId,//"2105546432"
                "eItemId": draggedEleItemId
            }
            this.grouper.splitIAItems(this.splitData,"IA-SPLIT-ORDERITEMPAGE").then((response: any)=>{
                currentObj.spinnerService.hide();
                if(response && response.hasWarningOrError === "false"){
                    tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                    currentObj.itemObject['iaGroupId'] = response.groupId;
                    this.newgroupId = response.groupId;
                    let oldgroupId = this.Indicator.items[draggedEleItemId].data.iaGroupId;
                    tempIndicator.items[draggedEleItemId].data.iaGroupId = this.newgroupId;
                    // FUL-25198
                    if (tempIndicator.items[draggedEleItemId].order.actionCode == "Renew" && !tempIndicator.items[draggedEleItemId].model.dirtyFlag){
                        tempIndicator.items[draggedEleItemId].model['dirtyFlag'] = true;
                        if (tempIndicator.items[draggedEleItemId].order.actionCode === 'Renew') {
                            tempIndicator.items[draggedEleItemId].model.renewalCopysheetRequiredFlag = true;
                            this.StorageService.update("item", draggedEleItemId + "v" + tempIndicator.items[draggedEleItemId].order.version, tempIndicator.items[draggedEleItemId].model).then(result => { });
                        }
                        if (tempIndicator.items[draggedEleItemId].hasOwnProperty('copy')){
                            tempIndicator.items[draggedEleItemId].copy['copysheet'] = null;
                        }else{
                            tempIndicator.items[draggedEleItemId]['copy'] = {};
                            tempIndicator.items[draggedEleItemId].copy['copysheet'] = null;
                        }
                        tempIndicator.items[draggedEleItemId]['hasCopysheet'] = false;
                        tempIndicator.items[draggedEleItemId]['requiresCopysheet'] = true;
                        this.StorageService.updateField("copyz", draggedEleItemId + "v"+ tempIndicator.items[draggedEleItemId].order.version, {"data.requiresCopysheet" : true})
                        .subscribe(()=>{},err=>{});
                        //tempIndicator.items[draggedEleItemId].counter++;
                        // Update indicator when copysheet is null for straight renew items
                        let id = draggedEleItemId;
                        let currentCount = tempIndicator["items"][id].counter;
                        let tempData = this.requiredfieldcountService.getNewItemCount(tempIndicator["items"][id].requiredFields, tempIndicator["items"][id].model, tempIndicator["items"][id].order, tempIndicator["orderPercent"], tempIndicator["items"][id].counter, true, tempIndicator["items"][id]);
                        this.updateIndictor(id, tempIndicator, tempData, currentCount);
                        tempIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                    }
                    let activityLogHistoryObj = {
                        'displayName':this.Indicator.user.name,
                        'email':this.Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                        'itemId':draggedEleItemId,
                        'itemStatus': this.Indicator.items[draggedEleItemId].order.status,
                        'itemActionCode':this.Indicator.items[draggedEleItemId].order.actionCode + "-" + this.Indicator.items[draggedEleItemId].order.actionType,
                        'fieldName':'IA Group',
                        'fieldOldValue':oldgroupId,
                        'fieldNewValue':this.newgroupId
                    }
                    this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                    //Akita store update
                   // currentObj.itemsInIA = [];
                    currentObj.indicatorStore.setLoading(true);
                    currentObj.indicatorStore.update((state) => {
                        return {
                            indicator : tempIndicator,
                            updatedFrom: "[IagroupComponent] splitIAItems",
                            isloaded: true,
                        }
                    });
                    currentObj.indicatorStore.setLoading(false);
                    currentObj.loadNonIAGroupTable();
                    currentObj.itemsInIA.push();
                    if (event.previousContainer.id){
                        let containerIdSplit = event.previousContainer.id.split('-');
                        if (containerIdSplit.length > 0 && containerIdSplit[0] == "container"){
                            this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator); // To do
                            // FUL-24773
                            if (this.graphicItems.graphicItemsArray && this.graphicItems.graphicItemsArray.length > 0){
                                loop1:
                                for (let i=0; i < this.graphicItems.graphicItemsArray.length; i++){
                                        if (this.graphicItems.graphicItemsArray[i].itemHeading == tempIndicator.items[draggedEleItemId].order.heading){
                                            for (let j=0 ; j < this.graphicItems.graphicItemsArray[i].items.length; j++ ){
                                                for (let k=0; k < this.graphicItems.graphicItemsArray[i].items.length; k++){
                                                    if (this.graphicItems.graphicItemsArray[i] && this.graphicItems.graphicItemsArray[i].items[j][k] && this.graphicItems.graphicItemsArray[i].items[j][k].svItemId == draggedEleItemId ){
                                                        this.graphicItems.graphicItemsArray[i].items[j].splice(k,1);
                                                        break loop1;
                                                    }
                                                }
                                            }
                                        }
                                }
                            }
                           let prevContainerData = [];
                           prevContainerData.push(event.item.data);//event.item.data
                            transferArrayItem(prevContainerData,
                                event.container.data,
                                event.previousIndex,
                                event.currentIndex);
                        }else{
                            // Push item to droppable area only on success
                        transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
                        }
                    }else{
                        // Push item to droppable area only on success
                    transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
                    }

                }else{
                    currentObj.spinnerService.hide();
                }
            },function(error){
                let errorMsg = "";
                let errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
                if(errorObject){
                    let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                    let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                    errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                }
                currentObj.Logger.logError(errorMsg);
                if (currentObj.newgroupItemsList && currentObj.newgroupItemsList.length > 0){
                    currentObj.newgroupItemsList.length = 0; // Remove item from newGroup list if error occured while creating group
                }
                currentObj.spinnerService.hide();
            });
        }else if(this.newgroupItemsList && this.newgroupItemsList.length > 1 && draggedEleItemId !== currentObj.itemObject.svItemId){
            this.mergeData = {
                "groupId": this.newgroupId,
                "eItemId": draggedEleItemId,
                "eAccountId": currentObj.account.kgenCustomerId,// "2105546432",
                "consumingSystem": "CA"
            }
            let splitid = this.Indicator.items[this.splitData.eItemId].calltracking;
            let mergeid = this.Indicator.items[this.mergeData.eItemId].calltracking;
            if (this.Indicator.items[this.splitData.eItemId].model.modelValue == undefined)
            {
                tempIndicator.items[this.splitData.eItemId].model.modelValue = "Yes";
            }
            if (this.Indicator.items[this.mergeData.eItemId].model.modelValue == undefined)
            {
                tempIndicator.items[this.mergeData.eItemId].model.modelValue = "Yes";
            }
            let isCTenabled = tempIndicator.items[this.splitData.eItemId].model.modelValue === tempIndicator.items[this.mergeData.eItemId].model.modelValue;
            let isSameUdac = tempIndicator.items[this.splitData.eItemId].order.UDAC === tempIndicator.items[this.mergeData.eItemId].order.UDAC;
            let isCTmodelvalueNo = tempIndicator.items[this.splitData.eItemId].model.modelValue === "No" && tempIndicator.items[this.mergeData.eItemId].model.modelValue == "No";
            let isMergeAllowed =  false;
            if (!isSameUdac){
                currentObj.Logger.logError("Items must have same UDAC");
                for (let i=0; i < this.newgroupItemsList.length; i++){
                    if(this.newgroupItemsList[i] == draggedEleItemId ){
                        this.newgroupItemsList.splice(i,1);
                        break;
                    }
                }
                currentObj.loadNonIAGroupTable();
                currentObj.spinnerService.hide();
                return;
            }
            if (splitid && mergeid && (!(splitid.length == 0 && mergeid.length == 0)))
            {
                if (((splitid.length == 0 || mergeid.length == 0) && !isCTmodelvalueNo ) ){//|| tempIndicator.items[this.mergeData.eItemId].model.modelValue == "No"
                    currentObj.Logger.logError("No Call tracking reserved for the items.");
                    // Split item call
                    this.grouper.splitIAItems(this.splitData,"IA-SPLIT-ORDERITEMPAGE").then((response:any)=>{
                        tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                        currentObj.spinnerService.hide();
                        if(response.hasWarningOrError === "false"){
                            currentObj.itemObject.iaGroupId = response.groupId;
                            this.newgroupId = response.groupId;
                            let oldgroupId = this.Indicator.items[draggedEleItemId].data.iaGroupId;
                            tempIndicator.items[draggedEleItemId].data.iaGroupId = this.newgroupId;
                            let activityLogHistoryObj = {
                                'displayName':this.Indicator.user.name,
                                'email':this.Indicator.user.email,
                                'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                                'itemId':draggedEleItemId,
                                'itemStatus': this.Indicator.items[draggedEleItemId].order.status,
                                'itemActionCode':this.Indicator.items[draggedEleItemId].order.actionCode + "-" + this.Indicator.items[draggedEleItemId].order.actionType,
                                'fieldName':'IA Group',
                                'fieldOldValue':oldgroupId,
                                'fieldNewValue':this.newgroupId
                            }
                            this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                            currentObj.loadNonIAGroupTable();
                        }else{
                            currentObj.spinnerService.hide();
                        }
                    },function(error){
                        currentObj.spinnerService.hide();
                        let errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
                        let errorMsg = "";
                        if(errorObject){
                            let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                            let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                            errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        }
                        currentObj.Logger.logError(errorMsg);
                    });
                    return;
                }
            }

                if (splitid && mergeid && (splitid.length > 0 && mergeid.length > 0) && !isCTmodelvalueNo){
                    splitid.forEach((value,index)=>{
                        mergeid.forEach((val,ind)=>{
                    if(value && (value.trackingNumber.replace(/\D/g, "") == val.trackingNumber.replace(/\D/g, "")) ){//&& isCTenabled
                        isMergeAllowed = true;
                       // this.newgroupItemsList = [];
                        this.grouper.mergeIAItems(this.mergeData,"IA-MERGE-ORDERITEMPAGE").subscribe((responseMerge : any)=>{
                            tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                            currentObj.spinnerService.hide();
                            if(responseMerge.hasWarningOrError === "false"){
                                // empty array only when new group is successfully created
                                this.newgroupItemsList = [];
                                currentObj.itemsInIA.push();
                                // Push item to droppable area only on success
                                transferArrayItem(event.previousContainer.data,
                                    event.container.data,
                                    event.previousIndex,
                                    event.currentIndex);
                                    this.updateCopysheetInSecondItem(draggedEleItemId,currentObj.itemObject.svItemId);
                                    let oldgroupId = this.Indicator.items[draggedEleItemId].data.iaGroupId;
                                tempIndicator.items[draggedEleItemId].data.iaGroupId = responseMerge.groupId;
                                currentObj.showButton = false;
                                let activityLogHistoryObj = {
                                    'displayName':this.Indicator.user.name,
                                    'email':this.Indicator.user.email,
                                    'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                                    'itemId':draggedEleItemId,
                                    'itemStatus': this.Indicator.items[draggedEleItemId].order.status,
                                    'itemActionCode': this.Indicator.items[draggedEleItemId].order.actionCode + "-" + this.Indicator.items[draggedEleItemId].order.actionType,
                                    'fieldName':'IA Group',
                                    'fieldOldValue':oldgroupId,
                                    'fieldNewValue':responseMerge.groupId
                                }

                                this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                                //update Akita store
                                //currentObj.itemsInIA = [];
                                this.removeFromCreateGroup(draggedEleItemId,currentObj.itemsInIA,true);
                                currentObj.indicatorStore.setLoading(true);
                                currentObj.indicatorStore.update((state) => {
                                  return {
                                    indicator : tempIndicator,
                                    updatedFrom: "[IagroupComponent] mergeIAItems1",
                                    isloaded: true,
                                  }
                                });
                                currentObj.indicatorStore.setLoading(false);
                                //this.graphicItems = "";
                                this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator); // To do
                                currentObj.loadNonIAGroupTable();
                                //this.ngOnInit();
                            }
                        },function(error){
                            currentObj.spinnerService.hide();
                            currentObj.showButton = false;
                            if (currentObj.newgroupItemsList && currentObj.newgroupItemsList.length > 0){
                                for (let i=0; i < currentObj.newgroupItemsList.length; i++){
                                    if(currentObj.newgroupItemsList[i] == draggedEleItemId ){
                                        currentObj.newgroupItemsList.splice(i,1);
                                        break;
                                    }
                                }
                            }
                            //this.graphicItems = "";
                            // this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(); // to do
                            // currentObj.loadNonIAGroupTable();
                            let errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
                            let errorMsg = "";
                            if(errorObject){
                                let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                                let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                                errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                            }
                            currentObj.Logger.logError(errorMsg);
                        });
                    }else{
                        currentObj.spinnerService.hide();
                        if (ind == mergeid.length-1 && !isMergeAllowed && index == (splitid.length - 1))
                        {
                            currentObj.Logger.logError("Items must have the same Call tracking number.");
                        }

                    }
                    });
                    });
                }
                else
                {
                    if (!isCTenabled && isSameUdac)
                    {
                        currentObj.Logger.logError("Items must have the same Call tracking number.");
                        currentObj.spinnerService.hide();
                        return;
                    }
                    else if (isCTmodelvalueNo || (splitid.length == 0 && mergeid.length == 0)) {
                        isMergeAllowed = true;
                       // this.newgroupItemsList = [];
                        this.grouper.mergeIAItems(this.mergeData,"IA-MERGE-ORDERITEMPAGE").subscribe((responseMerge:any)=>{

                            tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                            currentObj.spinnerService.hide();
                            if(responseMerge.hasWarningOrError === "false"){
                                this.newgroupItemsList = [];
                                currentObj.itemsInIA.push();
                                // Push item to droppable area only on success
                                transferArrayItem(event.previousContainer.data,
                                    event.container.data,
                                    event.previousIndex,
                                    event.currentIndex);
                                let oldgroupId = this.Indicator.items[draggedEleItemId].data.iaGroupId;
                                tempIndicator.items[draggedEleItemId].data.iaGroupId = responseMerge.groupId;
                                currentObj.showButton = false;
                                this.updateCopysheetInSecondItem(draggedEleItemId,currentObj.itemObject.svItemId);
                                let activityLogHistoryObj = {
                                    'displayName':this.Indicator.user.name,
                                    'email':this.Indicator.user.email,
                                    'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                                    'itemId':draggedEleItemId,
                                    'itemStatus': this.Indicator.items[draggedEleItemId].order.status,
                                    'itemActionCode': this.Indicator.items[draggedEleItemId].order.actionCode + "-" + this.Indicator.items[draggedEleItemId].order.actionType,
                                    'fieldName':'IA Group',
                                    'fieldOldValue':oldgroupId,
                                    'fieldNewValue':responseMerge.groupId
                                   }
                                this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                                //this.graphicItems = "";
                                //update Akita store
                                //currentObj.itemsInIA = [];
                                this.removeFromCreateGroup(draggedEleItemId,currentObj.itemsInIA,true);
                                currentObj.indicatorStore.setLoading(true);
                                currentObj.indicatorStore.update((state) => {
                                  return {
                                    indicator : tempIndicator,
                                    updatedFrom: "[IagroupComponent] mergeIAItems2",
                                    isloaded: true,
                                  }
                                });
                                currentObj.indicatorStore.setLoading(false);
                               // this.graphicItems = "";
                                this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator); // To do
                                currentObj.loadNonIAGroupTable();
                                //this.ngOnInit();
                            }
                        },function(error){
                            currentObj.spinnerService.hide();
                            currentObj.showButton = false;
                            if (currentObj.newgroupItemsList && currentObj.newgroupItemsList.length > 0){
                                for (let i=0; i < currentObj.newgroupItemsList.length; i++){
                                    if(currentObj.newgroupItemsList[i] == draggedEleItemId ){
                                        currentObj.newgroupItemsList.splice(i,1);
                                        break;
                                    }
                                }
                            }
                            //this.graphicItems = "";
                            // this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(); // To do
                            // currentObj.loadNonIAGroupTable();
                            let errorMsg = "";
                            let errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
                            if(errorObject){
                                let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                                let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                                errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                            }
                            currentObj.Logger.logError(errorMsg);
                        });
                    }
                }
        }else{
            currentObj.spinnerService.hide();
        }
       // currentObj.loadNonIAGroupTable()
         // currentObj.itemsInIA.push(); // this code is pushing itemObj, need to write this in correct place
      }
    }
    updateCopysheetInSecondItem(newItem,itemInIA){
        tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
        if (!tempIndicator.items[itemInIA].copy){
            tempIndicator.items[itemInIA]['copy'] = {};
        }
        if (!tempIndicator.items[newItem].copy){
            tempIndicator.items[newItem]['copy'] = {};
        }
        tempIndicator.items[newItem].copy['copysheet'] = tempIndicator.items[itemInIA].copy['copysheet'];
        tempIndicator.items[newItem]['baseCopysheet'] = tempIndicator.items[itemInIA].copy['copysheet'];
         // tempIndicator.items[newItem].copy['previousCopySheet'] = tempIndicator.items[itemInIA].copy.previousCopySheet;
        tempIndicator.items[newItem]['hasCopysheet'] = tempIndicator.items[itemInIA].hasCopysheet;
        //Commented Changes added for upload copysheet 
        // if(tempIndicator.items[itemInIA].copy.uploadFile){
        //     if(tempIndicator.items[newItem].copy.hasOwnProperty('uploadFile')){
        //     tempIndicator.items[newItem].copy.uploadFile = tempIndicator.items[itemInIA].copy.uploadFile;
        //      }
        //  else{
        //     tempIndicator.items[newItem].copy['uploadFile'] = {};
        //     tempIndicator.items[newItem].copy.uploadFile = tempIndicator.items[itemInIA].copy.uploadFile;
        //     }
        // }
        //Adding flag for adVersionIncInd of copysheet
        if(tempIndicator.items[itemInIA].copy.hasOwnProperty('copysheet')){
            this.StorageService.updateField("item", newItem , { "data.adVersionIncInd": "Y" }).subscribe(res => {
                console.log(res)
             });
             this.StorageService.updateField("item", itemInIA , { "data.adVersionIncInd": "Y" }).subscribe(res => {
                console.log(res)
             });
        }
        //FUL-25198
        if (tempIndicator.items[newItem].order.actionCode == "Renew" && !tempIndicator.items[newItem].model.dirtyFlag){
            tempIndicator.items[newItem].model['dirtyFlag'] = true;
            // if (tempIndicator.items[newItem].hasOwnProperty('copy')){
            //     tempIndicator.items[newItem].copy['copysheet'] = null;
            // }else{
            //     tempIndicator.items[newItem]['copy'] = {};
            //     tempIndicator.items[newItem].copy['copysheet'] = null;
            // }
            tempIndicator.items[newItem]['hasCopysheet'] = false;
            tempIndicator.items[newItem]['requiresCopysheet'] = true;
            //tempIndicator.items[newItem].counter++;
            // Straight Renewal code change
            // tempIndicator.items[newItem].model['renewalCopysheetRequiredFlag'] = true;
            // this.StorageService.update("item", newItem + "v" + tempIndicator.items[newItem].order.version, tempIndicator.items[newItem].model).then(result => { });
            this.StorageService.updateField("copyz", newItem + "v"+ tempIndicator.items[newItem].order.version, {"data.requiresCopysheet" : true})
            .subscribe(()=>{},err=>{});
        }
        if (tempIndicator.items[newItem].order.actionCode == "Renew"){
            tempIndicator.items[newItem].model.renewalCopysheetRequiredFlag = true;
            tempIndicator.items[newItem].model['renewHasCopysheet'] = true;
            this.StorageService.update("item", newItem + "v" + tempIndicator.items[newItem].order.version, tempIndicator.items[newItem].model).then(result => { });
            this.StorageService.updateField("copyz", newItem + "v"+ tempIndicator.items[newItem].order.version, {"data.requiresCopysheet" : true})
            .subscribe(()=>{},err=>{});
        }
        // update copysheetIndicator value
        if (tempIndicator.items[newItem].order.productType === "Print"){
            if (tempIndicator.items[newItem].model.hasOwnProperty('ManageCopysheet')){
                tempIndicator.items[newItem].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                tempIndicator.items[itemInIA].model.ManageCopysheet['copySheetIndicator'] = 'Y';
            }else{
                tempIndicator.items[newItem].model['ManageCopysheet'] = {};
                tempIndicator.items[newItem].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                tempIndicator.items[itemInIA].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
            }
            tempIndicator.items[newItem].model['digitalApiFlag'] = true;
        }else{
            tempIndicator.items[newItem].model['copySheetIndicator'] = 'Y';
            tempIndicator.items[itemInIA].model['copySheetIndicator'] = 'Y';
            tempIndicator.items[newItem].model['digitalApiFlag'] = true;
        }
        //Make service call
        this.Copysheet.updateCopysheet(newItem + "v" + tempIndicator.items[newItem].order.version, tempIndicator.items[newItem].copy);
        this.updateIAGroupCounter(newItem, tempIndicator);
        this.indicatorQuery.getIndicator().subscribe((res) => {
            tempIndicator = JSON.parse(JSON.stringify(res));
        });
    }
    // on item drop Non IA
    nonIADroppableConfig(draggedItem:any,isDroppedFromIA?){
        let nonIAdroppable = true;
        this.indicatorQuery.getIndicator().subscribe((res) => {
            tempIndicator = { ...res };
        });
        tempIndicator = JSON.parse(JSON.stringify(tempIndicator));

       // let draggedEleItemId = draggedItem.container.data[0] ? draggedItem.container.data[0].svItemId: draggedItem.item.data.svItemId;
       let draggedEleItemId = draggedItem.item.data ? draggedItem.item.data.svItemId
                                                     : draggedItem.previousContainer.data
                                                     ? draggedItem.previousContainer.data[0].svItemId
                                                     : ''
        let unDraggedId = "";
        if(this.graphicItems.iaItemslist.indexOf(draggedEleItemId) !== -1)
        {
            this.splitData = {
                "consumingSystem": "CA",
                "eAccountId": this.Indicator.account.kgenCustomerId,
                "eItemId": draggedEleItemId
            }
            currentObj.spinnerService.show();
            this.grouper.splitIAItems(this.splitData,"IA-SPLIT-ORDERITEMPAGE").then((response: any)=>{
                tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                if(response.hasWarningOrError === "false"){
                    currentObj.spinnerService.hide();
                    if((tempIndicator.items[draggedEleItemId].order.actionCode === 'Add' && tempIndicator.items[draggedEleItemId].order.actionType === 'New') || (tempIndicator.items[draggedEleItemId].order.actionCode === 'Renew' )){//&& tempIndicator.items[draggedEleItemId].order.actionType === 'Update'
                        let itemCounter = 1;
                        for (let key in tempIndicator.items) {
                            if (tempIndicator.items[key].data
                                && tempIndicator.items[key].data.iaGroupId === tempIndicator.items[draggedEleItemId].data.iaGroupId
                                && tempIndicator.items[key].order.svItemId !== draggedEleItemId){
                                    unDraggedId = tempIndicator.items[key].order.svItemId;
                                    itemCounter++;
                                }
                        }
                        if (itemCounter == 2){
                            if (tempIndicator.items[unDraggedId].order.actionCode === 'Renew') {
                                tempIndicator.items[unDraggedId].model.renewalCopysheetRequiredFlag = true;
                                tempIndicator.items[unDraggedId].model['renewHasCopysheet'] = false;
                                this.StorageService.update("item", unDraggedId + "v" + tempIndicator.items[unDraggedId].order.version, tempIndicator.items[unDraggedId].model).then(result => { });
                            }
                            tempIndicator.items[unDraggedId]['copy']['copysheet'] = null;
                            tempIndicator.items[unDraggedId]['copy']['fileType'] = '';
                            tempIndicator.items[unDraggedId].hasCopysheet = false;
                            if (tempIndicator.items[unDraggedId].requiresCopysheet || (tempIndicator.items[unDraggedId].order.actionCode === 'Renew' && !tempIndicator.items[unDraggedId].requiresCopysheet)){
                                if(tempIndicator.items[unDraggedId].order.actionCode === 'Renew'){
                                    tempIndicator.items[unDraggedId].requiresCopysheet = true;
                                    this.StorageService.updateField("copyz", unDraggedId + "v"+ tempIndicator.items[unDraggedId].order.version, {"data.requiresCopysheet" : true})
                                    .subscribe(()=>{},err=>{});
                                    //StorageService.updateField("copyz", unDraggedId + "v"+ Indicator.items[unDraggedId].order.version, {"data.hasCopysheet" : false});
                                }
                                let id = unDraggedId;
                                let currentCount = tempIndicator["items"][id].counter;
                                let tempData = this.requiredfieldcountService.getNewItemCount(tempIndicator["items"][id].requiredFields, tempIndicator["items"][id].model, tempIndicator["items"][id].order, tempIndicator["orderPercent"], tempIndicator["items"][id].counter, true, tempIndicator["items"][id]);
                                this.updateIndictor(id, tempIndicator, tempData, currentCount);
                                tempIndicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                                this.Copysheet.updateCopysheet(unDraggedId + "v" + tempIndicator.items[unDraggedId].order.version, tempIndicator.items[unDraggedId].copy)
                                .then(response => { }
                                , err => {
                                    this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                                });
                                this.StorageService.updateField("copyz", unDraggedId + "v"+ tempIndicator.items[unDraggedId].order.version, {"data.hasCopysheet" : false}).subscribe(data =>{},err =>{});
                                // tempIndicator.items[unDraggedId].counter++;
                                // tempIndicator.orderPercent.currentCount--;
                                // this.requiredfieldcountService.getPercent(tempIndicator.orderPercent.currentCount,tempIndicator.orderPercent.maxCount);
                            }
                        }else{
                            tempIndicator.items[draggedEleItemId]['copy']['copysheet'] = null;
                            tempIndicator.items[draggedEleItemId]['copy']['fileType'] = '';
                            tempIndicator.items[draggedEleItemId].hasCopysheet = false;
                        }
                        if (tempIndicator.items[draggedEleItemId].requiresCopysheet || (tempIndicator.items[draggedEleItemId].order.actionCode === 'Renew' && !tempIndicator.items[draggedEleItemId].requiresCopysheet)) {
                            if(tempIndicator.items[draggedEleItemId].order.actionCode === 'Renew'){
                                tempIndicator.items[draggedEleItemId].requiresCopysheet = true;
                                this.StorageService.updateField("copyz", draggedEleItemId + "v"+ tempIndicator.items[draggedEleItemId].order.version, {"data.requiresCopysheet" : true})
                                .subscribe(()=>{},err=>{})
                                //StorageService.updateField("copyz", draggedEleItemId + "v"+ Indicator.items[draggedEleItemId].order.version, {"data.hasCopysheet" : false});
                            }
                            //tempIndicator.items[draggedEleItemId].counter++;
                                tempIndicator.items[draggedEleItemId]['copy']['copysheet'] = null;
                                tempIndicator.items[draggedEleItemId]['copy']['fileType'] = '';
                                tempIndicator.items[draggedEleItemId].hasCopysheet = false;

                                let id = draggedEleItemId;
                                let currentCount = tempIndicator["items"][id].counter;
                                let tempData = this.requiredfieldcountService.getNewItemCount(tempIndicator["items"][id].requiredFields, tempIndicator["items"][id].model, tempIndicator["items"][id].order, tempIndicator["orderPercent"], tempIndicator["items"][id].counter, true, tempIndicator["items"][id]);
                                this.updateIndictor(id, tempIndicator, tempData, currentCount);
                                tempIndicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                                this.StorageService.updateField("copyz", draggedEleItemId + "v"+ tempIndicator.items[draggedEleItemId].order.version, {"data.hasCopysheet" : false}).subscribe(data =>{},err =>{});
                        }
                        // To do check this one
                        // update copysheet
                        this.Copysheet.updateCopysheet(draggedEleItemId + "v" + tempIndicator.items[draggedEleItemId].order.version, tempIndicator.items[draggedEleItemId].copy)
                        .then(response => { }
                        , err => {
                            this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                        });
                    }
                    let oldgroupId = tempIndicator.items[draggedEleItemId].data.iaGroupId;
                    tempIndicator.items[draggedEleItemId].data.iaGroupId = response.groupId;
                    let activityLogHistoryObj = {
                        'displayName':this.Indicator.user.name,
                        'email':this.Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                        'itemId': draggedEleItemId,
                        'itemStatus': this.Indicator.items[draggedEleItemId].order.status,
                        'itemActionCode': this.Indicator.items[draggedEleItemId].order.actionCode + "-" + this.Indicator.items[draggedEleItemId].order.actionType,
                        'fieldName':'IA Group',
                        'fieldOldValue':oldgroupId,
                        'fieldNewValue':response.groupId
                    }
                    this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                    //Akita store update
                    //currentObj.itemsInIA = [];
                    this.removeFromCreateGroup(draggedEleItemId,currentObj.itemsInIA)
                    currentObj.indicatorStore.setLoading(true);
                    currentObj.indicatorStore.update((state) => {
                        return {
                            indicator : tempIndicator,
                            updatedFrom: "[IagroupComponent] splitIAItems1",
                            isloaded: true,
                        }
                    });
                    currentObj.indicatorStore.setLoading(false);
                    this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator);
                    currentObj.loadNonIAGroupTable();
                }
            },function(error){
                currentObj.spinnerService.hide();
                this.graphicItems =currentObj.graphicContentGridServiceService.buildGraphicProducts();
                error.response = error.response ? error.response : error.error.response;
                let errorObject = error.response.esbServiceFault ? error.response.esbServiceFault
                                                                 : error.data.response.esbServiceFault
                                                                 ? error.data.response.esbServiceFault
                                                                 : null;
                let errorMsg;
                if (errorObject){
                    let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                    let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                    errorMsg = "<strong>Transaction : " + error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                }
                currentObj.Logger.logError(errorMsg);
            });

        }else if(currentObj.itemObject.svItemId && draggedEleItemId === currentObj.itemObject.svItemId){
            this.newgroupItemsList = [];
            this.splitData = {
                "consumingSystem": "CA",
                "eAccountId": this.Indicator.account.kgenCustomerId,
                "eItemId": draggedEleItemId
            }
            currentObj.spinnerService.show();
            this.grouper.splitIAItems(this.splitData,"IA-SPLIT-ORDERITEMPAGE").then((response :any)=>{
                tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                if(response.hasWarningOrError === "false"){
                    currentObj.showButton = false;
                    currentObj.spinnerService.hide();
                    currentObj.itemObject.iaGroupId = response.groupId;
                    let oldgroupId = tempIndicator.items[draggedEleItemId].data.iaGroupId;
                    tempIndicator.items[draggedEleItemId].data.iaGroupId = response.groupId;
                    let activityLogHistoryObj = {
                        'displayName': this.Indicator.user.name,
                        'email': this.Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                        'itemId': draggedEleItemId,
                        'itemStatus': this.Indicator.items[draggedEleItemId].order.status,
                        'itemActionCode': this.Indicator.items[draggedEleItemId].order.actionCode + "-" + this.Indicator.items[draggedEleItemId].order.actionType,
                        'fieldName':'IA Group',
                        'fieldOldValue':oldgroupId,
                        'fieldNewValue':response.groupId
                    }
                    this.StorageService.update("itemHistory", draggedEleItemId, activityLogHistoryObj);
                    this.removeFromCreateGroup(draggedEleItemId,currentObj.itemsInIA);
                    this.graphicItems = this.graphicContentGridServiceService.buildGraphicProducts(tempIndicator);
                    currentObj.loadNonIAGroupTable();
                }else{
                    currentObj.spinnerService.hide();
                }

            },function(error){
                currentObj.showButton = false;
                currentObj.spinnerService.hide();
               // this.graphicItems = this.graphicContentGridServiceService.buildGraphicProducts();
                let errorObject = error.data.response.esbServiceFault ? error.data.response.esbServiceFault : null;
                let errorMsg='';
                if(errorObject){
                    let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                    let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                    errorMsg = "<strong>Transaction : " + error.data.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                }
                currentObj.Logger.logError(errorMsg);
            });
        }
    }
    async splitAll(data) {
        let totalNoOfItems = data.length;
        currentObj.spinnerService.show();
        for (let indx = 0; indx < totalNoOfItems; indx++) {
            //Make split call
            let item = data[indx];
            this.splitData = {
                "consumingSystem": "CA",
                "eAccountId": this.Indicator.account.kgenCustomerId,//"2105546432"
                "eItemId": item.svItemId
            }
            await this.makeSplitServiceCall(this.splitData, indx, totalNoOfItems);
        }
    }
    async makeSplitServiceCall(splitData,currentCall,length){
        try{
        let response = await this.grouper.splitIAItems(splitData,"IA-SPLIT-ORDERITEMPAGE")
            this.serviceCallCount++;
            if(response.hasWarningOrError === "false"){
                // if (!tempIndicator){
                //     tempIndicator = JSON.parse(JSON.stringify(this.Indicator));
                // }else{
                //     tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                // }
                tempIndicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                currentObj.itemObject['iaGroupId'] = response.groupId;
                this.newgroupId = response.groupId;
                let oldgroupId = this.Indicator.items[splitData.eItemId].data.iaGroupId;
                tempIndicator.items[splitData.eItemId].data.iaGroupId = this.newgroupId;
                let storageID = splitData.eItemId + "v" + tempIndicator.items[splitData.eItemId].order.version;
                if (tempIndicator.items[splitData.eItemId].order.actionCode === 'Renew') {
                    tempIndicator.items[splitData.eItemId].model.renewalCopysheetRequiredFlag = true;
                    //console.log('inside renewal flag true')
                    // update itemstorage
                    this.StorageService.update("item", splitData.eItemId + "v" + tempIndicator.items[splitData.eItemId].order.version, tempIndicator.items[splitData.eItemId].model).then(result => {console.log('storage result'+JSON.stringify(result)) });
                }
                if (tempIndicator.items[splitData.eItemId]['copy']['copysheet']){
                    tempIndicator.items[splitData.eItemId]['copy']['copysheet'] = null;
                    tempIndicator.items[splitData.eItemId]['copy']['fileType'] = '';
                    tempIndicator.items[splitData.eItemId].hasCopysheet = false;
                    //tempIndicator.items[splitData.eItemId].counter++;
                    //tempIndicator.orderPercent.currentCount--;
                    //this.requiredfieldcountService.getPercent(tempIndicator.orderPercent.currentCount,tempIndicator.orderPercent.maxCount);
                     //update copysheet
                     this.Copysheet.updateCopysheet(splitData.eItemId + "v" + tempIndicator.items[splitData.eItemId].order.version, tempIndicator.items[splitData.eItemId].copy)
                     .then(response => { }
                       , err => {
                         this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                       });
                       this.StorageService.updateField("copyz", storageID, { "data.hasCopysheet": false}).subscribe(data =>{},err =>{});
                    let id = splitData.eItemId;
                    let currentCount = tempIndicator["items"][id].counter;
                    let tempData = this.requiredfieldcountService.getNewItemCount(tempIndicator["items"][id].requiredFields, tempIndicator["items"][id].model, tempIndicator["items"][id].order, tempIndicator["orderPercent"], tempIndicator["items"][id].counter, true, tempIndicator["items"][id]);
                    this.updateIndictor(id, tempIndicator, tempData, currentCount);
                    tempIndicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                    //tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
                }else{
                    tempIndicator.items[splitData.eItemId]['copy']['copysheet'] = null;
                    tempIndicator.items[splitData.eItemId]['copy']['fileType'] = '';
                    if (tempIndicator.items[splitData.eItemId].order.actionCode == "Renew"){
                        tempIndicator.items[splitData.eItemId]['requiresCopysheet'] = true;
                        this.StorageService.updateField("copyz", splitData.eItemId + "v"+ tempIndicator.items[splitData.eItemId].order.version, {"data.requiresCopysheet" : true})
                                    .subscribe(()=>{},err=>{});
                    }
                    if (tempIndicator.items[splitData.eItemId].hasOwnProperty('hasCopysheet')){
                        tempIndicator.items[splitData.eItemId].hasCopysheet = false;
                        let id = splitData.eItemId;
                        let currentCount = tempIndicator["items"][id].counter;
                        let tempData = this.requiredfieldcountService.getNewItemCount(tempIndicator["items"][id].requiredFields, tempIndicator["items"][id].model, tempIndicator["items"][id].order, tempIndicator["orderPercent"], tempIndicator["items"][id].counter, true, tempIndicator["items"][id]);
                        this.updateIndictor(id, tempIndicator, tempData, currentCount);
                        tempIndicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
                    }else{
                        tempIndicator.items[splitData.eItemId]['hasCopysheet'] = false;
                    }

                }

                let activityLogHistoryObj = {
                    'displayName':this.Indicator.user.name,
                    'email':this.Indicator.user.email,
                    'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                    'itemId':splitData.eItemId,
                    'itemStatus': this.Indicator.items[splitData.eItemId].order.status ,
                    'itemActionCode': this.Indicator.items[splitData.eItemId].order.actionCode + "-" + this.Indicator.items[splitData.eItemId].order.actionType,
                    'fieldName':'IA Group',
                    'fieldOldValue':oldgroupId,
                    'fieldNewValue':this.newgroupId
                }
                this.StorageService.update("itemHistory", splitData.eItemId, activityLogHistoryObj);
                //Akita store update
                this.removeFromCreateGroup(splitData.eItemId,currentObj.itemsInIA);
                currentObj.indicatorStore.setLoading(true);
                currentObj.indicatorStore.update((state) => {
                    return {
                        indicator : tempIndicator,
                        updatedFrom: "[IagroupComponent] makeSplitServiceCall",
                        isloaded: true,
                    }
                });
                currentObj.indicatorStore.setLoading(false);
            }else{
                currentObj.spinnerService.hide();
                this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator);
            }
            if (this.serviceCallCount == length){
                this.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator);
                currentObj.spinnerService.hide();
                currentObj.Logger.log("Please add Copysheet Again!");
                this.serviceCallCount = 0;
            }
        }catch(error){
            currentObj.spinnerService.hide();
            let errorMsg = "";
            let errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
            if(errorObject){
                let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
                let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
                errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            }
            currentObj.graphicItems = currentObj.graphicContentGridServiceService.buildGraphicProducts(tempIndicator);
            currentObj.loadNonIAGroupTable();
            currentObj.Logger.logError(errorMsg);
        }
    }
    removeFromCreateGroup(currentItem,itemList,isMerge?:boolean){
        if (isMerge){
            this.itemsInIA = [];
        }
        else if(itemList && itemList.length > 0){
            for (let i=0; i<itemList.length; i++){
                if(itemList[i].svItemId == currentItem ){
                    itemList.splice(i,1);
                    this.itemsInIA = itemList;
                    break;
                }
            }
        }
    }
    goToItemPage(itemDetails){
        this.isInsideItemBUttonClicked = true;
        this.close();
        setTimeout(() => {// timeout is added for spinner issue
            currentObj.router.navigate([`/printitems/item/${itemDetails.svItemId}`]);
          }, 1000);

    }
    
    convertImageToPdf(imageUrl: any) {
        let pdf_Base64: any;
        let copySheetUrl: string;
        let fd = new FormData();
        let filename = Math.round(Math.random() * 1000000000000) + "";

        console.log('Inside convertImageToPdf method ', imageUrl);
        return new Promise(async (resolve, reject) => {
            try {
                // Trigger the file service to fetch pdf in base64 format
                pdf_Base64 = await this.file.imageToPdf(imageUrl);
                // Convert the pdf in base64 format to blob
                const base64Response = await fetch(`data:image/jpeg;base64,${pdf_Base64}`);
                const blob = await base64Response.blob();
                // Trigger the service to save the pdf as copysheet in mongo database
                fd.append("files", blob, filename + '.' + 'pdf');
                fd.append("user", JSON.parse(JSON.stringify({ parameters: { accountId: this.Indicator.order.accountId, svOrderId: this.Indicator.order.orderId } })));
                this.file.savePdf(fd).then(resp => {
                    console.log('resp :: ', resp);
                    resolve(resp['copysheet']);
                });
            } catch (error) {
                reject(error)
            }
        });
    }
    openUploadDialog(item,page?){
        this.indicatorQuery.getIndicator().subscribe((res) => {
            this.Indicator = { ...res };
        });
        let storageID = item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version;
        this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
        if (this.Indicator.items && this.Indicator.items[item.svItemId].isCoysheetAnnotated) {
            this.Logger.logError('Please save annotated copysheet.');
        }//Commented below part since its the same in graphic item component
         //commenting this fix which was released as part of FUL-28649. The code came from branch copysheet-callIssue which is mentioned in the defect comment
        //  else if (!this.Indicator.items[item.svItemId].generatedCopysheet) {
        //     if (this.Indicator.items[item.svItemId].model.BillingAccount){
        //         this.manageCopySheetService.generateCopysheet(item.svItemId, this.Indicator,'upload',null,page);
        //     }else{
        //         this.Logger.logError(" Please select Billing Account");
        //         currentObj.spinnerService.hide();
        //     }
        // }
        else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.panelClass = 'upload-copysheet-modal';
            dialogConfig.data = {
              dialogform: this.copyDialogData
            }
            let dialogRef = this.dialog.open(CopysheetUploadComponent, dialogConfig);
            this.copyDialogData.dialogRef = dialogRef;
            let copysheet;

            dialogRef.afterClosed().subscribe( async data => {
                console.log("IA grp...",item.svItemId)
                 let IAgrp = 'COPYSHEET-UPLOAD-GRAPHICSITEM_IAPAGE';
                 if(data){
                    let resData = { ...data};
                    console.log("Response:",resData)
                    let fileData : any = resData.fileObj;
                    console.log("File obj",fileData)
                    this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
                    this.Indicator.items[item.svItemId].copy['uploadFile'] ={};
                    this.Indicator.items[item.svItemId].copy['uploadFile']= resData;
                    this.Indicator.items[item.svItemId]['isCopySheetUploaded'] = true;
                    
                    console.log("UploadFile",this.Indicator.items[item.svItemId].copy.uploadFile)
                    this.indicatorStore.update(state => {
                        console.log("Indicator Before update:",this.Indicator)
                        return {
                          indicator: this.Indicator,
                          updatedFrom: "[IAGroupComponent] openUploadDialog"
                        }
                      })
                        this.indicatorQuery.getIndicator().subscribe(data => {
                      this.Indicator = JSON.parse(JSON.stringify(data));
                      console.log("Indicator After Update:",this.Indicator)
                    }); 
                }
                //   if(data && (page == IAgrp)){
                //       this.iaGrpReplaceCopySheet(item);
                //   }
                this.indicatorQuery.getIndicator().subscribe((res) => {
                    this.Indicator = { ...res };
                });
                this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
              if (data && data.uploadedFile.copysheet != null) {
                if (data.fileType === 'copysheet') {
                  this.Indicator.items[item.svItemId].isCoysheetSubmitted = true;
                  this.StorageService.updateField("copyz",
                  item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version,
                  { "data.isCoysheetSubmitted": true }
                  ).subscribe(data =>{},err =>{})
                }
                //Adding flag for view annotate and cancel for the first time => FUL-37657,37656  
                if(!this.Indicator.items[item.svItemId].hasCopysheet){
                    this.Indicator.items[item.svItemId].copy['cancelCopySheet'] = true;
                }
                //Adding flag for adVersionIncInd of copysheet
                //this.Indicator.items[item.svItemId]['adVersionIncInd'] = 'Y';
                this.StorageService.updateField("item", item.svItemId , { "data.adVersionIncInd": "Y" }).subscribe(res => {
                    console.log(res)
                 });
                if (this.Indicator.items[item.svItemId].order.statusCode >= 6000) {
                  this.Indicator.items[item.svItemId].needsNewCopysheet = true;
                }
                let copsheetFileType = (data.uploadedFile.copysheet.split(".")[data.uploadedFile.copysheet.split(".").length - 1]).toLowerCase();
                // let pdf_copysheet_url;
                console.log(copsheetFileType);
                if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {

                    await this.convertImageToPdf(data.uploadedFile.copysheet).then((pdfurl) => {
                        console.log("pdfurl :: ", pdfurl);
                        this.pdf_copysheet_url = pdfurl;
                        this.IaGroup_pdf =true;
                    }).catch((err) => {
                        console.log('Exception in converting image to PDF : ', err)
                    })
                } else {
                   this.pdf_copysheet_url = data.uploadedFile.copysheet;
                }
                
                let oldCopysheet = (this.Indicator.items[item.svItemId].hasCopysheet == true) ? this.Indicator.items[item.svItemId].copy.copysheet : null;
                // this.Indicator.items[item.svItemId].copy.copysheet = data.uploadedFile.copysheet;
                // this.Indicator.items[item.svItemId].baseCopysheet = data.uploadedFile.copysheet;
                this.Indicator.items[item.svItemId].copy.copysheet = this.pdf_copysheet_url;
                this.Indicator.items[item.svItemId].baseCopysheet = this.pdf_copysheet_url;
                this.Indicator.items[item.svItemId].actionListObj = [];
                // copysheet = data.uploadedFile.copysheet;
                copysheet = this.pdf_copysheet_url;
                // this.Indicator.items[item.svItemId].actionListObj = [];
                copysheet = data.uploadedFile.copysheet;
                this.Indicator.items[item.svItemId].copy['fileType'] = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                if (!oldCopysheet){
                    //this.Indicator.items[item.svItemId].counter > 0 ? this.Indicator.items[item.svItemId].counter = this.Indicator.items[item.svItemId].counter -1 : this.Indicator.items[item.svItemId].counter = this.Indicator.items[item.svItemId].counter;
                    this.Indicator.items[item.svItemId].hasCopysheet = true;
                    this.updateIAGroupCounter(item.svItemId, this.Indicator);
                }
                if (this.Indicator.items[item.svItemId].copy.fileType.toLowerCase() === 'png' || this.Indicator.items[item.svItemId].copy.fileType.toLowerCase() === 'jpeg' || this.Indicator.items[item.svItemId].copy.fileType.toLowerCase() === 'jpg') {
                  if (!this.Indicator.items[item.svItemId].copy.hasOwnProperty('attachments')) {
                    this.Indicator.items[item.svItemId].copy['attachments'] = [];
                    this.Indicator.items[item.svItemId].copy.attachments.push(this.Indicator.items[item.svItemId].copy.copysheet);
                  }
                  else {
                    this.Indicator.items[item.svItemId].copy['attachments'].push(this.Indicator.items[item.svItemId].copy.copysheet);
                  }
                }
                this.Copysheet.updateCopysheet(item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version, this.Indicator.items[item.svItemId].copy)
                  .then(response => { 
                    var activityLogHistoryObj = {
                        'displayName': this.Indicator.user.name,
                        'email': this.Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                        'itemId': item.svItemId,
                        'itemStatus': this.Indicator.items[item.order.svItemId].order.status,
                        'itemActionCode': this.Indicator.items[item.order.svItemId].order.actionCode + "-" + this.Indicator.items[item.order.svItemId].order.actionType,
                        'fieldName': 'Copysheet Value',
                        'fieldOldValue': oldCopysheet,
                        'fieldNewValue': this.Indicator.items[item.svItemId].copy.copysheet,
                        'apiStatus': 'Success'
                      }
                      this.StorageService.update("itemHistory", item.svItemId, activityLogHistoryObj);
                  }
                    , err => {
                      this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                      var activityLogHistoryObj = {
                        'displayName': this.Indicator.user.name,
                        'email': this.Indicator.user.email,
                        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                        'itemId': item.svItemId,
                        'itemStatus': this.Indicator.items[item.order.svItemId].order.status,
                        'itemActionCode': this.Indicator.items[item.order.svItemId].order.actionCode + "-" + this.Indicator.items[item.order.svItemId].order.actionType,
                        'fieldName': 'Copysheet Value',
                        'fieldOldValue': oldCopysheet,
                        'fieldNewValue': this.Indicator.items[item.svItemId].copy.copysheet,
                        'apiStatus' : 'Failure'
                      }
                      this.StorageService.update("itemHistory", item.svItemId, activityLogHistoryObj);
                    });

                this.Indicator.items[item.svItemId].hasCopysheet = true;
                this.Indicator.items[item.svItemId].model['digitalApiFlag'] = true;
                this.StorageService.updateField("copyz", storageID, { "data.hasCopysheet": true, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
                .subscribe(()=>{},err=>{})
                // var activityLogHistoryObj = {
                //   'displayName': this.Indicator.user.name,
                //   'email': this.Indicator.user.email,
                //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
                //   'itemId': item.svItemId,
                //   'fieldName': 'Copysheet Value',
                //   'fieldOldValue': oldCopysheet,
                //   'fieldNewValue': this.Indicator.items[item.svItemId].copy.copysheet
                // }
                // this.StorageService.update("itemHistory", item.svItemId, activityLogHistoryObj);
                this.StorageService.updateField("copyz", storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
                .subscribe(()=>{},err=>{})
                this.Logger.log('Copysheet uploaded successfully.');
                // this.indicatorStore.update(state =>{
                //   return{
                //     indicator: this.Indicator
                //   }
                // })
                //Reverting the code for IA grp
                //if the item is part of an IA group
                if (this.Indicator.items[item.svItemId].data
                  && this.Indicator.items[item.svItemId].data.iaGroupId) {
                  //go through the other items on the order
                  for (var key in this.Indicator.items) {
                    //if the other item has the matching ia group id & isn't the current item
                    if (this.Indicator.items[key].data
                      && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[item.svItemId].data.iaGroupId
                      && this.Indicator.items[key].order.svItemId !== item.svItemId) {
                      //add the copysheet to the other item
                      if (!this.Indicator.items[key].copy){
                        this.Indicator.items[key]['copy'] = {};
                      }
                      if(this.IaGroup_pdf){
                        this.Indicator.items[key].copy['copysheet'] = this.pdf_copysheet_url;
                        this.Indicator.items[key]['baseCopysheet'] = this.pdf_copysheet_url;
                      }else{
                        this.Indicator.items[key].copy['copysheet'] = data.uploadedFile.copysheet;
                        this.Indicator.items[key]['baseCopysheet'] = data.uploadedFile.copysheet;
                      }
                     
                      this.Indicator.items[key]['hasCopysheet'] = true;
                      //Adding flag for adVersionIncInd of copysheet
                      //this.Indicator.items[key]['adVersionIncInd'] = 'Y';
                      this.StorageService.updateField("item", key , { "data.adVersionIncInd": "Y" }).subscribe(res => {
                        console.log(res)
                     });
                      // update copysheetIndicator value
                      if (this.Indicator.items[key].order.productType === "Print"){
                        if (this.Indicator.items[key].model.hasOwnProperty('ManageCopysheet')){
                            this.Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                            this.Indicator.items[item.svItemId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                        }else{
                            this.Indicator.items[key].model['ManageCopysheet'] = {};
                            this.Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                            this.Indicator.items[item.svItemId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                        }
                        this.Indicator.items[key].model['digitalApiFlag'] = true;
                    }else{
                        this.Indicator.items[key].model['copySheetIndicator'] = 'Y';
                        this.Indicator.items[item.svItemId].model['copySheetIndicator'] = 'Y';
                        this.Indicator.items[key].model['digitalApiFlag'] = true;
                    }

                      this.Indicator.items[key].copy['fileType'] = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
                      if (this.Indicator.items[key].copy.fileType.toLowerCase() === 'png' || this.Indicator.items[key].copy.fileType.toLowerCase() === 'jpeg' || this.Indicator.items[key].copy.fileType.toLowerCase() === 'jpg') {
                        if (!this.Indicator.items[key].copy.hasOwnProperty('attachments')) {
                          this.Indicator.items[key].copy.attachments = [];
                          this.Indicator.items[key].copy.attachments = this.Indicator.items[item.svItemId].copy.attachments;
                        }
                        else {
                          this.Indicator.items[key].copy['attachments'] = this.Indicator.items[item.svItemId].copy.attachments;
                        }
                      }
                      if (this.Indicator.items[key].hasOwnProperty('actionListObj')) {
                        this.Indicator.items[key].actionListObj = this.Indicator.items[item.svItemId].actionListObj;
                      }
                      // Need to check
                      this.Indicator.orderPercent = this.indicatorQuery.getValue().indicator['orderPercent'];
                      this.Indicator.items[item.svItemId].counter = this.indicatorQuery.getValue().indicator['items'][item.svItemId].counter;
                      this.updateIAGroupCounter(key, this.Indicator);
                      //update hasCopysheet flag in storage call
                      let currentStorageId = key + 'v'+ this.Indicator.items[key].order.version;

                      this.StorageService.updateField("copyz", currentStorageId, { "data.hasCopysheet": true}).subscribe(()=>{},err=>{});

                      this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                        .then( (response) => { })
                        .catch(
                          err => {
                            this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                          });
                          this.indicatorQuery.getIndicator().subscribe(data => {
                            this.Indicator = JSON.parse(JSON.stringify(data));
                          });
                    }
                  }
                }
                // this.indicatorStore.update(state =>{
                //     return{
                //       indicator: this.Indicator
                //     }
                //   })
              }
              else if(!data){ 
                if ( this.Indicator.items[item.svItemId].hasOwnProperty("hasCopysheet")  && !this.Indicator.items[item.svItemId]["hasCopysheet"]){
                  this.Indicator.items[item.svItemId].copy.copysheet = null;
                  //this.Indicator.items[this.Indicator.stateParamsId]['baseCopysheet'] =null;
                  this.Copysheet.updateCopysheet(item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version, this.Indicator.items[item.svItemId].copy)
                              .then(() => { }).catch((err) => {
                                this.Logger.logError("Failed to update copysheetII :" + JSON.stringify(err));
                              });
                }              
              }
            });
        }
    }

    private updateIAGroupCounter(itemID,indicatorVal) {
        let id = itemID;
        this.Indicator =indicatorVal; //JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        let currentCount = this.Indicator.items[itemID].counter;//this.requiredFieldCount.get(this.Indicator.items[itemID].schema, this.Indicator.items[itemID].form, this.Indicator.items[itemID].model, this.Indicator.items[itemID].order);

        //this.Indicator.items[itemID].counter = currentCount;
        let tempData = this.requiredfieldcountService.getNewItemCount(this.Indicator["items"][id].requiredFields, this.Indicator["items"][id].model, this.Indicator["items"][id].order, this.Indicator["orderPercent"], currentCount, true, this.Indicator["items"][id]);
        this.updateIndictor(id, this.Indicator, tempData, currentCount);

        // var count = this.requiredFieldCount.get(this.Indicator.items[itemID].schema, this.Indicator.items[itemID].form, this.Indicator.items[itemID].model, this.Indicator.items[itemID].order);
        // this.Indicator.items[itemID].counter = count;

        // if (this.Indicator.items[itemID].requiresCopysheet
        //   && !this.Indicator.items[itemID].hasCopysheet) {
        // //   this.Indicator.items[itemID].counter++;
        // //   this.Indicator.orderPercent.currentCount--;
        //     let id = itemID;
        //     let currentCount = tempIndicator["items"][id].counter;
        //     let tempData = this.requiredfieldcountService.getNewItemCount(tempIndicator["items"][id].requiredFields, tempIndicator["items"][id].model, tempIndicator["items"][id].order, tempIndicator["orderPercent"], tempIndicator["items"][id].counter, true, tempIndicator["items"][id]);
        //     this.updateIndictor(id, tempIndicator, tempData, currentCount);
        //     tempIndicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        // }
    }

    openUploadAttachmentDialog(item){
        //console.log('inside openUploadAttachmentDialog dialog--',item);
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // // dialogConfig.height = '300px';
        // dialogConfig.panelClass = 'attachment-dialog'
        // dialogConfig.data = {
        //   dialogform: this.dialogData
        // }
        // let dialogRef = this.dialog.open(CopysheetFileComponent, dialogConfig);
        // this.dialogData.dialogRef = dialogRef;
        // return dialogRef.afterClosed();
        let stateId = item.svItemId;
        let indicatorValue = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        indicatorValue.stateParamsId = stateId;
        this.indicatorStore.update(() => {
            return {
              indicator: indicatorValue,
              updatedFrom: "[IagroupComponent] openUploadAttachmentDialog",
              isloaded: true,
            };
          });
        this.manageCopySheetService.openUploadAttachmentDialog(stateId,indicatorValue,null);
    }
    openAdditionalInstructions(item,itemsInIA?){
        let itemInIAArr = [];
        if (!itemsInIA){
            itemsInIA = [item.svItemId];
        }
        //console.log('inside openAdditionalInstructions dialog--',item);

        itemsInIA.forEach(singleItem=>{
            itemInIAArr.push(singleItem.svItemId);
        })
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            itemId: item,
            iaItems: itemInIAArr
        }
        dialogConfig.width = '600px';
        const dialogRef = this.dialog.open(ArtistinstructionComponent,dialogConfig);
    }
    async continueButton(item,page?) {
        this.indicatorQuery.getIndicator().subscribe(data => {
            tempIndicator = JSON.parse(JSON.stringify(data));
          });
        if (!tempIndicator){
            tempIndicator = JSON.parse(JSON.stringify(this.Indicator));
        }else{
            tempIndicator = JSON.parse(JSON.stringify(tempIndicator));
        }
        currentObj.spinnerService.show();
        let selectedItemId = item.svItemId;
        let indicator = JSON.parse(JSON.stringify(this.Indicator));
        if(indicator.items[selectedItemId].isCopySheetUploaded){
           // this.iaGrpReplaceCopySheet(item);
           indicator.items[selectedItemId].isCopySheetUploaded = false;
            this.indicatorStore.update(state => {
                return {
                indicator: indicator,
                updatedFrom: "[IA-Group Component] ReplaceCopySheet"
                }
            })
        }
        this.indicatorQuery.getIndicator().subscribe(data => {
            tempIndicator = JSON.parse(JSON.stringify(data));
          });
        tempIndicator.items[selectedItemId].model.copySheetIndicator = 'Y';
        if (typeof tempIndicator.items[selectedItemId].model.ManageCopysheet === "object"){
            tempIndicator.items[selectedItemId].model.ManageCopysheet.copySheetIndicator = 'Y';
        }else{
            tempIndicator.items[selectedItemId].model.ManageCopysheet = 'Y';
        }
        tempIndicator.items[selectedItemId]["isCopysheetSaved"] = true;
        tempIndicator.items[selectedItemId].isCoysheetAnnotated = false;
        this.indicatorStore.update((state) => {
          return {
            indicator: tempIndicator,
            updatedFrom: "[IAGroup Component] UpdateCopySheetProperties",
            isloaded: true,
          };
        });
        currentObj.StorageService.updateField("copyz",selectedItemId + "v" + tempIndicator.items[selectedItemId].order.version, { "data.isCopysheetSaved": true })
        .subscribe(()=>{},err=>{})

        await currentObj.itemService.update(
            tempIndicator.account,
            tempIndicator.order,
            tempIndicator.items[selectedItemId],
            tempIndicator.listing,
            selectedItemId,
            page).then(success=>{
                currentObj.spinnerService.hide();
                if (tempIndicator.items[selectedItemId].order.statusCode >= 6000 && tempIndicator.items[selectedItemId].hasCopysheet){
                    this.sendCopysheetToKGEN(selectedItemId);
                }
            },failure=>{
                currentObj.spinnerService.hide();
            })
    };
    async continueButtonIAGroup(iaItems){
        for(let i=0;i<iaItems.length;i++){
            let iaItem = iaItems[i];
            await this.continueButton(iaItem,"PRODUCT-CREATE-IAPAGE-ORDERITEMPAGE")
        }
    }
    sendCopysheetToKGEN(selectedItemId){
        let item = tempIndicator.items[selectedItemId];
        let adVersionIncIndflg;
                        this.StorageService.get("item", item.order.svItemId).then(data=>{
                            adVersionIncIndflg = data[item.order.svItemId].adVersionIncInd;
                            console.log("adVersionIncIndflg:",data)
                        })
                        this.copyService.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
                            .then( (value)=> {
                                currentObj.spinnerService.show();
                              this.copyService.generateCompiled(item.order.svItemId + "v" + item.order.version, tempIndicator)
                                .then( (updateCopyResp)=> {
                                    this.StorageService.updateField("copyz", item.order.svItemId + "v" + item.order.version, { "kgenurl":  updateCopyResp.url })
                                    .subscribe(()=>{},err=>{})
                                    let data = {
                                        "type": "uploadCopySheet",
                                        "id": "",
                                        "callerId": "UCC",
                                        "correlationId": this.grouper.getAllItemsOfGroupButNotSelf(item.order.svItemId, tempIndicator).toString(),
                                        "sourceId": "",
                                        "copySheet": {
                                            "customerId":currentObj.account.kgenCustomerId,
                                            "svItemId": item.order.svItemId,
                                            "productCode": item.order.directoryNumber,
                                            "productIssueNum": item.order.directoryIssueNumber,
                                            //"adVersionIncInd": "Y", // Y when order status is greater than 6000, increases copysheet version
                                            "adVersionIncInd": adVersionIncIndflg ? adVersionIncIndflg : "N", //get adVersionIncInd value from mongo 
                                            "pdfUrl": updateCopyResp.url,
                                            "date":this.utils.getKgenDate(new Date()),
                                            "operatorId": tempIndicator.order.opportunity.owner.operatorId
                                        }
                                    };
                                    this.copyService.uploadCompiled(item.order.svItemId, item.order.sfdcItemId, data)
                                    .then((copy) => {
                                        //currentObj.spinnerService.hide();
                                        //update salesforce copysheet URL for all Items of IA Group
                                      let sfUpdateitems = [];
                                        for (var itemId in tempIndicator.items) {
                                            if (tempIndicator.items[itemId].data.iaGroupId === tempIndicator.items[item.order.svItemId].data.iaGroupId) {
                                              let sfItem = {
                                                    "eItemId": itemId,
                                                    "SFDCItemId": tempIndicator.items[itemId].order.sfdcItemId,
                                                    "CACopySheetUrl": updateCopyResp.url,
                                                    "copySheetStatus": "Success",
                                                    "systemExceptionReason": " "
                                                };
                                                sfUpdateitems.push(sfItem);
                                            }
                                        }
                                        //Setting adVersionIncInd flag to "N" 
                                        this.StorageService.updateField("item", item.order.svItemId, { "data.adVersionIncInd": "N" }).subscribe(res => {
                                            console.log("adversion set to N for",item.order.svItemId,res)
                                         });
                                        this.salesforceService.saveMultipleSFdata(sfUpdateitems).subscribe((res)=>{

                                        //   if (updateResult.hasWarningOrError == 'false' &&  !updateResult.servicePending){
                                        //     this.routeToNextItem();
                                        //   }
                                        currentObj.spinnerService.hide();
                                        }
                                        ,err =>{
                                            currentObj.spinnerService.hide();
                                            currentObj.Logger.logError("<strong>Error in update SFData <strong><br>Source System : Salesforce <br>");
                                        });
                                        let activityLogHistoryObj = {
                                            'displayName': this.Indicator.user.name,
                                            'email': this.Indicator.user.email,
                                            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                                            'itemId': item.order.svItemId,
                                            'itemStatus': this.Indicator.items[item.order.svItemId].order.status,
                                            'itemActionCode': this.Indicator.items[item.order.svItemId].order.actionCode + "-" + this.Indicator.items[item.order.svItemId].order.actionType,
                                            'fieldName': 'Copysheet Value',
                                            'fieldOldValue': '',
                                            'fieldNewValue': this.Indicator.items[item.order.svItemId].copy.copysheet,
                                            'transactionId': copy.transactionId,
                                            'errorMsg': (copy.esbServiceFault)?copy.esbServiceFault.exceptionDetail[0].errorDescription[0]: '',
                                            'apiStatus': copy.esbServiceFault ? "Failure" : "Success",
                                            'page': 'IA Items'
                                          }
                                          this.StorageService.update("itemHistory", item.order.svItemId, activityLogHistoryObj);

                                        //console.log('item end');
                                        //resolve(updateResult);
                                    }, err=> {
                                        let activityLogHistoryObj = {
                                            'displayName': this.Indicator.user.name,
                                            'email': this.Indicator.user.email,
                                            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                                            'itemId': item.order.svItemId,
                                            'itemStatus': this.Indicator.items[item.order.svItemId].order.status,
                                            'itemActionCode': this.Indicator.items[item.order.svItemId].order.actionCode + "-" + this.Indicator.items[item.order.svItemId].order.actionType,
                                            'fieldName': 'Copysheet Value',
                                            'fieldOldValue': '',
                                            'fieldNewValue': this.Indicator.items[item.order.svItemId].copy.copysheet,
                                            'transactionId':err.error.response.transactionId,
                                            'errorMsg':err.error.response.esbServiceFault.exceptionDetail[0].errorDescription,
                                            'apiStatus': "Failure",
                                            'page': 'IA Items'
                                          }
                                          this.StorageService.update("itemHistory", item.order.svItemId, activityLogHistoryObj);

                                        //update salesforce copysheet URL and error for all Items of IA Group
                                      let sfUpdateitems = [];
                                        for (var itemId in tempIndicator.items) {
                                            if (tempIndicator.items[itemId].data.iaGroupId === tempIndicator.items[item.order.svItemId].data.iaGroupId) {
                                              let sfItem = {
                                                    "eItemId": itemId,
                                                    "SFDCItemId": tempIndicator.items[itemId].order.sfdcItemId,
                                                    "systemExceptionReason": JSON.stringify(err),
                                                    "CACopySheetUrl": updateCopyResp.url,
                                                    "copySheetStatus": "Failure"
                                                };
                                                sfUpdateitems.push(sfItem);
                                            }
                                        }

                                        this.salesforceService.saveMultipleSFdata(sfUpdateitems).subscribe((res) =>{
                                            currentObj.spinnerService.hide();
                                        },err =>{
                                            currentObj.Logger.logError("<strong>Error in update SFData <strong><br>Source System : Salesforce <br>"+JSON.stringify(err));
                                        });
                                        currentObj.spinnerService.hide();
                                        let errorObject = err.error.response.esbServiceFault;
                                        let errorCode = errorObject.sourceSystem;
                                        let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                                        let errorMsg = "<strong>Transaction : " + err.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                                        currentObj.Logger.logError(errorMsg);
                                        //console.log('item end');
                                        //reject(err);
                                        //console.log("updateResult : : " + JSON.stringify(updateResult));
                                    });
                                },err=>{
                                    currentObj.spinnerService.hide();
                                    //Params.saving = false;
                                    //vm.Params.loadingbar = false;
                                    //fix for FUL-8569 --start
                                  tempIndicator.items[tempIndicator.stateParamsId].hasCopysheet = false;
                                  //TODO : If any issue in counter ,we will implement this
                                    // tempIndicator.items[tempIndicator.stateParamsId].counter++;
                                    currentObj.Logger.logError("Error in Generate copysheet.This error may be because of one of the below issues: <br> 1. Image is corrupted.Please remove and add valid Image.try again <br> 2.Please look the error : "+JSON.stringify(err));
                                    //fix for FUL-8569 --end
                                });
                            },err =>{
                                currentObj.spinnerService.hide();
                              // Params.saving = false;
                              // vm.Params.loadingbar = false;
                              currentObj.Logger.logError("Failed to update copysheet :" +JSON.stringify(err));
                            });
    }
    checkSaveButtonState(item){
       return !((currentObj.Indicator.items[item.svItemId].isCoysheetSubmitted && currentObj.Indicator.items[item.svItemId].hasCopysheet) || currentObj.Indicator.items[item.svItemId].isCopySheetUploaded);
    }
    isDragDisabled(getItemDetails){
        return getItemDetails.statusCode >= 6000;
    }
    getCopysheet(item,page?){
        let id = item.svItemId;
        let indicator =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        this.manageCopySheetService.getCopySheetData('annotate', id, indicator,page);
    }
    // Counter related code
    private updateIndictor(itemId, data, tempIndicator, currentCount) {
        let counter = [];
        data = JSON.parse(JSON.stringify(data));
        data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
        data.items[itemId].counter = tempIndicator.itemCount;
        if(this.footerFlag) {
            if(data['items'][itemId].counter == 0) {
                document.getElementById(itemId+'counter').classList.remove('item-order-error');
            } else if(data['items'][itemId].counter !== 0) {
                document.getElementById(itemId+'counter').classList.add('item-order-error');
            }
        }
        data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
        let percentage = this.requiredfieldcountService.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
        data.orderPercent.percent = percentage;
        counter.push(data.orderPercent.digitalCount);
        counter.push(data.orderPercent.printCount);
        counter.push(data.orderPercent.bpTotalCount);
        this.counterService.setError(counter);
        this.updatCounterStore(data);
      }
      private updatCounterStore(data) {
        this.indicatorStore.setLoading(true);
        this.indicatorStore.update(() => {
          return {
            indicator: data,
            updatedFrom: "[IagroupComponent] updateIndictor",
            isloaded: true,
          };
        });
        this.indicatorStore.setLoading(false);

      }

      private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
        let counter = 0;
        let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
        let productType = order && order.productType ? order.productType : '';
        if (itemCount == 0 && currentCount != 0) {
          counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
          orderPercent = this.updateCounter(productType, counter, orderPercent);
        } else if (itemCount != 0 && currentCount == 0) {
          counter = totalItemCount + 1;
          orderPercent = this.updateCounter(productType, counter, orderPercent);
        } else {
          //  counter = totalItemCount;
        }
        currentCount = counter;

        return orderPercent;
      }
      private updateCounter(productType, counter, orderPercent) {
        if (productType && productType === 'Digital') {
          orderPercent.digitalCount = counter - orderPercent.printCount;
        } else if (productType && productType === 'Print') {
          orderPercent.printCount = counter - orderPercent.digitalCount;
        }
        return orderPercent;
      }
      close(){
        this.dialogRef.close();
      }
     async iaGrpReplaceCopySheet(item){
        let copysheet;
        let storageID = item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version;
        let data = this.Indicator.items[item.svItemId].copy.uploadFile;
        this.indicatorQuery.getIndicator().subscribe((res) => {
            this.Indicator = { ...res };
        });
        this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
      if (data && data.uploadedFile.copysheet != null) {
        if (data.fileType === 'copysheet') {
          this.Indicator.items[item.svItemId].isCoysheetSubmitted = true;
          this.StorageService.updateField("copyz",
          item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version,
          { "data.isCoysheetSubmitted": true }
          ).subscribe(data =>{},err =>{})
        }
        //Adding flag for adVersionIncInd of copysheet
        //this.Indicator.items[item.svItemId]['adVersionIncInd'] = 'Y';
        this.StorageService.updateField("item", item.svItemId , { "data.adVersionIncInd": "Y" }).subscribe(res => {
            console.log(res)
         });
        if (this.Indicator.items[item.svItemId].order.statusCode >= 6000) {
          this.Indicator.items[item.svItemId].needsNewCopysheet = true;
        }
        let copsheetFileType = (data.uploadedFile.copysheet.split(".")[data.uploadedFile.copysheet.split(".").length - 1]).toLowerCase();
        // let pdf_copysheet_url;
        console.log(copsheetFileType);
        if (copsheetFileType == 'jpg' || copsheetFileType == 'jpeg' || copsheetFileType == 'png') {

            await this.convertImageToPdf(data.uploadedFile.copysheet).then((pdfurl) => {
                console.log("pdfurl :: ", pdfurl);
                this.pdf_copysheet_url = pdfurl;
                this.IaGroup_pdf =true;
            }).catch((err) => {
                console.log('Exception in converting image to PDF : ', err)
            })
        } else {
           this.pdf_copysheet_url = data.uploadedFile.copysheet;
        }
        
        let oldCopysheet = (this.Indicator.items[item.svItemId].hasCopysheet == true) ? this.Indicator.items[item.svItemId].copy.copysheet : null;
        // this.Indicator.items[item.svItemId].copy.copysheet = data.uploadedFile.copysheet;
        // this.Indicator.items[item.svItemId].baseCopysheet = data.uploadedFile.copysheet;
        this.Indicator.items[item.svItemId].copy.copysheet = this.pdf_copysheet_url;
        this.Indicator.items[item.svItemId].baseCopysheet = this.pdf_copysheet_url;
        this.Indicator.items[item.svItemId].actionListObj = [];
        // copysheet = data.uploadedFile.copysheet;
        copysheet = this.pdf_copysheet_url;
        // this.Indicator.items[item.svItemId].actionListObj = [];
        copysheet = data.uploadedFile.copysheet;
        this.Indicator.items[item.svItemId].copy['fileType'] = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
        if (!oldCopysheet){
            //this.Indicator.items[item.svItemId].counter > 0 ? this.Indicator.items[item.svItemId].counter = this.Indicator.items[item.svItemId].counter -1 : this.Indicator.items[item.svItemId].counter = this.Indicator.items[item.svItemId].counter;
            this.Indicator.items[item.svItemId].hasCopysheet = true;
            this.updateIAGroupCounter(item.svItemId, this.Indicator);
        }
        if (this.Indicator.items[item.svItemId].copy.fileType.toLowerCase() === 'png' || this.Indicator.items[item.svItemId].copy.fileType.toLowerCase() === 'jpeg' || this.Indicator.items[item.svItemId].copy.fileType.toLowerCase() === 'jpg') {
          if (!this.Indicator.items[item.svItemId].copy.hasOwnProperty('attachments')) {
            this.Indicator.items[item.svItemId].copy['attachments'] = [];
            this.Indicator.items[item.svItemId].copy.attachments.push(this.Indicator.items[item.svItemId].copy.copysheet);
          }
          else {
            this.Indicator.items[item.svItemId].copy['attachments'].push(this.Indicator.items[item.svItemId].copy.copysheet);
          }
        }
        this.Copysheet.updateCopysheet(item.svItemId + "v" + this.Indicator.items[item.svItemId].order.version, this.Indicator.items[item.svItemId].copy)
          .then(response => { 
            var activityLogHistoryObj = {
                'displayName': this.Indicator.user.name,
                'email': this.Indicator.user.email,
                'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                'itemId': item.svItemId,
                'itemStatus': this.Indicator.items[item.order.svItemId].order.status,
                'itemActionCode': this.Indicator.items[item.order.svItemId].order.actionCode + "-" + this.Indicator.items[item.order.svItemId].order.actionType,
                'fieldName': 'Copysheet Value',
                'fieldOldValue': oldCopysheet,
                'fieldNewValue': this.Indicator.items[item.svItemId].copy.copysheet,
                'apiStatus': 'Success'
              }
              this.StorageService.update("itemHistory", item.svItemId, activityLogHistoryObj);
          }
            , err => {
              this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
              var activityLogHistoryObj = {
                'displayName': this.Indicator.user.name,
                'email': this.Indicator.user.email,
                'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
                'itemId': item.svItemId,
                'itemStatus': this.Indicator.items[item.order.svItemId].order.status,
                'itemActionCode': this.Indicator.items[item.order.svItemId].order.actionCode + "-" + this.Indicator.items[item.order.svItemId].order.actionType,
                'fieldName': 'Copysheet Value',
                'fieldOldValue': oldCopysheet,
                'fieldNewValue': this.Indicator.items[item.svItemId].copy.copysheet,
                'apiStatus' : 'Failure'
              }
              this.StorageService.update("itemHistory", item.svItemId, activityLogHistoryObj);
            });

        this.Indicator.items[item.svItemId].hasCopysheet = true;
        this.Indicator.items[item.svItemId].model['digitalApiFlag'] = true;
        this.StorageService.updateField("copyz", storageID, { "data.hasCopysheet": true, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
        .subscribe(()=>{},err=>{})
        // var activityLogHistoryObj = {
        //   'displayName': this.Indicator.user.name,
        //   'email': this.Indicator.user.email,
        //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
        //   'itemId': item.svItemId,
        //   'fieldName': 'Copysheet Value',
        //   'fieldOldValue': oldCopysheet,
        //   'fieldNewValue': this.Indicator.items[item.svItemId].copy.copysheet
        // }
        // this.StorageService.update("itemHistory", item.svItemId, activityLogHistoryObj);
        this.StorageService.updateField("copyz", storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.baseCopysheet": data.uploadedFile.copysheet, "data.actionListObj": [] })
        .subscribe(()=>{},err=>{})
        this.Logger.log('Copysheet uploaded successfully.');        
        this.Indicator.items[item.svItemId].isCopySheetUploaded = false;
        this.indicatorStore.update(state => {
            return {
              indicator: this.Indicator,
              updatedFrom: "[IA-Group Component] ReplaceCopySheet"
            }
          })
        this.indicatorQuery.getIndicator().subscribe(data => {
          this.Indicator = JSON.parse(JSON.stringify(data));
        });
        // this.indicatorStore.update(state =>{
        //   return{
        //     indicator: this.Indicator
        //   }
        // })
        //Reverting the code for IA grp
        //if the item is part of an IA group
        if (this.Indicator.items[item.svItemId].data
          && this.Indicator.items[item.svItemId].data.iaGroupId) {
          //go through the other items on the order
          for (var key in this.Indicator.items) {
            //if the other item has the matching ia group id & isn't the current item
            if (this.Indicator.items[key].data
              && this.Indicator.items[key].data.iaGroupId === this.Indicator.items[item.svItemId].data.iaGroupId
              && this.Indicator.items[key].order.svItemId !== item.svItemId) {
              //add the copysheet to the other item
              if (!this.Indicator.items[key].copy){
                this.Indicator.items[key]['copy'] = {};
              }
              if(this.IaGroup_pdf){
                this.Indicator.items[key].copy['copysheet'] = this.pdf_copysheet_url;
                this.Indicator.items[key]['baseCopysheet'] = this.pdf_copysheet_url;
              }else{
                this.Indicator.items[key].copy['copysheet'] = data.uploadedFile.copysheet;
                this.Indicator.items[key]['baseCopysheet'] = data.uploadedFile.copysheet;
              }
             
              this.Indicator.items[key]['hasCopysheet'] = true;
              //Adding flag for adVersionIncInd of copysheet
              //this.Indicator.items[key]['adVersionIncInd'] = 'Y';
              this.StorageService.updateField("item", key , { "data.adVersionIncInd": "Y" }).subscribe(res => {
                console.log(res)
             });
              // update copysheetIndicator value
              if (this.Indicator.items[key].order.productType === "Print"){
                if (this.Indicator.items[key].model.hasOwnProperty('ManageCopysheet')){
                    this.Indicator.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                    this.Indicator.items[item.svItemId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                }else{
                    this.Indicator.items[key].model['ManageCopysheet'] = {};
                    this.Indicator.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                    this.Indicator.items[item.svItemId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                }
                this.Indicator.items[key].model['digitalApiFlag'] = true;
            }else{
                this.Indicator.items[key].model['copySheetIndicator'] = 'Y';
                this.Indicator.items[item.svItemId].model['copySheetIndicator'] = 'Y';
                this.Indicator.items[key].model['digitalApiFlag'] = true;
            }

              this.Indicator.items[key].copy['fileType'] = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
              if (this.Indicator.items[key].copy.fileType.toLowerCase() === 'png' || this.Indicator.items[key].copy.fileType.toLowerCase() === 'jpeg' || this.Indicator.items[key].copy.fileType.toLowerCase() === 'jpg') {
                if (!this.Indicator.items[key].copy.hasOwnProperty('attachments')) {
                  this.Indicator.items[key].copy.attachments = [];
                  this.Indicator.items[key].copy.attachments = this.Indicator.items[item.svItemId].copy.attachments;
                }
                else {
                  this.Indicator.items[key].copy['attachments'] = this.Indicator.items[item.svItemId].copy.attachments;
                }
              }
              if (this.Indicator.items[key].hasOwnProperty('actionListObj')) {
                this.Indicator.items[key].actionListObj = this.Indicator.items[item.svItemId].actionListObj;
              }
              // Need to check
              this.Indicator.orderPercent = this.indicatorQuery.getValue().indicator['orderPercent'];
              this.Indicator.items[item.svItemId].counter = this.indicatorQuery.getValue().indicator['items'][item.svItemId].counter;
              this.updateIAGroupCounter(key, this.Indicator);
              //update hasCopysheet flag in storage call
              let currentStorageId = key + 'v'+ this.Indicator.items[key].order.version;

              this.StorageService.updateField("copyz", currentStorageId, { "data.hasCopysheet": true}).subscribe(()=>{},err=>{});

              this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                .then( (response) => { })
                .catch(
                  err => {
                    this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                  });
                  this.indicatorQuery.getIndicator().subscribe(data => {
                    this.Indicator = JSON.parse(JSON.stringify(data));
                  });
            }
          }
        }
        // this.indicatorStore.update(state =>{
        //     return{
        //       indicator: this.Indicator
        //     }
        //   })
      }
      }
}
