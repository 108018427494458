import { Component, OnInit } from '@angular/core';

import { IndicatorQuery } from 'app/state/query';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignStore } from 'app/state/campaignstore';

@Component({
  selector: 'app-showemail',
  templateUrl: './showemail.component.html',
  styleUrls: ['./showemail.component.scss']
})
export class ShowemailComponent implements OnInit {

  invalidEmail: boolean = false;
  DisplayEmail: boolean;
  readonly: boolean = false;
  Email: any;
  Indicator: any;
  titleContent: any;
  campaignModel: CampaignClass;
  isChecked: boolean = true;

  constructor(private indicatorQuery: IndicatorQuery,
    private campaignQuery: CampaignQuery,
    private campaignStore: CampaignStore,) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = res;
      for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
        if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
          break;
        }
      }
    })
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data }
    })
    if(this.campaignModel.model.DisplayEmail === undefined) {
      this.campaignModel.model.DisplayEmail = {};
      this.DisplayEmail = true;
      this.campaignModel.model.DisplayEmail.DisplayEmail = this.DisplayEmail ? 'Y' : 'N'
    } else {
      if (this.campaignModel.model.DisplayEmail.DisplayEmail !== undefined) 
        {this.DisplayEmail = (this.campaignModel.model.DisplayEmail.DisplayEmail === 'Y') ? true : false;
        this.Email = this.campaignModel.model.DisplayEmail.Email}
      else {
        this.DisplayEmail = this.campaignModel.model.DisplayEmail
        this.Email = this.campaignModel.model.Email;
      }
    }
    this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title
    for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
      if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
        this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
        break;
      }
    }
  }

  change(email) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    this.invalidEmail = true;
    if (email) {
      if (this.validateEmail(email)) {
        modelData.DisplayEmail.Email = email
        this.invalidEmail = false;
      } else {
        modelData.DisplayEmail.Email = email
        this.invalidEmail = true;
      }
    } else {
      modelData.DisplayEmail.Email = ''
    }
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[ShowemailComponent] change'
      }
    })
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  checkFn() {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    modelData.DisplayEmail.DisplayEmail = this.DisplayEmail ? 'Y' : 'N'
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[ShowemailComponent] validateEmail'
      }
    })
  }

}
