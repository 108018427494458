import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { constant } from 'app/data/constants';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
constructor(){

}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const basicAuth = 'Basic ' + btoa(constant.UserName + ':' + constant.Password);
    let headers = req.headers.set('Authorization', basicAuth);
    let sessonStorageFields = ["currentAccountId","currentOrderId","currentListingId","currentuserid","oauthToken","orderId","federationidentifier","email","username"];
    sessonStorageFields.forEach(function(field){
      if(sessionStorage.getItem(field)){
        headers =  headers.set(field,sessionStorage.getItem(field));
      }
    })
    const modifiedReq = req.clone({
      headers: headers
    });
    return next.handle(modifiedReq);
  }
}