import { Injectable, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { IndicatorService } from '../indicator/indicator.service';
import { ParamsService } from '../params/params.service';
import { LoggerService } from '../logger/logger.service';
import { Quickbpclass } from '../../../interface/quickbp';
import { IndicatorStore } from '../../../state/store';
import { IndicatorQuery } from 'app/state/query';
import { NodeApiEnvUrlService } from '@app/service/nodeApiEnvUrl/node-api-env-url.service';


@Injectable({
  providedIn: "root",
})
export class RouterresolveService implements Resolve<any>, OnInit {
  indicator: any;
  params: any;
  listing;
  sharedData: Quickbpclass = new Quickbpclass();
  routes: string;

  constructor(
    private Indicator: IndicatorService,
    private Params: ParamsService,
    private Logger: LoggerService,
    private indicatorStore: IndicatorStore,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private indicatorQuery: IndicatorQuery,
    private nodeApiEnvUrlService: NodeApiEnvUrlService,
  ) {}
  ngOnInit() {
    this.router.events.subscribe(val => {
      if (this.location.path() != "") {
        let locationPath = "";
        locationPath = sessionStorage.getItem('okta-app-url').split('?')[1] + this.nodeApiEnvUrlService.getNodeUrl("locationurl");
        let targetObj = {};
        let paramsObj = Object.assign(targetObj, ...locationPath.split('&').map((s: string) => s.split('=')).map(([k, v]) => ({ [k]: v })));
        let user = paramsObj.user != undefined? paramsObj.user: paramsObj.User
        sessionStorage.setItem("currentuserid", user);
        sessionStorage.setItem("currentOrderId", paramsObj.order);
        sessionStorage.setItem("currentAccountId", paramsObj.account);
        sessionStorage.setItem("currentListingId", paramsObj.listingId);
        let mode = paramsObj.mode ? paramsObj.mode: "order";
        sessionStorage.setItem("mode", mode);
        sessionStorage.setItem("currentEaId", paramsObj.eaId);
        sessionStorage.setItem("isCopyBP", "false");
        this.params.getParamsData(paramsObj, false);
      }
      this.Params.params.mode = sessionStorage.getItem("mode");
    });
  }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    this.Params.params.spinnerMode = route.routeConfig.path.split('/')[0];
    this.Params.params.listingId = route.paramMap.get("id");
    this.Params.params.mode = sessionStorage.getItem("mode");
    if(route.queryParams.token != undefined && route.queryParams.token != null && route.queryParams.token != "" ){
      type sameProp = "None" | "Lax" | "Strict"
      let secureFlag = true;
      let samesiteProperty = "None";
      if (location.origin.indexOf("localhost") > 0) {
        secureFlag = false;
        samesiteProperty = "Lax";
      }
      let samesitePropertyFinal: sameProp = samesiteProperty as sameProp;
     // this.cookieService.set("token", route.queryParams.token, null, '/', null, secureFlag, samesitePropertyFinal);
    }

    if (this.Params.params.mode === "listing") {
      this.listing = this.Params.params.listingId;
    } else {
      this.listing = "";
    }
    if (
      this.Params["params"]["token"] == undefined ||
      this.Params["params"]["token"] == null ||
      this.Params["params"]["token"] == ""
    ) {
      this.Params.params.token = sessionStorage.getItem("oauthToken");
    }
    if (
      this.Params["params"]["account"] == undefined ||
      this.Params["params"]["account"] == null ||
      this.Params["params"]["account"] == ""
    ) {
      this.Params.params.account = sessionStorage.getItem("account");
    }
    if (
      this.Params["params"]["order"] == undefined ||
      this.Params["params"]["order"] == null ||
      this.Params["params"]["order"] == ""
    ) {
      this.Params.params.order = sessionStorage.getItem("order");
    }
    if (
      this.Params["params"]["id"] == undefined ||
      this.Params["params"]["id"] == null ||
      this.Params["params"]["id"] == ""
    ) {
      this.Params.params.listingId = route.queryParamMap["params"]["id"];
    }
    if (
      this.Params["params"]["mode"] == undefined ||
      this.Params["params"]["mode"] == null ||
      this.Params["params"]["mode"] == ""
    ) {
      this.Params.params.mode =sessionStorage.getItem("mode");
    }

    this.Params.params.isCopyBP =
      route.queryParamMap.get("isCopyBP") == "true" ? "true" : "false";
    this.Params.params.sforg = 'national'
    //this.cookieService.set("sforg", route.queryParamMap["params"]["sforg"], null, '/', null, false, "Lax");
    //get indicator from akita and check if it is already loaded
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.indicator = JSON.parse(JSON.stringify(res));
    });
    console.log(' Data from parent router resolve service :: ', window['parentIndicator'])  
    if ((this.indicator != null && this.indicator.isAlreadyLoaded == true ) || window['parentIndicator']){
      return;
    } else {
      return this.Indicator.processIndicator(this.listing).then(
        (res) => {
          this.indicator = res;
          // console.log("RouterresolveService -> res", res)
        },
        (err) => {
          this.Logger.logError(err);
        }
      );
    }
  }
}
