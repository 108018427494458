import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { SharedService } from '@app/service/shared/shared.service';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkboxinlineca',
  templateUrl: './checkboxinlineca.component.html',
  styleUrls: ['./checkboxinlineca.component.scss']
})
export class CheckboxinlinecaComponent implements OnInit {
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];
  options: any;
  checkboxsList: any = [];
  Indicator;
  isItemPage :boolean = false;
  modelObject;
  schemaObj;
  titleContent: any;
  bpTile3:boolean = false;
  isReadonly :boolean=false;
  modelValue :any =[];

  @ViewChild('accordion',{static:false}) Accordion: MatAccordion
  bpAccordianSub: Subscription;

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private sharedService: SharedService
    ) {

  }
  checkbox: any;
  ngOnInit() {
    this.bpAccordianSub = this.sharedService.getAccordianState().subscribe(state =>{
      if(state){
          this.Accordion.openAll();
      }else{
          this.Accordion.closeAll();
      }
    });
    this.options = this.layoutNode.options || {};
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
      if(this.Indicator['order'] ){
      let items = Object.keys(this.Indicator.items);
      items.forEach((item, index) => {
        if(item == this.Indicator.stateParamsId){
          this.isItemPage =true;
        }
      });
    }
    })
    if((this.Indicator['profiles'] &&
    this.Indicator['profiles'][this.Indicator.stateParamsId] &&
    this.Indicator['profiles'][this.Indicator.stateParamsId] &&
    this.Indicator['profiles'][this.Indicator.stateParamsId] ['schema'] &&
    this.Indicator['profiles'][this.Indicator.stateParamsId] ['schema'][2] &&
    this.Indicator['profiles'][this.Indicator.stateParamsId] ['schema'][2]) &&
    this.Indicator['profiles'][this.Indicator.stateParamsId] ['schema'][2]['title'] == 'Traits Qualities'){
    this.bpTile3 = true;
    }
    else{
    this.bpTile3 = false;

    }
    this.modelObject =this.isItemPage ? this.Indicator['items'] :this.Indicator['profiles'];
    this.schemaObj =this.isItemPage ? this.modelObject[this.Indicator.stateParamsId]['schema']['properties']: this.modelObject[this.Indicator.stateParamsId]['schema'][2]['properties'];

    this.isReadonly =this.schemaObj[this['layoutNode'].name].readonly;
    if (this['layoutNode'].name == "MarketsServed") {
      this.titleContent = this.schemaObj['MarketsServed']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.MarketsServed);
    }
    if (this['layoutNode'].name == "PaymentOptions") {
      this.titleContent = this.schemaObj['PaymentOptions']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.PaymentOptions);
    }
    if (this['layoutNode'].name == "SpokenLanguages") {
      this.titleContent = this.schemaObj['SpokenLanguages']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.SpokenLanguages);
    }
    if (this['layoutNode'].name == "Languages") {
      this.titleContent = this.schemaObj['Languages']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.Languages);
    }
    if (this['layoutNode'].name == "CustomersServed") {
      this.titleContent = this.schemaObj['CustomersServed']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.CustomersServed);
    }
    if (this['layoutNode'].name == "Credentials") {
      this.titleContent = this.schemaObj['Credentials']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.Credentials);
    }
    if (this['layoutNode'].name == "Ownership") {
      this.titleContent = this.schemaObj['Ownership']['title'];
      this.customTitleMap(this.options.titleMap.sort() || this.options.enumNames, this.modelObject[this.Indicator.stateParamsId].model.Ownership);
    }
  }

  customTitleMap(titleMaps: any, data: any) {
    let checkboxexists
      if( this.isItemPage){
        for (let titleMap of titleMaps) {
          this.checkboxsList.push({
            "selected": 'N',
            "_content_": titleMap.label
          })
        }

        if (Array.isArray(data) && data.length) {
          let paymentOption;
          for (let dataIndex of data) {
             for (let index in this.checkboxsList) {
                  if (this.checkboxsList[index]._content_.toLowerCase().replace(/['\s]+/g, '')== dataIndex.toLowerCase() ||(dataIndex == "Financing" && this.checkboxsList[index]._content_.includes("Financing"))){
                    this.checkboxsList[index].selected = 'Y'  ;
                    if(this.checkboxsList[index]._content_.indexOf(' ') >= 0){
                       paymentOption = this.checkboxsList[index]._content_.replace(/['\s]+/g, '');
                       if(paymentOption.includes("Financing")){
                         paymentOption ="Financing";
                       }
                       this.modelValue.push(paymentOption)
                    }else{
                       this.modelValue.push(this.checkboxsList[index]._content_) ;
                    }

                  }
                }

          }
        }

      }else{
        for (let titleMap of titleMaps) {

        if (Array.isArray(data) && data.length) {
          for (let dataIndex of data) {
            if (titleMap.label == dataIndex._content_ || titleMap.label == dataIndex.label) {
              if (this.checkboxsList && this.checkboxsList != null && !(this.checkboxsList === undefined)) {
                checkboxexists = false
                for (let checkboxitem of this.checkboxsList) {
                  if (checkboxitem._content_ == titleMap.label) {
                    checkboxexists = true;
                  }
                }
                if (!checkboxexists) {
                  this.checkboxsList.push({
                    "selected": dataIndex.selected ? dataIndex.selected :'Y',
                    "_content_": titleMap.label,
                    "label": titleMap.label
                  })
                }
              } else {
                this.checkboxsList.push({
                  "selected": dataIndex.selected ? dataIndex.selected :'N',
                  "_content_": titleMap.label,
                  "label": titleMap.label
                })
              }
            }
          }
        } else {
          this.checkboxsList.push({
            "selected": "N",
            "_content_": titleMap.label,
            "label": titleMap.label
          })
        }
      }

    }
  }

  updateValue(event) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    })
    this.modelObject =this.isItemPage ? this.Indicator['items'] :this.Indicator['profiles'];
    for (let i in this.checkboxsList) {
      if (event.target.value.toLowerCase() === this.checkboxsList[i]._content_.toLowerCase() || event.target.value.toLowerCase() === this.checkboxsList[i]._content_.toLowerCase().replace(/['\s]+/g, '') ) {
        if (event.target.checked) {
          this.checkboxsList[i].selected = "Y";
          if(event.target.value.indexOf(' ') >= 0){            
             if(event.target.value.toLowerCase().includes("financing")){
              event.target.value = "Financing";
             }else{
              event.target.value = event.target.value.replace(/['\s]+/g, '');
             }
             
          }
          this.modelValue.push(event.target.value);
        } else {
          this.checkboxsList[i].selected = "N";
          for (let index in this.modelValue) {
            if(this.modelValue[index].toLowerCase() == event.target.value.toLowerCase().replace(/['\s]+/g,  '') ){
              this.modelValue.splice(index, 1);
            }else if(this.modelValue[index].includes("Financing") &&  event.target.value.includes("Financing")){
              this.modelValue.splice(index, 1);
            }

          }
        }
      }else if(event.target.value =="Financing" && (this.checkboxsList[i]._content_.includes("Financing")) ){
        if (event.target.checked) {
          this.checkboxsList[i].selected = "Y";            
          this.modelValue.push(event.target.value);
        }else{
          this.checkboxsList[i].selected = "N";
          for (let index in this.modelValue) {
            if(this.modelValue[index].toLowerCase() == event.target.value.toLowerCase()){
              this.modelValue.splice(index, 1);
            }

          }
        }
        
      }
    }
    if (this['layoutNode'].name == "MarketsServed") {
      this.modelObject[this.Indicator.stateParamsId].model.MarketsServed = this.checkboxsList;
    }
    if (this['layoutNode'].name == "PaymentOptions") {
      this.modelObject[this.Indicator.stateParamsId].model.PaymentOptions = this.isItemPage ?  this.modelValue.toString() :this.checkboxsList;
    }
    if (this['layoutNode'].name == "SpokenLanguages") {
      this.modelObject[this.Indicator.stateParamsId].model.SpokenLanguages =  this.modelValue;
    }
    if (this['layoutNode'].name == "Languages") {
      this.modelObject[this.Indicator.stateParamsId].model.Languages = this.checkboxsList;
    }
    if (this['layoutNode'].name == "CustomersServed") {

      this.modelObject[this.Indicator.stateParamsId].model.CustomersServed = this.checkboxsList;
    }
    if (this['layoutNode'].name == "Credentials") {

      this.modelObject[this.Indicator.stateParamsId].model.Credentials = this.checkboxsList;
    }
    if (this['layoutNode'].name == "Ownership") {
      this.modelObject[this.Indicator.stateParamsId].model.Ownership = this.checkboxsList;
    }

if(this.isItemPage){​​​​​
  this.Indicator.items = this.modelObject;
  let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][ this.Indicator.stateParamsId].model))
  this.indicatorQuery.updateModel(this['layoutNode'].name,this.modelValue,modelData, this.Indicator, '[CheckboxinlinecaComponent] updateValue');

  }​​​​​else{​​​​​
  this.Indicator.profiles = this.modelObject;
  this.indicatorStore.update(state =>{
    return{
      indicator: this.Indicator,
      updatedFrom: "[CheckboxinlinecaComponent] updateValue"
    }
  })
  }​

  }
  ngOnDestroy() {
    this.bpAccordianSub.unsubscribe();
  }
}

