import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { CalltrackinschemaService } from '../calltrackingschema.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { CallinputComponent } from '../callinput/callinput.component';
import { ComponentQuery } from 'app/state/component.query';
import { CalltrackingService } from '@app/service/call/calltracking.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CallPhonenumberComponent } from '../call-phonenumber/call-phonenumber.component';
import { ComponentStore } from 'app/state/component.state';
import { CallselectComponent } from '../callselect/callselect.component';
import { StorageService } from "@app/service/storage/storage.service";
import {CopySheetComponent} from '../../copy-sheet/copy-sheet.component';
import { AdService } from '@app/service/ad/ad.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
//import { CallserviceService } from '../callservice.service';

@Component({
  selector: 'app-callnew',
  templateUrl: './callnew.component.html',
  styleUrls: ['./callnew.component.scss']
})
export class CallnewComponent implements OnInit,OnDestroy {

  form = [];
  schema:any;
  sharedData:any;
  selectedItem: any;
  customWidgets;
  existingCT;
  model: any;
  calltrckingType: any;
  options;
  productCode: any;
  ctSource: any[];
  matchType:[];
  enterpriseItemId;
  productType: any;
  destroySubject$: Subject<void> = new Subject();
  dataValue = "Local Number";
  isSubmitPressed:boolean = false;
  showVaidateButton: boolean = false;
  showReserveButton:boolean = true;
  isdisabled:boolean = false;
  validatetrackingNumber:any;
  noChanges: boolean = false;
  storageID: string;
  addlInstructions: string;
  tempCalltracking: any;
  urlAD: any;
  prefixValCT:any;
  areaCodeCT:any;

  constructor(public dialogRef: MatDialogRef<CallnewComponent>,
    private logger:LoggerService,
    private callservice:CalltrackingService,
    private componentQuery:ComponentQuery ,
    private indicatorQuery: IndicatorQuery,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private indicatorStore: IndicatorStore,
    private calltrackingschema:CalltrackinschemaService,
    private spinnerService: NgxSpinnerService,
    private componnentStore: ComponentStore,
    private adService: AdService,
    private StorageService: StorageService,
    private updateIndicatorService:  UpdateIndicatorService,
    private requiredfieldcount :RequiredfieldcountService
    ) {
      this.initCustomWidgets();
     }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
    });
    this.updateIndicatorService.areaCodeVal.subscribe(areaCode=>{
      this.areaCodeCT = areaCode;
    })
    this.updateIndicatorService.prefixVal.subscribe(prefix=>{
      this.prefixValCT = prefix;
    })
    this.selectedItem = this.sharedData.items[this.sharedData.stateParamsId].order;
    this.productCode = this.selectedItem.productCode;
    this.enterpriseItemId = this.selectedItem.svItemId;
    this.productType = this.selectedItem.productType;
    this.form = this.calltrackingschema.form;
    this.schema = this.calltrackingschema.schema
    this.options = this.calltrackingschema.options;
    this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data =>{
      this.dataValue = data;
      if(this.dataValue === "Market C Number"){
          this.showReserveButton = false;
          this.showVaidateButton= true;
      }else{
        this.showReserveButton = true;
        this.showVaidateButton= false;
        if ((this.productType === 'Digital' && this.selectedItem.statusCode >= 7000) || (this.productType === 'Print' && this.selectedItem.statusCode >= 6000)) {
          this.noChanges = true;
        }

      }
   });
  }
  initCustomWidgets() {
    this.customWidgets =
        {
            phonenumberfield:CallPhonenumberComponent,
            select:CallselectComponent,
            calltrackinginput: CallinputComponent
        }
  }
  provisionCT(distroType,upadatedName){
          return new Promise((resolve, reject) => {
            try {
            let callData:any = {};
            callData.enterpriseAccountId = upadatedName.account.enterpriseAccountId;
            callData.enterpriseItemIds = {};
            callData.enterpriseItemIds.enterpriseItemId = [];
            callData.enterpriseItemIds.enterpriseItemId = this.enterpriseItemId;
            callData.productType = this.productType;
            callData.provisionType = "NEW";
            callData.areaCode =  this.areaCodeCT,//upadatedName.account.phone.substring(1, 4),//this.model.areacode;
            callData.prefix = this.prefixValCT,//upadatedName.account.phone.substring(6, 9),//this.model.prefix;
            callData.number = upadatedName.account.phone.substring(10, 14),
            callData.callRecordEnabled = "Y",//this.model.callrecord;
            callData.adAlertEnabled = "Y",// this.model.callwhisper;
            callData.callVerifyEnabled = "Y",// this.model.callintercept;
            callData.terminationNumber = upadatedName.account.phone.replace(/\D/g, "");
            callData.actionType = this.selectedItem.actionType;
            callData.OptIntoCallTracking =true;
            callData.matchType= "LP";
            if (this.dataValue === 'Local Number') {
                callData.matchType = "LP";
          } else if (this.dataValue === 'Toll-Free Number') {
                callData.matchType = "TF";
          } else if (this.dataValue === 'Market C Number') {
                callData.matchType = "EM";
          }
          /*if(callData.callVerifyEnabled === true){
              callData.callVerifyEnabled = 'Y';
          }else{
              callData.callVerifyEnabled = 'N';
          }*/
          // if (callData.callRecordEnabled === true ){
          //     callData.callRecordEnabled='Y';
          // } else {
          //     callData.callRecordEnabled='N';
          // }

          /*if (callData.adAlertEnabled === true ){
              callData.adAlertEnabled='Y';
          } else {
              callData.adAlertEnabled='N';
          }*/

          //SEM gets three distributionTypes, other digital items only get SP
          if (this.productCode === 'YPC' || this.productCode === 'Single IYP' || this.productCode === 'Premium IYP'){
              callData.distributionType = "SP";
          } else {
              callData.distributionType = distroType;
          }
          callData.issueNumber = this.selectedItem.directoryIssueNumber;
          this.callservice.create(callData, upadatedName.items[upadatedName.stateParamsId]).then(data => {
              var newCT = {
                  "adAlertEnabled": callData.adAlertEnabled,
                  "callRecordEnabled": callData.callRecordEnabled,
                  "callVerifyEnabled": callData.callVerifyEnabled,
                  "callTrackingId": data["callTrackingId"],
                  "enterpriseItemId": this.enterpriseItemId,
                  "terminationNumber": callData.terminationNumber,
                  "trackingNumber": data["trackingNumber"],
                  "distributionType": callData.distributionType,
                  "OptIntoCallTracking":callData.OptIntoCallTracking
              };
              if (this.productCode === 'SEM' || this.productCode === 'SuperClick' || this.productCode === 'SEMP' || this.productCode === 'SEM Kicker'){
                  newCT.distributionType = distroType
              }if(upadatedName.items[upadatedName.stateParamsId].calltracking){
                upadatedName.items[upadatedName.stateParamsId].calltracking.push(newCT);
              }else{
                upadatedName.items[upadatedName.stateParamsId].calltracking.push(newCT);
              }
              this.indicatorStore.update((state) => {
                return {
                  indicator:upadatedName,
                  updatedFrom: "[CallnewComponent] provisionCT",
                  isloaded: true,
                };
              });
              return resolve(data);
          },error=> {
              this.dialogRef.close();
              this.spinnerService.hide();
              if (error && error.error.response && error.error.response.esbServiceFault && error.error.response.esbServiceFault.exceptionDetail[0].errorDescription === "You are unable to proceed due to a validation error with Inventory could not be found due to search criteria."){
                  var showmsg = "A CTN local to the area code and prefix requested is not currently available. Please provision a Toll Free number, or contact the Solution Center.";
                  this.logger.logError(showmsg);
              } else if(error && error.error.response && error.error.response.esbServiceFault){
                  var sourceSystem = error.error.response.esbServiceFault.sourceSystem;
                  var errorDescription = error.error.response.esbServiceFault.exceptionDetail[0].errorDescription;
                  this.logger.logError(sourceSystem + ": " + errorDescription);
              }else{
                  //{"message":"connect ETIMEDOUT"}
                  this.logger.logError(error.message);
              }
              return reject(error);
          });
        }catch(e){
            return reject(e);
        }
        })
}
OnSubmitCalltracking(data){
    this.spinnerService.show();
    this.indicatorQuery.getIndicator().subscribe((res) => {
        this.sharedData = { ...res };
      });
      let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
      let title = this.data.dialogform;
      let flag  = true;
      if(title == 'New Call Trackinggrid') {
        this.data.dialogform = 'New Call Tracking';
        flag =false;
        this.copysheetnotify();
      }

      if (flag && upadatedName.items[upadatedName.stateParamsId].order.actionType === 'New' && (upadatedName.items[upadatedName.stateParamsId].order.productCode == 'YPC' ||  upadatedName.items[upadatedName.stateParamsId].order.productType == "Print")) {
        this.copysheetnotify();
        CopySheetComponent.reTrigger();
        upadatedName = JSON.parse(JSON.stringify(this.indicatorStore.getValue().indicator));
      }
    if (this.productCode === 'SuperClick' || this.productCode === 'SEMP'){
      this.ctSource = [
          this.provisionCT("SP",upadatedName),
          this.provisionCT("GOOGLE",upadatedName),
          this.provisionCT("BING",upadatedName)
      ];
  } else if (this.productCode === 'SEM Kicker'){
      this.ctSource = [
          this.provisionCT("SP",upadatedName),
          this.provisionCT("GOOGLE",upadatedName),
          this.provisionCT("BING",upadatedName),
          this.provisionCT("YELP",upadatedName)
      ];
  } else if(this.productCode === 'SEM'){
    this.ctSource = [
      this.provisionCT("SP",upadatedName),
      // this.provisionCT("BING",upadatedName),
      this.provisionCT("YELP",upadatedName)
    ];
  }
  if (this.dataValue === 'Market C Number'){
    // vm.saving = true;
    this.validate(upadatedName).then(function(){

    });
} else if (this.productCode === 'SEM' || this.productCode === 'SuperClick' || this.productCode === 'SEMP' || this.productCode === 'SEM Kicker'){

    Promise.all(this.ctSource)
    .then(result =>{
        this.isSubmitPressed = true;
        //data.isSubmitPressed = this.isSubmitPressed
        this.ctMappingCalltracking(upadatedName.items[upadatedName.stateParamsId].calltracking, this.selectedItem, this.sharedData.items[this.sharedData.stateParamsId].model);
        this.spinnerService.hide();
        this.dialogRef.close(result);
    }).catch( err =>{
        this.spinnerService.hide();
        //console.log( ' error in promise all', err);
    })
} else {
    //vm.saving = true;
    //provision 1 number
    this.provisionCT('',upadatedName).then(async data=>{
        this.isSubmitPressed = true;
        data['isSubmitPressed'] = this.isSubmitPressed;
        //update the model with the newly provisioned numbers

        await this.ctMappingCalltracking(upadatedName.items[upadatedName.stateParamsId].calltracking, this.selectedItem, upadatedName.items[upadatedName.stateParamsId].model);
        this.spinnerService.hide();
        this.dialogRef.close(data);
    }).catch(err =>{
        this.spinnerService.hide();
        //console.log( "error ", err);
    })
}

}
validate(upadatedName){
return new Promise((resolve, reject) => {
    let validateData:any ={};
    validateData.enterpriseAccountId = upadatedName.account.enterpriseAccountId;
    validateData.enterpriseItemIds = {};
    validateData.enterpriseItemIds.enterpriseItemId = [this.enterpriseItemId];
    validateData.productType = this.productType;
    validateData.terminationNumber = upadatedName.account.phone.replace(/\D/g, "");
    validateData.trackingNumber = this.validatetrackingNumber;
    this.callservice.validate(validateData).subscribe(valData => {
      //have to make api call to get validated number's full info (call whisper and call record)
      this.callservice.getByItemId(this.enterpriseItemId).then(ctData =>{
          var i = 0;
          var length = ctData.trackingLines.trackingLine.length;

          for (i; i<length; i++){
              if (ctData.data.trackingLines.trackingLine[i].callTrackingId === valData.data.callTrackingId){
                  var validatedCT = {
                      "adAlertEnabled": ctData.data.trackingLines.trackingLine[i].adAlertEnabled,
                      "callRecordEnabled": ctData.data.trackingLines.trackingLine[i].callRecordEnabled,
                      "callVerifyEnabled": ctData.data.trackingLines.trackingLine[i].callVerifyEnabled,
                      "callTrackingId": ctData.data.trackingLines.trackingLine[i].callTrackingId,
                      "enterpriseItemId": this.enterpriseItemId,
                      "terminationNumber": ctData.data.trackingLines.trackingLine[i].terminationNumber,
                      "trackingNumber": ctData.data.trackingLines.trackingLine[i].trackingNumber
                  };

                  //add newly validated CT to indicator.item.calltracking array
                  this.sharedData.items[this.sharedData.stateParamsId].calltracking.calltracking.push(validatedCT);
              }
          }
      });

      //update the model with the newly validated and associated number
      this.ctMappingCalltracking(this.sharedData.items[this.sharedData.stateParamsId].calltracking, this.selectedItem, this.sharedData.items[this.sharedData.stateParamsId].model);

      this.dialogRef.close(valData);
      return resolve(valData);
      //deferred.resolve(valData);
  },(error:any)=>{
      this.spinnerService.hide();
      this.dialogRef.close();
      var trackingErrorMsg = "The Telephone Number does not match a number associated with the customer. Please re-enter or contact the Solution Center to confirm the number.";
      var destinationErrorMsg = "The Destination Number does not match a number associated with the customer. Please re-enter or contact the Solution Center to confirm the number.";
      if(error
          && error.error.response.esbServiceFault
          && error.error.response.esbServiceFault.exceptionDetail[0].errorDescription === "Invalid trackingNumber"){
          this.logger.logError(trackingErrorMsg)
      } else if(error
          && error.error.response.esbServiceFault
          && error.error.response.esbServiceFault.exceptionDetail[0].errorDescription === "Invalid terminationNumber"){
          this.logger.logError(destinationErrorMsg)
      } else if (error && error.error.response.esbServiceFault){
          var sourceSystem = error.error.response.esbServiceFault.sourceSystem;
          var errorDescription = error.error.response.esbServiceFault.exceptionDetail[0].errorDescription;
          this.logger.logError(sourceSystem + ": " + errorDescription);
      } else {
        this.logger.logError("Call Tracking Service Unavailable. Please Contact Tibco Team.");
      }
      return reject(error);
      //vm.saving = false;

  })
})

}
onUpdateDataFields(data){
    if(data.length != 3 || this.areaCodeCT.length == 0 || this.prefixValCT.length == 0){
        this.isdisabled = true;
    }else{
        this.isdisabled = false;
    }
}
onUpdatetrackingNumber(data){
    this.validatetrackingNumber = data.replace(/\D/g, "");
}
private async ctMappingCalltracking(uniqueCTs, orderItem, model){
    this.indicatorQuery.getIndicator().subscribe(data => {
        this.sharedData = {...data};
      });
      let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
      let storageID = orderItem.svItemId + "v" + orderItem.version;
      let stateParamsId = upadatedName.stateParamsId?upadatedName.stateParamsId:null;
      //check to see if NO(from CT dropdown) was selected
      let data = await this.StorageService.getField("call", storageID)
        //if (data[storageID] !== undefined && data[storageID].optout === true) {
        if (data[storageID] !== undefined && data[storageID].selectedOption === "No") {
          if(stateParamsId){
            upadatedName.items[upadatedName.stateParamsId].model.CallTracking = { "OptIntoCallTracking": false };
          }

        } else {
          //if not opt-out, then map any CTs on the item
          var length = uniqueCTs.length;
          var tibcoCTarray = [];
          var tibcoCTstring = "";
          var destinationNums = [];
          var callTrackingEnabled = false;

          for (var i = 0; i < length; i++) {
            var terminationNumber = uniqueCTs[i].terminationNumber;
            var trackingNumber = uniqueCTs[i].trackingNumber;
            var callTrackingId = uniqueCTs[i].callTrackingId;
            //var callRecordEnabled = uniqueCTs[i].callRecordEnabled;
            //var adAlertEnabled = uniqueCTs[i].adAlertEnabled;
            //sonar fixing
            var OptIntoCallTracking = uniqueCTs[i].OptIntoCallTracking == undefined ? true : uniqueCTs[i].OptIntoCallTracking;

            destinationNums.push(terminationNumber);

            //if true 'Call Record' check-box appears on publish page
            if (uniqueCTs[i].callRecordEnabled === 'Y' || uniqueCTs[i].callRecordEnabled === 'true') {
              callTrackingEnabled = true;
            }

            if (uniqueCTs[i].distributionType !== null) {
              var source = uniqueCTs[i].distributionType;
            } else {
              source = null
            }

            //CT info for Tibco update/create requests, format depends on item type
            if (orderItem.productCode === 'Premium IYP' || orderItem.productCode === 'Single IYP' || orderItem.productCode === 'YPC') {
              var cTbody :any = {
                "TrackingId": callTrackingId,
                "Source": source,
                "OptIntoCallTracking": OptIntoCallTracking
              };
            } else if (orderItem.productCode === 'SEM' || orderItem.productCode === 'SuperClick' || orderItem.productCode === 'SEMP' || orderItem.productCode === 'SEM Kicker') {
              let bodySEM = {
                "TrackingId": callTrackingId,
                "Source": source,
                "DestinationNumber": terminationNumber
              };
              tibcoCTarray.push(bodySEM);
            } else if (orderItem.productType === "Print") {
              if (tibcoCTstring === "") {
                tibcoCTstring = trackingNumber;
              } else (
                tibcoCTstring += "," + terminationNumber
              );
               cTbody = tibcoCTstring;
            }
          }

          //create list of destination numbers to make sure none are reused
          this.StorageService.updateField("call", storageID, { "data.destinations": [...new Set(destinationNums)]})
          .subscribe(()=>{},error=>{
            console.log("error is:",error);
          });
          //this is what gets sent to tibco product update/create about CT
          if (orderItem.productType === "Print" && orderItem.actionCode === "Renew" && tibcoCTstring === "") {
            if(stateParamsId){
              upadatedName.items[upadatedName.stateParamsId].model.CallTracking = tibcoCTarray;
              upadatedName.items[upadatedName.stateParamsId].model.CallTracking.CallTrackingCount = tibcoCTarray.length;
            }
            } else if (orderItem.productType === "Digital"
            && orderItem.actionCode === "Renew"
            && (orderItem.productCode === 'YPC' || orderItem.productCode === 'Single IYP')
            && length === 0
            ) {
            if(stateParamsId){
              upadatedName.items[upadatedName.stateParamsId].model.CallTracking = tibcoCTarray;
              upadatedName.items[upadatedName.stateParamsId].model.CallTracking.CallTrackingCount = tibcoCTarray.length;
            }
          } else {
            if (cTbody) {
              if(stateParamsId){
                upadatedName.items[upadatedName.stateParamsId].model.CallTracking = cTbody;
              }
            } else {
              if(stateParamsId){
                upadatedName.items[upadatedName.stateParamsId].model.CallTracking = tibcoCTarray;
                upadatedName.items[upadatedName.stateParamsId].model.CallTracking.CallTrackingCount = tibcoCTarray.length;
              }
            }
          }
          //if true 'Call Record' check-box appears on publish page
          orderItem.hasCallTracking = false;
          if (tibcoCTarray.length !== 0 || cTbody) {
            if (callTrackingEnabled) {
              orderItem.hasCallTracking = true;
              upadatedName.items[upadatedName.stateParamsId].order.hasCallTracking = true;
            }
          }
          if(upadatedName && upadatedName.stateParamsId){
          let temIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            upadatedName['orderPercent'] = temIndicator['orderPercent'];
            upadatedName.items[upadatedName.stateParamsId].model.modelValue = temIndicator.items[temIndicator.stateParamsId].model.modelValue;
            upadatedName.items[upadatedName.stateParamsId].counter = temIndicator.items[temIndicator.stateParamsId].counter;
            let  currentCount = upadatedName.items[upadatedName.stateParamsId].counter;
            let tempData = this.requiredfieldcount.getNewItemCount(upadatedName.items[upadatedName.stateParamsId].requiredFields, upadatedName.items[upadatedName.stateParamsId].model,upadatedName.items[upadatedName.stateParamsId].order, upadatedName.orderPercent, currentCount, true, upadatedName.items[upadatedName.stateParamsId]);
            this.updateIndicatorService.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData, currentCount);
            temIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            upadatedName['orderPercent'] = temIndicator['orderPercent'];
            upadatedName.items[upadatedName.stateParamsId].counter = temIndicator.items[temIndicator.stateParamsId].counter;
          }
          this.indicatorStore.update((state) => {
            return {
              indicator:upadatedName,
              updatedFrom: "[CallnewComponent] ctMappingCalltracking",
              isloaded: true,
            };
          });
        }
}
cancel(){
    if(this.dataValue === "Market C Number" || this.dataValue === 'Toll-Free Number'){
        this.componnentStore.update(()=>{
            return {
              componentValue:"Local Number"
            }
        })
    }
    this.dialogRef.close();
}
OnEnable(flag){
  this.isdisabled = flag;
}
ngOnDestroy() {
  this.destroySubject$.next();
}

copysheetnotify(){
  let indicator ;
  this.indicatorQuery.getIndicator().subscribe(data => {
    indicator = {...data};
  });
  let upadatedName = JSON.parse(JSON.stringify(indicator));
  upadatedName.items[indicator.stateParamsId].hasCopysheet = false;
    if(upadatedName.items[indicator.stateParamsId].order.productCode == 'YPC' ||  indicator.items[indicator.stateParamsId].order.productType == "Print"){
        if(upadatedName.items[indicator.stateParamsId].order.productCode == 'YPC'){
          upadatedName.items[indicator.stateParamsId].model.ManageCopysheet = 'Y';
        }else{
          upadatedName.items[indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
        }
        upadatedName.items[indicator.stateParamsId].requiresCopysheet =  true;
      }

  this.indicatorStore.update((state) => {
    return {
      indicator:upadatedName,
      updatedFrom: "[CallnewComponent] copysheetnotify",
      isloaded: true,
    };
  });

}

private getIndicator() {
  let Indicator;
  this.indicatorQuery.getIndicator().subscribe(data => {
    Indicator = JSON.parse(JSON.stringify(data));
    this.storageID = Indicator.stateParamsId + "v" + Indicator.items[Indicator.stateParamsId].order.version;
    this.addlInstructions = Indicator.items[Indicator.stateParamsId].copy.instructions;
    this.tempCalltracking = Indicator.items[Indicator.stateParamsId].hasCopysheet;
    try {
      this.adService.getAdUrl(Indicator.stateParamsId).subscribe(() => {
      }, err => {
        //console.log(err);
        this.urlAD = err['url'];
        //console.log(" add url : ", this.urlAD);
      })
    } catch (e) {
      //console.log(" error in getting the adservice url : ", e);
    }
  })
}

}


