import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Quickbp } from '../interface/quickbp';
import { CampaignClass } from 'app/interface/campaign';

export interface campaignState{
    campaign: CampaignClass;
}

export const initialState = () =>{
return {
    updatedFrom: null,
    campaign: null
};
};

@Injectable({
    providedIn : 'root'
})
@StoreConfig({ name: 'campaign' })
export class CampaignStore extends Store<campaignState> {
  constructor() {
    super(initialState());
  }
}