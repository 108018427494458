import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { BillingnewComponent } from '../billing-new/billingnew/billingnew.component';

@Component({
  selector: 'app-billingselect',
  templateUrl: './billingselect.component.html',
  styleUrls: ['./billingselect.component.scss']
})
export class BillingselectComponent implements OnInit {
  options;
  customField;
  id; 
 // billingAccountType = ["Monthly Advance Pay", "Invoice"];
 billingAccountType = [];
  sharedData;
  selectedValue;
  titleContent: string;
  disablePaymentType: boolean;
  
  constructor(private dialogRef: MatDialogRef<BillingnewComponent>,
    private indicatorQuery: IndicatorQuery) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.titleContent = "Billing Account Type";

    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      if (this.sharedData.account.address.country === 'NZ') {
        this.selectedValue = "Invoice"; // Default to Invoice
        this.billingAccountType = ["Invoice"];
        this.disablePaymentType = true; // Disable payment type selection
      } else {
        if (this.sharedData && this.sharedData.stateParamsId && this.sharedData.stateParamItemId) {
          let productType = this.sharedData.items[this.sharedData.stateParamsId].order.productType;
          if (productType && productType == 'Digital') {
            this.selectedValue = "Monthly Advance Pay";
            this.billingAccountType = ["Monthly Advance Pay"];
          } else if (productType && productType == 'Print') {
            this.selectedValue = "Invoice";
            this.billingAccountType = ["Invoice"];
          }
        } else {
          this.selectedValue = "Monthly Advance Pay";
          this.billingAccountType = ["Monthly Advance Pay", "Invoice"];
        }
      }
    });
  }
  
  sendValue(args) {
    this.selectedValue = args;
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.onUpdateAccountType(args);
    }
  }
}
