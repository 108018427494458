import { Injectable } from '@angular/core';
import { CounterService } from '../counter/counter.service';
import { UtilsService } from "../utils/utils.service";

@Injectable({
  providedIn: "root",
})
export class RequiredfieldcountService {
  itemCount;
  itemSchema;
  itemOrder;
  itemForm;
  itemModel;
  requiredFields;
  orderPercentObj;
  totalCount;
  maxCount;
  bpCount;
  bpCountMax;
  itemCountMax;
  orderStatusCancel = ['cancel','completed - cancelled','cancelled'];
  constructor(private utils: UtilsService, private counterService: CounterService) {}

  get(schema: any, form: any, model: any): any {
    var count = 0;
    //var schema = schema.properties;
    if (schema && schema.properties) {
      var schema = schema.properties;
  } else {
      console.error('Schema or its properties are undefined.');
      // Handle the situation where properties are not accessible
  }
  
    var model = JSON.parse(JSON.stringify(model));

    if (!this.utils.isEmpty(model)) {
      for (var i = 0; i < form.length; i++) {
        let key = form[i].key;
        if(this.utils.isEmpty(model[key]) || key === "BusinessPhone" || key === "BPBDEmail" || key === "BusinessDescription" || key === "BusinessAddressZip"){
          if (form[i].type != null && form[i].type === "zipcodeValidation") {
            if (
              model[form[i].key] &&
              model[form[i].key].hasOwnProperty("zip") &&
              model[form[i].key].hasOwnProperty("zip4")
            ) {
              var zip;
              if (
                model[form[i].key].zip4 !== undefined &&
                model[form[i].key].zip4 !== "" &&
                model[form[i].key].zip4 !== null &&
                !model[form[i].key].zip4.includes("-")
              ) {
                if (model[form[i].key].zip4.trim() != "")
                  zip =
                    model[form[i].key].zip + "-" + model[form[i].key].zip4.trim();
                else zip = model[form[i].key].zip;
              } else {
                zip = model[form[i].key].zip + model[form[i].key].zip4;
              }

              var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
              {
                if (
                  model[form[i].key].zip4 != "null" &&
                  model[form[i].key].zip4 != null &&
                  model[form[i].key].zip4 != undefined &&
                  !isValidZip
                ) {
                  count++;
                }
              }
            }
          }
          if (key === "BusinessPhone") {
            if (model[key] != undefined && model[key] != null) {
              model[key] = model[key].split("(").join("");
              model[key] = model[key].split(")").join("");
              model[key] = model[key].split("-").join("");
              model[key] = model[key].split(" ").join("");
            }
            if (model[key] === undefined || model[key] == null) {
              count++;
            } else {
              if (model[key] == "" || model[key].length < 10) {
                count++;
              }
            }
          }

          if (
            key === "BPBDEmail" &&
            (model[key] === undefined || !this.utils.isValidEmail(model[key]))
          ) {
            count++;
          }

          if (key === "BusinessAddressDisplayOptions" && model[key] === "") {
            count++;
          }
          if (key === "BusinessCategories" && this.utils.isEmpty(model[key])) {
            count++;
            count++;
          }
          if (
            key == "ConfirmHoursOfOperation" &&
            model[key] != true &&
            model[key] != "true"
          ) {
            count++;
          }

          if (this.utils.isString(model[key])) {
            if (
              schema.hasOwnProperty(key) &&
              schema[key].required === true &&
              model[key].toString().length === 0 &&
              key !== "BPBDEmail" &&
              key !== "BusinessDescription" &&
              key !== "BusinessPhone" &&
              key !== "ConfirmHoursOfOperation"
            ) {
              count++;
            }
            if (
              (key === "BusinessDescription" &&
                model[key].length < schema[key].minLength) ||
              (key === "BusinessDescription" &&
                model[key].length > schema[key].maxLength)
            ) {
              count++;
            }
            if (
              key === "BusinessAddress1" &&
              model[key].length > schema[key].maxLength
            ) {
              count++;
            }
            if (
              key === "BusinessAddressCity" &&
              model[key].length > schema[key].maxLength
            ) {
              count++;
            }
          } else if (key === "BusinessDescription" && model[key] === undefined) {
            count++;
          }
        }
      }
    }
    return count;
  }


  /**
   * Gets item count - Service to give the number of required fields in items
   * @param schema
   * @param form
   * @param model
   * @param order
   */
  getItemCount(schema: any, form: any, model: any, order: any) {
    this.itemSchema = schema.properties;
    this.itemForm = form;
    this.itemModel = model;
    this.itemOrder = order;
    this.itemCount = 0;

    if (!this.utils.isEmpty(model)) {
      for (let i = 0; i < this.itemForm.length; i++) {
        let key = this.itemForm[i].key;
        if (
          this.itemForm[i].key != null &&
          this.itemSchema[this.itemForm[i].key] &&
          this.itemSchema[this.itemForm[i].key].required
        ) {
          if(typeof model[key] === 'string'){
            this.utils.isEmpty(model[key]) ? this.itemCount++ : this.itemCount;
          } else if(typeof model[key] === 'object'){
            this.utils.checkObjectEmpty(model[key]) ? this.itemCount++ : this.itemCount
          }
        }
      }
    }




    return this.itemCount;
  }

  /**
   * Gets new item count - Service to give the number of required fields in items
   * Similar to above method but with modifications. Work in progress in this one
   * @param requiredFields
   * @param model
   * @param order
   */
  getNewItemCount(requiredFields: any, model: any, order: any, orderPercent:any, previousCount: number, isLoad: boolean, item?:any){
    // Dont show red indicator when BillingBasisBRM is required field in SF and remove object from requiredField array.
    this.requiredFields = requiredFields;
    this.itemModel = model;
    this.itemOrder = order;
    this.orderPercentObj = orderPercent;
    this.itemCount = 0;
    if (!this.utils.isEmpty(model)) {
      for (let i = 0; i < this.requiredFields.length; i++) {
        if (!(this.requiredFields[i] in this.itemModel)) {
          if(this.requiredFields[i] === 'AppDateTime'){
            if(order && order.UDAC === "SEMP" && order.actionCode != "Renew"){
              this.itemCount++;
            }
          }else{
            this.itemCount++;
          }
        }
        Object.keys(this.itemModel).forEach(key => {
          if(this.requiredFields[i] === key){
            if(typeof model[key] === 'string'){
              if(this.utils.isEmpty(model[key])) {
                if (key && key.toLowerCase() === 'calltracking') {
                  if(model['modelValue'] && model['modelValue'] == 'Yes' && item.calltracking.length == 0) {
                    this.itemCount++;
                  }
                } else if (key && key.toLowerCase() === 'managecopysheet') {
                  if(item && item.requiresCopysheet && !item.hasCopysheet) {
                    if(order.actionCode == "Renew" && order.actionType == "New"){
                      if(model.hasOwnProperty("renewalCopysheetRequiredFlag") && model['renewalCopysheetRequiredFlag']){
                        this.itemCount++;
                      }
                    }else{
                      this.itemCount++;
                    }
                  }
                } else {
                  if(key === 'AppDateTime'){
                    if(order && order.UDAC === "SEMP" && order.actionCode != "Renew"){
                      this.itemCount++;
                    }
                  }else{
                    this.itemCount++;
                  }
                }
              } else {
                if (key && key.toLowerCase() === 'managecopysheet') {
                  if(item && item.requiresCopysheet && !item.hasCopysheet) {
                    if(order.actionCode == "Renew" && order.actionType == "New"){
                      if(model.hasOwnProperty("renewalCopysheetRequiredFlag") && model['renewalCopysheetRequiredFlag']){
                        this.itemCount++;
                      }
                    }else{
                      this.itemCount++;
                    }
                  }
                } else if(key && key.toLowerCase() === 'calltracking') {
                  if(model['modelValue'] && model['modelValue'] == 'Yes' && model[key] === '0') {
                    this.itemCount++;
                  }
                }
                else if (key === "URL") {
                  if (model[key] && model[key] != "Client will select domain during consultation") {
                    let strArray = model[key].split("-");
                    if (!this.utils.validateURLDEXHub(strArray[1])) {
                      this.itemCount++;
                    }
                  }
                }
              }
            } else if (typeof model[key] === 'object') {
                if (this.utils.checkObjectEmpty(model[key])) {
                  if (key.toLowerCase() === 'calltracking') {
                    if(item){
                    if (model['modelValue'] && model['modelValue'] == 'Yes' && (this.utils.checkObjectLength(model[key]) && item.calltracking.length == 0 )) {
                      this.itemCount++;
                    } else if(model['modelValue'] && model['modelValue'] == 'Yes' && model[key] && model[key].hasOwnProperty('OptIntoCallTracking')) {
                      if(!model[key]['OptIntoCallTracking'] && item.calltracking.length == 0) {
                        this.itemCount++;
                      }
                    }
                  }
                  } else if(key.toLowerCase() === 'managecopysheet') {
                    if(item && item.requiresCopysheet && !item.hasCopysheet) {
                      if(order.actionCode == "Renew" && order.actionType == "New"){
                        if(model.hasOwnProperty("renewalCopysheetRequiredFlag") && model['renewalCopysheetRequiredFlag']){
                          this.itemCount++;
                        }
                      }else{
                        this.itemCount++;
                      }
                    }
                  } else {
                    if(key === 'AppDateTime'){
                      if(order && order.UDAC === "SEMP" && order.actionCode != "Renew"){
                        this.itemCount++;
                      }
                    }else{
                      this.itemCount++;
                    }
                }
                } else if (key && key.toLowerCase() === 'managecopysheet') {
                  if (item && item.requiresCopysheet && !item.hasCopysheet) {
                    if(order.actionCode == "Renew" && order.actionType == "New"){
                      if(model.hasOwnProperty("renewalCopysheetRequiredFlag") && model['renewalCopysheetRequiredFlag']){
                        this.itemCount++;
                      }
                    }else{
                      this.itemCount++;
                    }
                  }
                } else if(key && key.toLowerCase() === 'calltracking') {
                  if(model['modelValue'] && model['modelValue'] == 'Yes' && model[key] && model[key].hasOwnProperty('OptIntoCallTracking') && !model[key]['OptIntoCallTracking'] && item.calltracking.length == 0) {
                    this.itemCount++;
                  }
                }else if(key.toLowerCase() === 'websiteurl') {
                  if(model[key] && this.utils.isEmpty(model[key]['url'])) {
                  this.itemCount++;
                  }
                } else if (key.toLowerCase() === 'campaigntype') {
                  if(model[key] && !this.utils.checkObjectLength(model[key])) {
                    for(let i = 0; i < model[key].length;i++) {
                      if(!model[key][i].isComplete) {
                        this.itemCount++;
                        break;
                      }
                    }
                  }
                }
                else if(key.toLowerCase() === 'extraline'){
                  if (this.utils.checkObjectLength(model[key])) {
                    this.itemCount++;
                  }else{
                    for(let i= 0;i < model[key].length;i++){
                      if(!model[key][i]["lineType"]){
                        this.itemCount++;
                      }
                      if(!model[key][i]["text"]){
                        this.itemCount++;
                      }
                    }
                  }
                }
                else if( isLoad && key.toLowerCase() === 'businesscategories') {​​​​​​
                  if(model[key] && this.utils.checkObjectLength(model[key]['CategoryList'])) {​​​​​​
                    this.itemCount++;
                  }​​​​​​
                }
                else if(key.toLowerCase() === 'usebusinessprofile') {
                  if(model[key]['UseBusinessProfile'] === 'N' && this.utils.isEmpty(model[key]['WebsiteAddress'])){
                    this.itemCount++;
                  }
                }

            }
          }
        });
      }
    }
    let tempCurrentCount = 0;
    let tempMaxCount = 0;
    if(isLoad) {
      tempCurrentCount= this.orderPercentObj ? this.orderPercentObj.currentCount : 0;
      if(order.productCode ==="SEO" || order.productCode === "SEMP"){
        if(previousCount < this.itemCount ) {
          tempCurrentCount = this.orderPercentObj.currentCount -(this.itemCount -previousCount);
        } else if(previousCount > this.itemCount) {
          tempCurrentCount = this.orderPercentObj.currentCount +(previousCount - this.itemCount);
        }
      }else{
        if(previousCount < this.itemCount ) {
          tempCurrentCount = this.orderPercentObj.currentCount -1;
        } else if(previousCount > this.itemCount) {
          tempCurrentCount = this.orderPercentObj.currentCount +1;
        }
      }
      tempMaxCount = this.orderPercentObj.maxCount;
      if(tempMaxCount < tempCurrentCount) {
        tempCurrentCount = tempMaxCount;
      }
      //console.log(order +":::::::::itemCount"+this.itemCount+":::::::tempCurrentCount"+tempCurrentCount+":::::::previousCount",previousCount);
      this.getPercent(tempCurrentCount, this.orderPercentObj.maxCount);
    }else{
      if (this.itemOrder.productType == "Digital" && this.itemOrder.requireContent &&  this.itemOrder.actionType == 'Update'){
        if(this.itemOrder.statusCode < 6000) {
          if(this.itemModel.digitalApiFlag && this.itemOrder.actionCode == 'Add'){
            // do nothing -- If item is visited for digital rehandle order
          }else {
            this.itemCount++;
          }
        }
      }
    }

    return {
      itemCount: this.itemCount,
      tempCurrentCount: tempCurrentCount,
      tempMaxCount: tempMaxCount
    };
  }

  calculatePercent(items: any, profiles: any){
      const { itemcount, itemcountmax, digitalCount, printCount} = this.getItemsCount(items);
      const { bpcount, bpcountMax, bpTotalCount} = this.getBPCount(profiles);
      // console.log("itemcount =" , +itemcount)
      // console.log("itemcountmax =", +itemcountmax);
      // console.log("bpcount =" , +bpcount);
      // console.log("bpTotalCount =" , +bpTotalCount)
      // console.log("bpcountMax =", +bpcountMax);
      this.totalCount = itemcount + bpcount;
      this.maxCount = itemcountmax + bpcountMax;
      let result = this.getPercent(this.maxCount-this.totalCount, this.maxCount)
      return {
         result: result,
         maxCount: this.maxCount,
         totalCount: this.totalCount,
         digitalCount: digitalCount,
         printCount: printCount,
         bpTotalCount: bpTotalCount
    };
  }

   /**
   * Gets business profile count
   * @param profiles
   * @returns
   */
  getBPCount(profiles) {
    this.bpCount = 0;
    this.bpCountMax = 0;
    let bpTotalCount = 0;
    //iterate over the profiles
    if(profiles && Object.keys(profiles).length > 1){
      for (let key in profiles) {
          if (profiles.hasOwnProperty(key) && key !== "") {
              let profileCount = (profiles[key].menu[0][0].counter + profiles[key].menu[0][1].counter)
              this.bpCount = this.bpCount + profileCount ;  //take from the first tile only for now
              this.bpCountMax += (profiles[key].menu[0][0].required + profiles[key].menu[0][1].required);
              // if(profiles[key].schema[1].properties.hasOwnProperty('ConfirmHoursOfOperation') && profiles[key].menu[0][1].counter != 0)
              //   bpTotalCount += 1;
              if(profileCount > 0){
                bpTotalCount = bpTotalCount + 1
              }
              //bpTotalCount += this.bpCount  === 0 ? 0 : 1;
          }
      }
    }
    return {
      bpcount: this.bpCount,
      bpcountMax: this.bpCountMax,
      bpTotalCount: bpTotalCount
    };
  }

  /**
   * Gets item count
   * @param items
   * @returns
   */
  getItemsCount(items) {
    this.itemCount = 0;
    this.itemCountMax = 0
    let digitalCount = 0;
    let printCount = 0;
    //iterate over the items object
    for (let i in items) {
      if (!this.orderStatusCancel.includes(items[i].order.status.toLowerCase())){
        this.itemCount += items[i].counter;
        this.itemCountMax += items[i].required;
        digitalCount += items[i].order && items[i].order.productType === 'Digital' ? items[i].counter === 0 ? 0 : 1 : 0;
        printCount += items[i].order &&  items[i].order.productType === 'Print' ? items[i].counter === 0 ? 0 : 1 : 0;
      }
    }
    return {
      itemcount: this.itemCount,
      itemcountmax: this.itemCountMax,
      digitalCount: digitalCount,
      printCount: printCount
    };
  }

  getPercent(currentCount, maxCount){
    let result = 0;
    if(currentCount == 0 && maxCount == 0 ){
      result = 100;
    } else {
      result = Math.floor(( currentCount/ maxCount) * 100);
    }
    this.counterService.setPercent(result);
    return result;
  }
}
