import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';

import { StorageService } from 'app/core/service/storage/storage.service';
import { ProfileService } from 'app/core/service/profile/profile.service';
import { UtilsService } from 'app/core/service/utils/utils.service';
import { SalesforceService } from 'app/core/service/salesforce/salesforce.service';
import { AccountService } from 'app/core/service/account/account.service';
import { IndicatorQuery } from 'app/state/query';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignStore } from 'app/state/campaignstore';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { IndicatorStore } from 'app/state/store';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
// import { CampaignSharedService } from '../campaign.service';

@Component({
  selector: 'app-businessprofile-dropdown',
  templateUrl: './businessprofile-dropdown.component.html',
  styleUrls: ['./businessprofile-dropdown.component.scss']
})
export class BusinessprofileDropdownComponent implements OnInit {

  listings: any = [];
  lists: any = [];
  listDtls: any = [];
  ngModel = {}
  Item: any;
  Indicator: any;
  campaignModel: CampaignClass;
  campData = [];
  titleContent: string = '';
  customField: string = '';
  tootipvalue: string;
  readonly: boolean = false;
  isBPNameReadOnly: boolean = false;
  isListingReadOnly: boolean = false;
  constructor(private StorageService: StorageService,
    private Profile: ProfileService,
    private utils: UtilsService,
    private Account: AccountService,
    private indicatorQuery: IndicatorQuery,
    private campaignQuery: CampaignQuery,
    private salesforceService: SalesforceService,
    private indicatorService: IndicatorService,
    private counterService: CounterService,
    private requiredfieldcount: RequiredfieldcountService,
    private indicatorStore: IndicatorStore,
    private cdr: ChangeDetectorRef,
    private campaignStore: CampaignStore,
    private Logger: LoggerService,
    private campaignService: PhotoService,
    // private campaignService: CampaignSharedService
    ) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
      for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
        if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
          break;
        }
      }
    })
    this.getListingDtls();
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data }
      if (this.campaignModel.model != undefined) {
        this.campaignModel.model.ListingId != undefined ? this.ngModel = this.campaignModel.model.ListingId : this.ngModel = '';
        // if(this.campaignModel.model && this.campaignModel.model["isCampaignFromVision"]){
        //   if(this.campaignModel.model.hasOwnProperty("ListingId") && this.campaignModel.model.ListingId){
        //     this.customField = this.Indicator.profiles[ this.campaignModel.model.ListingId].model.BusinessName;//this.campaignModel.model.CampaignTitle       
        //   } else {
        //     this.customField = this.campaignModel.model.CampaignTitle;
        //   }
        // } else {
        //   if(this.campaignModel.model.hasOwnProperty("ListingId") && this.campaignModel.model.ListingId){
        //     this.customField = this.Indicator.profiles[ this.campaignModel.model.ListingId].model.BusinessName;//this.campaignModel.model.CampaignTitle       
        //   } else if(this.campaignModel.model.CampaignTitle){
        //     this.customField = this.campaignModel.model.CampaignTitle;
        //   }
        // } 
        if(this.campaignModel.model.hasOwnProperty("ListingId") && this.campaignModel.model.ListingId && this.campaignModel.model.ListingId.trim().length > 0){
          // this.customField = this.Indicator.profiles[ this.campaignModel.model.ListingId].model.BusinessName;//this.campaignModel.model.CampaignTitle       
          if(this.Indicator.profiles[ this.campaignModel.model.ListingId]){
            this.customField = this.Indicator.profiles[ this.campaignModel.model.ListingId].model.BusinessName;//this.campaignModel.model.CampaignTitle       
          } else{
            this.customField = "";
          }
        } else {
        // } else if(this.campaignModel.model.CampaignTitle){
          this.customField = this.campaignModel.model.CampaignTitle;
        }
        // if(this.ngModel){
        //   this.isBPNameReadOnly = true;
        // } else {
        //   this.isBPNameReadOnly = false;
        // }
        if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit'){
            if(this.campaignModel.model['CampaignId']){
              this.isBPNameReadOnly = true;
              this.isListingReadOnly = true;
            } else {
              if(this.ngModel) {
                this.isBPNameReadOnly = true;
                // this.isListingReadOnly = true;
              } else {
                this.isBPNameReadOnly = false;
                this.isListingReadOnly = false;
              }
            }
        } else if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
            // this.isBPNameReadOnly = false;
            this.isListingReadOnly = false;
            if(this.campaignModel.model['ListingId']){
              this.isBPNameReadOnly = true;
            } else {
              this.isBPNameReadOnly = false;
            }
        } else {
          if(this.ngModel) {
            this.isBPNameReadOnly = true;
          } else {
            this.isBPNameReadOnly = false;
          }
        }
      }
    },err => {
      console.log("Error in getCampaign ",err);
    })
    this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title;
  }


  async updateReference(value) {
    if (value) {
      this.campaignService.setBehaviorViewListingValue(true);

      if(this.campaignModel.selectedCampaign && 
        this.campaignModel.selectedCampaign['modelClicktypes'] && 
        this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
          // this.isBPNameReadOnly = false;
          if(this.campaignModel.model['ListingId']){
            this.isBPNameReadOnly = true;
          } else {
            this.isBPNameReadOnly = false;
          }
      } else {
        this.isBPNameReadOnly = true;
      }

      let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
      let oldValue = modelData.ListingId
      modelData.ListingId = value
      // modelData['isSelectedEmpty'] = false;
      for (let listing of this.lists) {
        if (listing.listingId === value) {
          modelData.CampaignTitle = listing.businessName;
          this.customField = listing.businessName;
          modelData.Address1 = listing.businessAddress["streetAddress"];
          modelData.CampaignAddressCity = listing.businessAddress["city"];
          modelData.City = listing.businessAddress["city"];
          modelData.CampaignAddressZip = listing.businessAddress["zip"];
          modelData.Zip = listing.businessAddress["zip"];
          modelData.TargetZIPcode = listing.businessAddress["zip"];
          modelData.CampaignAddressState = listing.businessAddress["state"];
          modelData.State = listing.businessAddress["state"];
          modelData.CampaignPhone = listing.primaryPhone["value"]
          if(modelData.CCCampaignCatKeys){
            modelData.CCCampaignCatKeys = [];
          }
          if(modelData.CategoryList){
            modelData.CategoryList = [];
          }
          if(modelData.CategoryListText){
            modelData.CategoryListText = "";
          }
          if(modelData.GeoList){
            modelData.GeoList = [];
          }
          if(modelData.GeoListText){
            modelData.GeoListText = "";
          }
          break;
        }
      }
      let listingData = this.Indicator.items[this.Indicator.stateParamsId].listingArray//.split(',');
      if(listingData){
        for (let listing of listingData) {
            if (listing.value === modelData.ListingId) {
              modelData['BPText'] = listing.name;
              break;
            }
        }
      }
      
      let activityLogHistoryObj = {};
      activityLogHistoryObj = {
        'displayName': this.Indicator.user["name"],
        'email': this.Indicator.user["email"],
        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
        'itemId': this.Indicator.stateParamsId,
        'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
        'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
        'fieldName': 'Business Profile',
        'fieldOldValue': '',
        'fieldNewValue': value
      }
      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[BusinessprofileDropdownComponent] updateReference'
        }
      });
      let latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      //remove old profile
      if (oldValue && oldValue != undefined && oldValue != null && oldValue != '') {
        this.indicatorService.removeProfileByIdFromCampaign(latestIndicator, oldValue, this.Indicator.stateParamsId)
        this.indicatorQuery.updateProfilesListingId(latestIndicator, '[BusinessprofileDropdownComponent] updateReference-updateProfilesListingId');
      }
      //add new profile
      await this.indicatorService.addProfileById(latestIndicator, value)
      latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      let existingListingIds = latestIndicator.listingIds
      if (existingListingIds != undefined && existingListingIds != null) {
        existingListingIds.push(value)
      } else {
        existingListingIds = []
        existingListingIds.push(value)
      }
      this.indicatorQuery.updateProfiles(value, existingListingIds, latestIndicator, '[BusinessprofileDropdownComponent] updateReference-updateProfiles');
      //update the counter bp count
      let counter = []
      const { bpcount, bpcountMax, bpTotalCount } = this.requiredfieldcount.getBPCount(latestIndicator.profiles);
      latestIndicator.orderPercent.bpTotalCount = bpTotalCount;
      counter.push(latestIndicator.orderPercent.digitalCount);
      counter.push(latestIndicator.orderPercent.printCount);
      counter.push(latestIndicator.orderPercent.bpTotalCount);
      this.counterService.setError(counter);
      this.indicatorStore.update(() => {
        return {
          indicator: latestIndicator,
          updatedFrom: "[BusinessProfileDropDownComponent] BP",
          isloaded: true,
        };
      });

      this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
    } else {
      this.campaignService.setBehaviorViewListingValue(false);

      this.isBPNameReadOnly = false;

      let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
      let oldValue = modelData.ListingId
      modelData.ListingId = value;
      modelData.CampaignTitle = "";
      this.customField = "";
      modelData.Address1 = "";
      modelData.Address2 = "";
      modelData.CampaignAddressCity = "";
      modelData.City = "";
      modelData.CampaignAddressZip = "";
      modelData.Zip = "";
      modelData.TargetZIPcode = "";
      modelData.CampaignAddressState = "";
      modelData.State = "";
      modelData.CampaignPhone = "";
      modelData.BPText = "";

      if(modelData.CCCampaignCatKeys){
        modelData.CCCampaignCatKeys = [];
      }
      if(modelData.CategoryList){
        modelData.CategoryList = [];
      }
      if(modelData.CategoryListText){
        modelData.CategoryListText = "";
      }
      if(modelData.GeoList){
        modelData.GeoList = [];
      }
      if(modelData.GeoListText){
        modelData.GeoListText = "";
      }

      let activityLogHistoryObj = {};
      activityLogHistoryObj = {
        'displayName': this.Indicator.user["name"],
        'email': this.Indicator.user["email"],
        'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
        'itemId': this.Indicator.stateParamsId,
        'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
        'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
        'fieldName': 'Business Profile',
        'fieldOldValue': '',
        'fieldNewValue': value
      }
      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[BusinessprofileDropdownComponent] updateReference'
        }
      });
      let latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      //remove old profile
      if (oldValue && oldValue != undefined && oldValue != null && oldValue != '') {
        this.indicatorService.removeProfileByIdFromCampaign(latestIndicator, oldValue, this.Indicator.stateParamsId)
        this.indicatorQuery.updateProfilesListingId(latestIndicator, '[BusinessprofileDropdownComponent] updateReference-updateProfilesListingId');
      }
      //add new profile
      // await this.indicatorService.addProfileById(latestIndicator, value)
      latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      //update the counter bp count
      let counter = []
      const { bpcount, bpcountMax, bpTotalCount } = this.requiredfieldcount.getBPCount(latestIndicator.profiles);
      latestIndicator.orderPercent.bpTotalCount = bpTotalCount;
      counter.push(latestIndicator.orderPercent.digitalCount);
      counter.push(latestIndicator.orderPercent.printCount);
      counter.push(latestIndicator.orderPercent.bpTotalCount);
      this.counterService.setError(counter);
      this.indicatorStore.update(() => {
        return {
          indicator: latestIndicator,
          updatedFrom: "[BusinessProfileDropDownComponent] BP",
          isloaded: true,
        };
      });

      this.StorageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
    }
  }

  getListingDtls() {
    let tempIndicator = JSON.parse(JSON.stringify(this.Indicator));
    let account = tempIndicator.account;
    let isEmptyOptionAdded = false;
    if (tempIndicator.items[tempIndicator.stateParamsId].order.productType == "Digital") {
      if(!isEmptyOptionAdded){
        let emptyListing = {
          name: "",
          value: "",
          index: 0
        }
        this.listings.push(emptyListing);
        isEmptyOptionAdded = true;
      }
      
      let lists = tempIndicator.items[tempIndicator.stateParamsId].listings ? tempIndicator.items[tempIndicator.stateParamsId].listings : [];
      this.lists = lists;
      for (var i = 0; i < lists.length; i++) {
        let phoneNumLocal;
        if(this.utils && this.utils.transformTelephone && this.utils.transformTelephone(lists[i].primaryPhone.value)){
        phoneNumLocal = this.utils.transformTelephone(lists[i].primaryPhone.value);
        }
        let name = lists[i].businessName + "," + phoneNumLocal + " " + lists[i].businessAddress.streetAddress + " " + lists[i].businessAddress.city + ", " + lists[i].businessAddress.state + " " + lists[i].businessAddress.zip;
        if (lists[i].businessAddress.zip4 && lists[i].businessAddress.zip4.trim() !== '') {
          name += '-' + lists[i].businessAddress.zip4
        }
        if (tempIndicator.profiles[lists[i].value] != null) {
          //formatting required for below
          phoneNumLocal = this.utils.transformTelephone(tempIndicator.profiles[lists[i].value].model.BusinessPhone); //$filter('telephone')(tempIndicator.profiles[lists[i].listingId].model.BusinessPhone, false);
          name = tempIndicator.profiles[lists[i].value].model.BusinessName + "," + phoneNumLocal + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddress1 + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddressCity + ", " + tempIndicator.profiles[lists[i].value].model.BusinessAddressState + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddressZip.zip;
          if (lists[i].BusinessAddressZip !== undefined && lists[i].BusinessAddressZip.zip4.trim() !== '') {
            name += '-' + lists[i].BusinessAddressZip.zip4
          }
        }

        var listing = {
          name: name,
          value: lists[i].listingId,
          index: i + 1,
        };
        
        this.listings.push(listing);
        this.listings.forEach(element => {
          if (element.value == this.ngModel) {
            this.tootipvalue = element.name;
          }
        });
        if (!(<ViewRef>this.cdr).destroyed)
          this.cdr.detectChanges();
        //DE29031 fixed
        if ((lists[i].value === tempIndicator.items[tempIndicator.stateParamsId].order.listingId) && (tempIndicator.items[tempIndicator.stateParamsId].order.productCode !== "SEMP" && tempIndicator.items[tempIndicator.stateParamsId].order.productCode !== "SEM")) {
          this.ngModel = this.lists[i].value;
          tempIndicator['listing'] = this.lists[i];
          tempIndicator.items[tempIndicator.stateParamsId].listing = this.lists[i];
        }
      }

      if (this.ngModel != null && this.ngModel["listingId"] != null) {
        this.ngModel = tempIndicator.mylistingId ? tempIndicator.mylistingId : this.ngModel["listingId"];

      }
    } else {
      this.Profile.getAllPrintListing(tempIndicator.account["kgenCustomerId"], {
        productcode: tempIndicator.items[tempIndicator.stateParamsId].order.directoryNumber,
        productissuenum: tempIndicator.items[tempIndicator.stateParamsId].order.directoryIssueNumber,
        sectioncode: tempIndicator.items[tempIndicator.stateParamsId].order.section
      }).then(result => {
        var key = this.utils.getFirstKey(result);
        var data = result[key];
        var itemkey = [];

        if (data.hasWarningOrError === "false") {
          var lists = data.listings.listing;
          this.lists = lists;
          this.campData = lists
          var directory_code = null;
          for (var i = 0; i < lists.length; i++) {

            directory_code = tempIndicator.items[tempIndicator.stateParamsId].order.directoryNumber || "";

            var listing = {
              name: lists[i].listedName + "," + lists[i].listedTN + " " + lists[i].address + " " + directory_code,
              value: lists[i].listingId,
              index: i
            };
            this.listings.push(listing);
            if (!(<ViewRef>this.cdr).destroyed)
              this.cdr.detectChanges();
            if (this.ngModel == null && lists[i].listingId === tempIndicator.items[tempIndicator.stateParamsId].order.listingId) {
              this.ngModel = lists[i].listingId;
              tempIndicator.listing = this.lists[i];
              tempIndicator.items[tempIndicator.stateParamsId].listing = this.lists[i];
            }

          }

          //Fix for DE15326
          if (this.ngModel === undefined) {
            this.salesforceService.getAssetListing(tempIndicator.items[tempIndicator.stateParamsId].order.svItemId,tempIndicator.account.id)
              .subscribe(result => {
                this.ngModel = result["records"][0].Listing_Id__c;
                for (var i = 0; i < this.lists.length; i++) {
                  if (this.lists[i].listingId === result["records"][0].Listing_Id__c) {
                    tempIndicator.listing = this.lists[i];
                    tempIndicator.items[tempIndicator.stateParamsId].listing = this.lists[i];
                  }
                }
              }, err => {
                this.Logger.logError("<strong>Error in get Listing from Asset <strong><br>Source System : Salesforce <br>" + JSON.stringify(err));
              })
          }

          tempIndicator.listing = this.lists;
        } else {
          var key = this.utils.getFirstKey(result);
          var data = result[key];
          var errorObject = data.esbServiceFault[0];
          var errorCode = errorObject.exceptionDetail[0].errorCode;
          var errorDesc = errorObject.exceptionDetail[0].errorDescription;
          this.Logger.logError(errorCode + ' - ' + errorDesc);
        }
      });
    }
  }

  sendValue(args) {
    console.log(args);
    let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    
    if (!modelData.ListingId) {
      // let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
      modelData.CampaignTitle = args;
      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[BusinessProfile-Dropdown] sendvalue'
        }
      })
    }
  }

}
