import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { Subject, BehaviorSubject } from 'rxjs';
import { TradenameService } from 'app/core/service/tradename/tradename.service';
import { LoggerService } from 'app/core/service/logger/logger.service';
import { ErrorhandlingService } from 'app/core/service/errorhandling/errorhandling.service';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-tradename',
  templateUrl: './tradename.component.html',
  styleUrls: ['./tradename.component.scss']
})
export class TradenameComponent implements OnInit {

  Indicator: any;
  titleContent: string;
  fetched_tradenames: Promise<[any]>;
  fetchedData: any;
  selectedTradeName: any;
  isRequiredField: boolean = false;

  constructor(private indicatorQuery: IndicatorQuery,
    private tradeNameService: TradenameService,
    private Logger: LoggerService,
    private errorService: ErrorhandlingService,
    private indicatorStore: IndicatorStore) { }

  ngOnInit() {

    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    })
    this.isRequiredField = this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name].required
    this.titleContent = this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name].title;
    if (this.Indicator.items[this.Indicator.stateParamsId].model['TradeName'])
      this.selectedTradeName = this.Indicator.items[this.Indicator.stateParamsId].model['TradeName']
  }

  getTradeNames(searchword) {
    if (searchword.term != null && searchword.term.length > 2) {
      this.fetched_tradenames = this.callService(searchword.term)
    } else {
      this.fetched_tradenames = null;
    }
  }

  callService(searchWord) {

    return new Promise<[any]>((resolve, reject) => {
      let tradeDtls;
      this.tradeNameService.getTradeNames(searchWord)
        .subscribe(data => {
          tradeDtls = data.map(i => i.tradeName)
          resolve(tradeDtls);
        },
          err => {
            this.Logger.logError(this.errorService.handleApiError(err))
            reject(err);
          },
          () => {
            resolve()
          });
    });

  }

  onSelectSelectedTradeNames(args) {
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    modelData.items[this.Indicator.stateParamsId].model['TradeName'] = args;
    // this.indicatorQuery.updateModel(this['layoutNode'].name, args, modelData, this.Indicator);
    let x = JSON.parse(JSON.stringify(this.Indicator));
    x = modelData;
    this.indicatorStore.update(state => {
      return {
        indicator: x,
        updatedFrom: "[TradenameComponent] onSelectSelectedTradeNames",
        isloaded: true
      }
    });
  }

}
