import { Component, OnInit } from '@angular/core';
import { HOURS, HOURSOPTIONS_PRINTOLN } from 'app/data/constants';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-item-hoursofoperation',
  templateUrl: './item-hoursofoperation.component.html',
  styleUrls: ['./item-hoursofoperation.component.scss']
})
export class ItemHoursofoperationComponent implements OnInit {
    options: any;
    sharedData;
    display_hours;
    titleContent:string;
    hours = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];
    dd_option_selected = { 'id': '', 'value': '' };
    dropdown_options = [
      { 'id': '1', 'value': 'Open 24/7' },
      { 'id': '2', 'value': 'Open Mon-Fri 9AM-5PM' },
      { 'id': '3', 'value': 'By Appointment Only' },
      { 'id': '4', 'value': 'Specify Business hours' }
    ];
    openweekdayscheckbox;
    openweekendscheckbox;
    openweekdaysfrom;
    openweekdaysto;
    openweekendsfrom;
    openweekendsto;
    additionalhours;

    appointmentDays = [{
      "weekday": "Monday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }, {
      "weekday": "Tuesday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }, {
      "weekday": "Wednesday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }, {
      "weekday": "Thursday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }, {
      "weekday": "Friday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }, {
      "weekday": "Saturday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }, {
      "weekday": "Sunday",
      "closed": "Y",
      "startTime": ["12:00 AM"],
      "endTime": ["12:00 AM"]
    }];

    daysCheck = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    daysObject = {
      "Monday": {
        "weekday": "Monday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      },
      "Tuesday": {
        "weekday": "Tuesday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      },
      "Wednesday": {
        "weekday": "Wednesday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      },
      "Thursday": {
        "weekday": "Thursday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      },
      "Friday": {
        "weekday": "Friday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      },
      "Saturday": {
        "weekday": "Saturday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      },
      "Sunday": {
        "weekday": "Sunday",
        "closed": "Y",
        "startTime": ['12:00 AM'],
        "endTime": ['12:00 AM']
      }
    };

    days_shared = [{
      "weekday": "Monday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }, {
      "weekday": "Tuesday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }, {
      "weekday": "Wednesday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }, {
      "weekday": "Thursday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }, {
      "weekday": "Friday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }, {
      "weekday": "Saturday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }, {
      "weekday": "Sunday",
      "closed": "N",
      "startTime": [this.hours[0]],
      "endTime": [this.hours[0]]
    }];
    

    constructor(public indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) { }

    ngOnInit() {
        this.options =this['layoutNode'].options;
      try {
        this.indicatorQuery.getIndicator().subscribe(data => {
          this.sharedData = JSON.parse(JSON.stringify(data));
          this.titleContent = this.sharedData['items'][this.sharedData.stateParamsId]['schema'].properties.BusinessHours.title;
          
      });

      if (this.sharedData.items[this.sharedData.stateParamsId].order.UDAC == "PRINTOLN"){
        let selectedBP = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessProfile || this.sharedData.items[this.sharedData.stateParamsId].order.listingId;
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] = this.sharedData.profiles[selectedBP].model['HoursOfOperation'] || this.sharedData.profiles[selectedBP].data.businessProfiles.businessProfile.genericData.hoursOfOperation;
        let modelData =  JSON.parse(JSON.stringify(this.sharedData['items'][this.sharedData.stateParamsId].model));
        let x = JSON.parse(JSON.stringify(this.sharedData));
        let selectedListingId = x.items[this.sharedData.stateParamsId].model.BusinessProfile || x.items[this.sharedData.stateParamsId].order.listingId;
        x['items'][this.sharedData.stateParamsId].model = modelData;
        x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;
        this.indicatorStore.update(state =>{
            return{
            indicator: x,
            updatedFrom: "[ItemHoursofoperationComponent] ngOnInit",
            isloaded: true
            }
        });
    }else{
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] =this.sharedData.items[this.sharedData.stateParamsId].model.BusinessHours ? this.sharedData.items[this.sharedData.stateParamsId].model.BusinessHours : null;
    }
        //Replace undefined start & end times with 12:00 AM
        if ( this.sharedData['items'][this.sharedData.stateParamsId].model.hasOwnProperty('BusinessHours') &&
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] &&
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] != undefined &&
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] != null) {
          if ( this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule) {
            for (var i = 0; i < this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule.length; i++) {
             if ( this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule[i].startTime == undefined) {
                this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule[i]['startTime'] = ["12:00 AM"];
              }
              if ( this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule[i].endTime == undefined) {
                this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule[i]['endTime'] = ["12:00 AM"];
              }
            }
          }
        }
        //Set for new BP
        if ( this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] === undefined ||
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] == null ||
        this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] == '') {
          this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'] = {
            "hrsOpsSlots": { "slot": this.hours },
            "dayOfWeek": {
              "label": "Hours of Operation",
              "updatable": "Y",
              "schedule": this.days_shared
            }
          };
          this.dd_option_selected = { 'id': '1', 'value': 'Open 24/7' }
        } else {
            let open247Counter = 0;
          let openMonFri95Counter = 0;
          let byAppointmentOnlyCounter = 0;
          if (!( this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].hoursOfOperationText == "By Appointment Only")) {
            this.setDays();
            for (let i = 0; i < this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule.length; i++) {
              let schedule = this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model['BusinessHours'].dayOfWeek.schedule;
              if (schedule[i].startTime[0] == this.hours[0] &&
                schedule[i].endTime[0] == this.hours[0] &&
                schedule[i].closed == "N") {
                open247Counter++;
              }
              if (i < 5 && (schedule[i].startTime[0] == "09:00 AM" || schedule[i].startTime[0] == "9:00 AM") &&
                (schedule[i].endTime[0] == "05:00 PM" || schedule[i].endTime[0] == "5:00 PM") &&
                schedule[i].closed == "N") {
                openMonFri95Counter++;
              }
              //compensate for the old way of setting By Appointment Only
              if (schedule[i].startTime[0] == "12:00 AM" &&
                schedule[i].endTime[0] == "12:00 AM" &&
                schedule[i].closed == "Y") {
                byAppointmentOnlyCounter++;
              }
            }
          }
          if (open247Counter == 7) {
            this.display_hours = false;
            this.dd_option_selected = { 'id': '1', 'value': 'Open 24/7' }
            delete this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].hoursOfOperationText;
          } else if (openMonFri95Counter == 5) {
            this.display_hours = false;
            this.dd_option_selected = { 'id': '2', 'value': 'Open Mon-Fri 9AM-5PM' }
            delete this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].hoursOfOperationText;
          } else if ( this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].hoursOfOperationText === "By Appointment Only") {
            this.display_hours = false;
            this.dd_option_selected = { 'id': '3', 'value': 'By Appointment Only' }
          } else if (byAppointmentOnlyCounter === 7 && this.sharedData['items'][this.sharedData.stateParamsId].model['BusinessHours'].hoursOfOperationText === null) {
            this.display_hours = false;
            this.dd_option_selected = { 'id': '3', 'value': 'By Appointment Only' }
          } else {
            this.display_hours = true;
            this.dd_option_selected = { 'id': '4', 'value': 'Specify Business Hours' }
          }
        }
      } catch (Exception) {
        console.log(Exception)
      }

    }

    setDays() {
    let selectedListingId = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessProfile;
      let modelData =  JSON.parse(JSON.stringify(this.sharedData.items[this.sharedData.stateParamsId].model));
      if (modelData.BusinessHours.dayOfWeek.schedule == null) {
        modelData.BusinessHours.dayOfWeek.schedule = this.appointmentDays;
      } else if (modelData.BusinessHours.dayOfWeek.schedule.length < 7) {
        let length = this.daysCheck.length;
        for (var i = 0; i < length; i++) {
          if (modelData.BusinessHours.dayOfWeek.schedule[i] && modelData.BusinessHours.dayOfWeek.schedule[i].weekday !== this.daysCheck[i]) {
            modelData.BusinessHours.dayOfWeek.schedule.splice(i, 0, this.daysObject[this.daysCheck[i]]);
          } else if (!modelData.BusinessHours.dayOfWeek.schedule[i]) {
            modelData.BusinessHours.dayOfWeek.schedule.splice(i, 0, this.daysObject[this.daysCheck[i]]);
          }
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      x['items'][this.sharedData.stateParamsId].model = modelData;
      x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] setDays",
          isloaded: true
        }
      });
    }

    onselection() {
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model));
      if (this.dd_option_selected.id == '1') {
        // Open 24/7
        if (!modelData.BusinessHours.dayOfWeek.schedule) {
          modelData.BusinessHours.dayOfWeek.schedule = this.days_shared;
        }
        this.display_hours = false;
        for (let i = 0; i < 7; i++) {
          modelData.BusinessHours.dayOfWeek.schedule[i].closed = "N";
          modelData.BusinessHours.dayOfWeek.schedule[i].startTime = ["12:00 AM"]
          modelData.BusinessHours.dayOfWeek.schedule[i].endTime = ["12:00 AM"]
        }
        modelData.BusinessHours.hoursOfOperationText = "";
      } else if (this.dd_option_selected.id == '2') {
        // Open Mon-Fri 9AM-5PM
        this.display_hours = false;
        if (!modelData.BusinessHours.dayOfWeek.schedule) {
          modelData.BusinessHours.dayOfWeek.schedule = this.days_shared;
        }
        for (let i = 0; i < 7; i++) {
          if (i < 5) {
            modelData.BusinessHours.dayOfWeek.schedule[i].closed = "N";
            modelData.BusinessHours.dayOfWeek.schedule[i].startTime = ["9:00 AM"]
            modelData.BusinessHours.dayOfWeek.schedule[i].endTime = ["5:00 PM"]
          } else {
            modelData.BusinessHours.dayOfWeek.schedule[i].closed = "Y"
            modelData.BusinessHours.dayOfWeek.schedule[i].startTime = ["12:00 AM"]
            modelData.BusinessHours.dayOfWeek.schedule[i].endTime = ["12:00 AM"]
          }
        }
        modelData.BusinessHours.hoursOfOperationText = "";
      } else if (this.dd_option_selected.id == '3') {
        // By Appointment Only
        this.display_hours = false;
        if (modelData.BusinessHours.dayOfWeek.hasOwnProperty('schedule')) {
          delete modelData.BusinessHours.dayOfWeek.schedule;
        }
        modelData.BusinessHours.hoursOfOperationText = "By Appointment Only";
      } else if (this.dd_option_selected.id == '4') {
        //Specify Business hours
        if (!modelData.BusinessHours.dayOfWeek.schedule) {
          modelData.BusinessHours.dayOfWeek.schedule = this.days_shared;
        }
        this.display_hours = true;
        this.openweekdayscheckbox = false;
        this.openweekendscheckbox = false;
        this.openweekdaysfrom = "12:00 AM";
        this.openweekdaysto = "12:00 AM";
        this.openweekendsfrom = "12:00 AM";
        this.openweekendsto = "12:00 AM";
        for (let i = 0; i < 7; i++) {
          modelData.BusinessHours.dayOfWeek.schedule[i].closed = "Y"
          modelData.BusinessHours.dayOfWeek.schedule[i].startTime = ["12:00 AM"]
          modelData.BusinessHours.dayOfWeek.schedule[i].endTime = ["12:00 AM"]
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      let selectedListingId = x.items[this.sharedData.stateParamsId].model.BusinessProfile || x.items[this.sharedData.stateParamsId].order.listingId;
      x['items'][this.sharedData.stateParamsId].model = modelData;
      x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] onselection",
          isloaded: true
        }
      });
    }

    weekdayscheckbox() {
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model));
      for (let i = 0; i < 5; i++) {
        if (this.openweekdayscheckbox) {
          modelData.BusinessHours.dayOfWeek.schedule[i].closed = "N";
        } else {
          modelData.BusinessHours.dayOfWeek.schedule[i].closed = "Y";
          modelData.BusinessHours.dayOfWeek.schedule[i].startTime = ["12:00 AM"]
          modelData.BusinessHours.dayOfWeek.schedule[i].endTime = ["12:00 AM"]
          this.openweekdaysfrom = "12:00 AM"
          this.openweekdaysto = "12:00 AM"
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      let selectedListingId = x.items[this.sharedData.stateParamsId].model.BusinessProfile || x.items[this.sharedData.stateParamsId].order.listingId;
      x['items'][this.sharedData.stateParamsId].model = modelData;
      x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] weekdayscheckbox",
          isloaded: true
        }
      });
    }

    weekdaysfromhours(evnt) {
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model));
      for (let i = 0; i < 7; i++) {
        if (i < 5) {
          let startTime = [];
          startTime.push(evnt)
          modelData.BusinessHours.dayOfWeek.schedule[i].startTime = startTime;
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      let selectedListingId = x.items[this.sharedData.stateParamsId].model.BusinessProfile || x.items[this.sharedData.stateParamsId].order.listingId;
      x['items'][this.sharedData.stateParamsId].model = modelData;
      x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] weekdaysfromhours",
          isloaded: true
        }
      });
    }

    weekdaystohours(evnt) {
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model));
      for (let i = 0; i < 7; i++) {
        if (i < 5) {
          let endTime = [];
          endTime.push(evnt)
          modelData.BusinessHours.dayOfWeek.schedule[i].endTime = endTime;
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      let selectedListingId = x.items[this.sharedData.stateParamsId].model.BusinessProfile || x.items[this.sharedData.stateParamsId].order.listingId;
      x['items'][this.sharedData.stateParamsId].model = modelData;
      x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;

  this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] weekdaystohours",
          isloaded: true
        }
      });
    }

    weekendscheckbox() {
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.sharedData.stateParamsId].model));
      for (let i = 5; i < 7; i++) {
        if (this.openweekendscheckbox) {
          modelData.BusinessHours.dayOfWeek.schedule[i].closed = "N";
        }
        else {
          modelData.BusinessHours.dayOfWeek.schedule[i].closed = "Y";
          modelData.BusinessHours.dayOfWeek.schedule[i].startTime = ["12:00 AM"]
          modelData.BusinessHours.dayOfWeek.schedule[i].endTime = ["12:00 AM"]
          this.openweekendsfrom = "12:00 AM"
          this.openweekendsto = "12:00 AM"
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      let selectedListingId = x.items[this.sharedData.stateParamsId].model.BusinessProfile || x.items[this.sharedData.stateParamsId].order.listingId;
      x['items'][this.sharedData.stateParamsId].model = modelData;
      x['profiles'][selectedListingId].model.HoursOfOperation = modelData.BusinessHours;

  this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] weekendscheckbox",
          isloaded: true
        }
      });
    }

    weekendsfromhours(evnt) {
    let selectedBP = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessProfile || this.sharedData.items[this.sharedData.stateParamsId].order.listingId;
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][selectedBP].model));
      for (let i = 0; i < 7; i++) {
        if (!(i < 5)) {
          let startTime = [];
          startTime.push(evnt)
          modelData.HoursOfOperation.dayOfWeek.schedule[i].startTime = startTime;
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      x['profiles'][this.sharedData.stateParamsId].model = modelData;

  this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] weekendsfromhours",
          isloaded: true
        }
      });
    }

    weekendstohours(evnt) {
        let selectedBP = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessProfile || this.sharedData.items[this.sharedData.stateParamsId].order.listingId;
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][selectedBP].model));
      for (let i = 0; i < 7; i++) {
        if (!(i < 5)) {
          let endTime = [];
          endTime.push(evnt)
          modelData.HoursOfOperation.dayOfWeek.schedule[i].endTime = endTime;
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      x['profiles'][this.sharedData.stateParamsId].model = modelData;

  this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] weekendstohours",
          isloaded: true
        }
      });
    }

    Alldayscheckbox(counter, evnt) {
    let selectedBP = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessProfile || this.sharedData.items[this.sharedData.stateParamsId].order.listingId;
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][selectedBP].model));
      if (evnt.target.checked)
      modelData.HoursOfOperation.dayOfWeek.schedule[counter].closed = "N";
      else
      modelData.HoursOfOperation.dayOfWeek.schedule[counter].closed = "Y";

      let x = JSON.parse(JSON.stringify(this.sharedData));
      x['profiles'][selectedBP].model = modelData;

  this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ItemHoursofoperationComponent] Alldayscheckbox",
          isloaded: true
        }
      });
    }

}
