import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ParamsService } from '@app/service/params/params.service';
import {PaynowService} from '@app/service/roundProgress/paynow/paynow.service'
import { IndicatorQuery } from 'app/state/query';



@Component({
  selector: 'app-billinginput',
  templateUrl: './billinginput.component.html',
  styleUrls: ['./billinginput.component.scss']
})
export class BillinginputComponent implements OnInit {
  options: any;
  billingtype :any;
  //url = "https://dctcasa02.corp.pvt/paynowtoolTest2/DefaultMap.aspx";
  titleContent: string;
  billingID: any;
  showPaymentLink: boolean = false;
  payNowurl;
  sharedData;
  countryCode
  isCountryCodeField: boolean = false;


  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private param: ParamsService,
    private PaynowService: PaynowService,
    private indicatorQuery: IndicatorQuery) { }


  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      this.countryCode = this.sharedData.account.address.country
      if (this['layoutNode'].name == 'BillingAccountType') {
        this.isCountryCodeField = false;
        this.billingID = this.data.dialogform._cell.row.data.billingAccountId;
        this.PaynowService.getPayNowLink(null);
        //this.payNowurl = url + this.this.PaynowService.finalURl;   
        // this.payNowurl= this.PaynowService.finalPaynowURL;

        if (this.countryCode === 'NZ') {
          // If the account is from the NZ, hide the payment link
          this.showPaymentLink = false;
          this.payNowurl = null;
        } else {
          // Set the PayNow link for accounts from other countries and show the payment link
          this.showPaymentLink = true;
          this.payNowurl = this.PaynowService.finalPaynowURL;
        }
        this.options = this['layoutNode'].options;
        this.titleContent = "Billing Account Type";
        if (this.data.title === "Update" && this['layoutNode'].name === "BillingAccountType") {
          this.billingtype = this.data.dialogform._cell.row.data.billingType;
        }
      } 
      if (this['layoutNode'].name == 'CountryCode') {
        this.titleContent = "Country Code";
        this.isCountryCodeField = true;
      }
    });
  }
  onPayNowClick() {
    let newWindow = window.open();
    newWindow.location.href = this.payNowurl;
  }

}
