import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-dexcard',
  templateUrl: './dexcard.component.html',
  styleUrls: ['./dexcard.component.scss']
})
export class DexcardComponent implements OnInit {
  subscription: any;
  @Input() titleContent: string;
  @Input() tileFlag: boolean;
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  @Input() receivedCount: any;

  sharedData: Quickbpclass = new Quickbpclass;
  campaignFlag: boolean;
  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data}
    });
    this.campaignFlag = this.sharedData.campaignFlag
  }

  ngOnInit() {
  }

  refreshSchema() {
    this.parentFun.emit();

  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }
}
