import  * as $ from 'jquery';
import { Router } from '@angular/router';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { OnInit } from '@angular/core';
import { AdminuserService } from '@app/service/adminuser/adminuser.service';
import { LoggerService } from '@app/service/logger/logger.service';
var that;
export class ToolModel {
  indicatorValue :Quickbp
    subscribe: any
    constructor(private router: Router,
              private params: ParamsService, private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,private adminuser:AdminuserService,private logger:LoggerService) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  that = this;
                });
    }
    columns: any ={
      "adminGrid":[
        {title:"User Id", field:"user"},
        {title:"Password", field:"password",editor:"input"},
        {title:"Actions",formatter:function(cell, formatterParams){ //plain text value
          // var button = document.createElement("button");
    
          // button.value="Update";
          // button.style.height="30px"
          // button.style.width="30px"
          return `<div>
          <button class="mat-button mat-button-base" id="update">Update</button>
          <button class="mat-button mat-button-base" id="delete">Delete</button>
          </div>`
          // return button
      },cellClick: (e, cell) => {
      var Btn = document.createElement('Button');
      Btn.id = "Btn_Id";
      var id1 = document.getElementsByTagName('button')[0].id;
      var id2 = document.getElementsByTagName('button')[1].id
      console.log("event called",Btn.id);
      // console.log(id1);
      // console.log(id2);
      if(e.target.id == 'update'){
        //this._router.navigate(['servicehistory/' + cell.getRow().getData().ItemID + '/' + cell.getRow().getData().ID]); 
        console.log("update button called",cell.getRow().getData());
        let updateData = {
          userId:cell.getRow().getData().user,
          password:cell.getRow().getData().password
        }
        this.adminuser.updateUser(updateData).subscribe((data)=> {
            console.log("updated value",updateData)
            this.logger.logSuccess('Password updated successfully');
        })
      }
      if(e.target.id == 'delete'){
        //this._router.navigate(['servicedetails/' + cell.getRow().getData().ItemID + '/' + cell.getRow().getData().ID]);
        console.log("delete button called",cell.getRow().getData())
        cell.getRow().delete();
        this.adminuser.deleteUser(cell.getRow().getData().user).subscribe((data)=> {
          console.log("updated value",cell.getRow().getData().user)
          this.logger.logWarning('User deleted succesfully');
          //vm.loadData();
      });;
      }
      return {id2, id1};
    }
        },
        ]
  }
}
