import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { OktaAuth, isAuthApiError } from '@okta/okta-auth-js';
import { NodeApiEnvUrlService } from '@app/service/nodeApiEnvUrl/node-api-env-url.service';
import { ConfigurationService } from '@app/service/configuration/configuration.service';
//import { IndicatorModel } from '@app/service/indicator/indicator.model';

@Injectable({ providedIn: 'root' })
export class OktaAuthService {
 // public indicator: IndicatorModel = new IndicatorModel();

  // IMPORTANT!
  // Replace {clientId} with your actual Client ID
  // Replace {yourOktaDomain} with your actual Okta domain
  // If using a custom authorization server, ISSUER should be 'https://{yourOktaDomain}/oauth2/${authServerId}'
  oktaAuth: any = null;

  $isAuthenticated: Observable<boolean>;
  private observer: Observer<boolean>;
   
  constructor(private router: Router,
    private Configuration: ConfigurationService,
    private nodeApiEnvUrlService: NodeApiEnvUrlService,) {

    //let CLIENT_ID = '0oayhfxjhlCbvHGQH0h7';
    //let ISSUER = 'https://dexmedia.oktapreview.com/oauth2/ausyi2np1d38MHtcL0h7';
    // let CLIENT_ID = '0oa1l7mo2lbWF3RjV1d8';
    // let ISSUER = 'https://dexmedia.okta.com/oauth2/aus1l7mqiaaUpB5za1d8';
    let loginLogouturls = this.nodeApiEnvUrlService.getNodeUrl("okta");
    let LOGIN_REDIRECT_URI = loginLogouturls.LOGIN_REDIRECT_URI;
    let LOGOUT_REDIRECT_URI = loginLogouturls.LOGOUT_REDIRECT_URI

    console.log('loginLogouturls -------', loginLogouturls)
    this.loadApplication();

    if (sessionStorage.getItem('CLIENT_ID') && sessionStorage.getItem('ISSUER')) {
      this.oktaAuth = new OktaAuth({
        clientId: sessionStorage.getItem('CLIENT_ID'),
        issuer: sessionStorage.getItem('ISSUER'),
        redirectUri: LOGIN_REDIRECT_URI,
        pkce: false,
        tokenManager: {
          storage: 'sessionStorage',
          autoRenew: false
        }
      });
        
        this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
        this.observer = observer;
        this.isAuthenticated().then(val => {
          console.log("val139", val);
          observer.next(val);
        });
      });
    }
    }
  
  async isAuthenticated() {
    await this.loadApplication();
    // Checks if there is a current accessToken in the TokenManger.
    return (await this.oktaAuth.tokenManager.get('accessToken'));
  }
 
  login(originalUrl) {
    // Save current URL before redirect , encodeURI(state.url)
    //localStorage.setItem("caangulareffererPath", window.location.href);
    sessionStorage.setItem("caangulareffererPath", encodeURI(originalUrl));
    sessionStorage.setItem('okta-app-url', originalUrl || window.location.href);
    // Launches the login redirect.
    this.oktaAuth.token.getWithRedirect({
      scopes: ['openid', 'email', 'profile']
    });
  }

  async handleAuthentication() {
    //  console.log("inside handle authentication-- ", this.isAuthenticated());
    const tokenContainer = await this.oktaAuth.token.parseFromUrl();

    this.oktaAuth.tokenManager.add('idToken', tokenContainer.tokens.idToken);
    this.oktaAuth.tokenManager.add('accessToken', tokenContainer.tokens.accessToken);
    if (await this.isAuthenticated()) {
      const url = sessionStorage.getItem('okta-app-url');
      let navigateUrl = decodeURI(sessionStorage.getItem("caangulareffererPath"));
      //navigateUrl = navigateUrl.split("?")[1]
      this.router.navigateByUrl(navigateUrl);
      this.observer.next(true);
    }

    // Retrieve the saved URL and navigate back
    // const url = sessionStorage.getItem('okta-app-url');
    // const navUrl = sessionStorage.getItem("caangulareffererPath");
    //this.router.navigateByUrl(navUrl);
  }
  async loadApplication()  {
    let loginLogouturls = this.nodeApiEnvUrlService.getNodeUrl("okta");
    let LOGIN_REDIRECT_URI = loginLogouturls.LOGIN_REDIRECT_URI;
    let LOGOUT_REDIRECT_URI = loginLogouturls.LOGOUT_REDIRECT_URI;
    try {
      // Fetch configuration data
      let configdetails = await this.Configuration.getAll();
     // this.indicator.configuration = configdetails;
      let CLIENT_ID_Prod, ISSUER_Prod, CLIENT_ID_Test, ISSUER_Test
      // Extract CLIENT_ID and ISSUER for Prod and Test environments
      configdetails.forEach((item) => {
        if (item.name === "clientId_Prod") {
          CLIENT_ID_Prod = item.value;
          ISSUER_Prod = configdetails.find(i => i.name === "issuer_Prod").value;
        } else if (item.name === "clientId_Test") {
          CLIENT_ID_Test = item.value;
          ISSUER_Test = configdetails.find(i => i.name === "issuer_Test").value;
        }
      });
      let CLIENT_ID, ISSUER
      if (loginLogouturls.LOGIN_REDIRECT_URI.indexOf('ucc.dexmedia') > 0) {
        CLIENT_ID = CLIENT_ID_Prod
        ISSUER = ISSUER_Prod
      } else {
        CLIENT_ID = CLIENT_ID_Test
        ISSUER = ISSUER_Test
      }
      // Check values obtained
      console.log('CLIENT_ID:', CLIENT_ID);
      console.log('ISSUER:', ISSUER);
      sessionStorage.setItem("CLIENT_ID", CLIENT_ID)
      sessionStorage.setItem("ISSUER", ISSUER)
      this.oktaAuth = new OktaAuth({
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: LOGIN_REDIRECT_URI,
        pkce: false,
        tokenManager: {
          storage: 'sessionStorage',
          autoRenew: false
        }
      })
      setTimeout(() => {   
        this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
        this.observer = observer;
        this.isAuthenticated().then(val => {
          console.log("val139", val);
          observer.next(val);
        });
      });}, 1000);
    //  return configdetails;
    } catch (error) {
      console.error('Error loading application:', error);
    }
  }

  async logout() {
    await this.oktaAuth.signOut({
      postLogoutRedirectUri: 'https://ucc-dev.dexmedia.com/'
    });
  }
}
