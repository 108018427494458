import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ParamsService } from "../params/params.service";
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  path: string = "/api/configuration";
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  /*
  headers = new HttpHeaders({"authorization" :"bearer " + this.Params.params.token});
  httpOptions = {
    headers: this.headers,
    'id': ''
  };*/
  constructor(private http: HttpClient, private Params: ParamsService,private API : ApiService) {

  }
  getAll() : Promise<any>  {
    return this.http.get(this.API.get(this.path)).toPromise();
  };
  update (data): Promise<any>  {
    return this.http.put(this.API.get(this.path),data).toPromise();
  };
  create(data) : Promise<any>  {
    return this.http.post(this.API.get(this.path),data).toPromise();
  };
  delete(type:any ,name:any,data): Promise<any>{
    //this.path =+'/'+ type+'/'+name;
      return this.http.put(this.API.get(this.path)+"/"+type+"/"+name,data).toPromise();
  };
}
