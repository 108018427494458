import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { NgForm } from '@angular/forms';
//import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Quickbpclass } from 'app/interface/quickbp';
import { StorageService } from '@app/service/storage/storage.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { ValidateService } from '@app/service/validate/validate.service';

import { MatDialog } from '@angular/material';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-imagelist',
  templateUrl: './imagelist.component.html',
  styleUrls: ['./imagelist.component.scss']
})
export class ImagelistComponent implements OnInit {

  subscription: any;
  selecteditem = (localStorage.getItem("callToActionLabel")) ? localStorage.getItem("callToActionLabel") : "Select";
  itemsList: any = [];
  selectButton = "Select"
  icondata: any = [];
  assetNumber: string = "";
  //Make bellow false once UI done
  iypPremium: boolean = false;
  done: boolean = false;
  URIType: string = "";
  validateURL: boolean = false;
  validUrl: boolean = true;
  url1: string = "";
  url2: string = "";
  typeOfAnchor: string = "";
  enterpriseId: string = "";
  AnchorType: string = "";
  iypflag: boolean = false;
  flag: boolean = false;
  cross: boolean = false;
  isSubmitted: boolean = false;
  //id: string = "123"
  asset = []
  closeResult: string = '';
  listingId: string = '';
  prodData: any = [];
  sharedData: Quickbpclass;
  dialogRef: any;
  actionTypeFlag: boolean = false;

  constructor(private _storageService: StorageService, private _profileService: ProfileService,
    private utils: UtilsService,
    private Validate: ValidateService,/* private modalService: NgbModal,*/private dialog: MatDialog,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = data;
      this.listingId = data.stateParamsId;
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"]) {
        if (this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"]["asset"])
          this.asset = this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"]["asset"]
        else this.asset = this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"];
      }
      else {
      }
    })
    if (this.asset[0] === undefined) {
      // Do nothing
    } else {
      this.URIType = this.asset[0].uriType;
      this.url1 = (this.URIType === "URL") ? this.asset[0].uri : "";
      this.validateURL = (this.URIType === "URL") ? true : false;

      if (this.asset[0].uri === "#Gallery") {
        this.AnchorType = "Photos";
      }
      else if (this.asset[0].uri === "#Coupons") {
        this.AnchorType = "Coupons";
      }
      else if (this.asset[0].uri === "#Reviews") {
        this.AnchorType = "Reviews";
      }
      else if (this.asset[0].uri === "#Info") {
        this.AnchorType = "Products/Services";
      }

      var actNum = this.asset[0].actionNumber;
      if (actNum === "1") {
        this.selecteditem = "Tickets";
      }
      else if (actNum === "2") {
        this.selecteditem = "Availability";
      }
      else if (actNum === "3") {
        this.selecteditem = "Enroll";
      }
      else if (actNum === "4") {
        this.selecteditem = "Contact";
      }
      else if (actNum === "5") {
        this.selecteditem = "Quote";
      }
      else if (actNum === "6") {
        this.selecteditem = "Reservation";
      }
      else if (actNum === "7") {
        this.selecteditem = "Staff";
      }
      else if (actNum === "8") {
        this.selecteditem = "Order";
      }
      else if (actNum === "9") {
        this.selecteditem = "Appointment";
      }
      else if (actNum === "10") {
        this.selecteditem = "Menu";
      }
      else if (actNum === "11") {
        this.selecteditem = "Products";
      }
      else if (actNum === "12") {
        this.selecteditem = "Services";
      }
      else if (actNum === "13") {
        this.selecteditem = "Catalog";
      }
      else if (actNum === "14") {
        this.selecteditem = "Coupons";
      }
      else if (actNum === "15") {
        this.selecteditem = "Reviews";
      }
      else if (actNum === "16") {
        this.selecteditem = "Photos";
      }
      else if (actNum === "17") {
        this.selecteditem = "Giftcard";
      }
      this.selectButton = this.selecteditem
    }
    if (this.selecteditem !== "Select" && this.isSubmitted == false) {
      this.cross = true;
    }
    this._profileService.getIcons().subscribe(icondata => {
      if(icondata && icondata.assets && icondata.assets.asset){
      this.icondata = icondata.assets.asset;
      this.assetNumber = icondata.assets.assetNumber;
      this.icondata.forEach(filterObj => {
        if (filterObj.assetTarget === "dexknows.com" && filterObj.assetId != "2.5" && filterObj.assetId != "1.5") {
          this.itemsList.push(filterObj);
        }
      });
    }
    })

    if (this.sharedData["account"] && this.sharedData["account"] != undefined && this.sharedData["account"] != null && this.sharedData["account"].hasOwnProperty("enterpriseAccountId"))
      this.enterpriseId = this.sharedData["account"].enterpriseAccountId;

    this._storageService.get("profile", this.listingId).then(data => {
      if (data[this.listingId] && data[this.listingId].coaFlag === undefined) {
        data[this.listingId].coaFlag = false;
      } else {
        if (data[this.listingId]) {
          this.flag = data[this.listingId].coaFlag;
        }
      }
      if (this.flag === true) {
        this.cross = false;
        this.isSubmitted = true;
      } else {
        this.cross = true;
      }
    })


    if (this.sharedData["order"]) {
      this.sharedData["order"].OrderItems.forEach(item => {
        if (item.productType === "Digital" && item.actionCode !== "Cancel") {
          let listingId;
          let itemData = this.sharedData["items"][item.svItemId].model;
          let itemGetContentData = this.sharedData["items"][item.svItemId].data;
          if (this.sharedData["items"][item.svItemId].order.actionCode === "Renew") {
            /*if(this.sharedData["items"][item.svItemId].order.listingId!=undefined && this.sharedData["items"][item.svItemId].order.listingId!=null && this.sharedData["items"][item.svItemId].order.listingId!=''){
              //get ListingId from salesforce for renewal items
              listingId = this.sharedData["items"][item.svItemId].order.listingId
            } else{
              if (itemGetContentData.ProductHeader) {
                if (itemGetContentData.ProductHeader.listingId) {
                    listingId = itemGetContentData.ProductHeader.listingId
                } else if (itemGetContentData.ProductHeader.businessLocationId) {
                    listingId = itemGetContentData.ProductHeader.businessLocationId
                } else {
                    listingId = ""
                }
              }
            }*/
            listingId = itemData['BusinessProfile'];
          } else{
                if (itemData !== undefined && itemData.hasOwnProperty("BusinessProfile") && itemData['BusinessProfile'] != undefined && itemData['BusinessProfile'] != null && itemData['BusinessProfile'] != "") {
                  listingId = itemData['BusinessProfile'];
                } else {
                    listingId = itemData['listingId'];
                }
          }

          if (this.listingId && this.listingId === listingId) {
            if (item.productCode === "Premium IYP" || item.productCode === "Single IYP") {
              for (var profile in this.sharedData["profiles"]) {
                if (this.sharedData["profiles"].hasOwnProperty(profile)) {
                  if (profile) {
                    if (this.sharedData["profiles"][profile].data.listings.listing[0].listingId === listingId) {
                      this.iypflag = true;
                      this.iypPremium = true;
                      this.ngAfterViewChecked();
                    }
                    break;
                  }
                }
              }
            }
          }
        }
      })
    }
    if (this.listingId !== "" && this.sharedData['account'] && this.sharedData['account']['id']) {
      return this.Validate.get(this.listingId,this.sharedData['account']['id']).then(data => {
        if (data && (data["hasIYPPremium"] === true || data["hasIYPBasic"] === true || this.iypflag === true)) {
          this.iypPremium = true;
          this.ngAfterViewChecked();
        }
        else {
          this._storageService.getAll("item")
            .then((data: any) => {
              this.prodData = data;
              if (this.prodData.length !== 0) {
                //var items = []; fix for sonar issue

                for (var i = 0; i < this.prodData.length; i++) {
                  if ((this.prodData[i].data.productCode === "Premium IYP" || this.prodData[i].data.productCode === "Single IYP") &&
                    this.prodData[i].data.actionCode !== "Cancel" && this.prodData[i].data.listingId === this.listingId) {
                    //show call to action on this item
                    this.iypPremium = true;
                    this.ngAfterViewChecked();
                    break;
                  }
                }
              }
            });
        }
      });
    }



  }

  open(content) {
    // this.dialog.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: "call-to-action-modal" }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    this.dialogRef = this.dialog.open(content, { disableClose: true , width: '800px', panelClass: 'coupon-dialog'});
    this.dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    });
  }
  close(){
    this.dialogRef.close();
  }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  onClick(e) {
  try{
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    this.selecteditem = e;
    this.itemsList.forEach(filterObj => {
      if (filterObj.assetName === this.selecteditem) {
        this.assetNumber = filterObj.assetNumber;
      }
    });
    // this.asset[0].actionNumber = this.assetNumber;
    if (this.asset[0] && this.asset[0].actionNumber) {
      this.asset =JSON.parse(JSON.stringify( this.asset))
      this.asset[0].actionNumber = this.assetNumber;
      }
    // this.sharedData['assets'] = this.asset;
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"] = this.asset;
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[ImagelistComponent] onClick",
      }
    })
    // this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"] = this.asset;
  }catch (error){
    console.log("Error in call to action:",error);
  }
  }

  ok() {
    this.selectButton = this.selecteditem
    this.cross = true;
    if(this.URIType === "URL" || this.URIType === "ANCHOR" || this.selectButton != "Select"){
      this.actionTypeFlag = true;
    }
    else{
      this.actionTypeFlag = false;
    }
    this.ngAfterViewChecked();
    this.dialogRef.close();

  }

  remove() {
    this.selecteditem = "Select";
    this.selectButton = "Select";
    // if (this.Indicator.indicator.profiles[this.listingId].model) {
    //   this.Indicator.indicator.profiles[this.listingId].model.assets = undefined;
    // }
    this.sharedData = JSON.parse(JSON.stringify(this.sharedData));
    if (this.sharedData['profiles'][this.listingId].model) {
      this.sharedData['profiles'][this.listingId].model["assets"] = undefined;
    }
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[ImagelistComponent] remove",
      }
    })
    // if(this.URIType === "URL" || this.URIType === "ANCHOR" || this.selectButton != "Select"){
    if(this.selectButton != "Select"){
      this.actionTypeFlag = true;
    }
    else{
      this.actionTypeFlag = false;
    }
    this.cross = false;
  }

  selectionChanged(event) {
    if(this.URIType === "URL" || this.URIType === "ANCHOR" || this.selectButton != "Select"){
      this.actionTypeFlag = true;
    }
    else{
      this.actionTypeFlag = false;
    }
    if (this.URIType === "URL") {
      this.validateURL = true;
    }
    else {
      this.validateURL = false;
    }
  }

  finalData(urlValue: NgForm) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    if (urlValue && urlValue.form && urlValue.form.controls && urlValue.form.controls.socialUrl &&
      urlValue.form.controls.socialUrl.value) {
      if (this.utils.validateURL(urlValue.form.controls.socialUrl.value, false) === true) {
        this.validUrl = true;
      } else {
        this.validUrl = false;
      }
    }

    if (this.selecteditem != null && this.selecteditem !== "Select") {
      this._profileService.getIcons().subscribe(success => {
        this.icondata = success.assets.asset;
        this.icondata.forEach(filterObj => {
          if (filterObj.assetName === this.selecteditem) {
            this.assetNumber = filterObj.assetNumber;
          }
        });
        this.asset = [
          {
            "actionNumber": this.assetNumber,
            "enterpriseId": this.enterpriseId,
            "uri": this.url1,
            "uriType": this.URIType
          }
        ];
        // this.sharedData['assets'] = this.asset;
        this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"] = this.asset;
        this.indicatorStore.update(state =>{
          return{
            indicator: this.sharedData,
            updatedFrom: "[ImagelistComponent] selecteditem",
          }
        })
      });

    }
  }

  anchorChanged() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    if (this.AnchorType === "") {
      return;
    }
    else if (this.AnchorType === "Photos") {
      this.typeOfAnchor = "#Gallery";
    }
    else if (this.AnchorType === "Coupons") {
      this.typeOfAnchor = "#Coupons";
    }
    else if (this.AnchorType === "Reviews") {
      this.typeOfAnchor = "#Reviews";
    }
    else if (this.AnchorType === "Products/Services") {
      this.typeOfAnchor = "#Info";
    }
    if (this.selecteditem != null && this.selecteditem !== "Select") {
      this._profileService.getIcons().subscribe(success => {
        if(success && success.assets && success.assets.asset){
        this.icondata = success.assets.asset;
        this.icondata.forEach(filterObj => {
          if (filterObj.assetName === this.selecteditem) {
            this.assetNumber = filterObj.assetNumber;
          } else {
          }
        });
      } else {
      }
      this.asset = [
        {
          "actionNumber": this.assetNumber,
          "enterpriseId": this.enterpriseId,
          "uri": this.typeOfAnchor,
          "uriType": this.URIType
        }
      ];
      // this.sharedData['assets'] = this.asset;
      this.sharedData['profiles'][this.sharedData.stateParamsId].model["assets"] = this.asset;
      this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[ImagelistComponent] selecteditem1",
        }
      })
      })
    }
  };
  ngAfterViewChecked() {
		if (!(<ViewRef>this.changeDetectorRef).destroyed)
		this.changeDetectorRef.detectChanges();
	}
}
