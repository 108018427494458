import { Component, OnInit } from '@angular/core';

import { ApiService } from '@app/service/api/api.service';
import { FileService } from '@app/service/file/file.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {

  subscription: any;
  sharedData: any;
  attachments = [];
  browseFilesArraylist: any = [];
  browseFiles: any = [];
  filteredFiles: any = [];
  files: any = [];
  currentPage: number = 1;
  sharedCopySheetObj: Photos = new Photos;
  errorMsg: string;
  emptyText: string = 'Add a description';
  showNoAttachments: boolean = false;
  showhide: boolean;
  duplicateAttachment: boolean;
  itemsPerPage: number = 8;

  constructor(private API: ApiService,
    private _file: FileService,
    private attachment: PhotoService,
    private Logger: LoggerService,
    private indicatorQuery: IndicatorQuery) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = { ...data }
    })
    
  }

  ngOnInit() {
    this.subscription = this.attachment.getBehaviorView().subscribe(result => {
      this.browseFiles = result.browseFilesObj
    })
    this.updateFileList();
  }

  change(currentPage) {
    this.currentPage = currentPage;
  }

  search(event) {
    this.filteredFiles = this.browseFilesArraylist;
    if (event) {
      this.filteredFiles = this.filteredFiles.filter((ele, i, array) => {
        let arrayElement = ele.filename.toLowerCase()
        return arrayElement.includes(event)
      })
    }
  }

  getFilePath(file) {
    return this.API.get("/api/file/") + file._id + "/" + file.filename;
  }

  updateFilename(file, data) {
    file.showErr = false
    var fileExtnBefore = file.filename.substring(file.filename.lastIndexOf("."), file.filename.length);
    var fileExtnAfter = data.value.substring(data.value.lastIndexOf("."), data.value.length);
    if (fileExtnBefore === fileExtnAfter) {
      var manageUploadData = file;
      manageUploadData.filename = data.value;
      this._file.put(file._id, manageUploadData).subscribe(() => {

      })
      for (var i = 0; i < this.attachments.length; i++) {
        if (this.attachments[i] === this.getFilePath(file)) {
          this.attachments[i] = this.getFilePath(file).substring(0, this.getFilePath(file).lastIndexOf("/") + 1) + data;
          break;
        }
      }
    } else {
      file.showErr = true;
      this.errorMsg = "Please don't change the extension of file--" + "File extension should be " + fileExtnBefore;
    }
  }

  updateDescription(file, data) {
    file.showErrDesc = false
    var manageUploadData = file;
    if (data.value.length <= 140) {
      manageUploadData.metadata.description = data.value;
      this._file.put(manageUploadData._id, manageUploadData).subscribe(() => {

      });
    } else {
      file.showErrDesc = true;
      this.errorMsg = "Description length exceeded 140 character- Please enter description within 140 character";
    }
  }

  addToAttachments(attachment) {
    this.duplicateAttachment = false;

    for (var i = 0; i < this.attachments.length; i++) {
      if (this.attachments[i] === attachment) {
        this.duplicateAttachment = true;
        break;
      }
    }

    if (!this.duplicateAttachment) {
      this.attachments.push(attachment);
      this.browseFiles = [...this.attachments]
      this.sharedCopySheetObj.attachmentsObj = this.attachments;
      this.sharedCopySheetObj.browseFilesObj = this.browseFiles;
      this.attachment.setBehaviorView(this.sharedCopySheetObj)
      this.updateFileList();
    }
    else {
      this.Logger.logWarning(attachment.substring(attachment.lastIndexOf("/") + 1, attachment.length) + ' already present in attachments');
    }
  }

  updateFileList() {
    this.browseFilesArraylist = []
    this._file.get().subscribe(fileList => {
      this.files = fileList;
      for (let k = 0; k < this.files.length; k++) {
        this.browseFilesArraylist.push(this.files[k]);
      }
      if (this.files === undefined || this.files.length == 0) {
        this.showhide = false;
      }
      else {
        this.showhide = true;
      }
      for (let i = 0; i < this.browseFilesArraylist.length; i++) {
        for (let j = 0; j < this.browseFiles.length; j++) {
          if (this.getFilePath(this.browseFilesArraylist[i]) === this.browseFiles[j]) {
            var index = this.browseFilesArraylist.indexOf(this.browseFilesArraylist[i]);
            if (index > -1) {
              this.browseFilesArraylist.splice(index, 1);
            }
          }
        }
      }
      this.filteredFiles = this.browseFilesArraylist
    }, (err) => {
      if (!(this.filteredFiles.length > 0))
        this.showNoAttachments = true;
      else
        this.showNoAttachments = false;
      console.log(err);
    }, () => {
      if (!(this.filteredFiles.length > 0))
        this.showNoAttachments = true;
      else
        this.showNoAttachments = false;
    })
  }

  myFilesFilter(file) {
    return file.filename.substring(file.filename.lastIndexOf("."), file.filename.length)
      .toLowerCase().match(/^(.jpg|.png|.jpeg|.gif)$/);
  };

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
