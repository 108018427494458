import { Component, OnInit, Inject, Optional } from '@angular/core';
import { UtilsService } from '@app/service/utils/utils.service';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

import { MAT_DIALOG_DATA } from '@angular/material';
import { ParamsService } from '@app/service/params/params.service';
import {PaynowService} from '@app/service/roundProgress/paynow/paynow.service'

@Component({
  selector: 'app-item-input',
  templateUrl: './item-input.component.html',
  styleUrls: ['./item-input.component.scss']
})
export class ItemInputComponent implements OnInit {
  titleContent: any;

  constructor(private _utils: UtilsService,
    private indicatorQuery: IndicatorQuery, 
    private indicatorStore: IndicatorStore,
     @Optional()  @Inject(MAT_DIALOG_DATA) public data: any,
    private param:ParamsService,
    private PaynowService:PaynowService) { }

  id:any;
  sharedData: Quickbpclass;
  urlInput = '';
  isValidURL: any = true;
  options: any;
  schemaObj;
  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data}
    })
    this.schemaObj =this.sharedData['items'][this.sharedData.stateParamsId]['schema']['properties'];
    if (this['layoutNode'].name == "Website") {
      this.titleContent = this.schemaObj['Website']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.Website;
      this.id= "Website"
    }
    if (this['layoutNode'].name == "FacebookPageURL") {
      this.titleContent = this.schemaObj['FacebookPageURL']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.FacebookPageURL;
      this.id= "FacebookPageURL"
    }
    if (this['layoutNode'].name == "TwitterURL") {
      this.titleContent = this.schemaObj['TwitterURL']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.TwitterURL;
      this.id= "TwitterURL"
    }
    if (this['layoutNode'].name == "GooglePlusURL") {
      this.titleContent = this.schemaObj['GooglePlusURL']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.GooglePlusURL;
      this.id= "GooglePlusURL"
    }
    if (this['layoutNode'].name == "LinkedInURL") {
      this.titleContent = this.schemaObj['LinkedInURL']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.LinkedInURL;
      this.id= "LinkedInURL"
    }
    if (this['layoutNode'].name == "PinterestURL") {
      this.titleContent = this.schemaObj['PinterestURL']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.PinterestURL;
      this.id= "PinterestURL"
    }
    if (this['layoutNode'].name == "calltoactionurl") {
      this.titleContent = this.schemaObj['calltoactionurl']['title'];
      this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.calltoactionurl;
      this.id= "calltoactionurl"
    }
    if (this.urlInput != undefined && this.urlInput != null) {
      this.isValidURL = this._utils.validateURL(this.urlInput, true)
      if (this.isValidURL && this.urlInput.length > 200)
        this.isValidURL = false
      else
        this.isValidURL = true
    } else {
      this.isValidURL = true
    }
  }
  sendValue(args) {
    if (args && args != undefined && args != null) {
      this.isValidURL = this._utils.validateURL(args, true);
      if (this.isValidURL && args.length > 200)
        this.isValidURL = false
    } else  {
      this.isValidURL = true
    }
    if (this.isValidURL) {
      if (this['layoutNode'].name === "BusinessWebsiteURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["BusinessWebsiteURL"] = args;
      if (this['layoutNode'].name === "FacebookPageURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["FacebookPageURL"] = args;
      if (this['layoutNode'].name === "TwitterURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["TwitterURL"] = args;
      if (this['layoutNode'].name === "GooglePlusURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["GooglePlusURL"] = args;
      if (this['layoutNode'].name === "LinkedInURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["LinkedInURL"] = args;
      if (this['layoutNode'].name === "PinterestURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["PinterestURL"] = args;
      if (this['layoutNode'].name === "calltoactionurl")
        this.sharedData['items'][this.sharedData.stateParamsId].model["calltoactionurl"] = args;
    } else {
      if (this['layoutNode'].name === "BusinessWebsiteURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["BusinessWebsiteURL"] = '';
      if (this['layoutNode'].name === "FacebookPageURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["FacebookPageURL"] = '';
      if (this['layoutNode'].name === "TwitterURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["TwitterURL"] = '';
      if (this['layoutNode'].name === "GooglePlusURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["GooglePlusURL"] = '';
      if (this['layoutNode'].name === "LinkedInURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["LinkedInURL"] = '';
      if (this['layoutNode'].name === "PinterestURL")
        this.sharedData['items'][this.sharedData.stateParamsId].model["PinterestURL"] = '';
      if (this['layoutNode'].name === "calltoactionurl")
        this.sharedData['items'][this.sharedData.stateParamsId].model["calltoactionurl"] = '';
    }
  }

  
}
