
import { Component, OnInit, ViewChild, NgZone, ChangeDetectorRef, ViewRef } from '@angular/core';
// import { MapsAPILoader } from '@agm/core';
import { GeoService } from 'app/core/service/geo/geo.service';
import { StorageService } from 'app/core/service/storage/storage.service';
import { Campaigndetails } from 'app/core/service/campaigndetails/campaigndetails.service';
import { IndicatorQuery } from 'app/state/query';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { LoggerService } from '@app/service/logger/logger.service';
import { CampaignStore } from 'app/state/campaignstore';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';
import { Constants } from '@app/service/datasource/constants';
import {} from 'googlemaps';

@Component({
  selector: 'app-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.scss']
})
// const Map:google.maps.Map; 
export class GeoComponent implements OnInit {
  latitude: any;
  longitude: any;
  zoom: any;
  address: string;
  private geoCoder: google.maps.Geocoder;
  google: any;
  pointList: { lat: number; lng: number }[] = [];
  selectedArea = 0;
  national: boolean = false;
  local: boolean = false;
  nationalObjects: any = [];
  stateObjects: any = [];
  metroObjects: any = [];
  allStates: any;
  allNationals = [];
  metroNames: any = [];
  geoArray: any = [];
  existingArray: any = [];
  finalArray: any = [];
  masterSelected: boolean = false;
  selectGroup: boolean = false;
  lat: any;
  long: any;
  polygonPathArray: any = [];
  checkedFlag: any = [];
  polygons: any = [];
  polygonPts: any = [];
  Indicator: any;
  campaignModel: CampaignClass
  type: string = ''
  noZipFound: boolean = false;
  isLoaded: boolean = false;
  selectedShape: any;
  map : google.maps.Map
  marker: any;
  @ViewChild('search', { static: false }) search: any;
  @ViewChild('gMap', { static: false }) mapElement: any;
  gMap: google.maps.Map;
  MapPolygon: google.maps.Polygon;
  initMarkerLatLang: any;
  initMarkers: google.maps.Marker[] =[];
  callingOnLoad: boolean = false;
  readonly: boolean = false;
  isZipReaodOnly: boolean = false;
  zipInputModel: any = "";
  private geoCounter = 0;
  constructor(
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private geo: GeoService,
    private storageService: StorageService,
    private Campaigndetails: Campaigndetails,
    private indicatorQuery: IndicatorQuery,
    private campaignQuery: CampaignQuery,
    private cdr: ChangeDetectorRef,
    private Logger: LoggerService,
    private campaignStore: CampaignStore,
    private ngxSpinner: NgxSpinnerService,
    private spinnerTextService: SpinnerService,
    private constant:Constants

  ) { }

  initMap(){
    this.latitude = 44.2126995
    this.longitude = -100.2471641
    this.callingOnLoad = true;
    const mapProperties = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: 8,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.gMap = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    // this.markerObject = [{lat: 44.2126995, lng: -100.2471641}];
    let onInitLatLang: google.maps.LatLngLiteral[] = [
      { lat: 25.774, lng: -80.19 },
      { lat: 18.466, lng: -66.118 },
      { lat: 32.321, lng: -64.757 },
    ];
    // this.drawMarker(this.gMap, this.markerObject);
    this.MapPolygon = new google.maps.Polygon({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#6060FB",
      fillOpacity: 0.35,
    });
    this.drawPoly();
  }

  drawPoly(latLang?){
      if(latLang){
      let mapCenter = new google.maps.LatLng(this.latitude, this.longitude)
      this.gMap.setCenter(mapCenter);
      this.MapPolygon.setPath(latLang);
      this.MapPolygon.setMap(this.gMap);
    }else if(this.callingOnLoad){
      this.callingOnLoad = false;
      this.initMarkerLatLang = {lat: this.latitude, lng: this.longitude};
      this.drawMarker(this.gMap, this.initMarkerLatLang);
    }
  }
  drawMarker(gMap, initMarkerLatLang) {
    const marker = new google.maps.Marker({
      position: initMarkerLatLang,
      map: gMap,
    });
    this.initMarkers.push(marker);
  }
  ngAfterViewInit(){
    if(this.local && !this.noZipFound){
      this.initMap();
    }
  }
  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
      for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
        if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
          break;
        }
      }
    })
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data }
      this.type = this.campaignModel.campaignType;
    })
    if(this.campaignModel.model.GeoList === undefined){
      this.campaignModel.model.GeoList = []
    }
    if (this.type === "Local" || this.type === "LOCAL") {
      // this.marker = {
      //   latitude: 44.2126995,
      //   longitude: -100.2471641
      // }
      // this.mapsAPILoader.load().then(() => {
      //   this.setCurrentLocation();
      //   this.geoCoder = new google.maps.Geocoder;
      // });

    }
    if (this.type === "Local" || this.type === "LOCAL") {
      this.local = true;
      this.national = false;
      this.allStates = [];
      // let zip = this.campaignModel.model.Zip ? this.campaignModel.model.Zip : this.campaignModel.model.CampaignAddressZip;
      // let zip = this.campaignModel.model['targetZip'] ? this.campaignModel.model['targetZip'] : this.campaignModel.model.CampaignAddressZip;
      let zip = this.campaignModel.model['TargetZIPcode'] ? this.campaignModel.model['TargetZIPcode'] : this.campaignModel.model.CampaignAddressZip;
      this.zipInputModel = zip;
      if(!this.campaignModel.model['TargetZIPcode'] || !(this.campaignModel.model['TargetZIPcode'].trim().length > 0)){
       // this.Logger.logError("Target Zip is not available. Source System : Vision");
        this.zipInputModel = this.campaignModel.model['Zip'];
        zip = this.zipInputModel;
      }
        // if(zip && this.campaignModel.model.CampaignAddressZip && this.campaignModel.model.ListingId){
        //   this.isZipReaodOnly = true;
        // } else {
        //   this.isZipReaodOnly = false;
        // }
        if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'edit'){
            if(this.campaignModel.model['CampaignId']){
              this.isZipReaodOnly = true;
              if(!this.campaignModel.model['TargetZIPcode'] || !(this.campaignModel.model['TargetZIPcode'].trim().length > 0)){
                  this.isZipReaodOnly = false;
              }
            if(this.Indicator.items[this.Indicator.stateParamsId] && 
              this.Indicator.items[this.Indicator.stateParamsId].data && 
              this.Indicator.items[this.Indicator.stateParamsId].data.products && 
              this.Indicator.items[this.Indicator.stateParamsId].data.products[0] && 
              this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList && 
              this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList.length > 0){
              for(let i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList.length; i++){
                if((this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList[i]['CampaignId'] == this.campaignModel.model['CampaignId'])){
                  if(!this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList[i]['TargetZIPcode'] || 
                  !(this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList[i]['TargetZIPcode'].trim().length > 0)){
                    this.isZipReaodOnly = false;
                    break;
                  }
                }
              }
            }
            } else {
              if(this.campaignModel.model.ListingId) {
                this.isZipReaodOnly = true;
              } else {
                this.isZipReaodOnly = false;
              }
            }
        } else if(this.campaignModel.selectedCampaign && 
          this.campaignModel.selectedCampaign['modelClicktypes'] && 
          this.campaignModel.selectedCampaign['modelClicktypes'] == 'copy'){
            // this.isZipReaodOnly = false;
            if(this.campaignModel.model.ListingId) {
              this.isZipReaodOnly = true;
            } else {
              this.isZipReaodOnly = false;
            }
        } else {
          if(this.campaignModel.model.ListingId) {
            this.isZipReaodOnly = true;
          } else {
            this.isZipReaodOnly = false;
          }
        }
      if(!this.campaignModel.model['TargetZIPcode'] || !(this.campaignModel.model['TargetZIPcode'].trim().length > 0)){
        this.getGeosByZip();
      }
      else {
      if (!isNaN(zip) && zip.length === 5) {
        this.geo.getByZip("local", zip).subscribe(res => {
          if (res && res.hasWarningOrError == "false") {
            this.local = true;
            this.storageService.get("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version);
            let obj = res.geos.geo;
            obj.forEach((filterObj: any) => {
              this.existingArray.push(filterObj);
            }
            )
            let arrayOfGeoGroups = [];
            for (let i = 0; i < this.existingArray.length; i++) {
              if (arrayOfGeoGroups.indexOf(this.existingArray[i].geoGroup) === -1) {
                arrayOfGeoGroups.push(this.existingArray[i].geoGroup);
              }
            }
            for (let a = 0; a < arrayOfGeoGroups.length; a++) {
              let eachGroup = {
                geoGroupName: arrayOfGeoGroups[a],
                groupElements: []
              };
              for (let j = 0; j < this.existingArray.length; j++) {
                if (this.existingArray[j].geoGroup === eachGroup.geoGroupName) {
                  eachGroup.groupElements.push(this.existingArray[j]);
                }
              }
              this.finalArray.push(eachGroup);
            }
            this.geosProcesserLocal(this.finalArray, this.campaignModel.model.GeoList);
          }
          else {
            let sourceSystem = res.esbServiceFault.sourceSystem;
            let errorDescription = res.esbServiceFault.exceptionDetail[0].errorDescription;
            this.Logger.logError(sourceSystem + ": " + errorDescription);
            this.polygonPathArray = [];
          }
          this.isLoaded = true;
          if (!(<ViewRef>this.cdr).destroyed){
          this.cdr.detectChanges()}
        });

      }
      else {
        this.noZipFound = true
      }
      this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
      }
    }
    else {
      this.storageService.get("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version);
      this.local = false;
      this.national = true;
      //****** get state and metro list
      this.geo.getStateAndMetro("state").subscribe(res => {
        if (res.hasWarningOrError == "false") {
          let obj = res.geos.geo;
          obj.forEach((filterObj: any) => {
            this.stateObjects.push(filterObj);
          }
          )
          this.geosProcesser(this.stateObjects, this.campaignModel.model.GeoList);
        }
        else {
          let sourceSystem = res.esbServiceFault.sourceSystem;
          let errorDescription = res.esbServiceFault.exceptionDetail[0].errorDescription;
          this.Logger.logError(sourceSystem + ": " + errorDescription);
          this.stateObjects = [];
        }
      });
      this.geo.getStateAndMetro("metro").subscribe(res => {
        if (res.hasWarningOrError == "false") {
          let obj = res.geos.geo;
          obj.forEach((filterObj: any) => {
            this.metroObjects.push(filterObj);
          }
          )
          this.geosProcesserMetro(this.metroObjects, this.campaignModel.model.GeoList);
        }
        else {
          let sourceSystem = res.esbServiceFault.sourceSystem;
          let errorDescription = res.esbServiceFault.exceptionDetail[0].errorDescription;
          this.Logger.logError(sourceSystem + ": " + errorDescription);
          this.metroObjects = [];
        }
      });
      this.geo.getStateAndMetro("national").subscribe(res => {
        if (res) {
          let obj = res.geos.geo;
          obj.forEach((filterObj: any) => {
            this.nationalObjects.push(filterObj);
          }
          )
          this.geosProcesserNational(this.nationalObjects, this.campaignModel.model.GeoList);
        }
        else {
          this.nationalObjects = [];
        }
      });
      this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
    }
  }
  geosProcesserLocal(localCityName: any, ngModel: any) {
    if (ngModel === undefined) {
      this.geoCounter = 0;
      if (length >= 0) {
        this.Campaigndetails.clearGeoArray();
        this.finalArray = localCityName;
      }
    } else {
      this.geoCounter = ngModel.length;
      for (let i = 0; i < ngModel.length; i++) {
        for (let j = 0; j < localCityName.length; j++) {
          for (let k = 0; k < localCityName[j].groupElements.length; k++) {
            if (localCityName[j].groupElements[k].geoId === ngModel[i].GeoId) {
              localCityName[j].groupElements[k]["checkboxValue"] = true;
              this.checkedFlag[localCityName[j].groupElements[k].geoId] = true;
              this.getGeoId(localCityName[j].groupElements[k].geoId, localCityName[j].groupElements[k].geoId);
              break;
            }
          }
        }
      }
      this.finalArray = localCityName;
    }
  }
  geosProcesserNational(allNational: any, geoArray: any) {
    this.allNationals = this.nationalObjects;
    if (geoArray === undefined) {
      if (length >= 0) {
        this.Campaigndetails.clearGeoArray();
        this.allNationals = this.nationalObjects;
      }
    } else {
      for (let i = 0; i < geoArray.length; i++) {
        for (let j = 0; j < allNational.length; j++) {
          if (allNational[j].geoId === geoArray[i].GeoId) {
            allNational[j]["checkboxValue"] = true;
            break;
          }
        }
      }
      this.allNationals = allNational;
    }
    if (!(<ViewRef>this.cdr).destroyed){
    this.cdr.detectChanges();}
  }
  geosProcesserMetro(metroNames: string | any[], geoArray: string | any[]) {
    if (geoArray === undefined) {
      if (length >= 0) {
        this.Campaigndetails.clearGeoArray();
        this.metroNames = this.metroObjects;
      }
    } else {
      for (let i = 0; i < geoArray.length; i++) {
        for (let j = 0; j < metroNames.length; j++) {
          if (metroNames[j].geoId === geoArray[i].GeoId) {
            metroNames[j]["checkboxValue"] = true;
            break;
          }
        }
      }
      this.metroNames = metroNames;
    }
    if (!(<ViewRef>this.cdr).destroyed){
    this.cdr.detectChanges()}
  }
  geosProcesser(allstate: any, geoArray: any) {

    if (geoArray === undefined) {
      if (length >= 0) {
        this.Campaigndetails.clearGeoArray();
        this.allStates = this.stateObjects;
      }
    } else {
      for (let i = 0; i < geoArray.length; i++) {
        for (let j = 0; j < allstate.length; j++) {
          if (allstate[j].geoId === geoArray[i].GeoId) {
            allstate[j]["checkboxValue"] = true;
            break;
          }
        }
      }
      this.allStates = allstate;
    }
    if (!(<ViewRef>this.cdr).destroyed){
    this.cdr.detectChanges()}
  }
  showNational(object: any, currentObj: any, e: any) {
    let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    let length = this.allNationals.length;
    for (let j = 0; j < length; j++) {
      if (this.allNationals[j].checkboxValue === true) {
        var modelArea = {
          GeoType: this.allNationals[j].geoType,
          GeoId: this.allNationals[j].geoId,
          GeoName: this.allNationals[j].geoName
        };
        let temp = modelData.GeoList.map((currentValue: { GeoId: any; }, index: any, array: any) => {
          return currentValue.GeoId;
        });
        //to remove duplicates values from ngModel
        if (temp.indexOf(this.allNationals[j].geoId) === -1) {
          modelData.GeoList.push(modelArea)
        }
      }
      else if (this.allNationals[j].checkboxValue === false) {
        //remove unchecked checkbox element from ngModel
        var modelArea = {
          GeoType: this.allNationals[j].geoType,
          GeoId: this.allNationals[j].geoId,
          GeoName: this.allNationals[j].geoName
        };
        let indexValue = modelData.GeoList.findIndex((element: { GeoType: any; GeoId: any; }, index: any, array: any) => {
          if (element.GeoType === this.allNationals[j].geoType && element.GeoId === this.allNationals[j].geoId)
            return true;
        });

        if (indexValue > -1) {
          modelData.GeoList.splice(indexValue, 1)
        }
      }
    }
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.storeUpdate(x)
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
  }
  showState(object: any, currentObj: any, e: any) {
    let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    let length = this.allStates.length;
    for (var j = 0; j < length; j++) {
      if (this.allStates[j].checkboxValue === true) {
        var modelArea = {
          GeoType: this.allStates[j].geoType,
          GeoId: this.allStates[j].geoId,
          GeoName: this.allStates[j].geoName
        };
        //selectedAreas.push(modelArea);
        var temp = modelData.GeoList.map((currentValue, index, array) => {
          return currentValue.GeoId;
        });
        //to remove duplicates values from ngModel
        if (temp.indexOf(this.allStates[j].geoId) === -1) {
          modelData.GeoList.push(modelArea)
        }
      }
      else if (this.allStates[j].checkboxValue === false) {
        var modelArea = {
          GeoType: this.allStates[j].geoType,
          GeoId: this.allStates[j].geoId,
          GeoName: this.allStates[j].geoName
        };
        var indexValue = modelData.GeoList.findIndex((element, index, array) => {
          if (element.GeoType === this.allStates[j].geoType && element.GeoId === this.allStates[j].geoId)
            return true;
        });

        if (indexValue > -1) {
          modelData.GeoList.splice(indexValue, 1)
        }
      }
    }
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.storeUpdate(x)
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
  }
  showMetro(object: any, currentObj: any, e: any) {
    let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    let length = this.metroNames.length;
    for (let j = 0; j < length; j++) {
      if (this.metroNames[j].checkboxValue === true) {
        var modelArea = {
          GeoType: this.metroNames[j].geoType,
          GeoId: this.metroNames[j].geoId,
          GeoName: this.metroNames[j].geoName
        };
        //to remove duplicates values from ngModel
        let temp = modelData.GeoList.map((currentValue: { GeoId: any; }, index: any, array: any) => {
          return currentValue.GeoId;
        });
        //to remove duplicates values from ngModel
        if (temp.indexOf(this.metroNames[j].geoId) === -1) {
          modelData.GeoList.push(modelArea)
        }
      }
      else if (this.metroNames[j].checkboxValue === false) {
        let indexValue = modelData.GeoList.findIndex((element: { GeoType: any; GeoId: any; }, index: any, array: any) => {
          if (element.GeoType === this.metroNames[j].geoType && element.GeoId === this.metroNames[j].geoId)
            return true;
        });

        if (indexValue > -1) {
          modelData.GeoList.splice(indexValue, 1)
        }
      }

    }
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.storeUpdate(x)
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);

  }
  geoListLength:number;
  isMasterLimit:boolean = false;
  get getGeoCounter() {
    if(this.geoCounter <1){
      this.ngxSpinner.hide();
    }else {
      this.ngxSpinner.show();
      this.spinnerTextService.setSpinnerText(this.constant.GET_GEO_DATA);
    }
    return;
  }

  showLocalState(evt: any, object: any) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    // this.geoListLength = modelData.GeoList.length;
    // let tempGeoLength = this.geoListLength;
    let flag = evt.masterSelected;
    // let tempTotalGeoLength = this.geoListLength + evt.groupElements.length;
    // evt.groupElements.forEach((value: any, key: any) => {
    //     value.Selected = flag;
    //     value.checkboxValue = value.Selected;
    //     if (flag === true) {
    //       this.selectGroup = true;
    //       this.getGeoId(value.geoId, key);
    //     } else {
    //       this.selectGroup = false;
    //       this.getGeoId(value.geoId, key);
    //     }
    // });
    this.geoCounter = this.geoCounter + evt.groupElements.length;
    evt.groupElements.forEach((value: any, key: any) => {
      // if(tempGeoLength < 7 && flag === true){
      if(flag === true){
        value.Selected = flag;
        value.checkboxValue = value.Selected;
          this.selectGroup = true;
          // tempGeoLength ++;
          this.getGeoId(value.geoId, key);
       
      }else if(flag === false){
        value.Selected = flag;
        value.checkboxValue = value.Selected;
          this.selectGroup = false;
          // tempGeoLength --;
          this.getGeoId(value.geoId, key);
      }
      // if(tempGeoLength > 7){
      //   this.isMasterLimit = true;
      // }
      // else{
      //   this.isMasterLimit = false;
      // }

  });
    let length = evt.groupElements.length;
    //to push all selected checkboxes to ngModel
    for (let j = 0; j < length; j++) {
      if (evt.groupElements[j].checkboxValue === true) {
        let modelArea = {
          GeoType: evt.groupElements[j].geoType,
          GeoId: evt.groupElements[j].geoId,
          GeoName: evt.groupElements[j].geoName
        };
        if(modelData && modelData.GeoList){
        let temp = modelData.GeoList.map((currentValue: { GeoId: any; }, index: any, array: any) => {
          return currentValue.GeoId;
        });
        //to remove duplicates values from ngModel
        if (temp.indexOf(evt.groupElements[j].geoId) === -1) {
          modelData.GeoList.push(modelArea)
        }
      }
    }
      else if (evt.groupElements[j].checkboxValue === false) {
        //remove unchecked checkbox element from ngModel
        let modelArea = {
          GeoType: evt.groupElements[j].geoType,
          GeoId: evt.groupElements[j].geoId,
          GeoName: evt.groupElements[j].geoName
        };
        let indexValue = modelData.GeoList.findIndex((element: { GeoType: any; GeoId: any; }, index: any, array: any) => {
          if (element.GeoType === evt.groupElements[j].geoType && element.GeoId === evt.groupElements[j].geoId)
            return true;
        });

        if (indexValue > -1) {
          modelData.GeoList.splice(indexValue, 1)
        }
      }
    }

    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.storeUpdate(x)
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
  }
  showCity(city: any, e: any) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    // this.geoListLength = modelData.GeoList.length;
    // if ( this.geoListLength < 7 && city.checkboxValue === true) {
      this.geoCounter= this.geoCounter+1;
    if (city.checkboxValue === true) {
      let modelArea = {
        GeoType: city.geoType,
        GeoId: city.geoId,
        GeoName: city.geoName
      };
      let temp = modelData.GeoList.map((currentValue: { GeoId: any; }, index: any, array: any) => {
        return currentValue.GeoId;
      });

      //to remove duplicates values from ngModel
      if (temp.indexOf(city.geoId) === -1) {
        modelData.GeoList.push(modelArea)
      }
      this.checkedFlag[city.geoId] = true;
      this.getGeoId(city.geoId, city.geoId);
    }
    else if (city.checkboxValue === false) {
      this.checkedFlag[city.geoId] = false;
      this.getGeoId(city.geoId, city.geoId);

      let indexValue = modelData.GeoList.findIndex((element: { GeoType: any; GeoId: any; }, index: any, array: any) => {
        if (element.GeoType === city.geoType && element.GeoId === city.geoId)
          return true;
      });

      if (indexValue > -1) {
        modelData.GeoList.splice(indexValue, 1)
      }
    }
    // if( this.geoListLength >= 7){
    //   if(city.checkboxValue === true){
    //     city.checkboxValue = null;
    //     city.Selected = null;
    //   }
    //   this.isMasterLimit = true;
    // }
    // else{
    //   this.isMasterLimit = false;
    // }
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.storeUpdate(x)
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);

  }
  markerObject: google.maps.LatLngLiteral[] =[];
  getGeoId(id: any, i: any) {
    this.geo.getByGeoId(id).subscribe((res: { hasWarningOrError: string; locations: { latitudes: { latitude: any[]; }[];
      longitudes: { longitude: any[]; }[]; }; }) => {
      this.geoCounter=this.geoCounter-1;
      if (!(<ViewRef>this.cdr).destroyed)
            this.cdr.detectChanges();

      if (res.hasWarningOrError && res.hasWarningOrError === "false") {
        if (res.locations.latitudes) {
          this.latitude = res.locations.latitudes[0].latitude[0];
          this.longitude = res.locations.longitudes[0].longitude[0];
          this.lat = res.locations.latitudes[0].latitude;
          this.long = res.locations.longitudes[0].longitude;
          this.lat.forEach((currentLat: any, index: any) => {
            let mapElement: any = {};
            mapElement['latitude'] = +currentLat;
            mapElement['longitude'] = +this.long[index];
            this.latitude = mapElement.latitude;
            this.longitude = mapElement.longitude;
            if (this.selectGroup === true) {
              this.polygonPathArray.push(mapElement);
            }
            else if (this.selectGroup === false) {
              let indexValue = this.polygonPathArray.findIndex((element: { latitude: any; longitude: any; }, index: any, array: any) => {
                if (element.latitude === mapElement.latitude && element.longitude === mapElement.longitude)
                  return true;
              });
              this.polygonPts = [];
              this.markerObject = [];
              if (indexValue > -1) {
                this.polygonPathArray.splice(indexValue, 1);
              }
            }

            if (this.checkedFlag[i] === true) {
              this.polygonPathArray.push(mapElement);

            }
            else if (this.checkedFlag[i] === false) {
              let indexValue1 = this.polygonPathArray.findIndex((element: { latitude: any; longitude: any; }, index: any, array: any) => {
                if (element.latitude === mapElement.latitude && element.longitude === mapElement.longitude)
                  return true;
              });


              if (indexValue1 > -1) {
                this.polygonPathArray.splice(indexValue1, 1);
              }
            }


          });
          this.polygonPathArray.forEach((element, index) => {
            // this.markerObject.push(
            //   {
            //     lat: element.latitude,
            //     lng: element.longitude
            //   }
            // );
            let ll = new google.maps.LatLng(parseFloat(element.latitude), parseFloat(element.longitude));
            this.polygonPts = [...this.polygonPts, ll];
            this.markerObject = this.polygonPts;
          });
           this.drawPoly(this.markerObject);
          if (!(<ViewRef>this.cdr).destroyed)
            this.cdr.detectChanges();
        } else {
          this.polygonPathArray = [];
        }
      }

    }, ()=>{
      if (!(<ViewRef>this.cdr).destroyed)
            this.cdr.detectChanges();
      this.geoCounter=this.geoCounter-1;
    });
  }
  // Get Current Location Coordinates
  // private setCurrentLocation() {
  //   this.latitude = 44.2126995
  //   this.longitude = -100.2471641
  //   this.zoom = 8;
  // }

  storeUpdate(campaignModel){
    this.campaignStore.update(state => {
      return {
        campaign: campaignModel,
        updatedFrom: '[GeoComponent] storeUpdate'
      }
    })
  }

  getGeosByZip(){
    this.existingArray = [];
    this.finalArray = [];
    let zip = this.zipInputModel;

    if (!isNaN(zip) && zip.length === 5) {
      this.geo.getByZip("local", zip).subscribe(res => {
        if (res && res.hasWarningOrError == "false") {
          this.local = true;
          this.storageService.get("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version);
          let obj = res.geos.geo;
          obj.forEach((filterObj: any) => {
            this.existingArray.push(filterObj);
          }
          )
          let arrayOfGeoGroups = [];
          for (let i = 0; i < this.existingArray.length; i++) {
            if (arrayOfGeoGroups.indexOf(this.existingArray[i].geoGroup) === -1) {
              arrayOfGeoGroups.push(this.existingArray[i].geoGroup);
            }
          }
          for (let a = 0; a < arrayOfGeoGroups.length; a++) {
            let eachGroup = {
              geoGroupName: arrayOfGeoGroups[a],
              groupElements: []
            };
            for (let j = 0; j < this.existingArray.length; j++) {
              if (this.existingArray[j].geoGroup === eachGroup.geoGroupName) {
                eachGroup.groupElements.push(this.existingArray[j]);
              }
            }
            this.finalArray.push(eachGroup);
          }
          this.updateCampaignModelGeoList();
          let campaignModelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
          this.geosProcesserLocal(this.finalArray, campaignModelData.GeoList);
          this.updateTargetZipIndicator(zip);
          this.initMap();

        }
        else {
          let sourceSystem = res.esbServiceFault.sourceSystem;
          let errorDescription = res.esbServiceFault.exceptionDetail[0].errorDescription;
          this.Logger.logError(sourceSystem + ": " + errorDescription);
          this.polygonPathArray = [];
        }
        this.isLoaded = true;
        if (!(<ViewRef>this.cdr).destroyed){
        this.cdr.detectChanges()}
      });

    }
    else {
      this.noZipFound = true
    }
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
  }
  updateTargetZipIndicator(targetZip){
    let modelData=  JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    modelData.TargetZIPcode = targetZip;

    let x = JSON.parse(JSON.stringify(this.campaignModel))
      x.model = modelData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[geo component] updateTargetZipIndicator'
        }
      })
  }
  updateCampaignModelGeoList(){
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    
    if(modelData.GeoList){
      modelData.GeoList = [];
    }
  
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.storeUpdate(x)
    this.storageService.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model);
  }
}