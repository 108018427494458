import { Component, Inject, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '@app/service/api/api.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { FieldcountService } from '@app/service/fieldcount/fieldcount.service';
import { FileService } from '@app/service/file/file.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { ParamsService } from '@app/service/params/params.service';
import { PdfAnnotateService } from '@app/service/pdf-annotate.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@app/service/datasource/constants';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CallconfirmationComponent } from '../call-new/callconfirmation/callconfirmation.component';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { CopysheetalertComponent } from '../copysheetalert/copysheetalert.component';


@Component({
  selector: 'app-copysheet-annotate',
  templateUrl: './copysheet-annotate.component.html',
  styleUrls: ['./copysheet-annotate.component.scss']
})
export class CopysheetAnnotateComponent implements OnInit, OnDestroy {

  fontSize: String;
  color: String;
  errorURL: boolean = true;
  Indicator: any;
  fontsizes = [{ "key": "2px", "value": "2" }, { "key": "5px", "value": "5" }, { "key": "7px", "value": "7" }, { "key": "8px", "value": "8" }, { "key": "9px", "value": "9" }, { "key": "12px", "value": "12" }];
  colors = [{ "key": "Red", "value": "#FF0000" }, { "key": "Green", "value": "#00FF00" }, { "key": "Blue", "value": "#0000FF" }, { "key": "Black", "value": "#000000" }, { "key": "White", "value": "#FFFFF" }];
  instnace: any;
  oldRef: any;
  storageID: any;
  emptyClassId: any;
  spinnerText: string;
  result: string = '';
  applyFlag: boolean = false;
  isShowDropZone: boolean = false;
  dragAreaClass: string;

  constructor(private pdfAnnotateService: PdfAnnotateService,
    private indicatorQuery: IndicatorQuery,
    private storageService: StorageService,
    private Logger: LoggerService,
    private Copysheet: CopysheetService,
    private params: ParamsService,
    private file: FileService,
    private requiredFieldCount: FieldcountService,
    private indicatorStore: IndicatorStore,
    private api: ApiService,
    private ngxspinner: NgxSpinnerService,
    private ConstantText: Constants,
    private matDialog: MatDialog,
    private counterService: CounterService,
    private requiredfieldcountservice: RequiredfieldcountService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    console.log("gragover");
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.isShowDropZone = true;
    console.log("dragenter");
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    console.log("dragend");
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    console.log("gragdragleaveover");
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    console.log("drop");
    const element = document.querySelector('#imageupload');
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      element['files'] = files;
      element.dispatchEvent(new Event('change'));
      // this.saveFiles(files);
    }
    this.isShowDropZone = false;
  }

  ngOnInit(): void {
    this.oldRef = this.data.dialogform.dilogReference
  }

  public ngAfterViewInit(): void {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
    });
    if (!this.Indicator.stateParamsId || (this.Indicator.stateParamsId != this.data.stateParmId &&  this.data.stateParmId !=null && this.data.stateParmId != undefined && this.data.stateParmId != '')) {
      this.Indicator.stateParamsId = this.data.stateParmId;
    }
    let localFormat
    try {
      let tempCopysheet = this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet;
      localFormat = tempCopysheet.slice(tempCopysheet.lastIndexOf(".") + 1, tempCopysheet.length);
    } catch (e) {
      localFormat = 'pdf';
    }
    localFormat = localFormat =='undefined' ? 'pdf': localFormat;
    this.instnace = this.pdfAnnotateService.getCanvasObject(localFormat);

    this.instnace['options']['toolsObj']['pencil'] = document.getElementById('pencil');
    this.instnace['options']['toolsObj']['canvasContainer'] = document.getElementById('canvas-container');
    this.instnace['options']['toolsObj']['canvas'] = document.getElementById('canvas');

    this.instnace['options']['toolsObj']['loading'] = document.getElementById('loading');
    this.instnace['options']['toolsObj']['square'] = document.getElementById('square');
    this.instnace['options']['toolsObj']['circle'] = document.getElementById('circle');
    this.instnace['options']['toolsObj']['ellipse'] = document.getElementById('ellipse');
    this.instnace['options']['toolsObj']['text'] = document.getElementById('text');
    this.instnace['options']['toolsObj']['contenteditor'] = document.getElementById('contenteditor');
    this.instnace['options']['toolsObj']['editor_wrapper'] = document.getElementById('editor_wrapper');
    this.instnace['options']['toolsObj']['arrow'] = document.getElementById('arrow');
    this.instnace['options']['toolsObj']['line'] = document.getElementById('line');
    this.instnace['options']['toolsObj']['imageupload'] = document.getElementById('imageupload');
    this.instnace['options']['toolsObj']['clear_image'] = document.getElementById('clear_image');
    this.instnace['options']['toolsObj']['frm_canvas_tool'] = document.getElementById('frm_canvas_tool');
    this.instnace['options']['toolsObj']['undo'] = document.getElementById('undo');
    this.instnace['options']['toolsObj']['redo'] = document.getElementById('redo');
    this.instnace['options']['toolsObj']['save'] = document.getElementById('save');
    this.instnace['options']['toolsObj']['fontsize'] = document.getElementById('fontsize');
    this.instnace['options']['toolsObj']['fillstyle'] = document.getElementById('fillstyle');
    this.instnace['options']['toolsObj']['linewidth'] = document.getElementById('linewidth');
    this.instnace['options']['toolsObj']['prevBtn'] = document.getElementById('prevBtn');
    this.instnace['options']['toolsObj']['nextBtn'] = document.getElementById('nextBtn');
    this.instnace['options']['toolsObj']['activePage'] = document.getElementById('activePage');
    this.instnace['options']['toolsObj']['currentPage'] = document.getElementById('currentPage');
    this.instnace['options']['toolsObj']['totalPage'] = document.getElementById('totalPage');
    this.instnace['options']['toolsObj']['close'] = document.getElementById('close');

    this.instnace['history']['initial_canvas_url'] = [];
    this.instnace['history']['final_canvas_url'] = [];
    this.instnace['history']['final_canvas_url'] = [];
    this.instnace['history']['history.redo_list'] = [];
    this.instnace['history']['undo_list'] = [];
    this.instnace['history']['raw_undo_list'] = [];
    this.instnace['history']['raw_redo_list'] = [];
    this.instnace['history']['raw_undo_ver_list'] = [];
    this.instnace['history']['raw_redo_ver_list'] = [];
    this.instnace['history']['tmp_raw_undo_list'] =  [];

    /* Need to add actionListObj on top of baseCopysheet while initializing the canvas object */
    if ( this.Indicator.items[this.Indicator.stateParamsId].hasOwnProperty("actionListObj") && this.Indicator.items[this.Indicator.stateParamsId].actionListObj != undefined && this.Indicator.items[this.Indicator.stateParamsId].actionListObj !=null && this.Indicator.items[this.Indicator.stateParamsId].actionListObj.length >0 ){
      this.instnace['options']['actionListObj'] = this.Indicator.items[this.Indicator.stateParamsId].actionListObj;
      this.instnace['history'].raw_undo_list = this.Indicator.items[this.Indicator.stateParamsId].actionListObj;
    }
    /*
    commented this piece of code for image movement
    if (this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet)
      this.instnace['renderPDF'](
        this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet, localFormat,
        this.instnace['options'].toolsObj.canvasContainer);
    else
    */
      this.instnace['renderPDF'](this.Indicator['items'][this.Indicator.stateParamsId].baseCopysheet, localFormat, this.instnace['options'].toolsObj.canvasContainer);
      this.errorURL = false;
  }
  ngOnDestroy() {
    //Remove stateparamsID valuse after coming out of item page
    /*
    this.indicatorQuery.getIndicator().subscribe((res) => {
    let updatedIndVal =JSON.parse(JSON.stringify(res));
    updatedIndVal.stateParamsId = "";
    this.indicatorStore.update((state) => {
      return {
        indicator: updatedIndVal,
        isloaded: true,
      };
    });
  });
  */
  }
  save(buttonName) {
    if (buttonName === 'saveandclose') {
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.Indicator = JSON.parse(JSON.stringify(data));
      });
      try {
        let headingOk = 'Selecting "OK" will send additional Copysheet transaction to Graphics whether changes have occurred or not!';
        let headingCancel = 'Selecting "CANCEL" will not send any additional Copysheet transaction to Graphics whether changes have occurred or not!';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'call-tracking-confirmation-dialog';
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          headingOk: headingOk,
          headingCancel: headingCancel,
        }
        dialogConfig.width = '910px';
        const dialogRef = this.matDialog.open(CopysheetalertComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.ngxspinner.show();
            this.saveAnnotation(
              this.instnace['event'].savepdf('saveandclose'),
              buttonName,
              this.instnace['history'].raw_undo_list,
              this.instnace['history'].deletedAttachmentArr);
          } else {
            this.discardCopysheetChanges();
            this.oldRef.close();
          }
        })
      } catch (e) {
        this.ngxspinner.hide();
        console.log(e, " exception in the save method");
      }
    } else{
      this.ngxspinner.show();
      this.spinnerText = this.ConstantText.COPYSHEET_SAVE;
      this.indicatorQuery.getIndicator().subscribe(data => {
          this.Indicator = JSON.parse(JSON.stringify(data));
      });
      setTimeout(() => {
        try {
          this.saveAnnotation(
            this.instnace['event'].savepdf('saveandclose'),
            buttonName,
            this.instnace['history'].raw_undo_list,
            this.instnace['history'].deletedAttachmentArr);
        } catch (e) {
          this.ngxspinner.hide();
          console.log(e, " exception in the save method");
        }
      }, 0);
  
    }
  }
  textAreaAdjust(t) {
    t.style.height = "1px",
      t.style.height = 15 + t.scrollHeight + "px"
  }
  closePopUp() {
    let msg = 'Are you sure you want to discard all your changes? ';
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'confirmaton-dialog';
    dialogConfig.data = {
      message: msg,
    }
    dialogConfig.width = '600px';
    const dialogRef = this.matDialog.open(CallconfirmationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      this.ngxspinner.hide();
      if (this.result) {
        this.discardCopysheetChanges()
        this.applyFlag ? this.oldRef.close(true) : this.oldRef.close(false);
      }
    });

  }

  saveAnnotation(blob, flag, actionListObj, deletedAttachment) {
    console.log(" into save annotate method ::::: ");    
    let fd = new FormData();
    let fileName = "";
    this.params['saving'] = true;
    this.params['loadingbar'] = true;
    let attachmentURL = [];

    if (this.Indicator.items[this.Indicator.stateParamsId].copy && this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet != null) {
      fileName = this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet.slice(this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet.lastIndexOf("/") + 1, this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet.lastIndexOf("."));
      var isnum = /^\d+$/.test(fileName);
      if (!isnum) {
        fileName = Math.round(Math.random() * 1000000000000) + "";
      }
    } else {
      fileName = Math.round(Math.random() * 1000000000000) + "";
    }

    fd.append("files", blob, fileName + '.' + this.Indicator.items[this.Indicator.stateParamsId].copy.fileType);
    fd.append("user", JSON.parse(JSON.stringify({ parameters: { accountId: this.Indicator.order.accountId, svOrderId: this.Indicator.order.orderId } })));
    // json object has benn stringified as the append method only accepts 2 values ( string , blob )
    console.log(this.Indicator.order.accountId, " account id ");
    console.log(JSON.parse(JSON.stringify({ parameters: { accountId: this.Indicator.order.accountId, svOrderId: this.Indicator.order.orderId } })), " testing the user param");

    this.file.savePdf(fd).then(resp => {      
      let copysheet;
      // this.indicatorQuery.getIndicator().subscribe(data => {
      //   this.Indicator = JSON.parse(JSON.stringify(data));
      // });
      //this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
      this.Indicator.items[this.Indicator.stateParamsId].isCoysheetSubmitted = true;
      for (let key in actionListObj) {
        for (let itemKey in actionListObj[key]) {
          if (actionListObj[key][itemKey] && (actionListObj[key][itemKey].name === 'image') && actionListObj[key][itemKey].attachmentURL) {
            attachmentURL.push(actionListObj[key][itemKey].attachmentURL);
          }
        }
      }
      if (!this.Indicator.items[this.Indicator.stateParamsId].copy.hasOwnProperty('attachments')) {
        this.Indicator.items[this.Indicator.stateParamsId].copy.attachments = attachmentURL;
      }
      else {
        for (let key in attachmentURL) {
          if (this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.indexOf(attachmentURL[key]) === -1) {
            this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.push(attachmentURL[key]);
          }
        }
      }
      if (deletedAttachment.length) {
        let index;
        for (let key in deletedAttachment) {
          index = this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.indexOf(deletedAttachment[key]);
          if (index !== -1) {
            this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.splice(index, 1);
          }
        }
      }

      this.storageService.updateField("copyz", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, { "data.isCoysheetSubmitted": true })
        .subscribe(() => { }, err => { })

      if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
        this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = true;
      }
      var oldCopysheet = (this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet == true) ? this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet : null;
      this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = resp['copysheet'];
      copysheet = resp['copysheet'];
      this.Indicator.items[this.Indicator.stateParamsId].copy.fileType = copysheet.slice(copysheet.lastIndexOf(".") + 1, copysheet.length);
      this.Copysheet.updateCopysheet(this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].copy)
        .then(response => { 
          console.log('******response is!!!!!',response);
          var activityLogHistoryObj = {
            'displayName': this.Indicator.user.name,
            'email': this.Indicator.user.email,
            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
            'itemId': this.Indicator.stateParamsId,
            'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
            'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
            'fieldName': 'Copysheet Value',
            'fieldOldValue': oldCopysheet,
            'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet,
            'apiStatus' : 'Success'
          }
          this.storageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
        })
        .catch(err => {
          console.log("Failed to update copysheet :" + JSON.stringify(err));
          var activityLogHistoryObj = {
            'displayName': this.Indicator.user.name,
            'email': this.Indicator.user.email,
            'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: true }),
            'itemId': this.Indicator.stateParamsId,
            'itemStatus': this.Indicator.items[this.Indicator.stateParamsId].order.status,
            'itemActionCode':this.Indicator.items[this.Indicator.stateParamsId].order.actionCode + "-" + this.Indicator.items[this.Indicator.stateParamsId].order.actionType,
            'fieldName': 'Copysheet Value',
            'fieldOldValue': oldCopysheet,
            'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet,
            'apiStatus' : 'Failure'
          }
          this.storageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
        });
        
      if(flag == 'saveandclose'){
        this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = true;
      }
      this.Indicator.items[this.Indicator.stateParamsId].actionListObj = actionListObj;
      this.storageID = this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version;
      this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": true, "data.isCorrupted": false, "data.actionListObj": actionListObj, "data.baseCopysheet": this.Indicator.items[this.Indicator.stateParamsId].baseCopysheet })
        .subscribe(() => { }, err => { })
      if (this.Indicator.items[this.Indicator.stateParamsId].hasOwnProperty("generatedCopysheet") && !this.Indicator.items[this.Indicator.stateParamsId]['generatedCopysheet'] ){
          this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet = true;
          this.storageService.updateField("copyz", this.storageID, { "data.generatedCopysheet": true })
            .subscribe(() => { }, err => { console.log(" Error in update filed service "); });
      }
      this.Logger.logSuccess('Copysheet uploaded successfully.');
      this.params['saving'] = false;
      this.params['loadingbar'] = false;
      this.Indicator.items[this.Indicator.stateParamsId].isCoysheetAnnotated = true;      
      if (flag === 'saveandclose') {
        this.oldRef.close(true);
      } else this.applyFlag = true;

      if (this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet && typeof this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet === "object") {
        this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.manageCopyModified = true;
        this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
      } else {
        this.Indicator.items[this.Indicator.stateParamsId].model.manageCopyModified = true;
        this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet = 'Y';
      }
      this.Indicator.items[this.Indicator.stateParamsId].isCopysheetSaved = true;
      this.storageService.updateField("copyz", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, { "data.isCopysheetSaved": true })
        .subscribe(() => { }, err => { })
      // var activityLogHistoryObj = {
      //   'displayName': this.Indicator.user.name,
      //   'email': this.Indicator.user.email,
      //   'date': new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', hour12: false }),
      //   'itemId': this.Indicator.stateParamsId,
      //   'fieldName': 'Copysheet Value',
      //   'fieldOldValue': oldCopysheet,
      //   'fieldNewValue': this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet
      // }
      // this.storageService.update("itemHistory", this.Indicator.stateParamsId, activityLogHistoryObj);
      //this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';

      this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = true;
      //set dirtyFlag and persist it
      this.Indicator.items[this.Indicator.stateParamsId].model.dirtyFlag = true;
      //this.Indicator.items[this.Indicator.stateParamsId].model['test'] = 'test';
      if (this.Indicator.items[this.Indicator.stateParamsId].requiresCopysheet) {
        let stateParamsId = this.Indicator.stateParamsId;
        let tempData = this.requiredfieldcountservice.getNewItemCount(this.Indicator["items"][stateParamsId].requiredFields, this.Indicator["items"][stateParamsId].model, this.Indicator["items"][stateParamsId].order, this.Indicator["orderPercent"], this.Indicator["items"][stateParamsId].counter, true, this.Indicator["items"][stateParamsId]);
        this.updateIndictor(this.Indicator.stateParamsId, this.Indicator, tempData, this.Indicator.items[this.Indicator.stateParamsId].counter);
        try {
          if (document.getElementById(stateParamsId + 'counter')) {
            if (this.Indicator['items'][stateParamsId].counter == 0) {
              document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
            } else if (this.Indicator['items'][stateParamsId].counter != 0) {
              document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
            }
          }
        } catch (e) {
          console.log(e, " exception in the class counter");
        }
      }
      this.updatCounterStore(this.Indicator);
      this.storageService.update("item",
        this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version,
        this.Indicator.items[this.Indicator.stateParamsId].model)
        .then(res => { }).catch(err => { });
      this.ngxspinner.hide();
      // this.Logger.logError("You must Save the item to send the Copy Sheet/Attachment/Ad Instructions to graphics")
    }).catch(err => {
      // this.oldRef.close();
      console.log('error:', err);
      this.ngxspinner.hide();
      this.Logger.logError('Error while saving copysheet.');
    })
  }

  updateIAGroupCounter(itemID) {    
    var count = this.requiredFieldCount.get(this.Indicator.items[itemID].schema, this.Indicator.items[itemID].form, this.Indicator.items[itemID].model, this.Indicator.items[itemID].order);
    this.Indicator.items[itemID].counter = count;

    if (this.Indicator.items[itemID].requiresCopysheet
      && !this.Indicator.items[itemID].hasCopysheet) {
      this.Indicator.items[itemID].counter++;
    }
    this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[CopysheetAnnotateComponent] updateIAGroupCounter"
      }
    })
  }
  //Below update the counter code
  private updateIndictor(itemId, data, tempIndicator, currentCount) {    
    let counter = [];
    console.log('update Indicator');
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcountservice.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    this.updatCounterStore(data);
  }
  private updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
  }

  private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
  }
  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }

  toggleClass(e) {
    if (this.emptyClassId) {
      document.getElementById(this.emptyClassId).classList.remove('current-active');
      this.emptyClassId = e.target.id;
      document.getElementById(this.emptyClassId).classList.add('current-active');
    } else {
      this.emptyClassId = e.target.id;
      document.getElementById(this.emptyClassId).classList.add('current-active');
    }
  }
  fileN: any = '';
  onChange(event) {  
    let name =(event.path[0] && event.path[0].files[0] && event.path[0].files[0].name) ? event.path[0].files[0].name: '';
    let fileExtension = name ? name.split('.').pop() : '';
    if (fileExtension){
      let allowedExtensions = ['jpg','jpeg', 'png','gif'];
      if (!allowedExtensions.includes(fileExtension.toLowerCase())){
        this.Logger.logError('Please select a valid file type (JPG, JPEG, PNG, GIF).');
        return;
      }
    }
    if (name && name.length > 15) {
      let firstPart = name.slice(0, 8);
      let secondPart = name.slice(name.length - 5, name.length);
      this.fileN = firstPart + '...' + secondPart;
    } else {
      this.fileN = name;
    }
  }

  saveAttachment = function (imageBlobObj, callback) {
    this.file.saveImageToPromise(this.Indicator.stateParamsId, { 'data': imageBlobObj.imageBlobObj }, "attachment")
      .then((response) => {
        let attachmentUrl = this.api.get('/api/file/' + response._id + '/' + response.filename);
        console.log(" attachment url generated is : ", attachmentUrl);
        callback(attachmentUrl);
      }).catch(e => {
        console.log(" error in saveImageToPromise : " + e);
      })
  };

  discardCopysheetChanges(){
    this.Indicator.items[this.Indicator.stateParamsId].copy.copysheet = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.copysheetSavePoint
    this.Indicator.items[this.Indicator.stateParamsId].copy.attachments = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.attachments
    this.Indicator.items[this.Indicator.stateParamsId].actionListObj = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.actionListObj
    this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.ManageCopysheet
    this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.hasCopysheet
    this.Indicator.items[this.Indicator.stateParamsId].isCorrupted = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCorrupted
    this.Indicator.items[this.Indicator.stateParamsId].isCoysheetSubmitted = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted
    this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.generatedCopysheet
    this.Indicator.items[this.Indicator.stateParamsId].isCopysheetSaved = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.isCopysheetSaved
    this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = this.Indicator.items[this.Indicator.stateParamsId].model.copySheetSavePoints.needsNewCopysheet

    this.Copysheet.updateCopysheet(this.storageID, this.Indicator.items[this.Indicator.stateParamsId].copy)
    .then(() => { }).catch((err) => {
      this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
    });
    this.storageService.updateField("copyz", this.storageID, 
    {"data.actionListObj": this.Indicator.items[this.Indicator.stateParamsId].model.actionListObj,
     "data.hasCopysheet": this.Indicator.items[this.Indicator.stateParamsId].hasCopysheet,
     "data.isCorrupted": this.Indicator.items[this.Indicator.stateParamsId].isCorrupted,
     "data.isCoysheetSubmitted": this.Indicator.items[this.Indicator.stateParamsId].isCoysheetSubmitted,
     "data.generatedCopysheet": this.Indicator.items[this.Indicator.stateParamsId].generatedCopysheet,
     "data.isCopysheetSaved": this.Indicator.items[this.Indicator.stateParamsId].isCopysheetSaved,
     "data.needsNewCopysheet": this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet 
    })
    .subscribe(() => { }, err => { })
    if (this.Indicator.items[this.Indicator.stateParamsId].requiresCopysheet) {
      let stateParamsId = this.Indicator.stateParamsId;
      let tempData = this.requiredfieldcountservice.getNewItemCount(this.Indicator["items"][stateParamsId].requiredFields, this.Indicator["items"][stateParamsId].model, this.Indicator["items"][stateParamsId].order, this.Indicator["orderPercent"], this.Indicator["items"][stateParamsId].counter, true, this.Indicator["items"][stateParamsId]);
      this.updateIndictor(this.Indicator.stateParamsId, this.Indicator, tempData, this.Indicator.items[this.Indicator.stateParamsId].counter);
      try {
        if (document.getElementById(stateParamsId + 'counter')) {
          if (this.Indicator['items'][stateParamsId].counter == 0) {
            document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
          } else if (this.Indicator['items'][stateParamsId].counter != 0) {
            document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
          }
        }
      } catch (e) {
        console.log(e, " exception in the class counter");
      }
    }
    this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[CopysheetAnnotateComponent] discardCopysheetchanges"
      }
    })

  }

}
