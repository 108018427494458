import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SetbaseService {
    PATH: string = '/api/setbase';
    constructor(private API: ApiService, private http: HttpClient) { }
    headers = new HttpHeaders()
    /**
    * getByAccountIdOrderId - get based on account and order id
    * @param  {[type]} id [description]
    * @return {[type]}    [description]
    */
    get(accountId: string, svOrderId: string): Promise<any> {
        let fullPath = this.API.get(this.PATH) + "/" + accountId + "/" + svOrderId ;
        console.log("fullPath::" + fullPath);
        return this.http.get(fullPath).toPromise();
    };
    setBaseImagesave(accountId: string, svOrderId: string, images): Promise<any> {
        let requestbody = {};
        requestbody['images']=images;
        let apipath = this.API.get(this.PATH) + "/" + accountId + "/" + svOrderId ;
        //console.log("APIpath:"+apipath);
        return this.http.post(apipath,requestbody).toPromise();          
    }
}