import { Component, OnInit, Inject, Optional } from '@angular/core';
import { UtilsService } from '@app/service/utils/utils.service';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

import { MAT_DIALOG_DATA } from '@angular/material';
import { ParamsService } from '@app/service/params/params.service';
import {PaynowService} from '@app/service/roundProgress/paynow/paynow.service'

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  titleContent: any;

  constructor(private _utils: UtilsService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
     @Optional()  @Inject(MAT_DIALOG_DATA) public data: any,
    private param:ParamsService,
    private PaynowService:PaynowService) { }

  id:any;
  sharedData: Quickbpclass;
  urlInput = '';
  isValidURL: any = true;
  options: any;
  requiredField: boolean = false;
  maxLength;
  errorMsg;
  readOnly: boolean = false;
  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data))
    })
    if (this['layoutNode'].name == "BusinessWebsiteURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['BusinessWebsiteURL']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessWebsiteURL;
      this.id= "BusinessWebsiteURL"
    }
    if (this['layoutNode'].name == "FacebookPageURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['FacebookPageURL']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.FacebookPageURL;
      this.id= "FacebookPageURL"
    }
    if (this['layoutNode'].name == "TwitterURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['TwitterURL']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.TwitterURL;
      this.id= "TwitterURL"
    }
    if (this['layoutNode'].name == "GooglePlusURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['GooglePlusURL']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.GooglePlusURL;
      this.id= "GooglePlusURL"
    }
    if (this['layoutNode'].name == "LinkedInURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['LinkedInURL']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.LinkedInURL;
      this.id= "LinkedInURL"
    }
    if (this['layoutNode'].name == "PinterestURL") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['PinterestURL']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.PinterestURL;
      this.id= "PinterestURL"
    }
    if (this['layoutNode'].name == "calltoactionurl") {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][1]['properties']['calltoactionurl']['title'];
      this.urlInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.calltoactionurl;
      this.id= "calltoactionurl"
    }
    if (this['layoutNode'].name == "WebsiteURL") {
      this.readOnly = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name].readonly

      if (this.sharedData['items'][this.sharedData.stateParamsId].order.UDAC == "PRINTOLN"){
        let selectedBP = this.sharedData['items'][this.sharedData.stateParamsId].model.BusinessProfile || this.sharedData['items'][this.sharedData.stateParamsId].order.listingId;
        this.titleContent = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name]['title'];
        this.requiredField = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name]['required'];
       // this.urlInput = selectedBP ? this.sharedData['profiles'][selectedBP].model.BusinessWebsiteURL || this.sharedData['profiles'][selectedBP].data.businessProfiles.businessProfile.genericData.url._content_ : '';
       this.maxLength = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name]['maxLength'];
       this.urlInput = selectedBP ? this.sharedData['profiles'][selectedBP].model.BusinessWebsiteURL : '';
       this.id= "WebsiteURL";
        let  tempObj =JSON.parse(JSON.stringify(this.sharedData));
        tempObj.items[this.sharedData.stateParamsId].model.WebsiteURL = this.urlInput;
        this.indicatorStore.update(state => {
            return {
              indicator: tempObj,
              updatedFrom: "[InputComponent] ngOnInit",
              isloaded: true
            }
          });
      }else{
        this.titleContent = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name]['title'];
        this.requiredField = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name]['required'];
        this.maxLength = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name]['maxLength'];
        this.urlInput = this.sharedData['items'][this.sharedData.stateParamsId].model.WebsiteURL;
        this.id= "WebsiteURL"
      }

    }
    if (this.urlInput != undefined && this.urlInput != null) {
      this.isValidURL = this._utils.validateURL(this.urlInput, true)
      if (this.isValidURL && this.urlInput.length > 200)
        this.isValidURL = false
      else
        this.isValidURL = true
    } else {
      this.isValidURL = true
    }
  }
  sendValue(args) {
    let modelData;
    if(this['layoutNode'].name === "WebsiteURL")
      modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    else modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));

    if (args && args != undefined && args != null) {
      this.isValidURL = this._utils.validateURL(args, true);
      if (this.isValidURL && args.length > 200)
        this.isValidURL = false
    } else  {
      this.isValidURL = true
    }
    this.sharedData = JSON.parse(JSON.stringify(this.sharedData))
    if (this.isValidURL) {
      if (this['layoutNode'].name === "BusinessWebsiteURL")
        modelData["BusinessWebsiteURL"] = args;
      if (this['layoutNode'].name === "FacebookPageURL")
        modelData["FacebookPageURL"] = args;
      if (this['layoutNode'].name === "TwitterURL")
        modelData["TwitterURL"] = args;
      if (this['layoutNode'].name === "GooglePlusURL")
        modelData["GooglePlusURL"] = args;
      if (this['layoutNode'].name === "LinkedInURL")
        modelData["LinkedInURL"] = args;
      if (this['layoutNode'].name === "PinterestURL")
        modelData["PinterestURL"] = args;
      if (this['layoutNode'].name === "calltoactionurl")
        modelData["calltoactionurl"] = args;
      if (this['layoutNode'].name === "WebsiteURL") {
        if(args.length <= this.maxLength){
        modelData['items'][this.sharedData.stateParamsId].model["WebsiteURL"] = args;
        this.errorMsg = '';
        }
        else {
          modelData['items'][this.sharedData.stateParamsId].model["WebsiteURL"] = '';
          this.errorMsg = 'Invalid URL';
        }
      }
    } else {
      if (this['layoutNode'].name === "BusinessWebsiteURL")
        modelData["BusinessWebsiteURL"] = '';
      if (this['layoutNode'].name === "FacebookPageURL")
        modelData["FacebookPageURL"] = '';
      if (this['layoutNode'].name === "TwitterURL")
        modelData["TwitterURL"] = '';
      if (this['layoutNode'].name === "GooglePlusURL")
        modelData["GooglePlusURL"] = '';
      if (this['layoutNode'].name === "LinkedInURL")
        modelData["LinkedInURL"] = '';
      if (this['layoutNode'].name === "PinterestURL")
        modelData["PinterestURL"] = '';
      if (this['layoutNode'].name === "calltoactionurl")
        modelData["calltoactionurl"] = '';
      if (this['layoutNode'].name === "WebsiteURL"){
        modelData['items'][this.sharedData.stateParamsId].model["WebsiteURL"] = '';
        this.errorMsg = 'Invalid URL';
      }
    }
    let x = JSON.parse(JSON.stringify(this.sharedData));
    if(this['layoutNode'].name === "WebsiteURL")
      x = modelData;
    else x['profiles'][this.sharedData.stateParamsId].model = modelData;
    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[InputComponent] sendValue",
      }
    })
  }


}
