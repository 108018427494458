import { Component, DebugNode, OnInit } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import {billingBasisObj,BILLINGBASISOBJPRINT,omitAddressObj,coOPTypeObj,callTypeDigitalObj,callTypePrintObj,BESTTIMEOBJ} from 'app/data/constants';
import { ComponentStore } from 'app/state/component.state';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CallconfirmationComponent } from '../../call-new/callconfirmation/callconfirmation.component';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  options;
  customField;
  id;
  isReadonly :boolean =false;
  titleContent: string;
  sharedData;
  hidden: boolean;
  isRequiredField :boolean =false;
  schemaObj;

  constructor(private componnentStore: ComponentStore,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private indicator:IndicatorQuery,
    private matDialog : MatDialog ) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
      this.schemaObj=this.sharedData.items[this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name];
      this.isRequiredField = this.schemaObj.required;
      if(this['layoutNode'].name !== "calltrackingtype"){
        this.titleContent =  this.schemaObj.title;
       this.isReadonly = this.schemaObj .readonly;
      }

      if (this['layoutNode'].name == 'BillingBasis') {  
        let billingBasisPrint =[
          {"value":"M","name":"Monthly"},
          {"value":"I","name":"Issue"}    
        ];  
        if(this.sharedData.items[this.sharedData.stateParamsId].order.productType === "Print"){
          this.options.enum = billingBasisPrint;
          const found = billingBasisObj.find(element => element.value == this.sharedData.items[this.sharedData.stateParamsId].model.BillingBasis);
          if(found.value !="M" && found.value !="I"){
            this.options.enum.push(found);
          }          
        }else{
          this.options.enum = billingBasisObj;
        }
        
        this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.BillingBasis || this.options.default;
        this.isReadonly  =this.sharedData.items[this.sharedData.stateParamsId].schema.properties.BillingBasis.readonly;
      }
      if(this['layoutNode'].name === "BillingBasisBRM"){
          this.hidden =true;
      }
      if(this['layoutNode'].name === "calltrackingtype"){
        this.titleContent = "Call Tracking";
        if(this.sharedData.items[this.sharedData.stateParamsId].order.productType === "Digital"){
          this.options.enum = callTypeDigitalObj;
          this.customField = "Local Number";
        }else{
          this.options.enum = callTypePrintObj;
          this.customField = "Local Number";
        }
      }
      if (this['layoutNode'].name == 'OmitAddress') {
        this.options.enum = omitAddressObj ;
        this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.OmitAddress;
      }
      if (this['layoutNode'].name == 'CoOpType') {
        this.options.enum = coOPTypeObj ;
        this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.CoOpType;

      }
      if (this['layoutNode'].name == 'HideAddress') {
        this.options.enum = coOPTypeObj ;
        this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.HideAddress;
        //Adding condition for SPPOW FUL-18739 from Angualar 1.5
        if (this.sharedData.items[this.sharedData.stateParamsId].order.productCode === "DPM"){
          this.dpmHideAddress();
       }
      }
      if (this['layoutNode'].name == 'BestTime') {
        this.options.enum = BESTTIMEOBJ ;
         this.customField = this.formatBestTime(this.sharedData.items[this.sharedData.stateParamsId].model.BestTime);
       }
    });
  }
   sendValue(args) {
    let  tempObj =JSON.parse(JSON.stringify(this.sharedData));
    if(this['layoutNode'].name === "calltrackingtype"){
      this.componnentStore.update(()=>{
        return {
          componentValue:args
        }
      })
    }

    tempObj.items[tempObj.stateParamsId].model[this['layoutNode'].name] = args;
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].model))
    if(this['layoutNode'].name === "CoOpType"){
      if(args =="No"){
        modelData.items[modelData.stateParamsId].model.CoOpNIAmount = "0";
      }
    }
    if(this['layoutNode'].name === "BillingBasis") {

      if (args != null && tempObj.items[tempObj.stateParamsId].model["BillingAccount"] != null && args !== tempObj.items[tempObj.stateParamsId].model["BillingBasis"] ) {
        let hasMatching = false;
        for (let key in tempObj.items) {
            if (tempObj.stateParamsId!== key && tempObj.items[key].model["BillingAccount"] != null &&
                tempObj.items[tempObj.stateParamsId].model["BillingAccount"] === tempObj.items[key].model["BillingAccount"] &&
                tempObj.items[key].order.productType === "Print" &&
                args != tempObj.items[key].model["BillingBasis"]) {
                hasMatching = true;
                break;
            }
        }
        if (hasMatching) {
          let msg = 'This will change the Billing Basis for all items with the same Billing Account. Do you still want to continue?';
          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            message: msg,
          }
          dialogConfig.width = '600px';
          const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
          dialogRef.afterClosed().subscribe(dialogResult => {
            if(dialogResult){
              for (let key in tempObj.items) {
                if (tempObj.items[tempObj.stateParamsId].model["BillingAccount"] === tempObj.items[key].model["BillingAccount"]) {
                  tempObj.items[key].model["BillingBasis"] = tempObj.items[tempObj.stateParamsId].model["BillingBasis"];
                }
              }
            }
          });
        } else {
          //     //tempObj.items[vm.stateParams.id].model['BillingBasis'] = oldValue;
          //    // vm.skip = oldValue;
        }

      }
    }
    this.indicatorQuery.updateModel(this['layoutNode'].name,args,modelData,tempObj, '[SelectComponent] sendValue');
  }
  //Adding condition for SPPOW FUL-18739 from Angualar 1.5
  private dpmHideAddress(){
    let tempIndicator = JSON.parse(JSON.stringify(this.sharedData));
    if (this.sharedData.items[this.sharedData.stateParamsId].order.productCode === "DPM" ){
      for (let key in this.sharedData.profiles) {
          if (key != "" && this.sharedData.items[this.sharedData.stateParamsId] && this.sharedData.items[this.sharedData.stateParamsId].model && this.sharedData.items[this.sharedData.stateParamsId].model.BusinessProfile == key) {
              if (this.sharedData.profiles[key].model.BusinessAddressDisplayOptions && this.sharedData.profiles[key].model.BusinessAddressDisplayOptions === "HIDE"){
                  tempIndicator.items[this.sharedData.stateParamsId].model["HideAddress"] = "Yes";
                  this.customField = "Yes";
              }else{
                 tempIndicator.items[this.sharedData.stateParamsId].model["HideAddress"] = "No";
                 this.customField = "No";
              }
              this.indicatorStore.update((state) => {
                return {
                  indicator: tempIndicator,
                  updatedFrom: "[SelectComponent] dpmHideAddress",
                  isloaded: true,
                };
              });
          }

      }
    }


  }

  formatBestTime(str){
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    else return '';
  }

}
