import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { GroupService } from '@app/service/iagroup/group.service';
import { OrderService } from '@app/service/order/order.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AdBasedComponent } from '../ad-based/ad-based.component';

@Component({
  selector: 'app-item-groups',
  templateUrl: './item-groups.component.html',
  styleUrls: ['./item-groups.component.scss']
})
export class ItemGroupsComponent implements OnInit {

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private groupService: GroupService,
    private orderService: OrderService,
    private storageService: StorageService,
    private copysheetService: CopysheetService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog) { }
  Indicator: any;
  group: any;
  Order: any;
  clickedAd: boolean;
  clickedApprnc: boolean;
  valueToPassAdBased: any;
  dialogData: any = {};
  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
    });
    this.group = this.groupService.createGroupObject(this.Indicator.items, this.Indicator.items[this.Indicator.stateParamsId]);
    //TODO :To check and remove orderService and get order from store
    // this.orderService.getMe().then(order=> {
    //   this.Order = order;
    // }).catch(err => {
    //   console.log(err, " Error in order service get me function call");
    // });
    //set based on ad check or x
    if (this.Indicator.items[this.Indicator.stateParamsId].order.productType === "Print"
      && this.Indicator.items[this.Indicator.stateParamsId].model
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId !== ""
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId !== undefined
    ) {
      this.clickedAd = true;
    } else {
      this.clickedAd = false;
    }

    // $scope.$watch("vm.Indicator.items[vm.stateParams.id].model.ManageCopysheet.baseAdId", function (newValue, oldValue) {
    //   if (newValue !== null && newValue !== "" && newValue !== undefined) {
    //     this.clickedAd = true;
    //   } else if (newValue === null || newValue === "" || newValue === undefined) {
    //     this.clickedAd = false;
    //   }
    // });

    //ia group check or x
    if (this.Indicator.items[this.Indicator.stateParamsId].order.productType === "Print"
      && this.Indicator.items[this.Indicator.stateParamsId].model
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== ""
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== undefined
      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== null
    ) {
      this.clickedApprnc = true;
    } else {
      this.clickedApprnc = false;
    }
    // $scope.$watch("vm.Indicator.items[vm.stateParams.id].model.ManageCopysheet.iaGroupId", function (newValue, oldValue) {
    //   if (oldValue === newValue && newValue !== null && newValue !== "") {
    //     this.clickedApprnc = true;
    //   } else if (oldValue !== newValue) {
    //     this.clickedApprnc = true;
    //   } else {
    //     this.clickedApprnc = false;
    //   }
    // });
  }

  openAdBasedDialog() {
    this.valueToPassAdBased = "I must be passed";
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = 'set-based-modal';
        dialogConfig.data = {
          dialogform: this.dialogData,
        }
    let modalInstance = this.matDialog.open(AdBasedComponent, dialogConfig);
    this.dialogData['dilogReference'] = modalInstance;
    modalInstance.afterClosed().subscribe(data => {
      this.indicatorQuery.getIndicator().subscribe(data => {
          this.Indicator = JSON.parse(JSON.stringify((data)));
      });
      if(data){
        if (data.displayAdId) {
        this.clickedAd = true;
      } else {
        this.clickedAd = false;
      }
    }else{}
      //this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['baseAdIdSelected'] = true;
      //$scope.ngModel.baseAdId = data.displayAdId;
      //$scope.ngModel.baseAdIdPrefix = data.displayAdIdPrefix;
      if (this.Indicator.items[this.Indicator.stateParamsId].order.statusCode >= 6000) {
        this.Indicator.items[this.Indicator.stateParamsId].needsNewCopysheet = true;
        this.indicatorStore.update(state =>{
          return{
            indicator: this.Indicator,
            updatedFrom: "[ItemGroupsComponent] openAdBasedDialog",
          }
        })
      }
      this.ngAfterViewChecked();
    });
  };

  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
	}
}
