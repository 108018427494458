import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  PATH: string = '/api/storage';
  constructor(private API: ApiService, private http : HttpClient,private Params : ParamsService) { }
  headers = new HttpHeaders()

/**
 * _get - driver for changing the this.accounts model without setting to null
 * @param  {[type]} id [description]
 * @return {[type]}    [description]
 */
  _get(page : string, id : string): Promise<any> {
      let additional = (id) ? "/" + id : "";
      let path = this.PATH + "/" + page + additional;
      let fullPath = this.API.get(this.PATH) + "/" + page + additional;
      return this.http.get(fullPath).toPromise();

  };

/**
 * [update description]
 * @param  {[type]} id   [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
update(page : string, id : string , data : any): Promise<any> {
    let fullPath = this.API.get(this.PATH) + "/" + page + "/" + id;
    let pathAll = this.PATH + "/" + page + "/" + id;
    return this.http.put(fullPath, data).toPromise();
};
updateField(page : string, id  : string, data :any) {
  return new Observable((observer) => {
    let fullPath = this.API.get(this.PATH) + "/field/" + page + "/" + id;
    this.http.put(fullPath, data).subscribe(data=>{
      observer.next(data);
    },error=>{
      observer.error(error);
    })
  });
};
updateFieldPromise(page : string, id  : string, data :any): Promise<any> {
    let fullPath = this.API.get(this.PATH) + "/field/" + page + "/" + id;
    return this.http.put(fullPath, data).toPromise();
};
/**
 * Get - the data if it exists otherwise just call the service to get the new response
 * @param  {string} page -the current page
 * @param  {string} id - the id of the object that relates to the page
 * @return {$promise}
 */
 get(page : string, id : string) {
    let path = this.PATH + "/" + page + "/" + id;
    if (page != "publishHistory" && page != "itemHistory" && page != "profileHistory" && JSON.parse(localStorage.getItem(path))) {
      //console.log("page---->", page);
      //observer.next(localStorageService.get(path)); --> need to implement storage service - vijay
      //console.log(JSON.parse(localStorage.getItem(path)))
    } else {
      return this._get(page, id)
    }
};

getField(page : string, id : string) {
    //var path = this.PATH + "/field/" + page + "/" + id; fix for sonar issue
    return this._get(page, id)
};

getAllField(page : string, id : string): Promise<any> {
  let additional = (id) ? "/" + id : "";
  let fullPath = this.API.get(this.PATH) + "/all"+ "/" + page + additional;
  return this.http.get(fullPath).toPromise();
};

/**
 * GetAll - the data if it exists otherwise just call the service to get the new response
 * @param  {string} page -the current page
 * @return {$promise}
 */
getAll(page : string) {
    var path = this.PATH + "/" + page;
    return this._get(page, null)

};

/**
 * getByAccountIdOrderId - get based on account and order id
 * @param  {[type]} id [description]
 * @return {[type]}    [description]
 */
getByAccountIdOrderId(page : string, id : string, accountId : string, svOrderId : string): Promise<any> {
  let additional = (id) ? "/" + id : "";
  let fullPath = this.API.get(this.PATH) + "/" + page + additional + "/" + accountId + "/" + svOrderId;
  return this.http.get(fullPath).toPromise();

};

}
