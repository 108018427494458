import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { StorageService } from '@app/service/storage/storage.service';
import { ProductService } from '@app/service/product/product.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-extraline',
  templateUrl: './extraline.component.html',
  styleUrls: ['./extraline.component.scss']
})
export class ExtralineComponent implements OnInit {
  selectedItem :any;
  quantity:any;
  tibcoArray:any =[];
  altTN:any =[];
  extraline:any=[];
  options: any;
  indicator:any;
  isdisabled:boolean= false;
  isLoaded:boolean = false;
  lineTypeValue= [];
  lineItem: any;
  text: any;
  tnType: any;
  lineName: any;
  customfield: any;
  isDataAvailable : Promise<boolean>;
  phonePattern;
  constructor(
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private storageservice:StorageService,
    private productService:ProductService,
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    this.spinnerService.show();
    for (let i = 0; i < this.indicator.items[this.indicator.stateParamsId].form.length; i++) {
        if (this.indicator.items[this.indicator.stateParamsId].form[i].type === "extraline") {
          if(this.indicator.items[this.indicator.stateParamsId].form[i]){
            this.isdisabled = this.indicator.items[this.indicator.stateParamsId].form[i].readonly;
          }
          if(this.indicator.items[this.indicator.stateParamsId].model['ExtraLine']){
          //this.extraline.push(this.indicator.items[this.indicator.stateParamsId].model.ExtraLine);
          this.extraline = this.indicator.items[this.indicator.stateParamsId].model.ExtraLine;
          }
          break;
        }
    }
    this.selectedItem = this.indicator.items[this.indicator.stateParamsId].order;
    this.quantity = this.selectedItem.quantity;
    if (!this.extraline || (this.extraline[0] && !this.extraline[0].lineType)) {
      this.extraline = [];
    }
    this.tibcoArray=this.extraline;
    this.storageservice.get("item", this.indicator.stateParamsId + "v" + this.indicator.items[this.indicator.stateParamsId].order.version);
    this.storageservice.get("item", this.selectedItem.itemId).then(data=>{
      if(data[0] && data[0].data!== this.quantity){
          this.tibcoArray=[];
          for (let i = 0; i < this.quantity; i++) {
              if(this.extraline.length < this.quantity)
              // Commented as per US FUL-52856
              // this.extraline.push({
              //     "lineType": "",
              //     "text": "",
              //     "tnType": "",
              //     "textTn": {
              //         "NPA": "",
              //         "COP": "",
              //         "LINE": ""
              //     }
              // });
              this.extraline.push({
                  "lineType": "",
                  "text": "",
                  "tnType": "",
                  "textTn": ""
              });
              if ( this.extraline[i].lineType === "2" || this.extraline[i].lineType  === "3" || this.extraline[i].lineType  === "50" || this.extraline[i].lineType  === "5") {
                  this.altTN[i] = true;
              } else {
                  this.altTN[i] = false;
              }
          }
          if(this.extraline.length >0){
            this.isLoaded = true;
            this.ngAfterViewChecked();
          }
      }
  });
  //get the line type options for the item from getLineTypes API
  this.productService.getLines(this.indicator.items[this.indicator.stateParamsId].order.svItemId).subscribe((lineTypes:any)=> {
    let name;
    this.spinnerService.show();
    if(lineTypes.hasWarningOrError==="true") {
          let errorCode = lineTypes.esbServiceFault.exceptionDetail[0].errorCode;
          let errorDescription = lineTypes.esbServiceFault.exceptionDetail[0].errorDescription[0];
          this.logger.logError("<strong>Transaction Id : "+lineTypes.transactionId+"</strong><br><strong>"+errorCode + " :</strong><br>" + errorDescription);
      } else {
        if (this.extraline[0] && (this.extraline[0].lineType  && this.extraline[0].lineType != "")) {
          name = lineTypes.products.product.content.find((e) => {
            if (e.lineTypeIntCode == this.extraline[0].lineType) {
              return e.lineName;
            }
          });
          this.lineName = name.lineName;
        }
          this.isDataAvailable = Promise.resolve(true);
          if(this.isDataAvailable){
            this.spinnerService.hide();
          }

          lineTypes = lineTypes.products.product.content;
          this.lineTypeValue = lineTypes;
          this.ngAfterViewChecked();
      }
  });
    // for (let i = 0; i < this.extraline.length; i++) {
    //   // if (!this.extraline[i].phone && this.extraline[i] && this.extraline[i].textTn && this.extraline[i].textTn.NPA && this.extraline[i].textTn.COP && this.extraline[i].textTn.LINE) {
    //   //   this.extraline[i].phone = '(' + this.extraline[i].textTn.NPA + ') ' + this.extraline[i].textTn.COP + '-' + this.extraline[i].textTn.LINE;
    //   // }
    //   if (!this.extraline[i].phone && this.extraline[i] && this.extraline[i].textTn) {
    //     let textTn = this.extraline[i].textTn
    //     // textTn.replace('(', '').replace(')', '');
    //     this.extraline[i].phone = textTn.replace('(', '').replace(')', '').replace(' ', '');
    //   } else if (this.extraline[i].phone) {
    //     let textTn = this.extraline[i].phone
    //     // textTn.replace('(', '').replace(')', '');
    //     this.extraline[i].phone = textTn.replace('(', '').replace(')', '').replace(' ', '');
    //   }
    // }

  }
  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }
  lineTypeValueChange(elLineType, index) {
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    let lineNameObj = this.lineTypeValue ? this.lineTypeValue.find(linetype => linetype.lineName == elLineType) : null;
    let updateExtra = JSON.parse(JSON.stringify(this.extraline[index]));
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    //disable TN Type and Telephone # unless the selected line type is an alternate call
    if (lineNameObj.lineTypeIntCode === "2" || lineNameObj.lineTypeIntCode === "3" || lineNameObj.lineTypeIntCode === "50" || lineNameObj.lineTypeIntCode === "5") {
      this.altTN[index] = true;
    } else {
      this.altTN[index] = false;
    }
    updateExtra['lineType'] = lineNameObj.lineTypeIntCode;
    this.lineName = elLineType;
    modelData['items'][this.indicator.stateParamsId].model.ExtraLine = [];
    modelData['items'][this.indicator.stateParamsId].model.ExtraLine[index] = updateExtra;
    let x = JSON.parse(JSON.stringify(this.indicator));
    x = modelData;
    this.storageservice.update("item", upadatedName.stateParamsId + "v" + x.items[this.indicator.stateParamsId].order.version, x.items[this.indicator.stateParamsId].model).then(result =>{});
    this.indicatorStore.update((state) => {
      return {
        indicator: x,
        updatedFrom: "[ExtralineComponent] lineTypeValueChange",
        isloaded: true,
      };
    });
  };
  textValue(elText, index) {
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    //let updateExtra = this.indicatorQuery.getValue().indicator.items[this.indicator.stateParamsId].model.ExtraLine[index];//JSON.parse(JSON.stringify(this.extraline[index]));
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    modelData.items[this.indicator.stateParamsId].model.ExtraLine = modelData.items[this.indicator.stateParamsId].model.ExtraLine ? modelData.items[this.indicator.stateParamsId].model.ExtraLine :[];
    let updateExtra =  {
      text: ''
    };
    if(modelData.items[this.indicator.stateParamsId].model.ExtraLine[index]) updateExtra = modelData.items[this.indicator.stateParamsId].model.ExtraLine[index]; 
    updateExtra['text'] = elText;
    this.text = elText;
    modelData['items'][this.indicator.stateParamsId].model['ExtraLine'] = [];
    modelData['items'][this.indicator.stateParamsId].model['ExtraLine'][index] = updateExtra;
    let x = JSON.parse(JSON.stringify(this.indicator));
    x = modelData;
    this.storageservice.update("item", upadatedName.stateParamsId + "v" + x.items[this.indicator.stateParamsId].order.version, x.items[this.indicator.stateParamsId].model).then(result =>{});
    this.indicatorStore.update((state) => {
      return {
        indicator: x,
        updatedFrom: "[ExtralineComponent] textValue",
        isloaded: true,
      };
    });
  };
  tnTypeValue(event){
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    //let updateExtra = JSON.parse(JSON.stringify(this.extraline[0]));
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    modelData.items[this.indicator.stateParamsId].model.ExtraLine = modelData.items[this.indicator.stateParamsId].model.ExtraLine ? modelData.items[this.indicator.stateParamsId].model.ExtraLine :[];
    let updateExtra = modelData.items[this.indicator.stateParamsId].model.ExtraLine[0];
    updateExtra['tnType'] = event;
    modelData['items'][this.indicator.stateParamsId].model.ExtraLine = [];
    modelData['items'][this.indicator.stateParamsId].model.ExtraLine[0] = updateExtra;
    let x = JSON.parse(JSON.stringify(this.indicator));
    x = modelData;
    this.storageservice.update("item", upadatedName.stateParamsId + "v" + x.items[this.indicator.stateParamsId].order.version, x.items[this.indicator.stateParamsId].model).then(result =>{});
    this.indicatorStore.update((state) => {
      return {
        indicator: x,
        updatedFrom: "[ExtralineComponent] tnTypeValue",
        isloaded: true,
      };
    });

  };
  textTNvalue(elTextTn, index) {
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    modelData.items[this.indicator.stateParamsId].model.ExtraLine = modelData.items[this.indicator.stateParamsId].model.ExtraLine ? modelData.items[this.indicator.stateParamsId].model.ExtraLine : [];
    let updateExtra = modelData.items[this.indicator.stateParamsId].model.ExtraLine[index];

    // updateExtra['phone'] = elTextTn;
    /////////////////////////
    // Commenting below code as per US FUL-52856
    /////////////////////////
    // let npa, cop, line;
    // if (elTextTn.length >= 7 && elTextTn.length <= 11) {
    //   // Extract NPA, COP, and LINE from the phone number
    //   if (elTextTn.length === 11) {
    //     npa = elTextTn.substring(0, 4);
    //     cop = elTextTn.substring(4, 7);
    //     line = elTextTn.substring(7, 11);
    //   } else {
    //     npa = elTextTn.substring(0, 3);
    //     cop = elTextTn.substring(3, 6);
    //     line = elTextTn.substring(6, 10);
    //   }

    //   // If the NPA is a four-digit number starting with '0', remove the leading '0'
    //   if (npa.length === 4) {
    //     npa = npa.substring(1);
    //   }
    //   this.extraline[index].textTn.NPA = npa;
    //   this.extraline[index].textTn.COP = cop;
    //   this.extraline[index].textTn.LINE = line;

    //   // Assign NPA, COP, and LINE to the textTn object
    //   updateExtra.textTn = { NPA: npa, COP: cop, LINE: line };

    //   // Update modelData with the modified ExtraLine
    //   modelData.items[this.indicator.stateParamsId].model.ExtraLine[index] = updateExtra;

    //   // Update the storage and state
    //   let x = JSON.parse(JSON.stringify(this.indicator));
    //   x = modelData;
    //   this.storageservice.update("item", this.indicator.stateParamsId + "v" + x.items[this.indicator.stateParamsId].order.version, x.items[this.indicator.stateParamsId].model).then(result => { });
    //   this.indicatorStore.update((state) => {
    //     return {
    //       indicator: x,
    //       updatedFrom: "[ExtralineComponent] textTNvalue",
    //       isloaded: true,
    //     };
    //   });
    // } else {
    //   // Handle invalid phone number length
    //   console.error('Invalid phone number length');
    // }

    
    /////////////////////////
    // Adding below code as per US FUL-52856
    /////////////////////////
    if (elTextTn.length >= 7 && elTextTn.length <= 15) {
      updateExtra['phone'] = elTextTn;
      
      this.extraline[index].textTn = elTextTn;

      // Assign elTextTn to the textTn object
      updateExtra.textTn = elTextTn;

      // Update modelData with the modified ExtraLine
      modelData.items[this.indicator.stateParamsId].model.ExtraLine[index] = updateExtra;

      // Update the storage and state
      let x = JSON.parse(JSON.stringify(this.indicator));
      x = modelData;
      this.storageservice.update("item", this.indicator.stateParamsId + "v" + x.items[this.indicator.stateParamsId].order.version, x.items[this.indicator.stateParamsId].model).then(result => { });
      this.indicatorStore.update((state) => {
        return {
          indicator: x,
          updatedFrom: "[ExtralineComponent] textTNvalue",
          isloaded: true,
        };
      });
    } else {
      // Handle invalid phone number length
      console.error('Invalid phone number length');
    }
  }


}
