import { Component, OnInit, Inject } from '@angular/core';
import { LoggerService } from '@app/service/logger/logger.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-show-history',
  templateUrl: './show-history.component.html',
  styleUrls: ['./show-history.component.scss']
})
export class ShowHistoryComponent implements OnInit {
  createdID: any;
	logHistory : any = [];
	isHistory: boolean = false;
	isFieldsChanged: boolean = false;
  public sortOptions: object;
  sharedData: any;
  updatedPublishGridData: any = [];
  dataValue = new Array();
  model: string;
  bpModel: boolean = false;
  itemStatusFlag: boolean = false;
  Indicator: any;
  storageName: string;
  id:any;
  
  constructor(private Logger: LoggerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowHistoryComponent>) { }

  ngOnInit() {
    this.storageName = this.data.chnageHistoryName;
    let tableData = this.data.storageDate;
    this.bpModel =this.data.bpModel;
    this.itemStatusFlag = this.data.itemStatusFlag;
    this.showHistory(tableData);
  }
  
  showHistory(tableData){
        tableData.forEach((data, index) =>{
          let dataObj = {
            name: data.displayName, 
            email: data.email, 
            dateTime: data.date, 
            field: data.fieldName,
            fieldValue: data.fieldValue,
            itemStatus:data.itemStatus,
            itemActionCode:data.itemActionCode,
            itemId: data.itemId,
            oldValue: data.fieldOldValue, 
            newValue: data.fieldNewValue,
            transactionId: data.transactionId
          }
          this.updatedPublishGridData.push(dataObj);
        });
        //Following loop is for showing latest record in change history
        for (var i = this.updatedPublishGridData.length - 1; i >= 0; i--) {
          this.dataValue.push(this.updatedPublishGridData[i]);
      }
      //  this.dataValue = this.updatedPublishGridData;//publishGridData;
        this.model = "footerChangeHistory";
   
  }

}

