import { ChangeDetectorRef, Component, OnDestroy, OnInit ,ViewRef} from '@angular/core';
import { AccountService } from '@app/service/account/account.service';
import { ParamsService } from '@app/service/params/params.service';
import { PaynowService } from '@app/service/roundProgress/paynow/paynow.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { IndicatorQuery } from 'app/state/query';
import { CounterService } from '@app/service/counter/counter.service';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { StorageService } from '@app/service/storage/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminuserService } from '@app/service/adminuser/adminuser.service';
import { FooterService } from '../footer/footer.service';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss']
})
export class LeftnavComponent implements OnInit, OnDestroy {

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showTransactionSubMenu: boolean = false;
  sfaUrl = this.Params.params.sfaUrl;
  storageMode: any = null;
  // bpMode = (this.Params.params.order == null || this.Params.params.order == "null");
  bpMode : any;
  url: Observable<any>;
  url2;
  itemCount: number= 0;
  digitalCount: number= 0;
  printCount: number= 0;
  bpTotalCount: number= 0;
  indicatorValue: any;
  accountName: string;
  orderId: string;
  orderStatus: string;
  eai: string;
  flagBp: boolean = true;
  manageBPModeFlag: boolean =false;
  destroySubject$: Subject<void> = new Subject();
  country: any;
  // isDisablePayNow : boolean = false;
  constructor(private account: AccountService, private Params: ParamsService, private payNow: PaynowService, private router: Router,
    private counterService: CounterService,private indicatorQuery: IndicatorQuery,private cd: ChangeDetectorRef,
    private requiredFieldCount: RequiredfieldcountService, private storageService: StorageService,
    private adminuser: AdminuserService,
    private spinnerService: NgxSpinnerService,private footerService: FooterService) { 
      router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      });
    }

  ngOnInit() {
    /* console.log("inside Account service");
    this.account.get("0013900001a2TZrAAM").subscribe(data => {
      console.log("data --> ", data);
    }) */
    //console.log("Sfaurl",this.sfaUrl)
    this.bpMode = (sessionStorage.getItem('currentOrderId') == null || sessionStorage.getItem('currentOrderId') == "null");
    console.log('bpmode', this.bpMode);
    this.storageMode = sessionStorage.getItem('mode') ? sessionStorage.getItem('mode') : "null" ;
    console.log('this.storageMode', this.storageMode);

    if (this.storageMode && this.storageMode == 'ManageBP'){
      this.manageBPModeFlag = true;
    }
    else{
      this.manageBPModeFlag = false;
    }


    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
      if(this.indicatorValue.account) {
        this.eai = this.indicatorValue.account.enterpriseAccountId;
        this.accountName = this.indicatorValue.account.name;
        if(this.indicatorValue.account.address && 
          this.indicatorValue.account.address.country) {
            this.country = this.indicatorValue.account.address.country;
            // if (this.country == 'NZ') {
            //   this.isDisablePayNow = true;
            // } else  {
            //   this.isDisablePayNow = false;
            // }
        }
      }
      if (this.indicatorValue.order != null) {
        this.orderId = this.indicatorValue.order.orderId;
        this.orderStatus = this.indicatorValue.order.status;
      } else {
        this.flagBp = false;
      }
      //this.subscribe.unsubscribe();
    });
    if (this.indicatorValue && this.indicatorValue.order && !this.bpMode ) {
      this.payNow.getPayNowSideMenuLink()
    }
    this.counterService.getError().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(count => {
      this.itemCount = count[0] + count[1];
      this.digitalCount = count[0];
      this.printCount = count[1];
      this.bpTotalCount = count[2];
      //console.log("Count :",count);
      //console.log("this.bpTotalCount1pipe :",this.bpTotalCount);
      this.ngAfterViewChecked();
    });
  }
  ngAfterViewInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
      this.itemCount = this.indicatorValue.orderPercent.digitalCount + this.indicatorValue.orderPercent.printCount;
      this.digitalCount =this.indicatorValue.orderPercent.digitalCount;
      this.printCount = this.indicatorValue.orderPercent.printCount;
      this.bpTotalCount = this.indicatorValue.orderPercent.bpTotalCount;
      /*for(let profileId in this.indicatorValue.profiles){
        if(this.indicatorValue.profiles[profileId].schema[1].properties.hasOwnProperty("ConfirmHoursOfOperation")){
          this.storageService.get("profileHelper", profileId).then(res => {
            if(res[profileId].ConfirmHoursOfOperation != true){
              let count = this.requiredFieldCount.get(this.indicatorValue['profiles'][profileId].schema[1], this.indicatorValue['profiles'][profileId].form[1], this.indicatorValue['profiles'][profileId].model);
              this.bpTotalCount += count;
              this.ngAfterViewChecked();
            }
          })
        }
      }*/
    });
  }
  //forming Url for paynow click
  paynowUrl() {
    if(this.indicatorValue && 
      this.indicatorValue.account && 
      this.indicatorValue.account.address && 
      this.indicatorValue.account.address.country != 'NZ') {
      //console.log("this.payNow.finalURl", this.payNow['finalURl'])
      this.url2 = this.payNow['finalURl'];
      let newWindow = window.open();
      newWindow.location.href = this.url2;
    }
  }
  openHistory(orderItem,IA?) {
    //this.disableTrue = true;
    this.footerService.sethistoryPage(orderItem);
    if(IA){
      this.footerService.IA_history = IA;
    }
   // this.disableTrue = false;
    }
  opendashboard(event){
  let isdevuser = false;
    this.adminuser.getAll().subscribe(
      (res) => {
        if(res.length){
          res.forEach(element => {
            if(element.userId && element.userId == "cadev"){
              for (const causer of element.userIds) {
                if(this.indicatorValue.user.username == causer){
                  isdevuser = true;
                  this.router.navigate(['/tools/admindashboard']);
                  break;
                }
              }
              
            }
          });
          if(!isdevuser){
            this.router.navigate(['/tools/dashboardlogin']);
          }
        }
      })
  //console.log("Click event called");
   
}
ngAfterViewChecked() {
  if (!(<ViewRef>this.cd).destroyed)
  this.cd.detectChanges();
}
ngOnDestroy() {
  this.destroySubject$.next();
}
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.spinnerService.show();
    }
     if (event instanceof NavigationEnd) {
      if(event.url && event.url == '/orderitems'){
        setTimeout(() => {
          this.spinnerService.hide();
        }, 1500);
      }
    } 

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.spinnerService.hide();
    }

  }
  onlaunchSFA(){
    let newWindow = window.open();
    newWindow.location.href = this.sfaUrl;
  }
}
