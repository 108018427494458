import { Component, ElementRef, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ApiService } from '@app/service/api/api.service';
import { FileService } from '@app/service/file/file.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-uploadphoto',
  templateUrl: './uploadphoto.component.html',
  styleUrls: ['./uploadphoto.component.scss']
})
export class UploadphotoComponent implements OnInit {

  photoSubscription: any;
  uploader: FileUploader;
  //uploadURL = '';
  showErrMsg: boolean;
  showSizeErrMsg: boolean;
  //selectAd: boolean;
  configObj = {
    fileType: '',
    uploadLimit: '',
    fileNameLength: '',
    uploadedFile: {}
  };
  files: any = [];
  uploadlogoArray: any = [];
  sharedLogoObj: Photos = new Photos;
  errorMsg: string = '';
  fileId: string;
  emptyText: string = 'first upload file </br> then only Add description';
  editMode: boolean = false;
  editModeDesc: boolean = false;
  inputIndex: any = "";
  inputIndexDesc: any = "";
  hasDropZoneOver: boolean;
  @Output() fileAdditionRemoval = new EventEmitter<boolean>();
  @ViewChild("inlineDesc", { static: false }) inlineDescEl: ElementRef;


  constructor(private API: ApiService,
    private File: FileService,
    private photoService: PhotoService,
    private _file: FileService) {

    this.hasDropZoneOver = false;

    this.uploader = File.uploader;
    this.uploader.onAfterAddingFile = (file) => {
      let fileFileName = file.file.name
      if (fileFileName && fileFileName.length > 10) {
        let firstPart = fileFileName.slice(0, 4);
        let secondPart = fileFileName.slice(fileFileName.length - 5, fileFileName.length);
        file.file['name1'] = firstPart + '...' + secondPart;
      } else {
        file.file['name1'] = file.file.name;
      }
      if (!file.file.type.toLowerCase().match("/(jpg|png|jpeg)")) {
        this.showErrMsg = true;
        if (file.file.size < 5 * 1024 * 1024) { this.showSizeErrMsg = false; }
        this.uploader.removeFromQueue(file);
      } else {
        this.showErrMsg = false;
        if (file.file.size > 5 * 1024 * 1024) {
          this.showSizeErrMsg = true;
          this.uploader.removeFromQueue(file);
        } else {
          this.showSizeErrMsg = false;
        }
      }
      this.sharedLogoObj.uploaderObj = this.uploader;
      this.photoService.setBehaviorView(this.sharedLogoObj);
    };

    this.uploader.onCompleteItem = (fileItem, response: any, status, headers) => {
      if (response) {
        response = JSON.parse(response);
        this.fileId = response._id;
        var filePath = this.getFilePath(response);
        this.files.push(filePath);
        this.sharedLogoObj.fileItemObj.push(fileItem);
        if (this.uploadlogoArray != null && this.uploadlogoArray != undefined) {
          var localAttachobj = {
            url: '',
            isNewImg: false,
            largeImg: '',
            showImg: false,
            thumbImg: '',
            urlName: '',
            id: ''
          };
          var urlName = "";
          var url = filePath.split("/");
          urlName = url.pop();
          localAttachobj['url'] = filePath;
          localAttachobj['isNewImg'] = true;
          localAttachobj['largeImg'] = filePath;
          localAttachobj['showImg'] = true;
          localAttachobj['thumbImg'] = filePath;
          localAttachobj['urlName'] = urlName;
          localAttachobj['id'] = response._id;
          this.uploadlogoArray.push(localAttachobj);

          for (let i = 0; i < this.uploader.queue.length; i++){
            if(this.uploader.queue[i] === fileItem){
              this.uploader.queue[i]['fileItemId'] =  response._id;
              console.log(this.uploader.queue[i]['fileItemId'] + "----- this.uploader.onCompleteItem -- if(this.uploader.queue[i] === fileItem){");
            }
          }
          this.sharedLogoObj.uploaderObj = this.uploader
          this.sharedLogoObj.uploadLogoArrObj = this.uploadlogoArray
        };
        this.photoService.setBehaviorView(this.sharedLogoObj);
      } else {

      }
      this.enableSave();
    }

  }

  enableSave() {
    let disableValue = true;
    for (let item of this.uploader.queue) {
      if (item.isSuccess == true) {
        disableValue = false;
        break;
      }
    }
    this.fileAdditionRemoval.emit(disableValue);
  }

  ngOnInit() {
    this.photoSubscription = this.photoService.getBehaviorView().subscribe(res => {
      this.sharedLogoObj = res;
    })
    if (this.uploader)
      this.uploader.clearQueue();
  }

  getFilePath(file) {
    return this.API.get("/api/file/") + file._id + "/" + file.filename;
  }

  remove(file) {
    for (var i = 0; i < this.uploadlogoArray.length; i++) {
      if (file.file.name === this.uploadlogoArray[0].urlName) {
        var index = this.uploadlogoArray.indexOf(this.uploadlogoArray[i])
        if (index > -1)
          this.uploadlogoArray.splice(index, 1);
        this.sharedLogoObj.fileItemObj.splice(index, 1);
      }
    }
    this.sharedLogoObj.uploadLogoArrObj = this.uploadlogoArray
    this.photoService.setBehaviorView(this.sharedLogoObj);
    if (this.uploader.queue.length === 0)
      this.uploader.progress = 0;
    this.enableSave();
  }

  removeAll() {
    this.uploadlogoArray.splice(0, this.uploadlogoArray.length);
    this.sharedLogoObj.fileItemObj.splice(0, this.sharedLogoObj.fileItemObj.length);
    this.uploader.progress = 0;
    this.enableSave();
  }

  onChange(event: any): void {
    event.srcElement.value = '';
  }

  addDescription(file, data, fileItemId) {
    file.showErrDesc = false
    if (data.value.length <= 140) {
      this._file.get().subscribe(files => {
        if (files["length"] > 0) {
          for (var i = 0; i < files["length"]; i++) {
            // if (files[i]._id === this.fileId) {
            if (files[i]._id === fileItemId) {
              var manageUploadData = files[i]
              manageUploadData.metadata.description = data.value;
              this._file.put(manageUploadData._id, manageUploadData).subscribe(() => {

              });
              if (this.uploadlogoArray && this.uploadlogoArray.length > 0) {
                for (var j = 0; j < this.uploadlogoArray.length; j++) {
                  if (this.uploadlogoArray[j].id == manageUploadData._id) {
                    this.uploadlogoArray[j].orgObj = manageUploadData;
                  }
                }
              }
              if (this.uploader.queue && this.uploader.queue.length > 0) {
                for (var j = 0; j < this.uploader.queue.length; j++) {
                  if (this.uploader.queue[j]["fileItemId"] == fileItemId) {
                    this.uploader.queue[j]["file"]["description"] = data.value;
                  }
                }
              }
              this.sharedLogoObj.uploadLogoArrObj = this.uploadlogoArray
              this.sharedLogoObj.uploaderObj = this.uploader
              this.photoService.setBehaviorView(this.sharedLogoObj);
              break;
            }
          }
        }
      });
      this.cancelDescription(file);
    } else {
      file.showErrDesc = true;
      this.errorMsg = "Description length exceeded 140 character- Please enter description within 140 character";
    }
  }
  editDescription(i) {
    this.inputIndexDesc = i;
    this.editModeDesc = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.inlineDescEl.nativeElement.focus();
    }, 0);
  }

  cancelDescription(file) {
    this.editModeDesc = false;
    if(file.showErrDesc){
      file.showErrDesc = false;
    }
  }
  photoOverOnDropZone(e: any): void {
    this.hasDropZoneOver = e;
  }
  ngOnDestroy() {
    this.photoSubscription.unsubscribe();
  }
}
