import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IndicatorQuery } from 'app/state/query';
import { CampaignpopupComponent } from './campaignpopup/campaignpopup.component';
import { UtilsService } from '@app/service/utils/utils.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorStore } from 'app/state/store';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignSharedService {
  Indicator: any;
  dialogData: any = {}


  constructor(private dialog: MatDialog,
    private indicatorQuery: IndicatorQuery,
    private utils: UtilsService,
    private storage: StorageService,
    private indicatorStore: IndicatorStore) {
  }

  initCall() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data))
    })
  }

  openCampaignDialog(ngModel, campaignType, selectedCampaign, index, modelClicktype, layoutNode): Observable<any> {
    this.dialogData.campaignPageIndex = 0;
    this.dialogData.campaignType = campaignType ? ((campaignType === 'L') ? 'Local' : 'National') : ngModel
    this.dialogData.selectedCampaign = selectedCampaign ? selectedCampaign : null
    this.dialogData.modelClickType = modelClicktype
    this.dialogData.index = index
    this.dialogData.layoutNode = layoutNode
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      dialogform : this.dialogData
    }
    dialogConfig.height = '600px'
    dialogConfig.panelClass = 'campaign-modal'
   // dialogConfig.height = 'auto'; /// Temp change
    let dialogRef = this.dialog.open(CampaignpopupComponent, dialogConfig);
    this.dialogData.dialogRef = dialogRef;

    return dialogRef.afterClosed();
  }

  initCampaignGrid(campaignList) {
    this.initCall();
    let listOfCampaigns = campaignList
    if (listOfCampaigns) {
      for (let campaign of listOfCampaigns) {
        campaign.index = listOfCampaigns.indexOf(campaign)
        if (campaign.NationalWebsite) {
          campaign.DisplayURL = campaign.NationalWebsite.displayurl;
          campaign.DestinationURL = campaign.NationalWebsite.destinationurl;
          delete campaign.NationalWebsite;
        }
        if (campaign.CCCampaignCatKeys) {
          campaign.CategoryList = campaign.CCCampaignCatKeys;
          // delete campaign.CCCampaignCatKeys;
        }
        if (campaign.LocalWebsite) {
          campaign.DisplayURL = campaign.LocalWebsite.displayurl;
          campaign.DestinationURL = campaign.LocalWebsite.destinationurl;
          campaign.disablelist = campaign.LocalWebsite.disablelist;
          if (campaign.LocalWebsite.disableprofile === true && campaign.LocalWebsite.disablelist === true) {
            campaign.LocalWebsite.disableprofile = "Y";
            campaign.UseBusinessProfile = campaign.LocalWebsite.disableprofile;
            if(!campaign.DisplayURL) campaign.DisplayURL = '';
            if(!campaign.DestinationURL) campaign.DestinationURL = '';
          } else if (campaign.LocalWebsite.disableprofile === false && campaign.LocalWebsite.disablelist === true) {
            campaign.LocalWebsite.disableprofile = "N";
            campaign.UseBusinessProfile = campaign.LocalWebsite.disableprofile;
          } else if (campaign.LocalWebsite.disableprofile === true && campaign.LocalWebsite.disablelist === false) {
            campaign.LocalWebsite.disableprofile = "Y";
            campaign.UseBusinessProfile = campaign.LocalWebsite.disableprofile;
            delete campaign.DisplayURL;
            delete campaign.DestinationURL;
          }
          delete campaign.LocalWebsite;
        }

        if (campaign.CampaignDisplayAddress) {
          campaign.DisplayAddress = campaign.CampaignDisplayAddress;
          delete campaign.CampaignDisplayAddress;
        }

        if (campaign.CampaignAddressCity) {
          campaign.City = campaign.CampaignAddressCity;
          delete campaign.CampaignAddressCity;
        }
        if (campaign.CampaignAddressState) {
          campaign.State = campaign.CampaignAddressState;
          delete campaign.CampaignAddressState;
        }
        if (campaign.CampaignAddressZip) {
          campaign.Zip = campaign.CampaignAddressZip;
          delete campaign.CampaignAddressZip;
        }
        if (campaign.nationalCampaign && campaign.nationalCampaign.hasOwnProperty("NationalDestinationURL")) {
          campaign.DestinationURL = campaign.nationalCampaign.NationalDestinationURL;
          delete campaign.nationalCampaign.NationalDestinationURL;
        }
        if (campaign.nationalCampaign && campaign.nationalCampaign.hasOwnProperty("NationalDisplayURL")) {
          campaign.DisplayURL = campaign.nationalCampaign.NationalDisplayURL;
          delete campaign.nationalCampaign.NationalDisplayURL;
        }
        if (this.utils.isEmpty(campaign.nationalCampaign) == true) {
          delete campaign.nationalCampaign;
        }

        if (campaign.CampaignDescription) {
          var description = campaign.CampaignDescription;
          var descriptionLength = 70;
          campaign.CampaignDescription = description.substring(0, descriptionLength);
        }
        if (campaign.GeoList && campaign.GeoList.length > 0) {
          var GeoListText = ""; // result variable
          for (var j = 0; j < campaign.GeoList.length; j++) {
            GeoListText += campaign.GeoList[j].GeoName + ",";
          }
          //remove the extra comma at the end, using a regex
          GeoListText = GeoListText.replace(/,(?=[^,]*$)/, '');
          campaign["GeoListText"] = GeoListText;
        } else {
          campaign["GeoListText"] = '';
        }
        //Categories as per table view
        if (campaign.CategoryList && campaign.CategoryList.length > 0) {
          var CategoryListText = "";
          for (var j = 0; j < campaign.CategoryList.length; j++) {
            CategoryListText += campaign.CategoryList[j].CategoryName + ",";
          }
          //remove the extra comma at the end, using a regex
          CategoryListText = CategoryListText.replace(/,(?=[^,]*$)/, '');
          campaign["CategoryListText"] = CategoryListText;
        }
        else{
          campaign["CategoryListText"] = '';
        }
        //CampaignText as per table view
        if ((campaign.CampaignType == undefined || campaign.CampaignType == null)) {
          campaign["CampaignTypeText"] = 'MSN';
          campaign["BPText"] = '';
        } else if (campaign.CampaignType && campaign.CampaignType.toUpperCase() === 'NATIONAL') {
          campaign["CampaignTypeText"] = 'MSN';
          campaign["BPText"] = '';
        } else {
          let listingData = this.Indicator.items[this.Indicator.stateParamsId].listings//.split(',');
          if(listingData){
          for (let listing of listingData) {
            if (campaign.ListingId) {
              if (listing.listingId === campaign.ListingId) {
                if(!campaign.CampaignPhone) campaign.CampaignPhone = this.utils.formatPhoneNumber(listing.primaryPhone.value);
                campaign['BPText'] = this.Indicator.items[this.Indicator.stateParamsId].listingArray.filter(list=> list.value === listing.listingId)[0].name;
                break;
              }
            } else {
              if(listing.primaryPhone.value === campaign.CampaignPhone){
                campaign.ListingId = listing.value
              }
            }
          }
        }
          campaign["CampaignTypeText"] = 'L';
          //Business Profile as per table view
          // campaign["BPText"] = campaign.CampaignTitle + ',' + this.utils.formatPhoneNumber(campaign.CampaignPhone) + ',' + campaign.Address1 + ' ' + campaign.City + ' ' + campaign.State + ' ' + campaign.Zip;
        }
      }
      this.Indicator.items[this.Indicator.stateParamsId].model.CampaignType = listOfCampaigns;
      for (var key in this.Indicator.items) {
        let current = 0;
        let max = 0;
        let itemCnt = 0;
        if (!('CampaignType' in this.Indicator.items[key].model)) {
            current += this.Indicator.items[key].required - this.Indicator.items[key].counter;
            max += this.Indicator.items[key].required;
            itemCnt = itemCnt + 1;
        } else {
            var previousCurrentCount = 0;
            var previousMaxCount = 0;
            current += this.Indicator.items[key].required - this.Indicator.items[key].counter;
            max += this.Indicator.items[key].required;
            itemCnt = itemCnt + 1;
            previousCurrentCount = current;
            previousMaxCount = max;
            for (var i = 0; i < this.Indicator.items[key].model.CampaignType.length; i++) {
                if (this.Indicator.items[key].model.CampaignType[i].CampaignType && this.Indicator.items[key].model.CampaignType[i].CampaignType.toUpperCase() === 'NATIONAL') {
                    max = max + 8; // 8 required fields for National Campaign
                    var maxCampaignNational = 8;
                    var ignoreKeys = ['index', 'Address1', 'Address2', 'TargetZIPcode', 'BPText', 'CampaignId', 'CampaignType', 'CampaignTypeText', 'CategoryListText', 'City', 'DisplayAddress', 'DisplayEmail', 'Email', 'GeoListText', 'ListingId', 'State', 'Zip', 'campaignFlag', 'imagecopy', 'imageopen', 'isComplete', 'modelClicktypes', 'nationalCampaign', 'CCCampaignCatKeys'];
                    for (var nationalkey in this.Indicator.items[key].model.CampaignType[i]) {
                        var bool = (ignoreKeys.indexOf(nationalkey) > -1);
                        if (bool === false && nationalkey != 'UseBusinessProfile' && (this.Indicator.items[key].model.CampaignType[i][nationalkey] !== undefined && this.Indicator.items[key].model.CampaignType[i][nationalkey] && this.Indicator.items[key].model.CampaignType[i][nationalkey].length > 0)) {
                            current++;
                            maxCampaignNational--;
                        }
                    }
                    let tempObj = JSON.parse(JSON.stringify(this.Indicator))
                    if (maxCampaignNational === 0) {
                      tempObj.items[key].model.CampaignType[i]['isComplete'] = true;
                    } else {
                      tempObj.items[key].model.CampaignType[i]['isComplete'] = false;
                    }
                    this.Indicator = tempObj;
                }
                try {
                if (this.Indicator.items[key].model.CampaignType[i].CampaignType && this.Indicator.items[key].model.CampaignType[i].CampaignType.toUpperCase() === 'LOCAL') {
                  // this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"] = this.Indicator.items[key].model.CampaignType[i].ListingId ? this.Indicator.profiles[ this.Indicator.items[key].model.CampaignType[i].ListingId].model.BusinessName : this.Indicator.profiles[this.Indicator.items[key].model.BusinessProfile].model.BusinessName;
                  if(this.Indicator.items[key].model.CampaignType[i].ListingId && this.Indicator.items[key].model.CampaignType[i].ListingId.trim().length > 0){
                    // this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"] = this.Indicator.profiles[this.Indicator.items[key].model.CampaignType[i].ListingId].model.BusinessName;
                    if(this.Indicator.profiles[this.Indicator.items[key].model.CampaignType[i].ListingId]){
                      this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"] = this.Indicator.profiles[this.Indicator.items[key].model.CampaignType[i].ListingId].model.BusinessName;
                    } else{
                      this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"] = "";
                    }
                  } else if (this.Indicator.items[key].model.CampaignType[i]['CampaignTitle']){
                    this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"] = this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"];
                  }else {
                    this.Indicator.items[key].model.CampaignType[i]["CampaignTitle"] = this.Indicator.profiles[this.Indicator.items[key].model.BusinessProfile].model.BusinessName
                  }
                 max = max + 13; // 10 required fields for Local Campaign
                    var maxCampaignLocal = 13;
                    // var ignoreKeys = ['index', 'Address1', 'Address2', 'BPText', 'CCCampaignCatKeys', 'CampaignAddressCity', 'CampaignAddressState', 'CampaignAddressZip', 'CampaignId', 'CampaignType', 'CampaignTypeText', 'CategoryListText', 'City', 'DisplayEmail', 'disablelist', 'Email', 'GeoListText', 'LocalWebsite', 'State', 'Zip', 'campaignFlag', 'imagecopy', 'imageopen', 'isComplete', 'modelClicktypes', 'UseBusinessProfile'];
                    var ignoreKeys = ['index', 'ListingId', 'Address2', 'TargetZIPcode', 'BPText', 'CCCampaignCatKeys', 'CampaignId', 'CampaignType', 'CampaignTypeText', 'CategoryListText', 'DisplayEmail', 'disablelist', 'Email', 'GeoListText', 'LocalWebsite', 'campaignFlag', 'imagecopy', 'imageopen', 'isComplete', 'modelClicktypes', 'UseBusinessProfile'];
                    for (var localkey in this.Indicator.items[key].model.CampaignType[i]) {
                        if (localkey === 'disablelist' && this.Indicator.items[key].model.CampaignType[i][localkey] === false) {
                            current++;
                            maxCampaignLocal--;
                        }
                        if (!this.Indicator.items[key].model.CampaignType[i].hasOwnProperty('DestinationURL') && !this.Indicator.items[key].model.CampaignType[i].hasOwnProperty('DisplayURL') && localkey === 'UseBusinessProfile' && (this.Indicator.items[key].model.CampaignType[i][localkey] === 'Y' || this.Indicator.items[key].model.CampaignType[i][localkey] === true)) {
                            current++;
                            maxCampaignLocal--;
                        }
                        var bool = (ignoreKeys.indexOf(localkey) > -1);
                        if (bool === false && (this.Indicator.items[key].model.CampaignType[i][localkey] !== undefined && this.Indicator.items[key].model.CampaignType[i][localkey] && this.Indicator.items[key].model.CampaignType[i][localkey].length > 0)) {
                            current++;
                            maxCampaignLocal--;
                        }
                    }
                    let tempObj = JSON.parse(JSON.stringify(this.Indicator))
                    if (maxCampaignLocal === 0) {
                      tempObj.items[key].model.CampaignType[i]['isComplete'] = true;
                    } else {
                      tempObj.items[key].model.CampaignType[i]['isComplete'] = false;
                    }
                    this.Indicator = tempObj;
                  }
                }catch (error){
                          console.log("Error setting campaign title:",error);
                }
            }
            if ((max - previousMaxCount) === (current - previousCurrentCount)) {
                this.Indicator.items[key].order.isCampaignComplete = true;
            } else {
                this.Indicator.items[key].order.isCampaignComplete = false;
            }
        }
    }
      this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[CampaignSharedService] initCampaignGrid"
      }
    })
      this.storage.update("campaign", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model)
      return this.Indicator.items[this.Indicator.stateParamsId].model.CampaignType;
    }
  }

}
