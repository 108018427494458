import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from './app.service';

@Injectable({ providedIn: 'root' })
export class OktaAuthGuard implements CanActivate {
  constructor(private okta: OktaAuthService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,) {
    sessionStorage.setItem('okta-app-url', state.url);
    const authenticated = await this.okta.isAuthenticated();
    console.log("authenticated--> ", authenticated);
    if (authenticated) { return true; }
    // Redirect to login flow.
    else {
      this.okta.login(state.url);
      return false;
    }

  }
}