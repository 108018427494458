import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '../utils/utils.service';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';
import { AccountService } from '../account/account.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  path;
  baseUri: string = '/api/profile';

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  indicator ;
  sourceType ="SFDC";
  sessionId;
  constructor(
    private http: HttpClient,
    private Params: ParamsService,
    private API : ApiService,
    private utils : UtilsService,
    private user: UserService,
    private accountService :AccountService) {
      if (sessionStorage.account != null) {
        if(!sessionStorage.oauthToken){
          this.user.getSessionId().then((res)=>{
            this.sessionId = res;
            sessionStorage.setItem("oauthToken",res);
            localStorage.setItem("oauthToken",res);
            this.getSourceType();
          });
        }else{
          this.sessionId =sessionStorage.getItem("oauthToken")
          this.getSourceType();
        }
        
      }
      /** need to check do we really need this below code this is only setting up the recordtype only */
      // this.user.getSessionId().then((res)=>{
      //   sessionStorage.setItem("oauthToken",res);
      //   localStorage.setItem("oauthToken",res);
      //   this.accountService.get(this.Params.params.account).toPromise().then(accountData => {
      //     if (accountData.hasWarningAndError) {
      //       return Promise.reject(accountData.errorException.message);
      //     }
      //     this.sourceType = accountData.accountRecordType == "National Account" ? "CMR" : "SFDC"; 
      //   }, accountErr => {        
      //    return Promise.reject(accountErr);
      //  });
      // })
      this.Params.params.sforg = "national"
     }
  getSessionIdSync(): number {
    return this.sessionId;
  }

  getSourceType(){
    this.accountService.get(sessionStorage.account).toPromise().then(accountData => {
      if (accountData.hasWarningAndError) {
        if(accountData.errorException.message =="INVALID_SESSION_ID"){

        }
        return Promise.reject(accountData.errorException.message);
      }
      this.sourceType = accountData.accountRecordType == "National Account" ? "CMR" : "SFDC"; 
    }, accountErr => {        
    return Promise.reject(accountErr);
  });
  }

  get(source, type, id,query, cache): Promise<any> {
    this.path = this.API.get(this.baseUri) + "/" + source + "/" + type + "/" + id;
    if(query) {
       this.path = this.path +"/" +  this.Params.params.sforg;
       this.path += "?" + this.utils.encodeQueryData(query);
     }

    if (type === "listing" && this.sourceType) {
      /**Adding below condition because if a user is searching directly using listing from dex media search not through the order page.
      this time Account.getMe() was returning null.
        */
      if (!this.sourceType) {
        this.sourceType = "SFDC";
      }
      this.path = this.path + "/" + this.sourceType + "/" + this.Params.params.sforg;
      //delete  this.Params.sourcetype;
    } else if (type === "eid" || type === "listing") {
      this.path = this.path + "/" + this.Params.params.sforg;
    }
    return this.http.get(this.path).toPromise();
    // return  this.http.get(path, { cache : (cache != null) ? cache : false });
  }



  getPrint(id, productcode, productissuenum, sectioncode): Observable<any> {
    var sforg = this.Params.params.sforg;
    return this.http.get(this.API.get(this.baseUri) + "/" + id + "/" + productcode + "/" + productissuenum + "/" + sectioncode + "/" + sforg);
  };
  getAllPrintListing(id,query) {
    return this.get("print", "null", id, query, true);
  };
  getByPrintListing(id) {
      return this.get("print", "listing", id,null, false);
  };
  getKgenListing(id) {
    return this.get("print", "null", id,null, false);
  }
  update(id, data): Promise<any> {
    data.sforg = "national";
    return this.http.put(this.API.get(this.baseUri) + "/" + id, data).toPromise();
  };
  create(source, data): Promise<any> {
    data.sforg = this.Params.params.sforg;
    return this.http.post(this.API.get(this.baseUri) + "/" + source, data).toPromise();
  };
  createPrint(data) {
    return this.create("print", data);
  }
  createDigital(data) {
      return this.create("digital", data);
  }
  dryrun(action, data): Promise<any> {
    data.sforg = this.Params.params.sforg;
    return this.http.post(this.API.get(this.baseUri) + "/bp/" + action, data).toPromise();
  };
  claim(data): Promise<any> {
    data.sforg = this.Params.params.sforg;
    return this.http.post(this.API.get(this.baseUri) + "/claim/digital", data).toPromise();
  };

  //app.get("/api/profile/icons", auth.isAuthenticated(), controller.icons);
  getIcons(): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + "/icons");
  };
  /*To Delete the BP using ESB end point
  Date-26/7/2017*/
  delete(sourceType, type, id, reason, duplicateNo):Promise<any> {
    let path = this.baseUri + "/" + sourceType + "/" + type + "/" + id + "/" + reason + "/" + duplicateNo;
    return this.http.delete(this.API.get(path)).toPromise();
  };

  getByEid(id): Promise<any> {
    return this.get("digital", "eid", id,null, false);
  }
  getByDigitalListing(id): Promise<any> {
    return this.get("digital", "listing", id, null, false);
  }
  getByListing (source, id):Promise<any> {
    return this.get(source, "listing", id, null,null);
}
  getByPhone(id,businessName, city, state, streetAddress, email): Promise<any> {
    return this.get("digital", "phone", id, { businessName : businessName, city : city, state : state, streetAddress : streetAddress, email : email},false);
}

}
