import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class CouponService { 
  baseUri:string = '/api/coupon';
  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService
  ) { }
  delete(couponId : string): Observable<any> {
    return this.http.delete(this.API.get(this.baseUri) + "/" + couponId)
  };

}