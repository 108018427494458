import { Component, OnInit } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private indicatorQuery: IndicatorQuery) { }
  indicatorValue: any;
  accountNameVal: string;
  orderId: string;
  orderStatus: string;
  eai: string;
  kgenId: string;
  flagBp: boolean = true;
  mode: boolean = false;
  showcredit: boolean = false;
  creditRating: any;
  creditLimitUrl: any;

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
      if(this.indicatorValue.account) {
        this.eai = this.indicatorValue.account.enterpriseAccountId;
        this.accountNameVal = this.indicatorValue.account.name;
        this.kgenId = this.indicatorValue.account.kgenCustomerId;
        if (this.indicatorValue.user) {
          if (this.indicatorValue.account.creditRating && 
            this.indicatorValue.account.creditRating.limit != null &&
            this.indicatorValue.account.creditRating.id != null) {
              this.showcredit = true;
              this.creditRating = this.indicatorValue.account.creditRating.limit;
              this.creditLimitUrl = this.indicatorValue.user.targetOrigin + "/apex/CreditStanding?id=" + this.indicatorValue.account.creditRating.id;
          } else {
              this.showcredit = false;
              this.creditRating = 'N/A';
          }
        } 
      }
      if (this.indicatorValue.order != null) {
        this.orderId = this.indicatorValue.order.orderId;
        this.orderStatus = this.indicatorValue.order.status;
      } else {
        this.flagBp = false;
      }
      if(this.indicatorValue && this.indicatorValue.params) {
        if (this.indicatorValue.params.mode == 'QuickBP' || this.indicatorValue.params.mode == 'ManageBP'){
          this.mode = true;
        }
        else{
          this.mode = false;
        }
      }
    });
  }

}
