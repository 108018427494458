import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { ApiService } from '@app/service/api/api.service';
import { ParamsService } from '@app/service/params/params.service';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { StorageService } from '@app/service/storage/storage.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { AccountService } from '@app/service/account/account.service';
import { OrderService } from '@app/service/order/order.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { ItemService } from '@app/service/item/item.service';
import { GroupService } from '@app/service/group/group.service';
import { UtilsService } from '@app/service/utils/utils.service';
// import { ManagecopysheetService } from '@app/service/managecopysheet/managecopysheet.service';
import { FileService } from '@app/service/file/file.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { FieldcountService } from '@app/service/fieldcount/fieldcount.service';


@Injectable({
  providedIn: 'root'
})
export class ImageDialogService {

  loadingCopysheet: boolean;
  loadingUpload: boolean;
  loadingAnnotate: boolean;
  storageID: string;

  constructor(private http: HttpClient, 
    private API: ApiService, 
    private Params: ParamsService,
    private indicatorStore: IndicatorStore,
    private indicatorQuery: IndicatorQuery,
    private counterService: CounterService,
    private requiredfieldcountService: RequiredfieldcountService,
    private FieldcountService: FieldcountService,
    private storageService: StorageService,
    private logger: LoggerService,
    private accountService: AccountService,
    private orderService: OrderService,
    private profileService: ProfileService,
    private itemService: ItemService,
    private groupService: GroupService,
    private utilsService: UtilsService,
    // private managecopysheetService: ManagecopysheetService,
    private file: FileService,
    private copysheetService: CopysheetService,

    ) { }

//   updateIndictor(itemId, data, tempIndicator, currentCount) {
//     let counter = [];
//     console.log('update Indicator');
//     data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
//     data.items[itemId].counter = tempIndicator.itemCount;
//     data.orderPercent = this.copysheetService.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
//     let percentage = this.requiredfieldcountservice.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
//     data.orderPercent.percent = percentage;
//     counter.push(data.orderPercent.digitalCount);
//     counter.push(data.orderPercent.printCount);
//     counter.push(data.orderPercent.bpTotalCount);
//     this.counterService.setError(counter);
//     this.copysheetService.updatCounterStore(data);
//   }
updateIAGroupCounter(itemID, Indicator) {

    let id = itemID;
    let currentCount = Indicator.items[itemID].counter;
    var count = this.FieldcountService.get(Indicator.items[itemID].schema, Indicator.items[itemID].form, Indicator.items[itemID].model, Indicator.items[itemID].order);
    Indicator.items[itemID].counter = count;



    if (Indicator.items[itemID].requiresCopysheet
      && !Indicator.items[itemID].hasCopysheet) {
      // Indicator.items[itemID].counter++;
      let tempData = this.requiredfieldcountService.getNewItemCount(Indicator["items"][itemID].requiredFields, Indicator["items"][itemID].model, Indicator["items"][itemID].order, Indicator["orderPercent"], Indicator["items"][itemID].counter, true, Indicator["items"][itemID]);
      this.copysheetService.updateIndictor(itemID, Indicator, tempData, count);
      // if (Indicator['items'][itemID].counter == 0) {
      //   document.getElementById(itemID + 'counter').classList.remove('item-order-error');
      // } else if (Indicator['items'][itemID].counter != 0) {
      //   document.getElementById(itemID + 'counter').classList.add('item-order-error');
      // }
    } else {
      //Indicator =indicatorVal; //JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      Indicator.items[itemID].counter = currentCount;
      let tempData = this.requiredfieldcountService.getNewItemCount(Indicator["items"][id].requiredFields, Indicator["items"][id].model, Indicator["items"][id].order, Indicator["orderPercent"], Indicator["items"][id].counter, true, Indicator["items"][id]);
      this.copysheetService.updateIndictor(id, Indicator, tempData, currentCount);
      // if (Indicator['items'][itemID].counter == 0) {
      //   document.getElementById(itemID + 'counter').classList.remove('item-order-error');
      // } else if (Indicator['items'][itemID].counter != 0) {
      //   document.getElementById(itemID + 'counter').classList.add('item-order-error');
      // }
    }
  }

newGenerateCopysheet(stateParamsId, indicatorValue, genType, openAttachment?, page?) {

    return new Promise(async (resolve) => {
      if (genType === "view") {
        this.loadingCopysheet = true;
      } else if (genType === "upload") {
        this.loadingUpload = true;
      } else if (genType === "annotate") {
        this.loadingAnnotate = true;
      }
      this.storageID = stateParamsId + "v" + indicatorValue.items[stateParamsId].order.version;

      Promise.all([
        this.accountService.getMetoPromise(),
        this.orderService.getMeToPromise(indicatorValue.order['sfdcOrderId'])
      ]).then((results) => {
        let account = results[0];
        let order = results[1];
        let promise = null;
        let id = null;
        if ((account.parentId != null && account.kgenCustomerId == null) || indicatorValue.account.kgenCustomerId) {
          account.kgenCustomerId = indicatorValue.account.kgenCustomerId;
        }
        if (indicatorValue.items[stateParamsId].order.productType === "Digital") {
          promise = this.profileService.getByEid(account.enterpriseAccountId);
          id = account.enterpriseAccountId;
        } else if (indicatorValue.items[stateParamsId].order.productType === "Print") {
          promise = this.profileService.getAllPrintListing(account.kgenCustomerId, {
            productcode: indicatorValue.items[stateParamsId].order.directoryNumber,
            productissuenum: indicatorValue.items[stateParamsId].order.directoryIssueNumber,
            sectioncode: indicatorValue.items[stateParamsId].order.section
          });
          id = account.kgenCustomerId;
        }
        if (promise) {
          promise.then((response) => {
            let data = response[id];
            if (data.hasWarningOrError !== "true") {
              let pageRef = page == "UPLOAD-COPYSHEET-ORDERITEMPAGE" ? "UPLOADCOPYSHEET-ORDERITEMPAGE" : "copysheet";

              this.itemService.update(account, order, indicatorValue.items[stateParamsId], data.listings.listing, stateParamsId, pageRef)
                .then((printRes) => {
                  let copySheetReqData = {
                    "type": "generateCopySheet",
                    "id": "",
                    "callerId": "UCC",
                    "correlationId": this.groupService.getAllItemsOfGroupButNotSelf(stateParamsId, indicatorValue).toString(),
                    "sourceId": "",
                    "copySheet": {
                      "svOrderId": order['orderId'],
                      "customerId": account.kgenCustomerId,
                      "svItemId": stateParamsId,
                      "productCode": (indicatorValue.items[stateParamsId].order.productType === "Digital") ? indicatorValue.items[stateParamsId].order.productCode : indicatorValue.items[stateParamsId].order.directoryNumber,
                      "productIssueNum": indicatorValue.items[stateParamsId].order.directoryIssueNumber,
                      "documentType": (indicatorValue.items[stateParamsId].order.actionCode === "Renew") ? "A" : "N",
                      "operatorId": order['opportunity'].owner.operatorId,
                      "date": this.utilsService.getKgenDate(new Date())
                    }
                  };
                  this.copysheetService.getCopySheets(copySheetReqData, stateParamsId, indicatorValue.items[stateParamsId].order.version, page)
                    .then(async (data) => {
                      this.loadingCopysheet = false;
                      this.loadingUpload = false;
                      this.loadingAnnotate = false;
                      // Adding flag for Header and Footer copysheet
                      indicatorValue.items[stateParamsId].copy['headercopysheet'] = true;

                      if (data && data['response'] && data['response'] == null || data['response'] == undefined) {
                        if (indicatorValue.items[stateParamsId].order.statusCode >= 6000) {
                          indicatorValue.items[stateParamsId].needsNewCopysheet = true;
                        }

                        indicatorValue.items[stateParamsId].copy.copysheet = data['copysheet'];
                        /*
                        This is added to avoid the blank screen when clicked on view/annotate from IA group of footer
                        */
                        if (!indicatorValue.items[stateParamsId].hasOwnProperty("baseCopysheet") || indicatorValue.items[stateParamsId]['baseCopysheet'] == null || indicatorValue.items[stateParamsId]['baseCopysheet'] == undefined || indicatorValue.items[stateParamsId]['baseCopysheet'] == '') {
                          indicatorValue.items[stateParamsId]['baseCopysheet'] = data['copysheet'];
                        }
                        indicatorValue.items[stateParamsId].copy.fileType = 'pdf';
                        await this.copysheetService.updateCopysheet(this.storageID, indicatorValue.items[stateParamsId].copy)
                          .then(() => { }).catch((err) => {
                            this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                            resolve(false);
                          });
                        console.log("genType : ", genType)
                        if (genType === "view") {
                          var win = window.open(data['copysheet']);
                          if (win) {
                            win.focus();
                          }
                        } else if (genType === "upload") {
                        //   openAttachment ? this.managecopysheetService.openUploadAttachmentDialog(stateParamsId, indicatorValue, null) : this.managecopysheetService.openUploadDialog(indicatorValue, stateParamsId);
                        } else if (genType === 'annotate') {

                          this.indicatorStore.update(state => {
                            return {
                              indicator: indicatorValue,
                              updatedFrom: "[ManagecopysheetService] getCopysheets",
                            }
                          })
                          this.indicatorQuery.getIndicator().subscribe(data => {
                            indicatorValue = { ...data }
                          });
                          await this.newAnnotateCopysheet(indicatorValue, stateParamsId)
                        }
                        this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": false })
                          .subscribe(() => { }, err => { console.log(" erro in copy z call") });
                        if (openAttachment !== undefined) {
                          console.log(" item componenet save button");
                        }

                        //Reverting the code for IA grp
                        if (indicatorValue.items[stateParamsId].data
                          && indicatorValue.items[stateParamsId].data.iaGroupId) {
                          //go through the other items on the order
                          for (let key in indicatorValue.items) {
                            indicatorValue = JSON.parse(JSON.stringify(indicatorValue));
                            if (indicatorValue.items[key].data
                              && indicatorValue.items[key].data.iaGroupId === indicatorValue.items[stateParamsId].data.iaGroupId
                              && indicatorValue.items[key].order.svItemId !== stateParamsId) {
                              //add the copysheet to the other item
                              indicatorValue.items[key].copy.copysheet = data['copysheet'];
                              indicatorValue.items[key].copy.fileType = 'pdf';
                              indicatorValue.items[key].hasCopysheet = true;
                              // update copysheetIndicator value
                              if (indicatorValue.items[key].order.productType === "Print") {
                                if (indicatorValue.items[key].model.hasOwnProperty('ManageCopysheet')) {
                                  indicatorValue.items[key].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                                  indicatorValue.items[stateParamsId].model.ManageCopysheet['copySheetIndicator'] = 'Y';
                                } else {
                                  indicatorValue.items[key].model['ManageCopysheet'] = {};
                                  indicatorValue.items[key].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                                  indicatorValue.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
                                }
                                indicatorValue.items[key].model['digitalApiFlag'] = true;
                              } else {
                                indicatorValue.items[key].model['copySheetIndicator'] = 'Y';
                                indicatorValue.items[stateParamsId].model['copySheetIndicator'] = 'Y';
                                indicatorValue.items[key].model['digitalApiFlag'] = true;
                              }

                              await this.copysheetService.updateCopysheet(indicatorValue.items[key].order.svItemId + "v" + indicatorValue.items[key].order.version, indicatorValue.items[key].copy)
                                .then(res => { }).catch(err => { console.log(" Error in update copy sheet ", err) });
                              this.updateIAGroupCounter(key, indicatorValue);
                            }
                          }
                        }
                        resolve(true);
                      } else if (data['response'].hasWarningOrError === "true") {
                        this.Params['saving'] = false;
                        this.Params['loadingbar'] = false;
                        this.loadingUpload = false;
                        var errorObject = data['response'].esbServiceFault;
                        var errorCode = errorObject.sourceSystem;
                        var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                        var errorMsg = "<strong>Transaction : " + data['response'].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        this.logger.logError(errorMsg);
                        resolve(false);
                      } else {
                        this.Params['saving'] = false;
                        this.Params['loadingbar'] = false;
                        this.loadingUpload = false;
                        this.logger.logError(data['message']);
                        resolve(false);
                      }
                    })
                    .catch((response) => {
                      this.loadingUpload = false;
                      this.loadingCopysheet = false;
                      this.loadingUpload = false;
                      if (response.response && response.response.hasWarningOrError == "true") {
                        var errorObject = response.response.esbServiceFault;
                        var errorCode = errorObject.sourceSystem;
                        var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                        var errorMsg = "<strong>Transaction : " + response.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                        this.logger.logError(errorMsg);
                        resolve(false);
                      } else {
                        this.logger.logError(response.message);
                        resolve(false);
                      }
                    });
                })
                .catch(err => {
                  this.loadingUpload = false;
                  this.Params['saving'] = false;
                  this.Params['loadingbar'] = false;
                  resolve(false);
                });
            } else {
              this.loadingUpload = false;
              this.Params['saving'] = false;
              this.Params['loadingbar'] = false;
              var errorObject = data.esbServiceFault[0];
              var errorCode = errorObject.sourceSystem;
              var errorDesc = errorObject.exceptionDetail[0].errorDescription;
              var errorMsg = "<strong>Transaction : " + data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
              this.logger.logError(errorMsg);
              resolve(false);
            }
          }).catch((response) => {
            this.loadingUpload = false;
            this.Params['saving'] = false;
            this.Params['loadingbar'] = false;
            resolve(false);
          });
        } else {
          resolve(false);
        }
      }).catch(err => {
        this.loadingUpload = false;
        this.Params['saving'] = false;
        this.Params['loadingbar'] = false;
        resolve(false);
      });
    })
  }

  newAnnotateCopysheet(Indicator, stateParamsId, page?) {
    let copysheet;
    let fileType;

    return new Promise(async (resolve) => {

      if (Indicator.items[stateParamsId].copy && Indicator.items[stateParamsId].copy.copysheet != 'null') {
        if (!Indicator.items[stateParamsId].model.copySheetSavePoints) {
          Indicator.items[stateParamsId].model.copySheetSavePoints = {}
        }
        if (Indicator.items[stateParamsId].copy.hasOwnProperty('attachments')) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.attachments = Indicator.items[stateParamsId].copy.attachments
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.attachments = []
        }
        Indicator.items[stateParamsId].model.copySheetSavePoints.copysheetSavePoint = Indicator.items[stateParamsId].copy.copysheet
        if (Indicator.items[stateParamsId].actionListObj) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.actionListObj = Indicator.items[stateParamsId].actionListObj
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.actionListObj = []
        }
        Indicator.items[stateParamsId].model.copySheetSavePoints.ManageCopysheet = Indicator.items[stateParamsId].model.ManageCopysheet
        if (Indicator.items[stateParamsId].hasCopysheet) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.hasCopysheet = Indicator.items[stateParamsId].hasCopysheet
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.hasCopysheet = false
        }
        if (Indicator.items[stateParamsId].isCorrupted) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.isCorrupted = Indicator.items[stateParamsId].isCorrupted
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.isCorrupted = false
        }
        if (Indicator.items[stateParamsId].isCoysheetSubmitted) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = Indicator.items[stateParamsId].isCoysheetSubmitted
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted = false
        }
        if (Indicator.items[stateParamsId].generatedCopysheet) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.generatedCopysheet = Indicator.items[stateParamsId].generatedCopysheet
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.generatedCopysheet = false
        }
        if (Indicator.items[stateParamsId].isCopysheetSaved) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.isCopysheetSaved = Indicator.items[stateParamsId].isCopysheetSaved
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.isCopysheetSaved = false
        }
        if (Indicator.items[stateParamsId].needsNewCopysheet) {
          Indicator.items[stateParamsId].model.copySheetSavePoints.needsNewCopysheet = Indicator.items[stateParamsId].needsNewCopysheet
        } else {
          Indicator.items[stateParamsId].model.copySheetSavePoints.needsNewCopysheet = false
        }

        this.indicatorStore.update(state => {
          return {
            indicator: Indicator,
            updatedFrom: "[ManagecopysheetService] annotateCopysheet savePoint",
          }
        })
        var CSfileType = "";
        copysheet = Indicator.items[stateParamsId].baseCopysheet ? Indicator.items[stateParamsId].baseCopysheet : Indicator.items[stateParamsId].copy.copysheet;
        if (copysheet) {
          var CSURL = copysheet;
          CSfileType = CSURL.slice(CSURL.lastIndexOf(".") + 1, CSURL.length);
        }
        if (copysheet) {
          //updateCopysheet indicator
          if (Indicator.items[stateParamsId].model['ManageCopysheet'] && typeof (Indicator.items[stateParamsId].model['ManageCopysheet']) == "object") {
            Indicator.items[stateParamsId].model['ManageCopysheet']['copySheetIndicator'] = 'Y';
          }
          else {
            Indicator.items[stateParamsId].model['ManageCopysheet'] = 'Y';
          }
          Indicator.items[stateParamsId].model['digitalApiFlag'] = true;
          var fileNameLocal = copysheet.split('/').pop();
          if (CSfileType == 'pdf') {
            try {
              this.file.validatePDF(fileNameLocal).subscribe(res => {
                if (res['data'] == "Invalid PDF structure") {
                  this.logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");

                  Indicator.items[stateParamsId].hasCopysheet = false;
                  this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
                    .subscribe(() => { }, err => { })

                  Indicator.items[stateParamsId].copy.copysheet = null;
                  Indicator.items[stateParamsId].copy.copysheet = null;
                  Indicator.items[stateParamsId].copy.fileType = '';
                  if (Indicator.items[stateParamsId].requiresCopysheet) {
                    // Indicator.items[stateParamsId].counter++;
                    let tempData = this.requiredfieldcountService.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
                    this.copysheetService.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
                    if (Indicator['items'][stateParamsId].counter == 0) {
                      document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
                    } else if (Indicator['items'][stateParamsId].counter != 0) {
                      document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
                    }
                  }
                } else { }
              }, err => {
                //console.log("error in vlaidating the pdf file ", err);
                this.logger.logError("Unable to generate existing Ad from kGen due to the file being corrupted.  Please upload a valid PDF file then View-Annotate activity can be performed to make changes as needed! ");
                return false;
              });
            } catch (e) {
              //console.log(" error in annotate copy sheet ", e)
              this.logger.logError("Timeout Error");
            }
          } else if (CSfileType == 'png' || CSfileType == 'jpg' || CSfileType == 'jpeg') {
            this.file.validateImage(fileNameLocal).subscribe((res) => {
              if (res['data'] == false) {

                this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false, "data.isCorrupted": true })
                  .subscribe(() => { }, err => { })
                Indicator.items[stateParamsId].copy.copysheet = null;
                Indicator.items[stateParamsId].copy.copysheet = null;
                Indicator.items[stateParamsId].copy.fileType = '';
                Indicator.items[stateParamsId].hasCopysheet = false;
                if (Indicator.items[stateParamsId].requiresCopysheet) {
                  let tempData = this.requiredfieldcountService.getNewItemCount(Indicator["items"][stateParamsId].requiredFields, Indicator["items"][stateParamsId].model, Indicator["items"][stateParamsId].order, Indicator["orderPercent"], Indicator["items"][stateParamsId].counter, true, Indicator["items"][stateParamsId]);
                  this.copysheetService.updateIndictor(stateParamsId, Indicator, tempData, Indicator.items[stateParamsId].counter);
                  if (Indicator['items'][stateParamsId].counter == 0) {
                    document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
                  } else if (Indicator['items'][stateParamsId].counter != 0) {
                    document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
                  }
                }
              }
            });
          }
        }
        fileType = Indicator.items[stateParamsId].copy.fileType;
        if ((fileType && CSfileType && fileType !== CSfileType)
          || (!fileType && CSfileType)) {
          fileType = CSfileType;
        } else if (!fileType && !CSfileType) {
          this.logger.logError("Failed to open copysheet. Please upload the copysheet again.");
        }
        Indicator.items[stateParamsId].baseCopysheet = copysheet;
        resolve(true);
      }
    })
  }
}
