import { Component, OnInit } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-proposal-id',
  templateUrl: './proposal-id.component.html',
  styleUrls: ['./proposal-id.component.scss']
})
export class ProposalIdComponent implements OnInit {
  indicator;
  schemaObj;
  isRequiredField ;
  customField ;
  titleContent ;
  isReadonly ;
  errorFlag :boolean =false;
  errorMsg ;

  constructor( private indicatorQuery: IndicatorQuery
    ,private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicator = JSON.parse(JSON.stringify(res));

    });
    this.schemaObj = this.indicator.items[this.indicator.stateParamsId].schema.properties[this['layoutNode'].name];
    this.isRequiredField = this.schemaObj.required;
    this.titleContent =  this.schemaObj.title;
    this.isReadonly = this.schemaObj.readonly;
    if (this['layoutNode'].name == "ProposalID") {
      this.customField = this.indicator.items[this.indicator.stateParamsId].model[this['layoutNode'].name];

     }
  }
  sendValue(args) {
    let tempValue;;
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    modelData.items[this.indicator.stateParamsId].model[this['layoutNode'].name] = args;
    let maxLength =modelData.items[this.indicator.stateParamsId].schema.properties[this['layoutNode'].name].maxLength;
    if(this['layoutNode'].name === "ProposalID"){
      if (args.length != maxLength) {
        this.errorFlag = true;
        tempValue = '';
        modelData.items[this.indicator.stateParamsId].model['ProposalID'] = '';
        this.errorMsg =  args.length > 5 ? 'String is too long (' + args.length + ' chars), maximum 5':"Expected Atleast 5 digits(Only digits)";
      } else {
        tempValue = args;
        modelData.items[this.indicator.stateParamsId].model['ProposalID'] = args;
        this.errorFlag = false;

      }
      //update store;
      let x = JSON.parse(JSON.stringify(this.indicator));
      x = modelData
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ProposalIdComponent] sendValue",
        }
      });
      // this.indicatorQuery.updateModel(this['layoutNode'].name, tempValue,  modelData, tempObj);

    }
  }

}
