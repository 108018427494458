import  * as $ from 'jquery';
import { Router } from '@angular/router';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { OnInit } from '@angular/core';
var that;
export class GoldenlistingModel implements OnInit{
    indicatorValue :Quickbp
    subscribe: any
    constructor(private router: Router,
               private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) {
                this.indicatorQuery.getIndicator().subscribe((res) => {
                  this.indicatorValue = { ...res };
                  that = this;
                });
    }
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }
    columns: any ={
        "goldenListingGrid":[
            {
                title: '',
                field: 'id',
                titleFormatter:"",
                width:30,
                formatter: function (cell) {
                  let listingId = cell.getData().listingId;
                  return `<input type="radio" class="select-row" name='goldenListingRadio' id= ${listingId} readonly style="opacity: 1;"/>`;
                },

                headerSort: false,
                cssClass: 'text-center',
                frozen: true,
                cellClick: function (e, cell) {
                  let indicatorValue = JSON.parse(JSON.stringify(that.indicatorValue));
                  let goldenlistingSelected = cell.getRow().getData().listingId;
                  indicatorValue['goldenlistingSelected'] = goldenlistingSelected;
                  that.indicatorStore.update((state) => {
                    return {
                      indicator : indicatorValue,
                      updatedFrom: "[GoldenlistingModel] ngoninit",
                      isloaded: true,
                    }
                  });
                }
              },
            {title:"BTN", field:"BTN",responsive:0, formatter: function (cell) {
                let listingId = cell.getData().listingId;
                let indicatorValue = JSON.parse(JSON.stringify(that.indicatorValue));
                if(indicatorValue.goldenListingData && indicatorValue.goldenListingData.goldenRecord &&  indicatorValue.goldenListingData.goldenRecord == listingId)
                 return "<span style='color:gold; font-weight:bold;'>" + cell.getData().BTN + "</span>";
                 else
                 return cell.getData().BTN;
              }
        },
            {title:"Name", field:"businessName",formatter: function (cell) {
                let listingId = cell.getData().listingId;
                let indicatorValue = JSON.parse(JSON.stringify(that.indicatorValue));
                if(indicatorValue.goldenListingData && indicatorValue.goldenListingData.goldenRecord &&  indicatorValue.goldenListingData.goldenRecord == listingId)
                 return "<span style='color:gold; font-weight:bold;'>" + cell.getData().businessName + "</span>";
                 else
                 return cell.getData().businessName;
              }},
            {title:"Address", field:"address",formatter: function (cell) {
                let listingId = cell.getData().listingId;
                let indicatorValue = JSON.parse(JSON.stringify(that.indicatorValue));
                if(indicatorValue.goldenListingData && indicatorValue.goldenListingData.goldenRecord &&  indicatorValue.goldenListingData.goldenRecord == listingId)
                 return "<span style='color:gold; font-weight:bold;'>" + cell.getData().address + "</span>";
                 else
                 return cell.getData().address;
              }
            },
            {title:"Account ID", field:"accountId",formatter: function (cell) {
                let listingId = cell.getData().listingId;
                let indicatorValue = JSON.parse(JSON.stringify(that.indicatorValue));
                if(indicatorValue.goldenListingData && indicatorValue.goldenListingData.goldenRecord &&  indicatorValue.goldenListingData.goldenRecord == listingId)
                 return "<span style='color:gold; font-weight:bold;'>" + cell.getData().accountId + "</span>";
                 else
                 return cell.getData().accountId;
              }},
            {title:"Listing ID", field:"listingId",formatter: function (cell) {
                let listingId = cell.getData().listingId;
                let indicatorValue = JSON.parse(JSON.stringify(that.indicatorValue));
                if(indicatorValue.goldenListingData && indicatorValue.goldenListingData.goldenRecord &&  indicatorValue.goldenListingData.goldenRecord == listingId)
                 return "<span style='color:gold; font-weight:bold;'>" + cell.getData().listingId + "</span>";
                 else
                 return cell.getData().listingId;
              }},
        ],
    }
}
