import { Component, OnInit } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-item-category',
  templateUrl: './item-category.component.html',
  styleUrls: ['./item-category.component.scss']
})
export class ItemCategoryComponent implements OnInit {
  options;
  Indicator;
  titleContent;
  isReadonly;
  customField;
  categoryDisplay;
  categoryValue;
  catArray =[];
  constructor(private indicatorQuery :IndicatorQuery,
    private indicatorStore :IndicatorStore) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));     
    });
    this.titleContent = this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name].title;
    this.isReadonly =this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name].readonly;
    if (this['layoutNode'].name == "Category"){
      this.customField = this.Indicator.items[this.Indicator.stateParamsId].model.Category;
      if(this.Indicator.items[this.Indicator.stateParamsId].order.category) {
       this.categoryDisplay = JSON.parse(this.Indicator.items[this.Indicator.stateParamsId].order.category)[0].displayedValue;
       this.categoryValue = JSON.parse(this.Indicator.items[this.Indicator.stateParamsId].order.category)[0].value;
    } 
    }      
    
  }

}
