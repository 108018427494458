import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { RouterresolveService } from './core/service/routerresolve/routerresolve.service';
import { OktaAuthGuard } from './app.guard';
import { CallbackComponent } from './callback.components';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';


const routes: Routes = [
  { path: 'callback', component: CallbackComponent },
  {path:'accessdenied', component: AccessDeniedComponent},
    {
      path: '',
      component: MainComponent,
      canActivate: [OktaAuthGuard],
      children: [
        {
          path: 'orderitems',
          loadChildren: () => import('./modules/order-items/order-items.module').then(m => m.OrderItemsModule),
          resolve: { items: RouterresolveService }
        },
        {
          path: 'printitems',
          loadChildren: () => import('./modules/print-items/print-items.module').then(m => m.PrintItemsModule)
        },
        {
          path: 'graphicitems',
          loadChildren: () => import('./modules/graphic-items/graphic-items.module').then(m => m.GraphicItemsModule)
        },
        {
          path: 'digitalitems',
          loadChildren: () => import('./modules/digital-items/digital-items.module').then(m => m.DigitalItemsModule)
        },
        {
          path: 'business',
          loadChildren: () => import('./modules/business-profiles/business-profiles.module').then(m => m.BusinessProfilesModule)
        },
        {
          path: 'publish',
          loadChildren: () => import('./modules/publish/publish.module').then(m => m.PublishModule)
        },
        {
          path: 'billingaccounts',
          loadChildren: () => import('./modules/billing-accounts/billing-accounts.module').then(m => m.BillingAccountsModule)
        },
        {
          path: 'transaction',
          loadChildren: () => import('./modules/transaction/transaction.module').then(m => m.TransactionModule)
        },
        {
          path: 'tools',
          loadChildren: () => import('./modules/tools/tools.module').then(m => m.ToolsModule)
        },
        { path: "", redirectTo: "/orderitems", pathMatch: "full" }
       // { path: '**', redirectTo: '/orderitems', pathMatch: "full" }
      //  {
      //   path: 'pdf',
      //   loadChildren: () => import('./modules/pdf-module/pdf.module').then(m => m.pdfModule),          
      //   resolve: { items: RouterresolveService }
      // }
      ]
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
