import { Component, OnInit } from '@angular/core';
import { ComponentStore } from 'app/state/component.state';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { callTypeDigitalObj, callTypePrintObj } from 'app/data/constants';

@Component({
  selector: 'app-callselect',
  templateUrl: './callselect.component.html',
  styleUrls: ['./callselect.component.scss']
})
export class CallselectComponent implements OnInit {
  options;
  customField;
  id; 
  sharedData: any;
  titleContent: string;
  constructor(private componnentStore: ComponentStore,private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;  
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
    });
    if(this['layoutNode'].name === "calltrackingtype"){
      this.titleContent = "Call Tracking";
      if(this.sharedData.items[this.sharedData.stateParamsId].order.productType === "Digital"){
        this.options = callTypeDigitalObj;
        this.customField = "Local Number";
      }else{
        this.options = callTypePrintObj;
        this.customField = "Local Number";
      }
      
    }
  }
  sendValue(args) {
    if(this['layoutNode'].name === "calltrackingtype"){
      this.componnentStore.update(()=>{
        return {
          componentValue:args
        }
      })
    }
  }

}
