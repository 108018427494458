import { ChangeDetectorRef, Component, Inject, OnInit, ViewRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AccountService } from '@app/service/account/account.service';
import { AdService } from '@app/service/ad/ad.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CallconfirmationComponent } from '../call-new/callconfirmation/callconfirmation.component';

@Component({
    selector: 'app-ad-based',
    templateUrl: './ad-based.component.html',
    styleUrls: ['./ad-based.component.scss']
})
export class AdBasedComponent implements OnInit {
    oldRef: any;
    constructor(
        private accountService: AccountService,
        private adService: AdService,
        private Copysheet: CopysheetService,
        private Logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private indicatorQuery: IndicatorQuery,
        private indicatorStore: IndicatorStore,
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
    ) { }

    Indicator: any;
    addSearch: any;
    ads = [];
    kgenCustomerId: any; pageCount: any;
    totalItems: any; itemsPerPage: any; currentPage: any; maxSize: any;
    filteredAds: any;
    result: string = '';
    ngOnInit() {
        this.oldRef = this.data.dialogform.dilogReference;
        this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify((data)));
        });
        this.accountService.getMe().subscribe(accounts => {
            this.kgenCustomerId = accounts.kgenCustomerId;
            this.adService.getItems(this.kgenCustomerId,"Ad-GET-ITEMPAGE")
                .subscribe(ads => {
                    this.ads = ads['details'];
                    this.totalItems = (this.ads) ? this.ads.length : 0;
                    this.itemsPerPage = 8;
                    this.currentPage = 1;
                    this.maxSize = 5;
                    this.pageCount = Math.ceil( this.totalItems / this.itemsPerPage);
                    this.change(this.currentPage);
                }, err => {
                    console.log("Error in calling the ad service ", err);
                });
        }, err => {
            console.log("Error in account service :", err)
        });
    }

    search() {
        if (this.addSearch) {
            this.filteredAds = this.ads;
          this.filteredAds = this.filteredAds.filter((ele, i, array) => {
              let searchword = '';
            searchword = searchword + ele.headingName.toLowerCase() +',';
            searchword = searchword + ele.product.toLowerCase() +',';
            searchword = searchword + ele.udac.toLowerCase() +',';
            searchword = searchword + ele.listedName.toLowerCase() +',';
            searchword = searchword + ele.listedTn.toLowerCase() +',';
            searchword = searchword + ele.displayAdId.toLowerCase();
            return searchword.includes(this.addSearch)
          })
        }else{
            return this.filteredAds = this.ads;
        }
      }

    getAdUrl(ad) {
            return this.adService.getAd(ad.displayAdId, ad.displayAdIdPrefix, ad.graphicRegion);
    };
    ngAfterViewChecked() {
        if (!(<ViewRef>this.cdr).destroyed)
            this.cdr.detectChanges();
	}

    change(currentPage) {
        this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify((data)));
        });
        this.currentPage = currentPage;
        let begin = ((this.currentPage - 1) * this.itemsPerPage);
        let end = begin + this.itemsPerPage;

        if (this.ads !== undefined) {
            this.filteredAds = this.ads.slice(begin, end);
        }

        if (this.Indicator && this.Indicator.items && this.Indicator.items[this.Indicator.stateParamsId] && this.Indicator.items[this.Indicator.stateParamsId].model
            && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
            && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId) {
            for (let i = 0; i < this.filteredAds.length; i++) {
                if (this.filteredAds[i].displayAdId === this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId) {
                    this.filteredAds[i].selectedAd = true;
                } else {
                    this.filteredAds[i].selectedAd = false;
                }
            }
        }
    }

    selectAd(ad) {
        this.indicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify((data)));
        });
       try{
        let msg= 'Are you sure you want to continue? ';
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          message: msg,
        }
        dialogConfig.width = '600px';
        const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
        dialogRef.afterClosed().subscribe(dialogResult => {
          this.result = dialogResult;
          if (this.result) {
              if (ad.displayAdId === this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId) {
                  this.Indicator.items[this.Indicator.stateParamsId].copy.ad = undefined;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.displayAdId = undefined;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.displayAdIdPrefix = undefined;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.graphicRegion = undefined;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.headingName = undefined;


                  this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId = undefined;
                  this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdIdPrefix = undefined;
                  this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = "Y";


                  if (this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
                      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== ""
                      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== undefined) {
                      //go through the other items on the order
                      this.Indicator.items.forEach((item, index) => {
                          //if the other item has the matching ia group id & is not the current item
                          if (item.model.ManageCopysheet
                              && item.model.ManageCopysheet.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId
                              && item.order.svItemId !== this.Indicator.stateParamsId) {

                              //add the set based on ad to the other item
                              //grouper.removeSetBasedOnAd(item);
                          }
                      });
                  }

                  this.Copysheet.updateCopysheet(this.Indicator.items[this.Indicator.stateParamsId].order.svItemId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].copy)
                      .then(() => {
                          this.Logger.logSuccess("Successfully removed based on ad");
                          this.oldRef.close(this.Indicator.items[this.Indicator.stateParamsId].copy);

                      }).catch(err => {
                          this.Logger.logError("Error in removing set based on ad");
                      });

              } else {
                  this.Indicator.items[this.Indicator.stateParamsId].copy.ad = this.getAdUrl(ad);
                  this.Indicator.items[this.Indicator.stateParamsId].copy.displayAdId = ad.displayAdId;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.displayAdIdPrefix = ad.displayAdIdPrefix;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.graphicRegion = ad.graphicRegion;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.headingName = ad.headingName;
                  this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.baseAdId = ad.displayAdId;
                  //For 2nd page:
                  this.Indicator.items[this.Indicator.stateParamsId].copy.adId = ad.adId;
                  var adIdArray = [];
                  for (var key in this.Indicator.items) {
                      if (this.Indicator.items[key].data.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].data.iaGroupId) {
                          adIdArray.push({ directoryName: this.Indicator.items[key].order.directoryName, directoryNumber: this.Indicator.items[key].order.directoryNumber, headingCode: this.Indicator.items[key].order.headingCode })
                      }
                  }
                  // this.Indicator.items[this.Indicator.stateParamsId].copy.directoryName = Indicator.items[this.Indicator.stateParamsId].order.directoryName;
                  // this.Indicator.items[this.Indicator.stateParamsId].copy.directoryNumber = Indicator.items[this.Indicator.stateParamsId].order.directoryNumber;
                  // this.Indicator.items[this.Indicator.stateParamsId].copy.headingCode = Indicator.items[this.Indicator.stateParamsId].order.headingCode;
                  this.Indicator.items[this.Indicator.stateParamsId].copy.adIdArray = adIdArray;
                  //if the item is part of an IA group
                  if (this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet
                      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== ""
                      && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId !== undefined) {
                      //go through the other items on the order
                      for (const key in this.Indicator.items) {
                          //if the other item has the matching ia group id & is not the current item
                          if (this.Indicator.items[key].model.ManageCopysheet
                              && this.Indicator.items[key].model.ManageCopysheet.iaGroupId === this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet.iaGroupId
                              && this.Indicator.items[key].order.svItemId !== this.Indicator.stateParamsId) {

                              //add the set based on ad to the other item
                              this.Indicator.items[key].copy.ad = this.getAdUrl(ad);
                              this.Indicator.items[key].copy.displayAdId = ad.displayAdId;
                              this.Indicator.items[key].copy.displayAdIdPrefix = ad.displayAdIdPrefix;
                              this.Indicator.items[key].copy.graphicRegion = ad.graphicRegion;
                              this.Indicator.items[key].copy.headingName = ad.headingName;

                              this.Indicator.items[key].model.ManageCopysheet.baseAdId = ad.displayAdId;
                              this.Indicator.items[key].model.ManageCopysheet.baseAdIdPrefix = ad.displayAdIdPrefix;

                              this.Copysheet.updateCopysheet(this.Indicator.items[key].order.svItemId + "v" + this.Indicator.items[key].order.version, this.Indicator.items[key].copy)
                                  .then(() => { })
                                  .catch((err) => {
                                      this.Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
                                  });
                          }
                      }
                  }

                  this.Copysheet.updateCopysheet(this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].copy)
                      .then(() => {
                          this.oldRef.close(this.Indicator.items[this.Indicator.stateParamsId].copy);
                          this.Logger.logSuccess("Successfully set based on ad");
                      }).catch((err) => {
                          this.Logger.logError("Error in setting based on ad");
                      });
              }
              //this.change(this.currentPage);
          }else{
              //this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet['baseAdIdSelected'] = false;
          }
        });
        this.indicatorStore.update(state =>{
            return{
              indicator: this.Indicator,
              updatedFrom: "[AdBasedComponent] selectAd"
            }
          })
        this.ngAfterViewChecked();
} catch(err){
   console.log(err);
}
    };

    closeModal() {
        this.oldRef.close();
    };

}
