import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewRef} from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MatDialogConfig, MatDialog, getMatIconFailedToSanitizeUrlError } from '@angular/material';
import Tabulator from 'tabulator-tables';
import { TransformerService } from '@app/service/transformer/transformer.service';
import { CallService } from '@app/service/call/call.service';
import { CalltrackingdropdownComponent } from '../calltrackingdropdown/calltrackingdropdown.component';
import { CallserviceService } from '../callservice.service';
import { CalltrackingService } from '@app/service/call/calltracking.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { CallconfirmationComponent } from '../callconfirmation/callconfirmation.component';
import { StorageService } from '@app/service/storage/storage.service';
import { GroupService } from '@app/service/group/group.service';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { ComponentQuery } from 'app/state/component.query';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ComponentStore } from 'app/state/component.state';
import {CopySheetComponent} from '../../copy-sheet/copy-sheet.component';
import { UtilsService } from '@app/service/utils/utils.service';
import { CounterService } from '@app/service/counter/counter.service';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

var vm;
@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})

export class CallComponent implements OnInit, OnDestroy{
  titleContent: any;
  options: any;
  indicator;
  calltrackingOption = ['Yes', 'No'];
  selectedOption: string;
  showlink:boolean;
  indicatorValue: any;
  callTrackingData: any =[];
  callTrackingTable: any;
  productType:string;
  itemId :string;
  productCode: string;
  selectedItem: any;
  isCTEnabledTouched = false;
  ypcTypeFlag: boolean = false;
  showDropdown: boolean = true;
  ctModel: any;
  TrackNumberArray:any = [];
  trackarr:any =[];
  showTable: boolean;
  TmpTrackNumberArray: any[];
  accountId: any;
  calltrackincheck:boolean = false;
  ctModelPrint:any;
  ngModel: any;
  isCalltrackingAdded:boolean = false;
  result: string = '';
  storageID:any;
  publishStatus:any;
  isReadonly:boolean = false;
  destroySubject$: Subject<void> = new Subject();
  itemPageFlage: boolean = false;
  changeModelFlag:boolean = false;
  splitItemFromIA: boolean = false;
  transactionId: any;

  constructor(private callshared: CallserviceService,
    private call:CallService,
    private transformer:TransformerService,
    private matDialog: MatDialog,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private calltracking:CalltrackingService,
    private logger: LoggerService,
    private storageservice:StorageService,
    private cdr: ChangeDetectorRef,
    private grouper : GroupService,
    private copysheet: CopysheetService,
    private requiredfieldcount: RequiredfieldcountService,
    private componentQuery :ComponentQuery,
    private componentStore: ComponentStore,
    private utils:UtilsService,
    private counterService: CounterService,
    private spinnerService: NgxSpinnerService
    ) { }


  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
      vm = this;
    });
    let updateddata = JSON.parse(JSON.stringify(this.indicator));
    this.selectedItem = this.indicator.items[this.indicator.stateParamsId].order;
    //for Renew, always get the model value from Indicator. If not present then set the modelValue to No.
    //for New, if the model value is not available then set to Yes
    if(this.selectedItem.actionCode === 'Add' && this.selectedItem.actionType === 'New'){
      //console.log('modelValue'+this.indicator.items[this.indicator.stateParamsId].model.modelValue)
      this.selectedOption =this.indicator.items[this.indicator.stateParamsId].model.modelValue ? this.indicator.items[this.indicator.stateParamsId].model.modelValue : 'Yes';
    } else{
      this.selectedOption =this.indicator.items[this.indicator.stateParamsId].model.modelValue ? this.indicator.items[this.indicator.stateParamsId].model.modelValue : 'No';
    }

    //(this.selectedOption === 'Yes') ? this.showResult(true) : this.showResult(false);
    if (!this.indicator.items[this.indicator.stateParamsId].model.modelValue){
      updateddata.items[updateddata.stateParamsId].model['modelValue'] = this.selectedOption;
    }
    this.columnModelPrint();
    this.columnModelDigital();
    this.productType = this.selectedItem.productType;
    this.itemId = this.selectedItem.svItemId;
    this.productCode = this.selectedItem.productCode;
    this.accountId = this.indicator.account.kgenCustomerId;
    this.storageID = this.indicator.stateParamsId + "v" + this.selectedItem.version;
    this.publishStatus = ['In Fulfillment','Digital Fulfillment'];
    if ((this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker")) {
      this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data =>{
        //console.log('component query'+data)
        this.indicatorQuery.getIndicator().subscribe(data => {
          this.indicator = {...data};
        });
        this.indicator = JSON.parse(JSON.stringify(this.indicator))
      if(data  === "Yes" || data === "No"){
        this.selectedOption = data;
        this.showLinkMethod()
        this.ngAfterViewChecked();
        if (this.selectedOption == "No"){
          //console.log('show link false')
          this.isReadonly = false;
          if (this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0){
              this.terminateCT();
          }
          this.ngAfterViewChecked();
        }else{
          //console.log('inside YES item model' + JSON.stringify(this.indicator.items[this.indicator.stateParamsId].calltracking))
          //console.log('model account id'+this.indicator.items[this.indicator.stateParamsId].model.AccountId.accountId+'9iuhgtf')
          if(typeof this.indicator.items[this.indicator.stateParamsId].model.AccountId === 'string'){
              //console.log('inside string init'+this.indicator.items[this.indicator.stateParamsId].model.AccountId)
              if(this.indicator.items[this.indicator.stateParamsId].model.AccountId == undefined || this.indicator.items[this.indicator.stateParamsId].model.AccountId == null || this.indicator.items[this.indicator.stateParamsId].model.AccountId == ''){
                //console.log('inside making readonly false init 2'+this.indicator.items[this.indicator.stateParamsId].model.AccountId)
                this.isReadonly = true;
                this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
              }else{
                this.isReadonly = false;
                this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
              }
          }else{
            if(this.indicator.items[this.indicator.stateParamsId].model.AccountId!=undefined && this.indicator.items[this.indicator.stateParamsId].model.AccountId!=null && this.indicator.items[this.indicator.stateParamsId].model.AccountId!=null && Object.keys(this.indicator.items[this.indicator.stateParamsId].model.AccountId).length > 0){
              let accountKeys = Object.keys(this.indicator.items[this.indicator.stateParamsId].model.AccountId);
              accountKeys.forEach((item, index) =>{
                if(item == "accountId"){
                  if(this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] == undefined || this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] == null || this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] == ""){
                   // console.log('inside making readonly false 1'+JSON.stringify(this.indicator.items[this.indicator.stateParamsId].model.AccountId))
                    this.isReadonly = true;
                    this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
                  }else{
                    this.isReadonly = false;
                    this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
                  }
                }
              })
            }else{
              this.isReadonly = true;
              this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
            }
          }
          this.storageservice.updateField('call',this.storageID,{ "data.callTracking": this.indicator.items[this.indicator.stateParamsId].calltracking })
          .subscribe(()=>{},error=>{
           // console.log("error is:",error);
          });
        }
        this.ngAfterViewChecked();
      }

       // console.log('before load digital data items' + JSON.stringify(this.indicator.items))
        this.loadDigitalData(); //FUL-20568 auto grid refresh on change of SEM Account ID
        // Making it null when differnt account id has same value.
        data = null;
        this.componentStore.update(() => {
          return {
            componentValue: data,
            // semAccount: this.ngModel['accountId']
          }
        });
      });
    }
    if (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker") {
          this.titleContent = "Call Tracking Enabled"
          //TODO why always set to true
          //this.isReadonly = true;
          //updateddata.items[updateddata.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      } else{
      this.titleContent = this.indicator['items'][this.indicator.stateParamsId]['schema']['properties']['CallTracking']['title'];
    }
    if(this.indicator.items[this.indicator.stateParamsId].order.statusCode >= 6000){
     // console.log('inside readonly 2')
      this.isReadonly = true;
      updateddata.items[updateddata.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      //console.log('show link false 2')
      //this.showlink = false;
    }
    //this.indicator.items[this.indicator.stateParamsId].model.modelValue =='No'? this.selectedOption:this.callChange(this.selectedOption, false);

    this.storageCall();
    this.loadCTs();
    this.showLinkMethod();
    this.CheckAccountType();
  }

  CheckAccountType(): void{
    const country = this.indicator.account.address.country;
    console.log("country", country)
    if(country === 'NZ') {
      this.selectedOption = 'No';
      this.isReadonly = true;
      this.showlink = false;
    }
  }

  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }
  private storageCall(){
    this.indicator =  JSON.parse(JSON.stringify(this.indicator));
    this.storageservice.getField("item", this.storageID).then(data => {
      let flag = false; //this flag is used  for hiding gridtable if calltracking none
      for (let item in this.indicator.items) {
          if (this.indicator.items[item].calltracking.length > 0 && this.indicator.items[item].order.productType === "Print") {
              flag = true;
          }
      }
      if (data[this.storageID] !== undefined && data[this.storageID].modelValue == undefined && (flag || this.productType === "Digital")) {
        this.selectedOption = this.selectedOption || "Yes";
          this.indicator.items[this.indicator.stateParamsId].model['modelValue'] = '';
          this.indicator.items[this.indicator.stateParamsId].model['modelValue'] = this.selectedOption;
          if (this.selectedOption === "Yes" && (this.productCode === 'Single IYP' || this.productCode === 'YPC') && this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0 && (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New' || this.indicator.items[this.indicator.stateParamsId].order.actionType === 'Update')) {
              this.showTable = true;
              //console.log('show link false 3')
              //this.showlink = false;
          } else if (this.selectedOption === "Yes" && this.productType === "Digital" && this.indicator.items[this.indicator.stateParamsId].calltracking.length <= 0 && this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
              //console.log('inside showlink 1')
              //this.showlink = true;
              this.showTable = true;
          } else {
            ///console.log('showLink true no 1')
              //this.showlink = true;
              this.showTable = true;
          }
      } else if (data[this.storageID].modelValue) {
          /*if (this.indicator.items[this.indicator.stateParamsId].calltracking && this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0)
          {
              this.selectedOption = "Yes";
          }
          else
          {
              this.selectedOption = data[this.storageID].selectedOption;
          }*/
          this.selectedOption = data[this.storageID].modelValue
          this.indicator = JSON.parse(JSON.stringify(this.indicator));
          this.indicator.items[this.indicator.stateParamsId].model['modelValue'] = '';
          this.indicator.items[this.indicator.stateParamsId].model['modelValue'] = this.selectedOption;
          if (this.selectedOption == 'Yes' && (this.productCode === 'Single IYP' || this.productCode === 'YPC') && this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0 && this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
            //console.log('show link false 4')
              //this.showlink = false;
              this.showTable = true;
          } else if (this.selectedOption == 'Yes' && flag) {
            //console.log('inside showlink 2')
              //this.showlink = true;
              this.showTable = true;
          } else if (this.selectedOption == 'No') {
            //console.log('show link false 5')
              //this.showlink = false;
              this.showTable = false;
          } else if (this.selectedOption == 'Yes') {//TODO if selected option is yes why showTable = false
            //console.log('inside showlink 3')
              //this.showlink = true;
              //this.showTable = false;
          }
      }
      if (((this.selectedItem.actionCode === 'Renew' && this.selectedItem.productType === 'Digital')|| this.publishStatus.indexOf(this.selectedItem.status) > -1) || this.selectedItem.statusCode > 6000) {
          //this.showlink = this.selectedOption === 'Yes' ? true : false; - link should not be shown other than SEM items for Digital renewal
              if(this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker"){
                  if ( this.indicator.items[this.indicator.stateParamsId].calltracking && this.indicator.items[this.indicator.stateParamsId].calltracking.length == 0 && this.selectedItem.actionCode === 'Renew'){
                      if ((data[this.storageID].callTracking && data[this.storageID].callTracking.length > 0) || data.selectedOption == 'Yes'){
                          let newTrackingArr = [];
                          this.indicator.items[this.indicator.stateParamsId].calltracking = data[this.storageID].callTracking;
                          //this.selectedOption = "Yes";
                          this.loadDigitalData();
                          this.showTable = true;
                          //console.log('inside showlink 6')
                          //this.showlink = true;
                          data[this.storageID].callTracking.forEach(function(tracking,ind){
                              let obj={
                                  DestinationNumber:tracking.terminationNumber,
                                  Source: tracking.distributionType,
                                  TrackingId: tracking.callTrackingId
                              }
                              newTrackingArr.push(obj);
                          })
                          this.indicator.items[this.indicator.stateParamsId].model.CallTracking = newTrackingArr;
                          this.indicator.items[this.indicator.stateParamsId].model.CallTracking.CallTrackingCount = newTrackingArr.length;
                          //this.showlink = true;
                      }else{
                        /* FUL-24604 - fix
                         */
                        //this.selectedOption = "No";
                        //this.showlink = false;
                        //console.log('show link false 6')
                        let localIndicator = this.indicator;
                        // this.selectedOption = this.indicator.items[this.indicator.stateParamsId].model['modelValue'] ? this.indicator.items[this.indicator.stateParamsId].model['modelValue'] : "No";
                        localIndicator.items[this.indicator.stateParamsId].model['modelValue'] = this.selectedOption;
                        this.indicatorStore.update((state) => {
                          return {
                            indicator:localIndicator,
                            updatedFrom: "[CallComponent] storageCall",
                            isloaded: true,
                          };
                        });
                      }

                  }
                  this.showTable = this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0 ? true : false;
              }else if(this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew' && this.indicator.items[this.indicator.stateParamsId].order.productType === 'Digital' && this.indicator.items[this.indicator.stateParamsId].calltracking.length == 0){
                  //console.log('coming inside 2')
                  //this.selectedOption = "No";
                  //this.indicator.items[this.indicator.stateParamsId].model.modelValue = "No"; //DEXYP-622733
                  this.indicator.items[this.indicator.stateParamsId].model['modelValue'] = '';
                  this.indicator.items[this.indicator.stateParamsId].model['modelValue'] = this.selectedOption;
                  this.showTable = true;
              }
              this.ngAfterViewChecked();


          // $scope.showTable = true;

      } else if (this.productType === 'Digital' && this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew' && this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0) {
          this.showTable = true;
          //console.log('show link false 7')
          //this.showlink = false;
      }
  });
  }

  async terminateCT() {
//this function is called when we select "No" from CT dropdown for Digital items
    this.showTable = false
    this.indicatorQuery.getIndicator().subscribe(data => {
    this.indicator = {...data};
    });
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    let callTrackingArr =upadatedName.items[upadatedName.stateParamsId].calltracking;
    let transactionArr = [];
      for (let i = 0; callTrackingArr.length > i; i++) {
              let data = this.buildTerminateCTRequest(callTrackingArr[i]);
              let res = await this.calltracking.terminate(data)
              transactionArr.push(res.transactionId);
              if(res.hasWarningOrError=="false"){
                upadatedName = JSON.parse(JSON.stringify(upadatedName));
                this.TrackNumberArray = [];
              }
      }
      this.transactionId = transactionArr.join();

      upadatedName.items[upadatedName.stateParamsId].model.modelValue = "No"
      upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
      if (upadatedName.items[upadatedName.stateParamsId].model.modelValue == "No" && this.productType == "Digital" && (this.productCode === 'YPC' || this.productCode === 'Single IYP')){
        upadatedName.items[upadatedName.stateParamsId].model["CallTracking"] = {"OptIntoCallTracking":false};
         upadatedName.items[upadatedName.stateParamsId].calltracking = [];
      }else{
        upadatedName.items[upadatedName.stateParamsId].model.CallTracking = [];
        upadatedName.items[upadatedName.stateParamsId].calltracking = [];
      }
      this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
      .subscribe(()=>{},error=>{
        //console.log("error is:",error);
      });
      this.indicatorStore.update((state) => {
        return {
          indicator:upadatedName,
          updatedFrom: "[CallComponent] terminateCT",
          isloaded: true,
        };
      });
  };

  columnModelDigital(){
    this.ctModel = [{title: "", width: 40, field:"callTrackingId",
    formatter:function(cell){
      let calltrackincheck = false;
      let data = cell.getRow().getData();
      if(cell.getRow().getData().ctIdEnabled === "Y"){
        calltrackincheck = true;
      }else{
        calltrackincheck = false;
      }
      // if(calltrackincheck){
      //   return `<input type = 'checkbox' checked value = ${calltrackincheck} [(ngModel)]= "checkedValue" onChange = "addRemoveCallTracking(data, this, checkedValue)" />`
      // }else{
      //   return `<input type = 'checkbox'  value = ${calltrackincheck} onChange ="addRemoveCallTracking(data, this, checkedValue)" />`
      // }
      var customCheckBox=document.createElement("input");
      customCheckBox.value= ""+calltrackincheck;
      customCheckBox.type="checkbox";
      customCheckBox.checked= calltrackincheck;
      customCheckBox.disabled = vm.disableCheckbox();
      ​customCheckBox.addEventListener("change", onChange);
      function onChange() {
      vm.terminateCallTracking(data,customCheckBox.checked);
      }
    return customCheckBox;
    }},
    {title: "CTN", width:200, field:"trackingNumber"},
    {title: "Destination", width:200, field:"terminationNumber"},
    {title: "Call Record", width:200, field:"callRecordEnabled",
    formatter:function(cell){
      let callRecordCheckBox = document.createElement("input");
      callRecordCheckBox.type = 'checkbox';
      callRecordCheckBox.checked = (cell.getRow().getData().callRecordEnabled === 'Y') ? true : false;
      callRecordCheckBox.disabled = true;
      return callRecordCheckBox;
      // return "<input type = 'checkbox' checked = true disabled/>"
    }
    },
    {title: "Call Intercept", width:200, field:"callVerifyEnabled",
    formatter:function(cell){
      let callVerifyCheckBox = document.createElement("input");
      callVerifyCheckBox.type = 'checkbox';
      callVerifyCheckBox.checked = (cell.getRow().getData().callVerifyEnabled === 'Y') ? true : false;
      callVerifyCheckBox.disabled = true;
      return callVerifyCheckBox;
      // return "<input type = 'checkbox'checked = true disabled/>"
    }
    },
    {title: "Source",field:"distributionType"},
     ]
  }
  columnModelPrint(){
    this.ctModelPrint = [{title: "",width: 40,field:"callTrackingId",
    formatter:function(cell){
      let calltrackincheck = false;
      let data = cell.getRow().getData();
      if(cell.getRow().getData().ctIdEnabled === "Y"){
        calltrackincheck = true;
      }else{
        calltrackincheck = false;
      }
      // if(calltrackincheck){
      //   return `<input type = 'checkbox' checked value = ${calltrackincheck} [(ngModel)]= "checkedValue" onChange = "addRemoveCallTracking(data, this, checkedValue)" />`
      // }else{
      //   return `<input type = 'checkbox'  value = ${calltrackincheck} onChange ="addRemoveCallTracking(data, this, checkedValue)" />`
      // }
      let customCheckBox=document.createElement("input");
      customCheckBox.value= ""+calltrackincheck;
      customCheckBox.type="checkbox";
      customCheckBox.checked= calltrackincheck;

      ​customCheckBox.addEventListener("change", onChange);
      function onChange() {
      vm.addRemoveCallTracking(data,customCheckBox.checked);
      }
      return customCheckBox;

    }},
    {title: "CTN", width: 150, field:"trackingNumber"},
    {title: "Destination", width: 200, field:"terminationNumber"},
    {title: "Call Record", width: 200, field:"callRecordEnabled",
    formatter:function(cell){
      let callRecordCheckBox = document.createElement("input");
      callRecordCheckBox.type = 'checkbox';
      callRecordCheckBox.checked = (cell.getRow().getData().callRecordEnabled === 'Y') ? true : false;
      callRecordCheckBox.disabled = true;
      return callRecordCheckBox;
      // return "<input type = 'checkbox' checked = true disabled/>"
    }
    },
    {title: "Call Intercept", width: 200, field:"callVerifyEnabled",
    formatter:function(cell){
      let callVerifyCheckBox = document.createElement("input");
      callVerifyCheckBox.type = 'checkbox';
      callVerifyCheckBox.checked = (cell.getRow().getData().callVerifyEnabled === 'Y') ? true : false;
      callVerifyCheckBox.disabled = true;
      return callVerifyCheckBox;
      // return "<input type = 'checkbox'checked = true disabled/>"
    }
    },
    {title: "Source", field:"distributionType"},
     ]

  }
  disableCheckbox(){
    if(this.selectedItem.statusCode >= 6000){
      return true
    }else{
      if(this.productType === "Digital" && (this.selectedItem.actionCode === 'Renew' || (this.selectedItem.actionCode === 'Add' && this.selectedItem.actionType === 'Update'))){
        if(this.productCode == "SEMP" || this.productCode == "SEM" || this.productCode == "SEM Kicker"){
          if(this.indicator.items[this.indicator.stateParamsId] && this.indicator.items[this.indicator.stateParamsId].data && this.indicator.items[this.indicator.stateParamsId].data.products && this.indicator.items[this.indicator.stateParamsId].data.products[0] && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled.toLowerCase() == "true"){
            return true
          }else{
            return false
          }
        } else{
          return true
        }
      }
    }
  }
  private buildUpdateCTSRequest(callTrackingId) {
    let data = {
        "callTrackingId": callTrackingId,
        "itemDetails": {
            "itemDetail": [{
                "enterpriseItemId": this.selectedItem.svItemId,
                "issueNumber": this.selectedItem.directoryIssueNumber,
                "action": "remove"
            }]
        }
    }
    return data;
  };
  private buildTerminateCTRequest (callTrackingArr) {
    let data = {
        "callTrackingId": callTrackingArr.callTrackingId,
        "terminationNumber": callTrackingArr.terminationNumber,
        "callRecordEnabled": callTrackingArr.callRecordEnabled,
        "adAlertEnabled": callTrackingArr.adAlertEnabled,
        "callVerifyEnabled":callTrackingArr.callVerifyEnabled

    };
    return data;
};
  async addRemoveCallTracking(elem, mode){
    /*if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
      this.copysheetnotify();
      CopySheetComponent.reTrigger();
    }*/
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    let iaGrouppresent = this.isIAGroupPresent();
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    let data = this.buildUpdateCTSRequest(elem.callTrackingId);
    /*if (upadatedName.items[upadatedName.stateParamsId].order.actionType === 'New' && iaGrouppresent == false) {
        this.copysheetnotify();
    }*/
    //console.log('iaGrouppresent'+iaGrouppresent)
    //console.log('mode'+mode)
    //console.log('elem is'+JSON.stringify(elem))
    if(iaGrouppresent){
      let msg= "This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.";
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
      dialogRef.afterClosed().subscribe(async dialogResult => {
      let result = dialogResult;
      if(result){
        /*if (upadatedName.items[upadatedName.stateParamsId].order.actionType === 'New') {
             this.copysheetnotify();
        }*/
        if (mode) {
            data.itemDetails.itemDetail[0].action = "add";
            this.spinnerService.show();
            let res = await this.calltracking.updateCTS(data)
                if(res.hasWarningOrError === "false"){
                    this.spinnerService.hide();
                    elem = JSON.parse(JSON.stringify(elem));
                    elem['ctIdEnabled'] = 'Y';
                    upadatedName.items[upadatedName.stateParamsId].calltracking.push(elem);
                    this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
                    .subscribe(()=>{},error=>{
                      //console.log("error is:",error);
                    });
                    // //Add checked trackingNumber into ngModel
                    this.editngModel(true, elem['trackingNumber'], upadatedName.items[upadatedName.stateParamsId].model);
                }else{
                    this.spinnerService.hide()
                    var errorObject =res.esbServiceFault;
                    var errorCode = errorObject.sourceSystem;
                    var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                    var errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    this.logger.logError(errorMsg);
                }
       } else {
          this.spinnerService.show();
          let res = await this.calltracking.updateCTS(data)
                if(res.hasWarningOrError ==="false"){
                    this.spinnerService.hide();
                    elem = JSON.parse(JSON.stringify(elem));
                    elem['ctIdEnabled'] = 'N';
                    let newArray = upadatedName.items[upadatedName.stateParamsId].calltracking.filter(function (el) {
                      return el.callTrackingId != elem['callTrackingId'];
                    });
                    upadatedName.items[upadatedName.stateParamsId].calltracking = newArray
                    this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
                    .subscribe(()=>{},error=>{
                      //console.log("error is:",error);
                      this.spinnerService.hide();
                    });
                    //upadatedName.items[upadatedName.stateParamsId].calltracking.splice(elem['index'], 1);
                    //Remove unchecked trackingNumber from ngModel
                    this.editngModel(false, elem['trackingNumber'], upadatedName.items[upadatedName.stateParamsId].model);
                }else{
                    this.spinnerService.hide();
                    var errorObject =res.esbServiceFault;
                    var errorCode = errorObject.sourceSystem;
                    var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                    var errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    this.logger.logError(errorMsg);
                }
        }
        if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
          upadatedName.items[upadatedName.stateParamsId].model.renewalCopysheetRequiredFlag = true
        }
        this.indicatorStore.update((state) => {
          return {
            indicator:upadatedName,
            updatedFrom: "[CallComponent] addRemoveCallTracking",
            isloaded: true,
          };
        });
        if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
          this.copysheetnotify();
          CopySheetComponent.reTrigger();
          this.indicatorQuery.getIndicator().subscribe(data => {
            this.indicator = {...data};
          });
          let tempData = this.requiredfieldcount.getNewItemCount(this.indicator["items"][this.indicator.stateParamsId].requiredFields, this.indicator["items"][this.indicator.stateParamsId].model, this.indicator["items"][this.indicator.stateParamsId].order, this.indicator["orderPercent"], this.indicator["items"][this.indicator.stateParamsId].counter, true, this.indicator["items"][this.indicator.stateParamsId]);
          this.updateIndictor(this.indicator.stateParamsId, this.indicator, tempData, this.indicator.items[this.indicator.stateParamsId].counter);
        }
        this.splitIAGroup(this.selectedOption);
      }else{
        this.loadPrintData();
      }
      });

    }else{
      if (mode) {
        data.itemDetails.itemDetail[0].action = "add";
        this.spinnerService.show();
        let res = await this.calltracking.updateCTS(data);
            if(res.hasWarningOrError=="false"){
                this.spinnerService.hide();
                elem = JSON.parse(JSON.stringify(elem));
                elem['ctIdEnabled'] = 'Y';
                upadatedName.items[upadatedName.stateParamsId].calltracking.push(elem);
                this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
                .subscribe(()=>{},error=>{
                  //console.log("error is:",error);
                });
                // //Add checked trackingNumber into ngModel
                this.editngModel(true, elem['trackingNumber'], upadatedName.items[upadatedName.stateParamsId].model);
            }else{
                this.spinnerService.hide();
                let errorObject = res.esbServiceFault;
                let errorCode = errorObject.sourceSystem;
                let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                let errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);
            }
    } else {
        this.spinnerService.show();
        let res = await this.calltracking.updateCTS(data)
            if(res.hasWarningOrError === "false"){
                this.spinnerService.hide();
                elem = JSON.parse(JSON.stringify(elem));
                elem['ctIdEnabled'] = 'N';
                //upadatedName.items[upadatedName.stateParamsId].calltracking.splice(elem['index'], 1);
                let newArray = upadatedName.items[upadatedName.stateParamsId].calltracking.filter(function (el) {
                  return el.callTrackingId != elem['callTrackingId'];
                });
                upadatedName.items[upadatedName.stateParamsId].calltracking = newArray
                this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
                .subscribe(()=>{},error=>{
                 // console.log("error is:",error);
                  this.spinnerService.hide();
                });
                // //Remove unchecked trackingNumber from ngModel
                this.editngModel(false, elem['trackingNumber'], upadatedName.items[upadatedName.stateParamsId].model);
            }else{
                this.spinnerService.hide();
                let errorObject =res.esbServiceFault;
                let errorCode = errorObject.sourceSystem;
                let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                let errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);
            }
    }
    if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
      upadatedName.items[upadatedName.stateParamsId].model.renewalCopysheetRequiredFlag = true
    }
    this.indicatorStore.update((state) => {
      return {
        indicator:upadatedName,
        updatedFrom: "[CallComponent] addRemoveCallTracking",
        isloaded: true,
      };
    });
    if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
      this.copysheetnotify();
      CopySheetComponent.reTrigger();
      this.indicatorQuery.getIndicator().subscribe(data => {
        this.indicator = {...data};
      });
      let tempData = this.requiredfieldcount.getNewItemCount(this.indicator["items"][this.indicator.stateParamsId].requiredFields, this.indicator["items"][this.indicator.stateParamsId].model, this.indicator["items"][this.indicator.stateParamsId].order, this.indicator["orderPercent"], this.indicator["items"][this.indicator.stateParamsId].counter, true, this.indicator["items"][this.indicator.stateParamsId]);
      this.updateIndictor(this.indicator.stateParamsId, this.indicator, tempData, this.indicator.items[this.indicator.stateParamsId].counter);
    }
    }

  }
  terminateCallTracking(elem,mode){
    let terminateArray = []
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    this.indicator = JSON.parse(JSON.stringify(this.indicator));
    let callTrackingArr = this.indicator.items[this.indicator.stateParamsId].calltracking;
    let msg= 'Are you sure you want to remove all tracking lines for this destination number?';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'call-tracking-confirmation-dialog';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: msg,
    }
    dialogConfig.width = '600px';
    const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(dialogResult => {
    this.result = dialogResult;
    if(this.result){
      terminateArray = []
      let terminationNumber = elem.terminationNumber;
      let trackingNumber = elem.trackingNumber;
      let data = this.buildTerminateCTRequest(this.TrackNumberArray[elem['index']]);
      let spliceArray = this.TrackNumberArray;
      if (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker") {
        this.spinnerService.show();
        for (var i = 0; i < callTrackingArr.length; i++) {
          if (terminationNumber.replace(/\D/g, "") === callTrackingArr[i].terminationNumber.replace(/\D/g, "")) {
              terminateArray.push(callTrackingArr[i].callTrackingId)
              data = this.buildTerminateCTRequest(callTrackingArr[i]);
              let index;
              for (let j = 0; j < spliceArray.length; j++) {
                  if (spliceArray[j].trackingNumber.replace(/\D/g, "") === callTrackingArr[i].trackingNumber.replace(/\D/g, "")) {
                      index = j;
                      break;
                  }
              }
              spliceArray.splice(index, 1);
              this.indicator.items[this.indicator.stateParamsId].model.CallTracking.splice(index, 1);
              this.calltracking.terminate(data).then((res) => {
              }, error => {
                this.spinnerService.hide();
              });
          }

      }
      for(let i=0;i< terminateArray.length; i++){
        let newArray = this.indicator.items[this.indicator.stateParamsId].calltracking.filter(function (el) {
          return el.callTrackingId != terminateArray[i];
        });
        this.indicator.items[this.indicator.stateParamsId].calltracking = newArray
      }
      this.storageservice.updateField('call',this.storageID,{ "data.callTracking": this.indicator.items[this.indicator.stateParamsId].calltracking})
      .subscribe(()=>{
        this.spinnerService.hide();
      },error=>{
        this.spinnerService.hide();
        //console.log("error is:",error);
      });
    }else{
      this.spinnerService.show();
      this.calltracking.terminate(data).then((res) => {
        this.spinnerService.hide();
      }, error => {
        this.spinnerService.hide();
      });
      spliceArray.splice(elem['index'], 1);
      this.indicator.items[this.indicator.stateParamsId].CallTracking = [];
      if (this.selectedOption === "Yes" && (this.productCode === 'Single IYP' || this.productCode === 'YPC') && this.TrackNumberArray.length <= 0 && (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New' || this.indicator.items[this.indicator.stateParamsId].order.actionType === 'Update')) {
          this.showlink = true;
          //console.log('inside showlink 9')
          this.showTable = true;
          this.ngAfterViewChecked();
      }
      //delete the call tracking
      if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New' && this.indicator.items[this.indicator.stateParamsId].order.productCode == 'YPC' ) {
        this.copysheetnotify();
        CopySheetComponent.reTrigger();
        this.itemPageFlage = true;
            let counter = 0;
            if(this.changeModelFlag) {
               counter = this.indicator.items[this.indicator.stateParamsId].counter;
               this.itemPageFlage = false;
            }
        this.indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        if(this.itemPageFlage ) {
          counter = this.indicator.items[this.indicator.stateParamsId].counter;
          this.itemPageFlage = false;
        }
        let stateParamsId = this.indicator.stateParamsId;
       // this.indicator .items[this.indicator .stateParamsId]['hasCallTracking'] = false;
        let tempData = this.requiredfieldcount.getNewItemCount(this.indicator["items"][stateParamsId].requiredFields, this.indicator["items"][stateParamsId].model, this.indicator["items"][stateParamsId].order, this.indicator["orderPercent"], this.indicator["items"][stateParamsId].counter, true, this.indicator["items"][stateParamsId]);
        this.updateIndictor(this.indicator.stateParamsId, this.indicator, tempData, counter);
        this.indicator = JSON.parse(JSON.stringify(this.indicator));
      }
    }
    this.TrackNumberArray = [];
    this.TrackNumberArray = spliceArray.length == 0? []:spliceArray;
    //this.TrackNumberArray = spliceArray;
    let tempIndicator = this.indicatorStore.getValue().indicator['orderPercent'];
    let counter = this.indicatorStore.getValue().indicator['items'][this.indicator.stateParamsId].counter;
    this.indicator.items[this.indicator.stateParamsId].model.CallTracking = this.TrackNumberArray;
    this.indicator.items[this.indicator.stateParamsId].calltracking = this.TrackNumberArray;
    this.indicator.items[this.indicator.stateParamsId].counter = counter;
    this.indicator['orderPercent'] = tempIndicator;
    this.indicatorStore.update((state) => {
      return {
        indicator:this.indicator,
        updatedFrom: "[CallComponent] terminateCallTracking",
        isloaded: true,
      };
    });
    //delete the call tracking
    if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New' && this.indicator.items[this.indicator.stateParamsId].order.productCode == 'YPC' ) {
      // this.indicator = JSON.parse(JSON.stringify(this.indicator));
       this.copysheetnotify();
       CopySheetComponent.reTrigger();
    }
    this.loadDigitalData();
  }else{
    this.loadDigitalData();
  }
  });
  }
  editngModel(flag, calltrackingNum, model){
    /*this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicatorValue = {...data};
    });*/
    let ctNumber = calltrackingNum ?  calltrackingNum.replace(/\D/g, "") : calltrackingNum;
    //console.log('new ctNumber'+ctNumber)
    //let upadatedName = JSON.parse(JSON.stringify(this.indicatorValue));
    //console.log('b4 adding call trackin'+JSON.stringify(model['CallTracking']))
    if (flag) {
      //Add checked trackingNumber into ngModel
      let modelvalue = model['CallTracking'];

      if (modelvalue  && typeof modelvalue != 'object') {
          var trackingNum = modelvalue.split(',');
          if (trackingNum.indexOf(ctNumber) <= 0) {
              trackingNum.push(ctNumber);
          }
          model['CallTracking'] = trackingNum.join(',');
      } else {
        model['CallTracking'] = ctNumber;
      }
      //console.log('after addition'+JSON.stringify(model['CallTracking']))
  } else {
      //Remove unchecked trackingNumber from ngModel
      var modelvalue = model['CallTracking'];
      if (modelvalue  && typeof modelvalue != 'object') {
          var trackingNum = modelvalue.split(',');
          trackingNum.splice(trackingNum.indexOf(ctNumber), 1);
          model['CallTracking'] = trackingNum.join(',');
      } else {
          model['CallTracking'] = ctNumber;
      }
  }
  /*this.indicatorStore.update((state) => {
    return {
      indicator:upadatedName,
      updatedFrom: "[CallComponent] editngModel",
      isloaded: true,
    };
    });*/
  }
  async removeCallTracking(){
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    let  iaGrouppresent = this.isIAGroupPresent();
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    let callTrackingArr = upadatedName.items[upadatedName.stateParamsId].calltracking;
    //console.log('calltracking array is'+JSON.stringify(callTrackingArr))
    if(iaGrouppresent === true){
      let msg= 'This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.';
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
      dialogRef.afterClosed().subscribe(async dialogResult => {
        let result = dialogResult;
        if(result){
          for (let i = 0; callTrackingArr.length > i; i++) {

              let data = this.buildUpdateCTSRequest(callTrackingArr[i].callTrackingId);

              //Remove unchecked trackingNumber from ngModel
              this.editngModel(false, callTrackingArr[i].trackingNumber, upadatedName.items[upadatedName.stateParamsId].model);
              callTrackingArr[i].ctIdEnabled = 'N';
              let res= await this.calltracking.updateCTS(data)
              if(res.hasWarningOrError === "true"){
                var errorObject = res.esbServiceFault;
                var errorCode = errorObject.sourceSystem;
                var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                var errorMsg = "<strong>Transaction : " + res.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);
              }
          }
          upadatedName.items[upadatedName.stateParamsId].calltracking = [];
          this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
          .subscribe(()=>{},error=>{
            //console.log("error is:",error);
          });
          upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
          upadatedName.items[upadatedName.stateParamsId].model.CallTracking = "";
          // Indicator.items[$stateParams.id].model.CallTracking = vm.TrackNumberArray ;
          this.indicatorStore.setLoading(true);
          this.indicatorStore.update((state) => {
              return {
                indicator:upadatedName,
                updatedFrom: "[CallComponent] removeCallTracking",
                isloaded: true,
              };
          });
          this.indicatorStore.setLoading(false);
          this.copysheetnotify();
          CopySheetComponent.reTrigger();
          this.splitIAGroup(this.selectedOption);
          upadatedName = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
          let tempData = this.requiredfieldcount.getNewItemCount(upadatedName["items"][upadatedName.stateParamsId].requiredFields, upadatedName["items"][upadatedName.stateParamsId].model, upadatedName["items"][upadatedName.stateParamsId].order, upadatedName["orderPercent"], upadatedName["items"][upadatedName.stateParamsId].counter, true, upadatedName["items"][upadatedName.stateParamsId]);
          this.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData,upadatedName.items[upadatedName.stateParamsId].counter);
        }else{
          this.selectedOption = "Yes";
          this.storageservice.updateField("call", this.storageID, { "data.selectedOption": this.selectedOption })
          .subscribe(()=>{},error=>{
            //console.log("error is:",error);
          });
          if (this.selectedOption === "Yes") {
              this.showlink = true;
              //console.log('inside showlink 10')
          }

        }
      })

    }else{
      for (let i = 0; callTrackingArr.length > i; i++) {
        if (callTrackingArr[i].ctIdEnabled === 'Y') {
            let data = this.buildUpdateCTSRequest(callTrackingArr[i].callTrackingId);
            //Remove unchecked trackingNumber from ngModel
            this.editngModel(false, callTrackingArr[i].trackingNumber, upadatedName.items[upadatedName.stateParamsId].model);
            callTrackingArr[i].ctIdEnabled = 'N';
            let res = await this.calltracking.updateCTS(data)
              if(res.hasWarningOrError === "true"){
                  var errorObject =res.esbServiceFault;
                  var errorCode = errorObject.sourceSystem;
                  var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                  var errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                  this.logger.logError(errorMsg);
              }
        }
      }
      upadatedName.items[upadatedName.stateParamsId].calltracking = [];
      this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
      .subscribe(()=>{},error=>{
        //console.log("error is:",error);
      });
      upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
          upadatedName.items[upadatedName.stateParamsId].model.CallTracking = "";
            // Indicator.items[$stateParams.id].model.CallTracking = vm.TrackNumberArray ;
          this.indicatorStore.setLoading(true);
          this.indicatorStore.update((state) => {
          return {
            indicator:upadatedName,
            updatedFrom: "[CallComponent] removeCallTracking",
            isloaded: true,
          };
          });
          this.indicatorStore.setLoading(false);
          this.copysheetnotify();
          CopySheetComponent.reTrigger();
          upadatedName = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
          let tempData = this.requiredfieldcount.getNewItemCount(upadatedName["items"][upadatedName.stateParamsId].requiredFields, upadatedName["items"][upadatedName.stateParamsId].model, upadatedName["items"][upadatedName.stateParamsId].order, upadatedName["orderPercent"], upadatedName["items"][upadatedName.stateParamsId].counter, true, upadatedName["items"][upadatedName.stateParamsId]);
          this.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData,upadatedName.items[upadatedName.stateParamsId].counter);
    }
  }
  splitIAGroup(modelValue) {
    let upadatedName = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    //let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    upadatedName.items[this.indicator.stateParamsId].model.modelValue = modelValue;
    let splitData = {
        "consumingSystem": "CA",
        "eAccountId": this.indicator.account.kgenCustomerId,
        "eItemId": this.indicator.stateParamsId
    };
    this.grouper.splitIAItems(splitData).then((response: any)=> {
          if (response.hasWarningOrError === "false") {
              upadatedName = JSON.parse(JSON.stringify(upadatedName));
              let newgroupId = response.groupId;
              let oldgroupId =upadatedName.items[this.indicator.stateParamsId].data.iaGroupId;
              upadatedName.items[this.indicator.stateParamsId].data.iaGroupId = newgroupId;
              let activityLogHistoryObj = {
                  'displayName':this.indicator.user.name,
                  'email':this.indicator.user.email,
                  'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                  'itemId':this.indicator.stateParamsId,
                  'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
                  'itemActionCode': this.indicator.items[this.indicator.stateParamsId].order.actionCode + "-" + this.indicator.items[this.indicator.stateParamsId].order.actionType,
                  'fieldName':'IA Group',
                  'fieldOldValue':oldgroupId,
                  'fieldNewValue':newgroupId
              }
              this.storageservice.update("itemHistory", this.indicator.stateParamsId, activityLogHistoryObj);
              if ((this.selectedItem.actionCode === 'Add' && (this.selectedItem.actionType === 'New' || this.selectedItem.actionType === 'Update' )) || (this.selectedItem.actionCode=== 'Renew' && (this.selectedItem.actionType === 'Update' || this.selectedItem.actionType === 'New' ))) {
                this.indicator = JSON.parse(JSON.stringify(this.indicator));
                this.indicator.items[this.indicator.stateParamsId].copy.copysheet = null;
                  this.indicator.items[this.indicator.stateParamsId].copy.fileType = '';
                  this.indicator.items[this.indicator.stateParamsId].hasCopysheet = false;
                  if (this.indicator.items[this.indicator.stateParamsId].requiresCopysheet) {
                    //this.indicator.items[this.indicator.stateParamsId].counter++;
                    let stateParamsId = this.indicator.stateParamsId;
                    let tempData = this.requiredfieldcount.getNewItemCount(this.indicator["items"][stateParamsId].requiredFields, this.indicator["items"][stateParamsId].model, this.indicator["items"][stateParamsId].order, this.indicator["orderPercent"], this.indicator["items"][stateParamsId].counter, true, this.indicator["items"][stateParamsId]);
                    this.updateIndictor(this.indicator.stateParamsId, this.indicator, tempData, this.indicator.items[this.indicator.stateParamsId].counter);
                  }
                  this.copysheet.get(this.indicator.stateParamsId + "v" + this.indicator.items[this.indicator.stateParamsId].order.version)
                      .then((data:any)=>{
                        //this.indicator.items[this.indicator.stateParamsId].copy = angular.extend(data,this.indicator.items[this.indicator.stateParamsId].copy);
                        upadatedName = JSON.parse(JSON.stringify(upadatedName));
                        upadatedName.items[this.indicator.stateParamsId].copy = Object.assign(data,upadatedName.items[this.indicator.stateParamsId].copy);
                          if (data === "" || data.length === 0) {
                            upadatedName.items[this.indicator.stateParamsId].copy = {};
                            upadatedName.items[this.indicator.stateParamsId].hasCopysheet = false;
                              if (upadatedName.items[this.indicator.stateParamsId].requiresCopysheet) {
                               // upadatedName.items[this.indicator.stateParamsId].counter++;
                               let stateParamsId = upadatedName.stateParamsId;
                               let tempData = this.requiredfieldcount.getNewItemCount(upadatedName["items"][stateParamsId].requiredFields, upadatedName["items"][stateParamsId].model, upadatedName["items"][stateParamsId].order, upadatedName["orderPercent"], upadatedName["items"][stateParamsId].counter, true, upadatedName["items"][stateParamsId]);
                               this.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData, upadatedName.items[upadatedName.stateParamsId].counter);
                              }

                          }
                          upadatedName = JSON.parse(JSON.stringify(upadatedName));
                          upadatedName.items[this.indicator.stateParamsId].copy.listing = upadatedName.listing;

                          if (!upadatedName.items[this.indicator.stateParamsId].copy.hasOwnProperty('copysheet')) {
                            upadatedName.items[this.indicator.stateParamsId].copy.copysheet = null;
                            upadatedName.items[this.indicator.stateParamsId].hasCopysheet = false;
                              if (upadatedName.items[this.indicator.stateParamsId].requiresCopysheet) {
                               //  upadatedName.items[this.indicator.stateParamsId].counter++;
                               let stateParamsId = upadatedName.stateParamsId;
                               let tempData = this.requiredfieldcount.getNewItemCount(upadatedName["items"][stateParamsId].requiredFields, upadatedName["items"][stateParamsId].model, upadatedName["items"][stateParamsId].order, upadatedName["orderPercent"], upadatedName["items"][stateParamsId].counter, true, upadatedName["items"][stateParamsId]);
                               this.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData, upadatedName.items[upadatedName.stateParamsId].counter);
                              }
                          }
                          this.copysheet.updateCopysheet(this.indicator.stateParamsId + "v" +this.indicator.items[this.indicator.stateParamsId].order.version, this.indicator.items[this.indicator.stateParamsId].copy)
                          .then((response:any)=> {
                             // Logger.logSuccess("Successfully Updated Copysheet");
                          },error=> {
                              this.logger.logError("Failed to update copysheet :" +JSON.stringify(error));
                          });
                      });
              }
              this.indicatorStore.update((state) => {
                return {
                  indicator:upadatedName,
                  updatedFrom: "[CallComponent] splitIAGroup",
                  isloaded: true,
                };
              });
            }
      },error=>{
          let errorObject = error.response ? error.response.esbServiceFault
                                          : error.error.response.esbServiceFault;
          if(errorObject){
          var errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
          var errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
          let transactionId = error.response ? error.response.transactionId : error.error.response.transactionId;
          var errorMsg = "<strong>Transaction : " + transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
          }  // sonar fix
          this.logger.logError(errorMsg);
      });

  }
  isIAGroupPresent = function() {
    let iaGrouppresent = false;
    for (var k in this.indicator.items) {
        if (k !== this.indicator.stateParamsId && this.indicator.items[this.indicator.stateParamsId].data.iaGroupId != undefined) {
            if (this.indicator.items[k].data.iaGroupId === this.indicator.items[this.indicator.stateParamsId].data.iaGroupId) {
                iaGrouppresent = true;
            }
        }
    }
    return iaGrouppresent;
  };
  showResult = async function(value) {
    this.showTable = value;
    //this.showlink = value;
    this.selectedOption = value ? "Yes" : "No";
    this.ngAfterViewChecked();
    if (this.selectedOption === "Yes" && (this.productCode === 'Single IYP' || this.productCode === 'YPC') && this.TrackNumberArray.length > 0 && (this.selectedItem.actionType === 'New' || this.selectedItem.actionType === 'Update')) {
        this.showTable = true;
        //this.showlink = false;
        //console.log('show link false 8')
        this.ngAfterViewChecked();
    }
    if (this.selectedOption === "No" && (this.productType === "Print" || this.productCode == 'YPC')) {
        await this.removeCallTracking();
    } else if (this.selectedOption === "No" && this.productType === "Digital") {
      //console.log('show link false 9')
        //this.showlink = false;
        this.terminateCT();

    } else if (this.selectedOption === "Yes" && this.TrackNumberArray.length <= 0) {
      let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.indicator.stateParamsId].model));
      this.indicator = JSON.parse(JSON.stringify(this.indicator));
      if (this.indicator.items[this.indicator.stateParamsId].order.productCode == 'YPC' || this.indicator.items[this.indicator.stateParamsId].order.productType == "Print") {
        if (this.indicator.items[this.indicator.stateParamsId].order.productCode == 'YPC') {
          modelData.ManageCopysheet = 'Y';
        } else {
          modelData.ManageCopysheet.copySheetIndicator = 'Y';
        }
      }
      let x = JSON.parse(JSON.stringify(this.indicator));
      x['items'][this.indicator.stateParamsId].model = modelData
      this.indicatorStore.update((state) => {
        return {
          indicator: x,
          updatedFrom: "[CallComponent] showResult",
          isloaded: true,
        };
      });
    }
    this.showLinkMethod()
  }
  callChange(value,yesFlag){
    let iaGrouppresent = this.isIAGroupPresent();
    if(iaGrouppresent && value == 'Yes'){
      let msg= "This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.";
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
      dialogRef.afterClosed().subscribe(dialogResult => {
      let result = dialogResult;
      if(result){
        this.splitItemFromIA = true;
        this.callChangeFn(value,yesFlag);
      }else{
        return;
      }
      })
    }else{
      this.callChangeFn(value,yesFlag);
    }
  }
  callChangeFn(value, yesFlag){
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    this.selectedOption = value;
    let updateIndicator = JSON.parse(JSON.stringify(this.indicator));
    let  modelOldValue = updateIndicator.items[updateIndicator.stateParamsId].model.modelValue;
    let iaGrouppresent = this.isIAGroupPresent();
    this.isCTEnabledTouched = true;
    this.storageservice.updateField("call", this.storageID, { "data.selectedOption": this.selectedOption })
    .subscribe(()=>{},error=>{
      //console.log("error is:",error);
    });
    if(this.selectedOption == "Yes"){
      this.changeModelFlag = true;
      updateIndicator.items[updateIndicator.stateParamsId].model.modelValue = this.selectedOption;
      updateIndicator.items[updateIndicator.stateParamsId].order.hasCallTracking = false;
      if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
        updateIndicator.items[updateIndicator.stateParamsId].model.renewalCopysheetRequiredFlag = true
      }
      this.indicatorStore.update((state) => {
        return {
          indicator:updateIndicator,
          updatedFrom: "[CallComponent] callChange Yes selected",
          isloaded: true,
        };
      });
      this.showlink = true;
      //console.log('inside showlink 11')
      this.showTable = true;
      //this.copysheetnotify()
      if (iaGrouppresent && this.splitItemFromIA){
        /*if(this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
          this.copysheetnotify();
        }*/
        this.indicator.items[updateIndicator.stateParamsId].model.modelValue = this.selectedOption;
        this.splitIAGroup(this.selectedOption);
        this.splitItemFromIA = false;
      }
      this.loadCTs();
      if (yesFlag && updateIndicator.items[updateIndicator.stateParamsId].order.actionType === 'New' && (updateIndicator.items[updateIndicator.stateParamsId].order.productCode == 'YPC' ||  updateIndicator.items[updateIndicator.stateParamsId].order.productType == "Print")) {
        //updateIndicator = JSON.parse(JSON.stringify(updateIndicator));
        //updateIndicator.items[updateIndicator.stateParamsId].order.hasCallTracking = false;
        this.copysheetnotify();
        CopySheetComponent.reTrigger();
        updateIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        let stateParamsId = updateIndicator.stateParamsId;
        let tempData = this.requiredfieldcount.getNewItemCount(updateIndicator["items"][stateParamsId].requiredFields, updateIndicator["items"][stateParamsId].model, updateIndicator["items"][stateParamsId].order, updateIndicator["orderPercent"], updateIndicator["items"][stateParamsId].counter, true, updateIndicator["items"][stateParamsId]);
        this.updateIndictor(updateIndicator.stateParamsId, updateIndicator, tempData,updateIndicator.items[updateIndicator.stateParamsId].counter);
      }
      if(iaGrouppresent == false){
          let activityLogHistoryObj = {
            'displayName':updateIndicator.user.name,
            'email':updateIndicator.user.email,
            'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
            'itemId':updateIndicator.stateParamsId,
            'itemStatus': updateIndicator.items[updateIndicator.stateParamsId].order.status,
            'itemActionCode':updateIndicator.items[updateIndicator.stateParamsId].order.actionCode + "-" + updateIndicator.items[updateIndicator.stateParamsId].order.actionType,
            'fieldName':'Call Tracking',
            'fieldOldValue':modelOldValue,
            'fieldNewValue':this.selectedOption
          }
          this.storageservice.update("itemHistory",updateIndicator.stateParamsId, activityLogHistoryObj);
      }
    }
    if(this.selectedOption == "No"){
      // let updateIndicator = JSON.parse(JSON.stringify(this.indicator));
      let msg= 'Are you sure you want to completely remove all call tracking line(s) for this item?';
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'call-tracking-option-dialog';
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
      dialogRef.afterClosed().subscribe(async dialogResult => {
      let result = dialogResult;
        if(result){
          if(this.productType === "Print" || this.productCode == 'YPC'){
            await this.callChangeNoPrint()
          } else if(this.productType === "Digital"){
            await this.terminateCT()
          }
          let activityLogHistoryObj = {
              'displayName':updateIndicator.user.name,
              'email':updateIndicator.user.email,
              'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
              'itemId':updateIndicator.stateParamsId,
              'itemStatus': updateIndicator.items[updateIndicator.stateParamsId].order.status,
              'itemActionCode':updateIndicator.items[updateIndicator.stateParamsId].order.actionCode + "-" + updateIndicator.items[updateIndicator.stateParamsId].order.actionType,
              'fieldName':'Call Tracking',
              'fieldOldValue':modelOldValue,
              'fieldNewValue':vm.selectedOption,
              'transactionId':this.transactionId
          }
          this.storageservice.update("itemHistory", updateIndicator.stateParamsId, activityLogHistoryObj);
          }else{
            this.selectedOption = "Yes";
            updateIndicator.items[updateIndicator.stateParamsId].model.modelValue = this.selectedOption;
            this.ngAfterViewChecked();
            this.storageservice.updateField("call", this.storageID, { "data.selectedOption": this.selectedOption })
            .subscribe(()=>{},error=>{
              //console.log("error is:",error);
            });
            this.loadCTs();
            this.indicatorStore.update((state) => {
              return {
                indicator:updateIndicator,
                updatedFrom: "[CallComponent] callChange No option Dialog No",
                isloaded: true,
              };
            });
            this.showResult(true);
          }
      });
    }
    if(this.selectedOption == "Yes" && (this.productCode === 'Single IYP' || this.productCode === 'YPC') && this.TrackNumberArray.length > 0 && (this.selectedItem.actionType === 'New' || this.selectedItem.actionType === 'Update')){
      this.showTable = true;
      //console.log('show link false 10')
      this.showlink = false;
    }else if (this.selectedOption === "Yes" && this.TrackNumberArray.length <= 0) {
      this.showlink = true;
      //console.log('inside showlink 12')
    }
    this.showLinkMethod()
  }
  async callChangeNoPrint(){
    this.showTable = false
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    let iaGrouppresent = this.isIAGroupPresent();
    if(iaGrouppresent === true){
      let msg= 'This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.';
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
      dialogRef.afterClosed().subscribe(async dialogResult => {
        let result = dialogResult;
        if(result){
          this.copysheetnotify();
          CopySheetComponent.reTrigger();
          let upadatedName = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
          let callTrackingArr = upadatedName.items[upadatedName.stateParamsId].calltracking;
          //console.log('calltracking array is'+JSON.stringify(callTrackingArr))
          for (let i = 0; callTrackingArr.length > i; i++) {
              let data = this.buildUpdateCTSRequest(callTrackingArr[i].callTrackingId);
              //Remove unchecked trackingNumber from ngModel
              this.editngModel(false, callTrackingArr[i].trackingNumber, upadatedName.items[upadatedName.stateParamsId].model);
              callTrackingArr[i].ctIdEnabled = 'N';
              let res= await this.calltracking.updateCTS(data)
              if(res.hasWarningOrError === "true"){
                var errorObject = res.esbServiceFault;
                var errorCode = errorObject.sourceSystem;
                var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                var errorMsg = "<strong>Transaction : " + res.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);
              }
          }
          if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
            upadatedName.items[upadatedName.stateParamsId].model.renewalCopysheetRequiredFlag = true
          }
          upadatedName.items[upadatedName.stateParamsId].model.modelValue = this.selectedOption;
          upadatedName.items[upadatedName.stateParamsId].calltracking = [];
          this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
          .subscribe(()=>{},error=>{
            //console.log("error is:",error);
          });
          upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
          upadatedName.items[upadatedName.stateParamsId].model.CallTracking = "";
          // Indicator.items[$stateParams.id].model.CallTracking = vm.TrackNumberArray ;
          this.indicatorStore.setLoading(true);
          this.indicatorStore.update((state) => {
              return {
                indicator:upadatedName,
                updatedFrom: "[CallComponent] callChangeNoPrint",
                isloaded: true,
              };
          });
          this.indicatorStore.setLoading(false);
          this.splitIAGroup(this.selectedOption);
          upadatedName = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
          let tempData = this.requiredfieldcount.getNewItemCount(upadatedName["items"][upadatedName.stateParamsId].requiredFields, upadatedName["items"][upadatedName.stateParamsId].model, upadatedName["items"][upadatedName.stateParamsId].order, upadatedName["orderPercent"], upadatedName["items"][upadatedName.stateParamsId].counter, true, upadatedName["items"][upadatedName.stateParamsId]);
          this.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData,upadatedName.items[upadatedName.stateParamsId].counter);
        }else{
          this.selectedOption = "Yes";
          this.storageservice.updateField("call", this.storageID, { "data.selectedOption": this.selectedOption })
          .subscribe(()=>{},error=>{
            //console.log("error is:",error);
          });
          if (this.selectedOption === "Yes") {
              this.showlink = true;
              //console.log('inside showlink 10')
          }

        }
      })

    }else{
      this.copysheetnotify();
      CopySheetComponent.reTrigger();
      let upadatedName = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      let callTrackingArr = upadatedName.items[upadatedName.stateParamsId].calltracking;
      //console.log('calltracking array is'+JSON.stringify(callTrackingArr))
      for (let i = 0; callTrackingArr.length > i; i++) {
        if (callTrackingArr[i].ctIdEnabled === 'Y') {
            let data = this.buildUpdateCTSRequest(callTrackingArr[i].callTrackingId);
            //Remove unchecked trackingNumber from ngModel
            this.editngModel(false, callTrackingArr[i].trackingNumber, upadatedName.items[upadatedName.stateParamsId].model);
            callTrackingArr[i].ctIdEnabled = 'N';
            let res = await this.calltracking.updateCTS(data)
            this.transactionId = res.transactionId
              if(res.hasWarningOrError === "true"){
                  var errorObject =res.esbServiceFault;
                  var errorCode = errorObject.sourceSystem;
                  var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                  var errorMsg = "<strong>Transaction : " + res.data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                  this.logger.logError(errorMsg);
              }
        }
      }
      if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
        upadatedName.items[upadatedName.stateParamsId].model.renewalCopysheetRequiredFlag = true
      }
      upadatedName.items[upadatedName.stateParamsId].model.modelValue = this.selectedOption;
      upadatedName.items[upadatedName.stateParamsId].calltracking = [];
      this.storageservice.updateField('call',this.storageID,{ "data.callTracking": upadatedName.items[upadatedName.stateParamsId].calltracking})
      .subscribe(()=>{},error=>{
        //console.log("error is:",error);
      });
      upadatedName.items[upadatedName.stateParamsId].order['hasCallTracking'] = false;
      upadatedName.items[upadatedName.stateParamsId].model.CallTracking = "";
      this.indicatorStore.setLoading(true);
      this.indicatorStore.update((state) => {
        return {
            indicator:upadatedName,
            updatedFrom: "[CallComponent] callChangeNoPrint",
            isloaded: true,
        };
      });
      this.indicatorStore.setLoading(false);
      let tempData = this.requiredfieldcount.getNewItemCount(upadatedName["items"][upadatedName.stateParamsId].requiredFields, upadatedName["items"][upadatedName.stateParamsId].model, upadatedName["items"][upadatedName.stateParamsId].order, upadatedName["orderPercent"], upadatedName["items"][upadatedName.stateParamsId].counter, true, upadatedName["items"][upadatedName.stateParamsId]);
      this.updateIndictor(upadatedName.stateParamsId, upadatedName, tempData,upadatedName.items[upadatedName.stateParamsId].counter);
    }
  }

  private preparedData() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicator = { ...res };
      let items = Object.keys(this.indicator.items);

      if(this.productType === "Digital")
          {
            this.callTrackingDrawForDigital();
          }else{
            this.callTrackingDraw();
          }

    })
  }
  private callTrackingDrawForDigital()
  {
    //console.log('indicator in call component'+JSON.stringify(this.indicator.items))
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    //console.log('call tracking length'+upadatedName.items[this.indicator.stateParamsId].calltracking.length)
    if(upadatedName.items[this.indicator.stateParamsId].calltracking.length == 0 && upadatedName.items[this.indicator.stateParamsId].model.CallTracking ){
        this.TrackNumberArray = [];
    }
    //console.log('<><><><><><><><><>><><><><><><><><><><><>', this.TrackNumberArray)
    this.callTrackingTable = new Tabulator("#CallTrackinDigitalTable",{
      height : "auto",
      //smaxHeight: 500,
      data: this.TrackNumberArray,
      dataTree: true,
      dataTreeStartExpanded:true,
      layout: "fitColumns",
      columns: this.ctModel,
      movableColumns: true,
      tooltips:true,
      placeholder: 'No Data Available.',
      pagination:this.TrackNumberArray.length>0 ? "local" : "",
      //pagination:"local",
      paginationSize:15,
      paginationSizeSelector:[15, 20, 25],

      columnMinWidth:10,
    });
  }

  private callTrackingDraw()
  {
    this.callTrackingTable = new Tabulator("#CallTrackinTable",{
      height : "auto",
      //maxHeight: 500,
      data: this.TrackNumberArray,
      dataTree: true,
      dataTreeStartExpanded:true,
      layout: "fitColumns",
      columns: this.ctModelPrint,
      movableColumns: true,
      tooltips:true,
      placeholder: 'No Data Available.',
      pagination:this.TrackNumberArray.length>0 ? "local" : "",
      paginationSize:15,
      paginationSizeSelector:[15, 20, 25],
      columnMinWidth:10,
    });
  }
  loadCTs(){
    if (this.productType === "Digital") {
        this.loadDigitalData();
    } else if (this.productType === "Print") {
        this.loadPrintData();
    }

  };

  openDialog(data){
    let iaGrouppresent = this.isIAGroupPresent();
    if(iaGrouppresent){
      let msg= "This call tracking number change will remove this item from its current Identical Appearance group. Please manage your copysheets accordingly.";
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: msg,
      }
      dialogConfig.width = '600px';
      const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
      dialogRef.afterClosed().subscribe(dialogResult => {
      let result = dialogResult;
      if(result){
        this.splitItemFromIA = true;
        // if(this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
        //    this.copysheetnotify();
        // }
        // this.splitIAGroup(this.selectedOption);
        this.callshared.openmodel(data).subscribe(data=>{
          if (data && this.splitItemFromIA){
            /*if(this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New') {
              this.copysheetnotify();
           }*/
           this.splitIAGroup(this.selectedOption);
           this.splitItemFromIA = false;
          }
          if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New' && (this.indicator.items[this.indicator.stateParamsId]
            .order.productCode == 'YPC' ||  this.indicator.items[this.indicator.stateParamsId].order.productType == "Print")) {
            this.copysheetnotify();
            CopySheetComponent.reTrigger();
          }
          let updateIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
          this.storageservice.updateField('call',this.storageID,{ "data.callTracking": updateIndicator.items[updateIndicator.stateParamsId].calltracking})
          .subscribe(()=>{},error=>{
            //console.log("error is:",error);
          });
          //console.log('call tracking after reserviing'+JSON.stringify(updateIndicator.items[updateIndicator.stateParamsId].calltracking))
          if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
            updateIndicator.items[updateIndicator.stateParamsId].model.renewalCopysheetRequiredFlag = true
          }
          this.indicatorStore.update((state) => {
            return {
              indicator:updateIndicator,
              updatedFrom: "[CallComponent] openDialog afterReserve call tracking",
              isloaded: true,
            };
          });
          let tempData = this.requiredfieldcount.getNewItemCount(updateIndicator["items"][updateIndicator.stateParamsId].requiredFields, updateIndicator["items"][updateIndicator.stateParamsId].model, updateIndicator["items"][updateIndicator.stateParamsId].order, updateIndicator["orderPercent"], updateIndicator["items"][updateIndicator.stateParamsId].counter, true, updateIndicator["items"][updateIndicator.stateParamsId]);
          this.updateIndictor(updateIndicator.stateParamsId, updateIndicator, tempData,updateIndicator.items[updateIndicator.stateParamsId].counter);

          this.ngOnInit();
          this.ngAfterViewChecked();
        })
      }
      })
    }else{
      this.callshared.openmodel(data).subscribe(data=>{
        //console.log('data after reserving call tracking is'+JSON.stringify(data))

        if (this.indicator.items[this.indicator.stateParamsId].order.actionType === 'New' && (this.indicator.items[this.indicator.stateParamsId]
          .order.productCode == 'YPC' ||  this.indicator.items[this.indicator.stateParamsId].order.productType == "Print")) {
          this.copysheetnotify();
          CopySheetComponent.reTrigger();
        }
        let updateIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        this.storageservice.updateField('call',this.storageID,{ "data.callTracking": updateIndicator.items[updateIndicator.stateParamsId].calltracking})
        .subscribe(()=>{},error=>{
          //console.log("error is:",error);
        });
        //console.log('call tracking after reserviing'+JSON.stringify(updateIndicator.items[updateIndicator.stateParamsId].calltracking))
        if (this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') {
          updateIndicator.items[updateIndicator.stateParamsId].model.renewalCopysheetRequiredFlag = true
        }
        this.indicatorStore.update((state) => {
          return {
            indicator:updateIndicator,
            updatedFrom: "[CallComponent] openDialog afterReserve call tracking",
            isloaded: true,
          };
        });
        let tempData = this.requiredfieldcount.getNewItemCount(updateIndicator["items"][updateIndicator.stateParamsId].requiredFields, updateIndicator["items"][updateIndicator.stateParamsId].model, updateIndicator["items"][updateIndicator.stateParamsId].order, updateIndicator["orderPercent"], updateIndicator["items"][updateIndicator.stateParamsId].counter, true, updateIndicator["items"][updateIndicator.stateParamsId]);
        this.updateIndictor(updateIndicator.stateParamsId, updateIndicator, tempData,updateIndicator.items[updateIndicator.stateParamsId].counter);
        this.ngOnInit();
        this.isCalltrackingAdded = true;
        this.ngAfterViewChecked();
      })
    }
  }
  buildCTlist(data, key) {
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    let productCTArray = [];
    let destAndTrackNumberObject;
    let tmpDestAndTrackNumberObject;
    if(this.indicator.items[this.indicator.stateParamsId].data.products && this.indicator.items[this.indicator.stateParamsId].data.products[1]
      && this.indicator.items[this.indicator.stateParamsId].data.products[1].hasOwnProperty('trackingLines')){
        productCTArray = this.indicator.items[this.indicator.stateParamsId].data.products[1].trackingLines;
    }
    let visionData  = [];
    let length = 0;
    if ((this.indicator.items[this.indicator.stateParamsId].order.actionCode === 'Renew') && (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker")) {
      if (_.isArray(productCTArray)) {
        if(productCTArray.length > 0){
          for(let ctLine in data){
              for(let i=0; i< productCTArray.length; i++){
                  if(data[ctLine].callTrackingId === productCTArray[i].trackingLine.callTrackingId){
                      visionData.push(data[ctLine]);
                  }
              }
          }
        } else if(productCTArray.length == 0){ // GEP-2777
          visionData = data;
        }
      }else if(_.isObject(productCTArray["trackingLine"])){
        for (let ctLine in data) {
          if (data[ctLine].callTrackingId === productCTArray['trackingLine'].callTrackingId) {
            visionData.push(data[ctLine]);
          }
        }
      }
      upadatedName.items[this.indicator.stateParamsId].calltracking = [];
      upadatedName.items[this.indicator.stateParamsId].model.CallTracking = visionData;
      upadatedName.items[this.indicator.stateParamsId]['calltracking'] = visionData;
      this.transformer.ctMapping(this.indicator.items[this.indicator.stateParamsId].calltracking, this.selectedItem, this.indicator.items[this.indicator.stateParamsId].model);
      data = visionData;
      length = visionData.length;
  }else{
      length = data.length;
  }
  //var length = data.length;
  let callTrackingEnabled = false;
  for (let i = 0; i < length; i++) {
    let terminationNumber = data[i].terminationNumber;
    let trackingNumber = data[i].trackingNumber;
    let callTrackingId = data[i].callTrackingId;
    let callRecordEnabled = ''; //fix for sonar issue
   // var callRecordEnabled = data[i].callRecordEnabled;//fix for sonar issue
    let ctIdEnabled = "";
    let adAlertEnabled;
    let callVerifyEnabled;
    let source;

    if (data[i].adAlertEnabled) {
        adAlertEnabled = data[i].adAlertEnabled;
    } else if (data[i].AdAlertEnabled) {
        adAlertEnabled = data[i].AdAlertEnabled;
    } else {
        adAlertEnabled = 'N';
    }

    if (data[i].callRecordEnabled) {
        callRecordEnabled = data[i].callRecordEnabled;
    } else {
        callRecordEnabled = 'N';
    }
    if (data[i].callVerifyEnabled) {
        callVerifyEnabled = data[i].callVerifyEnabled;
    } else {
        callVerifyEnabled = 'N';
    }
    if (data[i].callTrackingId && data[i].ctIdEnabled != 'N' && (key === this.indicator.stateParamsId || this.indicator.items[this.indicator.stateParamsId].order.type == "Mobile")) {
        ctIdEnabled = 'Y';
    } else {
        ctIdEnabled = 'N';
    }

    //Fix for : DE12279, CallTrackingService.setModel not required anymore
    if (data[i].callRecordEnabled === 'Y' || data[i].callRecordEnabled === 'true' && key === this.indicator.stateParamsId) {
        callTrackingEnabled = true;
    } else {
        callTrackingEnabled = data[i].callTrackingEnabled;
    }
    //DE12279 : DE12279, CallTrackingService.setModel not required anymore
    if (data[i].distributionType !== null) {
        source = data[i].distributionType;
    } else {
        source = null;
    }
    destAndTrackNumberObject = {
        "terminationNumber": this.utils.formatPhoneNumber(terminationNumber),
        "trackingNumber": this.utils.formatPhoneNumber(trackingNumber),
        "callTrackingId": callTrackingId,
        "callRecordEnabled": callRecordEnabled,
        "adAlertEnabled": adAlertEnabled,
        "callVerifyEnabled": callVerifyEnabled,
        "distributionType": source,
        "ctIdEnabled": ctIdEnabled,
        "enterpriseItemId": key,
        "index": i,
        "itemArray": [],
        "OptIntoCallTracking":true
    };

    //temporary solution use itemarray (Remove dulpicate CT numbers  )
    tmpDestAndTrackNumberObject = {
        "terminationNumber": this.utils.formatPhoneNumber(terminationNumber),
        "trackingNumber": this.utils.formatPhoneNumber(trackingNumber),
        "callTrackingId": callTrackingId,
        "callRecordEnabled": callRecordEnabled,
        "adAlertEnabled": adAlertEnabled,
        "callVerifyEnabled": callVerifyEnabled,
        "distributionType": source
    };
    if (this.productType === "Digital") {
        this.TrackNumberArray.push(destAndTrackNumberObject);
    } else if (this.productType === "Print") {
        var callIndex = JSON.stringify(this.TmpTrackNumberArray).indexOf(JSON.stringify(tmpDestAndTrackNumberObject));
        if (this.TrackNumberArray.length <= 0) {
            this.TrackNumberArray.push(destAndTrackNumberObject);
            this.TmpTrackNumberArray.push(tmpDestAndTrackNumberObject);
        } else if (callIndex < 0) {
          let numberPresent;
          for (let k = 0; k < this.TmpTrackNumberArray.length; k++) {
            if (this.TmpTrackNumberArray[k].trackingNumber.replace(/\D/g, "") === tmpDestAndTrackNumberObject.trackingNumber.replace(/\D/g, "")) {
                numberPresent = true;
                break;
            }
        }
        if (!numberPresent) {
            this.TrackNumberArray.push(destAndTrackNumberObject);
            this.TmpTrackNumberArray.push(tmpDestAndTrackNumberObject);
        }
            // this.TrackNumberArray.push(destAndTrackNumberObject);
            // this.TmpTrackNumberArray.push(tmpDestAndTrackNumberObject);
        } else if (callIndex >= 0 && destAndTrackNumberObject.ctIdEnabled === 'Y') {
            var index;
            for (var j = 0; j < this.TrackNumberArray.length; j++) {
                if (this.TrackNumberArray[j].trackingNumber === destAndTrackNumberObject.trackingNumber && this.TrackNumberArray[j].terminationNumber === destAndTrackNumberObject.terminationNumber) {
                    index = j;
                    break;
                }
            }
            this.TrackNumberArray.splice(index, 1);
            this.TrackNumberArray.push(destAndTrackNumberObject);
            this.TmpTrackNumberArray.splice(index, 1);
            this.TmpTrackNumberArray.push(tmpDestAndTrackNumberObject);
        }
        if (ctIdEnabled === 'Y') { //DE27485 Fix
            this.trackarr.push(trackingNumber);
            upadatedName.items[upadatedName.stateParamsId].model.CallTracking = this.trackarr.join(',');
        }
    }
  }
  if ((this.selectedItem.actionType === 'New' || this.selectedItem.actionType === 'Update') && (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker")) {
    //this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.title = "Call Tracking Enabled";
    //removing !isCallTrackingAdded from this condition.
    if(typeof this.indicator.items[this.indicator.stateParamsId].model.AccountId === 'string'){
      //console.log('inside string init'+this.indicator.items[this.indicator.stateParamsId].model.AccountId)
      if(this.indicator.items[this.indicator.stateParamsId].model.AccountId == undefined || this.indicator.items[this.indicator.stateParamsId].model.AccountId == null || this.indicator.items[this.indicator.stateParamsId].model.AccountId == ''){
        //console.log('inside making readonly true init 4'+this.indicator.items[this.indicator.stateParamsId].model.AccountId)
        this.isReadonly = true;
        this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      }else{
        this.isReadonly = false;
        this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      }
    }else{
      if(this.indicator.items[this.indicator.stateParamsId].model.AccountId!=undefined && this.indicator.items[this.indicator.stateParamsId].model.AccountId!=null && Object.keys(this.indicator.items[this.indicator.stateParamsId].model.AccountId).length > 0){
        let accountKeys = Object.keys(this.indicator.items[this.indicator.stateParamsId].model.AccountId);
        accountKeys.forEach((item, index) =>{
          if(item == "accountId"){
            if(this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] == undefined || this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] == null || this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] == ""){
              //console.log('inside making readonly true 3'+JSON.stringify(this.indicator.items[this.indicator.stateParamsId].model.AccountId))
              this.isReadonly = true;
              this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
            }else{
              this.isReadonly = false;
              this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
            }
          }
        })
      }else{
        this.isReadonly = true;
        this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      }
    }


}
if(this.indicator.items[this.indicator.stateParamsId].order.statusCode >= 6000){
  //console.log('inside readonly 3')
  this.isReadonly = true
  this.showlink = false
}
if (this.productType === "Digital" && this.selectedItem.actionCode === 'Renew') {
  if (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker") {
    if (this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled.toLowerCase() == "true"){
      //console.log('inside readonly true 20')
      this.isReadonly = true;
      upadatedName.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
    }else{
      if(this.selectedItem.statusCode >= 6000){
        //console.log('inside readonly true 21')
        this.isReadonly = true;
        upadatedName.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      }else{
        //console.log('inside readonly false 22')
        this.isReadonly = false;
        upadatedName.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      }
    }
  }else{
    //console.log('inside readonly 4')
    this.isReadonly = true;
    upadatedName.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
  }
}
if (this.selectedOption === "Yes" && (this.productCode === 'Single IYP' || this.productCode === 'YPC') && this.TrackNumberArray.length > 0 && this.selectedItem.actionType === 'New') {
  //console.log('show link false 11')
    this.showlink = false;
    this.showTable = false;
}
if (this.indicator.items[this.indicator.stateParamsId].order.actionCode=="Renew" && (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker") && this.selectedOption === "Yes"){
   this.storageservice.updateField('call',this.indicator.stateParamsId,{ "data.callTracking": this.indicator.items[this.indicator.stateParamsId].calltracking })
   .subscribe(()=>{},error=>{
     //console.log("error is:",error);
   })
   this.storageservice.updateField('call',this.storageID,{ "data.callTracking": this.indicator.items[this.indicator.stateParamsId].calltracking })
   .subscribe(()=>{},error=>{
    //console.log("error is:",error);
  })
}
if(this.indicator.items[this.indicator.stateParamsId].order.productType !== 'Print'){
  this.callTrackingDrawForDigital();
}
this.showLinkMethod()
  }
  loadDigitalData(){
    this.TrackNumberArray = []; //grid table shoud not have duplicate values
    //let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = { ...data };
    });
    this.indicator = JSON.parse(JSON.stringify(this.indicator));
    if (this.productType === "Digital" && this.selectedItem.actionCode === 'Renew') {
      if (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker") {
        if (this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled.toLowerCase() == "true"){
          //console.log('inside readonly true 24')
          this.isReadonly = true;
          this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
        }else{
          if(this.selectedItem.statusCode >= 6000){
            //console.log('inside readonly true 25')
            this.isReadonly = true;
            this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
          }else{
            //console.log('inside readonly false 26')
            this.isReadonly = false;
            this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
          }
        }
      }else{
        //console.log('inside readonly 4')
        this.isReadonly = true;
        this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
      }
    }
    /*if (this.productCode == "SEM" || this.productCode == "SEM Kicker" || this.productCode == "SEMP"){
      if ( this.indicator.items[this.indicator.stateParamsId].data && this.indicator.items[this.indicator.stateParamsId].data.products && this.indicator.items[this.indicator.stateParamsId].data.products[0]){
          if (this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled.toLowerCase() == "false" && this.selectedItem.statusCode < 6000){
              this.isReadonly = false;
              this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
          }
      }
    }*/
    if (this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0) {
      for (let ct in this.indicator.items[this.indicator.stateParamsId].calltracking) { //add ctIdEnabled in Indicator for each calltracking line to track checkbox
        this.indicator.items[this.indicator.stateParamsId].calltracking[ct].ctIdEnabled = "Y";
      }
      this.buildCTlist(this.indicator.items[this.indicator.stateParamsId].calltracking, this.indicator.stateParamsId); //display CTs associated with item
    }
    else if (this.productCode === 'YPC') {
      let ypcType = this.selectedItem.type;
      if (ypcType === "Mobile" && this.ypcTypeFlag == false) {
          this.ypcTypeFlag = true;
          this.showDropdown = false;
      }
    }
    else if (this.productCode === 'Premium IYP' || this.productCode === 'SuperClick') {
        this.selectedOption  = 'Yes';
    }
    else if ((this.selectedItem.actionType === 'New' || this.selectedItem.actionType === 'Update') && (this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker") && !this.isCTEnabledTouched) {

      if (this.indicator.items[this.indicator.stateParamsId].data && this.indicator.items[this.indicator.stateParamsId].data.products && this.indicator.items[this.indicator.stateParamsId].data.products[0]){
          if (this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled.toLowerCase() == "false" && this.indicator.items[this.indicator.stateParamsId].order.statusCode < 6000){
            //console.log('inside readonly false 26')
            this.isReadonly = false;
            this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
          }
      } else{
          //Indicator.items[$stateParams.id].schema.properties.CallTracking.readonly = true;
          if (this.indicator.items[this.indicator.stateParamsId].order.actionCode !== "Add" || !this.isCTEnabledTouched){
            //console.log('inside readonly 6')
            this.isReadonly = true;
            this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
              if (this.indicator.items[this.indicator.stateParamsId].order.actionCode == "Add" && this.indicator.items[this.indicator.stateParamsId].model.AccountId){
                if(typeof this.indicator.items[this.indicator.stateParamsId].model.AccountId === 'string'){
                  //console.log('inside string'+this.indicator.items[this.indicator.stateParamsId].model.AccountId)
                  if(this.indicator.items[this.indicator.stateParamsId].model.AccountId!=null && this.indicator.items[this.indicator.stateParamsId].model.AccountId!=''){
                    //console.log('inside making readonly false 2'+this.indicator.items[this.indicator.stateParamsId].model.AccountId)
                    this.isReadonly = false;
                    this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
                  }
                }else{
                  if(this.indicator.items[this.indicator.stateParamsId].model.AccountId!=undefined && this.indicator.items[this.indicator.stateParamsId].model.AccountId!=null && Object.keys(this.indicator.items[this.indicator.stateParamsId].model.AccountId).length > 0){
                    let accountKeys = Object.keys(this.indicator.items[this.indicator.stateParamsId].model.AccountId);
                    accountKeys.forEach((item, index) =>{
                      if(this.indicator.items[this.indicator.stateParamsId].model.AccountId[item]!=null && this.indicator.items[this.indicator.stateParamsId].model.AccountId[item] != ""){
                        //console.log('inside making readonly false 1'+JSON.stringify(this.indicator.items[this.indicator.stateParamsId].model.AccountId))
                        this.isReadonly = false;
                        this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
                      }
                    })

                  }
                }
              }
          }
    }

   }
   this.indicatorStore.update((state) => {
    return {
      indicator: this.indicator,
      isloaded: true,
    };
  });
   this.preparedData();
  }
  loadPrintData(){
    //let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = { ...data };
    });
    this.indicator = JSON.parse(JSON.stringify(this.indicator));
    //console.log('call tracking in print data is'+JSON.stringify(this.indicator.items[this.indicator.stateParamsId].calltracking))
    if((this.publishStatus.indexOf(this.selectedItem.status) > -1) && this.selectedItem.productType === "Print"){
      //console.log('inside readonly true 30')
      this.isReadonly = false;
      this.indicator.items[this.indicator.stateParamsId].schema.properties.CallTracking.readonly = this.isReadonly;
    }
    this.TrackNumberArray = [];
    this.TmpTrackNumberArray = []; //grid table should not have duplicate Call tracking
            //All print items should have same no. of lines
    for (let key in this.indicator.items) {
        //add ctIdEnabled in Indicator for each calltracking line to track checkbox
      for (let ct in this.indicator.items[key].calltracking) {
          //added instance of check to fix DE38083
          if (this.indicator.items[key].calltracking instanceof Array && !this.indicator.items[key].calltracking[ct].hasOwnProperty('ctIdEnabled')) {
              this.indicator.items[key].calltracking[ct].ctIdEnabled = "Y";
          }
      }
      if (this.indicator.items[key].order.productType === "Print") {
          this.buildCTlist(this.indicator.items[key].calltracking, key);
      }
    };
    if (this.indicator.items[this.indicator.stateParamsId].calltracking.length < 0) {

        //display CTs associated with item
        /* angular.forEach(Indicator.items, function(value, key){
            if(Indicator.items[key].calltracking){
                vm.buildCTlist(Indicator.items[key].calltracking, key);
            }
        });*/
    } else {
        this.getAllNumbers();
    }
    this.indicatorStore.update((state) => {
      return {
        indicator: this.indicator,
        isloaded: true,
      };
    });


  }
  getAllNumbers = function() {
    this.call.getByAccountId(this.accountId).then(data=> {
        let ctOptions = ['Yes', 'No'];
        let updateOptions = [];
        if (data.hasWarningOrError === "false" && data.trackingLines !== "" && data.trackingLines !== null) {
            let trackingNumberList = data.trackingLines.trackingLine;
            let length = trackingNumberList.length;

            for (let i = 0; i < length; i++) {
                let updateOption:any = {};
                updateOption.terminationNumber = this.utils.formatPhoneNumber(trackingNumberList[i].terminationNumber);
                updateOption.trackingNumber = this.utils.formatPhoneNumber(trackingNumberList[i].trackingNumber);
                updateOption.callTrackingId = trackingNumberList[i].callTrackingId;
                updateOption.callRecordEnabled = trackingNumberList[i].callRecordEnabled;
                updateOption.adAlertEnabled = trackingNumberList[i].adAlertEnabled;
                updateOption.callVerifyEnabled = trackingNumberList[i].callVerifyEnabled;
                updateOption.ctIdEnabled = trackingNumberList[i].ctIdEnabled;
                updateOption.enterpriseItemId = trackingNumberList[i].enterpriseItemId;
                updateOption.index = i;
                updateOption.itemArray = [];
                updateOptions.push(updateOption);
                let numberPresent = false;
                for (let k = 0; k < this.TrackNumberArray.length; k++) {
                    if (this.TrackNumberArray[k].trackingNumber === updateOption.trackingNumber) {
                        numberPresent = true;
                        break;
                    }
                }
                if (!numberPresent) {
                    this.TrackNumberArray.push(updateOption);
                    this.TmpTrackNumberArray.push(updateOption);
                }
            }
        } else {
            this.calltrackingOption = ['Yes', 'No'];
        }
        if (this.selectedOption === "Yes") {
          this.preparedData();
        }
    })
    //console.log('track number array is'+JSON.stringify(this.TrackNumberArray))

  }
  copysheetnotify(){
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    upadatedName.items[this.indicator.stateParamsId].hasCopysheet = false;
    this.storageservice.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
    .subscribe(()=>{},err=>{})
      if(upadatedName.items[this.indicator.stateParamsId].order.productCode == 'YPC' ||  this.indicator.items[this.indicator.stateParamsId].order.productType == "Print"){
          if(upadatedName.items[this.indicator.stateParamsId].order.productCode == 'YPC'){
            upadatedName.items[this.indicator.stateParamsId].model.ManageCopysheet = 'Y';
          }else{
            upadatedName.items[this.indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
          }
          upadatedName.items[this.indicator.stateParamsId].requiresCopysheet =  true;
          /* if (upadatedName.items[this.indicator.stateParamsId].requiresCopysheet) {
            upadatedName.items[this.indicator.stateParamsId].counter++;
          } */
      }

    this.indicatorStore.update((state) => {
      return {
        indicator:upadatedName,
        updatedFrom: "[CallComponent] copysheetnotify",
        isloaded: true,
      };
    });

  }

  onUpdateValue(){
    this.selectedOption = "Yes"
  }
  ngOnDestroy() {
    this.destroySubject$.next();
    this.componentStore.update(() => {
      return {
        componentValue: ''
      }
  });
  }

   //Below update the counter code
   private updateIndictor(itemId, data, tempIndicator, currentCount) {
    let counter = [];
    data = JSON.parse(JSON.stringify(data))
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(data, tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    this.updatCounterStore(data);
  }
  private updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[CallComponent] updatCounterStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);

  }

  private getItemErrorCount(data, itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    //let latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    for(let i = 0; i < data.order["OrderItems"].length; i++){
      if(data.items[data.order["OrderItems"][i].svItemId] && data.items[data.order["OrderItems"][i].svItemId].counter > 0){
        counter = counter + 1
      }
    }
    orderPercent = this.updateCounter(productType, counter, orderPercent);
    currentCount = counter;

    return orderPercent;
  }
  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }

  showLinkMethod(){
    //console.log('inside showLink method actioncode'+this.selectedItem.actionCode+'productcode'+this.productCode+'selectedOption'+this.selectedOption)
    if(this.selectedOption === "No"){
      this.showlink = false
    } else{
        if(this.selectedItem.actionCode === 'Renew' || (this.selectedItem.actionCode === 'Add' && this.selectedItem.actionType === 'Update')){
          if(this.indicator.items[this.indicator.stateParamsId].order.statusCode >= 6000 || this.publishStatus.indexOf(this.indicator.items[this.indicator.stateParamsId].order.status) > -1){
            this.showlink = false
          }else{
            if(this.selectedItem.productType === "Print"){
              this.showlink = true
            }else{
              if(this.productCode === "SEM" || this.productCode === "SEMP" || this.productCode === "SEM Kicker"){
                if (this.indicator && this.indicator.items && this.indicator.items[this.indicator.stateParamsId] && this.indicator.items[this.indicator.stateParamsId].data.products[0] && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled && this.indicator.items[this.indicator.stateParamsId].data.products[0].CallTrackingEnabled.toLowerCase() == "true"){
                  this.showlink = false
                }else{
                  this.showlink = true
                }
              }else if (this.productCode === 'Single IYP' || this.productCode === 'YPC') {
                if(this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0){
                  this.showlink = false
                }else{
                  this.showlink = true
                }
              }else{
                this.showlink = false
              }

            }
          }
        } else if(this.selectedItem.actionCode === 'Add' && this.selectedItem.actionType === 'New'){
          if(this.indicator.items[this.indicator.stateParamsId].order.statusCode >= 6000 || this.publishStatus.indexOf(this.indicator.items[this.indicator.stateParamsId].order.status) > -1){
            this.showlink = false
          }else{
            if (this.productCode === 'Single IYP' || this.productCode === 'YPC') {
              if(this.indicator.items[this.indicator.stateParamsId].calltracking.length > 0){
                this.showlink = false
              }else{
                this.showlink = true
              }
            }else{
              this.showlink = true
            }
          }
        }

    }

    //console.log('value of show link is'+this.showlink)
  }

  determineReadOnly(){
    if(this.indicator.items[this.indicator.stateParamsId].order.statusCode >= 6000){
      this.isReadonly = true;
    }

  }
}
