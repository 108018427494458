import { Injectable } from '@angular/core';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { IndicatorStore } from 'app/state/store';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateIndicatorService {
  
  areaCodeVal = new Subject<boolean>();
  prefixVal = new Subject<boolean>();

  constructor( private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService,
    private indicatorStore :IndicatorStore ) { }

   updateIndictor(itemId, data,tempIndicator, currentCount) {
    let counter=[];
    data = JSON.parse(JSON.stringify(data))
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount ;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount,  data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    this.indicatorStore.setLoading(true);
    this.updatStore(data);
  }
   updatStore(data){
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[UpdateIndicatorService] updatStore",
        isloaded: true,
      };
    });
    this.indicatorStore.setLoading(false);

  }
   getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent= this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent= this.updateCounter(productType, counter, orderPercent);
    }  else {
    //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
 }
   updateCounter(productType, counter, orderPercent) {
  if(productType && productType === 'Digital') {
    orderPercent.digitalCount =   counter -  orderPercent.printCount;
  } else if(productType && productType === 'Print') {
    orderPercent.printCount =   counter -  orderPercent.digitalCount;
  }
  return orderPercent;
  }

  updateBPOrderPercent( profileID ,Indicator: any, pageIndex ) {
    let indicator = JSON.parse(JSON.stringify(Indicator));
		let leftCounter = [];
		let profile = indicator['profiles'][profileID];
		let counter = profile.menu[0][pageIndex].counter;
    let required = profile.menu[0][pageIndex].required;
    let onLoadTotalCounter ;
    let onLoadFirstTilesCount;
    let onLoadSecondilesCount;
    let onLoadTotalBpCount ;

   onLoadFirstTilesCount = this.requiredfieldcount.get(
		indicator['profiles'][profileID].schema[0],
		indicator['profiles'][profileID].form[0],
		indicator['profiles'][profileID].model);
	  onLoadSecondilesCount = this.requiredfieldcount.get(
		indicator['profiles'][profileID].schema[1],
		indicator['profiles'][profileID].form[1],
		indicator['profiles'][profileID].model);
		onLoadTotalCounter = onLoadFirstTilesCount + onLoadSecondilesCount;
		onLoadTotalBpCount = indicator['orderPercent'] ? indicator['orderPercent'].bpTotalCount : 0;
		// let currentCount = indicator.orderPercent.currentCount - required + (required - counter);
		// let currentCount = indicator.orderPercent.currentCount - required + (required + (tileileCount - counter));
		// indicator.orderPercent.currentCount = currentCount;
    // this.requiredfieldcount.getPercent(currentCount, indicator.orderPercent.maxCount);
		let requiredData = this.requiredfieldcount.calculatePercent(indicator['items'], indicator['profiles']);
		/*if(onLoadTotalCounter != 0 && (profile.menu[0][0].counter + profile.menu[0][1].counter) == 0) {
			onLoadTotalCounter > 0 ? onLoadTotalCounter-- : 0;
			onLoadTotalCounter = 0;
		} else if(onLoadTotalCounter == 0 &&  (profile.menu[0][0].counter + profile.menu[0][1].counter) != 0) {
			onLoadTotalCounter++;
			onLoadTotalCounter = profile.menu[0][0].counter + profile.menu[0][1].counter;
    }
    console.log('it is coming here wrong'+indicator.orderPercent.bpTotalCount)*/
		indicator.orderPercent.bpTotalCount = requiredData.bpTotalCount;
		leftCounter.push(indicator.orderPercent.digitalCount);
		leftCounter.push(indicator.orderPercent.printCount);
		leftCounter.push(indicator.orderPercent.bpTotalCount);
		this.counterService.setError(leftCounter);
		//console.log(tileileCount,'::::::::',counter,'::::::',currentCount,':::::::',indicator.orderPercent.maxCount)
		return indicator;
	}

}
