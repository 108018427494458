import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';

import { Subject } from 'rxjs';
import { ComponentQuery } from 'app/state/component.query';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-zipcode-validation',
  templateUrl: './zipcode-validation.component.html',
  styleUrls: ['./zipcode-validation.component.scss']
})
export class ZipcodeValidationComponent implements OnInit, OnDestroy {
  zipcode;
  zipcode1;
  zip = '';
  zip4 = '';
  show: boolean = false;
  sharedData: any;
  error: boolean = false;
  id: any;
  options;
  isItemPage: boolean = false;
  modelObject;
  isReadonly :boolean=false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private componentQuery: ComponentQuery,
    private changeDetectorRef: ChangeDetectorRef,) {
  }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe((data) => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      if(this.sharedData.items){
      let items = Object.keys(this.sharedData.items);
      items.forEach((item, index) => {
        if(item == this.sharedData.stateParamsId){
          this.isItemPage =true;
        }
      });
      }
    this.modelObject =this.isItemPage ? this.sharedData['items'] :this.sharedData['profiles'];
    // this.isReadonly =this.modelObject[this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name].readonly;
    if(this.isItemPage ) {
      this.isReadonly =this.modelObject[this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name].readonly;
    } else {
      this.isReadonly =this.modelObject[this.sharedData.stateParamsId].schema[0].properties[this['layoutNode'].name].readonly;
    }

      // zip null check
      if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != undefined &&
        this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != null &&
        this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip) {
        if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip !== undefined &&
          this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip !== "") {
          this.zip = this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip;
        }
      }
      // zip 4 null check
      if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != undefined && this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != null && this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4) {
        if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4 !== undefined && this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4 !== "") {
          if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4.match(/^ *$/) !== null){
            this.zip4 = this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4.trim();
          } else {
            this.zip4 = this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4;
          }
        }
      }
      if (this.zip !== undefined && this.zip !== "" && this.zip4 !== undefined && this.zip4 !== null && this.zip4 !== "" && this.zip4 != "null" && !this.zip4.includes("-") && this.zip.length == 5) {
        this.zip = this.zip ? this.zip.replace(/^\D+/g, '') : '';
        this.zip4 = this.zip4 ? this.zip4.replace(/^\D+/g, '') : '';
        if (this.zip && this.zip4) {
          this.zipcode = this.zip + "-" + this.zip4;
        } else if (this.zip4) {
          this.zipcode = this.zip4;
        } else if (this.zip) {
          this.zipcode = this.zip;
        }
      } else if (this.zip4 !== undefined && this.zip4 !== null && this.zip4 !== "" && this.zip4.includes("-") && this.zip.length == 1) {
        this.zip=this.zip ? this.zip.replace( /^\D+/g, '') : '';
        this.zipcode = this.zip;
      } else {
        this.zip = this.zip ? this.zip.replace( /^\D+/g, '') : '';
        this.zip4 = this.zip4 ? this.zip4.replace( /^\D+/g, '') : '';
        if(this.zip && this.zip4) {
          this.zipcode = this.zip + "-" + this.zip4;
        } else if(this.zip) {
          this.zipcode = this.zip;
        } else if(this.zip4) {
          this.zipcode = this.zip4;
        }
      // this.zipcode = this.zip.concat(this.zip4);
      }
      this.componentQuery.getComponent().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {
        //let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
        if(data && !(data == "Yes" || data == "No")){
          if (!this.isItemPage) {
            let zip4store = this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip.zip4;            
            if(zip4store){
              zip4store = zip4store.trim();
              if (zip4store.includes("-")) {
                this.zipcode = data[3] + zip4store;
              } else {
                this.zipcode = data[3] + "-" + zip4store;
              }
            }else{
              this.zipcode = data[3];
            }
            if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != undefined &&
              this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != null) {
              this.validateZipcode(this.zipcode, 'N');
            }
          }
        }
        }, err => {
          console.log(err)
        }, () => {
        //  console.log('Completed Address1 update!!!!!')
      });
      if (this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != undefined && this.modelObject[this.sharedData.stateParamsId].model.BusinessAddressZip != null) {
        this.validateZipcode(this.zipcode, 'Y');
      }
      this.id = this['layoutNode'].name;
    })
    // this.changeDetectorRef.detectChanges();
  }

  validateZipcode(zipcode, calledFromOnInit: string) {
    this.zipcode1 = zipcode ? zipcode.replace(/[^0-9]/g, '') : '';
    if(this.zipcode1.length ==0){
      this.zipcode ='';
      this.zip = '';
      this.zip4 = '';
    }
    if (this.zipcode.length >= 6) {
      this.zip = this.zipcode.slice(0, 5)
      this.zip4 = this.zipcode.slice(5)
      if(this.zip4.length === 1 && this.zip4 === '-')
        this.zip4 = '';
      else this.zip.concat('-', this.zip4);
    } else if (this.zipcode.length <= 5) {
      this.zip = this.zipcode.slice(0, 5)
      this.zip4 = '';
    }
    if (this.zipcode.length >= 6) {
      this.zipcode=this.zipcode.replace(/(\d{5})(\d{1,4})/,'$1-$2');
      this.zip = this.zipcode.slice(0, 5);
      this.zip4 = this.zipcode.slice(6);
     }
    if (this.zipcode.length === 5 || this.zipcode.length === 10 || this.zipcode === undefined) {
      this.error = false;
    } else {
      this.error = true;
    }
    
    if( this.isItemPage){
      this.modelObject[this.sharedData.stateParamsId].model['BusinessAddressZip']['zip'] = this.zip;
      this.modelObject[this.sharedData.stateParamsId].model['BusinessAddressZip']['zip4'] = this.zip4;
      this.sharedData['items'] = this.modelObject;
      if (calledFromOnInit != 'Y') {
        this.indicatorStore.update(state =>{
        return{
          indicator: this.sharedData,
          updatedFrom: "[ZipcodeValidationComponent] validateZipcode",
        }
      })
      }
    }else{
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
      modelData['BusinessAddressZip']['zip'] = this.zip;
      modelData['BusinessAddressZip']['zip4'] = this.zip4;
      let x = JSON.parse(JSON.stringify(this.sharedData));
      x['profiles'][this.sharedData.stateParamsId].model = modelData;
      if (calledFromOnInit != 'Y') {
        this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[ZipcodeValidationComponent] validateZipcode1",
        }
      })
      }
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
