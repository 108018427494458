import { Component, OnInit, Inject, ChangeDetectorRef, ViewRef, ViewChild } from '@angular/core';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { IndicatorQuery } from 'app/state/query';
import { Campaigndetails } from '@app/service/campaigndetails/campaigndetails.service';
import { CampaignschemaService } from '@app/service/campaignschema/campaignschema.service';
import { CampaignfieldcountService } from '@app/service/fieldcount/campaignfieldcount.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { CampaignStore } from 'app/state/campaignstore';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { IndicatorStore } from 'app/state/store';
import { NationalUrlComponent } from 'app/shared/components/national-url/national-url.component';
import { ShowemailComponent } from 'app/shared/components/showemail/showemail.component';
import { DisplaydropdownComponent } from 'app/shared/components/displaydropdown/displaydropdown.component';
import { GeoComponent } from 'app/shared/components/geo/geo.component';
import { CamcatComponent } from 'app/shared/components/camcat/camcat.component';
import { WebsiteComponent } from 'app/shared/components/website/website.component';
import { StringComponent } from 'app/shared/components/string/string.component';
import { BusinessprofileDropdownComponent } from '../businessprofile-dropdown/businessprofile-dropdown.component';
import { CampaignbuttonComponent } from '../campaignbutton/campaignbutton.component';
import { PhonenumberfieldComponent } from 'app/shared/components/phonenumberfield/phonenumberfield.component';
import { StateComponent } from 'app/shared/components/state/state.component';
import { StorageService } from '@app/service/storage/storage.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { CounterService } from '@app/service/counter/counter.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { ToastContainerDirective } from 'ngx-toastr';
import { ProfileService } from '@app/service/profile/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-campaignpopup',
  templateUrl: './campaignpopup.component.html',
  styleUrls: ['./campaignpopup.component.scss']
})
export class CampaignpopupComponent implements OnInit {

  schema = {};
  form = [];
  model = {
    listingId: '',
    campaignFlag: false
  };
  keysOnCurrentForm: any = {};
  table: any = {}
  campaignModel: CampaignClass
  campaignType: string;
  dialogRef: any;
  Indicator: any;
  localTile2: boolean = false;
  // updatedIndicator: any;
  campaignList: any;
  modelClicktypes: string = ''
  type: string = ''
  readonly: boolean = false;
  campaignSchema = {
    form: [],
    schema: [],
    menuOrig: []
  };
  isDisplay: boolean = false;
  customWidgets: any;
  backButton = {
    "type": "button",
    "name": "BACK",
    "title": "BACK",
    "htmlClass": "backbtnitem col-xs-6",
    "style": "btn-info special",
    "onClick": "buttonMethods()"
  };
  continueButton = {
    "type": "button",
    "name": "CONTINUE",
    "title": "CONTINUE",
    "htmlClass": "float-right col-xs-6",
    "style": "btn-info float-right special",
    "onClick": "buttonMethods()"
  };
  submitButton = {
    "type": "button",
    "name": "SAVE",
    "title": "SAVE",
    "style": "btn-info special",
    "htmlClass": "pull-right",
    "onClick": "buttonMethods()"
  };
  cancelButton = {
    "type": "button",
    "name": "CANCEL",
    "title": "CANCEL",
    "htmlClass": "pull-left col-xs-6",
    "style": "btn-info pull-left special",
    "onClick": "buttonMethods()"
  };
  tile1Count = 0;
  campaignIndex;
  tile2Count = 0;
  tile3Count = 0;
  tile4Count = 0;
  subscription: any;
  isSubscribed: boolean = false;
  layoutNode: string;
  isLoadFlag: boolean;
  isSubmittingForm: boolean;
  updateCurrentCount: number = 0;
  // @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  isAddressValid: boolean = false;

  constructor(private utils: UtilsService,
    private campaignQuery: CampaignQuery,
    private indicatorQuery: IndicatorQuery,
    private CampaignDetails: Campaigndetails,
    private CampSchema: CampaignschemaService,
    private campaignrequiredFieldCount: CampaignfieldcountService,
    private campaignStore: CampaignStore,
    private indicatorStore: IndicatorStore,
    private indicatorService: IndicatorService,
    private StorageService: StorageService,
    private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private Logger: LoggerService,
    private Profile: ProfileService,
    private ngxspinner: NgxSpinnerService
    ) { }

  ngOnInit() {
    // this.Logger.SEMToastContainerInit(this.toastContainer);
    //console.log('Dialog data', this.data.dialogform)
    this.subscription = this.campaignQuery.getCampign().subscribe(data => {
      if(data)
        this.campaignModel = { ...data }
      else this.campaignModel = new CampaignClass()
    })
    this.isLoadFlag = true;
    this.isSubmittingForm = false;
    let tempIndcator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    this.updateCurrentCount = tempIndcator.orderPercent.currentCount;
    this.campaignModel.campaignFlag = true
    this.campaignModel.tileFlag1 = true;
    this.campaignModel.model = {}
    if(this.data.dialogform.selectedCampaign){
      this.campaignModel.model = this.data.dialogform.selectedCampaign
      this.campaignModel.selectedCampaign = this.data.dialogform.selectedCampaign
      if (this.data.dialogform.campaignType && this.data.dialogform.campaignType.toUpperCase() === "LOCAL") {
        if (this.campaignModel.model.City){
          this.campaignModel.model.CampaignAddressCity = this.campaignModel.model.City;
          this.campaignModel.selectedCampaign.CampaignAddressCity = this.campaignModel.model.City;
        }
        if (this.campaignModel.model.Zip){
          this.campaignModel.model.CampaignAddressZip = this.campaignModel.model.Zip;
          this.campaignModel.selectedCampaign.CampaignAddressZip = this.campaignModel.model.Zip;
        }
        if (this.campaignModel.model.State){
          this.campaignModel.model.CampaignAddressState = this.campaignModel.model.State;
          this.campaignModel.selectedCampaign.CampaignAddressState = this.campaignModel.model.State;
        }
      //   this.campaignModel.model.CampaignAddressCity = this.campaignModel.model.City;
      //   this.campaignModel.model.CampaignAddressZip = this.campaignModel.model.Zip;
      //   this.campaignModel.model.CampaignAddressState = this.campaignModel.model.State;

      //   this.campaignModel.selectedCampaign.CampaignAddressCity = this.campaignModel.model.City;
      //   this.campaignModel.selectedCampaign.CampaignAddressZip = this.campaignModel.model.Zip;
      //   this.campaignModel.selectedCampaign.CampaignAddressState = this.campaignModel.model.State;
      }
    } else { }
    this.campaignModel.campaignType = this.data.dialogform.campaignType
    this.campaignModel.campaignPageIndex = this.data.dialogform.campaignPageIndex
    this.campaignType = this.data.dialogform.campaignType
    this.dialogRef = this.data.dialogform.dialogRef
    this.modelClicktypes = this.data.dialogform.modelClickType
    this.campaignIndex = this.data.dialogform.index
    this.layoutNode = this.data.dialogform.layoutNode
    this.initCustomWidgets();
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res))
      if (!this.Indicator.items[this.Indicator.stateParamsId].model.CampaignType){
        this.Indicator.items[this.Indicator.stateParamsId].model.CampaignType = []
      }
      this.campaignList = this.Indicator.items[this.Indicator.stateParamsId].model.CampaignType
      this.StorageService.update("item", this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version, this.Indicator.items[this.Indicator.stateParamsId].model).then(result =>{});
      this.Indicator.campaignFlag = true;
        this.indicatorStore.update(state => {
          return {
            indicator: this.Indicator,
            updatedFrom: "[CampaignpopupComponent] ngOnInit"
          }
        })
    })

    for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
      if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
        this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
        break;
      }
    }

    if (this.Indicator.items[this.Indicator.stateParamsId].data.products && this.Indicator.items[this.Indicator.stateParamsId].data.products[0]) {
      var clist = this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList;
      if (this.data.dialogform.selectedCampaign) {
        for (var i = 0; i < clist.length; i++) {
          if ((clist[i].CampaignId === this.data.dialogform.selectedCampaign.CampaignId) && (this.data.dialogform.selectedCampaign.ListingId === clist[i].ListingId)) {
            this.data.dialogform.selectedCampaign.ListingId = clist[i].ListingId;
            this.Indicator.items[this.Indicator.stateParamsId].order.selectedCampaign = this.data.dialogform.selectedCampaign//angular.copy(selectedCampaign);
            this.campaignModel.selectedCampaign = this.data.dialogform.selectedCampaign;
            // this.campaignModel.selectedCampaign["isCampaignFromVision"] = true;
            // this.campaignModel.model["isCampaignFromVision"] = true;
          }
        }
      }
    }

    if(this.campaignModel.selectedCampaign && (Object.keys(this.campaignModel.selectedCampaign).length != 0)) {
      this.model = this.campaignModel.selectedCampaign;
      this.campaignModel.selectedCampaign.CCCampaignCatKeys = this.campaignModel.selectedCampaign.CategoryList;
      this.campaignModel.selectedCampaign.modelClicktypes = this.modelClicktypes;
      //Add campaign flag if model is open and not new.
      this.campaignModel.selectedCampaign.campaignFlag = this.campaignModel.campaignFlag;

      if (this.campaignType.toUpperCase() === "LOCAL") {
        this.campaignModel.selectedCampaign.LocalWebsite = {};
        this.campaignModel.selectedCampaign.LocalWebsite.displayurl = this.campaignModel.selectedCampaign.DisplayURL;
        this.campaignModel.selectedCampaign.LocalWebsite.destinationurl = this.campaignModel.selectedCampaign.DestinationURL;
        if(!this.campaignModel.selectedCampaign.ListingId){
          this.campaignModel.selectedCampaign.UseBusinessProfile = false;
          this.campaignModel.selectedCampaign.LocalWebsite.disableprofile = this.campaignModel.selectedCampaign.UseBusinessProfile;
        }
        if (this.campaignModel.selectedCampaign.UseBusinessProfile === "Y" || this.campaignModel.selectedCampaign.UseBusinessProfile === true) {
          this.campaignModel.selectedCampaign.UseBusinessProfile = true;
          this.campaignModel.selectedCampaign.LocalWebsite.disableprofile = this.campaignModel.selectedCampaign.UseBusinessProfile;
          if (this.campaignModel.selectedCampaign.DisplayURL && this.campaignModel.selectedCampaign.DestinationURL) {
            this.campaignModel.selectedCampaign.LocalWebsite.disablelist = true;
          }
        } else if (this.campaignModel.selectedCampaign.UseBusinessProfile === "N") {
          this.campaignModel.selectedCampaign.UseBusinessProfile = false;
          this.campaignModel.selectedCampaign.LocalWebsite.disableprofile = this.campaignModel.selectedCampaign.UseBusinessProfile;
        }
      } else {
        this.campaignModel.selectedCampaign.nationalCampaign = {}
        this.campaignModel.selectedCampaign.nationalCampaign.NationalDestinationURL = this.campaignModel.selectedCampaign.DestinationURL;
        this.campaignModel.selectedCampaign.nationalCampaign.NationalDisplayURL = this.campaignModel.selectedCampaign.DisplayURL
      }

      if (this.campaignModel.selectedCampaign.DisplayEmail) {
        var DisplayEmail = {
          DisplayEmail: '',
          Email: ''
        };
        if (this.campaignModel.selectedCampaign.DisplayEmail.DisplayEmail) {
          DisplayEmail.DisplayEmail = this.campaignModel.selectedCampaign.DisplayEmail.DisplayEmail;
        } else {
          DisplayEmail.DisplayEmail = this.campaignModel.selectedCampaign.DisplayEmail;
        }
        if (this.campaignModel.selectedCampaign.DisplayEmail.Email) {
          DisplayEmail.Email = this.campaignModel.selectedCampaign.DisplayEmail.Email;
        } else {
          DisplayEmail.Email = this.campaignModel.selectedCampaign.Email;
        }
        this.campaignModel.selectedCampaign.DisplayEmail = DisplayEmail;
      }
      this.CampaignDetails.setCampaignLength(Object.keys(this.campaignModel.selectedCampaign).length);
      this.CampaignDetails.clearGeoArray();
      this.CampaignDetails.setGeoArray(this.campaignModel.selectedCampaign.GeoList);
      this.CampaignDetails.setSelectedCampaign(this.campaignModel.selectedCampaign);
    } else {
      this.CampaignDetails.clearGeoArray();
    }
    this.keysOnCurrentForm = {
      put: (key) => {
        this.table[key] = true;
      },
      contains: (key) => {
        return this.table[key];
      }
    };

    this.CampaignDetails.setType(this.campaignType)
    this.type = this.CampaignDetails.getType();
    if (this.CampaignDetails.form.length === 0) {
      this.CampSchema.get("").subscribe(data => {
        // this.campaignSchema = data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].hasOwnProperty("form")) {
            this.getButtons(data[i].sortOrder, data.length, data[i].form);
            let tempForm = data[i].form;//angular.copy(data[i].form);
            if (this.type.toUpperCase() === "NATIONAL") {
              var nationalForm = [];
              for (var j = 0; j < tempForm.length; j++) {
                if ((tempForm[j].customInfo && tempForm[j].customInfo.indexOf("National") > -1) ||
                  tempForm[j].key === "GeoList" || tempForm[j].key === "CCCampaignCatKeys" || tempForm[j].type === "button" || tempForm[j].type === "submit" || tempForm[j].key === "nationalCampaign") {
                  if (this.readonly && tempForm[j].type !== "button" && tempForm[j].type !== "submit") {
                    tempForm[j].readonly = true;
                  }
                  nationalForm[j] = tempForm[j];
                  this.keysOnCurrentForm.put(tempForm[j].key);
                }
              }
              this.form[i] = nationalForm;
              this.form[i] = JSON.parse(JSON.stringify(this.form[i]).replace(/null,/g, ``))
            } else if (this.type.toUpperCase() === "LOCAL") {
              var localForm = [];
              for (var j = 0; j < tempForm.length; j++) {
                if (tempForm[j].key === 'Address1') {
                  // tempForm[j].readonly = true;
                  tempForm[j].readonly = false;
                  tempForm[j].htmlClass = "requiredField";
                } else if (tempForm[j].key === 'Address2') {
                  // tempForm[j].readonly = true;
                  tempForm[j].readonly = false;
                } else if (tempForm[j].key === 'CampaignAddressCity') {
                  // tempForm[j].readonly = true;
                  tempForm[j].readonly = false;
                  tempForm[j].htmlClass = "requiredField";
                } else if (tempForm[j].key === 'CampaignAddressState') {
                  // tempForm[j].readonly = true;
                  tempForm[j].readonly = false;
                  tempForm[j].htmlClass = "requiredField";
                } else if (tempForm[j].key === 'CampaignAddressZip') {
                  // tempForm[j].readonly = true;
                  tempForm[j].readonly = false;
                  tempForm[j].htmlClass = "requiredField";
                } else if (tempForm[j].key === 'CampaignTitle') {
                  // tempForm[j].readonly = true;
                  tempForm[j].readonly = false;
                } else if (tempForm[j].key === '1BusinessProfile') {
                  //tempForm[j].onChange = "vm.changeName(modelValue, form)";
                } else if (tempForm[j].key === '"ListingId"') {
                  tempForm[j].htmlClass = "";
                }
                if ((tempForm[j].customInfo && tempForm[j].customInfo.indexOf("Local") > -1) ||
                  tempForm[j].key === "GeoList" || tempForm[j].key === "CCCampaignCatKeys" || tempForm[j].type === "button" || tempForm[j].type === "submit") {
                  if (this.readonly && tempForm[j].type !== "button" && tempForm[j].type !== "submit") {
                    tempForm[j].readonly = true;
                  }
                  localForm[j] = tempForm[j];
                  this.keysOnCurrentForm.put(tempForm[j].key);
                }
              }
              this.form[i] = localForm;
              this.form[i] = JSON.parse(JSON.stringify(this.form[i]).replace(/null,/g, ``))
              for(let counter = 0; counter < this.form[0].length; counter++){
                if(this.form[0][counter].key === 'CampaignTitle'){
                  this.form[0][counter].type = 'hidden'
                  break
                }
              }
            }
            this.campaignSchema.form.push(this.form[i])
          }
          if (data[i].hasOwnProperty("schema")) {
            var finalSchema = {};
            finalSchema["type"] = data[i].schema["type"];
            finalSchema["title"] = data[i].schema["title"];
            var properties = {};
            finalSchema["properties"] = properties;
            for (var field in data[i].schema.properties) {
              if (this.keysOnCurrentForm.contains(field)) {
                let schemaKey = data[i].schema.properties[field];
                if (this.type.toUpperCase() === "LOCAL"){
                  if(schemaKey["title"] == "Business Profile"){
                    schemaKey["required"] = false;
                  } else if(schemaKey["title"] == "Address"){
                    schemaKey["required"] = true;
                  } else if(schemaKey["title"] == "City"){
                    schemaKey["required"] = true;
                  } else if(schemaKey["title"] == "State"){
                    schemaKey["required"] = true;
                  } else if(schemaKey["title"] == "Zip"){
                    schemaKey["required"] = true;
                  } 
                }
                properties[field] = schemaKey;
              }
            }
            this.schema[i] = finalSchema;
            if (i === 0)
              this.schema[0] = JSON.parse(JSON.stringify(this.schema[0]).replace(null, `"Business Profile"`));
            if (i === 1) {
              this.schema[1] = JSON.parse(JSON.stringify(this.schema[1]).replace(/,"default":null/g, ``))
            }
            this.campaignSchema.schema.push(this.schema[i])
          }
          let counter = this.campaignrequiredFieldCount.get(this.schema[this.campaignModel.campaignPageIndex], this.form[this.campaignModel.campaignPageIndex], this.model);
          let required = 0;

          for (var property in this.schema[i].properties) {
            if (this.schema[i].properties[property].required) {
              required++;
            }
          }
          this.campaignSchema.menuOrig.push({
            content: data[i].name,
            current: i === 0,
            canceled: false,
            counter: counter,
            required: required,
            state: i + ""
          });
          // this.changeDetectorRef.detectChanges();
          let tileCount = this.campaignrequiredFieldCount.get(this.schema[i], this.form[i], this.campaignModel.model);
          if(i === 0){
            this.tile1Count = tileCount
          } else if(i === 1){
            this.tile2Count = tileCount
          } else if(i === 2){
            this.tile3Count = tileCount
          } else if(i === 3) {
            this.tile4Count = tileCount
          }
        }
        //console.log('Campaign schema', this.campaignSchema)
        this.campaignModel.campaignSchema = this.campaignSchema
        this.CampaignDetails.menu = this.utils.getSets(this.CampaignDetails.menuOrig, this.CampaignDetails.menuOrig.length);
        this.isDisplay = true;
        this.campaignStore.update(state => {
          return {
            campaign: this.campaignModel,
            updatedFrom: '[CampaignpopupComponent] ngOnInit'
          }
        })
      })
    }

  }

  initCustomWidgets() {
    this.customWidgets = {
      string: StringComponent,
      profilepicklist: BusinessprofileDropdownComponent,
      showWebsite: WebsiteComponent,
      nationalUrl: NationalUrlComponent,
      button: CampaignbuttonComponent,
      select: DisplaydropdownComponent,
      phonenumberfield: PhonenumberfieldComponent,
      state: StateComponent,
      showEmail: ShowemailComponent,
      geoMaps: GeoComponent,
      campaigncatkeys: CamcatComponent
    }
  }

  getButtons(order, total, form) {
    let sortOrder = parseInt(order);

    if (sortOrder === total) {
      form.push(this.backButton);
      form.push(this.submitButton);
    } else if (sortOrder === 1) {
      form.push(this.cancelButton);
      form.push(this.continueButton);
    } else {
      form.push(this.backButton);
      form.push(this.continueButton);
    }
  }

  changeTile(args, modelData){
    modelData['campaignPageIndex'] = args;
    this.campaignModel = modelData;
    this.getActiveFlagDtls(args);
    this.campaignStore.update(state => {
      return {
        campaign: this.campaignModel,
        updatedFrom: '[CampaignpopupComponent] parentFun'
      }
    })
    this.StorageService.update("campaign", this.Indicator.stateParamsId, this.campaignModel.model);
  }

  async campAddressValidation(args, modelData){
    if(modelData.model.CampaignTitle && modelData.model.CampaignPhone){
      await this.dryrunAddressValidation(modelData);
      console.log("CONTINUE Button method with Validation")
      if(!this.isAddressValid){
        // let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
        modelData.model.Address1 = "";
        modelData.model.CampaignAddressCity = "";
        modelData.model.CampaignAddressZip = "";
        modelData.model.CampaignAddressState = "";
        // modelData.model.CampaignPhone = "";
        modelData.model.TargetZIPcode = "";

        this.changeTile(args, modelData);
      } else {
        this.changeTile(args, modelData);
      }
     } else {
      let error = "Please enter valid Business Name and Campaign Phone";
      this.Logger.logError(error);
    }
  }

  async parentFun(args) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign));
    if(modelData.campaignPageIndex == 1 && args == 2){
      if(modelData.selectedCampaign && 
        modelData.selectedCampaign['modelClicktypes'] && 
        modelData.selectedCampaign['modelClicktypes'] == 'edit') {
          if(!modelData.model['CampaignId'] && !modelData.model.ListingId){
            await this.campAddressValidation(args, modelData);
          } else {
            this.changeTile(args, modelData);
          }
      } else {
        await this.campAddressValidation(args, modelData);
      } 
    } else {
      this.changeTile(args, modelData);
    }
  }

  onChangesCampaign() {
    if(!(this.campaignModel.model.hasOwnProperty("LocalWebsite"))){
      this.campaignModel.model.LocalWebsite = {
        disablelist:true,
        // disableprofile:true,
        disableprofile:false,
        destinationurl:'',
        displayurl:''
      };
    }
    if(!this.isSubscribed){
      this.isSubscribed = true;
      this.subscription = this.campaignQuery.getCampign().subscribe(res => {
      if (this.campaignModel.campaignPageIndex === 0) {
        this.localTile2 = false;
        this.tile1Count = this.campaignrequiredFieldCount.get(this.campaignSchema.schema[this.campaignModel.campaignPageIndex], this.campaignSchema.form[this.campaignModel.campaignPageIndex], this.campaignModel.model);
        let urlcount = 0;
        var url = 0;
        if (this.type.toUpperCase() === 'NATIONAL') {
          //New Fix - if the first tab is not filled and directly gone to other tab
          if (this.campaignModel.campaignPageIndex === 0) {
            urlcount = this.campaignrequiredFieldCount.getNationalUrlCounts(this.campaignModel.model);
            url = urlcount;
          } else {
            url = this.campaignrequiredFieldCount.getNationalUrlCounts(this.campaignModel.model);
          }
          this.tile1Count = this.tile1Count + urlcount;
        } else if (this.type.toUpperCase() === "LOCAL") {
          if (this.campaignModel.campaignPageIndex === 0) {
            urlcount = this.campaignrequiredFieldCount.getLocalUrlCounts(this.campaignModel.model);
            url = urlcount;
          } else {
            url = this.campaignrequiredFieldCount.getLocalUrlCounts(this.campaignModel.model);
          }
          this.tile1Count = this.tile1Count + urlcount;
        }
      } else if (this.campaignModel.campaignPageIndex === 1) {
        if(this.campaignType.toUpperCase() === 'LOCAL'){
          this.localTile2 = true;
        }
        this.tile2Count = this.campaignrequiredFieldCount.get(this.campaignSchema.schema[this.campaignModel.campaignPageIndex], this.campaignSchema.form[this.campaignModel.campaignPageIndex], this.campaignModel.model);
      } else if (this.campaignModel.campaignPageIndex === 2) {
        this.localTile2 = false;
        this.tile3Count = this.campaignrequiredFieldCount.get(this.campaignSchema.schema[this.campaignModel.campaignPageIndex], this.campaignSchema.form[this.campaignModel.campaignPageIndex], this.campaignModel.model);
      } else if (this.campaignModel.campaignPageIndex === 3) {
        this.localTile2 = false;
        this.tile4Count = this.campaignrequiredFieldCount.get(this.campaignSchema.schema[this.campaignModel.campaignPageIndex], this.campaignSchema.form[this.campaignModel.campaignPageIndex], this.campaignModel.model);
      }
      let totalCount = this.tile1Count + this.tile2Count + this.tile3Count + this.tile4Count;
      this.calculateCounter(this.campaignType.toUpperCase(), totalCount);
    })
    } else {}
  }

  ngAfterViewChecked() {
  if (!(<ViewRef>this.cdr).destroyed)
    this.cdr.detectChanges();
  }

  ngOnDestroy(){
    // this.Logger.SEMToastContainerDestroy();
    this.customWidgets = {}
    if (this.subscription != undefined && this.subscription != null)
			this.subscription.unsubscribe();
  }

  cancel() {
    this.clearForm();
  }

  clearForm() {
    this.dialogRef.close();
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign));
    modelData['campaignFlag'] = false;
    modelData['tileFlag4'] = false;
    this.campaignModel = modelData;
    this.campaignStore.update(state => {
      return {
        campaign: this.campaignModel,
        updatedFrom: '[CampaignpopupComponent] clearForm'
      }
    })
    this.Indicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    this.Indicator.campaignFlag = false;
    this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[CampaignpopupComponent] clearForm"
      }
    })
  }

  async onSubmit() {

    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));

    if (this.campaignList) {
      for (var i = 0; i < this.campaignList.length; i++) {
        if (this.campaignList[i].disablelist === false) {
          delete this.campaignList[i].DestinationURL;
          delete this.campaignList[i].DisplayURL;
        }
      }
    } else {
      this.campaignList = [];
    }

    if (this.modelClicktypes === 'copy') {
      let tempObj = JSON.parse(JSON.stringify(this.campaignList))
      delete this.campaignModel.model.CampaignId;
      delete this.campaignModel.selectedCampaign.CampaignId;
      tempObj.push(this.campaignModel.model)
      this.campaignList = tempObj
    }
    if (this.campaignModel.selectedCampaign && this.campaignModel.selectedCampaign.CampaignId) {
      for (var i = 0; i < this.campaignList.length; i++) {
        if (this.campaignList[i].CampaignId === this.campaignModel.selectedCampaign.CampaignId) {
          this.campaignList  = JSON.parse(JSON.stringify(this.campaignList))
          this.campaignList[i] = this.campaignModel.model;
        }
      }
    } else if (this.campaignModel.selectedCampaign && this.campaignModel.selectedCampaign.index > -1) {
      if (this.campaignModel.selectedCampaign.modelClicktypes && this.campaignModel.selectedCampaign.modelClicktypes === 'edit') {
        for (var i = 0; i < this.campaignList.length; i++) {
          if (this.campaignList[i].index === this.campaignModel.selectedCampaign.index) {
            this.campaignList[i] = this.campaignModel.model;
          }
        }
      }
    } else if (this.campaignIndex === -1) {
      //Add campaign flag if model is open and new.
      this.model.campaignFlag = this.campaignModel.campaignFlag;
      this.campaignModel.model.CampaignType = this.campaignModel.campaignType
      let tempObj = JSON.parse(JSON.stringify(this.campaignList))
      tempObj.push(this.campaignModel.model)
      this.campaignList = tempObj
    } else {
      let tempObj = JSON.parse(JSON.stringify(this.campaignList))
      tempObj[this.campaignIndex] = this.campaignModel.model;
      this.campaignList = tempObj
    }
    // {
    //   let tempInd = JSON.parse(JSON.stringify(this.Indicator))
    //   modelData.CampaignType = this.campaignList
    //   this.Indicator = tempInd
    // }
    if (this.type.toUpperCase() === "LOCAL" && this.model && this.model.listingId) {
      await this.indicatorService.addProfileById(modelData,this.model.listingId)
      .then(result =>{
        let existingListingIds = modelData['listingIds']
        if(existingListingIds!=undefined && existingListingIds!=null){
          existingListingIds.push(this.model.listingId)
        }else{
          existingListingIds = []
          existingListingIds.push(this.model.listingId)
        }
        modelData['listingIds'] = existingListingIds
      })

    }
    modelData['items'][this.Indicator.stateParamsId].model[this.layoutNode] = this.campaignList;
    let x = JSON.parse(JSON.stringify(this.Indicator));
    x = modelData;
    this.indicatorStore.update(state => {
      return {
        indicator: x,
        updatedFrom: "[CampaignpopupComponent] onSubmit",
        isloaded: true
      }
    });
    // this.indicatorQuery.updateModel(this.layoutNode, this.campaignList,  modelData, this.Indicator)
    this.clearForm();
    this.isSubmittingForm = true;
    let totalCount = this.tile1Count + this.tile2Count + this.tile3Count + this.tile4Count;
    this.calculateCounter(this.campaignType.toUpperCase(), totalCount);
  }

  getActiveFlagDtls(pageIndex){
    if (pageIndex === 0) {
      this.campaignModel.tileFlag1 = true;
      this.campaignModel.tileFlag2 = false;
      this.campaignModel.tileFlag3 = false;
      this.campaignModel.tileFlag4 = false;
    } else if (pageIndex === 1) {
      this.campaignModel.tileFlag1 = false;
      this.campaignModel.tileFlag2 = true;
      this.campaignModel.tileFlag3 = false;
      this.campaignModel.tileFlag4 = false;
    } else if (pageIndex === 2) {
      this.campaignModel.tileFlag1 = false;
      this.campaignModel.tileFlag2 = false;
      this.campaignModel.tileFlag3 = true;
      this.campaignModel.tileFlag4 = false;
    } else {
      this.campaignModel.tileFlag1 = false;
      this.campaignModel.tileFlag2 = false;
      this.campaignModel.tileFlag3 = false;
      this.campaignModel.tileFlag4 = true;
    }
  }

  calculateCounter(campaignType, totalCount) {
      let requiredCount = 0;
      if(campaignType == 'NATIONAL') {
        requiredCount = 8;
      } else if(campaignType == 'LOCAL') {
        requiredCount = 10;
      }
      let tempIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
      if((this.campaignIndex == 0 ||this.campaignIndex == -1) && !this.isLoadFlag && requiredCount != totalCount ) {
      //  console.log('if::::::::',this.isLoadFlag,'::::::',this.campaignIndex,'::::::::',this.updateCurrentCount)
       tempIndicator.orderPercent.currentCount =   tempIndicator.orderPercent.currentCount + (requiredCount - totalCount);
       tempIndicator.orderPercent.maxCount = tempIndicator.orderPercent.maxCount + requiredCount;
      } else if(this.isLoadFlag && this.campaignIndex== -1) {
      //  console.log('else::::::::',this.isLoadFlag,'::::::',this.campaignIndex)
        tempIndicator.orderPercent.maxCount = tempIndicator.orderPercent.maxCount + requiredCount;
        this.isLoadFlag = false;
      }
      let percentage = this.requiredfieldcount.getPercent(tempIndicator.orderPercent.currentCount, tempIndicator.orderPercent.maxCount);
      tempIndicator.orderPercent.percent = percentage;
      //TODO update the code when open the existing campaign
     /*  tempIndicator.orderPercent.digitalCount = totalCount == 0 ?  tempIndicator.orderPercent.digitalCount != 0 ? tempIndicator.orderPercent.digitalCount - 1 : tempIndicator.orderPercent.digitalCount : tempIndicator.orderPercent.digitalCount + 1;
      let updateCounter = [];
      updateCounter.push(tempIndicator.orderPercent.digitalCount);
      updateCounter.push(tempIndicator.orderPercent.printCount);
      updateCounter.push(tempIndicator.orderPercent.bpTotalCount);
      this.counterService.setError(updateCounter); */
      if(this.isSubmittingForm) {
       // console.log('isSubmittingForm::::::::',this.isSubmittingForm);
        tempIndicator.orderPercent['campaignCount']= tempIndicator.orderPercent['campaignCount'] ? tempIndicator.orderPercent['campaignCount'] + this.updateCurrentCount : requiredCount;
        this.indicatorStore.update((state) => {
          return {
            indicator: tempIndicator,
            updatedFrom: "[CampaignpopupComponent] calculateCounter",
            isloaded: true,
          };
        });
      }
   }

   async dryrunAddressValidation(campaignData){
    let dryrunRequest = {
      "listings": {
        "listing": [
          {
            "enterpriseAccountIds": {
              "enterpriseAccountId": this.Indicator['account']["enterpriseAccountId"]
            },
            "businessName": campaignData.model.CampaignTitle,
            "businessAddress": {
              "zip": campaignData.model.CampaignAddressZip,
              "country": this.Indicator['account']["address"].country,
              "showStreet": "Y",
              "showZip": "Y",
              "showMap": "Y",
              "streetAddress": campaignData.model.Address1,
              "city": campaignData.model.CampaignAddressCity,
              "state": campaignData.model.CampaignAddressState
            },
            "source": this.utils.sourcetype,
            "primaryPhone": {
              // "value": "4124718501",
              "value": campaignData.model.CampaignPhone,
              "show": "Y"
            },
            "fax": {
              "value": "",
              "show": "Y"
            },
            "tollFreePhone": {
              "value": "",
              "show": "Y"
            },
            "alternatePhone": {
              "value": "",
              "show": "Y"
            },
            "websiteUrl": {
              "show": "Y"
            },
            "email": {
              "value": "",
              "show": "Y"
            },
            "categories": {
              "category": [
                // {
                //         "categoryId": "509745",
                //         "categoryName": "Hotels",
                //         "primary": "Yes"
                //       }
              ]
            },
          }
        ]
      }
    }
    
    await this.Profile.dryrun("dryrun", dryrunRequest).then(response => {
      if (response["hasWarningOrError"] === "false") {
      } else {
        var error = "";
        for (var i = 0; i < response["response"].esbServiceFault.length; i++) {
          var errorObject = response["response"].esbServiceFault[i];
          var errorCode = errorObject.exceptionDetail[i].errorCode;
          var errorDesc = errorObject.exceptionDetail[i].errorDescription;
          error += "<strong>Transaction : " + response["response"].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
        }
        this.Logger.logError(error);
        this.ngxspinner.hide();
      }
    }, err => {
      var respObj = err.error.response;
      // var errorObject = respObj.esbServiceFault[0];
      var errorObject;

      if (respObj && respObj.esbServiceFault && respObj.esbServiceFault[0]) {
        errorObject = respObj.esbServiceFault[0];
      } else {
        console.error('Some properties are undefined or not in the expected format.');
        // Handle the situation where properties are not accessible
      }
      var errorCode = errorObject.exceptionDetail[0].errorCode;
      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
      var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
      if(errorDesc.includes("Please provide a valid State-City-Zip combination") || 
        errorDesc.includes("A valid Business Name is required.")){
        this.isAddressValid = false;
        this.Logger.logError(errorMsg);
      } else if(errorDesc.includes("This System can not send request without categories")){
        this.isAddressValid = true;
      }
      this.ngxspinner.hide();
    });
  }
}
