import { Component, OnInit } from '@angular/core';


import { Quickbpclass } from 'app/interface/quickbp';
import { DatasharingService } from '@app/service/datasharing/datasharing.service';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';


@Component({
  selector: 'app-item-phonenumberfield',
  templateUrl: './item-phonenumberfield.component.html',
  styleUrls: ['./item-phonenumberfield.component.scss']
})
export class ItemPhonenumberfieldComponent implements OnInit {

  errorFlag: boolean = false;
  phoneNumber: string = '';
  Indicator;
  notRequired: boolean = false;
  id: any;
  options ;
  titleContent: any;
  schemaObj;
  isReadonly: boolean = false;
  constructor(private indicatorQuery :IndicatorQuery,
     private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.id = this['layoutNode'].name

    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
      this.schemaObj=this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name];
      this.titleContent =  this.schemaObj.title;
      this.isReadonly =this.schemaObj.readonly;
    })
    if (this['layoutNode'].name == "BusinessPhone") {     
      this.phoneNumber =this.Indicator.items[this.Indicator.stateParamsId].model.BusinessPhone;
      this.notRequired = false;
    }
    if (this['layoutNode'].name == "TollFreePhone") {  
      this.phoneNumber =this.Indicator.items[this.Indicator.stateParamsId].model.TollFreePhone;
      this.notRequired = true;
    }
    if (this['layoutNode'].name == "AlternatePhone") { 
      this.phoneNumber =this.Indicator.items[this.Indicator.stateParamsId].model.AlternatePhone;
      this.notRequired = true;
    }
    if (this['layoutNode'].name == "Fax") { 
      this.phoneNumber =this.Indicator.items[this.Indicator.stateParamsId].model.Fax;
      this.notRequired = true;
    }
    this.formatPhoneNumber('Y');
  }

  formatPhoneNumber(calledFromOnInit ) {
    if (this.phoneNumber != undefined && this.phoneNumber !=null) {
      this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, '')
      if (this.phoneNumber.length >= 1) {
        this.phoneNumber = ("(").concat(this.phoneNumber.slice(0))
      }
      if (this.phoneNumber.length >= 5) {
        this.phoneNumber = this.phoneNumber.slice(0, 4).concat(") ", this.phoneNumber.slice(4))
      }
      if (this.phoneNumber.length >= 10) {
        this.phoneNumber = this.phoneNumber.slice(0, 9).concat("-", this.phoneNumber.slice(9))
      }
      if (this['layoutNode'].name == "BusinessPhone") {
        if (this.phoneNumber == undefined || this.phoneNumber.length != 14)
          this.errorFlag = true;
        else
          this.errorFlag = false;
          this.Indicator.items[this.Indicator.stateParamsId].model.BusinessPhone = this.phoneNumber.replace(/[^0-9]/g, '');
      }
      // Toll free phone
      if (this['layoutNode'].name == "TollFreePhone") {
        if (this.phoneNumber.length > 0) {
          if (this.phoneNumber == undefined || this.phoneNumber.length != 14) {
            this.errorFlag = true
           this.Indicator.items[this.Indicator.stateParamsId].model.TollFreePhone = ''
          }
          else {
            this.errorFlag = false;
           this.Indicator.items[this.Indicator.stateParamsId].model.TollFreePhone = this.phoneNumber.replace(/[^0-9]/g, '');
          }
        }
        else {
          this.errorFlag = false;
         this.Indicator.items[this.Indicator.stateParamsId].model.TollFreePhone = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }
      // Alternate phone
      if (this['layoutNode'].name == "AlternatePhone") {
        if (this.phoneNumber.length > 0) {
          if (this.phoneNumber == undefined || this.phoneNumber.length != 14) {
            this.errorFlag = true
           this.Indicator.items[this.Indicator.stateParamsId].model.AlternatePhone = ''
          }
          else {
            this.errorFlag = false;
           this.Indicator.items[this.Indicator.stateParamsId].model.AlternatePhone = this.phoneNumber.replace(/[^0-9]/g, '');
          }
        }
        else {
          this.errorFlag = false;
         this.Indicator.items[this.Indicator.stateParamsId].model.AlternatePhone = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }
      // Fax
      if (this['layoutNode'].name == "Fax") {
        if (this.phoneNumber.length > 0) {
          if (this.phoneNumber == undefined || this.phoneNumber.length != 14) {
            this.errorFlag = true
           this.Indicator.items[this.Indicator.stateParamsId].model.Fax = ''
          }
          else {
            this.errorFlag = false;
           this.Indicator.items[this.Indicator.stateParamsId].model.Fax = this.phoneNumber.replace(/[^0-9]/g, '');
          }
        }
        else {
          this.errorFlag = false;
         this.Indicator.items[this.Indicator.stateParamsId].model.Fax = this.phoneNumber.replace(/[^0-9]/g, '');
        }
      }

      if (calledFromOnInit != 'Y' && !this.errorFlag) {
        let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId].model))
        this.indicatorQuery.updateModel(this['layoutNode'].name,this.phoneNumber.replace(/[^0-9]/g, ''),modelData,this.Indicator, '[ItemPhonenumberfieldComponent] formatPhoneNumber');
      }
    }
  }

}
