import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ItemschemaService {

  constructor(private http: HttpClient, private API: ApiService, private Params: ParamsService) { }

  PATH = "";

  headersObject = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + this.Params.params.token
    }
  }

  get(productType) {
    if (this.Params.params.sforg === 'national') {
      this.PATH = "/api/schemanational/product/";
    } else {
      this.PATH = "/api/schema/product/";
    }
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token;   
    return this.http.get(this.API.get(this.PATH) + productType,this.headersObject).toPromise();
  };
}
