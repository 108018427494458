import { Injectable } from '@angular/core';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  baseUri : string = '/api/order/'
  constructor(private Params : ParamsService,
    private http : HttpClient,
    private API :ApiService
    ) { }

  get(orderId : string) : Promise<any>{
    this.baseUri = this.baseUri + orderId;
    return this.http.get(this.API.get(this.baseUri)).toPromise();
  }
  getMe(): Promise<any>{
    return this.get(this.Params.params.order);
        }

  getMeToPromise(orderId?){
    let url ;
    if(this.Params.params.order)
      url = this.API.get('/api/order/' + this.Params.params.order);
    else
      url = this.API.get('/api/order/' + orderId);   
    return this.http.get(url).toPromise();
        }
}
