import { Component, OnInit } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';

import { UtilsService } from '@app/service/utils/utils.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';


@Component({
  selector: 'app-catextarea',
  templateUrl: './catextarea.component.html',
  styleUrls: ['./catextarea.component.scss']
})
export class CatextareaComponent implements OnInit {
  businessDescription: string = '';
  maxLength: any;
  minLength: any;
  sharedData: Quickbpclass;
  options: any;
  titleContent: any;
  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private utils: UtilsService) { }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.maxLength = this['layoutNode'].options.maxLength;
    this.minLength = this['layoutNode'].options.minLength;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })

    this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BusinessDescription']['title'];

    this.businessDescription = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessDescription"];
  }

  sendValue(args: any) {
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    let descUpdated = args;
    let bsnsDesc;
    if (args) {
      descUpdated = args.replace(/\|/g, "");
      descUpdated = this.utils.removeUnicodeChar(descUpdated);
      bsnsDesc = this.utils.stripTags(descUpdated, '<br><li>')
      bsnsDesc = bsnsDesc.replace(/(\r\n|\n|\r)/gm, " ");
    }
   // this.sharedData['profiles'][this.sharedData.stateParamsId].model["BusinessDescription"] = bsnsDesc;
    modelData["BusinessDescription"] = bsnsDesc;
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;

    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[CatextareaComponent] sendValue"
      }
    })
  }

}
