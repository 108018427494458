import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { UtilsService } from '@app/service/utils/utils.service';
import { StorageService } from '@app/service/storage/storage.service';


import {mapProductToField} from '../../../data/constants';
import { IndicatorQuery } from 'app/state/query';
import { LoggerService } from '@app/service/logger/logger.service';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-contactdropdown',
  templateUrl: './contactdropdown.component.html',
  styleUrls: ['./contactdropdown.component.scss']
})
export class ContactdropdownComponent implements OnInit {
  contactList = [];
  listingIds:any = [];
  productCode:any ;
  indicator;
  stateParamsId:any ;//harcodede the value
  mapping:any;
  options;
  accounts =[];
  titleContent: any;
  ngModel;
  schemaObj;
  isReadonly :boolean=false;
  contactValue;
  isContactPresent:boolean=false;

  constructor(private indicatorQuery : IndicatorQuery,
    private utils: UtilsService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private logger : LoggerService,
    private  indicatorStore :IndicatorStore) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = JSON.parse(JSON.stringify(data));
    });
    this.accounts['contacts'] = this.indicator.account.contacts;
    this.productCode =this.indicator.items[this.indicator.stateParamsId].order.productCode;
    this.mapping = mapProductToField[this.productCode];
    if(this.indicator.items[this.indicator.stateParamsId].model.Contact){
        this.ngModel = this.indicator.items[this.indicator.stateParamsId].model.Contact;
        this.ngModel.ContactPhone =  this.ngModel.ContactPhone ? this.utils.transformTelephone(this.ngModel.ContactPhone) :"";
    }else this.ngModel = {}
      if (this.ngModel.ContactEmail && this.ngModel.ContactEmail != "") {
          if (this.ngModel.contactLabel) {
              this.contactValue = this.ngModel.contactLabel;
              this.ngAfterViewChecked();
          } else {
              this.ngModel.contactLabel = this.selectLabel(this.ngModel);
              this.contactValue = this.ngModel.contactLabel;
              this.ngAfterViewChecked();
          }
      }
    this.schemaObj = this.indicator.items[this.indicator.stateParamsId].schema.properties[this['layoutNode'].name];
    this.titleContent =  this.schemaObj.title;
    this.isReadonly =this.schemaObj.readonly;
        let contactDetailsAcc = this.indicator.account.contacts;
        for (let i = 0; i < contactDetailsAcc.length; i++) {
            if(contactDetailsAcc[i].lastName !== null && contactDetailsAcc[i].phone !== null) {
                if(contactDetailsAcc[i].firstName === null || contactDetailsAcc[i].firstName === undefined){
                    contactDetailsAcc[i].firstName = "";
                }
                if(contactDetailsAcc[i].email === null ||contactDetailsAcc[i].email === undefined){
                    contactDetailsAcc[i].email = "";
                }
                //condition added to remove duplicate object from array
                if(  this.contactList && this.contactList.indexOf(contactDetailsAcc[i]) == -1 ) {
                    this.contactList.push(contactDetailsAcc[i]);
                }else{
                    this.contactList.push(contactDetailsAcc[i]);
                }
                if(this.indicator.items[this.indicator.stateParamsId].model.Contact && (this.indicator.items[this.indicator.stateParamsId].model.Contact.ContactEmail || this.indicator.items[this.indicator.stateParamsId].model.Contact.ContactEmail == "" ) && contactDetailsAcc[i].email == this.indicator.items[this.indicator.stateParamsId].model.Contact.ContactEmail &&contactDetailsAcc[i].phone == this.indicator.items[this.indicator.stateParamsId].model.Contact.ContactPhone ){
                    this.isContactPresent = true;
                }

            }
        }
          this.contactList=this.formatMe(this.contactList);
          if(!this.isContactPresent && this.indicator.items[this.indicator.stateParamsId].model.Contact){
            this.contactList.push(this.indicator.items[this.indicator.stateParamsId].model.Contact);
            } 
      if (!this.ngModel.contactLabel || (this.ngModel.contactLabel && this.ngModel.contactLabel.length <= 0) || (this.ngModel.ContactEmail = "" || !this.ngModel.ContactEmail)) {
          let emailFlag = false
          for (let i = 0; i < this.contactList.length; i++) {
              if (this.contactList[i].ContactEmail && this.contactList[i].ContactEmail != '' && !emailFlag) {
                  emailFlag = true;
                  this.ngModel = this.contactList[i];
                  if (this.ngModel.contactLabel) {
                      this.contactValue = this.ngModel.contactLabel;
                  } else {
                      this.ngModel.contactLabel = this.selectLabel(this.ngModel);
                      this.contactValue = this.ngModel.contactLabel;
                  }
                  let tempObj = JSON.parse(JSON.stringify(this.indicator));
                  tempObj.items[this.indicator.stateParamsId].model.Contact = this.contactList[i];
                  this.indicatorStore.update(state => {
                      return {
                          indicator: tempObj,
                          updatedFrom: "[ContactdropdownComponent] ngOnInit",
                          isloaded: true
                      }
                  });
              }
              if (emailFlag) {
                  break;
              }
        }
            // let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].model))
            // this.indicatorQuery.updateModel(this['layoutNode'].name, this.contactList[0],  modelData, tempObj);
          }
      }
    ngAfterViewChecked() {
        if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();
    }
  compareFn(a,b){
      console.log('inside compare me !!!!!!!!!!!!!!!--a',a,'b value is',b);
  }

  //for dropdown format
  formatMe(actualList){
    let temp=[];
    if(actualList[0]){
        for (let i = 0; i < actualList.length; i++) {
            let contact={};
            for (let k in actualList[i]) {
                if(this.mapping[k]){
                    if((this.productCode=="SEO" ||  this.productCode=="DPM") && k=="address"){
                        //TODO: request JSON should be corrected to avoid overhead
                        //As address is complex object, adding models directly
                        let addr=(actualList[i]["address"]);
                        contact["Address1"]=(addr["street1"])?(addr["street1"]): " ";
                        contact["City"]=(addr["city"])?addr["city"]:" ";
                        contact["State"]=(addr["state"])?addr["state"]:" ";
                        contact["Zip"]=(addr["zip"])?addr["zip"]:" ";
                        continue;
                    }
                    contact[this.mapping[k]]=actualList[i][k];
                }

            }
            if(this.productCode=="SuperClick" || this.productCode=="SEMP"){
                contact["ContactName"]=actualList[i]["firstName"]+ " "+actualList[i]["lastName"];
            }

           temp[i] = contact;
           temp[i].contactLabel =this.selectLabel(contact);
        };
    }
    return temp;
    };


//Contact display mapping
selectLabel(item) {
    let label = "";
    if(item){
        if(item[this.mapping["firstName"]]){
            label += item[this.mapping["firstName"]]+" ";
        }
        if(this.productCode=="SuperClick" || this.productCode=="SEMP"){
            label += "/" +" ";
        }
        if(item[this.mapping["lastName"]]){
        label += item[this.mapping["lastName"]] + " " + "/" +" ";
        }
        if(item[this.mapping["email"]]){
        label += item[this.mapping["email"]] + " " + "/" +" ";
        }
        if (item[this.mapping["ContactPhone"]]) {
            label += this.utils.transformTelephone(item[this.mapping["ContactPhone"]]);
        }else if(item[this.mapping["phone"]] ){
        label +=  this.utils.transformTelephone(item[this.mapping["phone"]]);
        }

        if(label==="/ "){
            return;
        }else{
            return label;
        }
    }
    return label;
};
//On dropdwon change
changeContact(item){
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    let contactObj =  this.contactList  ?  this.contactList.find(itemObj => itemObj.contactLabel == item) : null;
    let oldLabel =  "";
    this.indicator['version'] = 1
    if(this.indicator.items[this.indicator.stateParamsId].model.Contact){
        oldLabel = this.selectLabel(this.indicator.items[this.indicator.stateParamsId].model.Contact);
    }
    let tempLabel = this.selectLabel(contactObj);
    let activityLogHistoryObj = {
        'displayName':this.indicator.user.name, //commenting it because not getting data from indicator
        'email':this.indicator.user.email,
        'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
        'itemId':this.indicator.stateParamsId,
        'itemStatus': this.indicator.items[this.indicator.stateParamsId].order.status,
        'itemActionCode':this.indicator.items[this.indicator.stateParamsId].order.actionCode + "-" + this.indicator.items[this.indicator.stateParamsId].order.actionType,
        'fieldName':'Contact',
        'fieldOldValue':oldLabel,
        'fieldNewValue':tempLabel
    }
    this.storageService.update("itemHistory", this.indicator.stateParamsId + "v" + this.indicator['version'], activityLogHistoryObj);
    let  tempObj =JSON.parse(JSON.stringify(this.indicator));
    modelData.items[this.indicator.stateParamsId].model.Contact = contactObj;
    let x = JSON.parse(JSON.stringify(this.indicator));
    x = modelData;
    this.indicatorStore.update(state => {
      return {
        indicator: x,
        updatedFrom: "[ContactdropdownComponent] changeContact",
        isloaded: true
      }
    });
    // this.indicatorQuery.updateModel(this['layoutNode'].name, contactObj,  modelData, tempObj);

};

//for contact dropdown initialization
contactInit(item){
    this.selectLabel(item);
    if(item === undefined || item == "") {
        this.ngModel = {};
    } else {
        this.ngModel = item;
        this.ngModel.Phone = this.utils.formatPhoneNumber(item.Phone);
    }
};


}
