import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { SpinnerComponent } from './layout/main/spinner/spinner.component';
import { ParamsService } from './core/service/params/params.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Location } from "@angular/common";
import { OktaAuthService } from './app.service';
import { NodeApiEnvUrlService } from '@app/service/nodeApiEnvUrl/node-api-env-url.service';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = 'contentassistant';
  isAuthenticated: boolean;
  constructor(public dialog: MatDialog, private params: ParamsService, private route: ActivatedRoute, private location: Location,
    private router: Router, public oktaAuth: OktaAuthService, public nodeApiEnvUrlService: NodeApiEnvUrlService) { }
  ngOnInit() {
    if(this.oktaAuth && this.oktaAuth.$isAuthenticated) {
      this.oktaAuth.$isAuthenticated.subscribe(val => {
        this.isAuthenticated = val
        if (val) {
          this.openSpinner();
        }
        console.log("this.location.path()", this.location.path());
        sessionStorage.setItem("nginxServer",window.location.hostname);
        console.log("window.location.hostname",window.location.hostname);
      });
    }

    this.router.events.subscribe(val => {
    //  if (val instanceof NavigationStart) {
        if (this.location.path() != "/callback" && this.location.path() != "/accessdenied" && this.location.path().indexOf("?") < 0) {
          this.location.replaceState(sessionStorage.getItem('caangulareffererPath'));
        }
       // console.log("this.location .path --->", this.location.path());
        if (this.location.path() != "") {
          let locationPath = "";
          if(sessionStorage.getItem('okta-app-url') != null && sessionStorage.getItem('okta-app-url') != undefined){
            let oktaurlArray = sessionStorage.getItem('okta-app-url').split('?');
            if(oktaurlArray != null && oktaurlArray != undefined && oktaurlArray.length >= 2){
              locationPath = sessionStorage.getItem('okta-app-url').split('?')[1] + this.nodeApiEnvUrlService.getNodeUrl("locationurl");
            }
            else{
              locationPath = this.nodeApiEnvUrlService.getNodeUrl("locationurl");
            }
          }
          else{
            locationPath = this.nodeApiEnvUrlService.getNodeUrl("locationurl");
          }
          let targetObj = {};
          let paramsObj = Object.assign(targetObj, ...locationPath.split('&').map((s: string) => s.split('=')).map(([k, v]) => ({ [k]: v })));
          let user = paramsObj.user != undefined? paramsObj.user: paramsObj.User
          for(let key in paramsObj){
            if(key && key != 'account' && key.toUpperCase() == "ACCOUNT"){
              paramsObj['account'] = paramsObj[key];
              delete paramsObj[key];
            }else if(key && key != 'order' && key.toUpperCase() == "ORDER"){
              paramsObj['order'] = paramsObj[key];
              delete paramsObj[key];
            }else if(key && key != 'mode' && key.toUpperCase() == "MODE"){
              paramsObj['mode'] = paramsObj[key];
              delete paramsObj[key];
            }else if(key && key != 'eaId' && key.toUpperCase() == "EAID"){
              paramsObj['eaId'] = paramsObj[key];
              delete paramsObj[key];
            }else if(key && key != 'user' && key.toUpperCase() == "USER"){
              paramsObj['user'] = paramsObj[key];
              delete paramsObj[key];
            }
          }
          sessionStorage.setItem("currentuserid", user);
          sessionStorage.setItem("currentOrderId", paramsObj.order);
          sessionStorage.setItem("currentAccountId", paramsObj.account);
          sessionStorage.setItem("currentListingId", paramsObj.listingId);
          sessionStorage.setItem("partneraccountID", paramsObj.partneraccountID);
          let mode = paramsObj.mode ? paramsObj.mode: "order";
          sessionStorage.setItem("mode", mode);
          sessionStorage.setItem("currentEaId", paramsObj.eaId);
          sessionStorage.setItem("isCopyBP", "false");
          this.params.getParamsData(paramsObj, false);
        }
     // }
    });
  }
  openSpinner(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.height = '350px';
    //dialogConfig.maxHeight ='350px';

    const dialogRef = this.dialog.open(SpinnerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      //console.log("Dialog output:")
    });
  }
  // sample jSON schema form library object just for testing
  // exampleJsonObject = {
  //   first_name: "Jane",
  //   last_name: "Doe",
  //   age: 25,
  //   is_company: false,
  //   address: {
  //     street_1: "123 Main St.",
  //     street_2: null,
  //     city: "Las Vegas",
  //     state: "NV",
  //     zip_code: "89123",
  //   },
  //   phone_numbers: [
  //     { number: "702-123-4567", type: "cell" },
  //     { number: "702-987-6543", type: "work" },
  //   ],
  //   notes: "",
  // };
}
