import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { SalesforceService } from '../salesforce/salesforce.service'
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})

export class ValidateService {
  baseUri:string = '/api/validate';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
    constructor(
    private salesforceService: SalesforceService,
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService) { }

    association = {}
    resFlag =false;

    get(id: any, accountId: any) {
        return new Promise((resolve, reject) => {
            this.salesforceService.listingAssociatedIypEss(id, accountId).subscribe(function (data) {                
                this.association = {
                    "geographies": [],
                    "hasIYPBasic": false,
                    "hasIYPPremium": false
                }
                //this.association.geographies = [];
                //Put record.geography in association which is vm.association[id]


                var records = data["records"]    //This contains all IYP/ESS items assicated with the listing

                if (records && records.length > 0) {
                    for (var i = 0; i < records.length; i++) {
                        if (records[i].Product2.Product_Code__c === "Single IYP") {

                            //put geography data in vm.association[listingid]
                            processData(records[i].Listing_Id__c, this.association, records[i]);
                            this.association.hasIYPBasic = true;
                        } else if (records[i].Product2.Product_Code__c === "Premium IYP") {
                            processData(records[i].Listing_Id__c, this.association, records[i]);
                            this.association.hasIYPPremium = true;
                        }
                    }
                }

                function processData(id, association, record) {
                    //association[id] = association[id] ? association[id] : {};

                    var tempGeo = record.Geography_Level__c + " of " + record.Geography_Name__c;

                    association.geographies = association.geographies ? association.geographies : [];

                    //Add to the list if not present. It prevents duplication of geographies
                    if (association.geographies.indexOf(tempGeo) === -1) {

                        association.geographies.push(tempGeo);
                    }
                }
                resolve (this.association);
            })
        });

    }

    getURLValidation(url:any, cache:any): Observable<any> {
        return this.http.get(this.API.get(this.baseUri) + "/?" + "url" + "=" + url)
        //return $http.get(path, { cache: (cache != null) ? cache : false });
    };
    validateurl(url:any): Observable<any>{
       // var path = API.get("/api/validate" + "/" +encodeURIComponent(url));
       this.resFlag = false;        
        return this.http.get(this.API.get(this.baseUri) + "/?" + "url" + "=" + url)
        //this.resData = $http.get(path, data);
        //return  vm.resData;
    };

    getResponse(){
        return this.resFlag;
    };

    setResponse(response){
        this.resFlag = response;
    };
}
