import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  constructor(
    private http: HttpClient,
    private API: ApiService,
    private Params: ParamsService
  ) { }
  baseUri: string = '/api/logo/';
  
  get(listingId: string) {
    return this.http.get(this.API.get(this.baseUri) + listingId);
  }

  delete(iconid: string, delFlag: string): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + iconid + "/" + delFlag);
  }

  uploadLogo(id: string, data: any): Observable<any> {
    return this.http.post(this.API.get(this.baseUri) + "upload", data);
  };
  getListingFromDB(listingId: string): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + "view/" + listingId);
  };
  getuploadStatus(varRand: string): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + "status/" + varRand);
  };
}
