import { Component, OnInit } from '@angular/core';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignStore } from 'app/state/campaignstore';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorQuery } from 'app/state/query';

import { ProfileService } from '@app/service/profile/profile.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-campaignbutton',
  templateUrl: './campaignbutton.component.html',
  styleUrls: ['./campaignbutton.component.scss']
})
export class CampaignbuttonComponent implements OnInit {

  hideButton: boolean = false
  campaignModel: CampaignClass;
  title: string = '';
  id: string = '';
  htmlClass: string = '';
  style: string = '';
  Indicator: any
  isAddressValid: boolean = false;
  
  constructor(private campaignQuery: CampaignQuery,
    private campaignStore: CampaignStore,
    private StorageService: StorageService,
    private indicatorQuery: IndicatorQuery,
    private Profile: ProfileService,
    private utils: UtilsService,
    private Logger: LoggerService,
    private ngxspinner: NgxSpinnerService) { }

  ngOnInit() {
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data}
    })
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data}
    })

    this.id = this['layoutNode'].name
    
    if(this['layoutNode'].name != undefined ){
      if (this['layoutNode'].name == "BACK") {
        this.title = "BACK";
        this.style = "btn-info special";
        this.htmlClass = "col-xs-6"
      }
  
      if (this['layoutNode'].name == "CONTINUE") {
        this.title = "CONTINUE";
        this.style = "btn-info special";
        this.htmlClass = "col-xs-6"
      }
  
      if (this['layoutNode'].name == "CANCEL" || this['layoutNode'].name == "SAVE") {
        this.hideButton = true;
      }
    } else {
      this.hideButton = true;
    }
  }
  async dryrunAddressValidation(campaignData){
    let dryrunRequest = {
      "listings": {
        "listing": [
          {
            "enterpriseAccountIds": {
              "enterpriseAccountId": this.Indicator['account']["enterpriseAccountId"]
            },
            "businessName": campaignData.model.CampaignTitle,
            "businessAddress": {
              "zip": campaignData.model.CampaignAddressZip,
              "country": this.Indicator['account']["address"].country,
              "showStreet": "Y",
              "showZip": "Y",
              "showMap": "Y",
              "streetAddress": campaignData.model.Address1,
              "city": campaignData.model.CampaignAddressCity,
              "state": campaignData.model.CampaignAddressState
            },
            "source": this.utils.sourcetype,
            "primaryPhone": {
              // "value": "4124718501",
              "value": campaignData.model.CampaignPhone,
              "show": "Y"
            },
            "fax": {
              "value": "",
              "show": "Y"
            },
            "tollFreePhone": {
              "value": "",
              "show": "Y"
            },
            "alternatePhone": {
              "value": "",
              "show": "Y"
            },
            "websiteUrl": {
              "show": "Y"
            },
            "email": {
              "value": "",
              "show": "Y"
            },
            "categories": {
              "category": [
                // {
                //         "categoryId": "509745",
                //         "categoryName": "Hotels",
                //         "primary": "Yes"
                //       }
              ]
            },
          }
        ]
      }
    }
    
    await this.Profile.dryrun("dryrun", dryrunRequest).then(response => {
      if (response["hasWarningOrError"] === "false") {
      } else {
        var error = "";
        for (var i = 0; i < response["response"].esbServiceFault.length; i++) {
          var errorObject = response["response"].esbServiceFault[i];
          var errorCode = errorObject.exceptionDetail[i].errorCode;
          var errorDesc = errorObject.exceptionDetail[i].errorDescription;
          error += "<strong>Transaction : " + response["response"].transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
        }
        this.Logger.logError(error);
        this.ngxspinner.hide();
      }
    }, err => {
      var respObj = err.error.response;
      var errorObject = respObj.esbServiceFault[0];
      var errorCode = errorObject.exceptionDetail[0].errorCode;
      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
      var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
      if(errorDesc.includes("Please provide a valid State-City-Zip combination") || 
        errorDesc.includes("A valid Business Name is required.")){
        this.isAddressValid = false;
        this.Logger.logError(errorMsg);
      } else if(errorDesc.includes("This System can not send request without categories")){
        this.isAddressValid = true;
      }
      this.ngxspinner.hide();
    });
  }
  changeTile(campaignData){
    let pageIndex = campaignData.campaignPageIndex + 1;
    campaignData.campaignPageIndex = pageIndex;
    this.StorageService.update("campaign", this.Indicator.stateParamsId, campaignData.model);

    this.getActiveFlagDtls(campaignData);
    let x = JSON.parse(JSON.stringify(campaignData))
    x = campaignData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[CampaignbuttonComponent] buttonMethods'
      }
    })
  }
  async campAddressValidation(campaignData){
    if(campaignData.model.CampaignTitle && campaignData.model.CampaignPhone){
      await this.dryrunAddressValidation(campaignData);
      if(!this.isAddressValid){
        // let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
        campaignData.model.Address1 = "";
        campaignData.model.CampaignAddressCity = "";
        campaignData.model.CampaignAddressZip = "";
        campaignData.model.CampaignAddressState = "";
        // modelData.model.CampaignPhone = "";
        campaignData.model.TargetZIPcode = "";

        this.changeTile(campaignData);
      } else {
        this.changeTile(campaignData);
      }
     } else {
      let error = "Please enter valid Business Name and Campaign Phone";
      this.Logger.logError(error);
    }
  }
  async buttonMethods(){
    let campaignData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign))
    if (this.title === "CONTINUE") {
      // let pageIndex = campaignData.campaignPageIndex + 1;
      // campaignData.campaignPageIndex = pageIndex;
      // this.StorageService.update("campaign", this.Indicator.stateParamsId, campaignData.model);

      if(campaignData.campaignPageIndex == 1){
        if(campaignData.selectedCampaign && 
          campaignData.selectedCampaign['modelClicktypes'] && 
          campaignData.selectedCampaign['modelClicktypes'] == 'edit') {
            if(!campaignData.model['CampaignId'] && !campaignData.model.ListingId){
              await this.campAddressValidation(campaignData);
            } else {
              this.changeTile(campaignData);
            }
        } else {
          await this.campAddressValidation(campaignData);
        }
      } else {
        this.changeTile(campaignData);
      }
      // else {
        // let pageIndex = campaignData.campaignPageIndex + 1;
        // campaignData.campaignPageIndex = pageIndex;
        // this.StorageService.update("campaign", this.Indicator.stateParamsId, campaignData.model);

        // this.getActiveFlagDtls(campaignData);
        // let x = JSON.parse(JSON.stringify(campaignData))
        // x = campaignData
        // this.campaignStore.update(state => {
        //   return {
        //     campaign: x,
        //     updatedFrom: '[CampaignbuttonComponent] buttonMethods'
        //   }
        // })
      // }
    } else if (this.title === "BACK") {
      let pageIndex = campaignData.campaignPageIndex - 1;
      campaignData.campaignPageIndex = pageIndex;
      this.StorageService.update("campaign", this.Indicator.stateParamsId, campaignData.model);

      this.getActiveFlagDtls(campaignData);
      console.log("Button method last store update")

      let x = JSON.parse(JSON.stringify(this.campaignModel))
      x = campaignData
      this.campaignStore.update(state => {
        return {
          campaign: x,
          updatedFrom: '[CampaignbuttonComponent] buttonMethods'
        }
      })
    }
  }

  cuttonClass(title) {
    if (title === 'CONTINUE') {
      return 'continue-btn';
    }
    if(title === 'BACK') {
      return 'cancel-button'
    }
  }

  getActiveFlagDtls(campaignData){
    if (campaignData.campaignPageIndex == 0) {
      campaignData.tileFlag1 = true;
      campaignData.tileFlag2 = false;
      campaignData.tileFlag3 = false;
      campaignData.tileFlag4 = false;
    } else if (campaignData.campaignPageIndex == 1) {
      campaignData.tileFlag1 = false;
      campaignData.tileFlag2 = true;
      campaignData.tileFlag3 = false;
      campaignData.tileFlag4 = false;
    } else if (campaignData.campaignPageIndex === 2) {
      campaignData.tileFlag1 = false;
      campaignData.tileFlag2 = false;
      campaignData.tileFlag3 = true;
      campaignData.tileFlag4 = false;
    } else {
      campaignData.tileFlag1 = false;
      campaignData.tileFlag2 = false;
      campaignData.tileFlag3 = false;
      campaignData.tileFlag4 = true;
    }
  }

}
