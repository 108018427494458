import { Component, OnInit } from '@angular/core';

import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) { }

  id:any;
  invalid: boolean = false;
  errorMsg;
  isReadonly: boolean = false;
  schemaObj ;

  sharedData: Quickbpclass;
  textInput = '';
  options: any;
  titleContent: any;
  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data};
    })

    this.id = this['layoutNode'].name
    if (this['layoutNode'].name == "WhatsNew"){
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId].schema[1]['properties']['WhatsNew']['title'];
    }
    else if(this["layoutNode"].name == "AdditionalInfo"){
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId].schema[1]['properties']['AdditionalInfo']['title'];
    }
    if (this['layoutNode'].name == "Notes"){
      this.schemaObj = this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name];
      //this.textInput = this.sharedData['items'][this.sharedData.stateParamsId].model[this['layoutNode'].name];
      this.textInput = this.sharedData['items'][this.sharedData.stateParamsId].model.Notes;
      // this.isRequiredField = this.schemaObj.required;
       this.isReadonly = this.schemaObj.readonly;
      this.titleContent = this.schemaObj['title'];
    }
    if (this['layoutNode'].name == "WhatsNew" &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model.WhatsNew) {
      this.textInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.WhatsNew;
      if (this.textInput != undefined && this.textInput != null && this.textInput.length > 200) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.textInput.length + ' chars), maximum 200'
      } else {
        this.invalid = false;
      }
    }

    if (this["layoutNode"].name == "AdditionalInfo" &&
      this.sharedData['profiles'][this.sharedData.stateParamsId].model.AdditionalInfo) {
      this.textInput = this.sharedData['profiles'][this.sharedData.stateParamsId].model.AdditionalInfo;
      if (this.textInput != undefined && this.textInput != null && this.textInput.length > 200) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.textInput.length + ' chars), maximum 200'
      } else {
        this.invalid = false;
      }

    }
  }
  sendValue(args) {
    if (this['layoutNode'].name === "Notes") {
      let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
      if (args != undefined && args != null && args.length > 500) {
        this.invalid = true;
        this.errorMsg = 'String is too long (' + this.textInput.length + ' chars), maximum 500'
      } else {
        this.invalid = false;
          //update store;
          modelData.items[this.sharedData.stateParamsId].model.Notes = args;
        // let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].model))
        // this.indicatorQuery.updateModel(this['layoutNode'].name, args,  modelData, tempObj);
      this.indicatorStore.setLoading(true);
      this.indicatorStore.update(state =>{
        return{
          indicator: modelData,
          updatedFrom: "[TextareaComponent] sendvalue",
          isloaded: true
        }
      });
      this.indicatorStore.setLoading(false);
      }

    }else{
      let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
      if (this['layoutNode'].name === "WhatsNew") {
        if (args != undefined && args != null && args.length > 200) {
          modelData['WhatsNew'] = '';
          this.invalid = true;
          this.errorMsg = 'String is too long (' + this.textInput.length + ' chars), maximum 200'
        } else {
          modelData['WhatsNew'] = args;
          this.invalid = false;
        }
      }

      if (this['layoutNode'].name === "AdditionalInfo") {
        if (args != undefined && args != null && args.length > 200) {
          this.invalid = true;
          modelData['AdditionalInfo'] = '';
          this.errorMsg = 'String is too long (' + this.textInput.length + ' chars), maximum 200'
        } else {
          this.invalid = false;
          modelData['AdditionalInfo'] = args;
        }
      }
      let x = JSON.parse(JSON.stringify(this.sharedData));
      if(this['layoutNode'].name === "Notes")
      x = modelData;
      else
      x['profiles'][this.sharedData.stateParamsId].model = modelData;

      this.indicatorStore.setLoading(true);
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[TextareaComponent] sendvalue1",
          isloaded: true
        }
      });
      this.indicatorStore.setLoading(false);
    }

  }
}
