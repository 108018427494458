import { Component, OnInit, Inject } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ShowHistoryComponent } from '../show-history/show-history.component';
import { FooterService } from '../footer/footer.service';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})

export class PageFooterComponent implements OnInit {
  
  sharedData: any;
  Indicator;
  disableTrue: boolean = false;
  constructor(private dialog: MatDialog, 
    private indicatorQuery :IndicatorQuery, 
    private footerService: FooterService) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = { ...res };
      this.sharedData = JSON.parse(JSON.stringify(res));
    });
  }
  //mat dialouge
/**
 * @title Dialog Overview
 */ 
// 
openDialog(orderItem) {
  this.disableTrue = true;
  this.footerService.openChangeHistoryDialog(orderItem, ShowHistoryComponent);
  this.disableTrue = false;
  }
  
}
