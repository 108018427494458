import { Component, OnInit } from '@angular/core';
import { CampaignClass } from 'app/interface/campaign';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignStore } from 'app/state/campaignstore';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-displaydropdown',
  templateUrl: './displaydropdown.component.html',
  styleUrls: ['./displaydropdown.component.scss']
})
export class DisplaydropdownComponent implements OnInit {

  options: any;
  titleMaps = [];
  campaignModel: CampaignClass;
  selectedOption: string = ''
  titleContent: string = ''
  Indicator: any;
  readonly: boolean = false;

  constructor(private campaignQuery: CampaignQuery,
    private campaignStore: CampaignStore,
    private indicatorQuery: IndicatorQuery) { }

  ngOnInit() {
    this.options = this['layoutNode'].options || {};
    this.titleMaps = this.options.titleMap;
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data }    
    })
    this.titleContent = this.campaignModel.campaignSchema.schema[this.campaignModel.campaignPageIndex].properties[this['layoutNode'].name].title
    if (this['layoutNode'].name === 'DisplayAddress') {
      this.selectedOption = this.campaignModel.model.DisplayAddress
    }

    if (this['layoutNode'].name === 'PublishPhone') {
      this.selectedOption = this.campaignModel.model.PublishPhone
    }
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
      for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
        if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
          break;
        }
      }
    })
  }

  sendValue(args: any) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    if (this['layoutNode'].name === 'DisplayAddress') {
      modelData.DisplayAddress = args
    }

    if (this['layoutNode'].name === 'PublishPhone') {
      modelData.PublishPhone = args
    }

    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[DisplaydropdownComponent] sendValue'
      }
    })
  }

}
