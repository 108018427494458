export let productType :any =["Print","Digital"];
export let productTypePrint ="Print";
export let productTypeDigital ="Digital";
export let productCode :any =[
"SEMP",
"SEO",
"DPM" , 
"SEM Kicker",
"Free Listing",
"SEMCustom Website",
"SEOG Add On",
"YPC",
"Premium IYP",
"Single IYP",
"SSB",   
"Dex Hub", 
"Display Brand Awareness",
"Custom Website"
];
export let productCodeSEMP ="SEMP";
export let productCodeSEO ="SEO";
export let productCodeDPM ="DPM";
// export let productCodeSEO ="SEO";
// export let productCodeSEO ="SEO";
// export let productCodeSEO ="SEO";
// export let productCodeSEO ="SEO";
// export let productCodeSEO ="SEO";

export let dexHubItems = ["Dex Hub", "Google Places", "Local Power Listings"];
export let dexHubAddOn = ["Custom Website", "Video Add On", "GVV Add On", "Logo Add On", "SEOG Add On", "Social Premium Facebook Add On","Websites Adv Features","Photo Package Add On"];

export let mapProductToField ={"SuperClick":{"firstName":"ContactName","email":"ContactEmail","phone":"ContactPhone","contactLabel":"label"},
"SEMP":{"firstName":"ContactName","email":"ContactEmail","phone":"ContactPhone","ContactPhone":"ContactPhone","contactLabel":"label"},
"SEO":{"firstName":"FirstName","lastName":"LastName","email":"EmailID","phone":"Phone","address":"fakeAddress","contactLabel":"label"},
"DPM":{"firstName":"FirstName","lastName":"LastName","email":"ContactEmail","phone":"ContactPhone","address":"fakeAddress","contactLabel":"label"},
"Display Brand Awareness":{"firstName":"FirstName","lastName":"LastName","email":"Email","phone":"Phone","contactLabel":"label"},
"SEOG Add On":{"firstName":"FirstName","lastName":"LastName","email":"EmailID","phone":"Phone","contactLabel":"label"}
};

export let contactKeyValue =['FirstName', 'LastName', 'Phone', 'EmailID','ContactName','ContactEmail','ContactPhone'];
export let SEMPCONTACTKEYS = ['ContactName','ContactEmail','ContactPhone'];

export let constant ={

    billingAccountIndicator:"",
    kgenBillingDetails:"",
    BRM_BILLING_DETAILS: "BRM",
    LEGACY_BILLING_DETAILS: "LEGACY",
    KGEN_BILLING_DETAILS: "KGEN",
    BILLING_ACCOUNT_STATUS_ACTIVE: "ACTIVE",
    ITEM_TYPE_PRINT: "Print",
    ITEM_TYPE_DIGITAL: "Digital",
    //THRYV_3_15_PROD_CODE :["THRYVPHOTO","THRYV360","THRYVUNLIM","THRYVSTART","THRYVESSEN","THRYVPLUS","THRYVPROF","THRYVWEBST","THRYVIDIMP","THRYVSOCCU","THRYVBOOST","SSDX1","SSDX","SEOMBG4","SEOMBG8","SEOMBG6","SEOMBG1K","SEOMBG12","SEOMBG14","SEOMBG16","SEOMBG18","SEOMBG25","SEOMBG3K","SEOMBG35","SEOMBG4K","SEOMBG45","SEOMBG5K","SEOMBG6K","SEOMBG7K","IYPPRTNB","IYPCTNB","IYPPRCONB","PLNB","THRYV5PAGE","THRYVLOGO","LEADSB","LEADSPL","LEADSPR","THRYVESNSU","THRYVPLUSU","THRYVPROSU","THRYVUNLSU"],
    BILLING_TYPE_CREATE : "create",
    NATIONALUDAC_XDCALL : "XDCALL",
    NATIONALUDAC_XDCPM : "XDCPM",
    NATIONALUDAC_XDFCHS : "XDFCHS",
    NATIONALUDAC_XDPPC :"XDPPC",
    NATIONALUDAC_SPCITMPPC:"SPCITMPPC",
    NATIONALUDAC_SPPFPCLR:"SPPFPCLR",
    NATIONALUDAC_SPPFPICNL:"SPPFPICNL",
    NATIONALUDAC_SPPFPICNN:"SPPFPICNN",
    NATIONALUDAC_SPPFPITL:"SPPFPITL",
    NATIONALUDAC_SPPFPSZE:"SPPFPSZE",
    NATIONALUDAC_SPPPCAS:"SPPPCAS",
    NATIONALUDAC_SPPPCNS:"SPPPCNS",
    NATIONALUDAC_SPPPCVC:"SPPPCVC",
    NATIONALUDAC_SEONAT:"SEONAT",
    NATIONALUDAC_DPM:"DPM",
    SEO_PRODUCT_CODE: ["SEO", "SEOG Add On", "Thryv SEOGBIY"],
    PROFILE_DELETEBP:"",
    Confirmation_Number_MaxLength: "",
    ANGULAR8_URL:"",
    SOCKET_URL:"",
    // Blacklisted Email id 
    BLOCK_EMAIL_ID : ["m4.adsolutions.yp.com","marketing.dexmediaemail.com","yellowpages.com","supermedia.com","m5.adsolutions.yp.com","haineslocal.com","Dexinternet.com","dexknows.com","m1.ypmarketing.com","thryv.mail.onmicrosoft.com","dexautomotive.com","dexone.com","Superpages.com","thryvhotel.com","email.dexmedia.com","marketing.dexmedia.com","dexmedia.com","berrynetwork.com","yp.com","ypdirect.com","ml.yellowpages.com","dexyp.com","thryv.com","email.dexmediaemail.com","ms.yp.com","m2.adsolutions.yp.com","qa.dexmedia.com","sales.yp.com","yellowone.com","m1.adsolutions.yp.com","thryvinc.com","thryv.onmicrosoft.com","enlightenme.com","m1.ypcommunications.com","m3.adsolutions.yp.com","Thryvfoundation.org","lmberry.com"],
    SHOW_NG8_BP : '',
    LOCATIONURL : "&locked=false&admin=false&api=https%3A%2F%2Fucc-dev.dexmedia.com&payNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FpaynowtoolPM%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftxacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FpaynowtoolPM%2FDefaultLanding.aspx&sforg=national",
    LOCATTIONURL_PM: "&locked=false&admin=false&api=https%3A%2F%2Fucc-pm.dexmedia.com&payNow=https%3A%2F%2Fpm-casa.st1.corp.pvt%2FpaynowtoolPM%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftxacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fpm-casa.st1.corp.pvt%2FpaynowtoolPM%2FDefaultLanding.aspx&sforg=national",
    LOCATIONURL_DEV: "&locked=false&admin=false&api=https%3A%2F%2Fucc-dev.dexmedia.com&payNow=https%3A%2F%2Fpm-casa.st1.corp.pvt%2FpaynowtoolPM%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftxacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fpm-casa.st1.corp.pvt%2FpaynowtoolPM%2FDefaultLanding.aspx&sforg=national",
    LOCATIONURL_CFIT: "&locked=false&admin=false&api=https%3A%2F%2Fucc-cfit.dexmedia.com&payNow=https%3A%2F%2Fcfit-casa.st1.corp.pvt%2FpaynowtoolTest2%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftaacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fcfit-casa.st1.corp.pvt%2FpaynowtoolTest2%2FDefaultLanding.aspx&sforg=national",
    LOCATIONURL_PPT: "&locked=false&admin=false&api=https%3A%2F%2Fucc-ppt.dexmedia.com&payNow=https%3A%2F%2Fppt-casa.st1.corp.pvt%2FpaynowtoolTest%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftbacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fppt-casa.st1.corp.pvt%2FpaynowtoolTest%2FDefaultLanding.aspx&sforg=national",
    LOCATIONURL_PROD: "&locked=false&admin=false&api=https%3A%2F%2Fucc.dexmedia.com&payNow=https%3A%2F%2Fcasa.dexmedia.com%2FPayNowTool%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fprdacrmw.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fcasa.dexmedia.com%2FPayNowTool%2FDefaultLanding.aspx&sforg=national",
    LOCATIONURL_TRAIN: "&locked=false&admin=false&api=https%3A%2F%2Fucc-train.dexmedia.com&payNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FPayNowToolTest2%2FDefaultLanding.aspx&sfaUrl=https%3A%2F%2Ftrnacrmwest.dexmedia.com&sideMenuPayNow=https%3A%2F%2Fdfw2w2scasa4.corp.pvt%2FPayNowToolTest%2FDefaultLanding.aspx&sforg=national",
    //BASICAUTH : 'Basic ' + btoa("caccd" + ':' + "ExE@D915!"),
    UserName: "caccd",
    Password: "ExE@D915!"

}
export let field_billingBasisBRM="BillingBasisBRM";
export let field_CoOPNotes = "CoOPNotes";
export let billingBasisObj =[
    {"value":"M","name":"Monthly"},
    {"value":"F","name":"Flexible"},
    {"value":"I","name":"Issue"},
    {"value":"T","name":"3 Monthly"},
    {"value":"H","name":"6 Monthly"},
    {"value":"S","name":"Semi-Annual"},
    {"value":"Q","name":"Quarterly"},
    {"value":"E","name":"8 Monthly"}    
]
export const BILLINGBASISOBJPRINT =[
    {"value":"M","name":"Monthly"},
    {"value":"I","name":"Issue"}    
]
export let omitAddressObj =[
    {"value":"3","name":"Print Address DNA Community"},
    {"value":"0","name":"Not Omitted Address"},
    {"value":"2","name":"Omit Address Print Community"},
    {"value":"1","name":"Omit Address and Community"},
    {"value":"5","name":"Print Address Omit Community"},
    {"value":"4","name":"Omit Address DNA Community"},
]
export let coOPTypeObj =[
    {"value":"No","name":"No"},
    {"value":"Yes","name":"Yes"}
]
export let callTypeDigitalObj = [
    {"value":"Local Number","name":"Local Number"},
    {"value":"Toll-Free Number","name":"Toll-Free Number"}

]
export let callTypePrintObj = [
    {"value":"Local Number","name":"Local Number"},
    {"value":"Toll-Free Number","name":"Toll-Free Number"},
    {"value":"Market C Number","name":"Market C Number"}
]
export let BESTTIMEOBJ =[
    {value: "Evening", name: "Evening", label: "Evening"},
    {value: "Afternoon", name: "Afternoon", label: "Afternoon"},   
    {value: "Morning", name: "Morning", label: "Morning"}
]
export let msgSpecialities = 'Please enter specialties separated by a semicolon Ex. specialty1;specialty2;specialty3. Maximum of 10 Specialities';
export enum OrderBPConstant {
    SCHEMANATIONAL= 'Retrieve Data Mapping Information',
    ACCOUNT= 'Retrieve Account Information',
    ORDER= 'Retrieve Order Details',
    USER= 'Retrieve User Profile',
    CONFIGURATION= 'Retrieve Configuration Data',
    PROFILE= 'Retrieve Business Profile',
    PRODUCT= 'Retrieve Order Items',
    HEADING= 'Validate Items'
}

export enum ManageBPConstant {
    SCHEMANATIONAL= 'Retrieve Data Mapping Information',
    ACCOUNT= 'Retrieve Account Information',
    USER= 'Retrieve User Profile',
    CONFIGURATION= 'Retrieve Configuration Data',
    PROFILE= 'Retrieve Business Profile',
}

//dpm-Business hours component 
export let  HOURS:any = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59"];
export let HOURSOPTIONS_PRINTOLN :any =["Open 24/7", "Open Mon-Fri 9AM-5PM","By Appointment Only", "Specify Business Hours"];
export let HOURSOPTIONS :any =["Open 24/7", "Open Mon-Fri 9AM-5PM", "Specify Business Hours"];

export enum CustomTosterMessage {
    schemanationalprofile ='Schema Service Failed (MongoDB, Nginx)',
    account	='Account Service Failed (Salesforce)',
    order ='Order Service Failed (Salesforce)',
    user = 'User Service Failed (Salesforce)',
    configuration = 'Configuration Service Failed (MongoDB)',
    salesforce = 'Email Query Failed (Salesforce)',
    storageorder = 'Order Storage Service Failed  (MongoDB)',
    billing	 ='Billing Service Failed (ESB)',
    profiledigital  = 'Profile Service Failed (ESB)',
    profileprint = 'Profile Service Failed (ESB)',
    group ='Group Service Failed (ESB)',
    productcontent = 'Product Service Failed (ESB)',
    schemanationalproduct = 'Schema Service Failed (MongoDB)',
    storageitem	= 'Item Storage Service Failed  (MongoDB)',
    storagecopyz = 'Copysheet Storage Service Failed  (MongoDB)',
    copy = 'Copysheet Service Failed  (ESB)',
    call = 'Call Service Failed  (ESB)',
    heading	= 'Heading Service Failed  (ESB)',
    storagecall	= 'Call Storage Service Failed  (MongoDB)',
    storageaccount ='Account Storage Service Failed  (MongoDB)',
   // profiledigital	= 'Digital Listing Service Failed (ESB)',
    storageprofileHelper = 'Profile Storage Service Failed  (MongoDB)'
    
}

// export let clientIdAndIssuer = {
//     clientId_Prod : '0oa1l7mo2lbWF3RjV1d8',
//     issuer_Prod : 'https://dexmedia.okta.com/oauth2/aus1l7mqiaaUpB5za1d8',
//     clientId_Test : '0oayhfxjhlCbvHGQH0h7',
//     issuer_Test : 'https://dexmedia.oktapreview.com/oauth2/ausyi2np1d38MHtcL0h7'
// }
