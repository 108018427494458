import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UtilsService } from 'app/core/service/utils/utils.service';
import { ValidateService } from 'app/core/service/validate/validate.service';
import { CampaignQuery } from 'app/state/campaignquery';
import { CampaignClass } from 'app/interface/campaign';
import { LoggerService } from '@app/service/logger/logger.service';
import { CampaignStore } from 'app/state/campaignstore';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-national-url',
  templateUrl: './national-url.component.html',
  styleUrls: ['./national-url.component.scss']
})
export class NationalUrlComponent implements OnInit {

  NationalDestinationURL: string = '';
  NationalDisplayURL: string = '';
  invalidDestination: boolean = false;
  msgFlagDestination: boolean = false;
  msgDestination: string = '';
  campaignModel: CampaignClass;
  msgEnum = {
    contentSizeWarning: "content size is less than the threshhold of",
    pinkContent: "PINK content",
    failedMoved: "failed: moved permanently",
    moved: "moved permanently"
  }
  Indicator: any;
  readonly: boolean = false;

  constructor(private utils: UtilsService,
    private Validate: ValidateService,
    private campaignQuery: CampaignQuery,
    private Logger: LoggerService,
    private campaignStore: CampaignStore,
    private cdr: ChangeDetectorRef,
    private indicatorQuery: IndicatorQuery) { }

  ngOnInit() {
    this.campaignQuery.getCampaign().subscribe(data => {
      this.campaignModel = { ...data}
    })
    if ((this.campaignModel.model.nationalCampaign === undefined) || (this.campaignModel.model.nationalCampaign && !this.campaignModel.model.nationalCampaign.NationalDestinationURL && !this.campaignModel.model.nationalCampaign.NationalDisplayURL)) {
      this.campaignModel.model.nationalCampaign = {
        NationalDisplayURL: '',
        NationalDestinationURL: ''
      };
    } else {
      this.NationalDestinationURL = this.utils.validateURL(this.campaignModel.model.nationalCampaign.NationalDestinationURL, false) ? this.campaignModel.model.nationalCampaign.NationalDestinationURL : "";
      this.NationalDisplayURL = this.campaignModel.model.nationalCampaign.NationalDisplayURL;
    }
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
      for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
        if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignMain') {
          this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
          break;
        }
      }
    })
  }

  changeDestination(NationalDestinationURL) {
    this.invalidDestination = true;
    this.msgFlagDestination = false;
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    if (NationalDestinationURL) {
      if (this.utils.validateURL(NationalDestinationURL, false)) {
        this.NationalDestinationURL = NationalDestinationURL;
        this.invalidDestination = false;
        // return;
      }
    }
    modelData.nationalCampaign.NationalDestinationURL = NationalDestinationURL
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[NationalUrlComponent] changeDestination'
      }
    })
  }

  changeDisplay(DisplayURL) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    if (DisplayURL) {
      modelData.nationalCampaign.NationalDisplayURL = DisplayURL
    } else {
      modelData.nationalCampaign.NationalDisplayURL = ''
    }
    let x = JSON.parse(JSON.stringify(this.campaignModel))
    x.model = modelData
    this.campaignStore.update(state => {
      return {
        campaign: x,
        updatedFrom: '[NationalUrlComponent] changeDisplay'
      }
    })
  }

  callValidateURL(url) {
    let modelData = JSON.parse(JSON.stringify(this.campaignQuery.getValue().campaign.model))
    if (this.utils.validateURL(url, false)) {
      this.msgFlagDestination = true;
      this.msgDestination = 'URL Validation in Progress';
      this.Logger.timedLogWarning(this.msgDestination);
      this.Validate.validateurl(url).subscribe(data => {
        this.Validate.setResponse(data.hasWarningorError);

        if(data.hasWarningorError === 'false'){
        if (data.status == "OK") {
          this.Logger.logSuccess('URL Validation Successfull.');
          this.msgDestination = '';
          this.msgFlagDestination = false;
        }
        else if (((data.status == "FAIL" || data.status == "FAILED") && (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.contentSizeWarning.toLowerCase()) > -1)) || (data.status == "PINK" && (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.pinkContent.toLowerCase()) > -1) || (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.failedMoved.toLowerCase()) > -1) || (data.msg && data.msg.toLowerCase().indexOf(this.msgEnum.moved.toLowerCase()) > -1))) {
          this.msgFlagDestination = false;
          this.msgDestination = "";
          this.NationalDestinationURL = "";
          modelData.nationalCampaign.NationalDestinationURL = ""
          let overrideButton = '<input type = "button" id="overRideBtn" class="btn btn-primary Override" style="float:right" value="Approve URL"></input>';
          let cancelButton = '<br><br><input type = "button" id = "btnCancel" class="btn btn-primary" value="Cancel"></input>';
          this.Logger.log("<div class ='urlGrid'>Step 1" + "<ol type ='A' style='padding-left: 45px;'>" + "<li>" + "  Verify the destination URL is accurately entered." + "</li>"
            + "<li>" + "  Also verify that the URL is not redirected to a different URL. If true, the Destination URL should be entered." + "</li>" + "</ol>" + "If either of the above in Step 1 is inaccurate, click &#34;Cancel&#34; to update the Destination URL." + "<br><br>"
            + "Step 2" + "<br>" + "Verify that the Destination URL:</strong><br>" + "<ol type = 'A' style='padding-left: 50px;'>" + "<li>" + "  Launches Successfully." + "</li>" + "<li>" + "  Has enough content.</strong>" + "</li>" + "</ol>"
            + 'If these conditions are met, and the URL is accurate, click &#34;Approve URL&#34; to force acceptance of the URL.'
            + cancelButton + overrideButton) + '</div>';
        }

        else {
          this.NationalDestinationURL = "";
          this.msgFlagDestination = true;
          modelData.nationalCampaign.NationalDestinationURL = "";
          this.msgDestination = 'Please enter a valid URL';
          this.Logger.logError("<br>" + "1. Entered URL format is incorrect." + "<br>" +
            " 2. Website server is down." + "<br>" + "3. Website content is not safe." + "<br>" + "<br>"
            + "Please validate and try again.");
        }
      }
        else {
          this.msgFlagDestination = false;
          this.msgDestination = "";
          this.NationalDestinationURL = "";
          modelData.nationalCampaign.NationalDestinationURL = ""
          let errorObject = data.esbServiceFault;
          let errorCode = errorObject.sourceSystem;
          let errorDesc = errorObject.exceptionDetail[0].errorDescription;
          let errorMsg = "<strong>Transaction Id : " + data.transactionId + "</strong><br><strong>Source System : " + errorCode + "</strong><br>" + errorDesc;
          this.Logger.logError(errorMsg);
        }
        if (!(<ViewRef>this.cdr).destroyed){
        this.cdr.detectChanges()}
        let x = JSON.parse(JSON.stringify(this.campaignModel))
        x.model = modelData
        this.campaignStore.update(state => {
          return {
            campaign: x,
            updatedFrom: '[NationalUrlComponent] callValidateURL'
          }
        })
      });
    }
  }

}
