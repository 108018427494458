import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Quickbpclass } from 'app/interface/quickbp';
import { DatasharingService } from '@app/service/datasharing/datasharing.service';
import { CatKeysService } from '@app/service/CatKeys/CatKeys.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { ErrorhandlingService } from '@app/service/errorhandling/errorhandling.service';
import { BusinessCategories } from '@app/service/businessCategories/businessCategories.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';


@Component({
  selector: 'app-multisearch',
  templateUrl: './multisearch.component.html',
  styleUrls: ['./multisearch.component.scss']
})

export class MultisearchComponent implements OnInit {

  open_dd: boolean = false;
  myPromise: Promise<[any]>;
  sharedData: Quickbpclass;
  fetched_categories: Promise<[any]>;
  selected_categories;
  primary_category = { categoryId: '', categoryName: '' };
  id: any;
  selectedCategoriesArray = [];
  hideSelected$: Subject<boolean>;
  options: any;
  constructor(private BusinessCategories: BusinessCategories,
    private CatKeys: CatKeysService, private Logger: LoggerService,
    private errorService: ErrorhandlingService,
    private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) {

  }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    const data = [];
    this.hideSelected$ = new BehaviorSubject(!!data.length);
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    this.id = this['layoutNode'].name;
    if (this.sharedData['profiles'][this.sharedData.stateParamsId].model.hasOwnProperty('BusinessCategories')) {
      this.selected_categories = this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessCategories
      if (this.selected_categories && this.selected_categories != undefined && this.selected_categories != null && this.selected_categories.length > 0) {
        // check for primary category
        for (let i = 0; i < this.selected_categories.length; i++) {
          if (this.selected_categories[i].primary == 'Yes')
            this.primary_category = this.selected_categories[i].categoryName;
        }
        // When no primary categories are found make the first category as primary
        if (this.primary_category && this.primary_category.categoryId != null && this.primary_category.categoryId === '') {
          this.selected_categories[0].primary = 'Yes'
          this.primary_category = this.selected_categories[0].categoryName;
        }
        this.onSelectSelectedCategories();
      }
    }
  }

  callService(searchWord) {

    return new Promise<[any]>((resolve, reject) => {
      let categories;
      this.BusinessCategories.getCategories(searchWord)
        .subscribe(data => {
          categories = data;
          if (categories.length > 0) {
            for (let category of categories)
              category['primary'] = 'No'
          }
          resolve(categories);
        },
          err => {
            this.Logger.logError(this.errorService.handleApiError(err))
            reject(err);
          },
          () => {
            resolve(categories)
          });
    });

  }

  getCategories(searchword) {
    let categories;
    this.open_dd = true;

    if (searchword.term.length > 0) {
      this.fetched_categories = this.callService(searchword.term)
      this.fetched_categories.then((data) => {
        this.hideSelected$.next(!!data.length);
      }, (err) => {
      })
    } else {
      this.fetched_categories = null;
    }
  }

  getCatKeys(categoryName, categoryId) {
    let catKeys;
    this.CatKeys.getCatKeys(categoryName)
      .subscribe(data => {
        let catKeyExists;
        let catKeyTypes = ['Affiliations', 'Brands', 'Certifications', 'Products', 'Services', 'Specialities']
        catKeys = data;
        // Remove the catkeys that are already present in shared data from the response
        if (catKeys && catKeys != null && !(catKeys === undefined)) {
          for (let j = 0; j < catKeys.length; j++) {
            if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeys[j].type] && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeys[j].type] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeys[j].type] != null) {
              for (let i = 0; i < this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeys[j].type].length; i++) {
                if (catKeys[j].id == this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeys[j].type][i].catkeyId && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeys[j].type][i].categoryId == categoryId) {
                  catKeys.splice(j, 1)
                  j = j - 1;//Need to reset the loop counter for splicing
                  break;
                }
              }
            }
          }
        }
        // Add the new categories to shared data
        if (catKeys && catKeys != null && !(catKeys === undefined)) {
          for (let catkey of catKeys) {
            let newCatKey = {};
            newCatKey['category'] = categoryName
            newCatKey['categoryId'] = categoryId
            newCatKey['catkeyId'] = catkey.id
            newCatKey['selected'] = 'N'
            newCatKey['_content_'] = catkey.name
            if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkey.type] && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkey.type] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkey.type] != null) {
              // if(this.sharedData['profiles'][this.sharedData.stateParamsId].model['CustomCatKeys']) {
              //   let custCatkey = this.sharedData['profiles'][this.sharedData.stateParamsId].model['CustomCatKeys'];
              //   if(custCatkey) {
              //     for (const x in custCatkey) {
              //       if(catkey.type == x) {
              //         for (let j = 0; j < custCatkey[x].length; j++) {
              //           if(custCatkey[x][j]['text'] == catkey.name) {
              //             newCatKey['selected'] = 'Y'
              //           }
              //         }
              //       }
              //     }
              //   }
                this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkey.type].push(newCatKey)
              // }
            } else {
              let catKeyArray = [];
              catKeyArray.push(newCatKey);
              this.sharedData['profiles'][this.sharedData.stateParamsId].model[catkey.type] = catKeyArray;
            }
          }
        }
      }, err => {
        this.Logger.logError(this.errorService.handleApiError(err));
      });
  }

  refreshCatKeys() {
    let catKeyTypes = ['Affiliations', 'Brands', 'Certifications', 'Products', 'Services', 'Specialities']
    let category_exists

    //Remove the catkeys of deleted categories from sharedData
    for (let catKeyType of catKeyTypes) {
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType] && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType] != null) {
        for (let i = 0; i < this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType].length; i++) {
          category_exists = false
          for (let category of this.selected_categories) {
            if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType][i].categoryId == category.categoryId) {
              category_exists = true;
              break;
            }
          }
          if (!category_exists) {
            this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType].splice(i, 1);
            i = i - 1//Need to reset the loop counter for splicing
          }
        }
      }
    }
    //Fetch and add new catkeys to shared data
    for (let category of this.selected_categories) {
      this.getCatKeys(category.categoryName, category.categoryId);
    }
  }

  onSelectSelectedCategories() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    let primary_exists;
    let catKeyTypes = ['Affiliations', 'Brands', 'Certifications', 'Products', 'Services', 'Specialities']
    this.selected_categories= JSON.parse(JSON.stringify( this.selected_categories));
    if (this.selected_categories && this.selected_categories != null && !(this.selected_categories == undefined) && this.selected_categories.length > 0) {
      // If primary category is deleted then make first category as primary
      primary_exists = false;
      for (let category of this.selected_categories) {
        if (category.primary == 'Yes')
          primary_exists = true
      }
      if (!primary_exists) {
       this.selected_categories[0].primary = 'Yes'
        this.primary_category = this.selected_categories[0].categoryName;
      }
      // Refresh catKeys
      this.refreshCatKeys()
    } else {
      // When all categories are cleared , clear primary category
      this.primary_category = { categoryId: '', categoryName: '' };
      // Clear the cat keys
      for (let catKeyType of catKeyTypes) {
        if (this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType] && !(this.sharedData[catKeyType] === undefined) && this.sharedData[catKeyType] != null) {
          this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType].splice(0, this.sharedData['profiles'][this.sharedData.stateParamsId].model[catKeyType].length)
        }
      }
      //Clear earlier fetched categories
      this.fetched_categories = null
    }
    // let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    // modelData["BusinessCategories"] =  this.selected_categories;
    // let x = JSON.parse(JSON.stringify(this.sharedData));
    // x['profiles'][this.sharedData.stateParamsId].model = modelData;

    this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessCategories = this.selected_categories;
    // set the behavior view
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[MultisearchComponent] onSelectSelectedCategories",
      }
    })
  }

  onSelectPrimaryCategories(catName) {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    // Modify the business categories in shared data
    this.selected_categories= JSON.parse(JSON.stringify( this.selected_categories));
    if (this.selected_categories && this.selected_categories != null && !(this.selected_categories === undefined)) {
      for (let i = 0; i < this.selected_categories.length; i++) {
        if (this.selected_categories[i].categoryName == catName)
          this.selected_categories[i].primary = 'Yes'
        else
          this.selected_categories[i].primary = 'No'
      }
    }
    // let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    // modelData["BusinessCategories"] = this.selected_categories;
    // let x = JSON.parse(JSON.stringify(this.sharedData));
    // x['profiles'][this.sharedData.stateParamsId].model = modelData;

    this.sharedData['profiles'][this.sharedData.stateParamsId].model.BusinessCategories = this.selected_categories;
    // Delete the existing primary category and add new primary category in shared data
    /*
    if (this.sharedData.PrimaryCategories && this.sharedData.PrimaryCategories != null && !(this.sharedData.PrimaryCategories === undefined)) {
      for (let i = 0; i < this.sharedData.PrimaryCategories.length; i++) {
        this.sharedData.PrimaryCategories.splice(i, 1);
        i = i - 1;
      }
    }
    this.sharedData.PrimaryCategories.push(this.primary_category)
    */
    // set the behavior view
    this.indicatorStore.update(state =>{
      return{
        indicator: this.sharedData,
        updatedFrom: "[MultisearchComponent] onSelectPrimaryCategories",
      }
    })
  }
}
