import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ParamsService} from "../params/params.service";
import { ApiService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileschemaService {

  baseUri:string = '/api/schemanational/profile/';

  headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient,private Params : ParamsService,private API: ApiService) { }

  get(profile):Promise<any> {
    let url = this.baseUri;
    return this.http.get(this.API.get(url)+profile).toPromise();
  }

}
