import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class statesService {
  private PATH: string = '/api/state';

  constructor(private API: ApiService, private http: HttpClient, private Params: ParamsService) { }
  headers = new HttpHeaders()

  /**
   * Retrieves states based on the country code.
   * @param countryCode The country code for which states are to be retrieved.
   * @returns A promise that resolves to an array of states.
   */

  getStates(country_code): Promise<any> {
    return this.http.get(this.API.get(this.PATH) + "/" + country_code).toPromise();;
  }

}
