import { Component, OnInit ,Input} from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
//import data from './itemDummyData.json';
import {productCode,productType,productTypePrint,constant} from '../../../data/constants';

@Component({
  selector: 'app-print-item',
  templateUrl: './print-item.component.html',
  styleUrls: ['./print-item.component.scss']
})
export class PrintItemComponent implements OnInit {
  indicator :any;;
  @Input('productType') productType: string;
  model;
  constructor( private indicatorQuery :IndicatorQuery,
    private indicatorStore :IndicatorStore) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(async (res) => {
      this.indicator = JSON.parse(JSON.stringify(res));
      });
    //US28304 ListingID greyedout
    for (let j = 0; j < this.indicator.items[this.indicator.stateParamsId].form.length; j++) {
      if(this.indicator.items[this.indicator.stateParamsId].order.productType === productTypePrint && this.indicator.items[this.indicator.stateParamsId].order.productCode === "Free Listing"){
       this.indicator.items[this.indicator.stateParamsId].schema.properties.ListingId.readonly = true;
      }
      if(this.indicator.items[this.indicator.stateParamsId].order.productType === productTypePrint && this.indicator.account.billingAccountSystem == constant.BRM_BILLING_DETAILS && this.indicator.items[this.indicator.stateParamsId].form[j].key == "BillingBasisBRM" && this.indicator.items[this.indicator.stateParamsId].form[j-1].key == "BillingBasis"){
       this.indicator.items[this.indicator.stateParamsId].form[j-1].condition =  "false";        
      }else if(this.indicator.items[this.indicator.stateParamsId].order.productType === productTypePrint && this.indicator.account.billingAccountSystem != constant.BRM_BILLING_DETAILS  && this.indicator.items[this.indicator.stateParamsId].form[j].key == "BillingBasis" && this.indicator.items[this.indicator.stateParamsId].form[j+1].key == "BillingBasisBRM"){
       this.indicator.items[this.indicator.stateParamsId].form[j+1].condition =  "false"; 
      }
   }//US28304 end 
  }

}
