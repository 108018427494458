import { OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ParamsModel } from './params.model';
import { Location } from "@angular/common";
import { IndicatorQuery } from '../../../state/query';
import { IndicatorStore } from '../../../state/store'
@Injectable()
export class ParamsService implements OnInit {
    public params: ParamsModel = new ParamsModel();
    indicatorData;
    constructor(private cookieService: CookieService) {
    }
    ngOnInit() {
        this.getParamsData(this.params,false);
    }
    getParamsData(paramsObj,cctdev) {
        this.params.values = paramsObj;
        this.params.sourcetype = "SFDC";
        const refresh = this.params.values.token ? false : true; //whether screen is refreshed during development mode
        this.params.admin = (this.params.values.admin && this.params.values.admin != null) ? this.params.values.admin : sessionStorage.getItem("admin");
        this.params.account = (this.params.values.account && this.params.values.account != null) ? this.params.values.account : sessionStorage.getItem("account");
        this.params.order = (this.params.values.order && this.params.values.order != null) ? this.params.values.order : null; //Order in BP mode is not to be showedlistingBP
        this.params.mode = (this.params.values.mode && this.params.values.mode != null) ? this.params.values.mode : sessionStorage.getItem("mode");
        this.params.eaId = (this.params.values.eaId && this.params.values.eaId != null) ? this.params.values.eaId : null;
        this.params.listingId = (this.params.values.listingid != null) ? this.params.values.listingid : null;
        this.params.api = unescape((this.params.values.api && this.params.values.api != null) ? this.params.values.api : sessionStorage.getItem("mode"));
        this.params.locked = (this.params.values.locked != null) ? this.params.values.locked : false;
        this.params.payNow = unescape((this.params.values.payNow && this.params.values.payNow != null) ? this.params.values.payNow : sessionStorage.getItem("payNow"));
        this.params.sfaUrl = unescape((this.params.values.sfaUrl && this.params.values.sfaUrl != null) ? this.params.values.sfaUrl : sessionStorage.getItem("sfaUrl"));
        this.params.sideMenuPayNow = unescape((this.params.values.sideMenuPayNow != null) ? this.params.values.sideMenuPayNow : sessionStorage.getItem("sideMenuPayNow"));
        this.params.saving = false;
        this.params.loadingbar = false;
        this.params.warning = false;
        this.params.isCopyBP = (this.params.values.isCopyBP != null) ? this.params.values.isCopyBP : 'false';
        this.params.isLateAd = false;
        this.params.sforg = "national";
        if (this.params.mode && this.params.mode == "QuickBP") {
            this.params.quickBP = this.params.mode;
        } else {
            this.params.listingBP = this.params.mode;
        }
        // need to implement - Vijay
        /* if (this.params.account && this.params.order) {
            this.params.mode = "order";
        }else if (this.params.quickBP) {
            this.params.mode = "quickbp";
        }else if (this.params.listingBP) {
            this.params.mode = "listingbp";
        }else if (this.params.account && !this.params.order) {
            this.params.mode = "bp";
        } */
        if (this.params.mode == "listing") {
            this.params.account = null;
            this.params.order = null;
            this.params.eaId = null;
        }

        type sameProp = "None" | "Lax" | "Strict"
        let secureFlag = true;
        let samesiteProperty = "None";
        if (location.origin.indexOf("localhost") > 0) {
            if(!cctdev){
             secureFlag = false;
            samesiteProperty = "Lax";
            }         
        }

        let samesitePropertyFinal: sameProp = samesiteProperty as sameProp;
        sessionStorage.setItem("api", this.params.api);
        sessionStorage.setItem("eaId", this.params.eaId);
        sessionStorage.setItem("sforg", "national");
        sessionStorage.setItem("admin", this.params.admin);
        sessionStorage.setItem("api", this.params.api);
        sessionStorage.setItem("account", this.params.account);
        sessionStorage.setItem("order", this.params.order);
        sessionStorage.setItem("payNow", this.params.payNow);
        sessionStorage.setItem("sideMenuPayNow", this.params.sideMenuPayNow);
        sessionStorage.setItem("sfaUrl", this.params.sfaUrl);
        sessionStorage.setItem("mode", this.params.mode);
        sessionStorage.setItem("listingId", this.params.listingId);
        sessionStorage.setItem("api", this.params.api);
        sessionStorage.setItem("locked", this.params.locked);
        // this.cookieService.set("api", this.params.api, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("eaId", this.params.eaId, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("token", this.params.token, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("sforg", "national", null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("admin", this.params.admin, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("account", this.params.account, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("order", this.params.order, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("payNow", this.params.values.payNow, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("sideMenuPayNow", this.params.values.sideMenuPayNow, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("sfaUrl", this.params.sfaUrl, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("mode", this.params.mode, null, '/', null, secureFlag, samesitePropertyFinal);
        // this.cookieService.set("listingId", this.params.listingId, null, '/', null, secureFlag, samesitePropertyFinal);
        return this.params;

    }
}
