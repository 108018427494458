import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { FileService } from '@app/service/file/file.service';
import { Photos } from 'app/interface/photos';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { ApiService } from '@app/service/api/api.service';
import { PDFDocument } from 'pdf-lib';
import { LoggerService } from '@app/service/logger/logger.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  uploader: FileUploader;
  showErrMsg: boolean;
  showSizeErrMsg: boolean;
  sharedCopySheetObj: Photos = new Photos;
  files: any = [];
  fileId: string;
  errorMsg: string;
  subscription: any;
  attachments: any = [];
  hasDropZoneOver: boolean;
  @Output() fileAdditionRemoval = new EventEmitter<boolean>();


  constructor(private File: FileService,
    private photoService: PhotoService,
    private API: ApiService,
    private Logger: LoggerService) {
    this.hasDropZoneOver = false;

    this.uploader = File.uploader;
    this.uploader.onAfterAddingFile = async (file) => {
      let fileFileName = file.file.name;
      // Replace spaces, % or # in filename with underscore
      fileFileName = fileFileName.replace(/[ #%]/g, "_");
      file.file['name'] = fileFileName;
      if (fileFileName && fileFileName.length > 10) {
        let firstPart = fileFileName.slice(0, 4);
        let secondPart = fileFileName.slice(fileFileName.length - 5, fileFileName.length);
        file.file['name1'] = firstPart + '...' + secondPart;
      } else {
        file.file['name1'] = file.file.name;
      }
      if (!file.file.type.toLowerCase().match("/(pdf|jpeg|jpg|png|doc|docx|xls|xlsx|vnd.openxmlformats-officedocument.wordprocessingml.document|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet)")) {
        this.showErrMsg = true;
        if (file.file.size < 5 * 1024 * 1024) { this.showSizeErrMsg = false; }
        this.uploader.removeFromQueue(file);
      } else {
        this.showErrMsg = false;
        if (file.file.size > 5 * 1024 * 1024) {
          this.showSizeErrMsg = true;
          this.uploader.removeFromQueue(file);
        } else {
          this.showSizeErrMsg = false;
        }
        if (file.file.type.toLowerCase().match("/(jpeg|jpg|png)")) {
          // for image validation
          this.validateImage(file.file.rawFile, file);
        } else if (file.file.type.toLowerCase().match("/(pdf)")) {
          // for pdf validation
          await this.validatePdf(new Blob([file.file.rawFile], { type: 'application/pdf' }), file);
        }
      }
      this.sharedCopySheetObj.uploaderObj = this.uploader;
      this.photoService.setBehaviorView(this.sharedCopySheetObj);
    };

    this.uploader.onCompleteItem = (fileItem, response: any, status, headers) => {
      if (response) {
        response = JSON.parse(response);
        let filePath = this.getFilePath(response);
        this.files.push(filePath);
        if (this.attachments != null && this.attachments != undefined) {
          this.attachments.push(filePath)
          this.sharedCopySheetObj.attachmentsObj = this.attachments
          this.photoService.setBehaviorView(this.sharedCopySheetObj);
        }
      } else {

      }
      this.enableSave();
    }
  }

  ngOnInit() {
    this.subscription = this.photoService.getBehaviorView().subscribe(res => {
      this.sharedCopySheetObj = res;
    })
    if (this.uploader)
      this.uploader.clearQueue();
  }

  getFilePath(file) {
    return this.API.get("/api/file/") + file._id + "/" + file.filename;
  }

  remove(file) {
    for (var i = 0; i < this.attachments.length; i++) {
      if (file.file.name === this.attachments[0].urlName) {
        var index = this.attachments.indexOf(this.attachments[i])
        if (index > -1)
          this.attachments.splice(index, 1);
        this.sharedCopySheetObj.fileItemObj.splice(index, 1);
      }
    }
    this.sharedCopySheetObj.uploadLogoArrObj = this.attachments
    this.photoService.setBehaviorView(this.sharedCopySheetObj);
    if (this.uploader.queue.length === 0)
      this.uploader.progress = 0;
    this.enableSave();
  }

  removeAll() {
    this.attachments.splice(0, this.attachments.length);
    this.sharedCopySheetObj.fileItemObj.splice(0, this.sharedCopySheetObj.fileItemObj.length);
    this.uploader.progress = 0;
    this.enableSave();
  }

  onChange(event: any): void {
    event.srcElement.value = '';
  }

  addDescription(file, data) {
    file.showErrDesc = false
    if (data.value.length <= 140) {
      this.File.get().subscribe(files => {
        if (files["length"] > 0) {
          for (var i = 0; i < files["length"]; i++) {
            if (files[i]._id === this.fileId) {
              var manageUploadData = files[i]
              manageUploadData.metadata.description = data.value;
              this.File.put(manageUploadData._id, manageUploadData).subscribe(() => {

              });
              if (this.attachments && this.attachments.length > 0) {
                for (var j = 0; j < this.attachments.length; j++) {
                  if (this.attachments[j].id == manageUploadData._id) {
                    this.attachments[j].orgObj = manageUploadData;
                  }
                }
              }
              break;
            }
          }
        }
      });
    } else {
      file.showErrDesc = true;
      this.errorMsg = "Description length exceeded 140 character- Please enter description within 140 character";
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  photoOverOnDropZone(e: any): void {
    this.hasDropZoneOver = e;
  }
  enableSave() {
    let disableValue = true;
    for (let item of this.uploader.queue) {
      if (item.isSuccess == true) {
        disableValue = false;
        break;
      }
    }
    this.fileAdditionRemoval.emit(disableValue);
  }

  // Function for validating image files
  validateImage(file, fileItem) {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onerror = () => {
      const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
      this.Logger.logFormattedError(errorMsg);
      fileItem.remove();
      this.uploader.progress = 0;
      this.enableSave();
    };
    img.onload = () => {
      // The image is valid
    };
  }

  // Function for validating PDF files
  async validatePdf(file, fileItem) {
    let reader = new FileReader();
    reader.onload = async (event: any) => {
      try {

        // const pdfDoc = await PDFDocument.load(event.target.result);
        const pdfDoc = await PDFDocument.load(event.target.result, { ignoreEncryption: true });

        // If the PDFDocument loads successfully, the PDF is valid.
      } catch (error) {

        // If the PDFDocument fails to load, the PDF is invalid.
        const errorMsg = "The attached file got corrupted while uploading or saving for one of the following reasons. Please upload a valid PDF/Image file.\n\n- Document format must be a png, pdf, jpg or gif type of file.\n- Loss of internet connection\n- Device had a power failure\n- Device affected by Virus-infections\n- Hard disk drive failure";
        this.Logger.logFormattedError(errorMsg);
        fileItem.remove();
        this.uploader.progress = 0;
        this.enableSave();
      }
    };
    reader.readAsArrayBuffer(file);
  }


}
