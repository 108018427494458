import { Component, OnInit, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { LoggerService } from '@app/service/logger/logger.service';
import { LogoService } from '@app/service/logo/logo.service';
import { ParamsService } from '@app/service/params/params.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Photos } from 'app/interface/photos';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@app/service/datasource/constants';
import { SharedService } from '@app/service/shared/shared.service';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';


@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  sharedData: any;
  photoSubscription: any;
  closeResult: string;
  spcuid: string = '';
  listingId: string = '';
  uploadlogoArray: any = [];
  deleteArrayFromIH = [];
  sharedLogoObj: Photos = new Photos
  fileItem: any = [];
  uploader: FileUploader;
  mode: string = ''
  isOrderBPMode: boolean = false;
  photoSpinner = false;
  options: any;
  disableSave: boolean = false;
  constructor(private modalService: NgbModal,

    private _logoService: LogoService,
    private photo: PhotoService,
    private Logger: LoggerService,
    private spinnerService: NgxSpinnerService,
    private Params: ParamsService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private constants: Constants,
    private spinnerTextService: SpinnerService,
    private Shared: SharedService) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
      this.listingId = data.stateParamsId;
      this.spcuid = this.sharedData['user']["Id"].substring(0, 15);
    })
    if (this.listingId === '') this.isOrderBPMode = true;
    else this.isOrderBPMode = false;
    this.photoSubscription = this.photo.getBehaviorView().subscribe(result => {
      this.deleteArrayFromIH = result.deleteArrFromIHObj
      this.uploadlogoArray = result.uploadLogoArrObj
      this.fileItem = result.fileItemObj
      this.uploader = result.uploaderObj;
    })
  }

  onTabChange(event) {

    switch (event.nextId) {
      case "photos": {
        this.disableSave = false;
        break;
      }
      case "library": {
        this.disableSave = false;
        break;
      }
      case "upload": {
        this.disableSave = true;
        break;
      }
    }

  }

  onAdditionRemoval(event) {
    this.disableSave = event;
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: "logo-modal", backdrop: "static" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit() {
    // this.sharedData.loadingText = "Saving photos...";
    this.Params.params.loadingbartext = "Saving photos...";
    this.Shared.spinnerText = this.constants.BP_UPLOAD_PHOTO;
    this.Params.params.loadingBP = "managePhoto";
    // this.indicatorStore.update(state => {
    //   return {
    //     indicator: this.sharedData
    //   }
    // })
    if (this.deleteArrayFromIH.length > 0) {
      for (var i = 0; i < this.deleteArrayFromIH.length; i++) {
        this.photoSpinner = true;
        this.spinnerTextService.setSpinnerText('');
        this.spinnerService.show();
        this.spinnerTextService.setSpinnerText(this.Shared.spinnerText);
        this._logoService.delete(this.deleteArrayFromIH[i], 'Y').subscribe(photoLists => {
          if (photoLists.message === "Deletion request sent") {
            this.Logger.logSuccess("Status Code: 200" + "</br>Message:  Congrats! Your Image is deleted now ");
            this.spinnerService.hide();
            this.photoSpinner = false;
          } else if (photoLists.message === "Deletion request failed") {
            this.Logger.logError("Status Code: 500" + "</br>Message: Your Image is not deleted")
            this.spinnerService.hide();
            this.photoSpinner = false;
          }
          this.deleteArrayFromIH.splice(0, this.deleteArrayFromIH.length);
        }, error => {
          this.spinnerService.hide();
        })
      }
    }

    for (var i = 0; i < this.uploadlogoArray.length; i++) {
      var imgdesc = ""
      var file1 = this.uploadlogoArray[i].url;
      var fileId = file1.substr(file1.substr(0, file1.lastIndexOf("/")).lastIndexOf("/") + 1, 24);
      if (this.uploadlogoArray[i] && this.uploadlogoArray[i].orgObj && this.uploadlogoArray[i].orgObj.metadata.description) {
        imgdesc = this.uploadlogoArray[i].orgObj.metadata.description;
      } else {
        imgdesc = "";
      }
      var data = {
        "listingid": this.listingId,
        "SPCUID": this.spcuid,
        "imgTyp": "PHOTO",
        "file1": file1,
        "fileId": fileId,
        "imgdesc": imgdesc
      };
      this.photoSpinner = true;
      this.spinnerTextService.setSpinnerText('');
      this.spinnerService.show();
      this.spinnerTextService.setSpinnerText(this.Shared.spinnerText);
      this._logoService.uploadLogo(this.listingId, data).subscribe(res => {
        this._logoService.getuploadStatus(res.varRand).subscribe(status => {
          var localStatus = status.response.status[0];

          if (res.uploadStatus == 200 && (localStatus === "PUB" || localStatus === "APP" || localStatus === "ICAREPUB")) {
            this.Logger.logSuccess("Infohub Status Code: " + status.response.status[0] + "</br> Infohub Message:  Image is Online </br> If this Business Profile has an ESS Plus or Premium, photos will be sent to Yelp in 2 weeks ");
            this.spinnerService.hide();
            this.photoSpinner = false;
          }
          else if (res.uploadStatus == 200) {
            this.Logger.logWarning("Infohub Status Code: " + status.response.status[0] + "</br>Infohub Message: " + status.response.message[0]);
            this.spinnerService.hide();
            this.photoSpinner = false;
          } else if (res.uploadStatus == 500) {
            this.Logger.logError("Status Code: 500" + "</br>Message: please try again ");
            this.spinnerService.hide();
            this.photoSpinner = false;
          }
          this.sharedLogoObj.uploadLogoArrObj = this.uploadlogoArray;
          this.uploadlogoArray.splice(0, this.uploadlogoArray.length);
        }, err => {
          this.spinnerService.hide();
          this.photoSpinner = false;
        });
      }, err => {
        this.spinnerService.hide();
        this.photoSpinner = false;
      });
    }
    for (let i = 0; i < this.fileItem.length; i++) {
      if (this.fileItem[i].isUploading)
        this.fileItem[i].remove();
    }
    if (this.uploader)
      this.uploader.clearQueue();
  }

  onCancel() {
    for (let i = 0; i < this.fileItem.length; i++) {
      if (this.fileItem[i].isUploading)
        this.fileItem[i].remove();
    }
    if (this.uploader)
      this.uploader.clearQueue();
    this.disableSave = false;
  }

  ngOnDestroy() {
    this.photoSubscription.unsubscribe();
    this.photoSpinner = false;
  }
}
