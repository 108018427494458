import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CounterService {
  private subject = new Subject<number[] | null>();
  private updateValue = new Subject<boolean>();
  private updateItem = new Subject<any>();
  private updatePercent = new BehaviorSubject<number>(0);
  private seoBusinessCategories = new Subject<string[] | null>();
  private buttonFlag = new Subject<boolean>();
  constructor() { }

  setError(counter: number[]) {
        this.subject.next(counter);
    }

    getError(): Observable<number[]> {
      return this.subject.asObservable();
    }

    setUpdate( isUpdate: boolean) {
      this.updateValue.next(isUpdate);
    }

    isUpdate(): Observable<boolean> {
      return this.updateValue.asObservable();
    }
    setItem( item: any) {
      this.updateItem.next(item);
    }

    getItem(): Observable<any> {
      return this.updateItem.asObservable();
    }

    setPercent( item: number) {
      this.updatePercent.next(item);
    }

    getPercent(): Observable<number> {
      return this.updatePercent.asObservable();
    }
    setSeoData( seoDatas: string[]) {​​​​
      this.seoBusinessCategories.next(seoDatas);
      }​​​​
      
      
      
      getSeoData(): Observable<string[]> {​​​​
      return this.seoBusinessCategories.asObservable();
      }​​​​

    setbuttonEnableFlag(flag: boolean) {
      this.buttonFlag.next(flag);
    }

    getbuttonEnableFlag(): Observable<boolean> {
      return this.buttonFlag.asObservable();
    }
}
