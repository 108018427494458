import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminuserService } from '@app/service/adminuser/adminuser.service';
import { Router } from '@angular/router';
import { FooterService } from '../footer/footer.service'
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-login',
  templateUrl: './dashboard-login.component.html',
  styleUrls: ['./dashboard-login.component.scss']
})
export class DashboardLoginComponent implements OnInit {
  error;
  constructor(private adminuser:AdminuserService,
    private router:Router, 
    private footerService: FooterService,
    private spinnerService : NgxSpinnerService) { }

  loginForm: FormGroup = null;
  username;
  password;
  userNamError:boolean = false;
  passwordError:boolean = false;
 
  ngOnInit() {
    this.footerService.changeTab('dashboardLogin');
    this.spinnerService.hide();
  }

  getLoginForm(){
    this.loginForm = new FormGroup({
      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required])
    })
  }

  // validateuser(){
  //   let userName = this.loginForm.getRawValue().userName;
  //   let password = this.loginForm.getRawValue().password;
  //   //let passwod = this.loginForm.controls.password.value;


  // }
  //this.footerService.validateuser(this.username, this.password);

  
  validateuser(){
    this.adminuser.get(this.username,this.password).subscribe((res)=>{
      if (this.username) {
        this.userNamError = true;
      }
      if (!this.password) {
        this.passwordError = true;
      }
        if(res.password){
          this.router.navigate(['/tools/admindashboard']);
        }else if(res.password && res.userId == "test@test.com"){
            this.router.navigate(['/tools/dashboardlogin']);
        }else{
            this.error = 'Invalid userName or Password'
        }
    },err=>{
      console.log('Error: ', err);
        this.error = 'Invalid userName or Password'
    })  
}
}
