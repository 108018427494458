import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ApiService } from '@app/service/api/api.service';
import { FileService } from '@app/service/file/file.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { FileUploader, FileItem } from 'ng2-file-upload';


@Component({
  selector: 'app-uploadlogo',
  templateUrl: './uploadlogo.component.html',
  styleUrls: ['./uploadlogo.component.scss']
})
export class UploadlogoComponent implements OnInit {

  uploader: FileUploader;
  showErrMsg: boolean;
  showSizeErrMsg: boolean;
  selectAd: boolean;
  selectCopySheet: boolean;
  configObj = {
    fileType: 'Logo',
    uploadLimit: '5',
    fileNameLength: '',
    uploadedFile: {}
  };
  count: number = 0;
  sharedLogoObj: Photos = new Photos
  @Output() fileAdditionRemoval = new EventEmitter<boolean>();
  @ViewChild('selectedFile', { static: false }) selectedFile: any;
  hasDropZoneOver:boolean;

  constructor(private API: ApiService,
    private File: FileService,
    private photoService: PhotoService) {

    this.hasDropZoneOver = false;

    this.uploader = this.File.uploader;
    this.uploader.onAfterAddingFile = (file) => {
      let fileFileName = file.file.name
      if(fileFileName && fileFileName.length > 10){
        let firstPart = fileFileName.slice(0, 4);
        let secondPart = fileFileName.slice(fileFileName.length - 5, fileFileName.length);
        file.file['name1'] = firstPart + '...' + secondPart;
      }else{
        file.file['name1'] = file.file.name;
      }
      if (!file.file.type.toLowerCase().match("/(jpg|png|jpeg)")) {
        this.showErrMsg = true;
        this.uploader.removeFromQueue(file);
      } else {
        this.showErrMsg = false;
      }

      if (file.file.size > 5 * 1024 * 1024) {
        this.showSizeErrMsg = true;
        this.uploader.removeFromQueue(file);
      } else {
        this.showSizeErrMsg = false;
      }
      if (!(this.showSizeErrMsg || this.showErrMsg)) {
        this.count = this.count + 1;
        if (this.count > 1) {
          this.uploader.removeFromQueue(file);
        }
      }
      this.sharedLogoObj.uploaderObj = this.uploader;
      this.photoService.setBehaviorView(this.sharedLogoObj);      
    };

    this.uploader.onCompleteItem = (fileItem, response: any, status, headers) => {
      if (response) {
        response = JSON.parse(response);
        if (status !== 200 || !response._id || !response.filename) {
          this.uploader.queue.splice(this.uploader.queue.length - 1, 1);
        } else {
          let filePath = this.getFilePath(response);
          this.configObj.uploadedFile[this.configObj.fileType] = filePath;
        }
        this.sharedLogoObj.configObj = this.configObj;
        this.sharedLogoObj.fileitemObj = fileItem;
        this.photoService.setBehaviorView(this.sharedLogoObj);
      }
      else {
        fileItem.remove();
        this.uploader.progress = 0;
      }
      this.enableSave();
    }
  }

  ngOnInit() {
    if (this.uploader)
      this.uploader.clearQueue();
  }

  enableSave() {
    let disableValue = true;
    for (let item of this.uploader.queue) {
      if (item.isSuccess == true) {
        disableValue = false;
        break;
      }
    }
    this.fileAdditionRemoval.emit(disableValue);
  }

  getFilePath(file) {
    return this.API.get("/api/file/") + file._id + "/" + file.filename;
  }

  remove(file) {
    if(this.selectedFile) this.selectedFile.nativeElement.value = '';
    else {}
    if (this.configObj.uploadedFile["Logo"]) {
      let index = this.configObj.uploadedFile["Logo"].lastIndexOf("/");
      let filename = this.configObj.uploadedFile["Logo"].slice(index+1);
      if (file.file.name === filename) {
        this.configObj.uploadedFile = {};
        this.uploader.progress = 0;
        //this.uploader.removeFromQueue(file);
        this.count = 0;
        this.sharedLogoObj.configObj = this.configObj;
        this.photoService.setBehaviorView(this.sharedLogoObj);
      }
    } else {
      this.configObj.uploadedFile = {};
      this.count = 0;
    }
    this.enableSave();
  }
  photoOverOnDropZone(e:any):void {
    this.hasDropZoneOver = e;
  }
}
