import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { CopysheetService } from '@app/service/copysheet/copysheet.service';
import { LoggerService } from "../../../core/service/logger/logger.service";
import { StorageService } from '@app/service/storage/storage.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { CounterService } from '@app/service/counter/counter.service';

@Component({
  selector: 'app-artistinstruction',
  templateUrl: './artistinstruction.component.html',
  styleUrls: ['./artistinstruction.component.scss']
})
export class ArtistinstructionComponent implements OnInit {
  artistInstructionText: string;
  indicatorValue;
  addtnlMaxLength = 1500;
  constructor(private dialogRef: MatDialogRef<ArtistinstructionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private Copysheet: CopysheetService,
    private logger :LoggerService,
    private storageService: StorageService,
    private requiredfieldcount: RequiredfieldcountService,
    private counterService: CounterService
    ) { }

  ngOnInit() {
    this.artistInstructionText = "";
    let svitemId = this.data.itemId ? this.data.itemId.svItemId : this.data.iaItems[0];
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
      this.indicatorValue = JSON.parse(JSON.stringify(this.indicatorValue));
      if (this.indicatorValue.items[svitemId].copy && this.indicatorValue.items[svitemId].copy.instructions){
        this.artistInstructionText = this.indicatorValue.items[svitemId].copy.instructions;
      }
    });

}
  onSubmit(){
    // Close the dialog, return true
    let svitemId = this.data.itemId.svItemId;
    let itemsArr = this.data.iaItems[0] ? this.data.iaItems : [svitemId];
    let storageID
    if(this.artistInstructionText && this.artistInstructionText.length <= this.addtnlMaxLength){
      if (itemsArr.length > 0){
        itemsArr.forEach(eachItem=>{
          storageID = eachItem + "v" + this.indicatorValue.items[eachItem].order.version;
          if (!this.indicatorValue.items[eachItem].model.copy){
            this.indicatorValue.items[eachItem].model['copy']={};
            this.indicatorValue.items[eachItem].model.copy['instructions'] = this.artistInstructionText;
          }else{
            this.indicatorValue.items[eachItem].model.copy['instructions'] = this.artistInstructionText;
          }
          if (!this.indicatorValue.items[eachItem].copy){
            this.indicatorValue.items[eachItem]['copy']={};
            this.indicatorValue.items[eachItem].copy['instructions'] = this.artistInstructionText;
          }else{
            this.indicatorValue.items[eachItem].copy['instructions'] = this.artistInstructionText;
          }

          this.Copysheet.updateCopysheet(storageID, this.indicatorValue.items[eachItem].copy)
          .then(() => {
            this.logger.log("Artist Instructions updated Successfully");
          })
          .catch((err) => {
            this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
          });

          if (this.indicatorValue.items[eachItem].order.statusCode >= 6000) {
            this.indicatorValue.items[eachItem].needsNewCopysheet = true;
          }

          if (this.indicatorValue.items[eachItem].order.actionCode === 'Renew') {
            this.indicatorValue.items[eachItem].model['renewalCopysheetRequiredFlag'] = true
            this.indicatorValue.items[eachItem].model['dirtyFlag'] = true
            this.copysheetnotify(eachItem, this.indicatorValue, storageID)
            //isAnyRenewal = true
            let tempData = this.requiredfieldcount.getNewItemCount(this.indicatorValue.items[eachItem].requiredFields, this.indicatorValue.items[eachItem].model, this.indicatorValue.items[eachItem].order, this.indicatorValue["orderPercent"], this.indicatorValue.items[eachItem].counter, true, this.indicatorValue.items[eachItem]);
            this.storageService.update("item", storageID, this.indicatorValue.items[eachItem].model).then(result => { });
            this.updateIndictor(eachItem, this.indicatorValue, tempData, this.indicatorValue.items[eachItem].counter, false);
          }
    
        })
        this.indicatorStore.update((state) => {
          return {
            indicator:  this.indicatorValue,
            updatedFrom: "[ArtistinstructionComponent] onSubmit",
            isloaded: true,
          };
        });
      }

      //modelData = JSON.parse(JSON.stringify(modelData));

      //this.indicatorQuery.updateModel( 'copy',this.artistInstructionText,modelData,this.indicatorValue);
      this.dialogRef.close(true);
  }
  }

  copysheetnotify(stateParamsId, indicator, storageID){
    indicator.items[stateParamsId].hasCopysheet = false;
    this.storageService.updateField("copyz", storageID, { "data.hasCopysheet": false })
    .subscribe(()=>{},err=>{})
      if(indicator.items[stateParamsId].order.productCode == 'YPC' ||  indicator.items[stateParamsId].order.productType == "Print"){
          if(indicator.items[stateParamsId].order.productCode == 'YPC'){
            indicator.items[stateParamsId].model.ManageCopysheet = 'Y';
          }else{
            indicator.items[stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
          }
          indicator.items[stateParamsId].requiresCopysheet =  true;
      }
  }

  private updateIndictor(itemId, data, tempIndicator, currentCount, updateStoreFlag) {
    let counter = [];
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcount.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
  }

  private getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
  }

  private updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }

  close(){
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
