import { Injectable } from '@angular/core';
import { UtilsService } from "../utils/utils.service";
@Injectable({
  providedIn: 'root'
})
export class FieldcountService {

  constructor(
    private Utils: UtilsService,
  ) { }

  get(schema:any, form:any, model:any, order:any) {

    var count = 0;
   

    for ( var i = 0; i < form.length; i++ ) {
        if ( form[i].key != null) {

            
            if( schema.properties[form[i].key] && schema.properties[form[i].key].required && form[i].key != "CallTracking" && form[i].key != "AppDateTime"&&
               ((model[form[i].key] === undefined)
                || model[form[i].key] == "")) { //sonar fixing
                count++;
                
                //increases count  for multisearch directive (because two required  fields in  mulisearch directive)
                if(form[i].type != null && form[i].type ==="multisearch" && model.BusinessAddressDisplayOptions !== ""){
                    count++;
                }
                //to find out which item is missing, part 1/1:BusinessAddressDisplayOptions
                //console.log(schema.properties[form[i].key]);
            }
            if(form[i].type != null && form[i].type ==="zipcodeValidation"){
                if(model[form[i].key] && model[form[i].key].hasOwnProperty("zip") &&  model[form[i].key].hasOwnProperty("zip4") ){
                    if((model[form[i].key].zip.trim().length != 5 && model[form[i].key].zip4 != "null" && model[form[i].key].zip4 != null &&  model[form[i].key].zip4 != undefined &&  (model[form[i].key].zip4.trim().length != 0 || model[form[i].key].zip4.trim().length != 4))){
                        count++;
                    }
                }
            }
            if(form[i].type != null && form[i].type ==="extraline" && model[form[i].key]){
                    for(var j = 0;j < model[form[i].key].length;j++){
                        if(!model[form[i].key][j].hasOwnProperty("text") || model[form[i].key][j]["text"] === undefined || model[form[i].key][j]["text"] === ""){
                            count++;
                        }
                        if(!model[form[i].key][j].hasOwnProperty("lineType") || model[form[i].key][j]["lineType"] === undefined || model[form[i].key][j]["lineType"] === ""){
                            count++;
                        }
                    }
            }
            //counting for websiteurl only when url is not valid
            if(form[i].type != null && form[i].type === "websiteurl"){
                if(model[form[i].key] && model[form[i].key].hasOwnProperty("url")){
                    if(model[form[i].key].url == (undefined || "") || model[form[i].key].invalidUrl === true){
                        count++;
                    }
                }
            }
            //changes for the DE31856(ProposalID)
         if(order && (order.productCode === "SEO" || order.productCode === "SEOG Add On")  ){
            if(form[i].type != null && form[i].type === "proposalid"){
                if(model.hasOwnProperty("ProposalID")){
                    if(model[form[i].key] == (undefined || "" || null) || model[form[i].key].length < 5){
                        count++;
                    }
                }
            }
         }
         if(order && order.UDAC === "SEMP" && order.actionCode != "Renew"){
            if(form[i].type != null && form[i].type === "dateTime")
            { 
            // Added type check as AppDateTime value is either string (on item save) or object.
            if(model[form[i].key] && typeof model[form[i].key] === 'object')
             {
                if(model[form[i].key].date == (undefined || ""||null) ||model[form[i].key].time == (undefined ||""||null) ){
                    count++;
                } 
            }
            else if (!model[form[i].key])
            {
                count++;
            }


         }
         }
            if(form[i].type != null && form[i].type === "profiledirective"){
                if(model[form[i].key] && model[form[i].key].hasOwnProperty("UseBusinessProfile") &&  model[form[i].key].hasOwnProperty("WebsiteAddress")){
                    if(model[form[i].key].UseBusinessProfile === "N" && (model[form[i].key].WebsiteAddress === undefined || !this.Utils.validateURL(model[form[i].key].WebsiteAddress,true))){
                        count++;
                    }
                }
            }
            //changes for BusinessCategories DPM(local power listing) --Categories field is marked required and not counted in indicator
            if(form[i].type!=null && form[i].type === "businessCategoriesItem" && (form[i].key[0] === "BusinessCategories" || form[i].key === "BusinessCategories")){
                if(model[form[i].key] && model[form[i].key].hasOwnProperty("CategoryList") && model[form[i].key].CategoryList && model[form[i].key].CategoryList.length===0){
                    count++;
                }else if(this.Utils.isObjectEmpty(model[form[i].key])){
                    count++;
                }
                if(model[form[i].key] && (model[form[i].key].hasOwnProperty("PrimaryCatId") && model[form[i].key].PrimaryCatId == undefined )|| (model[form[i].key] && !model[form[i].key].hasOwnProperty("PrimaryCatId"))){
                    count++;
                }else if(this.Utils.isObjectEmpty(model[form[i].key])){
                    count++;
                }
            }
            /*
            Adding below condition becuase for SPPOW item , counter was not increasing if business categories was missing 
             */
            if(form[i].type!=null && form[i].type === "businessCategoriesItem" && (form[i].key[0] === "BusinessCategoriesDPM" || form[i].key === "BusinessCategoriesDPM")){
                if(model[form[i].key] && model[form[i].key].hasOwnProperty("CategoryList") && model[form[i].key].CategoryList && model[form[i].key].CategoryList.length===0){
                    count++;
                }else if(this.Utils.isObjectEmpty(model[form[i].key])){
                    count++;
                }
            }

            if(form[i].key != null && (form[i].key == "BestTime" || form[i].key == "HideAddress") && model[form[i].key] === null){
                        count++;
            }
            if(form[i].type != null && form[i].type === "websitetype"){
                if(model[form[i].key] && model[form[i].key] != "Client will select domain during consultation"){
                    var strArray = model[form[i].key].split("-");
                    if(!this.Utils.validateURLDEXHub(strArray[1])){
                        count++;
                    }
                }
            }
            if(form[i].type != null && form[i].type ==="catextarea"){
                if(model[form[i].key] && model[form[i].key].length){
                    if(((model[form[i].key].length < schema.properties[form[i].key].minLength) && (model[form[i].key].length !=0)) || model[form[i].key].length > schema.properties[form[i].key].maxLength){
                        count++;
                    }
                }
            }
            //changes made for confirmation number on custom website item
            if(form[i].type != null && (form[i].key ==="ConfirmationNumber" || form[i].key[0] ==="ConfirmationNumber")){
                if(model[form[i].key] === null){
                    count++;
                }
            }
            // Call tracking indicators
            
            if(form[i].type != null && form[i].type ==="call" && (order && order.UDAC !== "ML" && order.actionCode !== "Renew") && (model.modelValue == undefined || (model.modelValue == "Yes" && (((model[form[i].key] == "" || model[form[i].key[0]] == "" )&& typeof model[form[i].key] === 'object') || (model[form[i].key]=="" && typeof model[form[i].key] === 'string')) && model[form[i].key].length == 0))){                        
                count++;
            }

            if(form[i].type != null && form[i].type ==="call" && (order && order.UDAC !== "ML" && order.actionCode === "Renew" && !(order.productCode === "SEM" || order.productCode === "SEMP" || order.productCode === "SEM Kicker")) && (model.modelValue == undefined || (model.modelValue == "Yes" && (((model[form[i].key] == "" || model[form[i].key[0]] == "" )&& typeof model[form[i].key] === 'object') || (model[form[i].key]=="" && typeof model[form[i].key] === 'string')) && model[form[i].key].length == 0))){
                count++;                    
            }
            
            //Print Copysheet
            if(form[i].type != null && form[i].type ==="managecopy" && order && order.productType === "Print" && order.actionCode !=="Renew" && (model[form[i].key]  && model[form[i].key].copySheetIndicator === 'N')){
                count++;
            }

            if(form[i].type != null && form[i].type ==="managecopy" && order && order.productType === "Print" && order.actionCode ==="Renew" && order.requireContent === true && (model[form[i].key]  && model[form[i].key].copySheetIndicator === 'N')){
                count++;
            }

            // YPC Copysheet
            if(form[i].type != null && form[i].type ==="managecopy" && order && order.productCode === "YPC" &&  (model[form[i].key] === 'N' && model.copySheetIndicator === 'N')){
                count++;
            }        
        }
    }

    //to find out which item is missing, part 2/2:
    //console.log(count);
    if(order && order.UDAC === "SEMP" && order.actionCode === "Renew"){
        count = count > 0 ? count - 1 : 0;
    }

    return count;
};
}
