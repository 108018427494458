import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient, private API: ApiService, private Params: ParamsService) { }

  PATH = "/api/group";

  headersObject = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + this.Params.params.token
    }
  }


  generateGroupID() {
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token; 
    return this.http.get(this.API.get(this.PATH),this.headersObject).toPromise();
  }

  getIAGroupID(itemId, custId, prdctCode) {
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token; 
    return this.http.get(this.API.get(this.PATH) + "/" + itemId + "/" + custId + "/" + prdctCode,this.headersObject);
  }

  splitIAItems(data,page?): Promise<any> {
    if(page){
      data.page = page;
    }
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token; 
    return this.http.post(this.API.get(this.PATH) + "/split", data,this.headersObject).toPromise();
  }

  mergeIAItems(data,page?) {
    if(page){
      data.page = page;
    }
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token; 
    return this.http.post(this.API.get(this.PATH) + "/merge", data,this.headersObject);
  }
  //create the initial unassigned group or get the saved group
  /*createGroupObject: function (orderItemsZ, currentItemZ) {
  var orderItems = orderItemsZ,
    currentItem = currentItemZ.order,
    currentSize = currentItem.size,
    currentListing = currentItem.listingId,
    currentDirectory = currentItem.directoryName,
    currentProductCode = currentItem.productCode,
    currentNumberColumns = currentItem.numberColumns,
    currentId = currentItem.svItemId,
    currentCallTracking = currentItemZ.model.CallTracking,
    storageId = currentId + "v" + currentItem.version;

  return StorageService.getField("iaGroup", storageId).then(function (data) {
    var savedIaGroup = data;
    var boardObject;

    //if there is not IA group object saved for this item, check to see if it could be part of one
    if (_.isEmpty(savedIaGroup[storageId])) {
      var filteredIaGroup = _.filter(orderItems, function (item, id, items) {
        var orderItem = item.order;

        //IA criteria -- size, print listing ID, directory name, product code, call tracking, number of columns
        if (orderItem.size === currentSize
          && orderItem.listingId === currentListing
          && orderItem.directoryName === currentDirectory
          && orderItem.productCode === currentProductCode
          && orderItem.numberColumns === currentNumberColumns
          && (item.model.CallTracking === currentCallTracking ||
            _.isEmpty(item.model.CallTracking) === _.isEmpty(currentCallTracking))
        ) {
          return orderItem;
        }
      });

      boardObject = {
        "name": currentId,
        "numberOfColumns": "",
        "columns": [

        ]
      };

      //if there are at least two IA eligible items, create the unassigned column
      if (filteredIaGroup.length > 1) {
        var iaGroupColumn = {
          "name": {
            "groupID": 'Unassigned'
          },
          "cards": []
        };

        _.each(filteredIaGroup, function (item, key, items) {
          var currentItem = item.order;
          var card = {
            "title": currentItem.directoryNumber,
            "details": {
              "productId": currentItem.svItemId,
              "headingName": currentItem.heading
            }
          };

          iaGroupColumn.cards.push(card);
        });

        boardObject.columns.push(iaGroupColumn);
        return boardObject;
      } else {
        //no columns if there aren't any IA eligible matches
        return boardObject;
      }
    } else {
      //If there is a saved IA group object for this item, assign it to boardObject and return it.
      boardObject = savedIaGroup[storageId];
      return boardObject;
    }
  })
},

removeIaGroup: function (item) {
  item.copy.ad = undefined;
  item.copy.attachments = [];
  item.copy.copysheet = undefined;
  item.copy.displayAdId = undefined;
  item.copy.displayAdIdPrefix = undefined;
  item.copy.graphicRegion = undefined;
  item.copy.instructions = undefined;
  item.model.ManageCopysheet.baseAdId = undefined;
  item.model.ManageCopysheet.baseAdIdPrefix = undefined;
  item.model.ManageCopysheet.copySheetIndicator = "Y";
  item.model.ManageCopysheet.iaGroupId = undefined;
  item.hasCopysheet = false;

  Copysheet.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
    .success(function (response) {
    }).error(function (err) {
      Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
    });
  return item;
},

removeSetBasedOnAd: function (item) {
  item.copy.ad = undefined;
  item.copy.displayAdId = undefined;
  item.copy.displayAdIdPrefix = undefined;
  item.copy.graphicRegion = undefined;
  item.copy.headingName = undefined;

  item.model.ManageCopysheet.baseAdId = undefined;
  item.model.ManageCopysheet.baseAdIdPrefix = undefined;
  item.model.ManageCopysheet.copySheetIndicator = "Y";

  Copysheet.updateCopysheet(item.order.svItemId + "v" + item.order.version, item.copy)
    .success(function (response) {
      Logger.logSuccess("Successfully Updated Copysheet");
    }).error(function (err) {
      Logger.logError("Failed to update copysheet :" + JSON.stringify(err));
    });

  return item;
},*/

  /**
   * Get the list of unique items for whom copysheet needs to be uploaded. 
   * If there are multiple items ina  single group return list will have
   * only 1 item from that group.
   * @param - list of items for whom copysheet needs to be uploaded. e.g 
   * @returns - list of items for whom copysheet needs to be uploaded (just 1 item in each group) e.g. 
   */
  /*getUniqueItemsForCopysheetUpload: function (itemList) {
    var filtereditemList = [];
    var uniqueIAGroupIdList = [];
    for (var i = 0; i < itemList.length; i++) {
  
      if (itemList[i].order.productType === "Print" || itemList[i].order.productCode === "YPC") {
        if (itemList[i].data   //new IA group ID
          && itemList[i].data.iaGroupId
          && uniqueIAGroupIdList.indexOf(itemList[i].data.iaGroupId) === -1
        ) {
          uniqueIAGroupIdList.push(itemList[i].data.iaGroupId);
  
          filtereditemList.push(itemList[i])
        } else if (!itemList[i].data    // if there is no group ID
          || !itemList[i].data.iaGroupId) {
          filtereditemList.push(itemList[i])
        }
      }
  
    }
    return filtereditemList;
  },*/

  /**
   * Get the list of unique items for whom copysheet needs to be uploaded. 
   * If there are multiple items ina  single group return list will have
   * only 1 item from that group.
   * @param - svItemId - any tem of the group
   * @param - Indicator - Indicator object that will be used to find other items of same group 
   * @returns - list of items of the group. 
   */
  /*getAllItemsOfGroup: function (svItemId, Indicator) {
    var allGroupItems = [];
    for (var item in Indicator.items) {
      if (Indicator.items[item].order.productType === "Print" || Indicator.items[item].order.productCode === "YPC") {
        if (Indicator.items[item].data && Indicator.items[item].data.iaGroupId && Indicator.items[item].data.iaGroupId != "" &&
          Indicator.items[item].data.iaGroupId != null && Indicator.items[item].data.iaGroupId != undefined &&
          (Indicator.items[svItemId].data.iaGroupId === Indicator.items[item].data.iaGroupId)) {
          allGroupItems.push(item);
        }
      }
    }
    return allGroupItems;
  },*/
  /**
   * Get the list of unique items for whom copysheet needs to be uploaded. 
   * If there are multiple items ina  single group return list will have
   * only 1 item from that group.
   * @param - svItemId - any tem of the group
   * @param - Indicator - Indicator object that will be used to find other items of same group 
   * @returns - list of items of the group. 
   */
  getAllItemsOfGroupButNotSelf(svItemId, Indicator) {
    var allGroupItems = [];
    for (var item in Indicator.items) {
      if (Indicator.items[item].order.productType === "Print" || Indicator.items[item].order.productCode === "YPC") {
        if (Indicator.items[item].data && Indicator.items[item].data.iaGroupId && Indicator.items[item].data.iaGroupId != "" &&
          Indicator.items[item].data.iaGroupId != null && Indicator.items[item].data.iaGroupId != undefined &&
          (Indicator.items[svItemId].data.iaGroupId === Indicator.items[item].data.iaGroupId) && (item !== svItemId)) {
          allGroupItems.push(item);
        }
      }
    }
    return allGroupItems;
  }
    
}

