import { Component, OnInit } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { UtilsService } from '@app/service/utils/utils.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  email;
  invalidEmail: boolean = false;
  sharedData: Quickbpclass;
  options: any;
  titleContent: any;
  bpEmail:boolean = false;
  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private utils: UtilsService) { }

  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data))
    });
    this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['BPBDEmail']['title'];
    // this.bpEmail = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['title'] = "Business Info" ? true : false;
    this.bpEmail = (this.sharedData['profiles'] && this.sharedData['profiles'][this.sharedData.stateParamsId] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'] && this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['title'] ) &&
     (this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['title'] = "Business Info") ? true : false;
    if (this.sharedData['profiles'] && this.sharedData['profiles'] != undefined &&
    this.sharedData['profiles'] != null && this.sharedData['profiles'][this.sharedData.stateParamsId] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId] != undefined &&
    this.sharedData['profiles'][this.sharedData.stateParamsId] != null &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model != undefined &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model != null &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"] &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"] != undefined &&
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"] != null) {
      this.email = this.sharedData['profiles'][this.sharedData.stateParamsId].model["BPBDEmail"];
    }

    if (!this.utils.isValidEmail(this.email)) {
      this.invalidEmail = true;
    } else {
      if (this.email.length > 75) {
        this.invalidEmail = true;
      }else{
      this.invalidEmail = false;
    }
    }
  }

  sendValue(args) {
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    if (!this.utils.isValidEmail(this.email)) {
      this.invalidEmail = true;
    } else {
      if (this.email.length > 75) {
        this.invalidEmail = true;
      }else{
      this.invalidEmail = false;
    }
    }
    modelData["BPBDEmail"] = args;
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;
    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[EmailComponent] sendValue"
      }
    })
  }
}
