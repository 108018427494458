import { Component, OnInit } from '@angular/core';

import { LogoService } from '@app/service/logo/logo.service';
import { PhotoService } from '@app/service/photos/photosharing.service';
import { Photos } from 'app/interface/photos';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { CopyService } from '@app/service/copy/copy.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { ApiService } from '@app/service/api/api.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  subscription: any;
  Indicator: any;
  photoSubscription: any;
  filteredAttachments: any = [];
  itemId: string = '';
  photoType: string = '';
  totalItems: number = 0;
  itemsPerPage: number = 8;
  currentPage: number = 1;
  maxSize: number = 5;
  bigTotalItems: number = 175;
  bigCurrentPage: number = 1;
  sharedCopySheetObj: Photos = new Photos;
  page: any;
  showMessage: boolean = false;
  attachments: any = []
  duplicateAttachment: any;
  attachmentRemoved: boolean;
  showhide: boolean;
  browseFiles: any = [];
  fileName: string = '';

  constructor(private _logoService: LogoService,
    private spinnerService: NgxSpinnerService,
    private attachment: PhotoService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private Copysheet: CopyService,
    private Logger: LoggerService,
    private API: ApiService) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = { ...data }
      this.itemId = this.Indicator['stateParamsId'];
    })
    if (this.Indicator.items[this.Indicator.stateParamsId].copy.attachments && this.Indicator.items[this.Indicator.stateParamsId].copy.attachments.length == 1) {
      if (this.Indicator.items[this.Indicator.stateParamsId].copy.attachments[0] == null) {
        this.Indicator.items[this.Indicator.stateParamsId].copy.attachments = [];
      }
    }
    this.attachments = this.Indicator.items[this.Indicator.stateParamsId].copy.attachments || [];
    this.sharedCopySheetObj.attachmentsObj = this.attachments
    this.attachment.setBehaviorView(this.sharedCopySheetObj)
    this.photoSubscription = this.attachment.getBehaviorView().subscribe(result => {
      this.browseFiles = result.browseFilesObj
      this.attachments = result.attachmentsObj
    })
    this.loadAttachments();
  }

  loadAttachments() {
    this.Copysheet.get(this.itemId + "v" + this.Indicator.items[this.itemId].order.version)
    .subscribe(res => {
      this.attachments = this.arrayUnique(this.attachments.concat(res.attachments || []));
      this.browseFiles = this.attachments
      this.filteredAttachments = this.attachments

      if(this.filteredAttachments && this.filteredAttachments.length != 0) this.showMessage = false
      else this.showMessage = true

      this.sharedCopySheetObj.browseFilesObj = this.browseFiles
      this.sharedCopySheetObj.attachmentsObj = this.attachments
      this.attachment.setBehaviorView(this.sharedCopySheetObj)
    });
  }

  removeAttachment(attachment) {
    this.attachmentRemoved = true;
    for (var i = 0; i < this.attachments.length; i++) {
      if (this.attachments[i] === attachment) {
        this.attachmentRemoved = false;
        break;
      }
    }
    if (!this.duplicateAttachment) {
      var index = this.attachments.indexOf(attachment);
      if (index > -1) {
        this.attachments.splice(index, 1);
        this.sharedCopySheetObj.browseFilesObj = this.attachments;
        this.filteredAttachments = this.attachments;
        if (this.attachments === undefined || this.attachments.length == 0) {
        }
        else {
          this.sharedCopySheetObj.browseFilesObj = this.attachments;
        }
        this.attachment.setBehaviorView(this.sharedCopySheetObj)
      }
      if(this.filteredAttachments.length === 0) this.showMessage = true
      else this.showMessage = false
      this.Logger.logSuccess(attachment.substring(attachment.lastIndexOf("/") + 1, attachment.length) + ' removed from attachments');
    }
  }

  arrayUnique(array) {
    let a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }
    return a;
  }

  ngOnDestroy() {
    this.photoSubscription.unsubscribe();
  }

}
