import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { ThemePalette, ProgressSpinnerMode, MatDialogRef, MAT_DIALOG_DATA, MatStepper } from '@angular/material';
import { Subject } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { IndicatorQuery } from 'app/state/query';
import { ManageBPConstant, OrderBPConstant, CustomTosterMessage } from 'app/data/constants';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { LoggerService } from '@app/service/logger/logger.service';
import { ParamsService } from '@app/service/params/params.service';

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  /* providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }] */
})
export class SpinnerComponent implements OnInit, OnDestroy {
  sideBarOpen = true;
  showSpinner: boolean = false;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value: number = 0;
  counter: number = 5;
  isLinear = false;
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  processUrls: object[]  = [];
  destroySubject$: Subject<void> = new Subject();
  values: any;
  apiLabels: any;
  apiStatus: any;
  apiWarningError: any;
  constructor(public dialogRef: MatDialogRef<SpinnerComponent>
    , private indicatorQuery: IndicatorQuery
    , private Params: ParamsService
    , private spinnerService: SpinnerService
    , private logger: LoggerService
    , @Inject(MAT_DIALOG_DATA) data) { }

  ngOnInit() {
    let subUrl = '';
    this.showSpinner = true;
    const map = new Map();
    this.values = Object.values;
    let isClosed = true;
    this.apiLabels=[];
    let hasNumber = /\d/;
    this.spinnerService.getSpinner().pipe(filterNil, takeUntil(this.destroySubject$))
      .subscribe(data => {
        // console.log(" mode : ",this.Params.params.spinnerMode);
        if(this.Params.params.spinnerMode==='orderitems')
          this.apiLabels = OrderBPConstant;
        else
        this.apiLabels = ManageBPConstant;
        if(data && data.url && data.url.includes("/api/")  && data.status == 0) {
          let url = data.url;
          let fisrtPartUrl = url.substring(url.indexOf('/api/') + 5).split('/', 1)[0];
          let secondPartUrl = url.substring(url.indexOf('/api/') + 5).split('/', 2)[1];
         if(secondPartUrl &&  !hasNumber.test(secondPartUrl)) {
          secondPartUrl = fisrtPartUrl + secondPartUrl;
          this.logger.logError(CustomTosterMessage[secondPartUrl] );
         } else  {
          this.logger.logError(CustomTosterMessage[fisrtPartUrl]);
         }
          isClosed = false;
          return false;
        }
        if (data && data.url && data.url.includes("/api/") && !data.url.includes("/api/salesforce/") && !data.url.includes("/api/storage/")
          && !data.url.includes("/api/group/") && !data.url.includes("/api/copy/") && !data.url.includes("/api/call/")
          && !data.url.includes("/api/copysheetindicator/") && !data.url.includes("/api/configuration/") && !data.url.includes("/api/billing/")) {
          subUrl = data.url;
          subUrl = subUrl.substring(subUrl.indexOf('/api/') + 5).split('/', 1)[0];
          subUrl = subUrl.toUpperCase();
          if( subUrl == 'SCHEMANATIONAL' && data.status == 401) {
            isClosed = false;
            return false;
          }
          subUrl = OrderBPConstant[subUrl];
          if (!map.has(subUrl)) {
            map.set(subUrl, true);
            data.url = subUrl;
            this.processUrls.push(data);
            data.status == 200 || data.status == 204 ? '' : this.close();
          }
          if(this.value  <= 90) {
            this.value = this.value + this.counter;
          }
        }
    });
    this.indicatorQuery.getLoaded().subscribe(isLoaded => {
      if (isLoaded) {
        this.value = 95;
         isClosed ? this.close() : '';
        // setTimeout(() => {
        //   this.value = 100;
        //   this.close();
        // }, 2000);
      }
    })
      /*   console.log(this.isLoading)
        this.showSpinner = true;
        await this.indicator.accountSubject.pipe(take(1)).subscribe(() => {
          this.value = this.value + this.counter;
          this.myStepper.next();
        });

        await this.indicator.orderSubject.pipe(take(1)).subscribe(() => {
          this.value = this.value + this.counter;
          this.myStepper.next();
        });
        await this.indicator.billingSubject.pipe(take(1)).subscribe(() => {
        this.value = this.value + this.counter;
        this.myStepper.next();
      });
      await setTimeout (() => {
        this.myStepper.next();
        this.value = this.value + this.counter;
      // this.close();
      }, 12000); */
      /* await this.indicator.headerSubject.pipe(take(1)).subscribe(() => {
      this.value = this.value + this.counter;
      this.myStepper.next();
      this.close();
      }); */

  }
  close() {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.destroySubject$.next();
 }
}
