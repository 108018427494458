import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { CounterService } from '../counter/counter.service';
import { IndicatorQuery } from 'app/state/query';
import { UpdateIndicatorService } from '../updateIndicato/update-indicator.service';
import { RequiredfieldcountService } from '../requiredfieldcount/requiredfieldcount.service';



@Injectable({
  providedIn: 'root'
})
export class WatchHelperService {
  tempIndicator;
  constructor(
    private storageService :StorageService,
    private Profile: ProfileService,
    private counterService: CounterService,
    private indicatorQuery :IndicatorQuery,
    private updateIndicatorService:  UpdateIndicatorService,
    private requiredfieldcount :RequiredfieldcountService){ }
 
  /**
   * BusinessProfileWatcher Helper
   * @itemId - ItemId for the item that needs to be populated
   * @value - ListingId of the profile that needs to be used for populating the item details
   */
  async setItemValuesFromBP (itemId, value, Indicator,componentName){
   this.tempIndicator = JSON.parse(JSON.stringify(Indicator));
   if(componentName === "bp" && this.tempIndicator.items[itemId].order.productCode ==="SEO"  ){ //&& this.tempIndicator.items[itemId].order.actionType === "New" && this.tempIndicator.items[itemId].order.actionCode === "Add"
        let seoData =[];
        await this.Profile.getByListing('digital', value).then((resp) => {
            if(resp && resp[value] && resp[value].listings){
            let city = resp[value].listings.listing[0].businessAddress.city;
            let name = resp[value].listings.listing[0].businessName;
            this.tempIndicator.items[itemId].model["PrimaryCity"] = city;
            this.tempIndicator.items[itemId].model["BusinessName"] = name;   this.tempIndicator.items[itemId].model["BusinessCategories"]["CategoryList"]= resp[value].listings.listing[0].categories.category;
            this.updateStore(this.tempIndicator,itemId);
         
            let newIndicator=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            newIndicator.items[itemId].model["BusinessCategories"]["CategoryList"]= resp[value].listings.listing[0].categories.category;
            this.updateStore(newIndicator,itemId);
            this.tempIndicator = newIndicator;
            seoData.push(city);
            seoData.push(name);
            seoData.push(resp[value].listings.listing[0].categories.category);
            this.counterService.setSeoData(seoData);

            }
        });
    }

    this.tempIndicator = JSON.parse(JSON.stringify(this.tempIndicator));
      let storageID = itemId + "v" + this.tempIndicator.items[itemId].order.version;
      if (!itemId) return;
      let iypPremiumAssociation = false;

      if (this.tempIndicator.items[itemId].order.productCode === "Premium IYP") {
          iypPremiumAssociation = true;
      }

      if (value != null) {
          if (!iypPremiumAssociation && (this.tempIndicator.items[itemId].order.productCode ==="SEO" || this.tempIndicator.items[itemId].model.hasOwnProperty("BusinessCategories")) && this.tempIndicator.profiles[value] && this.tempIndicator.profiles[value].model.hasOwnProperty("BusinessCategories") && typeof this.tempIndicator.profiles[value].model["BusinessCategories"] == "object" && this.tempIndicator.items[itemId].model.isFirstLoadForSEO) {
            this.tempIndicator.items[itemId].model["BusinessCategories"] =this.tempIndicator.items[itemId].model["BusinessCategories"] ? this.tempIndicator.items[itemId].model["BusinessCategories"] : {}; //in some cases businesscategories obj is not coming for SEO and it is breaking while populating categoryList
            this.tempIndicator.items[itemId].model["BusinessCategories"]["CategoryList"] = this.tempIndicator.profiles[value].model["BusinessCategories"]    
            .map( (currentValue, index, array)=> {
                return {
                    CategoryId: currentValue.categoryId,
                    CategoryName: currentValue.categoryName
                }
            });
                
                  this.tempIndicator.items[itemId].model.isFirstLoadForSEO = false;
          }
          if ( this.tempIndicator.items[itemId].order.productCode === "DPM" && this.tempIndicator.profiles[value] && this.tempIndicator.profiles[value].model.hasOwnProperty("BusinessCategories") ) {
            this.tempIndicator.items[itemId].model["BusinessCategoriesDPM"]= this.tempIndicator.items[itemId].model["BusinessCategoriesDPM"] ? this.tempIndicator.items[itemId].model["BusinessCategoriesDPM"]:{};
            this.tempIndicator.items[itemId].model["BusinessCategoriesDPM"]["CategoryList"]= this.tempIndicator.profiles[value].model["BusinessCategories"]
           
                this.tempIndicator.items[itemId].model.isFirstLoadForSEO = false;
        }


          if (this.tempIndicator.items[itemId].schema.properties.ListingCategories &&  this.tempIndicator.profiles[value] && this.tempIndicator.profiles[value].model.hasOwnProperty("BusinessCategories") && typeof this.tempIndicator.profiles[value].model["BusinessCategories"] == "object") {
              this.tempIndicator.items[itemId].model["ListingCategories"] = this.tempIndicator.profiles[value].model["BusinessCategories"]
              .map( (currentValue, index, array) => {
                return {
                    CategoryId: currentValue.categoryId,
                    CategoryName: currentValue.categoryName
                }
            });
          }

               //Condition for DPM DE16820 Fix - So first time load all the data from BP to DPM. Next time only sync below fields with BP.
          if (this.tempIndicator.items[itemId].order.productCode === "DPM" && this.tempIndicator.profiles[value]) {
              if (this.tempIndicator.items[itemId].model.initialDataSet == true) {
                  this.tempIndicator.items[itemId].model["BusinessAddress1"] = this.tempIndicator.profiles[value].model["BusinessAddress1"];
                  this.tempIndicator.items[itemId].model["BusinessAddress2"] = this.tempIndicator.profiles[value].model["BusinessAddress2"];
                  this.tempIndicator.items[itemId].model["BusinessAddressCity"] = this.tempIndicator.profiles[value].model["BusinessAddressCity"];
                  this.tempIndicator.items[itemId].model["PrimaryCity"] = this.tempIndicator.profiles[value].model["BusinessAddressCity"];
                  this.tempIndicator.items[itemId].model["BusinessAddressState"] = this.tempIndicator.profiles[value].model["BusinessAddressState"];
                  this.tempIndicator.items[itemId].model["BusinessAddressZip"] = this.tempIndicator.profiles[value].model["BusinessAddressZip"];
                  //this.tempIndicator.items[itemId].model["SpecialtiesLPL"] = this.tempIndicator.profiles[value].model["SpecialtiesBP"];
                //  await this.storageService.getField("DPMBP", storageID).then((dpmdata)=>{
                //       if (dpmdata && dpmdata[storageID].firstTimeLoaded == undefined) {
                //            //this.setSpeciltyValuesfromBP(itemId,value);
                //       }
                //   });

              } else {

                  this.tempIndicator.items[itemId].model["BusinessName"] = this.tempIndicator.profiles[value].model["BusinessName"];
                  this.tempIndicator.items[itemId].model["BusinessPhone"] = this.tempIndicator.profiles[value].model["BusinessPhone"];
                  this.tempIndicator.items[itemId].model["BusinessAddress1"] = this.tempIndicator.profiles[value].model["BusinessAddress1"];
                  this.tempIndicator.items[itemId].model["BusinessAddress2"] = this.tempIndicator.profiles[value].model["BusinessAddress2"];
                  this.tempIndicator.items[itemId].model["BusinessAddressCity"] = this.tempIndicator.profiles[value].model["BusinessAddressCity"];
                  this.tempIndicator.items[itemId].model["PrimaryCity"] = this.tempIndicator.profiles[value].model["BusinessAddressCity"];
                  this.tempIndicator.items[itemId].model["BusinessAddressState"] = this.tempIndicator.profiles[value].model["BusinessAddressState"];
                  this.tempIndicator.items[itemId].model["BusinessAddressZip"] = this.tempIndicator.profiles[value].model["BusinessAddressZip"];
                //  await this.storageService.getField("DPMBP", storageID).then((dpmdata)=>{
                //       if (dpmdata && dpmdata[storageID].firstTimeLoaded == undefined) {
                        
                //            //this.setSpeciltyValuesfromBP(itemId,value);
                //       }
                //   });
                  //this.tempIndicator.items[itemId].model["SpecialtiesLPL"] = this.tempIndicator.profiles[value].model["SpecialtiesBP"];
                  this.tempIndicator.items[itemId].model["AlternatePhone"] = this.tempIndicator.profiles[value].model["PhoneAlternate"];//Fix for DE14910
                  this.tempIndicator.items[itemId].model["TollFreePhone"] = this.tempIndicator.profiles[value].model["PhoneTollFree"];//Fix for DE14910
                  this.tempIndicator.items[itemId].model["Fax"] = this.tempIndicator.profiles[value].model["Fax"];//Fix for DE14910 YearEstablished
                  this.tempIndicator.items[itemId].model["YearEstablished"] = this.tempIndicator.profiles[value].model["InBusinessSince"];//Fix for DE14910
                  if (!this.tempIndicator.items[itemId].model["FeaturedMessage"]) {                      
                      this.tempIndicator.items[itemId].model["FeaturedMessage"] = this.tempIndicator.profiles[value].model["BusinessTagline"];//Fix for DE14910
                  }
              }
          } else if(this.tempIndicator.profiles[value]) {
              // This is for all items other than DPM
              this.tempIndicator.items[itemId].model["BusinessName"] = this.tempIndicator.profiles[value].model["BusinessName"];
              this.tempIndicator.items[itemId].model["BusinessPhone"] = this.tempIndicator.profiles[value].model["BusinessPhone"];
              this.tempIndicator.items[itemId].model["BusinessAddress1"] = this.tempIndicator.profiles[value].model["BusinessAddress1"];
              this.tempIndicator.items[itemId].model["BusinessAddress2"] = this.tempIndicator.profiles[value].model["BusinessAddress2"];
              this.tempIndicator.items[itemId].model["BusinessAddressCity"] = this.tempIndicator.profiles[value].model["BusinessAddressCity"];
              this.tempIndicator.items[itemId].model["PrimaryCity"] = this.tempIndicator.profiles[value].model["BusinessAddressCity"];
              this.tempIndicator.items[itemId].model["BusinessAddressState"] = this.tempIndicator.profiles[value].model["BusinessAddressState"];
              /* this.tempIndicator.items[itemId].model["BusinessAddressZip"] =  this.tempIndicator.profiles[value].model["BusinessAddressZip"];*/ //Fix for DE14038
              this.tempIndicator.items[itemId].model["BusinessAddressZip"] = this.tempIndicator.profiles[value].model["BusinessAddressZip"];
              this.tempIndicator.items[itemId].model["AlternatePhone"] = this.tempIndicator.profiles[value].model["PhoneAlternate"];//Fix for DE14910
              this.tempIndicator.items[itemId].model["TollFreePhone"] = this.tempIndicator.profiles[value].model["PhoneTollFree"];//Fix for DE14910
              this.tempIndicator.items[itemId].model["Fax"] = this.tempIndicator.profiles[value].model["Fax"];//Fix for DE14910 YearEstablished
              this.tempIndicator.items[itemId].model["YearEstablished"] = this.tempIndicator.profiles[value].model["InBusinessSince"];//Fix for DE14910
              if (! this.tempIndicator.items[itemId].model["FeaturedMessage"]) {                 
                  this.tempIndicator.items[itemId].model["FeaturedMessage"] = this.tempIndicator.profiles[value].model["BusinessTagline"];//Fix for DE14910
              }
          }


          let paymentMap = {
              "Cash": "CASH",
              "VISA": "VISA",
            //   "Credit Terms Available": "CTA", // removing this as it is not displayed on item page. so, vision throwing error on item save
              "MasterCard": "MASTERCARD",
              "Financing Available": "FINANCING",
              "American Express": "AMERICANEXPRESS",
              "Discover": "DISCOVER"
          }

          //Default Payment options in DPM from Business  Profile
          if ( !this.tempIndicator.items[itemId].model["PaymentOptions"] && this.tempIndicator.profiles[value] && this.tempIndicator.profiles[value].model["PaymentOptions"]) {
              this.tempIndicator.items[itemId].model["PaymentOptions"] = this.tempIndicator.profiles[value].model["PaymentOptions"].map( (currentValue)=>{
               if (currentValue.selected === "Y") {
                    return paymentMap[currentValue._content_];
                }
            }).filter(currentValue =>{  //This will prevent null values to be returned.
                return currentValue;
            });
          }

          //Default SpokenLanguages in DPM from Business Profile
          if (!this.tempIndicator.items[itemId].model["SpokenLanguages"] && this.tempIndicator.profiles[value] && this.tempIndicator.profiles[value].model["Languages"]) {
              this.tempIndicator.items[itemId].model["SpokenLanguages"] = this.tempIndicator.profiles[value].model["Languages"].map(currentValue => {
                if (currentValue.selected === "Y") {
                    return currentValue._content_.toUpperCase();
                }
            }).filter(currentValue => {  //This will prevent null values to be returned.
                return currentValue;
            });
          }
      }
  return this.tempIndicator;
  
 }
   setSpeciltyValuesfromBP (itemId,value) {
    let temp = [];
    let lplspecString = "";
    if(this.tempIndicator.profiles[value].model["CustomCatKeys"] && this.tempIndicator.profiles[value].model["CustomCatKeys"].Specialities){
        temp = this.tempIndicator.profiles[value].model["CustomCatKeys"].Specialities.split(";");
    }

    if (this.tempIndicator.items[itemId].model["SpecialtiesLPL"] && temp.length <= 10) {
        this.tempIndicator.items[itemId].model["SpecialtiesLPL"] = this.tempIndicator.profiles[value].model["CustomCatKeys"].Specialities;
    } else if(this.tempIndicator.items[itemId].model["SpecialtiesLPL"]) {
        for (let i = temp.length - 1; i >= 10; i--) {
            temp.splice(i, 1);
        }
        for (let l = 0; l < temp.length; l++) {
            lplspecString = lplspecString + ";" + temp[l];
        }
        while (lplspecString.charAt(0) === ';')
            lplspecString = lplspecString.slice(1);
        this.tempIndicator.items[itemId].model["SpecialtiesLPL"] = lplspecString;
    }
};
updateStore(tempIndicator,itemId){
    let currentCount=  tempIndicator['items'][tempIndicator.stateParamsId].counter;
    let tempData = this.requiredfieldcount.getNewItemCount(tempIndicator.items[itemId].requiredFields, tempIndicator.items[itemId].model,tempIndicator.items[itemId].order, tempIndicator.orderPercent, currentCount, true);
    this.updateIndicatorService.updateIndictor(itemId, tempIndicator, tempData, currentCount);
    //this.requiredfieldcount.calculatePercent(tempIndicator['items'], tempIndicator['profiles']);
    
  }
  

}
