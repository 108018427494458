import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignfieldcountService {

  constructor() { }

  get(schema:any, form:any, model:any) {

    var count = 0;  

    if(form){
    for (var i = 0; i < form.length; i++ ) {
        if (form[i] !== null && form[i] !== undefined && form[i].key != null) {
            if (schema.properties[form[i].key].required
                && ((model[form[i].key]) === undefined || model[form[i].key] == "" || model[form[i].key]===null)) {
                count++;
            }
        }
    }
    }

    return count;
};

getNationalUrlCounts(model:any) {

    var nationalCount = 0;

    if(!model.nationalCampaign || model.nationalCampaign.NationalDisplayURL == ""){
        nationalCount++;
    }
    if(!model.nationalCampaign || model.nationalCampaign.NationalDestinationURL == ""){
        nationalCount++;
    }
    if(model.nationalCampaign && model.nationalCampaign.isOverrideClicked != undefined && !model.nationalCampaign.isOverrideClicked){
        nationalCount++;
    }
    

    return nationalCount;
};

getLocalUrlCounts(model:any) {
   
    var localCount = 0;

    if(model.LocalWebsite) {
        if(model.LocalWebsite.disablelist == false){
            localCount = 0;
        }else {
            if(model.LocalWebsite.displayurl == ""){
                localCount++;
            }
            if(model.LocalWebsite.destinationurl == ""){
                localCount++;
            }
            if (model.LocalWebsite.isOverrideClicked != undefined && !model.LocalWebsite.isOverrideClicked)
            {
                localCount++;
            }
            
        }
    }

    return localCount;
};

}
