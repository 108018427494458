import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { StorageService } from '@app/service/storage/storage.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-websitetype',
  templateUrl: './websitetype.component.html',
  styleUrls: ['./websitetype.component.scss']
})
export class WebsitetypeComponent implements OnInit {

  label1: string = 'Website Type';
  label2: string = 'Website URL';
  readonly: boolean = false;
  disableUrlField: boolean;
  websiteUrl: string = '';
  productCode: string = '';
  Indicator: any;
  invalidUrl: boolean = false;
  websiteTypes: any;
  selectedValue: string = '';
  ngModel: any;
  selectedItem: string;

  constructor(private indicatorQuery: IndicatorQuery,
    private StorageService: StorageService,
    private utils: UtilsService,
    private indicatorStore: IndicatorStore,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.websiteTypes = [{ name: 'New', value: 'New' }, { name: 'Existing', value: 'Existing' },
    { name: 'Client will select domain during consultation', value: 'Client will select domain during consultation' }];
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
      this.productCode = this.Indicator.items[this.Indicator.stateParamsId].order.productCode;
      this.selectedItem = this.Indicator.items[this.Indicator.stateParamsId].order.svItemId
      this.getDataFromStorage();
      this.readonly = this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name].readonly;
    });
  }

  selectedOption() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });

    if (this.selectedValue === 'New') {
      this.StorageService.updateField("item", this.selectedItem, { "data.websiteType": this.selectedValue }).subscribe(res => { });
      this.disableUrlField = false;
      if (this.utils.validateURLDEXHub(this.websiteUrl)) {
        this.invalidUrl = false;
      } else {
        this.invalidUrl = true;
      }
      if (this.websiteUrl !== undefined && this.websiteUrl !== null) {
        this.ngModel = 'Registered-' + this.websiteUrl;
        this.StorageService.updateField("item", this.selectedItem, { "data.websiteUrl": this.websiteUrl }).subscribe(res => { });
      }
    } else if (this.selectedValue === 'Existing') {
      this.disableUrlField = false;
      if (this.utils.validateURLDEXHub(this.websiteUrl)) {
        this.invalidUrl = false;
      } else {
        this.invalidUrl = true;
      }
      this.StorageService.updateField("item", this.selectedItem, { "data.websiteType": this.selectedValue }).subscribe(res => { });

      if (this.websiteUrl !== undefined && this.websiteUrl !== null) {
        this.ngModel = 'Existing-' + this.websiteUrl;

        this.StorageService.updateField("item", this.selectedItem, { "data.websiteUrl": this.websiteUrl }).subscribe(res => { });
      }
    } else if (this.selectedValue === 'Client will select domain during consultation') {
      this.disableUrlField = true;
      this.websiteUrl = '';
      this.ngModel = 'Client will select domain during consultation';
      this.invalidUrl = false;
      this.StorageService.updateField("item", this.selectedItem, { "data.websiteType": this.selectedValue }).subscribe(res => { });
    }
    this.Indicator.items[this.Indicator.stateParamsId].model.URL = this.ngModel
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[WebsitetypeComponent] selectedOption",
        isloaded: true
      }
    });
    this.indicatorStore.setLoading(false);
  };

  getDataFromStorage() {
    this.StorageService.getField("item", this.selectedItem).then(data => {

      if (data[this.selectedItem] !== undefined && data[this.selectedItem].URL !== undefined) {
        if (data[this.selectedItem].URL === 'Client will select domain during consultation') {
          this.selectedValue = 'Client will select domain during consultation';
          this.ngModel = 'Client will select domain during consultation';
          this.disableUrlField = true;
        } else if (data[this.selectedItem].URL.substring(0, 8) === 'Existing') {
          var eURL = data[this.selectedItem].URL.substring(9);

          this.selectedValue = 'Existing';
          this.disableUrlField = false;

          if (eURL) {
            this.websiteUrl = eURL;
            this.ngModel = 'Existing-' + eURL;
          }
        } else if (data[this.selectedItem].URL.substring(0, 10) === 'Registered') {
          var rURL = data[this.selectedItem].URL.substring(11);

          this.selectedValue = 'New';
          this.disableUrlField = false;

          if (rURL) {
            this.websiteUrl = rURL;
            this.disableUrlField = true;
            this.ngModel = 'Registered-' + rURL;
          }
        }
      } else if (data[this.selectedItem] !== undefined && data[this.selectedItem].websiteType !== undefined) {
        if (data[this.selectedItem].websiteType === 'Client will select domain during consultation') {
          this.selectedValue = 'Client will select domain during consultation';
          this.ngModel = 'Client will select domain during consultation';
          this.disableUrlField = true;
          this.invalidUrl = false;
        } else if (data[this.selectedItem].websiteType === 'Existing') {
          this.selectedValue = data[this.selectedItem].websiteType;
          this.disableUrlField = false;

          if (data[this.selectedItem].websiteUrl !== undefined) {
            this.websiteUrl = data[this.selectedItem].websiteUrl
            this.ngModel = 'Existing-' + this.websiteUrl;
          }
        } else if (data[this.selectedItem].websiteType === 'New') {
          this.selectedValue = 'New';
          this.disableUrlField = false;

          if (data[this.selectedItem].websiteUrl !== undefined && this.utils.validateURLDEXHub(data[this.selectedItem].websiteUrl) === true) {
            this.websiteUrl = data[this.selectedItem].websiteUrl;
            this.disableUrlField = true;
            this.invalidUrl = false;
            this.ngModel = 'Registered-' + this.websiteUrl;
          }
          if (!this.utils.validateURLDEXHub(this.websiteUrl)) {
            this.invalidUrl = true;
          } else {
            this.invalidUrl = false;
          }
        }
      } else {
        this.selectedValue = 'Client will select domain during consultation';
        this.ngModel = 'Client will select domain during consultation';
        this.disableUrlField = true;
        this.invalidUrl = false;
      }
      this.Indicator.items[this.Indicator.stateParamsId].model.URL = this.ngModel
      this.indicatorStore.setLoading(true);
      this.indicatorStore.update(state => {
        return {
          indicator: this.Indicator,
          updatedFrom: "[WebsitetypeComponent] getDataFromStorage",
          isloaded: true
        }
      });
      this.indicatorStore.setLoading(false);
      if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();
    });
  }

  sendValue(evt) {
    let args = evt.target.value;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = JSON.parse(JSON.stringify(data));
    });
    if (this.selectedValue === 'Existing') {
      if (this.websiteUrl !== undefined && this.websiteUrl !== null && this.websiteUrl !== "") {
        this.ngModel = 'Existing-' + args;
        if (this.utils.validateURLDEXHub(this.websiteUrl)) {
          this.invalidUrl = false;
        }
        else {
          this.invalidUrl = true;
        }
        this.StorageService.updateField("item", this.selectedItem, { "data.websiteUrl": this.websiteUrl }).subscribe(res => { });
      }
    } else if (this.selectedValue === 'New') {
      this.ngModel = 'Registered-' + args;
      if (this.utils.validateURLDEXHub(this.websiteUrl)) {
        this.invalidUrl = false;
      }
      else {
        this.invalidUrl = true;
      }
      this.StorageService.updateField("item", this.selectedItem, { "data.websiteUrl": this.websiteUrl }).subscribe(res => { });
    }
    this.Indicator.items[this.Indicator.stateParamsId].model.URL = this.ngModel
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(state => {
      return {
        indicator: this.Indicator,
        updatedFrom: "[WebsitetypeComponent] sendvalue",
        isloaded: true
      }
    });
    this.indicatorStore.setLoading(false);
  }

}
