import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { Quickbpclass } from 'app/interface/quickbp';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { ComponentStore } from 'app/state/component.state';

@Component({
  selector: 'app-addresskeydropdown',
  templateUrl: './addresskeydropdown.component.html',
  styleUrls: ['./addresskeydropdown.component.scss']
})
export class AddresskeydropdownComponent implements OnInit {

  sharedData: Quickbpclass;
  addressList = [];
  selectedAddressKey: string = '';
  options: any;
  titleContent: any;
  constructor(private indicatorQuery: IndicatorQuery,
    private componentStore: ComponentStore,
    private indicatorStore: IndicatorStore,
    private cdr: ChangeDetectorRef) { }
  ngOnInit() {
    this.options =this['layoutNode'].options;
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    });
   // this.constructAddress();
    if (this.sharedData /*&& !this.sharedData[0]*/) {
      this.titleContent = this.sharedData['profiles'][this.sharedData.stateParamsId]['schema'][0]['properties']['CCContact']['title'];
      if (this.sharedData['account'] && this.sharedData['account']['contacts'] !== undefined) {
        var contacts = this.sharedData['account']['contacts'];
        if (this.sharedData && contacts && contacts.length > 0) {
          var showIndexes = [];
          var formattedAddresses = [];
          var formattedAddressesStringArray = [];
          for (var i = 0; i < contacts.length; i++) {
            if (contacts[i].address.email !== null &&
              contacts[i].address.street1 !== null &&
              contacts[i].address.city !== null &&
              contacts[i].address.state !== null &&
              contacts[i].address.zip !== null) {

              var address = "";
              address += contacts[i].address.street1.replace(/\s/g, '').trim().toLowerCase();
              address += " - " + contacts[i].address.city.replace(/\s/g, '').trim().toLowerCase();
              address += " - " + contacts[i].address.state.replace(/\s/g, '').trim().toLowerCase();
              address += " - " + contacts[i].address.zip.split("-")[0].trim().replace(/\s/g, '').trim().toLowerCase();
              if (formattedAddresses.indexOf(address) === -1) {
                showIndexes.push(i);
                formattedAddresses.push(address);
              }
            }
          }

          formattedAddresses = [];
          for (var j = 0; j < showIndexes.length; j++) {
            formattedAddressesStringArray[j] = this.selectLabel(contacts[showIndexes[j]]);
            contacts[showIndexes[j]]['addressformat'] = formattedAddressesStringArray[j];
            formattedAddresses.push(contacts[showIndexes[j]]);
          }
          this.addressList = formattedAddresses;
        }
      }
    } else {

    }
  }

  selectLabel = function (item) {
    var label = "";
    if (item.address.street1 !== null)
      label += item.address.street1 + " " + "-" + " ";
    if (item.address.city !== null)
      label += item.address.city + " " + "-" + " ";
    if (item.address.state !== null)
      label += item.address.state + " " + "-" + " ";
    if (item.address.zip !== null)
      label += item.address.zip.split("-")[0]
    return label;
  };

  changedValue(values) {
    // this.selectedAddressKey =values.addressformat; // Sentry issue fix Moved this to if
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));

    if (values) {
      this.selectedAddressKey = values.addressformat; // Sentry issue fix
     // modelData["BusinessPhone"] = values ? values.phone : '';
      modelData["BusinessAddress1"] = values ? values.address.street1 : '';
      modelData["BusinessAddressCity"] = values ? values.address.city : '';
      modelData["BusinessAddressState"] = values ? values.address.state : '';
      this.splitAddressZip(values.address.zip);

      let x = JSON.parse(JSON.stringify(this.sharedData));
      x['profiles'][this.sharedData.stateParamsId].model = modelData;
      this.indicatorStore.update(state =>{
        return{
          indicator: x,
          updatedFrom: "[AddresskeydropdownComponent] changedValue"
        }
      });
      let addresses = values.addressformat.split(" - ");
      this.componentStore.update(() => {
        return {
          componentValue: addresses
        }
      });
    }
    this.ngAfterViewChecked();

  }


  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();
}

  splitAddressZip(zipField) {
    let modelData =  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][this.sharedData.stateParamsId].model));
    if (zipField) {
      var zipStr = zipField;
      var zipArr = zipStr.split("-");
      modelData.BusinessAddressZip = { "zip": "", "zip4": "" };
      modelData.BusinessAddressZip.zip = zipArr[0];
      if (zipArr.length > 1)
      modelData.BusinessAddressZip.zip4 = zipArr[1];
    } else {
      modelData.BusinessAddressZip = { "zip": "", "zip4": "" };
    }
    let x = JSON.parse(JSON.stringify(this.sharedData));
    x['profiles'][this.sharedData.stateParamsId].model = modelData;
    this.indicatorStore.update(state =>{
      return{
        indicator: x,
        updatedFrom: "[AddresskeydropdownComponent] splitAddressZip"
      }
    })
  }
  /* constructAddress() {
    let address='';
    let constructData= this.sharedData['profiles'][this.sharedData.stateParamsId];
    if(constructData) {
      address = constructData.model["BusinessAddress1"];
      address = address + ' - ' + constructData.model["BusinessAddressCity"];
      address = address + ' - ' + constructData.model.BusinessAddressZip["zip"];
      if(constructData.model.BusinessAddressZip["zip4"]) {
        address = address + ' - ' + constructData.model.BusinessAddressZip["zip4"]
      }
      address ? this.selectedAddressKey = address : '';
    }
  } */
}
