import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { LoggerService } from '@app/service/logger/logger.service';
import { UtilsService } from '@app/service/utils/utils.service';
import { ValidateService } from '@app/service/validate/validate.service';
import { IndicatorQuery } from 'app/state/query';

@Component({
  selector: 'app-website-url',
  templateUrl: './website-url.component.html',
  styleUrls: ['./website-url.component.scss']
})
export class WebsiteUrlComponent implements OnInit {
  invalidUrl :boolean = false;
  websiteUrlModel:any;
  indicator;
  schemaObj;
  url:any;
  username:any;
  password:any;
  isRequiredField :boolean = false;
  isReadonly :boolean = false;
  checkDexSite :boolean = false;
  checkIsNew :boolean = false;
  maxLength;
  constructor(
    private indicatorQuery : IndicatorQuery,
    private utils: UtilsService,
    private Validate :ValidateService,
    private logger :LoggerService,
    private cdr:ChangeDetectorRef
  ) { }

  ngOnInit() {//WebsiteUrl
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = JSON.parse(JSON.stringify(data));          
    });  
    this.schemaObj = this.indicator['items'][this.indicator.stateParamsId].schema.properties[this['layoutNode'].name];
    this.isRequiredField = this.schemaObj.required;
    this.isReadonly = this.schemaObj.readonly;
    this.maxLength = this.schemaObj.maxLength;

    if(!this.indicator.items[this.indicator.stateParamsId].model.WebsiteUrl) {
      this.websiteUrlModel = {};
      this.websiteUrlModel['url'] = "";
      this.websiteUrlModel['isDexmediaSite'] = 'N';
      this.websiteUrlModel['isNew']= 'N';
      this.websiteUrlModel['username'] = "";
      this.websiteUrlModel['password'] = "";      
    //  this.websiteUrlModel['invalidUrl'] = false;
    } else {
      this.websiteUrlModel = this.indicator.items[this.indicator.stateParamsId].model.WebsiteUrl;
     // this.websiteUrlModel.invalidUrl = this.websiteUrlModel.invalidUrl ? this.websiteUrlModel.invalidUrl : this.invalidUrl;
      this.url = this.websiteUrlModel.url;
      this.username = this.websiteUrlModel.username;
      this.password = this.websiteUrlModel.password;
    }
    this.websiteUrlModel['isPrimary'] = 'Y';
    if(this.websiteUrlModel.isNew ==="N"){
      this.checkValidUrl(this.websiteUrlModel);
    }
    if(this.websiteUrlModel.url.length === 0 || this.websiteUrlModel.url.length > this.maxLength){
      this.invalidUrl = true;
    }
    this.checkDexSite = this.websiteUrlModel.isDexmediaSite == 'N' ? false : true ;
    this.checkIsNew = this.websiteUrlModel.isNew == 'N' ? false : true ;
    this.ngAfterViewChecked();
   
  }
  ngAfterViewChecked() { 
    if (!(<ViewRef>this.cdr).destroyed)
    this.cdr.detectChanges();
  }
   change(item){
    this.websiteUrlModel = JSON.parse(JSON.stringify(this.websiteUrlModel));
    if(item.length === 0){
      this.websiteUrlModel.url = '';
      this.invalidUrl = true;
    }   
    //this.websiteUrlModel.invalidUrl = true;
    if (item) {
      if (item.length <= this.maxLength && this.utils.validateURL(item,true)) {
        this.invalidUrl = false;

       // this.websiteUrlModel.invalidUrl = this.invalidUrl; //TODO :Confirm if we really need this flag in a model else remove from model
        this.websiteUrlModel.url = item;
        this.indicator.items[this.indicator.stateParamsId].model.WebsiteUrl = this.websiteUrlModel;
        this.checkValidUrl(this.websiteUrlModel);
      }else{
        this.websiteUrlModel.url ='';
        this.invalidUrl = true;
      }
    } 
   
      this.websiteUrlModel.password =item.password ;

      this.updateStore();
     }
     usernameChange(item){
      if (item) {
        this.websiteUrlModel = JSON.parse(JSON.stringify(this.websiteUrlModel));
        this.websiteUrlModel.username =item;    
        this.updateStore();
      }
     }
     passwordChange(item){
      if (item) {
        this.websiteUrlModel = JSON.parse(JSON.stringify(this.websiteUrlModel));
        this.websiteUrlModel.password =item;    
        this.updateStore();
      } 
     }
     changeDexsite(flag){
      this.websiteUrlModel = JSON.parse(JSON.stringify( this.websiteUrlModel));
      this.websiteUrlModel.isDexmediaSite = (flag == false) ? 'N' :'Y';
      this.updateStore();
     }
     changeIsNew(flag){
      this.websiteUrlModel = JSON.parse(JSON.stringify( this.websiteUrlModel));
      this.websiteUrlModel.isNew = (flag == false) ? 'N' :'Y';
      this.checkValidUrl (this.websiteUrlModel);
      this.updateStore();
     }
     updateStore (){
        //update store;
        let  tempObj =JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
      //let  tempObj =JSON.parse(JSON.stringify(this.indicator));
      //tempObj.items[this.indicator.stateParamsId].model.WebsiteUrl = this.websiteUrlModel;
      let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].model));
      tempObj.items[tempObj.stateParamsId].counter = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].counter));
      tempObj["orderPercent"] = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator["orderPercent"]));
      this.indicatorQuery.updateModel(this['layoutNode'].name, this.websiteUrlModel,  modelData, tempObj, '[WebsiteUrlComponent] updateStore');
      
     }
  
  checkValidUrl (item){
    this.websiteUrlModel = JSON.parse(JSON.stringify( this.websiteUrlModel));
    if(item.isNew === "N" && item.url !=="" && this.utils.validateURL(item.url,false)){
        this.Validate.validateurl(item.url).subscribe((data) =>{
          this.websiteUrlModel = JSON.parse(JSON.stringify( this.websiteUrlModel));
            if(data.hasWarningorError === "true"){
              this.invalidUrl = true;
              this.websiteUrlModel.url ='';
             // this.websiteUrlModel.invalidUrl =this.invalidUrl;
                let errorObject = data.esbServiceFault;
                let errorCode = errorObject.sourceSystem;
                let errorDesc = errorObject.exceptionDetail[0].errorDescription;
                let errorMsg = "<strong>Transaction Id : " + data.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                this.logger.logError(errorMsg);

            }
        });
    }else if(!this.utils.validateURL(item.url,false)){
      this.invalidUrl = true;
      this.websiteUrlModel.url ='';
      //this.websiteUrlModel.invalidUrl = this.invalidUrl;
    }else{
      this.invalidUrl = false;
     // this.websiteUrlModel.invalidUrl = this.invalidUrl;

    }
};      

}
