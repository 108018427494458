import { Component, OnInit } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { BillingService } from '@app/service/billing/billing.service';
import { FooterService } from '../../shared/components/footer/footer.service';
import  * as $ from 'jquery';
import Tabulator from 'tabulator-tables';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '@app/service/utils/utils.service';

var currentObj;
@Component({
  selector: 'app-billing-accounts',
  templateUrl: './billing-accounts.component.html',
  styleUrls: ['./billing-accounts.component.scss']
})

export class BillingAccountsComponent implements OnInit {

  model: string;
  indicatorValue: any;
  billingMainGridData: any =[];
  router: any;
  billingTable:any;
  billingMain: any;


  constructor(private footerService: FooterService,private indicatorQuery: IndicatorQuery, private billing: BillingService,private spinnerService :NgxSpinnerService, private utils ?: UtilsService ) {
     currentObj = this;
   }

  ngOnInit() {
    this.constructModel();
    this.preparedData();
  }

  private preparedData() {
    this.spinnerService.show();
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
       let billingData;
       let billingType;
       let itemObj = this.indicatorValue.billing && this.indicatorValue.billing.billing ? this.indicatorValue.billing.billing : [];
       itemObj.forEach((element,ind) => {
         if(element && element.digitalAdvPayInd.toUpperCase() === "N" && element.digitalBcBillerInd.toUpperCase() === "N"){
           billingType = "Invoice"
           billingType = billingType + (element.digitalItemInd  && element.digitalItemInd.toUpperCase() =='Y' ? '-Digital Allowed' : '-Digital Not Allowed');
         }else if(element && element.digitalAdvPayInd.toUpperCase() === "Y" && element.digitalBcBillerInd.toUpperCase() === "N"){
           billingType = "MAP"
         }else if(element && element.digitalAdvPayInd.toUpperCase() === "N" && element.digitalBcBillerInd.toUpperCase() === "Y"){
           billingType = "CC";
         }else{
           billingType = "MAP";
         }
         billingData = {
           billingAccountId: element.billingAccountId,
           accountName: element.billingName,
          //  digitalAccountIndicator: element.digitalItemInd,//element.digitalAdvPayInd,
          //  billingAddress: element.billingAddress.streetAddress +" - "+ element.billingAddress.cityName +" - "+ element.billingAddress.stateAbbr + " - " +element.billingAddress.zip5+ " - " +element.billingAddress.zip4,
          billingAddress: element.billingAddress.streetAddress + " - " +
          element.billingAddress.cityName + " - " +
          element.billingAddress.stateAbbr + " - " +
          (element.billingAddress.zip5 || '') +
          (element.billingAddress.zip4 ? " - " + element.billingAddress.zip4.split("-")[0].trim() : '') + " - " +
          this.indicatorValue.account.address.country,

          //  billingType: billingType,
           billingType: element.digitalItemInd + " - " + billingType,
          //  phone:"(" + element.btn.telephoneType.NPA + ") " +
          //    element.btn.telephoneType.COP + "-" +
          //    element.btn.telephoneType.LINE,
          phone: element.btn,
           // phone: (element.btn.telephoneType.COP) + element.btn.telephoneType.LINE + element.btn.telephoneType.NPA,
          //  billingAmount:element.businessEntity+' - $'+element.lastBilledAmount,
           billingAmount:'$'+ this.utils.showtwoDecimalDigit(element.lastBilledAmount),
           /* lastBill: element.businessEntity, */
           address: element.billingAddress.streetAddress,
           zip: element.billingAddress.zip4?  element.billingAddress.zip5 + "-" + element.billingAddress.zip4 :element.billingAddress.zip5,
           city:  element.billingAddress.cityName,
           streetaddress: element.billingAddress.streetAddress,
           state: element.billingAddress.stateAbbr,
          //  cellular:"(" + element.cellularNum.telephoneType.NPA + ") " +
          //    element.cellularNum.telephoneType.COP + "-" +
          //    element.cellularNum.telephoneType.LINE,
          cellular: element.cellularNum,
          emailAddress: element.emailAddress,
          attn: element.attn
         }
         this.billingMainGridData.push(billingData);
       });
       this.billingDraw();
       this.footerService.changeTab("billingMain");


   })
    this.spinnerService.hide();
  }
  private billingDraw() {
    this.billingTable = new Tabulator("#BillingTable",{
      height : 500,
      width: '100%',
      data: this.billingMainGridData,
      dataTree: true,
      dataTreeStartExpanded:true,
      layout: "fitColumns",
      columns: this.billingMain,
      movableColumns: true,
      tooltips:true,
      placeholder: 'No Data Available...',
      pagination:"local",
      paginationSize:15,
      paginationSizeSelector:[15, 25, 35],
      columnMinWidth:10,
      dataLoaded: function(data) {
        var el = document.getElementById("total_count");
        if(data.length > 0) {
          el.innerHTML ='Total Count : '+ data.length;
        } else {
          el.innerHTML='';
        }
    }
    });
  }

  private constructModel(){
    this.billingMain = [
      {title:"BILLING ACCOUNT ID",field: "billingAccountId",width:'15%',cssClass:'tabulator-custom-title-Color',
        cellClick: function(e, cell)
        {
          currentObj.footerService.matDialogFunction(cell,null,"UPDATE_BILLING_BY_ITEM_ID-BILLINGPAGE").subscribe((data) => {
            currentObj.billingMainGridData =[];
            currentObj.ngOnInit();
          });
        }},
      {title: "BILLING COMPANY",field:"accountName",width:'15%'},
      {title: "BILLING ADDRESS",field:"billingAddress",width:'17%'},
      {title: "BILLING PHONE NUMBER",field:"phone",width:'18%'},
      {title: "BILLING TYPE/DIGITAL INDICATOR",field:"billingType",width:'17%'},
      // {title: "DIGITAL INDICATOR",field:"digitalAccountIndicator",width:'13%'},
      {title: "BILLING AMOUNT/LAST BILL ",field:"billingAmount",width:'18%'},
     /*  {title: "Last Bill",field:"lastBill",width: 100} */
    ];
  }

}
