import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
//import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import { NgxSpinnerService } from 'ngx-spinner';
import { Quickbpclass } from 'app/interface/quickbp';

import { LoggerService } from '@app/service/logger/logger.service';
import { CouponService } from '@app/service/coupon/coupon.service';
import { CouponSharingService } from '@app/service/coupon/couponsharing.service';
import { MatDialog } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-couponlink',
  templateUrl: './couponlink.component.html',
  styleUrls: ['./couponlink.component.scss']
})
export class CouponlinkComponent implements OnInit {

  isTitle: boolean = false;
  showUpdate: boolean = true;
  title: string;
  caption: string;
  fullText: string;
  offerStart: Date;
  offerEnd: Date;
  smallPrint: string;
  couponValue: string;
  offerCode: string;
  couponObject = {}
  index: number;
  coupons = [];
  closeResult: string = '';
  sharedData: Quickbpclass;
  delCoupon: {};
  couponId: string;
  dialogRef: any;
  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore,
    private Logger: LoggerService,
    private _coupon: CouponService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponService: CouponSharingService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService
    ) {
    
   }

  ngOnInit() {
    this.coupons = [];
    this.couponService.getcouponObj().subscribe(result =>{
      this.coupons = result;
    })
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = {...data};
    })
  }
  open(content) {
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',windowClass:'update-coupn-dialog' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    // if(content == "contentDelete"){
    // this.dialogRef = this.dialog.open(content, { disableClose: true, width: '500px', panelClass: 'delete-coupon-modal'});
    // } else{
      this.dialogRef = this.dialog.open(content, { disableClose: true, width: '800px', panelClass: 'coupon-modal'});
    // }
    this.dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
  }
  openDelete(content){
    this.dialogRef = this.dialog.open(content, { disableClose: true, width: '500px', panelClass: 'delete-coupon-modal'});
    this.dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  close(){
    this.dialogRef.close();
  }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  updateCoupen(coupon, index){
    this.title = coupon.title;
    this.caption = coupon.caption;
    this.fullText = coupon.fullText;
    this.offerStart = coupon.offerStart;
    this.offerEnd = coupon.offerEnd;
    this.smallPrint = coupon.smallPrint;
    this.couponValue = coupon.couponValue;
    this.offerCode = coupon.offerCode;
    this.index = index;
    if(coupon.couponId) this.couponId = coupon.couponId
    else this.couponId = null;
  }

  openDialog(coupon, index){
    this.index = index;
    this.delCoupon = coupon;    
  }

  deleteCoupon() {
    // this.sharedData.loadingText = 'Deleting Coupons...';
    // this.indicatorStore.update(state =>{
    //   return{
    //     indicator: this.sharedData
    //   }
    // })
    if (this.delCoupon["couponId"]) {
      this.spinnerService.show();
      this._coupon.delete(this.delCoupon["couponId"]).subscribe(res => {
        if (res.hasWarningOrError === "false") {
          this.coupons.splice(this.index, 1);
         this.spinnerService.hide();
          if (!(<ViewRef>this.changeDetectorRef).destroyed)
            this.changeDetectorRef.detectChanges();
          this.Logger.logSuccess("Message: Your Coupon is deleted now ");
        } else if (res.hasWarningOrError === "true") {
         this.spinnerService.hide();
          if (res.statusCode === 200) {
            this.Logger.logError("Status Code: 200" + "</br>Message: No match found");
          } else {
            this.Logger.logError("Status Code: 500" + "</br>Message: No Match found.....");
          }
        }
      });
      this.spinnerService.hide();
    } else {
      this.coupons.splice(this.index, 1);
    }
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"] = this.coupons;
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][ this.sharedData.stateParamsId].model))
    this.indicatorQuery.updateBPModel("coupons",this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"],modelData, this.sharedData, '[CouponlinkComponent] deleteCoupon');
  
    // this.indicatorStore.update(state =>{
    //   return{
    //     indicator: this.sharedData
    //   }
    // })
    if (!(<ViewRef>this.changeDetectorRef).destroyed)
      this.changeDetectorRef.detectChanges();

  }
    
  save() {
    if (!this.title || !this.offerStart || !this.offerEnd) {
      this.isTitle = true;
      return;
    } else {
      this.isTitle = false;
    }
    var dstarttimestamp;
    var dendtimestamp;

    if(typeof(this.offerStart) != 'string'){
      dstarttimestamp = (this.offerStart).toISOString();
    } else{
      dstarttimestamp = this.offerStart;
    }
    if(typeof(this.offerEnd) != 'string'){
      dendtimestamp = (this.offerEnd).toISOString();
    } else{
      dendtimestamp = this.offerEnd;
    }

    var modifiedOntimestamp = new Date();
    
    this.couponObject = {
      title: this.title,
      caption: this.caption,
      fullText: this.fullText,
      offerStart: dstarttimestamp,
      offerEnd: dendtimestamp,
      smallPrint: this.smallPrint,
      couponValue: this.couponValue,
      offerCode: this.offerCode,
      couponId: this.couponId,
      "imageUrl": "",
      "template": "default",
      "modifiedOn": modifiedOntimestamp
    }
    this.coupons[this.index] = this.couponObject;
    this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"] = this.coupons;
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['profiles'][ this.sharedData.stateParamsId].model))
    this.indicatorQuery.updateBPModel("coupons",this.sharedData['profiles'][this.sharedData.stateParamsId].model["coupons"],modelData, this.sharedData, '[CouponlinkComponent] save');
  
    // this.indicatorStore.update(state =>{
    //   return{
    //     indicator: this.sharedData
    //   }
    // })
    if (!(<ViewRef>this.changeDetectorRef).destroyed)
      this.changeDetectorRef.detectChanges();

    this.close();
  };

}
