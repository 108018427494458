import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UtilsService } from '@app/service/utils/utils.service';
import { CampaignService } from '@app/service/campaign/campaign.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { AccountService } from '@app/service/account/account.service';
import { CallService } from "@app/service/call/call.service";
import { IndicatorQuery } from 'app/state/query';
import { CampaignSharedService } from '../campaign/campaign.service';
import { CampaignComponent } from '../campaign/campaign.component';
import { ComponentStore } from 'app/state/component.state';
import { IndicatorStore } from 'app/state/store';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';

@Component({
    selector: 'app-campaign-account',
    templateUrl: './campaign-account.component.html',
    styleUrls: ['./campaign-account.component.scss']
})
export class CampaignAccountComponent implements OnInit {

    renewal = false;
    enterpriseID: any = "";
    activities: any = [];
    readonly: any = "";
    campaigns: any = [];
    ngModel :any= {
    };
    AccountID = "SEM Account ID";
    AccountName = "SEM Account Name";
    Indicator: any;
    isItemSelected: boolean
    modelValue: string;

    constructor(private IndicatorQuery: IndicatorQuery,
        private utils: UtilsService,
        private campaignService: CampaignService,
        private logger: LoggerService,
        private account: AccountService,
        private cdr: ChangeDetectorRef,
        private campService: CampaignSharedService,
        private componentStore: ComponentStore,
        private indicatorStore: IndicatorStore,
        private indicatorService: IndicatorService,
        private matDialog: MatDialog,
        private Call: CallService,
        private spinnerService: NgxSpinnerService) { }

    ngOnInit() {
        this.IndicatorQuery.getIndicator().subscribe(data => {
            this.Indicator = JSON.parse(JSON.stringify(data))
        })
        this.campaignAccountInit(this.ngModel)
        if (this.Indicator.items[this.Indicator.stateParamsId].model.AccountId) {
            this.ngModel['accountId'] = this.Indicator.items[this.Indicator.stateParamsId].model.AccountId['accountId'] || this.Indicator.items[this.Indicator.stateParamsId].model.AccountId['AccountId'];
            this.ngModel['accountName'] = this.Indicator.items[this.Indicator.stateParamsId].model.AccountId['accountName']  ||  this.Indicator.items[this.Indicator.stateParamsId].model.AccountId['AccountName'];
        }
        if(!this.ngModel['accountId'] ){
            this.ngModel['accountId'] = "";
            this.ngModel['accountName'] = "";
        }

        for (let i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].form.length; i++) {
            if (this.Indicator.items[this.Indicator.stateParamsId].form[i].type === 'campaignaccount') {
                this.readonly = this.Indicator.items[this.Indicator.stateParamsId].form[i].readonly;
                break;
            }
        }

        if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === "Renew") {
            this.renewal = true;
        };

        this.spinnerService.show();
        // Get the enterprise accountID
        this.account.getMe().subscribe(accounts => {
            this.spinnerService.show();
            if (!accounts[0]) {
                this.enterpriseID = accounts.enterpriseAccountId;
                if (this.enterpriseID !== undefined) {
                    var productCode = (this.Indicator.items[this.Indicator.stateParamsId].order.productCode === "SEM" || this.Indicator.items[this.Indicator.stateParamsId].order.productCode === "SEM Kicker") ? "SEM Plus Standard" : "SEM Plus Premium";
                    this.campaignService.getCancelled(this.enterpriseID, productCode)
                        .subscribe(data => {
                            if(data){
                            if ((Object.keys(this.ngModel).length === 0) && data.accounts !== undefined || this.ngModel == null) {
                                if (data.hasWarningOrError === "true") {
                                    this.collectErrorMessage(data);
                                }
                            }

                            if (data.accounts) {
                                this.activities = data.accounts.account;
                            } else {
                                let error = [
                                    {
                                        "accountId": "No AccountID associated with EnterpriseID",
                                        "accountName": "No Account associated with EnterpriseID"
                                    }
                                ];

                                this.activities = error;
                            }
                                if (!(<ViewRef>this.cdr).destroyed)
                                    this.cdr.detectChanges();
                              this.spinnerService.hide();
                        }
                        }, error => {
                            this.spinnerService.hide();
                        });
                } else {
                    this.activities = [];
                };

            } else {
                //console.log("Salesforce error getting accounts :", accounts[0]);
            }
        },err => {
            //console.log('Error in account.get me campaign account',err);
        });
    }

    convertKeysToCamelCase(obj) {
        var newObj = {};
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                var camelKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObj[camelKey] = obj[key];
            }

        }
        return newObj;
    };

    async changedValue(acctId) {
        this.IndicatorQuery.getIndicator().subscribe((res) => {
            this.Indicator = { ...res };
          });
        this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
        let item = {
            accountId: acctId,
            accountName: ''
        }

        //console.log('acctId is'+acctId)
        //For first time loading.
        if (acctId) {
            this.isItemSelected = true;
            for (let account of this.activities) {
                if (account.accountId === item.accountId) {
                    this.ngModel['accountName'] = account.accountName
                    item.accountName = account.accountName
                    break;
                }
            }
            this.Indicator.items[this.Indicator.stateParamsId].model['AccountId'] = JSON.parse(JSON.stringify(this.ngModel))
           } else {
            this.isItemSelected = false
            this.ngModel['accountName'] = ''
            item = null
        }
        if (item != null && item.accountId != null && ((this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"] != null && this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"].length > 0) || this.isItemSelected)) {
            this.Indicator.items[this.Indicator.stateParamsId].model['AccountId'] = JSON.parse(JSON.stringify(this.ngModel))
            this.spinnerService.show();
            this.campaignService.getData(item.accountId)
                .subscribe(data => {
                    //console.log('campaign service data'+JSON.stringify(data))
                    this.spinnerService.hide();
                    if (data.hasWarningOrError !== "true") {
                           // console.log('inside account false')
                            // enable call tracking dropdown when user changes SEM AccountId GEP-2777
                            //Add or Renew - if Vision has tracking lines then show the tracking lines in CA and dropdown should be disabled with value Yes
                            //if there are no tracking lines in Vision, then dropdown will be No in CA and enabled
                            if (data.TrackingLines && data.TrackingLines instanceof Array && data.TrackingLines.length > 0 && item != null && this.Indicator.items[this.Indicator.stateParamsId].order.statusCode < 6000) {
                                //console.log('inside has tracking lines')
                                this.Indicator.items[this.Indicator.stateParamsId].model.modelValue = "Yes";
                                this.modelValue = this.Indicator.items[this.Indicator.stateParamsId].model.modelValue;

                                let newTrackingArray = this.formCallTrackingModel(data.TrackingLines)
                                let modelArray = this.Indicator.items[this.Indicator.stateParamsId].model.CallTracking
                                modelArray.push.apply(modelArray, newTrackingArray)

                                let uniqueCTs = []
                                modelArray.map(x => uniqueCTs.filter(a => a.TrackingId == x.TrackingId).length > 0 ? null : uniqueCTs.push(x));
                                //console.log('uniquelines '+JSON.stringify(uniqueCTs))
                                this.Indicator.items[this.Indicator.stateParamsId].model.CallTracking = uniqueCTs;

                                let callTrackingArray = this.Indicator.items[this.Indicator.stateParamsId].calltracking
                                callTrackingArray.push.apply(callTrackingArray, data.TrackingLines)

                                //get unique CTS
                                let uniqueLines = []
                                callTrackingArray.map(x => uniqueLines.filter(a => a.callTrackingId == x.callTrackingId).length > 0 ? null : uniqueLines.push(x));
                               // console.log('uniquelines 2'+JSON.stringify(uniqueLines))
                                this.Indicator.items[this.Indicator.stateParamsId].calltracking = uniqueLines;

                               // console.log('call tracking length on change of SEM account'+JSON.stringify(this.Indicator.items[this.Indicator.stateParamsId].calltracking))
                                // this.storeUpdate(upadatedName);
                            }
                            else if (data.TrackingLines && !(data.TrackingLines instanceof Array) && this.Indicator.items[this.Indicator.stateParamsId].order.statusCode < 6000) {
                                //console.log('inside tracking lines not array')
                                this.Indicator.items[this.Indicator.stateParamsId].schema.properties.CallTracking.readonly = false;
                                this.Indicator.items[this.Indicator.stateParamsId].model.modelValue = "No";
                                this.Indicator.items[this.Indicator.stateParamsId].schema.properties.CallTracking.readonly = false;
                                this.modelValue = "No"
                                // this.storeUpdate(upadatedName);// set call tracking to No if no call tracking is available for renewal item.
                            }
                            // FUL 20568 changes
                            /*if(this.Indicator.items[this.Indicator.stateParamsId].calltracking.length === 0 && this.Indicator.items[this.Indicator.stateParamsId].order.actionCode.toLowerCase() === "add" && this.Indicator.items[this.Indicator.stateParamsId].order.actionType.toLowerCase() === "new"){
                                if (data.TrackingLines && data.TrackingLines instanceof Array && data.TrackingLines.length > 0) {
                                    this.Indicator.items[this.Indicator.stateParamsId].model.CallTracking = data.TrackingLines
                                    this.Indicator.items[this.Indicator.stateParamsId].calltracking = data.TrackingLines
                                }
                            }*/
                            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"] = data.ActiveAndPausedCampaignList;
                        //Modify the campaign details as per table view
                        for (var i = 0; i < this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"].length; i++) {
                            //DE31493 BPs not auto displaying on Campaigns (SEMP) not allowing the order to reach 100%
                            if (!this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].hasOwnProperty('ListingId')) {
                                if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode != "Add" || this.Indicator.items[this.Indicator.stateParamsId].order.actionType != "New" || this.Indicator.items[this.Indicator.stateParamsId].order.actionCode != "Renew") {
                                    if (this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignType.toUpperCase() === "LOCAL") {
                                        if (this.Indicator.items[this.Indicator.stateParamsId].data.products[0] && this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList[i]) {
                                            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["ListingId"] = this.Indicator.items[this.Indicator.stateParamsId].data.products[0].CampaignList[i]['ListingId'];
                                        } else {
                                            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["ListingId"] = this.Indicator.items[this.Indicator.stateParamsId].model.BusinessProfile;
                                        }
                                    }
                                }
                            }
                            //DE31493 End
                            //As corrupt data comes server and no campaign type is sent we assume that when DisplayAddress field is there its a local campaign else its a National campaign
                            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i] = this.utils.convertBadKeys(this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]);

                            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]['imageopen'] = "http://cdn.flaticon.com/png/256/70687.png";
                            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]['imagecopy'] = "http://cdn.flaticon.com/png/256/70704.png";
                            if (this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignType && this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignType.toUpperCase() === 'NATIONAL') {
                                this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["CampaignTypeText"] = 'MSN';
                                this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["BPText"] = '';
                            } else {
                                this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["CampaignTypeText"] = 'L';
                                //Campaign Business Profile data begin
                                this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["BPText"] = this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignTitle + ',' + this.utils.formatPhoneNumber(this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignPhone) + ',' + this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].Address1 + ' ' + this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignAddressCity + ' ' + this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignAddressState + ' ' + this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CampaignAddressZip;
                            }
                            if (this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].GeoList.length > 0) {
                                var GeoListText = ""; // result variable
                                for (var j = 0; j < this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].GeoList.length; j++) {
                                    GeoListText += this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].GeoList[j].GeoName + ",";
                                }
                                //remove the extra comma at the end, using a regex
                                GeoListText = GeoListText.replace(/,(?=[^,]*$)/, '');
                                this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["GeoListText"] = GeoListText;
                            }
                            if (this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CategoryList.length > 0) {
                                var CategoryListText = ""; // result variable
                                for (var j = 0; j < this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CategoryList.length; j++) {
                                    CategoryListText += this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i].CategoryList[j].CategoryName + ",";
                                }
                                //remove the extra comma at the end, using a regex
                                CategoryListText = CategoryListText.replace(/,(?=[^,]*$)/, '');
                                this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"][i]["CategoryListText"] = CategoryListText;
                            }
                        }

                    } else {
                        this.collectErrorMessage(data);
                    }
                    this.Indicator.items[this.Indicator.stateParamsId].model['AccountId'] = JSON.parse(JSON.stringify(this.ngModel))
                    this.storeUpdate(this.Indicator)
                }, error => {
                    this.spinnerService.hide();
                    //console.log("message is ", error);
                })
        } else if (!this.isItemSelected) {
            this.spinnerService.show();
            let ctArray = []
            await this.Call.getByItemId(this.Indicator.stateParamsId)
            .then(response => {
                ctArray = this.buildCTArray(response)
             }
              , error => {
                this.logger.logError("</strong>Error Getting Call tracking  Details :</strong><br>" + JSON.stringify(error))
              });
              this.Indicator.items[this.Indicator.stateParamsId].calltracking = ctArray;

            let newTrackingArray = this.formCallTrackingModel(ctArray)
            this.Indicator.items[this.Indicator.stateParamsId].model.CallTracking = newTrackingArray;

            this.Indicator.items[this.Indicator.stateParamsId].model['AccountId'] = JSON.parse(JSON.stringify(this.ngModel))
            this.campaigns = this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"];
            this.Indicator.items[this.Indicator.stateParamsId].model["CampaignType"] = [];
            this.Indicator.items[this.Indicator.stateParamsId].model.modelValue = "Yes";
            this.Indicator.items[this.Indicator.stateParamsId].schema.properties.CallTracking.readonly = true;
            this.modelValue = this.Indicator.items[this.Indicator.stateParamsId].model.modelValue;
            this.spinnerService.hide();
            this.storeUpdate(this.Indicator)
            //this.Indicator.items[this.Indicator.stateParamsId].schema.properties.CallTracking.readonly = true;
        }
        //-----Below code added for handling deletion of BPs in the left nav ------/
        if (this.campaigns) {
            for (let n in this.campaigns) {
                var tempListingId = this.campaigns[n].ListingId;
                var checkAssociatedBPFlag = false;
                for (var k in this.Indicator.items) {
                    if (this.Indicator.items[k].model.BusinessProfile === tempListingId) {
                        checkAssociatedBPFlag = true;
                        break;
                    }
                    if (this.Indicator.items[k].model.CampaignType) {
                        for (let m in this.Indicator.items[k].model.CampaignType) {
                            if (this.Indicator.items[k].model.CampaignType[m].ListingId === tempListingId) {
                                checkAssociatedBPFlag = true;
                                break;
                            }
                        }
                    }

                }

                if (checkAssociatedBPFlag === false) {
                    this.Indicator = JSON.parse(JSON.stringify(this.Indicator))
                    this.indicatorService.removeProfileById(this.Indicator, tempListingId);
                    this.IndicatorQuery.updateProfilesListingId(this.Indicator, '[CampaignAccountComponent] changedValue');
                }

            }
        }
        //-----Above code added for handling deletion of BPs in the left nav ------/
    };

    collectErrorMessage(response) {
        if (response.esbServiceFault) {
            var errorCode = response.esbServiceFault.exceptionDetail[0].errorCode;
            var errorDesc = response.esbServiceFault.exceptionDetail[0].errorDescription;
        } else {
            var errorCode = response.item[0].detail.code;
            var errorDesc = response.item[0].detail.description;
        }
        this.logger.logError("<strong>" + errorCode + ":</strong><br>" + errorDesc);
    };

    campaignAccountInit(item) {
        if (item === undefined) {
            this.ngModel = {};
        } else if (item.accountId === "" && item.accountName === ""
            && this.Indicator.items[this.Indicator.stateParamsId].data.products
            && this.Indicator.items[this.Indicator.stateParamsId].data.products[0]
            && this.Indicator.items[this.Indicator.stateParamsId].data.products[0].AccountId
            && this.Indicator.items[this.Indicator.stateParamsId].data.products[0].AccountName
            || (this.utils.isObjectEmpty(item) == true)
        ) {
            if (this.Indicator.items[this.Indicator.stateParamsId].data.products[0] && this.Indicator.items[this.Indicator.stateParamsId].data.products[0].AccountId) {
                this.ngModel['accountId'] = this.Indicator.items[this.Indicator.stateParamsId].data.products[0].AccountId;
                this.ngModel['accountName'] = this.Indicator.items[this.Indicator.stateParamsId].data.products[0].AccountName;
            } else {
                this.ngModel['accountId'] ="";
                this.ngModel['accountName']="";
                //Logger.logWarning("Oops! No AccountId for the item found.");
            }

        } else {
            this.ngModel = item;

        }
    };

    storeUpdate(upadatedName){
        //console.log('updatedname before sending to call component'+JSON.stringify(upadatedName.items)+'modelValue'+this.modelValue)
        this.indicatorStore.update((state) => {
            return {
              indicator:upadatedName,
              updatedFrom: "[CampaignAccountComponent] storeUpdate",
              isloaded: true,
            };
        });
        this.componentStore.update(() => {
            return {
              componentValue: this.modelValue,
              semAccount: this.ngModel['accountId']
            }
        });
    }

    ngOnDestroy(){
        this.componentStore.update(() => {
            return {
              componentValue: ''
            }
        });
    }

    formCallTrackingModel(callTrackingArray){
        let cTArray = []
        let source;
        for (let i = 0; i < callTrackingArray.length; i++) {
            let terminationNumber = callTrackingArray[i].terminationNumber;
            let callTrackingId = callTrackingArray[i].callTrackingId;

            if (callTrackingArray[i].distributionType !== null) {
              source = callTrackingArray[i].distributionType;
            } else {
              source = null
            }

            let bodySEM = {
                "TrackingId": callTrackingId,
                "Source": source,
                "DestinationNumber": terminationNumber
              };
              cTArray.push(bodySEM);

          }

          return cTArray
    }

    buildCTArray(calltracking){
        let isItemUnique = true
        let tempCallArr = []
        let itemCTarray = []
        if (calltracking.hasWarningOrError !== "true" && calltracking.trackingLines !== "") {
            let ctLength = calltracking.trackingLines.trackingLine.length;
            for (let j = 0; j < ctLength; j++) {
              isItemUnique = true;
              if (calltracking.trackingLines.trackingLine[j].enterpriseItemId === this.Indicator.stateParamsId) {
                if (tempCallArr.length > 0) {
                  for (let k = 0; k < tempCallArr.length; k++) {
                    if (tempCallArr[k].terminationNumber == calltracking.trackingLines.trackingLine[j].terminationNumber
                      && tempCallArr[k].distributionType && calltracking.trackingLines.trackingLine[j].distributionType
                      && tempCallArr[k].distributionType == calltracking.trackingLines.trackingLine[j].distributionType) {
                      isItemUnique = false;
                      break;
                    }
                  }
                  if (isItemUnique) {
                    itemCTarray.push(calltracking.trackingLines.trackingLine[j]);
                    tempCallArr.push(calltracking.trackingLines.trackingLine[j]);
                  }
                } else {
                  itemCTarray.push(calltracking.trackingLines.trackingLine[j]);
                  tempCallArr.push(calltracking.trackingLines.trackingLine[j]);
                }
              }
            }
          } else if (calltracking.hasWarningOrError === "true" && calltracking.esbServiceFault) {
            itemCTarray = []
          }
        return itemCTarray
    }

}
