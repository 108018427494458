import { Injectable } from '@angular/core';
import { GroupService } from '@app/service/group/group.service';
import { StorageService } from '../storage/storage.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { LoggerService } from '../logger/logger.service';
import { CopysheetService } from '../copysheet/copysheet.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
import { ItemService } from '../item/item.service';
import {CopySheetComponent} from '../../../shared/components/copy-sheet/copy-sheet.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class IagroupHelperService {
  indicator;
  selectedItem;

  constructor(private grouper: GroupService,
    private storageservice :StorageService,
    private requiredfieldcount : RequiredfieldcountService,
    private logger :LoggerService,
    private copysheet: CopysheetService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private  updateIndicatorService :UpdateIndicatorService,
    private itemService : ItemService,
    private spinnerService :NgxSpinnerService
) {

    }
  splitIAGroup(itemId,page?) {
    let indicator = JSON.parse(JSON.stringify(this.indicatorStore.getValue().indicator));
    let pageRef = page ? page :"splitIA" ;
    this.selectedItem = indicator.items[itemId].order;
    let splitData = {
        "consumingSystem": "CA",
        "eAccountId": indicator.account.kgenCustomerId,
        "eItemId": itemId
    };
    this.itemService.update(
      indicator.account,
      indicator.order,
      indicator.items[itemId],
      null,
      itemId,
      pageRef).then( (updateResult)=> {
        this.spinnerService.show();
        this.grouper.splitIAItems(splitData).then((response: any)=> {
          if (response.hasWarningOrError === "false") {

            indicator = JSON.parse(JSON.stringify(indicator));
            let newgroupId = response.groupId;
            let oldgroupId =indicator.items[itemId].data.iaGroupId;
            indicator.items[itemId].data.iaGroupId = newgroupId;
            let activityLogHistoryObj = {
                'displayName':indicator.user.name,
                'email':indicator.user.email,
                'date': new Date().toLocaleString('en-US', {timeZone: 'America/Chicago', hour12:true}),
                'itemId':itemId,
                'itemStatus': indicator.items[itemId].order.status,
                'itemActionCode':indicator.items[itemId].order.actionCode + "-" + indicator.items[itemId].order.actionType,
                'fieldName':'IA Group',
                'fieldOldValue':oldgroupId,
                'fieldNewValue':newgroupId
            }
            this.storageservice.update("itemHistory", itemId, activityLogHistoryObj);
            if ((this.selectedItem.actionCode === 'Add' && (this.selectedItem.actionType === 'New' || this.selectedItem.actionType === 'Update')) || (this.selectedItem.actionCode=== 'Renew' && (this.selectedItem.actionType === 'Update' || this.selectedItem.actionType === 'New' ))) {
              indicator = JSON.parse(JSON.stringify(indicator));
              indicator.items[itemId].copy.copysheet = null;
                indicator.items[itemId].copy.fileType = '';
                indicator.items[itemId].hasCopysheet = false;
                if (indicator.items[itemId].requiresCopysheet) {
                  //indicator.items[itemId].counter++;
                  let stateParamsId = itemId;
                  let tempData = this.requiredfieldcount.getNewItemCount(indicator["items"][stateParamsId].requiredFields, indicator["items"][stateParamsId].model, indicator["items"][stateParamsId].order, indicator["orderPercent"], indicator["items"][stateParamsId].counter, true, indicator["items"][stateParamsId]);
                  this.updateIndicatorService.updateIndictor(itemId, indicator, tempData, indicator.items[itemId].counter);
                }
                this.copysheet.get(itemId + "v" + indicator.items[itemId].order.version)
                    .then((data:any)=>{
                      //indicator.items[itemId].copy = angular.extend(data,indicator.items[itemId].copy);
                      indicator = JSON.parse(JSON.stringify(indicator));
                      indicator.items[itemId].copy = Object.assign(data,indicator.items[itemId].copy);
                        if (data === "" || data.length === 0) {
                          indicator.items[itemId].copy = {};
                          indicator.items[itemId].hasCopysheet = false;
                            if (indicator.items[itemId].requiresCopysheet) {
                            // indicator.items[itemId].counter++;
                            let stateParamsId = itemId;
                            let tempData = this.requiredfieldcount.getNewItemCount(indicator["items"][stateParamsId].requiredFields, indicator["items"][stateParamsId].model, indicator["items"][stateParamsId].order, indicator["orderPercent"], indicator["items"][stateParamsId].counter, true, indicator["items"][stateParamsId]);
                            this.updateIndicatorService.updateIndictor(itemId, indicator, tempData, indicator.items[itemId].counter);
                            }

                        }
                        indicator = JSON.parse(JSON.stringify(indicator));
                        indicator.items[itemId].copy.listing = indicator.listing;

                        if (!indicator.items[itemId].copy.hasOwnProperty('copysheet')) {
                          indicator.items[itemId].copy.copysheet = null;
                          indicator.items[itemId].hasCopysheet = false;
                            if (indicator.items[itemId].requiresCopysheet) {
                            //  indicator.items[itemId].counter++;
                            let stateParamsId = itemId;
                            let tempData = this.requiredfieldcount.getNewItemCount(indicator["items"][stateParamsId].requiredFields, indicator["items"][stateParamsId].model, indicator["items"][stateParamsId].order, indicator["orderPercent"], indicator["items"][stateParamsId].counter, true, indicator["items"][stateParamsId]);
                            this.updateIndicatorService.updateIndictor(itemId, indicator, tempData, indicator.items[itemId].counter);
                            }
                        }
                        this.copysheet.updateCopysheet(itemId + "v" +indicator.items[itemId].order.version, indicator.items[itemId].copy)
                        .then((response:any)=> {
                          // Logger.logSuccess("Successfully Updated Copysheet");
                        },error=> {
                            this.logger.logError("Failed to update copysheet :" +JSON.stringify(error));
                        });
                    });
            }
            this.indicatorStore.update((state) => {
              return {
                indicator:indicator,
                updatedFrom: "[IagroupHelperService] storageservice",
                isloaded: true,
              };
            });
          }else{
            //console.log("Split Item API failed");
          }
          this.spinnerService.hide();
        },error=>{
          let errorObject = error.error.response.esbServiceFault ? error.error.response.esbServiceFault : null;
          if(errorObject){
            let errorCode = errorObject.sourceSystem ? errorObject.sourceSystem : null;
            let errorDesc = errorObject.exceptionDetail[0].errorDescription ? errorObject.exceptionDetail[0].errorDescription : null;
            let errorMsg = "<strong>Transaction : " + error.error.response.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            this.logger.logError("Failed to Split Item from IA Group:" +JSON.stringify(errorMsg));
          }
          this.spinnerService.hide();

        });
      });

  }
  isIAGroupPresent(indicator,itemId){
    let iaGrouppresent = false;
    for (let k in indicator.items) {
        if (k !== itemId && indicator.items[itemId].data.iaGroupId != undefined) {
            if (indicator.items[k].data.iaGroupId === indicator.items[itemId].data.iaGroupId) {
                iaGrouppresent = true;
            }
        }
    }
    return iaGrouppresent;
  };

  copysheetnotify(itemId){

    this.indicatorQuery.getIndicator().subscribe(data => {
      this.indicator = {...data};
    });
    let storageID = itemId + "v" +this.indicator.items[itemId].order.version;

    let upadatedName = JSON.parse(JSON.stringify(this.indicator));
    upadatedName.items[itemId].hasCopysheet = false;
    this.storageservice.updateField("copyz", storageID, { "data.hasCopysheet": false })
    .subscribe(()=>{},err=>{})
      if(upadatedName.items[itemId].order.productCode == 'YPC' ||  this.indicator.items[itemId].order.productType == "Print"){
          if(upadatedName.items[itemId].order.productCode == 'YPC'){
            upadatedName.items[itemId].model.ManageCopysheet = 'Y';
          }else{
            upadatedName.items[itemId].model.ManageCopysheet.copySheetIndicator = 'Y';
          }
          upadatedName.items[itemId].requiresCopysheet =  true;

      }

    this.indicatorStore.update((state) => {
      return {
        indicator:upadatedName,
        updatedFrom: "[IagroupHelperService] copysheetnotify",
        isloaded: true,
      };
    });
    //CopySheetComponent.reTrigger();
  }
}
