import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';


@Injectable({
  providedIn: 'root'
})
export class CampaignschemaService {
  //baseUri:string = '/api/';
  baseUri:string
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService
  ) { }
  get(campaign: any): Observable<any> {
    if(this.Params.params.sforg){
      this.baseUri = '/api/schemanational/Campaign/';
    }else
    {
      this.baseUri = '/api/schema/Campaign/';
    }
    return this.http.get(this.API.get(this.baseUri) + campaign)
    //return $http.get(API.get(vm.PATH) + campaign , { cache : true });
};
}
