import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import { CounterService } from '../counter/counter.service';
import { RequiredfieldcountService } from '../requiredfieldcount/requiredfieldcount.service';
import { StorageService } from '../storage/storage.service';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class CopysheetService {

  constructor(private http: HttpClient, 
    private API: ApiService, 
    private Params: ParamsService,
    private indicatorStore: IndicatorStore,
    private indicatorQuery: IndicatorQuery,
    private counterService: CounterService,
    private requiredfieldcountservice: RequiredfieldcountService,
    private storageService: StorageService,
    private logger: LoggerService,
    ) { }

  PATH = "/api/copy";

  headersObject = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + this.Params.params.token
    }
  }

  get(id) {
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token;   
    return this.http.get(this.API.get(this.PATH) + '/' + id,this.headersObject).toPromise();
  };

  getCopySheets(data, accountId, version,page?) {
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token; 
    if(page) data.page = page; 
    data.sforg = this.Params.params.sforg;
    // let res = this.http.post(this.API.get(this.PATH) + "/" + accountId + "/" + version, data,this.headersObject).toPromise();
    // console.log("copysheet service-getcopysheets:",res);
    return this.http.post(this.API.get(this.PATH) + "/" + accountId + "/" + version, data,this.headersObject).toPromise();
  };

  updateCopysheet(id, data) {
    this.headersObject.headers.Authorization = "Bearer "+ this.Params.params.token; 
    data = JSON.parse(JSON.stringify(data));
    data.sforg = this.Params.params.sforg;
    //Call Copy api to update Copies collection with updated copysheets,
    //attachments, additional instructions, listing and contact
    return this.http.put(this.API.get(this.PATH) + "/" + id, data,this.headersObject).toPromise();
  };

  viewCopySheets(id, name) {
    var fileURL = this.API.get(this.PATH) + "/" + id + "/" + name;
    return fileURL;
  };

  discardCopysheetChanges(indicator){
    indicator.items[indicator.stateParamsId].copy.copysheet = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.copysheetSavePoint
    indicator.items[indicator.stateParamsId].copy.attachments = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.attachments
    // indicator.items[indicator.stateParamsId].actionListObj = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.actionListObj
    indicator.items[indicator.stateParamsId].model.ManageCopysheet = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.ManageCopysheet
    indicator.items[indicator.stateParamsId].hasCopysheet = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.hasCopysheet
    indicator.items[indicator.stateParamsId].isCorrupted = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.isCorrupted
    indicator.items[indicator.stateParamsId].isCoysheetSubmitted = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.isCoysheetSubmitted
    indicator.items[indicator.stateParamsId].generatedCopysheet = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.generatedCopysheet
    indicator.items[indicator.stateParamsId].isCopysheetSaved = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.isCopysheetSaved
    indicator.items[indicator.stateParamsId].needsNewCopysheet = indicator.items[indicator.stateParamsId].model.copySheetSavePoints.needsNewCopysheet

    this.updateCopysheet(indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, indicator.items[indicator.stateParamsId].copy)
    .then(() => { },err=>{
      console.log("Error in updateCopysheet in discarding process ",err)
    }).catch((err) => {
      this.logger.logError("Failed to update copysheet :" + JSON.stringify(err));
    });
    this.storageService.updateField("copyz", indicator.stateParamsId + "v" + indicator.items[indicator.stateParamsId].order.version, 
    {"data.actionListObj": indicator.items[indicator.stateParamsId].model.actionListObj,
     "data.hasCopysheet": indicator.items[indicator.stateParamsId].hasCopysheet,
     "data.isCorrupted": indicator.items[indicator.stateParamsId].isCorrupted,
     "data.isCoysheetSubmitted": indicator.items[indicator.stateParamsId].isCoysheetSubmitted,
     "data.generatedCopysheet": indicator.items[indicator.stateParamsId].generatedCopysheet,
     "data.isCopysheetSaved": indicator.items[indicator.stateParamsId].isCopysheetSaved,
     "data.needsNewCopysheet": indicator.items[indicator.stateParamsId].needsNewCopysheet 
    })
    .subscribe(() => { }, err => { console.log("error in storage updatefiled",err)})
    if (indicator.items[indicator.stateParamsId].requiresCopysheet) {
      let stateParamsId = indicator.stateParamsId;
      let tempData = this.requiredfieldcountservice.getNewItemCount(indicator["items"][stateParamsId].requiredFields, indicator["items"][stateParamsId].model, indicator["items"][stateParamsId].order, indicator["orderPercent"], indicator["items"][stateParamsId].counter, true, indicator["items"][stateParamsId]);
      this.updateIndictor(indicator.stateParamsId, indicator, tempData, indicator.items[indicator.stateParamsId].counter);
      try {
        if (document.getElementById(stateParamsId + 'counter')) {
          if (indicator['items'][stateParamsId].counter == 0) {
            document.getElementById(stateParamsId + 'counter').classList.remove('item-order-error');
          } else if (indicator['items'][stateParamsId].counter != 0) {
            document.getElementById(stateParamsId + 'counter').classList.add('item-order-error');
          }
        }
      } catch (e) {
        console.log(e, " exception in the class counter");
      }
    }
    this.indicatorStore.update(state => {
      return {
        indicator: indicator,
        updatedFrom: "[CopysheetAnnotateComponent] discardCopysheetchanges"
      }
    })
  }
  
  updateIndictor(itemId, data, tempIndicator, currentCount) {
    let counter = [];
    console.log('update Indicator');
    data.orderPercent.currentCount = tempIndicator.tempCurrentCount;
    data.items[itemId].counter = tempIndicator.itemCount;
    data.orderPercent = this.getItemErrorCount(tempIndicator.itemCount, data.orderPercent, currentCount, data.items[itemId].order);
    let percentage = this.requiredfieldcountservice.getPercent(data.orderPercent.currentCount, data.orderPercent.maxCount);
    data.orderPercent.percent = percentage;
    counter.push(data.orderPercent.digitalCount);
    counter.push(data.orderPercent.printCount);
    counter.push(data.orderPercent.bpTotalCount);
    this.counterService.setError(counter);
    this.updatCounterStore(data);
  }
  updatCounterStore(data) {
    this.indicatorStore.setLoading(true);
    this.indicatorStore.update(() => {
      return {
        indicator: data,
        updatedFrom: "[CopysheetAnnotateComponent] updatCounterStore",
        isloaded: true,
      };
    });
    // this.indicatorStore.setLoading(false);
    // this.indicatorQuery.getIndicator().subscribe(data => {
    // indicator = JSON.parse(JSON.stringify(data));
    // });
  }

  getItemErrorCount(itemCount, orderPercent, currentCount, order) {
    let counter = 0;
    let totalItemCount = orderPercent.digitalCount + orderPercent.printCount;
    let productType = order && order.productType ? order.productType : '';
    if (itemCount == 0 && currentCount != 0) {
      counter = totalItemCount == 0 ? 0 : totalItemCount - 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else if (itemCount != 0 && currentCount == 0) {
      counter = totalItemCount + 1;
      orderPercent = this.updateCounter(productType, counter, orderPercent);
    } else {
      //  counter = totalItemCount;
    }
    currentCount = counter;

    return orderPercent;
  }
  updateCounter(productType, counter, orderPercent) {
    if (productType && productType === 'Digital') {
      orderPercent.digitalCount = counter - orderPercent.printCount;
    } else if (productType && productType === 'Print') {
      orderPercent.printCount = counter - orderPercent.digitalCount;
    }
    return orderPercent;
  }

}
