import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    constructor() { }
    private updateSpinner = new Subject<any>();
    private spinnerText = new Subject<any>();
    private isPublish = new Subject<boolean>();
    private isCopySheet = new Subject<boolean>();
   /* obj: any;
    isLoading = new Subject<any>();
    show() {
        this.isLoading.next(this.obj);
    }
    hide() {
        this.isLoading.next(null);
    }
    addData(data) {
        this.obj = data;
    } */
    setSpinner(item: any) {
        this.updateSpinner.next(item);
    }

    getSpinner(): Observable<any> {
        return this.updateSpinner.asObservable();
    }

    setSpinnerText(text){
        this.spinnerText.next(text)
      }
      getSpinnerText(){
        return this.spinnerText.asObservable();
      }

      setPublishSpinner(publishPage){
        this.isPublish.next(publishPage)
      }
      isPublishSpinner(){
        return this.isPublish.asObservable();
      }
      setCopySheetSpinner(copysheet){​​​​​​​​
        this.isCopySheet.next(copysheet);
              }​​​​​​​​
        isCopySheetSpinner(){​​​​​​​​
        return this.isCopySheet.asObservable();
              }​​​​​​​​
}