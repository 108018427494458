import { NgModule,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatDividerModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatPaginatorModule,
  MatTabsModule,
  MatFormFieldModule,
  MatMenuModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatCardModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatOptionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatExpansionModule,
  MatCheckboxModule, 
  MatStepperModule, 
  MatChipsModule
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LeftnavComponent } from './components/leftnav/leftnav.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TabulatorgridDirective } from './tabulatorGrid/tabulatorgrid.directive';
import { Bootstrap4FrameworkModule } from '@ajsf/bootstrap4';
import { ItemComponent } from './components/item/item.component';
import { PrintItemComponent } from './components/print-item/print-item.component';
import { DigitalItemComponent } from './components/digital-item/digital-item.component';
import { AppConfigsService } from '../app.configs';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


//Services --
import { ApiService } from '../core/service/api/api.service';
//import { ParamsService } from '../core/service/params/params.service';
import { IndicatorService } from '../core/service/indicator/indicator.service';
import { RequiredfieldcountService } from '../core/service/requiredfieldcount/requiredfieldcount.service';
import { PhotoService } from '../core/service/photos/photosharing.service';
import { CouponSharingService } from '../core/service/coupon/couponsharing.service';
import { DatasharingService } from '../core/service/datasharing/datasharing.service';
import { StorageService } from '../core/service/storage/storage.service';
import { AccountService } from "../core/service/account/account.service";
import { ConfigurationService } from "../core/service/configuration/configuration.service";
import { ProfileschemaService } from "../core/service/profileschema/profileschema.service";
import { ProfileService } from "../core/service/profile/profile.service";
import { UtilsService } from "../core/service/utils/utils.service";
import { OrderService } from "../core/service/order/order.service";
import { UserService } from "../core/service/user/user.service";
import { TransformerService } from "../core/service/transformer/transformer.service";
import { SalesforceService } from "../core/service/salesforce/salesforce.service";
import { ToastrService, ToastContainerModule } from 'ngx-toastr';
import { CatKeysService } from '../core/service/CatKeys/CatKeys.service';
import { FileService } from '../core/service/file/file.service';
import { LoggerService }from '../core/service/logger/logger.service';
import { ValidateService } from '../core/service/validate/validate.service';
import { CookieService } from 'ngx-cookie-service';
import { ErrorhandlingService }from '../core/service/errorhandling/errorhandling.service';
import { TestComponent } from 'app/test/test.component';
import { BillingnewComponent } from './components/billing-new/billingnew/billingnew.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedRoutingModule } from './shared-routing.module';
import {ProductService} from '../core/service/product/product.service'

import { SelectComponent } from './components/select/select/select.component';
import { StringComponent } from './components/string/string.component';
import { EmailComponent } from './components/email/email.component';
import { PhonenumberfieldComponent } from './components/phonenumberfield/phonenumberfield.component';
import { StateComponent } from './components/state/state.component';
import { AddresskeydropdownComponent } from './components/addresskeydropdown/addresskeydropdown.component';
import { DashboardLoginComponent } from './components/dashboard-login/dashboard-login.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { BillingComponent } from './components/billing/billing.component';
import { DexcardComponent } from './components/dexcard/dexcard.component';
import { UrlComponent } from './components/url/url.component';
import { ZipcodeValidationComponent } from './components/zipcode-validation/zipcode-validation.component';
import { InputComponent } from './components/input/input.component';
import { SpecialtiesitemdirectiveComponent } from './components/specialtiesitemdirective/specialtiesitemdirective.component';
import { MultisearchComponent } from './components/multisearch/multisearch.component';
import { PhotosComponent } from './components/photos/photos.component';
import { BrowsephotoComponent } from './components/photos/browsephoto/browsephoto.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { LogoComponent } from './components/logo/logo.component';
import { ItemcampaignlinkComponent } from './components/itemcampaignlink/itemcampaignlink.component';
import { ButtonComponent } from './components/button/button.component';
import { BpcatkeyComponent } from './components/bpcatkey/bpcatkey.component';
import { BpGeomapComponent } from './components/bp-geomap/bp-geomap.component';
import { DisplaycheckboxComponent } from './components/displaycheckbox/displaycheckbox.component';
import { EmergencydropdownComponent } from './components/emergencydropdown/emergencydropdown.component';
import { DisplayaddressComponent } from './components/displayaddress/displayaddress.component';
import { CustomcatkeysComponent } from './components/customcatkeys/customcatkeys.component';
import { CheckboxinlinecaComponent } from './components/checkboxinlineca/checkboxinlineca.component';
import { CatextareaComponent } from './components/catextarea/catextarea.component';
import { HoursofoperationComponent } from './components/hoursofoperation/hoursofoperation.component';
import { ShowphotoComponent } from './components/photos/showphoto/showphoto.component';
import { UploadphotoComponent } from './components/photos/uploadphoto/uploadphoto.component';
import { DeletelogoComponent } from './components/logo/deletelogo/deletelogo.component';
import { UploadlogoComponent } from './components/logo/uploadlogo/uploadlogo.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CouponlinkComponent } from './components/itemcampaignlink/couponlink/couponlink.component';
import { AddresskeybillingComponent } from './components/addresskeybilling/addresskeybilling.component';
import { CopysheetThumbnailComponent } from './components/copysheet-thumbnail/copysheet-thumbnail.component';
import { ImagelistComponent } from './components/imagelist/imagelist.component';
import { ProfilepicklistComponent } from './components/profilepicklist/profilepicklist.component';
import { BillingstringComponent } from './components/billingstring/billingstring.component';
import { BillingstateComponent } from './components/billingstate/billingstate.component';
import { BillingphonennumberfieldComponent } from './components/billingphonennumberfield/billingphonennumberfield.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { BillingemailComponent } from './components/billingemail/billingemail.component';
import { BillingselectComponent } from './components/billingselect/billingselect.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HovercardComponent } from './components/hovercard/hovercard.component';
import { ItemPhonenumberfieldComponent } from './components/item-phonenumberfield/item-phonenumberfield.component';
import { ContactdropdownComponent } from './components/contactdropdown/contactdropdown.component';
//import { EditInputComponent } from './edit-input/edit-input.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { CampaignpopupComponent } from './components/campaign/campaignpopup/campaignpopup.component';
import { NationalUrlComponent } from './components/national-url/national-url.component';
import { ShowemailComponent } from './components/showemail/showemail.component';
import { DisplaydropdownComponent } from './components/displaydropdown/displaydropdown.component';
import { GeoComponent } from './components/geo/geo.component';
import { CamcatComponent } from './components/camcat/camcat.component';
// import { AgmCoreModule } from '@agm/core';
import { WebsiteComponent } from './components/website/website.component';
import { CampaignbuttonComponent } from './components/campaign/campaignbutton/campaignbutton.component';
import { BillinginputComponent } from './components/billinginput/billinginput.component';
import { CallComponent } from  './components/call-new/call/call.component'  //'./components/call-new/call/call.component';
import { CallnewComponent } from './components/call-new/callnew/callnew.component';
import { CallinputComponent } from './components/call-new/callinput/callinput.component';
import { ShowHistoryComponent } from './components/show-history/show-history.component';
import { DpmHoursofoperationComponent } from './components/dpm-hoursofoperation/dpm-hoursofoperation.component';
import { ItemStringComponent } from './components/item-string/item-string.component';
import { CalltrackingdropdownComponent } from './components/call-new/calltrackingdropdown/calltrackingdropdown.component';
import { CallPhonenumberComponent } from './components/call-new/call-phonenumber/call-phonenumber.component';
import { BusinessCategoriesItemComponent } from './components/business-categories-item/business-categories-item.component';
import { BusinessprofileDropdownComponent } from './components/campaign/businessprofile-dropdown/businessprofile-dropdown.component';
import { CallconfirmationComponent } from './components/call-new/callconfirmation/callconfirmation.component';
import { CampaignAccountComponent } from './components/campaign-account/campaign-account.component';
import { AppointmentdatetimeComponent } from './components/appointmentdatetime/appointmentdatetime.component';
import { PublishSpinnerComponent } from './components/publish-spinner/publish-spinner.component';
import { SEMPHoursService } from '@app/service/semphours/semphours.service';
import { CallselectComponent } from './components/call-new/callselect/callselect.component';
import { CopySheetComponent } from './components/copy-sheet/copy-sheet.component';
import { CopysheetFileComponent } from './components/copysheet-file/copysheet-file.component';
import { AttachmentsComponent } from './components/copysheet-file/attachments/attachments.component';
import { BrowseComponent } from './components/copysheet-file/browse/browse.component';
import { UploadComponent } from './components/copysheet-file/upload/upload.component';
import { CopysheetUploadComponent } from './components/copysheet-upload/copysheet-upload.component';
import { CopysheetAnnotateComponent } from './components/copysheet-annotate/copysheet-annotate.component';
import { PdfAnnotateService } from '@app/service/pdf-annotate.service';
import { ExtralineComponent } from './components/extraline/extraline.component';
import { AdBasedComponent } from './components/ad-based/ad-based.component';
import { ItemGroupsComponent } from './components/item-groups/item-groups.component';
import { NumberComponent } from './components/number/number.component';
import { ItemCategoryComponent } from './item-category/item-category.component';
import { StickyHeaderDirective } from './stickyHeader/sticky-header.directive';
import { ArtistinstructionComponent } from './components/artistinstruction/artistinstruction.component';
import { ItemHoursofoperationComponent } from './components/item-hoursofoperation/item-hoursofoperation.component';
import { WebsiteUrlComponent } from './components/website-url/website-url.component';
import { ProposalIdComponent } from './components/proposal-id/proposal-id.component';
import { NgxSpinnerComponent } from './components/ngx-spinner/ngx-spinner.component';
import { ProfiledDirectiveComponent } from './components/profiled-directive/profiled-directive.component';
import { IagroupComponent } from './components/iagroup/iagroup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TradenameComponent } from './components/tradename/tradename.component';
import { DeleteBusinessProfileComponent } from './components/delete-business-profile/delete-business-profile.component';
import { CampaignalertComponent } from './components/campaignalert/campaignalert.component';
import { DisabledControlDirective } from './components/website/disable.directive';
import { ItemInputComponent } from './components/item-input/item-input.component';
import { CopysheetalertComponent } from './components/copysheetalert/copysheetalert.component';
import { WebsitetypeComponent } from './components/websitetype/websitetype.component';
import { PDFViewerComponent } from './components/pdfviewer/pdfviewer.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { SetbaseService } from '@app/service/setbase/setbaseservice';
import { HistoryDialogComponent } from './components/history-dialog/history-dialog.component';


@NgModule({
  declarations: [
    ItemComponent,CallinputComponent, PrintItemComponent, DigitalItemComponent,HeaderComponent, FooterComponent, LeftnavComponent, ProgressBarComponent, TabulatorgridDirective, TestComponent,DashboardLoginComponent,AdminDashboardComponent, BillingnewComponent, SelectComponent, StringComponent, EmailComponent, PhonenumberfieldComponent, AddresskeydropdownComponent,BillingComponent,
    HeaderComponent, FooterComponent, LeftnavComponent, ProgressBarComponent, UploadlogoComponent,
    TabulatorgridDirective, TestComponent, DexcardComponent, UrlComponent, ImagelistComponent,
    ZipcodeValidationComponent, StringComponent, InputComponent, PhonenumberfieldComponent,
    MultisearchComponent, SpecialtiesitemdirectiveComponent, PhotosComponent, TextareaComponent,
    LogoComponent, ItemcampaignlinkComponent, ButtonComponent, ShowphotoComponent, BrowsephotoComponent,
    BpGeomapComponent, BpcatkeyComponent, AddresskeydropdownComponent, UploadphotoComponent,
    DisplaycheckboxComponent, DisplayaddressComponent, EmergencydropdownComponent, DeletelogoComponent,
    CustomcatkeysComponent, CheckboxinlinecaComponent, CatextareaComponent,HoursofoperationComponent
    , CouponlinkComponent,AddresskeybillingComponent, BillingstringComponent, BillingstateComponent,StateComponent, BillingphonennumberfieldComponent,ProfilepicklistComponent, BillingemailComponent, BillingselectComponent,PageFooterComponent
    , CampaignComponent,ShowHistoryComponent, CallComponent,CallnewComponent,CalltrackingdropdownComponent,CampaignpopupComponent, NationalUrlComponent, ShowemailComponent, DisplaydropdownComponent, GeoComponent, CamcatComponent, WebsiteComponent, CampaignbuttonComponent,CopysheetThumbnailComponent, HovercardComponent,ItemPhonenumberfieldComponent, ContactdropdownComponent,BillinginputComponent, DpmHoursofoperationComponent, ItemStringComponent, CallPhonenumberComponent,BusinessCategoriesItemComponent, BusinessprofileDropdownComponent, CallconfirmationComponent,
    AppointmentdatetimeComponent, CampaignAccountComponent
    , CampaignComponent,ShowHistoryComponent, CallComponent,CallnewComponent,CalltrackingdropdownComponent,CampaignpopupComponent, NationalUrlComponent, ShowemailComponent, DisplaydropdownComponent, GeoComponent, CamcatComponent, WebsiteComponent, CampaignbuttonComponent,CopysheetThumbnailComponent, HovercardComponent,ItemPhonenumberfieldComponent, ContactdropdownComponent,BillinginputComponent, DpmHoursofoperationComponent, ItemStringComponent, 
    CallPhonenumberComponent,BusinessCategoriesItemComponent, BusinessprofileDropdownComponent, PublishSpinnerComponent, CallselectComponent, CopySheetComponent, CopysheetFileComponent, AttachmentsComponent, BrowseComponent, 
    UploadComponent, CopysheetUploadComponent, CopysheetAnnotateComponent, ExtralineComponent, PublishSpinnerComponent,    
    ItemGroupsComponent, AdBasedComponent, NumberComponent, ItemCategoryComponent, StickyHeaderDirective, ArtistinstructionComponent,HoursofoperationComponent, ItemHoursofoperationComponent, WebsiteUrlComponent, ProposalIdComponent, ProfiledDirectiveComponent,NgxSpinnerComponent,IagroupComponent, TradenameComponent, DeleteBusinessProfileComponent, CampaignalertComponent,
    DisabledControlDirective, ItemInputComponent, CopysheetalertComponent, WebsitetypeComponent, PDFViewerComponent, AccessDeniedComponent, ImageDialogComponent, HistoryDialogComponent 
  ],
  entryComponents: [BillinginputComponent,CallPhonenumberComponent,CallinputComponent,CallComponent,CallnewComponent,BillingselectComponent,BillingemailComponent,ImagelistComponent , BillingphonennumberfieldComponent,StateComponent, 
    BillingstateComponent,BillingstringComponent,AddresskeybillingComponent, InputComponent, 
    BillingnewComponent,AddresskeydropdownComponent,SelectComponent,StringComponent,EmailComponent, 
    PhonenumberfieldComponent,BillingComponent,ProfilepicklistComponent, PDFViewerComponent,
    NationalUrlComponent, ShowemailComponent, GeoComponent, CampaignComponent, CampaignpopupComponent,
    DisplaydropdownComponent, ShowHistoryComponent,CalltrackingdropdownComponent,CamcatComponent, WebsiteComponent, CampaignbuttonComponent,CopysheetThumbnailComponent,HovercardComponent, ItemPhonenumberfieldComponent,ContactdropdownComponent,ZipcodeValidationComponent,CheckboxinlinecaComponent,SpecialtiesitemdirectiveComponent,DpmHoursofoperationComponent,ItemStringComponent,BusinessCategoriesItemComponent, 
    BusinessprofileDropdownComponent,CallselectComponent,CallconfirmationComponent,CampaignAccountComponent,AppointmentdatetimeComponent, CopySheetComponent, CopysheetFileComponent, CopysheetUploadComponent,
    CopysheetAnnotateComponent, ExtralineComponent, PublishSpinnerComponent, ItemGroupsComponent, AdBasedComponent, NumberComponent,ItemCategoryComponent,ArtistinstructionComponent,ItemHoursofoperationComponent,WebsiteUrlComponent,ProposalIdComponent,TextareaComponent,ProfiledDirectiveComponent,IagroupComponent, TradenameComponent, DeleteBusinessProfileComponent, CampaignalertComponent, CopysheetalertComponent, WebsitetypeComponent, ImageDialogComponent, HistoryDialogComponent],
  imports: [
    CommonModule, SharedRoutingModule, MatDividerModule, MatToolbarModule, MatIconModule,
    MatButtonModule,	MatDialogModule,	FlexLayoutModule,	MatListModule,
    NgCircleProgressModule.forRoot({}),	RouterModule,	MatMenuModule, MatTableModule,
    MatProgressSpinnerModule,	MatInputModule,	MatCardModule, MatSlideToggleModule,
    MatSelectModule,	MatOptionModule,	FormsModule, 	ReactiveFormsModule ,
    MatPaginatorModule,	MatTableModule,	MatTabsModule,	ReactiveFormsModule,
    FormsModule,	NgbModule, NgxPaginationModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    Bootstrap4FrameworkModule,
    FormsModule,
    FormsModule,ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}), CommonModule, MatDividerModule, MatToolbarModule, MatIconModule,
    MatButtonModule, FlexLayoutModule, MatListModule, MatDialogModule, NgSelectModule, MatNativeDateModule,
    NgCircleProgressModule.forRoot({}), RouterModule, MatTabsModule, FileUploadModule, MatDatepickerModule
    ,MatPaginatorModule,
    MatExpansionModule, MatCheckboxModule,
    NgxSpinnerModule,MatStepperModule,
    MatFormFieldModule,
    MatChipsModule,
    NgxDaterangepickerMd.forRoot(),
    DragDropModule,
    ToastContainerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    ItemComponent,ShowHistoryComponent,CallPhonenumberComponent,CallinputComponent,CallComponent,CallnewComponent,AddresskeydropdownComponent,HeaderComponent, FooterComponent, LeftnavComponent, TabulatorgridDirective,SelectComponent,StringComponent,EmailComponent,PhonenumberfieldComponent,BillingComponent,
    HeaderComponent, FooterComponent, LeftnavComponent, ProgressBarComponent, DeletelogoComponent,
    TabulatorgridDirective, TestComponent, DexcardComponent, UrlComponent, UploadlogoComponent,
    ZipcodeValidationComponent, StringComponent, InputComponent, PhonenumberfieldComponent,
    MultisearchComponent, SpecialtiesitemdirectiveComponent, PhotosComponent, TextareaComponent,
    LogoComponent, ItemcampaignlinkComponent, ButtonComponent, ShowphotoComponent, BrowsephotoComponent,
    BpGeomapComponent, BpcatkeyComponent, EmailComponent, AddresskeydropdownComponent, CouponlinkComponent,
    DisplaycheckboxComponent, DisplayaddressComponent, EmergencydropdownComponent, ImagelistComponent ,BillingselectComponent,
    CustomcatkeysComponent,CallselectComponent,CallconfirmationComponent,CalltrackingdropdownComponent,  CatextareaComponent,HoursofoperationComponent,BillingstringComponent,BillingstateComponent,StateComponent,BillingphonennumberfieldComponent,ProfilepicklistComponent,BillingemailComponent,CopysheetThumbnailComponent,HovercardComponent,ItemPhonenumberfieldComponent,ContactdropdownComponent,BillinginputComponent,DpmHoursofoperationComponent,ItemStringComponent,BusinessCategoriesItemComponent,NgxSpinnerModule,PublishSpinnerComponent
  ,CopysheetAnnotateComponent, ExtralineComponent, ItemGroupsComponent, AdBasedComponent, NumberComponent,ItemCategoryComponent, StickyHeaderDirective,ItemHoursofoperationComponent,WebsiteUrlComponent,ProposalIdComponent,ProfiledDirectiveComponent,NgxSpinnerComponent,ItemInputComponent,HistoryDialogComponent],
  providers: [
    ApiService,
    //ParamsService,
    IndicatorService,
    RequiredfieldcountService,
    PhotoService,
    CouponSharingService,
    DatasharingService,
    StorageService,
    SEMPHoursService,
    AccountService,
    ConfigurationService,
    ProfileschemaService,
    ProfileService,
    ProductService,
    UtilsService,
    OrderService,
    UserService,
    TransformerService,
    SalesforceService,
    ToastrService,
    CatKeysService,
    FileService,
    LoggerService,
    ValidateService,
    CookieService,
    ErrorhandlingService,
    AppConfigsService,
    DatePipe,
    PdfAnnotateService,
    SetbaseService
  ]
})
export class SharedModule {}
