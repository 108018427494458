import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorQuery } from 'app/state/query';
import { UtilsService } from '@app/service/utils/utils.service';
import { DatePipe } from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { DatetimesharedService, APP_DATE_FORMATS } from './datetimeshared.service';
import { IndicatorStore } from 'app/state/store';


@Component({
  selector: 'app-appointmentdatetime',
  templateUrl: './appointmentdatetime.component.html',
  styleUrls: ['./appointmentdatetime.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: DatetimesharedService},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class AppointmentdatetimeComponent implements OnInit {

  uniqItemId: string = '';
  Indicator: any;
  
  ngModel = {
    'date' :null,
    'time':null
  };
  renewal: boolean = false;
  readonly: boolean = false;
  items: any = [];
  minDate: Date
  maxDate: Date
  format: string = 'yyyy-MM-dd';
  secondLimitDate;
  firstLimitDate;
  orderCreatedDate;

  constructor(private storageService: StorageService,
    private indicatorQuery: IndicatorQuery,
    private utils: UtilsService,
    private datepipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.secondLimitDate = new Date();
      this.firstLimitDate  = new Date();
      this.orderCreatedDate = new Date();
      this.Indicator = JSON.parse(JSON.stringify(data))
      if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === "Renew") {
        this.renewal = true;
      } else {
        this.renewal = false;
      }
    this.uniqItemId = this.Indicator.stateParamsId + "v" + this.Indicator.items[this.Indicator.stateParamsId].order.version;
    if(this.Indicator.items[this.Indicator.stateParamsId].model.AppDateTime)
    this.ngModel = this.formatModel(JSON.parse(JSON.stringify(this.Indicator.items[this.Indicator.stateParamsId].model.AppDateTime)));
    if(this.ngModel) this.dateTimeInit(this.ngModel);
    //1: Get a date 3 days ahead of order creation date and check about weekends
    var numberOfDaysToAdd = 3;
    // Check if today is Friday, Saturday or Sunday
    if(this.firstLimitDate.getDay() === 5){ //if friday then add 5 bypassing the weekend
       this.firstLimitDate.setDate(this.firstLimitDate.getDate() + 5);
    } else if(this.firstLimitDate.getDay() === 6) { //if saturday then add 4 bypassing the weekend
       this.firstLimitDate.setDate(this.firstLimitDate.getDate() + 4);
    } else if(this.firstLimitDate.getDay() === 0) { //if sunday then add 3 bypassing the weekend
       this.firstLimitDate.setDate(this.firstLimitDate.getDate() + 3);
    } else {
      this.firstLimitDate.setDate(this.orderCreatedDate.getDate() + numberOfDaysToAdd); // add 3 days
      if (this.firstLimitDate.getDay() === 0) { // check if the date after addition falls on sunday
        this.firstLimitDate.setDate(this.firstLimitDate.getDate() + 1);
      } else if (this.firstLimitDate.getDay() === 6) { // check if the date after addition falls on saturday
        this.firstLimitDate.setDate(this.firstLimitDate.getDate() + 2);
      }
    }

    //2: Get a date 60 days ahead of pub request start date and check about weekends
    var pubStartDate = new Date(this.Indicator.items[this.Indicator.stateParamsId].order.pubRequestStartDate);
    var numberOfDaysToAdd = 60;
    this.secondLimitDate.setDate(pubStartDate.getDate() + numberOfDaysToAdd);
    if (this.secondLimitDate.getDay() === 0) {
      this.secondLimitDate.setDate(this.secondLimitDate.getDate() - 2);
    } else if (this.secondLimitDate.getDay() === 6) {
      this.secondLimitDate.setDate(this.secondLimitDate.getDate() - 1);
    }
    this.items = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM',
      '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM'];
  })
  }

  formatModel(dateTime){
    if(typeof dateTime === 'object'){
      return dateTime
    } else {
      let model = {}
      let date = new Date(dateTime.split(" ")[0])
      let day = date.getDate().toString();
      let month = (date.getMonth()+1).toString();
      let year = date.getFullYear().toString();
      model['date'] = year+'-'+month+'-'+day;
      model['time'] = dateTime.split(" ")[1] + " " + dateTime.split(" ")[2]
      return model;
    }
  }

  dateTimeInit(item) {
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    this.storageService.get("datetime", this.uniqItemId).then(data => {
      let itemLen = Object.keys(item).length
      if (itemLen > 0 && item.date && this.utils.isObjectEmpty(data[this.uniqItemId]) == true) {
        this.ngModel = item;
        this.ngModel['date'] = this.datepipe.transform(this.ngModel['date'], this.format);
        this.ngModel['time'] = item.time;
        this.maxDate = this.secondLimitDate;
        this.minDate = this.firstLimitDate;
      } else if (itemLen > 0 && item.date && (data[this.uniqItemId].date !== item.date)) {
        this.ngModel = data[this.uniqItemId];
        this.ngModel['date'] = this.datepipe.transform(this.ngModel['date'], this.format);
        this.ngModel['time'] = data[this.uniqItemId].time;
        this.maxDate = this.secondLimitDate;
        this.minDate = this.firstLimitDate;
      } else {
        this.ngModel['date'] = (!item.date) ? this.formatDate(this.firstLimitDate) : item.date;
        this.ngModel['time'] = (!item.time) ? this.items[0] : item.time;
        this.maxDate = this.secondLimitDate;
        this.minDate = this.firstLimitDate;
      }
      modelData.items[this.Indicator.stateParamsId].model.AppDateTime = this.ngModel;
      if (this.renewal || (modelData.items[this.Indicator.stateParamsId].model.AppDateTime && modelData.items[this.Indicator.stateParamsId].model.AppDateTime.date && modelData.items[this.Indicator.stateParamsId].model.AppDateTime.date.split('-').length > 1)) {
        let tempModel = modelData.items[this.Indicator.stateParamsId].model.AppDateTime
        let month = (new Date(tempModel['date']).getMonth() + 1).toString();
        let date = (new Date(tempModel['date']).getDate()).toString();
        let year = new Date(tempModel['date']).getFullYear().toString();
        let x = month + '/' + date + '/' + year + ' ' + tempModel['time'] //05/25/2019 10:00 AM //2021-01-16 //10:00 AM
        modelData.items[this.Indicator.stateParamsId].model.AppDateTime = x;
      }
      let x = JSON.parse(JSON.stringify(this.Indicator));
      x = modelData;
      this.indicatorStore.update(state => {
        return {
          indicator: x,
          updatedFrom: "[AppointmentdatetimeComponent] dateTimeInit",
          isloaded: true
        }
      });
      this.ngModel = JSON.parse(JSON.stringify(this.ngModel));
      this.ngModel['date'] = new Date(this.ngModel['date']+' 00:00:00')
      if (!(<ViewRef>this.cdr).destroyed)
        this.cdr.detectChanges();
      // this.indicatorQuery.updateModel(this['layoutNode'].name, JSON.parse(JSON.stringify(this.ngModel)),  modelData, this.Indicator);
    });
  }

  displayDate(ngModel) {
    let dateString;
    if (ngModel && ngModel.date) {
      dateString = ngModel.date.toString();
      if(typeof ngModel['date'] === 'string' && ngModel['date'].includes('T'))
        dateString = new Date(ngModel['date'].split('T')[0]+' 00:00:00').toString();
      dateString = dateString.split(' ').slice(0, 4).join(' ');
    }
    return dateString;
  }

  dateChanged(type, evt) {
    if (type === 'date') this.ngModel['date'] = (evt.target.value) ? new Date(evt.target.value) : this.Indicator.items[this.Indicator.stateParamsId].model.AppDateTime['date'];
    else if (type === 'time') this.ngModel['time'] = evt.target.value;
    else this.ngModel = evt
    let ngModel = JSON.parse(JSON.stringify(this.ngModel))
    if (type === 'date') ngModel['date'] = evt.target.value ? this.formatDate(evt.target.value) : this.Indicator.items[this.Indicator.stateParamsId].model.AppDateTime['date'];
    this.storageService.update("datetime", this.uniqItemId, ngModel);
    this.Indicator = JSON.parse(JSON.stringify(this.Indicator))
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator))
    modelData['items'][this.Indicator.stateParamsId].model[this['layoutNode'].name] = JSON.parse(JSON.stringify(ngModel))
    let x = JSON.parse(JSON.stringify(this.Indicator));
    x = modelData;
    this.indicatorStore.update(state => {
      return {
        indicator: x,
        updatedFrom: "[AppointmentdatetimeComponent] dateChanged",
        isloaded: true
      }
    });
    // this.indicatorQuery.updateModel(this['layoutNode'].name, JSON.parse(JSON.stringify(this.ngModel)),  modelData, this.Indicator);
  }

  formatDate(date){
    let day = date.getDate().toString();
    let month = (date.getMonth()+1).toString();
    let year = date.getFullYear().toString();
    day = +day < 10 ? '0' + day : day;
    month = +month < 10 ? '0' + month : month;
    return year+'-'+month+'-'+day
  }
}
