import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { ComponentQuery } from 'app/state/component.query';
import { Subject } from 'rxjs';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { CallnewComponent } from '../call-new/callnew/callnew.component';
import { BillingnewComponent } from '../billing-new/billingnew/billingnew.component';
import { BillingService } from '@app/service/billing/billing.service';

@Component({
  selector: 'app-billingphonennumberfield',
  templateUrl: './billingphonennumberfield.component.html',
  styleUrls: ['./billingphonennumberfield.component.scss']
})
export class BillingphonennumberfieldComponent implements OnInit, OnDestroy {
  options: any;
  customField: string;
  sharedData;
  errorFlag: boolean = false;
  updatedPhone:any;
  titleContent: string;
  hidden: boolean;
  baFlag: boolean =false;
  formSumbitted : boolean = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private componentQuery:ComponentQuery,private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BillingnewComponent>,
    private billing: BillingService) { }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
      this.options = this['layoutNode'].options;
      if(this['layoutNode'].name === "destinationnumber"){
        this.titleContent = "Destination Number";
        this.customField = this.sharedData.account.phone;
      }
      if(this.data && this.data.title === "Update"){
         if(this['layoutNode'].name === "BTN"){
            this.titleContent = "BTN (Phone)"
            this.baFlag = true;
            this.customField = this.data.dialogform._cell.row.data.phone;
            //this.sharedData.account.phone = this.customField;
         }if(this['layoutNode'].name === "Mobile"){
            this.titleContent = "Cellular (Mobile)"
            if(this.data.dialogform._cell.row.data.cellular == "() -"){
              this.customField = " ";
            } else{
              this.customField = this.data.dialogform._cell.row.data.cellular;
            }
            this.baFlag = false;
         }
      }else{
        if(this['layoutNode'].name === "BTN"){
         this.titleContent = "BTN (Phone)"
         this.baFlag = true;
        this.customField = this.sharedData.account.phone;
        }
        if(this['layoutNode'].name === "Mobile"){
          this.titleContent = "Cellular (Mobile)"
          this.customField = this.sharedData.account.cellular;
          this.baFlag = false;
        }
      }
    });
  }
  sendValue(args){
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
    this.customField = this.customField.replace(/[^0-9]/g, '')
      // if (this.customField.length >= 1) {
      //   this.customField = ("(").concat(this.customField.slice(0))
      // }
      // if (this.customField.length >= 5) {
      //   this.customField = this.customField.slice(0, 4).concat(") ", this.customField.slice(4))
      // }
      // if (this.customField.length >= 10) {
      //   this.customField = this.customField.slice(0, 9).concat("-", this.customField.slice(9))
      // }
    if(this['layoutNode'].name === "destinationnumber" ){
      if (args && args.length == 14 || args.length == 1) {
        this.errorFlag = false
        upadatedName.account.phone = args;
      }
      else {
        this.errorFlag = true;
        upadatedName.account.phone = "";
      }
    }
    if(this['layoutNode'].name === "BTN"){
      // if (args && args.length == 14 || args.length == 1) {
        if (args && args.length >= 7 && args.length <= 11) {
        this.errorFlag = false
        upadatedName.account.phone = args;
        if (this.dialogRef && this.dialogRef.componentInstance) {
          this.dialogRef.componentInstance.onUpdateBillingFields(args,this['layoutNode'].name);
        }
      }
      else {
        this.errorFlag = true;
        upadatedName.account.phone = "";
      }
    }
    if(this['layoutNode'].name === "Mobile"){
      if (args && args.length == 14 || args.length == 1) {
        this.errorFlag = false
        if (this.dialogRef && this.dialogRef.componentInstance) {
          this.dialogRef.componentInstance.onUpdateBillingFields(args,this['layoutNode'].name);
        }
      }
      else {
        this.errorFlag = true;
      }
    }
      this.indicatorStore.update((state) => {
        return {
          indicator:upadatedName,
          updatedFrom: "[BillingphonennumberfieldComponent] sendValue",
          isloaded: true,
        };
      });

  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
