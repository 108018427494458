  import { Injectable } from '@angular/core';
  import { Observable, throwError } from 'rxjs';
  import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

  import { ApiService } from '../api/api.service';
  import { ParamsService } from '../params/params.service';

  @Injectable({
    providedIn: 'root'
  })
  export class CopysheetindicatorService {
    baseUri: string = '/api/copysheetindicator';
    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(
      private http: HttpClient,
      private Params: ParamsService,
      private API: ApiService
    ) { }
    get(orderid: any, itemid: any): Observable<any> {
      return this.http.get(this.API.get(this.baseUri) + "/" + orderid + "/" + itemid);
    };
    getIndicator(orderid: any, itemid: any)  {​​​​​​​​
      return this.http.get(this.API.get(this.baseUri) + "/" + orderid + "/" + itemid).toPromise();
    }​​​​​​​​;

  }
