import { Component, OnInit, Inject, Optional, OnDestroy, ChangeDetectorRef,ViewRef } from '@angular/core';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ComponentQuery } from 'app/state/component.query';
import { Subject } from 'rxjs';
import { filterNil } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';
import { CounterService } from '@app/service/counter/counter.service';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';


@Component({
  selector: 'app-item-string',
  templateUrl: './item-string.component.html',
  styleUrls: ['./item-string.component.scss']
})
export class ItemStringComponent implements OnInit, OnDestroy {
  subscription: any;
  customField ;
  numbers: any = /^[0-9]+$/;
  error: boolean = false;
  id: any;
  errorMsg: any;
  invalid: boolean = false;
  sharedData;
  options :any;
  isReadonly:boolean =false;
  titleContent: string;
  hidden :boolean = false;
  destroySubject$: Subject<void> = new Subject();
  schemaObj ;
  isRequiredField :boolean =false;
  constructor(
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private counterService : CounterService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private updateIndicatorService:  UpdateIndicatorService,
    private requiredfieldcount :RequiredfieldcountService,
    private cdr: ChangeDetectorRef) {   }

  ngOnInit() {
    this.options = this['layoutNode'].options;
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe((res) => {
   
      this.sharedData = { ...res };
      this.schemaObj=this.sharedData.items[this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name];
      this.isRequiredField = this.schemaObj.required;
      
        this.titleContent =  this.schemaObj.title;
        this.isReadonly =this.schemaObj.readonly;
        //below loop is used to find out hidden fields from SF form so to hide form UI.
        for (let i=0; i<= this.sharedData.items[this.sharedData.stateParamsId].form.length;i++){
          
          if(this.sharedData.items[this.sharedData.stateParamsId].form[i]  && this.sharedData.items[this.sharedData.stateParamsId].form[i].key === this['layoutNode'].name && this['layoutNode'].name !== "GeographyName"&& this.sharedData.items[this.sharedData.stateParamsId].form[i].htmlClass ===" hideField" ){
            this.hidden = true;
            this.customField = this.sharedData.items[this.sharedData.stateParamsId].model[this['layoutNode'].name];
            return;
          }else{
            this.hidden = false;
            this.customField = this.sharedData.items[this.sharedData.stateParamsId].model[this['layoutNode'].name];

          }
        }
        // if (this['layoutNode'].name == "GeographyName") { 
        //   this['layoutNode'].options.sortOrder = 2; // To change sequence
        // }
        this.customField = this.sharedData.items[this.sharedData.stateParamsId].model[this['layoutNode'].name];
        if (this['layoutNode'].name == "GeographyLevel") { 
          this.titleContent =this['layoutNode'].name; //title was blank so passing this name as title
        
        }
        if (this['layoutNode'].name == "CoOpNIAmount") { 
          this.titleContent =this['layoutNode'].name; //title was blank so passing this name as title
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.CoOpNIAmount?this.sharedData.items[this.sharedData.stateParamsId].model.CoOpNIAmount:"0";
        }
        if (this['layoutNode'].name == "CoOPPIAmount") {  
          this.titleContent =this['layoutNode'].name;//title was blank so passing this name as title
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.CoOPPIAmount ?this.sharedData.items[this.sharedData.stateParamsId].model.CoOPPIAmount:"0";
        }
        if (this['layoutNode'].name == "CoOPPIStatus") {
          this.titleContent =this['layoutNode'].name;//title was blank so passing this name as title
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.CoOPPIStatus ?this.sharedData.items[this.sharedData.stateParamsId].model.CoOPPIStatus:"No";
        }
        if (this['layoutNode'].name == "CoOPNotes") { 
          this.titleContent =this['layoutNode'].name;//title was blank so passing this name as title
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.CoOPNotes;
         this.hidden =true;
        }
        if (this['layoutNode'].name == "Website") {      
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.Website;
         
        }
        if (this['layoutNode'].name == "FacebookURL") {  
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.FacebookURL;
         
        }
        if (this['layoutNode'].name == "BusinessDescription") { 
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessDescription;
         
        }
        if (this['layoutNode'].name == "FeaturedMessage") {  
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.FeaturedMessage;
         
        }
        if (this['layoutNode'].name == "YearEstablished") { 
         this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.YearEstablished;
         
        }
        if(this['layoutNode'].name === "AdvertiserBudget"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.AdvertiserBudget;
        }
        if(this['layoutNode'].name === "GeographyName"){
          this.customField =this.sharedData.items[this.sharedData.stateParamsId].order['geoName'];           
        }
        if(this['layoutNode'].name === "GeoNotes"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.GeoNotes;
        }
        if(this['layoutNode'].name === "GeoZipCode"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.GeoZipCode;
        }
        if(this['layoutNode'].name === "ServicesToAdvertise"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.ServicesToAdvertise;
        }
        if(this['layoutNode'].name === "SampleDescription"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.SampleDescription;
        }
        if(this['layoutNode'].name === "SampleTitle"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.SampleTitle;
        }
        if(this['layoutNode'].name === "LicenseNumber"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model['LicenseNumber'];
          let requiredFields = this.sharedData['items'][this.sharedData.stateParamsId].requiredFields;
          this.isRequiredField = false;
          if(requiredFields.indexOf('LicenseNumber')> -1 ){
            this.isRequiredField = true;
          }
        }
        if(this['layoutNode'].name === "PrimaryCity"){
          this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.PrimaryCity;          
            this.counterService.getSeoData().pipe( takeUntil(this.destroySubject$)).subscribe(data => {​​​​
            this.customField = data[0];
            this.sharedData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            this.sharedData['items'][this.sharedData.stateParamsId].model.PrimaryCity =  this.customField;
            this.ngAfterViewChecked();
            
            }​​​​);
        }
        if(this['layoutNode'].name === "BusinessName"){
            this.customField = this.sharedData.items[this.sharedData.stateParamsId].model.BusinessName;          
            this.counterService.getSeoData().pipe(filterNil, takeUntil(this.destroySubject$)).subscribe(data => {​​​​
            this.customField = data[1];
            this.sharedData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            this.sharedData.items[this.sharedData.stateParamsId].model['BusinessName']=  this.customField;
            this.ngAfterViewChecked();
            }​​​​);
        } 
         
    });       
     
  }
  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
    this.cdr.detectChanges();
  }
  sendValue(args) {
    
    let tempObj = JSON.parse(JSON.stringify(this.sharedData));
    tempObj.items[tempObj.stateParamsId].model[this['layoutNode'].name] = args;
    let maxLength =tempObj.items[tempObj.stateParamsId].schema.properties[this['layoutNode'].name].maxLength;
    if (this['layoutNode'].name === "BusinessName") {
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.account.name = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum '+ maxLength;
      } else {
        tempObj.account.name = args;
        this.invalid = false;
      }
    }else if(this['layoutNode'].name === "Address1"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.address.street1 = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum '+ maxLength;
      } else {
        tempObj.account.address.street1 = args;
        this.invalid = false;
      }

    }else if(this['layoutNode'].name === "City"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.address.city = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
        tempObj.account.address.city = args;
        this.invalid = false;
      }
    }else if(this['layoutNode'].name === "Zip"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.address.zip = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars), maximum '+ maxLength;
      } else {
        tempObj.account.address.zip = args;
        this.invalid = false;
      }
    } else if(this['layoutNode'].name === "GeoNotes"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
       tempObj.items[tempObj.stateParamsId].model.GeoNotes = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
       tempObj.items[tempObj.stateParamsId].model.GeoNotes = args;
        this.invalid = false;
      }
    } else if(this['layoutNode'].name === "GeoZipCode"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.items[tempObj.stateParamsId].model.GeoZipCode = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
        tempObj.items[tempObj.stateParamsId].model.GeoZipCode = args;
        this.invalid = false;
      }
    } else if(this['layoutNode'].name === "ServicesToAdvertise"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.items[tempObj.stateParamsId].model.ServicesToAdvertise = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
        tempObj.items[tempObj.stateParamsId].model.ServicesToAdvertise = args;
        this.invalid = false;
      }
    } else if(this['layoutNode'].name === "SampleDescription"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.items[tempObj.stateParamsId].model.SampleDescription = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
        tempObj.items[tempObj.stateParamsId].model.SampleDescription = args;
        this.invalid = false;
      }
    } else if(this['layoutNode'].name === "SampleTitle"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.items[tempObj.stateParamsId].model.SampleTitle = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
        tempObj.items[tempObj.stateParamsId].model.SampleTitle = args;
        this.invalid = false;
      }
    }
    else{
     //if(this['layoutNode'].name === "FeaturedMessage"){
      if (args != undefined && args != null && args.length > maxLength) {
        this.invalid = true;
        tempObj.items[tempObj.stateParamsId].model[this['layoutNode'].name]  = '';
        this.errorMsg = 'String is too long (' + args.length + ' chars),maximum '+ maxLength;
      } else {
        tempObj.items[tempObj.stateParamsId].model[this['layoutNode'].name] = args;
        this.invalid = false;
      }

    }
    let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].model));
    tempObj.items[tempObj.stateParamsId].counter = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][tempObj.stateParamsId].counter));
    tempObj["orderPercent"] = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator["orderPercent"]));
    this.indicatorQuery.updateModel(this['layoutNode'].name,args,modelData,tempObj, '[ItemStringComponent] sendValue');
    
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
  
}
