import { Injectable } from '@angular/core';

@Injectable()
export class Constants 
{
    loadingText :string =  'Fetching data from backend systems. It might take some time. Please wait ...';
    saveText : string =  'Saving Business Profile. Please wait ...';
    successTimeout : number = 5000;
    PUBLISH_LOAD:string = 'Please wait while loading account & order data...';
    PUBLISH_VERIFICATION_DATA = 'Please wait while loading data.';
    PUBLISH_UPDATE_ITEM = 'Please wait while loading item data...'
    PUBLISH_AUTHORIZATION = 'Please wait while authorization...';
    PUBLISH_ITEM_COUNT_CHECK = 'Please wait while the data is saved in downstream systems...';
    PUBLISH_UPLOAD_COPY = 'Please wait while uploading copysheet.'
    BP_UPLOAD_PHOTO = 'Saving photos...';
    BP_UPLOAD_LOGO = 'Saving logos...';
    BP_DELETE_PROFILE = 'Deleting Business Profile. Please wait ...';
    BP_DELETE_COUPONS = 'Deleting Coupons...';
    BP_CREATE_NEW = 'Create Business Profile';
    COPYSHEET_SAVE = 'Please wait while refreshing item billing data...';
    ITEM_SAVE = 'Saving....';
    GET_GEO_DATA = 'GEO selection in Progress, please wait';
    setbaseText:string = 'Please wait until the processing completed successfully! ';
}