import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';

import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';

@Injectable({
  providedIn: 'root'
})
export class CopyService {
  baseUri:string = '/api/copy';

  constructor(
    private http: HttpClient,
    private Params : ParamsService,
    private API: ApiService
  ) { }

  uploader: FileUploader = new FileUploader({
    url: this.API.get(this.baseUri), 
    headers: [{ name: "Authorization", value: "Bearer " + this.Params.params.token },{name:"accountId",value:sessionStorage.getItem("currentAccountId")},{name:"currentOrderId",value:sessionStorage.getItem("currentOrderId")},{name:"currentListingId",value:sessionStorage.getItem("currentListingId")}] 
  });

  // uploader.filters.push({
  //   name: 'customFilter',
  //   fn: function(item /*{File|FileLikeObject}*/, options) {
  //     return this.queue.length < 10;
  //   }
  // });

  get (id): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + '/' + id);
  };

  vgetCopySheets (data, accountId, version): Observable<any> {
    data.sforg = this.Params.params.sforg;
    return this.http.post(this.API.get(this.baseUri) + '/' + accountId + '/' + version, data);
  };

  updateCopysheet (id, data): Promise<any> {
    data.sforg = this.Params.params.sforg;
    //Call Copy api to update Copies collection with updated copysheets,
    //attachments, additional instructions, listing and contact
    return this.http.put(this.API.get(this.baseUri) + '/' + id, data).toPromise();
  };

  viewCopySheets (id, name) {
    return this.API.get(this.baseUri) + '/' + id + '/' + name;
  };

  generateCompiled(id, Indicator,page?): Promise<any> {
    let svItemId = id.substring(0, 10);
    let itemsOfSameIAGroup = {};
    let requestArray = []
    let retryTimeout = 5000

    let listingObj={}
    if(Indicator.items[svItemId].model.ListingId!=undefined){
      listingObj["listingId"] =  Indicator.items[svItemId].model.ListingId
    }

    for (let itemId in Indicator.items) {
      let copysheetItem = {};
      if ((Indicator.items[itemId].data.iaGroupId && Indicator.items[svItemId].data.iaGroupId) && Indicator.items[itemId].data.iaGroupId === Indicator.items[svItemId].data.iaGroupId && Indicator.items[itemId].order.actionType != 'Cancel') {
         copysheetItem = {
          copy: Indicator.items[itemId].copy,
          order: {
            directoryName: Indicator.items[itemId].order.directoryName,
            directoryCode: Indicator.items[itemId].order.directoryNumber,
            heading: Indicator.items[itemId].order.heading,
            headingCode: Indicator.items[itemId].order.headingCode
          }
        };
        itemsOfSameIAGroup[itemId] = copysheetItem;
      }
      else if(!(Indicator.items[itemId].data.iaGroupId) && itemId == svItemId && Indicator.items[itemId].order.actionType != 'Cancel'){
        copysheetItem = {
          copy: Indicator.items[itemId].copy,
          order: {
            directoryName: Indicator.items[itemId].order.directoryName,
            directoryCode: Indicator.items[itemId].order.directoryNumber,
            heading: Indicator.items[itemId].order.heading,
            headingCode: Indicator.items[itemId].order.headingCode
          }
        };
        itemsOfSameIAGroup[itemId] = copysheetItem;
      }
    }
    requestArray[0] = itemsOfSameIAGroup
    requestArray[1] = listingObj
    for (let i in Indicator.configuration) {
      if (Indicator.configuration[i].name == "COPYSHEET_RETRY_TIMEOUT") {
        retryTimeout = Indicator.configuration[i].value
      }
    }
    requestArray[2] = retryTimeout
    if(page){
      requestArray[3] = page
      //itemsOfSameIAGroup['page']= page
    }
    return this.http.post(this.API.get(this.baseUri) + '/generate/' + id, requestArray).toPromise();
  };

  uploadCompiled(id, sdfcItemId, data,page?): Promise<any> {
    if(page){
      data.page = page
    }
    return this.http.post(this.API.get(this.baseUri) + '/upload/' + id +'/'+ sdfcItemId, data).toPromise();
  };

  reUpload (svOrderId, svItemId, sfdcItemId): Observable<any> {
    return this.http.get(this.API.get(this.baseUri) + '/reupload/' + svOrderId + '/' + svItemId + '/' + sfdcItemId);
  };

  uploadUrlToSF (svItemId, gridfsUrl, sfdcItemId): Observable<any> {
    let data = {
      svItemId: svItemId,
      sfdcItemId: sfdcItemId,
      gridfsUrl: gridfsUrl
    }
    return this.http.put(this.API.get(this.baseUri) + '/sfurl/' + svItemId, data);
  };

  saveThumbnailImage (svOrderId, svItemId): Observable<any> {
    return this.http.post(this.API.get(this.baseUri) + '/reupload/' + svOrderId + '/' + svItemId, {});
  };
}
