import { Injectable } from '@angular/core';
import { IndicatorService } from '../../core/service/indicator/indicator.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
@Injectable({
  providedIn: 'root'
})
export class GraphicContentGridServiceService {
  //var vm = this;
  allOrderItems;//Indicator.items;
  nonIAGroupItem;
  iagroupIdList = [];

  constructor(private indicator:IndicatorService,private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) {
    this.allOrderItems = indicator.items;
  }

  buildGraphicProducts(localIndicator?:any, selectedItem?:string) {
    let selectedItemUDAC = "";
    if (selectedItem){
        selectedItemUDAC = this.indicator.items[selectedItem].order.UDAC;
    }
    this.allOrderItems = localIndicator ? localIndicator.items : this.indicator.items;
    var listOfUniqueDirectoryNames = [];
    let listOfGraphicItems:any = {};

     listOfGraphicItems.listOfUniqueDirectoryNames = [];
     listOfGraphicItems.graphicItemsArray = [];

    var uniqueIAGroupIdList = this.buildUniqueIAGroupIdList(selectedItemUDAC);
    var uniqueIAGroupArray = [];
    for (var i = 0; i < uniqueIAGroupIdList.length; i++){
        var uniqueIAGroupObj = uniqueIAGroupIdList[i];
        uniqueIAGroupArray[i] = {};
        uniqueIAGroupArray[i].UDAC = uniqueIAGroupObj.UDAC;
        uniqueIAGroupArray[i].iaGroupId = uniqueIAGroupObj.iaGroupId;
        uniqueIAGroupArray[i].businessName = uniqueIAGroupObj.businessName;
        uniqueIAGroupArray[i].primaryPhone = uniqueIAGroupObj.primaryPhone;
        uniqueIAGroupArray[i].items = this.getIAGroupItems(uniqueIAGroupObj.iaGroupId, uniqueIAGroupIdList);
    }

    var iaItemslist = [];
    for (var i = 0; i < uniqueIAGroupArray.length; i++) {
        for (var j = 0; j < uniqueIAGroupArray[i].items.length; j++) {
            if(uniqueIAGroupArray[i].items.length > 1){
            iaItemslist.push(uniqueIAGroupArray[i].items[j].svItemId);
            }
        }
    }
    this.nonIAGroupItem = {};
    for(var key in this.allOrderItems){        
        if(iaItemslist.indexOf(key) === -1) {
            //{
           // this.nonIAGroupItem[key] = this.allOrderItems[key];
            if (selectedItemUDAC){
                if (this.allOrderItems[key].order.UDAC == selectedItemUDAC ){
                    this.nonIAGroupItem[key] = this.allOrderItems[key];
                }
            }else{
                this.nonIAGroupItem[key] = this.allOrderItems[key];
            }

        //}
        }
    }

    listOfUniqueDirectoryNames = this.getUniqueDirectoryNames();
    var graphicListingIds = this.getListingIds();
    var listingGroups = this.getListingGroups(graphicListingIds);
    var graphicItems = this.getGraphicItemsMap(listingGroups);

    /* Code to build the graphic items to be displayed in the content-grid */
    var graphicItemsArray = [];
    for (var i = 0; i < graphicItems.length; i++) {
        var currentHeading = graphicItems[i];
        graphicItemsArray[i] = {};
        graphicItemsArray[i].heading = currentHeading.heading;
        graphicItemsArray[i].itemHeading = currentHeading.itemHeading;
        //if($rootScope.UDAC== graphicItemsArray[i].items[0].UDAC){
        graphicItemsArray[i].items = this.getItems(currentHeading.listingId, currentHeading.itemHeading, listOfUniqueDirectoryNames, currentHeading);
        //}
        //Patch for the condition when item[0][0] is blank DE27009
        if(graphicItemsArray[i].items && graphicItemsArray[i].items[0] && graphicItemsArray[i].items[0][0] && graphicItemsArray[i].items[0][0].categoryDisplay){
            graphicItemsArray[i].heading.category = graphicItemsArray[i].items[0][0].category.split(':')[2].split(',')[0].replace(/"/g, '');
        }else if(graphicItemsArray[i].items && graphicItemsArray[i].items[1] && graphicItemsArray[i].items[1][0] && graphicItemsArray[i].items[1][0].categoryDisplay){
            graphicItemsArray[i].heading.category = graphicItemsArray[i].items[1][0].category.split(':')[2].split(',')[0].replace(/"/g, '');
        }

    }


    /* Code to build the print items to be displayed in the content-grid */

    listOfGraphicItems.listOfUniqueDirectoryNames = listOfUniqueDirectoryNames;
    listOfGraphicItems.graphicItemsArray = graphicItemsArray;
    listOfGraphicItems.uniqueIAGroupIdArray = uniqueIAGroupArray;
    listOfGraphicItems.iaItemslist = iaItemslist;

    return listOfGraphicItems;
  };

  buildUniqueIAGroupIdList(selectedUDAC?:string) {
    var IAGroupIdList = [];
    for(var key in this.allOrderItems)
    {
        var products = this.allOrderItems[key].data;
        //var order = allOrderItems[key].order; fix for sonar issue
        if(products && products.iaGroupId){
            if (IAGroupIdList.map(function(e) { return e.iaGroupId; }).indexOf(products.iaGroupId) === -1) {
                if (selectedUDAC && (this.allOrderItems[key].order.UDAC == selectedUDAC)){
                    IAGroupIdList.push({ iaGroupId :  products.iaGroupId, UDAC: this.allOrderItems[key].order.UDAC, businessName: this.allOrderItems[key].order.listingName, primaryPhone: this.allOrderItems[key].order.listingTn});
                }else{
                    IAGroupIdList.push({ iaGroupId :  products.iaGroupId, UDAC: this.allOrderItems[key].order.UDAC, businessName: this.allOrderItems[key].order.listingName, primaryPhone: this.allOrderItems[key].order.listingTn});
                }                    
            }
        }

    }
    return IAGroupIdList;
  };
  getIAGroupItems(iaGroupId, uniqueIAGroupIdList) {
    var items = [];
    var udacForIaGroupId;
    uniqueIAGroupIdList.forEach(function(val,ind){
        if (iaGroupId == val.iaGroupId)
        {
            udacForIaGroupId = val.UDAC
        }
    })

        for(var key in this.allOrderItems) {
            var products = this.allOrderItems[key].data;
            var iaGroupIdItem = (products) ? products.iaGroupId : '';
            if (iaGroupIdItem === iaGroupId) {
                items.push(this.allOrderItems[key].order);
                //$rootScope.IAflag =true;
            }
            else if(iaGroupIdItem === iaGroupId && this.allOrderItems[key].order.UDAC == udacForIaGroupId){
                items.push(this.allOrderItems[key].order);//graphic tab

            }

            else{
                if(iaGroupIdItem === iaGroupId && this.allOrderItems[key].order.UDAC == udacForIaGroupId){
                  items.push(this.allOrderItems[key].order); 
                }
            }
        }
    return items;
  };

  getUniqueDirectoryNames() {
    var listOfUniqueDirectoryNames = [];
    for(var key in this.nonIAGroupItem) {
        var orderItem = this.nonIAGroupItem[key].order;
        if (orderItem.productType === 'Print' || orderItem.productCode === 'YPC') {
            if(this.nonIAGroupItem[key].schema.properties && this.nonIAGroupItem[key].schema.properties.ManageCopysheet){
                if (orderItem.directoryName !== null && listOfUniqueDirectoryNames.indexOf(orderItem.directoryName) === -1) {
                    listOfUniqueDirectoryNames.push(orderItem.directoryName);
                }
                else if((orderItem.directoryName === null || orderItem.directoryName == "") && listOfUniqueDirectoryNames.indexOf("Digital") === -1){
                    listOfUniqueDirectoryNames.push("Digital");
                }
            }
        }
    }
    return listOfUniqueDirectoryNames;
  };
  getListingIds = function() {
    let latestIndicatorValue;
    this.indicatorQuery.getIndicator().subscribe((res) => {
        latestIndicatorValue = { ...res };
    });
    var graphicListingIds = [];
    for(var key in this.nonIAGroupItem) {
        var orderItem = JSON.parse(JSON.stringify(this.nonIAGroupItem[key].order));
        // if (orderItem.productType === 'Print' || orderItem.productCode === 'YPC') {
        //     if(this.allOrderItems[key].schema.properties && this.allOrderItems[key].schema.properties.ManageCopysheet){
        //         if ((graphicListingIds.map(function(e) { return e.id; }).indexOf(orderItem.listingId) === -1) || (graphicListingIds.map(function(e) { return e.geoName; }).indexOf(orderItem.geoName) === -1)) {
        //             graphicListingIds.push({ id :  orderItem.listingId, name : orderItem.categoryDisplay, phone : orderItem.listingTn, geoName:orderItem.geoName});
        //         }
        //     }
        // }
        if (orderItem.productType === 'Print' || orderItem.productCode === 'YPC') {
            let selectedListingId = orderItem.productType == "Print" ? latestIndicatorValue.items[key].model.ListingId : latestIndicatorValue.items[key].model.BusinessProfile;
            let listingTnNum = "";
            // let selectedLIstingId = orderItem.productType == "Print" ? latestIndicatorValue.items[key].model.ListingId : latestIndicatorValue.items[key].model.BusinessProfile;
            if (!selectedListingId){
                selectedListingId = orderItem.listingId;
            }
            if(latestIndicatorValue.items[key].listings){
            for (let i=0; i< latestIndicatorValue.items[key].listings.length; i++){
                if (selectedListingId == latestIndicatorValue.items[key].listings[i].listingId){
                    listingTnNum = latestIndicatorValue.items[key].listings[i].listedTN || (latestIndicatorValue.items[key].listings[i].primaryPhone ? latestIndicatorValue.items[key].listings[i].primaryPhone.value : "" );
                    break;
                }
            }
        }
            if(this.allOrderItems[key].schema.properties && this.allOrderItems[key].schema.properties.ManageCopysheet){
                if ((graphicListingIds.map(function(e) { return e.id; }).indexOf(selectedListingId) === -1) || (graphicListingIds.map(function(e) { return e.geoName; }).indexOf(orderItem.geoName) === -1)) {
                    graphicListingIds.push({ id :  selectedListingId, name : orderItem.categoryDisplay, phone : listingTnNum, geoName:orderItem.geoName});
                }
            }
        } 
    }
    return graphicListingIds;
  }
  getListingGroups(graphicListingIds) {
    var listingGroups = [];
    let tempIndicatorValue;
    this.indicatorQuery.getIndicator().subscribe((res) => {
        tempIndicatorValue = { ...res };
    });
    
    for (var i = 0; i < graphicListingIds.length; i++) {
        var graphicListingId = graphicListingIds[i];
        var listingObject:any = {};
        /*listingObject.businessName = (printListingId === null) ? 'No Listing' : printListingId;*/
        listingObject.id = graphicListingId.id;
        listingObject.businessName = graphicListingId.name;
        listingObject.primaryPhone = graphicListingId.phone;
        listingObject.geoName = graphicListingId.geoName;
        listingObject.headings = [];
        for(var key in this.nonIAGroupItem) {
            var orderItem = this.nonIAGroupItem[key].order;
            let selectedListingId = orderItem.productType == "Print" ? tempIndicatorValue.items[key].model.ListingId : tempIndicatorValue.items[key].model.BusinessProfile;
            if (!selectedListingId){
                selectedListingId = orderItem.listingId;
            }
            if ((orderItem.productType === 'Print' || orderItem.productCode === 'YPC' && orderItem.UDAC != "ML") && (this.nonIAGroupItem[key].schema && this.nonIAGroupItem[key].schema.properties && this.nonIAGroupItem[key].schema.properties.ManageCopysheet) && selectedListingId === graphicListingId.id && orderItem.geoName === graphicListingId.geoName) {
                if (listingObject.headings.indexOf(orderItem.heading) === -1) {
                    listingObject.headings.push(orderItem.heading);
                }
            }
            // else if ((orderItem.productType === 'Print' || orderItem.productCode === 'YPC') && (this.nonIAGroupItem[key].schema.properties.ManageCopysheet) && orderItem.listingId === graphicListingId.id && orderItem.geoName === graphicListingId.geoName)
            // {
            //     if (listingObject.headings.indexOf(orderItem.heading) === -1) {
            //         listingObject.headings.push(orderItem.heading);
            //     }
            // }            
        }
        listingGroups.push(listingObject);
    }
    return listingGroups;
  };
  getGraphicItemsMap(listingGroups) {
    var graphicItems = [];
    for (var i = 0; i < listingGroups.length; i++) {
        var listingGroup = listingGroups[i];
        var headings = listingGroup.headings;
        for (var j = 0; j < headings.length; j++) {
            var graphicItem:any = {};
                graphicItem.heading = {};
                graphicItem.heading.businessName = listingGroup.businessName;
                graphicItem.heading.primaryPhone = listingGroup.primaryPhone;
                graphicItem.heading.geoName = listingGroup.geoName;
            /*printItem.items = [];*/
            graphicItem.itemHeading = headings[j];
            graphicItem.listingId = listingGroup.id;
            graphicItems.push(graphicItem);
        }
    }
    return graphicItems;
  };
  getItems(listingId, itemHeading, listOfUniqueDirectoryNames, currentHeading) {
    let copyItemHeading = itemHeading  
    let items = [];
    let tempIndicatorValue;
    this.indicatorQuery.getIndicator().subscribe((res) => {
        tempIndicatorValue = { ...res };
    });
    for (let j = 0; j < listOfUniqueDirectoryNames.length; j++) {
        let directory = listOfUniqueDirectoryNames[j];
        items[j] = [];
        for(let key in this.nonIAGroupItem) {
            let currentItem = JSON.parse(JSON.stringify(this.nonIAGroupItem[key].order));
            currentItem['copy'] = this.nonIAGroupItem[key].copy;
            //var copysheetURL = ""; fix for sonar issue
            // if ((currentItem.productType === 'Print' || currentItem.productCode === 'YPC') && this.allOrderItems[key].schema.properties.ManageCopysheet &&
            //     currentItem.heading === itemHeading && currentItem.directoryName === directory && currentHeading.heading.geoName === currentItem.geoName && currentItem.UDAC==$rootScope.UDAC && $state.current.name=="multiitems") {
            //     if (currentItem.listingId === listingId) {
            //         currentItem.hasCopysheet = this.nonIAGroupItem[key].hasCopysheet;
            //         currentItem.thumbnail = this.nonIAGroupItem[key].thumbnail;
            //         currentItem.counter = this.nonIAGroupItem[key].counter;
            //         currentItem.isCoysheetSubmitted = this.nonIAGroupItem[key].isCoysheetSubmitted;
            //         items[j].push(currentItem);
            //         //$rootScope.nonIAflag = true;
            //     }
            // }
            if(currentItem.productCode === 'YPC') {
                itemHeading = JSON.parse(currentItem.category)[0].displayedValue;
                if(!currentItem.directoryName){
                    currentItem.directoryName = "Digital";
                }
            }else{
                itemHeading = copyItemHeading
            }
            if(((currentItem.productType === 'Print' && currentItem.heading === itemHeading &&  currentItem.directoryName === directory ) || 
            (currentItem.productCode === 'YPC' && (currentItem.directoryName === directory) && JSON.parse(currentItem.category)[0].displayedValue === itemHeading ) && currentItem.UDAC != "ML" ) 
            && this.allOrderItems[key].schema && this.allOrderItems[key].schema.properties && this.allOrderItems[key].schema.properties.ManageCopysheet &&
            currentHeading.heading.geoName === currentItem.geoName){
                let itemsListingId = "";
                if (currentItem.productCode === "YPC"){
                    itemsListingId = tempIndicatorValue.items[currentItem.svItemId].model.BusinessProfile;
                }else{
                    itemsListingId = tempIndicatorValue.items[currentItem.svItemId].model.ListingId;
                }
                if (!itemsListingId){
                    itemsListingId = tempIndicatorValue.items[currentItem.svItemId].order.listingId;
                }
                if (itemsListingId === listingId) {
                    currentItem.hasCopysheet = this.nonIAGroupItem[key].hasCopysheet;
                    currentItem.thumbnail = this.nonIAGroupItem[key].thumbnail;
                    currentItem.counter = this.nonIAGroupItem[key].counter;
                    currentItem.isCoysheetSubmitted = this.nonIAGroupItem[key].isCoysheetSubmitted;
                    items[j].push(currentItem);
                }
            }
        }
    }
    return items;
  };
}

