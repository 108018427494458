import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { ProfileService } from '../../../core/service/profile/profile.service';
import { UtilsService } from '../../../core/service/utils/utils.service';
import { SalesforceService } from '../../../core/service/salesforce/salesforce.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { constant } from '../../../data/constants';
import { SEMPHoursService } from '@app/service/semphours/semphours.service';
import { IndicatorService } from '@app/service/indicator/indicator.service';
import { WatchHelperService } from '@app/service/watch-helper/watch-helper.service';
import { LoggerService } from '@app/service/logger/logger.service';
import { CounterService } from '@app/service/counter/counter.service';
import { RequiredfieldcountService } from '@app/service/requiredfieldcount/requiredfieldcount.service';
import { StorageService } from '@app/service/storage/storage.service';
import { CopySheetComponent } from '../copy-sheet/copy-sheet.component';
import { UpdateIndicatorService } from '@app/service/updateIndicato/update-indicator.service';
import { IagroupHelperService } from '@app/service/iagroup-helper/iagroup-helper.service';
import { CallconfirmationComponent } from '../call-new/callconfirmation/callconfirmation.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ItemsaveService } from '@app/service/itemsave/itemsave.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-profilepicklist',
  templateUrl: './profilepicklist.component.html',
  styleUrls: ['./profilepicklist.component.scss']
})
export class ProfilepicklistComponent implements OnInit {

  listings: any = [];
  lists: any = [];
  childitem: boolean = false;
  graphicItems: any;
  ngModel = {}
  Item: any;
  Indicator: any;
  titleContent: any;
  listing: string = ''
  campData = []
  selectedCity: any
  displayName: string = '';
  schemaObj;
  isReadonly: boolean = false;
  tootipvalue: string;
  storageID ;

  constructor(private Profile: ProfileService,
    private utils: UtilsService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private salesforceService: SalesforceService,
    private cdr: ChangeDetectorRef,
    private SEMPHours: SEMPHoursService,
    private indicatorService: IndicatorService,
    private watchHelper: WatchHelperService,
    private counterService: CounterService,
    private requiredfieldcount: RequiredfieldcountService,
    private Logger: LoggerService,
    private storageService :StorageService,
    private updateIndicatorService :UpdateIndicatorService,
    private iaGroupHelper : IagroupHelperService,
    private itemSaveService: ItemsaveService,
    private matDialog :MatDialog) { }

  ngOnInit() {

    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
    })
    this.storageID = this.Indicator.stateParamsId + "v" +this.Indicator.items[this.Indicator.stateParamsId].order.version;
    this.getListingDtls();
    if (this.Indicator.items[this.Indicator.stateParamsId].order.parentId) {
      let parentId = this.Indicator.items[this.Indicator.stateParamsId].order.parentId;
      this.ngModel = this.Indicator.items[parentId].model.ListingId ? this.Indicator.items[parentId].model.ListingId : this.Indicator.items[parentId].model.BusinessProfile;
    } else {
      this.ngModel = this.Indicator.items[this.Indicator.stateParamsId].model.ListingId ? this.Indicator.items[this.Indicator.stateParamsId].model.ListingId : this.Indicator.items[this.Indicator.stateParamsId].model.BusinessProfile;
    }
    if (!this.ngModel) {
      this.ngModel = this.Indicator.items[this.Indicator.stateParamsId].model.listingId || this.Indicator.items[this.Indicator.stateParamsId].order.listingId;
    }
    this.schemaObj = this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name];
    this.isReadonly = this.Indicator.items[this.Indicator.stateParamsId].order.parentId ? true : this.schemaObj.readonly;
    this.titleContent = this.schemaObj.title;
   
  }

  sendValue(value) {
    let oldValue = this.Indicator.items[this.Indicator.stateParamsId].model.BusinessProfile
    this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
    if (this['layoutNode'].name === "BusinessProfile") {
      this.updateOtherModels(value, oldValue);
      let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId].model))
      modelData.ListingId  = value;
      if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew') {
        modelData.renewalCopysheetRequiredFlag = true      
      }
      this.indicatorQuery.updateModel(this['layoutNode'].name, value, modelData, this.Indicator, '[ProfilepicklistComponent] sendValue-1');


    }
    if (this['layoutNode'].name === "ListingId") {
      this.updateOtherModels(value, oldValue);
      let  iaGrouppresent = this.isIAGroupPresent(this.Indicator);
      let upadatedIndicator = JSON.parse(JSON.stringify(this.Indicator));
      if(iaGrouppresent === true){
        let msg= "Updating the value for listing will move this item out of its current IA Group";
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          message: msg,
        }
        dialogConfig.width = '600px';
        const dialogRef = this.matDialog.open(CallconfirmationComponent,dialogConfig);
        dialogRef.afterClosed().subscribe(dialogResult => {
          let result = dialogResult;
          if(result){
            let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId].model))
            modelData.BusinessProfile  = value;
            if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew') {
              modelData.renewalCopysheetRequiredFlag = true      
            }
            this.indicatorQuery.updateModel(this['layoutNode'].name, value, modelData, this.Indicator, '[ProfilepicklistComponent] sendValue-2');
            this.iaGroupHelper.splitIAGroup(this.Indicator.stateParamsId,"splitiaitempage");

          }else{
            //TODO : Check if we dont want to split ia while BP change then stop and dont update store
          }
        })

      }else{
        let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][this.Indicator.stateParamsId].model))
        modelData.BusinessProfile  = value;
        if (this.Indicator.items[this.Indicator.stateParamsId].order.actionCode === 'Renew') {
          modelData.renewalCopysheetRequiredFlag = true      
        }
        this.indicatorQuery.updateModel(this['layoutNode'].name, value, modelData, this.Indicator, '[ProfilepicklistComponent] sendValue-3');
        let itemsUpdate = [this.Indicator.stateParamsId]
        this.Indicator =  JSON.parse(JSON.stringify(this.indicatorStore.getValue().indicator));
        this.itemSaveService.update(this.Indicator, itemsUpdate);
      }

      if(this.Indicator.items[this.Indicator.stateParamsId].model.hasOwnProperty('ManageCopysheet') && this.Indicator.items[this.Indicator.stateParamsId].model.ManageCopysheet){
        this.copysheetnotify();
        CopySheetComponent.reTrigger();
        let stateParamsId =  this.Indicator.stateParamsId;
        this.Indicator =  JSON.parse(JSON.stringify(this.indicatorStore.getValue().indicator));
        let tempData = this.requiredfieldcount.getNewItemCount( this.Indicator["items"][stateParamsId].requiredFields,  this.Indicator["items"][stateParamsId].model,  this.Indicator["items"][stateParamsId].order,  this.Indicator["orderPercent"],  this.Indicator["items"][stateParamsId].counter, true,  this.Indicator["items"][stateParamsId]);
        this.updateIndicatorService.updateIndictor( this.Indicator.stateParamsId,  this.Indicator, tempData, this.Indicator.items[ this.Indicator.stateParamsId].counter);
        this.ngAfterViewChecked();
      }
    }

  }
  ngAfterViewChecked() {
    if (!(<ViewRef>this.cdr).destroyed)
      this.cdr.detectChanges();
  }

  getListingDtls() {
    let tempIndicator = JSON.parse(JSON.stringify(this.Indicator));
    let account = tempIndicator.account;
    if (tempIndicator.items[tempIndicator.stateParamsId].order.productType == "Digital") {

      let lists = tempIndicator.items[tempIndicator.stateParamsId].listingArray ? tempIndicator.items[tempIndicator.stateParamsId].listingArray : [];
      this.lists = lists;
      for (let i = 0; i < lists.length; i++) {
        let phoneNumLocal;
        let name = lists[i].name;
        let displayedMissingDataError: boolean = false;
        if (tempIndicator.profiles[lists[i].value] != null) {
          //formatting required for below
          // phoneNumLocal = this.utils.transformTelephone(tempIndicator.profiles[lists[i].value].model.BusinessPhone); //$filter('telephone')(tempIndicator.profiles[lists[i].listingId].model.BusinessPhone, false);
          phoneNumLocal = tempIndicator.profiles[lists[i].value].model.BusinessPhone;
          // name = tempIndicator.profiles[lists[i].value].model.BusinessName + "," + phoneNumLocal + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddress1 + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddressCity + ", " + tempIndicator.profiles[lists[i].value].model.BusinessAddressState + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddressZip.zip;
          name = tempIndicator.profiles[lists[i].value].model.BusinessName + "," + phoneNumLocal + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddress1 + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddressCity + ", " + tempIndicator.profiles[lists[i].value].model.BusinessAddressState + " " + tempIndicator.profiles[lists[i].value].model.BusinessAddressZip.zip;
          if (lists[i].BusinessAddressZip !== undefined && lists[i].BusinessAddressZip.zip4.trim() !== '') {
            name += '-' + lists[i].BusinessAddressZip.zip4
          }
        }
        if (tempIndicator.items[tempIndicator.stateParamsId].order.productType == "Digital" &&
          (tempIndicator.items[tempIndicator.stateParamsId].order.listingId && tempIndicator.items[tempIndicator.stateParamsId].order.listingId == lists[i].listingId)
          || (tempIndicator.items[tempIndicator.stateParamsId].model.BusinessProfile && tempIndicator.items[tempIndicator.stateParamsId].model.BusinessProfile == lists[i].listingId)) {
          if (lists[i].primaryPhone) {
            let phoneNumLocal = this.utils.transformTelephone(lists[i].primaryPhone.value);
          } else {
            if (!displayedMissingDataError) {
              this.Logger.logError("An listing without a primary phone number cannot be published in Content Assistant.  Update the listing before launching CA");
              displayedMissingDataError = true;
            }
          }
        }

        let listing = {
          name: name,
          value: lists[i].value,
          index: i
        };

        this.listings.push(listing);
        this.listings.forEach(element => {
          if (element.value == this.ngModel) {
            this.tootipvalue = element.name;
          }
        });
        if (!(<ViewRef>this.cdr).destroyed)
          this.cdr.detectChanges();
        //DE29031 fixed
        if ((lists[i].value === tempIndicator.items[tempIndicator.stateParamsId].order.listingId) && (tempIndicator.items[tempIndicator.stateParamsId].order.productCode !== "SEMP" && tempIndicator.items[tempIndicator.stateParamsId].order.productCode !== "SEM")) {
          this.ngModel = this.lists[i].value;
          tempIndicator['listing'] = this.lists[i];
          tempIndicator.items[tempIndicator.stateParamsId].listing = this.lists[i];
        }
      }

      if (this.ngModel != null && this.ngModel["listingId"] != null) {
        this.ngModel = tempIndicator.mylistingId ? tempIndicator.mylistingId : this.ngModel["listingId"];

      }
    } else {
      //FUL-28888-CA is stuck at 98% due to listing is blank in CA//FIX
      let lists = tempIndicator.items[tempIndicator.stateParamsId].listings 
      this.lists = lists;
      this.campData = lists
      let directory_code = null;
      if(lists != null && lists != undefined){
      for (let i = 0; i < lists.length; i++) {
        directory_code = tempIndicator.items[tempIndicator.stateParamsId].order.directoryNumber || "";

        let listing = {
          // name: lists[i].listedName + "," + lists[i].listedTN + " " + lists[i].address + " " + directory_code,
          // name: lists[i].listedName + "," + lists[i].listedTN + "," + lists[i].address + "," + tempIndicator.account.address.country + "," + directory_code,
          name: lists[i].listedName + "," + lists[i].listedTN + " " + lists[i].address +  " " + directory_code,
          value: lists[i].listingId,
          index: i
        };
        this.listings.push(listing);
        if (!(<ViewRef>this.cdr).destroyed)
          this.cdr.detectChanges();
        if (this.ngModel == null && lists[i].listingId === tempIndicator.items[tempIndicator.stateParamsId].order.listingId) {
          this.ngModel = lists[i].listingId;
          tempIndicator.listing = this.lists[i];
          tempIndicator.items[tempIndicator.stateParamsId].listing = this.lists[i];
        }
        // tempIndicator['lists'] = this.lists[i];

      }
      }
      //Fix for DE15326
      if (this.ngModel === undefined) {

        // Check account ID 
        this.salesforceService.getAssetListing(tempIndicator.items[tempIndicator.stateParamsId].order.svItemId, tempIndicator.account.id)
          .subscribe(result => {
            this.ngModel = result["records"][0].Listing_Id__c;
            for (let i = 0; i < this.lists.length; i++) {
              if (this.lists[i].listingId === result["records"][0].Listing_Id__c) {
                tempIndicator.listing = this.lists[i];
                tempIndicator.items[tempIndicator.stateParamsId].listing = this.lists[i];
              }
            }
          }, err => {
            this.Logger.logError("<strong>Error in get Listing from Asset <strong><br>Source System : Salesforce <br>" + JSON.stringify(err));
          })
      }
      tempIndicator.listing = this.lists;  
      
    }
    this.indicatorStore.update((state) => {
      return {
        indicator: tempIndicator,
        updatedFrom: "[ProfilepicklistComponent] getListingDtls",
        isloaded: true,
      };
    });

    //});
  }
  async updateOtherModels(value, oldValue) {
    this.Indicator = JSON.parse(JSON.stringify(this.Indicator));
    let modelData = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
    modelData.items[this.Indicator.stateParamsId].model[this['layoutNode'].name] = value;
    modelData = await this.watchHelper.setItemValuesFromBP(this.Indicator.stateParamsId, value, modelData, "bp");
    modelData.bundles.forEach((bundle, index, bundles) => {
      bundle.children.forEach((child, childIndex, children) => {
        let newIndicator;
        if (bundle.parent == modelData.stateParamsId && modelData.items[child] && modelData.items[bundle.parent].model) {
          if (modelData.items[bundle.parent].model[this['layoutNode'].name]) {
            newIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
            newIndicator.items[child].model[this['layoutNode'].name] = modelData.items[bundle.parent].model[this['layoutNode'].name];
           }

         // newIndicator =  this.watchHelper.setItemValuesFromBP(child, value, newIndicator, "bp");
          if (this.Indicator.items[bundle.parent].order.productCode === "DPM") {
            newIndicator.items[bundle.parent].model.initialDataSet = true;
          }
          
          this.indicatorQuery.updateModel(this['layoutNode'].name, value, newIndicator.items[child].model, newIndicator, '[ProfilepicklistComponent] updateProfilesChild-updateOtherModels');
          //Condition for DPM DE16820 Fix

        }
      })
    })
    if (this['layoutNode'].name === "BusinessProfile") {
      let latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      //remove old profile
      if (oldValue && oldValue != undefined && oldValue != null && oldValue != '') {
        this.indicatorService.removeProfileById(latestIndicator, oldValue)
        this.indicatorQuery.updateProfilesListingId(latestIndicator, '[ProfilepicklistComponent] updateProfiles-updateOtherModels');
      }
      //add new profile
      await this.indicatorService.addProfileById(latestIndicator, value)
      latestIndicator = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      let existingListingIds = latestIndicator.listingIds
      if (existingListingIds != undefined && existingListingIds != null) {
        existingListingIds.push(value)
      } else {
        existingListingIds = []
        existingListingIds.push(value)
      }
      this.indicatorQuery.updateProfiles(value, existingListingIds, latestIndicator, '[ProfilepicklistComponent] updateProfiles-updateOtherModels');
      //update the counter bp count
      let counter = []
      const { bpcount, bpcountMax, bpTotalCount } = this.requiredfieldcount.getBPCount(latestIndicator.profiles);
      latestIndicator.orderPercent.bpTotalCount = bpTotalCount;
      counter.push(latestIndicator.orderPercent.digitalCount);
      counter.push(latestIndicator.orderPercent.printCount);
      counter.push(latestIndicator.orderPercent.bpTotalCount);
      this.counterService.setError(counter);
      this.indicatorStore.update(() => {
        return {
          indicator: latestIndicator,
          updatedFrom: "[ProfilepicklistComponent] BP",
          isloaded: true,
        };
      });
    }
    //this.SEMPHours.setHOOConfirmationsInIndicator(modelData);

  }
  copysheetnotify(){
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.Indicator = {...data};
    });
    let upadatedName = JSON.parse(JSON.stringify(this.Indicator));
    upadatedName.items[this.Indicator.stateParamsId].hasCopysheet = false;
    this.storageService.updateField("copyz", this.storageID, { "data.hasCopysheet": false })
    .subscribe(()=>{},err=>{})
      if(upadatedName.items[this.Indicator.stateParamsId].order.productCode == 'YPC' ||  this.Indicator.items[this.Indicator.stateParamsId].order.productType == "Print"){
          if(upadatedName.items[this.Indicator.stateParamsId].order.productCode == 'YPC'){
            upadatedName.items[this.Indicator.stateParamsId].model.ManageCopysheet = 'Y';
          }else{
            upadatedName.items[this.Indicator.stateParamsId].model.ManageCopysheet.copySheetIndicator = 'Y';
          }
          upadatedName.items[this.Indicator.stateParamsId].requiresCopysheet =  true;

      }

    this.indicatorStore.update((state) => {
      return {
        indicator:upadatedName,
        updatedFrom: "[ProfilepicklistComponent] CS notify",
        isloaded: true,
      };
    });
  }
  isIAGroupPresent(indicator){
    let iaGrouppresent = false;
    for (let k in indicator.items) {
        if (k !== indicator.stateParamsId && indicator.items[indicator.stateParamsId].data.iaGroupId != undefined) {
            if (indicator.items[k].data.iaGroupId === indicator.items[indicator.stateParamsId].data.iaGroupId) {
                iaGrouppresent = true;
            }
        }
    }
    return iaGrouppresent;
  };

}
