import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { ParamsService } from '../params/params.service';
@Injectable({
  providedIn: 'root'
})

export class AccountService {
  baseUri: string = '/api/account';
  constructor(
    private http: HttpClient,
    private Params: ParamsService,
    private API: ApiService
  ) { }
  /**
   *
   * @param id - this is account id to get the account details fro salesforce
   */
  get(id: string): Observable<any> {
    //this.httpOptions.id = id;
    return this.http.get(this.API.get(this.baseUri) + "/" + id).catch(err =>{
      return err;
    });
  };
  /**
   * this method is used with params.accountid stored in the localstorage
   */
  getMe(): Observable<any> {
    return this.get(this.Params.params.account).catch(err=>{
      return err;
    });
  };

  getMetoPromise() {
    return this.get(this.Params.params.account).toPromise();
  };

}
