import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import {msgSpecialities} from 'app/data/constants';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-specialtiesitemdirective',
  templateUrl: './specialtiesitemdirective.component.html',
  styleUrls: ['./specialtiesitemdirective.component.scss']
})
export class SpecialtiesitemdirectiveComponent implements OnInit {
  msg;
  sharedData;
  isReadonly :boolean = false;
  specialityValue;
  oldValue ;
  constructor(private indicatorQuery: IndicatorQuery, private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.msg = msgSpecialities;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = JSON.parse(JSON.stringify(res));
    });
    this.specialityValue  = this.sharedData.items[this.sharedData.stateParamsId].model.SpecialtiesLPL ;
    this.isReadonly =this.sharedData['items'][this.sharedData.stateParamsId].schema.properties[this['layoutNode'].name].readonly;
  }
  onModelChange(value){
    let  tempObj =JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
      //this.specialityValue =value;
    // if (!(/^([^;]+;){0,9}[^;]*$/.test(value))) {
    //   if(value.substr(value.length - 1)==';') {
    //     this.oldValue = !this.oldValue ? value :this.oldValue;
    //     //console.log("this.specialityValue",this.specialityValue) ;
    //     this.specialityValue =   value.substr(0,value.length - 1);
    //     return;
    //   }
    //   ////this.oldValue = !this.oldValue ? value :this.oldValue;
    //   //this.specialityValue = value;
    //   this.specialityValue = this.oldValue.substr(0,this.oldValue.length - 1);
    //  } else{
    //   this.specialityValue =value
    //  }
     let valArray = value.split(';');
     let stringValue;
      if(valArray.length >10){
        valArray.splice(10,valArray.length-10);
        stringValue = valArray.join(';').toString();
        stringValue = stringValue +";"; // this is actually not a good coding but it is  temporary fixing issue
        //console.log("stringValue", stringValue);
        this.specialityValue = value.substr(value.length - 1)==';' ? stringValue.substr(0,stringValue.length - 1) :stringValue;
   // this.specialityValue= stringValue.substr(0,stringValue.length);
      }else{
        this.specialityValue=value;
      }
        //console.log(" this.specialityValue", this.specialityValue)

   tempObj.items[this.sharedData.stateParamsId].model.SpecialtiesLPL =  this.specialityValue ;
   let x = JSON.parse(JSON.stringify(this.sharedData))
   x = tempObj
    this.indicatorStore.update(state =>{
      return {
        indicator:x,
        updatedFrom: "[SpecialtiesitemdirectiveComponent] onModelChange",
        isloaded: true,
      };
    })
  }

}
