import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ParamsService } from '@app/service/params/params.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { Quickbp } from 'app/interface/quickbp';
import { OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { StorageService } from '@app/service/storage/storage.service';
import { CallconfirmationComponent } from '../components/call-new/callconfirmation/callconfirmation.component';
import * as _ from 'lodash';
var that;
export class BusinessprofileModel implements OnInit {
  indicatorValue: Quickbp
  subscribe: any
  accountId = '';
  eaId = '';
  mode = '';
  result: string = '';

  constructor(private router: Router,
    private params: ParamsService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private StorageService: StorageService,
    private matDialog: MatDialog,
  ) {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.indicatorValue = { ...res };
      that = this;
    });
  }
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
  columns: any = {
    "businessProfileGrid": [
      {
        title: "", field: "counter", width: 5, cssClass: 'bp-tabulator-cell', formatter: function (cell, formatterParams) {
          var value = cell.getValue();
          if (value > 0) {
            return "<div style='min-width:6px;min-height:49px;background:#df2222;margin-top: -4px;'></div>"
          }
          return;
        }
      },
      {
        title: "copy", align: "center", width: 100, cssClass: 'bp-tabulator-cell', formatter: function (cell, formatterParams) {
          return "<i class='fa fa-clone'></i>";
        },
        cellClick: (e, cell) => {
          let rowData = cell.getRow().getData();

          let msg = 'Are you sure you want to copy the selected BP? ';
          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'copybp-modal';
          dialogConfig.data = {
            message: msg,
          }
          dialogConfig.width = '600px';
          const dialogRef = this.matDialog.open(CallconfirmationComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(dialogResult => {
            this.result = dialogResult;
            if (this.result) {
              console.log(" clicked the positive response");
              this.indicatorValue = this.indicatorQuery.getValue().indicator;
              let tempDefaultData = Object.assign({},this.indicatorValue['profiles'][""]['model'])
              this.indicatorValue['tempDefaultData'] = tempDefaultData;
              this.indicatorValue['defaultCopyBP'] = 'Y';
              this.indicatorStore.update(state => {
                return {
                  indicator: this.indicatorValue,
                  updatedFrom: "[BusinessprofileModel] clicked",
                }
              })
              for (var key in this.indicatorValue['profiles']) {
                if (key === rowData['listingId']) {
                  this.indicatorValue['profiles'][""].model = _.cloneDeep(this.indicatorValue['profiles'][key].model);
                  this.indicatorValue['profiles'][""].data = _.cloneDeep(this.indicatorValue['profiles'][key].data);
                  that.params.params.isCopyBP = 'true';

                  for (let i = 0; i < this.indicatorValue['profiles'][key].menu[0].length; i++) {
                    //angular.copy(Indicator.profiles[key].menu[0][i].counter, Indicator.profiles[""].menu[0][i].counter);
                    this.indicatorValue['profiles'][""].menu[0][i].counter = this.indicatorValue['profiles'][key].menu[0][i].counter;
                  }

                  //Remove assets if its IYP
                  if (this.indicatorValue['orders']) {
                    if (this.indicatorValue.hasOwnProperty('indicatorOrder') && this.indicatorValue['orders'] != null && !(this.indicatorValue['orders'] === undefined)) {
                      if (this.indicatorValue['orders'].hasOwnProperty('OrderItems') && this.indicatorValue['orders'].OrderItems && this.indicatorValue['orders'].OrderItems != null && this.indicatorValue['orders'].OrderItems !== undefined) {
                        this.indicatorValue['orders'].OrderItems.forEach((item: any) => {
                          if (item.productType === "Digital" && item.actionCode !== "Cancel") {
                            if (item.description === "Online Listing Premium" || item.description === "Online Listing") {
                              let listingId;
                              let itemData = this.indicatorValue['items'][item.svItemId].model;
                              if (itemData.hasOwnProperty("BusinessProfile") && itemData.BusinessProfile != undefined && itemData.BusinessProfile != null && itemData.BusinessProfile != "") {
                                listingId = itemData.BusinessProfile;
                              } else {
                                listingId = itemData.listingId;
                              }
                              if (listingId == rowData['listingId']) {
                                //If BP is associated with IYP thene delete the asset;
                                if (this.indicatorValue['profiles'][""].model.hasOwnProperty("assets"))
                                  delete this.indicatorValue['profiles'][""].model.assets;
                              }
                            }
                          }
                        })
                      }
                    }
                  }
                  that.StorageService.update("copyBP", " ", { isCopyBP: "Y" });
                }
              }
              let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
						x["isCopyBP"] = "true";
						this.indicatorStore.update(state =>{
							return{
								indicator: x,
                updatedFrom: "[BusinessProfilesmModel] CopyBP",
								isloaded: true
							}
						});
              that.router.navigate(['/business/business/0']);

            } else {
              console.log(" into negative response");
            }
          });
        }
      },
      {
        title: "Name", field: "name", width: 200, formatter: 'textarea', cssClass: 'tabulator-custom-title-Color bp-tabulator-cell',
        cellClick: (e, cell) => {
          let x = JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator));
						x["isCopyBP"] = "false";
						this.indicatorStore.update(state =>{
							return{
								indicator: x,
                updatedFrom: "[BusinessProfilesModel] ListingCellClick",
								isloaded: true
							}
						});
          this.router.navigate([`/business/business/${cell.getRow().getData().id}`]);
        },
      },
      { title: "Address", field: "address", cssClass: 'bp-tabulator-cell' },
      { title: "BTN", field: "BTN", width: 120, cssClass: 'bp-tabulator-cell' },
      { title: "Primary Category", field: "PrimaryCat", width: 200, formatter: 'textarea', cssClass: 'bp-tabulator-cell' },
      { title: "Business Categories", field: "BusinessCategories", width: 300, formatter: 'textarea', cssClass: 'bp-tabulator-cell' },
      { title: "listing Id", field: "listingId", cssClass: 'bp-tabulator-cell' }
    ],
  }
}
