import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Constants } from '@app/service/datasource/constants';
import { ParamsService } from '@app/service/params/params.service';
import { ProfileService } from '@app/service/profile/profile.service';
import { SharedService } from '@app/service/shared/shared.service';
import { StorageService } from '@app/service/storage/storage.service';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';
import { LoggerService } from '@app/service/logger/logger.service';
import { Router } from '@angular/router';
import { Quickbpclass } from 'app/interface/quickbp';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilsService } from '@app/service/utils/utils.service';
import { SalesforceService } from '@app/service/salesforce/salesforce.service';
import { CallconfirmationComponent } from '../call-new/callconfirmation/callconfirmation.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from 'app/layout/main/spinner/spinner.service';

@Component({
  selector: 'app-delete-business-profile',
  templateUrl: './delete-business-profile.component.html',
  styleUrls: ['./delete-business-profile.component.scss']
})
export class DeleteBusinessProfileComponent implements OnInit {

  sharedData: Quickbpclass;
  deleteBPFlag: boolean = false;
  confirmDeleteBP: boolean = false;
  form: FormGroup;
  reasons = [];
  selectedReason = {
    'Reason': '',
    'Code': ''
  };

  id: any;
  reasonValue: boolean = true;
  DuplicateNoValue;
  dupToLid = "";
  deleteBPresponse;
  saveText = this.constants.saveText;
  deleteBPClass: boolean = false;
  dupToLidflag: boolean = false;
  buttonSpinner: boolean = false;
  public customClass = 'final-modal-container';
  spinnerText:string;
  result: string = '';
  eaId :any;

  constructor(private StorageService: StorageService,
    private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore,
    private Utils: UtilsService,
    private Profile: ProfileService,
    private Params: ParamsService,
    private formBuilder: FormBuilder,
    private salesforceService: SalesforceService,
    private Shared: SharedService,
    private router: Router,
    private Logger: LoggerService,
    private constants: Constants,
    private cdr: ChangeDetectorRef,
    private ngxspinner: NgxSpinnerService,
		private spinnerTextService: SpinnerService,
    private dialog: MatDialog,
    private dialogRefDelete: MatDialogRef<DeleteBusinessProfileComponent>,
    private dialogRefConfirm: MatDialogRef<CallconfirmationComponent>) {
      this.form = this.formBuilder.group({
        reasons: ['']
      });
    // async res
    of(this.getReasons()).subscribe(res => {
      this.reasons = res;
    });

  }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe(data => {
      this.sharedData = JSON.parse(JSON.stringify(data));
    })
    this.Params.params.mode = sessionStorage.getItem("mode");

    var name = "PROFILE_DELETEBP";
    //check if dexmedia search mode listing is associated with any account and if yes then don't show deleteBP Button
    var freeListingFlag: boolean = false;
    if(this.sharedData && this.sharedData['account'] &&this.sharedData['account']['enterpriseAccountId'] ){
    this.eaId = this.sharedData['account']['enterpriseAccountId'];
    } else {
      this.eaId = "";
    }
    try{
      if (this.Params.params.mode === "listing") {
      if (this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds != null && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0] != undefined && this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0] != null)
        this.eaId = this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId[0];
      freeListingFlag = (this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds == null) ? true : false;
    }
    for (let i in this.sharedData.configuration) {
      if (this.sharedData.configuration[i].name == name) {
        if (this.sharedData['user']["profileName"] && this.sharedData.configuration[i].value.indexOf(this.sharedData['user']["profileName"]) >= 0 && (this.Params.params.mode === "ManageBP" || freeListingFlag) && (this.sharedData.stateParamsId !== "")) {
          this.deleteBPClass = true;
        }
      }
    }}catch(e){
      console.log( "error in listing mode button component :",e);
    }
  }
  getReasons() {
    return [{ Reason: "Out of Business", Code: "OOB" },
    { Reason: "Incorrect Information", Code: "BAD" },
    { Reason: "Opt Out Requested", Code: "OUT" },
    { Reason: "Duplicate to", Code: "DUP" },
    { Reason: "Created in Error", Code: "ERR" }];
  }
  changeReason(e) {
    this.dupToLidflag = false;
    this.selectedReason.Code = e.value;
    if (this.selectedReason.Code == "DUP") {
      this.DuplicateNoValue = true;
      this.reasonValue = true;
    } else {
      this.reasonValue = false;
      this.DuplicateNoValue = false;
    }

  }
  selectedReasonValue(e) {
    this.dupToLid = e.target.value;
    if (this.selectedReason.Code == "DUP") {
      this.dupToLidflag = true;
      this.reasonValue = true;
      this.DuplicateNoValue = true;
      if (this.dupToLid.length == 0) {
        this.reasonValue = true;
        this.dupToLidflag = true;
        this.dupToLid = "";
      } else if (this.dupToLid.length < 10) {
        this.reasonValue = true;
        this.dupToLidflag = true;
      } else {
        this.reasonValue = false;
        this.dupToLidflag = false;
      }
    } else {
      this.DuplicateNoValue = false;
      this.reasonValue = false;
      this.dupToLid = "";
    }
  }
  delete() {
    this.confirmDeleteBP = true;
  }
  dismiss() {
    this.deleteBPFlag = false;
    this.reasonValue = true;
    this.DuplicateNoValue = false;
    this.form.reset();
    // this.deleteBpConfirmation.hide();
    this.dialogRefDelete.close();

  }
  public onOpenDialog() {
    // this.dialog.open(content,{width:'598px'})
    // this.confirmDeleteBP = true;
    let msg= 'Selecting "Delete This Business Profile" will delete the entire profile with no possible retrieval. Are you sure you want to delete this BP?';
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: msg,
    }
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'delete-bp-confirm-modal'
    this.dialogRefConfirm = this.dialog.open(CallconfirmationComponent,dialogConfig);
    this.dialogRefConfirm.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result){
        this.deleteBP();
      }else{
        this.reasonValue = false;
        this.dialogRefConfirm.close();
      }
    });
  }
  // // Hide the Dialog when click the footer button.
  // public hideDialog() {
  //   this.reasonValue = false;
  //   this.confirmDeleteBP = false;
  // }

  deleteBP() {
    // this.buttonSpinner = true;
    //this.spinnerService.show();
    //var listingEaid = this.sharedData['profiles'][this.sharedData.stateParamsId].data.listings.listing["0"].enterpriseAccountIds.enterpriseAccountId["0"];
    this.ngxspinner.show();
    this.spinnerTextService.setSpinnerText(this.constants.BP_DELETE_PROFILE);
    if (this.Params.params.mode == "listing") {
      this.Profile.delete(this.Utils.sourcetype, "listing", this.sharedData.stateParamsId, this.selectedReason.Code, this.dupToLid)
        .then(response => {
          this.deleteBPresponse = response;
          // for (let key in this.sharedData['profiles']){
          //     if(this.sharedData.stateParamsId==this.sharedData['profiles'][key].data.listings.listing["0"].listingId){
          //         delete this.sharedData['profiles'][key];
          //         break;
          //     }
          // }
          this.confirmDeleteBP = false;
          this.deleteBPFlag = false;
          // this.deleteBpConfirmation.hide();
          //this.spinnerService.hide();
          this.dialogRefConfirm.close();
          this.dialogRefDelete.close();
		      this.ngxspinner.hide();
          this.Logger.logSuccess("Business Profile Deleted will be removed from Publishing in the nightly batch");

        }, error => {
          this.confirmDeleteBP = false;
          this.deleteBPFlag = false;
          // this.ejDialog.hide();
          // this.deleteBpConfirmation.hide();
          var respObj = error.error.response;
          //this.spinnerService.hide();
          this.dialogRefConfirm.close();
          this.dialogRefDelete.close();
          this.ngxspinner.hide();
          if (respObj.hasWarningOrError === "true") {
            var errorObject = respObj.esbServiceFault[0];
            var errorCode = errorObject.exceptionDetail[0].errorCode;
            var errorDesc = errorObject.exceptionDetail[0].errorDescription;
            var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
            this.Logger.logError(errorMsg);

          }
        });
    } else {
      this.eaId = this.sharedData['account']['enterpriseAccountId'] ? this.sharedData['account']['enterpriseAccountId'] : "";
      this.salesforceService.deleteBPitems(this.sharedData.stateParamsId, this.eaId, this.sharedData['account']['id'] ).then(result => {
        //if (result.totalSize >= 1) {          
          if (result >= 1) {
          this.sharedData['bpDirtyFlag'] = false;
						this.indicatorStore.update(state =>{
							return{
								indicator: this.sharedData,
                updatedFrom: "[DeleteBusinessProfileComponent] deleteBP",
								isloaded: true
							}
						});
          this.router.navigate(['/business/managebp'], { queryParams: this.Params.params.values });
          // this.ejDialog.hide();
          // this.deleteBpConfirmation.hide();
          //this.spinnerService.hide();
          this.dialogRefConfirm.close();
          this.dialogRefDelete.close();
          this.ngxspinner.hide();
          this.Logger.logError("Deletion not allowed. Business Profile is associated with active or pending advertising");
        } else {
          this.salesforceService.deleteBPassets(this.sharedData.stateParamsId, this.eaId ,this.sharedData['account']['id']).then(result => {
            //if (result.totalSize >= 1) {              
              if (result >= 1) {
              this.sharedData['bpDirtyFlag'] = false;
							this.indicatorStore.update(state =>{
								return{
									indicator: this.sharedData,
                  updatedFrom: "[DeleteBusinessProfileComponent] deleteBP1",
									isloaded: true
								}
							});
              this.router.navigate(['/business/managebp'], { queryParams: this.Params.params.values });
              // this.ejDialog.hide();
              // this.deleteBpConfirmation.hide();
              //this.spinnerService.hide();
              this.dialogRefConfirm.close();
              this.dialogRefDelete.close();
              this.ngxspinner.hide();
              this.Logger.logError("Deletion not allowed. Business Profile is associated with active or pending advertising");
            } else {
              this.Profile.delete(this.Utils.sourcetype, "listing", this.sharedData.stateParamsId, this.selectedReason.Code, this.dupToLid)
                .then(response => {
                  this.deleteBPresponse = response;
                  for (let key in this.sharedData['profiles']) {
                    if (this.sharedData.stateParamsId == this.sharedData['profiles'][key].data.listings.listing["0"].listingId) {
                      delete this.sharedData['profiles'][key];
                      break;
                    }
                  }
                  if (this.Params.params.mode != "listing") {
                    this.sharedData['bpDirtyFlag'] = false;
                    this.indicatorStore.update(state =>{
                      return{
                        indicator: this.sharedData,
                        updatedFrom: "[DeleteBusinessProfileComponent] deleteBP2",
                        isloaded: true
                      }
                    });
                    this.router.navigate(['/business/managebp'], { queryParams: this.Params.params.values });
                  }
                  // this.ejDialog.hide();
                  // this.deleteBpConfirmation.hide();
                  //this.spinnerService.hide();
                  this.dialogRefConfirm.close();
                  this.dialogRefDelete.close();
                  this.ngxspinner.hide();
                  this.Logger.logSuccess("Business Profile Deleted will be removed from Publishing in the nightly batch");

                }, error => {
                  this.deleteBPFlag = false;
                  // this.ejDialog.hide();
                  // this.deleteBpConfirmation.hide();
                  this.confirmDeleteBP = false;
                  var respObj = error.error.response;
                  //this.spinnerService.hide();
                  this.dialogRefConfirm.close();
                  this.dialogRefDelete.close();
                  this.ngxspinner.hide();

                  if (respObj.hasWarningOrError === "true") {
                    var errorObject = respObj.esbServiceFault[0];
                    var errorCode = errorObject.exceptionDetail[0].errorCode;
                    var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                    var errorMsg = "<strong>Transaction : " + respObj.transactionId + "</strong><br><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    this.Logger.logError(errorMsg);

                  }
                });
            }
          })
        }
      });
    }

  }

}

