import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { constant } from 'app/data/constants';

@Injectable({
  providedIn: 'root'
})
export class NodeApiEnvUrlService {

  constructor(private cookieService: CookieService) { }
  getNodeUrl(service) {
    let apiUrl = "https://ucc-pm.dexmedia.com";
    if (service == "okta") {
      //console.log("location.origin.indexOf",location.origin);
      let LOGIN_REDIRECT_URI = 'https://localhost:3000/callback';
      let LOGOUT_REDIRECT_URI = 'https://localhost:3000/';
      if (location.origin.indexOf('ucc-pm') > 0) {
        LOGIN_REDIRECT_URI = "https://ucc-pm.dexmedia.com/callback";
        LOGOUT_REDIRECT_URI = 'https:///ucc-pm.dexmedia.com/';
      } else if (location.origin.indexOf('ucc-dev') > 0) {
        LOGIN_REDIRECT_URI = "https://ucc-dev.dexmedia.com/callback";
        LOGOUT_REDIRECT_URI = 'https://ucc-dev.dexmedia.com/';
      } else if (location.origin.indexOf('ucc-cfit') > 0) {
        LOGIN_REDIRECT_URI = "https://ucc-cfit.dexmedia.com/callback"
        LOGOUT_REDIRECT_URI = 'https://ucc-cfit.dexmedia.com/';
      } else if (location.origin.indexOf('ucc-ppt') > 0) {
        LOGIN_REDIRECT_URI = "https://ucc-ppt.dexmedia.com/callback"
        LOGOUT_REDIRECT_URI = 'https://ucc-ppt.dexmedia.com/';
      } else if (location.origin.indexOf('ucc-train') > 0) {
        LOGIN_REDIRECT_URI = "https://ucc-train.dexmedia.com/callback"
        LOGOUT_REDIRECT_URI = 'https://ucc-train.dexmedia.com/';
      } else if (location.origin.indexOf('localhost') > 0) {
        LOGIN_REDIRECT_URI = "https://localhost:3000/callback"
        LOGOUT_REDIRECT_URI = 'https://localhost:3000/';
      } else if (location.origin.indexOf('ucc.dexmedia') > 0) {
        LOGIN_REDIRECT_URI = "https://ucc.dexmedia.com/callback"
        LOGOUT_REDIRECT_URI = 'https://ucc.dexmedia.com/';
      }
      let returnObj: any = { "LOGIN_REDIRECT_URI": LOGIN_REDIRECT_URI, "LOGOUT_REDIRECT_URI": LOGOUT_REDIRECT_URI };
      return returnObj
    } else if (service == "api") {
      let sessionApi = sessionStorage.getItem('api');
      if(sessionApi != null ) {
      if (sessionApi.indexOf('ucc-pm') > 0) {
        apiUrl = "https://ucc-pm.dexmedia.com"//"https://localhost:3002"//;        
      } else if (sessionApi.indexOf('ucc-dev') > 0) {
        apiUrl = "https://ucc-dev.dexmedia.com"//"https://localhost:3002";        
      } else if (sessionApi.indexOf('ucc-cfit') > 0) {
        apiUrl = "https://ucc-cfit.dexmedia.com";        
      } else if (sessionApi.indexOf('ucc-ppt') > 0) {
        apiUrl = "https://ucc-ppt.dexmedia.com"        
      } else if (sessionApi.indexOf('ucc-train') > 0) {
        apiUrl = "https://ucc-train.dexmedia.com"        
      } else if (sessionApi.indexOf('localhost') > 0) {
        apiUrl = "https://localhost:3002"
      } else if (sessionApi.indexOf('ucc.dexmedia') > 0) {
        apiUrl = "https://ucc.dexmedia.com"        
      }
      if (window.location && window.location.ancestorOrigins && window.location.ancestorOrigins[0] && window.location.host.indexOf("localhost") > 0) {
        apiUrl = "https://localhost:3002"
      }
      return apiUrl
    } else {
      console.warn('Waiting for sessionStorage to load...');
    }
  }else if (service == "locationurl") {

      let locationurl = constant.LOCATIONURL
      //let locationurl = "&locked=false&admin=false&api=https%3A%2F%2Fucc-dev.dexmedia.com&payNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FpaynowtoolPM%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftxacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FpaynowtoolPM%2FDefaultLanding.aspx&sforg=national"
      if (window.location.href.indexOf('ucc-pm') > 0) {
        locationurl = constant.LOCATTIONURL_PM
      } else if (window.location.href.indexOf('ucc-dev') > 0) {
        locationurl = constant.LOCATIONURL_DEV
      } else if (window.location.href.indexOf('ucc-cfit') > 0) {
        locationurl = constant.LOCATIONURL_CFIT
      } else if (window.location.href.indexOf('ucc-ppt') > 0) {
        locationurl = constant.LOCATIONURL_PPT
      } else if (window.location.href.indexOf('localhost') > 0) {
        //Here we can change the locationurl according to which environment we are using
        locationurl = constant.LOCATTIONURL_PM
        //locationurl = locationurl = "&locked=false&admin=false&api=https%3A%2F%2Fucc-pm.dexmedia.com&payNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FpaynowtoolPM%2FDefaultMap.aspx&sfaUrl=https%3A%2F%2Fftxacrmwest.dexmedia.com%2Facrm.jsp&sideMenuPayNow=https%3A%2F%2Fdctcasa02.corp.pvt%2FpaynowtoolPM%2FDefaultLanding.aspx&sforg=national"
      } else if (window.location.href.indexOf('ucc.dexmedia') > 0) {
        locationurl = constant.LOCATIONURL_PROD
      } else if (window.location.href.indexOf('ucc-train') > 0) {
        locationurl = constant.LOCATIONURL_TRAIN
      }
      //console.log("result",locationurl)
      if (window.location && window.location.ancestorOrigins && window.location.ancestorOrigins[0] && window.location.host.indexOf("localhost") > 0) {
        locationurl = constant.LOCATTIONURL_PM
      }
      return locationurl
    }
    else if (service = "pdfwindow") {
      let sessionApi = sessionStorage.getItem('api');
      if(sessionApi != null){
      let childWindowUrl = "";
      if (sessionApi.indexOf('ucc-pm') > 0) {
        childWindowUrl = "https://ucc-pm.dexmedia.com"        
      } else if (sessionApi.indexOf('ucc-dev') > 0) {
        childWindowUrl = "https://ucc-dev.dexmedia.com"        
      } else if (sessionApi.indexOf('ucc-cfit') > 0) {
        childWindowUrl = "https://ucc-cfit.dexmedia.com";        
      } else if (sessionApi.indexOf('ucc-ppt') > 0) {
        childWindowUrl = "https://ucc-ppt.dexmedia.com"        
      } else if (sessionApi.indexOf('ucc-train') > 0) {
        childWindowUrl = "https://ucc-train.dexmedia.com"        
      } else if (sessionApi.indexOf('localhost') > 0) {
        childWindowUrl = "https://localhost:3000"
      } else if (sessionApi.indexOf('ucc.dexmedia') > 0) {
        childWindowUrl = "https://ucc.dexmedia.com"        
      }
      if (window.location && window.location.ancestorOrigins && window.location.ancestorOrigins[0] && window.location.host.indexOf("localhost") > 0) {
        childWindowUrl = "https://localhost:3000"
      }
      return childWindowUrl;
    }else {
      console.warn('Waiting for sessionStorage to load...');
    }
  }
  }
}
