import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamsService } from '../params/params.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  constructor(private http: HttpClient, private API: ApiService, private Params: ParamsService) { }

  PATH = "/api/call";

  headersObject = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + this.Params.params.token
    }
  }

  httpOptions = {
   headers: { 
     'Content-Type': 'application/json',
     "Authorization" : "Bearer "+ this.Params.params.token
   },
    'id':''   };

  getByAccountId(accId) {
    this.headersObject.headers.Authorization = "Bearer " + this.Params.params.token;
    return this.http.get(this.API.get(this.PATH) + "/" + "acct" + "/" + accId, this.headersObject).toPromise();
  };

  getByItemId(itemId) {
    this.headersObject.headers.Authorization = "Bearer " + this.Params.params.token;
    return this.http.get(this.API.get(this.PATH) + "/" + itemId, this.headersObject).toPromise();
  };

  getByYpcListing(listingId) {
    this.headersObject.headers.Authorization = "Bearer " + this.Params.params.token;
    return this.http.get(this.API.get(this.PATH) + "/" + "ypc" + "/" + listingId, this.headersObject).toPromise();
  };

  terminate(data):Promise<any> {
    let baseUri = "/api/call/terminate";
    this.httpOptions.headers.Authorization = "Bearer "+ this.Params.params.token;  
    return this.http.put(this.API.get(baseUri),data,this.httpOptions).toPromise()
      //return $http.put(API.get(PATH), data);
  };


}
