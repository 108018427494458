import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {forkJoin} from 'rxjs';
//import 'rxjs/add/observable/forkJoin';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  sourcetype : any = "SFDC";
  disablePublishBtn:boolean = true;
  errArray:Array<any> = [];
  errAacrray:Array<any> = [];
  errorCount:number;
  index:number;
  constructor() {
    this.errorCount = 0;
  }
  getUniqueArray(arr : any):any[]{
    let newArray = arr.filter(function (el) {
      return el != null && el != '';
    });
    return newArray.filter((v,i,array)=>array.indexOf(v)==i);
  }
  public requestDataFromMultipleSources(array:any[]) : Promise<any> {

    return forkJoin(array).toPromise();
  }
  encodeQueryData (data : any) {
    var ret = [];
    for (var d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
};

getKgenUTCDate(dt : any) { //Changed from getDate() to getUTCDate() due to DE21904
  var date = new Date(dt);
  var yyyy = "" + date.getUTCFullYear();
  var mm = date.getUTCMonth() < 9 ? "0" + (date.getUTCMonth() + 1) : "" + (date.getUTCMonth() + 1); // getMonth() is zero-based
  var dd  = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : "" + date.getUTCDate();
  return yyyy + mm + dd;
};

isObjectEmpty(obj :any) {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }
  return true && JSON.stringify(obj) === JSON.stringify({});
};

validateYoutubeURL(url:any) {
  var re= /^(?:http)s?:\/\/(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/gi;
  let validURL = re.test(url);
  return validURL;
}


validateURL(url:any,flag:any) {
  var re;
  if(flag){
      //re = /^(?:http)s?:\/\/(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/gi;
        re = /^(http[s]?:\/\/)?(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}$/gi;
  }else{
      re= /^((([A-Za-z]{0,61}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:[A-Za-z]{0,61}\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+|(?:(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)))/;
  }
  let validURL = re.test(url);
  return validURL;
};

validateURLDEXHub(url: any) {
  var re = /^(?:)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i
  return re.test(url);
};

getSets(tArray :any , setCount : any) {
  var returnArray = [];
  for(var i=0; i< (tArray.length-setCount + 1);i++) {
      var tempArray = [];
      for(var j=0; j<setCount;j++) {
          tempArray.push(tArray[i+j]);
      }
      returnArray.push(tempArray);
  }
  return returnArray;
};

getFirstKey(obj : any) {
  for(var key in obj) {
      return key;
  }
};
cancelItem(item : any) : Boolean{
  //Fix for DE15244: Rehandle - Canceled item on re-handled order is not removed in CA
  let cancelItemArray = ["Mobile Sponsorship","Fixed Visibility","Local Match","CRM","Print Ad Online","Prime Display","ATTI","Performance ELP","Orange","IDB","Reach","Placement","Category","Performance ELP","Listing ELP","Trackable URL","Listing CCT","Mobile Website","Mobile Website Bundle","QR Codes"]
  for(let i=0;i<cancelItemArray.length;i++){
    if(item.productName == cancelItemArray[i]){
      return true;
    }
  }
  if(item.actionType == "Cancel" || item.actionCode == "Cancel"){
    return true;
  }else {
    return false
  }
};

uniqueObjInArray  (arr : any, obj : any) {
  this.index = 0;
  for (var j = 0; j < arr.length; j++) {
      if (arr[j].name === obj.name && arr[j].value === obj.value) {
          this.index = 1;
      }
  }
  return this.index;
}



isEmpty = (obj) => {
  return obj === null || undefined
    ? true
    : (() => {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }
      return true;
    })();
};

isString(obj) {
  return (Object.prototype.toString.call(obj) === '[object String]');
};

isValidEmail(mail) {
  if (
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return (false)
}

removeUnicodeChar(val) {
  if(val != "" && val != undefined && !(val instanceof Object)){
     var res = val.replace(/[^\x00-\x7F|"|'|©|®]/g, "");
  }
  return res;
}

stripTags(input, allowed) {
  allowed = (((allowed || '') + '')
  .toLowerCase()
  .match(/<[a-z][a-z0-9]*>/g) || [])
  .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  if (commentsAndPhpTags)
  {
          return input.replace(commentsAndPhpTags, '')
      .replace(tags, function($0, $1) {
          return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
  }
  return;

}
/**
* When array items are objects then find index of the item given an attribute and its value.
* @param array - array to be searched
* @param attr - attribute to be compared. e.g. {attr1 : 'value' }
* @param value - attribute value to be compared e.g. {attr1 : 'value' }
* @returns index of the item in array if found else nothing.
*/
findWithAttr(array:any, attr:any, value:any) {
  for(var i = 0; i < array.length; i += 1) {
      if(array[i][attr] === value) {
          return i;
      }
  }
};

getItem(obj:any, index:any) {
  var counter = 0;
  for(var i in obj) {
      if(counter === index) {
          return obj[i];
      }
      counter++;
  }
};

getKeyByRegex(obj:any, regex:any) {
  for(var key in obj) {
      if(key.match(regex)) {
          return key;
      }
  }
};

getPropertyByRegex(obj:any, regex:any) {
  for(var key in obj) {
      if(key.match(regex)) {
          return obj[key];
      }
  }
};

getFirstItem(obj:any) {
  for(var key in obj) {
      return obj[key];
  }
};

getKey(obj:any, index:any) {
  var counter = 0;
  for(var i in obj) {
      if(counter === index) {
          return i;
      }
      counter++;
  }
};

indexOf(obj:any, item:any) {
  var counter = 0;
  for(var i in obj) {
      if(_.isEqual(item, obj[i])) {
          return counter;
      }
      counter++;
  }
};

getSize(obj: any) {
  var counter = 0;
  for(var i in obj) {
      if(obj.hasOwnProperty(i)) {
          counter++;
      }
  }
  return counter;
};

getKgenDate(dt: any) {
  var date = new Date(dt);
  var yyyy = "" + date.getFullYear();
  var mm = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : "" + (date.getMonth() + 1); // getMonth() is zero-based
  var dd  = date.getDate() < 10 ? "0" + date.getDate() : "" + date.getDate();
  return yyyy + mm + dd;
};

getSigDate(dt: any) { //Changed from getDate() to getUTCDate() due to DE21904
  var date = new Date(dt);
  var yyyy = "" + date.getUTCFullYear();
  var mm = date.getUTCMonth() < 9 ? "0" + (date.getUTCMonth() + 1) : "" + (date.getUTCMonth() + 1); // getMonth() is zero-based
  var dd  = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : "" + date.getUTCDate();
  return yyyy +"-" + mm + "-" + dd;
};

findDifference(value: any, oldValue: any) {
  var fieldDifferences = [];

  for(var key in value) {
      if(oldValue[key] == null || oldValue[key] !== value[key]){
          fieldDifferences.push(key);
      }
  }

  return fieldDifferences;
};

serial(promises) {
  var promise = promises.shift();
  return promise
  .then(function(data) {
      if(promises.length > 0) {
          return this.serial(promises);
      } else {
          return;
      }
  });
};

//Validation for business description
validateBP (getValue){
if((getValue.keyCode == 13 || getValue.which == 13 || getValue.keyCode == 10 || getValue.which == 10) || getValue.shiftKey && (getValue.which == 188 || getValue.which == 190 || getValue.which == 124)){
  getValue.preventDefault();
 }
};

formatPhoneNumber  (s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
};

convertBadKeys (obj){
  var newObj = {};
  for(var key in obj) {
      if (obj.hasOwnProperty(key)) {
          var trimmedKey = key.trim();
          newObj[trimmedKey] = obj[key];
        }
  }
  return newObj;
};
//function to compare string values ignoring cases.because API can send value in any format(uppercase lowercase camelCase)
compareStringValueOnly (obj1,obj2){
      return obj1.toUpperCase() == obj2.toUpperCase();

}

//Utility function to look if keys exists in arrays or objects.
//Just pass it the key you want to find, and search obj (the object or array) you want to find it in.
isKeyExists (key, search) {
  if (!search || (search.constructor !== Array && search.constructor !== Object)) {
      return false;
  }
  for (var i = 0; i < search.length; i++) {
      if (search[i] === key) {
          return true;
      }
  }
  return key in search;
}
/* Adding this function to solve https://jira.dexyp.com/browse/FUL-11603
this function will take amount as string and retun the amount in the format of two decimal digit.
Scenarios:
If  amount is $140    --> it will return $140.00
If  amount is $140.3  --> it will return $140.30
If  amount is $140.34 --> it will return $140.34
*/
showtwoDecimalDigit(amount){
  if(amount){
      var localAmount = amount.split(".");
      if(localAmount[1]){
          if(localAmount[1].length == 1){
              return amount+"0";
          }else{
               return amount
              }
      }else{
          return amount + ".00";
      }
  }

}

 transformTelephone(tel) {
    let value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country, city, number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country == 1) {
      country = "";
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    return (country + " (" + city + ") " + number).trim();
  }
  //Check if object is empty (having keys but no values)
  checkObjectEmpty(object) {
    for (var key in object) {
      if (object[key] !== null && object[key] != "") return false;
    }
    return true;
}
checkObjectLength(object) {
  let requiredFlag = false;
  if(object && Object.keys(object).length == 0){
    return true
  } else if(Object.keys(object).length > 0){
    for (var key in object) {
      if(key == 'CallTrackingCount'){
        if (object[key] !== null && object[key] != undefined && object[key]==0){
          requiredFlag = true;
        }
      }
      
    }
    if(requiredFlag){
      return true
    } else{
      return false
    }
  }
}

}

