import { Component, OnInit } from '@angular/core';
import { HOURS, HOURSOPTIONS } from 'app/data/constants';
import { IndicatorQuery } from 'app/state/query';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-dpm-hoursofoperation',
  templateUrl: './dpm-hoursofoperation.component.html',
  styleUrls: ['./dpm-hoursofoperation.component.scss']
})
export class DpmHoursofoperationComponent implements OnInit {
  selectedOption: any="";
  selectedDay:any = {};
  ngModel:any =[];
  hours:any = HOURS;
  hoursoptions :any = HOURSOPTIONS;
  innerModel= this.ngModel;
  selectedListing: any;
  isReadonly :boolean =false;
  options;  
  id; 
  schemaObj; 
  titleContent;
  Indicator;
  selectedCheckboxWeekdays;
    fromHourWeekDays: string;
    selectedCheckboxWeekEnds;
    toHourWeekEnds: string;
    toHourWeekDays: string;
    fromHourWeekEnds: any;
  constructor(private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore) { }

  ngOnInit() {
    this.options = this['layoutNode'].options;  
    this.id = this['layoutNode'].name;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.Indicator = JSON.parse(JSON.stringify(res));
      this.schemaObj=this.Indicator.items[this.Indicator.stateParamsId].schema.properties[this['layoutNode'].name];
      this.titleContent =  this.schemaObj.title;
        this.isReadonly = this.schemaObj.readonly;
    });
    this.innerModel =this.Indicator.items[this.Indicator.stateParamsId].model.BusinessHours ?this.Indicator.items[this.Indicator.stateParamsId].model.BusinessHours : null;
    if(this.innerModel == null || this.innerModel == "") {
      this.selectedListing = "Open 24/7";
      this.innerModel = {
          "hrsOpsSlots": { "slot" : this.hours },
          "dayOfWeek": {
              "label": "Hours of Operation",
              "updatable": "Y",
              "schedule": [{
                  "weekday": "Monday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }, {
                  "weekday": "Tuesday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }, {
                  "weekday": "Wednesday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }, {
                  "weekday": "Thursday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }, {
                  "weekday": "Friday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }, {
                  "weekday": "Saturday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }, {
                  "weekday": "Sunday",
                  "closed": "N",
                  "startTime": this.hours[0],
                  "endTime": this.hours[0]
              }]
          }

      };
     } else {
      //.match(/\d+/g).join("")
      let open247Counter = 0;
      let openMonFri95Counter = 0;
      let byAppointmentOnlyCounter = 0;
     // let schedule = $scope.ngModel.dayOfWeek.schedule;
      let scheduleArray :any =[];
      let stringObj=this.innerModel;
      scheduleArray = stringObj.split(',');
      let eachGroup:any = {};
      eachGroup.hrsOpsSlots={"slot" : this.hours};
      eachGroup.dayOfWeek={};
      eachGroup.dayOfWeek.label="Hours of Operation";
      eachGroup.dayOfWeek.updatable="Y";
      eachGroup.dayOfWeek.schedule=[];
      scheduleArray.forEach((value)=> {
          let eachDay:any ={};
          let localSplitArray = value.split(':');

              if(localSplitArray[0] === "2"){
                  eachDay.weekday="Monday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              }
              else if(localSplitArray[0]==="3"){
                  eachDay.weekday="Tuesday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              } else  if(localSplitArray[0]==="4"){
                  eachDay.weekday="Wednesday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              }else  if(localSplitArray[0]==="5"){
                  eachDay.weekday="Thursday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              }else if(localSplitArray[0]==="6"){
                  eachDay.weekday="Friday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              }else  if(localSplitArray[0]==="7"){
                  eachDay.weekday="Saturday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              }else if(localSplitArray[0]==="1"){
                  eachDay.weekday="Sunday";
                  eachDay.startTime=localSplitArray[1]+":"+localSplitArray[2];
                  eachDay.endTime=localSplitArray[3]+":"+localSplitArray[4];
                  if(eachDay.startTime!==eachDay.endTime){
                      eachDay.closed="N";
                  }
                  else
                      eachDay.closed="Y";
                  eachGroup.dayOfWeek.schedule.push(eachDay);
              }

          }

      );
      this.innerModel = eachGroup;
      let schedule = this.innerModel.dayOfWeek.schedule;
      for(let i = 0; i < schedule.length; i++){
          this.selectedDay[schedule[i].weekday] = (schedule[i].closed === "N");

          if(schedule[i].startTime === this.hours[0] &&
              schedule[i].endTime === this.hours[48] &&
              schedule[i].closed === "N"){
              open247Counter++;
          }

          if(i < 5 && schedule[i].startTime === "09:00" &&
              schedule[i].endTime === "17:00" &&
              schedule[i].closed === "N"){
              openMonFri95Counter++;
          }

          if(schedule[i].startTime === "" &&
              schedule[i].endTime === "" &&
              schedule[i].closed === "N"){
              byAppointmentOnlyCounter++;
          }
      }

      if(open247Counter === 7) {
          this.selectedListing = "Open 24/7";
      } else if(openMonFri95Counter === 5) {
          this.selectedListing = "Open Mon-Fri 9AM-5PM";
      } else if(byAppointmentOnlyCounter === 7) {
          this.selectedListing = "By Appointment Only";
      } else {
          this.selectedListing = "Specify Business Hours";
      }
    }
    
    this.selectedValue();

  }
  //Usine ngDoCheck  for $watch in angular 1.5
  ngDoCheck(){
    let temp = this.innerModel;
           let daysArray=temp.dayOfWeek.schedule;
           let ngModelString="";
           daysArray.forEach((value)=>{

                if(value.weekday==="Monday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"2"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"2"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Tuesday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"3"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"3"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Wednesday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"4"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"4"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Thursday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"5"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"5"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Friday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"6"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"6"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Saturday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"7"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"7"+":"+"00:00"+":"+"00:00"+",";
                    }
                }   else  if(value.weekday==="Sunday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"1"+":"+value.startTime+":"+value.endTime;
                    else{
                        ngModelString=ngModelString+"1"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
            });

          this.ngModel=ngModelString;


  }

selectedValue(){
    if(this.selectedListing === "Open 24/7") {
        for(let i = 0; i < this.innerModel.dayOfWeek.schedule.length; i++) {
            this.innerModel.dayOfWeek.schedule[i].closed = "N";
            this.innerModel.dayOfWeek.schedule[i].startTime = "00:00";
            this.innerModel.dayOfWeek.schedule[i].endTime = "23:59";
            this.selectedDay[this.innerModel.dayOfWeek.schedule[i].weekday] = (this.innerModel.dayOfWeek.schedule[i].closed === "N");
        }
    } else if(this.selectedListing === "Open Mon-Fri 9AM-5PM") {
        for(let i = 0; i < this.innerModel.dayOfWeek.schedule.length; i++) {
            if(i < 5) {
                this.innerModel.dayOfWeek.schedule[i].closed = "N";
                this.innerModel.dayOfWeek.schedule[i].startTime = "09:00";
                this.innerModel.dayOfWeek.schedule[i].endTime = "17:00";
            } else {
                this.innerModel.dayOfWeek.schedule[i].closed = "Y";
                this.innerModel.dayOfWeek.schedule[i].startTime = "00:00";
                this.innerModel.dayOfWeek.schedule[i].endTime = "00:00";
            }
            this.selectedDay[this.innerModel.dayOfWeek.schedule[i].weekday] = (this.innerModel.dayOfWeek.schedule[i].closed === "N");
        }
    } else if(this.selectedListing === "By Appointment Only") {
        for(let i = 0; i < this.innerModel.dayOfWeek.schedule.length; i++) {
            this.innerModel.dayOfWeek.schedule[i].closed = "N";
            this.innerModel.dayOfWeek.schedule[i].startTime = "";
            this.innerModel.dayOfWeek.schedule[i].endTime = "";
            this.selectedDay[this.innerModel.dayOfWeek.schedule[i].weekday] = (this.innerModel.dayOfWeek.schedule[i].closed === "N");
        }
    }
    this.getModelValue();
};
  checkboxWeekday (item) {
    item.closed = (this.selectedDay[item.weekday]) ? "N" : "Y";
};

checkboxWeekdays() {
    let value = "Y"
    if(this.selectedCheckboxWeekdays) {
        value = "N";
    }
    for(let i = 0; i < 5; i++) {
        this.selectedDay[this.innerModel.dayOfWeek.schedule[i].weekday] = this.selectedCheckboxWeekdays;
        this.innerModel.dayOfWeek.schedule[i].closed = value;
    }
};

checkboxWeekEnds() {
    let value = "Y"
    if(this.selectedCheckboxWeekEnds) {
        value = "N";
    }
    for(let i = 5; i < 7; i++) {
        this.selectedDay[this.innerModel.dayOfWeek.schedule[i].weekday] = this.selectedCheckboxWeekEnds;
        this.innerModel.dayOfWeek.schedule[i].closed = value;
    }
};

fromWeekDays(index?) {
    if(index != null) {
        if(index < 5) {
            this.selectedCheckboxWeekdays = false;
            this.fromHourWeekDays="Various";
        } else {
            this.selectedCheckboxWeekdays = false;
            this.fromHourWeekDays="Various";
        }
    } else {
        if(this.selectedCheckboxWeekdays){
            for(let i = 0; i < 5; i++) {
                this.innerModel.dayOfWeek.schedule[i].startTime = this.fromHourWeekDays;
            }
        }
    }
  };
toWeekDays(index?) {
  if(index != null) {
      if(index < 5) {
          this.selectedCheckboxWeekdays = false;
          this.toHourWeekDays="Various";
      } else {
          this.selectedCheckboxWeekEnds = false;
          this.toHourWeekEnds="Various";
      }
  } else {
      if(this.selectedCheckboxWeekdays){
          for(let i = 0; i < 5; i++) {
              this.innerModel.dayOfWeek.schedule[i].endTime = this.toHourWeekDays;
          }
      }
  }
  this.getModelValue();
};

fromWeekEnds() {
  if(this.selectedCheckboxWeekEnds){
      for(let i = 5; i < 7; i++) {
          this.innerModel.dayOfWeek.schedule[i].startTime = this.fromHourWeekEnds;
      }
  }
};

toWeekEnds() {
  if(this.selectedCheckboxWeekEnds){
      for(let i = 5; i < 7; i++) {
          this.innerModel.dayOfWeek.schedule[i].endTime = this.toHourWeekEnds;
      }
  }
  this.getModelValue();
};
getModelValue(){
    let temp = this.innerModel;
           let daysArray=temp.dayOfWeek.schedule;
           let ngModelString="";
            daysArray.forEach((value, index) => {
                if(value.weekday==="Monday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"2"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"2"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Tuesday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"3"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"3"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Wednesday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"4"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"4"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Thursday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"5"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"5"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Friday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"6"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"6"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
                else  if(value.weekday==="Saturday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"7"+":"+value.startTime+":"+value.endTime+",";
                    else{
                        ngModelString=ngModelString+"7"+":"+"00:00"+":"+"00:00"+",";
                    }
                }   else  if(value.weekday==="Sunday"){
                    if(value.closed === "N")
                    ngModelString=ngModelString+"1"+":"+value.startTime+":"+value.endTime;
                    else{
                        ngModelString=ngModelString+"1"+":"+"00:00"+":"+"00:00"+",";
                    }
                }
            });
        this.ngModel=ngModelString;
        let upadatedName = JSON.parse(JSON.stringify(this.Indicator));      
        let modelData=  JSON.parse(JSON.stringify(this.indicatorQuery.getValue().indicator['items'][upadatedName.stateParamsId].model))
        this.indicatorQuery.updateModel(this['layoutNode'].name,ngModelString, modelData, upadatedName, '[DpmHoursofoperationComponent] getModelValue');


}

}
