import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IndicatorQuery } from 'app/state/query';
import { Subscription } from 'rxjs';
import { CallComponent } from '../call/call.component';
import { IndicatorStore } from 'app/state/store';

@Component({
  selector: 'app-calltrackingdropdown',
  templateUrl: './calltrackingdropdown.component.html',
  styleUrls: ['./calltrackingdropdown.component.scss']
})
export class CalltrackingdropdownComponent implements OnInit {
  content: string;
  subscription: Subscription;
  sharedData: any;
  mode:boolean;
  constructor(
    private dialogRef: MatDialogRef<CalltrackingdropdownComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private indicatorQuery: IndicatorQuery,private indicatorStore: IndicatorStore) {

     }

  ngOnInit() {
    this.content = this.data.billingType;
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    this.mode = this.data.mode;
  }
  onSubmit() {
    let data = JSON.parse(JSON.stringify(this.sharedData));
    data.items[data.stateParamsId].calltracking = [];
    this.indicatorStore.setLoading(true);
          this.indicatorStore.update((state) => {
            return {
              indicator:data,
              updatedFrom: "[CalltrackingdropdownComponent] onSubmit",
              isloaded: true,
            };
          });
          this.indicatorStore.setLoading(false);
    this.dialogRef.close("No");

  }
  close(){
    this.mode = !this.mode;
    this.dialogRef.close("Yes");
  }

}
