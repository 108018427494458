import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlingService {

  constructor() { }

  handleApiError(error) {
    let errorMsg;
    if(error && error!=undefined && error!=null && error.hasOwnProperty("error")){
      errorMsg = JSON.stringify(error.error);
    } else{
      errorMsg = "Error";
    }
    return errorMsg;
  }

}
